import React from 'react'
import { FormControl, MenuItem, Select, Typography } from '@mui/material' // Import necessary MUI components
import TextBox from './TextBox' // Import TextBox component

export default function SelectBox(props) {
  const menuStyle = {
    whiteSpace: 'normal',
  }

  return (
    <FormControl sx={{ minWidth: '100%', marginBottom: '16px' }}>
      {props.isLabel && (
        <Typography
          variant="label"
          style={{ fontSize: '14px', textAlign: 'left', marginBottom: 10 }}
        >
          {props.labelValue}
        </Typography>
      )}
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={props.value}
        name={props.name}
        error={props.error}
        onChange={props.onChange}
        variant="outlined"
        autoWidth={false}
        size={props.size}
        style={{
          borderColor: 'red',
          maxHeight: '400px',
        }}
        InputProps={{
          style: {
            borderColor: 'red', // Set your desired border color
          },
        }}
      >
        {props.options &&
          props.options.map((option) => (
            <MenuItem key={option.id} value={option.id} style={menuStyle}>
              {props.labelCondition === 'name'
                ? option.name
                : props.labelCondition === 'organization_name'
                  ? option.organization_name
                  : null}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}
