import React from 'react'
import TextBox from '../../input/TextBox'
import { general_Details } from '../../../recoil/atoms'
import { useRecoilState } from 'recoil'
import { v4 as uuidv4 } from 'uuid'
import { trial_name } from '../../../recoil/atoms'

const GeneralDetails = () => {
  const [GeneralDetails, setGeneralDetails] = useRecoilState(general_Details)
  const [trialName] = useRecoilState(trial_name)
  console.log(GeneralDetails, 'GeneralDetailsGeneralDetails')
  const uuid = uuidv4()

  const handleChange = (e) => {
    const { name, value } = e.target

    // Validation for mobile number
    if (name === 'mobile_number') {
      const digitsOnly = value.replace(/\D/g, '') // Remove non-digit characters
      if (digitsOnly.length > 10) return // Prevent input if more than 10 digits
      setGeneralDetails((prevGeneralDetails) => ({
        ...prevGeneralDetails,
        [name]: digitsOnly,
      }))
    } else {
      setGeneralDetails((prevGeneralDetails) => ({
        ...prevGeneralDetails,
        [name]: value,
      }))
    }
  }

  const isReadOnly = trialName === 'viewDataCollection'

  return (
    <div style={{ width: '100%' }}>
      {/* <div style={{ marginTop: "15px", marginRight: "15px" }}>
        <TextBox
          name="customer_name"
          label="Name of Customer"
          value={GeneralDetails && GeneralDetails.customer_name ? GeneralDetails.customer_name : ""}
          onChange={handleChange}
          disabled={isReadOnly} 
        />
      </div> */}
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="contact_person"
          label="Contact Person"
          value={
            GeneralDetails.contact_person ? GeneralDetails.contact_person : ''
          }
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="mobile_number"
          label="Mobile Number"
          type="number"
          value={GeneralDetails.mobile_number}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Date of Data Collection"
          type="date"
          value={GeneralDetails.date}
          onChange={handleChange}
          showCalendarIcon={true}
          disabled={isReadOnly}
        />
      </div>
    </div>
  )
}

export default GeneralDetails
