import React, { useState, useEffect } from 'react'
import { useRecoilState } from 'recoil'
import TextBox from '../../input/TextBox'
import Typography from '@mui/material/Typography'
import {
  cost_sheet_furnace,
  cost_sheet_furnace_nrm,
} from '../../../recoil/atoms'
import { trial_name } from '../../../recoil/atoms'
const Furnace = () => {
  const [furnaceDetails, setFurnaceDetails] = useRecoilState(cost_sheet_furnace)
  const [furnaceDetailsNRM, setFurnaceDetailsNRM] = useRecoilState(
    cost_sheet_furnace_nrm
  )
  const [trialName] = useRecoilState(trial_name)
  const isReadOnly = trialName === 'viewCostSheet'

  useEffect(() => {
    let furnaceObj = {
      furnaceDetails: {
        basic: furnaceDetails || {}, // Add a default empty object
        nrm: furnaceDetailsNRM || {}, // Add a default empty object
      },
    }
    console.log(furnaceObj, '35656')
  }, [furnaceDetails, furnaceDetailsNRM])

  const handleChange = (e) => {
    const { name, value } = e.target
    const regex = /^[0-9]*\.?[0-9]{0,2}$/
    if (regex.test(value) || value === '') {
      setFurnaceDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const handleChangeNRM = (e) => {
    const { name, value } = e.target
    const regex = /^[0-9]*\.?[0-9]{0,2}$/
    if (regex.test(value) || value === '') {
      setFurnaceDetailsNRM((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const custDet = JSON.parse(localStorage.getItem('customerDetails'))

  return (
    <div style={{ width: '100%' }}>
      <div
        style={{
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '15px',
        }}
      >
        <div style={{ marginRight: '20px' }}>
          <Typography
            variant="body1"
            style={{
              color: '#000000',
              fontFamily: 'Inter',
              fontWeight: 600,
              fontSize: '16px',
              lineHeight: '20px',
              marginTop: '0px',
            }}
          >
            {custDet.parameter}
          </Typography>
          <div style={{ marginTop: '10px' }}>
            <TextBox
              name="furnace_capacity"
              label="Furnace Capacity"
              type="text"
              value={furnaceDetails.furnace_capacity || ''} // Access property only if object is not null
              onChange={handleChange}
              disabled={isReadOnly}
            />
          </div>
          <div style={{ marginTop: '10px' }}>
            <TextBox
              name="furnace_output"
              label="Furnace output in Tons- sintering heat"
              value={furnaceDetails.furnace_output || ''} // Access property only if object is not null
              onChange={handleChange}
              type="text"
              disabled={isReadOnly}
            />
          </div>
        </div>
        <div>
          <Typography
            variant="body1"
            style={{
              color: '#000000',
              fontFamily: 'Inter',
              fontWeight: 600,
              fontSize: '14px',
              lineHeight: '20px',
              marginTop: '0px',
            }}
          >
            NRM
          </Typography>
          <div style={{ marginTop: '10px' }}>
            <TextBox
              name="furnace_capacity"
              label="Furnace Capacity"
              type="text"
              value={furnaceDetailsNRM.furnace_capacity || ''} // Access property only if object is not null
              onChange={handleChangeNRM}
              disabled={isReadOnly}
            />
          </div>
          <div style={{ marginTop: '10px' }}>
            <TextBox
              name="furnace_output"
              label="Furnace output in Tons- sintering heat"
              value={furnaceDetailsNRM.furnace_output || ''} // Access property only if object is not null
              onChange={handleChangeNRM}
              disabled={isReadOnly}
              type="text"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Furnace
