import React, { useEffect, useState } from 'react'
import { Autocomplete, TextField, Typography } from '@mui/material'

export default function AutoSelectBox(props) {
  const [selectedValue, setSelectedValue] = useState(null)

  useEffect(() => {
    if (props.options && props.value) {
      const selectedOption = props.options.find(
        (option) => option.id === props.value
      )
      setSelectedValue(selectedOption || null) // Ensure selectedOption is set or null
    }
  }, [props.options, props.value])

  const handleChange = (event, value) => {
    props.onChange({
      target: { name: props.name, value: value ? value.id : '' },
    })
  }

  const getOptionLabel = (option) => {
    const furnace_id = option.furnace_number + '-' + option.capacity
    return option.name || option.organization_name || furnace_id || ''
  }

  return (
    <div
      style={{ minWidth: '100%', marginBottom: '16px', position: 'relative',marginTop: window.innerWidth > 600 ? '15px' : '0px'  }}
    >
      {props.isLabel && (
        <Typography
          variant="label"
          style={{ fontSize: '14px', textAlign: 'left', marginBottom: 10 }}
        >
          {props.labelValue}
        </Typography>
      )}
      <Autocomplete
        id={props.name}
        options={props.options || []}
        getOptionLabel={getOptionLabel}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            error={!!props.error}
            helperText={props.error}
            label = {props.label}
            InputProps={{
              ...params.InputProps,
              ...props.TextFieldProps?.InputProps, // Allow overriding InputProps
            }}
          />
        )}
        onChange={handleChange}
        value={selectedValue} // Use the selected value from state
      />
    </div>
  )
}
