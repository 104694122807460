import React, { useState, useRef, useEffect } from 'react'
import { Card, CardContent, Typography, Avatar, Grid } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton } from '@mui/material'
import ViewIcon from '../../assets/icons/ic_view.png'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import API from '../services/API'
import { general_details } from '../../recoil/atoms'
import Tooltip from '@mui/material/Tooltip'
import * as XLSX from 'xlsx'
import DownloadIcon from '../../assets/icons/download_icon.svg'
import FileSaver from 'file-saver'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import Collapsed from '../../assets/icons/collapsed.svg'
import Expanded from '../../assets/icons/expanded.svg'

const DailyTrialMonitoring = ({
  selectedTab,
  cardsSubmitted,
  cardsDraft,
  cardsApproved,
  cardsRejected,
  file,
  selectedTemplate,
  SegmentList,
  filteredDailyTrials,
}) => {
  console.log(filteredDailyTrials, 'filteredDailyTrials')
  console.log(cardsRejected, 'cardsRejected')
  const navigate = useNavigate()
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [popupPosition, setPopupPosition] = useState({ top: 0, right: 0 })
  const [dataFileId, setDataFileId] = useState(null)
  const popupRef = useRef(null)
  const currentUser = JSON.parse(localStorage.getItem('user'))
  const [loading, setLoading] = useState(false)
  const access_token = localStorage.getItem('accesstoken')

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      closePopup()
    }
  }

  const closePopup = () => {
    setIsPopupOpen(false)
    console.log('Popup closed')
  }

  useEffect(() => {
    if (isPopupOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isPopupOpen])

  // const handleDelete = async () => {
  //   console.log("Button clicked!");
  //   console.log("cardId", dataFileId);

  //   try {
  //     // Make the API call here
  //     const url = `${API.DELETE_CARD}/${dataFileId}`;
  //     console.log("url", url);
  //     console.log("API.DELETE_CARD", API.DELETE_CARD);
  //     const response = await axios.delete(url);
  //     const data = response.data;

  //     // Do something with the response data
  //     console.log("DELETE_CARD", data);
  //     setIsPopupOpen(false);
  //     window.location.reload();
  //   } catch (error) {
  //     console.error("Error:", error);
  //     // Optionally, you can handle the error state here
  //   }
  // };

  const handleView = async () => {
    console.log('viewww', 'caleed')

    localStorage.setItem('datafile_id', dataFileId)
    navigate('/viewtrial')
  }

  const handleDownload = async () => {
    console.log('handle download clicked')
    const id = localStorage.getItem('dailytrialID')
    console.log('id', id)
    setLoading(true)

    try {
      // Fetch data from the endpoint
      const response = await axios.get(`${API.GET_DAILY_TRIAL_BY_ID}/${id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      const data = response.data
      console.log(data, 'data1234')

      // Fetch the Excel template
      const responseTemplate = await axios.get(
        API.GET_DAILY_TRIAL_EXCEL_DOWNLOAD,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      console.log(responseTemplate, 'responseTemplate')

      // Decode base64 to ArrayBuffer
      const base64Data = responseTemplate.data.downloadedTemplates[0].data
      const byteCharacters = atob(base64Data)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const arrayBuffer = new Uint8Array(byteNumbers).buffer

      // Create a new workbook and load the template
      const workbook = new ExcelJS.Workbook()
      await workbook.xlsx.load(arrayBuffer)

      // Get the worksheet
      const worksheet = workbook.worksheets[0]

      // Ensure data arrays are initialized
      const generalDetails = data.general_details || []
      const furnaceDetails = data.furnace_details || []
      const chargeMixDetails = data.charge_mix_details || []
      const carbonDetails = data.carbon || []
      console.log(carbonDetails, 'carbonDetails')
      const alloyingAddition = data.alloying_addition || []
      const otherParameters = data.other_parameters || []

      // Add general details data
      generalDetails.forEach((detail, index) => {
        if (detail) {
          // Ensure detail is not null
          worksheet.getCell(`A${9 + index}`).value = detail.furnace_no || ''
          worksheet.getCell(`B${9 + index}`).value = detail.crucible_no || ''
          worksheet.getCell(`C${9 + index}`).value = detail.campaign_no || ''
          worksheet.getCell(`D${9 + index}`).value =
            detail.lining_patching_no || ''
          worksheet.getCell(`E${9 + index}`).value = detail.heat_no || ''
          worksheet.getCell(`F${9 + index}`).value = detail.date || ''
          worksheet.getCell(`G${9 + index}`).value =
            detail.customer_ref_no || ''
          worksheet.getCell(`H${9 + index}`).value = detail.grade || ''
        }
      })

      // Add furnace details data
      furnaceDetails.forEach((detail, index) => {
        if (detail) {
          // Ensure detail is not null
          worksheet.getCell(`I${9 + index}`).value = detail.heat_on_time
          worksheet.getCell(`J${9 + index}`).value = detail.heat_tap_time
          worksheet.getCell(`K${9 + index}`).value = detail.break_down_duration
          worksheet.getCell(`L${9 + index}`).value = detail.heat_duration_actual
          // worksheet.getCell(`M${9 + index}`).value = detail.heat_duration_calculated;
          worksheet.getCell(`N${9 + index}`).value = detail.tapping_temperature
          worksheet.getCell(`O${9 + index}`).value = detail.tapped_tonnage
          // worksheet.getCell(`P${9 + index}`).value = detail.melt_rate_based_on_actual_duration;
          // worksheet.getCell(`Q${9 + index}`).value = detail.melt_rate_based_on_calculated_duration;
          worksheet.getCell(`R${9 + index}`).value = detail.frequency
          worksheet.getCell(`S${9 + index}`).value = detail.voltage
          worksheet.getCell(`T${9 + index}`).value = detail.power_in_the_panel
          worksheet.getCell(`U${9 + index}`).value = detail.capacitors_removed
          worksheet.getCell(`V${9 + index}`).value = detail.total_power_consumed
          worksheet.getCell(`W${9 + index}`).value =
            detail.energy_consumption_per_unit_ton_actual
          // worksheet.getCell(`X${9 + index}`).value = detail.energy_consumption_per_unit_ton_calculated;
        }
      })

      // Add charge mix details data
      chargeMixDetails.forEach((detail, index) => {
        if (detail) {
          // Ensure detail is not null
          worksheet.getCell(`Y${9 + index}`).value = detail.scrap
          worksheet.getCell(`Z${9 + index}`).value = detail.shredded
          worksheet.getCell(`AA${9 + index}`).value = detail.pig_iron
          worksheet.getCell(`AB${9 + index}`).value = detail.pooled_iron
          worksheet.getCell(`AC${9 + index}`).value = detail.hot_heel
          worksheet.getCell(`AD${9 + index}`).value = detail.lump
          worksheet.getCell(`AE${9 + index}`).value = detail.pellet
          worksheet.getCell(`AF${9 + index}`).value = detail.fines_lessthan_1mm
          worksheet.getCell(`AG${9 + index}`).value = detail.fines_lessthan_5mm
          worksheet.getCell(`AH${9 + index}`).value = detail.slag_crushers
          worksheet.getCell(`AI${9 + index}`).value =
            detail.tundish_or_slag_or_metalic_jam
          // worksheet.getCell(`AJ${9 + index}`).value = detail.total_charge;
        }
      })

      // Add carbon details data
      const carbonMapping = {
        cpc: 'cpc',
        carbon_block: 'carbon_block',
        anthracite: 'anthracite',
      }
      let carbonType = 'cpc'
      carbonDetails.forEach((detail, index) => {
        if (detail) {
          // Ensure detail is not null
          worksheet.getCell(`AK${9 + index}`).value =
            detail[carbonMapping[carbonType]]
          worksheet.getCell(`AL${9 + index}`).value = detail.met_coke
          worksheet.getCell(`AM${9 + index}`).value = detail.fifty_c_percentage
          worksheet.getCell(`AN${9 + index}`).value =
            detail.eightyfive_c_percentage
          worksheet.getCell(`AO${9 + index}`).value = detail.final_c_percentage
        }
      })

      // Add alloying addition data
      alloyingAddition.forEach((detail, index) => {
        if (detail) {
          // Ensure detail is not null
          worksheet.getCell(`AP${9 + index}`).value =
            detail.si_before_alloy_addition
          worksheet.getCell(`AQ${9 + index}`).value = detail.final_si
          worksheet.getCell(`AR${9 + index}`).value =
            detail.mn_before_alloy_addition
          worksheet.getCell(`AS${9 + index}`).value = detail.final_mn
          worksheet.getCell(`AT${9 + index}`).value =
            detail.cr_before_alloy_addition
          worksheet.getCell(`AU${9 + index}`).value = detail.final_cr
          worksheet.getCell(`AV${9 + index}`).value = detail.si_mn
          worksheet.getCell(`AW${9 + index}`).value = detail.mn_in_simn
          worksheet.getCell(`AX${9 + index}`).value = detail.feMn
          worksheet.getCell(`AY${9 + index}`).value = detail.feSi
          worksheet.getCell(`AZ${9 + index}`).value = detail.feCr
          worksheet.getCell(`BA${9 + index}`).value = detail.mill_scale
          worksheet.getCell(`BB${9 + index}`).value =
            detail.any_other_alloy_addition
        }
      })

      // Add other parameters data
      otherParameters.forEach((detail, index) => {
        if (detail) {
          // Ensure detail is not null
          worksheet.getCell(`BC${9 + index}`).value = detail.inlet_water_temp
          worksheet.getCell(`BD${9 + index}`).value = detail.outlet_water_temp
          worksheet.getCell(`BE${9 + index}`).value = 1
          worksheet.getCell(`BF${9 + index}`).value = detail.remarks
        }
      })

      // Generate the modified workbook and save
      const buffer = await workbook.xlsx.writeBuffer()
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })

      // Use FileSaver to save the file
      saveAs(blob, 'daily_trial_monitoring_with_data.xlsx')
    } catch (error) {
      console.error('Error downloading or processing template:', error.message)
    } finally {
      setLoading(false)
    }
  }

  const workbookToBlob = (workbook) => {
    return new Promise((resolve) => {
      // Write the workbook to a binary string
      const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' })

      // Convert the binary string to a Blob
      const blob = new Blob([s2ab(wbout)], {
        type: 'application/octet-stream',
      })
      resolve(blob)
    })
  }

  // Utility function to convert a string to an ArrayBuffer
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
    return buf
  }

  const handleMenuOpen = (event, item) => {
    const rect = event.target.getBoundingClientRect()
    setIsPopupOpen(true)
    setPopupPosition({
      top: rect.top + window.scrollY,
      right: window.innerWidth - rect.right,
    })
    console.log('itemdetails', item)
    localStorage.setItem('datafile_info', JSON.stringify(item))
    localStorage.setItem('dailytrialID', item.id)
    setDataFileId(item.id)
  }

  // const handleCardClick = async (cardId ) => {
  //     try {
  //         // Make the API call here
  //         const url= `${API.GET_CARD_DETAILS}/${cardId}`
  //         console.log("url",url)
  //         console.log("API.GET_CARD_DETAILS",API.GET_CARD_DETAILS)
  //         const response = await axios.get(url);
  //         const data = response.data;
  //         // Do something with the response data
  //         console.log('Card Details:', data);

  //     } catch (error) {
  //         console.error('Error:', error);
  //         // Optionally, you can handle the error state here
  //     }

  // };

  const handleApproval = async () => {
    console.log('cardId', dataFileId)

    try {
      // Make the API call here
      const url = `${API.APPROVED_CARD_UPDATE}/${dataFileId}`
      const response = await axios.post(url, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      const data = response.data
      // Do something with the response data
      console.log('Card Details:', data)
      setIsPopupOpen(false)
      window.location.reload()
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const handleRejected = async () => {
    console.log('cardId', dataFileId)

    try {
      const url = `${API.REJECTED_CARD_UPDATE}/${dataFileId}`
      const response = await axios.post(url, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      const data = response.data

      console.log('Card Details:', data)
      setIsPopupOpen(false)
      window.location.reload()
    } catch (error) {
      console.error('Error:', error)
    }
  }
  const [isDraftOpen, setIsDraftOpen] = useState(false)
  const [isSubmittedOpen, setIsSubmittedOpen] = useState(false)
  const [isRejectedOpen, setIsRejectedOpen] = useState(false)
  const [isApprovedOpen, setIsApprovedOpen] = useState(false)

  const [toggleCount, setToggleCount] = useState(0)
  console.log(toggleCount, 'count')

  const handleToggle = (section) => {
    switch (section) {
      case 'draft':
        setIsDraftOpen((prev) => {
          setToggleCount(prev ? toggleCount - 1 : toggleCount + 1)
          return !prev
        })
        break
      case 'submitted':
        setIsSubmittedOpen((prev) => {
          setToggleCount(prev ? toggleCount - 1 : toggleCount + 1)
          return !prev
        })
        break
      case 'rejected':
        setIsRejectedOpen((prev) => {
          setToggleCount(prev ? toggleCount - 1 : toggleCount + 1)
          return !prev
        })
        break
      case 'approved':
        setIsApprovedOpen((prev) => {
          setToggleCount(prev ? toggleCount - 1 : toggleCount + 1)
          return !prev
        })
        break
      default:
        break
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        borderTop: '2px solid #ebebeb',
      }}
    >
      {/* DRAFT */}
      <div
        style={{
          display: isDraftOpen ? 'block' : 'flex',
          padding: '8px',
          width:
            toggleCount === 1 && isDraftOpen
              ? '90%' // Wrap percentage values in quotes
              : toggleCount === 2 && isDraftOpen
                ? '50%'
                : toggleCount === 3 && isDraftOpen
                  ? '50%'
                  : toggleCount === 4 && isDraftOpen
                    ? '33%'
                    : '',
        }}
      >
        {isDraftOpen ? (
          <div
            onClick={() => handleToggle('draft')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
            }}
          >
            <img
              src={Expanded}
              alt="Expanded"
              style={{ marginRight: '10px' }}
            />{' '}
            {/* Expanded Icon */}
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              DRAFT
            </Typography>
          </div>
        ) : (
          <div
            onClick={() => handleToggle('draft')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
              borderRight: isDraftOpen ? 'none' : '2px solid #ebebeb',
              alignItems: 'center'
            }}
          >
            <img
              src={Collapsed}
              alt="Collapsed"
              style={{ marginBottom: '5px' }}
            />{' '}
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'center',
                color: '#254A9A',
              }}
            >
              D
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'center',
                color: '#254A9A',
              }}
            >
              R
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'center',
                color: '#254A9A',
              }}
            >
              A
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'center',
                color: '#254A9A',
              }}
            >
              F
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'center',
                color: '#254A9A',
              }}
            >
              T
            </Typography>
          </div>
        )}
        <Grid
          container
          style={{
            marginTop: '0px',
            display: isDraftOpen ? 'flex' : 'none',
            flexWrap: 'wrap',
          }}
        >
          {(filteredDailyTrials.length > 0
            ? filteredDailyTrials.filter(
                (item) => item.general_details[0]?.submission_status === 'draft'
              )
            : cardsDraft
          ).map((item) => {
            if (item.dataFile.template.template_id === 1) {
              const createdDate = new Date(item.created_at)
              const formattedDate = createdDate.toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })
              return (
                <Grid
                  xs={
                    toggleCount === 1
                      ? 3
                      : toggleCount === 2
                        ? 6
                        : toggleCount === 3
                          ? 12
                          : 12 // When all 4 are open
                  }
                >
                  <Card
                    key={item.id}
                    sx={{
                      border: '1px solid #E6E6E6',
                      boxShadow: '0px 5px 13px -5px #0A090B0D',
                      height: '180px',
                      marginBottom: '20px', 
                      marginRight: '5px', 
                    }}
                  >
                    <CardContent>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: '16px',
                            fontWeight: 600,
                            lineHeight: '20px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#252C32',
                            marginTop: '10px',
                          }}
                        >
                          {`Daily Trial Monitoring_${item.dataFile.customer.organization_name}`}
                        </Typography>
                        <IconButton
                          aria-label="options"
                          onClick={(event) => handleMenuOpen(event, item)}
                        >
                          <MoreVertIcon style={{ color: '#0086DC' }} />
                        </IconButton>
                      </div>

                      <Typography
                        variant="body1"
                        gutterBottom
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '14px',
                          fontWeight: 700,
                          lineHeight: '20px',
                          letterSpacing: '-0.6%',
                          textAlign: 'left',
                          marginTop: '5px',
                          color: '#252C32',
                        }}
                      >
                        {item.dataFile.customer.organization_name}
                      </Typography>

                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: 400,
                          lineHeight: '20px',
                          letterSpacing: '-0.006em',
                          textAlign: 'left',
                          color: '#6E7C87',
                        }}
                      >
                        {item.dataFile.segment.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: 500,
                          lineHeight: '16px',
                          letterSpacing: '-0.006em',
                          marginTop: '15px',
                          textAlign: 'left',
                          color: '#7F7D83',
                          width: '37px',
                          height: '16px',
                          background: '#7F7D831A', // Add background color here
                          padding: '0px 4px', // Add padding
                          borderRadius: '4px', // Add border radius
                        }}
                      >
                        Draft
                      </Typography>

                      <div
                        style={{
                          flex: 1,
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              fontFamily: 'Inter',
                              fontSize: '12px',
                              fontWeight: 500,
                              lineHeight: '20px',
                              letterSpacing: '-0.006em',
                              textAlign: 'left',
                              color: '#6E7C87',
                            }}
                          >
                            Created Date:
                          </Typography>

                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              fontFamily: 'Inter',
                              fontSize: '12px',
                              fontWeight: 700,
                              lineHeight: '20px',
                              letterSpacing: '-0.006em',
                              textAlign: 'left',
                              color: '#252C32',
                              marginLeft: '8px', // Adjust spacing between components
                            }}
                          >
                            {formattedDate}
                          </Typography>
                        </div>

                        {item.dataFile.user &&
                          item.dataFile.user.firstname &&
                          item.dataFile.user.lastname && (
                            <Tooltip
                              title={`${item.dataFile.user.firstname} ${item.dataFile.user.lastname}`}
                              arrow
                            >
                              <Avatar
                                style={{
                                  width: '30px',
                                  height: '30px',
                                  borderRadius: '12px',
                                  background: '#D7EDFF',
                                  color: '#0086DC',
                                  fontWeight: 700,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  marginLeft: 'auto', // Pushes the Avatar to the right
                                  fontSize: '10px', // Adjust font size of the Avatar content
                                }}
                              >
                                {item.dataFile.user.firstname.charAt(0)}
                                {item.dataFile.user.lastname.charAt(0)}
                              </Avatar>
                            </Tooltip>
                          )}
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              )
            }
          })}
        </Grid>
      </div>
      {/* SUBMITTED OR AWAITING APPROVAL */}
      <div
        style={{
          display: isSubmittedOpen ? 'block' : 'flex',
          padding: '8px',
          width:
            toggleCount === 1 && isSubmittedOpen
              ? '90%' // Wrap percentage values in quotes
              : toggleCount === 2 && isSubmittedOpen
                ? '50%'
                : toggleCount === 3 && isSubmittedOpen
                  ? '50%'
                  : toggleCount === 4 && isSubmittedOpen
                    ? '33%'
                    : '',
        }}
      >
        {isSubmittedOpen ? (
          <div
            onClick={() => handleToggle('submitted')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
            }}
          >
            <img
              src={Expanded}
              alt="Expanded"
              style={{ marginRight: '10px' }}
            />
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                letterSpacing: '0.006em',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              {currentUser.role.role_id === 3 || currentUser.role.role_id === 4
                ? 'AWAITING APPROVAL'
                : 'SUBMITTED'}
            </Typography>
          </div>
        ) : (
          <div
            onClick={() => handleToggle('submitted')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
              borderRight: isDraftOpen ? 'none' : '2px solid #ebebeb',
              alignItems: 'center'
            }}
          >
            <img
              src={Collapsed}
              alt="Collapsed"
              style={{ marginBottom: '5px' }}
            />{' '}
            {/* Collapsed Icon */}
            {Array.from(
              currentUser.role.role_id === 3 || currentUser.role.role_id === 4
                ? 'AWAITING APPROVAL'
                : 'SUBMITTED'
            ).map((letter, index) => (
              <Typography
                key={index}
                variant="body1"
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  letterSpacing: '0.006em',
                  textAlign: 'left',
                  color: '#254A9A',
                }}
              >
                {letter}
              </Typography>
            ))}
          </div>
        )}

        <Grid
          container
          style={{
            marginTop: '0',
            display: isSubmittedOpen ? 'flex' : 'none',
            flexWrap: 'wrap',
          }}
        >
          {(filteredDailyTrials.length > 0
            ? filteredDailyTrials.filter(
                (item) =>
                  item?.general_details[0]?.submission_status === 'submitted'
              )
            : cardsSubmitted
          ).map((item) => {
            console.log(filteredDailyTrials, 'filteredDailyTrials')
            console.log(cardsSubmitted, 'cardsSubmitted123')
            if (item.dataFile.template.template_id === 1) {
              const createdDate = new Date(item.created_at)
              const formattedDate = createdDate.toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })
              return (
                <Grid
                  xs={
                    toggleCount === 1
                      ? 3
                      : toggleCount === 2
                        ? 6
                        : toggleCount === 3
                          ? 12
                          : 12 // When all 4 are open
                  }
                >
                  <Card
                    key={item.id}
                    sx={{
                      border: '1px solid #E6E6E6',
                      boxShadow: '0px 5px 13px -5px #0A090B0D',
                      height: '180px',
                      marginBottom: '20px',
                      marginRight: '5px',
                    }}
                  >
                    <CardContent>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: '16px',
                            fontWeight: 600,
                            lineHeight: '20px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#252C32',
                            marginTop: '10px',
                          }}
                        >
                          {`Daily Trial Monitoring_${item.dataFile.customer.organization_name}`}
                        </Typography>
                        <IconButton
                          aria-label="options"
                          onClick={(event) => handleMenuOpen(event, item)}
                        >
                          <MoreVertIcon style={{ color: '#0086DC' }} />
                        </IconButton>
                      </div>

                      <Typography
                        variant="body1"
                        gutterBottom
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '14px',
                          fontWeight: 700,
                          lineHeight: '20px',
                          letterSpacing: '-0.6%',
                          textAlign: 'left',
                          marginTop: '5px',
                          color: '#252C32',
                        }}
                      >
                        {item.dataFile.customer.organization_name}
                      </Typography>

                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: 400,
                          lineHeight: '20px',
                          letterSpacing: '-0.006em',
                          textAlign: 'left',
                          color: '#6E7C87',
                        }}
                      >
                        {item.dataFile.segment.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: 500,
                          lineHeight: '16px',
                          letterSpacing: '-0.006em',
                          marginTop: '15px',
                          textAlign: 'left',
                          color: '#0086DC',
                          width:
                            currentUser.role.role_id === 3 ||
                            currentUser.role.role_id === 4
                              ? '120px'
                              : '70px',
                          height: '16px',
                          background: '#0086DC1A', // Add background color here
                          padding: '0px 4px', // Add padding
                          borderRadius: '4px', // Add border radius
                        }}
                      >
                        {currentUser.role.role_id === 3 ||
                        currentUser.role.role_id === 4
                          ? 'Awaiting approval'
                          : 'Submitted'}
                      </Typography>

                      <div
                        style={{
                          flex: 1,
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              fontFamily: 'Inter',
                              fontSize: '12px',
                              fontWeight: 500,
                              lineHeight: '20px',
                              letterSpacing: '-0.006em',
                              textAlign: 'left',
                              color: '#6E7C87',
                            }}
                          >
                            Created Date:
                          </Typography>

                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              fontFamily: 'Inter',
                              fontSize: '12px',
                              fontWeight: 700,
                              lineHeight: '20px',
                              letterSpacing: '-0.006em',
                              textAlign: 'left',
                              color: '#252C32',
                              marginLeft: '8px', // Adjust spacing between components
                            }}
                          >
                            {formattedDate}
                          </Typography>
                        </div>

                        {item.dataFile.user &&
                          item.dataFile.user.firstname &&
                          item.dataFile.user.lastname && (
                            <Tooltip
                              title={`${item.dataFile.user.firstname} ${item.dataFile.user.lastname}`}
                              arrow
                            >
                              <Avatar
                                style={{
                                  width: '30px',
                                  height: '30px',
                                  borderRadius: '12px',
                                  background: '#D7EDFF',
                                  color: '#0086DC',
                                  fontWeight: 700,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  marginLeft: 'auto', // Pushes the Avatar to the right
                                  fontSize: '10px', // Adjust font size of the Avatar content
                                }}
                              >
                                {item.dataFile.user.firstname.charAt(0)}
                                {item.dataFile.user.lastname.charAt(0)}
                              </Avatar>
                            </Tooltip>
                          )}
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              )
            }
          })}
        </Grid>
      </div>
      {/* APPROVED */}
      <div
        style={{
          display: isApprovedOpen ? 'block' : 'flex',
          padding: '8px',
          width:
            toggleCount === 1 && isApprovedOpen
              ? '90%'
              : toggleCount === 2 && isApprovedOpen
                ? '50%'
                : toggleCount === 3 && isApprovedOpen
                  ? '50%'
                  : toggleCount === 4 && isApprovedOpen
                    ? '33%'
                    : '',
        }}
      >
        {isApprovedOpen ? (
          <div
            onClick={() => handleToggle('approved')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
            }}
          >
            <img
              src={Expanded}
              alt="Expanded"
              style={{ marginRight: '10px' }}
            />
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                letterSpacing: '0.006em',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              APPROVED
            </Typography>
          </div>
        ) : (
          <div
            onClick={() => handleToggle('approved')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
              borderRight: isApprovedOpen ? 'none' : '2px solid #ebebeb',
              alignItems: 'center'
            }}
          >
            <img
              src={Collapsed}
              alt="Collapsed"
              style={{ marginBottom: '5px' }}
            />{' '}
            {/* Collapsed Icon */}
            {Array.from('APPROVED').map((letter, index) => (
              <Typography
                key={index}
                variant="body1"
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  letterSpacing: '0.006em',
                  textAlign: 'left',
                  color: '#254A9A',
                }}
              >
                {letter}
              </Typography>
            ))}
          </div>
        )}

        <Grid
          container
          style={{
            marginTop: '0',
            display: isApprovedOpen ? 'flex' : 'none',
            flexWrap: 'wrap',
          }}
        >
          {(filteredDailyTrials.length > 0
            ? filteredDailyTrials.filter(
                (item) =>
                  item?.general_details[0]?.submission_status === 'approved'
              )
            : cardsApproved
          ).map((item) => {
            if (item.dataFile.template.template_id === 1) {
              const createdDate = new Date(item.created_at)
              const formattedDate = createdDate.toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })
              return (
                <Grid
                  xs={
                    toggleCount === 1
                      ? 3
                      : toggleCount === 2
                        ? 6
                        : toggleCount === 3
                          ? 12
                          : 12 // When all 4 are open
                  }
                >
                  <Card
                    key={item.id}
                    sx={{
                      border: '1px solid #E6E6E6',
                      boxShadow: '0px 5px 13px -5px #0A090B0D',
                      width: '90%',
                      height: '180px',
                      marginBottom: '20px',
                      marginRight: '8px',
                    }}
                  >
                    <CardContent>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: '16px',
                            fontWeight: 600,
                            lineHeight: '20px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#252C32',
                            marginTop: '10px',
                          }}
                        >
                          {`Daily Trial Monitoring_${item.dataFile.customer.organization_name}`}
                        </Typography>
                        <IconButton
                          aria-label="options"
                          onClick={(event) => handleMenuOpen(event, item)}
                        >
                          <MoreVertIcon style={{ color: '#0086DC' }} />
                        </IconButton>
                      </div>

                      <Typography
                        variant="body1"
                        gutterBottom
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '14px',
                          fontWeight: 700,
                          lineHeight: '20px',
                          letterSpacing: '-0.6%',
                          textAlign: 'left',
                          marginTop: '5px',
                          color: '#252C32',
                        }}
                      >
                        {item.dataFile.customer.organization_name}
                      </Typography>

                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: 400,
                          lineHeight: '20px',
                          letterSpacing: '-0.006em',
                          textAlign: 'left',
                          color: '#6E7C87',
                        }}
                      >
                        {item.dataFile.segment.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: 500,
                          lineHeight: '16px',
                          letterSpacing: '-0.006em',
                          marginTop: '15px',
                          textAlign: 'left',
                          color: '#34A853',
                          width: '68px',
                          height: '16px',
                          background: '#34A8531A',
                          padding: '0px 4px', // Add padding
                          borderRadius: '4px', // Add border radius
                        }}
                      >
                        Approved
                      </Typography>

                      <div
                        style={{
                          flex: 1,
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              fontFamily: 'Inter',
                              fontSize: '12px',
                              fontWeight: 500,
                              lineHeight: '20px',
                              letterSpacing: '-0.006em',
                              textAlign: 'left',
                              color: '#6E7C87',
                            }}
                          >
                            Created Date:
                          </Typography>

                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              fontFamily: 'Inter',
                              fontSize: '12px',
                              fontWeight: 700,
                              lineHeight: '20px',
                              letterSpacing: '-0.006em',
                              textAlign: 'left',
                              color: '#252C32',
                              marginLeft: '8px', // Adjust spacing between components
                            }}
                          >
                            {formattedDate}
                          </Typography>
                        </div>

                        {item.dataFile.user &&
                          item.dataFile.user.firstname &&
                          item.dataFile.user.lastname && (
                            <Tooltip
                              title={`${item.dataFile.user.firstname} ${item.dataFile.user.lastname}`}
                              arrow
                            >
                              <Avatar
                                style={{
                                  width: '30px',
                                  height: '30px',
                                  borderRadius: '12px',
                                  background: '#D7EDFF',
                                  color: '#0086DC',
                                  fontWeight: 700,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  marginLeft: 'auto', // Pushes the Avatar to the right
                                  fontSize: '10px', // Adjust font size of the Avatar content
                                }}
                              >
                                {item.dataFile.user.firstname.charAt(0)}
                                {item.dataFile.user.lastname.charAt(0)}
                              </Avatar>
                            </Tooltip>
                          )}
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              )
            }
          })}
        </Grid>

        {isPopupOpen && (
          <div
            ref={popupRef}
            style={{
              position: 'absolute',
              top: `${popupPosition.top}px`,
              right: `${popupPosition.right}px`,
              background: 'rgba(255, 255, 255, 1)',
              padding: '20px',
              borderRadius: '8px',
              border: '1px solid #E8E8E8',
              boxShadow: '0px 0px 35px 0px rgba(0, 0, 0, 0.1)',
              zIndex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <div
              onClick={() => handleView(file)}
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                padding: '10px',
                padding: '10px',
              }}
            >
              <img
                src={ViewIcon}
                alt="Edit Icon"
                style={{ marginRight: '10px' }}
              />
              <Typography
                style={{
                  color: '#252C32',
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 800,
                  lineHeight: 'normal',
                  letterSpacing: '-0.084px',
                }}
              >
                View Trial
              </Typography>
            </div>
            {/* {filteredDailyTrials.length > 0 && filteredDailyTrials.includes(item => item?.general_details[0]?.submission_status === 'approved') ||cardsApproved.includes(item => item?.general_details[0]?.submission_status === 'approved') && ( */}
            <div
              onClick={handleDownload}
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                padding: '10px',
              }}
            >
              <img
                src={DownloadIcon}
                alt="Edit Icon"
                style={{ marginRight: '10px' }}
              />
              <Typography
                style={{
                  color: '#252C32',
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 800,
                  lineHeight: 'normal',
                  letterSpacing: '-0.084px',
                }}
              >
                Download
              </Typography>
            </div>
          </div>
        )}
      </div>
      {/* REJECTED */}
      <div
        style={{
          display: isRejectedOpen ? 'block' : 'flex',
          padding: '8px',
          width:
            toggleCount === 1 && isRejectedOpen
              ? '90%' // Wrap percentage values in quotes
              : toggleCount === 2 && isRejectedOpen
                ? '50%'
                : toggleCount === 3 && isRejectedOpen
                  ? '50%'
                  : toggleCount === 4 && isRejectedOpen
                    ? '33%'
                    : '',
        }}
      >
        {isRejectedOpen ? (
          <div
            onClick={() => handleToggle('rejected')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
            }}
          >
            <img
              src={Expanded}
              alt="Expanded"
              style={{ marginRight: '10px' }}
            />
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                letterSpacing: '0.006em',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              REJECTED
            </Typography>
          </div>
        ) : (
          <div
            onClick={() => handleToggle('rejected')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
              borderRight: isDraftOpen ? 'none' : '2px solid #ebebeb',
              alignItems: 'center'
            }}
          >
            <img
              src={Collapsed}
              alt="Collapsed"
              style={{ marginBottom: '5px' }}
            />{' '}
            {/* Collapsed Icon */}
            {Array.from('REJECTED').map((letter, index) => (
              <Typography
                key={index}
                variant="body1"
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  letterSpacing: '0.006em',
                  textAlign: 'left',
                  color: '#254A9A',
                }}
              >
                {letter}
              </Typography>
            ))}
          </div>
        )}

        <Grid
          container
          style={{
            marginTop: '0',
            display: isRejectedOpen ? 'flex' : 'none',
            flexWrap: 'wrap',
          }}
        >
          {(filteredDailyTrials.length > 0
            ? filteredDailyTrials.filter(
                (item) =>
                  item?.general_details[0]?.submission_status === 'rejected'
              )
            : cardsRejected
          ).map((item) => {
            if (item.dataFile.template.template_id === 1) {
              const createdDate = new Date(item.created_at)
              const formattedDate = createdDate.toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })
              return (
                <Grid
                  xs={
                    toggleCount === 1
                      ? 3
                      : toggleCount === 2
                        ? 6
                        : toggleCount === 3
                          ? 9
                          : 9 // When all 4 are open
                  }
                >
                  <Card
                    key={item.id}
                    sx={{
                      border: '1px solid #E6E6E6',
                      boxShadow: '0px 5px 13px -5px #0A090B0D',
                      height: '180px',
                      marginBottom: '20px',
                      marginRight: '8px',
                    }}
                  >
                    <CardContent>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: '16px',
                            fontWeight: 600,
                            lineHeight: '20px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#252C32',
                            marginTop: '10px',
                          }}
                        >
                          {`Daily Trial Monitoring_${item.dataFile.customer.organization_name}`}
                        </Typography>
                        <IconButton
                          aria-label="options"
                          onClick={(event) => handleMenuOpen(event, item)}
                        >
                          <MoreVertIcon style={{ color: '#0086DC' }} />
                        </IconButton>
                      </div>

                      <Typography
                        variant="body1"
                        gutterBottom
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '14px',
                          fontWeight: 700,
                          lineHeight: '20px',
                          letterSpacing: '-0.6%',
                          textAlign: 'left',
                          marginTop: '5px',
                          color: '#252C32',
                        }}
                      >
                        {item.dataFile.customer.organization_name}
                      </Typography>

                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: 400,
                          lineHeight: '20px',
                          letterSpacing: '-0.006em',
                          textAlign: 'left',
                          color: '#6E7C87',
                        }}
                      >
                        {item.dataFile.segment.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: 500,
                          lineHeight: '16px',
                          letterSpacing: '-0.006em',
                          marginTop: '15px',
                          textAlign: 'left',
                          color: '#EA4335',
                          width: '68px',
                          height: '16px',
                          background: '#EA43351A',
                          padding: '0px 4px', // Add padding
                          borderRadius: '4px', // Add border radius
                        }}
                      >
                        Rejected
                      </Typography>
                      <div
                        style={{
                          flex: 1,
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              fontFamily: 'Inter',
                              fontSize: '12px',
                              fontWeight: 500,
                              lineHeight: '20px',
                              letterSpacing: '-0.006em',
                              textAlign: 'left',
                              color: '#6E7C87',
                            }}
                          >
                            Created Date:
                          </Typography>

                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              fontFamily: 'Inter',
                              fontSize: '12px',
                              fontWeight: 700,
                              lineHeight: '20px',
                              letterSpacing: '-0.006em',
                              textAlign: 'left',
                              color: '#252C32',
                              marginLeft: '8px', // Adjust spacing between components
                            }}
                          >
                            {formattedDate}
                          </Typography>
                        </div>

                        {item.dataFile.user &&
                          item.dataFile.user.firstname &&
                          item.dataFile.user.lastname && (
                            <Tooltip
                              title={`${item.dataFile.user.firstname} ${item.dataFile.user.lastname}`}
                              arrow
                            >
                              <Avatar
                                style={{
                                  width: '30px',
                                  height: '30px',
                                  borderRadius: '12px',
                                  background: '#D7EDFF',
                                  color: '#0086DC',
                                  fontWeight: 700,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  marginLeft: 'auto', // Pushes the Avatar to the right
                                  fontSize: '10px', // Adjust font size of the Avatar content
                                }}
                              >
                                {item.dataFile.user.firstname.charAt(0)}
                                {item.dataFile.user.lastname.charAt(0)}
                              </Avatar>
                            </Tooltip>
                          )}
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              )
            }
          })}
        </Grid>
      </div>
    </div>
  )
}
export default DailyTrialMonitoring
