import React, { useState, useRef, useEffect } from 'react'
import { Card, CardContent, Typography, Avatar, Grid } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton } from '@mui/material'
import ViewIcon from '../../assets/icons/ic_view.png'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import API from '../services/API'
import { general_details } from '../../recoil/atoms'
import Tooltip from '@mui/material/Tooltip'
import * as XLSX from 'xlsx'
import DownloadIcon from '../../assets/icons/download_icon.svg'
import Collapsed from '../../assets/icons/collapsed.svg'
import Expanded from '../../assets/icons/expanded.svg'
import ExcelJS from 'exceljs'
const ErosionProfileWeb = ({
  selectedTab,
  cardsSubmitted,
  cardsDraft,
  approvedData,
  rejectData,
  file,
  selectedTemplate,
  SegmentList,
  filteredErosionProfile,
}) => {
  console.log(filteredErosionProfile, 'filteredErosionProfile')
  console.log(rejectData, 'rejectData')
  console.log(approvedData, 'approvedData')
  const navigate = useNavigate()
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [popupPosition, setPopupPosition] = useState({ top: 0, right: 0 })
  const [dataFileId, setDataFileId] = useState(null)
  const popupRef = useRef(null)
  const currentUser = JSON.parse(localStorage.getItem('user'))
  const access_token = localStorage.getItem('accesstoken')

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      closePopup()
    }
  }

  const closePopup = () => {
    setIsPopupOpen(false)
    console.log('Popup closed')
  }

  useEffect(() => {
    if (isPopupOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isPopupOpen])

  // const handleDelete = async () => {
  //   console.log("Button clicked!");
  //   console.log("cardId", dataFileId);

  //   try {
  //     // Make the API call here
  //     const url = `${API.DELETE_CARD}/${dataFileId}`;
  //     console.log("url", url);
  //     console.log("API.DELETE_CARD", API.DELETE_CARD);
  //     const response = await axios.delete(url);
  //     const data = response.data;

  //     // Do something with the response data
  //     console.log("DELETE_CARD", data);
  //     setIsPopupOpen(false);
  //     window.location.reload();
  //   } catch (error) {
  //     console.error("Error:", error);
  //     // Optionally, you can handle the error state here
  //   }
  // };

  const handleView = async () => {
    localStorage.setItem('datafile_id', dataFileId)
    navigate('/viewerosionprofile')
  }

  const handleMenuOpen = (event, item) => {
    const rect = event.target.getBoundingClientRect()
    setIsPopupOpen(true)
    setPopupPosition({
      top: rect.top + window.scrollY,
      right: window.innerWidth - rect.right,
    })
    console.log('itemdetails', item)
    localStorage.setItem('datafile_info', JSON.stringify(item))
    localStorage.setItem('erosionProfileID', item.id)
    setDataFileId(item.id)
  }
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
    return buf
  }

  const handleDownload = async () => {
    const id = localStorage.getItem('erosionProfileID')
    console.log(id, 'erosion id')
    try {
      // Fetch data from the endpoint
      const response = await axios.get(
        `${API.GET_EROSION_PROFILE_BY_ID}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      const data = response.data
      console.log(data, 'datadata12')

      const responseTemplate = await axios.get(API.GET_EROSION_EXCEL_DOWNLOAD, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })

      console.log(responseTemplate, 'responseTemplate')

      // Decode base64 to ArrayBuffer
      const base64Data = responseTemplate.data.downloadedTemplates[0].data
      const byteCharacters = atob(base64Data)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const arrayBuffer = new Uint8Array(byteNumbers).buffer

      // Create a new workbook and load the template
      const workbook = new ExcelJS.Workbook()
      await workbook.xlsx.load(arrayBuffer)
      console.log(workbook, 'workbook1')
      // Get the worksheet
      const worksheet = workbook.worksheets[0]

      // Get the general_details object from the provided JSON
      // const generalDetails = data.general_details;
      // const productDetails = data.product_details;
      // const furanceDetails = data.furnace_details;
      // const mildSteelDetails = data.mild_steel_customers_only;
      // const rawMaterialDetails = data.raw_material_energy_details;
      // const furnaceDimension = data.furnace_dimension;
      // const liningDetails = data.lining_details;
      // const alloyDetails = data.alloy_stainless_steel_customers;

      //general details
      // const contactPerson = generalDetails.contact_person || '';
      // const customerName = generalDetails.customer_name || '';
      // const date = generalDetails.date || '';
      // const mobileNumber = generalDetails.mobile_number || '';

      //   const style = {
      //     fill: {
      //         patternType: "solid", // Add this line
      //         fgColor: { rgb: "FFFF00" } // Yellow background
      //     }
      // };

      // worksheet['B1'] = { t: 's', v: customerName, s: style };
      // worksheet['B2'] = { t: 's', v: contactPerson, s: style };
      // worksheet['E1'] = { t: 's', v: date, s: style };
      // worksheet['E2'] = { t: 's', v: mobileNumber, s: style }

      // product details
      // const endProduct = productDetails.end_product || '';
      // const grades = productDetails.steel_produce || '';
      // const ratio = productDetails.ratio || '';

      // worksheet['B4'] = { t: 's', v: endProduct };
      // worksheet['B5'] = { t: 's', v: grades };
      // worksheet['B6'] = { t: 's', v: ratio };

      // furanceDetails
      // const lrf = furanceDetails.lrf || '';
      // const tap_time = furanceDetails.tap_time || '';
      // const operation = furanceDetails.operation || '';
      // const coil_grout = furanceDetails.coil_grout || '';
      // const fce_running = furanceDetails.fce_running || '';
      // const furnace_oem = furanceDetails.furnace_oem || '';
      // const avg_heatsize = furanceDetails.avg_heatsize || '';
      // const fce_capacity = furanceDetails.fce_capacity || '';
      // const furnace_year = furanceDetails.furnace_year || '';
      // const no_of_panels = furanceDetails.no_of_panels || '';
      // const power_rating = furanceDetails.power_rating || '';
      // const checking_time = furanceDetails.checking_time || '';
      // const sintering_time = furanceDetails.sintering_time || '';
      // const change_overtime = furanceDetails.change_overtime || '';
      // const frequency_range = furanceDetails.frequency_range || '';
      // const furnace_voltage = furanceDetails.furnace_voltage || '';
      // const sintering_panel = furanceDetails.sintering_panel || '';
      // const avg_tapping_temp = furanceDetails.avg_tapping_temp || '';
      // const max_tapping_temp = furanceDetails.max_tapping_temp || '';
      // const no_of_heats_perday = furanceDetails.no_of_heats_perday || '';
      // const avg_power_consumption = furanceDetails.avg_power_consumption || '';
      // const power_rating_sintering = furanceDetails.power_rating_sintering || '';

      // const columnData = [no_of_panels, fce_capacity,avg_heatsize,power_rating,fce_running,tap_time,sintering_panel,power_rating_sintering,no_of_heats_perday,avg_power_consumption,lrf,frequency_range,max_tapping_temp,avg_tapping_temp,furnace_voltage,furnace_oem,furnace_year,coil_grout,sintering_time,operation,change_overtime,checking_time]

      // for (let i = 7; i < 29; i++) {
      //     worksheet['B' + (i + 1)] = { t: 's', v: '' };
      // }
      // const rowData = columnData.map(data => [data]);
      // try {
      //   XLSX.utils.sheet_add_aoa(worksheet, rowData, { origin: 'B8' });
      // } catch (error) {
      //   console.error("Error adding new data:", error);
      // }

      //mild Steel customer only
      //     const mildSteelColumnData = [
      //       mildSteelDetails.deslagging_by,
      //       mildSteelDetails.using_magnet_or_not,
      //       mildSteelDetails.avg_c_percent_at_50,
      //       mildSteelDetails.avg_c_percent_at_80,
      //       mildSteelDetails.final_c_percent_beforeTapping,
      //       mildSteelDetails.data_mill_scale,
      //       mildSteelDetails.tilt_count_for_deslagging,
      //       mildSteelDetails.are_they_taking_liquid_metal,
      //       mildSteelDetails.dri_quality,
      //       mildSteelDetails.slag_generation_per_heat,
      //       mildSteelDetails.power_utilised,
      //       mildSteelDetails.silica_price,
      //       mildSteelDetails.ccm,
      //       mildSteelDetails.casting_time,
      //       mildSteelDetails.tundish_cap,
      //       mildSteelDetails.min_level_of_tundish,
      //       mildSteelDetails.billet_size,
      //       mildSteelDetails.no_of_sequence,
      //       mildSteelDetails.ccm_make,
      //       mildSteelDetails.ccm_preparation_time,
      //       mildSteelDetails.purpose_of_using_nrm
      //   ];
      //   for (let i = 30; i < 51; i++) {
      //     worksheet['B' + (i + 1)] = { t: 's', v: '' }; // Clearing existing data
      // }
      //   const mildSteelRowData = mildSteelColumnData.map(data => [data]);
      // try {
      //     XLSX.utils.sheet_add_aoa(worksheet, mildSteelRowData, { origin: 'B31' });
      // } catch (error) {
      //     console.error("Error adding new data:", error)

      // }

      // const rawMaterialColumnData = [
      // rawMaterialDetails.type_of_scrap,
      // rawMaterialDetails.charge_mix_ratio,
      // rawMaterialDetails.yield_of_charge_mix,
      // rawMaterialDetails.profit,
      // rawMaterialDetails.unit_price_of_power,
      // ]

      // for (let i = 3; i < 8; i++) {
      //   worksheet['E' + (i + 1)] = { t: 's', v: '' }; // Clearing existing data
      // }
      // const rawMaterialRowData = rawMaterialColumnData.map(data => [data]);
      // try {
      //   XLSX.utils.sheet_add_aoa(worksheet, rawMaterialRowData, { origin: 'E4' });
      // } catch (error) {
      //   console.error("Error adding new data:", error)

      // }

      // const furnaceDimensionData = [
      //   furnaceDimension.inner_dia_of_coil,
      //   furnaceDimension.inner_dia_after_coilcoat,
      //   furnaceDimension.former_outer_dia,
      //   furnaceDimension.furnace_height,
      //   furnaceDimension.bottom_taper_dia,
      //   furnaceDimension.bottom_outer_dia,
      //   furnaceDimension.former_ht,
      //   furnaceDimension.bottom_lining_ht,
      //   furnaceDimension.total_power_given_to_ms,
      // ]

      // for (let i = 21; i < 29; i++) {
      //   worksheet['E' + (i + 1)] = { t: 's', v: '' }; // Clearing existing data
      // }
      // const furnaceDimensionRowData = furnaceDimensionData.map(data => [data]);
      // try {
      //   XLSX.utils.sheet_add_aoa(worksheet, furnaceDimensionRowData, { origin: 'E21' });
      // } catch (error) {
      //   console.error("Error adding new data:", error)
      // }

      // const liningDetailsData = [
      //   liningDetails.consumption_for_new_lining,
      //   liningDetails.consumption_for_patching,
      //   liningDetails.new_lining_life,
      //   liningDetails.patching_lining_life,
      //   liningDetails.total_no_of_side_lining,
      //   liningDetails.present_lining_method,
      // ]

      // for (let i = 31; i < 36; i++) {
      //   worksheet['E' + (i + 1)] = { t: 's', v: '' }; // Clearing existing data
      // }
      // const liningDetailsRowData = liningDetailsData.map(data => [data]);
      // try {
      //   XLSX.utils.sheet_add_aoa(worksheet, liningDetailsRowData, { origin: 'E31' });
      // } catch (error) {
      //   console.error("Error adding new data:", error)
      // }

      // const alloyDetailsDataArray = [
      //   alloyDetails.fecr_consumption,
      //   alloyDetails.cr_percentage,
      //   alloyDetails.final_cr_percentage,
      //   alloyDetails.cr_recovery,
      //   alloyDetails.alloy_addition,
      //   alloyDetails.casting_method,
      //   alloyDetails.simn_consumption,
      //   alloyDetails.mn_percentage,
      //   alloyDetails.mn_recovery,
      //   alloyDetails.addition_of_simn,
      //   alloyDetails.any_other_alloy_addition,
      //   alloyDetails.final_chemistry_at_furnace,
      //   alloyDetails.final_chemistry_at_billet,
      //   alloyDetails.agreement,
      // ]

      // for (let i = 38; i < 51; i++) {
      //   worksheet['E' + (i + 1)] = { t: 's', v: '' }; // Clearing existing data
      // }
      // const alloyDetailsData = alloyDetailsDataArray.map(data => [data]);
      // try {
      //   XLSX.utils.sheet_add_aoa(worksheet, alloyDetailsData, { origin: 'E38' });
      // } catch (error) {
      //   console.error("Error adding new data:", error)
      // }

      const newWorkbookBlob = new Blob(
        [s2ab(XLSX.write(workbook, { type: 'binary' }))],
        { type: 'application/octet-stream' }
      )

      // Create a URL for the blob data
      const url = window.URL.createObjectURL(newWorkbookBlob)

      // Create a link element
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Erosion Profile.xlsx') // Change extension to .xlsx

      // Append the link to the document body and click it
      document.body.appendChild(link)
      link.click()

      // Clean up
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Error downloading template:', error.message)
    }
  }

  // const handleCardClick = async (cardId ) => {
  //     try {
  //         // Make the API call here
  //         const url= `${API.GET_CARD_DETAILS}/${cardId}`
  //         console.log("url",url)
  //         console.log("API.GET_CARD_DETAILS",API.GET_CARD_DETAILS)
  //         const response = await axios.get(url);
  //         const data = response.data;
  //         // Do something with the response data
  //         console.log('Card Details:', data);

  //     } catch (error) {
  //         console.error('Error:', error);
  //         // Optionally, you can handle the error state here
  //     }

  // };

  //   const handleApproval = async () => {
  //     console.log("cardId", dataFileId);

  //     try {
  //       // Make the API call here
  //       const url = `${API.APPROVED_CARD_UPDATE}/${dataFileId}`;
  //       console.log("url", url);
  //       console.log("API.APPROVED_CARD_UPDATE", API.APPROVED_CARD_UPDATE);
  //       const response = await axios.post(url);
  //       const data = response.data;
  //       // Do something with the response data
  //       console.log("Card Details:", data);
  //       setIsPopupOpen(false);
  //       window.location.reload();
  //     } catch (error) {
  //       console.error("Error:", error);
  //     }
  //   };

  //   const handleRejected = async () => {
  //     console.log("cardId", dataFileId);

  //     try {
  //       const url = `${API.REJECTED_CARD_UPDATE}/${dataFileId}`;
  //       console.log("url", url);
  //       console.log("API.REJECTED_CARD_UPDATE", API.REJECTED_CARD_UPDATE);
  //       const response = await axios.post(url);
  //       const data = response.data;

  //       console.log("Card Details:", data);
  //       setIsPopupOpen(false);
  //       window.location.reload();
  //     } catch (error) {
  //       console.error("Error:", error);
  //     }
  //   };

  const [isDraftOpen, setIsDraftOpen] = useState(false)
  const [isSubmittedOpen, setIsSubmittedOpen] = useState(false)
  const [isRejectedOpen, setIsRejectedOpen] = useState(false)
  const [isApprovedOpen, setIsApprovedOpen] = useState(false)

  const [toggleCount, setToggleCount] = useState(0)
  console.log(toggleCount, 'count')

  const handleToggle = (section) => {
    switch (section) {
      case 'draft':
        setIsDraftOpen((prev) => {
          setToggleCount(prev ? toggleCount - 1 : toggleCount + 1)
          return !prev
        })
        break
      case 'submitted':
        setIsSubmittedOpen((prev) => {
          setToggleCount(prev ? toggleCount - 1 : toggleCount + 1)
          return !prev
        })
        break
      case 'rejected':
        setIsRejectedOpen((prev) => {
          setToggleCount(prev ? toggleCount - 1 : toggleCount + 1)
          return !prev
        })
        break
      case 'approved':
        setIsApprovedOpen((prev) => {
          setToggleCount(prev ? toggleCount - 1 : toggleCount + 1)
          return !prev
        })
        break
      default:
        break
    }
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        borderTop: '2px solid #ebebeb',
      }}
    >
      {/* draft */}
      <div
        style={{
          display: isDraftOpen ? 'block' : 'flex',
          padding: '8px',
          width:
            toggleCount === 1 && isDraftOpen
              ? '90%' // Wrap percentage values in quotes
              : toggleCount === 2 && isDraftOpen
                ? '50%'
                : toggleCount === 3 && isDraftOpen
                  ? '50%'
                  : toggleCount === 4 && isDraftOpen
                    ? '33%'
                    : '',
        }}
      >
        {' '}
        {isDraftOpen ? (
          <div
            onClick={() => handleToggle('draft')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
            }}
          >
            <img
              src={Expanded}
              alt="Expanded"
              style={{ marginRight: '10px' }}
            />{' '}
            {/* Expanded Icon */}
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              DRAFT
            </Typography>
          </div>
        ) : (
          <div
            onClick={() => handleToggle('draft')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
              borderRight: isDraftOpen ? 'none' : '2px solid #ebebeb',
              alignItems: 'center',
            }}
          >
            <img
              src={Collapsed}
              alt="Collapsed"
              style={{ marginBottom: '5px' }}
            />{' '}
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              D
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              R
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              A
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              F
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              T
            </Typography>
          </div>
        )}
        <Grid
          container
          style={{
            marginTop: '0px',
            display: isDraftOpen ? 'flex' : 'none',
            flexWrap: 'wrap',
          }}
        >
          {(filteredErosionProfile.length > 0
            ? filteredErosionProfile.filter(
                (item) => item.submission_status === 'draft'
              )
            : cardsDraft
          ).map((item) => {
            if (item.dataFile.template.template_id === 1) {
              const createdDate = new Date(item.created_at)
              const formattedDate = createdDate.toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })
              return (
                <Grid
                  xs={
                    toggleCount === 1
                      ? 3
                      : toggleCount === 2
                        ? 6
                        : toggleCount === 3
                          ? 12
                          : 12 // When all 4 are open
                  }
                >
                  <Card
                    key={item.id}
                    sx={{
                      border: '1px solid #E6E6E6',
                      boxShadow: '0px 5px 13px -5px #0A090B0D',
                      height: '180px',
                      marginBottom: '20px',
                      marginRight: '5px',
                    }}
                  >
                    <CardContent>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: '16px',
                            fontWeight: 600,
                            lineHeight: '20px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#252C32',
                            marginTop: '10px',
                          }}
                        >
                          {`Erosion_Profile_${item.dataFile.customer.organization_name}`}
                        </Typography>
                        <IconButton
                          aria-label="options"
                          onClick={(event) => handleMenuOpen(event, item)}
                        >
                          <MoreVertIcon style={{ color: '#0086DC' }} />
                        </IconButton>
                      </div>

                      <Typography
                        variant="body1"
                        gutterBottom
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '14px',
                          fontWeight: 700,
                          lineHeight: '20px',
                          letterSpacing: '-0.6%',
                          textAlign: 'left',
                          marginTop: '5px',
                          color: '#252C32',
                        }}
                      >
                        {item.dataFile.customer.organization_name}
                      </Typography>

                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: 400,
                          lineHeight: '20px',
                          letterSpacing: '-0.006em',
                          textAlign: 'left',
                          color: '#6E7C87',
                        }}
                      >
                        {item.dataFile.segment.name
                          ? item.dataFile.segment.name
                          : ''}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: 500,
                          lineHeight: '16px',
                          letterSpacing: '-0.006em',
                          marginTop: '15px',
                          textAlign: 'left',
                          color: '#7F7D83',
                          width: '37px',
                          height: '16px',
                          background: '#7F7D831A', // Add background color here
                          padding: '0px 4px', // Add padding
                          borderRadius: '4px', // Add border radius
                        }}
                      >
                        Draft
                      </Typography>

                      <div
                        style={{
                          flex: 1,
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              fontFamily: 'Inter',
                              fontSize: '12px',
                              fontWeight: 500,
                              lineHeight: '20px',
                              letterSpacing: '-0.006em',
                              textAlign: 'left',
                              color: '#6E7C87',
                            }}
                          >
                            Created Date:
                          </Typography>

                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              fontFamily: 'Inter',
                              fontSize: '12px',
                              fontWeight: 700,
                              lineHeight: '20px',
                              letterSpacing: '-0.006em',
                              textAlign: 'left',
                              color: '#252C32',
                              marginLeft: '8px', // Adjust spacing between components
                            }}
                          >
                            {formattedDate}
                          </Typography>
                        </div>

                        {item.user &&
                          item.user.firstname &&
                          item.user.lastname && (
                            <Avatar
                              style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '12px',
                                background: '#D7EDFF',
                                color: '#0086DC',
                                fontWeight: 700,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: 'auto', // Pushes the Avatar to the right
                                fontSize: '10px', // Adjust font size of the Avatar content
                              }}
                            >
                              {item.user.firstname.charAt(0)}
                              {item.user.lastname.charAt(0)}
                            </Avatar>
                          )}
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              )
            }
          })}
        </Grid>
      </div>

      {/* submitted or awaiting approval */}
      <div
        style={{
          display: isSubmittedOpen ? 'block' : 'flex',
          padding: '8px',
          width:
            toggleCount === 1 && isSubmittedOpen
              ? '90%' // Wrap percentage values in quotes
              : toggleCount === 2 && isSubmittedOpen
                ? '50%'
                : toggleCount === 3 && isSubmittedOpen
                  ? '50%'
                  : toggleCount === 4 && isSubmittedOpen
                    ? '33%'
                    : '',
        }}
      >
        {isSubmittedOpen ? (
          <div
            onClick={() => handleToggle('submitted')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
            }}
          >
            <img
              src={Expanded}
              alt="Expanded"
              style={{ marginRight: '10px' }}
            />
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                letterSpacing: '0.006em',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              {currentUser.role.role_id === 3 || currentUser.role.role_id === 4
                ? 'AWAITING APPROVAL'
                : 'SUBMITTED'}
            </Typography>
          </div>
        ) : (
          <div
            onClick={() => handleToggle('submitted')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
              borderRight: isDraftOpen ? 'none' : '2px solid #ebebeb',
              alignItems: 'center',
            }}
          >
            <img
              src={Collapsed}
              alt="Collapsed"
              style={{ marginBottom: '5px' }}
            />{' '}
            {/* Collapsed Icon */}
            {Array.from(
              currentUser.role.role_id === 3 || currentUser.role.role_id === 4
                ? 'AWAITING APPROVAL'
                : 'SUBMITTED'
            ).map((letter, index) => (
              <Typography
                key={index}
                variant="body1"
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  letterSpacing: '0.006em',
                  textAlign: 'left',
                  color: '#254A9A',
                }}
              >
                {letter}
              </Typography>
            ))}
          </div>
        )}

        <Grid
          container
          style={{
            marginTop: '0',
            display: isSubmittedOpen ? 'flex' : 'none',
            flexWrap: 'wrap',
          }}
        >
          {(filteredErosionProfile.length > 0
            ? filteredErosionProfile.filter(
                (item) => item?.submission_status === 'submitted'
              )
            : cardsSubmitted
          ).map((item) => {
            if (item.dataFile.template.template_id === 3) {
              const createdDate = new Date(item.created_at)
              const formattedDate = createdDate.toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })
              return (
                <Grid
                  xs={
                    toggleCount === 1
                      ? 3
                      : toggleCount === 2
                        ? 6
                        : toggleCount === 3
                          ? 12
                          : 12 // When all 4 are open
                  }
                >
                  <Card
                    key={item.id}
                    sx={{
                      border: '1px solid #E6E6E6',
                      boxShadow: '0px 5px 13px -5px #0A090B0D',
                      height: '180px',
                      marginBottom: '20px',
                      marginRight: '5px',
                    }}
                  >
                    <CardContent>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: '16px',
                            fontWeight: 600,
                            lineHeight: '20px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#252C32',
                            marginTop: '10px',
                          }}
                        >
                          {`Erosion_Profile_${item.dataFile.customer.organization_name}`}
                        </Typography>
                        {/* <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "20px",
                letterSpacing: "-0.006em",
                textAlign: "left",
                color: "#6E7C87",
              }}
            >
              {item.dataFile.segment.name ? item.dataFile.segment.name : ""}
            </Typography> */}

                        <IconButton
                          aria-label="options"
                          onClick={(event) => handleMenuOpen(event, item)}
                        >
                          <MoreVertIcon style={{ color: '#0086DC' }} />
                        </IconButton>
                      </div>

                      <Typography
                        variant="body1"
                        gutterBottom
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '14px',
                          fontWeight: 700,
                          lineHeight: '20px',
                          letterSpacing: '-0.6%',
                          textAlign: 'left',
                          marginTop: '5px',
                          color: '#252C32',
                        }}
                      >
                        {item.dataFile.customer.organization_name}
                      </Typography>

                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: 400,
                          lineHeight: '20px',
                          letterSpacing: '-0.006em',
                          textAlign: 'left',
                          color: '#6E7C87',
                        }}
                      >
                        {item?.dataFile?.segment?.name
                          ? item.dataFile.segment.name
                          : ''}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: 500,
                          lineHeight: '16px',
                          letterSpacing: '-0.006em',
                          marginTop: '15px',
                          textAlign: 'left',
                          color: '#0086DC',
                          width: '120px',
                          height: '16px',
                          background: '#0086DC1A', // Add background color here
                          padding: '0px 4px', // Add padding
                          borderRadius: '4px', // Add border radius
                        }}
                      >
                        {currentUser.role.role_id === 3 ||
                        currentUser.role.role_id === 4
                          ? 'Awaiting approval'
                          : 'Submitted'}
                      </Typography>

                      <div
                        style={{
                          flex: 1,
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              fontFamily: 'Inter',
                              fontSize: '12px',
                              fontWeight: 500,
                              lineHeight: '20px',
                              letterSpacing: '-0.006em',
                              textAlign: 'left',
                              color: '#6E7C87',
                            }}
                          >
                            Created Date:
                          </Typography>

                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              fontFamily: 'Inter',
                              fontSize: '12px',
                              fontWeight: 700,
                              lineHeight: '20px',
                              letterSpacing: '-0.006em',
                              textAlign: 'left',
                              color: '#252C32',
                              marginLeft: '8px', // Adjust spacing between components
                            }}
                          >
                            {formattedDate}
                          </Typography>
                        </div>

                        {item.dataFile.user &&
                          item.dataFile.user.firstname &&
                          item.dataFile.user.lastname && (
                            <Tooltip
                              title={`${item.dataFile.user.firstname} ${item.dataFile.user.lastname}`}
                              arrow
                            >
                              <Avatar
                                style={{
                                  width: '30px',
                                  height: '30px',
                                  borderRadius: '12px',
                                  background: '#D7EDFF',
                                  color: '#0086DC',
                                  fontWeight: 700,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  marginLeft: 'auto', // Pushes the Avatar to the right
                                  fontSize: '10px', // Adjust font size of the Avatar content
                                }}
                              >
                                {item.dataFile.user.firstname.charAt(0)}
                                {item.dataFile.user.lastname.charAt(0)}
                              </Avatar>
                            </Tooltip>
                          )}
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              )
            }
          })}
        </Grid>
      </div>
      {/* approved */}
      <div
        style={{
          display: isApprovedOpen ? 'block' : 'flex',
          padding: '8px',
          width:
            toggleCount === 1 && isApprovedOpen
              ? '90%'
              : toggleCount === 2 && isApprovedOpen
                ? '50%'
                : toggleCount === 3 && isApprovedOpen
                  ? '50%'
                  : toggleCount === 4 && isApprovedOpen
                    ? '33%'
                    : '',
        }}
      >
        {isApprovedOpen ? (
          <div
            onClick={() => handleToggle('approved')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
            }}
          >
            <img
              src={Expanded}
              alt="Expanded"
              style={{ marginRight: '10px' }}
            />
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                letterSpacing: '0.006em',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              APPROVED
            </Typography>
          </div>
        ) : (
          <div
            onClick={() => handleToggle('approved')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
              borderRight: isApprovedOpen ? 'none' : '2px solid #ebebeb',
              alignItems: 'center',
            }}
          >
            <img
              src={Collapsed}
              alt="Collapsed"
              style={{ marginBottom: '5px' }}
            />{' '}
            {/* Collapsed Icon */}
            {Array.from('APPROVED').map((letter, index) => (
              <Typography
                key={index}
                variant="body1"
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  letterSpacing: '0.006em',
                  textAlign: 'left',
                  color: '#254A9A',
                }}
              >
                {letter}
              </Typography>
            ))}
          </div>
        )}
        <Grid
          container
          style={{
            marginTop: '0',
            display: isApprovedOpen ? 'flex' : 'none',
            flexWrap: 'wrap',
          }}
        >
          {(filteredErosionProfile.length > 0
            ? filteredErosionProfile.filter(
                (item) => item.submission_status === 'approved'
              )
            : approvedData
          ).map((item) => {
            if (item.dataFile.template.template_id === 3) {
              const createdDate = new Date(item.created_at)
              const formattedDate = createdDate.toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })
              return (
                <Grid
                  xs={
                    toggleCount === 1
                      ? 3
                      : toggleCount === 2
                        ? 6
                        : toggleCount === 3
                          ? 12
                          : 12 // When all 4 are open
                  }
                >
                  <Card
                    key={item.id}
                    sx={{
                      border: '1px solid #E6E6E6',
                      boxShadow: '0px 5px 13px -5px #0A090B0D',
                      height: '180px',
                      marginBottom: '20px',
                      marginRight: '5px',
                    }}
                  >
                    <CardContent>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: '16px',
                            fontWeight: 600,
                            lineHeight: '20px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#252C32',
                            marginTop: '10px',
                          }}
                        >
                          {`Erosion_Profile_${item.dataFile.customer.organization_name}`}
                        </Typography>
                        {/* <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "20px",
                letterSpacing: "-0.006em",
                textAlign: "left",
                color: "#6E7C87",
              }}
            >
              {item.dataFile.segment.name ? item.dataFile.segment.name : ""}
            </Typography> */}
                        <IconButton
                          aria-label="options"
                          onClick={(event) => handleMenuOpen(event, item)}
                        >
                          <MoreVertIcon style={{ color: '#0086DC' }} />
                        </IconButton>
                      </div>

                      <Typography
                        variant="body1"
                        gutterBottom
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '14px',
                          fontWeight: 700,
                          lineHeight: '20px',
                          letterSpacing: '-0.6%',
                          textAlign: 'left',
                          marginTop: '5px',
                          color: '#252C32',
                        }}
                      >
                        {item.dataFile.customer.organization_name}
                      </Typography>

                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: 400,
                          lineHeight: '20px',
                          letterSpacing: '-0.006em',
                          textAlign: 'left',
                          color: '#6E7C87',
                        }}
                      >
                        {item?.dataFile?.segment?.name
                          ? item.dataFile.segment.name
                          : ''}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: 500,
                          lineHeight: '16px',
                          letterSpacing: '-0.006em',
                          marginTop: '15px',
                          textAlign: 'left',
                          color: '#34A853',
                          width: '68px',
                          height: '16px',
                          background: '#34A8531A',
                          padding: '0px 4px', // Add padding
                          borderRadius: '4px', // Add border radius
                        }}
                      >
                        Approved
                      </Typography>

                      <div
                        style={{
                          flex: 1,
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              fontFamily: 'Inter',
                              fontSize: '12px',
                              fontWeight: 500,
                              lineHeight: '20px',
                              letterSpacing: '-0.006em',
                              textAlign: 'left',
                              color: '#6E7C87',
                            }}
                          >
                            Created Date:
                          </Typography>

                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              fontFamily: 'Inter',
                              fontSize: '12px',
                              fontWeight: 700,
                              lineHeight: '20px',
                              letterSpacing: '-0.006em',
                              textAlign: 'left',
                              color: '#252C32',
                              marginLeft: '8px', // Adjust spacing between components
                            }}
                          >
                            {formattedDate}
                          </Typography>
                        </div>

                        {item.dataFile.user &&
                          item.dataFile.user.firstname &&
                          item.dataFile.user.lastname && (
                            <Tooltip
                              title={`${item.dataFile.user.firstname} ${item.dataFile.user.lastname}`}
                              arrow
                            >
                              <Avatar
                                style={{
                                  width: '30px',
                                  height: '30px',
                                  borderRadius: '12px',
                                  background: '#D7EDFF',
                                  color: '#0086DC',
                                  fontWeight: 700,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  marginLeft: 'auto', // Pushes the Avatar to the right
                                  fontSize: '10px', // Adjust font size of the Avatar content
                                }}
                              >
                                {item.dataFile.user.firstname.charAt(0)}
                                {item.dataFile.user.lastname.charAt(0)}
                              </Avatar>
                            </Tooltip>
                          )}
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              )
            }
          })}
        </Grid>
      </div>
      {/* rejected */}
      <div
        style={{
          display: isRejectedOpen ? 'block' : 'flex',
          padding: '8px',
          width:
            toggleCount === 1 && isRejectedOpen
              ? '90%' // Wrap percentage values in quotes
              : toggleCount === 2 && isRejectedOpen
                ? '50%'
                : toggleCount === 3 && isRejectedOpen
                  ? '50%'
                  : toggleCount === 4 && isRejectedOpen
                    ? '33%'
                    : '',
        }}
      >
        {isRejectedOpen ? (
          <div
            onClick={() => handleToggle('rejected')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
            }}
          >
            <img
              src={Expanded}
              alt="Expanded"
              style={{ marginRight: '10px' }}
            />
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                letterSpacing: '0.006em',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              REJECTED
            </Typography>
          </div>
        ) : (
          <div
            onClick={() => handleToggle('rejected')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
              borderRight: isDraftOpen ? 'none' : '2px solid #ebebeb',
              alignItems: 'center',
            }}
          >
            <img
              src={Collapsed}
              alt="Collapsed"
              style={{ marginBottom: '5px' }}
            />{' '}
            {/* Collapsed Icon */}
            {Array.from('REJECTED').map((letter, index) => (
              <Typography
                key={index}
                variant="body1"
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  letterSpacing: '0.006em',
                  textAlign: 'left',
                  color: '#254A9A',
                }}
              >
                {letter}
              </Typography>
            ))}
          </div>
        )}
        <Grid
          container
          style={{
            marginTop: '0',
            display: isRejectedOpen ? 'flex' : 'none',
            flexWrap: 'wrap',
          }}
        >
          {(filteredErosionProfile.length > 0
            ? filteredErosionProfile.filter(
                (item) => item?.submission_status === 'rejected'
              )
            : rejectData
          ).map((item) => {
            if (item.dataFile.template.template_id === 3) {
              const createdDate = new Date(item.created_at)
              const formattedDate = createdDate.toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })
              return (
                <Grid
                  xs={
                    toggleCount === 1
                      ? 3
                      : toggleCount === 2
                        ? 6
                        : toggleCount === 3
                          ? 9
                          : 9 // When all 4 are open
                  }
                >
                  <Card
                    key={item.id}
                    sx={{
                      border: '1px solid #E6E6E6',
                      boxShadow: '0px 5px 13px -5px #0A090B0D',
                      height: '180px',
                      marginBottom: '20px',
                      marginRight: '5px',
                    }}
                  >
                    <CardContent>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: '16px',
                            fontWeight: 600,
                            lineHeight: '20px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#252C32',
                            marginTop: '10px',
                          }}
                        >
                          {`Erosion_Profile_${item.dataFile.customer.organization_name}`}
                        </Typography>
                        {/* <Typography
              variant="body2"
              color="textSecondary"
              sx={{
                fontFamily: "Inter",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "20px",
                letterSpacing: "-0.006em",
                textAlign: "left",
                color: "#6E7C87",
              }}
            >
              {item.dataFile.segment.name ? item.dataFile.segment.name : ""}
            </Typography> */}
                        <IconButton
                          aria-label="options"
                          onClick={(event) => handleMenuOpen(event, item)}
                        >
                          <MoreVertIcon style={{ color: '#0086DC' }} />
                        </IconButton>
                      </div>

                      <Typography
                        variant="body1"
                        gutterBottom
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '14px',
                          fontWeight: 700,
                          lineHeight: '20px',
                          letterSpacing: '-0.6%',
                          textAlign: 'left',
                          marginTop: '5px',
                          color: '#252C32',
                        }}
                      >
                        {item.dataFile.customer.organization_name}
                      </Typography>

                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: 400,
                          lineHeight: '20px',
                          letterSpacing: '-0.006em',
                          textAlign: 'left',
                          color: '#6E7C87',
                        }}
                      >
                        {item?.dataFile?.segment?.name
                          ? item.dataFile.segment.name
                          : ''}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: 500,
                          lineHeight: '16px',
                          letterSpacing: '-0.006em',
                          marginTop: '15px',
                          textAlign: 'left',
                          color: '#EA4335',
                          width: '68px',
                          height: '16px',
                          background: '#EA43351A',
                          padding: '0px 4px', // Add padding
                          borderRadius: '4px', // Add border radius
                        }}
                      >
                        Rejected
                      </Typography>
                      <div
                        style={{
                          flex: 1,
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              fontFamily: 'Inter',
                              fontSize: '12px',
                              fontWeight: 500,
                              lineHeight: '20px',
                              letterSpacing: '-0.006em',
                              textAlign: 'left',
                              color: '#6E7C87',
                            }}
                          >
                            Created Date:
                          </Typography>

                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              fontFamily: 'Inter',
                              fontSize: '12px',
                              fontWeight: 700,
                              lineHeight: '20px',
                              letterSpacing: '-0.006em',
                              textAlign: 'left',
                              color: '#252C32',
                              marginLeft: '8px', // Adjust spacing between components
                            }}
                          >
                            {formattedDate}
                          </Typography>
                        </div>

                        {item.dataFile.user &&
                          item.dataFile.user.firstname &&
                          item.dataFile.user.lastname && (
                            <Tooltip
                              title={`${item.dataFile.user.firstname} ${item.dataFile.user.lastname}`}
                              arrow
                            >
                              <Avatar
                                style={{
                                  width: '30px',
                                  height: '30px',
                                  borderRadius: '12px',
                                  background: '#D7EDFF',
                                  color: '#0086DC',
                                  fontWeight: 700,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  marginLeft: 'auto', // Pushes the Avatar to the right
                                  fontSize: '10px', // Adjust font size of the Avatar content
                                }}
                              >
                                {item.dataFile.user.firstname.charAt(0)}
                                {item.dataFile.user.lastname.charAt(0)}
                              </Avatar>
                            </Tooltip>
                          )}
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              )
            }
          })}
        </Grid>

        {isPopupOpen && (
          <div
            ref={popupRef}
            style={{
              position: 'absolute',
              top: `${popupPosition.top}px`,
              right: `${popupPosition.right}px`,
              background: 'rgba(255, 255, 255, 1)',
              padding: '20px',
              borderRadius: '8px',
              border: '1px solid #E8E8E8',
              boxShadow: '0px 0px 35px 0px rgba(0, 0, 0, 0.1)',
              zIndex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            {/* Your popup content */}
            <div
              onClick={() => handleView(file)}
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                padding: '10px',
                padding: '10px',
              }}
            >
              <img
                src={ViewIcon}
                alt="Edit Icon"
                style={{ marginRight: '10px' }}
              />
              <Typography
                style={{
                  color: '#252C32',
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 800,
                  lineHeight: 'normal',
                  letterSpacing: '-0.084px',
                }}
              >
                View Trial
              </Typography>
            </div>
            <div
              onClick={handleDownload}
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                padding: '10px',
              }}
            >
              <img
                src={DownloadIcon}
                alt="Edit Icon"
                style={{ marginRight: '10px' }}
              />
              <Typography
                style={{
                  color: '#252C32',
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 800,
                  lineHeight: 'normal',
                  letterSpacing: '-0.084px',
                }}
              >
                Download
              </Typography>
            </div>
            {/* <div onClick={handleEdit} style={{ display: "flex", alignItems: 'center', cursor: 'pointer',padding:"10px"}}>
                            <img src={EditIcon} alt="Edit Icon" style={{ marginRight: '10px' }} />
                            <Typography
                            style={{
                                color: '#252C32',
                                fontFamily: 'Inter',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 800,
                                lineHeight: 'normal',
                                letterSpacing: '-0.084px',
                            }}>Edit Trial</Typography>
                        </div>
                        <div onClick={handleEdit} style={{ display: "flex", alignItems: 'center', cursor: 'pointer',padding:"10px"}}>
                            <img src={AddIcon} alt="Edit Icon" style={{ marginRight: '10px' }} />
                            <Typography
                            style={{
                                color: '#252C32',
                                fontFamily: 'Inter',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 800,
                                lineHeight: 'normal',
                                letterSpacing: '-0.084px',
                            }}>Add Trial</Typography>
                        </div> */}
            {/* <div
              onClick={handleApproval}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "10px",
              }}
            >
              <img
                src={ApprovalIcon}
                alt="Edit Icon"
                style={{ marginRight: "10px" }}
              />
              <Typography
                style={{
                  color: "#252C32",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 800,
                  lineHeight: "normal",
                  letterSpacing: "-0.084px",
                }}
              >
                Approval
              </Typography>
            </div>
            <div
              onClick={handleRejected}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "10px",
              }}
            >
              <img
                src={ApprovalIcon}
                alt="Edit Icon"
                style={{ marginRight: "10px" }}
              />
              <Typography
                style={{
                  color: "#252C32",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 800,
                  lineHeight: "normal",
                  letterSpacing: "-0.084px",
                }}
              >
                Rejected
              </Typography>
            </div>
            <div
              onClick={handleDelete}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "10px",
              }}
            >
              <img
                src={DeleteIcon}
                alt="Delete Icon"
                style={{ marginRight: "10px" }}
              />
              <Typography
                style={{
                  color: "#FF3347",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 800,
                  lineHeight: "normal",
                  letterSpacing: "-0.084px",
                }}
              >
                Delete
              </Typography>
            </div> */}
          </div>
        )}
      </div>
    </div>
  )
}
export default ErosionProfileWeb
