import React, { useState, useRef, useEffect } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
} from '@mui/material'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import API from '../services/API'
import AlertDialog from '../input/AlertDialog'
import StyledButtonWeb from '../input/StyledButtonWeb'
import furnaceImage from '../../assets/picture/furnace.png'
const ViewDataCollection = () => {
  const styles = {
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      marginBottom: '20px',
    },
    thTd: {
      border: '1px solid #000',
      padding: '8px',
      textAlign: 'left',
    },
    imageCell: {
      position: 'relative',
      height: '100px', // Adjust as needed
      width: '100%', // Span across columns
      background: `url(${furnaceImage}) no-repeat center center`,
      backgroundSize: 'cover',
      border: 'none', // Hide border if needed
    },
    emptyCell: {
      border: 'none',
      padding: '0',
      background: `url(${furnaceImage}) no-repeat center center`,
      backgroundSize: 'cover',
    },
    th: {
      backgroundColor: '#c9daf8',
    },
    categoryHeader: {
      backgroundColor: '#6d9eeb',
      fontWeight: 'bold',
    },
    unitHeader: {
      backgroundColor: '#93c47d',
    },
    detailsTable: {
      marginTop: '20px',
    },
    furnaceImage: {
      textAlign: 'center',
    },
    furnaceImageImg: {
      maxWidth: '100%',
    },
  }

  const navigate = useNavigate()
  const [tableData, setTableData] = useState([])
  const [headerText, setHeaderText] = useState('General Details')
  const tableRef = useRef(null)
  const id = localStorage.getItem('daily_trial_id')
  const dataFileData = localStorage.getItem('datafile_info')
  // Parse the JSON string back to an object
  const parsedData = JSON.parse(dataFileData)
  const currentUser = JSON.parse(localStorage.getItem('user'))
  const [showRejectPopup, setShowRejectPopup] = useState(false)
  const [comments, setComments] = useState('')
  const [submissionStatus, setSubmissionStatus] = useState('')
  const access_token = localStorage.getItem('accesstoken')

  console.log('parsedData', parsedData)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataFileData = localStorage.getItem('datafile_info')
        const parsedData = JSON.parse(dataFileData)
        if (parsedData.dataFile.template.template_id === 4) {
          const datafile_id = parsedData.datafile_id
          console.log(datafile_id, 'datafile_id')
          let url = `${API.VIEW_DATA_COLLECTION}/${datafile_id}`
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          })
          console.log('viewtrial', response.data)
          setTableData(response.data)
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [])

  const details = tableData.general_details
  const product_details = tableData.product_details
  const furnace_details = tableData.furnace_details
  const mild_steel_customers_only = tableData.mild_steel_customers_only
  const raw_material_energy_details = tableData.raw_material_energy_details
  const furnace_dimension = tableData.furnace_dimension
  const lining_details = tableData.lining_details
  const alloy_stainless_steel_customers =
    tableData.alloy_stainless_steel_customers

  const handleApprove = async () => {
    AlertDialog({
      type: 'delete',
      title: 'Submission',
      text: 'Are you sure you want to approve?',
      confirmButtonText: 'Yes',
      onConfirm: async () => {
        if (currentUser.role.role_id === 3 || currentUser.role.role_id === 4) {
          updateFormData('approved')
          // if (parsedData.dataFile.template.template_id === 1) {
          //   updateGeneralDetails();
          // }else{
          //   updateNRMPerformance();
          // }
        }
      },
    })
  }

  // const handleReject = async() => {

  //   AlertDialog({
  //     type: 'delete',
  //     title: 'Submission',
  //     text: 'Are you sure you want to Reject?',
  //     confirmButtonText: 'Yes',
  //     onConfirm: async () => {
  //       if (currentUser.role.role_id === 3 || currentUser.role.role_id === 4) {

  //         try {
  //           updateFormData("rejected");
  //          // Navigate after successful update
  //        } catch (error) {
  //          // Handle errors
  //          console.error('An error occurred while updating data:', error);
  //        }
  //       }
  //     }
  //   });

  // }

  const handleReject = () => {
    setShowRejectPopup(true)
  }

  const handleBack = () => {
    navigate(-1) 
  }

  const handleRejectComment = async () => {
    const commentsFromData = tableData.comments
    setComments(commentsFromData)
    setShowRejectPopup(true)
  }

  const handlePopupOk = async () => {
    // Handle OK button click logic (e.g., send comments to server)
    console.log(comments)
    setShowRejectPopup(false)

    if (currentUser.role.role_id === 3 || currentUser.role.role_id === 4) {
      // Map through parsedData and update general_details
      try {
        const updated_data = {
          id: tableData.id,
          submission_status: 'rejected',
          comments: comments || '',
          updated_data: {
            ...tableData
          },
        }

        const response = await axios.put(
          API.UPDATE_DATA_COLLECTION,
          updated_data,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        console.log('Data updated successfully:', response.data)
        if (response.status === 200) {
          AlertDialog({
            type: 'success',
            title: 'Success',
            text: 'Your Trial is Rejected',
            confirmButtonText: 'Ok',
            onConfirm: async () => {
              navigate('/datamanagement')
            },
          })
        } else {
          console.error('Error saving data:', response.data)
          AlertDialog({
            type: 'warning',
            title: 'Error',
            text: 'Failed to save data',
          })
        }
      } catch (error) {
        // Handle errors
        console.error('An error occurred while updating data:', error)
      }
    }
  }

  const updateFormData = async (submission_status) => {
    try {
      const data = {
        id: tableData.id,
        submission_status: submission_status,
        updated_data: {
          general_details: tableData.generalDetails,
          product_details: tableData.productDetails,
          furnace_details: tableData.furnaceDetails,
          mild_steel_customers_only: tableData.mildSteelCustomer,
          raw_material_energy_details: tableData.rawAndEnergyDetails,
          furnace_dimension: tableData.furnaceDimension,
          lining_details: tableData.liningDetails,
          alloy_stainless_steel_customers: tableData.alloyAndSteel,
        },
      }

      const response = await axios.put(
        API.UPDATE_DATA_COLLECTION,
        data,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      console.log('Data updated successfully:', response.data)
      if (response.status === 200) {
        AlertDialog({
          type: 'success',
          title: 'Success',
          text: 'Approved Suceessfully',
          confirmButtonText: 'Ok',
          onConfirm: async () => {
            navigate('/datamanagement')
          },
        })
      } else {
        console.error('Error saving data:', response.data)
        AlertDialog({
          type: 'warning',
          title: 'Error',
          text: 'Failed to save data',
        })
      }
    } catch (error) {
      // Handle errors
      console.error('An error occurred while updating data:', error)
    }
  }

  const TableA = () => {
    if (!tableData) {
      return <p>Loading...</p>
    }

    return (
      <div>
        {/* Product Details Table */}
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={{ ...styles.thTd }}>Name of Customer</th>
              <th style={{ ...styles.thTd }}>
                {details && details.customer_name ? details.customer_name : ''}
              </th>
              <th style={{ ...styles.thTd }}>No Unit</th>
              <th style={{ ...styles.thTd }}>Date of Data Collection</th>
              <th style={{ ...styles.thTd }}>
                {details && details.date ? details.date : ''}
              </th>
              <th style={{ ...styles.thTd }}>No Unit</th>
            </tr>
            <tr>
              <th style={{ ...styles.thTd }}>Contact Person</th>
              <th style={{ ...styles.thTd }}>
                {details && details.contact_person
                  ? details.contact_person
                  : ''}
              </th>
              <th style={{ ...styles.thTd }}>No Unit</th>
              <th style={{ ...styles.thTd }}>Mobile Number(s)</th>
              <th style={{ ...styles.thTd }}>
                {details && details.mobile_number ? details.mobile_number : ''}
              </th>
              <th style={{ ...styles.thTd }}>No Unit</th>
            </tr>
            <tr>
              <th
                colSpan="3"
                style={{ ...styles.thTd, ...styles.categoryHeader }}
              >
                Product Details
              </th>
              <th
                colSpan="3"
                style={{ ...styles.thTd, ...styles.categoryHeader }}
              >
                Raw Materials Details
              </th>
            </tr>
            <tr>
              <th style={{ ...styles.thTd, ...styles.unitHeader }}>Detail</th>
              <th style={{ ...styles.thTd, ...styles.unitHeader }}>
                Description
              </th>
              <th style={{ ...styles.thTd, ...styles.unitHeader }}>Unit</th>
              <th style={{ ...styles.thTd, ...styles.unitHeader }}>
                Material Type
              </th>
              <th style={{ ...styles.thTd, ...styles.unitHeader }}>Details</th>
              <th style={{ ...styles.thTd, ...styles.unitHeader }}>Unit</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={styles.thTd}>End Product of the Plant</td>
              <td style={styles.thTd}>
                {product_details && product_details.ratio
                  ? product_details.ratio
                  : ''}
              </td>
              <td style={styles.thTd}>No Unit</td>
              <td style={styles.thTd}>Types of Scrap</td>
              <td style={styles.thTd}>
                {raw_material_energy_details &&
                raw_material_energy_details.type_of_scrap
                  ? raw_material_energy_details.type_of_scrap
                  : ''}
              </td>
              <td style={styles.thTd}>No Unit</td>
            </tr>
            <tr>
              <td style={styles.thTd}>Grades of Steel Produce</td>
              <td style={styles.thTd}>
                {product_details && product_details.end_product
                  ? product_details.end_product
                  : ''}
              </td>
              <td style={styles.thTd}>No Unit</td>
              <td style={styles.thTd}>Charge Mix Ratio (Scrap : PI : DRI)</td>
              <td style={styles.thTd}>
                {raw_material_energy_details &&
                raw_material_energy_details.charge_mix_ratio
                  ? raw_material_energy_details.charge_mix_ratio
                  : ''}
              </td>
              <td style={styles.thTd}>Unit 2</td>
            </tr>
            <tr>
              <td style={styles.thTd}>Ratio of MS Grade</td>
              <td style={styles.thTd}>
                {product_details && product_details.steel_produce
                  ? product_details.steel_produce
                  : ''}
              </td>
              <td style={styles.thTd}>No Unit</td>
              <td style={styles.thTd}>Yield of Charge Mix</td>
              <td style={styles.thTd}>
                {raw_material_energy_details &&
                raw_material_energy_details.yield_of_charge_mix
                  ? raw_material_energy_details.yield_of_charge_mix
                  : 'Details here'}
              </td>
              <td style={styles.thTd}>%</td>
            </tr>
            <tr>
              <td
                colSpan="3"
                style={{ ...styles.thTd, ...styles.categoryHeader }}
              >
                Furnace Details
              </td>
              <td style={styles.thTd}>Profit / Kg</td>
              <td style={styles.thTd}>
                {raw_material_energy_details &&
                raw_material_energy_details.unit_price_of_power
                  ? raw_material_energy_details.unit_price_of_power
                  : ''}
              </td>
              <td style={styles.thTd}>INR/Kg</td>
            </tr>
            <tr>
              <td style={styles.thTd}>Total No. of Panel</td>
              <td style={styles.thTd}>
                {furnace_details && furnace_details.no_of_panels
                  ? furnace_details.no_of_panels
                  : ''}
              </td>
              <td style={styles.thTd}>No.</td>
              <td style={styles.thTd}>Unit Price of Power</td>
              <td style={styles.thTd}>
                {raw_material_energy_details &&
                raw_material_energy_details.unit_price_of_power
                  ? raw_material_energy_details.unit_price_of_power
                  : ''}
              </td>
              <td style={styles.thTd}>INR/KWh</td>
            </tr>
            <tr>
              <td style={styles.thTd}>Fce. Capacity in which need to trial</td>
              <td style={styles.thTd}>
                {furnace_details && furnace_details.fce_capacity
                  ? furnace_details.fce_capacity
                  : ''}
              </td>
              <td style={styles.thTd}>MT</td>
              <td
                colSpan="3"
                style={{ ...styles.thTd, ...styles.categoryHeader }}
              >
                Furnace Dimension
              </td>
            </tr>
            <tr>
              <td style={styles.thTd}>Avg. Heat Size (total MT per heat)</td>
              <td style={styles.thTd}>
                {furnace_details && furnace_details.avg_heatsize
                  ? furnace_details.avg_heatsize
                  : ''}
              </td>
              <td style={styles.thTd}>MT</td>
              <td style={styles.emptyCell}></td>
              <td style={styles.emptyCell}></td>
              <td style={styles.emptyCell}></td>
            </tr>
            <tr>
              <td style={styles.thTd}>Power Rating of Fce.</td>
              <td style={styles.thTd}>
                {furnace_details && furnace_details.power_rating
                  ? furnace_details.power_rating
                  : ''}
              </td>
              <td style={styles.thTd}>kW</td>
              <td style={styles.emptyCell}></td>
              <td style={styles.emptyCell}></td>
              <td style={styles.emptyCell}></td>
            </tr>
            <tr>
              <td style={styles.thTd}>Is Fce Running with Full Power</td>
              <td style={styles.thTd}>
                {furnace_details && furnace_details.fce_running
                  ? furnace_details.fce_running
                  : ''}
              </td>
              <td style={styles.thTd}>Yes / No</td>
              <td style={styles.emptyCell}></td>
              <td style={styles.emptyCell}></td>
              <td style={styles.emptyCell}></td>
            </tr>
            <tr>
              <td style={styles.thTd}>Tap to Tap Time</td>
              <td style={styles.thTd}>
                {furnace_details && furnace_details.tap_time
                  ? furnace_details.tap_time
                  : ''}
              </td>
              <td style={styles.thTd}>HH:mm</td>
              <td style={styles.emptyCell}></td>
              <td style={styles.emptyCell}></td>
              <td style={styles.emptyCell}></td>
            </tr>
            <tr>
              <td style={styles.thTd}>Sintering Panel</td>
              <td style={styles.thTd}>
                {furnace_details && furnace_details.sintering_panel
                  ? furnace_details.sintering_panel
                  : ''}
              </td>
              <td style={styles.thTd}>Yes / No</td>
              <td style={styles.emptyCell}></td>
              <td style={styles.emptyCell}></td>
              <td style={styles.emptyCell}></td>
            </tr>
            <tr>
              <td style={styles.thTd}>Power Rating (Sintering Panel)</td>
              <td style={styles.thTd}>
                {furnace_details && furnace_details.power_rating_sintering
                  ? furnace_details.power_rating_sintering
                  : ''}
              </td>
              <td style={styles.thTd}>KW</td>
              <td style={styles.emptyCell}></td>
              <td style={styles.emptyCell}></td>
              <td style={styles.emptyCell}></td>
            </tr>
            <tr>
              <td style={styles.thTd}>Total No. of Heats / Day</td>
              <td style={styles.thTd}>
                {furnace_details && furnace_details.no_of_heats_perday
                  ? furnace_details.no_of_heats_perday
                  : ''}
              </td>
              <td style={styles.thTd}>each</td>
              <td style={styles.emptyCell}></td>
              <td style={styles.emptyCell}></td>
              <td style={styles.emptyCell}></td>
            </tr>
            <tr>
              <td style={styles.thTd}>
                Avg. Power Consumption / MT of Billet (Only Fce)
              </td>
              <td style={styles.thTd}>
                {furnace_details && furnace_details.avg_power_consumption
                  ? furnace_details.avg_power_consumption
                  : ''}
              </td>
              <td style={styles.thTd}>kWh / MT</td>
              <td style={styles.emptyCell}></td>
              <td style={styles.emptyCell}></td>
              <td style={styles.emptyCell}></td>
            </tr>
            <tr>
              <td style={styles.thTd}>LRF/AOD</td>
              <td style={styles.thTd}>
                {furnace_details && furnace_details.lrf
                  ? furnace_details.lrf
                  : ''}
              </td>
              <td style={styles.thTd}>Yes / No</td>
              <td style={styles.emptyCell}></td>
              <td style={styles.emptyCell}></td>
              <td style={styles.emptyCell}></td>
            </tr>
            <tr>
              <td style={styles.thTd}>Frequency Range</td>
              <td style={styles.thTd}>
                {furnace_details && furnace_details.frequency_range
                  ? furnace_details.frequency_range
                  : ''}
              </td>
              <td style={styles.thTd}>Hz</td>
              <td style={styles.emptyCell}></td>
              <td style={styles.emptyCell}></td>
              <td style={styles.emptyCell}></td>
            </tr>
            <tr>
              <td style={styles.thTd}>Maximum tapping temperature</td>
              <td style={styles.thTd}>
                {furnace_details && furnace_details.max_tapping_temp
                  ? furnace_details.max_tapping_temp
                  : ''}
              </td>
              <td style={styles.thTd}>⁰C</td>
              <td style={styles.emptyCell}></td>
              <td style={styles.emptyCell}></td>
              <td style={styles.emptyCell}></td>
            </tr>
            <tr>
              <td style={styles.thTd}>Average tapping temperature</td>
              <td style={styles.thTd}>
                {furnace_details && furnace_details.avg_tapping_temp
                  ? furnace_details.avg_tapping_temp
                  : ''}
              </td>
              <td style={styles.thTd}>⁰C</td>
              <td style={styles.thTd}>Inner Dia. of Coil ,(inch / mm)</td>
              <td style={styles.thTd}>
                {furnace_dimension && furnace_dimension.inner_dia_after_coilcoat
                  ? furnace_dimension.inner_dia_after_coilcoat
                  : ''}
              </td>
              <td style={styles.thTd}>inch</td>
            </tr>
            <tr>
              <td style={styles.thTd}>Furnace voltage</td>
              <td style={styles.thTd}>
                {furnace_details && furnace_details.furnace_voltage
                  ? furnace_details.furnace_voltage
                  : ''}
              </td>
              <td style={styles.thTd}>V</td>
              <td style={styles.thTd}>
                A (Inner Dia. after doing Coil Coat / Grout)
              </td>
              <td style={styles.thTd}>
                {furnace_dimension && furnace_dimension.inner_dia_after_coilcoat
                  ? furnace_dimension.inner_dia_after_coilcoat
                  : ''}
              </td>
              <td style={styles.thTd}>inch</td>
            </tr>
            <tr>
              <td style={styles.thTd}>Furnace OEM</td>
              <td style={styles.thTd}>
                {furnace_details && furnace_details.furnace_oem
                  ? furnace_details.furnace_oem
                  : ''}
              </td>
              <td style={styles.thTd}>No unit</td>
              <td style={styles.thTd}>B (Former Outer Dia.)</td>
              <td style={styles.thTd}>
                {furnace_dimension && furnace_dimension.former_outer_dia
                  ? furnace_dimension.former_outer_dia
                  : ''}
              </td>
              <td style={styles.thTd}>inch</td>
            </tr>
            <tr>
              <td style={styles.thTd}>Furnace year of manufacture</td>
              <td style={styles.thTd}>
                {furnace_details && furnace_details.furnace_year
                  ? furnace_details.furnace_year
                  : ''}
              </td>
              <td style={styles.thTd}>YYYY</td>
              <td style={styles.thTd}>C Furnace height</td>
              <td style={styles.thTd}>
                {furnace_dimension && furnace_dimension.furnace_height
                  ? furnace_dimension.furnace_height
                  : ''}
              </td>
              <td style={styles.thTd}>inch</td>
            </tr>
            <tr>
              <td style={styles.thTd}>Coil grout / coat Supplier name</td>
              <td style={styles.thTd}>
                {furnace_details && furnace_details.coil_grout
                  ? furnace_details.coil_grout
                  : ''}
              </td>
              <td style={styles.thTd}>No Unit</td>
              <td style={styles.thTd}>E Bottom Taper Dia</td>
              <td style={styles.thTd}>
                {furnace_dimension && furnace_dimension.bottom_taper_dia
                  ? furnace_dimension.bottom_taper_dia
                  : ''}
              </td>
              <td style={styles.thTd}>inch</td>
            </tr>
            <tr>
              <td style={styles.thTd}>Sintering time</td>
              <td style={styles.thTd}>
                {furnace_details && furnace_details.sintering_time
                  ? furnace_details.sintering_time
                  : ''}
              </td>
              <td style={styles.thTd}>Hrs.</td>
              <td style={styles.thTd}>F (Bottom Outer Dia.)</td>
              <td style={styles.thTd}>
                {furnace_dimension && furnace_dimension.bottom_outer_dia
                  ? furnace_dimension.bottom_outer_dia
                  : ''}
              </td>
              <td style={styles.thTd}>inch</td>
            </tr>
            <tr>
              <td style={styles.thTd}>Operation</td>
              <td style={styles.thTd}>
                {furnace_details && furnace_details.operation
                  ? furnace_details.operation
                  : ''}
              </td>
              <td style={styles.thTd}>No Units</td>
              <td style={styles.thTd}>G (Former ht.)</td>
              <td style={styles.thTd}>
                {furnace_dimension && furnace_dimension.former_ht
                  ? furnace_dimension.former_ht
                  : ''}
              </td>
              <td style={styles.thTd}>inch</td>
            </tr>
            <tr>
              <td style={styles.thTd}>Furnace Change over time</td>
              <td style={styles.thTd}>
                {furnace_details && furnace_details.change_overtime
                  ? furnace_details.change_overtime
                  : ''}
              </td>
              <td style={styles.thTd}>minutes</td>
              <td style={styles.thTd}>H (Bottom Lining Ht.)</td>
              <td style={styles.thTd}>
                {furnace_dimension && furnace_dimension.bottom_lining_ht
                  ? furnace_dimension.bottom_lining_ht
                  : ''}
              </td>
              <td style={styles.thTd}>inch</td>
            </tr>
            <tr>
              <td style={styles.thTd}>Furnace Checking time</td>
              <td style={styles.thTd}>
                {furnace_details && furnace_details.checking_time
                  ? furnace_details.checking_time
                  : ''}
              </td>
              <td style={styles.thTd}>minutes</td>
              <td style={styles.thTd}>Total Power given to SMS</td>
              <td style={styles.thTd}>
                {furnace_dimension && furnace_dimension.bottom_lining_ht
                  ? furnace_dimension.bottom_lining_ht
                  : ''}
              </td>
              <td style={styles.thTd}>MW</td>
            </tr>
            <tr>
              <th
                colSpan="3"
                style={{ ...styles.thTd, ...styles.categoryHeader }}
              >
                Product Details
              </th>
              <th
                colSpan="3"
                style={{ ...styles.thTd, ...styles.categoryHeader }}
              >
                Lining Details
              </th>
            </tr>
            <tr>
              <td style={styles.thTd}>Deslagging by</td>
              <td style={styles.thTd}>
                {mild_steel_customers_only &&
                mild_steel_customers_only.checking_time
                  ? mild_steel_customers_only.checking_time
                  : ''}
              </td>
              <td style={styles.thTd}>Spoon / Tilt</td>
              <td style={styles.thTd}>Consumption for New lining</td>
              <td style={styles.thTd}>
                {lining_details && lining_details.consumption_for_new_lining
                  ? lining_details.consumption_for_new_lining
                  : ''}
              </td>
              <td style={styles.thTd}>Kg / Lining</td>
            </tr>
            <tr>
              <td style={styles.thTd}>
                Use Magnet to remove the slag jam after every deslagging
              </td>
              <td style={styles.thTd}>
                {mild_steel_customers_only &&
                mild_steel_customers_only.using_magnet_or_not
                  ? mild_steel_customers_only.using_magnet_or_not
                  : ''}
              </td>
              <td style={styles.thTd}>Yes / No</td>
              <td style={styles.thTd}>Consumption for Patching/Side Lining</td>
              <td style={styles.thTd}>
                {lining_details && lining_details.consumption_for_patching
                  ? lining_details.consumption_for_patching
                  : ''}
              </td>
              <td style={styles.thTd}>Kg / Side Lining</td>
            </tr>
            <tr>
              <td style={styles.thTd}>Avg. C% at 50% of Bath Metal</td>
              <td style={styles.thTd}>
                {mild_steel_customers_only &&
                mild_steel_customers_only.avg_c_percent_at_50
                  ? mild_steel_customers_only.avg_c_percent_at_50
                  : ''}
              </td>
              <td style={styles.thTd}>%</td>
              <td style={styles.thTd}>New Lining Life</td>
              <td style={styles.thTd}>
                {lining_details && lining_details.consumption_for_patching
                  ? lining_details.consumption_for_patching
                  : ''}
              </td>
              <td style={styles.thTd}>No. of heats</td>
            </tr>
            <tr>
              <td style={styles.thTd}>Avg. C% at 80% of Bath Metal</td>
              <td style={styles.thTd}>
                {mild_steel_customers_only &&
                mild_steel_customers_only.avg_c_percent_at_80
                  ? mild_steel_customers_only.avg_c_percent_at_80
                  : ''}
              </td>
              <td style={styles.thTd}>%</td>
              <td style={styles.thTd}>Side/Patching lining life</td>
              <td style={styles.thTd}>
                {lining_details && lining_details.patching_lining_life
                  ? lining_details.patching_lining_life
                  : ''}
              </td>
              <td style={styles.thTd}>No. of heats</td>
            </tr>
            <tr>
              <td style={styles.thTd}>Final C% Before Tapping</td>
              <td style={styles.thTd}>
                {mild_steel_customers_only &&
                mild_steel_customers_only.final_c_percent_beforeTapping
                  ? mild_steel_customers_only.final_c_percent_beforeTapping
                  : ''}
              </td>
              <td style={styles.thTd}>%</td>
              <td style={styles.thTd}>
                Total No. of Side lining with keeping same bottom
              </td>
              <td style={styles.thTd}>
                {lining_details && lining_details.total_no_of_side_lining
                  ? lining_details.total_no_of_side_lining
                  : ''}
              </td>
              <td style={styles.thTd}>each</td>
            </tr>
            <tr>
              <td style={styles.thTd}>Mill Scale / heat</td>
              <td style={styles.thTd}>
                {mild_steel_customers_only &&
                mild_steel_customers_only.data_mill_scale
                  ? mild_steel_customers_only.data_mill_scale
                  : ''}
              </td>
              <td style={styles.thTd}>Kg</td>
              <td style={styles.thTd}>Present Lining method</td>
              <td style={styles.thTd}>
                {lining_details && lining_details.present_lining_method
                  ? lining_details.present_lining_method
                  : ''}
              </td>
              <td style={styles.thTd}>Manual / Machine</td>
            </tr>
            <tr>
              <td style={styles.thTd}>
                No of times TILT the Fce for deslagging per heat
              </td>
              <td style={styles.thTd}>
                {mild_steel_customers_only &&
                mild_steel_customers_only.tilt_count_for_deslagging
                  ? mild_steel_customers_only.tilt_count_for_deslagging
                  : ''}
              </td>
              <td style={styles.thTd}>No.</td>
              <th
                colSpan="3"
                style={{ ...styles.thTd, ...styles.categoryHeader }}
              >
                Alloy and Stainless Steel Customers
              </th>
            </tr>
            <tr>
              <td style={styles.thTd}>
                Are they taking Liquid metal up to full level
              </td>
              <td style={styles.thTd}>
                {mild_steel_customers_only &&
                mild_steel_customers_only.are_they_taking_liquid_metal
                  ? mild_steel_customers_only.are_they_taking_liquid_metal
                  : ''}
              </td>
              <td style={styles.thTd}>No Unit</td>
              <td style={styles.thTd}>FeCr Consumption / heat</td>
              <td style={styles.thTd}>
                {alloy_stainless_steel_customers &&
                alloy_stainless_steel_customers.simn_consumption
                  ? alloy_stainless_steel_customers.simn_consumption
                  : ''}
              </td>
              <td style={styles.thTd}>Kg / heat</td>
            </tr>
            <tr>
              <td style={styles.thTd}>DRI Quality (Lumps / Pellets / Fines)</td>
              <td style={styles.thTd}>
                {mild_steel_customers_only &&
                mild_steel_customers_only.dri_quality
                  ? mild_steel_customers_only.dri_quality
                  : ''}
              </td>
              <td style={styles.thTd}>Ratio</td>
              <td style={styles.thTd}>Cr% in FeCr</td>
              <td style={styles.thTd}>
                {alloy_stainless_steel_customers &&
                alloy_stainless_steel_customers.simn_consumption
                  ? alloy_stainless_steel_customers.simn_consumption
                  : ''}
              </td>
              <td style={styles.thTd}>%</td>
            </tr>
            <tr>
              <td style={styles.thTd}>Slag Generation per heat</td>
              <td style={styles.thTd}>
                {mild_steel_customers_only &&
                mild_steel_customers_only.dri_quality
                  ? mild_steel_customers_only.dri_quality
                  : ''}
              </td>
              <td style={styles.thTd}>%</td>
              <td style={styles.thTd}>Final Cr %</td>
              <td style={styles.thTd}>
                {alloy_stainless_steel_customers &&
                alloy_stainless_steel_customers.simn_consumption
                  ? alloy_stainless_steel_customers.simn_consumption
                  : ''}
              </td>
              <td style={styles.thTd}>%</td>
            </tr>
            <tr>
              <td style={styles.thTd}>Power utilised</td>
              <td style={styles.thTd}>
                {mild_steel_customers_only &&
                mild_steel_customers_only.power_utilised
                  ? mild_steel_customers_only.power_utilised
                  : ''}
              </td>
              <td style={styles.thTd}>Own / Grid</td>
              <td style={styles.thTd}>Cr Recovery</td>
              <td style={styles.thTd}>
                {alloy_stainless_steel_customers &&
                alloy_stainless_steel_customers.simn_consumption
                  ? alloy_stainless_steel_customers.simn_consumption
                  : ''}
              </td>
              <td style={styles.thTd}>%</td>
            </tr>
            <tr>
              <td style={styles.thTd}>Silica Price, (Landed Price)</td>
              <td style={styles.thTd}>
                {mild_steel_customers_only &&
                mild_steel_customers_only.silica_price
                  ? mild_steel_customers_only.silica_price
                  : ''}
              </td>
              <td style={styles.thTd}>INR / kg</td>
              <td style={styles.thTd}>Any other alloys additions</td>
              <td style={styles.thTd}>
                {alloy_stainless_steel_customers &&
                alloy_stainless_steel_customers.simn_consumption
                  ? alloy_stainless_steel_customers.simn_consumption
                  : ''}
              </td>
              <td style={styles.thTd}>No unit</td>
            </tr>
            <tr>
              <td style={styles.thTd}>CCM (Avg casting speed)</td>
              <td style={styles.thTd}>
                {mild_steel_customers_only && mild_steel_customers_only.ccm
                  ? mild_steel_customers_only.ccm
                  : ''}
              </td>
              <td style={styles.thTd}>m/min.</td>
              <td style={styles.thTd}>Casting method</td>
              <td style={styles.thTd}>
                {alloy_stainless_steel_customers &&
                alloy_stainless_steel_customers.casting_method
                  ? alloy_stainless_steel_customers.casting_method
                  : ''}
              </td>
              <td style={styles.thTd}>No unit</td>
            </tr>
            <tr>
              <td style={styles.thTd}>Casting time</td>
              <td style={styles.thTd}>
                {mild_steel_customers_only &&
                mild_steel_customers_only.casting_time
                  ? mild_steel_customers_only.casting_time
                  : ''}
              </td>
              <td style={styles.thTd}>m</td>
              <td style={styles.thTd}>Simn consumption/heat</td>
              <td style={styles.thTd}>
                {alloy_stainless_steel_customers &&
                alloy_stainless_steel_customers.simn_consumption
                  ? alloy_stainless_steel_customers.simn_consumption
                  : ''}
              </td>
              <td style={styles.thTd}>Kg/heat</td>
            </tr>
            <tr>
              <td style={styles.thTd}>Tundish cap</td>
              <td style={styles.thTd}>
                {mild_steel_customers_only &&
                mild_steel_customers_only.tundish_cap
                  ? mild_steel_customers_only.tundish_cap
                  : ''}
              </td>
              <td style={styles.thTd}>MT</td>
              <td style={styles.thTd}>Mn % in Simn</td>
              <td style={styles.thTd}>
                {alloy_stainless_steel_customers &&
                alloy_stainless_steel_customers.mn_percentage
                  ? alloy_stainless_steel_customers.mn_percentage
                  : ''}
              </td>
              <td style={styles.thTd}>%</td>
            </tr>
            <tr>
              <td style={styles.thTd}>Min level of tundish</td>
              <td style={styles.thTd}>
                {mild_steel_customers_only &&
                mild_steel_customers_only.min_level_of_tundish
                  ? mild_steel_customers_only.min_level_of_tundish
                  : ''}
              </td>
              <td style={styles.thTd}>MT</td>
              <td style={styles.thTd}>Mn Recovery</td>
              <td style={styles.thTd}>
                {alloy_stainless_steel_customers &&
                alloy_stainless_steel_customers.mn_percentage
                  ? alloy_stainless_steel_customers.mn_percentage
                  : ''}
              </td>
              <td style={styles.thTd}>%</td>
            </tr>
            <tr>
              <td style={styles.thTd}>Billet size in (LXHXT)</td>
              <td style={styles.thTd}>
                {mild_steel_customers_only &&
                mild_steel_customers_only.billet_size
                  ? mild_steel_customers_only.billet_size
                  : ''}
              </td>
              <td style={styles.thTd}>mm x mm x mm</td>
              <td style={styles.thTd}>Addition of SiMn (FC or ladle Ratio)</td>
              <td style={styles.thTd}>
                {alloy_stainless_steel_customers &&
                alloy_stainless_steel_customers.addition_of_simn
                  ? alloy_stainless_steel_customers.addition_of_simn
                  : ''}
              </td>
              <td style={styles.thTd}>Kg/heat</td>
            </tr>
            <tr>
              <td style={styles.thTd}>No of sequence</td>
              <td style={styles.thTd}>
                {mild_steel_customers_only &&
                mild_steel_customers_only.no_of_sequence
                  ? mild_steel_customers_only.no_of_sequence
                  : ''}
              </td>
              <td style={styles.thTd}>each</td>
              <td style={styles.thTd}>Any other alloys additions</td>
              <td style={styles.thTd}>
                {alloy_stainless_steel_customers &&
                alloy_stainless_steel_customers.addition_of_simn
                  ? alloy_stainless_steel_customers.addition_of_simn
                  : ''}
              </td>
              <td style={styles.thTd}>No unit</td>
            </tr>
            <tr>
              <td style={styles.thTd}>CCM make - OEM</td>
              <td style={styles.thTd}>
                {mild_steel_customers_only && mild_steel_customers_only.ccm_make
                  ? mild_steel_customers_only.ccm_make
                  : ''}
              </td>
              <td style={styles.thTd}>No unit</td>
              <td style={styles.thTd}>Final Chemistry at Furnace (Range)</td>
              <td style={styles.thTd}>
                {alloy_stainless_steel_customers &&
                alloy_stainless_steel_customers.final_chemistry_at_furnace
                  ? alloy_stainless_steel_customers.final_chemistry_at_furnace
                  : ''}
              </td>
              <td style={styles.thTd}>No unit</td>
            </tr>
            <tr>
              <td style={styles.thTd}>CCM Preparation time in Hours</td>
              <td style={styles.thTd}>
                {mild_steel_customers_only &&
                mild_steel_customers_only.ccm_preparation_time
                  ? mild_steel_customers_only.ccm_preparation_time
                  : ''}
              </td>
              <td style={styles.thTd}>HH</td>
              <td style={styles.thTd}>Final Chemistry at Billet (Range)</td>
              <td style={styles.thTd}>Details here</td>
              <td style={styles.thTd}>No unit</td>
            </tr>
            <tr>
              <td style={styles.thTd}>Objective / Purpose of using NRM</td>
              <td style={styles.thTd}>
                {mild_steel_customers_only &&
                mild_steel_customers_only.purpose_of_using_nrm
                  ? mild_steel_customers_only.purpose_of_using_nrm
                  : ''}
              </td>
              <td style={styles.thTd}></td>
              <td style={styles.thTd}>
                Agreement to share relevant plant data during trial evaluation{' '}
                <br />
                of the proposed Heat King Refractory Lining Solution
              </td>
              <td style={styles.thTd}>Details here</td>
              <td style={styles.thTd}></td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  //   const handleApprove = () => {
  //     AlertDialog({
  //       type: 'delete',
  //       title: 'Submission',
  //       text: 'Are you sure you want to Approval?',
  //       confirmButtonText: 'Yes',
  //       onConfirm: async () => {
  //         // if(currentUser.role.role_id === 3 || currentUser.role.role_id === 4){
  //         //   await insertData(updatedGeneralDetails, uploadId,"approved");
  //         // }else{
  //         //   await insertData(updatedGeneralDetails, uploadId,"submitted");
  //         // }
  //       },
  //     });

  //   }
  //   const handleReject = () => {

  //   }
  return (
    <div>
      <div style={{ overflowX: 'auto' }}>
        {tableData.length === 0 ? (
          <Typography
            variant="body1"
            sx={{ textAlign: 'center', marginTop: '20px' }}
          >
            Trials not found
          </Typography>
        ) : (
          <div>
            {tableData.submission_status === 'submitted' &&
              (currentUser.role.role_id === 3 ||
                currentUser.role.role_id === 4) && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'fixed',
                    top: 100,
                    right: 20,
                    backgroundColor: 'white',
                    zIndex: 1000,
                    padding: '10px',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    variant="body1"
                    onClick={handleBack}
                    style={{
                      cursor: 'pointer',
                      color: '#1976d2',
                      padding: '10px',
                    }}
                  >
                    Back
                  </Typography>

                  {tableData.submission_status === 'submitted' &&
                    (currentUser.role.role_id === 3 ||
                      currentUser.role.role_id === 4) && (
                      <>
                        <StyledButtonWeb
                          variant="outlined_reject"
                          text="Reject"
                          onClick={handleReject}
                          style={{ width: '100px' }}
                        />
                        <StyledButtonWeb
                          variant="contained"
                          color="primary"
                          text="Approve"
                          onClick={handleApprove}
                          style={{ width: '100px' }}
                        />
                      </>
                    )}

                  {tableData.submission_status === 'rejected' && (
                    <StyledButtonWeb
                      variant="contained"
                      color="primary"
                      text="View"
                      onClick={handleRejectComment}
                      style={{ width: '100px' }}
                    />
                  )}
                </div>
              )}

            {tableData.submission_status === 'rejected' && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  position: 'fixed',
                  top: 100,
                  right: 20,
                  backgroundColor: 'white',
                  zIndex: 1000,
                  padding: '10px',
                  alignItems: 'center',
                }}
              >
                <StyledButtonWeb
                  variant="contained"
                  color="primary"
                  text="View"
                  onClick={handleRejectComment}
                  style={{ width: '100px' }}
                />
              </div>
            )}

            {showRejectPopup && (
              <div
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 1001,
                }}
              >
                <div
                  style={{
                    backgroundColor: 'white',
                    padding: '20px',
                    borderRadius: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <TextField
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    placeholder="Enter comments"
                    style={{
                      width: '300px',
                      height: '100px',
                      marginBottom: '10px',
                    }}
                  />
                  <StyledButtonWeb
                    variant="contained"
                    color="primary"
                    text="OK"
                    onClick={handlePopupOk}
                    style={{ width: '100px' }}
                  />
                </div>
              </div>
            )}

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '20px',
                  letterSpacing: '-0.006em',
                  textAlign: 'left',
                  color: '#252C32',
                  marginTop: '10px',
                  zIndex: 1,
                  backgroundColor: '#fff',
                  position: 'sticky',
                  top: 0,
                }}
              >
                Data Collection
              </Typography>
            </div>
            <TableContainer component={Paper}>
              {(() => {
                if (parsedData.dataFile.template.template_id === 4) {
                  return <TableA />
                } else {
                  return null
                }
              })()}
            </TableContainer>
          </div>
        )}
      </div>
    </div>
  )
}

export default ViewDataCollection
