import React, { useEffect } from 'react'
import TextBox from '../../input/TextBox'
import SelectBox from '../../input/SelectBox'
import { useRecoilState, useRecoilValue } from 'recoil'
import { furnace_details, general_details } from '../../../recoil/atoms'
// import MyTimePicker from '../input/MyTimePicker';
import { energy_conspt } from '../../../recoil/selector'
import HourMinInput from '../../input/TimePicker'
import { event } from 'jquery'

const FurnaceDetails = () => {
  const [furnaceDetails, setFurnaceDetails] = useRecoilState(furnace_details)
  console.log(furnaceDetails, 'heatonetime')
  const [generalDetails] = useRecoilState(general_details)
  const uniqueId = generalDetails.id ? generalDetails.id : ''
  const energyConsumption = useRecoilValue(energy_conspt)
  console.log(energyConsumption, 'energyConsumption')

  // const MnRecovery = useRecoilValue(heatDurationCalculated);
  // console.log("MnRecoveryMnRecoveryMnRecovery",MnRecovery);

  // const handleChange = (e) => {
  //     const { name, value } = e.target;
  //     const TrialName = localStorage.getItem("Trial");

  //     // Check if TrialName is "new"
  //     if (TrialName === "new" || TrialName === "add") {
  //         // Generate unique ID
  //   setFurnaceDetails(prevState => ({
  //     ...prevState,
  //     [name]: value,
  //     id:uniqueId,
  //   }));
  // } else {
  //   // If TrialName is not "new", no need to generate unique ID
  //   setFurnaceDetails(prevState => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // }

  // //   Get calculated heat duration
  // //   const heatDuration = useRecoilValue(heatDurationCalculated);

  // //   console.log("heatDuration",heatDuration);

  //     // Regular expression to allow texts with optional decimal point
  //     const regex = /^[0-9]*\.?[0-9]{0,2}$/;

  //     // Check if the input value matches the text pattern
  //     if (regex.test(value) || value === "") {
  //       if (TrialName === "new") {
  //         // Generate unique ID
  //         setFurnaceDetails((prevState) => ({
  //           ...prevState,
  //           [name]: value,
  //           id: uniqueId,
  //         }));
  //       } else {
  //         // If TrialName is not "new", no need to generate unique ID
  //         setFurnaceDetails((prevState) => ({
  //           ...prevState,
  //           [name]: value,
  //         }));
  //       }
  //     }

  //         // / Get calculated heat duration
  // const heatDuration = useRecoilValue(heatDurationCalculated);
  // console.log("heatDuration",heatDuration);

  //   };

  function convertToMinutes(timeStr) {
    if (typeof timeStr === 'string') {
      const [hours, minutes] = timeStr.split(':').map(Number)
      return hours * 60 + minutes
    } else {
      console.error('Invalid time string:', timeStr)
      return NaN
    }
  }

  const calculateDuration = (startTime, endTime) => {
    const [startHours, startMinutes] = startTime.split(':').map(Number)
    const [endHours, endMinutes] = endTime.split(':').map(Number)

    let durationHours = endHours - startHours
    let durationMinutes = endMinutes - startMinutes

    if (durationMinutes < 0) {
      durationMinutes += 60
      durationHours -= 1
    }

    if (durationHours < 0) {
      durationHours += 24
    }

    // Ensure both hours and minutes are two digits
    const formattedHours = String(durationHours).padStart(2, '0')
    const formattedMinutes = String(durationMinutes).padStart(2, '0')

    return `${formattedHours}:${formattedMinutes}`
  }

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   const TrialName = localStorage.getItem('Trial');

  //   setFurnaceDetails((prevState) => {
  //     const updatedDetails = {
  //       ...prevState,
  //       [name]: value,
  //       id: TrialName === 'new' ? uniqueId : prevState.id,
  //     };

  //     if (updatedDetails.heat_on_time && updatedDetails.heat_tap_time) {
  //       updatedDetails.heat_duration_calculated = calculateDuration(
  //         updatedDetails.heat_on_time,
  //         updatedDetails.heat_tap_time
  //       );
  //     }

  //     if (updatedDetails.tapped_tonnage && updatedDetails.heat_duration_actual) {
  //       const tappedTonnage = parseFloat(updatedDetails.tapped_tonnage);
  //       const [hours, minutes] = updatedDetails.heat_duration_actual.split(":").map(Number);
  //       const heatDurationMinutesActual = hours * 60 + minutes;
  //       const meltRate = ((tappedTonnage * 60) / heatDurationMinutesActual * 1000 / 60).toFixed(2);

  //       updatedDetails.melt_rate_based_on_actual_duration = meltRate;
  //     }

  //     const heatDurationCalculated = updatedDetails.heat_duration_calculated;

  //     console.log("heatDurationCalculated",heatDurationCalculated);

  //      const tappedTonnage = parseFloat(updatedDetails.tapped_tonnage);

  //     if (heatDurationCalculated && heatDurationCalculated !== "") {
  //                   const [hours, minutes] = heatDurationCalculated.split(":").map(Number);
  //                   const heatDurationMinutesCalculated = hours * 60 + minutes;
  //                   if (!isNaN(hours) && !isNaN(minutes)) {
  //                       const meltRateCalculated = (tappedTonnage * 60 / heatDurationMinutesCalculated * 1000 / 60).toFixed(2);

  //                       setFurnaceDetails(prevState => ({
  //                           ...prevState,
  //                           melt_rate_based_on_calculated_duration: meltRateCalculated,
  //                       }));
  //                   } else {
  //                       setFurnaceDetails(prevState => ({
  //                           ...prevState,
  //                           melt_rate_based_on_calculated_duration: 0, // or any default value
  //                       }));
  //                   }
  //               } else {
  //                   setFurnaceDetails(prevState => ({
  //                       ...prevState,
  //                       melt_rate_based_on_calculated_duration: 0, // or any default value
  //                   }));
  //               }

  //   const totalPowerConsumedActual = parseFloat(updatedDetails.total_power_consumed);
  //   if (!isNaN(tappedTonnage) && !isNaN(totalPowerConsumedActual) && tappedTonnage !== 0) {
  //       const energyConsumptionPerUnitTonCalculated = (totalPowerConsumedActual / tappedTonnage).toFixed(2);
  //       setFurnaceDetails(prevState => ({
  //           ...prevState,
  //           energy_consumption_per_unit_ton_calculated: energyConsumptionPerUnitTonCalculated,
  //       }));
  //     }
  //     return updatedDetails;
  //   });
  // };

  const handleChange = (e) => {
    const { name, value } = e.target
    const TrialName = localStorage.getItem('Trial')
    const regex = /^\d{0,5}(\.\d{0,2})?$/
    if (!regex.test(value)) {
      console.warn('Invalid input: ', value)
      return
    }

    setFurnaceDetails((prevState) => {
      const updatedDetails = {
        ...prevState,
        [name]: value,
        id: TrialName === 'new' ? uniqueId : prevState.id,
        energy_consumption_per_unit_ton_calculated: energyConsumption,
      }

      console.log('updatedDetails', updatedDetails)

      if (
        updatedDetails.heat_duration_calculated &&
        updatedDetails.heat_duration_calculated !== ''
      ) {
        const [hours, minutes] = updatedDetails.heat_duration_calculated
          .split(':')
          .map(Number)
        const heatDurationMinutesCalculated = hours * 60 + minutes
        if (!isNaN(hours) && !isNaN(minutes)) {
          const meltRateCalculated = (
            (((parseFloat(updatedDetails.tapped_tonnage) * 60) /
              heatDurationMinutesCalculated) *
              1000) /
            60
          ).toFixed(2)

          updatedDetails.melt_rate_based_on_calculated_duration =
            meltRateCalculated
        } else {
          updatedDetails.melt_rate_based_on_calculated_duration = 0 // or any default value
        }
      } else {
        updatedDetails.melt_rate_based_on_calculated_duration = 0 // or any default value
      }

      if (
        updatedDetails.tapped_tonnage &&
        updatedDetails.heat_duration_actual
      ) {
        const tappedTonnage = parseFloat(updatedDetails.tapped_tonnage)
        const [hours, minutes] = updatedDetails.heat_duration_actual
          .split(':')
          .map(Number)
        const heatDurationMinutesActual = hours * 60 + minutes
        const meltRate = (
          (((tappedTonnage * 60) / heatDurationMinutesActual) * 1000) /
          60
        ).toFixed(2)

        updatedDetails.melt_rate_based_on_actual_duration = meltRate
      }

      return updatedDetails
    })
  }

  // useEffect(() => {
  //   console.log('heat_on_time1232', furnaceDetails.heat_on_time);
  //   console.log('heat_tap_time1232', furnaceDetails.heat_tap_time);
  //   console.log('heat_duration_calculated', furnaceDetails.heat_duration_calculated);
  // }, [furnaceDetails]);

  //     const handleChange = (e) => {
  //         const { name, value } = e.target;
  //         const TrialName = localStorage.getItem("Trial");

  //         if (TrialName === "new") {
  //             setFurnaceDetails((prevState) => ({
  //               ...prevState,
  //               [name]: value,
  //               id: uniqueId,
  //             }));
  //           } else {
  //             setFurnaceDetails((prevState) => ({
  //               ...prevState,
  //               [name]: value,
  //             }));
  //           }

  //        console.log("heat_on_time", furnaceDetails.heat_on_time);
  //        console.log("heat_tap_time",furnaceDetails.heat_tap_time);

  //         // Get the values of Heat ON Time and Heat Tap Time
  //         const heatOnTime = parseFloat(furnaceDetails.heat_on_time?.replace(':', '.') || 0);
  //         const heatTapTime = parseFloat(furnaceDetails.heat_tap_time?.replace(':', '.') || 0);
  //         console.log("heatOnTime122222",furnaceDetails.heat_on_time)
  //         console.log("heatTapTime121212",furnaceDetails.heat_tap_time)

  //         const heatOnTimeMinutes = convertToMinutes(heatOnTime);
  //     const heatTapTimeMinutes = convertToMinutes(heatTapTime);

  //     // Check if both values are valid numbers
  //     if (!isNaN(heatOnTimeMinutes) && !isNaN(heatTapTimeMinutes)) {
  //         // Calculate the Heat Duration in minutes
  //         let heatDuration;
  //         if (heatTapTimeMinutes < heatOnTimeMinutes) {
  //             heatDuration = heatTapTimeMinutes + 1440 - heatOnTimeMinutes; // 1440 = 24 * 60
  //         } else {
  //             heatDuration = heatTapTimeMinutes - heatOnTimeMinutes;
  //         }
  //         console.log("heatDuration", heatDuration);

  //         // Convert the heat duration to HH:mm format (24-hour)
  //         const formattedHours = Math.floor(heatDuration / 60).toString().padStart(2, '0');
  //         const formattedMinutes = (heatDuration % 60).toString().padStart(2, '0');
  //         const formattedHeatDuration = `${formattedHours}:${formattedMinutes}`;
  //         console.log("formattedHeatDuration", formattedHeatDuration);

  //         // Update furnaceDetails with the calculated heat duration
  //         setFurnaceDetails(prevState => ({
  //             ...prevState,
  //             heat_duration_calculated: formattedHeatDuration,
  //         }));

  //         console.log("heat_duration_calculatedheat_duration_calculated",furnaceDetails.heat_duration_calculated);
  //         // Get the tapped tonnage and heat duration actual
  //         const tappedTonnage = parseFloat(furnaceDetails.tapped_tonnage);
  //         const heatDurationActual = furnaceDetails.heat_duration_actual;
  //         if (heatDurationActual && heatDurationActual !== "") {
  //             const [hours, minutes] = heatDurationActual.split(":").map(Number);
  //             const heatDurationMinutesActual = hours * 60 + minutes;
  //             const meltRate = (tappedTonnage * 60 / heatDurationMinutesActual * 1000 / 60).toFixed(2);

  //             // Update furnaceDetails with the calculated melt rate
  //             setFurnaceDetails(prevState => ({
  //                 ...prevState,
  //                 melt_rate_based_on_actual_duration: meltRate,
  //             }));
  //         }

  //         const heatDurationCalculated = furnaceDetails.heat_duration_calculated;
  //         if (heatDurationCalculated && heatDurationCalculated !== "") {
  //             const [hours, minutes] = heatDurationCalculated.split(":").map(Number);
  //             const heatDurationMinutesCalculated = hours * 60 + minutes;
  //             if (!isNaN(hours) && !isNaN(minutes)) {
  //                 const meltRateCalculated = (tappedTonnage * 60 / heatDurationMinutesCalculated * 1000 / 60).toFixed(2);

  //                 setFurnaceDetails(prevState => ({
  //                     ...prevState,
  //                     melt_rate_based_on_calculated_duration: meltRateCalculated,
  //                 }));
  //             } else {
  //                 setFurnaceDetails(prevState => ({
  //                     ...prevState,
  //                     melt_rate_based_on_calculated_duration: 0, // or any default value
  //                 }));
  //             }
  //         } else {
  //             setFurnaceDetails(prevState => ({
  //                 ...prevState,
  //                 melt_rate_based_on_calculated_duration: 0, // or any default value
  //             }));
  //         }

  //         const totalPowerConsumedActual = parseFloat(furnaceDetails.total_power_consumed);
  //         if (!isNaN(tappedTonnage) && !isNaN(totalPowerConsumedActual) && tappedTonnage !== 0) {
  //             const energyConsumptionPerUnitTonCalculated = (totalPowerConsumedActual / tappedTonnage).toFixed(2);
  //             setFurnaceDetails(prevState => ({
  //                 ...prevState,
  //                 energy_consumption_per_unit_ton_calculated: energyConsumptionPerUnitTonCalculated,
  //             }));
  //         }
  //     }
  // }

  // const changeHeatTimes = (name, newValue) => {
  //   console.log(`Extracted ${name.replace('_', ' ')}:`, newValue);

  //   // Assuming `setFurnaceDetails` is a state updater function from `useState`
  //   setFurnaceDetails((prevDetails) => ({
  //     ...prevDetails,
  //     [name]: newValue,
  //   }));
  // };

  const changeHeatTimes = (name, newValue) => {
    if (newValue && newValue.isValid()) {
      const hours = newValue.hour().toString().padStart(2, '0')
      const minutes = newValue.minute().toString().padStart(2, '0')

      const formattedTime = `${hours}:${minutes}`

      console.log(`Extracted ${name.replace('_', ' ')}:`, formattedTime)
      setFurnaceDetails((prevDetails) => {
        const updatedDetails = {
          ...prevDetails,
          [name]: formattedTime,
        }
        if (updatedDetails.heat_on_time && updatedDetails.heat_tap_time) {
          const duration = calculateDuration(
            updatedDetails.heat_on_time,
            updatedDetails.heat_tap_time
          )
          updatedDetails.heat_duration_calculated = duration

          console.log('Calculated Duration:', duration)
        }

        return updatedDetails
      })
    } else {
      console.error('Invalid date value:', newValue)
    }
  }

  useEffect(() => {
    console.log('heat_on_time1232', furnaceDetails.heat_on_time)
    console.log('heat_tap_time1232', furnaceDetails.heat_tap_time)
  }, [furnaceDetails])

  function extractTime(timeObj) {
    if (timeObj && timeObj.$H !== undefined && timeObj.$m !== undefined) {
      const hours = timeObj.$H.toString().padStart(2, '0')
      const minutes = timeObj.$m.toString().padStart(2, '0')
      return `${hours}:${minutes}`
    } else {
      console.warn('Invalid time object:', timeObj)
      return null
    }
  }

  return (
    <div>
      <div style={{ flex: 1, marginRight: '15px', marginTop: '15px' }}>
        <HourMinInput
          label="Heat ON Time (HH:mm)"
          name="heat_on_time"
          value={furnaceDetails.heat_on_time ? furnaceDetails.heat_on_time : ''}
          onChange={(newValue) => changeHeatTimes('heat_on_time', newValue)}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px', marginTop: '15px' }}>
        <HourMinInput
          label="Heat Tap Time (HH:mm)"
          name="heat_tap_time"
          value={furnaceDetails.heat_tap_time ? furnaceDetails.heat_tap_time : ''}
          onChange={(newValue) => changeHeatTimes('heat_tap_time', newValue)}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px', marginTop: '15px' }}>
        <HourMinInput
          label="Breakdown duration (HH:mm)"
          name="break_down_duration"
          value={furnaceDetails.break_down_duration ? furnaceDetails.break_down_duration : ''}
          onChange={(newValue) =>
            changeHeatTimes('break_down_duration', newValue)
          }
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px', marginTop: '15px' }}>
        <HourMinInput
          name="heat_duration_actual"
          label="Heat Duration Actual (HH:mm)"
          value={furnaceDetails.heat_duration_actual}
          onChange={(newValue) =>
            changeHeatTimes('heat_duration_actual', newValue)
          }
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px', marginTop: '15px' }}>
        <TextBox
          name="heat_duration_calculated"
          label="Heat Duration Calculated (HH:mm)"
          type="text"
          value={furnaceDetails.heat_duration_calculated}
          onChange={handleChange}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="tapping_temperature"
          label="Tapping temperature (°C)"
          type="number"
          maxLength={5}
          value={furnaceDetails.tapping_temperature}
          onChange={handleChange}
        />
      </div>
      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="tapped_tonnage"
          label="Tapped tonnage (MT)"
          type="number"
          value={furnaceDetails.tapped_tonnage}
          onChange={handleChange}
        />
      </div>
      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="melt_rate_based_on_actual_duration"
          label="Melt Rate based on actual duration (kg/min)"
          type="number"
          value={furnaceDetails.melt_rate_based_on_actual_duration}
          onChange={handleChange}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="melt_rate_based_on_calculated_duration"
          label="Melt Rate based on calculated duration (kg/min)"
          type="number"
          value={furnaceDetails.melt_rate_based_on_calculated_duration}
          onChange={handleChange}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="frequency"
          label="Frequency (Hz)"
          type="number"
          value={furnaceDetails.frequency}
          onChange={handleChange}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="voltage"
          label="Voltage (V)"
          type="number"
          value={furnaceDetails.voltage}
          onChange={handleChange}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="power_in_the_panel"
          label="Power in the panel (KW)"
          type="number"
          value={furnaceDetails.power_in_the_panel}
          onChange={handleChange}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="capacitors_removed"
          label="Capacitors Removed (No.)"
          type="number"
          value={furnaceDetails.capacitors_removed}
          onChange={handleChange}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="total_power_consumed"
          label="Total Power Consumed (KWH)"
          type="number"
          value={furnaceDetails.total_power_consumed}
          onChange={handleChange}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="energy_consumption_per_unit_ton_actual"
          label="Energy consumption per unit Ton actual (KWH/Ton)"
          type="number"
          value={furnaceDetails.energy_consumption_per_unit_ton_actual}
          onChange={handleChange}
        />
      </div>
      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="energy_consumption_per_unit_ton_calculated"
          label="Energy consumption per unit Ton calculated KWH/Ton)"
          type="number"
          value={energyConsumption}
          onChange={handleChange}
        />
      </div>
    </div>
  )
}

export default FurnaceDetails
