import React, { useState } from 'react'
import { Tabs, Tab } from '@mui/material'
import Customers from './Customers'
import Furnaces from './Furnaces'
import Users from './Users'

const Settings = () => {
  const [selectedTab, setSelectedTab] = useState(0)

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  const handleButtonClick = () => {
    alert('Button clicked!')
  }

  return (
    <div>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        left
        indicatorColor="primary" // Change indicator color to primary
        TabIndicatorProps={{
          // Customize the indicator style
          style: { width: '150px' }, // Increase the width of the indicator line
        }}
      >
        <Tab label="Customers" />
        <Tab label="Users" />
        <Tab label="Furnaces" />
      </Tabs>
      <div>
        <div>
          {selectedTab === 0 && <Customers />}
          {selectedTab === 1 && <Users />}
          {selectedTab === 2 && <Furnaces />}
        </div>
      </div>
    </div>
  )
}

export default Settings
