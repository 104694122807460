import React, { useState, useEffect } from 'react'
import TextBox from '../../input/TextBox'
import Helper from '../../services/Helper'
import API from '../../services/API'
import axios from 'axios'
import { general_details } from '../../../recoil/atoms'
import { useRecoilState } from 'recoil'
import { v4 as uuidv4 } from 'uuid'
import CustomAutocomplete from '../../input/CustomAutocomplete'

const GeneralDetails = () => {
  const [compaignNo, setCompaignNo] = useState([])
  const [liningPatchingNo, setLiningPatchingNo] = useState([])
  const [generalDetails, setGeneralDetails] = useRecoilState(general_details)
  const [furnaces, setFurnaces] = useState([])
  console.log(generalDetails, 'generalDetails')
  const dataFile = localStorage.getItem('dataFileResponse')
  const dataFileResponse = JSON.parse(dataFile)
  const access_token = localStorage.getItem('accesstoken')
  const [isEdit, setIsEdit] = useState(localStorage.getItem('Trial') === 'Edit')
  /////////////offline//////////
  const [isOnline, setIsOnline] = useState(window.navigator.onLine)
  const [db, setDb] = useState(null)

  function getAbbreviatedLiningType(liningType) {
    if (!liningType) return '' // Check if liningType is undefined or empty
    let abbreviated = ''
    liningType.split(' ').forEach((word) => {
      abbreviated += word.charAt(0).toUpperCase()
    })
    return abbreviated
  }

  const lorp_id =
    (generalDetails.furnace_no ? generalDetails.furnace_no : '') +
    '-' +
    (generalDetails.crucible_no ? generalDetails.crucible_no : '') +
    '-' +
    (generalDetails.campaign_no ? generalDetails.campaign_no : '') +
    '-' +
    getAbbreviatedLiningType(generalDetails.lining_patch_no)

  useEffect(() => {
    const handleOnline = () => {
      if (!isOnline) {
        setIsOnline(true)
        localStorage.setItem('onlinestate', 1)
        window.location.reload()
      }
    }

    const handleOffline = () => {
      localStorage.setItem('onlinestate', 0)
      // setIsOnlines(false)
      setIsOnline(false)
      // navigate('/reports')
    }

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [isOnline])

  const dbName = 'pcr'
  const customerName = 'customers'
  const furnacesName = 'furnaces'
  const templateName = 'template'
  const segmentName = 'segment'
  const datafileName = 'datafile'
  const dailytrialName = 'daily_trial'
  const nrmName = 'nrm_performance'
  const erosionName = 'erosion_profile'
  const dataCollectionName = 'data_collection'
  const costSheetName = 'cost_sheet'
  const LiningName = 'lining_patching_no'

  useEffect(() => {
    if (!isOnline) {
      const request = indexedDB.open(dbName, 1)

      request.onupgradeneeded = (event) => {
        const database = event.target.result
        database.createObjectStore(customerName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(furnacesName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(templateName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(segmentName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(datafileName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(dailytrialName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(nrmName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(erosionName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(dataCollectionName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(costSheetName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(LiningName, {
          keyPath: 'id',
          autoIncrement: true,
        })
      }

      request.onsuccess = (event) => {
        const database = event.target.result
        const transaction = database.transaction(
          [
            'customers',
            'furnaces',
            'template',
            'segment',
            'datafile',
            'daily_trial',
            'nrm_performance',
            'erosion_profile',
            'data_collection',
            'cost_sheet',
            'lining_patching_no',
          ],
          'readwrite'
        )

        setDb(database)
      }

      request.onerror = (event) => {
        console.error('Error opening IndexedDB', event)
      }
    }
  }, [isOnline])

  useEffect(() => {
    if (db) {
      fetchFurnaceData()
      fetchData()
      fetchLiningData()
    }
  }, [db])

  useEffect(() => {
    if (isOnline) {
      fetchFurnaceData()
      fetchData()
      fetchLiningData()
    }
  }, [isOnline])

  const fetchFurnaceData = async () => {
    if (isOnline) {
      try {
        const customer_id = dataFileResponse.data.customer_id
        console.log('customer_id', customer_id)

        const response = await axios.get(API.GET_FURNACES, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        console.log('furnace response', response)
        //   const matchingFurnace = response.data.find(furnace => furnace.assign_customer_id === value);
        const matchingFurnaces = response.data.filter(
          (furnace) => furnace.assign_customer_id === customer_id
        )

        if (matchingFurnaces) {
          console.log('Matching Furnace:', matchingFurnaces)
          const matchingFurnaceArr = matchingFurnaces
          setFurnaces(matchingFurnaceArr)
          // Do something with the matching furnace object
        } else {
          setFurnaces([])
          console.log('No matching furnace found')
        }
        //   setFurnaces(response.data);
      } catch (error) {
        console.error('Error fetching users:', error)
      }
    } else {
      console.log('db', db)
      const transaction1 = db.transaction(datafileName, 'readonly')
      const objectStore1 = transaction1.objectStore(datafileName)
      const datafile_id = localStorage.getItem('datafile_id')
      console.log('datafile_id', typeof datafile_id)
      const datafile_id_int = parseInt(datafile_id, 10)
      const getAllRequest1 = objectStore1.get(datafile_id_int)
      getAllRequest1.onsuccess = () => {
        const allData = getAllRequest1.result
        console.log('allData123', allData)
        // console.log('allData', allData.customer_id);
        const customer_id = allData.customer_id

        const transaction = db.transaction(furnacesName, 'readonly')
        const objectStore = transaction.objectStore(furnacesName)
        const getAllRequest = objectStore.getAll()
        getAllRequest.onsuccess = () => {
          const allData = getAllRequest.result
          const matchingFurnaces = allData.filter(
            (furnace) => furnace.assign_customer_id === customer_id
          )
          setFurnaces(matchingFurnaces)
        }

        getAllRequest.onerror = (event) => {
          setFurnaces([])
          console.error(
            'Error getting data from the area table in IndexedDB',
            event
          )
        }
      }
    }
  }

  const handleChange = (name, value) => {
    console.log('nammeee', name)
    console.log('valuee', value)
    const TrialName = localStorage.getItem('Trial')
    if (TrialName === 'new' || TrialName === 'add') {
      const uniqueId = uuidv4() // Replace this with your unique ID generation logic
      setGeneralDetails((prevState) => ({
        ...prevState,
        [name]: value,
        id: uniqueId,
        isdelete: false,
        lorp_id: lorp_id,
      }))
    } else {
      setGeneralDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const fetchData = async () => {
    try {
      const campaignNumbers = await Helper.getCompaignNumbers()
      console.log('campaignNumbers', campaignNumbers)
      setCompaignNo(campaignNumbers)
    } catch (error) {
      console.error('Error fetching campaign numbers:', error)
    }
  }

  const fetchLiningData = async () => {
    if (isOnline) {
      try {
        const response = await axios.get(API.LINING_PATCHING_NO, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        console.log('RESSSS', response)
        setLiningPatchingNo(response.data)
      } catch (error) {
        console.error('Error fetching lining:', error)
      }
    } else {
      const transaction = db.transaction(LiningName, 'readonly')
      const objectStore = transaction.objectStore(LiningName)
      const getAllRequest = objectStore.getAll()
      getAllRequest.onsuccess = () => {
        const allData = getAllRequest.result
        console.log('allDataLiningPatchingNo', allData)
        setLiningPatchingNo(allData)
      }

      getAllRequest.onerror = (event) => {
        setLiningPatchingNo([])
        console.error(
          'Error getting data from the area table in IndexedDB',
          event
        )
      }
    }
  }

  // Empty dependency array ensures this effect runs only once after the initial render

  return (
    <div style={{ width: '100%' }}>
      <div style={{ marginTop: '15px', marginRight: '15px' }}>
        <CustomAutocomplete
          name="furnace_no"
          isLabel={true}
          labelValue="Furnace No."
          options={furnaces.map((option) => option.furnace_number)}
          value={generalDetails.furnace_no} // Assuming role is one of the fields in your form
          onChange={(event, value) => handleChange('furnace_no', value)}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <CustomAutocomplete
          name="crucible_no"
          labelValue="Crucible No."
          isLabel={true}
          options={Helper.CrucibleNo.map((option) => option.name)}
          value={generalDetails.crucible_no} // Assuming role is one of the fields in your form
          onChange={(event, value) => handleChange('crucible_no', value)}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <CustomAutocomplete
          name="campaign_no"
          isLabel={true}
          labelValue="Campaign No"
          options={compaignNo.map((option) => option.name)}
          value={generalDetails.campaign_no}
          onChange={(event, value) => handleChange('campaign_no', value)}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <CustomAutocomplete
          name="lining_patch_no"
          isLabel={true}
          labelValue="Lining/Patching No."
          options={liningPatchingNo.map((option) => option.name)}
          value={generalDetails.lining_patch_no}
          onChange={(event, value) => handleChange('lining_patch_no', value)}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="heat_no"
          label="Heat No. (Nos)"
          type="number"
          value={generalDetails.heat_no || ''}
          onChange={(event) => handleChange('heat_no', event.target.value)}
        />
      </div>

      <TextBox
        label="Date"
        name="date"
        type="date"
        value={generalDetails.date ? generalDetails.date : ''}
        onChange={(event) => handleChange('date', event.target.value)}
        showCalendarIcon={true} // Add this prop
      />

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="customer_ref_no"
          label="Customer Ref No."
          isString={true}
          value={generalDetails.customer_ref_no ? generalDetails.customer_ref_no : ''}
          onChange={(event) =>
            handleChange('customer_ref_no', event.target.value)
          }
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="grade"
          label="Grade"
          isString={true}
          value={generalDetails.grade ? generalDetails.grade : ''}
          onChange={(event) => handleChange('grade', event.target.value)}
        />
      </div>
    </div>
  )
}
export default GeneralDetails
