import { selector } from 'recoil'
import {
  other_parameters,
  alloying_addition,
  carbon,
  furnace_details,
  charge_mix_details,
  height_bc_0,
  height_bc_10,
  height_bc_20,
  height_bc_30,
  height_bc_40,
  height_bc_50,
  height_bc_60,
  height_bc_70,
  height_bc_80,
  height_bc_90,
  height_bc_100,
  height_ac_0,
  height_ac_10,
  height_ac_20,
  height_ac_30,
  height_ac_40,
  height_ac_50,
  height_ac_60,
  height_ac_70,
  height_ac_80,
  height_ac_90,
  height_ac_100,
  nrm_usage_details,
  dimensional_details,
  new_lining_details_nrm,
  new_lining_details,
  side_lining_details_nrm,
  side_lining_details,
  cost_calculation,
  repairing_details,
  repairing_details_nrm,
  patching_details,
  patching_details_nrm,
  cost_calculation_nrm,
  labor_cost,
  labor_cost_nrm,
  former_cost,
  former_cost_nrm,
  increased_productivity_nrm,
  increased_productivity,
  savings_energy_nrm,
  yield_improvement_nrm,
  savings_alloy_nrm,
  savings_tangible_benefit_nrm,
  sintering_heats_nrm,
  sintering_heats,
} from './atoms'

// Define selectors
//Daily trial monitoring

export const energy_conspt = selector({
  key: 'energy_conspt',
  get: ({ get }) => {
    const furnaceDet = get(furnace_details)
    return (
      Number(furnaceDet.total_power_consumed) /
      Number(furnaceDet.tapped_tonnage)
    ).toFixed(2)
  },
})

export const totalMnAddedSelector = selector({
  key: 'totalMnAddedSelector',
  get: ({ get }) => {
    const otherParams = get(other_parameters)
    const alloyingAddition = get(alloying_addition)
    console.log(otherParams.met_coke, 'totalMnAddedSelector')
    return (
      Number(0.6 * alloyingAddition.si_mn) + Number(0.7 * alloyingAddition.feMn)
    ).toFixed(2)
  },
})

export const cpcPerTonSelector = selector({
  key: 'cpcPerTonSelector',
  get: ({ get }) => {
    const chargeMixDetails = get(charge_mix_details)
    const carbonData = get(carbon)
    return (
      Number(carbonData.cpc) / Number(chargeMixDetails.total_charge)
    ).toFixed(2)
  },
})

export const metCokePerTonSelector = selector({
  key: 'metCokePerTonSelector',
  get: ({ get }) => {
    const chargeMixDetails = get(charge_mix_details)
    const carbonData = get(carbon)
    return (
      Number(carbonData.met_coke) / Number(chargeMixDetails.total_charge)
    ).toFixed(2)
  },
})

export const SiMnPerTonSelector = selector({
  key: 'SiMnPerTonSelector',
  get: ({ get }) => {
    const chargeMixDetails = get(charge_mix_details)
    const alloyingAddition = get(alloying_addition)
    return (
      Number(alloyingAddition.si_mn) / Number(chargeMixDetails.total_charge)
    ).toFixed(2)
  },
})

export const FeMnPerTonSelector = selector({
  key: 'FeMnPerTonSelector',
  get: ({ get }) => {
    const chargeMixDetails = get(charge_mix_details)
    const alloyingAddition = get(alloying_addition)
    return (
      Number(alloyingAddition.feMn) / Number(chargeMixDetails.total_charge)
    ).toFixed(2)
  },
})

export const feSiPerTonSelector = selector({
  key: 'feSiPerTonSelector',
  get: ({ get }) => {
    const chargeMixDetails = get(charge_mix_details)
    const alloyingAddition = get(alloying_addition)
    return (
      Number(alloyingAddition.feSi) / Number(chargeMixDetails.total_charge)
    ).toFixed(2)
  },
})

export const feCrPerTonSelector = selector({
  key: 'feCrPerTonSelector',
  get: ({ get }) => {
    const chargeMixDetails = get(charge_mix_details)
    const alloyingAddition = get(alloying_addition)
    return (
      Number(alloyingAddition.feCr) / Number(chargeMixDetails.total_charge)
    ).toFixed(2)
  },
})

export const MnDifferenceSelector = selector({
  key: 'MnDifferenceSelector',
  get: ({ get }) => {
    const alloyingAddition = get(alloying_addition)
    return (
      Number(alloyingAddition.mn_after_alloy_addition) -
      Number(alloyingAddition.mn_before_alloy_addition)
    ).toFixed(2)
  },
})

export const CrDifferenceSelector = selector({
  key: 'CrDifferenceSelector',
  get: ({ get }) => {
    const alloyingAddition = get(alloying_addition)
    return (
      Number(alloyingAddition.cr_after_alloy_addition) -
      Number(alloyingAddition.cr_before_alloy_addition)
    ).toFixed(2)
  },
})

export const MnRecoverySelector = selector({
  key: 'MnRecoverySelector',
  get: ({ get }) => {
    const alloyingAddition = get(alloying_addition)
    const furnaceDetails = get(furnace_details)
    const totalMnAdded = get(totalMnAddedSelector)
    return (
      (Number(furnaceDetails.tapped_tonnage) *
        Number(alloyingAddition.cr_after_alloy_addition) -
        Number(alloyingAddition.cr_before_alloy_addition)) /
      totalMnAdded
    ).toFixed(2)
  },
})

export const heatDurationCalculated = selector({
  key: 'heatDurationCalculated',
  get: ({ get }) => {
    const furnaceDetails = get(furnace_details)
    console.log(furnaceDetails, 'furnaceDetails123')
    return furnace_details.heat_tap_time < furnaceDetails.heat_on_time
      ? furnaceDetails.heat_tap_time + 24 - furnaceDetails.heat_on_time
      : furnaceDetails.heat_tap_time - furnaceDetails.heat_on_time
  },
})

// dimensional details

export const initialLiningHeight = selector({
  key: 'initialLiningHeight',
  get: ({ get }) => {
    const dimensionalDetails = get(dimensional_details)
    const initLiningHt =
      Number(dimensionalDetails.fce_height) -
      Number(dimensionalDetails.bottom_thickness)
    const liningHeightAE =
      Number(dimensionalDetails.fce_height) -
      Number(dimensionalDetails.bottom_thickness) +
      Number(dimensionalDetails.bottom_erosion)

    return {
      initLiningHt,
      liningHeightAE,
    }
  },
})

// erosionFile NrmUsage
export const tot_NRM_Consumption = selector({
  key: 'tot_NRM_Consumption',
  get: ({ get }) => {
    const nrmUsage = get(nrm_usage_details)
    console.log(nrmUsage, 'nrmUsage')
    const tot_Consumpt =
      Number(nrmUsage.conspt_bottom_product) +
      Number(nrmUsage.conspt_sidewall_1) +
      Number(nrmUsage.conspt_sidewall_2) +
      Number(nrmUsage.conspt_locking_product) +
      Number(nrmUsage.conspt_spout_launder_product)
    console.log(tot_Consumpt, 'totConsumpt')
    return {
      tot_Consumpt,
    }
  },
})

// before chipping calculation

export const beforeChipping_0 = selector({
  key: 'beforeChipping_0',
  get: ({ get }) => {
    const BC_0 = get(height_bc_0)
    const spout_back_0 =
      (Number(BC_0.coil_coat_diameter) - Number(BC_0.bc_spout_back_erosion)) / 2
    const left_right_0 =
      (Number(BC_0.coil_coat_diameter) - Number(BC_0.bc_left_right_erosion)) / 2
    return {
      spout_back_0,
      left_right_0,
    }
  },
})

export const beforeChipping_10 = selector({
  key: 'beforeChipping_10',
  get: ({ get }) => {
    const BC_10 = get(height_bc_10)
    const spout_back_10 =
      (Number(BC_10.coil_coat_diameter) - Number(BC_10.bc_spout_back_erosion)) /
      2
    const left_right_10 =
      (Number(BC_10.coil_coat_diameter) - Number(BC_10.bc_left_right_erosion)) /
      2
    return {
      spout_back_10,
      left_right_10,
    }
  },
})

export const beforeChipping_20 = selector({
  key: 'beforeChipping_20',
  get: ({ get }) => {
    const BC_20 = get(height_bc_20)
    const spout_back_20 =
      (Number(BC_20.coil_coat_diameter) - Number(BC_20.bc_spout_back_erosion)) /
      2
    const left_right_20 =
      (Number(BC_20.coil_coat_diameter) - Number(BC_20.bc_left_right_erosion)) /
      2
    return {
      spout_back_20,
      left_right_20,
    }
  },
})

export const beforeChipping_30 = selector({
  key: 'beforeChipping_30',
  get: ({ get }) => {
    const BC_30 = get(height_bc_30)
    const spout_back_30 =
      (Number(BC_30.coil_coat_diameter) - Number(BC_30.bc_spout_back_erosion)) /
      2
    const left_right_30 =
      (Number(BC_30.coil_coat_diameter) - Number(BC_30.bc_left_right_erosion)) /
      2
    return {
      spout_back_30,
      left_right_30,
    }
  },
})

export const beforeChipping_40 = selector({
  key: 'beforeChipping_40',
  get: ({ get }) => {
    const BC_40 = get(height_bc_40)
    const spout_back_40 =
      (Number(BC_40.coil_coat_diameter) - Number(BC_40.bc_spout_back_erosion)) /
      2
    const left_right_40 =
      (Number(BC_40.coil_coat_diameter) - Number(BC_40.bc_left_right_erosion)) /
      2
    return {
      spout_back_40,
      left_right_40,
    }
  },
})

export const beforeChipping_50 = selector({
  key: 'beforeChipping_50',
  get: ({ get }) => {
    const BC_50 = get(height_bc_50)
    const spout_back_50 =
      (Number(BC_50.coil_coat_diameter) - Number(BC_50.bc_spout_back_erosion)) /
      2
    const left_right_50 =
      (Number(BC_50.coil_coat_diameter) - Number(BC_50.bc_left_right_erosion)) /
      2
    return {
      spout_back_50,
      left_right_50,
    }
  },
})

export const beforeChipping_60 = selector({
  key: 'beforeChipping_60',
  get: ({ get }) => {
    const BC_60 = get(height_bc_60)
    const spout_back_60 =
      (Number(BC_60.coil_coat_diameter) - Number(BC_60.bc_spout_back_erosion)) /
      2
    const left_right_60 =
      (Number(BC_60.coil_coat_diameter) - Number(BC_60.bc_left_right_erosion)) /
      2
    return {
      spout_back_60,
      left_right_60,
    }
  },
})

export const beforeChipping_70 = selector({
  key: 'beforeChipping_70',
  get: ({ get }) => {
    const BC_70 = get(height_bc_70)
    const spout_back_70 =
      (Number(BC_70.coil_coat_diameter) - Number(BC_70.bc_spout_back_erosion)) /
      2
    const left_right_70 =
      (Number(BC_70.coil_coat_diameter) - Number(BC_70.bc_left_right_erosion)) /
      2
    return {
      spout_back_70,
      left_right_70,
    }
  },
})

export const beforeChipping_80 = selector({
  key: 'beforeChipping_80',
  get: ({ get }) => {
    const BC_80 = get(height_bc_80)
    const spout_back_80 =
      (Number(BC_80.coil_coat_diameter) - Number(BC_80.bc_spout_back_erosion)) /
      2
    const left_right_80 =
      (Number(BC_80.coil_coat_diameter) - Number(BC_80.bc_left_right_erosion)) /
      2
    return {
      spout_back_80,
      left_right_80,
    }
  },
})

export const beforeChipping_90 = selector({
  key: 'beforeChipping_90',
  get: ({ get }) => {
    const BC_90 = get(height_bc_90)
    const spout_back_90 =
      (Number(BC_90.coil_coat_diameter) - Number(BC_90.bc_spout_back_erosion)) /
      2
    const left_right_90 =
      (Number(BC_90.coil_coat_diameter) - Number(BC_90.bc_left_right_erosion)) /
      2
    return {
      spout_back_90,
      left_right_90,
    }
  },
})

export const beforeChipping_100 = selector({
  key: 'beforeChipping_100',
  get: ({ get }) => {
    const BC_100 = get(height_bc_100)
    const spout_back_100 =
      (Number(BC_100.coil_coat_diameter) -
        Number(BC_100.bc_spout_back_erosion)) /
      2
    const left_right_100 =
      (Number(BC_100.coil_coat_diameter) -
        Number(BC_100.bc_left_right_erosion)) /
      2
    return {
      spout_back_100,
      left_right_100,
    }
  },
})

// after chipping calculation

export const afterChipping_0 = selector({
  key: 'afterChipping_0',
  get: ({ get }) => {
    const AC_0 = get(height_ac_0)
    const BC_0 = get(height_bc_0)
    const spout_back_0 =
      (Number(BC_0.coil_coat_diameter) - Number(AC_0.ac_spout_back_erosion)) / 2
    const left_right_0 =
      (Number(AC_0.coil_coat_diameter) - Number(AC_0.ac_left_right_erosion)) / 2
    return {
      spout_back_0,
      left_right_0,
    }
  },
})

// Selector for height 10
export const afterChipping_10 = selector({
  key: 'afterChipping_10',
  get: ({ get }) => {
    const AC_10 = get(height_ac_10)
    const BC_10 = get(height_bc_10)
    const spout_back_10 =
      (Number(BC_10.coil_coat_diameter) - Number(AC_10.ac_spout_back_erosion)) /
      2
    const left_right_10 =
      (Number(AC_10.coil_coat_diameter) - Number(AC_10.ac_left_right_erosion)) /
      2
    return {
      spout_back_10,
      left_right_10,
    }
  },
})

// Selector for height 20
export const afterChipping_20 = selector({
  key: 'afterChipping_20',
  get: ({ get }) => {
    const AC_20 = get(height_ac_20)
    const BC_20 = get(height_bc_20)
    const spout_back_20 =
      (Number(BC_20.coil_coat_diameter) - Number(AC_20.ac_spout_back_erosion)) /
      2
    const left_right_20 =
      (Number(AC_20.coil_coat_diameter) - Number(AC_20.ac_left_right_erosion)) /
      2
    return {
      spout_back_20,
      left_right_20,
    }
  },
})

// Repeat for other heights...

// Selector for height 30
export const afterChipping_30 = selector({
  key: 'afterChipping_30',
  get: ({ get }) => {
    const AC_30 = get(height_ac_30)
    const BC_30 = get(height_bc_30)
    const spout_back_30 =
      (Number(BC_30.coil_coat_diameter) - Number(AC_30.ac_spout_back_erosion)) /
      2
    const left_right_30 =
      (Number(AC_30.coil_coat_diameter) - Number(AC_30.ac_left_right_erosion)) /
      2
    return {
      spout_back_30,
      left_right_30,
    }
  },
})

// Selector for height 40
export const afterChipping_40 = selector({
  key: 'afterChipping_40',
  get: ({ get }) => {
    const AC_40 = get(height_ac_40)
    const BC_40 = get(height_bc_40)
    const spout_back_40 =
      (Number(BC_40.coil_coat_diameter) - Number(AC_40.ac_spout_back_erosion)) /
      2
    const left_right_40 =
      (Number(AC_40.coil_coat_diameter) - Number(AC_40.ac_left_right_erosion)) /
      2
    return {
      spout_back_40,
      left_right_40,
    }
  },
})

// Selector for height 50
export const afterChipping_50 = selector({
  key: 'afterChipping_50',
  get: ({ get }) => {
    const AC_50 = get(height_ac_50)
    const BC_50 = get(height_bc_50)
    const spout_back_50 =
      (Number(BC_50.coil_coat_diameter) - Number(AC_50.ac_spout_back_erosion)) /
      2
    const left_right_50 =
      (Number(AC_50.coil_coat_diameter) - Number(AC_50.ac_left_right_erosion)) /
      2
    return {
      spout_back_50,
      left_right_50,
    }
  },
})

// Selector for height 60
export const afterChipping_60 = selector({
  key: 'afterChipping_60',
  get: ({ get }) => {
    const AC_60 = get(height_ac_60)
    const BC_60 = get(height_bc_60)
    const spout_back_60 =
      (Number(BC_60.coil_coat_diameter) - Number(AC_60.ac_spout_back_erosion)) /
      2
    const left_right_60 =
      (Number(AC_60.coil_coat_diameter) - Number(AC_60.ac_left_right_erosion)) /
      2
    return {
      spout_back_60,
      left_right_60,
    }
  },
})

// Selector for height 70
export const afterChipping_70 = selector({
  key: 'afterChipping_70',
  get: ({ get }) => {
    const AC_70 = get(height_ac_70)
    const BC_70 = get(height_bc_70)
    const spout_back_70 =
      (Number(BC_70.coil_coat_diameter) - Number(AC_70.ac_spout_back_erosion)) /
      2
    const left_right_70 =
      (Number(AC_70.coil_coat_diameter) - Number(AC_70.ac_left_right_erosion)) /
      2
    return {
      spout_back_70,
      left_right_70,
    }
  },
})

// Selector for height 80
export const afterChipping_80 = selector({
  key: 'afterChipping_80',
  get: ({ get }) => {
    const AC_80 = get(height_ac_80)
    const BC_80 = get(height_bc_80)
    const spout_back_80 =
      (Number(BC_80.coil_coat_diameter) - Number(AC_80.ac_spout_back_erosion)) /
      2
    const left_right_80 =
      (Number(AC_80.coil_coat_diameter) - Number(AC_80.ac_left_right_erosion)) /
      2
    return {
      spout_back_80,
      left_right_80,
    }
  },
})

// Selector for height 90
export const afterChipping_90 = selector({
  key: 'afterChipping_90',
  get: ({ get }) => {
    const AC_90 = get(height_ac_90)
    const BC_90 = get(height_bc_90)
    const spout_back_90 =
      (Number(BC_90.coil_coat_diameter) - Number(AC_90.ac_spout_back_erosion)) /
      2
    const left_right_90 =
      (Number(AC_90.coil_coat_diameter) - Number(AC_90.ac_left_right_erosion)) /
      2
    return {
      spout_back_90,
      left_right_90,
    }
  },
})

// Selector for height 100
export const afterChipping_100 = selector({
  key: 'afterChipping_100',
  get: ({ get }) => {
    const AC_100 = get(height_ac_100)
    const BC_100 = get(height_bc_100)
    const spout_back_100 =
      (Number(BC_100.coil_coat_diameter) -
        Number(AC_100.ac_spout_back_erosion)) /
      2
    const left_right_100 =
      (Number(AC_100.coil_coat_diameter) -
        Number(AC_100.ac_left_right_erosion)) /
      2
    return {
      spout_back_100,
      left_right_100,
    }
  },
})

//cost calculation

export const costCalculation = selector({
  key: 'costCalculation',
  get: ({ get }) => {
    const newLining = get(new_lining_details)
    const newLiningNRM = get(new_lining_details_nrm)
    const sideLining = get(side_lining_details)
    const sideLiningNRM = get(side_lining_details_nrm)
    const patchingDetails = get(patching_details)
    const patchingDetailsNRM = get(patching_details_nrm)
    const repairDetails = get(repairing_details)
    const repairDetailsNRM = get(repairing_details_nrm)
    const costCalculation = get(repairing_details_nrm)

    // basic

    let totalHeatSize = 0
    let numberOfItems = 0

    // Calculate totalHeatSize and numberOfItems
    if (
      newLining &&
      newLining.avg_heat_size !== undefined &&
      newLining.avg_heat_size !== ''
    ) {
      totalHeatSize += Number(newLining.avg_heat_size)
      numberOfItems++
    }

    if (
      sideLining &&
      sideLining.avg_heat_size !== undefined &&
      sideLining.avg_heat_size !== ''
    ) {
      totalHeatSize += Number(sideLining.avg_heat_size)
      numberOfItems++
    }

    if (
      patchingDetails &&
      patchingDetails.avg_heat_size_per_patching !== undefined &&
      patchingDetails.avg_heat_size_per_patching !== ''
    ) {
      totalHeatSize += Number(patchingDetails.avg_heat_size_per_patching)
      numberOfItems++
    }

    let averageHeatSize
    if (numberOfItems > 0) {
      averageHeatSize = (totalHeatSize / numberOfItems).toFixed(2)
    } else {
      console.log('No valid heat size data available to calculate the average.')
    }

    let totalLifeInCampaign = 0
    let numberOftotalLifeItems = 0

    // Calculate totalLifeInCampaign and numberOftotalLifeItems
    if (newLining && newLining.average_new_lining_life !== '') {
      totalLifeInCampaign += Number(newLining.average_new_lining_life)
      numberOftotalLifeItems++
    }

    if (sideLining && sideLining.average_side_lining_life !== '') {
      totalLifeInCampaign += Number(sideLining.average_side_lining_life)
      numberOftotalLifeItems++
    }

    if (patchingDetails && patchingDetails.avg_heat_size_per_patching !== '') {
      totalLifeInCampaign += Number(patchingDetails.avg_heat_size_per_patching)
      numberOftotalLifeItems++
    }

    let averageLife
    if (numberOftotalLifeItems > 0) {
      averageLife = (totalLifeInCampaign / numberOftotalLifeItems).toFixed(2)
    } else {
      console.log('No valid life data available to calculate the average life.')
    }

    const totNoOfCycle =
      ((newLining && Number(newLining.no_of_new_lining)) || 0) +
      ((sideLining && Number(sideLining.no_of_side_lining)) || 0) +
      ((patchingDetails && Number(patchingDetails.no_of_patching)) || 0)

    const totNoOfHeats =
      ((newLining && Number(newLining.average_new_lining_life)) || 0) *
        ((newLining && Number(newLining.no_of_new_lining)) || 0) +
      ((sideLining && Number(sideLining.average_side_lining_life)) || 0) *
        ((sideLining && Number(sideLining.no_of_side_lining)) || 0) +
      ((patchingDetails &&
        Number(patchingDetails.average_patching_lining_life)) ||
        0) *
        ((patchingDetails && Number(patchingDetails.no_of_patching)) || 0)

    console.log(totNoOfHeats, 'totNoOfHeats')

    const totProduction =
      Number(totNoOfHeats) && Number(averageHeatSize)
        ? (Number(totNoOfHeats) * Number(averageHeatSize)).toFixed(2)
        : '0.00'

    const rammingMaterialCost_1 =
      costCalculation.ramming_material_landed_cost_1 *
      ((Number(newLining.no_of_new_lining) || 0) *
        (Number(newLining.qty_new_lining_1) || 0) +
        (Number(sideLining.no_of_side_lining) || 0) *
          (Number(sideLining.qty_side_lining_1) || 0) +
        (Number(patchingDetails.no_of_patching) || 0) *
          (Number(patchingDetails.qty_patching_1) || 0) +
        (Number(repairDetails.qty_repairing_1) || 0))
    console.log(rammingMaterialCost_1, 'ramming_material_landed_cost_1')

    const toppingMaterialCost_2 =
      costCalculation.topping_material_2 *
      ((Number(newLining.no_of_new_lining) || 0) *
        (Number(newLining.qty_spout_and_top_2) || 0) +
        (Number(sideLining.no_of_side_lining) || 0) *
          (Number(sideLining.qty_spout_and_top_2) || 0) +
        (Number(patchingDetails.no_of_patching) || 0) *
          (Number(patchingDetails.qty_spout_and_top_2) || 0) +
        (Number(repairDetails.qty_spout_and_top_2) || 0))
    console.log(toppingMaterialCost_2, 'toppingMaterialCost_2')

    const toppingMaterialCost_3 =
      costCalculation.topping_material_3 *
      ((Number(newLining.no_of_new_lining) || 0) *
        (Number(newLining.qty_spout_and_top_3) || 0) +
        (Number(sideLining.no_of_side_lining) || 0) *
          (Number(sideLining.qty_spout_and_top_3) || 0) +
        (Number(patchingDetails.no_of_patching) || 0) *
          (Number(patchingDetails.qty_spout_and_top_3) || 0) +
        (Number(repairDetails.qty_spout_and_top_3) || 0))
    console.log(toppingMaterialCost_3, 'toppingMaterialCost_3')

    const totCostCampaignRefractory = (
      Number(rammingMaterialCost_1) +
      Number(toppingMaterialCost_2) +
      Number(toppingMaterialCost_3)
    ).toFixed(2)
    console.log(totCostCampaignRefractory, 'totCostCampaignRefractory')

    const totRefractoryConsumption =
      (Number(newLining.no_of_new_lining) || 0) *
        (Number(newLining.qty_new_lining_1) || 0) +
      (Number(sideLining.no_of_side_lining) || 0) *
        (Number(sideLining.qty_side_lining_1) || 0) +
      (Number(patchingDetails.no_of_patching) || 0) *
        (Number(patchingDetails.qty_patching_1) || 0) +
      (Number(repairDetails.qty_repairing_1) || 0) +
      ((Number(newLining.no_of_new_lining) || 0) *
        (Number(newLining.qty_spout_and_top_2) || 0) +
        (Number(sideLining.no_of_side_lining) || 0) *
          (Number(sideLining.qty_spout_and_top_2) || 0) +
        (Number(patchingDetails.no_of_patching) || 0) *
          (Number(patchingDetails.qty_spout_and_top_2) || 0) +
        (Number(repairDetails.qty_spout_and_top_2) || 0)) +
      ((Number(newLining.no_of_new_lining) || 0) *
        (Number(newLining.qty_spout_and_top_3) || 0) +
        (Number(sideLining.no_of_side_lining) || 0) *
          (Number(sideLining.qty_spout_and_top_3) || 0) +
        (Number(patchingDetails.no_of_patching) || 0) *
          (Number(patchingDetails.qty_spout_and_top_3) || 0) +
        (Number(repairDetails.qty_spout_and_top_3) || 0))

    console.log(totRefractoryConsumption, 'totRefractoryConsumption')

    const SpecificRefractoryConsumpt =
      Number(totRefractoryConsumption) && Number(totProduction)
        ? (
            (Number(totRefractoryConsumption) * 1000) /
            Number(totProduction)
          ).toFixed(2)
        : '0.00'
    console.log(SpecificRefractoryConsumpt, 'SpecificRefractoryConsumpt')

    const costOfrefractory =
      Number(totCostCampaignRefractory) && Number(totProduction)
        ? (Number(totCostCampaignRefractory) / Number(totProduction)).toFixed(2)
        : '0.00'
    console.log(costOfrefractory, 'costOfrefractory')

    return {
      averageHeatSize,
      averageLife,
      totNoOfCycle,
      totNoOfHeats,
      totProduction,
      rammingMaterialCost_1,
      toppingMaterialCost_2,
      toppingMaterialCost_3,
      totCostCampaignRefractory,
      totRefractoryConsumption,
      SpecificRefractoryConsumpt,
      costOfrefractory,
    }
  },
})

// cost calculaton Nrm

export const costCalculationNRM = selector({
  key: 'costCalculationNRM',
  get: ({ get }) => {
    const newLining = get(new_lining_details)
    const newLiningNRM = get(new_lining_details_nrm)
    const sideLining = get(side_lining_details)
    const sideLiningNRM = get(side_lining_details_nrm)
    const patchingDetails = get(patching_details)
    const patchingDetailsNRM = get(patching_details_nrm)
    const repairDetails = get(repairing_details)
    const repairDetailsNRM = get(repairing_details_nrm)
    const costCalculationNRM = get(cost_calculation_nrm)
    // const costOfrefractoryBasic = get(costCalculation.costOfrefractory);
    // basic

    let totalHeatSizeNRM = 0
    let numberOfItemsNRM = 0

    if (newLiningNRM && newLiningNRM.avg_heat_size !== '') {
      totalHeatSizeNRM += Number(newLiningNRM.avg_heat_size)
      numberOfItemsNRM++
    }

    if (sideLiningNRM && sideLiningNRM.avg_heat_size !== '') {
      totalHeatSizeNRM += Number(sideLiningNRM.avg_heat_size)
      numberOfItemsNRM++
    }

    if (
      patchingDetailsNRM &&
      patchingDetailsNRM.avg_heat_size_per_patching !== ''
    ) {
      totalHeatSizeNRM += Number(patchingDetailsNRM.avg_heat_size_per_patching)
      numberOfItemsNRM++
    }

    const averageHeatSizeNRM =
      totalHeatSizeNRM && numberOfItemsNRM
        ? (totalHeatSizeNRM / numberOfItemsNRM).toFixed(2)
        : '0.00'

    let totalLifeNRM = 0
    let numberOfItemstotalLifeNRM = 0

    if (newLiningNRM && newLiningNRM.average_new_lining_life !== '') {
      totalLifeNRM += Number(newLiningNRM.average_new_lining_life)
      numberOfItemstotalLifeNRM++
    }

    if (sideLiningNRM && sideLiningNRM.average_side_lining_life !== '') {
      totalLifeNRM += Number(sideLiningNRM.average_side_lining_life)
      numberOfItemstotalLifeNRM++
    }

    if (
      patchingDetailsNRM &&
      patchingDetailsNRM.average_patching_lining_life !== ''
    ) {
      totalLifeNRM += Number(patchingDetailsNRM.average_patching_lining_life)
      numberOfItemstotalLifeNRM++
    }

    const averageLifeNRM =
      Number(totalLifeNRM) && Number(numberOfItemstotalLifeNRM)
        ? (Number(totalLifeNRM) / Number(numberOfItemstotalLifeNRM)).toFixed(2)
        : '0.00'

    const totNoOfCycleNRM = (
      ((newLiningNRM && Number(newLiningNRM.no_of_new_lining)) || 0) +
      ((sideLiningNRM && Number(sideLiningNRM.no_of_side_lining)) || 0) +
      ((patchingDetailsNRM && Number(patchingDetailsNRM.no_of_patching)) || 0)
    ).toFixed(2)

    console.log(totNoOfCycleNRM, 'totNoOfCycleNRM')

    const totNoOfHeatsNRM = (
      ((newLiningNRM && Number(newLiningNRM.average_new_lining_life)) || 0) *
        ((newLiningNRM && Number(newLiningNRM.no_of_new_lining)) || 0) +
      ((sideLiningNRM && Number(sideLiningNRM.average_side_lining_life)) || 0) *
        ((sideLiningNRM && Number(sideLiningNRM.no_of_side_lining)) || 0) +
      ((patchingDetailsNRM &&
        Number(patchingDetailsNRM.average_patching_lining_life)) ||
        0) *
        ((patchingDetailsNRM && Number(patchingDetailsNRM.no_of_patching)) || 0)
    ).toFixed(2)

    console.log(totNoOfHeatsNRM, 'totNoOfHeatsNRM')

    const totProductionNRM =
      Number(totNoOfHeatsNRM) && Number(averageHeatSizeNRM)
        ? (Number(totNoOfHeatsNRM) * Number(averageHeatSizeNRM)).toFixed(2)
        : '0.00'

    const rammingMaterialCostNRM_1 = (
      costCalculationNRM.ramming_material_landed_cost_1 *
      ((Number(newLiningNRM.no_of_new_lining) || 0) *
        (Number(newLiningNRM.qty_new_lining_1) || 0) +
        (Number(sideLiningNRM.no_of_side_lining) || 0) *
          (Number(sideLiningNRM.qty_side_lining_1) || 0) +
        (Number(patchingDetailsNRM.no_of_patching) || 0) *
          (Number(patchingDetailsNRM.qty_patching_1) || 0) +
        (Number(repairDetailsNRM.qty_repairing_1) || 0))
    ).toFixed(2)

    console.log(rammingMaterialCostNRM_1, 'rammingMaterialCostNRM_1')

    const toppingMaterialCostNRM_2 = (
      costCalculationNRM.topping_material_2 *
      ((Number(newLiningNRM.no_of_new_lining) || 0) *
        (Number(newLiningNRM.qty_spout_and_top_2) || 0) +
        (Number(sideLiningNRM.no_of_side_lining) || 0) *
          (Number(sideLiningNRM.qty_spout_and_top_2) || 0) +
        (Number(patchingDetailsNRM.no_of_patching) || 0) *
          (Number(patchingDetailsNRM.qty_spout_and_top_2) || 0) +
        (Number(repairDetailsNRM.qty_spout_and_top_2) || 0))
    ).toFixed(2)
    console.log(toppingMaterialCostNRM_2, 'toppingMaterialCostNRM_2')

    const toppingMaterialCostNRM_3 = (
      costCalculationNRM.topping_material_3 *
      ((Number(newLiningNRM.no_of_new_lining) || 0) *
        (Number(newLiningNRM.qty_spout_and_top_3) || 0) +
        (Number(sideLiningNRM.no_of_side_lining) || 0) *
          (Number(sideLiningNRM.qty_spout_and_top_3) || 0) +
        (Number(patchingDetailsNRM.no_of_patching) || 0) *
          (Number(patchingDetailsNRM.qty_spout_and_top_3) || 0) +
        (Number(repairDetailsNRM.qty_spout_and_top_3) || 0))
    ).toFixed(2)
    console.log(toppingMaterialCostNRM_3, 'toppingMaterialCostNRM_3')

    const totCostCampaignRefractoryNRM =
      Number(rammingMaterialCostNRM_1) +
      Number(toppingMaterialCostNRM_3) +
      Number(toppingMaterialCostNRM_3)
    console.log(totCostCampaignRefractoryNRM, 'totCostCampaignRefractoryNRM345')

    const totRefractoryConsumptionNRM = (
      (Number(newLiningNRM.no_of_new_lining) || 0) *
        (Number(newLiningNRM.qty_new_lining_1) || 0) +
      (Number(sideLiningNRM.no_of_side_lining) || 0) *
        (Number(sideLiningNRM.qty_side_lining_1) || 0) +
      (Number(patchingDetailsNRM.no_of_patching) || 0) *
        (Number(patchingDetailsNRM.qty_patching_1) || 0) +
      (Number(repairDetailsNRM.qty_repairing_1) || 0) +
      ((Number(newLiningNRM.no_of_new_lining) || 0) *
        (Number(newLiningNRM.qty_spout_and_top_2) || 0) +
        (Number(sideLiningNRM.no_of_side_lining) || 0) *
          (Number(sideLiningNRM.qty_spout_and_top_2) || 0) +
        (Number(patchingDetailsNRM.no_of_patching) || 0) *
          (Number(patchingDetailsNRM.qty_spout_and_top_2) || 0) +
        (Number(repairDetailsNRM.qty_spout_and_top_2) || 0)) +
      ((Number(newLiningNRM.no_of_new_lining) || 0) *
        (Number(newLiningNRM.qty_spout_and_top_3) || 0) +
        (Number(sideLiningNRM.no_of_side_lining) || 0) *
          (Number(sideLiningNRM.qty_spout_and_top_3) || 0) +
        (Number(patchingDetailsNRM.no_of_patching) || 0) *
          (Number(patchingDetailsNRM.qty_spout_and_top_3) || 0) +
        (Number(repairDetailsNRM.qty_spout_and_top_3) || 0))
    ).toFixed(2)

    console.log(totRefractoryConsumptionNRM, 'totRefractoryConsumptionNRM')

    const SpecificRefractoryConsumptNRM =
      Number(totRefractoryConsumptionNRM) && Number(totProductionNRM)
        ? (
            (Number(totRefractoryConsumptionNRM) * 1000) /
            Number(totProductionNRM)
          ).toFixed(2)
        : '0.00'

    const costOfrefractoryNRM =
      Number(totCostCampaignRefractoryNRM) && Number(totProductionNRM)
        ? (
            Number(totCostCampaignRefractoryNRM) / Number(totProductionNRM)
          ).toFixed(2)
        : '0.00'
    console.log(costOfrefractoryNRM, 'costOfrefractoryNRM')

    // const increaseInCostDueToNRM = Number(costOfrefractoryNRM) && Number(costOfrefractoryBasic) ? (Number(costOfrefractoryNRM) - Number(costOfrefractoryBasic)).toFixed(2): "0.00"
    // console.log(increaseInCostDueToNRM, "increaseInCostDueToNRM")

    const relativeGainNRM = (
      Number(totCostCampaignRefractoryNRM) - Number(totProductionNRM)
    ).toFixed(2)
    console.log(relativeGainNRM)
    return {
      averageHeatSizeNRM,
      averageLifeNRM,
      totNoOfCycleNRM,
      totNoOfHeatsNRM,
      totProductionNRM,
      rammingMaterialCostNRM_1,
      toppingMaterialCostNRM_2,
      toppingMaterialCostNRM_3,
      totCostCampaignRefractoryNRM,
      totRefractoryConsumptionNRM,
      SpecificRefractoryConsumptNRM,
      costOfrefractoryNRM,
      relativeGainNRM,
    }
  },
})

// labor cost

export const laborCostCampaignSelector = selector({
  key: 'laborCostCampaignSelector',
  get: ({ get }) => {
    const newLining = get(new_lining_details)
    const sideLining = get(side_lining_details)
    const patchingDetails = get(patching_details)
    const laborCost = get(labor_cost) // assuming `laborCostState` is defined

    const laborCostCampaign =
      laborCost.labor_cost_per_nl *
      (((newLining && Number(newLining.no_of_new_lining)) || 0) +
        ((sideLining && Number(sideLining.no_of_side_lining)) || 0) +
        ((patchingDetails && Number(patchingDetails.no_of_patching)) || 0))

    return laborCostCampaign
  },
})

export const laborCostPerTonSelector = selector({
  key: 'laborCostPerTonSelector',
  get: ({ get }) => {
    const laborCostCampaign = get(laborCostCampaignSelector)
    const calculationObj = get(costCalculation)

    const laborCostPerTon =
      calculationObj && Number(calculationObj.averageLife)
        ? (laborCostCampaign / Number(calculationObj.totProduction)).toFixed(2)
        : '0.00'

    return laborCostPerTon
  },
})

export const laborCostCampaignNRMSelector = selector({
  key: 'laborCostCampaignNRMSelector',
  get: ({ get }) => {
    const newLiningNRM = get(new_lining_details_nrm)
    const sideLiningNRM = get(side_lining_details_nrm)
    const patchingDetailsNRM = get(patching_details_nrm)
    const laborCostNRM = get(labor_cost_nrm) // assuming `laborCostNRMState` is defined

    const laborCostCampaignNRM =
      laborCostNRM.labor_cost_per_nl *
      (((newLiningNRM && Number(newLiningNRM.no_of_new_lining)) || 0) +
        ((sideLiningNRM && Number(sideLiningNRM.no_of_side_lining)) || 0) +
        ((patchingDetailsNRM && Number(patchingDetailsNRM.no_of_patching)) ||
          0))

    return laborCostCampaignNRM
  },
})

export const laborCostPerTonNRMSelector = selector({
  key: 'laborCostPerTonNRMSelector',
  get: ({ get }) => {
    const laborCostCampaignNRM = get(laborCostCampaignNRMSelector)
    const calculationObjNRM = get(costCalculationNRM) // assuming `calculation_object_nrm` is defined

    const laborCostPerTonNRM =
      calculationObjNRM && Number(calculationObjNRM.averageLifeNRM)
        ? (
            laborCostCampaignNRM / Number(calculationObjNRM.totProductionNRM)
          ).toFixed(2)
        : '0.00'

    return laborCostPerTonNRM
  },
})

export const savingInLaborCostNRMSelector = selector({
  key: 'savingInLaborCostNRMSelector',
  get: ({ get }) => {
    const laborCostPerTon = get(laborCostPerTonSelector)
    const laborCostPerTonNRM = get(laborCostPerTonNRMSelector)

    const SavingInLaborCostNRM = (laborCostPerTon - laborCostPerTonNRM).toFixed(
      2
    )

    return SavingInLaborCostNRM
  },
})

// former cost

export const formerCostCampaignSelector = selector({
  key: 'formerCostCampaignSelector',
  get: ({ get }) => {
    const newLining = get(new_lining_details)
    const sideLining = get(side_lining_details)
    const patchingDetails = get(patching_details)
    const formerCost = get(former_cost) // assuming `formerCostState` is defined in your state

    const FormerCostCampaign =
      formerCost.former_cost_per_piece *
      (((newLining && Number(newLining.no_of_new_lining)) || 0) +
        ((sideLining && Number(sideLining.no_of_side_lining)) || 0) +
        ((patchingDetails && Number(patchingDetails.no_of_patching)) || 0))

    return FormerCostCampaign
  },
})

export const formerCostPerTonSelector = selector({
  key: 'formerCostPerTonSelector',
  get: ({ get }) => {
    const FormerCostCampaign = get(formerCostCampaignSelector)
    const calculationObj = get(costCalculation)

    const formerCostPerTon =
      calculationObj && Number(calculationObj.totProduction)
        ? (FormerCostCampaign / Number(calculationObj.totProduction)).toFixed(2)
        : '0.00'

    return formerCostPerTon
  },
})

export const formerCostCampaignNRMSelector = selector({
  key: 'formerCostCampaignNRMSelector',
  get: ({ get }) => {
    const newLiningNRM = get(new_lining_details_nrm)
    const sideLiningNRM = get(side_lining_details_nrm)
    const patchingDetailsNRM = get(patching_details_nrm)
    const formerCostNRM = get(former_cost_nrm) // assuming `formerCostNRMState` is defined

    const FormerCostCampaignNRM =
      formerCostNRM.former_cost_per_piece *
      (((newLiningNRM && Number(newLiningNRM.no_of_new_lining)) || 0) +
        ((sideLiningNRM && Number(sideLiningNRM.no_of_side_lining)) || 0) +
        ((patchingDetailsNRM && Number(patchingDetailsNRM.no_of_patching)) ||
          0))

    return FormerCostCampaignNRM
  },
})

export const formerCostPerTonNRMSelector = selector({
  key: 'formerCostPerTonNRMSelector',
  get: ({ get }) => {
    const FormerCostCampaignNRM = get(formerCostCampaignNRMSelector)
    const calculationObjNRM = get(costCalculationNRM)

    const formerCostPerTonNRM =
      calculationObjNRM && Number(calculationObjNRM.totProductionNRM)
        ? (
            FormerCostCampaignNRM / Number(calculationObjNRM.totProductionNRM)
          ).toFixed(2)
        : '0.00'

    return formerCostPerTonNRM
  },
})

export const savingInFormerCostSelector = selector({
  key: 'savingInFormerCostSelector',
  get: ({ get }) => {
    const formerCostPerTon = get(formerCostPerTonSelector)
    const formerCostPerTonNRM = get(formerCostPerTonNRMSelector)

    const savingInFormerCost = (formerCostPerTon - formerCostPerTonNRM).toFixed(
      2
    )

    return savingInFormerCost
  },
})

// increased productivity
const totalAvailableTime = 24 * 60 * 30

export const maxNoOfHeatsSelector = selector({
  key: 'maxNoOfHeatsSelector',
  get: ({ get }) => {
    const increasedProductivity = get(increased_productivity) // assuming `increasedProductivityState` is defined
    return increasedProductivity && Number(increasedProductivity.avg_heat_time)
      ? (
          totalAvailableTime / Number(increasedProductivity.avg_heat_time)
        ).toFixed(2)
      : '0.00'
  },
})

export const noOfSinteringSelector = selector({
  key: 'noOfSinteringSelector',
  get: ({ get }) => {
    const maxNoOfHeats = get(maxNoOfHeatsSelector)
    const calculationObj = get(costCalculation) // assuming `calculation_object` is defined

    return calculationObj && Number(calculationObj.averageLife)
      ? (Number(maxNoOfHeats) / Number(calculationObj.averageLife)).toFixed(2)
      : '0.00'
  },
})

export const totDownTimeSelector = selector({
  key: 'totDownTimeSelector',
  get: ({ get }) => {
    const increasedProductivity = get(increased_productivity)
    const noOfSintering = get(noOfSinteringSelector)

    return (increasedProductivity.extra_time_sintering * noOfSintering).toFixed(
      2
    )
  },
})

export const totDownTimeDueToFceSelector = selector({
  key: 'totDownTimeDueToFceSelector',
  get: ({ get }) => {
    const increasedProductivity = get(increased_productivity)
    const maxNoOfHeats = get(maxNoOfHeatsSelector)

    return increasedProductivity &&
      Number(increasedProductivity.frequency_of_furnace)
      ? (
          maxNoOfHeats *
          (increasedProductivity.frequency_of_furnace / 100) *
          increasedProductivity.avg_fce_checking_time
        ).toFixed(2)
      : '0.00'
  },
})

export const totTimeAvailableProdSelector = selector({
  key: 'totTimeAvailableProdSelector',
  get: ({ get }) => {
    const totalDownTime = get(totDownTimeSelector)
    const totalDownTimeDueToFce = get(totDownTimeDueToFceSelector)
    const increasedProductivity = get(increased_productivity)

    return (
      totalAvailableTime -
      totalDownTime -
      totalDownTimeDueToFce -
      Number(increasedProductivity.total_down_time_change_over)
    ).toFixed(2)
  },
})

export const actualNoOfHeatsSelector = selector({
  key: 'actualNoOfHeatsSelector',
  get: ({ get }) => {
    const totTimeAvailableProd = get(totTimeAvailableProdSelector)
    const increasedProductivity = get(increased_productivity)

    return (
      totTimeAvailableProd / Number(increasedProductivity.avg_heat_time)
    ).toFixed(2)
  },
})

export const actualProdInMonthSelector = selector({
  key: 'actualProdInMonthSelector',
  get: ({ get }) => {
    const actualNoOfHeats = get(actualNoOfHeatsSelector)
    const calculationObj = get(costCalculation)

    return calculationObj && Number(calculationObj.averageHeatSize)
      ? (actualNoOfHeats * calculationObj.averageHeatSize).toFixed(2)
      : '0.00'
  },
})

// NRM Selectors

export const maxNoOfHeatsNRMSelector = selector({
  key: 'maxNoOfHeatsNRMSelector',
  get: ({ get }) => {
    const increasedProductivityNRM = get(increased_productivity_nrm) // assuming `increasedProductivityNRMState` is defined

    return increasedProductivityNRM &&
      Number(increasedProductivityNRM.avg_heat_time)
      ? (
          totalAvailableTime / Number(increasedProductivityNRM.avg_heat_time)
        ).toFixed(2)
      : '0.00'
  },
})

export const noOfSinteringNRMSelector = selector({
  key: 'noOfSinteringNRMSelector',
  get: ({ get }) => {
    const maxNoOfHeatsNRM = get(maxNoOfHeatsNRMSelector)
    const calculationObjNRM = get(costCalculationNRM)

    return calculationObjNRM && Number(calculationObjNRM.averageLifeNRM)
      ? (maxNoOfHeatsNRM / Number(calculationObjNRM.averageLifeNRM)).toFixed(2)
      : '0.00'
  },
})

export const totDownTimeNRMSelector = selector({
  key: 'totDownTimeNRMSelector',
  get: ({ get }) => {
    const increasedProductivityNRM = get(increased_productivity_nrm)
    const noOfSinteringNRM = get(noOfSinteringNRMSelector)

    return increasedProductivityNRM &&
      Number(increasedProductivityNRM.extra_time_sintering)
      ? (
          increasedProductivityNRM.extra_time_sintering * noOfSinteringNRM
        ).toFixed(2)
      : '0.00'
  },
})

export const totDownTimeDueToFceNRMSelector = selector({
  key: 'totDownTimeDueToFceNRMSelector',
  get: ({ get }) => {
    const maxNoOfHeatsNRM = get(maxNoOfHeatsNRMSelector)
    const increasedProductivityNRM = get(increased_productivity_nrm)

    return (
      maxNoOfHeatsNRM *
      (increasedProductivityNRM.frequency_of_furnace / 100) *
      increasedProductivityNRM.avg_fce_checking_time
    ).toFixed(2)
  },
})

export const totTimeAvailableProdNRMSelector = selector({
  key: 'totTimeAvailableProdNRMSelector',
  get: ({ get }) => {
    const totDownTimeNRM = get(totDownTimeNRMSelector)
    const totDownTimeDueToFceNRM = get(totDownTimeDueToFceNRMSelector)
    const increasedProductivityNRM = get(increased_productivity_nrm)

    return (
      totalAvailableTime -
      totDownTimeNRM -
      totDownTimeDueToFceNRM -
      Number(increasedProductivityNRM.total_down_time_change_over)
    ).toFixed(2)
  },
})

export const actualNoOfHeatsNRMSelector = selector({
  key: 'actualNoOfHeatsNRMSelector',
  get: ({ get }) => {
    const totTimeAvailableProdNRM = get(totTimeAvailableProdNRMSelector)
    const increasedProductivityNRM = get(increased_productivity_nrm)

    return (
      totTimeAvailableProdNRM / Number(increasedProductivityNRM.avg_heat_time)
    ).toFixed(2)
  },
})

export const actualProdInMonthNRMSelector = selector({
  key: 'actualProdInMonthNRMSelector',
  get: ({ get }) => {
    const actualNoOfHeatsNRM = get(actualNoOfHeatsNRMSelector)
    const calculationObjNRM = get(costCalculationNRM)

    return calculationObjNRM && Number(calculationObjNRM.averageHeatSizeNRM)
      ? (actualNoOfHeatsNRM * calculationObjNRM.averageHeatSizeNRM).toFixed(2)
      : '0.00'
  },
})

export const prodIncreaseSelector = selector({
  key: 'prodIncreaseSelector',
  get: ({ get }) => {
    const actualProdInMonth = get(actualProdInMonthSelector)
    const actualProdInMonthNRM = get(actualProdInMonthNRMSelector)

    return (actualProdInMonthNRM - actualProdInMonth).toFixed(2)
  },
})

export const perOfProdDueToNRMSelector = selector({
  key: 'perOfProdDueToNRMSelector',
  get: ({ get }) => {
    const actualProdInMonth = get(actualProdInMonthSelector)
    const prodIncrease = get(prodIncreaseSelector)

    return Number(actualProdInMonth)
      ? ((prodIncrease * 100) / actualProdInMonth).toFixed(2)
      : '0.00'
  },
})

export const costBenefitsSelector = selector({
  key: 'costBenefitsSelector',
  get: ({ get }) => {
    const prodIncrease = get(prodIncreaseSelector)
    return (prodIncrease * 2000).toFixed(2)
  },
})

export const savingDueToIncProdSelector = selector({
  key: 'savingDueToIncProdSelector',
  get: ({ get }) => {
    const costBenefits = get(costBenefitsSelector)
    const actualProdInMonthNRM = get(actualProdInMonthNRMSelector)

    return Number(costBenefits) && Number(actualProdInMonthNRM)
      ? (Number(costBenefits) / Number(actualProdInMonthNRM)).toFixed(2)
      : '0.00'
  },
})

// energy

export const costSavingsDueToPowerSelector = selector({
  key: 'costSavingsDueToPowerSelector',
  get: ({ get }) => {
    const energyNRM = get(savings_energy_nrm)
    const costSavingsDueToPower = (
      energyNRM.cost_per_electricity_unit * energyNRM.power_savings_per_mt
    ).toFixed(2)
    return costSavingsDueToPower
  },
})

// yield improvements

export const costSavingsDueToYieldImprovementSelector = selector({
  key: 'costSavingsDueToYieldImprovementSelector',
  get: ({ get }) => {
    const yieldImprovementNRM = get(yield_improvement_nrm)
    const costSavingsDueToYieldImprovement = (
      (yieldImprovementNRM.yield_benefit / 100) *
      yieldImprovementNRM.market_price_of_scrap
    ).toFixed(2)

    return costSavingsDueToYieldImprovement
  },
})

// alloy

export const savingsDueToReductionSelector = selector({
  key: 'savingsDueToReductionSelector',
  get: ({ get }) => {
    const alloyNRM = get(savings_alloy_nrm)

    // Perform the savings calculation
    const savingsDueToReduction = (
      Number(alloyNRM.simn_savings) +
      Number(alloyNRM.fecr_savings) +
      Number(alloyNRM.savings_due_to_other_alloy)
    ).toFixed(2)

    return savingsDueToReduction
  },
})

// tangiable benefits

export const otherTangibleBenefitsSelector = selector({
  key: 'otherTangibleBenefitsSelector',
  get: ({ get }) => {
    const tangibleBenefitsNRM = get(savings_tangible_benefit_nrm)
    const otherTangibleBenefits = (
      Number(tangibleBenefitsNRM.asbestos_sheet) +
      Number(tangibleBenefitsNRM.coil_coat_for_repair) +
      Number(tangibleBenefitsNRM.antenna_replacement) +
      Number(tangibleBenefitsNRM.sodium_silicate) +
      Number(tangibleBenefitsNRM.slag_dumping) +
      Number(tangibleBenefitsNRM.safety_in_operations)
    ).toFixed(2)

    return otherTangibleBenefits
  },
})

// sintering heats

export const chargedSinteringPerMonthSelector = selector({
  key: 'chargedSinteringPerMonthSelector',
  get: ({ get }) => {
    const sinteringHeats = get(sintering_heats)
    const noOfSintering = get(noOfSinteringSelector)

    return (
      Number(sinteringHeats.charge_mix_regular_heat) * Number(noOfSintering)
    ).toFixed(2)
  },
})

export const costSinteringHeatPerMonthSelector = selector({
  key: 'costSinteringHeatPerMonthSelector',
  get: ({ get }) => {
    const chargedSinteringPerMonth = get(chargedSinteringPerMonthSelector)
    const sinteringHeats = get(sintering_heats)

    return (
      Number(chargedSinteringPerMonth) *
      Number(sinteringHeats.market_price_sintering_scrap)
    ).toFixed(2)
  },
})

export const costOfReplacingWithDRISelector = selector({
  key: 'costOfReplacingWithDRISelector',
  get: ({ get }) => {
    const costSinteringHeatPerMonth = get(costSinteringHeatPerMonthSelector)
    const sinteringHeats = get(sintering_heats)

    const result =
      Number(costSinteringHeatPerMonth) -
      ((Number(costSinteringHeatPerMonth) *
        Number(sinteringHeats.yield_of_sintering_scrap)) /
        Number(sinteringHeats.yield_of_dri)) *
        Number(sinteringHeats.market_price_of_dri_mt)

    return result.toFixed(2)
  },
})

export const costOfchargedSinteringHeatPerMTSelector = selector({
  key: 'costOfchargedSinteringHeatPerMTSelector',
  get: ({ get }) => {
    const costOfReplacingWithDRI = get(costOfReplacingWithDRISelector)
    const actualProdInMonth = get(actualProdInMonthSelector)

    return (Number(costOfReplacingWithDRI) / Number(actualProdInMonth)).toFixed(
      2
    )
  },
})

// NRM calculations
export const chargedSinteringPerMonthNRMSelector = selector({
  key: 'chargedSinteringPerMonthNRMSelector',
  get: ({ get }) => {
    const sinteringHeatsNRM = get(sintering_heats_nrm)
    const noOfSinteringNRM = get(noOfSinteringNRMSelector)

    return (
      Number(sinteringHeatsNRM.charge_mix_regular_heat) *
      Number(noOfSinteringNRM)
    ).toFixed(2)
  },
})

export const costSinteringHeatPerMonthNRMSelector = selector({
  key: 'costSinteringHeatPerMonthNRMSelector',
  get: ({ get }) => {
    const chargedSinteringPerMonthNRM = get(chargedSinteringPerMonthNRMSelector)
    const sinteringHeatsNRM = get(sintering_heats_nrm)

    return (
      Number(chargedSinteringPerMonthNRM) *
      Number(sinteringHeatsNRM.market_price_sintering_scrap)
    ).toFixed(2)
  },
})

export const costOfReplacingWithDRINRMSelector = selector({
  key: 'costOfReplacingWithDRINRMSelector',
  get: ({ get }) => {
    const costSinteringHeatPerMonthNRM = get(
      costSinteringHeatPerMonthNRMSelector
    )
    const sinteringHeatsNRM = get(sintering_heats_nrm)

    const result =
      Number(costSinteringHeatPerMonthNRM) -
      ((Number(costSinteringHeatPerMonthNRM) *
        Number(sinteringHeatsNRM.yield_of_sintering_scrap)) /
        Number(sinteringHeatsNRM.yield_of_dri)) *
        Number(sinteringHeatsNRM.market_price_of_dri_mt)

    return result.toFixed(2)
  },
})

export const costOfchargedSinteringHeatPerMTNRMSelector = selector({
  key: 'costOfchargedSinteringHeatPerMTNRMSelector',
  get: ({ get }) => {
    const costOfReplacingWithDRINRM = get(costOfReplacingWithDRINRMSelector)
    const actualProdInMonthNRM = get(actualProdInMonthNRMSelector)

    return (
      Number(costOfReplacingWithDRINRM) / Number(actualProdInMonthNRM)
    ).toFixed(2)
  },
})

// NRM 


