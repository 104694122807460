import React, { useState, useEffect } from 'react'
import * as XLSX from 'xlsx'
import configParam from '../../config'
import trashIcon from '../../assets/icons/trash_icon.svg'
import {
  Button,
  Paper,
  Typography,
  Grid,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Divider,
} from '@mui/material'
// import { DAILY_TRIAL_MONITORING, COST_SHEET } from "./constants";
import FileSaver from 'file-saver'
import { Modal } from '@mui/material'
import { CloudDownload } from '@mui/icons-material'
import excelIcon from '../../assets/icons/ic_excel.svg'
import API from '../services/API'
import axios from 'axios'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import AlertDialog from '../input/AlertDialog'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SelectBox from '../input/SelectBox'
import JSZip, { forEach } from 'jszip'
import {
  BrowserRouter as Router,
  Link,
  useNavigate,
  json,
} from 'react-router-dom' // Import Routes
import Box from '@mui/material/Box'
import FilterIcon from '../../assets/icons/ic_home_filter.svg'
import UploadIcon from '../../assets/icons/ic_cloud.svg'
import DownloadIcon from '../../assets/icons/ic_download.svg'
import DailyTrialMonitoring from './DailyTrialMonitoring'
import ErosionProfileWeb from './ErosionProfile'
import ViewTrial from './ViewTrial'
import AutoSelectBox from '../input/AutoSelectBox'
import NRMPerformance from './NRMPerformance'
import DataCollection from './DataCollection'
import { v4 as uuidv4 } from 'uuid'
import Helper from '../services/Helper'
import CostSheet from './CostSheet'
import SubmittedStatus from '../mobile/datafilestatus/SubmittedStatus'

const moment = require('moment')

const ApiURL = configParam.API_URL
const DataManagement = (props) => {
  const [selectedTab, setSelectedTab] = useState(0)
  const navigate = useNavigate()
  const [showModals, setShowModals] = useState(false)
  const [customerName, setCustomerName] = useState('')
  const [furnaceCapacity, setFurnaceCapacity] = useState('')
  const [segment, setSegment] = useState('')
  const [fileTemplate, setfileTemplate] = useState([])
  const [selectedSegment, setselectedSegment] = useState('')
  const [file, setFile] = useState(null)
  const [fileexcel, setFileExcel] = useState([])
  const [cards, setCards] = useState([])
  // const [extractedData, setExtractedData] = useState([]);
  const [startRow, setStartRow] = useState(13)
  const access_token = localStorage.getItem('accesstoken')
  // 5 template whole data
  const [dailyTrials, setDailyTrials] = useState([])
  console.log(dailyTrials, 'dailyTrials')
  const [dataCollection, setDataCollection] = useState([])
  const [costSheetData, setCostSheetData] = useState([])
  const [nrmPerformanceData, setNRMPerformanceData] = useState([])
  const [erosionProfileData, setErosionProfileData] = useState([])
  console.log(erosionProfileData, 'erosionProfileData')

  // filtered usestates
  const [filteredDailyTrials, setFilteredDailyTrials] = useState([])
  console.log(filteredDailyTrials, 'filteredDailyTrials')
  const [filteredDataCollection, setFilteredDataCollection] = useState([])
  const [filteredErosionProfile, setFilteredErosionProfile] = useState([])
  const [filteredCostSheetData, setFilteredCostSheetData] = useState([])
  const [filteredNRMPerformanceData, setFilteredNRMPerformanceData] = useState(
    []
  )

  const [cardsDraft, setCardsDraft] = useState([])
  const [cardsSubmitted, setCardsSubmitted] = useState([])
  const [cardsApproved, setCardsApproved] = useState([])
  const [cardsRejected, setCardsRejected] = useState([])
  // nrm
  const [nrmcardsDraft, setNRMCardsDraft] = useState([])
  const [nrmcardsSubmitted, setNRMCardsSubmitted] = useState([])
  const [nrmCardsApproved, setNrmCardsApproved] = useState([])
  const [nrmCardsRejected, setNrmCardsRejected] = useState([])
  // erosion profile
  const [erosionProfileDraft, setErosionProfileDraft] = useState([])
  const [erosionProfileSubmitted, setErosionProfileSubmitted] = useState([])
  const [erosionProfileApproved, setErosionProfileApproved] = useState([])
  console.log(erosionProfileApproved, 'erosionProfileApproved')
  const [erosionProfileRejected, setErosionProfileRejected] = useState([])
  // data collection
  const [dataCollectionDraft, setDataCollectionDraft] = useState([])
  const [dataCollectionSubmitted, setDataCollectionSubmitted] = useState([])
  console.log(dataCollectionSubmitted, 'check1')
  const [dataCollectionApproved, setDataCollectioApproved] = useState([])
  const [dataCollectionRejected, setDataCollectionRejected] = useState([])
  //cost sheet
  const [costSheetSubmitted, setCostSheetSubmitted] = useState([])
  const [costSheetDraft, setCostSheetDraft] = useState([])
  const [costSheetApproved, setCostSheetApproved] = useState([])
  const [costSheetRejected, setCostSheetRejected] = useState([])

  const [approvedData, setapprovedData] = useState([])
  const [rejectData, setrejectData] = useState([])
  const [selectedSegmentList, setselectedSegmentList] = useState([])
  const [SegmentList, setSegmentList] = useState([])
  const [templateId, setTemplateId] = useState('')
  const [Segmentid, setSegmentid] = useState('')
  const [customers, setCustomers] = useState([])
  const [selectCustomers, setSelectCustomers] = useState([])
  const [segments, setSegments] = useState([])
  const [templates, setTemplates] = useState([])
  const [furnaces, setFurnaces] = useState([])
  const [formData, setFormData] = useState({
    segment_id: '',
    customer_id: '',
    template_id: '',
    furnace_id: '',
  })
  const [formErrors, setFormErrors] = useState({})
  const currentUser = JSON.parse(localStorage.getItem('user'))
  const [blobtemplates, setBlobTemplates] = useState([])
  const [templateNames, setTemplateNames] = useState([])
  const [open, setOpen] = useState(false)
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const selectedCustomer = JSON.parse(localStorage.getItem('selectedCustomer'))
  const customer_id = selectedCustomer?.id

  const handleOpen = () => setOpen(true)
  const handleFilterClose = () => setOpen(false)

  const handleApply = () => {
    // Filter data based on selectedTab
    switch (selectedTab) {
      case 0:
        filterDailyTrials()
        break
      case 2:
        filterErosionProfile()
        break
      case 3:
        filterDataCollection()
        break
      case 4:
        filterCostSheet()
        break
      default:
        filterNRMPerformance()
        break
    }
    setOpen(false)
    handleFilterClose()
  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.filter-container')) {
        handleFilterClose()
      }
    }

    if (open) {
      document.addEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [open])

  const handleFormCancel = async () => {
    setShowModals(false)
  }

  const filterDailyTrials = () => {
    const filtered = dailyTrials.filter((trial) => {
      const trialDate = new Date(trial.created_at)
      const from = new Date(fromDate)
      const to = new Date(toDate)
      to.setHours(23, 59, 59, 999)
      return trialDate >= from && trialDate <= to
    })

    if (filtered.length === 0) {
      AlertDialog({
        type: 'delete',
        title: 'Alert',
        text: 'No Records Matched',
        confirmButtonText: 'Ok',
        onConfirm: async () => {
          onclose()
        },
      })
    } else {
      AlertDialog({
        type: 'success',
        title: 'Success',
        text: 'Filter Applied',
        confirmButtonText: 'Ok',
        onConfirm: async () => {
          onclose()
        },
      })
    }

    setFilteredDailyTrials(filtered)
  }

  const filterErosionProfile = () => {
    const filtered = erosionProfileData.filter((item) => {
      const itemDate = new Date(item.created_at)
      const from = new Date(fromDate)
      const to = new Date(toDate)
      to.setHours(23, 59, 59, 999)
      return itemDate >= from && itemDate <= to
    })

    if (filtered.length === 0) {
      AlertDialog({
        type: 'delete',
        title: 'Alert',
        text: 'No Records Matched',
        confirmButtonText: 'Ok',
        onConfirm: async () => {
          onclose()
        },
      })
    } else {
      AlertDialog({
        type: 'success',
        title: 'Success',
        text: 'Filter Applied',
        confirmButtonText: 'Ok',
        onConfirm: async () => {
          onclose()
        },
      })
    }

    setFilteredErosionProfile(filtered)
  }

  const filterDataCollection = () => {
    const filtered = dataCollection.filter((item) => {
      const itemDate = new Date(item.created_at)
      const from = new Date(fromDate)
      const to = new Date(toDate)
      to.setHours(23, 59, 59, 999)
      return itemDate >= from && itemDate <= to
    })

    if (filtered.length === 0) {
      AlertDialog({
        type: 'delete',
        title: 'Alert',
        text: 'No Records Matched',
        confirmButtonText: 'Ok',
        onConfirm: async () => {
          onclose()
        },
      })
    } else {
      AlertDialog({
        type: 'success',
        title: 'Success',
        text: 'Filter Applied',
        confirmButtonText: 'Ok',
        onConfirm: async () => {
          onclose()
        },
      })
    }

    setFilteredDataCollection(filtered)
  }

  const filterCostSheet = () => {
    const filtered = costSheetData.filter((item) => {
      const itemDate = new Date(item.created_at)
      const from = new Date(fromDate)
      const to = new Date(toDate)
      to.setHours(23, 59, 59, 999)
      return itemDate >= from && itemDate <= to
    })

    if (filtered.length === 0) {
      AlertDialog({
        type: 'delete',
        title: 'Alert',
        text: 'No Records Matched',
        confirmButtonText: 'Ok',
        onConfirm: async () => {
          onclose()
        },
      })
    } else {
      AlertDialog({
        type: 'success',
        title: 'Success',
        text: 'Filter Applied',
        confirmButtonText: 'Ok',
        onConfirm: async () => {
          onclose()
        },
      })
    }

    setFilteredCostSheetData(filtered)
  }

  const filterNRMPerformance = () => {
    const filtered = nrmPerformanceData.filter((item) => {
      const itemDate = new Date(item.created_at)
      const from = new Date(fromDate)
      const to = new Date(toDate)
      to.setHours(23, 59, 59, 999)
      return itemDate >= from && itemDate <= to
    })

    if (filtered.length === 0) {
      AlertDialog({
        type: 'delete',
        title: 'Alert',
        text: 'No Records Matched',
        confirmButtonText: 'Ok',
        onConfirm: async () => {
          onclose()
        },
      })
    } else {
      AlertDialog({
        type: 'success',
        title: 'Success',
        text: 'Filter Applied',
        confirmButtonText: 'Ok',
        onConfirm: async () => {
          onclose()
        },
      })
    }
    setFilteredNRMPerformanceData(filtered)
  }

  const handleReset = () => {
    setFromDate('')
    setToDate('')
  }

  // const UploadForm = () => {
  //   const [file, setFile] = useState(null);

  //   const handleFileChange = (e) => {
  //     setFile(e.target.files[0]);
  //   };

  //   const handleUpload = async () => {
  //     const formData = new FormData();
  //     formData.append('file', file);

  //     try {
  //       await axios.post('/upload', formData);
  //       alert('File uploaded successfully');
  //     } catch (error) {
  //       console.error('Error uploading file:', error);
  //       alert('Error uploading file');
  //     }
  //   };

  const handleFileDelete = () => {
    setFile(null)
  }

  const handleModalOpens = () => {
    setFormData({
      customer_id: '',
      segment_id: '',
      template_id: '',
    })

    // Fetch fresh data
    fetchCustomers()
    fetchSegments()
    fetchTemplates()

    // Show modal
    setShowModals(true)
  }

  const fetchSegments = async () => {
    try {
      const response = await axios.get(API.GET_SEGMENT, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      setSegments(response.data)
    } catch (error) {
      console.error('Error fetching lining:', error)
    }
  }

  // const fetchCustomers = async () => {
  //   try {
  //     const response = await axios.get(API.GET_CUSTOMER); // Adjust the URL as needed
  //     console.log("response", response);
  //     setCustomers(response.data); // Update state with fetched data
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const fetchCustomers = async () => {
    try {
      const response = await axios.get(API.GET_CUSTOMER, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      const customerResponse = response.data

      // For role 1
      if (currentUser.role.role_id === 1) {
        const customerId = currentUser.assign_customer_id[0]
        const customers = customerResponse.filter(
          (item) => item.id === customerId
        )
        setCustomers(customers)

        const customer_id = storedCustomerDet.id
        const selectedCustomer = customerResponse.filter(
          (item) => item.id === customer_id
        )
        setSelectCustomers(selectedCustomer)

        // For roles 2 and 3
      } else if (
        currentUser.role.role_id === 2 ||
        currentUser.role.role_id === 3
      ) {
        const assignCustomerIds = currentUser.assign_customer_id.map((id) => id)
        const filteredCustomers = customerResponse.filter((customer) =>
          assignCustomerIds.includes(customer.id)
        )
        setCustomers(filteredCustomers)

        const customer_id = storedCustomerDet.id
        const selectedCustomer = filteredCustomers.filter(
          (item) => item.id === customer_id
        )
        setSelectCustomers(selectedCustomer)

        // For other roles (e.g., 4, 5, etc.)
      } else {
        setCustomers(customerResponse)

        const customer_id = storedCustomerDet.id
        const selectedCustomer = customerResponse.filter(
          (item) => item.id === customer_id
        )
        setSelectCustomers(selectedCustomer)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const fetchTemplates = async () => {
    try {
      const response = await axios.get(API.GET_TEMPLATES)
      console.log('responsetemplates', response)
      setTemplates(response.data)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const selectedTemplate = templates.find(
    (template) => template.id === formData.template_id
  )

  const handleModalCloses = () => {
    setShowModals(false)
    setCustomerName('')
    setFurnaceCapacity('')
    setSegment('')
  }

  const handleFormSubmit = async (e) => {
    try {
      e.preventDefault() // Prevent default form submission behavior

      let errors = {}
      let isValid = true

      // Check if the mandatory fields are filled
      if (!formData.template_id) {
        errors.template_id = 'Data File Template is required'
        isValid = false
      }

      if (
        selectedTemplate &&
        selectedTemplate.template_id !== 4 &&
        selectedTemplate.template_id !== 5
      ) {
        if (!formData.segment_id) {
          errors.segment_id = 'Segment is required'
          isValid = false
        }
      }

      // if (!formData.customer_id) {
      //   errors.customer_id = 'Customer Name is required'
      //   isValid = false
      // }

      if (
        selectedTemplate &&
        selectedTemplate.template_id !== 4 &&
        selectedTemplate.template_id !== 5
      ) {
        if (!formData.furnace_id) {
          errors.furnace_id = 'Furnace Number is required'
          isValid = false
        }
      }

      if (!file) {
        errors.file = 'File upload is required'
        isValid = false
      }

      // If there are errors, update the formErrors state and return
      if (!isValid) {
        setFormErrors(errors)
        return
      }

      setFormErrors({})

      const jsonData = {
        ...formData,
        user_id: currentUser.id,
      }

      const matchedTemplate = templates.find(
        (template) => template.id === jsonData.template_id
      )
      console.log('matchedTemplate', matchedTemplate)
      const template_id = matchedTemplate.template_id
      setTemplateId(template_id)
      console.log(template_id, 'template_id')

      console.log('jsonDatajsonData', jsonData)

      if (file) {
        console.log(file, 'file123')
        if (template_id === 1) {
          await handleExtractData(template_id, file)
          const extractedData = JSON.parse(
            localStorage.getItem('extractedData')
          )
          console.log('extractedData', extractedData)
          console.log('template_id', template_id)
          const predefinedHeaders1 = [
            'Furnace No.',
            'Crucible No.',
            'Campaign No.',
            'Lining / Patching No',
            'Heat No',
            'Date',
            'Customer Ref No',
            'Grade',
            'Heat ON Time',
            'Heat TAP Time',
            'Breakdown duration',
            'Heat Duration actual',
            'Heat Duration calculated',
            'Tapping temperature',
            'Tapped tonnage',
            'Melt Rate based on actual duration',
            'Melt Rate based on calculated duration',
            'Frequency',
            'Voltage',
            'Power In the panel',
            'Capacitors Removed',
            'Total Power Consumed',
            'Energy consumption per unit Ton actual',
            'Energy consumption per unit Ton calculated',
            'Scrap',
            'Shredded',
            'Pig Iron',
            'Pooled Iron',
            'Hot Heel',
            'Lump',
            'Pellet',
            'Fines less than 5 mm',
            'Fines less than 1 mm',
            'Slag Crushers',
            'Tundish or Slag or Metalic Jam',
            'Total charge',
            'CPC ',
            'Met Coke',
            '50 C%',
            '85 C%',
            'Final C%',
            'Si% Before addition',
            'Final Si%',
            'Mn% before Addition',
            'Final Mn%',
            'Cr% before addition',
            'Final Cr%',
            'SiMn',
            'Mn % in SiMn',
            'FeMn',
            'FeSi',
            'FeCr',
            'Mill scale',
            'Any other Alloy addition',
            'Inlet water temp.',
            'Outlet Water temp.',
            'Water Pr.',
            'Remarks',
          ] // Define your predefined headers here
          const predefinedHeaders2 = [
            'Furnace No.',
            'Crucible No.',
            'Campaign No.',
            'Lining / Patching No',
            'Heat No',
            'Date',
            'Customer Ref No',
            'Grade',
            'Heat ON Time',
            'Heat TAP Time',
            'Breakdown duration',
            'Heat Duration actual',
            'Heat Duration calculated',
            'Tapping temperature',
            'Tapped tonnage',
            'Melt Rate based on actual duration',
            'Melt Rate based on calculated duration',
            'Frequency',
            'Voltage',
            'Power In the panel',
            'Capacitors Removed',
            'Total Power Consumed',
            'Energy consumption per unit Ton actual',
            'Energy consumption per unit Ton calculated',
            'Scrap',
            'Shredded',
            'Pig Iron',
            'Pooled Iron',
            'Hot Heel',
            'Lump',
            'Pellet',
            'Fines less than 5 mm',
            'Fines less than 1 mm',
            'Slag Crushers',
            'Tundish or Slag or Metalic Jam',
            'Total charge',
            'Anthracite',
            'Met Coke',
            '50 C%',
            '85 C%',
            'Final C%',
            'Si% Before addition',
            'Final Si%',
            'Mn% before Addition',
            'Final Mn%',
            'Cr% before addition',
            'Final Cr%',
            'SiMn',
            'Mn % in SiMn',
            'FeMn',
            'FeSi',
            'FeCr',
            'Mill scale',
            'Any other Alloy addition',
            'Inlet water temp.',
            'Outlet Water temp.',
            'Water Pr.',
            'Remarks',
          ]
          const predefinedHeaders3 = [
            'Furnace No.',
            'Crucible No.',
            'Campaign No.',
            'Lining / Patching No',
            'Heat No',
            'Date',
            'Customer Ref No',
            'Grade',
            'Heat ON Time',
            'Heat TAP Time',
            'Breakdown duration',
            'Heat Duration actual',
            'Heat Duration calculated',
            'Tapping temperature',
            'Tapped tonnage',
            'Melt Rate based on actual duration',
            'Melt Rate based on calculated duration',
            'Frequency',
            'Voltage',
            'Power In the panel',
            'Capacitors Removed',
            'Total Power Consumed',
            'Energy consumption per unit Ton actual',
            'Energy consumption per unit Ton calculated',
            'Scrap',
            'Shredded',
            'Pig Iron',
            'Pooled Iron',
            'Hot Heel',
            'Lump',
            'Pellet',
            'Fines less than 5 mm',
            'Fines less than 1 mm',
            'Slag Crushers',
            'Tundish or Slag or Metalic Jam',
            'Total charge',
            'Carbon block ',
            'Met Coke',
            '50 C%',
            '85 C%',
            'Final C%',
            'Si% Before addition',
            'Final Si%',
            'Mn% before Addition',
            'Final Mn%',
            'Cr% before addition',
            'Final Cr%',
            'SiMn',
            'Mn % in SiMn',
            'FeMn',
            'FeSi',
            'FeCr',
            'Mill scale',
            'Any other Alloy addition',
            'Inlet water temp.',
            'Outlet Water temp.',
            'Water Pr.',
            'Remarks',
          ]
          const extractedHeaders = extractedData.headers
          console.log('extractedHeaders', extractedHeaders)

          function arraysEqual(arr1, arr2) {
            return arr1.some((item) => arr2.includes(item))
          }

          const headersMatch =
            arraysEqual(predefinedHeaders1, extractedHeaders) ||
            arraysEqual(predefinedHeaders2, extractedHeaders) ||
            arraysEqual(predefinedHeaders3, extractedHeaders)
          if (headersMatch) {
            jsonData.filename = file.name
            jsonData.status = 'Submitted'
            const response = await axios.post(API.UPLOAD_DATAFILE, jsonData, {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            })
            console.log('UPLOAD_DATAFILE', response)
            const responseData = response.data
            const datafile_id = responseData.data.id
            localStorage.setItem('datafile_id', datafile_id)
            const Data = extractedData.data
            console.log('Data', Data)

            if (datafile_id && Data) {
              const general_details = []
              const furnace_details = []
              const charge_mix_details = []
              const carbon = []
              const alloying_addition = []
              const other_calculated_field = []
              const other_parameters = []
              const convertToHHMM = (decimalTime) => {
                const totalHours = decimalTime * 24
                const hours = Math.floor(totalHours)
                const minutes = Math.round((totalHours - hours) * 60)
                const formattedHours = String(hours)
                const formattedMinutes = String(minutes).padStart(2, '0')
                return `${formattedHours}:${formattedMinutes}`
              }

              const formatDecimal = (value) => {
                let integerValue = Math.abs(value)
                if (integerValue >= 100000) {
                  integerValue = Math.round(integerValue / 1000) * 1000 // Round off to the nearest thousand
                }

                if (!Number.isInteger(value)) {
                  return Number(value).toFixed(2)
                } else {
                  return `${value}.00`
                }
              }

              const formatPercentage = (value) => {
                const formattedPercentage = (value * 100).toFixed(2) + '%'
                return `${formattedPercentage}`
              }

              const formatDate = (value) => {
                // Convert value to a JavaScript Date object
                const date = new Date(
                  value * 24 * 60 * 60 * 1000 + Date.UTC(1899, 11, 30)
                )

                // Extract day, month, and year from the Date object
                const day = date.getUTCDate()
                const month = date.getUTCMonth() + 1 // Month is zero-based
                const year = date.getUTCFullYear()

                // Format day and month with single digits if necessary
                const formattedDay = day < 10 ? '0' + day : day
                const formattedMonth = month < 10 ? '0' + month : month

                // Return the date in the desired format
                return `${formattedMonth}/${formattedDay}/${year}`
              }

              Data.forEach((entry) => {
                const entryId = uuidv4()
                const isEmptyGeneralDetails =
                  !entry['Furnace No.'] &&
                  !entry['Crucible No.'] &&
                  !entry['Campaign No.'] &&
                  !entry['Lining / Patching No'] &&
                  !entry['Heat No'] &&
                  isNaN(new Date(entry['Date'])) &&
                  !entry['Customer Ref No'] &&
                  !entry['Grade']

                function getAbbreviatedLiningType(liningType) {
                  if (!liningType) return '' // Check if liningType is undefined or empty
                  let abbreviated = ''
                  liningType.split(' ').forEach((word) => {
                    abbreviated += word.charAt(0).toUpperCase()
                  })
                  return abbreviated
                }

                const lorp_id =
                  customerName +
                  (entry['Furnace No.'] ? entry['Furnace No.'] : '') +
                  '-' +
                  (entry['Crucible No.'] ? entry['Crucible No.'] : '') +
                  '-' +
                  (entry['Campaign No.'] ? entry['Campaign No.'] : '') +
                  '-' +
                  getAbbreviatedLiningType(entry['Lining / Patching No'])

                let submissionStatus = ''
                if (
                  currentUser.role.role_id === 3 ||
                  currentUser.role.role_id === 4
                ) {
                  submissionStatus = 'approved'
                } else {
                  submissionStatus = 'submitted'
                }
                if (!isEmptyGeneralDetails) {
                  general_details.push({
                    id: entryId,
                    furnace_no: entry['Furnace No.'],
                    crucible_no: entry['Crucible No.'],
                    campaign_no: entry['Campaign No.'],
                    lining_patch_no: entry['Lining / Patching No'],
                    heat_no: entry['Heat No'],
                    date: formatDate(entry['Date']),
                    customer_ref_no: entry['Customer Ref No'],
                    grade: entry['Grade'],
                    isdelete: false,
                    lorp_id: lorp_id,
                    submission_status: submissionStatus,
                  })

                  furnace_details.push({
                    id: entryId,
                    heat_on_time: convertToHHMM(entry['Heat ON Time']),
                    heat_tap_time: convertToHHMM(entry['Heat TAP Time']),
                    break_down_duration: convertToHHMM(
                      entry['Breakdown duration']
                    ),
                    heat_duration_actual: convertToHHMM(
                      entry['Heat Duration actual']
                    ),
                    heat_duration_calculated: convertToHHMM(
                      entry['Heat Duration calculated']
                    ),
                    tapping_temperature: formatDecimal(
                      entry['Tapping temperature']
                    ),
                    tapped_tonnage: formatDecimal(entry['Tapped tonnage']),
                    melt_rate_based_on_actual_duration: formatDecimal(
                      entry['Melt Rate based on actual duration']
                    ),
                    melt_rate_based_on_calculated_duration: formatDecimal(
                      entry['Melt Rate based on calculated duration']
                    ),
                    frequency: formatDecimal(entry['Frequency']),
                    voltage: formatDecimal(entry['Voltage']),
                    power_in_the_panel: formatDecimal(
                      entry['Power In the panel']
                    ),
                    capacitors_removed: formatDecimal(
                      entry['Capacitors Removed']
                    ),
                    total_power_consumed: formatDecimal(
                      entry['Total Power Consumed']
                    ),
                    energy_consumption_per_unit_ton_actual: formatDecimal(
                      entry['Energy consumption per unit Ton actual']
                    ),
                    energy_consumption_per_unit_ton_calculated: formatDecimal(
                      entry['Energy consumption per unit Ton calculated']
                    ),
                  })

                  charge_mix_details.push({
                    id: entryId,
                    scrap: formatDecimal(entry['Scrap']),
                    shredded: formatDecimal(entry['Shredded']),
                    pig_iron: formatDecimal(entry['Pig Iron']),
                    pooled_iron: formatDecimal(entry['Pooled Iron']),
                    hot_heel: formatDecimal(entry['Hot Heel']),
                    lump: formatDecimal(entry['Lump']),
                    pellet: formatDecimal(entry['Pellet']),
                    fines_lessthan_5mm: formatDecimal(
                      entry['Fines less than 5 mm']
                    ),
                    fines_lessthan_1mm: formatDecimal(
                      entry['Fines less than 1 mm']
                    ),
                    slag_crushers: formatDecimal(entry['Slag Crushers']),
                    tundish_or_slag_or_metalic_jam: formatDecimal(
                      entry['Tundish or Slag or Metalic Jam']
                    ),
                    total_charge: formatDecimal(entry['Total charge']),
                  })

                  let value
                  let key
                  if (entry['Anthracite']) {
                    key = 'anthracite'
                    value = formatDecimal(entry['Anthracite'])
                  } else if (entry['Carbon block']) {
                    key = 'carbon_block'
                    value = formatDecimal(entry['Carbon block'])
                  } else if (entry['CPC ']) {
                    key = 'cpc'
                    value = formatDecimal(entry['CPC '])
                  } else {
                    key = 'anthracite'
                    value = formatDecimal(entry['Anthracite'])
                  }

                  carbon.push({
                    id: entryId,
                    [key]: value,
                    met_coke: formatDecimal(entry['Met Coke']),
                    fifty_c_percentage: formatDecimal(entry['50 C%']),
                    eightyfive_c_percentage: formatDecimal(entry['85 C%']),
                    final_c_percentage: formatDecimal(entry['Final C%']),
                  })

                  alloying_addition.push({
                    id: entryId,
                    si_before_alloy_addition: formatPercentage(
                      entry['Si% Before addition']
                    ),
                    final_si: formatPercentage(entry['Final Si%']),
                    mn_before_alloy_addition: formatPercentage(
                      entry['Mn% before Addition']
                    ),
                    final_mn: formatPercentage(entry['Final Mn%']),
                    cr_before_alloy_addition: formatPercentage(
                      entry['Cr% before addition']
                    ),
                    final_cr: formatPercentage(entry['Final Cr%']),
                    si_mn: formatDecimal(entry['SiMn']),
                    mn_in_simn: entry['Mn % in SiMn'],
                    feMn: formatDecimal(entry['FeMn']),
                    feSi: formatDecimal(entry['FeSi']),
                    feCr: formatDecimal(entry['FeCr']),
                    mill_scale: formatDecimal(entry['Mill scale']),
                    any_other_alloy_addition: entry['Any other Alloy addition'],
                  })

                  const totalMnAdded = (
                    0.6 * parseFloat(entry['SiMn']) +
                    0.7 * parseFloat(entry['FeMn'])
                  ).toFixed(2)
                  const cpcPerTon = (
                    parseFloat(value) / parseFloat(entry['Total charge'])
                  ).toFixed(2)
                  const metCokePerTon = (
                    parseFloat(entry['Met Coke']) /
                    parseFloat(entry['Total charge'])
                  ).toFixed(2)
                  const SiMnPerTon = (
                    parseFloat(entry['SiMn']) /
                    parseFloat(entry['Total charge'])
                  ).toFixed(2)
                  const FeMnPerTon = (
                    parseFloat(entry['FeMn']) /
                    parseFloat(entry['Total charge'])
                  ).toFixed(2)
                  const FeSiPerTon = (
                    parseFloat(entry['FeSi']) /
                    parseFloat(entry['Total charge'])
                  ).toFixed(2)
                  const FeCrPerTon = (
                    parseFloat(entry['FeCr']) /
                    parseFloat(entry['Total charge'])
                  ).toFixed(2)
                  const MnDifference = (
                    parseFloat(entry['Final Mn%']) -
                    parseFloat(entry['Mn% before Addition'])
                  ).toFixed(2)
                  const CrDifference = (
                    parseFloat(entry['Final Cr%']) -
                    parseFloat(entry['Cr% before addition'])
                  ).toFixed(2)
                  const MnRecovery =
                    parseFloat(entry['Heat TAP Time']) <
                    parseFloat(entry['Heat ON Time'])
                      ? parseFloat(entry['Heat TAP Time']) +
                        24 -
                        parseFloat(entry['Heat ON Time'])
                      : parseFloat(entry['Heat TAP Time']) -
                        parseFloat(entry['Heat ON Time'])

                  console.log('Total Mn Added:', totalMnAdded)
                  console.log('CPC per ton:', cpcPerTon)
                  console.log('Met Coke per ton:', metCokePerTon)
                  console.log('SiMn per ton:', SiMnPerTon)
                  console.log('FeMn per ton:', FeMnPerTon)
                  console.log('FeSi per ton:', FeSiPerTon)
                  console.log('FeCr per ton:', FeCrPerTon)
                  console.log('Mn Difference:', MnDifference)
                  console.log('Cr Difference:', CrDifference)
                  console.log('Mn Recovery:', MnRecovery)

                  other_calculated_field.push({
                    id: entryId,
                    totalMnAdded: totalMnAdded,
                    cpcPerTon: cpcPerTon,
                    metCokePerTon: metCokePerTon,
                    SiMnPerTon: SiMnPerTon,
                    FeMnPerTon: FeMnPerTon,
                    FeSiPerTon: FeSiPerTon,
                    FeCrPerTon: FeCrPerTon,
                    MnDifference: MnDifference,
                    CrDifference: CrDifference,
                    MnRecovery: MnRecovery,
                  })

                  other_parameters.push({
                    id: entryId,
                    inlet_water_temp: formatDecimal(entry['Inlet water temp.']),
                    outlet_water_temp: formatDecimal(
                      entry['Outlet Water temp.']
                    ),
                    water_pr: formatDecimal(entry['Water Pr.']),
                    remarks: entry['Remarks'],
                  })
                }
              })

              const jsonDataexcel = Data.map((entry, index) => ({
                general_details: general_details[index],
                furnace_details: furnace_details[index],
                charge_mix_details: charge_mix_details[index],
                carbon: carbon[index],
                alloying_addition: alloying_addition[index],
                other_calculated_varialble: other_calculated_field[index],
                other_parameters: other_parameters[index],
              }))

              console.log('jsonexxcel', jsonDataexcel)
              console.log('jsonexxcel')

              axios
                .post(
                  API.EXCEL_INSERT,
                  { jsonDataexcel: jsonDataexcel, datafile_id: datafile_id },
                  {
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${access_token}`,
                    },
                  }
                )
                .then((excelresponse) => {
                  console.log(
                    'Excel data inserted successfully:',
                    excelresponse
                  )
                  setShowModals(false)
                  if (excelresponse.status === 200) {
                    setLoading(true)
                    AlertDialog({
                      type: 'success',
                      title: 'Success',
                      text: 'Excel data inserted successfully!',
                      confirmButtonText: 'Ok',
                      onConfirm: async () => {
                        try {
                          await fetchCards()
                        } catch (error) {
                          console.error('Error fetching cards:', error)
                        } finally {
                          setLoading(false)
                        }
                      },
                    })
                  } else {
                    console.error('Error saving data:', response.data)
                    AlertDialog({
                      type: 'warning',
                      title: 'Error',
                      text: 'Failed to save data',
                    })
                  }
                })
                .catch((error) => {
                  console.log(
                    'An error occurred while inserting Excel data:',
                    error
                  )
                })
            } else {
              alert('Please attach the proper template')
              setShowModals(false)
              return
            }
            if (response.status === 200) {
              AlertDialog({
                type: 'success',
                title: 'Success',
                text: 'Excel data inserted successfully!',
                confirmButtonText: 'Ok',
                onConfirm: async () => {
                  window.location.reload()
                },
              })
            } else {
              console.error('Error saving data:', response.data)
              AlertDialog({
                type: 'warning',
                title: 'Error',
                text: 'Failed to save data',
              })
            }
          }
        } else if (template_id === 2) {
          await handleExtractData(template_id, file)
          const extractedData = JSON.parse(
            localStorage.getItem('extractedData')
          )
          console.log('extractedData', extractedData)
          const predefinedHeaders = [
            'S.No.',
            'Furnace No.',
            'Crucible No.',
            'Campaign No.',
            'Lining or Patching',
            'Date of Completion lining or Patching',
            'Fce prod start date',
            'Fce prod start time',
            'Fce prod down date',
            'Fce prod down time',
            'Life',
            'Average Heat size',
            'Total Furnace Running Hours',
            'Total Production',
            'Total Furnace Running Hours',
            'Bottom Product',
            'Sidewall product 1 ',
            'Sidewall product 2',
            'Top Locking product',
            'Topping Mass Product',
            'Side wall 1  Coverage upto X%',
            'Side wall 2 Coverage upto 100%',
            'NRM consumption\r\nbottom',
            'NRM consumption Sidewall 1',
            'NRM consumption Sidewall 2',
            'NRM consumption at Top Locking',
            'Topping mass consumption',
            'Total NRM  consumption sidewall ',
            'NRM Specific consumption',
            'Former Dimension',
            'Coil coat ID',
            'Fce Ht.',
            'Castable top Ht.',
            'Bottom ht.',
            'Total Sintering time ',
            'holding time ',
            'holding temp ',
            'Reason for Down',
            'IR no. ',
            'Job Done by',
            'Remarks',
          ]
          const extractedHeaders = extractedData.headers
          function arraysEqual(arr1, arr2) {
            if (arr1.length !== arr2.length) return false
            for (let i = 0; i < arr1.length; i++) {
              if (arr1[i] !== arr2[i]) return false
            }

            return true
          }

          const headersMatch = arraysEqual(predefinedHeaders, extractedHeaders)
          if (headersMatch) {
            jsonData.filename = file.name
            jsonData.status = 'Submitted'
            const response = await axios.post(API.UPLOAD_DATAFILE, jsonData, {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            })
            const responseData = response.data
            const datafile_id = responseData.data.id
            localStorage.setItem('datafile_id', datafile_id)
            const Data = extractedData.data

            if (datafile_id && Data) {
              const lining_reference = []
              const production_summary = []
              const lining_material_product_details = []
              const lining_material_consumption = []
              const dimensional_parameters = []
              const sintering_parameters = []
              const convertToHHMM = (decimalTime) => {
                const totalHours = decimalTime * 24
                const hours = Math.floor(totalHours)
                const minutes = Math.round((totalHours - hours) * 60)
                const formattedHours = String(hours)
                const formattedMinutes = String(minutes).padStart(2, '0')
                return `${formattedHours}:${formattedMinutes}`
              }

              const formatDecimal = (value) => {
                // Ensure at least 5 digits before the decimal point
                let integerValue = Math.abs(value)
                if (integerValue >= 100000) {
                  integerValue = Math.round(integerValue / 1000) * 1000 // Round off to the nearest thousand
                }

                if (!Number.isInteger(value)) {
                  return Number(value).toFixed(2)
                } else {
                  return `${value}.00`
                }
              }

              const convertDecimalToTime = (value) => {
                const totalMinutes = value * 24 * 60
                const hours = Math.floor(totalMinutes / 60)
                const minutes = Math.floor(totalMinutes % 60)
                const seconds = Math.floor((totalMinutes % 1) * 60)

                // Formatting to ensure two digits
                const formattedHours = String(hours).padStart(2, '0')
                const formattedMinutes = String(minutes).padStart(2, '0')
                const formattedSeconds = String(seconds).padStart(2, '0')

                return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
              }

              const convertToDaysInHours = (value) => {
                // Convert the value to a string
                const timeStr = String(value)
                // Find the position of the colon
                const colonIndex = timeStr.indexOf(':')

                // Extract hours and minutes as substrings
                const hoursStr = timeStr.substring(0, colonIndex)
                const minutesStr = timeStr.substring(colonIndex + 1)

                // Parse the hours and minutes to integers
                const hours = parseInt(hoursStr, 10)
                const minutes = parseInt(minutesStr, 10)

                // Convert the total hours to days
                const days = hours / 24

                // Format the days as a decimal to two places
                const totalHoursInDays = days.toFixed(2)

                // Return the result
                return totalHoursInDays
              }

              const formatPercentage = (value) => {
                const formattedPercentage = (value * 100).toFixed(2) + '%'
                return `${formattedPercentage}`
              }

              const formatDate = (value) => {
                // Convert value to a JavaScript Date object
                const date = new Date(
                  value * 24 * 60 * 60 * 1000 + Date.UTC(1899, 11, 30)
                )

                // Extract day, month, and year from the Date object
                const day = date.getUTCDate()
                const month = date.getUTCMonth() + 1 // Month is zero-based
                const year = date.getUTCFullYear()

                // Format day and month with single digits if necessary
                const formattedDay = day < 10 ? '0' + day : day
                const formattedMonth = month < 10 ? '0' + month : month

                // Return the date in the desired format
                return `${formattedMonth}/${formattedDay}/${year}`
              }

              await Promise.all(
                Data.map(async (entry) => {
                  const entryId = uuidv4()
                  const customerName = await getCustomerName()
                  // const entryId = uuidv4();
                  const isEmptyGeneralDetails =
                    !entry['Furnace No.'] &&
                    !entry['Crucible No.'] &&
                    !entry['Campaign No.'] &&
                    !entry['Lining or Patching']

                  function getAbbreviatedLiningType(liningType) {
                    if (!liningType) return '' // Check if liningType is undefined or empty
                    let abbreviated = ''
                    liningType.split(' ').forEach((word) => {
                      abbreviated += word.charAt(0).toUpperCase()
                    })
                    return abbreviated
                  }

                  const lorp_id =
                    customerName +
                    (entry['Furnace No.'] ? entry['Furnace No.'] : '') +
                    '-' +
                    (entry['Crucible No.'] ? entry['Crucible No.'] : '') +
                    '-' +
                    (entry['Campaign No.'] ? entry['Campaign No.'] : '') +
                    '-' +
                    getAbbreviatedLiningType(entry['Lining or Patching'])

                  let submissionStatus = ''
                  if (
                    currentUser.role.role_id === 3 ||
                    currentUser.role.role_id === 4
                  ) {
                    submissionStatus = 'approved'
                  } else {
                    submissionStatus = 'submitted'
                  }
                  if (!isEmptyGeneralDetails) {
                    lining_reference.push({
                      id: entryId,
                      'S.No.': entry['S.No.'],
                      furnace_no: entry['Furnace No.'],
                      crucible_no: entry['Crucible No.'],
                      campaign_no: entry['Campaign No.'],
                      lining_or_patching: entry['Lining or Patching'],
                      date_of_completion_lining_or_patching: formatDate(
                        entry['Date of Completion lining or Patching']
                      ),
                      lorp_id: lorp_id,
                      submission_status: submissionStatus,
                    })

                    production_summary.push({
                      id: entryId,
                      fce_prod_start_date: formatDate(
                        entry['Fce prod start date']
                      ),
                      fce_prod_start_time: convertDecimalToTime(
                        entry['Fce prod start time']
                      ),
                      fce_prod_down_date: formatDate(
                        entry['Fce prod down date']
                      ),
                      fce_prod_down_time: convertDecimalToTime(
                        entry['Fce prod down time']
                      ),
                      life: entry['Life'],
                      average_heat_size: formatDecimal(
                        entry['Average Heat size']
                      ),
                      total_furnace_running_hours:
                        entry['Total Furnace Running Hours'],
                      total_production: formatDecimal(
                        entry['Total Production']
                      ),
                      total_furnace_running_hours:
                        entry['Total Furnace Running Hours'],
                    })

                    lining_material_product_details.push({
                      id: entryId,
                      bottom_product: entry['Bottom Product'],
                      sidewall_product_one: entry['Sidewall product 1 '],
                      sidewall_product_two: entry['Sidewall product 2'],
                      top_locking_product: entry['Top Locking product'],
                      topping_mass_product: entry['Topping Mass Product'],
                      sidewall_one_coverage_upto_x: formatPercentage(
                        entry['Side wall 1  Coverage upto X%']
                      ),
                      sidewall_two_coverage_upto_100: formatPercentage(
                        entry['Side wall 2 Coverage upto 100%']
                      ),
                    })

                    lining_material_consumption.push({
                      id: entryId,

                      nrm_consumption_bottom: formatDecimal(
                        entry['NRM consumption\r\nbottom']
                      ),
                      nrm_consumption_sidewall_one: formatDecimal(
                        entry['NRM consumption Sidewall 1']
                      ),
                      nrm_consumption_sidewall_two: formatDecimal(
                        entry['NRM consumption Sidewall 2']
                      ),
                      nrm_consumption_top_locking: formatDecimal(
                        entry['NRM consumption at Top Locking']
                      ),
                      topping_mass_consumption: formatDecimal(
                        entry['Topping mass consumption']
                      ),
                      total_nrm_consumption_sidewall_one: formatDecimal(
                        entry['Total NRM  consumption sidewall ']
                      ),
                      nrm_specific_consumption: formatDecimal(
                        entry['NRM Specific consumption']
                      ),
                    })

                    dimensional_parameters.push({
                      id: entryId,
                      former_dimension: entry['Former Dimension'],
                      coil_coat_id: formatDecimal(entry['Coil coat ID']),
                      fce_ht: formatDecimal(entry['Fce Ht.']),
                      costable_top_ht: formatDecimal(entry['Castable top Ht.']),
                      bottom_ht: formatDecimal(entry['Bottom ht.']),
                    })

                    sintering_parameters.push({
                      id: entryId,
                      total_sintering_time: convertToHHMM(
                        entry['Total Sintering time ']
                      ),
                      holding_time: convertToHHMM(entry['holding time ']),
                      holding_temp: entry['holding temp '],
                      reason_for_down: entry['Reason for Down'],
                      IR_no: entry['IR no. '],
                      remarks: entry['Remarks'],
                    })
                  }
                })
              )

              const jsonDataexcel = Data.map((entry, index) => ({
                lining_reference: lining_reference[index],
                production_summary: production_summary[index],
                lining_material_product_details:
                  lining_material_product_details[index],
                lining_material_consumption: lining_material_consumption[index],
                dimensional_parameters: dimensional_parameters[index],
                sintering_parameters: sintering_parameters[index],
              }))

              console.log('jsonexxcel', jsonDataexcel)
              console.log('jsonexxcel')

              axios
                .post(
                  API.EXCEL_INSERT_NRM,
                  { jsonDataexcel: jsonDataexcel, datafile_id: datafile_id },
                  {
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${access_token}`,
                    },
                  }
                )
                .then((excelresponse) => {
                  console.log(
                    'Excel data inserted successfully:',
                    excelresponse
                  )
                  setShowModals(false)
                  if (excelresponse.status === 200) {
                    AlertDialog({
                      type: 'success',
                      title: 'Success',
                      text: 'Excel data inserted successfully!',
                      confirmButtonText: 'Ok',
                      onConfirm: async () => {
                        window.location.reload()
                      },
                    })
                  } else {
                    console.error('Error saving data:', response.data)
                    AlertDialog({
                      type: 'warning',
                      title: 'Error',
                      text: 'Failed to save data',
                    })
                  }
                })
                .catch((error) => {
                  console.log(
                    'An error occurred while inserting Excel data:',
                    error
                  )
                  // Optionally, you can handle the error state here
                })
            }
          } else {
            alert('Please attach the proper template')
            setShowModals(false)
            return
          }
        } else if (template_id === 4) {
          await handleExtractData(template_id, file)
          const extractedData = JSON.parse(
            localStorage.getItem('extractedData')
          )
          console.log('extractedData', extractedData)
          console.log('template_id', template_id)
          const predefinedHeaders = [
            'Name of Customer',
            'Contact Person',
            'Product Details',
            'End Product of the Plant',
            'Grades of Steel Produce',
            'Ratio of MS Grade',
            'Furnace Details',
            'Total No. of Panel',
            'Fce. Capacity in which need to trial',
            'Avg. Heat Size (total MT per heat)',
            'Power rating of Fce.',
            'Is Fce is running with full power',
            'Tap to tap time',
            'Sintering Panel',
            'Power rating (Sintering panel)',
            'Total No. of Heats / day',
            'Avg. Power Consumption / MT of Billet    (only Fce)     ',
            'LRF/AOD',
            'Frequency Range ',
            'Maximum tapping temperature',
            'Average tapping temperature',
            'Furnace voltage',
            'Furnace OEM ',
            'Furnace year of manufacture',
            'Coil grout / coat Supplier name',
            'Sintering time ',
            'Operation ',
            'Furnace Change over time',
            'Furnace Checking time',
            'Mild Steel Customers Only',
            'Deslagging by ',
            'use Magnet to remove the slag jam after every deslagging',
            'Avg. C% at 50% of Bath Metal',
            'Avg. C% at 80% of Bath Metal',
            'Final C% Before Tapping',
            'Mill Scale / heat',
            'No of times TILT the Fce for deslagging per heat',
            'Are they taking Liquid metal up to full level',
            'DRI Quality (Lumps / Pellets / Fines)',
            'Slag Generation per heat',
            'Power utilised',
            'Silica Price ,( Landed Price)',
            'CCM (Avg casting speed',
            'Casting time',
            'Tundish cap',
            'Min level of tundish',
            'Billet size in (LXHXT)',
            'No of sequence ',
            'CCM make - OEM',
            'CCM Preparation time in Hours',
            'Objective / Purpose of using NRM',
            'Variable Details for each customer',
            'Note:- Taper repair at heat no 4,8,13 with silica ramming mass. ',
            'Material consumption in taper repair per campaign-600-700kg',
            'time taken in one repair10-12 minutes',
            'Date of Data collection',
            'Mobile Number(s)',
            'Raw Material & Energy Details',
            'Types of Scrap',
            'Charge Mix Ratio (Scrap : PI : DRI)',
            'Yield of charge mix',
            'Profit / Kg ',
            'Unit Price of Power',
            'Furnace Dimension (Ref. below)',
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            'Inner Dia. of Coil ,(inch / mm)',
            'A (Inner Dia. after doing Coil Coat / Grout) ',
            'B (Former Outer Dia.)',
            'C Furnace height ',
            'E Bottom Taper Dia',
            'F (Bottom Outer Dia.)',
            'G (Former ht.)',
            'H (Bottom Lining Ht.)',
            'Total Power given to SMS ',
            'Lining Details',
            'Consumption for New lining',
            'Consumption for Patching/Side Lining',
            'New Lining  Life ',
            'Side/Patching lining life',
            'Total No. of Side lining with keeping same bottom',
            'Present Lining method',
            'Alloy and Stainless Steel Customers',
            'FeCr Consumption / heat',
            'Cr% in FeCr',
            'Final Cr %',
            'Cr Recovery',
            'Any other alloys additions',
            'Casting method',
            'Simn consumption/heat',
            'Mn % in Simn',
            'Mn Recovery',
            'Addition of SiMn (FC or ladle Ratio)',
            'Any other alloys additions',
            'Final Chemistry at Furnace (Range)',
            'Final Chemistry at Billet (Range)',
            'Agreement to share relevant plant data during trial evaluation of the proposed Heat King Refractory Lining Solution',
            'Variable Details for each customer',
            null,
            null,
            null,
          ]
          const extractedHeaders = extractedData.headers
          console.log('extractedHeaders', extractedHeaders)

          function arraysEqual(arr1, arr2) {
            // Check if any item in arr1 exists in arr2
            return arr1.some((item) => arr2.includes(item))
          }
          const headersMatch = arraysEqual(predefinedHeaders, extractedHeaders)
          if (headersMatch) {
            const jsonDatas = {
              filename: file.name,
              customer_id: jsonData.customer_id,
              user_id: jsonData.user_id,
              template_id: jsonData.template_id,
            }

            console.log(jsonDatas, 'working now')
            const response = await axios.post(
              API.UPLOAD_DATA_COLLECTION_DATAFILE,
              jsonDatas,
              {
                headers: {
                  Authorization: `Bearer ${access_token}`,
                },
              }
            )
            console.log('UPLOAD_DATAFILE', response)
            const responseData = response.data
            const datafile_id = responseData.data.id
            localStorage.setItem('datafile_id', datafile_id)
            // Await the extraction of data
            const Data = extractedData.data
            console.log('Datacollection Data', Data)

            if (datafile_id && Data) {
              const convertToHHMM = (decimalTime) => {
                const totalHours = decimalTime * 24
                const hours = Math.floor(totalHours)
                const minutes = Math.round((totalHours - hours) * 60)
                const formattedHours = String(hours)
                const formattedMinutes = String(minutes).padStart(2, '0')
                return `${formattedHours}:${formattedMinutes}`
              }

              const formatDecimal = (value) => {
                // Ensure at least 5 digits before the decimal point
                let integerValue = Math.abs(value)
                if (integerValue >= 100000) {
                  integerValue = Math.round(integerValue / 1000) * 1000 // Round off to the nearest thousand
                }

                if (!Number.isInteger(value)) {
                  return Number(value).toFixed(2)
                } else {
                  return `${value}.00`
                }
              }

              const formatPercentage = (value) => {
                const formattedPercentage = (value * 100).toFixed(2) + '%'
                return `${formattedPercentage}`
              }

              const formatDate = (value) => {
                // Convert value to a JavaScript Date object
                const date = new Date(
                  value * 24 * 60 * 60 * 1000 + Date.UTC(1899, 11, 30)
                )

                // Extract day, month, and year from the Date object
                const day = date.getUTCDate()
                const month = date.getUTCMonth() + 1 // Month is zero-based
                const year = date.getUTCFullYear()

                // Format day and month with single digits if necessary
                const formattedDay = day < 10 ? '0' + day : day
                const formattedMonth = month < 10 ? '0' + month : month

                // Return the date in the desired format
                return `${formattedMonth}/${formattedDay}/${year}`
              }

              let generalDetails = {}
              let productDetails = {}
              let furnaceDetails = {}
              let mildSteel = {}
              let rawMaterial = {}
              let furnaceDimension = {}
              let liningDetails = {}
              let alloyStainless = {}
              const generalDetailsSet = new Set()
              const productDetailsSet = new Set()
              const furnaceDetailsSet = new Set()
              const mildSteelSet = new Set()
              const rawMaterialSet = new Set()
              const furnaceDimensionSet = new Set()
              const liningDetailsSet = new Set()
              const alloyStainlessSet = new Set()

              Data.forEach((entry) => {
                if (entry['Name of Customer']) {
                  generalDetails.customer_name = entry['Name of Customer']
                }
                if (entry['Contact Person']) {
                  generalDetails.contact_person = entry['Contact Person']
                }
                if (entry['Date of Data collection']) {
                  generalDetails.date = entry['Date of Data collection']
                }
                if (entry['Mobile Number(s)']) {
                  generalDetails.mobile_number = entry['Mobile Number(s)']
                }
                //product details
                if (entry['End Product of the Plant']) {
                  productDetails.end_product = entry['End Product of the Plant']
                }
                if (entry['Grades of Steel Produce']) {
                  productDetails.steel_produce =
                    entry['Grades of Steel Produce']
                }
                if (entry['Ratio of MS Grade']) {
                  productDetails.ratio = entry['Ratio of MS Grade']
                }
                //furnace details
                if (entry['Total No. of Panel']) {
                  furnaceDetails.no_of_panels = entry['Total No. of Panel']
                }
                if (entry['Fce. Capacity in which need to trial']) {
                  furnaceDetails.fce_capacity =
                    entry['Fce. Capacity in which need to trial']
                }
                if (entry['Avg. Heat Size (total MT per heat)']) {
                  furnaceDetails.avg_heatsize =
                    entry['Avg. Heat Size (total MT per heat)']
                }
                if (entry['Power rating of Fce.']) {
                  furnaceDetails.power_rating = entry['Power rating of Fce.']
                }
                if (entry['Is Fce is running with full power']) {
                  furnaceDetails.fce_running =
                    entry['Is Fce is running with full power']
                }
                if (entry['Tap to tap time']) {
                  furnaceDetails.tap_time = entry['Tap to tap time']
                }
                if (entry['Sintering Panel']) {
                  furnaceDetails.sintering_panel = entry['Sintering Panel']
                }
                if (entry['Power rating (Sintering panel)']) {
                  furnaceDetails.power_rating_sintering =
                    entry['Power rating (Sintering panel)']
                }
                if (entry['Total No. of Heats / day']) {
                  furnaceDetails.no_of_heats_perday =
                    entry['Total No. of Heats / day']
                }
                if (
                  entry[
                    'Avg. Power Consumption / MT of Billet    (only Fce)     '
                  ]
                ) {
                  furnaceDetails.avg_power_consumption =
                    entry[
                      'Avg. Power Consumption / MT of Billet    (only Fce)     '
                    ]
                }
                if (entry['LRF/AOD']) {
                  furnaceDetails.lrf = entry['LRF/AOD']
                }
                if (entry['Frequency Range ']) {
                  furnaceDetails.frequency_range = entry['Frequency Range ']
                }
                if (entry['Maximum tapping temperature']) {
                  furnaceDetails.max_tapping_temp =
                    entry['Maximum tapping temperature']
                }
                if (entry['Average tapping temperature']) {
                  furnaceDetails.avg_tapping_temp =
                    entry['Average tapping temperature']
                }
                if (entry['Furnace voltage']) {
                  furnaceDetails.furnace_voltage = entry['Furnace voltage']
                }
                if (entry['Furnace OEM ']) {
                  furnaceDetails.furnace_oem = entry['Furnace OEM ']
                }
                if (entry['Furnace year of manufacture']) {
                  furnaceDetails.furnace_year =
                    entry['Furnace year of manufacture']
                }
                if (entry['Coil grout / coat Supplier name']) {
                  furnaceDetails.coil_grout =
                    entry['Coil grout / coat Supplier name']
                }
                if (entry['Sintering time ']) {
                  furnaceDetails.sintering_time = entry['Sintering time ']
                }
                if (entry['Operation ']) {
                  furnaceDetails.operation = entry['Operation ']
                }
                if (entry['Furnace Change over time']) {
                  furnaceDetails.change_overtime =
                    entry['Furnace Change over time']
                }
                if (entry['Furnace Checking time']) {
                  furnaceDetails.checking_time = entry['Furnace Checking time']
                }
                //mild steel customers
                if (entry['Deslagging by']) {
                  mildSteel.deslagging_by = entry['Deslagging by']
                }
                if (
                  entry[
                    'use Magnet to remove the slag jam after every deslagging'
                  ]
                ) {
                  mildSteel.using_magnet_or_not =
                    entry[
                      'use Magnet to remove the slag jam after every deslagging'
                    ]
                }
                if (entry['Avg. C% at 50% of Bath Metal']) {
                  mildSteel.avg_c_percent_at_50 =
                    entry['Avg. C% at 50% of Bath Metal']
                }
                if (entry['Avg. C% at 80% of Bath Metal']) {
                  mildSteel.avg_c_percent_at_80 =
                    entry['Avg. C% at 80% of Bath Metal']
                }
                if (entry['Final C% Before Tapping']) {
                  mildSteel.final_c_percent_beforeTapping =
                    entry['Final C% Before Tapping']
                }
                if (entry['Mill Scale / heat']) {
                  mildSteel.data_mill_scale = entry['Mill Scale / heat']
                }
                if (entry['No of times TILT the Fce for deslagging per heat']) {
                  mildSteel.tilt_count_for_deslagging =
                    entry['No of times TILT the Fce for deslagging per heat']
                }
                if (entry['Are they taking Liquid metal up to full level']) {
                  mildSteel.are_they_taking_liquid_metal =
                    entry['Are they taking Liquid metal up to full level']
                }
                if (entry['DRI Quality (Lumps / Pellets / Fines)']) {
                  mildSteel.dri_quality =
                    entry['DRI Quality (Lumps / Pellets / Fines)']
                }
                if (entry['Slag Generation per heat']) {
                  mildSteel.slag_generation_per_heat =
                    entry['Slag Generation per heat']
                }
                if (entry['Power utilised']) {
                  mildSteel.power_utilised = entry['Power utilised']
                }
                if (entry['Silica Price ,( Landed Price)']) {
                  mildSteel.silica_price =
                    entry['Silica Price ,( Landed Price)']
                }
                if (entry['CCM (Avg casting speed']) {
                  mildSteel.ccm = entry['CCM (Avg casting speed']
                }
                if (entry['Casting time']) {
                  mildSteel.casting_time = entry['Casting time']
                }
                if (entry['Tundish cap']) {
                  mildSteel.tundish_cap = entry['Tundish cap']
                }
                if (entry['Min level of tundish']) {
                  mildSteel.min_level_of_tundish = entry['Min level of tundish']
                }
                if (entry['Billet size in (LXHXT)']) {
                  mildSteel.billet_size = entry['Billet size in (LXHXT)']
                }
                if (entry['No of sequence ']) {
                  mildSteel.no_of_sequence = entry['No of sequence ']
                }
                if (entry['CCM make - OEM']) {
                  mildSteel.ccm_make = entry['CCM make - OEM']
                }
                if (entry['CCM Preparation time in Hours']) {
                  mildSteel.ccm_preparation_time =
                    entry['CCM Preparation time in Hours']
                }
                if (entry['Objective / Purpose of using NRM']) {
                  mildSteel.purpose_of_using_nrm =
                    entry['Objective / Purpose of using NRM']
                }
                //raw material
                if (entry['Types of Scrap']) {
                  rawMaterial.type_of_scrap = entry['Types of Scrap']
                }
                if (entry['Charge Mix Ratio (Scrap : PI : DRI)']) {
                  rawMaterial.charge_mix_ratio =
                    entry['Charge Mix Ratio (Scrap : PI : DRI)']
                }
                if (entry['Yield of charge mix']) {
                  rawMaterial.yield_of_charge_mix = entry['Yield of charge mix']
                }
                if (entry['Profit / Kg']) {
                  rawMaterial.profit = entry['Profit / Kg']
                }
                if (entry['Unit Price of Power']) {
                  rawMaterial.unit_price_of_power = entry['Unit Price of Power']
                }
                //furnace dimension
                if (entry['Inner Dia. of Coil ,(inch / mm)']) {
                  furnaceDimension.inner_dia_of_coil =
                    entry['Inner Dia. of Coil ,(inch m']
                }
                if (entry['A (Inner Dia. after doing Coil Coat / Grout) ']) {
                  furnaceDimension.inner_dia_after_coilcoat =
                    entry['A (Inner Dia. after doing Coil Coat / Grout) ']
                }
                if (entry['B (Former Outer Dia.)']) {
                  furnaceDimension.former_outer_dia =
                    entry['B (Former Outer Dia.)']
                }
                if (entry['C Furnace height ']) {
                  furnaceDimension.furnace_height = entry['C Furnace height ']
                }
                if (entry['E Bottom Taper Dia']) {
                  furnaceDimension.bottom_taper_dia =
                    entry['E Bottom Taper Dia']
                }
                if (entry['F (Bottom Outer Dia.)']) {
                  furnaceDimension.bottom_outer_dia =
                    entry['F (Bottom Outer Dia.)']
                }
                if (entry['G (Former ht.)']) {
                  furnaceDimension.former_ht = entry['G (Former ht.)']
                }
                if (entry['H (Bottom Lining Ht.)']) {
                  furnaceDimension.bottom_lining_ht =
                    entry['H (Bottom Lining Ht.)']
                }
                if (entry['Total Power given to SMS ']) {
                  furnaceDimension.total_power_given_to_ms =
                    entry['Total Power given to SMS']
                }
                //lining Details
                if (entry['Consumption for New lining']) {
                  liningDetails.consumption_for_new_lining =
                    entry['Consumption for New lining']
                }
                if (entry['Consumption for Patching/Side Lining']) {
                  liningDetails.consumption_for_patching =
                    entry['Consumption for Patching/Side Lining']
                }
                if (entry['New Lining Life']) {
                  liningDetails.new_lining_life = entry['New Lining Life']
                }
                if (entry['Side/Patching lining life']) {
                  liningDetails.patching_lining_life =
                    entry['Side/Patching lining life']
                }
                if (
                  entry['Total No. of Side lining with keeping same bottom']
                ) {
                  liningDetails.total_no_of_side_lining =
                    entry['Total No. of Side lining with keeping same bottom']
                }
                if (entry['Present Lining method']) {
                  liningDetails.present_lining_method =
                    entry['Present Lining method']
                }
                //alloy and steel
                if (entry['FeCr Consumption / heat']) {
                  alloyStainless.fecr_consumption =
                    entry['FeCr Consumption / heat']
                }
                if (entry['Cr% in FeCr']) {
                  alloyStainless.cr_percentage = entry['Cr% in FeCr']
                }
                if (entry['Final Cr %']) {
                  alloyStainless.final_cr_percentage = entry['Final Cr %']
                }
                if (entry['Cr Recovery']) {
                  alloyStainless.cr_recovery = entry['Cr Recovery']
                }
                if (entry['Any other alloys additions']) {
                  alloyStainless.alloy_addition =
                    entry['Any other alloys additions']
                }
                if (entry['Casting method']) {
                  alloyStainless.casting_method = entry['Casting method']
                }
                if (entry['Simn consumption/heat']) {
                  alloyStainless.simn_consumption =
                    entry['Simn consumption/heat']
                }
                if (entry['Mn % in Simn']) {
                  alloyStainless.mn_percentage = entry['Mn % in Simn']
                }
                if (entry['Mn Recovery']) {
                  alloyStainless.mn_recovery = entry['Mn Recovery']
                }
                if (entry['Addition of SiMn (FC or ladle Ratio)']) {
                  alloyStainless.addition_of_simn =
                    entry['Addition of SiMn (FC or ladle Ratio)']
                }
                if (entry['Any other alloys additions']) {
                  alloyStainless.any_other_alloy_addition =
                    entry['Any other alloys additions']
                }
                if (entry['Final Chemistry at Furnace (Range)']) {
                  alloyStainless.final_chemistry_at_furnace =
                    entry['Final Chemistry at Furnace (Range)']
                }
                if (entry['Final Chemistry at Billet (Range)']) {
                  alloyStainless.final_chemistry_at_billet =
                    entry['Final Chemistry at Billet (Range)']
                }
                if (
                  entry[
                    'Agreement to share relevant plant data during trial evaluation of the proposed Heat King Refractory Lining Solution'
                  ]
                ) {
                  alloyStainless.agreement =
                    entry[
                      'Agreement to share relevant plant data during trial evaluation of the proposed Heat King Refractory Lining Solution'
                    ]
                }
              })
              console.log(furnaceDetailsSet, 'furnaceDetailsSet123')

              if (Object.keys(generalDetails).length > 3) {
                generalDetailsSet.add(JSON.stringify(generalDetails))
              }
              if (Object.keys(productDetails).length > 2) {
                productDetailsSet.add(JSON.stringify(productDetails))
              }
              if (
                Object.keys(furnaceDetails).length >
                Object.keys(furnaceDetails).length - 1
              ) {
                furnaceDetailsSet.add(JSON.stringify(furnaceDetails))
              }
              if (
                Object.keys(mildSteel).length >
                Object.keys(mildSteel).length - 1
              ) {
                mildSteelSet.add(JSON.stringify(mildSteel))
              }
              if (
                Object.keys(rawMaterial).length >
                Object.keys(rawMaterial).length - 1
              ) {
                rawMaterialSet.add(JSON.stringify(rawMaterial))
              }
              if (
                Object.keys(furnaceDimension).length >
                Object.keys(furnaceDimension).length - 1
              ) {
                furnaceDimensionSet.add(JSON.stringify(furnaceDimension))
              }
              if (
                Object.keys(liningDetails).length >
                Object.keys(liningDetails).length - 1
              ) {
                liningDetailsSet.add(JSON.stringify(liningDetails))
              }
              if (
                Object.keys(alloyStainless).length >
                Object.keys(alloyStainless).length - 1
              ) {
                alloyStainlessSet.add(JSON.stringify(alloyStainless))
              }
              console.log(furnaceDetailsSet, 'furnaceDetailsSet')
              console.log(
                Object.keys(furnaceDetails).length,
                'furnaceDetailsfurnaceDetails'
              )

              const general_details = Array.from(generalDetailsSet).map(
                (entry) => JSON.parse(entry)
              )
              const product_details = Array.from(productDetailsSet).map(
                (entry) => JSON.parse(entry)
              )
              const furnace_details = Array.from(furnaceDetailsSet).map(
                (entry) => JSON.parse(entry)
              )
              console.log(furnace_details, 'furnace_detailsfurnace_details')
              const mild_steel = Array.from(mildSteelSet).map((entry) =>
                JSON.parse(entry)
              )
              const raw_material = Array.from(rawMaterialSet).map((entry) =>
                JSON.parse(entry)
              )
              const furnace_dimension = Array.from(furnaceDimensionSet).map(
                (entry) => JSON.parse(entry)
              )
              const lining_details = Array.from(liningDetailsSet).map((entry) =>
                JSON.parse(entry)
              )
              const alloy_stainless_steel = Array.from(alloyStainlessSet).map(
                (entry) => JSON.parse(entry)
              )

              let submissionStatus = ''
              if (
                currentUser.role.role_id === 3 ||
                currentUser.role.role_id === 4
              ) {
                submissionStatus = 'approved'
              } else {
                submissionStatus = 'submitted'
              }

              const jsonDataexcel = {
                general_details: general_details,
                product_details: product_details,
                furnace_details: furnace_details,
                mild_steel_customers_only: mild_steel,
                raw_material_energy_details: raw_material,
                furnace_dimension: furnace_dimension,
                lining_details: lining_details,
                alloy_stainless_steel_customers: alloy_stainless_steel,
              }
              console.log('jsonexxcel', jsonDataexcel)

              axios
                .post(
                  API.INSERT_DATA_COLLECTION_EXCEL,
                  {
                    jsonDataexcel: jsonDataexcel,
                    datafile_id: datafile_id,
                    submission_status: submissionStatus,
                  },
                  {
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${access_token}`,
                    },
                  },
                  {}
                )
                .then((excelresponse) => {
                  console.log(
                    'Excel data inserted successfully:',
                    excelresponse
                  )

                  setShowModals(false)
                  if (excelresponse.status === 200) {
                    AlertDialog({
                      type: 'success',
                      title: 'Success',
                      text: 'Excel data inserted successfully!',
                      confirmButtonText: 'Ok',
                      onConfirm: async () => {
                        window.location.reload()
                      },
                    })
                  } else {
                    console.error('Error saving data:', response.data)
                    AlertDialog({
                      type: 'warning',
                      title: 'Error',
                      text: 'Failed to save data',
                    })
                  }
                })
                .catch((error) => {
                  console.log(
                    'An error occurred while inserting Excel data:',
                    error
                  )
                })
            } else {
              alert('Please attach the proper template')
              setShowModals(false)
              return
            }
          }
        } else if (template_id === 5) {
          console.log('working now')
          await handleExtractData(template_id, file)
          const extractedData = JSON.parse(
            localStorage.getItem('extractedData')
          )
          console.log('extractedData', extractedData)
          console.log('template_id', template_id)
          const predefinedHeaders = [
            'Parameter',
            'Customer Details',
            'Customer Name',
            'Select Country',
            'Select currency',
            'Furnace Details (A)',
            'Furnace Capacity',
            'Furnace output in Tons-Sintering heat (Former size difference)',
            'New Lining Details (B)',
            'Ramming material used in new lining - 1',
            'Quantity of material required for new lining - 1',
            'Material used in spout and top  - 2',
            'Qty. of Material required for Spout & Top - 2',
            'Material used in spout and top  - 3',
            'Qty. of Material required for Spout & Top - 3',
            'Average New Lining life',
            'No of new lining',
            'Average heat size per New lining',
            'Side Lining Details (C)',
            'Ramming material used in side lining - 1',
            'Quantity of material required for side lining - 1',
            'Material used in spout and top  - 2',
            'Qty. of Material required for Spout & Top - 2',
            'Material used in spout and top  - 3',
            'Qty. of Material required for Spout & Top - 3',
            'Average Side lining life ',
            'No of Side Lining',
            'Average heat size per Side lining',
            'Patching Details (D)',
            'Ramming material used in patching - 1',
            'Quantity of material required for patching - 1',
            'Material used in spout and top  - 2',
            'Qty. of Material required for Spout & Top - 2',
            'Material used in spout and top  - 3',
            'Qty. of Material required for Spout & Top - 3',
            'Average Patching lining life ',
            'No of patching ',
            'Average heat size per Patching',
            'Repair details (E)',
            'Ramming material used in repairing - 1',
            'Quantity of material required for repairing - 1',
            'Material used in spout and top  - 2',
            'Qty. of Material required for Spout & Top - 2',
            'Material used in spout and top  - 3',
            'Qty. of Material required for Spout & Top - 3',
            'Cost Calculations (E)',
            'Average heat size in a campaign',
            'Average life in a campaign',
            'Total no of cycle in a campaign',
            'Total no of heats in a campaign',
            'Total Production per Campaign',
            'Ramming material Landed cost - 1',
            'Ramming material cost In a Campaign - 1',
            'Topping material Landed cost - 2',
            'Topping material cost in a Campaign - 2',
            'Topping material Landed cost - 3',
            'Topping material cost in a Campaign - 3',
            'Total cost in a campaign (Refractory)',
            'Total Refractory Consumption',
            'Specific Refractory Consumption (kg/T of steel)',
            'Cost of refractory per ton of steel produced',
            'Increase in cost due to NRM',
            'Total Savings due to NRM (S1 to S8)',
            'Relative Gain in cost due to NRM',
            'Parameter (S)',
            'S1 : Savings in Labor Cost',
            'Labor cost per NL / SL / PA',
            'Labor cost in a campaign',
            'Labor cost per ton of steel produced',
            'Savings In Labor Cost',
            'S2 : Savings in Former Cost ',
            'Former cost per piece',
            'Total former cost in a campaign',
            'Former cost per ton of steel produced',
            'Savings in Former cost',
            'S3 : Savings due to increased productivity',
            'Average Heat time (with / without hot heel for NRM)',
            'Total Available time in a month (30 days)',
            'Maximum No. of Heats in a month',
            'No. of Sintering Heats in a Month',
            'Extra time in sintering heat in min with Main Panel',
            'Total Down time in a month due to sintering',
            'Average Fce Checking Time ',
            'Frequency of furnace Checking as percentage',
            'Total down time in a month due to Fce Checking time',
            'Total down time in a month due to Change over ',
            'Total time available for Production in a Month',
            'Actual No.of heats in a month',
            'Actual Production in a month',
            'Production increase due to NRM',
            '% production increase in a month',
            'Profit Margin per ton of steel',
            'Cost benefit due to NRM in a month',
            'Saving due to increased productivity ',
            'S4 : Savings due to energy',
            'Cost per electricity unit',
            'Power savings per MT',
            'Cost Savings due to power consumption in KWh / MT of steel',
            'S5 : Savings due to Yield improvement',
            'Yield Benefit ',
            'Market price of Scrap or DRI per ton',
            'Savings due to yield benefit',
            'S6 : Savings in alloy ',
            'SiMn Savings ',
            'FeCr Savings',
            'Savings due to other alloys , if applicable  - (Al)',
            'Savings due to reduction in alloy addition',
            'S7 : Savings due to other tangible benefits ',
            'Savings due to Asbestos sheet ',
            'Savings due to Coil coat for repair ',
            'Savings due to avoiding antenna replacement',
            'Savings due to Sodium Silicate ',
            'Savings due to Slag Dumping',
            'Savings due to safety in operations',
            'Savings due to Other Tangible Benefit (F5)',
            'S8 : Savings due to other metallurgical benefits',
            'Savings due to Metallurgical Benefit (F6)',
            'S9 : Savings due to less number of sintering heats',
            'Percentage Scrap addition difference between sintering and normal heat',
            'Yield of overall chargemix',
            'Yield of DRI',
            'Market price of  DRI (which will replace Excess Scrap )',
            'Excess Scrap charged in Sintering heat',
            'No of sintering heats difference SRM - NRM',
            'Cost of Excess Scrap charged in Sintering heat',
            'Total Savings due to NRM (S1 to S8)',
            'Comments',
          ]
          const extractedHeaders = extractedData.headers
          console.log('extractedHeaders', extractedHeaders)

          function arraysEqual(arr1, arr2) {
            return arr1.some((item) => arr2.includes(item))
          }
          const headersMatch = arraysEqual(predefinedHeaders, extractedHeaders)
          if (headersMatch) {
            const jsonDatas = {
              filename: file.name,
              status: 'Submitted',
              customer_id: jsonData.customer_id,
              user_id: jsonData.user_id,
              template_id: jsonData.template_id,
            }

            console.log(jsonData, 'working now')
            const response = await axios.post(
              API.UPLOAD_DATA_COLLECTION_DATAFILE,
              jsonDatas,
              {
                headers: {
                  Authorization: `Bearer ${access_token}`,
                },
              }
            )
            console.log('UPLOAD_DATAFILE', response)
            const responseData = response.data
            const datafile_id = responseData.data.id
            localStorage.setItem('datafile_id', datafile_id)
            // Await the extraction of data
            const Data = extractedData.data
            console.log('Data', Data)

            if (datafile_id && Data) {
              console.log('working fin123e')

              let furnaceDetails = {}
              let newLiningDetails = {}
              let sideLiningDetails = {}
              let patchingDetails = {}
              let repairingDetails = {}
              let costCalculation = {}
              let laborCostDataObject = {}
              let formerCostDataObject = {}
              let increasedProductivityObject = {}
              let energyDataObject = {}
              let yieldImprovementObject = {}
              let alloyObject = {}
              let tangibleBenefitsObject = {}
              let metallurgicalBenefitsObject = {}
              // let sinteringHeats = {};
              //furnace details
              let furnace_capacity
              let furnace_output
              //new lining

              let ramming_material_new_lining_1
              let qty_new_lining_1
              let material_used_spout_and_top_2
              let qty_spout_and_top_2
              let material_used_spout_and_top_3
              let qty_spout_and_top_3
              let average_new_lining_life
              let no_of_new_lining
              let avg_heat_size

              //side lining
              let ramming_material_side_lining_1
              let qty_side_lining_1
              let material_used_top_spout_and_top_3
              let average_side_lining_life
              let no_of_side_lining

              // patching Details
              let ramming_material_patching_1
              let qty_patching_1
              let average_patching_lining_life
              let no_of_patching
              let avg_heat_size_per_patching

              // repair details
              let ramming_material_repairing_1
              let qty_repairing_1

              //labor cost
              let labor_cost_per_nl
              let labor_cost_campaign
              let labor_cost_per_steel_produced
              let savings_labor_cost

              // former cost
              let former_cost_per_piece
              let former_cost_campaign
              let former_cost_per_steel_produced
              let savings_former_cost

              // increasedProuctivity

              let totDownTime
              let cost_benefit
              let maxNoOfHeats
              let avg_heat_time
              let noOfSintering
              let profit_margin
              let actualNoOfHeats
              let actualProdInMonth
              let totalAvailableTime
              let totDownTimeDueToFce
              let extra_time_sintering
              let frequency_of_furnace
              let totTimeAvailableProd
              let avg_fce_checking_time
              let total_down_time_change_over
              let percentage_production_increase
              let production_increase_due_to_nrm
              let saving_due_to_increased_productivity

              // energy
              let power_savings_per_mt
              let cost_per_electricity_unit
              let savings_power_consumption

              // yield
              let yield_benefit
              let market_price_of_scrap
              let savings_due_to_yield_benefit

              //alloy
              let fecr_savings
              let simn_savings
              let savings_due_to_reduction
              let savings_due_to_other_alloy

              // tangible benefits
              let slag_dumping
              let asbestos_sheet
              let sodium_silicate
              let antenna_replacement
              let coil_coat_for_repair
              let safety_in_operations
              let other_tangible_benefits

              // metallurgical benefit
              let savings_metallurgical

              // cost calculation
              let avg_life
              let tot_prod
              let inc_in_cost
              let no_of_heats
              let tot_savings
              let no_of_cycles
              let relative_gain
              let tot_refractory
              let avg_in_heat_size
              let cost_in_campaign_2
              let cost_in_campaign_3
              let cost_of_refractory
              let topping_material_2
              let topping_material_3
              let tot_cost_in_campaign
              let spec_refractory_consumpt
              let ramming_cost_in_campaign_1
              let ramming_material_landed_cost_1

              Data.forEach((entry) => {
                //furnace Details
                if (entry['Furnace Capacity']) {
                  furnace_capacity = entry['Furnace Capacity'].split(',')
                }
                if (
                  entry[
                    'Furnace output in Tons - Sintering heat (As per Former size)'
                  ]
                ) {
                  furnace_output =
                    entry[
                      'Furnace output in Tons - Sintering heat (As per Former size)'
                    ].split(',')
                }

                if (
                  furnace_output &&
                  furnace_output.length > 0 &&
                  furnace_capacity &&
                  furnace_capacity.length > 0
                ) {
                  furnaceDetails = {
                    nrm: {
                      furnace_output: furnace_output[1],
                      furnace_capacity: furnace_capacity[1],
                    },
                    basic: {
                      furnace_output: furnace_output[0],
                      furnace_capacity: furnace_capacity[0],
                    },
                  }
                }

                //new lining
                if (entry['Ramming material used in new lining - 1']) {
                  ramming_material_new_lining_1 =
                    entry['Ramming material used in new lining - 1'].split(',')
                  console.log(
                    'ramming_material_new_lining_1:',
                    ramming_material_new_lining_1
                  )
                }
                if (entry['Quantity of material required for new lining - 1']) {
                  qty_new_lining_1 =
                    entry[
                      'Quantity of material required for new lining - 1'
                    ].split(',')
                  console.log('qty_new_lining_1:', qty_new_lining_1)
                }
                if (entry['Material used in spout and top  - 2']) {
                  material_used_spout_and_top_2 =
                    entry['Material used in spout and top  - 2'].split(',')
                  console.log(
                    'material_used_spout_and_top_2:',
                    material_used_spout_and_top_2
                  )
                }
                if (entry['Qty. of Material required for Spout & Top - 2']) {
                  qty_spout_and_top_2 =
                    entry[
                      'Qty. of Material required for Spout & Top - 2'
                    ].split(',')
                  console.log('qty_spout_and_top_2:', qty_spout_and_top_2)
                }
                if (entry['Material used in spout and top  - 3']) {
                  material_used_spout_and_top_3 =
                    entry['Material used in spout and top  - 3'].split(',')
                  console.log(
                    'material_used_spout_and_top_3:',
                    material_used_spout_and_top_3
                  )
                }
                if (entry['Qty. of Material required for Spout & Top - 3']) {
                  qty_spout_and_top_3 =
                    entry[
                      'Qty. of Material required for Spout & Top - 3'
                    ].split(',')
                  console.log('qty_spout_and_top_3:', qty_spout_and_top_3)
                }
                if (entry['Average New Lining life']) {
                  average_new_lining_life =
                    entry['Average New Lining life'].split(',')
                  console.log(
                    'average_new_lining_life:',
                    average_new_lining_life
                  )
                }
                if (entry['No of new lining']) {
                  no_of_new_lining = entry['No of new lining'].split(',')
                  console.log('no_of_new_lining:', no_of_new_lining)
                }
                if (entry['Average heat size per New lining']) {
                  avg_heat_size =
                    entry['Average heat size per New lining'].split(',')
                  console.log('avg_heat_size:', avg_heat_size)
                }
                if (
                  ramming_material_new_lining_1 &&
                  ramming_material_new_lining_1.length > 0 &&
                  qty_new_lining_1 &&
                  qty_new_lining_1.length > 0 &&
                  material_used_spout_and_top_2 &&
                  material_used_spout_and_top_2.length > 0 &&
                  qty_spout_and_top_2 &&
                  qty_spout_and_top_2.length > 0 &&
                  material_used_spout_and_top_3 &&
                  material_used_spout_and_top_3.length > 0 &&
                  qty_spout_and_top_3 &&
                  qty_spout_and_top_3.length > 0 &&
                  average_new_lining_life &&
                  average_new_lining_life.length > 0 &&
                  no_of_new_lining &&
                  no_of_new_lining.length > 0 &&
                  avg_heat_size &&
                  avg_heat_size.length > 0
                ) {
                  newLiningDetails = {
                    nrm: {
                      avg_heat_size: avg_heat_size[1],
                      no_of_new_lining: no_of_new_lining[1],
                      qty_new_lining_1: qty_new_lining_1[1],
                      qty_spout_and_top_2: qty_spout_and_top_2[1],
                      qty_spout_and_top_3: qty_spout_and_top_3[1],
                      average_new_lining_life: average_new_lining_life[1],
                      material_used_spout_and_top_2:
                        material_used_spout_and_top_2[1],
                      material_used_spout_and_top_3:
                        material_used_spout_and_top_3[1],
                      ramming_material_new_lining_1:
                        ramming_material_new_lining_1[1],
                    },
                    basic: {
                      avg_heat_size: avg_heat_size[0],
                      no_of_new_lining: no_of_new_lining[0],
                      qty_new_lining_1: qty_new_lining_1[0],
                      qty_spout_and_top_2: qty_spout_and_top_2[0],
                      qty_spout_and_top_3: qty_spout_and_top_3[0],
                      average_new_lining_life: average_new_lining_life[0],
                      material_used_spout_and_top_2:
                        material_used_spout_and_top_2[0],
                      material_used_spout_and_top_3:
                        material_used_spout_and_top_3[0],
                      ramming_material_new_lining_1:
                        ramming_material_new_lining_1[0],
                    },
                  }
                } else {
                  console.log(
                    'Conditions not met for creating newLining object.'
                  )
                }
                // side lining
                if (entry['Ramming material used in side lining - 1']) {
                  ramming_material_side_lining_1 =
                    entry['Ramming material used in side lining - 1'].split(',')
                  console.log(
                    'ramming_material_side_lining_1:',
                    ramming_material_side_lining_1
                  )
                }
                if (
                  entry['Quantity of material required for side lining - 1']
                ) {
                  qty_side_lining_1 =
                    entry[
                      'Quantity of material required for side lining - 1'
                    ].split(',')
                  console.log('qty_side_lining_1:', qty_side_lining_1)
                }
                if (entry['Material used in spout and top  - 2']) {
                  material_used_spout_and_top_2 =
                    entry['Material used in spout and top  - 2'].split(',')
                  console.log(
                    'material_used_spout_and_top_2:',
                    material_used_spout_and_top_2
                  )
                }
                if (entry['Qty. of Material required for Spout & Top - 2']) {
                  qty_spout_and_top_2 =
                    entry[
                      'Qty. of Material required for Spout & Top - 2'
                    ].split(',')
                  console.log('qty_spout_and_top_2:', qty_spout_and_top_2)
                }
                if (entry['Material used in spout and top  - 3']) {
                  material_used_top_spout_and_top_3 =
                    entry['Material used in spout and top  - 3'].split(',')
                  console.log(
                    'material_used_top_spout_and_top_3:',
                    material_used_top_spout_and_top_3
                  )
                }
                if (entry['Qty. of Material required for Spout & Top - 3']) {
                  qty_spout_and_top_3 =
                    entry[
                      'Qty. of Material required for Spout & Top - 3'
                    ].split(',')
                  console.log('qty_spout_and_top_3:', qty_spout_and_top_3)
                }
                if (entry['Average Side lining life ']) {
                  average_side_lining_life =
                    entry['Average Side lining life '].split(',')
                  console.log(
                    'average_side_lining_life:',
                    average_side_lining_life
                  )
                }
                if (entry['No of Side Lining']) {
                  no_of_side_lining = entry['No of Side Lining'].split(',')
                  console.log('no_of_side_lining:', no_of_side_lining)
                }
                if (entry['Average heat size per Side lining']) {
                  avg_heat_size =
                    entry['Average heat size per Side lining'].split(',')
                  console.log('avg_heat_size:', avg_heat_size)
                }

                if (
                  ramming_material_side_lining_1 &&
                  ramming_material_side_lining_1.length > 0 &&
                  qty_side_lining_1 &&
                  qty_side_lining_1.length > 0 &&
                  material_used_spout_and_top_2 &&
                  material_used_spout_and_top_2.length > 0 &&
                  qty_spout_and_top_2 &&
                  qty_spout_and_top_2.length > 0 &&
                  material_used_top_spout_and_top_3 &&
                  material_used_top_spout_and_top_3.length > 0 &&
                  qty_spout_and_top_3 &&
                  qty_spout_and_top_3.length > 0 &&
                  average_side_lining_life &&
                  average_side_lining_life.length > 0 &&
                  no_of_side_lining &&
                  no_of_side_lining.length > 0 &&
                  avg_heat_size &&
                  avg_heat_size.length > 0
                ) {
                  sideLiningDetails = {
                    nrm: {
                      avg_heat_size: avg_heat_size[1],
                      no_of_side_lining: no_of_side_lining[1],
                      qty_side_lining_1: qty_side_lining_1[1],
                      qty_spout_and_top_2: qty_spout_and_top_2[1],
                      qty_spout_and_top_3: qty_spout_and_top_3[1],
                      average_side_lining_life: average_side_lining_life[1],
                      material_used_spout_and_top_2:
                        material_used_spout_and_top_2[1],
                      material_used_spout_and_top_3:
                        material_used_top_spout_and_top_3[1],
                      ramming_material_side_lining_1:
                        ramming_material_side_lining_1[1],
                    },
                    basic: {
                      avg_heat_size: avg_heat_size[0],
                      no_of_side_lining: no_of_side_lining[0],
                      qty_side_lining_1: qty_side_lining_1[0],
                      qty_spout_and_top_2: qty_spout_and_top_2[0],
                      qty_spout_and_top_3: qty_spout_and_top_3[0],
                      average_side_lining_life: average_side_lining_life[0],
                      material_used_spout_and_top_2:
                        material_used_spout_and_top_2[0],
                      material_used_spout_and_top_3:
                        material_used_top_spout_and_top_3[0],
                      ramming_material_side_lining_1:
                        ramming_material_side_lining_1[0],
                    },
                  }
                } else {
                  console.log(
                    'Conditions not met for creating sideLining object.'
                  )
                }

                // patching Details

                if (entry['Ramming material used in patching - 1']) {
                  ramming_material_patching_1 =
                    entry['Ramming material used in patching - 1'].split(',')
                  console.log(
                    'ramming_material_patching_1:',
                    ramming_material_patching_1
                  )
                }
                if (entry['Quantity of material required for patching - 1']) {
                  qty_patching_1 =
                    entry[
                      'Quantity of material required for patching - 1'
                    ].split(',')
                  console.log('qty_patching_1:', qty_patching_1)
                }
                if (entry['Material used in spout and top  - 2']) {
                  material_used_spout_and_top_2 =
                    entry['Material used in spout and top  - 2'].split(',')
                  console.log(
                    'material_used_spout_and_top_2:',
                    material_used_spout_and_top_2
                  )
                }
                if (entry['Qty. of Material required for Spout & Top - 2']) {
                  qty_spout_and_top_2 =
                    entry[
                      'Qty. of Material required for Spout & Top - 2'
                    ].split(',')
                  console.log('qty_spout_and_top_2:', qty_spout_and_top_2)
                }
                if (entry['Material used in spout and top  - 3']) {
                  material_used_spout_and_top_3 =
                    entry['Material used in spout and top  - 3'].split(',')
                  console.log(
                    'material_used_spout_and_top_3:',
                    material_used_spout_and_top_3
                  )
                }
                if (entry['Qty. of Material required for Spout & Top - 3']) {
                  qty_spout_and_top_3 =
                    entry[
                      'Qty. of Material required for Spout & Top - 3'
                    ].split(',')
                  console.log('qty_spout_and_top_3:', qty_spout_and_top_3)
                }
                if (entry['Average Patching lining life ']) {
                  average_patching_lining_life =
                    entry['Average Patching lining life '].split(',')
                  console.log(
                    'average_patching_lining_life:',
                    average_patching_lining_life
                  )
                }
                if (entry['No of patching ']) {
                  no_of_patching = entry['No of patching '].split(',')
                  console.log('no_of_patching:', no_of_patching)
                }
                if (entry['Average heat size per Patching']) {
                  avg_heat_size_per_patching =
                    entry['Average heat size per Patching'].split(',')
                  console.log(
                    'avg_heat_size_per_patching:',
                    avg_heat_size_per_patching
                  )
                }
                if (
                  ramming_material_patching_1 &&
                  ramming_material_patching_1.length > 0 &&
                  qty_patching_1 &&
                  qty_patching_1.length > 0 &&
                  material_used_spout_and_top_2 &&
                  material_used_spout_and_top_2.length > 0 &&
                  material_used_spout_and_top_3 &&
                  material_used_spout_and_top_3.length > 0 &&
                  average_patching_lining_life &&
                  average_patching_lining_life.length > 0 &&
                  no_of_patching &&
                  no_of_patching.length > 0 &&
                  avg_heat_size_per_patching &&
                  avg_heat_size_per_patching.length > 0 &&
                  qty_spout_and_top_3 &&
                  qty_spout_and_top_3.length > 0 &&
                  qty_spout_and_top_2 &&
                  qty_spout_and_top_2.length > 0
                ) {
                  patchingDetails = {
                    basic: {
                      avg_heat_size_per_patching: avg_heat_size_per_patching[0],
                      no_of_patching: no_of_patching[0],
                      qty_patching_1: qty_patching_1[0],
                      qty_spout_and_top_2: qty_spout_and_top_2[0],
                      qty_spout_and_top_3: qty_spout_and_top_3[0],
                      average_patching_lining_life:
                        average_patching_lining_life[0],
                      material_used_spout_and_top_2:
                        material_used_spout_and_top_2[0],
                      material_used_spout_and_top_3:
                        material_used_spout_and_top_3[0],
                      ramming_material_patching_1:
                        ramming_material_patching_1[0],
                    },
                    nrm: {
                      avg_heat_size_per_patching: avg_heat_size_per_patching[1],
                      no_of_patching: no_of_patching[1],
                      qty_patching_1: qty_patching_1[1],
                      qty_spout_and_top_2: qty_spout_and_top_2[1],
                      qty_spout_and_top_3: qty_spout_and_top_3[1],
                      average_patching_lining_life:
                        average_patching_lining_life[1],
                      material_used_spout_and_top_2:
                        material_used_spout_and_top_2[1],
                      material_used_spout_and_top_3:
                        material_used_spout_and_top_3[1],
                      ramming_material_patching_1:
                        ramming_material_patching_1[1],
                    },
                  }
                } else {
                  console.log(
                    'Conditions not met for creating patchingData object.'
                  )
                }

                // repair details

                if (entry['Ramming material used in patching - 1']) {
                  ramming_material_repairing_1 =
                    entry['Ramming material used in patching - 1'].split(',')
                  console.log(
                    'ramming_material_repairing_1:',
                    ramming_material_repairing_1
                  )
                }

                if (entry['Quantity of material required for patching - 1']) {
                  qty_repairing_1 =
                    entry[
                      'Quantity of material required for patching - 1'
                    ].split(',')
                  console.log('qty_repairing_1:', qty_repairing_1)
                }

                if (entry['Material used in spout and top  - 2']) {
                  material_used_spout_and_top_2 =
                    entry['Material used in spout and top  - 2'].split(',')
                  console.log(
                    'material_used_spout_and_top_2:',
                    material_used_spout_and_top_2
                  )
                }

                if (entry['Qty. of Material required for Spout & Top - 2']) {
                  qty_spout_and_top_2 =
                    entry[
                      'Qty. of Material required for Spout & Top - 2'
                    ].split(',')
                  console.log('qty_spout_and_top_2:', qty_spout_and_top_2)
                }

                if (entry['Material used in spout and top  - 3']) {
                  material_used_spout_and_top_3 =
                    entry['Material used in spout and top  - 3'].split(',')
                  console.log(
                    'material_used_spout_and_top_3:',
                    material_used_spout_and_top_3
                  )
                }

                if (entry['Qty. of Material required for Spout & Top - 3']) {
                  qty_spout_and_top_3 =
                    entry[
                      'Qty. of Material required for Spout & Top - 3'
                    ].split(',')
                  console.log('qty_spout_and_top_3:', qty_spout_and_top_3)
                }

                if (
                  ramming_material_repairing_1 &&
                  ramming_material_repairing_1.length > 0 &&
                  qty_repairing_1 &&
                  qty_repairing_1.length > 0 &&
                  material_used_spout_and_top_2 &&
                  material_used_spout_and_top_2.length > 0 &&
                  qty_spout_and_top_2 &&
                  qty_spout_and_top_2.length > 0 &&
                  material_used_spout_and_top_3 &&
                  material_used_spout_and_top_3.length > 0 &&
                  qty_spout_and_top_3 &&
                  qty_spout_and_top_3.length > 0
                ) {
                  repairingDetails = {
                    basic: {
                      ramming_material_repairing_1:
                        ramming_material_repairing_1[0],
                      qty_repairing_1: qty_repairing_1[0],
                      material_used_spout_and_top_2:
                        material_used_spout_and_top_2[0],
                      qty_spout_and_top_2: qty_spout_and_top_2[0],
                      material_used_spout_and_top_3:
                        material_used_spout_and_top_3[0],
                      qty_spout_and_top_3: qty_spout_and_top_3[0],
                    },
                    nrm: {
                      ramming_material_repairing_1:
                        ramming_material_repairing_1[1],
                      qty_repairing_1: qty_repairing_1[1],
                      material_used_spout_and_top_2:
                        material_used_spout_and_top_2[1],
                      qty_spout_and_top_2: qty_spout_and_top_2[1],
                      material_used_spout_and_top_3:
                        material_used_spout_and_top_3[1],
                      qty_spout_and_top_3: qty_spout_and_top_3[1],
                    },
                  }
                } else {
                  console.log(
                    'Conditions not met for creating patchingData object.'
                  )
                }
                // Utility function to handle 'NA' values
                const handleNA = (value, defaultValue = null) => {
                  return value === 'NA' ? defaultValue : value
                }

                if (entry['Labor cost per NL / SL / PA']) {
                  labor_cost_per_nl = entry['Labor cost per NL / SL / PA']
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log('labor_cost_per_nl:', labor_cost_per_nl)
                }

                if (entry['Labor cost in a campaign']) {
                  labor_cost_campaign = entry['Labor cost in a campaign']
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log('labor_cost_campaign:', labor_cost_campaign)
                }

                if (entry['Labor cost per ton of steel produced']) {
                  labor_cost_per_steel_produced = entry[
                    'Labor cost per ton of steel produced'
                  ]
                    .split(',')
                    .map((val) => handleNA(val, '0.00'))
                  console.log(
                    'labor_cost_per_steel_produced:',
                    labor_cost_per_steel_produced
                  )
                }

                if (entry['Savings In Labor Cost']) {
                  savings_labor_cost = entry['Savings In Labor Cost']
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log('savings_labor_cost:', savings_labor_cost)
                }

                // Condition to check and build laborCostDataObject
                if (
                  labor_cost_per_nl &&
                  labor_cost_per_nl.length > 0 &&
                  labor_cost_campaign &&
                  labor_cost_campaign.length > 0 &&
                  labor_cost_per_steel_produced &&
                  labor_cost_per_steel_produced.length > 0 &&
                  savings_labor_cost &&
                  savings_labor_cost.length > 0
                ) {
                  laborCostDataObject = {
                    laborCost: {
                      basic: {
                        labor_cost_per_nl: labor_cost_per_nl[0],
                        savings_labor_cost: savings_labor_cost[0],
                        labor_cost_campaign: parseInt(labor_cost_campaign[0]),
                        labor_cost_per_steel_produced: parseFloat(
                          labor_cost_per_steel_produced[0]
                        ).toFixed(2),
                      },
                      nrm: {
                        labor_cost_per_nl: labor_cost_per_nl[1],
                        savings_labor_cost: savings_labor_cost[1],
                        labor_cost_campaign: parseInt(labor_cost_campaign[1]),
                        labor_cost_per_steel_produced: parseFloat(
                          labor_cost_per_steel_produced[1]
                        ).toFixed(2),
                      },
                    },
                  }
                } else {
                  console.log(
                    'Conditions not met for creating laborCostDataObject.'
                  )
                }

                // former cost
                if (entry['Former cost per piece']) {
                  former_cost_per_piece =
                    entry['Former cost per piece'].split(',')
                  console.log('former_cost_per_piece:', former_cost_per_piece)
                }
                if (entry['Total former cost in a campaign']) {
                  former_cost_campaign =
                    entry['Total former cost in a campaign'].split(',')
                  console.log('former_cost_campaign:', former_cost_campaign)
                }
                if (entry['Former cost per ton of steel produced']) {
                  former_cost_per_steel_produced =
                    entry['Former cost per ton of steel produced'].split(',')
                  console.log(
                    'former_cost_per_steel_produced:',
                    former_cost_per_steel_produced
                  )
                }
                if (entry['Savings in Former cost']) {
                  savings_former_cost =
                    entry['Savings in Former cost'].split(',')
                  console.log('savings_former_cost:', savings_former_cost)
                }
                if (
                  former_cost_per_piece &&
                  former_cost_per_piece.length > 0 &&
                  former_cost_campaign &&
                  former_cost_campaign.length > 0 &&
                  former_cost_per_steel_produced &&
                  former_cost_per_steel_produced.length > 0 &&
                  savings_former_cost &&
                  savings_former_cost.length > 0
                ) {
                  formerCostDataObject = {
                    formerCost: {
                      basic: {
                        savings_former_cost: savings_former_cost[0],
                        former_cost_campaign: former_cost_campaign[0],
                        former_cost_per_piece: parseInt(
                          former_cost_per_piece[0]
                        ),
                        former_cost_per_steel_produced: parseFloat(
                          former_cost_per_steel_produced[0]
                        ).toFixed(2),
                      },
                      nrm: {
                        savings_former_cost: savings_former_cost[1],
                        former_cost_campaign: former_cost_campaign[1],
                        former_cost_per_piece: parseInt(
                          former_cost_per_piece[1]
                        ),
                        former_cost_per_steel_produced: parseFloat(
                          former_cost_per_steel_produced[1]
                        ).toFixed(2),
                      },
                    },
                  }
                } else {
                  console.log(
                    'Conditions not met for creating laborCostDataObject.'
                  )
                }

                //increased productivity

                if (entry['Total Down time in a month due to sintering']) {
                  totDownTime = entry[
                    'Total Down time in a month due to sintering'
                  ]
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log('totDownTime:', totDownTime)
                }

                if (entry['Cost benefit due to NRM in a month']) {
                  cost_benefit = entry['Cost benefit due to NRM in a month']
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log('cost_benefit:', cost_benefit)
                }

                if (entry['Maximum No. of Heats in a month']) {
                  maxNoOfHeats = entry['Maximum No. of Heats in a month']
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log('maxNoOfHeats:', maxNoOfHeats)
                }

                if (
                  entry['Average Heat time (with / without hot heel for NRM)']
                ) {
                  avg_heat_time = entry[
                    'Average Heat time (with / without hot heel for NRM)'
                  ]
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log('avg_heat_time:', avg_heat_time)
                }

                if (entry['No. of Sintering Heats in a Month']) {
                  noOfSintering = entry['No. of Sintering Heats in a Month']
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log('noOfSintering:', noOfSintering)
                }

                if (entry['Profit Margin per ton of steel']) {
                  profit_margin = entry['Profit Margin per ton of steel']
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log('profit_margin:', profit_margin)
                }

                if (entry['Actual No.of heats in a month']) {
                  actualNoOfHeats = entry['Actual No.of heats in a month']
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log('actualNoOfHeats:', actualNoOfHeats)
                }

                if (entry['Actual Production in a month']) {
                  actualProdInMonth = entry['Actual Production in a month']
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log('actualProdInMonth:', actualProdInMonth)
                }

                if (entry['Total time available for Production in a Month']) {
                  totalAvailableTime = entry[
                    'Total time available for Production in a Month'
                  ]
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log('totalAvailableTime:', totalAvailableTime)
                }

                if (
                  entry['Total down time in a month due to Fce Checking time']
                ) {
                  totDownTimeDueToFce = entry[
                    'Total down time in a month due to Fce Checking time'
                  ]
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log('totDownTimeDueToFce:', totDownTimeDueToFce)
                }

                if (
                  entry['Extra time in sintering heat in min with Main Panel']
                ) {
                  extra_time_sintering = entry[
                    'Extra time in sintering heat in min with Main Panel'
                  ]
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log('extra_time_sintering:', extra_time_sintering)
                }

                if (entry['Frequency of furnace Checking as percentage']) {
                  frequency_of_furnace = entry[
                    'Frequency of furnace Checking as percentage'
                  ]
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log('frequency_of_furnace:', frequency_of_furnace)
                }

                if (entry['Total time available for Production in a Month']) {
                  totTimeAvailableProd = entry[
                    'Total time available for Production in a Month'
                  ]
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log('totTimeAvailableProd:', totTimeAvailableProd)
                }

                if (entry['Average Furnace Checking Time']) {
                  avg_fce_checking_time = entry['Average Furnace Checking Time']
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log('avg_fce_checking_time:', avg_fce_checking_time)
                }

                if (entry['Total down time in a month due to Change over ']) {
                  total_down_time_change_over = entry[
                    'Total down time in a month due to Change over '
                  ]
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log(
                    'total_down_time_change_over:',
                    total_down_time_change_over
                  )
                }

                if (entry['% production increase in a month']) {
                  percentage_production_increase = entry[
                    '% production increase in a month'
                  ]
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log(
                    'percentage_production_increase:',
                    percentage_production_increase
                  )
                }

                if (entry['Production increase due to NRM']) {
                  production_increase_due_to_nrm = entry[
                    'Production increase due to NRM'
                  ]
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log(
                    'production_increase_due_to_nrm:',
                    production_increase_due_to_nrm
                  )
                }

                if (entry['Saving due to increased productivity ']) {
                  saving_due_to_increased_productivity = entry[
                    'Saving due to increased productivity '
                  ]
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log(
                    'saving_due_to_increased_productivity:',
                    saving_due_to_increased_productivity
                  )
                }

                if (
                  totDownTime &&
                  totDownTime.length > 0 &&
                  cost_benefit &&
                  cost_benefit.length > 0 &&
                  maxNoOfHeats &&
                  maxNoOfHeats.length > 0 &&
                  avg_heat_time &&
                  avg_heat_time.length > 0 &&
                  noOfSintering &&
                  noOfSintering.length > 0 &&
                  profit_margin &&
                  profit_margin.length > 0 &&
                  actualNoOfHeats &&
                  actualNoOfHeats.length > 0 &&
                  actualProdInMonth &&
                  actualProdInMonth.length > 0 &&
                  totalAvailableTime &&
                  totalAvailableTime.length > 0 &&
                  totDownTimeDueToFce &&
                  totDownTimeDueToFce.length > 0 &&
                  extra_time_sintering &&
                  extra_time_sintering.length > 0 &&
                  frequency_of_furnace &&
                  frequency_of_furnace.length > 0 &&
                  totTimeAvailableProd &&
                  totTimeAvailableProd.length > 0 &&
                  avg_fce_checking_time &&
                  avg_fce_checking_time.length > 0 &&
                  total_down_time_change_over &&
                  total_down_time_change_over.length > 0 &&
                  percentage_production_increase &&
                  percentage_production_increase.length > 0 &&
                  production_increase_due_to_nrm &&
                  production_increase_due_to_nrm.length > 0 &&
                  saving_due_to_increased_productivity &&
                  saving_due_to_increased_productivity.length > 0
                ) {
                  let increasedProductivityObject = {
                    IncreasedProductivity: {
                      nrm: {
                        totDownTime: totDownTime[0],
                        cost_benefit: cost_benefit[0],
                        maxNoOfHeats: maxNoOfHeats[0],
                        avg_heat_time: avg_heat_time[0],
                        noOfSintering: noOfSintering[0],
                        profit_margin: profit_margin[0],
                        actualNoOfHeats: actualNoOfHeats[0],
                        actualProdInMonth: actualProdInMonth[0],
                        totalAvailableTime: totalAvailableTime[0],
                        totDownTimeDueToFce: totDownTimeDueToFce[0],
                        extra_time_sintering: extra_time_sintering[0],
                        frequency_of_furnace: frequency_of_furnace[0],
                        totTimeAvailableProd: totTimeAvailableProd[0],
                        avg_fce_checking_time: avg_fce_checking_time[0],
                        total_down_time_change_over:
                          total_down_time_change_over[0],
                        percentage_production_increase:
                          percentage_production_increase[0],
                        production_increase_due_to_nrm:
                          production_increase_due_to_nrm[0],
                        saving_due_to_increased_productivity:
                          saving_due_to_increased_productivity[0],
                      },
                      basic: {
                        totDownTime: totDownTime[1],
                        cost_benefit: cost_benefit[1] || 'NA',
                        maxNoOfHeats: maxNoOfHeats[1],
                        avg_heat_time: avg_heat_time[1],
                        noOfSintering: noOfSintering[1],
                        profit_margin: profit_margin[1] || 'NA',
                        actualNoOfHeats: actualNoOfHeats[1],
                        actualProdInMonth: actualProdInMonth[1],
                        totalAvailableTime: totalAvailableTime[1],
                        totDownTimeDueToFce: totDownTimeDueToFce[1],
                        extra_time_sintering: extra_time_sintering[1],
                        frequency_of_furnace: frequency_of_furnace[1],
                        totTimeAvailableProd: totTimeAvailableProd[1],
                        avg_fce_checking_time: avg_fce_checking_time[1],
                        total_down_time_change_over:
                          total_down_time_change_over[1],
                        percentage_production_increase:
                          percentage_production_increase[1] || 'NA',
                        production_increase_due_to_nrm:
                          production_increase_due_to_nrm[1] || 'NA',
                        saving_due_to_increased_productivity:
                          saving_due_to_increased_productivity[1] || 'NA',
                      },
                    },
                  }
                  console.log(
                    'increasedProductivityObject:',
                    increasedProductivityObject
                  )
                } else {
                  console.log(
                    'Conditions not met for creating increasedProductivityObject.'
                  )
                }

                //energy
                if (entry['Cost per electricity unit']) {
                  cost_per_electricity_unit = entry['Cost per electricity unit']
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log(
                    'cost_per_electricity_unit:',
                    cost_per_electricity_unit
                  )
                }

                if (entry['Power savings per MT']) {
                  power_savings_per_mt = entry['Power savings per MT']
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log('power_savings_per_mt:', power_savings_per_mt)
                }

                if (
                  entry[
                    'Cost Savings due to power consumption in KWh / MT of steel'
                  ]
                ) {
                  savings_power_consumption = entry[
                    'Cost Savings due to power consumption in KWh / MT of steel'
                  ]
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log(
                    'savings_power_consumption:',
                    savings_power_consumption
                  )
                }

                if (
                  cost_per_electricity_unit &&
                  cost_per_electricity_unit.length > 0 &&
                  power_savings_per_mt &&
                  power_savings_per_mt.length > 0 &&
                  savings_power_consumption &&
                  savings_power_consumption.length > 0
                ) {
                  energyDataObject = {
                    basic: {
                      cost_per_electricity_unit: parseFloat(
                        cost_per_electricity_unit[0]
                      ).toFixed(2),
                      power_savings_per_mt: parseFloat(
                        power_savings_per_mt[0]
                      ).toFixed(2),
                      savings_power_consumption: parseFloat(
                        savings_power_consumption[0]
                      ).toFixed(2),
                    },
                    nrm: {
                      cost_per_electricity_unit: parseFloat(
                        cost_per_electricity_unit[1]
                      ).toFixed(2),
                      power_savings_per_mt: parseFloat(
                        power_savings_per_mt[1]
                      ).toFixed(2),
                      savings_power_consumption: parseFloat(
                        savings_power_consumption[1]
                      ).toFixed(2),
                    },
                  }
                } else {
                  console.log(
                    'Conditions not met for creating powerSavingsDataObject.'
                  )
                }
                //
                if (entry['Yield Benefit']) {
                  yield_benefit = entry['Yield Benefit']
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log('yield_benefit:', yield_benefit)
                }

                if (entry['Market price of Scrap or DRI per ton']) {
                  market_price_of_scrap = entry[
                    'Market price of Scrap or DRI per ton'
                  ]
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log('market_price_of_scrap:', market_price_of_scrap)
                }

                if (entry['Savings due to yield benefit']) {
                  savings_due_to_yield_benefit = entry[
                    'Savings due to yield benefit'
                  ]
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log(
                    'savings_due_to_yield_benefit:',
                    savings_due_to_yield_benefit
                  )
                }

                if (
                  yield_benefit &&
                  yield_benefit.length > 0 &&
                  market_price_of_scrap &&
                  market_price_of_scrap.length > 0 &&
                  savings_due_to_yield_benefit &&
                  savings_due_to_yield_benefit.length > 0
                ) {
                  yieldImprovementObject = {
                    yieldImprovement: {
                      basic: {
                        yield_benefit: parseFloat(yield_benefit[0]).toFixed(2),
                        market_price_of_scrap: parseFloat(
                          market_price_of_scrap[0]
                        ).toFixed(2),
                        savings_due_to_yield_benefit: parseFloat(
                          savings_due_to_yield_benefit[0]
                        ).toFixed(2),
                      },
                      nrm: {
                        yield_benefit: parseFloat(yield_benefit[1]).toFixed(2),
                        market_price_of_scrap: parseFloat(
                          market_price_of_scrap[1]
                        ).toFixed(2),
                        savings_due_to_yield_benefit: parseFloat(
                          savings_due_to_yield_benefit[1]
                        ).toFixed(2),
                      },
                    },
                  }
                } else {
                  console.log(
                    'Conditions not met for creating yieldBenefitDataObject.'
                  )
                }

                // alloy
                if (entry['SiMn Savings']) {
                  simn_savings = entry['SiMn Savings']
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log('simn_savings:', simn_savings)
                }

                if (entry['FeCr Savings']) {
                  fecr_savings = entry['FeCr Savings']
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log('fecr_savings:', fecr_savings)
                }

                if (
                  entry['Savings due to other alloys , if applicable  - (Al)']
                ) {
                  savings_due_to_other_alloy = entry[
                    'Savings due to other alloys , if applicable  - (Al)'
                  ]
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log(
                    'savings_due_to_other_alloy:',
                    savings_due_to_other_alloy
                  )
                }

                if (entry['Savings due to reduction in alloy addition']) {
                  savings_due_to_reduction = entry[
                    'Savings due to reduction in alloy addition'
                  ]
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log(
                    'savings_due_to_reduction:',
                    savings_due_to_reduction
                  )
                }

                if (
                  simn_savings &&
                  simn_savings.length > 0 &&
                  fecr_savings &&
                  fecr_savings.length > 0 &&
                  savings_due_to_other_alloy &&
                  savings_due_to_other_alloy.length > 0 &&
                  savings_due_to_reduction &&
                  savings_due_to_reduction.length > 0
                ) {
                  alloyObject = {
                    alloy: {
                      basic: {
                        simn_savings: parseFloat(simn_savings[0]).toFixed(2),
                        fecr_savings: parseFloat(fecr_savings[0]).toFixed(2),
                        savings_due_to_other_alloy: parseFloat(
                          savings_due_to_other_alloy[0]
                        ).toFixed(2),
                        savings_due_to_reduction: parseFloat(
                          savings_due_to_reduction[0]
                        ).toFixed(2),
                      },
                      nrm: {
                        simn_savings: parseFloat(simn_savings[1]).toFixed(2),
                        fecr_savings: parseFloat(fecr_savings[1]).toFixed(2),
                        savings_due_to_other_alloy: parseFloat(
                          savings_due_to_other_alloy[1]
                        ).toFixed(2),
                        savings_due_to_reduction: parseFloat(
                          savings_due_to_reduction[1]
                        ).toFixed(2),
                      },
                    },
                  }
                } else {
                  console.log(
                    'Conditions not met for creating alloySavingsDataObject.'
                  )
                }

                // tangible benefits

                if (entry['Savings due to Asbestos sheet']) {
                  asbestos_sheet = entry['Savings due to Asbestos sheet']
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log('asbestos_sheet:', asbestos_sheet)
                }

                if (entry['Savings due to Coil coat for repair']) {
                  coil_coat_for_repair = entry[
                    'Savings due to Coil coat for repair'
                  ]
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log('coil_coat_for_repair:', coil_coat_for_repair)
                }

                if (entry['Savings due to avoiding antenna replacement']) {
                  antenna_replacement = entry[
                    'Savings due to avoiding antenna replacement'
                  ]
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log('antenna_replacement:', antenna_replacement)
                }

                if (entry['Savings due to Sodium Silicate']) {
                  sodium_silicate = entry['Savings due to Sodium Silicate']
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log('sodium_silicate:', sodium_silicate)
                }

                if (entry['Savings due to Slag Dumping']) {
                  slag_dumping = entry['Savings due to Slag Dumping']
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log('slag_dumping:', slag_dumping)
                }

                if (entry['Savings due to safety in operations']) {
                  safety_in_operations = entry[
                    'Savings due to safety in operations'
                  ]
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log('safety_in_operations:', safety_in_operations)
                }

                if (entry['Savings due to Other Tangible Benefit (F5)']) {
                  other_tangible_benefits = entry[
                    'Savings due to Other Tangible Benefit (F5)'
                  ]
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log(
                    'other_tangible_benefits:',
                    other_tangible_benefits
                  )
                }

                if (
                  asbestos_sheet &&
                  asbestos_sheet.length > 0 &&
                  coil_coat_for_repair &&
                  coil_coat_for_repair.length > 0 &&
                  antenna_replacement &&
                  antenna_replacement.length > 0 &&
                  sodium_silicate &&
                  sodium_silicate.length > 0 &&
                  slag_dumping &&
                  slag_dumping.length > 0 &&
                  safety_in_operations &&
                  safety_in_operations.length > 0 &&
                  other_tangible_benefits &&
                  other_tangible_benefits.length > 0
                ) {
                  tangibleBenefitsObject = {
                    tangibleBenefits: {
                      basic: {
                        asbestos_sheet: parseFloat(asbestos_sheet[0]).toFixed(
                          2
                        ),
                        coil_coat_for_repair: parseFloat(
                          coil_coat_for_repair[0]
                        ).toFixed(2),
                        antenna_replacement: parseFloat(
                          antenna_replacement[0]
                        ).toFixed(2),
                        sodium_silicate: parseFloat(sodium_silicate[0]).toFixed(
                          2
                        ),
                        slag_dumping: parseFloat(slag_dumping[0]).toFixed(2),
                        safety_in_operations: parseFloat(
                          safety_in_operations[0]
                        ).toFixed(2),
                        other_tangible_benefits: parseFloat(
                          other_tangible_benefits[0]
                        ).toFixed(2),
                      },
                      nrm: {
                        asbestos_sheet: parseFloat(asbestos_sheet[1]).toFixed(
                          2
                        ),
                        coil_coat_for_repair: parseFloat(
                          coil_coat_for_repair[1]
                        ).toFixed(2),
                        antenna_replacement: parseFloat(
                          antenna_replacement[1]
                        ).toFixed(2),
                        sodium_silicate: parseFloat(sodium_silicate[1]).toFixed(
                          2
                        ),
                        slag_dumping: parseFloat(slag_dumping[1]).toFixed(2),
                        safety_in_operations: parseFloat(
                          safety_in_operations[1]
                        ).toFixed(2),
                        other_tangible_benefits: parseFloat(
                          other_tangible_benefits[1]
                        ).toFixed(2),
                      },
                    },
                  }
                } else {
                  console.log(
                    'Conditions not met for creating otherBenefitsDataObject.'
                  )
                }

                // metellurgical benefits

                if (entry['Savings Metallurgical']) {
                  savings_metallurgical = entry['Savings Metallurgical']
                    .split(',')
                    .map((val) => handleNA(val, '0'))
                  console.log('savings_metallurgical:', savings_metallurgical)
                }

                if (savings_metallurgical && savings_metallurgical.length > 0) {
                  metallurgicalBenefitsObject = {
                    metallurgicalBenefits: {
                      basic: {
                        savings_metallurgical: parseFloat(
                          savings_metallurgical[0]
                        ).toFixed(2),
                      },
                      nrm: {
                        savings_metallurgical: parseFloat(
                          savings_metallurgical[1]
                        ).toFixed(2),
                      },
                    },
                  }
                } else {
                  console.log(
                    'Conditions not met for creating metallurgicalSavingsDataObject.'
                  )
                }

                // cost calculation
                if (entry['Average life in a campaign']) {
                  avg_life = entry['Average life in a campaign'].split(',')
                  console.log('avg_life:', avg_life)
                }
                if (entry['Total Production per Campaign']) {
                  tot_prod = entry['Total Production per Campaign'].split(',')
                  console.log('tot_prod:', tot_prod)
                }
                if (entry['Increase in cost due to NRM']) {
                  inc_in_cost = entry['Increase in cost due to NRM'].split(',')
                  console.log('inc_in_cost:', inc_in_cost)
                }
                if (entry['Total no of heats in a campaign']) {
                  no_of_heats =
                    entry['Total no of heats in a campaign'].split(',')
                  console.log('no_of_heats:', no_of_heats)
                }
                if (entry['Total Savings due to NRM (S1 to S8)']) {
                  tot_savings =
                    entry['Total Savings due to NRM (S1 to S8)'].split(',')
                  console.log('tot_savings:', tot_savings)
                }
                if (entry['Total no of cycle in a campaign']) {
                  no_of_cycles =
                    entry['Total no of cycle in a campaign'].split(',')
                  console.log('no_of_cycles:', no_of_cycles)
                }
                if (entry['Relative Gain in cost due to NRM']) {
                  relative_gain =
                    entry['Relative Gain in cost due to NRM'].split(',')
                  console.log('relative_gain:', relative_gain)
                }
                if (entry['Total Refractory Consumption']) {
                  tot_refractory =
                    entry['Total Refractory Consumption'].split(',')
                  console.log('tot_refractory:', tot_refractory)
                }
                if (entry['Average heat size in a campaign']) {
                  avg_in_heat_size =
                    entry['Average heat size in a campaign'].split(',')
                  console.log('avg_in_heat_size:', avg_in_heat_size)
                }
                if (entry['Topping material cost in a Campaign - 2']) {
                  cost_in_campaign_2 =
                    entry['Topping material cost in a Campaign - 2'].split(',')
                  console.log('cost_in_campaign_2:', cost_in_campaign_2)
                }
                if (entry['Topping material cost in a Campaign - 3']) {
                  cost_in_campaign_3 =
                    entry['Topping material cost in a Campaign - 3'].split(',')
                  console.log('cost_in_campaign_3:', cost_in_campaign_3)
                }
                if (entry['Cost of refractory per ton of steel produced']) {
                  cost_of_refractory =
                    entry['Cost of refractory per ton of steel produced'].split(
                      ','
                    )
                  console.log('cost_of_refractory:', cost_of_refractory)
                }
                if (entry['Topping material Landed cost - 2']) {
                  topping_material_2 =
                    entry['Topping material Landed cost - 2'].split(',')
                  console.log('topping_material_2:', topping_material_2)
                }
                if (entry['Topping material Landed cost - 3']) {
                  topping_material_3 =
                    entry['Topping material Landed cost - 3'].split(',')
                  console.log('topping_material_3:', topping_material_3)
                }
                if (entry['Total cost in a campaign (Refractory)']) {
                  tot_cost_in_campaign =
                    entry['Total cost in a campaign (Refractory)'].split(',')
                  console.log('tot_cost_in_campaign:', tot_cost_in_campaign)
                }
                if (entry['Specific Refractory Consumption (kg/T of steel)']) {
                  spec_refractory_consumpt =
                    entry[
                      'Specific Refractory Consumption (kg/T of steel)'
                    ].split(',')
                  console.log(
                    'spec_refractory_consumpt:',
                    spec_refractory_consumpt
                  )
                }
                if (entry['Ramming material cost In a Campaign - 1']) {
                  ramming_cost_in_campaign_1 =
                    entry['Ramming material cost In a Campaign - 1'].split(',')
                  console.log(
                    'ramming_cost_in_campaign_1:',
                    ramming_cost_in_campaign_1
                  )
                }
                if (entry['Ramming material Landed cost - 1']) {
                  ramming_material_landed_cost_1 =
                    entry['Ramming material Landed cost - 1'].split(',')
                  console.log(
                    'ramming_material_landed_cost_1:',
                    ramming_material_landed_cost_1
                  )
                }

                if (
                  avg_life &&
                  avg_life.length > 0 &&
                  tot_prod &&
                  tot_prod.length > 0 &&
                  inc_in_cost &&
                  inc_in_cost.length > 0 &&
                  no_of_heats &&
                  no_of_heats.length > 0 &&
                  tot_savings &&
                  tot_savings.length > 0 &&
                  no_of_cycles &&
                  no_of_cycles.length > 0 &&
                  relative_gain &&
                  relative_gain.length > 0 &&
                  tot_refractory &&
                  tot_refractory.length > 0 &&
                  avg_in_heat_size &&
                  avg_in_heat_size.length > 0 &&
                  cost_in_campaign_2 &&
                  cost_in_campaign_2.length > 0 &&
                  cost_in_campaign_3 &&
                  cost_in_campaign_3.length > 0 &&
                  cost_of_refractory &&
                  cost_of_refractory.length > 0 &&
                  topping_material_2 &&
                  topping_material_2.length > 0 &&
                  topping_material_3 &&
                  topping_material_3.length > 0 &&
                  tot_cost_in_campaign &&
                  tot_cost_in_campaign.length > 0 &&
                  spec_refractory_consumpt &&
                  spec_refractory_consumpt.length > 0 &&
                  ramming_cost_in_campaign_1 &&
                  ramming_cost_in_campaign_1.length > 0 &&
                  ramming_material_landed_cost_1 &&
                  ramming_material_landed_cost_1.length > 0
                ) {
                  costCalculation = {
                    basic: {
                      avg_life: parseFloat(avg_life[0]).toFixed(2),
                      tot_prod: parseFloat(tot_prod[0]).toFixed(2),
                      inc_in_cost: parseFloat(inc_in_cost[0]).toFixed(2),
                      no_of_heats: parseFloat(no_of_heats[0]).toFixed(2),
                      tot_savings: parseFloat(tot_savings[0]).toFixed(2),
                      no_of_cycles: parseFloat(no_of_cycles[0]).toFixed(2),
                      relative_gain: parseFloat(relative_gain[0]).toFixed(2),
                      tot_refractory: parseFloat(tot_refractory[0]).toFixed(2),
                      avg_in_heat_size: parseFloat(avg_in_heat_size[0]).toFixed(
                        2
                      ),
                      cost_in_campaign_2: parseFloat(
                        cost_in_campaign_2[0]
                      ).toFixed(2),
                      cost_in_campaign_3: parseFloat(
                        cost_in_campaign_3[0]
                      ).toFixed(2),
                      cost_of_refractory: parseFloat(
                        cost_of_refractory[0]
                      ).toFixed(2),
                      topping_material_2: parseFloat(
                        topping_material_2[0]
                      ).toFixed(2),
                      topping_material_3: parseFloat(
                        topping_material_3[0]
                      ).toFixed(2),
                      tot_cost_in_campaign: parseFloat(
                        tot_cost_in_campaign[0]
                      ).toFixed(2),
                      spec_refractory_consumpt: parseFloat(
                        spec_refractory_consumpt[0]
                      ).toFixed(2),
                      ramming_cost_in_campaign_1: parseFloat(
                        ramming_cost_in_campaign_1[0]
                      ).toFixed(2),
                      ramming_material_landed_cost_1: parseFloat(
                        ramming_material_landed_cost_1[0]
                      ).toFixed(2),
                    },
                    nrm: {
                      avg_life: parseFloat(avg_life[1]).toFixed(2),
                      tot_prod: parseFloat(tot_prod[1]).toFixed(2),
                      inc_in_cost: parseFloat(inc_in_cost[1]).toFixed(2),
                      no_of_heats: parseFloat(no_of_heats[1]).toFixed(2),
                      tot_savings: parseFloat(tot_savings[1]).toFixed(2),
                      no_of_cycles: parseFloat(no_of_cycles[1]).toFixed(2),
                      relative_gain: parseFloat(relative_gain[1]).toFixed(2),
                      tot_refractory: parseFloat(tot_refractory[1]).toFixed(2),
                      avg_in_heat_size: parseFloat(avg_in_heat_size[1]).toFixed(
                        2
                      ),
                      cost_in_campaign_2: parseFloat(
                        cost_in_campaign_2[1]
                      ).toFixed(2),
                      cost_in_campaign_3: parseFloat(
                        cost_in_campaign_3[1]
                      ).toFixed(2),
                      cost_of_refractory: parseFloat(
                        cost_of_refractory[1]
                      ).toFixed(2),
                      topping_material_2: parseFloat(
                        topping_material_2[1]
                      ).toFixed(2),
                      topping_material_3: parseFloat(
                        topping_material_3[1]
                      ).toFixed(2),
                      tot_cost_in_campaign: parseFloat(
                        tot_cost_in_campaign[1]
                      ).toFixed(2),
                      spec_refractory_consumpt: parseFloat(
                        spec_refractory_consumpt[1]
                      ).toFixed(2),
                      ramming_cost_in_campaign_1: parseFloat(
                        ramming_cost_in_campaign_1[1]
                      ).toFixed(2),
                      ramming_material_landed_cost_1: parseFloat(
                        ramming_material_landed_cost_1[1]
                      ).toFixed(2),
                    },
                  }
                } else {
                  console.log(
                    'Conditions not met for creating campaignDataObject.'
                  )
                }
              })

              console.log(furnaceDetails, 'furnaceDetails13')
              console.log(newLiningDetails, 'newLining13')
              console.log(sideLiningDetails, 'sideLining123')
              console.log(patchingDetails, 'patchingDetails123')
              console.log(repairingDetails, 'repairDetails')

              const consumptionDetails = [
                { furnaceDetails },
                { newLiningDetails },
                { sideLiningDetails },
                { patchingDetails },
                { repairingDetails },
              ]
              const savingsDetails = [
                { laborCostDataObject },
                { formerCostDataObject },
                { increasedProductivityObject },
                { tangibleBenefitsObject },
                { energyDataObject },
                { yieldImprovementObject },
                { alloyObject },
                { metallurgicalBenefitsObject },
              ]
              const costCalculations = { costCalculation }
              console.log(consumptionDetails, 'consumptionDetails')
              console.log(savingsDetails, 'savingsDetails')

              const jsonDataexcel = {
                // general_details: general_details,
                consumption_details: consumptionDetails,
                saving_details: savingsDetails,
                cost_calculation: costCalculations,
              }
              console.log('jsonexxcel', jsonDataexcel)

              let submissionStatus = ''
              if (
                currentUser.role.role_id === 3 ||
                currentUser.role.role_id === 4
              ) {
                submissionStatus = 'approved'
              } else {
                submissionStatus = 'submitted'
              }

              axios
                .post(
                  API.INSERT_COST_SHEET_EXCEL,
                  {
                    jsonDataexcel: jsonDataexcel,
                    datafile_id: datafile_id,
                    submission_status: submissionStatus,
                  },
                  {
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${access_token}`,
                    },
                  }
                )
                .then((excelresponse) => {
                  console.log(
                    'Excel data inserted successfully:',
                    excelresponse
                  )

                  setShowModals(false)
                  if (excelresponse.status === 200) {
                    AlertDialog({
                      type: 'success',
                      title: 'Success',
                      text: 'Excel data inserted successfully!',
                      confirmButtonText: 'Ok',
                      onConfirm: async () => {
                        window.location.reload()
                      },
                    })
                  } else {
                    console.error('Error saving data:', response.data)
                    AlertDialog({
                      type: 'warning',
                      title: 'Error',
                      text: 'Failed to save data',
                    })
                  }
                })
                .catch((error) => {
                  console.log(
                    'An error occurred while inserting Excel data:',
                    error
                  )
                })
            } else {
              alert('Please attach the proper template')
              setShowModals(false)
              return
            }
          }
        } else if (template_id === 3) {
          console.log('working now')
          await handleExtractData(template_id, file)
          const extractedData = JSON.parse(
            localStorage.getItem('extractedData')
          )
          console.log('extractedData', extractedData)
          const predefinedHeaders = [
            'Details',
            'Customer Name',
            'Date of Lining or patching or side lining',
            'Segment',
            'Furnace No',
            'Crucible No',
            'Lining / Patching No',
            'Campaign No.',
            'Furnace capacity',
            'Furnace Down at',
            'Type of Former',
            'Make of the furnace',
            'Dimensional details',
            'Fce Height',
            'Castable height',
            'Bottom Thickness',
            'Bottom Erosion',
            'Former Top OD',
            'Former Bottom OD',
            'Former Height',
            'Former 1st taper OD ',
            'Former Taper length',
            'Initial Lining Height ',
            'Lining Height after erosion',
            'NRM usage details',
            'Bottom Product',
            'Sidewall Product 1',
            'Sidewall Product 2',
            'Locking product',
            'Spout/ Launder product',
            'NRM Consumption - Bottom Product',
            'NRM Consumption -  sidewall product 1',
            'NRM Consumption -  sidewall product 2',
            'NRM Consumption - Locking product',
            'NRM Consumption - Spout/ Launder product',
            'Total NRM Consumption',
            'Height',
            'inch',
            '0 (Top)',
            10,
            20,
            30,
            40,
            50,
            60,
            70,
            80,
            90,
            100,
            110,
            120,
            130,
            140,
            150,
            160,
            170,
            180,
            190,
            200,
            'Lining side',
            null,
            'Left',
            'Right',
            'Spout',
            'Back',
          ]
          const extractedHeaders = extractedData.headers
          console.log('extractedHeaders', extractedHeaders)

          function arraysEqual(arr1, arr2) {
            return arr1.some((item) => arr2.includes(item))
          }
          const headersMatch = arraysEqual(predefinedHeaders, extractedHeaders)
          if (headersMatch) {
            const jsonDatas = {
              filename: file.name,
              status: 'Submitted',
              customer_id: jsonData.customer_id,
              user_id: jsonData.user_id,
              template_id: jsonData.template_id,
              segment_id: jsonData.segment_id,
              furnace_id: jsonData.furnace_id,
            }
            const response = await axios.post(API.UPLOAD_DATAFILE, jsonDatas, {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            })
            console.log('UPLOAD_DATAFILE', response)
            const responseData = response.data
            const datafile_id = responseData.data.id
            localStorage.setItem('datafile_id', datafile_id)
            // Await the extraction of data
            const Data = extractedData.data

            if (datafile_id && Data) {
              console.log('Data', Data)

              // details object
              const DetailsKeys = {
                Details: 'segment',
                'Customer Name': 'cus_name',
                'Date of Lining or patching or side lining': 'date_of_lining',
                Segment: 'segment',
                'Furnace No': 'furnace_no',
                'Crucible No': 'crucible_no',
                'Lining / Patching No': 'lining_no',
                'Campaign No.': 'campaign_no',
                'Furnace capacity': 'furnace_capacity',
                'Furnace Down at': 'furnace_down',
                'Type of Former': 'former_type',
                'Make of the furnace': 'furnace_make',
              }

              const details = {}

              Data.forEach((item) => {
                for (const key in item) {
                  if (item.hasOwnProperty(key) && DetailsKeys[key]) {
                    details[DetailsKeys[key]] = item[key]
                  }
                }
              })

              // dimentional details
              const dimensionalDetailsKey = {
                'Fce Height': 'fce_height',
                'Former Height': 'former_height',
                'Former Top OD': 'former_top_od',
                'Bottom Erosion': 'bottom_erosion',
                'Castable height': 'castable_height',
                'Bottom Thickness': 'bottom_thickness',
                'Former Bottom OD': 'former_bottom_od',
                'Former 1st taper OD ': 'former_first_taper',
                'Former Taper length': 'former_taper_length',
                'Initial Lining Height ': 'initial_lining_height',
                'Lining Height after erosion': 'lining_height_erosion',
              }
              const dimensional_details = {}
              Data.forEach((item) => {
                for (const key in item) {
                  if (item.hasOwnProperty(key) && dimensionalDetailsKey[key]) {
                    dimensional_details[dimensionalDetailsKey[key]] = item[key]
                  }
                }
              })

              // nrm usage details
              const nrmUsageDetailsKey = {
                'Bottom Product': 'bottom_product',
                'Sidewall Product 1': 'sidewall_1',
                'Sidewall Product 2': 'sidewall_2',
                'Locking product': 'locking_product',
                'Spout/ Launder product': 'spout_launder_product',
                'NRM Consumption - Bottom Product': 'conspt_bottom_product',
                'NRM Consumption - sidewall product 1': 'conspt_sidewall_1',
                'NRM Consumption - sidewall product 2': 'conspt_sidewall_2',
                'NRM Consumption - Locking product': 'conspt_locking_product',
                'NRM Consumption - Spout/ Launder product':
                  'conspt_spout_launder_product',
                'Total NRM Consumption': 'tot_nrm_conspt',
              }
              const nrm_usage_details = {}
              Data.forEach((item) => {
                for (const key in item) {
                  if (item.hasOwnProperty(key) && nrmUsageDetailsKey[key]) {
                    nrm_usage_details[nrmUsageDetailsKey[key]] = item[key]
                  }
                }
              })

              // before chipping
              function beforeChippingData(Data) {
                console.log(Data, 'datata')
                const beforeChipping = []

                for (let height = 39; height <= 49; height++) {
                  console.log(height, 'jshdja')
                  const valuesArray = Data[height]
                  let KeyVariable1 = Object.keys(valuesArray)[0].split('_')
                  let KeyVariable2 = Object.keys(valuesArray)[0]
                  let separated_Value = Data[height][KeyVariable2].split(',')
                  let valuesj = isNaN(Number(KeyVariable1[0]))
                    ? 0
                    : KeyVariable1[0]
                  console.log(valuesj, 'valuesj')
                  console.log(KeyVariable1, 'KeyVariable1')
                  const heightObject = {
                    [`height${valuesj}`]: {
                      left_right: separated_Value[0] || 0,
                      spout_back: separated_Value[1] || 0,
                      coil_coat_diameter: separated_Value[2] || '3',
                      bc_left_right_erosion: separated_Value[3] || '3',
                      bc_spout_back_erosion: separated_Value[4] || '3',
                    },
                  }
                  beforeChipping.push(heightObject)
                }

                return beforeChipping
              }
              const beforeChippingResult = beforeChippingData(Data)

              //After Chipping
              function afterChippingData(Data) {
                console.log(Data, 'datata')
                const afterChipping = []

                for (let height = 53; height <= 63; height++) {
                  console.log(height, 'jshdja')
                  const valuesArray = Data[height]
                  let KeyVariable1 = Object.keys(valuesArray)[0].split('_')
                  let KeyVariable2 = Object.keys(valuesArray)[0]
                  let separated_Value = Data[height][KeyVariable2].split(',')
                  let valuesj = isNaN(Number(KeyVariable1[0]))
                    ? 0
                    : KeyVariable1[0]
                  console.log(valuesj, 'valuesj')
                  console.log(KeyVariable1, 'KeyVariable1')
                  const heightObject = {
                    [`height${valuesj}`]: {
                      left_right: separated_Value[0] || 0,
                      spout_back: separated_Value[1] || 0,
                      coil_coat_diameter: separated_Value[2] || 0,
                      ac_left_right_erosion: separated_Value[3] || 0,
                      ac_spout_back_erosion: separated_Value[4] || 0,
                    },
                  }
                  afterChipping.push(heightObject)
                }

                return afterChipping
              }
              const afterChippingresults = afterChippingData(Data)

              // lining reference
              // function liningSide(Data) {
              //   console.log(Data, "datata")
              //   const liningSide = [];

              //   for (let cell = 90; cell <= 93; cell ++) {
              //     console.log(height, "jshdja")
              //     const valuesArray = Data[height]
              //     let KeyVariable1 = Object.keys(valuesArray)[0].split('_')
              //     let KeyVariable2= Object.keys(valuesArray)[0]
              //     let separated_Value = Data[height][KeyVariable2].split(',')
              //     let valuesj = isNaN(Number(KeyVariable1[0])) ? 0 : KeyVariable1[0]
              //     console.log(valuesj, "valuesj")
              //     console.log(KeyVariable1, "KeyVariable1")
              //     const heightObject = {
              //       [`height${valuesj}`]: {
              //         "breaking_height": separated_Value[0] || 0,
              //         "chipping_height": separated_Value[1] || 0,
              //         "measurement_of_4_phase": separated_Value[3] || 0,
              //         "powder_layer_thickness": separated_Value[4] || 0,
              //         "semi_centered_layer_thickness": separated_Value[5] || 0,
              //         "black_sintered_layer_thickness": separated_Value[6] || 0,
              //         "white_hard_sintered_layer_thickness": separated_Value[7] || 0,
              //         "excess_scrap_charged_per_sinteringHeat": separated_Value[8] || 0,
              //       }
              //     };
              //     liningSide.push(heightObject);
              //   }

              //   return liningSide;
              // }
              // const liningSideResults = liningSide(Data);
              let submissionStatus = ''
              if (
                currentUser.role.role_id === 3 ||
                currentUser.role.role_id === 4
              ) {
                submissionStatus = 'approved'
              } else {
                submissionStatus = 'submitted'
              }

              const jsonData = {
                details: details,
                dimensional_details: dimensional_details,
                nrm_usage_details: nrm_usage_details,
                before_chipping: beforeChippingResult,
                after_chipping: afterChippingresults,
              }

              axios
                .post(
                  API.INSERT_EROSION_SHEET_EXCEL,
                  {
                    jsonData: jsonData,
                    datafile_id: datafile_id,
                    submission_status: submissionStatus,
                  },
                  {
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${access_token}`,
                    },
                  }
                )
                .then((excelresponse) => {
                  console.log(
                    'Excel data inserted successfully:',
                    excelresponse
                  )

                  setShowModals(false)
                  if (excelresponse.status === 200) {
                    AlertDialog({
                      type: 'success',
                      title: 'Success',
                      text: 'Excel data inserted successfully!',
                      confirmButtonText: 'Ok',
                      onConfirm: async () => {
                        window.location.reload()
                      },
                    })
                  } else {
                    console.error('Error saving data:', response.data)
                    AlertDialog({
                      type: 'warning',
                      title: 'Error',
                      text: 'Failed to save data',
                    })
                  }
                })
                .catch((error) => {
                  console.log(
                    'An error occurred while inserting Excel data:',
                    error
                  )
                })
            } else {
              alert('Please attach the proper template')
              setShowModals(false)
              return
            }
          }
        }
      }
    } catch (error) {
      console.error('An error occurred while submitting form:', error)
    }
  }

  const handleExtractData = async (template_id, file) => {
    try {
      if (file) {
        console.log('file', file, template_id, 'template_id')
        const reader = new FileReader()
        reader.readAsArrayBuffer(file)
        const extracted = await new Promise((resolve, reject) => {
          reader.onload = (e) => {
            const data = new Uint8Array(e.target.result)
            const workbook = XLSX.read(data, {
              type: 'array',
              cellStyles: true,
            })
            const worksheetName = workbook.SheetNames[0]
            const worksheet = workbook.Sheets[worksheetName]
            console.log(worksheet, 'owquei')
            let headerRange
            if (template_id === 1) {
              headerRange = XLSX.utils.decode_range('A7:BF7')
            } else if (template_id === 2) {
              console.log(
                'template_idtemplate_idtemplate_idtemplate_id',
                template_id
              )
              headerRange = XLSX.utils.decode_range('A7:AO7')
              console.log('headerRangeeee', headerRange)
            } else if (template_id === 3) {
              console.log(
                'template_idtemplate_idtemplate_idtemplate_id',
                template_id
              )
              headerRange = XLSX.utils.decode_range('A1:K44')
              console.log('headerRangeeee', headerRange)
            } else if (template_id === 4) {
              console.log('template_id', template_id)
              headerRange = XLSX.utils.decode_range('A7:AO7') // Adjust the range as needed
              console.log('headerRangeeee', headerRange) // Add this logging
            } else if (template_id === 5) {
              console.log('template_id', template_id) // Add this logging
              headerRange = XLSX.utils.decode_range('A3:I65') // Adjust the range as needed
              console.log('headerRangeeee', headerRange)
            } else {
              console.log('templatesss')
              reject('Invalid template')
              setShowModals(false)
              return
            }

            // extracting the headers
            const headers = []
            if (template_id === 4) {
              let headerRangeA = XLSX.utils.decode_range('A1:A55')
              let headerRangeD = XLSX.utils.decode_range('D1:D55')
              for (let R = headerRangeA.s.r; R <= headerRangeA.e.r; ++R) {
                const cellAddressA = { c: headerRangeA.s.c, r: R }
                const headerCellA = XLSX.utils.encode_cell(cellAddressA)
                try {
                  const headerCellValueA = worksheet[headerCellA].v
                  headers.push(headerCellValueA)
                } catch (error) {
                  console.error(
                    'An error occurred while extracting header from column A:',
                    error
                  )
                  reject('Invalid header')
                  alert('Invalid template')
                  return
                }
              }
              for (let R = headerRangeD.s.r; R <= headerRangeD.e.r; ++R) {
                const cellAddressD = { c: headerRangeD.s.c, r: R }
                const headerCellD = XLSX.utils.encode_cell(cellAddressD)
                console.log('Reading header cell D:', headerCellD)
                try {
                  const headerCellValueD = worksheet[headerCellD].v
                  headers.push(headerCellValueD)
                } catch (error) {
                  console.error(
                    'An error occurred while extracting header from column D:',
                    error
                  )
                  reject('Invalid header')
                  alert('Invalid template')
                  return
                }
              }
            } else if (template_id === 3) {
              const ranges = [
                { start: 'B1', end: 'B12' },
                { start: 'E1', end: 'E12' },
                { start: 'H1', end: 'H12' },
                { start: 'A15', end: 'A37' },
                { start: 'A39', end: 'A44' },
              ]

              for (const range of ranges) {
                const headerRange = XLSX.utils.decode_range(
                  `${range.start}:${range.end}`
                )
                for (let R = headerRange.s.r; R <= headerRange.e.r; ++R) {
                  const cellAddress = { c: headerRange.s.c, r: R }
                  const headerCell = XLSX.utils.encode_cell(cellAddress)
                  console.log(`Reading header cell ${headerCell}`) // Add this logging
                  try {
                    const headerCellValue = worksheet[headerCell].v
                    headers.push(headerCellValue)
                    console.log(headers, 'headers123')
                  } catch (error) {
                    console.error(
                      `An error occurred while extracting header from ${range.start}:${range.end}:`,
                      error
                    )
                    reject('Invalid header')
                    alert('Invalid template')
                    return
                  }
                }
              }
            } else if (template_id === 5) {
              console.log('hi')
              // Define header ranges for columns A and F from row 2 to row 70
              let headerRangeA = {
                s: { c: 0, r: 1 },
                e: { c: 0, r: 70 },
              }

              let headerRangeF = {
                s: { c: 5, r: 1 },
                e: { c: 5, r: 70 },
              }

              // Function to extract headers from a given range
              function extractHeaders(headerRange, columnLetter) {
                for (let R = headerRange.s.r; R <= headerRange.e.r; ++R) {
                  const cellAddress = { c: headerRange.s.c, r: R }
                  const headerCell = XLSX.utils.encode_cell(cellAddress)

                  try {
                    // Check if the cell exists in the worksheet
                    if (worksheet[headerCell]) {
                      const headerCellValue = worksheet[headerCell].v
                      headers.push(headerCellValue) // Push header from the specified column
                    } else {
                      console.warn(
                        `Cell ${headerCell} does not exist in the worksheet`
                      )
                    }
                  } catch (error) {
                    console.error(
                      `An error occurred while extracting header from column ${columnLetter}:`,
                      error
                    )
                    reject('Invalid header')
                    alert('Invalid template')
                    return
                  }
                }
              }

              // Extract headers from column A
              extractHeaders(headerRangeA, 'A')

              // Extract headers from column F
              extractHeaders(headerRangeF, 'F')
            } else {
              for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
                const cellAddress = { c: C, r: headerRange.s.r }
                const headerCell = XLSX.utils.encode_cell(cellAddress)
                try {
                  const headerCellValue = worksheet[headerCell].v
                  headers.push(headerCellValue)
                  console.log(headers, 'headers123')
                } catch (error) {
                  console.error(
                    'An error occurred while extracting header:',
                    error
                  )
                  reject('Invalid header')
                  alert('Invalid template')
                  return
                }
              }
            }

            // extracting headers and headers
            const dataRange = XLSX.utils.decode_range(worksheet['!ref'])
            console.log(dataRange, 'dataRangedataRange')
            let extractedData = []
            console.log(extractedData, 'extractedData')
            let startRow = 8

            if (template_id === 3) {
              console.log('working 3')
              startRow = 0
              const extractHeadersAndData = (
                headerCol,
                dataCols,
                rowCount,
                concatenate = false,
                suffix = ''
              ) => {
                for (let R = 0; R < rowCount; ++R) {
                  const row = {}
                  const headerCell = { c: headerCol, r: startRow + R }
                  const headerAddress = XLSX.utils.encode_cell(headerCell)
                  const headerValue = worksheet[headerAddress]?.v

                  if (headerValue !== undefined) {
                    let dataValue = ''

                    if (concatenate) {
                      dataCols.forEach((dataCol, index) => {
                        const dataCell = { c: dataCol, r: startRow + R }
                        const dataAddress = XLSX.utils.encode_cell(dataCell)
                        const value = worksheet[dataAddress]?.v || ''
                        dataValue += (index > 0 ? ', ' : '') + value
                      })
                    } else {
                      const dataCell = { c: dataCols[0], r: startRow + R }
                      const dataAddress = XLSX.utils.encode_cell(dataCell)
                      dataValue = worksheet[dataAddress]?.v || ''
                    }

                    row[headerValue + suffix] = dataValue
                    extractedData.push(row)
                  }
                }
              }

              // Extract headers and data for each range
              extractHeadersAndData(1, [2], 12)
              extractHeadersAndData(4, [5], 12)
              extractHeadersAndData(7, [8], 12)
              extractHeadersAndData(0, [1, 2, 3, 4, 5], 27, true, '_BC')
              extractHeadersAndData(0, [1, 6, 7, 8, 9], 27, true, '_AC')
              extractHeadersAndData(0, [1, 2, 3, 4, 5, 6, 7, 8], 44, true)

              console.log(extractedData) // Log the final extracted data
            } else if (template_id === 4) {
              for (let R = dataRange.s.r; R <= dataRange.e.r; ++R) {
                const rowA = {}
                const headerCellAddressA = { c: dataRange.s.c, r: R }
                const headerCellRefA =
                  XLSX.utils.encode_cell(headerCellAddressA)
                const headerValueA = worksheet[headerCellRefA]
                  ? worksheet[headerCellRefA].v
                  : undefined
                const dataCellAddressA = { c: dataRange.s.c + 1, r: R }
                const dataCellRefA = XLSX.utils.encode_cell(dataCellAddressA)
                const dataValueA = worksheet[dataCellRefA]
                  ? worksheet[dataCellRefA].v
                  : undefined
                rowA[headerValueA] = dataValueA
                extractedData.push(rowA)
              }
              for (let R = dataRange.s.r; R <= dataRange.e.r; ++R) {
                const rowD = {}
                // Fetching header and data from column D
                const headerCellAddressD = { c: dataRange.s.c + 3, r: R }
                const headerCellRefD =
                  XLSX.utils.encode_cell(headerCellAddressD)
                const headerValueD = worksheet[headerCellRefD]
                  ? worksheet[headerCellRefD].v
                  : undefined
                const dataCellAddressD = { c: dataRange.s.c + 4, r: R }
                const dataCellRefD = XLSX.utils.encode_cell(dataCellAddressD)
                const dataValueD = worksheet[dataCellRefD]
                  ? worksheet[dataCellRefD].v
                  : undefined
                // Adding data from column D to rowD
                rowD[headerValueD] = dataValueD
                // Pushing rowD to extractedData
                extractedData.push(rowD)
              }
            } else if (template_id === 5) {
              // First, process columns A, B, and C
              for (let R = dataRange.s.r; R <= dataRange.e.r; ++R) {
                const rowA = {}
                const headerCellA = { c: dataRange.s.c, r: R }
                const dataCellB = { c: dataRange.s.c + 1, r: R }
                const dataCellC = { c: dataRange.s.c + 2, r: R }

                const headerA =
                  worksheet[XLSX.utils.encode_cell(headerCellA)]?.v
                const dataB = worksheet[XLSX.utils.encode_cell(dataCellB)]?.v
                const dataC = worksheet[XLSX.utils.encode_cell(dataCellC)]?.v

                if (headerA !== undefined) {
                  rowA[headerA] = `${dataB ? dataB : ''}, ${dataC ? dataC : ''}`
                }

                // Push rowA to extractedData if it contains any data
                if (Object.keys(rowA).length > 0) {
                  extractedData.push(rowA)
                }
              }

              // Next, process columns F, G, and H
              for (let R = dataRange.s.r; R <= dataRange.e.r; ++R) {
                const rowF = {}
                const headerCellF = { c: dataRange.s.c + 5, r: R }
                const dataCellG = { c: dataRange.s.c + 6, r: R }
                const dataCellH = { c: dataRange.s.c + 7, r: R }

                const headerF =
                  worksheet[XLSX.utils.encode_cell(headerCellF)]?.v
                const dataG = worksheet[XLSX.utils.encode_cell(dataCellG)]?.v
                const dataH = worksheet[XLSX.utils.encode_cell(dataCellH)]?.v

                if (headerF !== undefined) {
                  rowF[headerF] = `${dataG ? dataG : ''}, ${dataH ? dataH : ''}`
                }

                // Push rowF to extractedData if it contains any data
                if (Object.keys(rowF).length > 0) {
                  extractedData.push(rowF)
                }
              }
            } else {
              for (let R = startRow; R <= dataRange.e.r; ++R) {
                const row = {}
                for (let C = dataRange.s.c; C <= dataRange.e.c; ++C) {
                  const cellAddress = { c: C, r: R }
                  const cellRef = XLSX.utils.encode_cell(cellAddress)
                  const cellValue = worksheet[cellRef]
                    ? worksheet[cellRef].v
                    : undefined
                  row[headers[C]] = cellValue
                }
                extractedData.push(row)
              }
            }

            const result = { headers, data: extractedData }
            console.log(result, 'resultsss')
            localStorage.setItem('extractedData', JSON.stringify(result))
            resolve(result)
          }
          reader.onerror = (error) => {
            reject(error)
          }
        })
        return extracted
      }
    } catch (error) {
      console.error(
        'An error occurred while extracting data from Excel:',
        error
      )
      throw error
    }
  }
  const fetchCards = async () => {
    try {
      if (currentUser.role.role_id === 1) {
        const userId = currentUser.id
        const selectedCustomer = JSON.parse(
          localStorage.getItem('selectedCustomer')
        )
        const customer_id = selectedCustomer?.id
        console.log(customer_id, 'customer_id')
        const response = await axios.get(API.GET_ALL_DAILY_TRIAL_MONITORING, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        const dailyTrialResponse = response.data
        console.log(
          dailyTrialResponse,
          'dailyTrialResponsedailyT123rialResponse'
        )
        if (Array.isArray(dailyTrialResponse)) {
          let filteredResponse = dailyTrialResponse.filter(
            (item) =>
              item.dataFile.customer_id === customer_id &&
              item.dataFile.user_id === userId
          )
          console.log(filteredResponse, 'filteredResponse123')
          setDailyTrials(filteredResponse)
          console.log()

          let cardsDraftArray = []
          let cardsSubmittedArray = []
          let cardsApprovedArray = []
          let cardsRejectedArray = []

          filteredResponse.forEach((item, index) => {
            const generalDetails = item.general_details
            if (Array.isArray(generalDetails) && generalDetails.length > 0) {
              const submissionStatus =
                generalDetails?.[0]?.submission_status || 'test'
              if (
                submissionStatus === 'draft' &&
                item.dataFile.filename === null
              ) {
                cardsDraftArray.push(item)
              } else if (submissionStatus === 'submitted') {
                cardsSubmittedArray.push(item)
              } else if (submissionStatus === 'approved') {
                cardsApprovedArray.push(item)
              } else {
                cardsRejectedArray.push(item)
              }
            } else {
              console.log(
                `general_details array is empty or not an array for item ${index}`
              )
            }
          })

          setCardsDraft(cardsDraftArray)
          setCardsSubmitted(cardsSubmittedArray)
          setCardsRejected(cardsRejectedArray)
          setCardsApproved(cardsApprovedArray)
        } else {
          console.log('dailyTrialResponse is not an array')
        }

        //  nrm performance
        const nrmresponse = await axios.get(API.GET_ALL_NRM_PERFORMANCE, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        const nrmPerformnaceResponse = nrmresponse.data
        if (Array.isArray(nrmPerformnaceResponse)) {
          let filteredResponse = nrmPerformnaceResponse.filter(
            (item) =>
              item.dataFile.customer_id === customer_id &&
              item.dataFile.user_id === userId
          )
          setNRMPerformanceData(filteredResponse)

          let nrmcardsDraftArray = []
          let nrmcardsSubmittedArray = []
          let nrmCardsApprovedArray = []
          let nrmCardsRejectedArray = []

          filteredResponse.forEach((item, index) => {
            const submissionStatus = item.lining_reference[0].submission_status
            if (
              submissionStatus === 'draft' &&
              item.dataFile.filename === null
            ) {
              nrmcardsDraftArray.push(item)
            } else if (submissionStatus === 'submitted') {
              nrmcardsSubmittedArray.push(item)
            } else if (submissionStatus === 'approved') {
              nrmCardsApprovedArray.push(item)
            } else {
              nrmCardsRejectedArray.push(item)
            }
          })
          setNRMCardsDraft(nrmcardsDraftArray)
          setNRMCardsSubmitted(nrmcardsSubmittedArray)
          setNrmCardsApproved(nrmCardsApprovedArray)
          setNrmCardsRejected(nrmCardsRejectedArray)
        } else {
          console.log('nrm Performance is not an array')
        }

        //data collection
        const responseData = await axios.get(API.GET_ALL_DATA_COLLECTION, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        const dataCollectionResponse = responseData.data
        console.log(responseData, 'responseData')

        if (Array.isArray(dataCollectionResponse)) {
          let filteredResponse = dataCollectionResponse.filter(
            (item) =>
              item.dataFile.customer_id === customer_id &&
              item.dataFile.user_id === userId
          )
          setDataCollection(filteredResponse)

          let dataCollectioncardsDraftArray = []
          let dataCollectioncardsSubmittedArray = []
          let dataCollectionApprovedArray = []
          let dataCollectionRejectedArray = []

          filteredResponse.forEach((item, index) => {
            const submissionStatus = item.submission_status
            if (
              item.dataFile.customer_id === customer_id &&
              item.dataFile.user_id === userId
            ) {
              if (
                submissionStatus === 'draft' &&
                item.dataFile.filename === null
              ) {
                dataCollectioncardsDraftArray.push(item)
              } else if (submissionStatus === 'submitted') {
                dataCollectioncardsSubmittedArray.push(item)
              } else if (submissionStatus === 'approved') {
                dataCollectionApprovedArray.push(item)
              } else {
                dataCollectionRejectedArray.push(item)
              }
            } else {
              console.log(
                `lining_reference array is empty or not an array for item ${index}`
              )
            }
          })
          setDataCollectionSubmitted(dataCollectioncardsSubmittedArray)
          setDataCollectionDraft(dataCollectioncardsDraftArray)
          setDataCollectioApproved(dataCollectionApprovedArray)
          setDataCollectionRejected(dataCollectionRejectedArray)
        } else {
          console.log('nrm Performance is not an array')
        }

        // cost sheet
        const costSheetData = await axios.get(API.GET_ALL_COST_SHEETS, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        const costSheetResponse = costSheetData.data
        if (Array.isArray(costSheetResponse)) {
          let filteredResponse = costSheetResponse.filter(
            (item) =>
              item.dataFile.customer_id === customer_id &&
              item.dataFile.user_id === userId
          )
          setCostSheetData(filteredResponse)

          let costSheetCardsDraftArray = []
          let costSheetCardsSubmittedArray = []
          let costSheetApprovedArray = []
          let costSheetRejectedArray = []

          filteredResponse.forEach((item, index) => {
            const submissionStatus = item.submission_status
            if (
              submissionStatus === 'draft' &&
              item.dataFile.filename === null
            ) {
              costSheetCardsDraftArray.push(item)
            } else if (submissionStatus === 'submitted') {
              costSheetCardsSubmittedArray.push(item)
            } else if (submissionStatus === 'approved') {
              costSheetApprovedArray.push(item)
            } else {
              costSheetRejectedArray.push(item)
            }
          })
          setCostSheetSubmitted(costSheetCardsSubmittedArray)
          setCostSheetDraft(costSheetCardsDraftArray)
          setCostSheetApproved(costSheetApprovedArray)
          setCostSheetRejected(costSheetRejectedArray)
        } else {
          console.log('nrm Performance is not an array')
        }
        // erosion Profile
        const erosionResponse = await axios.get(API.GET_ALL_EROSION_PROFILE, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        const erosionData = erosionResponse.data
        console.log(erosionData, 'erosiondata')
        if (Array.isArray(erosionData)) {
          let filteredResponse = erosionData.filter(
            (item) =>
              item.dataFile.customer_id === customer_id &&
              item.dataFile.user_id === userId
          )
          setErosionProfileData(filteredResponse)
          console.log(filteredResponse, 'filteredResponseerosion')
          let erosioncardsDraftArray = []
          let erosionDatacardsSubmittedArray = []
          let erosionDataCardsApprovedArray = []
          let erosionDataCardsRejectedArray = []

          filteredResponse.forEach((item) => {
            const submissionStatus = item.submission_status
            if (
              submissionStatus === 'draft' &&
              item.dataFile.filename === null
            ) {
              erosioncardsDraftArray.push(item)
            } else if (submissionStatus === 'submitted') {
              erosionDatacardsSubmittedArray.push(item)
            } else if (submissionStatus === 'approved') {
              erosionDataCardsApprovedArray.push(item)
            } else {
              erosionDataCardsRejectedArray.push(item)
            }
          })

          setErosionProfileDraft(erosioncardsDraftArray)
          setErosionProfileSubmitted(erosionDatacardsSubmittedArray)
          setErosionProfileRejected(erosionDataCardsRejectedArray)
          setErosionProfileApproved(erosionDataCardsApprovedArray)
        } else {
          console.log('NRM performance response is not an array')
        }
      } else if (currentUser.role.role_id === 2) {
        const assignCustomerIds = currentUser.assign_customer_id.map((id) => id)

        const selectedCustomer = JSON.parse(
          localStorage.getItem('selectedCustomer')
        )
        const customer_id = selectedCustomer?.id
        const userId = currentUser.id

        const response = await axios.get(API.GET_ALL_DAILY_TRIAL_MONITORING, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        const dailyTrialResponse = response.data

        if (Array.isArray(dailyTrialResponse)) {
          let filteredResponse = dailyTrialResponse.filter(
            (item) =>
              assignCustomerIds.includes(item.dataFile.customer_id) &&
              item.dataFile.customer_id === customer_id
          )

          setDailyTrials(filteredResponse)

          let cardsDraftArray = []
          let cardsSubmittedArray = []
          let cardsApprovedArray = []
          let cardsRejectedArray = []
          filteredResponse.forEach((item) => {
            const generalDetails = item.general_details
            if (Array.isArray(generalDetails) && generalDetails.length > 0) {
              const submissionStatus = generalDetails[0].submission_status
              if (
                submissionStatus === 'draft' &&
                item.dataFile.filename === null
              ) {
                cardsDraftArray.push(item)
              } else if (submissionStatus === 'submitted') {
                cardsSubmittedArray.push(item)
              } else if (submissionStatus === 'approved') {
                cardsApprovedArray.push(item)
              } else {
                cardsRejectedArray.push(item)
              }
            }
          })

          console.log('cardsDraftArray', cardsDraftArray)
          console.log('cardsSubmittedArray', cardsSubmittedArray)

          setCardsDraft(cardsDraftArray)
          setCardsSubmitted(cardsSubmittedArray)
          setCardsRejected(cardsRejectedArray)
          setCardsApproved(cardsApprovedArray)
        } else {
          console.log('dailyTrialResponse is not an array')
        }

        // nrm
        const nrmresponse = await axios.get(API.GET_ALL_NRM_PERFORMANCE, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        const nrmPerformnaceResponse = nrmresponse.data
        if (Array.isArray(nrmPerformnaceResponse)) {
          let filteredResponse = nrmPerformnaceResponse.filter(
            (item) =>
              assignCustomerIds.includes(item.dataFile.customer_id) &&
              item.dataFile.customer_id === customer_id
          )
          setNRMPerformanceData(filteredResponse)

          let nrmcardsDraftArray = []
          let nrmcardsSubmittedArray = []
          let nrmCardsApprovedArray = []
          let nrmCardsRejectedArray = []

          filteredResponse.forEach((item, index) => {
            if (assignCustomerIds.includes(item.dataFile.customer_id)) {
              const liningReference = item.lining_reference
              const submissionStatus = liningReference[0].submission_status
              if (
                submissionStatus === 'draft' &&
                item.dataFile.filename === null
              ) {
                nrmcardsDraftArray.push(item)
              } else if (submissionStatus === 'submitted') {
                nrmcardsSubmittedArray.push(item)
              } else if (submissionStatus === 'approved') {
                nrmCardsApprovedArray.push(item)
              } else {
                nrmCardsRejectedArray.push(item)
              }
            }
          })

          setNRMCardsDraft(nrmcardsDraftArray)
          setNRMCardsSubmitted(nrmcardsSubmittedArray)
          setNrmCardsRejected(nrmCardsRejectedArray)
          setNrmCardsApproved(nrmCardsApprovedArray)
        } else {
          console.log('NRM performance response is not an array')
        }

        //data collection
        const dataCollectionResponse = await axios.get(
          API.GET_ALL_DATA_COLLECTION,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        const responseDataCollection = dataCollectionResponse.data
        console.log(responseDataCollection, 'responseDataCollection')

        if (Array.isArray(responseDataCollection)) {
          let filteredResponse = responseDataCollection.filter(
            (item) =>
              assignCustomerIds.includes(item.dataFile.customer_id) &&
              item.dataFile.customer_id === customer_id
          )
          setDataCollection(filteredResponse)

          let datacollectionDraftArray = []
          let datacollectionSubmittedArray = []
          let dataCollectionApprovedArray = []
          let dataCollectionRejectedArray = []

          filteredResponse.forEach((item, index) => {
            const submissionStatus = item.submission_status
            if (
              submissionStatus === 'draft' &&
              item.dataFile.filename === null
            ) {
              datacollectionDraftArray.push(item)
            } else if (submissionStatus === 'submitted') {
              datacollectionSubmittedArray.push(item)
            } else if (submissionStatus === 'approved') {
              dataCollectionApprovedArray.push(item)
            } else {
              dataCollectionRejectedArray.push(item)
            }
          })

          setDataCollectionSubmitted(datacollectionSubmittedArray)
          setDataCollectionDraft(datacollectionDraftArray)
          setDataCollectioApproved(dataCollectionApprovedArray)
          setDataCollectionRejected(dataCollectionRejectedArray)
        } else {
          console.log('Data collection response is not an array')
        }

        // cost sheet
        const costSheetData = await axios.get(API.GET_ALL_COST_SHEETS, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        const costSheetResponse = costSheetData.data

        if (Array.isArray(costSheetResponse)) {
          let filteredResponse = costSheetResponse.filter(
            (item) =>
              item.dataFile.customer_id === customer_id &&
              item.dataFile.user_id === userId
          )
          setCostSheetData(filteredResponse)
          let costSheetCardsDraftArray = []
          let costSheetCardsSubmittedArray = []
          let costSheetRejectedArray = []
          let costSheetApprovedArray = []

          costSheetResponse.forEach((item, index) => {
            const submissionStatus = item.submission_status
            if (assignCustomerIds.includes(item.dataFile.customer_id)) {
              if (
                submissionStatus === 'draft' &&
                item.dataFile.filename === null
              ) {
                costSheetCardsDraftArray.push(item)
              } else if (submissionStatus === 'submitted') {
                costSheetCardsSubmittedArray.push(item)
              }
            } else if (submissionStatus === 'approved') {
              costSheetApprovedArray.push(item)
            } else {
              costSheetRejectedArray.push(item)
            }
          })
          setCostSheetSubmitted(costSheetCardsSubmittedArray)
          setCostSheetDraft(costSheetCardsDraftArray)
          setCostSheetApproved(costSheetApprovedArray)
          setCostSheetRejected(costSheetRejectedArray)
        } else {
          console.log('Cost sheet response is not an array')
        }

        // erosion Profile
        const erosionResponse = await axios.get(API.GET_ALL_EROSION_PROFILE, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        const erosionData = erosionResponse.data
        console.log(erosionData, 'erosiondata')
        if (Array.isArray(erosionData)) {
          let filteredResponse = erosionData.filter(
            (item) =>
              item.dataFile.customer_id === customer_id &&
              item.dataFile.user_id === userId
          )
          setErosionProfileData(filteredResponse)

          let erosioncardsDraftArray = []
          let erosionDatacardsSubmittedArray = []
          let erosionDataCardsApprovedArray = []
          let erosionDataCardsRejectedArray = []

          filteredResponse.forEach((item) => {
            if (assignCustomerIds.includes(item.dataFile.customer_id)) {
              const submissionStatus = item.submission_status
              if (
                submissionStatus === 'draft' &&
                item.dataFile.filename === null
              ) {
                erosioncardsDraftArray.push(item)
              } else if (submissionStatus === 'submitted') {
                erosionDatacardsSubmittedArray.push(item)
              } else if (submissionStatus === 'approved') {
                erosionDataCardsApprovedArray.push(item)
              } else {
                erosionDataCardsRejectedArray.push(item)
              }
            }
          })

          setErosionProfileDraft(erosioncardsDraftArray)
          setErosionProfileSubmitted(erosionDatacardsSubmittedArray)
          setErosionProfileRejected(erosionDataCardsRejectedArray)
          setErosionProfileApproved(erosionDataCardsApprovedArray)
        } else {
          console.log('NRM performance response is not an array')
        }
      } else {
        const response = await axios.get(API.GET_ALL_DAILY_TRIAL_MONITORING, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        const dailyTrialResponse = response.data
        setDailyTrials(dailyTrialResponse)
        const userId = currentUser.id
        const selectedCustomer = JSON.parse(
          localStorage.getItem('selectedCustomer')
        )
        const customer_id = selectedCustomer?.id
        let filteredResponse = dailyTrialResponse.filter(
          (item) => item.dataFile.customer_id === customer_id
        )

        if (Array.isArray(dailyTrialResponse)) {
          let cardsDraftArray = []
          let cardsSubmittedArray = []
          let cardsApprovedArray = []
          let cardsRejectedArray = []

          filteredResponse.forEach((item, index) => {
            const generalDetails = item.general_details
            if (Array.isArray(generalDetails) && generalDetails.length > 0) {
              const submissionStatus =
                generalDetails?.[0]?.submission_status ?? 'test'
              if (
                submissionStatus === 'draft' &&
                item.dataFile.filename === null
              ) {
                cardsDraftArray.push(item)
              } else if (submissionStatus === 'submitted') {
                cardsSubmittedArray.push(item)
              } else if (submissionStatus === 'approved') {
                cardsApprovedArray.push(item)
              } else {
                cardsRejectedArray.push(item)
              }
            } else {
              console.log(
                `general_details array is empty or not an array for item ${index}`
              )
            }
          })

          console.log('cardsRejectedArray', cardsRejectedArray)

          setCardsDraft(cardsDraftArray)
          setCardsSubmitted(cardsSubmittedArray)
          setCardsApproved(cardsApprovedArray)
          setCardsRejected(cardsRejectedArray)
        } else {
          console.log('dailyTrialResponse is not an array')
        }

        // nrm
        const nrmresponse = await axios.get(API.GET_ALL_NRM_PERFORMANCE, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        const nrmPerformnaceResponse = nrmresponse.data
        setNRMPerformanceData(nrmPerformanceData)
        const NRMfilteredResponse = nrmPerformnaceResponse.filter(
          (item) => item.dataFile.customer_id === customer_id
        )

        if (Array.isArray(NRMfilteredResponse)) {
          let nrmcardsDraftArray = []
          let nrmcardsSubmittedArray = []
          let nrmcardsApprovedArray = []
          let nrmCardsRejectedArray = []

          NRMfilteredResponse.forEach((item) => {
            const submissionStatus =
              (item.lining_reference &&
                item.lining_reference.length > 0 &&
                item.lining_reference[0].submission_status) ||
              ''

            if (
              submissionStatus === 'draft' &&
              item.dataFile.filename === null
            ) {
              nrmcardsDraftArray.push(item)
            } else if (submissionStatus === 'submitted') {
              nrmcardsSubmittedArray.push(item)
            } else if (submissionStatus === 'approved') {
              nrmcardsApprovedArray.push(item)
            } else {
              nrmCardsRejectedArray.push(item)
            }
          })
          console.log(nrmcardsDraftArray, 'nrmcardsDraftArray')
          console.log(nrmcardsSubmittedArray, 'nrmcardsSubmittedArray')
          setNRMCardsDraft(nrmcardsDraftArray)
          setNRMCardsSubmitted(nrmcardsSubmittedArray)
          setNrmCardsApproved(nrmcardsApprovedArray)
          setNrmCardsRejected(nrmCardsRejectedArray)
        } else {
          console.log('nrm Performance is not an array')
        }

        // Data collection
        const responseData = await axios.get(API.GET_ALL_DATA_COLLECTION, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        const dataCollectionResponse = responseData.data
        console.log(dataCollectionResponse, 'dataCollectionResponsecheck')
        setDataCollection(dataCollectionResponse)
        const dataCollectionFilteredResponse = dataCollectionResponse.filter(
          (item) => item.dataFile.customer_id === customer_id
        )

        console.log(dataCollectionFilteredResponse, 'sekurit cus')

        if (Array.isArray(dataCollectionFilteredResponse)) {
          let dataCollectioncardsDraftArray = []
          let dataCollectioncardsSubmittedArray = []
          console.log(
            dataCollectioncardsSubmittedArray,
            'dataCollectioncardsSubmittedArray'
          )
          let dataCollectionApprovedArray = []
          let dataCollectionRejectedArray = []

          dataCollectionFilteredResponse.forEach((item, index) => {
            console.log(item, 'foreachcheck')
            const submissionStatus = item.submission_status
            console.log(submissionStatus, 'statuscheck')
            if (
              submissionStatus === 'draft' &&
              item.dataFile.filename === null
            ) {
              dataCollectioncardsDraftArray.push(item)
            } else if (submissionStatus === 'submitted') {
              dataCollectioncardsSubmittedArray.push(item)
            } else if (submissionStatus === 'approved') {
              dataCollectionApprovedArray.push(item)
            } else {
              dataCollectionRejectedArray.push(item)
            }
          })

          setDataCollectionSubmitted(dataCollectioncardsSubmittedArray)
          setDataCollectionDraft(dataCollectioncardsDraftArray)
          setDataCollectioApproved(dataCollectionApprovedArray)
          setDataCollectionRejected(dataCollectionRejectedArray)
        } else {
          console.log('Data collection response is not an array')
        }

        // Cost sheet
        const costSheetData = await axios.get(API.GET_ALL_COST_SHEETS, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        const costSheetResponse = costSheetData.data
        setCostSheetData(costSheetResponse)
        const costSheetFilteredResponse = costSheetResponse.filter(
          (item) => item.dataFile.customer_id === customer_id
        )

        if (Array.isArray(costSheetFilteredResponse)) {
          let costSheetCardsDraftArray = []
          let costSheetCardsSubmittedArray = []
          let costSheetApprovedArray = []
          let costSheetRejectedArray = []

          costSheetFilteredResponse.forEach((item, index) => {
            const submissionStatus = item.submission_status
            if (
              submissionStatus === 'draft' &&
              item.dataFile.filename === null
            ) {
              costSheetCardsDraftArray.push(item)
            } else if (submissionStatus === 'submitted') {
              costSheetCardsSubmittedArray.push(item)
            } else if (submissionStatus === 'approved') {
              costSheetApprovedArray.push(item)
            } else {
              costSheetRejectedArray.push(item)
            }
          })

          setCostSheetSubmitted(costSheetCardsSubmittedArray)
          setCostSheetDraft(costSheetCardsDraftArray)
          setCostSheetApproved(costSheetApprovedArray)
          setCostSheetRejected(costSheetRejectedArray)
        } else {
          console.log('Cost sheet response is not an array')
        }
        //erosion profile
        const erosionResponse = await axios.get(API.GET_ALL_EROSION_PROFILE, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        const erosionData = erosionResponse.data
        setErosionProfileData(erosionData)
        const erosionFilteredResponse = erosionData.filter(
          (item) => item.dataFile.customer_id === customer_id
        )

        console.log(erosionFilteredResponse, 'erosionFilteredResponse')

        if (Array.isArray(erosionFilteredResponse)) {
          let erosioncardsDraftArray = []
          let erosionDatacardsSubmittedArray = []
          let erosionDataCardsApprovedArray = []
          let erosionDataCardsRejectedArray = []

          erosionFilteredResponse.forEach((item) => {
            const submissionStatus = item.submission_status
            if (
              submissionStatus === 'draft' &&
              item.dataFile.filename === null
            ) {
              erosioncardsDraftArray.push(item)
            } else if (submissionStatus === 'submitted') {
              erosionDatacardsSubmittedArray.push(item)
            } else if (submissionStatus === 'approved') {
              erosionDataCardsApprovedArray.push(item)
            } else {
              erosionDataCardsRejectedArray.push(item)
            }
          })

          setErosionProfileDraft(erosioncardsDraftArray)
          setErosionProfileSubmitted(erosionDatacardsSubmittedArray)
          setErosionProfileRejected(erosionDataCardsRejectedArray)
          setErosionProfileApproved(erosionDataCardsApprovedArray)
        } else {
          console.log('Erosion Profile response is not an array')
        }
      }
    } catch (error) {
      console.error('An error occurred while fetching submitted cards:', error)
    }
  }
  useEffect(() => {
    fetchCards()
  }, [])

  const handleFileUploads = (e) => {
    e.preventDefault()
    const uploadedFiles = e.target.files
    const maxFileSize = 2 * 1024 * 1024 // 2MB in bytes

    console.log('Uploaded Files:', uploadedFiles)

    if (uploadedFiles.length === 0) {
      setFile(null)
      return
    }

    const uploadedFile = uploadedFiles[0]
    console.log('Uploaded File:', uploadedFile)

    // Check if the file size exceeds the maximum limit
    if (uploadedFile.size > maxFileSize) {
      alert('File size exceeds 2MB limit.')
      setFile(null) // Clear the file if it exceeds the limit
      return
    }

    // Proceed with setting the file
    setFile(uploadedFile)
  }

  useEffect(() => {
    // Clear file when modal is closed
    if (!showModals) {
      setFile(null)
    }
  }, [showModals])

  const handleButtonClick = (e) => {
    console.log('button cliked')
    e.preventDefault()
    // Trigger file input click event
    const fileInput = document.getElementById('file-input')
    fileInput.click()
    // handleFormSubmit();
  }

  const [showModal, setShowModal] = useState(false)
  const storedCustomerDet = JSON.parse(localStorage.getItem('selectedCustomer'))

  const [selectCustomer, setSelectCustomer] = useState('')
  const [page, setPage] = useState(0)
  const rowsPerPage = 15

  const handleChange = async (event) => {
    const { name, value } = event.target
    if (name === 'customer_id') {
      setFormData({ ...formData, [name]: value })
      await fetchFurnaceData(value)
    } else {
      setFormData({ ...formData, [name]: value })
    }
  }

  useEffect(() => {}, [selectCustomer])
  useEffect(() => {
    if (customers.length > 0) {
      setSelectCustomer(
        customers.filter((x) => x.id === storedCustomerDet.id).length > 0 &&
          customers.filter((x) => x.id === storedCustomerDet.id)[0].id
          ? customers.filter((x) => x.id === storedCustomerDet.id)[0].id
          : null
      )
    }
  }, [customers])

  useEffect(() => {
    fetchCustomers()
  }, [])

  const handleCustomerChange = (selectedOption) => {
    const selectedCustomerDetails = customers.find(
      (customer) => customer.id === selectedOption
    )

    if (selectedCustomerDetails) {
      setSelectCustomer(selectedCustomerDetails.id)
      localStorage.setItem(
        'selectedCustomer',
        JSON.stringify(selectedCustomerDetails)
      )
      setTimeout(() => {
        // window.location.reload()
        fetchCustomers()
      }, 0)
    } else {
      console.error('Selected customer not found in the customers list')
    }
  }

  const fetchFurnaceData = async (value) => {
    try {
      const response = await axios.get(API.GET_FURNACES, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      console.log('furnace response', response)
      console.log('value', value)
      const matchingFurnaces = response.data.filter(
        (furnace) => furnace.assign_customer_id === value
      )

      if (matchingFurnaces) {
        console.log('Matching Furnace:', matchingFurnaces)
        const matchingFurnaceArr = matchingFurnaces
        setFurnaces(matchingFurnaceArr)
      } else {
        setFurnaces([])
        console.log('No matching furnace found')
      }
      //   setFurnaces(response.data);
    } catch (error) {
      console.error('Error fetching users:', error)
    }
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await axios.get(API.GET_TEMPLATES_FROM_BLOB)
        setBlobTemplates(response.data.downloadedTemplates)
        setTemplateNames(response.data.templateNames)
      } catch (error) {
        console.error('Error fetching templates:', error)
      }
    }
    fetchTemplates()
  }, [])

  const handleDownload = async (templateName) => {
    console.log(templateName, 'templateName')
    try {
      const template = blobtemplates.find((t) => t.name === templateName)
      if (template) {
        const byteCharacters = atob(template.data)
        const byteNumbers = new Array(byteCharacters.length)

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)
        const blob = new Blob([byteArray], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })

        FileSaver.saveAs(blob, template.name)
      } else {
        console.error(`Template ${templateName} not found`)
      }
    } catch (error) {
      console.error(`Error downloading ${templateName} template:`, error)
    }
  }

  const handleDownloadAll = async () => {
    try {
      const zip = new JSZip()

      blobtemplates.forEach((template) => {
        const byteCharacters = atob(template.data)
        const byteNumbers = new Array(byteCharacters.length)

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i)
        }

        const byteArray = new Uint8Array(byteNumbers)
        const blob = new Blob([byteArray], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        zip.file(template.name, blob)
      })

      const zipBlob = await zip.generateAsync({ type: 'blob' })
      FileSaver.saveAs(zipBlob, 'All Templates.zip')
    } catch (error) {
      console.error('Error downloading all templates:', error)
    }
  }

  const handleModalClose = () => {
    setShowModals(false)
  }

  const [excelFile, setExcelFile] = useState(null)
  const [typeError, setTypeError] = useState(null)

  // submit state
  const [excelData, setExcelData] = useState(null)

  // onchange event
  const handleFile = (e) => {
    let fileTypes = [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'text/csv',
    ]
    let selectedFile = e.target.files[0]
    if (selectedFile) {
      if (selectedFile && fileTypes.includes(selectedFile.type)) {
        setTypeError(null)
        let reader = new FileReader()
        reader.readAsArrayBuffer(selectedFile)
        reader.onload = (e) => {
          setExcelFile(e.target.result)
        }
      } else {
        setTypeError('Please select only excel file types')
        setExcelFile(null)
      }
    } else {
      console.log('Please select your file')
    }
  }

  //provide code for handleExtractData with formats

  //   const handleExtractData = async (segment) => {
  //     try {
  //       if (file) {

  //         console.log("segment",segment);
  //         console.log("file",file)
  //         const reader = new FileReader();
  //         reader.readAsArrayBuffer(file);
  //         await new Promise((resolve, reject) => {
  //         reader.onload =  (e) => {
  //           console.log("hello")

  //           const data = new Uint8Array(e.target.result);
  //           const workbook = XLSX.read(data, { type: 'array' });
  //           const worksheetName = workbook.SheetNames[0];
  //           const worksheet = workbook.Sheets[worksheetName];
  //           let headerRange;
  //           switch (segment) {
  //             case "Segment A":
  //                 headerRange = XLSX.utils.decode_range("A7:BF7");
  //                 break;
  //             case "Segment B":
  //                 headerRange = XLSX.utils.decode_range("A7:AN7");
  //                 break;
  //             case "Segment C":
  //                 headerRange = XLSX.utils.decode_range("A15:K15");
  //                 break;
  //             default:
  //                 reject("Invalid segment");
  //         }
  //         const headers = [];
  //         for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
  //             const cellAddress = { c: C, r: headerRange.s.r };
  //             const headerCell = XLSX.utils.encode_cell(cellAddress);
  //             headers.push(worksheet[headerCell].v);
  //         }
  //         const dataRange = XLSX.utils.decode_range(worksheet['!ref']);
  //         const extracted = [];
  //         let startRow = 8;
  //         if (segment === "Segment C") {
  //           startRow = 16;
  //         }
  //         for (let R = startRow; R <= dataRange.e.r; ++R) {
  //             const row = {};
  //             for (let C = dataRange.s.c; C <= dataRange.e.c; ++C) {
  //                 const cellAddress = { c: C, r: R };
  //                 const cellRef = XLSX.utils.encode_cell(cellAddress);
  //                 const cellValue = worksheet[cellRef] ? worksheet[cellRef].v : undefined;
  //                 row[headers[C]] = cellValue;
  //             }
  //             extracted.push(row);
  //         }

  //           // const extractedData = XLSX.utils.sheet_to_json(worksheet);
  //           console.log("extractedData",extracted);
  //           if (segment !== null) {
  //             localStorage.setItem('extractedData', JSON.stringify(extracted));
  //           } else {
  //             localStorage.setItem('extractedData', null);
  //           }
  //           resolve();
  //         };

  //       reader.onerror = (error) => {
  //         reject(error);
  //     };
  // });
  // }
  //     } catch (error) {
  //       console.error('An error occurred while extracting data from Excel:', error);
  //       // Optionally, you can handle the error state here
  //     }
  //   };

  // const handleExtractData = (callback) => {
  //   try {
  //     if (file) {
  //       console.log("file",file)
  //       const reader = new FileReader();
  //       reader.onload =  (e) => {
  //         console.log("hello")

  //         const data = new Uint8Array(e.target.result);
  //         const workbook = XLSX.read(data, { type: 'array' });
  //         const worksheetName = workbook.SheetNames[0];
  //         const worksheet = workbook.Sheets[worksheetName];
  //         const headerRange = XLSX.utils.decode_range("A7:Z7");
  //       const headers = [];
  //       for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
  //           const cellAddress = { c: C, r: headerRange.s.r };
  //           const headerCell = XLSX.utils.encode_cell(cellAddress);
  //           headers.push(worksheet[headerCell].v);
  //       }
  //       const dataRange = XLSX.utils.decode_range(worksheet['!ref']);
  //       const extractedData = [];
  //       for (let R = 8; R <= dataRange.e.r; ++R) {
  //           const row = {};
  //           for (let C = dataRange.s.c; C <= dataRange.e.c; ++C) {
  //               const cellAddress = { c: C, r: R };
  //               const cellRef = XLSX.utils.encode_cell(cellAddress);
  //               const cellValue = worksheet[cellRef] ? worksheet[cellRef].v : undefined;
  //               row[headers[C]] = cellValue;
  //           }
  //           extractedData.push(row);
  //       }

  //         // const extractedData = XLSX.utils.sheet_to_json(worksheet);
  //         console.log("extractedData",extractedData)

  //         // setExtractedData(extractedData.slice(0, 10));
  //         setExtractedData(extractedData);
  //         callback(extractedData);
  //       };
  //       reader.readAsArrayBuffer(file);
  //     }
  //   } catch (error) {
  //     console.error('An error occurred while extracting data from Excel:', error);
  //     // Optionally, you can handle the error state here
  //   }
  // };

  const handleClose = () => {
    setShowModal(false)
  }

  const getCustomerName = async () => {
    const getCustomerDetails = customers.find(
      (customer) => customer.id === formData.customer_id
    )
    const customerName = getCustomerDetails.organization_name
    const trigram = Helper.getCutomerTrigram(customerName)
    return trigram
  }

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue)
  }
  const handleSegmentChange = (event) => {
    const selectedSegmentName = event.target.value
    setselectedSegment(selectedSegmentName)
    console.log('SegmentList', SegmentList)
    const selectedSegmentObj = SegmentList.find(
      (item) => item.name === selectedSegmentName
    )
    console.log('selectedSegmentObj', selectedSegmentObj)
    if (selectedSegmentObj) {
      setSegmentid(selectedSegmentObj.id)
    }
  }

  const style = {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 5,
    p: 4,
    outline: 'none',
    zIndex: 1,
  }

  return (
    <div>
      <Grid container justifyContent={'flex-end'}>
        <Grid
          item
          xs={12}
          md={9}
          style={{ display: 'flex', alignItems: 'flex-start' }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="Tabs"
            indicatorColor="primary"
            TabIndicatorProps={{
              style: {
                display: 'flex',
                justifyContent: 'center',
              },
              children: <span style={{ width: '100%', maxWidth: '140px' }} />,
            }}
          >
            <Tab label="Daily trial monitoring" />
            <Tab label="NRM performance" />
            <Tab label="Erosion profile" />
            <Tab label="Data Collection" />
            <Tab label="Cost sheet" />
          </Tabs>
          {selectedTab === 0 && (
            <Typography
              variant="body1"
              style={{
                marginTop: '70px',
                width: '100%',
                position: 'absolute',
                fontFamily: 'Inter',
                fontSize: '18px',
                fontWeight: 400,
              }}
            >
              Daily Trial Board View
            </Typography>
          )}

          {selectedTab === 1 && (
            <Typography
              variant="body1"
              style={{
                marginTop: '70px',
                width: '100%',
                position: 'absolute',
                fontFamily: 'Inter',
                fontSize: '18px',
                fontWeight: 400,
              }}
            >
              NRM Performance Board View
            </Typography>
          )}

          {selectedTab === 2 && (
            <Typography
              variant="body1"
              style={{
                marginTop: '70px',
                width: '100%',
                position: 'absolute',
                fontFamily: 'Inter',
                fontSize: '18px',
                fontWeight: 400,
              }}
            >
              Erosion Profile Board View
            </Typography>
          )}

          {selectedTab === 3 && (
            <Typography
              variant="body1"
              style={{
                marginTop: '70px',
                width: '100%',
                position: 'absolute',
                fontFamily: 'Inter',
                fontSize: '18px',
                fontWeight: 400,
              }}
            >
              Data Collection Board View
            </Typography>
          )}

          {selectedTab === 4 && (
            <Typography
              variant="body1"
              style={{
                marginTop: '70px',
                width: '100%',
                position: 'absolute',
                fontFamily: 'Inter',
                fontSize: '18px',
                fontWeight: 400,
              }}
            >
              Cost Sheet Board View
            </Typography>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          md={3}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginTop: '10px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <div style={{ position: 'relative' }}>
              <img
                src={FilterIcon}
                alt="Filter Icon"
                style={{
                  width: '45px',
                  height: '40px',
                  marginRight: '15px',
                  cursor: 'pointer',
                  marginTop: '8px',
                }}
                onClick={handleOpen}
              />
              {open && (
                <Box sx={style} className="filter-container">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                  >
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleApply}
                      >
                        Apply
                      </Button>
                      <Button
                        variant="text"
                        color="primary"
                        onClick={handleReset}
                      >
                        Reset
                      </Button>
                    </div>
                    <IconButton onClick={handleFilterClose}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <Divider />
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <div>Date</div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TextField
                        id="from-date"
                        label="From Date"
                        type="date"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        style={{ marginBottom: '10px', borderRadius: 4 }}
                      />
                      <TextField
                        id="to-date"
                        label="To Date"
                        type="date"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                      />
                    </AccordionDetails>
                  </Accordion>
                </Box>
              )}
            </div>
          </div>
          <img
            src={excelIcon}
            alt="Excel Icon"
            style={{
              marginRight: '8px',
              width: '50px',
              height: '44px',
              cursor: 'pointer',
              marginTop: '12px',
            }}
            onClick={() => setShowModal(true)}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={handleModalOpens}
            style={{ minWidth: '40%', padding: '11px', marginTop: '13px' }}
          >
            Upload Data File
          </Button>
          <div
            style={{
              minWidth: '200px',
              marginLeft: '5px',
              marginTop: '15px',
              minHeight: '50px',
            }}
          >
            <AutoSelectBox
              name="controllable-states-demo"
              isLabel={true}
              options={customers}
              labelCondition="organization_name"
              label="select customer"
              value={selectCustomer}
              onChange={(e) => handleCustomerChange(e.target.value)}
              error={formErrors.customer_id}
              TextFieldProps={{
                InputProps: {
                  style: { padding: '5px' }, // Add local padding here
                },
              }}
            />
          </div>

          <div style={{ marginTop: '20px' }}></div>
        </Grid>
      </Grid>

      <Modal
        open={showModal}
        onClose={handleModalClose}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // semi-transparent background
            backdropFilter: 'blur(10px)', // blurs the background
          },
        }}
      >
        <Paper>
          <Box
            sx={{
              width: 600,
              height: 518,
              top: 271,
              left: 660,
              padding: '8px 16px', // Individual padding values separated by space
              borderRadius: 6,
              background: 'white',
              border: 'none',
              gap: 16, // Gap is used for grid layouts, consider using margin for regular elements
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', margin: 15 }}>
              <div style={{ flex: 1 }}>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: 18,
                    fontWeight: 600,
                    lineHeight: '24px',
                    letterSpacing: '-0.006em',
                    textAlign: 'left',
                    marginTop: '10px',
                    color: '#252C32',
                  }}
                >
                  Download data template
                </Typography>
              </div>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '30px',
                marginRight: '15px',
                marginLeft: '10px',
              }}
            >
              <img
                src={excelIcon}
                alt="Excel Icon"
                style={{ marginRight: '5px', width: '32px', height: '32px' }}
              />
              <div
                style={{ flex: '1', display: 'flex', flexDirection: 'column' }}
              >
                <Typography
                  style={{
                    color: '#1E293B',
                    fontWeight: 700,
                    fontSize: '14px',
                    lineHeight: '21px',
                  }}
                >
                  Daily Trial Monitoring Data File Template.xlsx
                </Typography>
                <Typography
                  style={{
                    color: '#7F7D83',
                    fontFamily: 'Inter',
                    fontWeight: 400,
                    fontSize: '10px',
                    lineHeight: '15px',
                  }}
                >
                  2.0MB
                </Typography>{' '}
                {/* Display the file size text below the file name */}
              </div>

              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  handleDownload('01 Daily trial monitoring sheet v7 (1).xlsx')
                }
                startIcon={<CloudDownload />}
                sx={{ width: '118px', height: '32px', textTransform: 'none' }}
              >
                Download
              </Button>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '30px',
                marginRight: '15px',
                marginLeft: '10px',
              }}
            >
              <img
                src={excelIcon}
                alt="Excel Icon"
                style={{ marginRight: '5px', width: '32px', height: '32px' }}
              />
              <div
                style={{ flex: '1', display: 'flex', flexDirection: 'column' }}
              >
                <Typography
                  style={{
                    color: '#1E293B',
                    fontWeight: 700,
                    fontSize: '14px',
                    lineHeight: '21px',
                  }}
                >
                  NRM Performance Data File Template.xlsx
                </Typography>
                <Typography
                  style={{
                    color: '#7F7D83',
                    fontFamily: 'Inter',
                    fontWeight: 400,
                    fontSize: '10px',
                    lineHeight: '15px',
                  }}
                >
                  2.0MB
                </Typography>{' '}
                {/* Display the file size text below the file name */}
              </div>

              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleDownload('02 NRM performance sheet V9 R1.xlsx')
                }}
                startIcon={<CloudDownload />}
                sx={{ width: '118px', height: '32px', textTransform: 'none' }}
              >
                Download
              </Button>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '30px',
                marginRight: '15px',
                marginLeft: '10px',
              }}
            >
              <img
                src={excelIcon}
                alt="Excel Icon"
                style={{ marginRight: '5px', width: '32px', height: '32px' }}
              />
              <div
                style={{ flex: '1', display: 'flex', flexDirection: 'column' }}
              >
                <Typography
                  style={{
                    color: '#1E293B',
                    fontWeight: 700,
                    fontSize: '14px',
                    lineHeight: '21px',
                  }}
                >
                  Erosion Profile Data File Template.xlsx
                </Typography>
                <Typography
                  style={{
                    color: '#7F7D83',
                    fontFamily: 'Inter',
                    fontWeight: 400,
                    fontSize: '10px',
                    lineHeight: '15px',
                  }}
                >
                  2.0MB
                </Typography>{' '}
                {/* Display the file size text below the file name */}
              </div>

              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleDownload('03 Erosion profile sheet V6 1.xlsx')
                }}
                startIcon={<CloudDownload />}
                sx={{ width: '118px', height: '32px', textTransform: 'none' }}
              >
                Download
              </Button>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '30px',
                marginRight: '15px',
                marginLeft: '10px',
              }}
            >
              <img
                src={excelIcon}
                alt="Excel Icon"
                style={{ marginRight: '5px', width: '32px', height: '32px' }}
              />
              <div
                style={{ flex: '1', display: 'flex', flexDirection: 'column' }}
              >
                <Typography
                  style={{
                    color: '#1E293B',
                    fontWeight: 700,
                    fontSize: '14px',
                    lineHeight: '21px',
                  }}
                >
                  Data collection.xlsx
                </Typography>
                <Typography
                  style={{
                    color: '#7F7D83',
                    fontFamily: 'Inter',
                    fontWeight: 400,
                    fontSize: '10px',
                    lineHeight: '15px',
                  }}
                >
                  2.0MB
                </Typography>{' '}
                {/* Display the file size text below the file name */}
              </div>

              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleDownload('04 Data collection sheet V6.xlsx')
                }}
                startIcon={<CloudDownload />}
                sx={{ width: '118px', height: '32px', textTransform: 'none' }}
              >
                Download
              </Button>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '30px',
                marginRight: '15px',
                marginLeft: '10px',
              }}
            >
              <img
                src={excelIcon}
                alt="Excel Icon"
                style={{ marginRight: '5px', width: '32px', height: '32px' }}
              />
              <div
                style={{ flex: '1', display: 'flex', flexDirection: 'column' }}
              >
                <Typography
                  style={{
                    color: '#1E293B',
                    fontWeight: 700,
                    fontSize: '14px',
                    lineHeight: '21px',
                  }}
                >
                  Cost Sheet Template.xlsx
                </Typography>
                <Typography
                  style={{
                    color: '#7F7D83',
                    fontFamily: 'Inter',
                    fontWeight: 400,
                    fontSize: '10px',
                    lineHeight: '15px',
                  }}
                >
                  2.0MB
                </Typography>{' '}
                {/* Display the file size text below the file name */}
              </div>

              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleDownload('05 Cost Sheet V10.xlsx')
                }}
                startIcon={<CloudDownload />}
                sx={{ width: '118px', height: '32px', textTransform: 'none' }}
              >
                Download
              </Button>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '40px',
                marginRight: '15px',
                marginLeft: '10px',
                flexDirection: 'row',
              }}
            >
              <Typography
                style={{
                  color: '#0086DC',
                  fontWeight: 600,
                  fontSize: '12px',
                  lineHeight: '24px',
                }}
              >
                Download all Templates (.zip)
              </Typography>
              <img
                src={DownloadIcon}
                style={{ marginLeft: '15px', cursor: 'pointer' }}
                onClick={handleDownloadAll}
              />
            </div>
          </Box>
        </Paper>
      </Modal>
      <div>
        <Modal
          open={showModals}
          onClose={handleModalCloses}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          BackdropProps={{
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              backdropFilter: 'blur(10px)',
            },
          }}
        >
          <Box
            sx={{
              width: 600,
              height: 734,
              top: 172.5,
              left: 660,
              padding: '8px 16px',
              borderRadius: 3,
              background: 'white',
              border: 'none',
              gap: 16,
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ flex: 1 }}>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: 18,
                    fontWeight: 600,
                    lineHeight: '24px',
                    letterSpacing: '-0.006em',
                    textAlign: 'left',
                    marginTop: '10px',
                    color: '#252C32',
                  }}
                >
                  Upload New Data File
                </Typography>
              </div>
              <IconButton onClick={handleModalClose}>
                <CloseIcon />
              </IconButton>
            </div>

            <form>
              <div style={{ marginTop: '20px' }}>
                <AutoSelectBox
                  name="template_id"
                  labelValue="Data File Template *"
                  isLabel={true}
                  options={templates}
                  labelCondition="name"
                  value={formData.template_id}
                  onChange={handleChange}
                  error={formErrors.template_id}
                />
              </div>
              {selectedTemplate &&
                selectedTemplate.template_id !== 4 &&
                selectedTemplate &&
                selectedTemplate.template_id !== 5 && (
                  <div style={{ marginTop: '20px' }}>
                    <AutoSelectBox
                      name="segment_id"
                      labelValue="Segment *"
                      isLabel={true}
                      options={segments}
                      value={formData.segment_id}
                      labelCondition="name"
                      onChange={handleChange}
                      error={formErrors.segment_id}
                    />
                  </div>
                )}

              <div style={{ marginTop: '20px' }}>
                <AutoSelectBox
                  name="customer_id"
                  labelValue="Customer Name *"
                  isLabel={true}
                  options={selectCustomers}
                  labelCondition="organization_name"
                  value={formData.customer_id}
                  onChange={handleChange}
                  error={formErrors.customer_id}
                />
              </div>

              {selectedTemplate &&
                selectedTemplate.template_id !== 4 &&
                selectedTemplate &&
                selectedTemplate.template_id !== 5 && (
                  <div style={{ marginTop: '20px' }}>
                    <AutoSelectBox
                      name="furnace_id"
                      labelValue="Furnace Number - Capacity *"
                      isLabel={true}
                      options={furnaces}
                      labelCondition="furnace_id"
                      value={formData.furnace_id}
                      onChange={handleChange}
                      error={formErrors.furnace_id}
                    />
                  </div>
                )}

              <div>
                {!file ? (
                  <div
                    style={{
                      border: '2px dashed #00448B',
                      padding: '20px',
                      flex: 1,
                      display: 'flex',
                      width: '100%',
                      height: '150px',
                      marginTop: '10px',
                      marginRight: 20,
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '4px',
                    }}
                  >
                    <label htmlFor="file-input">
                      <button
                        style={{
                          width: 30,
                          height: 30,
                          marginLeft: 10,
                          marginBottom: 10,
                          cursor: 'pointer',
                          padding: 0, // Remove padding to ensure image is not covered
                          border: 'none', // Remove border to ensure image is not covered
                          background: 'none', // Remove background to ensure image is not covered
                        }}
                        aria-label="Upload File"
                        onClick={handleButtonClick}
                      >
                        <img src={UploadIcon} alt="Upload File" />
                      </button>
                    </label>

                    <Typography
                      style={{
                        textAlign: 'center',
                        marginLeft: 15,
                        color: 'black',
                        fontWeight: 600,
                        width: 170,
                        fontSize: '14px',
                        color: '#0086DC',
                      }}
                    >
                      Browse files
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 400,
                        lineHeight: '18px',
                        letterSpacing: '0px',
                        textAlign: 'center',
                        color: '#4F4D55',
                      }}
                    >
                      .xlsx - max file size 2MB
                    </Typography>
                    <input
                      type="file"
                      id="file-input"
                      onChange={handleFileUploads}
                      style={{ display: 'none' }}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginTop: '20px',
                      backgroundColor: '#0086DC08',
                      height: '48px',
                      borderRadius: '8px',
                      padding: '0 10px',
                      width: '100%', // Ensures the div takes the full width of its container
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <img
                        src={excelIcon}
                        alt="Excel File"
                        style={{ width: 30, height: 30, marginRight: 10 }}
                      />
                      <Typography
                        variant="body2"
                        style={{
                          fontFamily: 'Inter',
                          fontSize: '16px',
                          fontWeight: 400,
                          lineHeight: '18px',
                          letterSpacing: '0px',
                          color: '#1E293B',
                        }}
                      >
                        {file.name}
                      </Typography>
                    </div>
                    <img
                      style={{
                        cursor: 'pointer',
                        color: '#FF0000',
                      }}
                      src={trashIcon} // Use the correct variable for the trash icon source
                      alt="Delete File"
                      onClick={handleFileDelete}
                    />
                  </div>
                )}
              </div>
              <div>
                <div
                  style={{
                    width: '95%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 10,
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      width: '252px',
                      height: '40px',
                      padding: '8px 12px',
                      borderRadius: '6px',
                      color: '#252C32',
                      gap: '8px',
                      border: '1px solid #E5E9EB',
                      fontSize: '14px',
                      marginRight: '10px',
                    }}
                    onClick={handleFormCancel}
                  >
                    CANCEL
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      width: '252px',
                      height: '40px',
                      padding: '8px 12px',
                      borderRadius: '6px',
                      color: '#FFFFFF',
                      gap: '8px',
                      background: '#0086DC',
                      fontSize: '14px',
                    }}
                    onClick={handleFormSubmit}
                  >
                    SUBMIT
                  </Button>
                </div>
              </div>
            </form>
          </Box>
        </Modal>
      </div>
      {selectedTab === 0 ? (
        <React.Fragment>
          <DailyTrialMonitoring
            cardsSubmitted={cardsSubmitted}
            cardsDraft={cardsDraft}
            cardsApproved={cardsApproved}
            cardsRejected={cardsRejected}
            fileexcel={fileexcel}
            selectedTab={selectedTab}
            templateId={templateId}
            filteredDailyTrials={filteredDailyTrials}
          />
        </React.Fragment>
      ) : selectedTab === 3 ? (
        <React.Fragment>
          {console.log('templateId4', templateId)}
          <DataCollection
            cardsSubmitted={dataCollectionSubmitted}
            cardsDraft={dataCollectionDraft}
            approvedData={dataCollectionApproved}
            rejectData={dataCollectionRejected}
            fileexcel={fileexcel}
            selectedTab={selectedTab}
            templateId={templateId}
            filteredDataCollection={filteredDataCollection}
          />
        </React.Fragment>
      ) : selectedTab === 2 ? (
        <React.Fragment>
          {console.log('templateId4', templateId)}
          <ErosionProfileWeb
            cardsSubmitted={erosionProfileSubmitted}
            cardsDraft={erosionProfileDraft}
            approvedData={erosionProfileApproved}
            rejectData={erosionProfileRejected}
            fileexcel={fileexcel}
            selectedTab={selectedTab}
            templateId={templateId}
            filteredErosionProfile={filteredErosionProfile}
          />
        </React.Fragment>
      ) : selectedTab === 4 ? (
        <React.Fragment>
          {console.log('templateId5', templateId)}
          <CostSheet
            cardsSubmitted={costSheetSubmitted}
            cardsDraft={costSheetDraft}
            approvedData={costSheetApproved}
            rejectData={costSheetRejected}
            fileexcel={fileexcel}
            selectedTab={selectedTab}
            templateId={templateId}
            filteredCostSheetData={filteredCostSheetData}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          {console.log('templateIdN', templateId)}
          <NRMPerformance
            cardsSubmitted={nrmcardsSubmitted}
            cardsDraft={nrmcardsDraft}
            cardsApproved={nrmCardsApproved}
            rejectData={nrmCardsRejected}
            fileexcel={fileexcel}
            selectedTab={selectedTab}
            templateId={templateId}
            filteredNRMPerformanceData={filteredNRMPerformanceData}
          />
        </React.Fragment>
      )}
    </div>
  )
}

export default DataManagement
