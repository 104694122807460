import React, { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import TextBox from '../../input/TextBox'
import Helper from '../../services/Helper'
import AutoSelectBox from '../../input/AutoSelectBox'
import { getCountryDataList } from 'countries-list'
import { customer_details } from '../../../recoil/atoms'
import { useRecoilState } from 'recoil'
import { trial_name } from '../../../recoil/atoms'
const GeneralDetailsPage = ({ onSave }) => {
  const [custDet, setCustDet] = useRecoilState(customer_details)
  const [trialName] = useRecoilState(trial_name)
  const [params, setParams] = useState('')
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [countryOptions, setCountryOptions] = useState([])
  const currencyCodes = require('currency-codes')
  const isReadOnly = trialName === 'viewCostSheet'
  useEffect(() => {
    const formattedCountryOptions = formatCountryOptions()
    setCountryOptions(formattedCountryOptions)
    setSelectedCountry(formattedCountryOptions[0])
  }, [])

  const formatCountryOptions = () => {
    const countries = getCountryDataList()
    const countryOptions = []
    for (const code in countries) {
      const country = countries[code]
      countryOptions.push({ id: code, name: country.name })
    }
    return countryOptions
  }

  const handleCountryChange = (selectedOption) => {
    console.log()
    const selectedCountryData = getCountryDataList()[selectedOption.id]
    if (selectedCountryData) {
      const currency = selectedCountryData.currency[0]
      const updatedCustDet = {
        ...custDet,
        country: selectedCountryData.name,
        currency: currency,
      }
      setCustDet(updatedCustDet)
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setCustDet((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleParameterChange = (selectedParameter) => {
    if (selectedParameter) {
      const parameterName = selectedParameter.name
      setCustDet((prevState) => ({
        ...prevState,
        parameter: parameterName,
      }))
      setParams(parameterName)
    }
  }

  useEffect(() => {
    const data = {
      parameter: custDet.parameter,
      customerName: custDet.customer_name,
      country: custDet.country,
      currency: custDet.currency,
    }
    localStorage.setItem('customerDetails', JSON.stringify(data))
  }, [custDet])

  return (
    <div sx={{ padding: 4, maxWidth: 600, margin: '0 auto' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body1"
          style={{
            color: '#000000',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '20px',
            marginTop: '0px',
          }}
        >
          Enter General Details
        </Typography>
      </div>
      <div style={{ marginTop: '20px' }}>
        <AutoSelectBox
          name="Parameter"
          labelValue="Parameter"
          isLabel={true}
          options={Helper.Parameter}
          value={
            Helper.Parameter.find(
              (option) => option.name === custDet.parameter
            ) || ''
          }
          onChange={handleParameterChange}
          disabled={isReadOnly}
        />
      </div>
      <div>
        <TextBox
          name="customer_name"
          label="Customer Name"
          value={custDet.customer_name}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginBottom: '15px' }}>
        <AutoSelectBox
          labelValue="Select Country"
          name="Select_country"
          isLabel={true}
          options={countryOptions}
          value={
            countryOptions.find((option) => option.name === custDet.country) ||
            ''
          }
          onChange={handleCountryChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginBottom: '15px' }}>
        <TextBox
          label="Select Currency"
          name="Select_currency"
          isLabel={true}
          value={custDet.currency}
          disabled={isReadOnly}
        />
      </div>
    </div>
  )
}

export default GeneralDetailsPage
