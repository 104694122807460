import { React, useEffect } from 'react'
import AutoSelectBox from '../../input/AutoSelectBox'
import Typography from '@mui/material/Typography'
import Helper from '../../services/Helper'
import TextBox from '../../input/TextBox'
import { useRecoilState } from 'recoil'
import { sintering_heats_nrm, sintering_heats } from '../../../recoil/atoms'
import StyledButton from '../../input/StyledButton'
import {
  chargedSinteringPerMonthSelector,
  costSinteringHeatPerMonthSelector,
  costOfReplacingWithDRISelector,
  costOfchargedSinteringHeatPerMTSelector,
  chargedSinteringPerMonthNRMSelector,
  costSinteringHeatPerMonthNRMSelector,
  costOfReplacingWithDRINRMSelector,
  costOfchargedSinteringHeatPerMTNRMSelector,
} from '../../../recoil/selector'
import { useRecoilValue } from 'recoil'

export default function SinteringHeats() {
  const [sinteringHeats, setSinteringHeats] = useRecoilState(sintering_heats)
  const [sinteringHeatsNRM, setSinteringHeatsNRM] =
    useRecoilState(sintering_heats_nrm)
  console.log(sinteringHeats, 'sinteringHeats')

  const handleSinteringHeatChange = (name, value) => {
    const regex = /^[0-9]*\.?[0-9]{0,2}$/

    if (regex.test(value) || value === '') {
      setSinteringHeats((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const handleSinteringHeatNRMChange = (name, value) => {
    const regex = /^[0-9]*\.?[0-9]{0,2}$/

    if (regex.test(value) || value === '') {
      setSinteringHeatsNRM((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const noOfSintering = JSON.parse(localStorage.getItem('noOfSintering'))
  const noOfSinteringNRM = JSON.parse(localStorage.getItem('noOfSinteringNRM'))
  const actualProdInMonth = JSON.parse(
    localStorage.getItem('actualProdInMonth')
  )
  const actualProdInMonthNRM = JSON.parse(
    localStorage.getItem('actualProdInMonthNRM')
  )

  const chargedSinteringPerMonth = useRecoilValue(
    chargedSinteringPerMonthSelector
  )
  console.log(chargedSinteringPerMonth, 'chargedSinteringPerMonth')
  const costSinteringHeatPerMonth = useRecoilValue(
    costSinteringHeatPerMonthSelector
  )
  const costOfReplacingWithDRI = useRecoilValue(costOfReplacingWithDRISelector)
  const costOfchargedSinteringHeatPerMT = useRecoilValue(
    costOfchargedSinteringHeatPerMTSelector
  )
  // nrm
  const chargedSinteringPerMonthNRM = useRecoilValue(
    chargedSinteringPerMonthNRMSelector
  )
  const costSinteringHeatPerMonthNRM = useRecoilValue(
    costSinteringHeatPerMonthNRMSelector
  )
  const costOfReplacingWithDRINRM = useRecoilValue(
    costOfReplacingWithDRINRMSelector
  )
  const costOfchargedSinteringHeatPerMTNRM = useRecoilValue(
    costOfchargedSinteringHeatPerMTNRMSelector
  )

  // calculation
  //basic
  // const chargedSinteringPerMonth = (Number(sinteringHeats.charge_mix_regular_heat) * Number(noOfSintering))
  // console.log(chargedSinteringPerMonth, "chargedSinteringPerMonth")

  // const costSinteringHeatPerMonth = Number(chargedSinteringPerMonth) * Number(sinteringHeats.market_price_sintering_scrap)
  // console.log(costSinteringHeatPerMonth, "costSinteringHeatPerMonth")

  // const costOfReplacingWithDRI = Number(costSinteringHeatPerMonth) - (((Number(costSinteringHeatPerMonth) * Number(sinteringHeats.yield_of_sintering_scrap)) / Number(sinteringHeats.yield_of_dri) * Number(sinteringHeats.market_price_of_dri_mt)))
  // console.log(costOfReplacingWithDRI, "costOfReplacingWithDRI")

  // const costOfchargedSinteringHeatPerMT = Number(costOfReplacingWithDRI) / Number(actualProdInMonth)
  // console.log(costOfchargedSinteringHeatPerMT, "costOfchargedSinteringHeatPerMT")

  //nrm
  // const chargedSinteringPerMonthNRM = (Number(sinteringHeatsNRM.charge_mix_regular_heat) * Number(noOfSinteringNRM))
  // console.log(chargedSinteringPerMonth, "chargedSinteringPerMonth")

  // const costSinteringHeatPerMonthNRM = Number(chargedSinteringPerMonthNRM) * Number(sinteringHeatsNRM.market_price_sintering_scrap)
  // console.log(costSinteringHeatPerMonthNRM, "costSinteringHeatPerMonthNRM")

  // const costOfReplacingWithDRINRM = Number(costSinteringHeatPerMonthNRM) - (((Number(costSinteringHeatPerMonthNRM) * Number(sinteringHeatsNRM.yield_of_sintering_scrap)) / Number(sinteringHeatsNRM.yield_of_dri) * Number(sinteringHeatsNRM.market_price_of_dri_mt)))
  // console.log(costOfReplacingWithDRINRM, "costOfReplacingWithDRINRM")

  // const costOfchargedSinteringHeatPerMTNRM = Number(costOfReplacingWithDRINRM) / Number(actualProdInMonthNRM)
  // console.log(costOfchargedSinteringHeatPerMTNRM, "costOfchargedSinteringHeatPerMTNRM")

  const sinteringHeatObj = {
    charge_mix_regular_heat: sinteringHeats.charge_mix_regular_heat,
    labor_cosyield_of_sintering_scrapt_campaign:
      sinteringHeats.yield_of_sintering_scrap,
    market_price_sintering_scrap: sinteringHeats.market_price_sintering_scrap,
    percentage_scrap_addition: sinteringHeats.percentage_scrap_addition,
    excess_scrap_charged_per_sinteringHeat:
      sinteringHeats.excess_scrap_charged_per_sinteringHeat,
    excess_scrap_charged__sintering_perMonth: chargedSinteringPerMonth,
    yield_of_dri: sinteringHeats.yield_of_dri,
    market_price_of_dri_mt: sinteringHeats.market_price_of_dri_mt,
    cost_charged_due_sinteringHeat_perMonth: costSinteringHeatPerMonth,
    cost_of_replacing_with_dri: costOfReplacingWithDRI,
    cost_charged_sinteringHeat_per_MT: costOfchargedSinteringHeatPerMT,
  }

  const sinteringHeaNRMtObj = {
    charge_mix_regular_heat: sinteringHeatsNRM.charge_mix_regular_heat,
    labor_cosyield_of_sintering_scrapt_campaign:
      sinteringHeatsNRM.yield_of_sintering_scrap,
    market_price_sintering_scrap:
      sinteringHeatsNRM.market_price_sintering_scrap,
    percentage_scrap_addition: sinteringHeatsNRM.percentage_scrap_addition,
    excess_scrap_charged_per_sinteringHeat:
      sinteringHeatsNRM.excess_scrap_charged_per_sinteringHeat,
    excess_scrap_charged__sintering_perMonth: chargedSinteringPerMonthNRM,
    yield_of_dri: sinteringHeatsNRM.yield_of_dri,
    market_price_of_dri_mt: sinteringHeatsNRM.market_price_of_dri_mt,
    cost_charged_due_sinteringHeat_perMonth: costSinteringHeatPerMonthNRM,
    cost_of_replacing_with_dri: costOfReplacingWithDRINRM,
    cost_charged_sinteringHeat_per_MT: costOfchargedSinteringHeatPerMTNRM,
  }

  // useEffect(() => {
  //   if(props.trialName === 'costSheetEdit' && props.editSavingsDetails.length > 0 ){
  //     console.log(props.editSavingsDetails, "props.editSavingsDetails")
  //     let editObj = doesKeyExist(props.editSavingsDetails, 'sinteringHeatDataObject')
  //     console.log(editObj, "editObj")
  //     if (editObj){
  //       setSinteringHeats(editObj.sinteringHeatsDataObject.sinteringHeat.basic)
  //       setSinteringHeatsNRM(editObj.sinteringHeatsDataObject.sinteringHeat.nrm)

  //     }

  //   }
  // }, [props.editSavingsDetails])

  // function doesKeyExist(array, key) {
  //   for (let i = 0; i < array.length; i++) {
  //     if (Array.isArray(array[i])) {
  //       // If the current item is an array, recursively search within it
  //       const result = doesKeyExist(array[i], key);
  //       if (result) return result;
  //     } else if (typeof array[i] === 'object') {
  //       // If the current item is an object, check if it has the desired key
  //       if (array[i].hasOwnProperty(key)) {
  //         return array[i];
  //       }
  //     }
  //   }
  //   return false;

  const custDet = JSON.parse(localStorage.getItem('customerDetails'))
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body1"
          style={{
            color: '#000000',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '20px',
            marginTop: '0px',
          }}
        >
          {custDet.parameter}
        </Typography>
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="charge_mix_regular_heat"
          label="Yield of Overall charge mix in regular heat - (%)"
          type="text"
          value={sinteringHeats.charge_mix_regular_heat}
          onChange={(event) =>
            handleSinteringHeatChange(
              'charge_mix_regular_heat',
              event.target.value
            )
          }
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="yield_of_sintering_scrap"
          label="Yield of sintering scrap - (%)"
          type="text"
          value={sinteringHeats.yield_of_sintering_scrap}
          onChange={(event) =>
            handleSinteringHeatChange(
              'yield_of_sintering_scrap',
              event.target.value
            )
          }
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="market_price_sintering_scrap"
          label="Market price of sintering scrap/MT - (Rs./MT)"
          type="text"
          value={sinteringHeats.market_price_sintering_scrap}
          onChange={(event) =>
            handleSinteringHeatChange(
              'market_price_sintering_scrap',
              event.target.value
            )
          }
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="percentage_scrap_addition"
          label="Percentage Scrap Addition difference between sintering and regular heat - (%)"
          type="text"
          value={sinteringHeats.percentage_scrap_addition}
          onChange={(event) =>
            handleSinteringHeatChange(
              'percentage_scrap_addition',
              event.target.value
            )
          }
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="excess_scrap_charged_per_sinteringHeat"
          label="Excess Scrap charged per sintering heat - (MT)"
          type="text"
          value={sinteringHeats.excess_scrap_charged_per_sinteringHeat}
          onChange={(event) =>
            handleSinteringHeatChange(
              'excess_scrap_charged_per_sinteringHeat',
              event.target.value
            )
          }
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="mobile_number"
          label="Excess Scrap charged due to sintering per month - (MT)"
          type="text"
          value={chargedSinteringPerMonth ? chargedSinteringPerMonth : '0.00'}
          onChange={() => {}}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="yield_of_dri"
          label="Yield of DRI (For DRI Customer) or Regular Scrap (For Scrap Customer) in regular heat - (%)"
          type="text"
          value={sinteringHeats.yield_of_dri}
          onChange={(event) =>
            handleSinteringHeatChange('yield_of_dri', event.target.value)
          }
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="market_price_of_dri_mt"
          label="Market Price of DRI/MT (For DRI Customer) or Regular Scrap (For Scrap Customer) - (Rs./MT)"
          type="text"
          value={sinteringHeats.market_price_of_dri_mt}
          onChange={(event) =>
            handleSinteringHeatChange(
              'market_price_of_dri_mt',
              event.target.value
            )
          }
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Cost of Excess Scrap charged due to Sinteringheat (per month) - (INR/MT)"
          type="text"
          value={costSinteringHeatPerMonth ? costSinteringHeatPerMonth : '0.00'}
          onChange={() => {}}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Cost of Excess Scrap after replacing with DRI or regular Scrap(per month) - (INR/MT)"
          type="text"
          value={costOfReplacingWithDRI ? costOfReplacingWithDRI : '0.00'}
          onChange={() => {}}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Cost of Excess Scrap Charged in Sintering Heat per MT - (INR/MT)"
          type="text"
          value={
            costOfchargedSinteringHeatPerMT
              ? costOfchargedSinteringHeatPerMT
              : '0.00'
          }
          onChange={() => {}}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Scrap Cost saving in NRM due to less no. of Sintering heats - (INR/MT)"
          type="text"
          value="NA"
          onChange={() => {}}
        />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body1"
          style={{
            color: '#000000',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '20px',
            marginTop: '0px',
          }}
        >
          NRM
        </Typography>
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="charge_mix_regular_heat"
          label="Yield of Overall charge mix in regular heat - (%)"
          type="text"
          value={sinteringHeatsNRM.charge_mix_regular_heat}
          onChange={(event) =>
            handleSinteringHeatNRMChange(
              'charge_mix_regular_heat',
              event.target.value
            )
          }
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="yield_of_sintering_scrap"
          label="Yield of sintering scrap  - (%)"
          type="text"
          value={sinteringHeatsNRM.yield_of_sintering_scrap}
          onChange={(event) =>
            handleSinteringHeatNRMChange(
              'yield_of_sintering_scrap',
              event.target.value
            )
          }
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="market_price_sintering_scrap"
          label="Market price of sintering scrap/MT - (Rs./MT)"
          type="text"
          value={sinteringHeatsNRM.market_price_sintering_scrap}
          onChange={(event) =>
            handleSinteringHeatNRMChange(
              'market_price_sintering_scrap',
              event.target.value
            )
          }
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="percentage_scrap_addition"
          label="Percentage Scrap Addition difference between sintering and regular heat - (%)"
          type="text"
          value={sinteringHeatsNRM.percentage_scrap_addition}
          onChange={(event) =>
            handleSinteringHeatNRMChange(
              'percentage_scrap_addition',
              event.target.value
            )
          }
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="excess_scrap_charged_per_sinteringHeat"
          label="Excess Scrap charged per sintering heat - (MT)"
          type="text"
          value={sinteringHeatsNRM.excess_scrap_charged_per_sinteringHeat}
          onChange={(event) =>
            handleSinteringHeatNRMChange(
              'excess_scrap_charged_per_sinteringHeat',
              event.target.value
            )
          }
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="mobile_number"
          label="Excess Scrap charged due to sintering per month - (MT)"
          type="text"
          value={
            chargedSinteringPerMonthNRM ? chargedSinteringPerMonthNRM : '0.00'
          }
          onChange={() => {}}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="yield_of_dri"
          label="Yield of DRI (For DRI Customer) or Regular Scrap (For Scrap Customer) in regular heat - (%)"
          type="text"
          value={sinteringHeatsNRM.yield_of_dri}
          onChange={(event) =>
            handleSinteringHeatNRMChange('yield_of_dri', event.target.value)
          }
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="market_price_of_dri_mt"
          label="Market Price of DRI/MT (For DRI Customer) or Regular Scrap (For Scrap Customer) - (Rs./MT)"
          type="text"
          value={sinteringHeatsNRM.market_price_of_dri_mt}
          onChange={(event) =>
            handleSinteringHeatNRMChange(
              'market_price_of_dri_mt',
              event.target.value
            )
          }
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Cost of Excess Scrap charged due to Sinteringheat (per month) - (INR/MT)"
          type="text"
          value={
            costSinteringHeatPerMonthNRM ? costSinteringHeatPerMonthNRM : '0.00'
          }
          onChange={() => {}}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Cost of Excess Scrap after replacing with DRI or regular Scrap(per month) - (INR/MT)"
          type="text"
          value={costOfReplacingWithDRINRM ? costOfReplacingWithDRINRM : '0.00'}
          onChange={() => {}}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Cost of Excess Scrap Charged in Sintering Heat per MT - (INR/MT)"
          type="text"
          value={
            costOfchargedSinteringHeatPerMTNRM
              ? costOfchargedSinteringHeatPerMTNRM
              : '0.00'
          }
          onChange={() => {}}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Scrap Cost saving in NRM due to less no. of Sintering heats - (INR/MT)"
          type="text"
          value="NA"
          onChange={() => {}}
        />
      </div>
    </div>
  )
}
