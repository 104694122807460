import React, { useState, useEffect } from 'react'
import { Typography } from '@mui/material'
import StyledButton from '../../input/StyledButton'
import GeneralDetails from './GeneralDetails'
import FurnaceDetails from './FurnaceDetails'
import Carbon from './Carbon'
import ChargeMixDetails from './ChargeMixDetails'
import AlloyingAddition from './AlloyingAddition'
import OtherParameters from './OtherParameters'
import CalculatedVariables from '../../../pages/mobile/dailytrialmonitoring/OtherCalculatedVariables'
import {
  totalMnAddedSelector,
  cpcPerTonSelector,
  metCokePerTonSelector,
  SiMnPerTonSelector,
  FeMnPerTonSelector,
  feSiPerTonSelector,
  feCrPerTonSelector,
  MnDifferenceSelector,
  CrDifferenceSelector,
  MnRecoverySelector,
} from '../../../recoil/selector'
import {
  general_details,
  furnace_details,
  charge_mix_details,
  carbon,
  alloying_addition,
  other_parameters,
  trial_name,
  carbon_type,
} from '../../../recoil/atoms'
import { useRecoilState, useRecoilValue } from 'recoil'
import axios from 'axios'
import API from '../../services/API'
import { useNavigate } from 'react-router-dom'
import AlertDialog from '../../input/AlertDialog'
import CircularProgress from '@mui/material/CircularProgress'
const steps = [
  'General Details',
  'Furnace Details',
  'Charge Mix Details',
  'Carbon',
  'Alloying Addition',
  'Other Parameters',
]

function DailyTrialMonitoringM() {
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState(0)
  const [generalDetails, setGeneralDetails] = useRecoilState(general_details)
  const [furnaceDetails, setFurnaceDetails] = useRecoilState(furnace_details)
  const [chargeMixDetails, setChargeMixDetails] =
    useRecoilState(charge_mix_details)
  const [carbonObj, setCarbonObj] = useRecoilState(carbon)
  const [alloyingAddition, setAlloyingAddition] =
    useRecoilState(alloying_addition)
  const [otherParameters, setOtherParameters] = useRecoilState(other_parameters)
  const [loading, setLoading] = useState(false) // Loader state
  const [error, setError] = useState(null) // Error state
  const [trialName] = useRecoilState(trial_name)
  console.log('trialName123344', trialName)
  const progressText = `${activeStep + 1} of ${steps.length} datas completed`
  const currentUser = JSON.parse(localStorage.getItem('user'))
  const [carbonType, setCarbonType] = useRecoilState(carbon_type)
  const [label, setLabel] = useState('')
  const access_token = localStorage.getItem('accesstoken')

  /////////////offline//////////

  const [isOnline, setIsOnline] = useState(window.navigator.onLine)

  const [db, setDb] = useState(null)

  useEffect(() => {
    const handleOnline = () => {
      if (!isOnline) {
        setIsOnline(true)
        localStorage.setItem('onlinestate', 1)

        window.location.reload()
      }
    }

    const handleOffline = () => {
      localStorage.setItem('onlinestate', 0)
      // setIsOnlines(false)
      setIsOnline(false)
      // navigate('/reports')
    }

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [isOnline])

  const dbName = 'pcr'
  const customerName = 'customers'
  const furnacesName = 'furnaces'
  const templateName = 'template'
  const segmentName = 'segment'
  const datafileName = 'datafile'
  const dailytrialName = 'daily_trial'
  const nrmName = 'nrm_performance'
  const erosionName = 'erosion_profile'
  const dataCollectionName = 'data_collection'
  const costSheetName = 'cost_sheet'
  const LiningName = 'lining_patching_no'

  useEffect(() => {
    if (!isOnline) {
      const request = indexedDB.open(dbName, 1)

      request.onupgradeneeded = (event) => {
        const database = event.target.result
        database.createObjectStore(customerName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(furnacesName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(templateName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(segmentName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(datafileName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(dailytrialName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(nrmName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(erosionName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(dataCollectionName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(costSheetName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(LiningName, {
          keyPath: 'id',
          autoIncrement: true,
        })
      }

      request.onsuccess = (event) => {
        const database = event.target.result
        const transaction = database.transaction(
          [
            'customers',
            'furnaces',
            'template',
            'segment',
            'datafile',
            'daily_trial',
            'nrm_performance',
            'erosion_profile',
            'data_collection',
            'cost_sheet',
            'lining_patching_no',
          ],
          'readwrite'
        )

        setDb(database)
      }

      request.onerror = (event) => {
        console.error('Error opening IndexedDB', event)
      }
    }
  }, [isOnline])

  //other calculatedVarialble
  const totalMnAdded = useRecoilValue(totalMnAddedSelector)
  const cpcPerTon = useRecoilValue(cpcPerTonSelector)
  const metCokePerTon = useRecoilValue(metCokePerTonSelector)
  const SiMnPerTon = useRecoilValue(SiMnPerTonSelector)
  const FeMnPerTon = useRecoilValue(FeMnPerTonSelector)
  const FeSiPerTon = useRecoilValue(feSiPerTonSelector)
  const FeCrPerTon = useRecoilValue(feCrPerTonSelector)
  const MnDifference = useRecoilValue(MnDifferenceSelector)
  const CrDifference = useRecoilValue(CrDifferenceSelector)
  const MnRecovery = useRecoilValue(MnRecoverySelector)

  const otherCalculatedVariables = [
    {
      totalMnAdded: totalMnAdded,
      cpcPerTon: cpcPerTon,
      metCokePerTon: metCokePerTon,
      SiMnPerTon: SiMnPerTon,
      FeMnPerTon: FeMnPerTon,
      FeSiPerTon: FeSiPerTon,
      FeCrPerTon: FeCrPerTon,
      MnDifference: MnDifference,
      CrDifference: CrDifference,
      MnRecovery: MnRecovery,
    },
  ]

  console.log(otherCalculatedVariables, 'otherCalculatedVariables')

  console.log('generalDetails', generalDetails)
  console.log('furnaceDetails', furnaceDetails)
  console.log('chargeMixDetails', chargeMixDetails)
  console.log('carbon', carbonObj)
  console.log('alloyingAddition', alloyingAddition)
  console.log('otherParameters', otherParameters)

  const isEdit = localStorage.getItem('Trial')
  console.log('isEditisEdit', isEdit)

  useEffect(() => {
    const fetchData = async () => {
      if (isEdit === 'edit' || isEdit === 'view') {
        console.log('true')
        setLoading(true)
        setError(null)
        try {
          const id = localStorage.getItem('daily_trial_id')
          const TrialIndex = localStorage.getItem('TrialIndex')

          const response = await axios.get(
            `${API.GET_DAILY_TRIAL_BY_ID}/${id}`,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          )
          console.log(response, 'view Trial')
          const generalDetails = response.data.general_details[TrialIndex]
          const furnaceDetails = response.data.furnace_details[TrialIndex]
          const chargeMixDetails = response.data.charge_mix_details[TrialIndex]
          const carbonObject = response.data.carbon[TrialIndex]
          const alloyingAddition = response.data.alloying_addition[TrialIndex]
          const otherParameters = response.data.other_parameters[TrialIndex]

          setGeneralDetails(generalDetails)
          setFurnaceDetails(furnaceDetails)
          setChargeMixDetails(chargeMixDetails)
          setCarbonObj(carbonObject)
          setAlloyingAddition(alloyingAddition)
          setOtherParameters(otherParameters)
        } catch (error) {
          console.error('Error fetching data:', error)
          setError(error.message)
          // Handle error
        } finally {
          setLoading(false)
        }
      } else {
        setGeneralDetails({})
        setFurnaceDetails({})
        setChargeMixDetails({})
        setCarbonObj({
          id: '',
          met_coke: '',
          fifty_c_percentage: '',
          eightyfive_c_percentage: '',
          final_c_percentage: '',
        })
        setAlloyingAddition({})
        setOtherParameters({})
      }
    }

    if (isOnline) {
      fetchData() // Call the function to fetch data
    }
  }, [isEdit, isOnline])

  useEffect(() => {
    if (db) {
      fetchData()
    }
  }, [db])

  const fetchData = async () => {
    if (isOnline) {
      if (isEdit === 'view' || isEdit === 'edit') {
        setLoading(true)
        setError(null)
        try {
          const id = localStorage.getItem('daily_trial_id')
          const TrialIndex = localStorage.getItem('TrialIndex')

          const response = await axios.get(
            `${API.GET_DAILY_TRIAL_BY_ID}/${id}`,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          )
          console.log(response, 'view Trial')
          const generalDetails = response.data.general_details[TrialIndex]
          const furnaceDetails = response.data.furnace_details[TrialIndex]
          const chargeMixDetails = response.data.charge_mix_details[TrialIndex]
          const carbonObj = response.data.carbon[TrialIndex]
          const alloyingAddition = response.data.alloying_addition[TrialIndex]
          const otherParameters = response.data.other_parameters[TrialIndex]

          console.log('generalDetails1233', generalDetails)

          setGeneralDetails(generalDetails)
          setFurnaceDetails(furnaceDetails)
          setChargeMixDetails(chargeMixDetails)
          setCarbonObj(carbonObj)
          setAlloyingAddition(alloyingAddition)
          setOtherParameters(otherParameters)
        } catch (error) {
          console.error('Error fetching data:', error)
          setError(error.message)
          // Handle error
        } finally {
          setLoading(false)
        }
      } else {
        setGeneralDetails({})
        setFurnaceDetails({})
        setChargeMixDetails({})
        setCarbonObj({})
        setAlloyingAddition({})
        setOtherParameters({})
      }
    } else {
      if (isEdit === 'view') {
        setLoading(true)
        setError(null)
        try {
          const id = localStorage.getItem('daily_trial_id')
          const TrialIndex = localStorage.getItem('TrialIndex')

          const transaction1 = db.transaction(dailytrialName, 'readonly')
          const objectStore1 = transaction1.objectStore(dailytrialName)

          const id_int = parseInt(id, 10)
          const getAllRequest1 = objectStore1.get(id_int)

          console.log('id', typeof id)

          getAllRequest1.onsuccess = () => {
            const trialdata = getAllRequest1.result
            console.log('all123', trialdata)

            const generalDetails =
              (TrialIndex !== undefined &&
                trialdata.general_details?.[TrialIndex]) ||
              trialdata.general_details ||
              {}
            const furnaceDetails =
              (TrialIndex !== undefined &&
                trialdata.furnace_details?.[TrialIndex]) ||
              trialdata.furnace_details ||
              {}
            const chargeMixDetails =
              (TrialIndex !== undefined &&
                trialdata.charge_mix_details?.[TrialIndex]) ||
              trialdata.charge_mix_details ||
              {}
            const carbonObj =
              (TrialIndex !== undefined && trialdata.carbon?.[TrialIndex]) ||
              trialdata.carbon ||
              {}
            const alloyingAddition =
              (TrialIndex !== undefined &&
                trialdata.alloying_addition?.[TrialIndex]) ||
              trialdata.alloying_addition ||
              {}
            const otherParameters =
              (TrialIndex !== undefined &&
                trialdata.other_parameters?.[TrialIndex]) ||
              trialdata.other_parameters ||
              {}

            console.log('generalDetails1233', generalDetails)

            setGeneralDetails(generalDetails)
            setFurnaceDetails(furnaceDetails)
            setChargeMixDetails(chargeMixDetails)
            setCarbonObj(carbonObj)
            setAlloyingAddition(alloyingAddition)
            setOtherParameters(otherParameters)
          }

          getAllRequest1.onerror = (event) => {
            console.error('Error getting data from IndexedDB', event)
          }
        } catch (error) {
          console.error('Error fetching data:', error)
          setError(error.message)
          // Handle error
        } finally {
          setLoading(false)
        }
      } else {
        setGeneralDetails({})
        setFurnaceDetails({})
        setChargeMixDetails({})
        setCarbonObj({})
        setAlloyingAddition({})
        setOtherParameters({})
      }
    }
  }

  const handleNext = () => {
    console.log('activeStep', activeStep)
    if (activeStep === 5) {
      submitFormData()
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const submitFormData = async () => {
    const uploadId = localStorage.getItem('datafile_id')
    let updatedGeneralDetails

    if (currentUser.role.role_id === 3 || currentUser.role.role_id === 4) {
      updatedGeneralDetails = {
        ...generalDetails,
        submission_status: 'approved',
      }
    } else {
      updatedGeneralDetails = {
        ...generalDetails,
        submission_status: 'submitted',
      }
    }
    try {
      if (isEdit === 'new') {
        AlertDialog({
          type: 'delete',
          title: 'Submission',
          text: 'Are you sure you want to Submit for an Approval?',
          confirmButtonText: 'Yes',
          onConfirm: async () => {
            if (
              currentUser.role.role_id === 3 ||
              currentUser.role.role_id === 4
            ) {
              await insertData(updatedGeneralDetails, uploadId, 'approved')
            } else {
              await insertData(updatedGeneralDetails, uploadId, 'submitted')
            }
          },
        })
      } else if (isEdit === 'add') {
        // Code for handling the 'add' case
        const id = localStorage.getItem('daily_trial_id')
        AlertDialog({
          type: 'delete',
          title: 'Submission',
          text: 'Are you sure you want to Submit for an Approval?',
          confirmButtonText: 'Yes',
          onConfirm: async () => {
            if (
              currentUser.role.role_id === 3 ||
              currentUser.role.role_id === 4
            ) {
              await addData(updatedGeneralDetails, uploadId, id, 'approved')
            } else {
              await addData(updatedGeneralDetails, uploadId, id, 'submitted')
            }
          },
        })
      } else {
        const id = localStorage.getItem('daily_trial_id')
        const uploadId = localStorage.getItem('datafile_id')
        console.log(id, 'idididid')
        console.log(uploadId, 'uploadId')
        const TrialIndex = parseInt(localStorage.getItem('TrialIndex'))
        AlertDialog({
          type: 'delete',
          title: 'Submission',
          text: 'Are you sure you want to Submit for an Approval?',
          confirmButtonText: 'Yes',
          onConfirm: async () => {
            if (
              currentUser.role.role_id === 3 ||
              currentUser.role.role_id === 4
            ) {
              await updateData(
                updatedGeneralDetails,
                uploadId,
                id,
                TrialIndex,
                'approved'
              )
            } else {
              await updateData(
                updatedGeneralDetails,
                uploadId,
                id,
                TrialIndex,
                'submitted'
              )
            }
          },
        })
      }
      // Optionally, navigate to another page or perform other actions
    } catch (error) {
      // Handle errors
      console.error('An error occurred while inserting data:', error)
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep > 0) {
        return prevActiveStep - 1
      }
      return prevActiveStep
    })
  }

  const updateData = async (
    updatedGeneralDetails,
    uploadId,
    id,
    TrialIndex,
    status
  ) => {
    setLoading(true)
    setError(false)
    try {
      if (isOnline) {
        const response = await axios.get(`${API.GET_DAILY_TRIAL_BY_ID}/${id}`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        const data = response.data

        // Initialize arrays if they are null or undefined
        data.general_details = data.general_details || []
        data.furnace_details = data.furnace_details || []
        data.charge_mix_details = data.charge_mix_details || []
        data.carbon = data.carbon || []
        data.alloying_addition = data.alloying_addition || []
        data.other_parameters = data.other_parameters || []
        data.other_calculated_varialble = data.other_calculated_varialble || []

        // Update the values based on the index
        data.general_details[TrialIndex] = updatedGeneralDetails
        data.furnace_details[TrialIndex] = furnaceDetails
        data.charge_mix_details[TrialIndex] = chargeMixDetails
        data.carbon[TrialIndex] = carbonObj
        data.alloying_addition[TrialIndex] = alloyingAddition
        data.other_parameters[TrialIndex] = otherParameters
        data.other_calculated_varialble[TrialIndex] = otherCalculatedVariables

        if (status === 'submitted') {
          data.general_details.forEach((detail) => {
            detail.submission_status = 'submitted'
          })
        } else if (status === 'draft') {
          data.general_details.forEach((detail) => {
            detail.submission_status = 'draft'
          })
        } else {
          data.general_details.forEach((detail) => {
            detail.submission_status = 'approved'
          })
        }

        const updateResponse = await axios.put(
          `${API.FORM_UPDATE}/${id}`,
          { data: data, id: id },
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        console.log('Response:', updateResponse.data)

        if (status === 'submitted') {
          navigate('/submittedstatus')
        } else if (status === 'approved') {
          navigate('/approvestatus')
        } else {
          navigate('/draftstatus')
        }
      } else {
        const id = localStorage.getItem('daily_trial_id')
        const TrialIndex = localStorage.getItem('TrialIndex')

        const transaction = db.transaction(dailytrialName, 'readwrite')
        const objectStore = transaction.objectStore(dailytrialName)

        const id_int = parseInt(id, 10)
        const getRequest = objectStore.get(id_int)

        getRequest.onsuccess = async () => {
          const trialdata = getRequest.result

          if (!trialdata) {
            console.error('Trial data not found in IndexedDB.')
            setLoading(false)
            return
          }

          // Initialize arrays if they are null or undefined
          trialdata.general_details = trialdata.general_details || []
          trialdata.furnace_details = trialdata.furnace_details || []
          trialdata.charge_mix_details = trialdata.charge_mix_details || []
          trialdata.carbon = trialdata.carbon || []
          trialdata.alloying_addition = trialdata.alloying_addition || []
          trialdata.other_parameters = trialdata.other_parameters || []
          trialdata.other_calculated_varialble =
            trialdata.other_calculated_varialble || []

          // Update the specific row based on TrialIndex
          trialdata.general_details[TrialIndex] = updatedGeneralDetails
          trialdata.furnace_details[TrialIndex] = furnaceDetails
          trialdata.charge_mix_details[TrialIndex] = chargeMixDetails
          trialdata.carbon[TrialIndex] = carbonObj
          trialdata.alloying_addition[TrialIndex] = alloyingAddition
          trialdata.other_parameters[TrialIndex] = otherParameters
          trialdata.other_calculated_varialble[TrialIndex] =
            otherCalculatedVariables

          // Update submission_status for all or specific row
          if (Array.isArray(trialdata.general_details)) {
            trialdata.general_details.forEach((detail) => {
              if (status === 'submitted') {
                detail.submission_status = 'submitted'
              } else if (status === 'draft') {
                detail.submission_status = 'draft'
              } else {
                detail.submission_status = 'approved'
              }
            })
          } else {
            trialdata.general_details = trialdata.general_details || {}
            if (status === 'submitted') {
              trialdata.general_details.submission_status = 'submitted'
            } else if (status === 'draft') {
              trialdata.general_details.submission_status = 'draft'
            } else {
              trialdata.general_details.submission_status = 'approved'
            }
          }

          const updateRequest = objectStore.put(trialdata)

          updateRequest.onsuccess = () => {
            console.log(
              'Offline Mode - Trial data updated successfully:',
              updateRequest.result
            )
            const daily_trial_id = updateRequest.result
            localStorage.setItem('daily_trial_id', daily_trial_id)

            if (status === 'submitted') {
              navigate('/submittedstatus')
            } else if (status === 'approved') {
              navigate('/approvestatus')
            } else {
              navigate('/draftstatus')
            }
          }

          updateRequest.onerror = () => {
            console.error(
              'Offline Mode - Error updating trial data in IndexedDB.'
            )
            setError('Error updating trial data in IndexedDB.')
            setLoading(false)
          }
        }

        getRequest.onerror = () => {
          console.error(
            'Offline Mode - Error fetching trial data from IndexedDB.'
          )
          setError('Error fetching trial data from IndexedDB.')
          setLoading(false)
        }
      }
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const addData = async (updatedGeneralDetails, uploadId, id, status) => {
    setError(null)
    setLoading(true)
    try {
      if (isOnline) {
        const res = await axios.get(`${API.GET_DAILY_TRIAL_BY_ID}/${id}`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        // console.log(res.data, "123res");

        const existingData = res.data

        const newTrialObj = {
          general_details: updatedGeneralDetails,
          furnace_details: furnaceDetails,
          charge_mix_details: chargeMixDetails,
          carbon: carbonObj,
          alloying_addition: alloyingAddition,
          other_parameters: otherParameters,
          other_calculated_varialble: otherCalculatedVariables,
        }

        console.log(
          existingData,
          'existingData123',
          'newTrialObj123',
          newTrialObj
        )
        Object.keys(newTrialObj).forEach((key) => {
          if (!existingData[key]) {
            // If the key doesn't exist or its value is null/undefined, initialize it as an empty array
            existingData[key] = [];
          }
          // Now push the newTrialObj data into the array
          existingData[key].push(newTrialObj[key]);
        });
        
        console.log(newTrialObj, 'newTrialObj')
        console.log(existingData)
        console.log(existingData, 'updated existingData')

        if (status === 'submitted') {
          existingData.general_details.forEach((detail) => {
            detail.submission_status = 'submitted'
          })
        } else if (status === 'draft') {
          existingData.general_details.forEach((detail) => {
            detail.submission_status = 'draft'
          })
        } else {
          existingData.general_details.forEach((detail) => {
            detail.submission_status = 'approved'
          })
        }

        const data = {
          upload_id: uploadId,
          data: existingData,
        }

        const response = await axios.put(API.UPDATE_NEW_TRIAL, data, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        console.log('Data updated successfully:', response.data)

        if (status === 'submitted') {
          navigate('/submittedstatus')
        } else if (status === 'approved') {
          navigate('/approvestatus')
        } else {
          navigate('/draftstatus')
        }
      } else {
        // Offline mode: use IndexedDB
        const transaction = db.transaction(dailytrialName, 'readonly')
        const objectStore = transaction.objectStore(dailytrialName)

        const id_int = parseInt(id, 10)

        // Define newTrialObj with updated details
        const newTrialObj = {
          general_details: updatedGeneralDetails,
          furnace_details: furnaceDetails,
          charge_mix_details: chargeMixDetails,
          carbon: carbonObj,
          alloying_addition: alloyingAddition,
          other_parameters: otherParameters,
          other_calculated_varialble: otherCalculatedVariables,
        }

        // Retrieve existing data from IndexedDB
        const getRequest = objectStore.get(id_int)

        getRequest.onsuccess = async () => {
          const existingData = getRequest.result
          console.log('existingData', existingData, typeof existingData)

          Object.keys(newTrialObj).forEach((key) => {
            if (existingData[key] === undefined) {
              existingData[key] = [] // Initialize as an array if it's undefined
            } else if (!Array.isArray(existingData[key])) {
              // Handle cases where existingData[key] is not an array (if needed)
              // For example, convert it to an array or handle differently
              existingData[key] = [existingData[key]] // Convert to array
            }

            existingData[key].push(newTrialObj[key])
          })

          if (Array.isArray(existingData.general_details)) {
            existingData.general_details.forEach((detail) => {
              if (status === 'submitted') {
                detail.submission_status = 'submitted'
              } else if (status === 'draft') {
                detail.submission_status = 'draft'
              } else {
                detail.submission_status = 'approved'
              }
            })
          } else {
            // If trialdata.general_details is not an array
            existingData.general_details = existingData.general_details || {} // Ensure it's an object
            if (status === 'submitted') {
              existingData.general_details.submission_status = 'submitted'
            } else if (status === 'draft') {
              existingData.general_details.submission_status = 'draft'
            } else {
              existingData.general_details.submission_status = 'approved'
            }
          }

          const dataToUpdate = {
            ...existingData,
          }

          const transactionUpdate2 = db.transaction(dailytrialName, 'readwrite')
          const objectStoreUpdate2 =
            transactionUpdate2.objectStore(dailytrialName)

          // Update IndexedDB with the updated data
          const updateRequest = objectStoreUpdate2.put(dataToUpdate)

          updateRequest.onsuccess = () => {
            console.log(
              'Offline Mode - Trial data updated successfully:',
              updateRequest.result
            )
            const daily_trial_id = updateRequest.result
            localStorage.setItem('daily_trial_id', daily_trial_id)

            // Navigate based on status
            if (status === 'submitted') {
              navigate('/submittedstatus')
            } else {
              navigate('/draftstatus')
            }
          }

          updateRequest.onerror = () => {
            console.error('Offline Mode - Error updating trial data')
            setError('Error updating trial data in IndexedDB.')
            setLoading(false)
          }
        }

        getRequest.onerror = () => {
          console.error('Offline Mode - Error fetching trial data')
          setError('Error fetching trial data from IndexedDB.')
          setLoading(false)
        }
      }
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }
  const insertData = async (updatedGeneralDetails, uploadId, status) => {
    setError(null)
    setLoading(true)
    try {
      if (isOnline) {
        const data = {
          upload_id: uploadId,
          data: [
            // Assuming you have an array of data objects to be inserted
            {
              general_details: updatedGeneralDetails,
              furnace_details: furnaceDetails,
              charge_mix_details: chargeMixDetails,
              carbon: carbonObj,
              alloying_addition: alloyingAddition,
              other_parameters: otherParameters,
              other_calculated_varialble: otherCalculatedVariables,
            },
          ],
        }

        // Make the POST request using axios
        const response = await axios.post(API.FORM_INSERT, data, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        const daily_trial_id = response.data.raw[0].id
        localStorage.setItem('daily_trial_id', daily_trial_id)

        // Handle the response if needed
        console.log('Data inserted successfully:', response.data.raw[0].id)
      } else {
        const data1 = {
          datafile_id: uploadId,
          created_at: new Date(),
          isDeleted: false,

          general_details: updatedGeneralDetails,
          furnace_details: furnaceDetails,
          charge_mix_details: chargeMixDetails,
          carbon: carbonObj,
          alloying_addition: alloyingAddition,
          other_parameters: otherParameters,
          other_calculated_varialble: otherCalculatedVariables,
        }
        const transaction = db.transaction(dailytrialName, 'readwrite')
        const objectStore = transaction.objectStore(dailytrialName)
        const trialdetailsrequest = objectStore.add(data1)
        trialdetailsrequest.onsuccess = () => {
          console.log(
            'File saved successfully in IndexedDB trialdetails',
            trialdetailsrequest.result
          )
          const daily_trial_id = trialdetailsrequest.result
          localStorage.setItem('daily_trial_id', daily_trial_id)
        }

        trialdetailsrequest.onerror = () => {
          console.log('Error saving file in IndexedDB')
        }
      }

      if (status === 'submitted') {
        navigate('/submittedstatus')
      } else if (status === 'approved') {
        navigate('/approvestatus')
      } else {
        navigate('/draftstatus')
      }
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleDraft = async () => {
    // navigate('/draftstatus');
    // Update submission_status to "draft"

    const updatedGeneralDetails = {
      ...generalDetails,
      submission_status: 'draft',
    }
    const datafile_id = localStorage.getItem('datafile_id')

    try {
      if (isEdit === 'new') {
        console.log('new', isEdit)
        console.log('datafile_id', datafile_id)
        await insertData(updatedGeneralDetails, datafile_id, 'draft')
      } else if (isEdit === 'add') {
        console.log('new', isEdit)
        const id = localStorage.getItem('daily_trial_id')
        await addData(updatedGeneralDetails, datafile_id, id, 'draft')
      } else {
        console.log('new', isEdit)
        const id = localStorage.getItem('daily_trial_id')
        const TrialIndex = parseInt(localStorage.getItem('TrialIndex'))
        await updateData(
          updatedGeneralDetails,
          datafile_id,
          id,
          TrialIndex,
          'draft'
        )
      }
    } catch (error) {
      // Handle errors
      console.error('An error occurred while inserting data:', error.message)
    }
  }
  // const handleDraft = async() => {
  //   // navigate('/draftstatus');
  //   // Update submission_status to "draft"

  // const updatedGeneralDetails = { ...generalDetails, submission_status: "draft" };
  // const datafile_id = localStorage.getItem("datafile_id");

  // try {
  //   if (isEdit === "new") {
  //     console.log("datafile_id", datafile_id);
  //     await insertData(updatedGeneralDetails, datafile_id, "draft");
  //   } else if (isEdit === "add") {
  //     const id = localStorage.getItem("daily_trial_id");
  //     await addData(updatedGeneralDetails, datafile_id, id, "draft");
  //   } else {
  //     const id = localStorage.getItem("daily_trial_id");
  //      const TrialIndex = parseInt(localStorage.getItem("TrialIndex"));
  //     await updateData(
  //       updatedGeneralDetails,
  //       datafile_id,
  //       id,
  //       TrialIndex,
  //       "draft"
  //     );
  //   }
  //   // Optionally, navigate to another page or perform other actions
  // } catch (error) {
  //   // Handle errors
  //   console.error("An error occurred while inserting data:", error.message);
  // }
  // }

  const handleStepClick = (index) => {
    setActiveStep(index)
  }

  return (
    <div>
      {error && <div>{error}</div>}
      {loading ? (
        <div
          style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div
          style={{
            width: '100%',
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          <div style={{ flex: 1, overflowY: 'auto' }}>
            <div style={{ marginTop: '30px' }}>
              <Typography
                variant="body1"
                style={{
                  color: '#000000',
                  fontFamily: 'Inter',
                  fontWeight: 600,
                  fontSize: '18px',
                  lineHeight: '20px',
                }}
              >
                {steps[activeStep]}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  marginBottom: '20px',
                  color: '#6E7C87',
                  fontFamily: 'Inter',
                  fontWeight: 400,
                  fontSize: '14px',
                  marginTop: '5px',
                }}
              >
                {progressText}
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '20px',
              }}
            >
              {steps.map((step, index) => (
                <div
                  key={index}
                  style={{ flex: 1, position: 'relative', marginRight: '10px' }}
                >
                  <div
                    onClick={() => handleStepClick(index)}
                    style={{
                      borderBottom: `5px solid ${index === activeStep ? '#0086DC' : '#BDBDBD'}`,
                      width: '100%',
                      marginBottom: '10px',
                      marginTop: '10px',
                      borderRadius: '7px',
                      cursor: 'pointer',
                    }}
                  />
                </div>
              ))}
            </div>

            <div>
              {activeStep === steps.length ? (
                <div>
                  <Typography>All steps completed</Typography>
                </div>
              ) : (
                <div>
                  {activeStep === 0 && <GeneralDetails />}
                  {activeStep === 1 && <FurnaceDetails />}
                  {activeStep === 2 && <ChargeMixDetails />}
                  {activeStep === 3 && <Carbon />}
                  {activeStep === 4 && <AlloyingAddition />}
                  {activeStep === 5 && <OtherParameters />}
                  {/* {activeStep === 6 && <CalculatedVariables />} */}
                </div>
              )}
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: activeStep === 0 ? 'center' : 'space-between',
              alignItems: 'center',
              marginTop: 'auto',
              marginBottom: '10px',
              position: 'sticky',
              bottom: 0,
              backgroundColor: 'white',
              zIndex: 1000,
              padding: '10px',
            }}
          >
            {activeStep !== 0 && (
              <StyledButton
                variant="outlined"
                text="Back"
                onClick={handleBack}
                style={{ marginRight: 10 }}
              />
            )}
            <div style={{ flexGrow: 1 }}></div>{' '}
            {/* This spacer div helps with the alignment */}
            <StyledButton
              variant="outlined_blue"
              color="secondary" // Choose your preferred color
              text="Save Draft"
              onClick={handleDraft}
              style={{ marginRight: 10 }}
            />
            <StyledButton
              variant="contained"
              color="primary"
              text={
                activeStep === steps.length - 1 ? 'Submit for Approval' : 'Next'
              }
              onClick={handleNext}
              style={
                activeStep === 0
                  ? { marginLeft: 'auto', marginRight: 'auto' }
                  : {}
              }
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default DailyTrialMonitoringM
