import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

function CustomAutocomplete(props) {
  const handleChange = (event, value) => {
    if (props.onChange) {
      props.onChange(event, value)
    }
  }

  return (
    <div
      style={{ minWidth: '100%', marginBottom: '16px', position: 'relative' }}
    >
      {props.isLabel && (
        <Typography
          variant="label"
          style={{ fontSize: '14px', textAlign: 'left', marginBottom: 10 }}
        >
          {props.labelValue}
        </Typography>
      )}
      <Autocomplete
        id={props.name}
        options={props.options}
        value={props.value}
        onChange={handleChange}
        disabled={props.disabled} 
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label={props.inputLabel} />
        )}
        openOnFocus={true}
      />
    </div>
  )
}

export default CustomAutocomplete
