import React, { useState, useEffect } from 'react'
import SelectBox from '../../input/SelectBox'
import TextBox from '../../input/TextBox'
import Helper from '../../services/Helper'
import { useRecoilState } from 'recoil'
import {
  lining_material_product_details,
  lining_reference,
} from '../../../recoil/atoms'
import AutoSelectBox from '../../input/AutoSelectBox'
import API from '../../services/API'
import axios from 'axios'
import CustomAutocomplete from '../../input/CustomAutocomplete'

const LiningMaterialProductDetails = () => {
  const [liningMaterialProductDetails, setLiningMaterialProductDetails] =
    useRecoilState(lining_material_product_details)
  const [liningReference] = useRecoilState(lining_reference)
  const uniqueId = liningReference.id ? liningReference.id : ''
  const [nrmProductRange, setNRMProductRange] = useState([])
  const access_token = localStorage.getItem('accesstoken')

  const [isOnline, setIsOnline] = useState(window.navigator.onLine)

  const [db, setDb] = useState(null)

  useEffect(() => {
    const handleOnline = () => {
      if (!isOnline) {
        setIsOnline(true)
        localStorage.setItem('onlinestate', 1)

        window.location.reload()
      }
    }

    const handleOffline = () => {
      localStorage.setItem('onlinestate', 0)
      // setIsOnlines(false)
      setIsOnline(false)
      // navigate('/reports')
    }

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [isOnline])

  useEffect(() => {
    console.log(
      liningMaterialProductDetails,
      'liningMaterialProductDetails123fds'
    )
  }, [])

  const dbName = 'pcr'
  const customerName = 'customers'
  const furnacesName = 'furnaces'
  const templateName = 'template'
  const segmentName = 'segment'
  const datafileName = 'datafile'
  const dailytrialName = 'daily_trial'
  const nrmName = 'nrm_performance'
  const erosionName = 'erosion_profile'
  const dataCollectionName = 'data_collection'
  const costSheetName = 'cost_sheet'
  const LiningName = 'lining_patching_no'

  useEffect(() => {
    if (!isOnline) {
      const request = indexedDB.open(dbName, 1)

      request.onupgradeneeded = (event) => {
        const database = event.target.result
        database.createObjectStore(customerName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(furnacesName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(templateName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(segmentName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(datafileName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(dailytrialName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(nrmName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(erosionName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(dataCollectionName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(costSheetName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(LiningName, {
          keyPath: 'id',
          autoIncrement: true,
        })
      }

      request.onsuccess = (event) => {
        const database = event.target.result
        const transaction = database.transaction(
          [
            'customers',
            'furnaces',
            'template',
            'segment',
            'datafile',
            'daily_trial',
            'nrm_performance',
            'erosion_profile',
            'data_collection',
            'cost_sheet',
            'lining_patching_no',
          ],
          'readwrite'
        )

        setDb(database)
      }

      request.onerror = (event) => {
        console.error('Error opening IndexedDB', event)
      }
    }
  }, [isOnline])

  useEffect(() => {
    if (db) {
      fetchLiningData()
      // fetchData();
      // fetchLiningData();
    }
  }, [db])

  useEffect(() => {
    if (isOnline) {
      fetchLiningData()
      // fetchData();
      // fetchLiningData();
    }
  }, [isOnline])

  useEffect(() => {
    fetchLiningData()
  }, [])

  const fetchLiningData = async () => {
    if (isOnline) {
      try {
        const response = await axios.get(API.GET_NRM_PRODUCT_RANGE, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        setNRMProductRange(response.data)
      } catch (error) {
        console.error('Error fetching lining:', error)
      }
    } else {
      const transaction = db.transaction(LiningName, 'readonly')
      const objectStore = transaction.objectStore(LiningName)
      const getAllRequest = objectStore.getAll()
      getAllRequest.onsuccess = () => {
        const allData = getAllRequest.result
        console.log('allDataLiningPatchingNo', allData)
        setNRMProductRange(allData)
      }

      getAllRequest.onerror = (event) => {
        setNRMProductRange([])
        console.error(
          'Error getting data from the area table in IndexedDB',
          event
        )
      }
    }
  }

  const handleChange = (name, value) => {
    console.log(value, 'value234')
    const TrialName = localStorage.getItem('Trial')
    const selectedOption = nrmProductRange.find(
      (option) => option.name === value
    )

    if (selectedOption) {
      if (TrialName === 'new') {
        setLiningMaterialProductDetails((prevState) => ({
          ...prevState,
          [name]: selectedOption.name,
          id: uniqueId, // Set the unique ID
        }))
      } else {
        setLiningMaterialProductDetails((prevState) => ({
          ...prevState,
          [name]: selectedOption.name, // Save the name instead of id
        }))
      }
    }
  }

  const handleTextBoxChange = (name, value) => {
    const TrialName = localStorage.getItem('Trial')

    if (TrialName === 'new') {
      setLiningMaterialProductDetails((prevState) => ({
        ...prevState,
        [name]: value,
        id: uniqueId, 
      }))
    } else {
      setLiningMaterialProductDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  return (
    <div>
      <div style={{ flex: 1, marginTop: '15px' }}>
        <CustomAutocomplete
          name="bottom_product"
          labelValue="Bottom Product"
          isLabel={true}
          options={nrmProductRange.map((option) => option.name)}
          value={liningMaterialProductDetails?.bottom_product || ''} // Default empty string
          onChange={(event, value) => handleChange('bottom_product', value)}
        />
      </div>

      <div style={{ flex: 1, marginTop: '15px' }}>
        <CustomAutocomplete
          name="sidewall_product_one"
          labelValue="Sidewall product 1"
          isLabel={true}
          options={nrmProductRange.map((option) => option.name)}
          value={liningMaterialProductDetails.sidewall_product_one || ''} // Assuming role is one of the fields in your form
          onChange={(event, value) =>
            handleChange('sidewall_product_one', value)
          }
          // options={Helper.CrucibleInfo}
          // labelCondition="name" // Assuming you want to display role names
          // error={formErrors.crucible_info_id}
        />
      </div>
      <div style={{ flex: 1, marginTop: '15px' }}>
        <CustomAutocomplete
          name="sidewall_product_two"
          labelValue="Sidewall product 2"
          isLabel={true}
          options={nrmProductRange.map((option) => option.name)}
          value={liningMaterialProductDetails.sidewall_product_two || ''} 
          onChange={(event, value) =>
            handleChange('sidewall_product_two', value)
          }
          // options={Helper.CrucibleInfo}
          // labelCondition="name" // Assuming you want to display role names
          // error={formErrors.crucible_info_id}
        />
      </div>

      <div style={{ flex: 1, marginTop: '15px' }}>
        <CustomAutocomplete
          name="top_locking_product"
          labelValue="Top Locking Product"
          isLabel={true}
          options={nrmProductRange.map((option) => option.name)}
          value={liningMaterialProductDetails.top_locking_product || ''} // Assuming || role is one of the fields in your form
          onChange={(event, value) =>
            handleChange('top_locking_product', value)
          }
          // options={Helper.CrucibleInfo}
          // labelCondition="name" // Assuming you want to display role names
          // error={formErrors.crucible_info_id}
        />
      </div>

      <div style={{ flex: 1, marginTop: '15px' }}>
        <CustomAutocomplete
          name="topping_mass_product"
          labelValue="Topping Mass Product"
          isLabel={true}
          options={nrmProductRange.map((option) => option.name)}
          value={liningMaterialProductDetails.topping_mass_product || ''}
          onChange={(event, value) =>
            handleChange('topping_mass_product', value)
          }
          // options={Helper.CrucibleInfo}
          // labelCondition="name" // Assuming you want to display role names
          // error={formErrors.crucible_info_id}
        />
      </div>

      <div style={{ flex: 1, marginTop: '15px' }}>
        <TextBox
          name="sidewall_one_coverage_upto_x"
          label="Side wall 1 Coverage upto X% (%)"
          type="number"
          value={liningMaterialProductDetails.sidewall_one_coverage_upto_x || ''}
          onChange={(event) =>
            handleTextBoxChange(
              'sidewall_one_coverage_upto_x',
              event.target.value
            )
          }
        />
      </div>
      <div style={{ flex: 1, marginTop: '15px' }}>
        <TextBox
          name="sidewall_two_coverage_upto_100"
          label="Side wall 2 Coverage upto 100% (%)"
          type="number"
          value={
            liningMaterialProductDetails.sidewall_two_coverage_upto_100
          }
          onChange={(event) =>
            handleTextBoxChange(
              'sidewall_two_coverage_upto_100',
              event.target.value
            )
          }
        />
      </div>
    </div>
  )
}

export default LiningMaterialProductDetails
