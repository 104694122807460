import React, { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography'
import TextBox from '../../input/TextBox'
import StyledButton from '../../input/StyledButton'
import axios from 'axios'
import API from '../../services/API'

const ProfileView = () => {
  const [userDet, setUserDet] = useState({})
  const [customers, setCustomers] = useState([])
  const [isLoadingCustomers, setIsLoadingCustomers] = useState(true)
  const access_token = localStorage.getItem('accesstoken')

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await axios.get(
          API.GET_CUSTOMER,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          },
        ) // Adjust the URL as needed
        const customerResponse = response.data
        setCustomers(customerResponse)
        setIsLoadingCustomers(false) // Mark customers as loaded
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchCustomers()
  }, [])

  useEffect(() => {
    if (!isLoadingCustomers) {
      // Only run if customers have been loaded
      let data = localStorage.getItem('user')
      let user = JSON.parse(data)

      if (user && user.assign_customer_id) {
        const customerNames = user.assign_customer_id.map((customerId) => {
          const customer = customers.find((c) => c.id === customerId)
          return customer ? customer.organization_name : 'Unknown'
        })
        user.customers = customerNames
      }

      setUserDet(user)
    }
  }, [isLoadingCustomers, customers])

  const handleClose = () => {
    window.history.back()
  }

  return (
    <div style={{ width: '100%', padding: '20px' }}>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'Inter',
            fontSize: 25,
            fontWeight: 600,
            lineHeight: '24px',
            letterSpacing: '-0.006em',
            textAlign: 'left',
            color: '#252C32',
            marginBottom: '10px',
          }}
        >
          My Profile
        </Typography>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
        <div style={{ marginBottom: '10px' }}>
          <TextBox
            label="Name"
            isString={true}
            value={`${userDet.firstname || ''} ${userDet.lastname || ''}`.trim()}
            fullWidth
          />
        </div>

        <div style={{ marginBottom: '10px' }}>
          <TextBox
            name="description"
            label="Designation"
            isString={true}
            multiline={true}
            value={userDet.designation || ''}
            fullWidth
          />
        </div>

        <div style={{ marginBottom: '10px' }}>
          <TextBox
            name="email"
            label="Email"
            value={userDet.email || ''}
            fullWidth
          />
        </div>

        <div style={{ marginBottom: '10px' }}>
          <TextBox
            name="website"
            label="Contact Number"
            value={userDet.contact_number || ''}
            fullWidth
          />
        </div>

        <div style={{ marginBottom: '10px' }}>
          <TextBox
            name="address"
            label="SGID"
            value={userDet.sgid || ''}
            fullWidth
          />
        </div>

        <div style={{ marginBottom: '10px' }}>
          <TextBox
            name="city"
            label="Role"
            value={userDet.role ? userDet.role.name : ''}
            fullWidth
          />
        </div>

        <div style={{ marginBottom: '10px' }}>
          <TextBox
            name="state"
            label="Customers"
            value={userDet.customers ? userDet.customers.join(', ') : ''}
            fullWidth
          />
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
          <StyledButton
            variant="outlined"
            text="Close"
            onClick={handleClose}
            style={{ marginRight: 10 }}
          />
        </div>
      </div>
    </div>
  )
}

export default ProfileView
