import React, { useState, useEffect } from 'react'
import TextBox from '../../input/TextBox'
import AutoSelectBox from '../../input/AutoSelectBox'
import Typography from '@mui/material/Typography'
import Helper from '../../services/Helper'
import { patching_details, patching_details_nrm } from '../../../recoil/atoms'
import { useRecoilState } from 'recoil'
import { trial_name } from '../../../recoil/atoms'

export default function PatchingDetails() {
  const [patchingDetails, setPatchingDetails] = useRecoilState(patching_details)
  const [patchingDetailsNRM, setPatchingDetailsNRM] =
    useRecoilState(patching_details_nrm)
  const [trialName] = useRecoilState(trial_name)
  const isReadOnly = trialName === 'viewCostSheet'
  const handlePatchingDetailsChange = (name, value) => {
    const regex = /^[0-9]*\.?[0-9]{0,2}$/
    if (regex.test(value) || value === '') {
      setPatchingDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const handlePatchingDetailsNRMChange = (name, value) => {
    const regex = /^[0-9]*\.?[0-9]{0,2}$/
    if (regex.test(value) || value === '') {
      setPatchingDetailsNRM((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  useEffect(() => {
    let patchingDetailsObj = {
      patchingDetails: {
        basic: patchingDetails,
        nrm: patchingDetailsNRM,
      },
    }
    console.log(patchingDetailsObj, '35656123patching')
  }, [patchingDetails, patchingDetailsNRM])

  useEffect(() => {
    localStorage.setItem('patchingDetails', JSON.stringify(patchingDetails))
    localStorage.setItem(
      'patchingDetailsNRM',
      JSON.stringify(patchingDetailsNRM)
    )
  }, [patchingDetails, patchingDetailsNRM])

  const custDet = JSON.parse(localStorage.getItem('customerDetails'))

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body1"
          style={{
            color: '#000000',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '20px',
            marginTop: '0px',
          }}
        >
          {custDet.parameter}
        </Typography>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <AutoSelectBox
          labelValue="Ramming Material used in Patching - 1"
          name="ramming_material_side_lining_1"
          isLabel={true}
          options={Helper.CostSheet}
          value={patchingDetails.ramming_material_side_lining_1}
          onChange={(event) =>
            handlePatchingDetailsChange(
              'ramming_material_side_lining_1',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="qty_patching_1"
          label="Qty. of Material required for Patching - 1"
          type="text"
          value={patchingDetails.qty_patching_1}
          onChange={(event) =>
            handlePatchingDetailsChange('qty_patching_1', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginBottom: '15px' }}>
        <AutoSelectBox
          labelValue="Material used in Spout and Top - 2"
          name="material_used_spout_and_top_2"
          isLabel={true}
          options={Helper.CostSheet}
          value={patchingDetails.material_used_spout_and_top_2}
          onChange={(event) =>
            handlePatchingDetailsChange(
              'material_used_spout_and_top_2',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="qty_spout_and_top_2"
          label="Quality of Material required for Spout & Top - 2"
          type="text"
          value={patchingDetails.qty_spout_and_top_2}
          onChange={(event) =>
            handlePatchingDetailsChange(
              'qty_spout_and_top_2',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginBottom: '15px' }}>
        <AutoSelectBox
          labelValue="Material used in spout and top - 3"
          name="material_used_top_spout_and_top_3"
          isLabel={true}
          options={Helper.CostSheet}
          value={patchingDetails.material_used_top_spout_and_top_3}
          onChange={(event) =>
            handlePatchingDetailsChange(
              'material_used_top_spout_and_top_3',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="material_used_top_spout_and_top_3"
          label="Qty. of Material required for Spout & Top - 3"
          type="text"
          value={patchingDetails.qty_spout_and_top_3}
          onChange={(event) =>
            handlePatchingDetailsChange(
              'qty_spout_and_top_3',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="average_patching_lining_life"
          label="Average Patching Lining Life"
          type="text"
          value={patchingDetails.average_patching_lining_life}
          onChange={(event) =>
            handlePatchingDetailsChange(
              'average_patching_lining_life',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="no_of_patching"
          label="No of Patching"
          type="text"
          value={patchingDetails.no_of_patching}
          onChange={(event) =>
            handlePatchingDetailsChange('no_of_patching', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="avg_heat_size_per_patching"
          label="Average Heat Size Per Patching"
          type="text"
          value={patchingDetails.avg_heat_size_per_patching}
          onChange={(event) =>
            handlePatchingDetailsChange(
              'avg_heat_size_per_patching',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body1"
          style={{
            color: '#000000',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '20px',
            marginTop: '0px',
          }}
        >
          NRM
        </Typography>
      </div>

      <div style={{ marginBottom: '15px' }}>
        <AutoSelectBox
          labelValue="Ramming Material used in Patching - 1"
          name="ramming_material_side_lining_1"
          isLabel={true}
          options={Helper.CostSheet}
          value={patchingDetailsNRM.ramming_material_side_lining_1}
          onChange={(event) =>
            handlePatchingDetailsNRMChange(
              'ramming_material_side_lining_1',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="qty_patching_1"
          label="Qty. of Material required for Patching - 1"
          type="text"
          value={patchingDetailsNRM.qty_patching_1}
          onChange={(event) =>
            handlePatchingDetailsNRMChange('qty_patching_1', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginBottom: '15px' }}>
        <AutoSelectBox
          labelValue="Material used in Spout and Top - 2"
          name="material_used_spout_and_top_2"
          isLabel={true}
          options={Helper.CostSheet}
          value={patchingDetailsNRM.material_used_spout_and_top_2}
          onChange={(event) =>
            handlePatchingDetailsNRMChange(
              'material_used_spout_and_top_2',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="qty_spout_and_top_2"
          label="Qty. of Material required for Spout & Top -2"
          type="text"
          value={patchingDetailsNRM.qty_spout_and_top_2}
          onChange={(event) =>
            handlePatchingDetailsNRMChange(
              'qty_spout_and_top_2',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginBottom: '15px' }}>
        <AutoSelectBox
          labelValue="Material used in spout and top - 3"
          name="material_used_top_spout_and_top_3"
          isLabel={true}
          options={Helper.CostSheet}
          value={patchingDetailsNRM.material_used_top_spout_and_top_3}
          onChange={(event) =>
            handlePatchingDetailsNRMChange(
              'material_used_top_spout_and_top_3',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="qty_spout_and_top_3"
          label="Qty. of Material required for Spout & Top - 3"
          type="text"
          value={patchingDetailsNRM.qty_spout_and_top_3}
          onChange={(event) =>
            handlePatchingDetailsNRMChange(
              'qty_spout_and_top_3',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="average_patching_lining_life"
          label="Average Patching Lining Life"
          type="text"
          value={patchingDetailsNRM.average_patching_lining_life}
          onChange={(event) =>
            handlePatchingDetailsNRMChange(
              'average_patching_lining_life',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="no_of_patching"
          label="No of Patching"
          type="text"
          value={patchingDetailsNRM.no_of_patching}
          onChange={(event) =>
            handlePatchingDetailsNRMChange('no_of_patching', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="avg_heat_size_per_patching"
          label="Average Heat Size Per Side Patching"
          type="text"
          value={patchingDetailsNRM.avg_heat_size_per_patching}
          onChange={(event) =>
            handlePatchingDetailsNRMChange(
              'avg_heat_size_per_patching',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>
    </div>
  )
}
