import React, { useState, useEffect } from 'react'
import TextBox from '../../input/TextBox'
import SelectBox from '../../input/SelectBox'
import { sintering_parameters, lining_reference } from '../../../recoil/atoms'
import { useRecoilState } from 'recoil'
import CustomAutocomplete from '../../input/CustomAutocomplete'
import axios from 'axios'
import API from '../../services/API'
import HourMinInput from '../../input/TimePicker'

const SinteringParameters = () => {
  const [sinteringParameters, setSinteringParameters] =
    useRecoilState(sintering_parameters)
  const [reason, setReason] = useState([])
  console.log(reason, 'asjdag')
  const [liningReference] = useRecoilState(lining_reference)
  const uniqueId = liningReference.id ? liningReference.id : ''
  const [isOnline, setIsOnline] = useState(window.navigator.onLine)
  const access_token = localStorage.getItem('accesstoken')
  const [db, setDb] = useState(null)

  useEffect(() => {
    const handleOnline = () => {
      if (!isOnline) {
        setIsOnline(true)
        localStorage.setItem('onlinestate', 1)
        window.location.reload()
      }
    }

    const handleOffline = () => {
      localStorage.setItem('onlinestate', 0)
      // setIsOnlines(false)
      setIsOnline(false)
      // navigate('/reports')
    }

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [isOnline])

  const dbName = 'pcr'
  const customerName = 'customers'
  const furnacesName = 'furnaces'
  const templateName = 'template'
  const segmentName = 'segment'
  const datafileName = 'datafile'
  const dailytrialName = 'daily_trial'
  const nrmName = 'nrm_performance'
  const erosionName = 'erosion_profile'
  const dataCollectionName = 'data_collection'
  const costSheetName = 'cost_sheet'
  const LiningName = 'lining_patching_no'

  useEffect(() => {
    if (!isOnline) {
      const request = indexedDB.open(dbName, 1)
      request.onupgradeneeded = (event) => {
        const database = event.target.result
        database.createObjectStore(customerName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(furnacesName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(templateName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(segmentName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(datafileName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(dailytrialName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(nrmName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(erosionName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(dataCollectionName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(costSheetName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(LiningName, {
          keyPath: 'id',
          autoIncrement: true,
        })
      }

      request.onsuccess = (event) => {
        const database = event.target.result
        const transaction = database.transaction(
          [
            'customers',
            'furnaces',
            'template',
            'segment',
            'datafile',
            'daily_trial',
            'nrm_performance',
            'erosion_profile',
            'data_collection',
            'cost_sheet',
            'lining_patching_no',
          ],
          'readwrite'
        )

        setDb(database)
      }

      request.onerror = (event) => {
        console.error('Error opening IndexedDB', event)
      }
    }
  }, [isOnline])

  const handleChange = (e) => {
    const { name, value } = e.target
    const TrialName = localStorage.getItem('Trial')
    // Check if TrialName is "new"
    if (TrialName === 'new') {
      setSinteringParameters((prevState) => ({
        ...prevState,
        [name]: value,
        id: uniqueId,
      }))
    } else {
      // If TrialName is not "new", no need to generate unique ID
      setSinteringParameters((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  useEffect(() => {
    fetchReasonData()
  }, [])

  const fetchReasonData = async () => {
    try {
      const response = await axios.get(API.GET_REASON, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      setReason(response.data)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const changeHeatTimes = (name, newValue) => {
    if (newValue && newValue.isValid()) {
      const hours = newValue.hour().toString().padStart(2, '0')
      const minutes = newValue.minute().toString().padStart(2, '0')

      const formattedTime = `${hours}:${minutes}`

      console.log(`Extracted ${name.replace('_', ' ')}:`, formattedTime)
      setSinteringParameters((prevDetails) => {
        const updatedDetails = {
          ...prevDetails,
          [name]: formattedTime,
        }
        //   if (updatedDetails.heat_on_time && updatedDetails.heat_tap_time) {
        //     const duration = calculateDuration(updatedDetails.heat_on_time, updatedDetails.heat_tap_time);
        //     updatedDetails.heat_duration_calculated = duration;

        //     console.log('Calculated Duration:', duration);
        //   }

        return updatedDetails
      })
    } else {
      console.error('Invalid date value:', newValue)
    }
  }

  const handleAutocompleteChange = (name, value) => {
    setSinteringParameters((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  useEffect(() => {
    console.log(sinteringParameters, 'consolehigh34')
  }, [])

  return (
    <div>
      <div style={{ flex: 1, marginRight: '15px', marginTop: '15px' }}>
        <HourMinInput
          name="total_sintering_time"
          label="Total Sintering time (HH:mm)"
          value={sinteringParameters.total_sintering_time}
          onChange={(newValue) =>
            changeHeatTimes('total_sintering_time', newValue)
          }
        />
      </div>
      <div style={{ flex: 1, marginRight: '15px', marginTop: '15px' }}>
        <HourMinInput
          name="holding_time"
          label="Holding time (HH:mm)"
          value={sinteringParameters.holding_time}
          onChange={(newValue) => changeHeatTimes('holding_time', newValue)}
        />
      </div>
      <div style={{ flex: 1, marginRight: '15px', marginTop: '15px' }}>
        <TextBox
          name="holding_temp"
          label="Holding temp. (°C)"
          type="text"
          value={sinteringParameters.holding_temp}
          onChange={handleChange}
          //   error={formErrors.coil_grout}
        />
      </div>
      <div style={{ flex: 1 }}>
        <CustomAutocomplete
          name="reason_for_down"
          labelValue="Reason For Down"
          isLabel={true}
          options={reason.map((option) => option.name)}
          value={sinteringParameters.reason_for_down}
          onChange={(event, value) =>
            handleAutocompleteChange('reason_for_down', value)
          }
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="IR_no"
          label="IR no."
          type="text"
          value={sinteringParameters.IR_no}
          onChange={handleChange}
          //   error={formErrors.coil_grout}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="job_done_by"
          label="Job Done by"
          value={sinteringParameters.job_done_by}
          onChange={handleChange}
        />
      </div>
      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="remarks"
          label="Remarks"
          value={sinteringParameters.remarks}
          onChange={handleChange}
        />
      </div>
    </div>
  )
}

export default SinteringParameters
