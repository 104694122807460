import React, { useEffect } from 'react'
import TextBox from '../../input/TextBox'
import AutoSelectBox from '../../input/AutoSelectBox'
import Typography from '@mui/material/Typography'
import Helper from '../../services/Helper'
import { useRecoilState } from 'recoil'
import { savings_energy, savings_energy_nrm } from '../../../recoil/atoms'
import StyledButton from '../../input/StyledButton'
import { trial_name } from '../../../recoil/atoms'
import { useRecoilValue } from 'recoil'
import { costSavingsDueToPowerSelector } from '../../../recoil/selector'

export default function Energy(props) {
  const [energy, setEnergy] = useRecoilState(savings_energy)
  const [energyNRM, setEnergyNRM] = useRecoilState(savings_energy_nrm)
  const [trialName] = useRecoilState(trial_name)
  const isReadOnly = trialName === 'viewCostSheet'

  const costSavingsDutToPower = useRecoilValue(costSavingsDueToPowerSelector)
  console.log('Cost Savings Due to Power:', costSavingsDutToPower)

  const handleEnergy = (name, value) => {
    setEnergy((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleEnergyNRM = (name, value) => {
    setEnergyNRM((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  //calculation
  // const costSavingsDutToPower = ((energyNRM.cost_per_electricity_unit) * (energyNRM.power_savings_per_mt)).toFixed(2)
  // console.log(costSavingsDutToPower, "costSavingsDutToPower")

  // const energyObj = {
  //   cost_per_electricity_unit: 'NA',
  //   power_savings_per_mt: 'NA',
  //   savings_power_consumption: 'NA',

  // }

  // const energyObjNRM = {
  //   cost_per_electricity_unit: energyNRM.cost_per_electricity_unit,
  //   power_savings_per_mt: energyNRM.power_savings_per_mt,
  //   savings_power_consumption: costSavingsDutToPower,

  // }

  useEffect(() => {
    if (
      props.trialName === 'costSheetEdit' &&
      props.editSavingsDetails.length > 0
    ) {
      console.log(props.editSavingsDetails, 'props.editSavingsDetails')
      let editObj = doesKeyExist(props.editSavingsDetails, 'energyDataObject')
      console.log(editObj, 'editObj')
      if (editObj) {
        setEnergy(editObj.energyDataObject.energy.basic)
        setEnergyNRM(editObj.energyDataObject.energy.basic)
      }
    }
  }, [props.editSavingsDetails])

  function doesKeyExist(array, key) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].hasOwnProperty(key)) {
        return array[i]
      }
    }
    return false
  }

  // const handlenextTriger =()=>{
  //   props.handleNext()
  //   props.handleEnergyObject(energyObj, energyObjNRM)
  // }
  const custDet = JSON.parse(localStorage.getItem('customerDetails'))

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body1"
          style={{
            color: '#000000',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '20px',
            marginTop: '0px',
          }}
        >
          {custDet.parameter}
        </Typography>
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="mobile_number"
          label="Cost per electricity unit"
          type="number"
          value="NA"
          onChange={(event) =>
            handleEnergy('cost_per_electricity_unit', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="mobile_number"
          label="Power Savings per MT "
          type="number"
          value="NA"
          onChange={(event) =>
            handleEnergy('power_savings_per_mt', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Cost Savings due to Power consumption in KWh / MT of steel "
          type="number"
          value="NA"
          onChange={(event) =>
            handleEnergy('savings_power_consumption', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body1"
          style={{
            color: '#000000',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '20px',
            marginTop: '0px',
          }}
        >
          NRM
        </Typography>
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="mobile_number"
          label="Cost per electricity unit"
          type="number"
          value={energyNRM.cost_per_electricity_unit}
          onChange={(event) =>
            handleEnergyNRM('cost_per_electricity_unit', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="mobile_number"
          label="Power Savings per MT "
          type="number"
          value={energyNRM.power_savings_per_mt}
          onChange={(event) =>
            handleEnergyNRM('power_savings_per_mt', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Cost Savings due to Power consumption in KWh / MT of steel "
          type="number"
          value={costSavingsDutToPower}
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>

      {/* <div style={{ justifyContent: 'space-between', flexDirection: "row", display: "flex", marginTop: "auto", marginBottom: "10px", position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1000 }}>
        <StyledButton
          variant="outlined"
          text="Cancel"
          onClick={props.handleBack}
          disabled={props.activeStep === 0}
          style={{ marginRight: 10 }}
        />
        <StyledButton
          variant="contained"
          color="primary"
          text={
            (props.trialName === 'edit' && props.selectedTab === 2) ? 'Update' :
            (props.activeStep === (props.selectedTab === 0 ? props.CostSheetTabs.length - 1 : props.savingsDetails.length - 1)) ? 'Finish' : 
            'Next'
          }
        onClick={handlenextTriger}
        />
      </div> */}
    </div>
  )
}
