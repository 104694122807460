import React, { useState, useEffect } from 'react'
import { Button, Modal, Box, Typography } from '@mui/material'
import { styled } from '@mui/system'
import IconButton from '@mui/material/IconButton'
import TextBox from '../input/TextBox'
import StyledButton from '../input/StyledButton'
import API from '../services/API'
import axios from 'axios'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material'
import EditIcon from '../../assets/icons/ic_edit.svg'
import DeleteIcon from '../../assets/icons/ic_delete.svg'
import SearchIcon from '../../assets/icons/ic_search.svg'
// import FilterIcon from "../../assets/icons/ic_filter.svg";
import DownloadIcon from '../../assets/icons/ic_dowload_settings.svg'
import closeIcon from '../../assets/icons/ic_close.svg'
import CircularProgress from '@mui/material/CircularProgress'
import AlertDialog from '../input/AlertDialog'
const ExcelJS = require('exceljs')

const ModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: 800,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[24],
  padding: theme.spacing(4),

  '& .cell': {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.006em',
    textAlign: 'left',
    color: '#7F7D83',
  },
  '& .row': {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.006em',
    textAlign: 'left',
    color: '#252C32',
  },
  '& .firstRow': {
    fontFamily: 'Inter',
    fontSize: '14px',
    width: '30px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.006em',
    textAlign: 'left',
    color: '#252C32',
  },
}))

const firstRowStyles = {
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 800,
  lineHeight: '24px',
  letterSpacing: '-0.006em',
  textAlign: 'left',
  color: '#252C32',
}

const Customers = () => {
  const [open, setOpen] = useState(false)
  const [formData, setFormData] = useState({
    organization_name: '',
    description: '',
    email: '',
    website: '',
    address: '',
    city: '',
    state: '',
    pincode: '',
  })
  const [customers, setCustomers] = useState([])
  const [selectedCustomerIndex, setSelectedCustomerIndex] = useState(null)
  const [isEditMode, setIsEditMode] = useState(false) // New state for edit mode
  const [isInputVisible, setInputVisible] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredCustomers, setFilteredCustomers] = useState([])
  const [formErrors, setFormErrors] = useState({}) // Initialize formErrors state
  const [loading, setLoading] = useState(false) // Loader state
  const [error, setError] = useState(null) // Error state
  const currentUser = JSON.parse(localStorage.getItem('user'))
  const access_token = localStorage.getItem('accesstoken')

  // Function to fetch data from the API
  const fetchData = async () => {
    setError(null)
    try {
      setLoading(true)
      const response = await axios.get(API.GET_CUSTOMER, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      console.log('responseeeecustomers', response.data)
      setCustomers(response.data)
    } catch (error) {
      setError('Error fetching data')
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false) // Close the loader regardless of success or failure
    }
  }

  useEffect(() => {
    fetchData()
  }, []) 

  const handleSearchIconClick = () => {
    setInputVisible(true)
  }
  // const handleInputChange = (event) => {
  //   const query = event.target.value.toLowerCase(); // Convert query to lowercase
  //   console.log("queryyy", query);
  //   setSearchQuery(query);
  //   const filtered = customers.filter((customer) =>
  //     customer.organization_name.toLowerCase().includes(query) ||
  //     customer.email.toLowerCase().includes(query) ||
  //     customer.city.toLowerCase().includes(query)
  //   );
  //   setFilteredCustomers(filtered);
  // };

  const handleInputChange = (event) => {
    const query = event.target.value.toLowerCase() // Convert query to lowercase
    setSearchQuery(query)
    let filtered
    if (query.trim() === '') {
      filtered = customers // Reset to original list if query is empty
    } else {
      filtered = customers.filter((customer) => {
        const orgNameIncludesQuery = customer.organization_name
          .toLowerCase()
          .includes(query)
        const emailIncludesQuery = customer.email.toLowerCase().includes(query)
        const cityIncludesQuery = customer.city.toLowerCase().includes(query)
        const trigramIncludesQuery = getTrigram(customer.organization_name)
          .toLowerCase()
          .includes(query)
        return (
          orgNameIncludesQuery ||
          emailIncludesQuery ||
          cityIncludesQuery ||
          trigramIncludesQuery
        )
      })
    }
    setFilteredCustomers(filtered)
  }

  const handleCloseIconClick = () => {
    setInputVisible(false)
    setSearchQuery('')
    setFilteredCustomers([])
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }))
  }

  const handleOpenModal = () => {
    setOpen(true)
    setIsEditMode(false)
    setFormData({
      organization_name: '',
      description: '',
      email: '',
      website: '',
      address: '',
      city: '',
      state: '',
      pincode: '',
    })
  }

  const handleCloseModal = () => {
    setOpen(false)
  }
  const handleSave = async () => {
    try {
      const isValid = await validateFormData()
      if (!isValid) return

      setLoading(true) // Start loader

      const jsonData = formData
      console.log('formDataaaaa', formData)
      let response
      let successMessage

      if (isEditMode && selectedCustomerIndex !== null) {
        const customerId = customers[selectedCustomerIndex].id
        response = await axios.put(
          `${API.UPDATE_CUSTOMER}/${customerId}`,
          jsonData,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        successMessage = 'Customer Updated Successfully'
      } else {
        response = await axios.post(API.INSERT_CUSTOMER, jsonData, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        successMessage = 'Customer Inserted Successfully'
      }

      if (response.status === 200) {
        AlertDialog({
          type: 'success',
          title: 'Success',
          text: successMessage,
          confirmButtonText: 'Ok',
          onConfirm: async () => {
            await fetchData()
            await fetchUsers()
          },
        })
      } else {
        console.error('Error saving data:', response.data)
        AlertDialog({
          type: 'warning',
          title: 'Error',
          text: 'Failed to save data',
        })
      }

      setOpen(false) // Close the modal after saving
    } catch (error) {
      console.error('Error saving data:', error)
      AlertDialog({
        type: 'warning',
        title: 'Error',
        text: error.message || 'An error occurred',
      })
    } finally {
      setLoading(false) // Stop loader regardless of success or failure
    }
  }

  // const handleSave = async () => {
  //   try {
  //     const isValid = validateFormData();
  //     if (!isValid) return;

  //     setLoading(true); // Start loader

  //     const jsonData = formData;
  //     console.log("formDataaaaa",formData);

  //       // Check for duplicate organization name
  //   const existingRecords = await axios.get(API.GET_CUSTOMER);
  //   const isDuplicate = existingRecords.data.some(
  //     record =>
  //       record.organization_name.toLowerCase() === jsonData.organization_name.toLowerCase() &&
  //       record.id !== (isEditMode && selectedCustomerIndex !== null ? customers[selectedCustomerIndex].id : null)
  //   );

  //   console.log("existingRecordsexistingRecords",existingRecords);
  //   console.log("isDuplicateisDuplicate",isDuplicate);

  //   if (isDuplicate) {
  //     AlertDialog({
  //       type: 'warning',
  //       title: 'Error',
  //       text: 'An error occurred',
  //     });
  //         }
  //     let response;
  //     let successMessage;

  //     if (isEditMode && selectedCustomerIndex !== null) {
  //       const customerId = customers[selectedCustomerIndex].id;
  //       response = await axios.put(`${API.UPDATE_CUSTOMER}/${customerId}`, jsonData);
  //       successMessage = 'Customer Updated Successfully';
  //     } else {
  //       response = await axios.post(API.INSERT_CUSTOMER, jsonData);
  //       successMessage = 'Customer Inserted Successfully';
  //     }

  //     if (response.status === 200) {
  //       AlertDialog({
  //         type: 'success',
  //         title: 'Success',
  //         text: successMessage,
  //         confirmButtonText: 'Ok',
  //         onConfirm: async () => {
  //           await fetchData();
  //           await fetchUsers();
  //         },
  //       });
  //     } else {
  //       console.error('Error saving data:', response.data);
  //       AlertDialog({
  //         type: 'warning',
  //         title: 'Error',
  //         text: 'Failed to save data',
  //       });
  //     }

  //     setOpen(false); // Close the modal after saving
  //   } catch (error) {
  //     console.error('Error saving data:', error);
  //     AlertDialog({
  //       type: 'warning',
  //       title: 'Error',
  //       text: error.message || 'An error occurred',
  //     });
  //   } finally {
  //     setLoading(false); // Stop loader regardless of success or failure
  //   }
  // };

  const fetchUsers = async () => {
    setError(null)
    try {
      setLoading(true)

      const [usersResponse, customersResponse] = await Promise.all([
        axios.get(API.GET_USERS, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }),
        axios.get(API.GET_CUSTOMER, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }),
      ])

      console.log('userresponse', usersResponse.data)
      console.log('customers response', customersResponse.data)

      const users = usersResponse.data
      const customerIds = customersResponse.data.map((customer) => customer.id)

      // Filter users by role_id 3 or 4 and create an update payload
      const updatePayloads = users
        .filter((user) => user.role.role_id === 3 || user.role.role_id === 4)
        .map((user) => ({
          id: user.id,
          assign_customer_id: customerIds,
        }))

      console.log('updatePayloads', updatePayloads)

      // Update each user with role_id 3 or 4
      for (const payload of updatePayloads) {
        await axios.put(`${API.UPDATE_ALL_USERS}/${payload.id}`, {
          assign_customer_id: payload.assign_customer_id,
        })
      }

      console.log('Update API response:', 'All relevant users updated')
    } catch (error) {
      // setError("Error fetching data");
      console.error('Error fetching data:', error.message)
    } finally {
      setLoading(false) // Close the loader regardless of success or failure
    }
  }

  // const fetchUsers = async () => {
  //   setError(null);
  //   try {
  //     setLoading(true);
  //     const response = await axios.get(API.GET_USERS);
  //     console.log("userresponse", response.data)
  //     console.log("customers response", customers)

  //     const usersRespponse = response.data;

  //     const customerIds = customers.map(customer => customer.id);
  //             // Process users and update assign_customer_id based on role_id
  //         const updatedUsers = usersRespponse.map(user => {
  //           // Check if the role_id is 3 or 4
  //           if (user.role.role_id === 3 || user.role.role_id === 4) {
  //             // Update assign_customer_id to include the customer IDs
  //             return {
  //               ...user,
  //               assign_customer_id: customerIds
  //             };
  //           }
  //           return user;
  //         });

  //         console.log("updatedUsers",updatedUsers);

  //       // Call the API to update users
  //       const updateResponse = await axios.put(API.UPDATE_ALL_USERS, {
  //         assign_customer_id: customerIds // Pass assign_customer_id to the API
  //       });

  //       // Log response from update API
  //       console.log("Update API response:", updateResponse);

  //     // setUsers(response.data);
  //   } catch (error) {
  //     setError("Error fetching data");
  //     console.error('Error fetching data:', error);
  //   } finally {
  //     setLoading(false); // Close the loader regardless of success or failure
  //   }
  // };

  // const validateFormData = async() => {
  //   // Implement your validation logic here
  //   const errors = {};

  //   const existingOrganizations = await fetchData();

  //   // Check if organization name is provided
  //   if (!formData.organization_name) {
  //     errors.organization_name = 'Organization name is required';
  //   }else{
  //      // Check for duplicate organization name
  //   const isDuplicate = existingOrganizations.some(
  //     org => org.organization_name.toLowerCase() === formData.organization_name.toLowerCase()
  //   );
  //   console.log("isDuplicateisDuplicate",isDuplicate)
  //   if (isDuplicate) {
  //     errors.organization_name = 'Organization name already exists';
  //   }
  //   }

  //   // Check if email is provided and valid
  //   if (!formData.email) {
  //     errors.email = 'Email address is required';
  //   } else if (!isValidEmail(formData.email)) {
  //     errors.email = 'Invalid email address';
  //   }

  //   // Check if city is provided
  //   if (!formData.city) {
  //     errors.city = 'City is required';
  //   }
  //   // Set the form errors state
  //   setFormErrors(errors);

  //   // Return true if there are no errors, otherwise return false
  //   return Object.keys(errors).length === 0;
  // };

  const validateFormData = async () => {
    const errors = {}

    try {
      console.log('customeresss', customers)

      // Check if organization name is provided
      if (!formData.organization_name) {
        errors.organization_name = 'Organization name is required'
      } else {
        // Check for duplicate organization name
        const isDuplicate = customers.some(
          (org) =>
            org.organization_name.toLowerCase() ===
            formData.organization_name.toLowerCase()
        )

        console.log('isDuplicateisDuplicateisDuplicate', isDuplicate)
        if (isDuplicate) {
          errors.organization_name = 'Organization name already exists'
        }
      }

      // else {
      //   // Check for duplicate organization name
      //   const isDuplicate = customers.some(
      //     org => org.organization_name.toLowerCase() === formData.organization_name.toLowerCase()
      //   );

      //   console.log("isDuplicateisDuplicateisDuplicate",isDuplicate);
      //   if (isDuplicate) {
      //     errors.organization_name = 'Organization name already exists';
      //   }
      // }

      // Check if email is provided and valid
      if (!formData.email) {
        errors.email = 'Email address is required'
      } else if (!isValidEmail(formData.email)) {
        errors.email = 'Invalid email address'
      }

      // Check if city is provided
      if (!formData.city) {
        errors.city = 'City is required'
      }

      // Set the form errors state
      setFormErrors(errors)

      // Return true if there are no errors, otherwise return false
      return Object.keys(errors).length === 0
    } catch (error) {
      console.error('Error fetching data for validation:', error)
      return false // Consider validation to fail if fetch fails
    }
  }
  const isValidEmail = (email) => {
    const optimizedEmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return optimizedEmailRegex.test(email);
  };
  
  const handleEdit = async (index) => {
    setSelectedCustomerIndex(index)
    setIsEditMode(true)

    const sourceArray =
      searchQuery.trim() === '' ? customers : filteredCustomers

    const selectedCustomer = sourceArray[index]
    setFormData({
      organization_name: selectedCustomer.organization_name,
      description: selectedCustomer.description,
      email: selectedCustomer.email,
      website: selectedCustomer.website,
      address: selectedCustomer.address,
      city: selectedCustomer.city,
      state: selectedCustomer.state,
      pincode: selectedCustomer.pincode,
    })

    setOpen(true)
  }

  const handleDelete = async (index) => {
    setSelectedCustomerIndex(index)
    const selectedCustomer = customers[index]
    const id = selectedCustomer.id
    AlertDialog({
      type: 'delete',
      title: 'Delete?',
      text: 'Are you sure you want to delete?',
      confirmButtonText: 'Yes',
      onConfirm: async () => {
        const response = await axios.delete(`${API.DELETE_CUSTOMER}/${id}`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        if (response.status === 200) {
          AlertDialog({
            type: 'success',
            title: 'Success',
            text: 'Deleted Successfully',
            confirmButtonText: 'Ok',
            onConfirm: async () => {
              await fetchData()
            },
          })
        } else {
          console.error('Error saving data:', response.data)
          AlertDialog({
            type: 'warning',
            title: 'Error',
            text: 'Failed to save data',
          })
        }
      },
    })
  }

  const handleExcelDownload = async () => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Customers')
    // Define column headers
    worksheet.columns = [
      { header: 'No', key: 'no', width: 10 },
      { header: 'Name', key: 'organization_name', width: 20 },
      { header: 'Trigram', key: 'trigram', width: 20 },
      { header: 'Email', key: 'email', width: 30 },
      { header: 'City', key: 'city', width: 20 },
    ]
    const dataToDownload = searchQuery ? filteredCustomers : customers
    dataToDownload.forEach((row, index) => {
      const trigram = getTrigram(row.organization_name)
      worksheet.addRow({ ...row, no: index + 1, trigram: trigram })
    })

    // Generate the Excel file as a Blob
    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob)

    // Create a temporary <a> element to trigger the download
    const link = document.createElement('a')
    link.href = url
    link.download = 'customers.xlsx'

    // Programmatically click the link to trigger the download
    document.body.appendChild(link)
    link.click()

    // Cleanup
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }

  // const handleEmail = async() => {

  //   try {

  //     const emailData ={
  //       to: 'roopavathi.n@saint-gobain.com',
  //       subject:'MOC test',
  //       message:"HI",
  // };

  //     const response = await fetch('http://localhost:3045/send_mail', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify(emailData)
  //     });

  //     if (response.ok) {
  //       console.log('Email sent successfully');
  //       // Handle success, e.g., show a success message to the user
  //     } else {
  //       console.error('Failed to send email');
  //       // Handle error, e.g., show an error message to the user
  //     }
  //   } catch (error) {
  //     console.error('Error:', error);
  //     // Handle network error, e.g., show an error message to the user
  //   }

  // }

  const getTrigram = (name) => {
    // Split the organization name by spaces
    const words = name.trim().split(' ')

    // Initialize trigram
    let trigram = ''

    // Check the number of words
    if (words.length === 1) {
      // If it's one word, take the first three characters
      trigram = words[0].slice(0, 3).toUpperCase()
    } else if (words.length === 2) {
      // If it's two words, take the first two characters of each word
      trigram =
        words[0].slice(0, 2).toUpperCase() + words[1].charAt(0).toUpperCase()
    } else if (words.length >= 3) {
      // If it's three or more words, take the first character of the first three words
      trigram =
        words[0].charAt(0).toUpperCase() +
        words[1].charAt(0).toUpperCase() +
        words[2].charAt(0).toUpperCase()
    }

    return trigram
  }

  return (
    <div>
      {error && <div>{error}</div>}
      {loading ? (
        <CircularProgress />
      ) : (
        <div>
          {/* <StyledButton
              variant="outlined"
              text="send Mail"
              onClick={handleEmail} // Define handleBackButtonClick function
            /> */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: '1px solid #E5E9EB',
              paddingBottom: '8px',
              marginBottom: '8px',
            }}
          >
            <Typography
              variant="h5"
              component="div"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                letterSpacing: '-0.006em',
                textAlign: 'left',
                color: '#252C32',
              }}
            >
              Customer Details
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={SearchIcon}
                alt="search icon"
                style={{ marginRight: '15px', cursor: 'pointer' }}
                onClick={handleSearchIconClick}
              />
              {isInputVisible && (
                <div style={{ position: 'relative' }}>
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={handleInputChange}
                    placeholder="Search..."
                    style={{ marginRight: '15px', height: '35px' }}
                  />
                  <img
                    src={closeIcon}
                    alt="close icon"
                    style={{
                      position: 'absolute',
                      top: '50%',
                      right: '20px',
                      transform: 'translateY(-50%)',
                      cursor: 'pointer',
                    }}
                    onClick={handleCloseIconClick}
                  />
                </div>
              )}
              {/* <img src={FilterIcon} style={{ marginRight: '15px' }} /> */}
              <img
                src={DownloadIcon}
                style={{ marginRight: '20px' }}
                onClick={handleExcelDownload}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{
                  textTransform: 'none', // Remove all-caps transformation
                }}
                onClick={handleOpenModal}
              >
                Add Customer
              </Button>
            </div>
          </div>
          <div>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ ...firstRowStyles, width: '30px' }}>
                      S.No
                    </TableCell>
                    <TableCell style={firstRowStyles}>Name</TableCell>
                    <TableCell style={firstRowStyles}>Trigram</TableCell>
                    <TableCell style={firstRowStyles}>Email</TableCell>
                    <TableCell style={firstRowStyles}>City</TableCell>
                    <TableCell style={firstRowStyles}>Action</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {(searchQuery ? filteredCustomers : customers).map(
                    (row, index) => (
                      <TableRow key={row.id}>
                        <TableCell
                          style={{
                            borderLeft: '1px solid #e0e0e0',
                            borderRight: '1px solid #e0e0e0',
                            width: '20px',
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell>{row.organization_name}</TableCell>
                        <TableCell>
                          {getTrigram(row.organization_name)}
                        </TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>{row.city}</TableCell>
                        <TableCell>
                          <IconButton
                            aria-label="edit"
                            onClick={() => handleEdit(index)}
                          >
                            <img src={EditIcon} alt="Edit Icon" />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            onClick={() => handleDelete(index)}
                          >
                            <img src={DeleteIcon} alt="Delete Icon" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <CustomerModal
            open={open}
            handleClose={handleCloseModal}
            formData={formData}
            handleChange={handleChange}
            handleSave={handleSave}
            isEditMode={isEditMode}
            formErrors={formErrors}
          />
        </div>
      )}
    </div>
  )
}

const CustomerModal = ({
  open,
  handleClose,
  formData,
  handleChange,
  handleSave,
  isEditMode,
  formErrors,
}) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalContent style={{ maxHeight: '80vh', overflowY: 'auto' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: 18,
                fontWeight: 600,
                lineHeight: '24px',
                letterSpacing: '-0.006em',
                textAlign: 'left',
                color: '#252C32',
              }}
            >
              Add Customer
            </Typography>
          </div>

          <div style={{ marginTop: '5px' }}>
            <TextBox
              name="organization_name"
              label="Organization Name*"
              isString={true}
              value={formData.organization_name}
              onChange={handleChange}
              error={formErrors.organization_name}
              helperText={formErrors.organization_name}
            />
          </div>

          <div style={{ marginTop: '5px' }}>
            <TextBox
              name="description"
              label="Description"
              isString={true}
              multiline={true}
              value={formData.description}
              onChange={handleChange}
              // onChange={firstNameOnChnage}
              // error={formErrors.firstName}
            />
          </div>
          <div style={{ marginTop: '5px' }}>
            <TextBox
              name="email"
              label="Email Address*"
              value={formData.email}
              onChange={handleChange}
              isString={true}
              error={formErrors.email}
            />
          </div>
          <div style={{ marginTop: '5px' }}>
            <TextBox
              name="website"
              label="Website"
              value={formData.website}
              onChange={handleChange}
              isString={true}
              // onChange={firstNameOnChnage}
              // error={formErrors.firstName}
            />
          </div>
          <div style={{ marginTop: '5px' }}>
            <TextBox
              name="address"
              label="Address"
              value={formData.address}
              onChange={handleChange}
              isString={true}
              // onChange={firstNameOnChnage}
              // error={formErrors.firstName}
            />
          </div>
          <div style={{ marginTop: '5px' }}>
            <TextBox
              name="city"
              label="City*"
              value={formData.city}
              onChange={handleChange}
              isString={true}
              error={formErrors.city}
            />
          </div>
          <div style={{ marginTop: '5px' }}>
            <TextBox
              name="state"
              label="State"
              value={formData.state}
              onChange={handleChange}
              isString={true}
              // onChange={firstNameOnChnage}
              // error={formErrors.firstName}
            />
          </div>
          <div style={{ marginTop: '5px' }}>
            <TextBox
              name="pincode"
              label="PinCode"
              value={formData.pincode}
              onChange={handleChange}
              // error={formErrors.firstName}
            />
          </div>
          <div style={{ flexDirection: 'row', flex: 1, display: 'flex' }}>
            <StyledButton
              variant="outlined"
              text="Cancel"
              onClick={handleClose} // Define handleBackButtonClick function
            />
            <StyledButton
              variant="contained"
              text={isEditMode ? 'Update' : 'Save'}
              onClick={handleSave} // Define handleFormSubmit function
            />
          </div>
        </ModalContent>
      </Modal>
    </div>
  )
}
export default Customers
