import React, { useState, useEffect, useRef } from 'react'
import {
  Typography,
  Avatar,
  IconButton,
  Popover,
  Card,
  CardContent,
} from '@mui/material'
import axios from 'axios'
import API from '../../services/API'
import { useNavigate } from 'react-router-dom'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import DownloadIcon from '../../../assets/icons/ic_download.svg'
import ViewIcon from '../../../assets/icons/ic_view.png'
import AddIcon from '../../../assets/icons/ic_add.svg'
import { useRecoilState, useResetRecoilState } from 'recoil'
import Tooltip from '@mui/material/Tooltip'
import FilterPage from './DailyTrialFilter'
import * as XLSX from 'xlsx'
import {
  general_details,
  furnace_details,
  charge_mix_details,
  carbon,
  alloying_addition,
  other_parameters,
} from '../../../recoil/atoms'
import FilterIcon from '../../../assets/icons/ic_home_filter.svg'
import CircularProgress from '@mui/material/CircularProgress'
import WifiOffIcon from '@mui/icons-material/WifiOff'

const DailyTrialMonitoringHome = () => {
  const navigate = useNavigate()
  const [dailyTrials, setDailytrials] = useState([])
  const currentUser = JSON.parse(localStorage.getItem('user'))
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [popupPosition, setPopupPosition] = useState({ top: 0, right: 0 })
  const [popupDataFileId, setPopupDatafileId] = useState('')
  const resetGeneralDetailsValue = useResetRecoilState(general_details)
  const resetFurnaceDetailsValue = useResetRecoilState(furnace_details)
  const resetChargeMixDetailsValue = useResetRecoilState(charge_mix_details)
  const resetCarbonValue = useResetRecoilState(carbon)
  const resetAlloyingadditionValue = useResetRecoilState(alloying_addition)
  const resetOtherParametersValue = useResetRecoilState(other_parameters)
  const [isAccordionOpen, setIsAccordionOpen] = useState(false)
  const [selectedSubmissionStatus, setSelectedSubmissionStatus] = useState('')
  const [loading, setLoading] = useState(false) // Loader state
  const [error, setError] = useState(null) // Error state
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [OnlineTest, setOnlineTest] = useState()
  console.log(isFilterOpen, 'isFilterOpen')
  const [anchorEl, setAnchorEl] = useState(null)
  const [coordinates, setCoordinates] = useState([])
  const openFilterPopover = Boolean(anchorEl)
  const filterId = openFilterPopover ? 'simple-popover' : undefined
  const isEdit = localStorage.getItem('Trial')
  const [filteredTrials, setFilteredTrials] = useState([])
  const [offlineInspectionlength, setofflineInspectionlength] = useState(
    localStorage.getItem('lenofflinedata') || '0'
  )
  const access_token = localStorage.getItem('accesstoken')
  const popupRef = useRef(null)

  //////offline code//////

  const [isOnline, setIsOnline] = useState(window.navigator.onLine)

  const [db, setDb] = useState(null)

  useEffect(() => {
    indexedDB.databases().then((databases) => {
      const numberOfDatabases = databases.length

      databases.forEach((db) => {})
      if (databases.find((option) => option.name === 'pcr') === undefined) {
        localStorage.setItem('lenofflinedata', 0)
        setofflineInspectionlength(0)
      }
    })
  }, [])

  useEffect(() => {
    const handleOnline = () => {
      if (!isOnline) {
        setIsOnline(true)
        localStorage.setItem('onlinestate', 1)

        window.location.reload()
      }
    }

    const handleOffline = () => {
      localStorage.setItem('onlinestate', 0)
      // setIsOnlines(false)
      setIsOnline(false)
      // navigate('/reports')
    }

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [isOnline])

  useEffect(() => {
    const onlinecheck = localStorage.getItem('onlinestate')

    setOnlineTest(onlinecheck)
  }, [])

  const dbName = 'pcr'
  const customerName = 'customers'
  const furnacesName = 'furnaces'
  const templateName = 'template'
  const segmentName = 'segment'
  const datafileName = 'datafile'
  const dailytrialName = 'daily_trial'
  const nrmName = 'nrm_performance'
  const erosionName = 'erosion_profile'
  const dataCollectionName = 'data_collection'
  const costSheetName = 'cost_sheet'
  const LiningName = 'lining_patching_no'

  useEffect(() => {
    if (!isOnline) {
      const request = indexedDB.open(dbName, 1)

      request.onupgradeneeded = (event) => {
        const database = event.target.result
        database.createObjectStore(customerName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(furnacesName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(templateName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(segmentName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(datafileName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(dailytrialName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(nrmName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(erosionName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(dataCollectionName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(costSheetName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(LiningName, {
          keyPath: 'id',
          autoIncrement: true,
        })
      }

      request.onsuccess = (event) => {
        const database = event.target.result
        const transaction = database.transaction(
          [
            'customers',
            'furnaces',
            'template',
            'segment',
            'datafile',
            'daily_trial',
            'nrm_performance',
            'erosion_profile',
            'data_collection',
            'cost_sheet',
            'lining_patching_no',
          ],
          'readwrite'
        )

        setDb(database)
      }

      request.onerror = (event) => {
        console.error('Error opening IndexedDB', event)
      }
    }
  }, [isOnline])

  useEffect(() => {
    if (!isOnline && db) {
      const transaction = db.transaction(
        [
          'daily_trial',
          'datafile',
          'template',
          'segment',
          'furnaces',
          'customers',
        ],
        'readonly'
      )
      const daily_trialStore = transaction.objectStore('daily_trial')
      const datafileStore = transaction.objectStore('datafile')
      const templateStore = transaction.objectStore('template')
      const segmentStore = transaction.objectStore('segment')
      const furnacesStore = transaction.objectStore('furnaces')
      const customersStore = transaction.objectStore('customers')

      const daily_trialdata = []
      const datafileData = []
      const templateData = []
      const segmentData = []
      const furnacesData = []
      const customersData = []

      const daily_trialCursorRequest = daily_trialStore.openCursor()
      daily_trialCursorRequest.onsuccess = function (event) {
        const cursor = event.target.result

        if (cursor) {
          daily_trialdata.push(cursor.value)
          cursor.continue()
        } else {
          const datafileCursorRequest = datafileStore.openCursor()
          datafileCursorRequest.onsuccess = function (event) {
            const cursor = event.target.result

            if (cursor) {
              datafileData.push(cursor.value)
              cursor.continue()
            } else {
              const templateCursorRequest = templateStore.openCursor()
              templateCursorRequest.onsuccess = function (event) {
                const cursor = event.target.result

                if (cursor) {
                  templateData.push(cursor.value)
                  cursor.continue()
                } else {
                  const segmentCursorRequest = segmentStore.openCursor()
                  segmentCursorRequest.onsuccess = function (event) {
                    const cursor = event.target.result
                    if (cursor) {
                      segmentData.push(cursor.value)
                      cursor.continue()
                    } else {
                      const furnacesCursorRequest = furnacesStore.openCursor()
                      furnacesCursorRequest.onsuccess = function (event) {
                        const cursor = event.target.result

                        if (cursor) {
                          furnacesData.push(cursor.value)
                          cursor.continue()
                        } else {
                          const customersCursorRequest =
                            customersStore.openCursor()
                          customersCursorRequest.onsuccess = function (event) {
                            const cursor = event.target.result
                            if (cursor) {
                              customersData.push(cursor.value)
                              cursor.continue()
                            } else {
                              // Perform join operations based on specified conditions

                              const joinedData = daily_trialdata.map(
                                (daily_trialItem, index) => {
                                  console.log(
                                    'daily_trialItem.datafile_id:',
                                    daily_trialItem.datafile_id
                                  )
                                  const matchingDatafile = datafileData.find(
                                    (datafileItem) =>
                                      datafileItem.id ===
                                      parseInt(daily_trialItem.datafile_id, 10)
                                  )
                                  const matchingTemplate = templateData.find(
                                    (templateItem) =>
                                      templateItem.id ===
                                      datafileData[index].template_id
                                  )
                                  const matchingSegment = segmentData.find(
                                    (segmentItem) =>
                                      segmentItem.id ===
                                      datafileData[index].segment_id
                                  )
                                  const matchingFurnace = furnacesData.find(
                                    (furnaceItem) =>
                                      furnaceItem.id ===
                                      datafileData[index].furnace_id
                                  )
                                  const matchingCustomer = customersData.find(
                                    (customerItem) =>
                                      customerItem.id ===
                                      datafileData[index].customer_id
                                  )

                                  return {
                                    ...daily_trialItem,
                                    datafile: matchingDatafile,
                                    template: matchingTemplate,
                                    segment: matchingSegment,
                                    furnace: matchingFurnace,
                                    customer: matchingCustomer,
                                  }
                                }
                              )
                              // setJoined(joinedData)
                              console.log('Joined data:', joinedData)
                              localStorage.setItem(
                                'lenofflinedata',
                                joinedData.length
                              )
                              setDailytrials(joinedData)
                              console.log('daily_trialdata:', daily_trialdata)
                              console.log('datafileData:', datafileData)
                              console.log('templateData:', templateData)
                              console.log('segmentData:', segmentData)
                              console.log('furnacesData:', furnacesData)
                              console.log('customersData:', customersData)
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          transaction.onerror = function (event) {
            console.error('Error in transaction:', event.target.error)
          }
        }
      }
    }
  }, [db])

  const Indexeddbdatas = async () => {
    indexedDB.databases().then((databases) => {
      const numberOfDatabases = databases.length
      const pcrDatabase = databases.find((db) => db.name === 'pcr')
      databases.forEach((db) => {})
      if (numberOfDatabases > 0 && pcrDatabase) {
        const request = indexedDB.open('pcr')
        request.onsuccess = async function (event) {
          const db = event.target.result
          const transaction = db.transaction(
            [
              'daily_trial',
              'datafile',
              'template',
              'segment',
              'furnaces',
              'customers',
            ],
            'readonly'
          )
          const daily_trialStore = transaction.objectStore('daily_trial')
          const datafileStore = transaction.objectStore('datafile')
          const templateStore = transaction.objectStore('template')
          const segmentStore = transaction.objectStore('segment')
          const furnacesStore = transaction.objectStore('furnaces')
          const customersStore = transaction.objectStore('customers')

          const daily_trialdata = []
          const datafileData = []
          const templateData = []
          const segmentData = []
          const furnacesData = []
          const customersData = []

          const daily_trialCursorRequest = daily_trialStore.openCursor()
          daily_trialCursorRequest.onsuccess = async function (event) {
            const cursor = event.target.result

            if (cursor) {
              daily_trialdata.push(cursor.value)
              cursor.continue()
            } else {
              const datafileCursorRequest = datafileStore.openCursor()
              datafileCursorRequest.onsuccess = async function (event) {
                const cursor = event.target.result

                if (cursor) {
                  datafileData.push(cursor.value)
                  cursor.continue()
                } else {
                  const templateCursorRequest = templateStore.openCursor()
                  templateCursorRequest.onsuccess = async function (event) {
                    const cursor = event.target.result

                    if (cursor) {
                      templateData.push(cursor.value)
                      cursor.continue()
                    } else {
                      const segmentCursorRequest = segmentStore.openCursor()
                      segmentCursorRequest.onsuccess = async function (event) {
                        const cursor = event.target.result
                        if (cursor) {
                          segmentData.push(cursor.value)
                          cursor.continue()
                        } else {
                          const furnacesCursorRequest =
                            furnacesStore.openCursor()
                          furnacesCursorRequest.onsuccess = async function (
                            event
                          ) {
                            const cursor = event.target.result

                            if (cursor) {
                              furnacesData.push(cursor.value)
                              cursor.continue()
                            } else {
                              const customersCursorRequest =
                                customersStore.openCursor()
                              customersCursorRequest.onsuccess =
                                async function (event) {
                                  const cursor = event.target.result
                                  if (cursor) {
                                    customersData.push(cursor.value)
                                    cursor.continue()
                                  } else {
                                    // Perform join operations based on specified conditions

                                    const joinedData = daily_trialdata.map(
                                      (daily_trialItem, index) => {
                                        console.log(
                                          'daily_trialItem.datafile_id:',
                                          daily_trialItem.datafile_id
                                        )
                                        const matchingDatafile =
                                          datafileData.find(
                                            (datafileItem) =>
                                              datafileItem.id ===
                                              parseInt(
                                                daily_trialItem.datafile_id,
                                                10
                                              )
                                          )
                                        const matchingTemplate =
                                          templateData.find(
                                            (templateItem) =>
                                              templateItem.id ===
                                              datafileData[index].template_id
                                          )
                                        const matchingSegment =
                                          segmentData.find(
                                            (segmentItem) =>
                                              segmentItem.id ===
                                              datafileData[index].segment_id
                                          )
                                        const matchingFurnace =
                                          furnacesData.find(
                                            (furnaceItem) =>
                                              furnaceItem.id ===
                                              datafileData[index].furnace_id
                                          )
                                        const matchingCustomer =
                                          customersData.find(
                                            (customerItem) =>
                                              customerItem.id ===
                                              datafileData[index].customer_id
                                          )

                                        return {
                                          ...daily_trialItem,
                                          datafile: matchingDatafile,
                                          template: matchingTemplate,
                                          segment: matchingSegment,
                                          furnace: matchingFurnace,
                                          customer: matchingCustomer,
                                        }
                                      }
                                    )
                                    // setJoined(joinedData)
                                    console.log('Joined data:', joinedData)
                                    localStorage.setItem(
                                      'lenofflinedata',
                                      joinedData.length
                                    )
                                    // setofflineInspectionlength(joinedData.length)
                                    if (joinedData.length > 0) {
                                      const imagesData =
                                        await uploadBlobImagesAPI(
                                          joinedData[0],
                                          0,
                                          joinedData.length
                                        )
                                    }
                                    if (joinedData.length === 0) {
                                      setLoading(false)
                                      // localStorage.setItem("isloading",0)
                                    }
                                    // if(joinedData.length===0){
                                    //   const arrayTrialId = JSON.parse(localStorage.getItem('datafile_dailytrial_id'));
                                    //   if(arrayTrialId!==null&& arrayTrialId.length>0){
                                    //     const response5 = await axios.post(API.GET_ARRAY_DAILY_TRIAL, arrayTrialId);
                                    //     // Do something with response5 if needed

                                    //     const uniqueEntries = new Map();
                                    //     const filteredData = [];
                                    //     const remainingData = [];

                                    //     response5.data.forEach(item => {
                                    //       const key = `${item.alloying_addition}-${item.carbon}-${item.charge_mix_details}-${item.dataFile.user_id}-${item.dataFile.segment_id}-${item.dataFile.furnace_id}-${item.furnace_details}-${item.general_details}-${item.other_calculated_varialble}`;
                                    //       if (!uniqueEntries.has(key)) {
                                    //         uniqueEntries.set(key, true);
                                    //         filteredData.push(item);
                                    //       } else {
                                    //         remainingData.push(item);
                                    //       }
                                    //     });

                                    //     const trialIds = remainingData.map(item => item.datafile_id);
                                    //     const response6 = await axios.post(API.DELETE_ARRAY_DAILY_TRIAL, trialIds);
                                    //     // Do something with response5 if needed
                                    //     if(response6.status===200){
                                    //       localStorage.setItem('datafile_dailytrial_id',JSON.stringify([]))
                                    //     }

                                    //   }
                                    // }
                                  }
                                }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              transaction.onerror = function (event) {
                console.error('Error in transaction:', event.target.error)
              }
            }
          }
        }
        request.onerror = function (event) {
          console.error('Error opening database:', event.target.error)
        }
      }
    })
  }

  const MAX_RETRIES = 10 // Set maximum retry attempts

  const fetchWithRetry = async (url, options, retries = 0) => {
    try {
      const response = await axios.post(url, options)
      if (response.status === 200) {
        return response
      }
    } catch (error) {
      if (retries < MAX_RETRIES && error.response.status !== 200) {
        await new Promise((resolve) => setTimeout(resolve, 1000)) // Adjust delay as needed
        return fetchWithRetry(url, options, retries + 1)
      } else {
        throw error // Re-throw error if exceeding retries or status code is not the issue
      }
    }
  }

  const fetchWithRetryGet = async (url, retries = 0) => {
    try {
      const response = await axios.get(url)
      if (response.status === 200) {
        return response
      }
    } catch (error) {
      if (retries < MAX_RETRIES && error.response.status !== 200) {
        await new Promise((resolve) => setTimeout(resolve, 1000)) // Adjust delay as needed
        return fetchWithRetryGet(url, retries + 1)
      } else {
        throw error // Re-throw error if exceeding retries or status code is not the issue
      }
    }
  }

  const uploadBlobImagesAPI = async (data, index, length) => {
    console.log('syncdat1', data)

    try {
      const dailytrialdata = {
        general_details: Array.isArray(data.general_details)
          ? data.general_details
          : [data.general_details],
        furnace_details: Array.isArray(data.furnace_details)
          ? data.furnace_details
          : [data.furnace_details],
        charge_mix_details: Array.isArray(data.charge_mix_details)
          ? data.charge_mix_details
          : [data.charge_mix_details],
        carbon: Array.isArray(data.carbon) ? data.carbon : [data.carbon],
        alloying_addition: Array.isArray(data.alloying_addition)
          ? data.alloying_addition
          : [data.alloying_addition],
        other_parameters: Array.isArray(data.other_parameters)
          ? data.other_parameters
          : [data.other_parameters],
        other_calculated_variable: Array.isArray(data.other_calculated_variable)
          ? data.other_calculated_variable
          : [data.other_calculated_variable],
      }

      const newTrail = {
        customer_id: data.datafile.customer_id,
        template_id: data.datafile.template_id,
        user_id: data.datafile.user_id,
        segment_id: data.datafile.segment_id,
        furnace_id: data.datafile.furnace_id,
      }
      const response6 = await fetchWithRetry(
        API.UPLOAD_DATAFILE_MOBILE,
        newTrail,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      if (response6.status === 200) {
        console.log('response.iddd', response6.data)
        console.log('response.iddd1', response6.data.data.id)
        //           const arrayTrialId=JSON.parse(localStorage.getItem('datafile_dailytrial_id'))
        // if(arrayTrialId===null){
        //   let trialIdvalues=[]
        //   trialIdvalues.push(response6.data.data.id)
        //   localStorage.setItem('datafile_dailytrial_id',JSON.stringify(trialIdvalues))
        // }
        // if (arrayTrialId!==null){
        //   let trialIdvalues=arrayTrialId
        //   trialIdvalues.push(response6.data.data.id)
        //   localStorage.setItem('datafile_dailytrial_id',JSON.stringify(trialIdvalues))
        // }
        dailytrialdata.upload_id = response6.data.data.id

        indexedDB.databases().then((databases) => {
          const numberOfDatabases = databases.length

          databases.forEach((db) => {})
          if (numberOfDatabases > 0) {
            const request = indexedDB.open('pcr')
            request.onsuccess = function (event) {
              const db = event.target.result
              const transactiontrial = db.transaction(datafileName, 'readwrite')
              const objectStoretrial =
                transactiontrial.objectStore(datafileName)

              const deleterequesttrial = objectStoretrial.delete(data.id)
              deleterequesttrial.onsuccess = () => {}

              deleterequesttrial.onerror = () => {
                console.error('Error updating record in IndexedDB')
              }
              const transactiontrialdetail = db.transaction(
                dailytrialName,
                'readwrite'
              )
              const objectStoretrialdetail =
                transactiontrialdetail.objectStore(dailytrialName)

              const deleterequest = objectStoretrialdetail.delete(data.id)

              deleterequest.onsuccess = async () => {}

              deleterequest.onerror = () => {
                console.error('Error updating record in IndexedDB')
              }
            }
            request.onerror = function (event) {
              console.error('Error opening database:', event.target.error)
            }
          }
        })
        const response7 = await fetchWithRetry(
          API.FORM_INSERT_SYNC,
          dailytrialdata,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        // const arrayTrialDetailsId=JSON.parse(localStorage.getItem('trialdetailsidarray'))
        // let trialdetailsIdvalues=arrayTrialDetailsId
        //     trialdetailsIdvalues.push(response7.data.identifiers[0].id)
        //     localStorage.setItem('trialdetailsidarray',JSON.stringify(trialdetailsIdvalues))
        //   localStorage.setItem("trialsyncid",response7.data.identifiers[0].id)

        if (response7.status === 200) {
          // localStorage.setItem("trialsyncid",response7.data.identifiers[0].id)
          setofflineInspectionlength(length)

          await Indexeddbdatas()
          // handleOpenAlert(`Inspection ${index+1} created Successfully`);

          //      if(length-1===index){
          //       setTimeout(() => {
          //         setLoading(false)
          //         localStorage.setItem("lenofflinedata",0)
          //         window.location.reload();
          //       }, 2500);
          // }
        }
      }

      // Handle the response and update your UI or state as needed
    } catch (error) {
      console.error('Error uploading images:', error)
      // Handle error, update UI, etc.
    }
  }

  useEffect(() => {
    if (OnlineTest === '1') {
      // window.location.reload()

      setTimeout(async () => {
        indexedDB.databases().then((databases) => {
          const numberOfDatabases = databases.length
          const pcrDatabase = databases.find((db) => db.name === 'pcr')
          databases.forEach((db) => {})
          if (numberOfDatabases > 0 && pcrDatabase) {
            const request = indexedDB.open('pcr')
            request.onsuccess = async function (event) {
              setLoading(true)
              // localStorage.setItem("isloading",1)
              const db = event.target.result
              const transaction = db.transaction(
                [
                  'daily_trial',
                  'datafile',
                  'template',
                  'segment',
                  'furnaces',
                  'customers',
                ],
                'readonly'
              )
              const daily_trialStore = transaction.objectStore('daily_trial')
              const datafileStore = transaction.objectStore('datafile')
              const templateStore = transaction.objectStore('template')
              const segmentStore = transaction.objectStore('segment')
              const furnacesStore = transaction.objectStore('furnaces')
              const customersStore = transaction.objectStore('customers')

              const daily_trialdata = []
              const datafileData = []
              const templateData = []
              const segmentData = []
              const furnacesData = []
              const customersData = []

              const daily_trialCursorRequest = daily_trialStore.openCursor()
              daily_trialCursorRequest.onsuccess = async function (event) {
                const cursor = event.target.result

                if (cursor) {
                  daily_trialdata.push(cursor.value)
                  cursor.continue()
                } else {
                  const datafileCursorRequest = datafileStore.openCursor()
                  datafileCursorRequest.onsuccess = async function (event) {
                    const cursor = event.target.result

                    if (cursor) {
                      datafileData.push(cursor.value)
                      cursor.continue()
                    } else {
                      const templateCursorRequest = templateStore.openCursor()
                      templateCursorRequest.onsuccess = async function (event) {
                        const cursor = event.target.result

                        if (cursor) {
                          templateData.push(cursor.value)
                          cursor.continue()
                        } else {
                          const segmentCursorRequest = segmentStore.openCursor()
                          segmentCursorRequest.onsuccess = async function (
                            event
                          ) {
                            const cursor = event.target.result
                            if (cursor) {
                              segmentData.push(cursor.value)
                              cursor.continue()
                            } else {
                              const furnacesCursorRequest =
                                furnacesStore.openCursor()
                              furnacesCursorRequest.onsuccess = async function (
                                event
                              ) {
                                const cursor = event.target.result

                                if (cursor) {
                                  furnacesData.push(cursor.value)
                                  cursor.continue()
                                } else {
                                  const customersCursorRequest =
                                    customersStore.openCursor()
                                  customersCursorRequest.onsuccess =
                                    async function (event) {
                                      const cursor = event.target.result
                                      if (cursor) {
                                        customersData.push(cursor.value)
                                        cursor.continue()
                                      } else {
                                        // Perform join operations based on specified conditions

                                        const joinedData = daily_trialdata.map(
                                          (daily_trialItem, index) => {
                                            console.log(
                                              'daily_trialItem.datafile_id:',
                                              daily_trialItem.datafile_id
                                            )
                                            const matchingDatafile =
                                              datafileData.find(
                                                (datafileItem) =>
                                                  datafileItem.id ===
                                                  parseInt(
                                                    daily_trialItem.datafile_id,
                                                    10
                                                  )
                                              )
                                            const matchingTemplate =
                                              templateData.find(
                                                (templateItem) =>
                                                  templateItem.id ===
                                                  datafileData[index]
                                                    .template_id
                                              )
                                            const matchingSegment =
                                              segmentData.find(
                                                (segmentItem) =>
                                                  segmentItem.id ===
                                                  datafileData[index].segment_id
                                              )
                                            const matchingFurnace =
                                              furnacesData.find(
                                                (furnaceItem) =>
                                                  furnaceItem.id ===
                                                  datafileData[index].furnace_id
                                              )
                                            const matchingCustomer =
                                              customersData.find(
                                                (customerItem) =>
                                                  customerItem.id ===
                                                  datafileData[index]
                                                    .customer_id
                                              )

                                            return {
                                              ...daily_trialItem,
                                              datafile: matchingDatafile,
                                              template: matchingTemplate,
                                              segment: matchingSegment,
                                              furnace: matchingFurnace,
                                              customer: matchingCustomer,
                                            }
                                          }
                                        )
                                        // setJoined(joinedData)
                                        console.log('Joined data:', joinedData)
                                        localStorage.setItem(
                                          'lenofflinedata',
                                          joinedData.length
                                        )
                                        // setofflineInspectionlength(joinedData.length)
                                        if (joinedData.length > 0) {
                                          const imagesData =
                                            await uploadBlobImagesAPI(
                                              joinedData[0],
                                              0,
                                              joinedData.length
                                            )
                                        }
                                        if (joinedData.length === 0) {
                                          setLoading(false)
                                          localStorage.setItem('isloading', 0)
                                        }
                                        // if(joinedData.length===0){
                                        //   const arrayTrialId = JSON.parse(localStorage.getItem('datafile_dailytrial_id'));
                                        //   if(arrayTrialId!==null&& arrayTrialId.length>0){
                                        //     const response5 = await axios.post(API.GET_ARRAY_DAILY_TRIAL, arrayTrialId);
                                        //     // Do something with response5 if needed

                                        //     const uniqueEntries = new Map();
                                        //     const filteredData = [];
                                        //     const remainingData = [];

                                        //     response5.data.forEach(item => {
                                        //       const key = `${item.alloying_addition}-${item.carbon}-${item.charge_mix_details}-${item.dataFile.user_id}-${item.dataFile.segment_id}-${item.dataFile.furnace_id}-${item.furnace_details}-${item.general_details}-${item.other_calculated_varialble}`;
                                        //       if (!uniqueEntries.has(key)) {
                                        //         uniqueEntries.set(key, true);
                                        //         filteredData.push(item);
                                        //       } else {
                                        //         remainingData.push(item);
                                        //       }
                                        //     });

                                        //     const trialIds = remainingData.map(item => item.datafile_id);
                                        //     const response6 = await axios.post(API.DELETE_ARRAY_DAILY_TRIAL, trialIds);
                                        //     // Do something with response5 if needed
                                        //     if(response6.status===200){
                                        //       localStorage.setItem('datafile_dailytrial_id',JSON.stringify([]))
                                        //     }

                                        //   }
                                        // }
                                      }
                                    }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }

                  transaction.onerror = function (event) {
                    console.error('Error in transaction:', event.target.error)
                  }
                }
              }
            }
            request.onerror = function (event) {
              console.error('Error opening database:', event.target.error)
            }
          }
        })
      }, 0)
    }
  }, [isOnline, OnlineTest])

  useEffect(() => {
    const fetchDataAndProcess = async () => {
      console.log('offhello')
      if (
        localStorage.getItem('lenofflinedata') === '0' &&
        localStorage.getItem('lenofflinenrmdata') === '0' &&
        localStorage.getItem('lenofflineerosiondata') === '0' &&
        localStorage.getItem('onlinestate') === '1'
      ) {
        // const arrayTrialId = JSON.parse(localStorage.getItem('datafile_dailytrial_id'));
        // if(arrayTrialId!==null&& arrayTrialId.length>0){
        //   const response5 = await axios.post(API.GET_ARRAY_DAILY_TRIAL, arrayTrialId);
        //   // Do something with response5 if needed

        //   const uniqueEntries = new Map();
        //   const filteredData = [];
        //   const remainingData = [];

        //   response5.data.forEach(item => {
        //     const key = `${item.alloying_addition}-${item.carbon}-${item.charge_mix_details}-${item.dataFile.user_id}-${item.dataFile.segment_id}-${item.dataFile.furnace_id}-${item.furnace_details}-${item.general_details}-${item.other_calculated_varialble}`;
        //     if (!uniqueEntries.has(key)) {
        //       uniqueEntries.set(key, true);
        //       filteredData.push(item);
        //     } else {
        //       remainingData.push(item);
        //     }
        //   });

        //   const trialIds = remainingData.map(item => item.datafile_id);
        //   const response6 = await axios.post(API.DELETE_ARRAY_DAILY_TRIAL, trialIds);
        //   // Do something with response5 if needed
        //   if(response6.status===200){
        //     localStorage.setItem('datafile_dailytrial_id',JSON.stringify([]))
        //   }

        // }
        console.log('offhello2')
        const dbName = 'pcr' // Replace 'YourDatabaseName' with the name of your database
        const deleteDBRequest = window.indexedDB.deleteDatabase(dbName)

        deleteDBRequest.onsuccess = () => {
          console.log('db deleted successfully')
        }

        deleteDBRequest.onerror = (event) => {
          console.error('Error deleting database:', event.target.error)
        }

        deleteDBRequest.onblocked = (event) => {
          console.error('Database deletion is blocked:', event.target.error)
        }
      }
    }

    fetchDataAndProcess()
  }, [isOnline, OnlineTest])

  useEffect(() => {
    setofflineInspectionlength(localStorage.getItem('lenofflinedata') || '0')
  }, [offlineInspectionlength])

  useEffect(() => {
    fetchData()
  }, [])

  const handleApplyFilter = (filteredData) => {
    console.log(filteredData, 'filteredData')
    setFilteredTrials(filteredData)
  }

  const handleFilterClick = () => {
    setIsFilterOpen(true)
    // navigate("/dailyTrialFilter");
  }

  const fetchData = async () => {
    setLoading(true)
    setError(null)

    try {
      const response = await axios.get(API.GET_ALL_DAILY_TRIAL_MONITORING, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      const dailyTrialResponse = response.data
      const selectedCustomer = JSON.parse(
        localStorage.getItem('selectedCustomer')
      )
      const customer_id = selectedCustomer?.id
      if (!customer_id) {
        console.warn('No customer selected or customer_id not found')
        setDailytrials([])
        return
      }

      if (currentUser.role.role_id === 1) {
        const userId = currentUser.id
        const selectedCustomer = JSON.parse(
          localStorage.getItem('selectedCustomer')
        )
        const customer_id = selectedCustomer?.id
  
        const filterDailyTrialResponse = dailyTrialResponse.filter(
          (item) =>
            item.dataFile.customer_id === customer_id &&
            item.dataFile.user_id === userId
        )
        setDailytrials(filterDailyTrialResponse)
      } else if (currentUser.role.role_id === 2) {
        const assignCustomerIds = currentUser.assign_customer_id
        const selectedCustomer = JSON.parse(
          localStorage.getItem('selectedCustomer')
        )
        const customer_id = selectedCustomer?.id

        const filterDailyTrialResponse = dailyTrialResponse.filter((item) =>
          customer_id.includes(item.dataFile.customer_id)
        )
        setDailytrials(filterDailyTrialResponse)
      } else if (
        currentUser.role.role_id === 3 ||
        currentUser.role.role_id === 4
      ) {
        const selectedCustomer = JSON.parse(
          localStorage.getItem('selectedCustomer')
        )
        const customer_id = selectedCustomer?.id
        const filterDailyTrialResponse = dailyTrialResponse.filter(
          (item) => item.dataFile.customer_id === customer_id
        )
        setDailytrials(filterDailyTrialResponse)
      }
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleMenuOpen = (
    event,
    datafile_id,
    daily_trial_id,
    submission_status
  ) => {
    const rect = event.target.getBoundingClientRect()
    setIsPopupOpen(true)
    setPopupPosition({
      top: rect.top + window.scrollY,
      right: window.innerWidth - rect.right,
    })
    localStorage.setItem('daily_trial_id', daily_trial_id)
    setPopupDatafileId(datafile_id)
    setSelectedSubmissionStatus(submission_status)

    console.log(
      'Clicked Card Details:',
      daily_trial_id,
      datafile_id,
      submission_status
    )
  }

  const handleView = async () => {
    localStorage.setItem('Trial', 'view')
    navigate('/viewdailytrial')
  }

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsPopupOpen(false) // Close the popup if clicked outside
    }
  }

  useEffect(() => {
    if (isPopupOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isPopupOpen])

  const handleAddTrial = async () => {
    resetGeneralDetailsValue()
    resetFurnaceDetailsValue()
    resetChargeMixDetailsValue()
    resetCarbonValue()
    resetAlloyingadditionValue()
    resetOtherParametersValue()

    localStorage.setItem('Trial', 'add')
    navigate('/dailytrialmonitoringM')
  }

  // const handleDownload = async () => {
  //   console.log('handle download clicked')
  //   const id = localStorage.getItem('daily_trial_id')
  //   console.log('idd', id)

  //   setLoading(true)
  //   setError(null)
  //   try {
  //     // Fetch data from the endpoint
  //     const response = await axios.get(`${API.GET_DAILY_TRIAL_BY_ID}/${id}`, {
  //       headers: {
  //         Authorization: `Bearer ${access_token}`,
  //       },
  //     })
  //     const data = response.data

  //     console.log('DATAAA', data)

  //     // Make a GET request to the downloadTemplate API endpoint
  //     const responseTemplate = await axios.get(API.GET_EXCEL_DOWNLOAD, {
  //       responseType: 'blob', // Set the response type to 'blob'
  //     })

  //     // Read the downloaded template as binary string
  //     const reader = new FileReader()
  //     reader.onload = async () => {
  //       const binaryStr = reader.result
  //       const workbook = XLSX.read(binaryStr, { type: 'binary' })

  //       // Modify the workbook with data from the response
  //       const sheetName = workbook.SheetNames[0]
  //       const worksheet = workbook.Sheets[sheetName]

  //       const columnsToClear = [
  //         { start: 'A', end: 'H' },
  //         { start: 'I', end: 'X' },
  //         { start: 'Y', end: 'AJ' },
  //         { start: 'AK', end: 'AO' },
  //         { start: 'AP', end: 'BB' },
  //         { start: 'BC', end: 'BF' },
  //       ]

  //       // Get the range of the worksheet
  //       const range = XLSX.utils.decode_range(worksheet['!ref'])

  //       // Clear existing data in the specified columns (starting from the 9th row)
  //       columnsToClear.forEach(({ start, end }) => {
  //         for (let rowIndex = 8; rowIndex <= range.e.r; rowIndex++) {
  //           for (
  //             let colIndex = XLSX.utils.decode_col(start);
  //             colIndex <= XLSX.utils.decode_col(end);
  //             colIndex++
  //           ) {
  //             const cellAddress = XLSX.utils.encode_cell({
  //               r: rowIndex,
  //               c: colIndex,
  //             })
  //             if (worksheet[cellAddress]) {
  //               delete worksheet[cellAddress]
  //             }
  //           }
  //         }
  //       })

  //       // Update the range to reflect the cleared data
  //       worksheet['!ref'] = XLSX.utils.encode_range(range.s, {
  //         r: 7,
  //         c: range.e.c,
  //       })

  //       // Assuming the first row is for headers and the second row is for data
  //       const headerRow = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0]

  //       // Get the general_details object from the provided JSON
  //       const generalDetails = data.general_details
  //       const furnaceDetails = data.furnace_details
  //       const chargeMixDetails = data.charge_mix_details
  //       const carbonDetails = data.carbon
  //       const OtherParameters = data.other_parameters
  //       const alloyingAddition = data.alloying_addition
  //       console.log('chargeMixDetails', chargeMixDetails)
  //       // Loop through the general_details object
  //       generalDetails.forEach((detail, index) => {
  //         // Access each property of the detail object and do something with it
  //         const furnaceNo = detail.furnace_no || ''
  //         const crucibleNo = detail.crucible_no || ''
  //         const campaingnNo = detail.campaign_no || ''
  //         const liningPatchingNo = detail.lining_patching_no || ''
  //         const heatNo = detail.heat_no || ''
  //         const date = detail.date || ''
  //         const customerRefNo = detail.customer_ref_no || ''
  //         const grade = detail.grade || ''
  //         const rowData = [
  //           furnaceNo,
  //           crucibleNo,
  //           campaingnNo,
  //           liningPatchingNo,
  //           heatNo,
  //           date,
  //           customerRefNo,
  //           grade,
  //         ]
  //         const rowIndex = 9 + index // Calculate the row index dynamically based on the index
  //         XLSX.utils.sheet_add_aoa(worksheet, [rowData], {
  //           origin: `A${rowIndex}`,
  //         })
  //       })

  //       furnaceDetails.forEach((detail, index) => {
  //         const heatOnTime = detail.heat_on_time
  //         const heatTapTime = detail.heat_tap_time
  //         const breakdownDuration = detail.break_down_duration
  //         const breakdownActual = detail.break_down_duration

  //         const heatDurationActual = detail.heat_duration_actual
  //         const heatDurationCalculated = detail.heat_duration_calculated
  //         const tappingTemperature = detail.tapping_temperature
  //         const tappedTonnage = detail.tapped_tonnage
  //         const meltRateBasedOnActualDuration =
  //           detail.melt_rate_based_on_actual_duration

  //         const meltRateBasedOnCalculatedDuration =
  //           detail.melt_rate_based_on_calculated_duration
  //         const frequency = detail.frequency
  //         const voltage = detail.voltage
  //         const powerInthePanel = detail.power_in_the_panel
  //         const capacitorsRemoved = detail.capacitors_removed
  //         const totalPowerConsumed = detail.total_power_consumed
  //         const energyConsumptionperUnitTonActual =
  //           detail.energy_consumption_per_unit_ton_actual
  //         const energyConsumptionperUnitTonCalculated =
  //           detail.energy_consumption_per_unit_ton_calculated
  //         const rowData = [
  //           heatOnTime,
  //           heatTapTime,
  //           breakdownDuration,
  //           breakdownActual,
  //           heatDurationActual,
  //           heatDurationCalculated,
  //           tappingTemperature,
  //           tappedTonnage,
  //           meltRateBasedOnActualDuration,
  //           meltRateBasedOnCalculatedDuration,
  //           frequency,
  //           voltage,
  //           powerInthePanel,
  //           capacitorsRemoved,
  //           totalPowerConsumed,
  //           energyConsumptionperUnitTonActual,
  //           energyConsumptionperUnitTonCalculated,
  //         ]
  //         const rowIndex = 9 + index // Calculate the row index dynamically based on the index
  //         XLSX.utils.sheet_add_aoa(worksheet, [rowData], {
  //           origin: `I${rowIndex}`,
  //         })
  //       })

  //       chargeMixDetails.forEach((detail, index) => {
  //         const scrap = detail.scrap
  //         const shredded = detail.shredded
  //         const pigIron = detail.pig_iron
  //         const pooledIron = detail.pooled_iron
  //         const hotHeel = detail.hot_heel
  //         const lump = detail.lump
  //         const pellet = detail.pellet
  //         const fineslessthan5MM = detail.fines_lessthan_1mm
  //         const fineslessthan1MM = detail.fines_lessthan_5mm
  //         const slagCrushers = detail.slag_crushers
  //         const tundishorMetalicJam = detail.tundish_or_slag_or_metalic_jam
  //         const totalCharge = detail.total_charge

  //         // / Do something with the extracted data, such as inserting it into a worksheet
  //         // Example:
  //         const rowData = [
  //           scrap,
  //           shredded,
  //           pigIron,
  //           pooledIron,
  //           hotHeel,
  //           lump,
  //           pellet,
  //           fineslessthan5MM,
  //           fineslessthan1MM,
  //           slagCrushers,
  //           tundishorMetalicJam,
  //           totalCharge,
  //         ]
  //         const rowIndex = 9 + index // Calculate the row index dynamically based on the index
  //         XLSX.utils.sheet_add_aoa(worksheet, [rowData], {
  //           origin: `Y${rowIndex}`,
  //         })
  //       })

  //       carbonDetails.forEach((detail, index) => {
  //         const cpc = detail.cpc
  //         const metcoke = detail.metcoke
  //         const fifty_percent = detail.fifty_percent
  //         const eightyfive_percent = detail.eightyfive_percent
  //         const final_percent = detail.fianl_percent
  //         const rowData = [
  //           cpc,
  //           metcoke,
  //           fifty_percent,
  //           eightyfive_percent,
  //           final_percent,
  //         ]
  //         const rowIndex = 9 + index // Calculate the row index dynamically based on the index
  //         XLSX.utils.sheet_add_aoa(worksheet, [rowData], {
  //           origin: `AK${rowIndex}`,
  //         })
  //       })

  //       alloyingAddition.forEach((detail, index) => {
  //         const siBeforeAddition = detail.si_before_alloy_addition
  //         const finalSi = detail.final_si
  //         const mnBeforeAddition = detail.mn_before_alloy_addition
  //         const finalMN = detail.final_mn
  //         const crBeforeAddition = detail.cr_before_alloy_addition
  //         const finalCR = detail.final_cr
  //         const siMn = detail.si_mn
  //         const mnpercentageinSiMn = detail.mn_in_simn
  //         const feMn = detail.feMn
  //         const feSi = detail.feSi
  //         const feCr = detail.feCr
  //         const millScale = detail.mill_scale
  //         const anyOtherAlloyAddition = detail.any_other_alloy_addition

  //         const rowData = [
  //           siBeforeAddition,
  //           finalSi,
  //           mnBeforeAddition,
  //           finalMN,
  //           crBeforeAddition,
  //           finalCR,
  //           siMn,
  //           mnpercentageinSiMn,
  //           feMn,
  //           feSi,
  //           feCr,
  //           millScale,
  //           anyOtherAlloyAddition,
  //         ]
  //         const rowIndex = 9 + index // Calculate the row index dynamically based on the index
  //         XLSX.utils.sheet_add_aoa(worksheet, [rowData], {
  //           origin: `AP${rowIndex}`,
  //         })
  //       })
  //       OtherParameters.forEach((detail, index) => {
  //         const inletWaterTemp = detail.inlet_water_temp
  //         const outletWaterTemp = detail.outlet_water_temp
  //         const erPr = 1
  //         const remarks = detail.remarks
  //         const rowData = [inletWaterTemp, outletWaterTemp, erPr, remarks]
  //         const rowIndex = 9 + index // Calculate the row index dynamically based on the index
  //         XLSX.utils.sheet_add_aoa(worksheet, [rowData], {
  //           origin: `BC${rowIndex}`,
  //         })
  //       })
  //       // Loo

  //       // Generate blob for the modified workbook
  //       const newWorkbookBlob = await workbookToBlob(workbook)

  //       // Create a URL for the blob data
  //       const url = window.URL.createObjectURL(newWorkbookBlob)

  //       // Create a link element
  //       const link = document.createElement('a')
  //       link.href = url
  //       link.setAttribute('download', 'daily_trial_monitoring_with_data.xlsx') // Change extension to .xlsx

  //       // Append the link to the document body and click it
  //       document.body.appendChild(link)
  //       link.click()

  //       // Clean up
  //       document.body.removeChild(link)
  //       window.URL.revokeObjectURL(url)
  //     }

  //     reader.readAsBinaryString(responseTemplate.data)
  //   } catch (error) {
  //     console.error('Error downloading template:', error.message)
  //     setError(error.message)
  //   } finally {
  //     setLoading(false)
  //   }
  // }

  const workbookToBlob = (workbook) => {
    return new Promise((resolve) => {
      // Write the workbook to a binary string
      const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' })

      // Convert the binary string to a Blob
      const blob = new Blob([s2ab(wbout)], {
        type: 'application/octet-stream',
      })
      resolve(blob)
    })
  }

  // Utility function to convert a string to an ArrayBuffer
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
    return buf
  }

  const capitalizeFirstLetter = (string) => {
    if (!string) return string
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const handleRejectEditTrial = () => {
    localStorage.setItem('Trial', 'edit')
    navigate('/viewdailytrial')
  }
  const handleCloseFilter = () => {
    setAnchorEl(null)
  }

  return (
    <>
      {isOnline ? (
        <div>
          {error && <div>{error}</div>}
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 20,
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div>
              <div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    width: '100%',
                    margin: 10,
                  }}
                >
                  <img
                    src={FilterIcon}
                    alt="Filter Icon"
                    style={{
                      width: '24px',
                      height: '24px',
                      marginRight: '20px',
                      cursor: 'pointer',
                    }}
                    onClick={handleFilterClick}
                  />
                  <Tooltip
                    title={`Number Of Offline Inspections:${offlineInspectionlength}`}
                  >
                    <IconButton style={{}}>
                      <WifiOffIcon />
                      <span
                        style={{
                          position: 'absolute',
                          top: -1,
                          right: -5,
                          background: 'red',
                          color: 'white',
                        }}
                      >
                        {offlineInspectionlength}
                      </span>
                    </IconButton>
                  </Tooltip>
                </div>
                {isFilterOpen ? (
                  <FilterPage
                    onClose={() => setIsFilterOpen(false)}
                    dailyTrials={dailyTrials}
                    applyFilter={handleApplyFilter}
                  />
                ) : (
                  <></>
                )}
                <div>
                  {filteredTrials && filteredTrials.length > 0 ? (
                    filteredTrials.map((trial, index) => {
                      const createdDate = new Date(trial.dataFile.created_at)
                      const daily_trial_id = trial.id
                      const datafile_id = trial.datafile_id
                      console.log(
                        'datafile_iddatafile_iddatafile_id',
                        datafile_id
                      )
                      const submission_status =
                        trial?.general_details?.[0]?.submission_status
                      console.log('submission_status', submission_status)
                      const capitalizedSubmissionStatus =
                        capitalizeFirstLetter(submission_status)
                      console.log(
                        'capitalizedSubmissionStatus',
                        capitalizedSubmissionStatus
                      )

                      const SubmisionBackgroundColor =
                        capitalizedSubmissionStatus === 'Submitted'
                          ? '#0086DC1A'
                          : capitalizedSubmissionStatus === 'Draft'
                            ? '#7F7D831A'
                            : capitalizedSubmissionStatus === 'Approved'
                              ? '#34A8531A'
                              : capitalizedSubmissionStatus === 'Rejected'
                                ? '#EA43351A'
                                : 'transparent'

                      const SubmisionColor =
                        capitalizedSubmissionStatus === 'Submitted'
                          ? '#0086DC'
                          : capitalizedSubmissionStatus === 'Draft'
                            ? '#7F7D83'
                            : capitalizedSubmissionStatus === 'Approved'
                              ? '#34A853'
                              : capitalizedSubmissionStatus === 'Rejected'
                                ? '#EA4335'
                                : 'transparent'

                      const shouldDisplayAwaitingApproval =
                        (currentUser.role.role_id === 3 ||
                          currentUser.role.role_id === 4) &&
                        capitalizedSubmissionStatus === 'Submitted'
                      const displaySubmissionStatus =
                        shouldDisplayAwaitingApproval
                          ? 'Awaiting Approval'
                          : capitalizedSubmissionStatus

                      const formattedDate = createdDate.toLocaleDateString(
                        'en-US',
                        {
                          day: 'numeric',
                          month: 'short',
                          year: 'numeric',
                        }
                      )

                      return (
                        <div key={index}>
                          <Card
                            sx={{
                              border: '1px solid #E6E6E6',
                              boxShadow: '0px 5px 13px -5px #0A090B0D',
                              width: '100%',
                              height: '180px',
                              marginBottom: '20px',
                            }}
                          >
                            <CardContent>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  height: '100%',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    gutterBottom
                                    sx={{
                                      fontFamily: 'Inter',
                                      fontSize: '16px',
                                      fontWeight: 600,
                                      lineHeight: '20px',
                                      letterSpacing: '-0.006em',
                                      textAlign: 'left',
                                      color: '#252C32',
                                      marginTop: '10px',
                                    }}
                                  >
                                    {`Daily Trial Monitoring_${trial.dataFile.customer.organization_name}`}
                                  </Typography>

                                  <IconButton
                                    aria-label="options"
                                    onClick={(event) =>
                                      handleMenuOpen(
                                        event,
                                        datafile_id,
                                        daily_trial_id,
                                        submission_status
                                      )
                                    }
                                  >
                                    <MoreVertIcon
                                      style={{ color: '#0086DC' }}
                                    />
                                  </IconButton>
                                </div>

                                <Typography
                                  variant="body1"
                                  gutterBottom
                                  sx={{
                                    fontFamily: 'Inter',
                                    fontSize: '14px',
                                    fontWeight: 700,
                                    lineHeight: '20px',
                                    letterSpacing: '-0.6%',
                                    textAlign: 'left',
                                    marginTop: '5px',
                                    color: '#252C32',
                                  }}
                                >
                                  {trial.dataFile.customer.organization_name}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  sx={{
                                    fontFamily: 'Inter',
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    lineHeight: '20px',
                                    letterSpacing: '-0.006em',
                                    textAlign: 'left',
                                    color: '#6E7C87',
                                  }}
                                >
                                  {trial.dataFile.segment.name !== null
                                    ? trial.dataFile.segment.name
                                    : 'N/A'}
                                </Typography>

                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  sx={{
                                    fontFamily: 'Inter',
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    lineHeight: '20px',
                                    letterSpacing: '-0.006em',
                                    textAlign: 'left',
                                    color: SubmisionColor,
                                  }}
                                >
                                  <span
                                    style={{
                                      backgroundColor: SubmisionBackgroundColor,
                                      padding: '0 8px',
                                      borderRadius: '4px',
                                    }}
                                  >
                                    {displaySubmissionStatus}
                                  </span>
                                </Typography>

                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: 'auto',
                                  }}
                                >
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    sx={{
                                      fontFamily: 'Inter',
                                      fontSize: '12px',
                                      fontWeight: 500,
                                      lineHeight: '20px',
                                      letterSpacing: '-0.006em',
                                      textAlign: 'left',
                                      color: '#6E7C87',
                                    }}
                                  >
                                    Created Date:
                                  </Typography>

                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    sx={{
                                      fontFamily: 'Inter',
                                      fontSize: '12px',
                                      fontWeight: 700,
                                      lineHeight: '20px',
                                      letterSpacing: '-0.006em',
                                      textAlign: 'left',
                                      color: '#252C32',
                                      marginLeft: '8px',
                                    }}
                                  >
                                    {formattedDate}
                                  </Typography>

                                  {/* Avatar component */}
                                  <Tooltip
                                    title={`${trial.dataFile.user.firstname} ${trial.dataFile.user.lastname}`}
                                    arrow
                                  >
                                    <Avatar
                                      style={{
                                        width: '30px',
                                        height: '30px',
                                        borderRadius: '12px',
                                        background: '#D7EDFF',
                                        color: '#0086DC',
                                        fontWeight: 700,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginLeft: 'auto',
                                        fontSize: '10px',
                                      }}
                                    >
                                      {trial.dataFile.user &&
                                        trial.dataFile.user.firstname &&
                                        trial.dataFile.user.lastname && (
                                          <Tooltip
                                            title={`${trial.dataFile.user.firstname} ${trial.dataFile.user.lastname}`}
                                            arrow
                                          >
                                            <Avatar
                                              style={{
                                                width: '30px',
                                                height: '30px',
                                                borderRadius: '12px',
                                                background: '#D7EDFF',
                                                color: '#0086DC',
                                                fontWeight: 700,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                marginLeft: 'auto',
                                                fontSize: '10px',
                                              }}
                                            >
                                              {trial.dataFile.user.firstname.charAt(
                                                0
                                              )}
                                              {trial.dataFile.user.lastname.charAt(
                                                0
                                              )}
                                            </Avatar>
                                          </Tooltip>
                                        )}
                                    </Avatar>
                                  </Tooltip>
                                </div>
                              </div>
                            </CardContent>
                          </Card>
                        </div>
                      )
                    })
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-around',
                        marginTop: 20,
                      }}
                    >
                      {dailyTrials.map((trial, index) => {
                        const createdDate = new Date(trial.created_at)
                        const daily_trial_id = trial.id
                        const datafile_id = trial.datafile_id
                        const submission_status =
                          trial?.general_details?.[0]?.submission_status ??
                          'test'
                        const capitalizedSubmissionStatus =
                          capitalizeFirstLetter(submission_status)
                        const shouldDisplayAwaitingApproval =
                          (currentUser.role.role_id === 3 ||
                            currentUser.role.role_id === 4) &&
                          capitalizedSubmissionStatus === 'Submitted'
                        const displaySubmissionStatus =
                          shouldDisplayAwaitingApproval
                            ? 'Awaiting Approval'
                            : capitalizedSubmissionStatus

                        const SubmisionBackgroundColor =
                          capitalizedSubmissionStatus === 'Submitted'
                            ? '#0086DC1A'
                            : capitalizedSubmissionStatus === 'Draft'
                              ? '#7F7D831A'
                              : capitalizedSubmissionStatus === 'Approved'
                                ? '#34A8531A'
                                : capitalizedSubmissionStatus === 'Rejected'
                                  ? '#EA43351A'
                                  : 'transparent'

                        const SubmisionColor =
                          capitalizedSubmissionStatus === 'Submitted'
                            ? '#0086DC'
                            : capitalizedSubmissionStatus === 'Draft'
                              ? '#7F7D83'
                              : capitalizedSubmissionStatus === 'Approved'
                                ? '#34A853'
                                : capitalizedSubmissionStatus === 'Rejected'
                                  ? '#EA4335'
                                  : 'transparent'

                        const formattedDate = createdDate.toLocaleDateString(
                          'en-US',
                          {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                          }
                        )

                        return (
                          <Card
                            key={index}
                            sx={{
                              border: '1px solid #E6E6E6',
                              boxShadow: '0px 5px 13px -5px #0A090B0D',
                              width: '100%',
                              height: '180px',
                              marginBottom: '20px',
                            }}
                          >
                            <CardContent>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  height: '100%',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    gutterBottom
                                    sx={{
                                      fontFamily: 'Inter',
                                      fontSize: '16px',
                                      fontWeight: 600,
                                      lineHeight: '20px',
                                      letterSpacing: '-0.006em',
                                      textAlign: 'left',
                                      color: '#252C32',
                                      marginTop: '10px',
                                    }}
                                  >
                                    {`Daily Trial Monitoring_${trial.dataFile.customer.organization_name}`}
                                  </Typography>

                                  <IconButton
                                    aria-label="options"
                                    onClick={(event) =>
                                      handleMenuOpen(
                                        event,
                                        datafile_id,
                                        daily_trial_id,
                                        submission_status
                                      )
                                    }
                                  >
                                    <MoreVertIcon
                                      style={{ color: '#0086DC' }}
                                    />
                                  </IconButton>
                                </div>

                                <Typography
                                  variant="body1"
                                  gutterBottom
                                  sx={{
                                    fontFamily: 'Inter',
                                    fontSize: '14px',
                                    fontWeight: 700,
                                    lineHeight: '20px',
                                    letterSpacing: '-0.6%',
                                    textAlign: 'left',
                                    marginTop: '5px',
                                    color: '#252C32',
                                  }}
                                >
                                  {trial.dataFile.customer.organization_name}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  sx={{
                                    fontFamily: 'Inter',
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    lineHeight: '20px',
                                    letterSpacing: '-0.006em',
                                    textAlign: 'left',
                                    color: '#6E7C87',
                                  }}
                                >
                                  {trial.dataFile.segment.name !== null
                                    ? trial.dataFile.segment.name
                                    : 'N/A'}
                                </Typography>

                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  sx={{
                                    fontFamily: 'Inter',
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    lineHeight: '20px',
                                    letterSpacing: '-0.006em',
                                    textAlign: 'left',
                                    color: SubmisionColor,
                                  }}
                                >
                                  <span
                                    style={{
                                      backgroundColor: SubmisionBackgroundColor,
                                      padding: '0 8px',
                                      borderRadius: '4px',
                                    }}
                                  >
                                    {displaySubmissionStatus}
                                  </span>
                                </Typography>

                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: 'auto',
                                  }}
                                >
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    sx={{
                                      fontFamily: 'Inter',
                                      fontSize: '12px',
                                      fontWeight: 500,
                                      lineHeight: '20px',
                                      letterSpacing: '-0.006em',
                                      textAlign: 'left',
                                      color: '#6E7C87',
                                    }}
                                  >
                                    Created Date:
                                  </Typography>

                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    sx={{
                                      fontFamily: 'Inter',
                                      fontSize: '12px',
                                      fontWeight: 700,
                                      lineHeight: '20px',
                                      letterSpacing: '-0.006em',
                                      textAlign: 'left',
                                      color: '#252C32',
                                      marginLeft: '8px',
                                    }}
                                  >
                                    {formattedDate}
                                  </Typography>

                                  {/* Avatar component */}
                                  <Tooltip
                                    title={`${trial.dataFile.user.firstname} ${trial.dataFile.user.lastname}`}
                                    arrow
                                  >
                                    <Avatar
                                      style={{
                                        width: '30px',
                                        height: '30px',
                                        borderRadius: '12px',
                                        background: '#D7EDFF',
                                        color: '#0086DC',
                                        fontWeight: 700,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginLeft: 'auto',
                                        fontSize: '10px',
                                      }}
                                    >
                                      {trial.dataFile.user &&
                                        trial.dataFile.user.firstname &&
                                        trial.dataFile.user.lastname && (
                                          <Tooltip
                                            title={`${trial.dataFile.user.firstname} ${trial.dataFile.user.lastname}`}
                                            arrow
                                          >
                                            <Avatar
                                              style={{
                                                width: '30px',
                                                height: '30px',
                                                borderRadius: '12px',
                                                background: '#D7EDFF',
                                                color: '#0086DC',
                                                fontWeight: 700,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                marginLeft: 'auto',
                                                fontSize: '10px',
                                              }}
                                            >
                                              {trial.dataFile.user.firstname.charAt(
                                                0
                                              )}
                                              {trial.dataFile.user.lastname.charAt(
                                                0
                                              )}
                                            </Avatar>
                                          </Tooltip>
                                        )}
                                    </Avatar>
                                  </Tooltip>
                                </div>
                              </div>
                            </CardContent>
                          </Card>
                        )
                      })}
                    </div>
                  )}
                </div>
              </div>
              {isPopupOpen && (
                <div
                  ref={popupRef}
                  style={{
                    position: 'absolute',
                    top: `${popupPosition.top}px`,
                    right: `${popupPosition.right}px`,
                    background: 'rgba(255, 255, 255, 1)',
                    padding: '20px',
                    borderRadius: '8px',
                    border: '1px solid #E8E8E8',
                    boxShadow: '0px 0px 35px 0px rgba(0, 0, 0, 0.1)',
                    zIndex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  <div
                    onClick={handleView}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      padding: '10px',
                      padding: '10px',
                    }}
                  >
                    <img
                      src={ViewIcon}
                      alt="Edit Icon"
                      style={{ marginRight: '10px' }}
                    />
                    <Typography
                      style={{
                        color: '#252C32',
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 800,
                        lineHeight: 'normal',
                        letterSpacing: '-0.084px',
                      }}
                    >
                      View Trial
                    </Typography>
                  </div>

                  {selectedSubmissionStatus === 'draft' && (
                    <div
                      onClick={handleAddTrial}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        padding: '10px',
                      }}
                    >
                      <img
                        src={AddIcon}
                        alt="Edit Icon"
                        style={{ marginRight: '10px' }}
                      />
                      <Typography
                        style={{
                          color: '#252C32',
                          fontFamily: 'Inter',
                          fontSize: '16px',
                          fontStyle: 'normal',
                          fontWeight: 800,
                          lineHeight: 'normal',
                          letterSpacing: '-0.084px',
                        }}
                      >
                        Add Trial
                      </Typography>
                    </div>
                  )}
                  {selectedSubmissionStatus === 'rejected' &&
                    (currentUser.role.role_id === 1 ||
                      currentUser.role.role_id === 2) && (
                      <div
                        onClick={handleRejectEditTrial}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                          padding: '10px',
                        }}
                      >
                        <img
                          src={AddIcon}
                          alt="Edit Icon"
                          style={{ marginRight: '10px' }}
                        />
                        <Typography
                          style={{
                            color: '#252C32',
                            fontFamily: 'Inter',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 800,
                            lineHeight: 'normal',
                            letterSpacing: '-0.084px',
                          }}
                        >
                          Edit Trial
                        </Typography>
                      </div>
                    )}

                  {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              padding: "10px",
            }}
          >
            <img
              src={ApprovalIcon}
              alt="Edit Icon"
              style={{ marginRight: "10px" }}
            />
            <Typography
              style={{
                color: "#252C32",
                fontFamily: "Inter",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 800,
                lineHeight: "normal",
                letterSpacing: "-0.084px",
              }}
            >
              Approval
            </Typography>
          </div> */}

                  {/* <div
            onClick={handleDownload}
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              padding: "10px",
            }}
          >
            <img
              src={DownloadIcon}
              alt="Edit Icon"
              style={{ marginRight: "10px" }}
            />
            <Typography
              style={{
                color: "#252C32",
                fontFamily: "Inter",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 800,
                lineHeight: "normal",
                letterSpacing: "-0.084px",
              }}
            >
              Download
            </Typography>
          </div> */}
                  {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              padding: "10px",
            }}
          >
            <img
              src={ApprovalIcon}
              alt="Edit Icon"
              style={{ marginRight: "10px" }}
            />
            <Typography
              style={{
                color: "#252C32",
                fontFamily: "Inter",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 800,
                lineHeight: "normal",
                letterSpacing: "-0.084px",
              }}
            >
              Rejected
            </Typography>
          </div> */}
                  {/* <div onClick={() => handleDelete()} style={{ display: "flex", alignItems: 'center', cursor: 'pointer', padding: "10px" }}>
            <img src={DeleteIcon} alt="Delete Icon" style={{ marginRight: '10px' }} />
            <Typography
              style={{
                color: '#FF3347',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 800,
                lineHeight: 'normal',
                letterSpacing: '-0.084px',
              }}>Delete</Typography>
          </div> */}
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div>
          {error && <div>{error}</div>}
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 20,
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'space-around',
                  marginTop: 20,
                }}
              >
                {dailyTrials.map((trial, index) => {
                  const createdDate = new Date(trial.created_at)
                  const daily_trial_id = trial.id
                  const datafile_id = trial.datafile_id
                  let submission_status = 'Unknown'

                  if (trial.general_details) {
                    // Check if there's a direct submission_status in general_details
                    if (trial.general_details.submission_status) {
                      submission_status =
                        trial.general_details.submission_status
                    } else if (trial.general_details[index]) {
                      submission_status =
                        trial.general_details[index].submission_status ||
                        'Unknown'
                    }
                  }

                  console.log('submission_status', submission_status)
                  const capitalizedSubmissionStatus =
                    capitalizeFirstLetter(submission_status)
                  const submissionBackgroundColor =
                    capitalizedSubmissionStatus === 'Submitted'
                      ? '#0086DC1A'
                      : capitalizedSubmissionStatus === 'Draft'
                        ? '#7F7D831A'
                        : 'transparent'
                  const submissionColor =
                    capitalizedSubmissionStatus === 'Submitted'
                      ? '#0086DC'
                      : capitalizedSubmissionStatus === 'Draft'
                        ? '#7F7D83'
                        : 'transparent'

                  const formattedDate = createdDate.toLocaleDateString(
                    'en-US',
                    {
                      day: 'numeric',
                      month: 'short',
                      year: 'numeric',
                    }
                  )

                  return (
                    <Card
                      key={index}
                      sx={{
                        border: '1px solid #E6E6E6',
                        boxShadow: '0px 5px 13px -5px #0A090B0D',
                        width: '100%',
                        height: '180px',
                        marginBottom: '20px',
                      }}
                    >
                      <CardContent>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Typography
                              variant="h6"
                              gutterBottom
                              sx={{
                                fontFamily: 'Inter',
                                fontSize: '16px',
                                fontWeight: 600,
                                lineHeight: '20px',
                                letterSpacing: '-0.006em',
                                textAlign: 'left',
                                color: '#252C32',
                                marginTop: '10px',
                              }}
                            >
                              {`Daily Trial Monitoring_${trial.customer?.organization_name}`}
                            </Typography>

                            <IconButton
                              aria-label="options"
                              onClick={(event) =>
                                handleMenuOpen(
                                  event,
                                  datafile_id,
                                  daily_trial_id,
                                  submission_status
                                )
                              }
                            >
                              <MoreVertIcon style={{ color: '#0086DC' }} />
                            </IconButton>
                          </div>

                          <Typography
                            variant="body1"
                            gutterBottom
                            sx={{
                              fontFamily: 'Inter',
                              fontSize: '14px',
                              fontWeight: 700,
                              lineHeight: '20px',
                              letterSpacing: '-0.6%',
                              textAlign: 'left',
                              marginTop: '5px',
                              color: '#252C32',
                            }}
                          >
                            {trial.customer?.organization_name}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              fontFamily: 'Inter',
                              fontSize: '12px',
                              fontWeight: 400,
                              lineHeight: '20px',
                              letterSpacing: '-0.006em',
                              textAlign: 'left',
                              color: '#6E7C87',
                            }}
                          >
                            {trial.segment?.name !== null
                              ? trial.segment?.name
                              : 'N/A'}
                          </Typography>

                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              fontFamily: 'Inter',
                              fontSize: '12px',
                              fontWeight: 400,
                              lineHeight: '20px',
                              letterSpacing: '-0.006em',
                              textAlign: 'left',
                              color: submissionColor,
                            }}
                          >
                            <span
                              style={{
                                backgroundColor: submissionBackgroundColor,
                                padding: '0 8px',
                                borderRadius: '4px',
                              }}
                            >
                              {capitalizedSubmissionStatus}
                            </span>
                          </Typography>

                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginTop: 'auto',
                            }}
                          >
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{
                                fontFamily: 'Inter',
                                fontSize: '12px',
                                fontWeight: 500,
                                lineHeight: '20px',
                                letterSpacing: '-0.006em',
                                textAlign: 'left',
                                color: '#6E7C87',
                              }}
                            >
                              Created Date:
                            </Typography>

                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{
                                fontFamily: 'Inter',
                                fontSize: '12px',
                                fontWeight: 700,
                                lineHeight: '20px',
                                letterSpacing: '-0.006em',
                                textAlign: 'left',
                                color: '#252C32',
                                marginLeft: '8px',
                              }}
                            >
                              {formattedDate}
                            </Typography>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  )
                })}
              </div>
              {isPopupOpen && (
                <div
                  style={{
                    position: 'absolute',
                    top: `${popupPosition.top}px`,
                    right: `${popupPosition.right}px`,
                    background: 'rgba(255, 255, 255, 1)',
                    padding: '20px',
                    borderRadius: '8px',
                    border: '1px solid #E8E8E8',
                    boxShadow: '0px 0px 35px 0px rgba(0, 0, 0, 0.1)',
                    zIndex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  <div
                    onClick={handleView}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      padding: '10px',
                    }}
                  >
                    <img
                      src={ViewIcon}
                      alt="View Icon"
                      style={{ marginRight: '10px' }}
                    />
                    <Typography
                      style={{
                        color: '#252C32',
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 800,
                        lineHeight: 'normal',
                        letterSpacing: '-0.084px',
                      }}
                    >
                      View Trial
                    </Typography>
                  </div>

                  {selectedSubmissionStatus === 'draft' && (
                    <div
                      onClick={handleAddTrial}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        padding: '10px',
                      }}
                    >
                      <img
                        src={AddIcon}
                        alt="Add Icon"
                        style={{ marginRight: '10px' }}
                      />
                      <Typography
                        style={{
                          color: '#252C32',
                          fontFamily: 'Inter',
                          fontSize: '16px',
                          fontStyle: 'normal',
                          fontWeight: 800,
                          lineHeight: 'normal',
                          letterSpacing: '-0.084px',
                        }}
                      >
                        Add Trial
                      </Typography>
                    </div>
                  )}

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      padding: '10px',
                    }}
                  >
                    <img
                      src={DownloadIcon}
                      alt="Download Icon"
                      style={{ marginRight: '10px' }}
                    />
                    <Typography
                      style={{
                        color: '#252C32',
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 800,
                        lineHeight: 'normal',
                        letterSpacing: '-0.084px',
                      }}
                    >
                      Download
                    </Typography>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default DailyTrialMonitoringHome
