import { atom } from 'recoil'
export const user = atom({
  key: 'user',
  default: {
    sgid: '',
    name: '',
    lastName: '',
    id: '',
    role: '',
    roleId: '',
    zone: '',
  },
})
export const menuList = atom({
  key: 'menuList',
  default: 'analytics',
})
export const drawerMode = atom({
  key: 'drawerMode',
  default: false,
})
export const activeStep = atom({
  key: 'activeStep',
  default: 0,
})

export const general_details = atom({
  key: 'general_details',
  default: {
    id: '',
    furnace_no: '',
    crucible_no: '',
    campaign_no: '',
    lining_patch_no: '',
    heat_no: '',
    date: '',
    customer_ref_no: '',
    grade: '',
    isdelete: false,
    submission_status: '',
    comments: '',
  },
})

export const furnace_details = atom({
  key: 'furnace_details',
  default: {
    id: '',
    heat_on_time: '',
    heat_tap_time: '',
    break_down_duration: '',
    heat_duration_actual: '',
    heat_duration_calculated: '',
    tapping_temperature: '',
    tapped_tonnage: '',
    melt_rate_based_on_actual_duration: '',
    melt_rate_based_on_calculated_duration: '',
    frequency: '',
    voltage: '',
    power_in_the_panel: '',
    capacitors_removed: '',
    total_power_consumed: '',
    energy_consumption_per_unit_ton_actual: '',
    energy_consumption_per_unit_ton_calculated: '',
  },
})

export const charge_mix_details = atom({
  key: 'charge_mix_details',
  default: {
    id: '',
    scrap: '',
    shredded: '',
    pig_iron: '',
    pooled_iron: '',
    hot_heel: '',
    lump: '',
    pellet: '',
    fines_lessthan_5mm: '',
    fines_lessthan_1mm: '',
    slag_crushers: '',
    tundish_or_slag_or_metalic_jam: '',
    total_charge: '',
  },
})

export const carbon = atom({
  key: 'carbon',
  default: {
    id: '',
    met_coke: '',
    fifty_c_percentage: '',
    eightyfive_c_percentage: '',
    final_c_percentage: '',
  },
})

export const alloying_addition = atom({
  key: 'alloying_addition',
  default: {
    id: '',
    si_before_alloy_addition: '',
    final_si: '',
    mn_before_alloy_addition: '',
    final_mn: '',
    cr_before_alloy_addition: '',
    final_cr: '',
    si_mn: '',
    mn_in_simn: '',
    feMn: '',
    feSi: '',
    feCr: '',
    mill_scale: '',
    any_other_alloy_addition: '',
  },
})

export const other_parameters = atom({
  key: 'other_parameters',
  default: {
    id: '',
    // yield: "", it is not there in the template
    inlet_water_temp: '',
    outlet_water_temp: '',
    water_pr: '',
    remarks: '',
  },
})

export const trial_name = atom({
  key: 'trial_name',
  default: 'new',
})

export const carbon_type = atom({
  key: 'carbon_type',
  default: 'CPC',
})

export const dataManagementTab = atom({
  key: 'dataManagementTab',
  default: 0,
})

// export const edit_trail = atom({
//   key: 'edit_trial',
//   default: "new"
// });

// nrm performance

export const lining_reference = atom({
  key: 'lining_reference',
  default: {
    id: '',
    furnace_no: '',
    crucible_no: '',
    campaign_no: '',
    lining_or_patching: '',
    date_of_completion_lining_or_patching: '',
    isdelete: false,
    submission_status: '',
  },
})

export const production_summary = atom({
  key: 'production_summary',
  default: {
    id: '',
    fce_prod_start_date: '',
    fce_prod_start_time: '',
    fce_prod_down_date: '',
    fce_prod_down_time: '',
    life: '',
    average_heat_size: '',
    total_furnace_running_hours: '',
    total_production: '',
    total_furnace_running_hours_calculated: '',
  },
})

export const lining_material_product_details = atom({
  key: 'lining_material_product_details',
  default: {
    id: '',
    bottom_product: '',
    sidewall_product_one: '',
    sidewall_product_two: '',
    top_locking_product: '',
    topping_mass_product: '',
    sidewall_one_coverage_upto_x: '',
    sidewall_two_coverage_upto_100: '',
  },
})

export const lining_material_consumption = atom({
  key: 'lining_material_consumption',
  default: {
    id: '',
    nrm_consumption_bottom: '',
    nrm_consumption_sidewall_one: '',
    nrm_consumption_sidewall_two: '',
    nrm_consumption_top_locking: '',
    topping_mass_consumption: '',
    total_nrm_consumption_sidewall_one: '',
    nrm_specific_consumption: '',
  },
})

export const future_requirements = atom({
  key: 'future_requirements',
  default: {
    id: '',
    Fe_percent_in_FeMn: '',
    nrm_consumption_sidewall_one: '',
    nrm_consumption_sidewall_two: '',
    nrm_consumption_top_locking: '',
    topping_mass_consumption: '',
    total_nrm_consumption_sidewall_one: '',
    nrm_specific_consumption: '',
  },
})

// data collection template

export const Product_Details = atom({
  key: 'Product_Details',
  default: {
    id: '',
    end_product: '',
    steel_produce: '',
    ratio: '',
  },
})

export const general_Details = atom({
  key: 'general_Details',
  default: {
    id: '',
    customer_name: '',
    contact_person: '',
    date: '',
    mobile_number: '',
  },
})

export const furnace_Details_data_collection = atom({
  key: 'furnace_Details_data_collection',
  default: {
    id: '',
    no_of_panels: '',
    fce_capacity: '',
    avg_heatsize: '',
    power_rating: '',
    fce_running: '',
    tap_time: '',
    sintering_panel: '',
    power_rating_sintering: '',
    no_of_heats_perday: '',
    avg_power_consumption: '',
    lrf: '',
    frequency_range: '',
    max_tapping_temp: '',
    avg_tapping_temp: '',
    furnace_voltage: '',
    furnace_oem: '',
    furnace_year: '',
    coil_grout: '',
    sintering_time: '',
    operation: '',
    change_overtime: '',
    checking_time: '',
  },
})

export const mild_steel_customer = atom({
  key: 'mild_steel_customer',
  default: {
    id: '',
    deslagging_by: '',
    using_magnet_or_not: '',
    avg_c_percent_at_50: '',
    avg_c_percent_at_80: '',
    final_c_percent_beforeTapping: '',
    data_mill_scale: '',
    tilt_count_for_deslagging: '',
    are_they_taking_liquid_metal: '',
    dri_quality: '',
    slag_generation_per_heat: '',
    power_utilised: '',
    silica_price: '',
    ccm: '',
    casting_time: '',
    tundish_cap: '',
    min_level_of_tundish: '',
    billet_size: '',
    no_of_sequence: '',
    ccm_make: '',
    ccm_preparation_time: '',
    purpose_of_using_nrm: '',
  },
})

export const raw_materials_energy_details = atom({
  key: 'raw_materials_energy_details',
  default: {
    id: '',
    type_of_scrap: '',
    charge_mix_ratio: '',
    yield_of_charge_mix: '',
    profit: '',
    unit_price_of_power: '',
  },
})

export const furnace_dimension = atom({
  key: 'furnace_dimension',
  default: {
    id: '',
    inner_dia_of_coil: '',
    inner_dia_after_coilcoat: '',
    former_outer_dia: '',
    furnace_height: '',
    bottom_taper_dia: '',
    bottom_outer_dia: '',
    former_ht: '',
    bottom_lining_ht: '',
    total_power_given_to_ms: '',
  },
})

export const lining_details = atom({
  key: 'lining_details',
  default: {
    id: '',
    consumption_for_new_lining: '',
    consumption_for_patching: '',
    new_lining_life: '',
    patching_lining_life: '',
    total_no_of_side_lining: '',
    present_lining_method: '',
  },
})

export const alloy_and_steel = atom({
  key: 'alloy_and_steel',
  default: {
    id: '',
    fecr_consumption: '',
    cr_percentage: '',
    final_cr_percentage: '',
    cr_recovery: '',
    alloy_addition: '',
    casting_method: '',
    simn_consumption: '',
    mn_percentage: '',
    mn_recovery: '',
    addition_of_simn: '',
    any_other_alloy_addition: '',
    final_chemistry_at_furnace: '',
    final_chemistry_at_billet: '',
    agreement: '',
  },
})

export const dimensional_parameters = atom({
  key: 'dimensional_parameters',
  default: {
    former_dimension: '',
    coil_coat_id: '',
    fce_ht: '',
    costable_top_ht: '',
    bottom_ht: '',
  },
})

export const sintering_parameters = atom({
  key: 'sintering_parameters',
  default: {
    total_sintering_time: '',
    holding_time: '',
    holding_temp: '',
    reason_for_down: '',
    IR_no: '',
    job_done_by: '',
    remarks: '',
  },
})

export const customer_details = atom({
  key: 'customer_details',
  default: {
    parameter: '',
    customer_name: '',
    country: '',
    currency: '',
  },
})

// cost sheet

const createFurnaceDetails = (key) => {
  return atom({
    key: key,
    default: {
      furnace_capacity: '',
      furnace_output: '',
    },
  })
}

export const cost_sheet_furnace = createFurnaceDetails('cost_sheet_furnace')
export const cost_sheet_furnace_nrm = createFurnaceDetails(
  'cost_sheet_furnace_nrm'
)

const createNewLiningDetails = (key) => {
  return atom({
    key: key,
    default: {
      ramming_material_new_lining_1: '',
      qty_new_lining_1: '',
      material_used_spout_and_top_2: '',
      qty_spout_and_top_2: '',
      material_used_spout_and_top_3: '',
      qty_spout_and_top_3: '',
      average_new_lining_life: '',
      no_of_new_lining: '',
      avg_heat_size: '',
    },
  })
}

export const new_lining_details = createNewLiningDetails('new_lining_details')
export const new_lining_details_nrm = createNewLiningDetails(
  'new_lining_details_nrm'
)

const createSideLiningDetails = (key) => {
  return atom({
    key: key,
    default: {
      ramming_material_side_lining_1: '',
      qty_side_lining_1: '',
      material_used_spout_and_top_2: '',
      qty_spout_and_top_2: '',
      material_used_top_spout_and_top_3: '',
      qty_spout_and_top_3: '',
      average_side_lining_life: '',
      no_of_side_lining: '',
      avg_heat_size: '',
    },
  })
}

export const side_lining_details = createSideLiningDetails(
  'side_lining_details'
)
export const side_lining_details_nrm = createSideLiningDetails(
  'side_lining_details_nrm'
)

const createPatchingDetails = (key) => {
  return atom({
    key: key,
    default: {
      ramming_material_patching_1: '',
      qty_patching_1: '',
      material_used_spout_and_top_2: '',
      qty_spout_and_top_2: '',
      material_used_spout_and_top_3: '',
      qty_spout_and_top_3: '',
      average_patching_lining_life: '',
      no_of_patching: '',
      avg_heat_size_per_patching: '',
    },
  })
}

export const patching_details = createPatchingDetails('patching_details')
export const patching_details_nrm = createPatchingDetails(
  'patching_details_nrm'
)

const createRepairingDetails = (key) => {
  return atom({
    key: key,
    default: {
      ramming_material_repairing_1: '',
      qty_repairing_1: '',
      material_used_spout_and_top_2: '',
      qty_spout_and_top_2: '',
      material_used_spout_and_top_3: '',
      qty_spout_and_top_3: '',
    },
  })
}

export const repairing_details = createPatchingDetails('repairing_details')
export const repairing_details_nrm = createRepairingDetails(
  'repairing_details_nrm'
)

export const createLaborCost = (key) => {
  return atom({
    key: key,
    default: {
      labor_cost_per_nl: '',
      labor_cost_campaign: '',
      labor_cost_per_steel_produced: '',
      savings_labor_cost: '',
    },
  })
}

export const labor_cost = createLaborCost('labor_cost')
export const labor_cost_nrm = createLaborCost('labor_cost_nrm')

const createFormerCost = (key) => {
  return atom({
    key: key,
    default: {
      former_cost_per_piece: '',
      former_cost_campaign: '',
      former_cost_per_steel_produced: '',
      savings_former_cost: '',
    },
  })
}

export const former_cost = createFormerCost('former_cost')
export const former_cost_nrm = createFormerCost('former_cost_nrm')

const createIncreasedProductivity = (key) => {
  return atom({
    key: key,
    default: {
      avg_heat_time: '',
      total_available_time: '',
      max_no_of_heats: '',
      no_of_sintering_heats: '',
      extra_time_sintering: '',
      total_down_time_sintering: '',
      avg_fce_checking_time: '',
      frequency_of_furnace: '',
      total_down_time_fce: '',
      total_down_time_change_over: '',
      total_available_for_production: '',
      actual_no_of_heats: '',
      actual_production: '',
      production_increase_due_to_nrm: '',
      percentage_production_increase: '',
      profit_margin: '',
      cost_benefit: '',
      saving_due_to_increased_productivity: '',
    },
  })
}

export const increased_productivity = createIncreasedProductivity(
  'increased_productivity'
)
export const increased_productivity_nrm = createIncreasedProductivity(
  'increased_productivity_nrm'
)

const createEnergy = (key) => {
  return atom({
    key: key,
    default: {
      cost_per_electricity_unit: '',
      power_savings_per_mt: '',
      savings_power_consumption: '',
    },
  })
}

export const savings_energy = createEnergy('savings_energy')
export const savings_energy_nrm = createEnergy('savings_energy_nrm')

const createYieldImprovement = (key) => {
  return atom({
    key: key,
    default: {
      yield_benefit: '',
      market_price_of_scrap: '',
      savings_due_to_yield_benefit: '',
    },
  })
}

export const yield_improvement = createYieldImprovement('yield_improvement')
export const yield_improvement_nrm = createYieldImprovement(
  'yield_improvement_nrm'
)

const createAlloy = (key) => {
  return atom({
    key: key,
    default: {
      simn_savings: '',
      fecr_savings: '',
      savings_due_to_other_alloy: '',
      savings_due_to_reduction: '',
    },
  })
}

export const savings_alloy = createAlloy('savings_alloy')
export const savings_alloy_nrm = createAlloy('savings_alloy_nrm')

const createTangibleBenefits = (key) => {
  return atom({
    key: key,
    default: {
      asbestos_sheet: '',
      coil_coat_for_repair: '',
      antenna_replacement: '',
      sodium_silicate: '',
      slag_dumping: '',
      safety_in_operations: '',
      other_tangible_benefits: '',
    },
  })
}

export const savings_tangible_benefit = createTangibleBenefits(
  'savings_tangible_benefit'
)
export const savings_tangible_benefit_nrm = createTangibleBenefits(
  'savings_tangible_benefit_nrm'
)

const createMetallurgicalBenefits = (key) => {
  return atom({
    key: key,
    default: {
      savings_metallurgical: '',
    },
  })
}

export const savings_metallurgical_benefit = createMetallurgicalBenefits(
  'savings_metallurgical_benefit'
)
export const savings_metallurgical_benefit_nrm = createMetallurgicalBenefits(
  'savings_metallurgical_benefit_nrm'
)

const createCostCalculation = (key) => {
  return atom({
    key: key,
    default: {
      ramming_material_landed_cost_1: '',
      topping_material_2: '',
      topping_material_3: '',
    },
  })
}

export const cost_calculation = createCostCalculation('cost_calculation')
export const cost_calculation_nrm = createCostCalculation(
  'cost_calculation_nrm'
)

const createSinteringHeats = (key) => {
  return atom({
    key: key,
    default: {
      charge_mix_regular_heat: '',
      yield_of_sintering_scrap: '',
      market_price_sintering_scrap: '',
      percentage_scrap_addition: '',
      excess_scrap_charged_per_sinteringHeat: '',
      yield_of_dri: '',
      market_price_of_dri_mt: '',
    },
  })
}

export const sintering_heats = createSinteringHeats('sintering_heats')
export const sintering_heats_nrm = createSinteringHeats('sintering_heats_nrm')

export const savingsArray = atom({
  key: 'savingsArray',
  default: [],
})

export const editSavingDetails = atom({
  key: 'editSavingDetails',
  default: {},
})

export const erosion_details = atom({
  key: 'erosion_details',
  default: {
    cus_name: '',
    date_of_lining: '',
    segment: '',
    furnace_no: '',
    crucible_no: '',
    lining_no: '',
    campaign_no: '',
    furnace_capacity: '',
    furnace_down: '',
    former_type: '',
    furnace_make: '',
  },
})

export const dimensional_details = atom({
  key: 'dimensional_details',
  default: {
    fce_height: '',
    castable_height: '',
    bottom_thickness: '',
    bottom_erosion: '',
    former_top_od: '',
    former_bottom_od: '',
    former_height: '',
    former_first_taper: '',
    former_taper_length: '',
  },
})

export const nrm_usage_details = atom({
  key: 'nrm_usage_details',
  default: {
    bottom_product: '',
    sidewall_1: '',
    sidewall_2: '',
    locking_product: '',
    spout_launder_product: '',
    conspt_bottom_product: '',
    conspt_sidewall_1: '',
    conspt_sidewall_2: '',
    conspt_locking_product: '',
    conspt_spout_launder_product: '',
  },
})

const createLiningSide = (key) => {
  return atom({
    key: key,
    default: {
      measurement_of_4_phase: '',
      black_sintered_layer_thickness: '',
      white_hard_sintered_layer_thickness: '',
      semi_centered_layer_thickness: '',
      excess_scrap_charged_per_sinteringHeat: '',
      powder_layer_thickness: '',
      chipping_height: '',
      breaking_height: '',
    },
  })
}

export const lining_side_left = createLiningSide('lining_side_left')
export const lining_side_right = createLiningSide('lining_side_right')
export const lining_side_spout = createLiningSide('lining_side_spout')
export const lining_side_back = createLiningSide('lining_side_back')

// before chipping
const createBeforeChipping = (key) => {
  return atom({
    key: key,
    default: {
      coil_coat_diameter: '',
      bc_spout_back_erosion: '',
      bc_left_right_erosion: '',
    },
  })
}

// Export atoms for different heights
export const height_bc_0 = createBeforeChipping('height_bc_0')
export const height_bc_10 = createBeforeChipping('height_bc_10')
export const height_bc_20 = createBeforeChipping('height_bc_20')
export const height_bc_30 = createBeforeChipping('height_bc_30')
export const height_bc_40 = createBeforeChipping('height_bc_40')
export const height_bc_50 = createBeforeChipping('height_bc_50')
export const height_bc_60 = createBeforeChipping('height_bc_60')
export const height_bc_70 = createBeforeChipping('height_bc_70')
export const height_bc_80 = createBeforeChipping('height_bc_80')
export const height_bc_90 = createBeforeChipping('height_bc_90')
export const height_bc_100 = createBeforeChipping('height_bc_100')

//after chipping

const createAfterChipping = (key) => {
  return atom({
    key: key,
    default: {
      coil_coat_diameter: '',
      ac_spout_back_erosion: '',
      ac_left_right_erosion: '',
    },
  })
}

// Export atoms for different heights
export const height_ac_0 = createAfterChipping('height_ac_0')
export const height_ac_10 = createAfterChipping('height_ac_10')
export const height_ac_20 = createAfterChipping('height_ac_20')
export const height_ac_30 = createAfterChipping('height_ac_30')
export const height_ac_40 = createAfterChipping('height_ac_40')
export const height_ac_50 = createAfterChipping('height_ac_50')
export const height_ac_60 = createAfterChipping('height_ac_60')
export const height_ac_70 = createAfterChipping('height_ac_70')
export const height_ac_80 = createAfterChipping('height_ac_80')
export const height_ac_90 = createAfterChipping('height_ac_90')
export const height_ac_100 = createAfterChipping('height_ac_100')
