import React, { useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import TextBox from '../../input/TextBox'
import Typography from '@mui/material/Typography'
import {
  height_ac_0,
  height_ac_10,
  height_ac_20,
  height_ac_30,
  height_ac_40,
  height_ac_50,
  height_ac_60,
  height_ac_70,
  height_ac_80,
  height_ac_90,
  height_ac_100,
  height_bc_0,
  height_bc_10,
  height_bc_20,
  height_bc_30,
  height_bc_40,
  height_bc_50,
  height_bc_60,
  height_bc_70,
  height_bc_80,
  height_bc_90,
  height_bc_100,
} from '../../../recoil/atoms'
import { trial_name } from '../../../recoil/atoms'
import {
  afterChipping_0,
  afterChipping_10,
  afterChipping_20,
  afterChipping_30,
  afterChipping_40,
  afterChipping_50,
  afterChipping_60,
  afterChipping_70,
  afterChipping_80,
  afterChipping_90,
  afterChipping_100,
} from '../../../recoil/selector'

const AfterChipping = () => {
  const [BC_height0, setBC_Height0] = useRecoilState(height_bc_0)
  const [BC_height10, setBC_Height10] = useRecoilState(height_bc_10)
  const [BC_height20, setBC_Height20] = useRecoilState(height_bc_20)
  const [BC_height30, setBC_Height30] = useRecoilState(height_bc_30)
  const [BC_height40, setBC_Height40] = useRecoilState(height_bc_40)
  const [BC_height50, setBC_Height50] = useRecoilState(height_bc_50)
  const [BC_height60, setBC_Height60] = useRecoilState(height_bc_60)
  const [BC_height70, setBC_Height70] = useRecoilState(height_bc_70)
  const [BC_height80, setBC_Height80] = useRecoilState(height_bc_80)
  const [BC_height90, setBC_Height90] = useRecoilState(height_bc_90)
  const [BC_height100, setBC_Height100] = useRecoilState(height_bc_100)

  const [height0, setHeight0] = useRecoilState(height_ac_0)
  const [height10, setHeight10] = useRecoilState(height_ac_10)
  const [height20, setHeight20] = useRecoilState(height_ac_20)
  const [height30, setHeight30] = useRecoilState(height_ac_30)
  const [height40, setHeight40] = useRecoilState(height_ac_40)
  const [height50, setHeight50] = useRecoilState(height_ac_50)
  const [height60, setHeight60] = useRecoilState(height_ac_60)
  const [height70, setHeight70] = useRecoilState(height_ac_70)
  const [height80, setHeight80] = useRecoilState(height_ac_80)
  const [height90, setHeight90] = useRecoilState(height_ac_90)
  const [height100, setHeight100] = useRecoilState(height_ac_100)

  // calculated fields
  const ac_lot_0 = useRecoilValue(afterChipping_0)
  const ac_lot_10 = useRecoilValue(afterChipping_10)
  const ac_lot_20 = useRecoilValue(afterChipping_20)
  const ac_lot_30 = useRecoilValue(afterChipping_30)
  const ac_lot_40 = useRecoilValue(afterChipping_40)
  const ac_lot_50 = useRecoilValue(afterChipping_50)
  const ac_lot_60 = useRecoilValue(afterChipping_60)
  const ac_lot_70 = useRecoilValue(afterChipping_70)
  const ac_lot_80 = useRecoilValue(afterChipping_80)
  const ac_lot_90 = useRecoilValue(afterChipping_90)
  const ac_lot_100 = useRecoilValue(afterChipping_100)

  const handleChange = (e, setState) => {
    const { name, value } = e.target
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const [trialName] = useRecoilState(trial_name)
  const isReadOnly = trialName === 'viewErosionProfile'

  useEffect(() => {
    const heights = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  
    const heightStateSetters = {
      0: setHeight0,
      10: setHeight10,
      20: setHeight20,
      30: setHeight30,
      40: setHeight40,
      50: setHeight50,
      60: setHeight60,
      70: setHeight70,
      80: setHeight80,
      90: setHeight90,
      100: setHeight100,
    };
  
    const heightStates = {
      0: BC_height0,
      10: BC_height10,
      20: BC_height20,
      30: BC_height30,
      40: BC_height40,
      50: BC_height50,
      60: BC_height60,
      70: BC_height70,
      80: BC_height80,
      90: BC_height90,
      100: BC_height100,
    };
  
    heights.forEach((height) => {
      heightStateSetters[height]((prevState) => ({
        ...prevState,
        coil_coat_diameter: heightStates[height].coil_coat_diameter,
      }));
    });
  }, [
    BC_height0,
    BC_height10,
    BC_height20,
    BC_height30,
    BC_height40,
    BC_height50,
    BC_height60,
    BC_height70,
    BC_height80,
    BC_height90,
    BC_height100,
    setHeight0,
    setHeight10,
    setHeight20,
    setHeight30,
    setHeight40,
    setHeight50,
    setHeight60,
    setHeight70,
    setHeight80,
    setHeight90,
    setHeight100,
  ]);
  

  const renderSection = (title, state, setState, acSpoutBack, acLeftRight) => (
    <div>
      <div style={{ marginTop: '10px' }}>
        <Typography
          variant="body1"
          style={{
            color: '#000000',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '20px',
            marginTop: '0px',
          }}
        >
          {title}
        </Typography>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: '15px',
        }}
      >
        <div style={{ flex: '1', marginRight: '15px' }}>
          <TextBox
            name="coil_coat_diameter"
            label="Coil Coat Diameter"
            type="text"
            value={state.coil_coat_diameter}
            onChange={(e) => handleChange(e, setState)}
            disabled={isReadOnly}
          />
        </div>
        <div style={{ flex: '1', marginRight: '15px' }}>
          <TextBox
            name="ac_spout_back_erosion"
            label="AC Spout - back erosion"
            type="text"
            value={state.ac_spout_back_erosion}
            onChange={(e) => handleChange(e, setState)}
            disabled={isReadOnly}
          />
        </div>
        <div style={{ flex: '1', marginRight: '15px' }}>
          <TextBox
            name="ac_left_right_erosion"
            label="AC Left - Right erosion"
            value={state.ac_left_right_erosion}
            onChange={(e) => handleChange(e, setState)}
            disabled={isReadOnly}
          />
        </div>
      </div>
      <div>
        <div style={{ marginTop: '10px' }}>
          <Typography
            variant="body1"
            style={{
              color: '#000000',
              fontFamily: 'Inter',
              fontWeight: 600,
              fontSize: '16px',
              lineHeight: '20px',
              marginTop: '0px',
            }}
          >
            Left over thickness (LOT) after chipping(AC)
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '10px',
          }}
        >
          <div style={{ flex: '1', marginRight: '15px' }}>
            <TextBox
              name="bc_spout_back_0"
              label="AC LOT: Spout - Back"
              type="text"
              value={acSpoutBack}
              disabled
            />
          </div>
          <div style={{ flex: '1', marginRight: '15px' }}>
            <TextBox
              name="bc_spout_back_0"
              label="AC LOT: Spout - Back"
              type="text"
              value={acLeftRight}
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div style={{ width: '100%' }}>
      {renderSection(
        'Erosion After Chipping - Height 0',
        height0,
        setHeight0,
        ac_lot_0.spout_back_0,
        ac_lot_0.left_right_0
      )}
      {renderSection(
        'Erosion After Chipping - Height 10',
        height10,
        setHeight10,
        ac_lot_10.spout_back_10,
        ac_lot_10.left_right_10
      )}
      {renderSection(
        'Erosion After Chipping - Height 20',
        height20,
        setHeight20,
        ac_lot_20.spout_back_20,
        ac_lot_20.left_right_20
      )}
      {renderSection(
        'Erosion After Chipping - Height 30',
        height30,
        setHeight30,
        ac_lot_30.spout_back_30,
        ac_lot_30.left_right_30
      )}
      {renderSection(
        'Erosion After Chipping - Height 40',
        height40,
        setHeight40,
        ac_lot_40.spout_back_40,
        ac_lot_40.left_right_40
      )}
      {renderSection(
        'Erosion After Chipping - Height 50',
        height50,
        setHeight50,
        ac_lot_50.spout_back_50,
        ac_lot_50.left_right_50
      )}
      {renderSection(
        'Erosion After Chipping - Height 60',
        height60,
        setHeight60,
        ac_lot_60.spout_back_60,
        ac_lot_60.left_right_60
      )}
      {renderSection(
        'Erosion After Chipping - Height 70',
        height70,
        setHeight70,
        ac_lot_70.spout_back_70,
        ac_lot_70.left_right_70
      )}
      {renderSection(
        'Erosion After Chipping - Height 80',
        height80,
        setHeight80,
        ac_lot_80.spout_back_80,
        ac_lot_80.left_right_80
      )}
      {renderSection(
        'Erosion After Chipping - Height 90',
        height90,
        setHeight90,
        ac_lot_90.spout_back_90,
        ac_lot_90.left_right_90
      )}
      {renderSection(
        'Erosion After Chipping - Height 100',
        height100,
        setHeight100,
        ac_lot_100.spout_back_100,
        ac_lot_100.left_right_100
      )}
    </div>
  )
}

export default AfterChipping
