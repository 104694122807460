import React, { useState, useEffect } from 'react'
import SelectBox from '../../input/SelectBox'
import TextBox from '../../input/TextBox'
import { useRecoilState } from 'recoil'
import { carbon } from '../../../recoil/atoms'
import { dimensional_parameters, lining_reference } from '../../../recoil/atoms'

const DimensionalParameters = () => {
  const [dimensionalParameters, setDimensonalParameters] = useRecoilState(
    dimensional_parameters
  )
  const [liningReference] = useRecoilState(lining_reference)
  const uniqueId = liningReference.id ? liningReference.id : ''

  const [isOnline, setIsOnline] = useState(window.navigator.onLine)

  const [db, setDb] = useState(null)

  useEffect(() => {
    const handleOnline = () => {
      if (!isOnline) {
        setIsOnline(true)
        localStorage.setItem('onlinestate', 1)

        window.location.reload()
      }
    }

    const handleOffline = () => {
      localStorage.setItem('onlinestate', 0)
      // setIsOnlines(false)
      setIsOnline(false)
      // navigate('/reports')
    }

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [isOnline])

  const dbName = 'pcr'
  const customerName = 'customers'
  const furnacesName = 'furnaces'
  const templateName = 'template'
  const segmentName = 'segment'
  const datafileName = 'datafile'
  const dailytrialName = 'daily_trial'
  const nrmName = 'nrm_performance'
  const erosionName = 'erosion_profile'
  const dataCollectionName = 'data_collection'
  const costSheetName = 'cost_sheet'
  const LiningName = 'lining_patching_no'

  useEffect(() => {
    if (!isOnline) {
      const request = indexedDB.open(dbName, 1)

      request.onupgradeneeded = (event) => {
        const database = event.target.result
        database.createObjectStore(customerName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(furnacesName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(templateName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(segmentName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(datafileName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(dailytrialName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(nrmName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(erosionName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(dataCollectionName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(costSheetName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(LiningName, {
          keyPath: 'id',
          autoIncrement: true,
        })
      }

      request.onsuccess = (event) => {
        const database = event.target.result
        const transaction = database.transaction(
          [
            'customers',
            'furnaces',
            'template',
            'segment',
            'datafile',
            'daily_trial',
            'nrm_performance',
            'erosion_profile',
            'data_collection',
            'cost_sheet',
            'lining_patching_no',
          ],
          'readwrite'
        )

        setDb(database)
      }

      request.onerror = (event) => {
        console.error('Error opening IndexedDB', event)
      }
    }
  }, [isOnline])

  const handleChange = (e) => {
    const { name, value } = e.target
    const TrialName = localStorage.getItem('Trial')
    if (TrialName === 'new') {
      setDimensonalParameters((prevState) => ({
        ...prevState,
        [name]: value,
        id: uniqueId,
      }))
    } else {
      setDimensonalParameters((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  return (
    <div>
      <div style={{ flex: 1, marginTop: '15px' }}>
        <TextBox
          name="former_dimension"
          label="Former Dimension (in * in * in)"
          type="text"
          value={dimensionalParameters.former_dimension}
          onChange={handleChange}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="coil_coat_id"
          label="Coil coat ID (Inch)"
          type="text"
          value={dimensionalParameters.coil_coat_id}
          onChange={handleChange}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="fce_ht"
          label="Fce Ht. (Inch)"
          type="text"
          value={dimensionalParameters.fce_ht}
          onChange={handleChange}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="costable_top_ht"
          label="Castable Top Ht. (Inch)"
          type="text"
          value={dimensionalParameters.costable_top_ht}
          onChange={handleChange}
        />
      </div>
      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="bottom_ht"
          label="Bottom Ht. (Inch)"
          type="text"
          value={dimensionalParameters.bottom_ht}
          onChange={handleChange}
        />
      </div>
    </div>
  )
}

export default DimensionalParameters
