import React, { useState, useEffect } from 'react'
import TextBox from '../../input/TextBox'
import StyledButton from '../../input/StyledButton'
import SelectBox from '../../input/SelectBox'
import { Typography } from '@mui/material'
import AutoSelectBox from '../../input/AutoSelectBox'
import Helper from '../../services/Helper'
import { CompareArrows } from '@mui/icons-material'
import API from '../../services/API'
import axios from 'axios'
import { lining_reference } from '../../../recoil/atoms'
import { useRecoilState, useRecoilValue } from 'recoil'
import { v4 as uuidv4 } from 'uuid'
import CustomAutocomplete from '../../input/CustomAutocomplete'

const LiningReference = () => {
  const [compaignNo, setCompaignNo] = useState([])
  const [liningPatchingNo, setLiningPatchingNo] = useState([])
  const [liningReference, setLiningReference] = useRecoilState(lining_reference)
  const [furnaces, setFurnaces] = useState([])
  const dataFile = localStorage.getItem('dataFileResponse')
  const dataFileResponse = JSON.parse(dataFile)
  const access_token = localStorage.getItem('accesstoken')

  const [isOnline, setIsOnline] = useState(window.navigator.onLine)

  const [db, setDb] = useState(null)
  function getAbbreviatedLiningType(liningType) {
    if (!liningType) return ''
    let abbreviated = ''
    liningType.split(' ').forEach((word) => {
      abbreviated += word.charAt(0).toUpperCase()
    })
    return abbreviated
  }

  const lorp_id =
    (liningReference.furnace_no ? liningReference.furnace_no : '') +
    '-' +
    (liningReference.crucible_no ? liningReference.crucible_no : '') +
    '-' +
    (liningReference.campaign_no ? liningReference.campaign_no : '') +
    '-' +
    getAbbreviatedLiningType(liningReference.lining_or_patching)

  console.log(lorp_id, 'lorpjds')

  useEffect(() => {
    const handleOnline = () => {
      if (!isOnline) {
        setIsOnline(true)
        localStorage.setItem('onlinestate', 1)

        window.location.reload()
      }
    }

    const handleOffline = () => {
      localStorage.setItem('onlinestate', 0)
      // setIsOnlines(false)
      setIsOnline(false)
      // navigate('/reports')
    }

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [isOnline])

  const dbName = 'pcr'
  const customerName = 'customers'
  const furnacesName = 'furnaces'
  const templateName = 'template'
  const segmentName = 'segment'
  const datafileName = 'datafile'
  const dailytrialName = 'daily_trial'
  const nrmName = 'nrm_performance'
  const erosionName = 'erosion_profile'
  const dataCollectionName = 'data_collection'
  const costSheetName = 'cost_sheet'
  const LiningName = 'lining_patching_no'

  useEffect(() => {
    if (!isOnline) {
      const request = indexedDB.open(dbName, 1)

      request.onupgradeneeded = (event) => {
        const database = event.target.result
        database.createObjectStore(customerName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(furnacesName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(templateName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(segmentName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(datafileName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(dailytrialName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(nrmName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(erosionName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(dataCollectionName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(costSheetName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(LiningName, {
          keyPath: 'id',
          autoIncrement: true,
        })
      }

      request.onsuccess = (event) => {
        const database = event.target.result
        const transaction = database.transaction(
          [
            'customers',
            'furnaces',
            'template',
            'segment',
            'datafile',
            'daily_trial',
            'nrm_performance',
            'erosion_profile',
            'data_collection',
            'cost_sheet',
            'lining_patching_no',
          ],
          'readwrite'
        )

        setDb(database)
      }

      request.onerror = (event) => {
        console.error('Error opening IndexedDB', event)
      }
    }
  }, [isOnline])

  useEffect(() => {
    if (db) {
      fetchFurnaceData()
      fetchData()
      fetchLiningData()
    }
  }, [db])

  useEffect(() => {
    if (isOnline) {
      fetchFurnaceData()
      fetchData()
      fetchLiningData()
    }
  }, [isOnline])

  useEffect(() => {
    fetchFurnaceData()
  }, [])

  // const fetchFurnaceData = async () => {
  //   try {
  //     const customer_id = dataFileResponse.data.customer_id;
  //     console.log("customer_id",customer_id);

  //     const response = await axios.get(API.GET_FURNACES);
  //     console.log("furnace response", response);
  //     const matchingFurnaces = response.data.filter(furnace => furnace.assign_customer_id === customer_id);

  //     if (matchingFurnaces) {
  //       console.log("Matching Furnace:", matchingFurnaces);
  //       const matchingFurnaceArr = matchingFurnaces
  //       setFurnaces(matchingFurnaceArr);
  //       // Do something with the matching furnace object
  //     } else {
  //       setFurnaces([]);
  //       console.log("No matching furnace found");
  //     }
  //     //   setFurnaces(response.data);
  //   } catch (error) {
  //     console.error('Error fetching users:', error);
  //   }
  // };

  const fetchFurnaceData = async () => {
    if (isOnline) {
      try {
        console.log('dataFileResponse', dataFileResponse)
        if (
          dataFileResponse &&
          dataFileResponse.data &&
          dataFileResponse.data.furnace
        ) {
          const customer_id = dataFileResponse.data.customer_id
          console.log('customer_id', customer_id)
          const matchingFurnace = [dataFileResponse.data.furnace]
          console.log('matchingFurnace', matchingFurnace)
          setFurnaces(matchingFurnace)
          // setLiningReference((prevState) => ({
          //   ...prevState,
          //   furnace_no: matchingFurnace[0]?.lining_reference || '',
          // }))
        } else {
          console.error('Error: No furnace data found in dataFileResponse')
        }
      } catch (error) {
        console.error('Error fetching furnaces:', error)
      }
    } else {
      console.log('db', db)
      const transaction1 = db.transaction(datafileName, 'readonly')
      const objectStore1 = transaction1.objectStore(datafileName)
      const datafile_id = localStorage.getItem('datafile_id')
      console.log('datafile_id', typeof datafile_id)
      const datafile_id_int = parseInt(datafile_id, 10)
      const getAllRequest1 = objectStore1.get(datafile_id_int)
      getAllRequest1.onsuccess = () => {
        const allData = getAllRequest1.result
        console.log('allData123', allData)
        // console.log('allData', allData.customer_id);
        const customer_id = allData.customer_id

        const transaction = db.transaction(furnacesName, 'readonly')
        const objectStore = transaction.objectStore(furnacesName)
        const getAllRequest = objectStore.getAll()
        getAllRequest.onsuccess = () => {
          const allData = getAllRequest.result
          const matchingFurnaces = allData.filter(
            (furnace) => furnace.assign_customer_id === customer_id
          )
          setFurnaces(matchingFurnaces)
          setLiningReference((prevState) => ({
            ...prevState,
            furnace_no: matchingFurnaces[0]?.lining_reference || '', // Default to empty string or handle appropriately
          }))
        }

        getAllRequest.onerror = (event) => {
          setFurnaces([])
          console.error(
            'Error getting data from the area table in IndexedDB',
            event
          )
        }
      }
    }
  }

  const handleChange = (name, value) => {
    console.log(name, value, "123")
    const TrialName = localStorage.getItem('Trial')
    if (TrialName === 'new' || TrialName === 'add') {
      const uniqueId = uuidv4()
      setLiningReference((prevState) => ({
        ...prevState,
        [name]: value,
        id: uniqueId,
        isdelete: false,
        lorp_id: lorp_id,
      }))
    } else {
      setLiningReference((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  useEffect(() => {
    fetchData()
    fetchLiningData()
  }, [])

  const fetchData = async () => {
    try {
      const campaignNumbers = await Helper.getCompaignNumbers()
      console.log('campaignNumbers', campaignNumbers)
      setCompaignNo(campaignNumbers)
    } catch (error) {
      console.error('Error fetching campaign numbers:', error)
    }
  }

  const fetchLiningData = async () => {
    if (isOnline) {
      try {
        const response = await axios.get(API.LINING_PATCHING_NO, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        console.log('RESSSS', response)
        setLiningPatchingNo(response.data)
      } catch (error) {
        console.error('Error fetching lining:', error)
      }
    } else {
      const transaction = db.transaction(LiningName, 'readonly')
      const objectStore = transaction.objectStore(LiningName)
      const getAllRequest = objectStore.getAll()
      getAllRequest.onsuccess = () => {
        const allData = getAllRequest.result
        console.log('allDataLiningPatchingNo', allData)
        setLiningPatchingNo(allData)
      }

      getAllRequest.onerror = (event) => {
        setLiningPatchingNo([])
        console.error(
          'Error getting data from the area table in IndexedDB',
          event
        )
      }
    }
  }

  const handleAutocompleteChange = (name, value) => {
    setLiningReference((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  return (
    <div style={{ width: '100%' }}>
      <div style={{ marginTop: '15px', marginRight: '15px' }}>
        <CustomAutocomplete
          name="furnace_no"
          isLabel={true}
          labelValue="Furnace No."
          options={furnaces.map((option) => option.furnace_number)}
          value={liningReference.furnace_no}
          onChange={(event, value) => handleAutocompleteChange('furnace_no', value)}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <CustomAutocomplete
          name="crucible_no"
          labelValue="Crucible No."
          isLabel={true}
          value={liningReference.crucible_no}
          options={Helper.CrucibleNo.map((option) => option.name)}
          onChange={(event, value) => handleChange('crucible_no', value)}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <CustomAutocomplete
          name="campaign_no"
          isLabel={true}
          labelValue="Campaign No"
          options={compaignNo.map((option) => option.name)}
          value={liningReference.campaign_no}
          onChange={(event, value) => handleChange('campaign_no', value)}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <CustomAutocomplete
          name="lining_or_patching"
          isLabel={true}
          labelValue="Lining/Patching No."
          options={liningPatchingNo.map((option) => option.name)}
          value={liningReference.lining_or_patching}
          onChange={(event, value) => handleChange('lining_or_patching', value)}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          label="Date of Complettion lining or Patching"
          name="date_of_completion_lining_or_patching"
          type="date"
          value={liningReference.date_of_completion_lining_or_patching ? liningReference.date_of_completion_lining_or_patching : ""}
          onChange={(event, value) => handleChange('date_of_completion_lining_or_patching', event.target.value)
          }
          showCalendarIcon={true}
        />
      </div>
    </div>
  )
}
export default LiningReference
