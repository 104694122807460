import React, { useState, useEffect } from 'react'
import { Typography } from '@mui/material'
import StatusImage from '../../../assets/icons/ic_status.svg'
import StyledButton from '../../input/StyledButton'
import { useNavigate } from 'react-router-dom'

const DraftStatus = () => {
  const navigate = useNavigate()
  const dataFile = localStorage.getItem('dataFileResponse')
  const dataFileResponse = JSON.parse(dataFile)
  console.log('dataFileResponse', dataFileResponse)
  const [isOnline, setIsOnline] = useState(window.navigator.onLine)

  const [db, setDb] = useState(null)

  useEffect(() => {
    const handleOnline = () => {
      if (!isOnline) {
        setIsOnline(true)
        localStorage.setItem('onlinestate', 1)

        window.location.reload()
      }
    }

    const handleOffline = () => {
      localStorage.setItem('onlinestate', 0)
      // setIsOnlines(false)
      setIsOnline(false)
      // navigate('/reports')
    }

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [isOnline])

  const handleDataManagement = () => {
    navigate('/datamanagement')
  }

  const handleAddRow = () => {
    localStorage.setItem('Trial', 'add')
    const templateId = dataFileResponse.data.template.template_id
    if (templateId === 1) {
      navigate('/dailytrialmonitoringM')
    } else if (templateId === 2) {
      navigate('/nrmperformance')
    } else if (templateId === 4) {
      navigate('/HorizontalDataCollectionStepper')
    } else if (templateId === 5) {
      navigate('/costsheet')
    }
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <img src={StatusImage} alt="Status" style={{ marginBottom: 20 }} />
      <Typography
        style={{
          fontFamily: 'Inter',
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '28px',
        }}
      >
        Data File is saved as Draft
      </Typography>
      <div style={{ marginTop: 40, display: 'flex', gap: 20 }}>
        {isOnline &&
        dataFileResponse &&
        dataFileResponse.data &&
        (dataFileResponse.data.template.template_id === 1 ||
          dataFileResponse.data.template.template_id === 2) ? (
          <StyledButton
            variant="outlined_blue"
            color="primary"
            text="Add Row"
            onClick={handleAddRow}
            style={{ marginLeft: 20 }} // Adding margin to the second button
          />
        ) : (
          <></>
        )}

        <StyledButton
          variant="contained"
          color="primary"
          text="Go to Data Management"
          onClick={handleDataManagement}
        />
      </div>
    </div>
  )
}
export default DraftStatus
