import React, { useState, useEffect } from 'react'
import {
  Typography,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
} from '@mui/material'
import StyledButton from '../../input/StyledButton'
import GeneralDetails from './GeneralDetails'
import AlloyAndSteel from './AlloyAndStainlessSteelCustomer'
import FurnaceDetails from './FurnaceDetails'
import FurnaceDimension from './FurnaceDimension'
import LiningDetails from './LiningDetails'
import MildSteelCustomer from './MildSteelCustomer'
import ProductDetails from './ProductDetails'
import RawMaterialsAndEnergy from './RawMaterial&EnergyDetails'

import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import furnaceImage from '../../../assets/picture/furnace.png'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

import {
  trial_name,
  general_Details,
  furnace_dimension,
  raw_materials_energy_details,
  mild_steel_customer,
  furnace_Details_data_collection,
  Product_Details,
  lining_details,
  alloy_and_steel,
} from '../../../recoil/atoms'
import { useRecoilState, useRecoilValue } from 'recoil'
import axios from 'axios'
import API from '../../services/API'
import { useNavigate } from 'react-router-dom'
import AlertDialog from '../../input/AlertDialog'
import { Snackbar } from '@mui/material'
import { Alert } from '@mui/material'

const steps = [
  'General Details',
  'Product Details',
  'Furnace Details',
  'Mild Steel Customers Only',
  'Raw Material & Energy Details',
  'Furnace Dimension',
  'Lining Details',
  'Alloy and Stainless Steel Customers',
]

function HorizontalDataCollectionStepper() {
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState(0)
  const [generalDetails, setGeneralDetails] = useRecoilState(general_Details)
  const [productDetails, setProductDetails] = useRecoilState(Product_Details)
  const [furnaceDetails, setFurnaceDetails] = useRecoilState(
    furnace_Details_data_collection
  )
  const [mildSteelCustomer, setMildSteelCustomer] =
    useRecoilState(mild_steel_customer)
  const [rawAndEnergyDetails, setRawAndEnergyDetails] = useRecoilState(
    raw_materials_energy_details
  )
  const [furnaceDimension, setFurnaceDimension] =
    useRecoilState(furnace_dimension)
  const [liningDetails, setLiningDetails] = useRecoilState(lining_details)
  const [alloyAndSteel, setAlloyAndSteel] = useRecoilState(alloy_and_steel)
  const [comments, setComments] = useState('')
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const currentUser = JSON.parse(localStorage.getItem('user'))
  // const submissionStatus = localStorage.getItem("data_collection_submission_status");
  // console.log("submissionStatussubmissionStatussubmissionStatus",submissionStatus);
  const access_token = localStorage.getItem('accesstoken')

  const [trialName] = useRecoilState(trial_name)
  console.log(trialName, 'trialName')
  const [open, setOpen] = useState(false)

  console.log('productDetails', productDetails)
  console.log('generalDetails', generalDetails)
  console.log('furnaceDetails123', furnaceDetails)
  console.log('mildSteelCustomer', mildSteelCustomer)
  console.log('rawAndEnergyDetails', rawAndEnergyDetails)
  console.log('furnaceDimension', furnaceDimension)
  console.log('liningDetails', liningDetails)
  console.log('alloyAndSteel', alloyAndSteel)

  // const handleNext = () => {
  //   console.log("activeStep", activeStep);
  //   if (activeStep === 7) {
  //     submitFormData();
  //   } else {
  //     // If it's not the last step, proceed to the next step
  //     setActiveStep(prevActiveStep => prevActiveStep + 1);
  //   }
  // };

  const handleNext = async () => {
    if (activeStep === 7) {
      if (trialName === 'DataCollectionedit') {
        await updateFormData()
      } else {
        await submitFormData()
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const submitFormData = async () => {
    try {
      AlertDialog({
        type: 'delete',
        title: 'Submission',
        text: 'Are you sure you want to Submit for an Approval?',
        confirmButtonText: 'Yes',
        onConfirm: async () => {
          await insertData()
        },
      })

    } catch (error) {
      console.error('An error occurred while inserting data:', error)
    }
  }

  const insertData = async () => {
    const uploadId = localStorage.getItem('datafile_id')
    let submissionStatus = ''
    if (currentUser.role.role_id === 3 || currentUser.role.role_id === 4) {
      submissionStatus = 'approved'
    } else {
      submissionStatus = 'submitted'
    }
    console.log('uploadIduploadId', uploadId)
    const data = {
      datafile_id: uploadId,
      submission_status: submissionStatus,
      data: {
        general_details: generalDetails,
        product_details: productDetails,
        furnace_details: furnaceDetails,
        mild_steel_customers_only: mildSteelCustomer,
        raw_material_energy_details: rawAndEnergyDetails,
        furnace_dimension: furnaceDimension,
        lining_details: liningDetails,
        alloy_stainless_steel_customers: alloyAndSteel,
      },
    }

    console.log(data, 'data123')

    // Make the POST request using axios
    const response = await axios.post(API.INSERT_DATA_COLLECTION, data, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })

    // Handle the response if needed
    console.log('Data inserted successfully', response.data)

    navigate('/submittedstatus')
  }

  const updateFormData = async () => {
    try {
      AlertDialog({
        type: 'delete',
        title: 'Submission',
        text: 'Are you sure you want to Update for an Approval?',
        confirmButtonText: 'Yes',
        onConfirm: async () => {
          await updateData()
        },
      })
    } catch (error) {
      // Handle errors
      console.error('An error occurred while inserting data:', error)
    }
  }

  const updateData = async () => {
    try {
      const id = localStorage.getItem('dataCollectionID')

      const data = {
        id: id,
        submission_status: 'submitted',
        updated_data: {
          general_details: generalDetails,
          product_details: productDetails,
          furnace_details: furnaceDetails,
          mild_steel_customers_only: mildSteelCustomer,
          raw_material_energy_details: rawAndEnergyDetails,
          furnace_dimension: furnaceDimension,
          lining_details: liningDetails,
          alloy_stainless_steel_customers: alloyAndSteel,
        },
      }

      console.log(data, 'update data')
      const response = await axios.put(API.UPDATE_DATA_COLLECTION, data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      console.log('Data updated successfully:', response.data)

      // Navigate after successful update
      navigate('/submittedstatus')
    } catch (error) {
      // Handle errors
      console.error('An error occurred while updating data:', error)
    }
  }
  useEffect(() => {
    if (
      trialName === 'DataCollectionedit' ||
      trialName === 'viewDataCollection'
    ) {
      const fetchData = async () => {
        try {
          const id = localStorage.getItem('dataCollectionID')
          console.log(id, '123445')
          const response = await axios.get(
            `${API.GET_DATA_COLLECTION_BY_ID}/${id}`,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          )
          console.log(response.data, 'dataCollectionID')
          const dataCollectionData = response.data
          console.log(dataCollectionData, 'dataCollectionData')
          console.log(
            dataCollectionData.general_details,
            'dataCollectionData.general_details[0]'
          )

          if (dataCollectionData && Object.keys(dataCollectionData)) {
            setGeneralDetails(dataCollectionData.general_details)
            setProductDetails(dataCollectionData.product_details)
            setFurnaceDetails(dataCollectionData.furnace_details)
            setMildSteelCustomer(dataCollectionData.mild_steel_customers_only)
            setRawAndEnergyDetails(
              dataCollectionData.raw_material_energy_details
            )
            setFurnaceDimension(dataCollectionData.furnace_dimension)
            setLiningDetails(dataCollectionData.lining_details)
            setAlloyAndSteel(dataCollectionData.alloy_stainless_steel_customers)
          }
        } catch (error) {
          console.error('Error fetching data:', error)
        }
      }

      fetchData()
    }
  }, [trialName])

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep > 0) {
        return prevActiveStep - 1
      }
      return prevActiveStep
    })
  }

  const handleDraft = async () => {
    if (trialName === 'DataCollectionedit') {
      try {
        const id = localStorage.getItem('dataCollectionID')

        const data = {
          id: id,
          submission_status: 'draft',
          updated_data: {
            general_details: generalDetails,
            product_details: productDetails,
            furnace_details: furnaceDetails,
            mild_steel_customers_only: mildSteelCustomer,
            raw_material_energy_details: rawAndEnergyDetails,
            furnace_dimension: furnaceDimension,
            lining_details: liningDetails,
            alloy_stainless_steel_customers: alloyAndSteel,
          },
        }

        console.log(data, 'update data')
        const response = await axios.put(API.UPDATE_DATA_COLLECTION, data, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        console.log('Data updated successfully:', response.data)

        // Navigate after successful update
        navigate('/draftstatus')
      } catch (error) {
        // Handle errors
        console.error('An error occurred while updating data:', error)
      }
    } else {
      try {
        const uploadId = localStorage.getItem('datafile_id')
        console.log('uploadIduploadId', uploadId)
        const data = {
          datafile_id: uploadId,
          submission_status: 'draft',
          data: {
            general_details: generalDetails,
            product_details: productDetails,
            furnace_details: furnaceDetails,
            mild_steel_customers_only: mildSteelCustomer,
            raw_material_energy_details: rawAndEnergyDetails,
            furnace_dimension: furnaceDimension,
            lining_details: liningDetails,
            alloy_stainless_steel_customers: alloyAndSteel,
          },
        }

        console.log(data, 'data123')
        // Make the POST request using axios
        const response = await axios.post(API.INSERT_DATA_COLLECTION, data, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })

        // Handle the response if needed
        console.log('Data inserted successfully', response.data)

        navigate('/draftstatus')
      } catch (error) {
        // Handle errors
        console.error('An error occurred while inserting data:', error)
      }
    }
  }

  const handleApprove = async () => {
    try {
      const id = localStorage.getItem('dataCollectionID')

      const data = {
        id: id,
        submission_status: 'approved',
        updated_data: {
          general_details: generalDetails,
          product_details: productDetails,
          furnace_details: furnaceDetails,
          mild_steel_customers_only: mildSteelCustomer,
          raw_material_energy_details: rawAndEnergyDetails,
          furnace_dimension: furnaceDimension,
          lining_details: liningDetails,
          alloy_stainless_steel_customers: alloyAndSteel,
        },
      }

      console.log(data, 'update data')
      const response = await axios.put(API.UPDATE_DATA_COLLECTION, data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      console.log('Data updated successfully:', response.data)

      // Navigate after successful update
      navigate('/approvestatus')
    } catch (error) {
      // Handle errors
      console.error('An error occurred while updating data:', error)
    }
  }
  const handleReject = async () => {
    if (comments.trim() === '') {
      setSnackbarOpen(true)
      return
    }

    try {
      const id = localStorage.getItem('dataCollectionID')

      const data = {
        id: id,
        submission_status: 'rejected',
        comments: comments,
        updated_data: {
          general_details: generalDetails,
          product_details: productDetails,
          furnace_details: furnaceDetails,
          mild_steel_customers_only: mildSteelCustomer,
          raw_material_energy_details: rawAndEnergyDetails,
          furnace_dimension: furnaceDimension,
          lining_details: liningDetails,
          alloy_stainless_steel_customers: alloyAndSteel,
        },
      }

      console.log(data, 'update data')
      const response = await axios.put(API.UPDATE_DATA_COLLECTION, data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      console.log('Data updated successfully:', response.data)

      // Navigate after successful update
      navigate('/rejectstatus')
    } catch (error) {
      // Handle errors
      console.error('An error occurred while updating data:', error)
    }
  }

  const handleResubmit = async () => {
    try {
      const id = localStorage.getItem('dataCollectionID')

      const data = {
        id: id,
        submission_status: 'submitted',
        updated_data: {
          general_details: generalDetails,
          product_details: productDetails,
          furnace_details: furnaceDetails,
          mild_steel_customers_only: mildSteelCustomer,
          raw_material_energy_details: rawAndEnergyDetails,
          furnace_dimension: furnaceDimension,
          lining_details: liningDetails,
          alloy_stainless_steel_customers: alloyAndSteel,
        },
      }

      console.log(data, 'update data')
      const response = await axios.put(API.UPDATE_DATA_COLLECTION, data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      console.log('Data updated successfully:', response.data)

      // Navigate after successful update
      navigate('/submittedstatus')
    } catch (error) {
      // Handle errors
      console.error('An error occurred while updating data:', error)
    }
  }

  // const RenderButtons = () => {
  //   const isViewDataCollection = trialName === "viewDataCollection";
  //   const isUserRole1234 = [1, 2, 3, 4].includes(currentUser.role.role_id);

  //   if (isViewDataCollection && isUserRole1234) {
  //     return null;
  //   }

  //   if (trialName === 'viewDataCollection' && activeStep === 7 && (currentUser.role.role_id === 3 || currentUser.role.role_id === 4)) {
  //     return (
  //       <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '100vh', paddingBottom: '60px' }}>
  //         <div style={{ paddingTop: '30px' }}>
  //           <TextField
  //             label="Comments"
  //             variant="outlined"
  //             value={comments}
  //             onChange={(e) => setComments(e.target.value)}
  //             fullWidth
  //             multiline
  //             rows={4}
  //             sx={{
  //               marginTop: 2,
  //               marginBottom: 2,
  //               '& .MuiInputBase-root': {
  //                 fontSize: '16px', // Input text size
  //               },
  //               '& .MuiInputLabel-root': {
  //                 fontSize: '16px', // Label text size
  //               },
  //             }}
  //           />
  //         </div>
  //         <div style={{
  //           display: "flex",
  //           flexDirection: "row",
  //           position: 'fixed',
  //           bottom: 20,
  //           left: '50%',
  //           transform: 'translateX(-50%)',
  //           backgroundColor: 'white',
  //           zIndex: 1000,
  //           padding: '10px',
  //           alignItems: "center",
  //         }}>
  //           <StyledButton
  //             variant="outlined_reject"
  //             text="Reject"
  //             onClick={handleReject}
  //             style={{ marginRight: 10 }}
  //           />
  //           <StyledButton
  //             variant="contained"
  //             color="primary"
  //             text="Approve"
  //             onClick={handleApprove}
  //           />
  //         </div>

  //         <Snackbar
  //           open={snackbarOpen}
  //           autoHideDuration={6000}
  //           onClose={handleSnackbarClose}
  //         >
  //           <Alert onClose={handleSnackbarClose} severity="warning" sx={{ width: '100%' }}>
  //             Comments cannot be empty!
  //           </Alert>
  //         </Snackbar>
  //       </div>
  //     );
  //   } else if (trialName !== "viewDataCollection") {
  //     return (
  //       <div style={{ flexDirection: "row", display: "flex", marginTop: "auto", marginBottom: "10px", position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1000 }}>
  //         <StyledButton
  //           variant="outlined"
  //           text="Cancel"
  //           onClick={handleBack}
  //           disabled={activeStep === 0}
  //           style={{ marginRight: 10 }}
  //         />
  //         {activeStep === steps.length - 1 && (
  //           <StyledButton
  //             variant="outlined_blue"
  //             color="secondary"
  //             text="Save Draft"
  //             onClick={handleDraft}
  //           />
  //         )}
  //         <StyledButton
  //           variant="contained"
  //           color="primary"
  //           text={activeStep === steps.length - 1 ? 'Submit for Approval' : 'Next'}
  //           onClick={handleNext}
  //         />
  //       </div>
  //     );
  //   }
  //   return null;
  // };

  // const RenderButtons = () => {
  //   return (
  //     trialName === 'viewDataCollection' && activeStep === 7  && (currentUser.role.role_id === 3 || currentUser.role.role_id === 4)  ? (
  //       <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '100vh', paddingBottom: '60px' }}>
  //         <div style={{ paddingTop: '30px' }}>
  //           <TextField
  //             label="Comments"
  //             variant="outlined"
  //             value={comments}
  //             onChange={(e) => setComments(e.target.value)}
  //             fullWidth
  //             multiline
  //             rows={4}
  //             sx={{
  //               marginTop: 2,
  //               marginBottom: 2,
  //               '& .MuiInputBase-root': {
  //                 fontSize: '16px', // Input text size
  //               },
  //               '& .MuiInputLabel-root': {
  //                 fontSize: '16px', // Label text size
  //               },
  //             }}
  //           />
  //         </div>
  //         <div style={{
  //           display: "flex",
  //           flexDirection: "row",
  //           position: 'fixed',
  //           bottom: 20,
  //           left: '50%',
  //           transform: 'translateX(-50%)',
  //           backgroundColor: 'white',
  //           zIndex: 1000,
  //           padding: '10px',
  //           alignItems: "center",
  //         }}>
  //           <StyledButton
  //             variant="outlined_reject"
  //             text="Reject"
  //             onClick={handleReject}
  //             style={{ marginRight: 10 }}
  //           />
  //           <StyledButton
  //             variant="contained"
  //             color="primary"
  //             text="Approve"
  //             onClick={handleApprove}
  //           />
  //         </div>

  //         <Snackbar
  //           open={snackbarOpen}
  //           autoHideDuration={6000}
  //           onClose={handleSnackbarClose}
  //         >
  //           <Alert onClose={handleSnackbarClose} severity="warning" sx={{ width: '100%' }}>
  //             Comments cannot be empty!
  //           </Alert>
  //         </Snackbar>
  //       </div>
  //     ):(
  //     // ) : (currentUser.role.role_id === 1 || currentUser.role.role_id === 2) && activeStep === 7  ? (
  //     //   <div style={{ paddingTop: '30px' }}>
  //     //       <TextField
  //     //   label="Comments"
  //     //   variant="outlined"
  //     //   value={comments}
  //     //   onChange={(e) => setComments(e.target.value)}
  //     //   fullWidth
  //     //   multiline
  //     //   rows={4}
  //     //   sx={{
  //     //     marginTop: 2,
  //     //     marginBottom: 2,
  //     //     '& .MuiInputBase-root': {
  //     //       fontSize: '16px', // Input text size
  //     //     },
  //     //     '& .MuiInputLabel-root': {
  //     //       fontSize: '16px', // Label text size
  //     //     },
  //     //   }}
  //     // />
  //     //     <div style={{
  //     //       display: "flex",
  //     //       flexDirection: "row",
  //     //       position: 'fixed',
  //     //       bottom: 20,
  //     //       left: '50%',
  //     //       transform: 'translateX(-50%)',
  //     //       backgroundColor: 'white',
  //     //       zIndex: 1000,
  //     //       padding: '10px',
  //     //       alignItems: "center",
  //     //     }}>
  //     //       <StyledButton
  //     //         variant="contained"
  //     //         color="primary"
  //     //         text="Resubmit"
  //     //         onClick={handleResubmit}
  //     //       />
  //     //     </div>
  //     //   </div>
  //     // ) : (
  //       {trialName !== "viewDataCollection" ? (
  //         <div style={{ flexDirection: "row", display: "flex", marginTop: "auto", marginBottom: "10px", position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1000 }}>
  //           <StyledButton
  //             variant="outlined"
  //             text="Cancel"
  //             onClick={handleBack}
  //             disabled={activeStep === 0}
  //             style={{ marginRight: 10 }}
  //           />
  //           {activeStep === steps.length - 1 && (
  //             <StyledButton
  //               variant="outlined_blue"
  //               color="secondary"
  //               text="Save Draft"
  //               onClick={handleDraft}
  //             />
  //           )}
  //           <StyledButton
  //             variant="contained"
  //             color="primary"
  //             text={activeStep === steps.length - 1 ? 'Submit for Approval' : 'Next'}
  //             onClick={handleNext}
  //           />
  //         </div>
  //       ) : null}

  // };

  const handleStepClick = (index) => {
    setActiveStep(index)
  }

  const progressText = `${activeStep + 1} of ${steps.length} datas completed`

  return (
    <div
      style={{
        width: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <div style={{ flex: 1, overflowY: 'auto' }}>
        <div style={{ marginTop: '30px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="body1"
              style={{
                color: '#000000',
                fontFamily: 'Inter',
                fontWeight: 600,
                fontSize: '18px',
                lineHeight: '20px',
              }}
            >
              {steps[activeStep]}
            </Typography>
            {activeStep === 5 && (
              <>
                <RemoveRedEyeOutlinedIcon onClick={handleOpen} />
                <Dialog open={open} onClose={handleClose}>
                  <DialogContent>
                    <img
                      src={furnaceImage}
                      alt="Image"
                      style={{ maxWidth: '100%' }}
                    />
                  </DialogContent>
                  <IconButton
                    onClick={handleClose}
                    style={{ position: 'absolute', top: 0, right: 0 }}
                  >
                    <CloseOutlinedIcon />
                  </IconButton>
                </Dialog>
              </>
            )}
          </div>
          <Typography
            variant="body1"
            sx={{
              marginBottom: '20px',
              color: '#6E7C87',
              fontFamily: 'Inter',
              fontWeight: 400,
              fontSize: '14px',
              marginTop: '5px',
            }}
          >
            {progressText}
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '20px',
          }}
        >
          {steps.map((step, index) => (
            <div
              key={index}
              style={{ flex: 1, position: 'relative', marginRight: '10px' }}
            >
              <div
                onClick={() => handleStepClick(index)}
                style={{
                  borderBottom: `5px solid ${index === activeStep ? '#0086DC' : '#BDBDBD'}`,
                  width: '100%',
                  marginBottom: '10px',
                  marginTop: '10px',
                  borderRadius: '7px',
                }}
              />
            </div>
          ))}
        </div>

        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography>All steps completed</Typography>
            </div>
          ) : (
            <div>
              {activeStep === 0 && <GeneralDetails />}
              {activeStep === 1 && <ProductDetails />}
              {activeStep === 2 && <FurnaceDetails />}
              {activeStep === 3 && <MildSteelCustomer />}
              {activeStep === 4 && <RawMaterialsAndEnergy />}
              {activeStep === 5 && <FurnaceDimension />}
              {activeStep === 6 && <LiningDetails />}
              {activeStep === 7 && <AlloyAndSteel />}
            </div>
          )}
        </div>
      </div>
      {trialName !== 'viewDataCollection' ? (
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            marginTop: 'auto',
            marginBottom: '1  0px',
            position: 'sticky',
            bottom: 0,
            backgroundColor: 'white',
            zIndex: 1000,
          }}
        >
          {activeStep !== 0 && (
            <StyledButton
              variant="outlined"
              text="Back"
              onClick={handleBack}
              style={{ marginRight: 10 }}
            />
          )}

          <StyledButton
            variant="outlined_blue"
            color="secondary"
            text="Save Draft"
            onClick={handleDraft}
          />

          <StyledButton
            variant="contained"
            color="primary"
            text={
              activeStep === steps.length - 1 ? 'Submit for Approval' : 'Next'
            }
            onClick={handleNext}
          />
        </div>
      ) : (
        <div>
          {activeStep === steps.length - 1 &&
          trialName === 'viewDataCollection' ? null : ( // No buttons shown on the last step in view mode
            <div
              style={{
                flexDirection: 'row',
                display: 'flex',
                marginTop: 'auto',
                marginBottom: '10px',
                position: 'sticky',
                bottom: 0,
                backgroundColor: 'white',
                zIndex: 1000,
              }}
            >
              {activeStep !== 0 && (
                <StyledButton
                  variant="outlined"
                  text="Back"
                  onClick={handleBack}
                  style={{ marginRight: 10 }}
                />
              )}
              <StyledButton
                variant="contained"
                color="primary"
                text={
                  activeStep === steps.length - 1
                    ? 'Submit for Approval'
                    : 'Next'
                }
                onClick={handleNext}
              />
            </div>
          )}
        </div>
      )}

      {trialName === 'viewDataCollection' &&
        (currentUser.role.role_id === 3 || currentUser.role.role_id === 4) &&
        activeStep === 7 && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              minHeight: '100vh',
              paddingBottom: '60px',
            }}
          >
            <div style={{ paddingTop: '30px' }}>
              <TextField
                label="Comments"
                variant="outlined"
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                fullWidth
                multiline
                rows={4}
                sx={{
                  marginTop: 2,
                  marginBottom: 2,
                  '& .MuiInputBase-root': {
                    fontSize: '16px', // Input text size
                  },
                  '& .MuiInputLabel-root': {
                    fontSize: '16px', // Label text size
                  },
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                position: 'fixed',
                bottom: 20,
                left: '50%',
                transform: 'translateX(-50%)',
                backgroundColor: 'white',
                zIndex: 1000,
                padding: '10px',
                alignItems: 'center',
              }}
            >
              <StyledButton
                variant="outlined_reject"
                text="Reject"
                onClick={handleReject}
                style={{ marginRight: 10 }}
              />
              <StyledButton
                variant="contained"
                color="primary"
                text="Approve"
                onClick={handleApprove}
              />
            </div>

            <Snackbar
              open={snackbarOpen}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
            >
              <Alert
                onClose={handleSnackbarClose}
                severity="warning"
                sx={{ width: '100%' }}
              >
                Comments cannot be empty!
              </Alert>
            </Snackbar>
          </div>
        )}
    </div>
  )
}

export default HorizontalDataCollectionStepper
