import React, { useState, useRef, useEffect } from 'react'
import { Card, CardContent, Typography, Avatar, Grid } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton } from '@mui/material'
import ViewIcon from '../../assets/icons/ic_view.png'
import { useNavigate } from 'react-router-dom'
import API from '../services/API'
import axios from 'axios'
import Tooltip from '@mui/material/Tooltip'
import DownloadIcon from '../../assets/icons/download_icon.svg'
import * as XLSX from 'xlsx'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import Collapsed from '../../assets/icons/collapsed.svg'
import Expanded from '../../assets/icons/expanded.svg'
const NRMPerformnace = ({
  selectedTab,
  cardsSubmitted,
  cardsDraft,
  cardsApproved,
  rejectData,
  file,
  selectedTemplate,
  SegmentList,
  filteredNRMPerformanceData,
}) => {
  console.log(SegmentList, 'SegmentList')
  console.log('selectedTemplate', selectedTemplate)
  console.log('selectedTab', selectedTab)
  console.log('file', file)
  console.log('cardsSubmittedcheck2', cardsSubmitted)
  console.log('cardsApproved', cardsApproved)
  console.log('rejectData', rejectData)
  console.log('filteredNRMPerformanceData', filteredNRMPerformanceData)
  const navigate = useNavigate()
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [popupPosition, setPopupPosition] = useState({ top: 0, right: 0 })
  const [dataFileId, setDataFileId] = useState(null)
  const popupRef = useRef(null)
  const currentUser = JSON.parse(localStorage.getItem('user'))
  const [loading, setLoading] = useState(false)
  const access_token = localStorage.getItem('accesstoken')

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      closePopup()
    }
  }

  const closePopup = () => {
    setIsPopupOpen(false)
    console.log('Popup closed')
  }

  useEffect(() => {
    if (isPopupOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isPopupOpen])

  const handleDelete = async () => {
    console.log('Button clicked!')
    console.log('cardId', dataFileId)

    try {
      // Make the API call here
      const url = `${API.DELETE_CARD}/${dataFileId}`
      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      const data = response.data

      // Do something with the response data
      console.log('DELETE_CARD', data)
      setIsPopupOpen(false)
      window.location.reload()
    } catch (error) {
      console.error('Error:', error)
      // Optionally, you can handle the error state here
    }
  }

  const handleEdit = () => {
    console.log('Button clicked!')
  }

  const handleView = async () => {
    console.log('cardId', dataFileId)
    localStorage.setItem('dataFileId', dataFileId)
    navigate('/viewtrial')
  }

  const handleMenuOpen = (event, item) => {
    const rect = event.target.getBoundingClientRect()
    setIsPopupOpen(true)
    setPopupPosition({
      top: rect.top + window.scrollY,
      right: window.innerWidth - rect.right,
    })
    console.log('itemdetails', item)
    localStorage.setItem('datafile_info', JSON.stringify(item))
    localStorage.setItem('nrm_performance_id', item.id)
    setDataFileId(item.id)
  }

  const handleApproval = async () => {
    console.log('cardId', dataFileId)

    try {
      // Make the API call here
      const url = `${API.APPROVED_CARD_UPDATE}/${dataFileId}`
      const response = await axios.post(url, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      const data = response.data
      // Do something with the response data
      console.log('Card Details:', data)
      setIsPopupOpen(false)
      window.location.reload()
    } catch (error) {
      console.error('Error:', error)
      // Optionally, you can handle the error state here
    }
  }

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
    return buf
  }

  const workbookToBlob = (workbook) => {
    return new Promise((resolve) => {
      // Write the workbook to a binary string
      const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' })

      // Convert the binary string to a Blob
      const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' })
      resolve(blob)
    })
  }

  const handleDownload = async () => {
    console.log('handle download clicked')
    const id = localStorage.getItem('nrm_performance_id')
    console.log('id', id)
    setLoading(true)

    try {
      // Fetch data from the endpoint
      const response = await axios.get(
        `${API.GET_NRM_PERFORMANCE_BY_ID}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      const data = response.data
      console.log(data, 'data')

      // Fetch the Excel template
      const responseTemplate = await axios.get(API.GET_NRM_EXCEL_DOWNLOAD, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      console.log(responseTemplate, 'sjhsafjas')

      // Decode base64 to ArrayBuffer
      const base64Data = responseTemplate.data.downloadedTemplates[0].data
      const byteCharacters = atob(base64Data)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const arrayBuffer = new Uint8Array(byteNumbers).buffer

      // Create a new workbook and load the template
      const workbook = new ExcelJS.Workbook()
      await workbook.xlsx.load(arrayBuffer)

      // Get the worksheet
      const worksheet = workbook.worksheets[0]

      // Ensure data arrays are initialized
      const liningReference = data.lining_reference || []
      const productionSummary = data.production_summary || []
      const liningMaterialProductDetails =
        data.lining_material_product_details || []
      const liningMaterialConsumption = data.lining_material_consumption || []
      const dimensionalParameters = data.dimensional_parameters || []
      const sinteringParameters = data.sintering_parameters || []

      // Add lining reference data
      liningReference.forEach((detail, index) => {
        if (detail) {
          const rowIndex = 9 + index // Adjust row index as needed
          worksheet.getCell(`A${rowIndex}`).value = detail.furnace_no || ''
          worksheet.getCell(`B${rowIndex}`).value = detail.crucible_no || ''
          worksheet.getCell(`C${rowIndex}`).value = detail.campaign_no || ''
          worksheet.getCell(`D${rowIndex}`).value =
            detail.lining_or_patching || ''
          worksheet.getCell(`E${rowIndex}`).value =
            detail.date_of_completion_lining_or_patching || ''
        }
      })

      // Add production summary data
      productionSummary.forEach((detail, index) => {
        if (detail) {
          const rowIndex = 9 + index // Adjust row index as needed
          worksheet.getCell(`F${rowIndex}`).value =
            detail.fce_prod_start_date || ''
          worksheet.getCell(`G${rowIndex}`).value =
            detail.fce_prod_start_time || ''
          worksheet.getCell(`H${rowIndex}`).value =
            detail.fce_prod_down_date || ''
          worksheet.getCell(`I${rowIndex}`).value =
            detail.fce_prod_down_time || ''
          worksheet.getCell(`J${rowIndex}`).value = detail.life || ''
          worksheet.getCell(`K${rowIndex}`).value =
            detail.average_heat_size || ''
          worksheet.getCell(`L${rowIndex}`).value =
            detail.total_furnace_running_hours || ''
          worksheet.getCell(`M${rowIndex}`).value =
            detail.total_production || ''
        }
      })

      // Add lining material product details data
      liningMaterialProductDetails.forEach((detail, index) => {
        if (detail) {
          const rowIndex = 9 + index // Adjust row index as needed
          worksheet.getCell(`N${rowIndex}`).value = detail.bottom_product || ''
          worksheet.getCell(`O${rowIndex}`).value =
            detail.sidewall_product_one || ''
          worksheet.getCell(`P${rowIndex}`).value =
            detail.sidewall_product_two || ''
          worksheet.getCell(`Q${rowIndex}`).value =
            detail.top_locking_product || ''
          worksheet.getCell(`R${rowIndex}`).value =
            detail.topping_mass_product || ''
          worksheet.getCell(`S${rowIndex}`).value =
            detail.average_heat_size || ''
          worksheet.getCell(`T${rowIndex}`).value =
            detail.sidewall_one_coverage_upto_x || ''
          worksheet.getCell(`U${rowIndex}`).value =
            detail.sidewall_two_coverage_upto_100 || ''
        }
      })

      // Add lining material consumption data
      liningMaterialConsumption.forEach((detail, index) => {
        if (detail) {
          const rowIndex = 9 + index // Adjust row index as needed
          worksheet.getCell(`V${rowIndex}`).value =
            detail.nrm_consumption_bottom || ''
          worksheet.getCell(`W${rowIndex}`).value =
            detail.nrm_consumption_sidewall_one || ''
          worksheet.getCell(`X${rowIndex}`).value =
            detail.nrm_consumption_sidewall_two || ''
          worksheet.getCell(`Y${rowIndex}`).value =
            detail.nrm_consumption_top_locking || ''
          worksheet.getCell(`Z${rowIndex}`).value =
            detail.topping_mass_consumption || ''
          worksheet.getCell(`AA${rowIndex}`).value =
            detail.total_nrm_consumption_sidewall_one || ''
          worksheet.getCell(`AB${rowIndex}`).value =
            detail.nrm_specific_consumption || ''
        }
      })

      // Add dimensional parameters data
      dimensionalParameters.forEach((detail, index) => {
        if (detail) {
          const rowIndex = 9 + index // Adjust row index as needed
          worksheet.getCell(`AC${rowIndex}`).value =
            detail.former_dimension || ''
          worksheet.getCell(`AD${rowIndex}`).value = detail.coil_coat_id || ''
          worksheet.getCell(`AE${rowIndex}`).value = detail.fce_ht || ''
          worksheet.getCell(`AF${rowIndex}`).value =
            detail.costable_top_ht || ''
          worksheet.getCell(`AG${rowIndex}`).value = detail.bottom_ht || ''
        }
      })

      // Add sintering parameters data
      sinteringParameters.forEach((detail, index) => {
        if (detail) {
          const rowIndex = 9 + index // Adjust row index as needed
          worksheet.getCell(`AH${rowIndex}`).value =
            detail.total_sintering_time || ''
          worksheet.getCell(`AI${rowIndex}`).value = detail.holding_time || ''
          worksheet.getCell(`AJ${rowIndex}`).value = detail.holding_temp || ''
          worksheet.getCell(`AK${rowIndex}`).value =
            detail.reason_for_down || ''
          worksheet.getCell(`AL${rowIndex}`).value = detail.IR_no || ''
          worksheet.getCell(`AM${rowIndex}`).value = detail.remarks || ''
        }
      })

      // Generate the modified workbook and save
      const buffer = await workbook.xlsx.writeBuffer()
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })

      // Use FileSaver to save the file
      saveAs(blob, 'NRM_Performance.xlsx')
    } catch (error) {
      console.error('Error downloading or processing template:', error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleRejected = async () => {
    console.log('cardId', dataFileId)

    try {
      // Make the API call here
      const url = `${API.REJECTED_CARD_UPDATE}/${dataFileId}`
      const response = await axios.post(url, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      const data = response.data

      // Do something with the response data
      console.log('Card Details:', data)
      setIsPopupOpen(false)
      window.location.reload()
    } catch (error) {
      console.error('Error:', error)
      // Optionally, you can handle the error state here
    }
  }

  const [isDraftOpen, setIsDraftOpen] = useState(false)
  const [isSubmittedOpen, setIsSubmittedOpen] = useState(false)
  const [isRejectedOpen, setIsRejectedOpen] = useState(false)
  const [isApprovedOpen, setIsApprovedOpen] = useState(false)

  const [toggleCount, setToggleCount] = useState(0)
  console.log(toggleCount, 'count')

  const handleToggle = (section) => {
    switch (section) {
      case 'draft':
        setIsDraftOpen((prev) => {
          setToggleCount(prev ? toggleCount - 1 : toggleCount + 1)
          return !prev
        })
        break
      case 'submitted':
        setIsSubmittedOpen((prev) => {
          setToggleCount(prev ? toggleCount - 1 : toggleCount + 1)
          return !prev
        })
        break
      case 'rejected':
        setIsRejectedOpen((prev) => {
          setToggleCount(prev ? toggleCount - 1 : toggleCount + 1)
          return !prev
        })
        break
      case 'approved':
        setIsApprovedOpen((prev) => {
          setToggleCount(prev ? toggleCount - 1 : toggleCount + 1)
          return !prev
        })
        break
      default:
        break
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        borderTop: '2px solid #ebebeb',
      }}
    >
      {/* draft */}
      <div
        style={{
          display: isDraftOpen ? 'block' : 'flex',
          padding: '8px',
          width:
            toggleCount === 1 && isDraftOpen
              ? '90%' // Wrap percentage values in quotes
              : toggleCount === 2 && isDraftOpen
                ? '50%'
                : toggleCount === 3 && isDraftOpen
                  ? '50%'
                  : toggleCount === 4 && isDraftOpen
                    ? '33%'
                    : '',
        }}
      >
        {' '}
        {isDraftOpen ? (
          <div
            onClick={() => handleToggle('draft')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
            }}
          >
            <img
              src={Expanded}
              alt="Expanded"
              style={{ marginRight: '10px' }}
            />{' '}
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              DRAFT
            </Typography>
          </div>
        ) : (
          <div
            onClick={() => handleToggle('draft')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
              borderRight: isDraftOpen ? 'none' : '2px solid #ebebeb',
              alignItems: 'center'
            }}
          >
            <img
              src={Collapsed}
              alt="Collapsed"
              style={{ marginBottom: '5px' }}
            />{' '}
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              D
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              R
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              A
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              F
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              T
            </Typography>
          </div>
        )}
        <Grid
          container
          style={{
            marginTop: '0',
            display: isDraftOpen ? 'flex' : 'none',
            flexWrap: 'wrap',
          }}
        >
          {(filteredNRMPerformanceData.length > 0
            ? filteredNRMPerformanceData.filter(
                (item) =>
                  item?.lining_reference[0]?.submission_status === 'draft'
              )
            : cardsDraft
          ).map((item) => {
            if (item.dataFile.template.template_id === 2) {
              const createdDate = new Date(item.created_at)
              const formattedDate = createdDate.toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })
              return (
                <Card
                  key={item.id}
                  sx={{
                    border: '1px solid #E6E6E6',
                    boxShadow: '0px 5px 13px -5px #0A090B0D',
                    height: '180px',
                    marginBottom: '20px',
                    marginRight: '8px',
                  }}
                  md={6}
                >
                  <CardContent>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '16px',
                          fontWeight: 600,
                          lineHeight: '20px',
                          letterSpacing: '-0.006em',
                          textAlign: 'left',
                          color: '#252C32',
                          marginTop: '10px',
                        }}
                      >
                        {`NRM Performance_${item.dataFile.customer.organization_name}`}
                      </Typography>
                      <IconButton
                        aria-label="options"
                        onClick={(event) => handleMenuOpen(event, item)}
                      >
                        <MoreVertIcon style={{ color: '#0086DC' }} />
                      </IconButton>
                    </div>

                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: 700,
                        lineHeight: '20px',
                        letterSpacing: '-0.6%',
                        textAlign: 'left',
                        marginTop: '5px',
                        color: '#252C32',
                      }}
                    >
                      {item.dataFile.customer.organization_name}
                    </Typography>

                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 400,
                        lineHeight: '20px',
                        letterSpacing: '-0.006em',
                        textAlign: 'left',
                        color: '#6E7C87',
                      }}
                    >
                      {item.dataFile.segment.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '16px',
                        letterSpacing: '-0.006em',
                        marginTop: '15px',
                        textAlign: 'left',
                        color: '#7F7D83',
                        width: '37px',
                        height: '16px',
                        background: '#7F7D831A',
                        padding: '0px 4px',
                        borderRadius: '4px',
                      }}
                    >
                      Draft
                    </Typography>

                    <div
                      style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '20px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#6E7C87',
                          }}
                        >
                          Created Date:
                        </Typography>

                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 700,
                            lineHeight: '20px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#252C32',
                            marginLeft: '8px',
                          }}
                        >
                          {formattedDate}
                        </Typography>
                      </div>

                      {item.dataFile.user &&
                        item.dataFile.user.firstname &&
                        item.dataFile.user.lastname && (
                          <Tooltip
                            title={`${item.dataFile.user.firstname} ${item.dataFile.user.lastname}`}
                            arrow
                          >
                            <Avatar
                              style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '12px',
                                background: '#D7EDFF',
                                color: '#0086DC',
                                fontWeight: 700,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: 'auto',
                                fontSize: '10px',
                              }}
                            >
                              {item.dataFile.user.firstname.charAt(0)}
                              {item.dataFile.user.lastname.charAt(0)}
                            </Avatar>
                          </Tooltip>
                        )}
                    </div>
                  </CardContent>
                </Card>
              )
            }
          })}
        </Grid>
      </div>
      {/* awaiting approval or submitted */}
      <div
        style={{
          display: isSubmittedOpen ? 'block' : 'flex',
          padding: '8px',
          width:
            toggleCount === 1 && isSubmittedOpen
              ? '90%' // Wrap percentage values in quotes
              : toggleCount === 2 && isSubmittedOpen
                ? '50%'
                : toggleCount === 3 && isSubmittedOpen
                  ? '50%'
                  : toggleCount === 4 && isSubmittedOpen
                    ? '33%'
                    : '',
        }}
      >
        {isSubmittedOpen ? (
          <div
            onClick={() => handleToggle('submitted')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
            }}
          >
            <img
              src={Expanded}
              alt="Expanded"
              style={{ marginRight: '10px' }}
            />
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                letterSpacing: '0.006em',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              {currentUser.role.role_id === 3 || currentUser.role.role_id === 4
                ? 'AWAITING APPROVAL'
                : 'SUBMITTED'}
            </Typography>
          </div>
        ) : (
          <div
            onClick={() => handleToggle('submitted')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
              borderRight: isDraftOpen ? 'none' : '2px solid #ebebeb',
              alignItems:'center'
            }}
          >
            <img
              src={Collapsed}
              alt="Collapsed"
              style={{ marginBottom: '5px' }}
            />
            {Array.from(
              currentUser.role.role_id === 3 || currentUser.role.role_id === 4
                ? 'AWAITING APPROVAL'
                : 'SUBMITTED'
            ).map((letter, index) => (
              <Typography
                key={index}
                variant="body1"
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  letterSpacing: '0.006em',
                  textAlign: 'left',
                  color: '#254A9A',
                }}
              >
                {letter}
              </Typography>
            ))}
          </div>
        )}

        <Grid
          container
          style={{
            marginTop: '0',
            display: isSubmittedOpen ? 'flex' : 'none',
            flexWrap: 'wrap',
          }}
        >
          {(filteredNRMPerformanceData.length > 0
            ? filteredNRMPerformanceData.filter(
                (item) =>
                  item?.general_details[0]?.submission_status === 'submitted'
              )
            : cardsSubmitted && cardsSubmitted
          ).map((item) => {
            if (item.dataFile.template.template_id === 2) {
              const createdDate = new Date(item.created_at)
              const formattedDate = createdDate.toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })

              return (
                <Card
                  key={item.id}
                  sx={{
                    border: '1px solid #E6E6E6',
                    boxShadow: '0px 5px 13px -5px #0A090B0D',
                    height: '180px',
                    marginBottom: '20px',
                    marginRight: '5px', 
                  }}
                >
                  <CardContent>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '16px',
                          fontWeight: 600,
                          lineHeight: '20px',
                          letterSpacing: '-0.006em',
                          textAlign: 'left',
                          color: '#252C32',
                          marginTop: '10px',
                        }}
                      >
                        {`Daily Trial Monitoring_${item.dataFile.customer.organization_name}`}
                      </Typography>
                      <IconButton
                        aria-label="options"
                        onClick={(event) => handleMenuOpen(event, item)}
                      >
                        <MoreVertIcon style={{ color: '#0086DC' }} />
                      </IconButton>
                    </div>

                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: 700,
                        lineHeight: '20px',
                        letterSpacing: '-0.6%',
                        textAlign: 'left',
                        marginTop: '5px',
                        color: '#252C32',
                      }}
                    >
                      {item.dataFile.customer.organization_name}
                    </Typography>

                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 400,
                        lineHeight: '20px',
                        letterSpacing: '-0.006em',
                        textAlign: 'left',
                        color: '#6E7C87',
                      }}
                    >
                      {item.dataFile.segment.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '16px',
                        letterSpacing: '-0.006em',
                        marginTop: '15px',
                        textAlign: 'left',
                        color: '#0086DC',
                        width:
                          currentUser.role.role_id === 3 ||
                          currentUser.role.role_id === 4
                            ? '120px'
                            : '70px',
                        height: '16px',
                        background: '#0086DC1A',
                        padding: '0px 4px',
                        borderRadius: '4px',
                      }}
                    >
                      {currentUser.role.role_id === 3 ||
                      currentUser.role.role_id === 4
                        ? 'Awaiting approval'
                        : 'Submitted'}
                    </Typography>

                    <div
                      style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '20px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#6E7C87',
                          }}
                        >
                          Created Date:
                        </Typography>

                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 700,
                            lineHeight: '20px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#252C32',
                            marginLeft: '8px',
                          }}
                        >
                          {formattedDate}
                        </Typography>
                      </div>

                      {item.dataFile.user &&
                        item.dataFile.user.firstname &&
                        item.dataFile.user.lastname && (
                          <Tooltip
                            title={`${item.dataFile.user.firstname} ${item.dataFile.user.lastname}`}
                            arrow
                          >
                            <Avatar
                              style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '12px',
                                background: '#D7EDFF',
                                color: '#0086DC',
                                fontWeight: 700,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: 'auto',
                                fontSize: '10px',
                              }}
                            >
                              {item.dataFile.user.firstname.charAt(0)}
                              {item.dataFile.user.lastname.charAt(0)}
                            </Avatar>
                          </Tooltip>
                        )}
                    </div>
                  </CardContent>
                </Card>
              )
            }
          })}
        </Grid>
      </div>
      {/* approved */}
      <div
        style={{
          display: isApprovedOpen ? 'block' : 'flex',
          padding: '8px',
          width:
            toggleCount === 1 && isApprovedOpen
              ? '90%'
              : toggleCount === 2 && isApprovedOpen
                ? '50%'
                : toggleCount === 3 && isApprovedOpen
                  ? '50%'
                  : toggleCount === 4 && isApprovedOpen
                    ? '33%'
                    : '',
        }}
      >
        {isApprovedOpen ? (
          <div
            onClick={() => handleToggle('approved')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
            }}
          >
            <img
              src={Expanded}
              alt="Expanded"
              style={{ marginRight: '10px' }}
            />
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                letterSpacing: '0.006em',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              APPROVED
            </Typography>
          </div>
        ) : (
          <div
            onClick={() => handleToggle('approved')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
              borderRight: isApprovedOpen ? 'none' : '2px solid #ebebeb',
              alignItems: 'center'
            }}
          >
            <img
              src={Collapsed}
              alt="Collapsed"
              style={{ marginBottom: '5px' }}
            />{' '}
            {/* Collapsed Icon */}
            {Array.from('APPROVED').map((letter, index) => (
              <Typography
                key={index}
                variant="body1"
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  letterSpacing: '0.006em',
                  textAlign: 'left',
                  color: '#254A9A',
                }}
              >
                {letter}
              </Typography>
            ))}
          </div>
        )}
        <Grid
          container
          style={{
            marginTop: '0',
            display: isApprovedOpen ? 'flex' : 'none',
            flexWrap: 'wrap',
          }}
        >
          {(filteredNRMPerformanceData.length > 0
            ? filteredNRMPerformanceData.filter(
                (item) =>
                  item?.lining_reference[0]?.submission_status === 'approved'
              )
            : cardsApproved && cardsApproved
          ).map((item) => {
            if (item.dataFile.template.template_id === 2) {
              const createdDate = new Date(item.created_at)
              const formattedDate = createdDate.toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })
              return (
                <Card
                  key={item.id}
                  sx={{
                    border: '1px solid #E6E6E6',
                    boxShadow: '0px 5px 13px -5px #0A090B0D',
                    height: '180px',
                    marginBottom: '20px',
                    marginRight: '5px', 
                  }}
                >
                  <CardContent>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '16px',
                          fontWeight: 600,
                          lineHeight: '20px',
                          letterSpacing: '-0.006em',
                          textAlign: 'left',
                          color: '#252C32',
                          marginTop: '10px',
                        }}
                      >
                        {`NRM Performance_${item.dataFile.customer.organization_name}`}
                      </Typography>
                      <IconButton
                        aria-label="options"
                        onClick={(event) => handleMenuOpen(event, item)}
                      >
                        <MoreVertIcon style={{ color: '#0086DC' }} />
                      </IconButton>
                    </div>

                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: 700,
                        lineHeight: '20px',
                        letterSpacing: '-0.6%',
                        textAlign: 'left',
                        marginTop: '5px',
                        color: '#252C32',
                      }}
                    >
                      {item.dataFile.customer.organization_name}
                    </Typography>

                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 400,
                        lineHeight: '20px',
                        letterSpacing: '-0.006em',
                        textAlign: 'left',
                        color: '#6E7C87',
                      }}
                    >
                      {item.dataFile.segment.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '16px',
                        letterSpacing: '-0.006em',
                        marginTop: '15px',
                        textAlign: 'left',
                        color: '#34A853',
                        width: '68px',
                        height: '16px',
                        background: '#34A8531A',
                        padding: '0px 4px', // Add padding
                        borderRadius: '4px', // Add border radius
                      }}
                    >
                      Approved
                    </Typography>

                    <div
                      style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '20px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#6E7C87',
                          }}
                        >
                          Created Date:
                        </Typography>

                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 700,
                            lineHeight: '20px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#252C32',
                            marginLeft: '8px', // Adjust spacing between components
                          }}
                        >
                          {formattedDate}
                        </Typography>
                      </div>

                      {item.dataFile.user &&
                        item.dataFile.user.firstname &&
                        item.dataFile.user.lastname && (
                          <Tooltip
                            title={`${item.dataFile.user.firstname} ${item.dataFile.user.lastname}`}
                            arrow
                          >
                            <Avatar
                              style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '12px',
                                background: '#D7EDFF',
                                color: '#0086DC',
                                fontWeight: 700,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: 'auto', // Pushes the Avatar to the right
                                fontSize: '10px', // Adjust font size of the Avatar content
                              }}
                            >
                              {item.dataFile.user.firstname.charAt(0)}
                              {item.dataFile.user.lastname.charAt(0)}
                            </Avatar>
                          </Tooltip>
                        )}
                    </div>
                  </CardContent>
                </Card>
              )
            }
          })}
        </Grid>
        {isPopupOpen && (
          <div
            ref={popupRef}
            style={{
              position: 'absolute',
              top: `${popupPosition.top}px`,
              right: `${popupPosition.right}px`,
              background: 'rgba(255, 255, 255, 1)',
              padding: '20px',
              borderRadius: '8px',
              border: '1px solid #E8E8E8',
              boxShadow: '0px 0px 35px 0px rgba(0, 0, 0, 0.1)',
              zIndex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            {/* Your popup content */}
            <div
              onClick={() => handleView(file)}
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                padding: '10px',
                padding: '10px',
              }}
            >
              <img
                src={ViewIcon}
                alt="Edit Icon"
                style={{ marginRight: '10px' }}
              />
              <Typography
                style={{
                  color: '#252C32',
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 800,
                  lineHeight: 'normal',
                  letterSpacing: '-0.084px',
                }}
              >
                View Trial
              </Typography>
            </div>
            <div
              onClick={handleDownload}
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                padding: '10px',
              }}
            >
              <img
                src={DownloadIcon}
                alt="Edit Icon"
                style={{ marginRight: '10px' }}
              />
              <Typography
                style={{
                  color: '#252C32',
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 800,
                  lineHeight: 'normal',
                  letterSpacing: '-0.084px',
                }}
              >
                Download
              </Typography>
            </div>
            {/* <div onClick={handleEdit} style={{ display: "flex", alignItems: 'center', cursor: 'pointer',padding:"10px"}}>
                            <img src={EditIcon} alt="Edit Icon" style={{ marginRight: '10px' }} />
                            <Typography
                            style={{
                                color: '#252C32',
                                fontFamily: 'Inter',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 800,
                                lineHeight: 'normal',
                                letterSpacing: '-0.084px',
                            }}>Edit Trial</Typography>
                        </div>
                        <div onClick={handleEdit} style={{ display: "flex", alignItems: 'center', cursor: 'pointer',padding:"10px"}}>
                            <img src={AddIcon} alt="Edit Icon" style={{ marginRight: '10px' }} />
                            <Typography
                            style={{
                                color: '#252C32',
                                fontFamily: 'Inter',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 800,
                                lineHeight: 'normal',
                                letterSpacing: '-0.084px',
                            }}>Add Trial</Typography>
                        </div> */}
            {/* <div
              onClick={handleApproval}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "10px",
              }}
            >
              <img
                src={ApprovalIcon}
                alt="Edit Icon"
                style={{ marginRight: "10px" }}
              />
              <Typography
                style={{
                  color: "#252C32",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 800,
                  lineHeight: "normal",
                  letterSpacing: "-0.084px",
                }}
              >
                Approval
              </Typography>
            </div>
            <div
              onClick={handleRejected}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "10px",
              }}
            >
              <img
                src={ApprovalIcon}
                alt="Edit Icon"
                style={{ marginRight: "10px" }}
              />
              <Typography
                style={{
                  color: "#252C32",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 800,
                  lineHeight: "normal",
                  letterSpacing: "-0.084px",
                }}
              >
                Rejected
              </Typography>
            </div>
            <div
              onClick={handleDelete}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "10px",
              }}
            >
              <img
                src={DeleteIcon}
                alt="Delete Icon"
                style={{ marginRight: "10px" }}
              />
              <Typography
                style={{
                  color: "#FF3347",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 800,
                  lineHeight: "normal",
                  letterSpacing: "-0.084px",
                }}
              >
                Delete
              </Typography>
            </div> */}
          </div>
        )}
      </div>
      {/* rejected */}
      <div
        style={{
          display: isRejectedOpen ? 'block' : 'flex',
          padding: '8px',
          width:
            toggleCount === 1 && isRejectedOpen
              ? '90%' // Wrap percentage values in quotes
              : toggleCount === 2 && isRejectedOpen
                ? '50%'
                : toggleCount === 3 && isRejectedOpen
                  ? '50%'
                  : toggleCount === 4 && isRejectedOpen
                    ? '33%'
                    : '',
        }}
      >
        {isRejectedOpen ? (
          <div
            onClick={() => handleToggle('rejected')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
            }}
          >
            <img
              src={Expanded}
              alt="Expanded"
              style={{ marginRight: '10px' }}
            />
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                letterSpacing: '0.006em',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              REJECTED
            </Typography>
          </div>
        ) : (
          <div
            onClick={() => handleToggle('rejected')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
              borderRight: isDraftOpen ? 'none' : '2px solid #ebebeb',
              alignItems: 'center'
            }}
          >
            <img
              src={Collapsed}
              alt="Collapsed"
              style={{ marginBottom: '5px' }}
            />{' '}
            {/* Collapsed Icon */}
            {Array.from('REJECTED').map((letter, index) => (
              <Typography
                key={index}
                variant="body1"
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  letterSpacing: '0.006em',
                  textAlign: 'left',
                  color: '#254A9A',
                }}
              >
                {letter}
              </Typography>
            ))}
          </div>
        )}
        <Grid container
          style={{
            marginTop: '0',
            display: isRejectedOpen ? 'flex' : 'none',
            flexWrap: 'wrap',
          }}
        >
          {(filteredNRMPerformanceData.length > 0
            ? filteredNRMPerformanceData.filter(
                (item) =>
                  item?.lining_reference[0]?.submission_status === 'rejected'
              )
            : rejectData && rejectData
          ).map((item) => {
            if (item.dataFile.template.template_id === 2) {
              const createdDate = new Date(item.created_at)
              const formattedDate = createdDate.toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })
              return (
                <Card
                  key={item.id}
                  sx={{
                    border: '1px solid #E6E6E6',
                    boxShadow: '0px 5px 13px -5px #0A090B0D',
                    height: '180px',
                    marginBottom: '20px',
                    marginRight: '5px', 
                  }}
                >
                  <CardContent>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '16px',
                          fontWeight: 600,
                          lineHeight: '20px',
                          letterSpacing: '-0.006em',
                          textAlign: 'left',
                          color: '#252C32',
                          marginTop: '10px',
                        }}
                      >
                        {`NRM Performance_${item.dataFile.customer.organization_name}`}
                      </Typography>
                      <IconButton
                        aria-label="options"
                        onClick={(event) => handleMenuOpen(event, item)}
                      >
                        <MoreVertIcon style={{ color: '#0086DC' }} />
                      </IconButton>
                    </div>

                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: 700,
                        lineHeight: '20px',
                        letterSpacing: '-0.6%',
                        textAlign: 'left',
                        marginTop: '5px',
                        color: '#252C32',
                      }}
                    >
                      {item.dataFile.customer.organization_name}
                    </Typography>

                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 400,
                        lineHeight: '20px',
                        letterSpacing: '-0.006em',
                        textAlign: 'left',
                        color: '#6E7C87',
                      }}
                    >
                      {item.dataFile.segment.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '16px',
                        letterSpacing: '-0.006em',
                        marginTop: '15px',
                        textAlign: 'left',
                        color: '#EA4335',
                        width: '68px',
                        height: '16px',
                        background: '#EA43351A',
                        padding: '0px 4px', // Add padding
                        borderRadius: '4px', // Add border radius
                      }}
                    >
                      Rejected
                    </Typography>

                    <div
                      style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '20px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#6E7C87',
                          }}
                        >
                          Created Date:
                        </Typography>

                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 700,
                            lineHeight: '20px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#252C32',
                            marginLeft: '8px', // Adjust spacing between components
                          }}
                        >
                          {formattedDate}
                        </Typography>
                      </div>

                      {item.dataFile.user &&
                        item.dataFile.user.firstname &&
                        item.dataFile.user.lastname && (
                          <Tooltip
                            title={`${item.dataFile.user.firstname} ${item.dataFile.user.lastname}`}
                            arrow
                          >
                            <Avatar
                              style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '12px',
                                background: '#D7EDFF',
                                color: '#0086DC',
                                fontWeight: 700,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: 'auto', // Pushes the Avatar to the right
                                fontSize: '10px', // Adjust font size of the Avatar content
                              }}
                            >
                              {item.dataFile.user.firstname.charAt(0)}
                              {item.dataFile.user.lastname.charAt(0)}
                            </Avatar>
                          </Tooltip>
                        )}
                    </div>
                  </CardContent>
                </Card>
              )
            }
          })}
        </Grid>
        {/* {isPopupOpen && (
          <div
          ref={popupRef}
            style={{
              position: "absolute",
              top: `${popupPosition.top}px`,
              right: `${popupPosition.right}px`,
              background: "rgba(255, 255, 255, 1)",
              padding: "20px",
              borderRadius: "8px",
              border: "1px solid #E8E8E8",
              boxShadow: "0px 0px 35px 0px rgba(0, 0, 0, 0.1)",
              zIndex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            {/* Your popup content */}
        {/* <div
              onClick={() => handleView(file)}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "10px",
                padding: "10px",
              }}
            >
              <img
                src={ViewIcon}
                alt="Edit Icon"
                style={{ marginRight: "10px" }}
              />
              <Typography
                style={{
                  color: "#252C32",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 800,
                  lineHeight: "normal",
                  letterSpacing: "-0.084px",
                }}
              >
                View Trial
              </Typography>
            </div> */}
        {/* <div onClick={handleEdit} style={{ display: "flex", alignItems: 'center', cursor: 'pointer',padding:"10px"}}>
                            <img src={EditIcon} alt="Edit Icon" style={{ marginRight: '10px' }} />
                            <Typography
                            style={{
                                color: '#252C32',
                                fontFamily: 'Inter',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 800,
                                lineHeight: 'normal',
                                letterSpacing: '-0.084px',
                            }}>Edit Trial</Typography>
                        </div>
                        <div onClick={handleEdit} style={{ display: "flex", alignItems: 'center', cursor: 'pointer',padding:"10px"}}>
                            <img src={AddIcon} alt="Edit Icon" style={{ marginRight: '10px' }} />
                            <Typography
                            style={{
                                color: '#252C32',
                                fontFamily: 'Inter',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 800,
                                lineHeight: 'normal',
                                letterSpacing: '-0.084px',
                            }}>Add Trial</Typography>
                        </div> */}
        {/* <div
              onClick={handleApproval}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "10px",
              }}
            >
              <img
                src={ApprovalIcon}
                alt="Edit Icon"
                style={{ marginRight: "10px" }}
              />
              <Typography
                style={{
                  color: "#252C32",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 800,
                  lineHeight: "normal",
                  letterSpacing: "-0.084px",
                }}
              >
                Approval
              </Typography>
            </div> */}
        {/* <div
              onClick={handleRejected}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "10px",
              }}
            >
              <img
                src={ApprovalIcon}
                alt="Edit Icon"
                style={{ marginRight: "10px" }}
              />
              <Typography
                style={{
                  color: "#252C32",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 800,
                  lineHeight: "normal",
                  letterSpacing: "-0.084px",
                }}
              >
                Rejected
              </Typography>
            </div> */}
        {/* <div
              onClick={handleDelete}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "10px",
              }}
            >
              <img
                src={DeleteIcon}
                alt="Delete Icon"
                style={{ marginRight: "10px" }}
              />
              <Typography
                style={{
                  color: "#FF3347",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 800,
                  lineHeight: "normal",
                  letterSpacing: "-0.084px",
                }}
              >
                Delete
              </Typography>
            </div> */}
        {/* </div> */}
        {/* )} */}
      </div>

      {/* <ViewTrial SampleId={SampleId} /> */}
      {/* <ExtractedDataPage extractedData={extractedData}/> */}
    </div>
  )
}
export default NRMPerformnace
