import { React, useState } from 'react'
import SelectBox from '../../input/SelectBox'
import TextBox from '../../input/TextBox'
import Helper from '../../services/Helper'
import { useRecoilState } from 'recoil'
import { charge_mix_details, general_details } from '../../../recoil/atoms'

const ChargeMixDetails = () => {
  const [chargeMixDetails, setChargeMixDetails] =
    useRecoilState(charge_mix_details)
  const [generalDetails] = useRecoilState(general_details)
  const uniqueId = generalDetails.id ? generalDetails.id : ''
  const [value, setValue] = useState('')

  const handleChange = (e) => {
    const { name, value } = e.target
    const TrialName = localStorage.getItem('Trial')
    const regex = /^\d{0,5}(\.\d{0,2})?$/

    if (regex.test(value)) {
      const updatedDetails = { ...chargeMixDetails, [name]: value }

      if (TrialName === 'new') {
        updatedDetails.id = uniqueId
      }

      // Calculate total charge after setting the value
      const total = calculateTotalCharge(updatedDetails)

      // Set the updated details and total charge into Recoil state
      setChargeMixDetails({
        ...updatedDetails,
        total_charge: total.toFixed(2), // Adding total charge here
      })
    } else {
      e.target.value = ''
    }
  }

  const calculateTotalCharge = (details) => {
    let total = 0
    const chargeFields = [
      'scrap',
      'shredded',
      'pig_iron',
      'pooled_iron',
      'hot_heel',
      'lump',
      'pellet',
      'fines_lessthan_5mm',
      'fines_lessthan_1mm',
      'slag_crushers',
      'tundish_or_slag_or_metalic_jam',
    ]

    chargeFields.forEach((field) => {
      if (details[field] && !isNaN(details[field])) {
        total += parseFloat(details[field])
      }
    })

    return total
  }

  return (
    <div>
      <div style={{ flex: 1, marginTop: '15px' }}>
        <TextBox
          name="scrap"
          label="Scrap (MT)"
          type="text"
          value={chargeMixDetails.scrap}
          onChange={handleChange}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="shredded"
          label="Shredded (MT)"
          type="text"
          value={chargeMixDetails.shredded}
          onChange={handleChange}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="pig_iron"
          label="Pig Iron (MT)"
          type="text"
          value={chargeMixDetails.pig_iron}
          onChange={handleChange}
        />
      </div>
      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="pooled_iron"
          label="Pooled Iron (MT)"
          type="text"
          value={chargeMixDetails.pooled_iron}
          onChange={handleChange}
        />
      </div>
      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="hot_heel"
          label="Hot Heel (MT)"
          type="text"
          value={chargeMixDetails.hot_heel}
          onChange={handleChange}
        />
      </div>
      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="lump"
          label="Lump (MT)"
          type="text"
          value={chargeMixDetails.lump}
          onChange={handleChange}
        />
      </div>
      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="pellet"
          label="Pellet (MT)"
          type="text"
          value={chargeMixDetails.pellet}
          onChange={handleChange}
        />
      </div>
      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="fines_lessthan_5mm"
          label="Fines less than 5 mm(MT)"
          type="text"
          value={chargeMixDetails.fines_lessthan_5mm}
          onChange={handleChange}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="fines_lessthan_1mm"
          label="Fines less than 1 mm (MT)"
          type="text"
          value={chargeMixDetails.fines_lessthan_1mm}
          onChange={handleChange}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="slag_crushers"
          label="Slag Crushers (MT)"
          type="text"
          value={chargeMixDetails.slag_crushers}
          onChange={handleChange}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="tundish_or_slag_or_metalic_jam"
          label="Tundish or Slag or Metalic Jam(MT)"
          type="text"
          value={chargeMixDetails.tundish_or_slag_or_metalic_jam}
          onChange={handleChange}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="total_charge"
          label="Total charge(MT)"
          type="text"
          value={chargeMixDetails.total_charge}
          onChange={handleChange}
        />
      </div>
    </div>
  )
}

export default ChargeMixDetails
