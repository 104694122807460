import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Grid,
  Tabs,
  Tab,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Typography,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Checkbox,
} from '@mui/material'
import { Delete, Visibility } from '@mui/icons-material'
import { Modal } from '@mui/material'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import * as XLSX from 'xlsx'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import UploadIcon from '../../assets/icons/ic_cloud.svg'
import { v4 as uuidv4 } from 'uuid'
import MenuItem from '@mui/material/MenuItem'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import API from '../services/API'
import axios from 'axios'
import Helper from '../services/Helper'
import AutoSelectBox from '../input/AutoSelectBox'
import ViewCompleteData from './ViewCompleteData'
import { ReactComponent as DownloadIcon } from '../../assets/icons/download_icon.svg'
import AlertDialog from '../input/AlertDialog'

const ReportsDataSetup = () => {
  const template_id = localStorage.getItem('match_template_id')
  //console.log("template_id",template_id);

  const [showModal, setShowModal] = useState(false)
  const [templateId, setTemplateId] = useState('')
  const [templates, setTemplates] = useState([])
  const [selectedTab, setSelectedTab] = useState('')
  const [reportsDataSeup, setReportsDataSeup] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [viewExcel, setViewExcel] = useState(false)
  const navigate = useNavigate()
  const [fileTemplate, setfileTemplate] = useState([])

  const [showModals, setShowModals] = useState(false)
  const [reportName, setReportName] = useState('')
  const [desc, setDesc] = useState('')
  const [minDate, setMinDate] = useState('')
  const [maxDate, setMaxDate] = useState('')

  const [selectedTemplate, setSelectedTemplate] = useState('')
  const [templateError, setTemplateError] = useState('')
  const [selectedGrade, setSelectedGrade] = useState('')
  const [selectedLiningNumber, setSelectedLiningNumber] = useState('')
  const [selectedMinimumYield, setSelectedMinimumYield] = useState('')
  const [selectedMaximumYield, setSelectedMaximumYield] = useState('')
  const [selectedMinimumTappedTonnage, setSelectedMinimumTappedTonnage] =
    useState('')
  const [selectedMaximumTappedTonnage, setSelectedMaximumTappedTonnage] =
    useState('')

  const [file, setFile] = useState(null)
  const [selectedRowFilename, setSelectedRowFilename] = useState('')

  const [furnaceCapacity, setFurnaceCapacity] = useState('')
  const [customer, setCustomer] = useState('')
  const [isDatabaseSearchSelected, setIsDatabaseSearchSelected] =
    useState(false)
  const [isUploadFileSelected, setIsUploadFileSelected] = useState(false)
  const [reportCardDetails, setReportCardDetails] = useState([])

  const cardId = localStorage.getItem('reportCardId')
  const [data, setData] = useState({})
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [grade, setGrade] = useState('')
  const [liningPatching, setLiningPatching] = useState('')
  const [minYield, setMinYield] = useState('')
  const [maxYield, setMaxYield] = useState('')
  const [minTappedTonnage, setMinTappedTonnage] = useState('')
  const [maxTappedTonnage, setMaxTappedTonnage] = useState('')
  const [formErrors, setFormErrors] = useState({})
  const [customers, setCustomers] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [match_template, setMatchTemplate] = useState('')
  const [formData, setFormData] = useState({
    customer_id: '',
    furnace_id: '',
    template_id: '',
  })

  const [tabs, setTabs] = useState([])

  const tab = localStorage.getItem('selectedTabLabel')

  const [furnaces, setFurnaces] = useState([])
  const access_token = localStorage.getItem('accesstoken')

  useEffect(() => {
    const selectedTabLabel = 'Daily Trial Monitoring'
    localStorage.setItem('selectedTabLabel', selectedTabLabel)
  }, [])

  const handleModalOpens = () => {
    setShowModals(true)
    fetchCustomers()
    fetchTemplates()
  }

  useEffect(() => {
    fetchCustomers()
    fetchTemplates()
  }, [])

  const fetchTemplates = async () => {
    try {
      const response = await axios.get(API.GET_TEMPLATES) // Adjust the URL as needed
      //console.log("responsetemplates", response);
      setTemplates(response.data) // Update state with fetched data
    } catch (error) {
      //console.error("Error fetching data:", error);
    }
  }

  const fetchCustomers = async () => {
    try {
      const response = await axios.get(API.GET_CUSTOMER, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }) // Adjust the URL as needed
      //console.log("response", response);
      setCustomers(response.data) // Update state with fetched data
    } catch (error) {
      //console.error("Error fetching data:", error);
    }
  }

  const getCustomerNameById = (customerId) => {
    //console.log("customerId", customerId);
    const customer = customers.find((customer) => customer.id === customerId)
    //console.log("customer", customer);
    // return customer ? customer.organization_name : 'Unknown Customer';
    return customer.organization_name
  }

  const handleChange = async (event) => {
    const { name, value } = event.target

    // If the name is 'customer_id', directly set the value in the state
    if (name === 'customer_id') {
      setFormData({ ...formData, [name]: value })
      await fetchFurnaceData(value)
    } else {
      // Otherwise, update the form data as usual
      setFormData({ ...formData, [name]: value })
    }
  }

  const getCustomerName = async () => {
    const getCustomerDetails = customers.find(
      (customer) => customer.id === formData.customer_id
    )
    const customerName = getCustomerDetails.organization_name
    const trigram = Helper.getCutomerTrigram(customerName)
    return trigram
  }

  const fetchFurnaceData = async (value) => {
    try {
      const response = await axios.get(API.GET_FURNACES, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      //console.log("furnace response", response);
      //console.log("value", value);
      //   const matchingFurnace = response.data.find(furnace => furnace.assign_customer_id === value);
      const matchingFurnaces = response.data.filter(
        (furnace) => furnace.assign_customer_id === value
      )

      if (matchingFurnaces) {
        //console.log("Matching Furnace:", matchingFurnaces);
        const matchingFurnaceArr = matchingFurnaces
        setFurnaces(matchingFurnaceArr)
        // Do something with the matching furnace object
      } else {
        setFurnaces([])
        //console.log("No matching furnace found");
      }
      //   setFurnaces(response.data);
    } catch (error) {
      //console.error("Error fetching users:", error);
    }
  }

  const handleModalCloses = () => {
    setShowModals(false)
    window.location.reload()
  }

  const handleFileUploads = (e) => {
    e.preventDefault()
    const uploadedFiles = e.target.files
    ////console.log("Uploaded Files:", uploadedFiles);
    if (uploadedFiles.length === 0) {
      // No file selected, set file state to null
      setFile(null)
      return
    }

    const uploadedFile = uploadedFiles[0]
    //console.log("Uploaded File:", uploadedFile);
    setFile(uploadedFile)
  }

  const handleButtonClick = (e) => {
    //console.log("button cliked")
    e.preventDefault()
    // Trigger file input click event
    const fileInput = document.getElementById('file-input')
    fileInput.click()
    // handleFormSubmit();
  }

  const handleFormSubmit = async (e) => {
    if (minDate && !maxDate) {
      alert('Maximum Date is required when Minimum Date is selected.')
      return
    }

    if (!minDate && maxDate) {
      alert('Minimum Date is required when Maximum Date is selected.')
      return
    }

    if (selectedMinimumYield && !selectedMaximumYield) {
      alert('Maximum Yield is required when Minimum Yield is selected.')
      return
    }

    if (!selectedMinimumYield && selectedMaximumYield) {
      alert('Minimum Yield is required when Maximum Yield is selected.')
      return
    }

    if (selectedMinimumTappedTonnage && !selectedMaximumTappedTonnage) {
      alert(
        'Maximum Tapped Tonnage is required when Minimum Tapped Tonnage is selected.'
      )
      return
    }

    if (!selectedMinimumTappedTonnage && selectedMaximumTappedTonnage) {
      alert(
        'Minimum Tapped Tonnage is required when Maximum Tapped Tonnage is selected.'
      )
      return
    }
    //console.log('hello:')
    // navigate('/ReportAnalysis')
    //   if (!selectedTemplate) {
    //     setTemplateError('Please select a template');
    //     return;
    // }
    try {
      const jsonData = {
        report_name: reportName,
        description: desc,
        ...formData,
      }

      const matchedTemplate = templates.find(
        (template) => template.id === formData.template_id
      )
      //console.log("matchedTemplate", matchedTemplate);
      const match_template_id = matchedTemplate.template_id
      setTemplateId(match_template_id)

      //console.log("match_template_id", match_template_id);

      if (isDatabaseSearchSelected && cardId) {
        const userString = localStorage.getItem('user')

        // Parse the JSON string into a JavaScript object
        const user = JSON.parse(userString)
        //console.log("user",user);
        const created_by = user.firstname + ' ' + user.lastname
        //console.log("created_by",created_by);

        if (match_template_id === 1) {
          //console.log("isDatabaseSearchSelected") ;
          const jsonDatabaseSearch = {
            reports_id: cardId,
            created_by: created_by,
            customer_id: formData.customer_id,
            database_name: reportName,
            furnace_id: formData.furnace_id,
            from_date: minDate,
            to_date: maxDate,
            grade: selectedGrade,
            lining_patching: selectedLiningNumber,
            min_yield: selectedMinimumYield,
            max_yield: selectedMaximumYield,
            min_tapped_tonnage: selectedMinimumTappedTonnage,
            max_tapped_tonnage: selectedMaximumTappedTonnage,
            template_id: formData.template_id,
          }

          //console.log("jsonDatabaseSearch", jsonDatabaseSearch) ;

          const result = await axios.post(
            API.DATABASE_SEARCH,
            jsonDatabaseSearch
          )

          if (result.status === 200) {
            AlertDialog({
              type: 'success',
              title: 'Success',
              text: 'Uploaded Successfully!',
              confirmButtonText: 'Ok',
              onConfirm: async () => {
                window.location.reload()
              },
            })
            setShowModals(false)
          } else {
            //console.error('Error saving data:', result.data);
            AlertDialog({
              type: 'warning',
              title: 'Error',
              text: 'Failed to save data',
            })
          }
        } else if (match_template_id === 2) {
          //console.log("isDatabaseSearchSelected") ;
          const jsonDatabaseSearch = {
            reports_id: cardId,
            created_by: created_by,
            customer_id: formData.customer_id,
            database_name: reportName,
            furnace_id: formData.furnace_id,
            from_date: minDate,
            to_date: maxDate,
            crucible: selectedGrade,
            liningPatching: selectedLiningNumber,
            template_id: formData.template_id,
          }

          //console.log("jsonDatabaseSearch", jsonDatabaseSearch) ;

          const result = await axios.post(
            API.DATABASE_SEARCH_NRM,
            jsonDatabaseSearch
          )

          if (result.status === 200) {
            AlertDialog({
              type: 'success',
              title: 'Success',
              text: 'Uploaded Successfully!',
              confirmButtonText: 'Ok',
              onConfirm: async () => {
                window.location.reload()
              },
            })
            setShowModals(false)
          } else {
            //console.error('Error saving data:', result.data);
            AlertDialog({
              type: 'warning',
              title: 'Error',
              text: 'Failed to save data',
            })
          }
        }
        // Add validation logic for database search fields
        // if (!minDate || !maxDate) {
        //   setDatabaseSearchError('Please select both minimum and maximum dates');
        //   return;
        // }
        // // Reset error message for database search fields
        // setDatabaseSearchError('');
      } else if (isUploadFileSelected && cardId) {
        if (file) {
          //console.log("good");
          const reports_id = cardId
          if (match_template_id === 1) {
            //console.log("good");
            await handleExtractData(match_template_id, file)
            const extractedData = JSON.parse(
              localStorage.getItem('extractedData')
            )
            //console.log("extractedData", extractedData);

            const predefinedHeaders1 = [
              'Furnace No.',
              'Crucible No.',
              'Campaign No.',
              'Lining / Patching No',
              'Heat No',
              'Date',
              'Customer Ref No',
              'Grade',
              'Heat ON Time',
              'Heat TAP Time',
              'Breakdown duration',
              'Heat Duration actual',
              'Heat Duration calculated',
              'Tapping temperature',
              'Tapped tonnage',
              'Melt Rate based on actual duration',
              'Melt Rate based on calculated duration',
              'Frequency',
              'Voltage',
              'Power In the panel',
              'Capacitors Removed',
              'Total Power Consumed',
              'Energy consumption per unit Ton actual',
              'Energy consumption per unit Ton calculated',
              'Scrap',
              'Shredded',
              'Pig Iron',
              'Pooled Iron',
              'Hot Heel',
              'Lump',
              'Pellet',
              'Fines less than 5 mm',
              'Fines less than 1 mm',
              'Slag Crushers',
              'Tundish or Slag or Metalic Jam',
              'Total charge',
              'CPC ',
              'Met Coke',
              '50 C%',
              '85 C%',
              'Final C%',
              'Si% Before addition',
              'Final Si%',
              'Mn% before Addition',
              'Final Mn%',
              'Cr% before addition',
              'Final Cr%',
              'SiMn',
              'Mn % in SiMn',
              'FeMn',
              'FeSi',
              'FeCr',
              'Mill scale',
              'Any other Alloy addition',
              'Inlet water temp.',
              'Outlet Water temp.',
              'Water Pr.',
              'Remarks',
            ] // Define your predefined headers here
            const predefinedHeaders2 = [
              'Furnace No.',
              'Crucible No.',
              'Campaign No.',
              'Lining / Patching No',
              'Heat No',
              'Date',
              'Customer Ref No',
              'Grade',
              'Heat ON Time',
              'Heat TAP Time',
              'Breakdown duration',
              'Heat Duration actual',
              'Heat Duration calculated',
              'Tapping temperature',
              'Tapped tonnage',
              'Melt Rate based on actual duration',
              'Melt Rate based on calculated duration',
              'Frequency',
              'Voltage',
              'Power In the panel',
              'Capacitors Removed',
              'Total Power Consumed',
              'Energy consumption per unit Ton actual',
              'Energy consumption per unit Ton calculated',
              'Scrap',
              'Shredded',
              'Pig Iron',
              'Pooled Iron',
              'Hot Heel',
              'Lump',
              'Pellet',
              'Fines less than 5 mm',
              'Fines less than 1 mm',
              'Slag Crushers',
              'Tundish or Slag or Metalic Jam',
              'Total charge',
              'Anthracite',
              'Met Coke',
              '50 C%',
              '85 C%',
              'Final C%',
              'Si% Before addition',
              'Final Si%',
              'Mn% before Addition',
              'Final Mn%',
              'Cr% before addition',
              'Final Cr%',
              'SiMn',
              'Mn % in SiMn',
              'FeMn',
              'FeSi',
              'FeCr',
              'Mill scale',
              'Any other Alloy addition',
              'Inlet water temp.',
              'Outlet Water temp.',
              'Water Pr.',
              'Remarks',
            ]
            const predefinedHeaders3 = [
              'Furnace No.',
              'Crucible No.',
              'Campaign No.',
              'Lining / Patching No',
              'Heat No',
              'Date',
              'Customer Ref No',
              'Grade',
              'Heat ON Time',
              'Heat TAP Time',
              'Breakdown duration',
              'Heat Duration actual',
              'Heat Duration calculated',
              'Tapping temperature',
              'Tapped tonnage',
              'Melt Rate based on actual duration',
              'Melt Rate based on calculated duration',
              'Frequency',
              'Voltage',
              'Power In the panel',
              'Capacitors Removed',
              'Total Power Consumed',
              'Energy consumption per unit Ton actual',
              'Energy consumption per unit Ton calculated',
              'Scrap',
              'Shredded',
              'Pig Iron',
              'Pooled Iron',
              'Hot Heel',
              'Lump',
              'Pellet',
              'Fines less than 5 mm',
              'Fines less than 1 mm',
              'Slag Crushers',
              'Tundish or Slag or Metalic Jam',
              'Total charge',
              'Carbon block ',
              'Met Coke',
              '50 C%',
              '85 C%',
              'Final C%',
              'Si% Before addition',
              'Final Si%',
              'Mn% before Addition',
              'Final Mn%',
              'Cr% before addition',
              'Final Cr%',
              'SiMn',
              'Mn % in SiMn',
              'FeMn',
              'FeSi',
              'FeCr',
              'Mill scale',
              'Any other Alloy addition',
              'Inlet water temp.',
              'Outlet Water temp.',
              'Water Pr.',
              'Remarks',
            ]
            const extractedHeaders = extractedData.headers
            //console.log("extractedHeaders", extractedHeaders);

            function arraysEqual(arr1, arr2) {
              // Check if any item in arr1 exists in arr2
              return arr1.some((item) => arr2.includes(item))
            }

            const headersMatch =
              arraysEqual(predefinedHeaders1, extractedHeaders) ||
              arraysEqual(predefinedHeaders2, extractedHeaders) ||
              arraysEqual(predefinedHeaders3, extractedHeaders)

            if (headersMatch) {
              //console.log("good");

              const Data = extractedData.data
              //console.log("Data",Data);
              //console.log("reports_idreports_id",reports_id);
              if (reports_id && Data) {
                //console.log("good");
                const general_details = []
                const furnace_details = []
                const charge_mix_details = []
                const carbon = []
                const alloying_addition = []
                const other_parameters = []
                const convertToHHMM = (value) => {
                  const totalHours = value * 24
                  const hours = Math.floor(totalHours)
                  const minutes = Math.round((totalHours - hours) * 60)
                  const formattedHours = String(hours)
                  const formattedMinutes = String(minutes).padStart(2, '0')
                  return `${formattedHours}:${formattedMinutes}`
                }

                const formatDecimal = (value) => {
                  // Ensure at least 5 digits before the decimal point
                  let integerValue = Math.abs(value)
                  if (integerValue >= 100000) {
                    integerValue = Math.round(integerValue / 1000) * 1000 // Round off to the nearest thousand
                  }

                  if (!Number.isInteger(value)) {
                    return Number(value).toFixed(2)
                  } else {
                    return `${value}.00`
                  }
                }

                const formatPercentage = (value) => {
                  const formattedPercentage = (value * 100).toFixed(2) + '%'
                  return `${formattedPercentage}`
                }

                const decimalHoursToHHMM = (value) => {
                  // Convert decimal hours to total minutes
                  var totalMinutes = value * 60

                  // Calculate hours and minutes
                  var hours = Math.floor(totalMinutes / 60)
                  var minutes = Math.round(totalMinutes % 60)

                  // If minutes is 60, increment hours and set minutes to 0
                  if (minutes === 60) {
                    hours++
                    minutes = 0
                  }

                  // Format the output as HH:mm
                  var formattedTime =
                    hours.toString().padStart(2, '0') +
                    ':' +
                    minutes.toString().padStart(2, '0')

                  return formattedTime
                }

                const formatDate = (value) => {
                  // Convert value to a JavaScript Date object
                  const date = new Date(
                    value * 24 * 60 * 60 * 1000 + Date.UTC(1899, 11, 30)
                  )

                  // Extract day, month, and year from the Date object
                  const day = date.getUTCDate()
                  const month = date.getUTCMonth() + 1 // Month is zero-based
                  const year = date.getUTCFullYear()

                  // Format day and month with single digits if necessary
                  const formattedDay = day < 10 ? '0' + day : day
                  const formattedMonth = month < 10 ? '0' + month : month

                  // Return the date in the desired format
                  return `${formattedMonth}/${formattedDay}/${year}`
                }

                // Data.forEach((entry) => {
                await Promise.all(
                  Data.map(async (entry) => {
                    //console.log("good");

                    const entryId = uuidv4()
                    //console.log("entryId", entryId);
                    const customerName = await getCustomerName()
                    // //console.log("customerName", customerName);

                    const isEmptyGeneralDetails =
                      !entry['Furnace No.'] &&
                      !entry['Crucible No.'] &&
                      !entry['Campaign No.'] &&
                      !entry['Lining / Patching No']

                    function getAbbreviatedLiningType(liningType) {
                      // Convert liningType to string if it's not already
                      const liningTypeStr = String(liningType)

                      // Split the string and build the abbreviation
                      let abbreviated = ''
                      liningTypeStr.split(' ').forEach((word) => {
                        if (word) {
                          // Ensure the word is not empty
                          abbreviated += word.charAt(0).toUpperCase()
                        }
                      })

                      return abbreviated
                    }

                    const lorp_id =
                      customerName +
                      (entry['Furnace No.'] ? entry['Furnace No.'] : '') +
                      '-' +
                      (entry['Crucible No.'] ? entry['Crucible No.'] : '') +
                      '-' +
                      (entry['Campaign No.'] ? entry['Campaign No.'] : '') +
                      '-' +
                      getAbbreviatedLiningType(entry['Lining / Patching No'])

                    if (!isEmptyGeneralDetails) {
                      //console.log("good");

                      general_details.push({
                        id: entryId,
                        furnace_no: entry['Furnace No.'],
                        crucible_no: entry['Crucible No.'],
                        campaign_no: entry['Campaign No.'],
                        lining_patch_no: entry['Lining / Patching No'],
                        heat_no: entry['Heat No'],
                        date: formatDate(entry['Date']),
                        customer_ref_no: entry['Customer Ref No'],
                        grade: entry['Grade'],
                        isdelete: false,
                        lorp_id: lorp_id,
                      })

                      furnace_details.push({
                        id: entryId,
                        heat_on_time: convertToHHMM(entry['Heat ON Time']),
                        heat_tap_time: convertToHHMM(entry['Heat TAP Time']),
                        break_down_duration: decimalHoursToHHMM(
                          entry['Breakdown duration']
                        ),

                        heat_duration_actual: convertToHHMM(
                          entry['Heat Duration actual']
                        ),
                        heat_duration_calculated: convertToHHMM(
                          entry['Heat Duration calculated']
                        ),
                        tapping_temperature: formatDecimal(
                          entry['Tapping temperature']
                        ),
                        tapped_tonnage: formatDecimal(entry['Tapped tonnage']),
                        melt_rate_based_on_actual_duration: formatDecimal(
                          entry['Melt Rate based on actual duration']
                        ),
                        melt_rate_based_on_calculated_duration: formatDecimal(
                          entry['Melt Rate based on calculated duration']
                        ),
                        frequency: formatDecimal(entry['Frequency']),
                        voltage: formatDecimal(entry['Voltage']),
                        power_in_the_panel: formatDecimal(
                          entry['Power In the panel']
                        ),
                        capacitors_removed: formatDecimal(
                          entry['Capacitors Removed']
                        ),
                        total_power_consumed: formatDecimal(
                          entry['Total Power Consumed']
                        ),
                        energy_consumption_per_unit_ton_actual: formatDecimal(
                          entry['Energy consumption per unit Ton actual']
                        ),
                        energy_consumption_per_unit_ton_calculated:
                          formatDecimal(
                            entry['Energy consumption per unit Ton calculated']
                          ),
                      })

                      charge_mix_details.push({
                        id: entryId,
                        scrap: formatDecimal(entry['Scrap']),
                        shredded: formatDecimal(entry['Shredded']),
                        pig_iron: formatDecimal(entry['Pig Iron']),
                        pooled_iron: formatDecimal(entry['Pooled Iron']),
                        hot_heel: formatDecimal(entry['Hot Heel']),
                        lump: formatDecimal(entry['Lump']),
                        pellet: formatDecimal(entry['Pellet']),
                        fines_lessthan_5mm: formatDecimal(
                          entry['Fines less than 5 mm']
                        ),
                        fines_lessthan_1mm: formatDecimal(
                          entry['Fines less than 1 mm']
                        ),
                        slag_crushers: formatDecimal(entry['Slag Crushers']),
                        tundish_or_slag_or_metalic_jam: formatDecimal(
                          entry['Tundish or Slag or Metalic Jam']
                        ),
                        total_charge: formatDecimal(entry['Total charge']),
                      })

                      carbon.push({
                        id: entryId,
                        cpc:
                          formatDecimal(entry['CPC ']) ||
                          formatDecimal(entry['Anthracite']) ||
                          formatDecimal(entry['Carbon block']),
                        met_coke: formatDecimal(entry['Met Coke']),
                        fifty_c_percentage: formatDecimal(entry['50 C%']),
                        eightyfive_c_percentage: formatDecimal(entry['85 C%']),
                        final_c_percentage: formatDecimal(entry['Final C%']),
                      })

                      alloying_addition.push({
                        id: entryId,
                        si_before_alloy_addition: formatPercentage(
                          entry['Si% Before addition']
                        ),
                        final_si: formatPercentage(entry['Final Si%']),
                        mn_before_alloy_addition: formatPercentage(
                          entry['Mn% before Addition']
                        ),
                        final_mn: formatPercentage(entry['Final Mn%']),
                        cr_before_alloy_addition: formatPercentage(
                          entry['Cr% before addition']
                        ),
                        final_cr: formatPercentage(entry['Final Cr%']),
                        si_mn: formatDecimal(entry['SiMn']),
                        mn_in_simn: entry['Mn % in SiMn'],
                        feMn: formatDecimal(entry['FeMn']),
                        feSi: formatDecimal(entry['FeSi']),
                        feCr: formatDecimal(entry['FeCr']),
                        mill_scale: formatDecimal(entry['Mill scale']),
                        any_other_alloy_addition:
                          entry['Any other Alloy addition'],
                      })

                      other_parameters.push({
                        id: entryId,
                        inlet_water_temp: formatDecimal(
                          entry['Inlet water temp.']
                        ),
                        outlet_water_temp: formatDecimal(
                          entry['Outlet Water temp.']
                        ),
                        water_pr: formatDecimal(entry['Water Pr.']),
                        remarks: entry['Remarks'],
                      })
                    }
                  })
                )
                //console.log("good");

                //console.log('general_detailsgeneral_details',general_details);

                const jsonDataexcel = Data.map((entry, index) => ({
                  general_details: general_details[index],
                  furnace_details: furnace_details[index],
                  charge_mix_details: charge_mix_details[index],
                  carbon: carbon[index],
                  alloying_addition: alloying_addition[index],
                  other_parameters: other_parameters[index],
                }))

                //console.log("jsonexxcel", jsonDataexcel);
                //console.log("jsonexxcel");

                const userString = localStorage.getItem('user')

                // Parse the JSON string into a JavaScript object
                const user = JSON.parse(userString)
                //console.log("user",user);
                const created_by = user.firstname + ' ' + user.lastname
                //console.log("created_by",created_by);

                axios
                  .post(
                    API.REPORTS_EXCEL_INSERT,
                    {
                      jsonDataexcel: jsonDataexcel,
                      reports_id: reports_id,
                      created_by: created_by,
                      customer_id: formData.customer_id,
                      file_name: file.name,
                      template_id: formData.template_id,
                    },
                    { headers: { 'Content-Type': 'application/json' } }
                  )
                  .then((excelresponse) => {
                    if (excelresponse.status === 200) {
                      AlertDialog({
                        type: 'success',
                        title: 'Success',
                        text: 'Uploaded Successfully!',
                        confirmButtonText: 'Ok',
                        onConfirm: async () => {
                          window.location.reload()
                        },
                      })
                      setShowModals(false)
                    } else {
                      //console.error('Error saving data:', excelresponse.data);
                      AlertDialog({
                        type: 'warning',
                        title: 'Error',
                        text: 'Failed to save data',
                      })
                    }
                    // setShowModals(false);
                    //console.log("Excel data inserted successfully:", excelresponse);
                  })
                  .catch((error) => {
                    //console.log('An error occurred while inserting Excel data:', error);
                    // Optionally, you can handle the error state here
                  })
              }
            } else {
              alert('Please attach the proper template')
              // setShowModals(false);
              return
              // Stop execution if headers don't match
            }
          } else if (match_template_id === 2) {
            await handleExtractData(match_template_id, file)
            const extractedData = JSON.parse(
              localStorage.getItem('extractedData')
            )
            //console.log("extractedData", extractedData);
            const predefinedHeaders = [
              'S.No.',
              'Furnace No.',
              'Crucible No.',
              'Campaign No.',
              'Lining or Patching',

              'Date of Completion lining or Patching',
              'Fce prod start date',
              'Fce prod start time',

              'Fce prod down date',
              'Fce prod down time',
              'Life',
              'Average Heat size',
              'Total Furnace Running Hours',

              'Total Production',
              'Total Furnace Running Hours',
              'Bottom Product',
              'Sidewall product 1 ',

              'Sidewall product 2',
              'Top Locking product',
              'Topping Mass Product',
              'Side wall 1  Coverage upto X%',

              'Side wall 2 Coverage upto 100%',
              'NRM consumption\r\nbottom',
              'NRM consumption Sidewall 1',

              'NRM consumption Sidewall 2',
              'NRM consumption at Top Locking',
              'Topping mass consumption',

              'Total NRM  consumption sidewall ',
              'NRM Specific consumption',
              'Former Dimension',

              'Coil coat ID',
              'Fce Ht.',
              'Castable top Ht.',
              'Bottom ht.',
              'Total Sintering time ',

              'holding time ',
              'holding temp ',
              'Reason for Down',
              'IR no. ',
              'Job Done by',
              'Remarks',
            ] // Define your predefined headers here
            const extractedHeaders = extractedData.headers
            function arraysEqual(arr1, arr2) {
              // Check if the arrays have the same length
              if (arr1.length !== arr2.length) return false

              // Check if all items are equal and in the same order
              for (let i = 0; i < arr1.length; i++) {
                if (arr1[i] !== arr2[i]) return false
              }

              return true
            }

            const headersMatch = arraysEqual(
              predefinedHeaders,
              extractedHeaders
            )
            //console.log("headersMatchheadersMatchheadersMatch", headersMatch);
            if (headersMatch) {
              const Data = extractedData.data
              //console.log("Data",Data);

              if (reports_id && Data) {
                const lining_reference = []
                const production_summary = []
                const lining_material_product_details = []
                const lining_material_consumption = []
                const dimensional_parameters = []
                const sintering_parameters = []
                const convertToHHMM = (value) => {
                  const totalHours = value * 24
                  const hours = Math.floor(totalHours)
                  const minutes = Math.round((totalHours - hours) * 60)
                  const formattedHours = String(hours)
                  const formattedMinutes = String(minutes).padStart(2, '0')
                  return `${formattedHours}:${formattedMinutes}`
                }

                const formatDecimal = (value) => {
                  // Ensure at least 5 digits before the decimal point
                  let integerValue = Math.abs(value)
                  if (integerValue >= 100000) {
                    integerValue = Math.round(integerValue / 1000) * 1000 // Round off to the nearest thousand
                  }

                  if (!Number.isInteger(value)) {
                    return Number(value).toFixed(2)
                  } else {
                    return `${value}.00`
                  }
                }

                const formatPercentage = (value) => {
                  const formattedPercentage = (value * 100).toFixed(2) + '%'
                  return `${formattedPercentage}`
                }

                const decimalHoursToHHMM = (value) => {
                  // Convert decimal hours to total minutes
                  var totalMinutes = value * 60

                  // Calculate hours and minutes
                  var hours = Math.floor(totalMinutes / 60)
                  var minutes = Math.round(totalMinutes % 60)

                  // If minutes is 60, increment hours and set minutes to 0
                  if (minutes === 60) {
                    hours++
                    minutes = 0
                  }

                  // Format the output as HH:mm
                  var formattedTime =
                    hours.toString().padStart(2, '0') +
                    ':' +
                    minutes.toString().padStart(2, '0')

                  return formattedTime
                }

                const formatDate = (value) => {
                  // Convert value to a JavaScript Date object
                  const date = new Date(
                    value * 24 * 60 * 60 * 1000 + Date.UTC(1899, 11, 30)
                  )

                  // Extract day, month, and year from the Date object
                  const day = date.getUTCDate()
                  const month = date.getUTCMonth() + 1 // Month is zero-based
                  const year = date.getUTCFullYear()

                  // Format day and month with single digits if necessary
                  const formattedDay = day < 10 ? '0' + day : day
                  const formattedMonth = month < 10 ? '0' + month : month

                  // Return the date in the desired format
                  return `${formattedMonth}/${formattedDay}/${year}`
                }

                await Promise.all(
                  Data.map(async (entry) => {
                    const entryId = uuidv4()
                    const customerName = await getCustomerName()
                    // const entryId = uuidv4();
                    const isEmptyGeneralDetails =
                      !entry['Furnace No.'] &&
                      !entry['Crucible No.'] &&
                      !entry['Campaign No.'] &&
                      !entry['Lining or Patching']

                    function getAbbreviatedLiningType(liningType) {
                      // Convert liningType to string if it's not already
                      const liningTypeStr = String(liningType)

                      // Split the string and build the abbreviation
                      let abbreviated = ''
                      liningTypeStr.split(' ').forEach((word) => {
                        if (word) {
                          // Ensure the word is not empty
                          abbreviated += word.charAt(0).toUpperCase()
                        }
                      })

                      return abbreviated
                    }

                    const lorp_id =
                      customerName +
                      (entry['Furnace No.'] ? entry['Furnace No.'] : '') +
                      '-' +
                      (entry['Crucible No.'] ? entry['Crucible No.'] : '') +
                      '-' +
                      (entry['Campaign No.'] ? entry['Campaign No.'] : '') +
                      '-' +
                      getAbbreviatedLiningType(entry['Lining or Patching'])

                    if (!isEmptyGeneralDetails) {
                      lining_reference.push({
                        id: entryId,
                        'S.No.': entry['S.No.'],
                        furnace_no: entry['Furnace No.'],
                        crucible_no: entry['Crucible No.'],
                        campaign_no: entry['Campaign No.'],
                        lining_or_patching: entry['Lining or Patching'],
                        date_of_completion_lining_or_patching: formatDate(
                          entry['Date of Completion lining or Patching']
                        ),
                        lorp_id: lorp_id,
                      })

                      production_summary.push({
                        id: entryId,
                        fce_prod_start_date: formatDate(
                          entry['Fce prod start date']
                        ),
                        fce_prod_start_time: convertToHHMM(
                          entry['Fce prod start time']
                        ),
                        fce_prod_down_date: formatDate(
                          entry['Fce prod down date']
                        ),
                        fce_prod_down_time: convertToHHMM(
                          entry['Fce prod down time']
                        ),
                        life: entry['Life'],
                        average_heat_size: formatDecimal(
                          entry['Average Heat size']
                        ),
                        total_furnace_running_hours: formatDecimal(
                          entry['Total Furnace Running Hours']
                        ),
                        total_production: formatDecimal(
                          entry['Total Production']
                        ),
                        total_furnace_running_hours: formatDecimal(
                          entry['Total Furnace Running Hours']
                        ),
                      })

                      lining_material_product_details.push({
                        id: entryId,
                        bottom_product: entry['Bottom Product'],
                        sidewall_product_one: entry['Sidewall product 1 '],
                        sidewall_product_two: entry['Sidewall product 2'],
                        top_locking_product: entry['Top Locking product'],
                        topping_mass_product: entry['Topping Mass Product'],
                        sidewall_one_coverage_upto_x: formatPercentage(
                          entry['Side wall 1  Coverage upto X%']
                        ),
                        sidewall_two_coverage_upto_100: formatPercentage(
                          entry['Side wall 2 Coverage upto 100%']
                        ),
                      })

                      lining_material_consumption.push({
                        id: entryId,

                        nrm_consumption_bottom: formatDecimal(
                          entry['NRM consumption\r\nbottom']
                        ),
                        nrm_consumption_sidewall_one: formatDecimal(
                          entry['NRM consumption Sidewall 1']
                        ),
                        nrm_consumption_sidewall_two: formatDecimal(
                          entry['NRM consumption Sidewall 2']
                        ),
                        nrm_consumption_top_locking: formatDecimal(
                          entry['NRM consumption at Top Locking']
                        ),
                        topping_mass_consumption: formatDecimal(
                          entry['Topping mass consumption']
                        ),
                        total_nrm_consumption_sidewall_one: formatDecimal(
                          entry['Total NRM  consumption sidewall ']
                        ),
                        nrm_specific_consumption: formatDecimal(
                          entry['NRM Specific consumption']
                        ),
                      })

                      dimensional_parameters.push({
                        id: entryId,
                        former_dimension: entry['Former Dimension'],
                        coil_coat_id: formatDecimal(entry['Coil coat ID']),
                        fce_ht: formatDecimal(entry['Fce Ht.']),
                        costable_top_ht: formatDecimal(
                          entry['Castable top Ht.']
                        ),
                        bottom_ht: formatDecimal(entry['Bottom ht.']),
                      })

                      sintering_parameters.push({
                        id: entryId,
                        total_sintering_time: convertToHHMM(
                          entry['Total Sintering time ']
                        ),
                        holding_time: formatDecimal(entry['holding time ']),
                        holding_temp: formatDecimal(entry['holding temp ']),
                        reason_for_down: entry['Reason for Down'],
                        IR_no: entry['IR no. '],
                        job_done_by: entry['Job Done by'],
                        remarks: entry['Remarks'],
                      })
                    }
                  })
                )

                const jsonDataexcel = Data.map((entry, index) => ({
                  lining_reference: lining_reference[index],
                  production_summary: production_summary[index],
                  lining_material_product_details:
                    lining_material_product_details[index],
                  lining_material_consumption:
                    lining_material_consumption[index],
                  dimensional_parameters: dimensional_parameters[index],
                  sintering_parameters: sintering_parameters[index],
                }))

                //console.log("jsonexxcel", jsonDataexcel);
                //console.log("jsonexxcel");

                const userString = localStorage.getItem('user')

                // Parse the JSON string into a JavaScript object
                const user = JSON.parse(userString)
                //console.log("user",user);
                const created_by = user.firstname + ' ' + user.lastname
                //console.log("created_by",created_by);

                axios
                  .post(
                    API.REPORTS_EXCEL_INSERT_NRM,
                    {
                      jsonDataexcel: jsonDataexcel,
                      reports_id: reports_id,
                      created_by: created_by,
                      customer_id: formData.customer_id,
                      file_name: file.name,
                      template_id: formData.template_id,
                    },
                    { headers: { 'Content-Type': 'application/json' } }
                  )
                  .then((excelresponse) => {
                    if (excelresponse.status === 200) {
                      AlertDialog({
                        type: 'success',
                        title: 'Success',
                        text: 'Uploaded Successfully!',
                        confirmButtonText: 'Ok',
                        onConfirm: async () => {
                          window.location.reload()
                        },
                      })
                      setShowModals(false)
                    } else {
                      //console.error('Error saving data:', excelresponse.data);
                      AlertDialog({
                        type: 'warning',
                        title: 'Error',
                        text: 'Failed to save data',
                      })
                    }
                    //console.log("Excel data inserted successfully:", excelresponse);
                  })
                  .catch((error) => {
                    //console.log('An error occurred while inserting Excel data:', error);
                    // Optionally, you can handle the error state here
                  })
              }
            } else {
              alert('Please attach the proper template')
              // setShowModals(false);
              return
              //  Stop execution if headers don't match
            }
          }
        }
      }

      // Add validation logic for upload file fields
      else {
        // setUploadFileError('Please upload a file');
        return
      }
      // Reset error message for upload file fields
      // setUploadFileError('');
    } catch (error) {
      // window.location.reload();
      //console.error('An error occurred while submitting form:', error);
      // Optionally, you can handle the error state here
    }
    // setShowModals(false);
    // window.location.reload();
  }

  const handleExtractData = async (match_template_id, file) => {
    try {
      if (file) {
        //console.log("good");
        //console.log("file",file);
        //console.log("template_idhandleExtractData", match_template_id);
        //console.log("filehandleExtractData", file);
        const reader = new FileReader()
        reader.readAsArrayBuffer(file)
        const extracted = await new Promise((resolve, reject) => {
          reader.onload = (e) => {
            const data = new Uint8Array(e.target.result)
            const workbook = XLSX.read(data, {
              type: 'array',
              cellStyles: true,
            })
            const worksheetName = workbook.SheetNames[0]
            const worksheet = workbook.Sheets[worksheetName]
            let headerRange
            if (match_template_id === 1) {
              headerRange = XLSX.utils.decode_range('A7:BF7')
            } else if (match_template_id === 2) {
              console.log(
                'template_idtemplate_idtemplate_idtemplate_id',
                match_template_id
              )
              headerRange = XLSX.utils.decode_range('A7:AO7')
              //console.log("headerRangeeee", headerRange);
            } else {
              //console.log("templatesss");
              reject('Invalid template')
              return
            }
            const headers = []
            if (match_template_id === 4) {
              // For template_id === 4, headers are in column A from row 1 to row 55
              let headerRangeA = XLSX.utils.decode_range('A1:A55')
              let headerRangeD = XLSX.utils.decode_range('D1:D55')

              for (let R = headerRangeA.s.r; R <= headerRangeA.e.r; ++R) {
                const cellAddressA = { c: headerRangeA.s.c, r: R }
                const headerCellA = XLSX.utils.encode_cell(cellAddressA)

                try {
                  const headerCellValueA = worksheet[headerCellA].v
                  headers.push(headerCellValueA) // Push header from column A
                } catch (error) {
                  console.error(
                    'An error occurred while extracting header from column A:',
                    error
                  )
                  reject('Invalid header')
                  return
                }
              }

              for (let R = headerRangeD.s.r; R <= headerRangeD.e.r; ++R) {
                const cellAddressD = { c: headerRangeD.s.c, r: R }
                const headerCellD = XLSX.utils.encode_cell(cellAddressD)

                //console.log("Reading header cell D:", headerCellD); // Add this logging

                try {
                  const headerCellValueD = worksheet[headerCellD].v
                  headers.push(headerCellValueD)
                } catch (error) {
                  console.error(
                    'An error occurred while extracting header from column D:',
                    error
                  )
                  reject('Invalid header')
                  return
                }
              }
            } else {
              for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
                const cellAddress = { c: C, r: headerRange.s.r }
                const headerCell = XLSX.utils.encode_cell(cellAddress)
                try {
                  const headerCellValue = worksheet[headerCell].v
                  headers.push(headerCellValue)
                } catch (error) {
                  console.error(
                    'An error occurred while extracting header:',
                    error
                  )
                  reject('Invalid header')
                  return
                }
              }
            }

            //console.log("hello");
            const dataRange = XLSX.utils.decode_range(worksheet['!ref'])
            //console.log(dataRange, "dataRange");
            const extractedData = []
            let startRow = 8
            if (match_template_id === 3) {
              startRow = 16
            }
            if (match_template_id === 4) {
              for (let R = dataRange.s.r; R <= dataRange.e.r; ++R) {
                const rowA = {}

                // Fetching header and data from column A
                const headerCellAddressA = { c: dataRange.s.c, r: R }
                const headerCellRefA =
                  XLSX.utils.encode_cell(headerCellAddressA)
                const headerValueA = worksheet[headerCellRefA]
                  ? worksheet[headerCellRefA].v
                  : undefined

                const dataCellAddressA = { c: dataRange.s.c + 1, r: R }
                const dataCellRefA = XLSX.utils.encode_cell(dataCellAddressA)
                const dataValueA = worksheet[dataCellRefA]
                  ? worksheet[dataCellRefA].v
                  : undefined

                // Adding data from column A to rowA
                rowA[headerValueA] = dataValueA

                // Pushing rowA to extractedData
                extractedData.push(rowA)
              }

              for (let R = dataRange.s.r; R <= dataRange.e.r; ++R) {
                const rowD = {}

                // Fetching header and data from column D
                const headerCellAddressD = { c: dataRange.s.c + 3, r: R }
                const headerCellRefD =
                  XLSX.utils.encode_cell(headerCellAddressD)
                const headerValueD = worksheet[headerCellRefD]
                  ? worksheet[headerCellRefD].v
                  : undefined

                const dataCellAddressD = { c: dataRange.s.c + 4, r: R }
                const dataCellRefD = XLSX.utils.encode_cell(dataCellAddressD)
                const dataValueD = worksheet[dataCellRefD]
                  ? worksheet[dataCellRefD].v
                  : undefined

                // Adding data from column D to rowD
                rowD[headerValueD] = dataValueD

                // Pushing rowD to extractedData
                extractedData.push(rowD)
              }
            } else {
              for (let R = startRow; R <= dataRange.e.r; ++R) {
                const row = {}
                for (let C = dataRange.s.c; C <= dataRange.e.c; ++C) {
                  const cellAddress = { c: C, r: R }
                  const cellRef = XLSX.utils.encode_cell(cellAddress)
                  const cellValue = worksheet[cellRef]
                    ? worksheet[cellRef].v
                    : undefined
                  row[headers[C]] = cellValue
                }
                extractedData.push(row)
              }
            }
            const result = { headers, data: extractedData }
            //console.log(result, "resultsss");
            localStorage.setItem('extractedData', JSON.stringify(result))
            resolve(result)
          }

          reader.onerror = (error) => {
            reject(error)
          }
        })
        return extracted
      }
    } catch (error) {
      console.error(
        'An error occurred while extracting data from Excel:',
        error
      )
      throw error
    }
  }

  const handleDatabaseSearchSelect = () => {
    setIsDatabaseSearchSelected(true)
    setIsUploadFileSelected(false)
  }

  const handleUploadFileSelect = () => {
    setIsDatabaseSearchSelected(false)
    setIsUploadFileSelected(true)
  }

  const grades = ['Grade 1', 'Grade 2', 'Grade 3'] // Example array of grades
  const crucible = ['A', 'B', 'C', 'D', 'E']
  const liningNumbers = [
    'New Lining',
    'Side Lining 1',
    'Side Lining 2',
    'Side Lining 3',
    'Side Lining 4',
    'Side Lining 5',
    'Side Lining 6',
    'Side Lining 7',
    'Side Lining 8',
    'Side Lining 9',
    'Side Lining 10',
    'Side Lining 11',
    'Side Lining 12',
    'Side Lining 13',
    'Side Lining 14',
    'Side Lining 15',
    'Patching 1',
    'Patching 2',
    'Patching 3',
    'Patching 4',
    'Patching 5',
    'Patching 6',
    'Patching 7',
    'Patching 8',
    'Patching 9',
    'Patching 10',
    'Patching 11',
    'Patching 12',
    'Patching 13',
    'Patching 14',
    'Patching 15',
    'Patching 16',
    'Patching 17',
    'Patching 18',
    'Patching 19',
    'Patching 20',
    'Patching 21',
    'Patching 22',
    'Patching 23',
    'Patching 24',
    'Patching 25',
  ]

  const handleGradeChange = (event) => {
    setSelectedGrade(event.target.value)
  }

  const handleLiningNumberChange = (event) => {
    setSelectedLiningNumber(event.target.value)
  }

  const handleMinimumYield = (event) => {
    setSelectedMinimumYield(event.target.value)
  }

  const handleMaximumYield = (event) => {
    setSelectedMaximumYield(event.target.value)
  }

  const handleMinimumTappedTonnage = (event) => {
    setSelectedMinimumTappedTonnage(event.target.value)
  }

  const handleMaximumTappedTonnage = (event) => {
    setSelectedMaximumTappedTonnage(event.target.value)
  }

  const handleModalClose = () => {
    setShowModals(false)
  }

  const handleTemplateChange = (event) => {
    const value = event.target.value
    setSelectedTemplate(value)
    setTemplateError(value ? '' : 'Please select a template')
  }

  const handleModalOpen = () => {
    setShowModal(true)
  }

  // const tabs = [
  //   { label: "Daily Trial Monitoring", value: "1278e494-7771-421c-8bbe-d0409031efa2" },
  //   { label: "NRM performance", value: "0271c530-6fb5-4b96-9576-3eadaab5c317" },
  //   { label: "Erosion profile", value: "492e5308-1ebd-4cf0-965b-0f323cc2e531" }
  // ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue)
    //console.log("newValue",newValue);
    const selectedTabLabel = tabs.find((tab) => tab.value === newValue)?.label
    localStorage.setItem('selectedTabLabel', selectedTabLabel)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url_tab = `${API.GET_TEMPLATETAB}`
        const response_tab = await axios.get(url_tab)
        const data = response_tab.data
        console.log('tab data', data)
        const trimmedData = data.slice(0, -2)
        const tab_data = trimmedData.map((item) => ({
          label: item.name,
          value: item.id,
        }))

        console.log('tab_data', tab_data)

        setTabs(tab_data)

        if (tab_data.length > 0) {
          setSelectedTab(tab_data[0].value)
        }

        const filetemplate = await axios.get(API.GET_TEMPLATES)
        const dataTemplate = filetemplate.data.map((item) => item.name)
        //console.log("dataTemplate",dataTemplate);
        setfileTemplate(dataTemplate)
        const cardId = localStorage.getItem('reportCardId')
        //console.log("cardIDDDDDD", cardId)

        const url = `${API.GET_REPORTS_DATA_SETUP}/${cardId}`
        const response = await axios.get(url)
        //console.log("response132", response)

        const urlData = `${API.GET_REPORTS_DATA_SETUP_NRM}/${cardId}`
        const responseData = await axios.get(urlData)
        //console.log("responseData", responseData);

        const combinedData = [
          ...response.data, // Assuming responseSetup.data is an array
          ...responseData.data, // Assuming responseData.data is an array
        ]

        //console.log("combinedData", combinedData);
        // Update state with the combined data
        setReportsDataSeup(combinedData)
      } catch (error) {
        //console.error('Error fetching data:', error);
      }
    }

    fetchData()
  }, [])

  const handleCheckboxChange = (event, id) => {
    const isChecked = event.target.checked
    if (isChecked) {
      setSelectedRows((prevSelectedRows) => {
        const updatedSelectedRows = [...prevSelectedRows, id]
        //console.log("selectedRows", updatedSelectedRows);
        return updatedSelectedRows
      })
    } else {
      setSelectedRows((prevSelectedRows) => {
        const updatedSelectedRows = prevSelectedRows.filter(
          (rowId) => rowId !== id
        )
        //console.log("selectedRows", updatedSelectedRows);
        return updatedSelectedRows
      })
    }
  }

  const handleSelectAllCheckboxChange = (event) => {
    const isChecked = event.target.checked
    if (isChecked) {
      const allIds = reportsDataSeup.map((row) => row.id)
      setSelectedRows(allIds)
      //console.log("ALLselectedRows",selectedRows )
    } else {
      setSelectedRows([])
    }
  }

  useEffect(() => {
    if (formData.template_id !== '') {
      const matchedTemplate = templates.find(
        (template) => template.id === formData.template_id
      )
      if (matchedTemplate) {
        const { template_id } = matchedTemplate
        setMatchTemplate(template_id)
        // localStorage.setItem('match_template_id', template_id);
      }
    }
  }, [formData.template_id, templates])

  useEffect(() => {
    //console.log(reportsDataSeup,'reportsDataSeup');

    const filteredRows = reportsDataSeup.filter(
      (row) => row.template_id === selectedTab
    )
    setFilteredData(filteredRows)

    const allIds = filteredRows.map((row) => row.id)
    setSelectedRows(allIds)
    //console.log("ALLselectedRows",selectedRows );
  }, [reportsDataSeup, selectedTab])

  const rowIdsString = selectedRows.join(',')
  localStorage.setItem('rowIdsString', rowIdsString)

  const handleViewCompleteData = () => {
    localStorage.setItem('View', 'ViewCompleteData')

    // Join the array elements into a comma-separated string
    const allIds = reportsDataSeup.map((row) => row.id)
    setSelectedRows(allIds)
    //console.log("ALLselectedRows",selectedRows )

    //console.log("selectedRows:", selectedRows);
    //console.log("rowIdsString:", rowIdsString);
    navigate(`/ViewCompleteData`, {
      state: {
        template: selectedTab,
      },
    })
  }

  const handleRowClick = (row) => {
    localStorage.setItem('View', 'singleView')

    // Here you can access the details of the clicked row and perform any actions you want
    //console.log('Clicked Row Details:', row);
    const rowIdsString = row.id
    localStorage.setItem('rowIdsString', rowIdsString)
    navigate(`/ViewCompleteData`)
  }

  const handleDeleteClick = async (row) => {
    // Here you can access the details of the clicked row and perform any actions you want
    //console.log('Clicked Row Details:', row);
    const rowIdsString = row.id

    if (tab === 'Daily Trial Monitoring') {
      try {
        const check = `${API.CHECK_ID_EXIST}/${rowIdsString}`
        const checkresponse = await axios.get(check)
        //console.log("checkresponse", checkresponse.data);

        if (
          checkresponse.data.length > 0 &&
          checkresponse.data[0].case === 'Exists'
        ) {
          //console.log("delete from excel");
          const check = `${API.DELETE_EXCEL}/${rowIdsString}`
          const checkresponse = await axios.put(check)
          window.location.reload()
        } else {
          //console.log("delete from db");
          const check = `${API.DELETE_DB}/${rowIdsString}`
          const checkresponse = await axios.put(check)
          window.location.reload()
        }
      } catch (error) {
        //console.error("Error checking ID existence:", error);
      }
    } else if (tab === 'NRM Performance') {
      try {
        const check = `${API.CHECK_ID_EXIST_NRM}/${rowIdsString}`
        const checkresponse = await axios.get(check)
        //console.log("checkresponse", checkresponse.data);

        if (
          checkresponse.data.length > 0 &&
          checkresponse.data[0].case === 'Exists'
        ) {
          //console.log("delete from excel");
          const check = `${API.DELETE_EXCEL_NRM}/${rowIdsString}`
          const checkresponse = await axios.put(check)
          window.location.reload()
        } else {
          //console.log("delete from db");
          const check = `${API.DELETE_DB_NRM}/${rowIdsString}`
          const checkresponse = await axios.put(check)
          window.location.reload()
        }
      } catch (error) {
        //console.error("Error checking ID existence:", error);
      }
    }
  }

  return (
    <div>
      <Grid
        container
        spacing={2}
        sx={{ border: '1px solid #E5E9EB', padding: '12px 10px 10px 10px' }}
      >
        <Grid item xs={10}>
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: 600,
              fontFamily: 'inter',
              padding: '5px',
              borderBottom: '2px solid #E5E9EB',
            }}
          >
            Reports Data List
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          container
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            style={{
              fontFamily: 'inter',
              fontSize: 16,
              fontWeight: 500,
              marginRight: '15px',
            }}
            onClick={handleViewCompleteData}
          >
            View Complete Data
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="Tabs"
            indicatorColor="primary"
            TabIndicatorProps={{ style: { width: '150px' } }}
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
          <Box display="flex" alignItems="center" ml="auto">
            {/* <IconButton color="primary" sx={{ ml: 2 }}>
            <DownloadIcon />
          </IconButton> */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleModalOpens}
              sx={{ ml: 2, marginRight: '15px' }}
            >
              Add Data Setup
            </Button>
          </Box>
        </Grid>
      </Grid>
      <div>
        <Modal
          open={showModals}
          onClose={handleModalCloses}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              width: 600,
              maxHeight: '90vh', // Set maximum height
              overflowY: 'auto',
              left: 660,
              padding: '8px 16px', // Individual padding values separated by space
              borderRadius: 3,
              background: 'white',
              border: 'none',
              gap: 16, // Gap is used for grid layouts, consider using margin for regular elements
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ flex: 1 }}>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: 18,
                    fontWeight: 600,
                    lineHeight: '24px',
                    letterSpacing: '-0.006em',
                    textAlign: 'left',
                    marginTop: '10px',
                    color: '#252C32',
                  }}
                >
                  Add Report
                </Typography>
              </div>
              <IconButton onClick={handleModalClose}>
                <CloseIcon />
              </IconButton>
            </div>

            <form>
              <div style={{ marginTop: '20px' }}>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: 14,
                    fontWeight: 700,
                    lineHeight: '17px',
                    letterSpacing: '-0.006em',
                    textAlign: 'left',
                    color: '#2D2B32',
                  }}
                >
                  Report Name
                </Typography>
                <TextField
                  id="customer-name"
                  label="Enter"
                  value={reportName}
                  onChange={(e) => setReportName(e.target.value)}
                  fullWidth
                  style={{ marginTop: '10px' }}
                />
              </div>

              <div style={{ marginTop: '20px' }}>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: 14,
                    fontWeight: 700,
                    lineHeight: '17px',
                    letterSpacing: '-0.006em',
                    textAlign: 'left',
                    color: '#2D2B32',
                  }}
                >
                  Description
                </Typography>
                <TextField
                  id="customer-name"
                  label="Enter"
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                  fullWidth
                  style={{ marginTop: '10px' }}
                />
              </div>

              <div style={{ marginTop: '20px' }}>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: 14,
                    fontWeight: 700,
                    lineHeight: '17px',
                    letterSpacing: '-0.006em',
                    textAlign: 'left',
                    color: '#2D2B32',
                  }}
                >
                  Customer{' '}
                </Typography>
                <AutoSelectBox
                  name="customer_id"
                  isLabel={true}
                  options={customers}
                  labelCondition="organization_name"
                  value={formData.customer_id}
                  onChange={handleChange}
                  error={formErrors.customer_id}
                />
              </div>

              <div style={{ marginTop: '20px' }}>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: 14,
                    fontWeight: 700,
                    lineHeight: '17px',
                    letterSpacing: '-0.006em',
                    textAlign: 'left',
                    color: '#2D2B32',
                  }}
                >
                  Select Furnace{' '}
                </Typography>
                <AutoSelectBox
                  name="furnace_id"
                  isLabel={true}
                  options={furnaces}
                  labelCondition="furnace_id"
                  value={formData.furnace_id}
                  onChange={handleChange}
                  error={formErrors.furnace_id}
                />
              </div>

              <div style={{ marginTop: '20px' }}>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: 14,
                    fontWeight: 700,
                    lineHeight: '17px',
                    letterSpacing: '-0.006em',
                    textAlign: 'left',
                    color: '#2D2B32',
                  }}
                >
                  Data File Template
                </Typography>
                <AutoSelectBox
                  name="template_id"
                  isLabel={true}
                  options={templates}
                  labelCondition="name"
                  value={formData.template_id}
                  onChange={handleChange}
                  error={formErrors.template_id}
                />
              </div>

              {match_template === 1 && (
                <Accordion style={{ marginTop: '20px', border: 'none' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    onClick={handleDatabaseSearchSelect}
                  >
                    <Typography variant="body1">DATABASE SEARCH</Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '20px',
                      }}
                    >
                      <div style={{ flex: 1, marginRight: '10px' }}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: 14,
                            fontWeight: 700,
                            lineHeight: '17px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#2D2B32',
                          }}
                        >
                          Minimum Date
                        </Typography>
                        <TextField
                          id="min-date"
                          type="date"
                          value={minDate}
                          onChange={(e) => setMinDate(e.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          sx={{
                            marginTop: '10px',
                            border: '1px solid #E6E6E6',
                            borderRadius: '8px',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#E0E0E0',
                              },
                              '&:hover fieldset': {
                                borderColor: '#A0A0A0',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#3f51b5',
                              },
                            },
                          }}
                        />
                      </div>
                      <div style={{ flex: 1 }}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: 14,
                            fontWeight: 700,
                            lineHeight: '17px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#2D2B32',
                          }}
                        >
                          Maximum Date
                        </Typography>
                        <TextField
                          id="max-date"
                          type="date"
                          value={maxDate}
                          onChange={(e) => setMaxDate(e.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          sx={{
                            marginTop: '10px',
                            border: '1px solid #E6E6E6',
                            borderRadius: '8px',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#E0E0E0',
                              },
                              '&:hover fieldset': {
                                borderColor: '#A0A0A0',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#3f51b5',
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '20px',
                      }}
                    >
                      <div style={{ flex: 1, marginRight: '10px' }}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: 14,
                            fontWeight: 700,
                            lineHeight: '17px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#2D2B32',
                          }}
                        >
                          Grade
                        </Typography>
                        <TextField
                          id="grade"
                          value={selectedGrade}
                          onChange={handleGradeChange}
                          fullWidth
                          sx={{
                            marginTop: '10px',
                            border: '1px solid #E6E6E6',
                            borderRadius: '8px',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#E0E0E0',
                              },
                              '&:hover fieldset': {
                                borderColor: '#A0A0A0',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#3f51b5',
                              },
                            },
                          }}
                        />
                      </div>
                      <div style={{ flex: 1 }}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: 14,
                            fontWeight: 700,
                            lineHeight: '17px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#2D2B32',
                          }}
                        >
                          Lining/Patching Number
                        </Typography>
                        <TextField
                          id="lining-number"
                          select
                          value={selectedLiningNumber}
                          onChange={handleLiningNumberChange}
                          fullWidth
                          sx={{
                            marginTop: '10px',
                            border: '1px solid #E6E6E6',
                            borderRadius: '8px',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#E0E0E0',
                              },
                              '&:hover fieldset': {
                                borderColor: '#A0A0A0',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#3f51b5',
                              },
                            },
                          }}
                        >
                          {liningNumbers.map((number, index) => (
                            <MenuItem key={index} value={number}>
                              {number}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '20px',
                      }}
                    >
                      <div style={{ flex: 1, marginRight: '10px' }}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: 14,
                            fontWeight: 700,
                            lineHeight: '17px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#2D2B32',
                          }}
                        >
                          Minimum Yield
                        </Typography>
                        <TextField
                          id="minimum-yield"
                          value={selectedMinimumYield}
                          onChange={handleMinimumYield}
                          fullWidth
                          sx={{
                            marginTop: '10px',
                            border: '1px solid #E6E6E6',
                            borderRadius: '8px',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#E0E0E0',
                              },
                              '&:hover fieldset': {
                                borderColor: '#A0A0A0',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#3f51b5',
                              },
                            },
                          }}
                        />
                      </div>
                      <div style={{ flex: 1 }}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: 14,
                            fontWeight: 700,
                            lineHeight: '17px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#2D2B32',
                          }}
                        >
                          Maximum Yield
                        </Typography>
                        <TextField
                          id="maximum-yield"
                          value={selectedMaximumYield}
                          onChange={handleMaximumYield}
                          fullWidth
                          sx={{
                            marginTop: '10px',
                            border: '1px solid #E6E6E6',
                            borderRadius: '8px',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#E0E0E0',
                              },
                              '&:hover fieldset': {
                                borderColor: '#A0A0A0',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#3f51b5',
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '20px',
                      }}
                    >
                      <div style={{ flex: 1, marginRight: '10px' }}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: 14,
                            fontWeight: 700,
                            lineHeight: '17px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#2D2B32',
                          }}
                        >
                          Minimum Tapped Tonnage
                        </Typography>
                        <TextField
                          id="minimum-tapped-tonnage"
                          value={selectedMinimumTappedTonnage}
                          onChange={handleMinimumTappedTonnage}
                          fullWidth
                          sx={{
                            marginTop: '10px',
                            border: '1px solid #E6E6E6',
                            borderRadius: '8px',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#E0E0E0',
                              },
                              '&:hover fieldset': {
                                borderColor: '#A0A0A0',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#3f51b5',
                              },
                            },
                          }}
                        />
                      </div>
                      <div style={{ flex: 1 }}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: 14,
                            fontWeight: 700,
                            lineHeight: '17px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#2D2B32',
                          }}
                        >
                          Maximum Tapped Tonnage
                        </Typography>
                        <TextField
                          id="maximum-tapped-tonnage"
                          value={selectedMaximumTappedTonnage}
                          onChange={handleMaximumTappedTonnage}
                          fullWidth
                          sx={{
                            marginTop: '10px',
                            border: '1px solid #E6E6E6',
                            borderRadius: '8px',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#E0E0E0',
                              },
                              '&:hover fieldset': {
                                borderColor: '#A0A0A0',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#3f51b5',
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}
              {match_template === 2 && (
                <Accordion style={{ marginTop: '20px', border: 'none' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    onClick={handleDatabaseSearchSelect}
                  >
                    <Typography variant="body1">DATABASE SEARCH</Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '20px',
                      }}
                    >
                      <div style={{ flex: 1, marginRight: '10px' }}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: 14,
                            fontWeight: 700,
                            lineHeight: '17px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#2D2B32',
                          }}
                        >
                          Minimum Date
                        </Typography>
                        <TextField
                          id="min-date"
                          type="date"
                          value={minDate}
                          onChange={(e) => setMinDate(e.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          sx={{
                            marginTop: '10px',
                            border: '1px solid #E6E6E6',
                            borderRadius: '8px',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#E0E0E0',
                              },
                              '&:hover fieldset': {
                                borderColor: '#A0A0A0',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#3f51b5',
                              },
                            },
                          }}
                        />
                      </div>
                      <div style={{ flex: 1 }}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: 14,
                            fontWeight: 700,
                            lineHeight: '17px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#2D2B32',
                          }}
                        >
                          Maximum Date
                        </Typography>
                        <TextField
                          id="max-date"
                          type="date"
                          value={maxDate}
                          onChange={(e) => setMaxDate(e.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          sx={{
                            marginTop: '10px',
                            border: '1px solid #E6E6E6',
                            borderRadius: '8px',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#E0E0E0',
                              },
                              '&:hover fieldset': {
                                borderColor: '#A0A0A0',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#3f51b5',
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '20px',
                      }}
                    >
                      <div style={{ flex: 1, marginRight: '10px' }}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: 14,
                            fontWeight: 700,
                            lineHeight: '17px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#2D2B32',
                          }}
                        >
                          Crucible
                        </Typography>
                        <TextField
                          id="grade"
                          value={selectedGrade}
                          onChange={handleGradeChange}
                          fullWidth
                          sx={{
                            marginTop: '10px',
                            border: '1px solid #E6E6E6',
                            borderRadius: '8px',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#E0E0E0',
                              },
                              '&:hover fieldset': {
                                borderColor: '#A0A0A0',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#3f51b5',
                              },
                            },
                          }}
                        >
                          {crucible.map((number, index) => (
                            <MenuItem key={index} value={number}>
                              {number}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                      <div style={{ flex: 1 }}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: 14,
                            fontWeight: 700,
                            lineHeight: '17px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#2D2B32',
                          }}
                        >
                          Lining/Patching Number
                        </Typography>
                        <TextField
                          id="lining-number"
                          select
                          value={selectedLiningNumber}
                          onChange={handleLiningNumberChange}
                          fullWidth
                          sx={{
                            marginTop: '10px',
                            border: '1px solid #E6E6E6',
                            borderRadius: '8px',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#E0E0E0',
                              },
                              '&:hover fieldset': {
                                borderColor: '#A0A0A0',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#3f51b5',
                              },
                            },
                          }}
                        >
                          {liningNumbers.map((number, index) => (
                            <MenuItem key={index} value={number}>
                              {number}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}

              <Typography
                variant="body1"
                align="center"
                style={{
                  margin: '20px 0',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Divider style={{ flex: 1 }} />
                <Typography variant="body1" style={{ margin: '0 10px' }}>
                  or
                </Typography>
                <Divider style={{ flex: 1 }} />
              </Typography>

              <Accordion style={{ marginTop: '20px' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  onClick={handleUploadFileSelect}
                >
                  <Typography variant="body1">UPLOAD DATA FILE </Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <div
                    style={{
                      border: '2px dashed #00448B',
                      padding: '20px',
                      flex: 1,
                      display: 'flex',
                      width: '100%',
                      height: '150px',
                      marginTop: '10px',
                      marginRight: 20,
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '4px',
                    }}
                  >
                    <label htmlFor="file-input">
                      <button
                        style={{
                          width: 30,
                          height: 30,
                          marginLeft: 10,
                          marginBottom: 10,
                          cursor: 'pointer',
                          padding: 0, // Remove padding to ensure image is not covered
                          border: 'none', // Remove border to ensure image is not covered
                          background: 'none', // Remove background to ensure image is not covered
                        }}
                        aria-label="Upload File"
                        onClick={handleButtonClick}
                      >
                        <img src={UploadIcon} alt="Upload File" />
                      </button>
                    </label>

                    <Typography
                      style={{
                        textAlign: 'center',
                        marginLeft: 15,
                        color: 'black',
                        fontWeight: 600,
                        width: 170,
                        fontSize: '14px',
                        color: '#0086DC',
                      }}
                    >
                      Browse files
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 400,
                        lineHeight: '18px',
                        letterSpacing: '0px',
                        textAlign: 'center',
                        color: '#4F4D55',
                      }}
                    >
                      .xlsx - max file size 2MB
                    </Typography>
                    <input
                      type="file"
                      id="file-input"
                      onChange={handleFileUploads}
                      style={{ display: 'none' }}
                    />
                  </div>
                </AccordionDetails>
              </Accordion>

              <div
                style={{
                  width: '95%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: 10,
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    width: '252px',
                    height: '40px',
                    padding: '8px 12px', // Individual padding values separated by space
                    borderRadius: '6px',
                    color: '#252C32',
                    gap: '8px',
                    border: '1px solid #E5E9EB',
                    fontSize: '14px',
                    marginRight: '10px', // Add margin to create space between buttons
                  }}
                  onClick={handleModalCloses}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    width: '252px',
                    height: '40px',
                    padding: '8px 12px', // Individual padding values separated by space
                    borderRadius: '6px',
                    color: '#FFFFFF',
                    gap: '8px',
                    background: '#A3A4A5',
                    fontSize: '14px',
                  }}
                  onClick={handleFormSubmit}
                >
                  Save & Next
                </Button>
              </div>
            </form>
          </Box>
        </Modal>
      </div>
      <div>
        <TableContainer component={Paper}>
          {/* <Grid container spacing={2} sx={{ border: '1px solid #E5E9EB', padding: "12px 10px 10px 10px" }}>
      <Grid item xs={10}>
        <Typography sx={{ fontSize: 20, fontWeight: 600, fontFamily: "inter", padding: "5px"}}>
          Reports Data List
        </Typography>
      </Grid>
      <Grid item xs={2} container justifyContent="flex-end" alignItems="center">
        <Button style={{ fontFamily: "inter", fontSize: 16, fontWeight: 500, marginRight: "15px" }} onClick={handleViewCompleteData}>
          View Complete Data
        </Button>
      </Grid>
    </Grid> */}
          {/* <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="Tabs" indicatorColor="primary" TabIndicatorProps={{ style: { width: '150px' } }}>
                            <Tab label="Daily Trial Monitoring" value="1278e494-7771-421c-8bbe-d0409031efa2" />
                            <Tab label="NRM performance" value="NRM performance" />
                            <Tab label="Erosion profile" value="Erosion profile" />
                        </Tabs>
                    </Grid>
                    <Grid item>
                        <Box display="flex" alignItems="center">
                            <Box ml={2}>
                                <IconButton color="primary">
                                    <DownloadIcon />
                                </IconButton>
                            </Box>
                            <Box ml={2}>
                                <Button style={{marginRight: "15px"}} variant="contained" color="primary" onClick={handleModalOpens}>Add Data Setup</Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid> */}

          <Table style={{ border: 'none' }}>
            <TableHead style={{ border: 'none' }}>
              <TableRow style={{ border: 'none' }}>
                <TableCell
                  style={{
                    font: 'inter',
                    fontSize: 16,
                    fontWeight: 500,
                    color: '#64748B',
                  }}
                >
                  S.NO
                </TableCell>
                <TableCell
                  style={{
                    font: 'inter',
                    fontSize: 16,
                    fontWeight: 500,
                    color: '#64748B',
                  }}
                >
                  File Name
                </TableCell>
                <TableCell
                  style={{
                    font: 'inter',
                    fontSize: 16,
                    fontWeight: 500,
                    color: '#64748B',
                  }}
                >
                  Customer
                </TableCell>
                <TableCell
                  style={{
                    font: 'inter',
                    fontSize: 16,
                    fontWeight: 500,
                    color: '#64748B',
                  }}
                >
                  Created By
                </TableCell>
                <TableCell
                  style={{
                    font: 'inter',
                    fontSize: 16,
                    fontWeight: 500,
                    color: '#64748B',
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    style={{
                      font: 'inter',
                      fontSize: 16,
                      fontWeight: 500,
                      color: '#252C32',
                    }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    style={{
                      font: 'inter',
                      fontSize: 16,
                      fontWeight: 500,
                      color: '#252C32',
                    }}
                  >
                    {row.combined_column}
                  </TableCell>
                  <TableCell
                    style={{
                      font: 'inter',
                      fontSize: 16,
                      fontWeight: 500,
                      color: '#252C32',
                    }}
                  >
                    {getCustomerNameById(row.customer_id)}
                  </TableCell>
                  <TableCell
                    style={{
                      font: 'inter',
                      fontSize: 16,
                      fontWeight: 500,
                      color: '#252C32',
                    }}
                  >
                    {row.created_by}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="view"
                      onClick={() => handleRowClick(row)}
                    >
                      <Visibility />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDeleteClick(row)}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

export default ReportsDataSetup
