import React, { useEffect, useState } from 'react'
import { Button, Card, Grid, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import configParam from '../../config'
import TruckImage from '../../assets/icons/PCR.png'
import DigitalFoundaryIcon from '../../assets/icons/ic_digital_foundary.svg'
import saintGobainLogo from '../../assets/icons/saint-gobain-logo.svg'
import API from '../services/API'
import axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress'
import jwtDecode from 'jwt-decode'

const StyledButton = styled(Button)({
  marginTop: (theme) => theme.spacing(2),
  backgroundColor: '#000057',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#000045',
  },
})

const Container = styled(Grid)({
  minHeight: '100vh',
  alignItems: 'center',
  background: 'linear-gradient(to bottom, #f0f0f0, #d0d0d0)',
  overflow: 'hidden',
})

const Login = () => {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSSoLogin = () => {
    const url = configParam.RUN_SSO_URL()
    window.location.replace(url)
  }

  const navigate = useNavigate()
  useEffect(() => {
    userDetails()
  }, [])

  const userDetails = async () => {
    const url = window.location.href
    const decodedUrl = decodeURIComponent(url)
    console.log('decodedUrl:', decodedUrl)

    const myArray = decodedUrl.split('?')
    if (myArray.length > 1) {
      const queryParameters = new URLSearchParams(window.location.search)
      console.log('queryParameters', queryParameters)

      // Check for error parameter
      const error = queryParameters.get('err')
      if (error) {
        console.error('Error:', error)
        setError(error || 'Access denied')
        return // Exit the function
      }

      const code = queryParameters.get('code')

      if (code) {
        // Call the API to check the user's authentication status
        const Apiurl = configParam.API_URL
        const response = await axios.get(`${Apiurl + 'login'}?code=${code}`)

        if (response.status === 200) {
          const userData = response.data
          let access_token = userData.data.access_token.replace(/"/g, '')
          console.log('access_token', access_token)
          const token = jwtDecode(access_token)

          const apiUrl = `${API.GET_USER_DATA_FROM_ID}?id=${token.userId}`
          console.log('Request URL:', apiUrl) // Check the API URL
          access_token = access_token.replace(/"/g, '')
          try {
            const response = await axios.get(apiUrl, {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            })

            if (response.status === 200) {
              if (response && response.data) {
                let userData = response.data
                console.log('userData:', userData)
                userData['access_token'] = `${access_token}`
                localStorage.setItem('user', JSON.stringify(userData))
                localStorage.setItem('accesstoken', access_token)

                let path = '/customerselection'
                if (userData.role.role_id === 2) {
                  path = `/customerselection`
                }
                console.log('Navigation Path:', path) // Log the path before navigating
                navigate(path)
              }
            } else {
              console.log('Server error')
              localStorage.setItem('userDetails', '')
              setError('Error fetching user details. Please try again.')
            }
          } catch (error) {
            console.error('Request failed:', error)
            setError(
              'An error occurred while fetching user details. Please try again.'
            )
          } finally {
            setLoading(false) // Set loading to false after the API call completes
          }
        }
      }
    }
  }

  return (
    <Grid
      container
      style={{ height: '100vh', objectFit: 'cover', overflow: 'hidden' }}
    >
      <Grid item md={9} xl={9} sm={12} xs={12}>
        <img
          src={TruckImage}
          alt="Truck"
          style={{
            height: '100%',
            width: '100%',
          }}
        />
      </Grid>
      <Grid
        item
        md={3}
        xl={3}
        sm={12}
        xs={12}
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          flexDirection: 'column',
          background: '#FFF',
          borderRadius: '10px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flexStart',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '20px',
          }}
        >
          <img
            src={DigitalFoundaryIcon}
            alt="Logo"
            style={{ marginTop: '30px', marginBottom: '30px' }}
          />
          <Typography
            variant="subtitle1"
            style={{ color: 'red', marginTop: '15px' }}
          >
            {error}&nbsp;
          </Typography>
          {!loading ? (
            <StyledButton
              variant="contained"
              onClick={handleSSoLogin}
              style={{
                width: '50%',
                backgroundColor: '#0086DC',
                color: '#fff',
              }}
            >
              Sign-On
            </StyledButton>
          ) : (
            <CircularProgress />
          )}
          <Typography
            variant="body2"
            style={{
              marginTop: 20,
              color: '#6E7C87',
              fontWeight: 400,
              fontSize: '12px',
              lineHeight: '14.52px',
              fontFamily: 'Inter',
            }}
          >
            Note: Login using Single Sign-On <br /> if you are Saint-Gobain
            User.
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
            backgroundColor: '#fff',
          }}
        >
          <img
            src={saintGobainLogo}
            alt="Truck"
            style={{
              height: '50px',
              width: '90px',
            }}
          />
          <Typography
            variant="h6"
            style={{
              fontFamily: 'Inter',
              fontSize: '12px',
              fontWeight: 600,
              lineHeight: '12.1px',
              letterSpacing: '-0.006em',
              textAlign: 'center',
              marginTop: '-5px',
            }}
          >
            Copyright © 2024 INDEC4.0
          </Typography>
        </div>
      </Grid>

      <style>
        {`
          @media only screen and (max-width: 1024px) and (min-width: 768px) {
            .MuiGrid-root.MuiGrid-item {
              flex-direction: column;
              align-items: center;
              text-align: center;
            }

            // .MuiGrid-root.MuiGrid-item img[alt="Truck"] {
            //   display: none;
            // }

            .MuiGrid-root.MuiGrid-item div {
              margin-top: 20px;
            }
          }
        `}
      </style>
    </Grid>
  )
}

export default Login
