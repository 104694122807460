import React, { useState, useRef, useEffect } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
} from '@mui/material'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import API from '../services/API'
import AlertDialog from '../input/AlertDialog'
import StyledButtonWeb from '../input/StyledButtonWeb'
const ViewErosionProfile = () => {
  // const firstRowStyles = {
  //   fontFamily: "Inter",
  //   fontSize: "14px",
  //   fontWeight: 800,
  //   lineHeight: "24px",
  //   letterSpacing: "-0.006em",
  //   textAlign: "left",
  //   color: "#252C32",
  //   backgroundColor: '#e8e8e8',
  // };

  const firstRowStyles = {
    backgroundColor: '#e8e8e8', // Light gray background color
    fontWeight: 'bold',
    textAlign: 'left',
    width: '5%',
    border: '2px solid lightgrey',
  }

  const secondRowStyles = {
    backgroundColor: '#f4f9e9',
    border: '2px solid lightgrey', // Light green background color
  }

  const firstRowStyles1 = {
    backgroundColor: '#e8e8e8', // Light gray background color
    fontWeight: 'bold',
    textAlign: 'left',
    width: '15%',
    border: '2px solid lightgrey',

    // Ensure the width is set to half the table width
  }

  const navigate = useNavigate()
  const [tableData, setTableData] = useState([])
  const [headerText, setHeaderText] = useState('General Details')
  const tableRef = useRef(null)
  const id = localStorage.getItem('daily_trial_id')
  const dataFileData = localStorage.getItem('datafile_info')
  // Parse the JSON string back to an object
  const parsedData = JSON.parse(dataFileData)
  const currentUser = JSON.parse(localStorage.getItem('user'))
  const [showRejectPopup, setShowRejectPopup] = useState(false)
  const [comments, setComments] = useState('')
  const [submissionStatus, setSubmissionStatus] = useState('')
  const access_token = localStorage.getItem('accesstoken')

  console.log('parsedData', parsedData)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataFileData = localStorage.getItem('datafile_info')
        const parsedData = JSON.parse(dataFileData)
        if (parsedData.dataFile.template.template_id === 3) {
          const datafile_id = parsedData.datafile_id
          console.log(datafile_id, 'datafile_id')
          let url = `${API.VIEW_EROSION_PROFILE}/${datafile_id}`
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          })
          console.log('viewtrial', response.data)
          setTableData(response.data)
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [])

  const details = tableData.details
  const dimensional_details = tableData.dimensional_details
  const nrmUsageDetails = tableData.nrm_usage_details
  const before_chipping = tableData.before_chipping
  const after_chipping = tableData.after_chipping

  const handleApprove = async () => {
    AlertDialog({
      type: 'delete',
      title: 'Submission',
      text: 'Are you sure you want to approve?',
      confirmButtonText: 'Yes',
      onConfirm: async () => {
        if (currentUser.role.role_id === 3 || currentUser.role.role_id === 4) {
          updateFormData('approved')
          // if (parsedData.dataFile.template.template_id === 1) {
          //   updateGeneralDetails();
          // }else{
          //   updateNRMPerformance();
          // }
        }
      },
    })
  }

  // const handleReject = async() => {

  //   AlertDialog({
  //     type: 'delete',
  //     title: 'Submission',
  //     text: 'Are you sure you want to Reject?',
  //     confirmButtonText: 'Yes',
  //     onConfirm: async () => {
  //       if (currentUser.role.role_id === 3 || currentUser.role.role_id === 4) {

  //         try {
  //           updateFormData("rejected");
  //          // Navigate after successful update
  //        } catch (error) {
  //          // Handle errors
  //          console.error('An error occurred while updating data:', error);
  //        }
  //       }
  //     }
  //   });

  // }

  const handleReject = () => {
    setShowRejectPopup(true)
  }

  const handleRejectComment = async () => {
    const commentsFromData = tableData.comments
    setComments(commentsFromData)
    setShowRejectPopup(true)
  }

  const handlePopupOk = async () => {
    // Handle OK button click logic (e.g., send comments to server)
    console.log(comments)
    setShowRejectPopup(false)

    if (currentUser.role.role_id === 3 || currentUser.role.role_id === 4) {
      // Map through parsedData and update general_details
      try {
        const updated_data = {
          id: tableData.id,
          submission_status: 'rejected',
          comments: comments || '',
          updated_data: {
            details: tableData.details,
            nrm_usage_details: tableData.NRMUsageDetails,
            dimensional_details: tableData.dimensional_details,
            before_chipping: tableData.before_chipping,
            after_chipping: tableData.after_chipping,
            lining_side: tableData.lining_side,
          },
        }

        const response = await axios.post(
          API.UPDATE_EROSION_PROFILE,
          updated_data,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        console.log('Data updated successfully:', response.data)
        if (response.status === 200) {
          AlertDialog({
            type: 'success',
            title: 'Success',
            text: 'Your Trial is Rejected',
            confirmButtonText: 'Ok',
            onConfirm: async () => {
              navigate('/datamanagement')
            },
          })
        } else {
          console.error('Error saving data:', response.data)
          AlertDialog({
            type: 'warning',
            title: 'Error',
            text: 'Failed to save data',
          })
        }
      } catch (error) {
        // Handle errors
        console.error('An error occurred while updating data:', error)
      }
    }
  }

  const updateFormData = async (submission_status) => {
    try {
      const updated_data = {
        id: tableData.id,
        submission_status: submission_status,
        updated_data: {
          details: tableData.details,
          nrm_usage_details: tableData.NRMUsageDetails,
          dimensional_details: tableData.dimensional_details,
          before_chipping: tableData.before_chipping,
          after_chipping: tableData.after_chipping,
          lining_side: tableData.lining_side,
        },
      }

      const response = await axios.post(
        API.UPDATE_EROSION_PROFILE,
        updated_data,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      console.log('Data updated successfully:', response.data)
      if (response.status === 200) {
        AlertDialog({
          type: 'success',
          title: 'Success',
          text: 'Approved Suceessfully',
          confirmButtonText: 'Ok',
          onConfirm: async () => {
            navigate('/datamanagement')
          },
        })
      } else {
        console.error('Error saving data:', response.data)
        AlertDialog({
          type: 'warning',
          title: 'Error',
          text: 'Failed to save data',
        })
      }
    } catch (error) {
      // Handle errors
      console.error('An error occurred while updating data:', error)
    }
  }

  const TableA = () => {
    if (
      !tableData ||
      !tableData.details ||
      !tableData.dimensional_details ||
      !tableData.before_chipping ||
      !tableData.after_chipping
    ) {
      return <p>Loading...</p>
    }

    // const validateFurnaceNo = (value) => {
    //   return value >= 1 && value <= 20;
    // };

    // const validateCrucibleNo = (value) => {
    //   return /[A-E]/.test(value);
    // };

    // const validateCampaignNo = (value) => {
    //   return value >= 1 && value <= 100;
    // };

    // const validateLiningPatchingNo = (value) => {
    //   const validValues = [
    //     "New Lining",
    //     "Side Lining 1",
    //     "Side Lining 2",
    //     "Side Lining 3",
    //     "Side Lining 4",
    //     "Side Lining 5",
    //     "Side Lining 6",
    //     "Side Lining 7",
    //     "Side Lining 8",
    //     "Side Lining 9",
    //     "Side Lining 10",
    //     "Side Lining 11",
    //     "Side Lining 12",
    //     "Side Lining 13",
    //     "Side Lining 14",
    //     "Side Lining 15",
    //     "Patching 1",
    //     "Patching 2",
    //     "Patching 3",
    //     "Patching 4",
    //     "Patching 5",
    //     "Patching 6",
    //     "Patching 7",
    //     "Patching 8",
    //     "Patching 9",
    //     "Patching 10",
    //     "Patching 11",
    //     "Patching 12",
    //     "Patching 13",
    //     "Patching 14",
    //     "Patching 15",
    //     "Patching 16",
    //     "Patching 17",
    //     "Patching 18",
    //     "Patching 19",
    //     "Patching 20",
    //     "Patching 21",
    //     "Patching 22",
    //     "Patching 23",
    //     "Patching 24",
    //     "Patching 25",
    //   ];
    //   return validValues.includes(value);
    // };

    // const validateHeatNo = (value) => {
    //   return !isNaN(value); // Check if it's a valid number
    // };

    // const validateDate = (value) => {
    //   const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
    //   return regex.test(value);
    // };

    return (
      <Table>
        <TableHead>
          <TableRow style={{ width: '30px' }}>
            <TableCell style={firstRowStyles}>Details</TableCell>
            <TableCell style={firstRowStyles}>Value</TableCell>
            <TableCell style={firstRowStyles}>Dimensional Details</TableCell>
            <TableCell style={firstRowStyles}>Value of Dimension</TableCell>
            <TableCell style={firstRowStyles}>NRM Usage Details</TableCell>
            <TableCell style={firstRowStyles}>Value of Usage</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell style={secondRowStyles}>Customer Name</TableCell>
            <TableCell style={secondRowStyles}>{details.cus_name}</TableCell>
            <TableCell style={secondRowStyles}>Fce Height</TableCell>
            <TableCell style={secondRowStyles}>
              {dimensional_details.fce_height}
            </TableCell>
            <TableCell style={secondRowStyles}>Bottom Product</TableCell>
            <TableCell style={secondRowStyles}>
              {nrmUsageDetails.bottom_product}
            </TableCell>
            {/* <TableCell colSpan={4} /> */}
          </TableRow>
          <TableRow>
            <TableCell style={secondRowStyles}>
              Date of Lining or patching or side lining
            </TableCell>
            <TableCell style={secondRowStyles}>
              {details.date_of_lining}
            </TableCell>
            <TableCell style={secondRowStyles}>Castable height</TableCell>
            <TableCell style={secondRowStyles}>
              {dimensional_details.castable_height}
            </TableCell>
            <TableCell style={secondRowStyles}>Sidewall Product 1</TableCell>
            <TableCell style={secondRowStyles}>
              {nrmUsageDetails.sidewall_1}
            </TableCell>
            {/* <TableCell colSpan={4} /> */}
          </TableRow>
          <TableRow>
            <TableCell style={secondRowStyles}>Segment</TableCell>
            <TableCell style={secondRowStyles}>{details.segment}</TableCell>
            <TableCell style={secondRowStyles}>Bottom Thickness</TableCell>
            <TableCell style={secondRowStyles}>
              {dimensional_details.bottom_thickness}
            </TableCell>
            <TableCell style={secondRowStyles}>Sidewall Product 2</TableCell>
            <TableCell style={secondRowStyles}>
              {nrmUsageDetails.sidewall_2}
            </TableCell>
            {/* <TableCell colSpan={4} /> */}
          </TableRow>
          <TableRow>
            <TableCell style={secondRowStyles}>Furnace No</TableCell>
            <TableCell style={secondRowStyles}>{details.furnace_no}</TableCell>
            <TableCell style={secondRowStyles}>Bottom Erosion</TableCell>
            <TableCell style={secondRowStyles}>
              {dimensional_details.bottom_erosion}
            </TableCell>
            <TableCell style={secondRowStyles}>Locking product</TableCell>
            <TableCell style={secondRowStyles}>
              {nrmUsageDetails.locking_product}
            </TableCell>
            {/* <TableCell colSpan={4} /> */}
          </TableRow>
          <TableRow>
            <TableCell style={secondRowStyles}>Crucible No</TableCell>
            <TableCell style={secondRowStyles}>{details.crucible_no}</TableCell>
            <TableCell style={secondRowStyles}>Former Top OD</TableCell>
            <TableCell style={secondRowStyles}>
              {dimensional_details.former_top_od}
            </TableCell>
            <TableCell style={secondRowStyles}>
              Spout/ Launder product
            </TableCell>
            <TableCell style={secondRowStyles}>
              {nrmUsageDetails.spout_launder_product}
            </TableCell>
            {/* <TableCell colSpan={4} /> */}
          </TableRow>
          <TableRow>
            <TableCell style={secondRowStyles}>Lining / Patching No</TableCell>
            <TableCell style={secondRowStyles}>{details.lining_no}</TableCell>
            <TableCell style={secondRowStyles}>Former Bottom OD</TableCell>
            <TableCell style={secondRowStyles}>
              {dimensional_details.former_bottom_od}
            </TableCell>
            <TableCell style={secondRowStyles}>
              NRM Consumption - Bottom Product
            </TableCell>
            <TableCell style={secondRowStyles}>
              {nrmUsageDetails.conspt_bottom_product}
            </TableCell>
            {/* <TableCell colSpan={4} /> */}
          </TableRow>
          <TableRow>
            <TableCell style={secondRowStyles}>Campaign No</TableCell>
            <TableCell style={secondRowStyles}>{details.campaign_no}</TableCell>
            <TableCell style={secondRowStyles}>Former Height</TableCell>
            <TableCell style={secondRowStyles}>
              {dimensional_details.former_height}
            </TableCell>
            <TableCell style={secondRowStyles}>
              NRM Consumption - sidewall product 1
            </TableCell>
            <TableCell style={secondRowStyles}>
              {nrmUsageDetails.sidewall_1}
            </TableCell>
            {/* <TableCell colSpan={4} /> */}
          </TableRow>
          <TableRow>
            <TableCell style={secondRowStyles}>Furnace capacity</TableCell>
            <TableCell style={secondRowStyles}>
              {details.furnace_capacity}
            </TableCell>
            <TableCell style={secondRowStyles}>Former 1st taper OD</TableCell>
            <TableCell style={secondRowStyles}>
              {dimensional_details.former_first_taper}
            </TableCell>
            <TableCell style={secondRowStyles}>
              NRM Consumption - sidewall product 2
            </TableCell>
            <TableCell style={secondRowStyles}>
              {nrmUsageDetails.sidewall_2}
            </TableCell>
            {/* <TableCell colSpan={4} /> */}
          </TableRow>
          <TableRow>
            <TableCell style={secondRowStyles}>Furnace Down at</TableCell>
            <TableCell style={secondRowStyles}>
              {details.furnace_down}
            </TableCell>
            <TableCell style={secondRowStyles}>Former Taper length</TableCell>
            <TableCell style={secondRowStyles}>
              {dimensional_details.former_taper_length}
            </TableCell>
            <TableCell style={secondRowStyles}>
              NRM Consumption - Locking product
            </TableCell>
            <TableCell style={secondRowStyles}>
              {nrmUsageDetails.conspt_locking_product}
            </TableCell>
            {/* <TableCell colSpan={4} /> */}
          </TableRow>
          <TableRow>
            <TableCell style={secondRowStyles}>Type of Former</TableCell>
            <TableCell style={secondRowStyles}>{details.former_type}</TableCell>
            <TableCell style={secondRowStyles}>Initial Lining Height</TableCell>
            <TableCell style={secondRowStyles}>
              {dimensional_details.initial_lining_height}
            </TableCell>
            <TableCell style={secondRowStyles}>
              NRM Consumption - Spout/ Launder product
            </TableCell>
            <TableCell style={secondRowStyles}>
              {nrmUsageDetails.conspt_spout_launder_product}
            </TableCell>
            {/* <TableCell colSpan={4} /> */}
          </TableRow>
          <TableRow>
            <TableCell style={secondRowStyles}>Make of the furnace</TableCell>
            <TableCell style={secondRowStyles}>
              {details.furnace_make}
            </TableCell>
            <TableCell style={secondRowStyles}>
              Lining Height after erosion
            </TableCell>
            <TableCell style={secondRowStyles}>
              {dimensional_details.lining_height_erosion}
            </TableCell>
            <TableCell style={secondRowStyles}>Total NRM Consumption</TableCell>
            <TableCell style={secondRowStyles}>
              {nrmUsageDetails.tot_nrm_conspt}
            </TableCell>
            {/* <TableCell colSpan={4} /> */}
          </TableRow>
          {/* Add more rows as needed */}
        </TableBody>

        <TableHead>
          <TableRow>
            {/* <TableCell style={firstRowStyles}>S.No</TableCell> */}
            <TableCell style={firstRowStyles}>Height</TableCell>
            <TableCell style={firstRowStyles}>Coil Coat Diameter</TableCell>
            <TableCell style={firstRowStyles}>
              BC Spout - back erosion
            </TableCell>
            <TableCell style={firstRowStyles}>
              BC Left - Right erosion
            </TableCell>
            <TableCell style={firstRowStyles}>BC LOT - Spout-Back</TableCell>
            <TableCell style={firstRowStyles}>BC LOT - Left-Right</TableCell>
            <TableCell style={firstRowStyles}>
              AC Spout - back erosion
            </TableCell>
            <TableCell style={firstRowStyles}>
              AC Left - Right erosion
            </TableCell>
            <TableCell style={firstRowStyles}>AC LOT - Spout-Back</TableCell>
            <TableCell style={firstRowStyles}>AC LOT - Left-right</TableCell>
            {/* <TableCell style={firstRowStyles}>Remarks</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {before_chipping.map((item, index) => {
            const [heightKey, values] = Object.entries(item)[0]
            const afterValues = after_chipping[index][heightKey]
            console.log(afterValues, 'aftervalues')
            return (
              <TableRow>
                {/* <TableCell style={secondRowStyles}>{index + 1}</TableCell> */}
                <TableCell style={secondRowStyles}>
                  {heightKey.replace('height', '')}
                </TableCell>
                <TableCell style={secondRowStyles}>
                  {values.coil_coat_diameter}
                </TableCell>
                <TableCell style={secondRowStyles}>
                  {values.bc_spout_back_erosion}
                </TableCell>
                <TableCell style={secondRowStyles}>
                  {values.bc_left_right_erosion}
                </TableCell>
                <TableCell style={secondRowStyles}>
                  {values.spout_back}
                </TableCell>
                <TableCell style={secondRowStyles}>
                  {values.left_right}
                </TableCell>
                <TableCell style={secondRowStyles}>
                  {afterValues.ac_spout_back_erosion}
                </TableCell>
                <TableCell style={secondRowStyles}>
                  {afterValues.ac_left_right_erosion}
                </TableCell>
                <TableCell style={secondRowStyles}>
                  {afterValues.spout_back}
                </TableCell>
                <TableCell style={secondRowStyles}>
                  {afterValues.left_right}
                </TableCell>
                {/* <TableCell>Any remarks here</TableCell> */}
              </TableRow>
            )
          })}
        </TableBody>

        <TableHead>
          <TableRow>
            {/* <TableCell style={firstRowStyles}>S.No</TableCell> */}
            <TableCell style={firstRowStyles}>Lining Side</TableCell>
            <TableCell style={firstRowStyles}>
              measurement of 4 Phase at Diff height
            </TableCell>
            <TableCell style={firstRowStyles}>
              Black sintered layer thickness
            </TableCell>
            <TableCell style={firstRowStyles}>
              White hard sintered layer thickness
            </TableCell>
            <TableCell style={firstRowStyles}>
              Semi sintered layer thickness
            </TableCell>
            <TableCell style={firstRowStyles}>Powder layer thickness</TableCell>
            <TableCell style={firstRowStyles}>Chipping Height</TableCell>
            <TableCell style={firstRowStyles}>Breaking heights</TableCell>
            {/* <TableCell style={firstRowStyles}>Remarks</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell style={secondRowStyles}>Left</TableCell>
            <TableCell style={secondRowStyles}>
              {details.furnace_make}
            </TableCell>
            <TableCell style={secondRowStyles}>
              {dimensional_details.lining_height_erosion}
            </TableCell>
            <TableCell style={secondRowStyles}>
              {nrmUsageDetails.tot_nrm_conspt}
            </TableCell>
            <TableCell style={secondRowStyles}>
              {nrmUsageDetails.tot_nrm_conspt}
            </TableCell>
            <TableCell style={secondRowStyles}>
              {nrmUsageDetails.tot_nrm_conspt}
            </TableCell>
            <TableCell style={secondRowStyles}>
              {nrmUsageDetails.tot_nrm_conspt}
            </TableCell>
            <TableCell style={secondRowStyles}>
              {nrmUsageDetails.tot_nrm_conspt}
            </TableCell>
            {/* <TableCell colSpan={4} /> */}
          </TableRow>
          <TableRow>
            <TableCell style={secondRowStyles}>Right</TableCell>
            <TableCell style={secondRowStyles}>
              {details.furnace_make}
            </TableCell>
            <TableCell style={secondRowStyles}>
              {dimensional_details.lining_height_erosion}
            </TableCell>
            <TableCell style={secondRowStyles}>
              {nrmUsageDetails.tot_nrm_conspt}
            </TableCell>
            <TableCell style={secondRowStyles}>
              {nrmUsageDetails.tot_nrm_conspt}
            </TableCell>
            <TableCell style={secondRowStyles}>
              {nrmUsageDetails.tot_nrm_conspt}
            </TableCell>
            <TableCell style={secondRowStyles}>
              {nrmUsageDetails.tot_nrm_conspt}
            </TableCell>
            <TableCell style={secondRowStyles}>
              {nrmUsageDetails.tot_nrm_conspt}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={secondRowStyles}>Spout</TableCell>
            <TableCell style={secondRowStyles}>
              {details.furnace_make}
            </TableCell>
            <TableCell style={secondRowStyles}>
              {dimensional_details.lining_height_erosion}
            </TableCell>
            <TableCell style={secondRowStyles}>
              {nrmUsageDetails.tot_nrm_conspt}
            </TableCell>
            <TableCell style={secondRowStyles}>
              {nrmUsageDetails.tot_nrm_conspt}
            </TableCell>
            <TableCell style={secondRowStyles}>
              {nrmUsageDetails.tot_nrm_conspt}
            </TableCell>
            <TableCell style={secondRowStyles}>
              {nrmUsageDetails.tot_nrm_conspt}
            </TableCell>
            <TableCell style={secondRowStyles}>
              {nrmUsageDetails.tot_nrm_conspt}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={secondRowStyles}>Back</TableCell>
            <TableCell style={secondRowStyles}>
              {details.furnace_make}
            </TableCell>
            <TableCell style={secondRowStyles}>
              {dimensional_details.lining_height_erosion}
            </TableCell>
            <TableCell style={secondRowStyles}>
              {nrmUsageDetails.tot_nrm_conspt}
            </TableCell>
            <TableCell style={secondRowStyles}>
              {nrmUsageDetails.tot_nrm_conspt}
            </TableCell>
            <TableCell style={secondRowStyles}>
              {nrmUsageDetails.tot_nrm_conspt}
            </TableCell>
            <TableCell style={secondRowStyles}>
              {nrmUsageDetails.tot_nrm_conspt}
            </TableCell>
            <TableCell style={secondRowStyles}>
              {nrmUsageDetails.tot_nrm_conspt}
            </TableCell>
            {/* <TableCell colSpan={4} /> */}
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  //   const handleApprove = () => {
  //     AlertDialog({
  //       type: 'delete',
  //       title: 'Submission',
  //       text: 'Are you sure you want to Approval?',
  //       confirmButtonText: 'Yes',
  //       onConfirm: async () => {
  //         // if(currentUser.role.role_id === 3 || currentUser.role.role_id === 4){
  //         //   await insertData(updatedGeneralDetails, uploadId,"approved");
  //         // }else{
  //         //   await insertData(updatedGeneralDetails, uploadId,"submitted");
  //         // }
  //       },
  //     });

  //   }
  //   const handleReject = () => {

  //   }
  return (
    <div>
      <div style={{ overflowX: 'auto' }}>
        {tableData.length === 0 ? (
          <Typography
            variant="body1"
            sx={{ textAlign: 'center', marginTop: '20px' }}
          >
            Trials not found
          </Typography>
        ) : (
          <div>
            {tableData.submission_status === 'submitted' &&
              (currentUser.role.role_id === 3 ||
                currentUser.role.role_id === 4) && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'fixed',
                    top: 100,
                    right: 20,
                    backgroundColor: 'white',
                    zIndex: 1000,
                    padding: '10px',
                    alignItems: 'center',
                  }}
                >
                  {/* Example of StyledButtonWeb */}
                  <StyledButtonWeb
                    variant="outlined_reject"
                    text="Reject"
                    onClick={handleReject}
                    style={{ marginRight: 10, width: '100px' }}
                  />
                  <StyledButtonWeb
                    variant="contained"
                    color="primary"
                    text="Approve"
                    onClick={handleApprove}
                    style={{ width: '100px' }}
                  />
                </div>
              )}

            {tableData.submission_status === 'rejected' && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  position: 'fixed',
                  top: 100,
                  right: 20,
                  backgroundColor: 'white',
                  zIndex: 1000,
                  padding: '10px',
                  alignItems: 'center',
                }}
              >
                <StyledButtonWeb
                  variant="contained"
                  color="primary"
                  text="View"
                  onClick={handleRejectComment}
                  style={{ width: '100px' }}
                />
              </div>
            )}

            {showRejectPopup && (
              <div
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 1001,
                }}
              >
                <div
                  style={{
                    backgroundColor: 'white',
                    padding: '20px',
                    borderRadius: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <TextField
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    placeholder="Enter comments"
                    style={{
                      width: '300px',
                      height: '100px',
                      marginBottom: '10px',
                    }}
                  />
                  <StyledButtonWeb
                    variant="contained"
                    color="primary"
                    text="OK"
                    onClick={handlePopupOk}
                    style={{ width: '100px' }}
                  />
                </div>
              </div>
            )}

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '20px',
                  letterSpacing: '-0.006em',
                  textAlign: 'left',
                  color: '#252C32',
                  marginTop: '10px',
                  zIndex: 1,
                  backgroundColor: '#fff',
                  position: 'sticky',
                  top: 0,
                }}
              >
                Erosion Profile
              </Typography>
            </div>
            <TableContainer component={Paper}>
              {(() => {
                if (parsedData.dataFile.template.template_id === 3) {
                  return <TableA />
                } else {
                  return null
                }
              })()}
            </TableContainer>
          </div>
        )}
      </div>
    </div>
  )
}

export default ViewErosionProfile
