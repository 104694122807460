import React, { useState, useEffect } from 'react'
import TextBox from '../../input/TextBox'
import Typography from '@mui/material/Typography'
import {
  cost_calculation,
  cost_calculation_nrm,
  new_lining_details,
  new_lining_details_nrm,
} from '../../../recoil/atoms'
import { useRecoilState, useRecoilValue } from 'recoil'
import { trial_name } from '../../../recoil/atoms'
import { costCalculation, costCalculationNRM } from '../../../recoil/selector'

export default function CostCalculations() {
  const [CostCalculation, setCostCalculation] = useRecoilState(cost_calculation)
  const [CostCalculationNRM, setCostCalculationNRM] =
    useRecoilState(cost_calculation_nrm)

  //calculation

  const costCalculatedvalues = useRecoilValue(costCalculation)
  const costCalculatedvaluesNRM = useRecoilValue(costCalculationNRM)
  console.log(costCalculatedvalues, 'costCalculatedvalues')
  console.log(costCalculatedvaluesNRM, 'costCalculatedvaluesNRM')

  const [trialName] = useRecoilState(trial_name)
  const isReadOnly = trialName === 'viewCostSheet'

  const handleCostCalculationsChange = (name, value) => {
    setCostCalculation((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleCostCalculationsNRMChange = (name, value) => {
    setCostCalculationNRM((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  //   useEffect(() => {
  //     if (trialName === 'costSheetEdit' || trialName === "viewCostSheet"){
  //     localStorage.setItem('CostCalculation', JSON.stringify(CostCalculation));
  //     // Save furnaceDetailsNRM to local storage
  //     localStorage.setItem('CostCalculationNRM', JSON.stringify(CostCalculationNRM));
  //     }
  // }, [CostCalculation, CostCalculationNRM]);

  // const savedNewLiningNRM = JSON.parse(localStorage.getItem('newLiningNRM'));
  // const savedSideLiningNRM = JSON.parse(localStorage.getItem('sideLiningNRM'));
  // const savedPatchingDetailsNRM = JSON.parse(localStorage.getItem('patchingDetailsNRM'));
  // const savedRepairingDetailsNRM = JSON.parse(localStorage.getItem('repairingDetailsNRM'));

  // const savedNewLining = JSON.parse(localStorage.getItem('newLining'));
  // const savedSideLining = JSON.parse(localStorage.getItem('sideLining'));
  // const savedPatchingDetails = JSON.parse(localStorage.getItem('patchingDetails'));
  // const savedRepairingDetails = JSON.parse(localStorage.getItem('repairingDetails'));

  // //calculation

  // let totalHeatSize = 0;
  // let numberOfItems = 0;

  // if (savedNewLining && savedNewLining.avg_heat_size !== undefined && (savedNewLining.avg_heat_size) !== "") {
  //   totalHeatSize += Number(savedNewLining.avg_heat_size);
  //   numberOfItems++;
  // }

  // if (savedSideLining && savedSideLining.avg_heat_size !== undefined && (savedSideLining.avg_heat_size) !== "") {
  //   totalHeatSize += Number(savedSideLining.avg_heat_size);
  //   numberOfItems++;
  // }

  // if (savedPatchingDetails && savedPatchingDetails.avg_heat_size_per_patching !== undefined && (savedPatchingDetails.avg_heat_size_per_patching) !== "") {
  //   totalHeatSize += Number(savedPatchingDetails.avg_heat_size_per_patching);
  //   console.log("Value of savedPatchingDetails.avg_heat_size_per_patching:", savedPatchingDetails.avg_heat_size_per_patching);

  //   numberOfItems++;
  // }
  // console.log(numberOfItems, "numberOfItems")

  // let averageHeatSize;
  // if (numberOfItems > 0) {
  //   averageHeatSize = (totalHeatSize / numberOfItems).toFixed(2);
  // } else {
  //   console.log("No valid heat size data available to calculate the average.");
  // }

  // let totalLifeInCampaign = 0;
  // let numberOftotalLifeItems = 0;

  // if (savedNewLining && savedNewLining.average_new_lining_life !== "") {
  //   totalLifeInCampaign += Number(savedNewLining.average_new_lining_life);
  //   numberOftotalLifeItems++;
  // }

  // if (savedSideLining && savedSideLining.average_side_lining_life !== "") {
  //   totalLifeInCampaign += Number(savedSideLining.average_side_lining_life);
  //   numberOftotalLifeItems++;
  // }

  // if (savedPatchingDetails && savedPatchingDetails.avg_heat_size_per_patching !== "") {
  //   totalLifeInCampaign += Number(savedPatchingDetails.avg_heat_size_per_patching);
  //   numberOftotalLifeItems++;
  // }

  // const averageLife = Number(totalLifeInCampaign) &&  Number(numberOftotalLifeItems) ? (Number(totalLifeInCampaign) / Number(numberOftotalLifeItems)).toFixed(2) : "0.00";

  // console.log(averageLife, "averageLife")

  // const totNoOfCycle =
  //   (savedNewLining && Number(savedNewLining.no_of_new_lining) || 0) +
  //   (savedSideLining && Number(savedSideLining.no_of_side_lining) || 0) +
  //   (savedPatchingDetails && Number(savedPatchingDetails.no_of_patching) || 0);

  // console.log(totNoOfCycle, "totNoOfCycle")

  // const totNoOfHeats =
  //   ((savedNewLining && Number(savedNewLining.average_new_lining_life) || 0) * (savedNewLining && Number(savedNewLining.no_of_new_lining) || 0)) +
  //   ((savedSideLining && Number(savedSideLining.average_side_lining_life) || 0) * (savedSideLining && Number(savedSideLining.no_of_side_lining) || 0)) +
  //   ((savedPatchingDetails && Number(savedPatchingDetails.average_patching_lining_life) || 0) * (savedPatchingDetails && Number(savedPatchingDetails.no_of_patching) || 0));

  //   console.log(totNoOfHeats, "totNoOfHeats")

  // const totProduction = Number(totNoOfHeats) && Number(averageHeatSize) ? (Number(totNoOfHeats) * Number(averageHeatSize)).toFixed(2) : "0.00";

  // const rammingMaterialCost_1 =
  //   ((CostCalculation.ramming_material_landed_cost_1)) * (
  //   ((Number(savedNewLining.no_of_new_lining) || 0) * (Number(savedNewLining.qty_new_lining_1) || 0)) +
  //   ((Number(savedSideLining.no_of_side_lining) || 0) * (Number(savedSideLining.qty_side_lining_1) || 0)) +
  //   ((Number(savedPatchingDetails.no_of_patching) || 0) * (Number(savedPatchingDetails.qty_patching_1) || 0)) +
  //   (Number(savedRepairingDetails.qty_repairing_1) || 0));
  //   console.log(rammingMaterialCost_1, "ramming_material_landed_cost_1")

  //   const toppingMaterialCost_2 =
  //   ((CostCalculation.topping_material_2)) * (
  //   ((Number(savedNewLining.no_of_new_lining) || 0) * (Number(savedNewLining.qty_spout_and_top_2) || 0)) +
  //   ((Number(savedSideLining.no_of_side_lining) || 0) * (Number(savedSideLining.qty_spout_and_top_2) || 0)) +
  //   ((Number(savedPatchingDetails.no_of_patching) || 0) * (Number(savedPatchingDetails.qty_spout_and_top_2) || 0)) +
  //   (Number(savedRepairingDetails.qty_spout_and_top_2) || 0));
  //   console.log(toppingMaterialCost_2, "toppingMaterialCost_2")

  //   const toppingMaterialCost_3 =
  //   ((CostCalculation.topping_material_3)) * (
  //   ((Number(savedNewLining.no_of_new_lining) || 0) * (Number(savedNewLining.qty_spout_and_top_3) || 0)) +
  //   ((Number(savedSideLining.no_of_side_lining) || 0) * (Number(savedSideLining.qty_spout_and_top_3) || 0)) +
  //   ((Number(savedPatchingDetails.no_of_patching) || 0) * (Number(savedPatchingDetails.qty_spout_and_top_3) || 0)) +
  //   (Number(savedRepairingDetails.qty_spout_and_top_3) || 0));
  //   console.log(toppingMaterialCost_3, "toppingMaterialCost_3")

  //   const totCostCampaignRefractory =  (Number(rammingMaterialCost_1 )+ Number(toppingMaterialCost_2) + Number(toppingMaterialCost_3)).toFixed(2);
  //   console.log(totCostCampaignRefractory, "totCostCampaignRefractory")

  //   const totRefractoryConsumption = (
  //     ((Number(savedNewLining.no_of_new_lining) || 0) * (Number(savedNewLining.qty_new_lining_1) || 0)) +
  //     ((Number(savedSideLining.no_of_side_lining) || 0) * (Number(savedSideLining.qty_side_lining_1) || 0)) +
  //     ((Number(savedPatchingDetails.no_of_patching) || 0) * (Number(savedPatchingDetails.qty_patching_1) || 0)) +
  //     (Number(savedRepairingDetails.qty_repairing_1) || 0)) + (
  //       ((Number(savedNewLining.no_of_new_lining) || 0) * (Number(savedNewLining.qty_spout_and_top_2) || 0)) +
  //       ((Number(savedSideLining.no_of_side_lining) || 0) * (Number(savedSideLining.qty_spout_and_top_2) || 0)) +
  //       ((Number(savedPatchingDetails.no_of_patching) || 0) * (Number(savedPatchingDetails.qty_spout_and_top_2) || 0)) +
  //       (Number(savedRepairingDetails.qty_spout_and_top_2) || 0)) + (
  //         ((Number(savedNewLining.no_of_new_lining) || 0) * (Number(savedNewLining.qty_spout_and_top_3) || 0)) +
  //         ((Number(savedSideLining.no_of_side_lining) || 0) * (Number(savedSideLining.qty_spout_and_top_3) || 0)) +
  //         ((Number(savedPatchingDetails.no_of_patching) || 0) * (Number(savedPatchingDetails.qty_spout_and_top_3) || 0)) +
  //         (Number(savedRepairingDetails.qty_spout_and_top_3) || 0))

  //     console.log(totRefractoryConsumption, "totRefractoryConsumption")

  //     const SpecificRefractoryConsumpt = Number(totRefractoryConsumption) && Number(totProduction) ? ((Number(totRefractoryConsumption) * 1000) / Number(totProduction)).toFixed(2) : "0.00"
  //     console.log(SpecificRefractoryConsumpt, "SpecificRefractoryConsumpt")

  //     const costOfrefractory = Number(totCostCampaignRefractory)  && Number(totProduction) ? (Number(totCostCampaignRefractory) / Number(totProduction)).toFixed(2) : "0.00";
  //     console.log(costOfrefractory, "costOfrefractory")

  // // calculation NRM

  // let totalHeatSizeNRM = 0;
  // let numberOfItemsNRM = 0;

  // if (savedNewLiningNRM && savedNewLiningNRM.avg_heat_size !== "") {
  //   totalHeatSizeNRM += Number(savedNewLiningNRM.avg_heat_size);
  //   numberOfItemsNRM++;
  // }

  // if (savedSideLiningNRM && savedSideLiningNRM.avg_heat_size !== "") {
  //   totalHeatSizeNRM += Number(savedSideLiningNRM.avg_heat_size);
  //   numberOfItemsNRM++;
  // }

  // if (savedPatchingDetailsNRM && savedPatchingDetailsNRM.avg_heat_size_per_patching !== "") {
  //   totalHeatSizeNRM += Number(savedPatchingDetailsNRM.avg_heat_size_per_patching);
  //   numberOfItemsNRM++;
  // }

  // const averageHeatSizeNRM = totalHeatSizeNRM && numberOfItemsNRM ? (totalHeatSizeNRM / numberOfItemsNRM).toFixed(2): "0.00";

  // let totalLifeNRM = 0;
  // let numberOfItemstotalLifeNRM = 0;

  // if (savedNewLiningNRM && savedNewLiningNRM.average_new_lining_life !== "") {
  //   totalLifeNRM += Number(savedNewLiningNRM.average_new_lining_life);
  //   numberOfItemstotalLifeNRM++;
  // }

  // if (savedSideLiningNRM && savedSideLiningNRM.average_side_lining_life !== "") {
  //   totalLifeNRM += Number(savedSideLiningNRM.average_side_lining_life);
  //   numberOfItemstotalLifeNRM++;
  // }

  // if (savedPatchingDetailsNRM && savedPatchingDetailsNRM.average_patching_lining_life !== "") {
  //   totalLifeNRM += Number(savedPatchingDetailsNRM.average_patching_lining_life);
  //   numberOfItemstotalLifeNRM++;
  // }

  // const averageLifeNRM = Number(totalLifeNRM) && Number(numberOfItemstotalLifeNRM) ?  (Number(totalLifeNRM) / Number(numberOfItemstotalLifeNRM)).toFixed(2) : "0.00";

  // const totNoOfCycleNRM = (
  //   (savedNewLiningNRM && Number(savedNewLiningNRM.no_of_new_lining) || 0) +
  //   (savedSideLiningNRM && Number(savedSideLiningNRM.no_of_side_lining) || 0) +
  //   (savedPatchingDetailsNRM && Number(savedPatchingDetailsNRM.no_of_patching) || 0)).toFixed(2);

  // console.log(totNoOfCycleNRM, "totNoOfCycleNRM")

  // const totNoOfHeatsNRM = (
  //   ((savedNewLiningNRM && Number(savedNewLiningNRM.average_new_lining_life) || 0) * (savedNewLiningNRM && Number(savedNewLiningNRM.no_of_new_lining) || 0)) +
  //   ((savedSideLiningNRM && Number(savedSideLiningNRM.average_side_lining_life) || 0) * (savedSideLiningNRM && Number(savedSideLiningNRM.no_of_side_lining) || 0)) +
  //   ((savedPatchingDetailsNRM && Number(savedPatchingDetailsNRM.average_patching_lining_life) || 0) * (savedPatchingDetailsNRM && Number(savedPatchingDetailsNRM.no_of_patching) || 0))).toFixed(2);

  // console.log(totNoOfHeatsNRM, "totNoOfHeatsNRM");

  // const totProductionNRM = Number(totNoOfHeatsNRM) && Number(averageHeatSizeNRM) ? (Number(totNoOfHeatsNRM) * Number(averageHeatSizeNRM)).toFixed(2) : "0.00"

  // const rammingMaterialCostNRM_1 = (
  //   ((CostCalculationNRM.ramming_material_landed_cost_1)) * (
  //   ((Number(savedNewLiningNRM.no_of_new_lining) || 0) * (Number(savedNewLiningNRM.qty_new_lining_1) || 0)) +
  //   ((Number(savedSideLiningNRM.no_of_side_lining) || 0) * (Number(savedSideLiningNRM.qty_side_lining_1) || 0)) +
  //   ((Number(savedPatchingDetailsNRM.no_of_patching) || 0) * (Number(savedPatchingDetailsNRM.qty_patching_1) || 0)) +
  //   (Number(savedRepairingDetailsNRM.qty_repairing_1) || 0))).toFixed(2);

  //   console.log(rammingMaterialCostNRM_1, "rammingMaterialCostNRM_1")

  //   const toppingMaterialCostNRM_2 = (
  //   ((CostCalculation.topping_material_2)) * (
  //   ((Number(savedNewLiningNRM.no_of_new_lining) || 0) * (Number(savedNewLiningNRM.qty_spout_and_top_2) || 0)) +
  //   ((Number(savedSideLiningNRM.no_of_side_lining) || 0) * (Number(savedSideLiningNRM.qty_spout_and_top_2) || 0)) +
  //   ((Number(savedPatchingDetailsNRM.no_of_patching) || 0) * (Number(savedPatchingDetailsNRM.qty_spout_and_top_2) || 0)) +
  //   (Number(savedRepairingDetailsNRM.qty_spout_and_top_2) || 0))).toFixed(2);
  //   console.log(toppingMaterialCostNRM_2, "toppingMaterialCostNRM_2")

  //   const toppingMaterialCostNRM_3 = (
  //   ((CostCalculation.topping_material_3)) * (
  //   ((Number(savedNewLiningNRM.no_of_new_lining) || 0) * (Number(savedNewLiningNRM.qty_spout_and_top_3) || 0)) +
  //   ((Number(savedSideLiningNRM.no_of_side_lining) || 0) * (Number(savedSideLiningNRM.qty_spout_and_top_3) || 0)) +
  //   ((Number(savedPatchingDetailsNRM.no_of_patching) || 0) * (Number(savedPatchingDetailsNRM.qty_spout_and_top_3) || 0)) +
  //   (Number(savedRepairingDetailsNRM.qty_spout_and_top_3) || 0))).toFixed(2);
  //   console.log(toppingMaterialCostNRM_3, "toppingMaterialCostNRM_3")

  // const totCostCampaignRefractoryNRM = (Number(rammingMaterialCostNRM_1) + Number(toppingMaterialCostNRM_3) + Number(toppingMaterialCostNRM_3));
  // console.log(totCostCampaignRefractoryNRM, "totCostCampaignRefractoryNRM345")

  // const totRefractoryConsumptionNRM = ((
  //   ((Number(savedNewLiningNRM.no_of_new_lining) || 0) * (Number(savedNewLiningNRM.qty_new_lining_1) || 0)) +
  //   ((Number(savedSideLiningNRM.no_of_side_lining) || 0) * (Number(savedSideLiningNRM.qty_side_lining_1) || 0)) +
  //   ((Number(savedPatchingDetailsNRM.no_of_patching) || 0) * (Number(savedPatchingDetailsNRM.qty_patching_1) || 0)) +
  //   (Number(savedRepairingDetailsNRM.qty_repairing_1) || 0)) + (
  //     ((Number(savedNewLiningNRM.no_of_new_lining) || 0) * (Number(savedNewLiningNRM.qty_spout_and_top_2) || 0)) +
  //     ((Number(savedSideLiningNRM.no_of_side_lining) || 0) * (Number(savedSideLiningNRM.qty_spout_and_top_2) || 0)) +
  //     ((Number(savedPatchingDetailsNRM.no_of_patching) || 0) * (Number(savedPatchingDetailsNRM.qty_spout_and_top_2) || 0)) +
  //     (Number(savedRepairingDetailsNRM.qty_spout_and_top_2) || 0)) + (
  //       ((Number(savedNewLiningNRM.no_of_new_lining) || 0) * (Number(savedNewLiningNRM.qty_spout_and_top_3) || 0)) +
  //       ((Number(savedSideLiningNRM.no_of_side_lining) || 0) * (Number(savedSideLiningNRM.qty_spout_and_top_3) || 0)) +
  //       ((Number(savedPatchingDetailsNRM.no_of_patching) || 0) * (Number(savedPatchingDetailsNRM.qty_spout_and_top_3) || 0)) +
  //       (Number(savedRepairingDetailsNRM.qty_spout_and_top_3) || 0))).toFixed(2)

  //       console.log(totRefractoryConsumptionNRM, "totRefractoryConsumptionNRM")

  //       const SpecificRefractoryConsumptNRM = Number(totRefractoryConsumptionNRM) &&  Number(totProductionNRM) ? ((Number(totRefractoryConsumptionNRM) * 1000) / Number(totProductionNRM)).toFixed(2) : "0.00"

  //       const costOfrefractoryNRM = Number(totCostCampaignRefractoryNRM)  && Number(totProductionNRM) ? (Number(totCostCampaignRefractoryNRM) / Number(totProductionNRM)).toFixed(2): "0.00"
  //       console.log(costOfrefractoryNRM, "costOfrefractoryNRM")

  //       const increaseInCostDueToNRM = Number(costOfrefractoryNRM) && Number(costOfrefractory) ? (Number(costOfrefractoryNRM) - Number(costOfrefractory)).toFixed(2): "0.00"
  //       console.log(increaseInCostDueToNRM, "increaseInCostDueToNRM")

  //       const relativeGainNRM = (Number(totCostCampaignRefractoryNRM) - Number(totProductionNRM)).toFixed(2)
  //       console.log(relativeGainNRM)

  //       const calculationObject = {
  //         avg_in_heat_size: averageHeatSize,
  //         avg_life: averageLife,
  //         no_of_cycles: totNoOfCycle,
  //         no_of_heats: totNoOfHeats,
  //         tot_prod: totProduction,
  //         ramming_material_landed_cost_1: CostCalculation.ramming_material_landed_cost_1,
  //         ramming_cost_in_campaign_1: rammingMaterialCost_1,
  //         topping_material_2: CostCalculation.topping_material_2,
  //         cost_in_campaign_2: toppingMaterialCost_2,
  //         topping_material_3: CostCalculation.topping_material_3,
  //         cost_in_campaign_3: toppingMaterialCost_3,
  //         tot_cost_in_campaign: totCostCampaignRefractory,
  //         tot_refractory: totRefractoryConsumption,
  //         spec_refractory_consumpt: SpecificRefractoryConsumpt,
  //         cost_of_refractory: costOfrefractory,
  //         inc_in_cost: "NA",
  //         tot_savings: "NA",
  //         relative_gain: "NA",
  //       }

  //       const calculationObjectNRM = {
  //         avg_in_heat_size: averageHeatSizeNRM,
  //         avg_life: averageLifeNRM,
  //         no_of_cycles: totNoOfCycleNRM,
  //         no_of_heats: totNoOfHeatsNRM,
  //         tot_prod: totProductionNRM,
  //         ramming_material_landed_cost_1: CostCalculationNRM.ramming_material_landed_cost_1,
  //         ramming_cost_in_campaign_1: rammingMaterialCostNRM_1,
  //         topping_material_2: CostCalculationNRM.topping_material_2,
  //         cost_in_campaign_2: toppingMaterialCostNRM_2,
  //         topping_material_3: CostCalculationNRM.topping_material_3,
  //         cost_in_campaign_3: toppingMaterialCostNRM_3,
  //         tot_cost_in_campaign: totCostCampaignRefractoryNRM,
  //         tot_refractory: totRefractoryConsumptionNRM,
  //         spec_refractory_consumpt: SpecificRefractoryConsumptNRM,
  //         cost_of_refractory: costOfrefractoryNRM,
  //         inc_in_cost: increaseInCostDueToNRM,
  //         tot_savings: "15.28",
  //         relative_gain: relativeGainNRM,
  //       }

  //       console.log(calculationObject, "calculationObject")

  //      useEffect(() => {
  //     localStorage.setItem('calculationObject', JSON.stringify(calculationObject));
  //     localStorage.setItem('calculationObjectNRM', JSON.stringify(calculationObjectNRM));
  // }, [calculationObject]);

  // useEffect(() => {
  //   let costCalculationObj = {
  //     CostCalculation: {
  //            basic: calculationObject,
  //            nrm: calculationObjectNRM
  //       },
  //   }
  // console.log(costCalculationObj, "costCalculationObj")
  // },[CostCalculation, CostCalculationNRM])

  const custDet = JSON.parse(localStorage.getItem('customerDetails'))

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body1"
          style={{
            color: '#000000',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '20px',
            marginTop: '0px',
          }}
        >
          {custDet.parameter}
        </Typography>
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="avg_in_heat_size"
          label="Average heat size in a campaign"
          type="number"
          value={costCalculatedvalues.averageHeatSize}
          onChange={(event) =>
            handleCostCalculationsChange('avg_in_heat_size', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="avg_life"
          label="Average life in a campaign"
          type="number"
          value={costCalculatedvalues.averageLife}
          onChange={(event) =>
            handleCostCalculationsChange('avg_life', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="no_of_cycles"
          label="Total no. of cycle in a campaign"
          type="number"
          value={costCalculatedvalues.totNoOfCycle}
          onChange={(event) =>
            handleCostCalculationsChange('no_of_cycles', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="no_of_heats"
          label="Total no. of heats in a campaign"
          type="number"
          value={costCalculatedvalues.totNoOfHeats}
          onChange={() => handleCostCalculationsChange()}
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="tot_prod"
          label="Total Production per Campaign"
          type="number"
          value={costCalculatedvalues.totProduction}
          onChange={(event) =>
            handleCostCalculationsChange('tot_prod', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="ramming_material_landed_cost_1"
          label="Ramming material Landed cost - 1"
          type="number"
          value={CostCalculation.ramming_material_landed_cost_1}
          onChange={(event) =>
            handleCostCalculationsChange(
              'ramming_material_landed_cost_1',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="ramming_cost_in_campaign_1"
          label="Ramming material cost In a Campaign - 1"
          type="number"
          value={
            costCalculatedvalues.rammingMaterialCost_1
              ? costCalculatedvalues.rammingMaterialCost_1
              : 0
          }
          onChange={(event) =>
            handleCostCalculationsChange(
              'ramming_cost_in_campaign_1',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="topping_material_2"
          label="Topping material Landed cost - 2"
          type="number"
          value={CostCalculation.topping_material_2}
          onChange={(event) =>
            handleCostCalculationsChange(
              'topping_material_2',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="cost_in_campaign_2"
          label="Topping material cost in a Campaign - 2"
          type="number"
          value={costCalculatedvalues.toppingMaterialCost_2}
          onChange={(event) =>
            handleCostCalculationsChange(
              'cost_in_campaign_2',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="topping_material_3"
          label="Topping material Landed cost - 3"
          type="number"
          value={CostCalculation.topping_material_3}
          onChange={(event) =>
            handleCostCalculationsChange(
              'topping_material_3',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="cost_in_campaign_3"
          label="Topping material cost in a Campaign - 3"
          type="number"
          value={costCalculatedvalues.toppingMaterialCost_3}
          onChange={(event) =>
            handleCostCalculationsChange(
              'cost_in_campaign_3',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="tot_cost_in_campaign"
          label="Total cost in a campaign (Refractory)"
          type="number"
          value={costCalculatedvalues.totCostCampaignRefractory}
          onChange={(event) =>
            handleCostCalculationsChange(
              'tot_cost_in_campaign',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="tot_refractory"
          label="Total Refractory Consumption"
          type="number"
          value={costCalculatedvalues.totRefractoryConsumption}
          onChange={(event) =>
            handleCostCalculationsChange('tot_refractory', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="spec_refractory_consumpt"
          label="Specific Refractory Consumption (kg/T of steel)"
          type="number"
          value={costCalculatedvalues.SpecificRefractoryConsumpt}
          onChange={(event) =>
            handleCostCalculationsChange(
              'spec_refractory_consumpt',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="cost_of_refractory"
          label="Cost of refractory per ton of steel produced"
          type="number"
          value={costCalculatedvalues.costOfrefractory}
          onChange={(event) =>
            handleCostCalculationsChange(
              'cost_of_refractory',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="inc_in_cost"
          label="Increase in cost due to NRM"
          type="number"
          value="NA"
          onChange={(event) =>
            handleCostCalculationsChange('inc_in_cost', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="tot_savings"
          label="Total Savings due to NRM(S1 to S8)"
          type="number"
          value="NA"
          onChange={(event) =>
            handleCostCalculationsChange('tot_savings', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="relative_gain"
          label="Relative Gain in cost due to NRM"
          type="number"
          value="NA"
          onChange={(event) =>
            handleCostCalculationsChange('relative_gain', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body1"
          style={{
            color: '#000000',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '20px',
            marginTop: '0px',
          }}
        >
          NRM
        </Typography>
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="avg_in_heat_size"
          label="Average heat size in a campaign"
          type="number"
          value={costCalculatedvaluesNRM.averageHeatSizeNRM}
          onChange={(event) =>
            handleCostCalculationsNRMChange(
              'avg_in_heat_size',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="avg_life"
          label="Average life in a campaign"
          type="number"
          value={costCalculatedvaluesNRM.averageLifeNRM}
          onChange={(event) =>
            handleCostCalculationsNRMChange('avg_life', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="no_of_cycles"
          label="Total no. of cycle in a campaign"
          type="number"
          value={costCalculatedvaluesNRM.totNoOfCycleNRM}
          onChange={(event) =>
            handleCostCalculationsNRMChange('no_of_cycles', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="no_of_heats"
          label="Total no. of heats in a campaign"
          type="number"
          value={costCalculatedvaluesNRM.totNoOfHeatsNRM}
          onChange={(event) =>
            handleCostCalculationsNRMChange('no_of_heats', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="tot_prod"
          label="Total Production per Campaign"
          type="number"
          value={costCalculatedvaluesNRM.totProductionNRM}
          onChange={(event) =>
            handleCostCalculationsNRMChange('tot_prod', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="ramming_material_landed_cost_1"
          label="Ramming material Landed cost - 1"
          type="number"
          value={CostCalculationNRM.ramming_material_landed_cost_1}
          onChange={(event) =>
            handleCostCalculationsNRMChange(
              'ramming_material_landed_cost_1',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="ramming_cost_in_campaign_1"
          label="Ramming material cost in a campaign - 1"
          type="number"
          value={costCalculatedvaluesNRM.rammingMaterialCostNRM_1}
          onChange={(event) =>
            handleCostCalculationsNRMChange(
              'ramming_cost_in_campaign_1',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="topping_material_2"
          label="Topping material Landed cost - 2"
          type="number"
          value={CostCalculationNRM.topping_material_2}
          onChange={(event) =>
            handleCostCalculationsNRMChange(
              'topping_material_2',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="cost_in_campaign_2"
          label="Topping material cost in a Campaign - 2"
          type="number"
          value={costCalculatedvaluesNRM.toppingMaterialCostNRM_2}
          onChange={(event) =>
            handleCostCalculationsNRMChange(
              'cost_in_campaign_2',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="topping_material_3"
          label="Topping material Landed cost - 3"
          type="number"
          value={CostCalculationNRM.topping_material_3}
          onChange={(event) =>
            handleCostCalculationsNRMChange(
              'topping_material_3',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="cost_in_campaign_3"
          label="Topping material cost in a Campaign - 3"
          type="number"
          value={costCalculatedvaluesNRM.toppingMaterialCostNRM_3}
          onChange={(event) =>
            handleCostCalculationsNRMChange(
              'cost_in_campaign_3',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="tot_cost_in_campaign"
          label="Total cost in a campaign (Refractory)"
          type="number"
          value={costCalculatedvaluesNRM.totCostCampaignRefractoryNRM}
          onChange={(event) =>
            handleCostCalculationsNRMChange(
              'tot_cost_in_campaign',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="tot_refractory"
          label="Total Refractory Consumption"
          type="number"
          value={costCalculatedvaluesNRM.totRefractoryConsumptionNRM}
          onChange={(event) =>
            handleCostCalculationsNRMChange(
              'tot_refractory',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="spec_refractory_consumpt"
          label="Specific Refractory Consumption (kg/T of steel)"
          type="number"
          value={costCalculatedvaluesNRM.SpecificRefractoryConsumptNRM}
          onChange={(event) =>
            handleCostCalculationsNRMChange(
              'spec_refractory_consumpt',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="cost_of_refractory"
          label="Cost of refractory per ton of steel produced"
          type="number"
          value={costCalculatedvaluesNRM.costOfrefractoryNRM}
          onChange={(event) =>
            handleCostCalculationsNRMChange(
              'cost_of_refractory',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="inc_in_cost"
          label="Increase in cost due to NRM"
          type="number"
          value={costCalculatedvaluesNRM.increaseInCostDueToNRM}
          onChange={(event) =>
            handleCostCalculationsNRMChange('inc_in_cost', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="tot_savings"
          label="Total Savings due to NRM(S1 to S8)"
          type="number"
          value={CostCalculationNRM.tot_savings}
          onChange={(event) =>
            handleCostCalculationsNRMChange('tot_savings', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="relative_gain"
          label="Relative Gain in cost due to NRM"
          type="number"
          value={costCalculatedvaluesNRM.relativeGainNRM}
          onChange={(event) =>
            handleCostCalculationsNRMChange('relative_gain', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
    </div>
  )
}
