import React, { useState, useEffect } from 'react'
import TextBox from '../../input/TextBox'
import axios from 'axios'
import { dimensional_details } from '../../../recoil/atoms'
import { useRecoilState, useRecoilValue } from 'recoil'
import { v4 as uuidv4 } from 'uuid'
import { trial_name } from '../../../recoil/atoms'
import { initialLiningHeight } from '../../../recoil/selector'
const DimensionalDetails = () => {
  const [dimensionalDetails, setDimensionalDetails] =
    useRecoilState(dimensional_details)
  const [trialName] = useRecoilState(trial_name)
  const isReadOnly = trialName === 'viewErosionProfile'
  const initialLiningHeightvalue = useRecoilValue(initialLiningHeight)
  const liningHeightValue = useRecoilValue(initialLiningHeight)
  const uuid = uuidv4()

  const handleChange = (name, value) => {
    console.log('nammeee', name)
    console.log('valuee', value)
    const TrialName = localStorage.getItem('Trial')
    if (TrialName === 'new' || TrialName === 'add') {
      const uniqueId = uuidv4()
      setDimensionalDetails((prevState) => ({
        ...prevState,
        [name]: value,
        id: uniqueId, // Set the unique ID
        isdelete: false,
      }))
    } else {
      // If TrialName is not "new", no need to generate unique ID
      setDimensionalDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const handleDateSelection = (value, type) => {
    console.log('typee', type)
  }

  return (
    <div style={{ width: '100%' }}>
      <div style={{ marginTop: '15px', marginRight: '15px' }}>
        <TextBox
          name="fce_height"
          label="Fce Height (inch)"
          type="number"
          value={dimensionalDetails.fce_height}
          onChange={(event) => handleChange('fce_height', event.target.value)}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="castable_height"
          label="Castable height (inch)"
          type="number"
          value={dimensionalDetails.castable_height}
          onChange={(event) =>
            handleChange('castable_height', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="bottom_thickness"
          label="Bottom Thickness (inch)"
          value={dimensionalDetails.bottom_thickness}
          onChange={(event) =>
            handleChange('bottom_thickness', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="bottom_erosion"
          label="Bottom Erosion (inch)"
          value={dimensionalDetails.bottom_erosion}
          onChange={(event) =>
            handleChange('bottom_erosion', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="former_top_od"
          label="Former Top OD (inch)"
          value={dimensionalDetails.former_top_od}
          onChange={(event) =>
            handleChange('former_top_od', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <TextBox
        name="former_bottom_od"
        label="Former Bottom OD (inch)"
        value={dimensionalDetails.former_bottom_od}
        onChange={(event) =>
          handleChange('former_bottom_od', event.target.value)
        }
        disabled={isReadOnly}
      />
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="former_height"
          label="Former Height (inch)"
          isString={true}
          value={dimensionalDetails.former_height}
          onChange={(event) =>
            handleChange('former_height', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="former_first_taper"
          label="Former 1st taper OD (inch)"
          value={dimensionalDetails.former_first_taper}
          onChange={(event) =>
            handleChange('former_first_taper', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="initial_lining_height"
          label="Former Taper length (inch)"
          value={dimensionalDetails.former_taper_length}
          onChange={(event) =>
            handleChange('former_taper_length', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="lining_height_erosion"
          label="Initial Lining Height (inch)"
          value={initialLiningHeightvalue.initLiningHt}
          onChange={() => handleChange()}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="frequency_range"
          label="Lining Height after erosion (inch)"
          value={liningHeightValue.liningHeightAE}
          onChange={() => handleChange()}
          disabled={isReadOnly}
        />
      </div>
    </div>
  )
}
export default DimensionalDetails
