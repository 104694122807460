import React from 'react'
import {
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from '@mui/material'
import { styled } from '@mui/system'

const StyledTextField = styled(TextField)({
  // Existing styles
  '& .MuiSelect-root': {
    color: '#0086DC',
  },
  '& .MuiInputLabel-root': {
    color: '#49454F',
    padding: '0 8px',
    backgroundColor: 'white',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#00448B',
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    borderColor: '#E6E6E6',
    borderRadius: '8px',
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#0086DC',
  },
  '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: 'red',
  },
  '& .MuiOutlinedInput-inputMultiline': {
    minHeight: '200px', // Adjust the height as needed
  },
  // Additional styles
  '& .MuiInputBase-root': {
    backgroundColor: '#f7f7f7',
    borderRadius: '8px',
  },
})

const TextBox = (props) => {
  const renderInputAdornment = () => {
    if (props.type === 'date' && props.showCalendarIcon) {
      return (
        <InputAdornment position="end">
          <IconButton></IconButton>
        </InputAdornment>
      )
    } else if (props.type === 'time' && props.showClockIcon) {
      return (
        <InputAdornment position="end">
          <IconButton>
            {/* Render your clock icon here */}
            {/* <img src={ClockIcon} alt="Clock Icon" /> */}
          </IconButton>
        </InputAdornment>
      )
    } else {
      return null
    }
  }

  const getInputType = () => {
    // Determine the input type based on conditions
    if (props.type === 'date') {
      return 'date'
    } else if (props.type === 'time') {
      return 'time'
    } else if (props.allowText && props.allowNumber) {
      return 'text'
    } else if (props.allowNumber) {
      return 'number'
    } else {
      return 'text'
    }
  }

  const handleChange = (event) => {
    // If type is number, allow only numbers and maximum of 5 characters
    if (props.type === 'number') {
      let value = event.target.value.replace(/[^0-9]/g, '') // Allow only numbers
      value = value.slice(0, 10) // Limit to maximum of 5 characters
      props.onChange({ target: { value, name: props.name } })
    } else {
      props.onChange(event)
    }
  }

  return (
    <React.Fragment>
      <Typography
        style={{
          textAlign: 'left',
          marginBottom: '8px',
          fontFamily: 'Inter',
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '15px',
          letterSpacing: '0em',
          color: '#333333',
          textAlign: 'left',
          marginBottom: '8px',
        }}
      >
        {props.label}
      </Typography>
      <StyledTextField
        required={props.required ? props.required : null}
        style={{
          width: '100%',
          marginBottom: '16px',
          borderColor: props.error ? 'red' : '', // Set border color based on props.error and stepperError
        }}
        id={props.id}
        type={getInputType()} // Set input type dynamically
        value={props.value}
        InputLabelProps={{
          shrink: true,
        }}
        name={props.name}
        onChange={handleChange}
        inputRef={props.inputRef}
        error={props.error ? props.error : false}
        helperText={props.helperText ? props.helperText : ''}
        disabled={props.disabled ? props.disabled : false}
        variant="outlined"
        InputProps={{
          endAdornment: renderInputAdornment(),
          inputProps: {
            maxLength: props.maxLength, // Add maxLength attribute based on props.maxLength
          },
        }}
      />
    </React.Fragment>
  )
}

export default TextBox
