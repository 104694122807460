import React, { useState, useEffect } from 'react'
import TextBox from '../../input/TextBox'
import { furnace_dimension } from '../../../recoil/atoms'
import { useRecoilState, useRecoilValue } from 'recoil'
import { v4 as uuidv4 } from 'uuid'
import { trial_name } from '../../../recoil/atoms'

const FurnaceDimension = () => {
  const [furnaceDimension, setFurnaceDimension] =
    useRecoilState(furnace_dimension)
  const [trialName] = useRecoilState(trial_name)
  const uuid = uuidv4()

  const handleChange = (e) => {
    const { name, value } = e.target
    setFurnaceDimension((prevState) => ({
      ...prevState,
      [name]: value,
      id: uuid,
    }))
  }

  const handleDateSelection = (value, type) => {
    console.log('typee', type)
  }

  const isReadOnly = trialName === 'viewDataCollection'

  return (
    <div style={{ width: '100%' }}>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="inner_dia_of_coil"
          label="Inner Dia. of Coil - inch"
          type="text"
          value={furnaceDimension.inner_dia_of_coil}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>

      <TextBox
        label="A (Inner Dia. after doing Coil Coat/Grout)"
        name="inner_dia_after_coilcoat"
        value={furnaceDimension.inner_dia_after_coilcoat}
        type="text"
        onChange={handleChange}
        disabled={isReadOnly}
      />

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="former_outer_dia"
          label="B (Former Outer Dia.) - (inch)"
          isString={true}
          value={furnaceDimension.former_outer_dia}
          type="text"
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="furnace_height"
          label="C Furnace height - (inch)"
          isString={true}
          value={furnaceDimension.furnace_height}
          type="text"
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="bottom_taper_dia"
          label="D Bottom Taper Dia - (inch)"
          isString={true}
          value={furnaceDimension.bottom_taper_dia}
          type="text"
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="bottom_outer_dia"
          label="E Bottom Outer Dia. - (inch)"
          isString={true}
          value={furnaceDimension.bottom_outer_dia}
          type="text"
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="former_ht"
          label="F Former ht. - (inch)"
          isString={true}
          value={furnaceDimension.former_ht}
          type="text"
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="bottom_lining_ht"
          label="G Bottom Lining ht. - (inch)"
          isString={true}
          value={furnaceDimension.bottom_lining_ht}
          type="text"
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="total_power_given_to_ms"
          label="Total Power given To SMS - (MW)"
          isString={true}
          value={furnaceDimension.total_power_given_to_ms}
          type="text"
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
    </div>
  )
}
export default FurnaceDimension
