import React, { useState, useEffect } from 'react'
import TopAppBar from '../../../Components/drawer/TopAppBar'
import { Tabs, Tab, Typography, Box, Button, Modal } from '@mui/material'
import axios from 'axios'
import API from '../../services/API'
import AutoSelectBox from '../../input/AutoSelectBox'
import { useNavigate } from 'react-router-dom'
import { trial_name, dataManagementTab } from '../../../recoil/atoms'
import { useRecoilState, useResetRecoilState } from 'recoil'
import AlertDialog from '../../input/AlertDialog'
import NRMPerformanceHome from '../nrmperformance/NRMPerformnaceHome'
import DailyTrialMonitoringHome from '../dailytrialmonitoring/DailyTrialMonitoringHome'
import DataCollectionHome from '../datacollection/DataCollectionHome'
import CostSheetHome from '../costsheet/CostSheetHome'
import ErosionProfileHome from '../erosionprofile/ErosionProfileHome'
import CircularProgress from '@mui/material/CircularProgress'

const DataManagementMobile = () => {
  const navigate = useNavigate()
  const [buttonClicked, setButtonClicked] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [customers, setCustomers] = useState([])
  const [segments, setSegments] = useState([])
  const [templates, setTemplates] = useState([])
  const [furnaces, setFurnaces] = useState([])
  const [selectedTab, setSelectedTab] = useRecoilState(dataManagementTab)
  const [costSheet, setCostSheet] = useState([])
  const [liningPatchingNo, setLiningPatchingNo] = useState([])
  const resetTrialName = useResetRecoilState(trial_name)
  const access_token = localStorage.getItem('accesstoken')

  const [formData, setFormData] = useState({
    segment_id: '',
    customer_id: '',
    template_id: '',
    furnace_id: '',
  })
  const [dailyTrials, setDailyTrails] = useState([])
  const currentUser = JSON.parse(localStorage.getItem('user'))
  const [formErrors, setFormErrors] = useState({})

  /////offline code///////

  const offline_customers = JSON.parse(localStorage.getItem('setCustomers'))
  const offline_furnaces = JSON.parse(localStorage.getItem('setFurnaces'))
  const offline_templates = JSON.parse(localStorage.getItem('setTemplates'))
  const offline_segments = JSON.parse(localStorage.getItem('setSegments'))
  const offline_lining = JSON.parse(localStorage.getItem('setLiningPatchingNo'))

  const [isOnline, setIsOnline] = useState(window.navigator.onLine)

  const [db, setDb] = useState(null)

  useEffect(() => {
    const handleOnline = () => {
      if (!isOnline) {
        setIsOnline(true)
        localStorage.setItem('onlinestate', 1)

        window.location.reload()
      }
    }

    const handleOffline = () => {
      localStorage.setItem('onlinestate', 0)
      // setIsOnlines(false)
      setIsOnline(false)
      // navigate('/reports')
    }

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [isOnline])

  const dbName = 'pcr'
  const customerName = 'customers'
  const furnacesName = 'furnaces'
  const templateName = 'template'
  const segmentName = 'segment'
  const datafileName = 'datafile'
  const dailytrialName = 'daily_trial'
  const nrmName = 'nrm_performance'
  const erosionName = 'erosion_profile'
  const dataCollectionName = 'data_collection'
  const costSheetName = 'cost_sheet'
  const LiningName = 'lining_patching_no'

  useEffect(() => {
    if (!isOnline) {
      const request = indexedDB.open(dbName, 1)

      request.onupgradeneeded = (event) => {
        const database = event.target.result
        database.createObjectStore(customerName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(furnacesName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(templateName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(segmentName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(datafileName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(dailytrialName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(nrmName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(erosionName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(dataCollectionName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(costSheetName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(LiningName, {
          keyPath: 'id',
          autoIncrement: true,
        })
      }

      request.onsuccess = (event) => {
        const database = event.target.result
        const transaction = database.transaction(
          [
            'customers',
            'furnaces',
            'template',
            'segment',
            'datafile',
            'daily_trial',
            'nrm_performance',
            'erosion_profile',
            'data_collection',
            'cost_sheet',
            'lining_patching_no',
          ],
          'readwrite'
        )

        setDb(database)
        // if(localStorage.getItem("offlinedbinserttest")==='0'){
        insertDataIntoCustomers(database, offline_customers, customerName)
        insertDataIntoFurnaces(database, offline_furnaces, furnacesName)
        insertDataIntoTemplates(database, offline_templates, templateName)
        insertDataIntoSegments(database, offline_segments, segmentName)
        insertDataIntoLining(database, offline_lining, LiningName)
      }

      request.onerror = (event) => {
        console.error('Error opening IndexedDB', event)
      }
    }
  }, [isOnline])

  const insertDataIntoCustomers = (database, dataArray, storeName) => {
    const transaction = database.transaction(storeName, 'readwrite')
    const objectStore = transaction.objectStore(storeName)

    dataArray.forEach((data) => {
      objectStore.add(data)
    })

    transaction.oncomplete = () => {
      console.log('Data inserted successfully into IndexedDB')
    }

    transaction.onerror = (event) => {
      console.error('Error inserting data into IndexedDB', event)
    }
  }
  const insertDataIntoFurnaces = (database, dataArray, storeName) => {
    const transaction = database.transaction(storeName, 'readwrite')
    const objectStore = transaction.objectStore(storeName)

    dataArray.forEach((data) => {
      objectStore.add(data)
    })

    transaction.oncomplete = () => {
      console.log('Data inserted successfully into IndexedDB')
    }

    transaction.onerror = (event) => {
      console.error('Error inserting data into IndexedDB', event)
    }
  }

  const insertDataIntoTemplates = (database, dataArray, storeName) => {
    const transaction = database.transaction(storeName, 'readwrite')
    const objectStore = transaction.objectStore(storeName)

    dataArray.forEach((data) => {
      objectStore.add(data)
    })

    transaction.oncomplete = () => {
      console.log('Data inserted successfully into IndexedDB')
    }

    transaction.onerror = (event) => {
      console.error('Error inserting data into IndexedDB', event)
    }
  }

  const insertDataIntoSegments = (database, dataArray, storeName) => {
    const transaction = database.transaction(storeName, 'readwrite')
    const objectStore = transaction.objectStore(storeName)

    dataArray.forEach((data) => {
      objectStore.add(data)
    })

    transaction.oncomplete = () => {
      console.log('Data inserted successfully into IndexedDB')
    }

    transaction.onerror = (event) => {
      console.error('Error inserting data into IndexedDB', event)
    }
  }

  const insertDataIntoLining = (database, dataArray, storeName) => {
    const transaction = database.transaction(storeName, 'readwrite')
    const objectStore = transaction.objectStore(storeName)

    dataArray.forEach((data) => {
      objectStore.add(data)
    })

    transaction.oncomplete = () => {
      console.log('Data inserted successfully into IndexedDB')
    }

    transaction.onerror = (event) => {
      console.error('Error inserting data into IndexedDB', event)
    }
  }

  /////offline code///////

  const handleButtonClick = () => {
    setButtonClicked(true)
  }

  const handleModalCloses = () => {
    setShowModal(false)
  }

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  const handleChange = async (event) => {
    const { name, value } = event.target

    // If the name is 'customer_id', directly set the value in the state
    if (name === 'customer_id') {
      setFormData({ ...formData, [name]: value })
      await fetchFurnaceData(value)
    } else {
      // Otherwise, update the form data as usual
      setFormData({ ...formData, [name]: value })
    }
  }
  const fetchFurnaceData = async (value) => {
    if (isOnline) {
      try {
        const response = await axios.get(API.GET_FURNACES, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        console.log('furnace response', response)
        console.log('value', value)
        //   const matchingFurnace = response.data.find(furnace => furnace.assign_customer_id === value);
        const matchingFurnaces = response.data.filter(
          (furnace) => furnace.assign_customer_id === value
        )

        if (matchingFurnaces) {
          console.log('Matching Furnace:', matchingFurnaces)
          const matchingFurnaceArr = matchingFurnaces
          setFurnaces(matchingFurnaceArr)
          // Do something with the matching furnace object
        } else {
          setFurnaces([])
          console.log('No matching furnace found')
        }
        //   setFurnaces(response.data);
      } catch (error) {
        console.error('Error fetching users:', error)
      }
    } else {
      const transaction = db.transaction(furnacesName, 'readonly')
      const objectStore = transaction.objectStore(furnacesName)
      const getAllRequest = objectStore.getAll()
      getAllRequest.onsuccess = () => {
        const allData = getAllRequest.result
        const matchingFurnaces = allData.filter(
          (furnace) => furnace.assign_customer_id === value
        )
        setFurnaces(matchingFurnaces)
      }

      getAllRequest.onerror = (event) => {
        setFurnaces([])
        console.error(
          'Error getting data from the area table in IndexedDB',
          event
        )
      }
    }
  }

  const onClickFunction = () => {
    handleModalOpens()
    resetTrialName()
  }
  const handleModalOpens = () => {
    console.log('goodgood')
    setShowModal(true)
    fetchTemplates()
    fetchCustomers()
    fetchSegments()

    setFormData({
      // Initialize with default or empty values
      // Example:
      customer_id: '',
      segment_id: '',
      template_id: '',
      // Add more fields as needed
    })

    // Show modal
    setShowModal(true)
  }

  const fetchSegments = async () => {
    if (isOnline) {
      try {
        const response = await axios.get(API.GET_SEGMENT, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        setSegments(response.data)
      } catch (error) {
        console.error('Error fetching lining:', error)
      }
    } else {
      const transaction = db.transaction(segmentName, 'readonly')
      const objectStore = transaction.objectStore(segmentName)
      const getAllRequest = objectStore.getAll()
      getAllRequest.onsuccess = () => {
        const allData = getAllRequest.result

        setSegments(allData)
      }

      getAllRequest.onerror = (event) => {
        setSegments([])
        console.error(
          'Error getting data from the area table in IndexedDB',
          event
        )
      }
    }
  }

  const handleFormCancel = async () => {
    handleModalCloses()
  }

  const fetchCustomers = async () => {
    if (isOnline) {
      try {
        const response = await axios.get(API.GET_CUSTOMER, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }) // Adjust the URL as needed
        console.log('response', response)
        const customerResponse = response.data
        console.log('customerResponse', customerResponse)
        if (currentUser.role.role_id === 1) {
          const customerId = currentUser.assign_customer_id[0]
          const customers = customerResponse.filter(
            (item) => item.id === customerId
          )
          setCustomers(customers)
        } else if (
          currentUser.role.role_id === 2 ||
          currentUser.role.role_id === 3
        ) {
          const assignCustomerIds = currentUser.assign_customer_id.map(
            (id) => id
          )
          console.log('assignCustomerIds', assignCustomerIds)
          // Filter the data to get customers whose IDs match the given customer IDs
          const filteredCustomers = customerResponse.filter((customer) =>
            assignCustomerIds.includes(customer.id)
          )
          setCustomers(filteredCustomers)
        } else {
          setCustomers(customerResponse)
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    } else {
      const transaction = db.transaction(customerName, 'readonly')
      const objectStore = transaction.objectStore(customerName)
      const getAllRequest = objectStore.getAll()
      getAllRequest.onsuccess = () => {
        const allData = getAllRequest.result

        setCustomers(allData)
      }

      getAllRequest.onerror = (event) => {
        setCustomers([])
        console.error(
          'Error getting data from the area table in IndexedDB',
          event
        )
      }
    }
  }

  const fetchTemplates = async () => {
    console.log('good')
    if (isOnline) {
      console.log('online')
      try {
        const response = await axios.get(API.GET_TEMPLATES) // Adjust the URL as needed
        console.log('responsetemplates', response)
        setTemplates(response.data) // Update state with fetched data
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    } else {
      console.log('offline')
      const transaction = db.transaction(templateName, 'readonly')
      const objectStore = transaction.objectStore(templateName)
      const getAllRequest = objectStore.getAll()
      getAllRequest.onsuccess = () => {
        const allData = getAllRequest.result

        setTemplates(allData)
      }

      getAllRequest.onerror = (event) => {
        setTemplates([])
        console.error(
          'Error getting data from the area table in IndexedDB',
          event
        )
      }
    }
  }

  const selectedTemplate = templates.find(
    (template) => template.id === formData.template_id
  )
  console.log(selectedTemplate, 'selectedTemplate')
  const handleFormSubmit = async (e) => {
    if (isOnline) {
      try {
        const errors = {}
        if (!formData.template_id) {
          errors.template_id = 'Data file template is required'
        }

        if (!formData.customer_id) {
          errors.customer_id = 'Customer is required'
        }

        if (
          !formData.segment_id &&
          !(
            selectedTemplate.template_id === 4 ||
            selectedTemplate.template_id === 5
          )
        ) {
          errors.segment_id = 'Segment is required'
        }

        if (
          !formData.furnace_id &&
          !(
            selectedTemplate.template_id === 4 ||
            selectedTemplate.template_id === 5
          )
        ) {
          if (furnaces.length === 0) {
            errors.furnace_id =
              'Please contact Admin or choose another customer.'
          } else {
            errors.furnace_id = 'Furnace Number-Capacity is required'
          }
        }

        if (Object.keys(errors).length > 0) {
          setFormErrors(errors)
          return // Exit function to prevent further processing
        }

        const jsonDataWithUserId = {
          ...formData,
          user_id: currentUser.id,
        }
        console.log(jsonDataWithUserId, 'jsonDataWithUserId')
        let response
        if (
          selectedTemplate.template_id === 4 ||
          selectedTemplate.template_id === 5
        ) {
          response = await axios.post(
            API.UPLOAD_DATA_COLLECTION_DATAFILE,
            jsonDataWithUserId,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          )
        } else {
          response = await axios.post(
            API.UPLOAD_DATAFILE_MOBILE,
            jsonDataWithUserId,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          )
        }
        console.log('response', response)

        if (response.status === 200) {
          const responseData = response.data
          localStorage.setItem('dataFileResponse', JSON.stringify(responseData))
          const datafile_id = responseData.data.id
          console.log('datafile_iddatafile_id', datafile_id)
          localStorage.setItem('datafile_id', datafile_id)
          const newValue = 'new'
          localStorage.setItem('Trial', newValue)

          const templateId = responseData.data.template.template_id
          console.log('templateId', templateId)
          console.log('responseData', responseData)

          if (templateId === 1) {
            navigate('/dailytrialmonitoringM')
          } else if (templateId === 2) {
            navigate('/nrmperformance')
          } else if (templateId === 3) {
            navigate('/erosionprofile')
          } else if (templateId === 4) {
            navigate('/HorizontalDataCollectionStepper')
          } else if (templateId === 5) {
            navigate('/costsheet')
          }
        } else {
          console.log('error', 'data file update')
        }
      } catch (error) {
        console.error('An error occurred while submitting form:', error)
        // Optionally, you can handle the error state here
      }
    } else {
      try {
        const errors = {}
        if (!formData.template_id) {
          errors.template_id = 'Data file template is required'
        }

        if (!formData.customer_id) {
          errors.customer_id = 'Customer is required'
        }

        if (
          !formData.segment_id &&
          !(
            selectedTemplate.template_id === 4 ||
            selectedTemplate.template_id === 5
          )
        ) {
          errors.segment_id = 'Segment is required'
        }

        if (
          !formData.furnace_id &&
          !(
            selectedTemplate.template_id === 4 ||
            selectedTemplate.template_id === 5
          )
        ) {
          if (furnaces.length === 0) {
            errors.furnace_id =
              'Please contact Admin or choose another customer.'
          } else {
            errors.furnace_id = 'Furnace Number-Capacity is required'
          }
        }

        if (Object.keys(errors).length > 0) {
          setFormErrors(errors)
          return // Exit function to prevent further processing
        }

        const jsonDataWithUserId = {
          ...formData,
          user_id: currentUser.id,
        }
        console.log(jsonDataWithUserId, 'jsonDataWithUserId')

        const transaction = db.transaction(datafileName, 'readwrite')
        const objectStore = transaction.objectStore(datafileName)
        const trialdetailsrequest = objectStore.add(jsonDataWithUserId)
        trialdetailsrequest.onsuccess = () => {
          console.log(
            'File saved successfully in IndexedDB trialdetails',
            trialdetailsrequest.result
          )
          localStorage.setItem('datafile_id', trialdetailsrequest.result)
          const newValue = 'new'
          localStorage.setItem('Trial', newValue)

          const templateId = formData.template_id
          console.log('templateId', templateId)

          const transaction1 = db.transaction(templateName, 'readonly')
          const objectStore = transaction1.objectStore(templateName)
          const getAllRequest = objectStore.get(templateId)
          getAllRequest.onsuccess = () => {
            const allData = getAllRequest.result
            console.error('allData', allData.template_id)

            if (allData.template_id === 1) {
              navigate('/dailytrialmonitoringM')
            } else if (allData.template_id === 2) {
              navigate('/nrmperformance')
            } else if (allData.template_id === 3) {
              navigate('/erosionprofile')
            } else if (allData.template_id === 4) {
              navigate('/HorizontalDataCollectionStepper')
            } else if (allData.template_id === 5) {
              navigate('/costsheet')
            }
          }

          getAllRequest.onerror = (event) => {
            console.error(
              'Error getting data from the area table in IndexedDB',
              event
            )
          }
        }

        trialdetailsrequest.onerror = () => {
          console.log('Error saving file in IndexedDB')
        }
      } catch (error) {
        console.error('An error occurred while submitting form:', error)
        // Optionally, you can handle the error state here
      }
    }
  }

  return (
    <div>
      {/* {localStorage.getItem("isloading")==='1'&&(
         <div style={{ display: 'flex', justifyContent: 'center', marginTop:20 }}>
         <CircularProgress />
       </div>
      )} */}
      <div>
        <Modal
          open={showModal}
          onClose={handleModalCloses}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          BackdropProps={{
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // semi-transparent background
              backdropFilter: 'blur(10px)', // blurs the background
            },
          }}
        >
          <Box
            sx={{
              width: '90%', // Adjust width for mobile view
              margin: 'auto', // Center the modal horizontally
              padding: '20px',
              borderRadius: '8px',
              background: 'white',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontFamily: 'Inter',
                fontWeight: 600,
                textAlign: 'center',
                marginBottom: '20px',
                color: '#252C32',
              }}
            >
              Upload New Data File
            </Typography>

            <form>
              <div style={{ flex: 1, marginRight: '15px', marginTop: '20px' }}>
                <AutoSelectBox
                  name="template_id"
                  labelValue="Data File Template *"
                  isLabel={true}
                  options={templates}
                  labelCondition="name"
                  value={formData.template_id}
                  onChange={handleChange}
                  error={formErrors.template_id}
                />
              </div>

              {selectedTemplate &&
                selectedTemplate.template_id !== 4 &&
                selectedTemplate.template_id !== 5 && (
                  <div style={{ marginTop: '20px' }}>
                    <AutoSelectBox
                      name="segment_id"
                      labelValue="Segment *"
                      isLabel={true}
                      options={segments}
                      value={formData.segment_id}
                      labelCondition="name"
                      onChange={handleChange}
                      error={formErrors.segment_id}
                    />
                  </div>
                )}

              <div style={{ flex: 1, marginRight: '15px', marginTop: '20px' }}>
                <AutoSelectBox
                  name="customer_id"
                  labelValue="Customer *"
                  isLabel={true}
                  options={customers}
                  labelCondition="organization_name"
                  value={formData.customer_id}
                  onChange={handleChange}
                  error={formErrors.customer_id}
                />
              </div>
              {selectedTemplate &&
                selectedTemplate.template_id !== 4 &&
                selectedTemplate.template_id !== 5 && (
                  <div style={{ marginTop: '20px' }}>
                    <AutoSelectBox
                      name="furnace_id"
                      labelValue="Furnace Number - Capacity *"
                      isLabel={true}
                      options={furnaces}
                      labelCondition="furnace_id"
                      value={formData.furnace_id}
                      onChange={handleChange}
                      error={formErrors.furnace_id}
                    />
                  </div>
                )}

              <div
                style={{
                  width: '95%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: 10,
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    width: '252px',
                    height: '40px',
                    padding: '8px 12px', // Individual padding values separated by space
                    borderRadius: '6px',
                    color: '#252C32',
                    gap: '8px',
                    border: '1px solid #E5E9EB',
                    fontSize: '14px',
                    marginRight: '10px', // Add margin to create space between buttons
                  }}
                  onClick={handleFormCancel}
                >
                  cancel
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    width: '252px',
                    height: '40px',
                    padding: '8px 12px', // Individual padding values separated by space
                    borderRadius: '6px',
                    color: '#FFFFFF',
                    gap: '8px',
                    background: '#0086DC',
                    fontSize: '14px',
                  }}
                  onClick={handleFormSubmit}
                >
                  Next
                </Button>
              </div>
            </form>
          </Box>
        </Modal>
      </div>
      <TopAppBar onClickFunction={onClickFunction} />
      <div style={{ overflowX: 'auto' }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Daily Trial Monitoring" />
          <Tab label="NRM Performance" />
          <Tab label="Data Collection" />
          <Tab label="Cost Sheet" />
          <Tab label="Erosion Profile" />
        </Tabs>

        <div>
          {selectedTab === 0 && <DailyTrialMonitoringHome />}
          {selectedTab === 1 && <NRMPerformanceHome />}
          {selectedTab === 2 && <DataCollectionHome />}
          {selectedTab === 3 && <CostSheetHome />}
          {selectedTab === 4 && <ErosionProfileHome />}
        </div>
      </div>
    </div>
  )
}

export default DataManagementMobile
