import React, { useState, useEffect } from 'react'
import TextBox from '../../input/TextBox'
import SelectBox from '../../input/SelectBox'
import {
  lining_material_consumption,
  lining_reference,
  production_summary,
} from '../../../recoil/atoms'
import { useRecoilState } from 'recoil'

const LiningMaterialConsumption = () => {
  const [liningMaterialConsumption, setLiningMaterialConsumption] =
    useRecoilState(lining_material_consumption)
  const [liningReference] = useRecoilState(lining_reference)
  const [productionSummary] = useRecoilState(production_summary)

  const uniqueId = liningReference.id ? liningReference.id : ''
  const [isOnline, setIsOnline] = useState(window.navigator.onLine)
  console.log(uniqueId, 'nrm2')
  const [db, setDb] = useState(null)

  useEffect(() => {
    const handleOnline = () => {
      if (!isOnline) {
        setIsOnline(true)
        localStorage.setItem('onlinestate', 1)

        window.location.reload()
      }
    }

    const handleOffline = () => {
      localStorage.setItem('onlinestate', 0)
      // setIsOnlines(false)
      setIsOnline(false)
      // navigate('/reports')
    }

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [isOnline])

  const dbName = 'pcr'
  const customerName = 'customers'
  const furnacesName = 'furnaces'
  const templateName = 'template'
  const segmentName = 'segment'
  const datafileName = 'datafile'
  const dailytrialName = 'daily_trial'
  const nrmName = 'nrm_performance'
  const erosionName = 'erosion_profile'
  const dataCollectionName = 'data_collection'
  const costSheetName = 'cost_sheet'
  const LiningName = 'lining_patching_no'

  useEffect(() => {
    if (!isOnline) {
      const request = indexedDB.open(dbName, 1)

      request.onupgradeneeded = (event) => {
        const database = event.target.result
        database.createObjectStore(customerName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(furnacesName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(templateName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(segmentName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(datafileName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(dailytrialName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(nrmName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(erosionName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(dataCollectionName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(costSheetName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(LiningName, {
          keyPath: 'id',
          autoIncrement: true,
        })
      }

      request.onsuccess = (event) => {
        const database = event.target.result
        const transaction = database.transaction(
          [
            'customers',
            'furnaces',
            'template',
            'segment',
            'datafile',
            'daily_trial',
            'nrm_performance',
            'erosion_profile',
            'data_collection',
            'cost_sheet',
            'lining_patching_no',
          ],
          'readwrite'
        )

        setDb(database)
      }

      request.onerror = (event) => {
        console.error('Error opening IndexedDB', event)
      }
    }
  }, [isOnline])
  const handleChange = (e) => {
    const { name, value } = e.target
    const numericValue = parseFloat(value) || 0 // Convert value to number or default to 0
    const TrialName = localStorage.getItem('Trial')

    // Prepare updated state with current changes
    const updatedState = { ...liningMaterialConsumption, [name]: numericValue }

    // Calculate total_nrm_consumption_sidewall_one if any of the relevant fields change
    if (
      name === 'nrm_consumption_bottom' ||
      name === 'nrm_consumption_sidewall_one' ||
      name === 'nrm_consumption_sidewall_two' ||
      name === 'nrm_consumption_top_locking' ||
      name === 'topping_mass_consumption'
    ) {
      // Sum all relevant fields
      const totalNRMConsumptionSidewallOne =
        (parseFloat(updatedState.nrm_consumption_bottom) || 0) +
        (parseFloat(updatedState.nrm_consumption_sidewall_one) || 0) +
        (parseFloat(updatedState.nrm_consumption_sidewall_two) || 0) +
        (parseFloat(updatedState.nrm_consumption_top_locking) || 0) +
        (parseFloat(updatedState.topping_mass_consumption) || 0)

      updatedState.total_nrm_consumption_sidewall_one =
        totalNRMConsumptionSidewallOne
    }

    // Compute nrm_specific_consumption based on the updated state
    // Assuming you have access to productionSummary here
    const totalProduction = parseFloat(productionSummary.total_production) || 0
    const totalNRMConsumptionSidewallOne =
      parseFloat(updatedState.total_nrm_consumption_sidewall_one) || 0
    const nrmSpecificConsumption =
      totalProduction * totalNRMConsumptionSidewallOne

      updatedState.nrm_specific_consumption = parseFloat(nrmSpecificConsumption.toFixed(2));

    updatedState.nrm_specific_consumption =
    (totalNRMConsumptionSidewallOne / totalProduction).toFixed(2);

    console.log('updatedState', updatedState)

    // Check if TrialName is "new"
    if (TrialName === 'new') {
      updatedState.id = uniqueId // Set the unique ID
    }

    setLiningMaterialConsumption(updatedState)
  }

  return (
    <div>
      <div style={{ flex: 1, marginRight: '15px', marginTop: '15px' }}>
        <TextBox
          name="nrm_consumption_bottom"
          label="NRM consumption bottom (kg)"
          type="number"
          maxLength={5}
          value={liningMaterialConsumption.nrm_consumption_bottom}
          onChange={handleChange}
        />
      </div>
      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="nrm_consumption_sidewall_one"
          label="NRM consumption sidewall 1 (kg)"
          type="number"
          maxLength={5}
          value={liningMaterialConsumption.nrm_consumption_sidewall_one}
          onChange={handleChange}
          //   error={formErrors.coil_grout}
        />
      </div>
      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="nrm_consumption_sidewall_two"
          label="NRM consumption sidewall 2 (kg)"
          type="number"
          maxLength={5}
          value={liningMaterialConsumption.nrm_consumption_sidewall_two}
          onChange={handleChange}
          //   error={formErrors.coil_grout}
        />
      </div>
      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="nrm_consumption_top_locking"
          label="NRM consumption at Top Locking (kg)"
          type="number"
          maxLength={5}
          value={liningMaterialConsumption.nrm_consumption_top_locking}
          onChange={handleChange}
          //   error={formErrors.coil_grout}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="topping_mass_consumption"
          label="Topping mass consumption (kg)"
          type="number"
          maxLength={5}
          value={liningMaterialConsumption.topping_mass_consumption}
          onChange={handleChange}
          //   error={formErrors.coil_grout}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="total_nrm_consumption_sidewall_one"
          label="Total NRM consumption sidewall (kg)"
          type="number"
          value={liningMaterialConsumption.total_nrm_consumption_sidewall_one}
          onChange={handleChange}
          //   error={formErrors.coil_grout}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="nrm_specific_consumption"
          label="NRM Specific consumption (kg/MT of steel)"
          type="number"
          value={liningMaterialConsumption.nrm_specific_consumption}
          onChange={handleChange}
          //   error={formErrors.coil_grout}
        />
      </div>
    </div>
  )
}

export default LiningMaterialConsumption
