import React from 'react'
import Swal from 'sweetalert2'

const AlertDialog = ({ type, title, text, confirmButtonText, onConfirm }) => {
  let config = {}

  if (type === 'success') {
    config = {
      icon: 'success',
      title: title || 'Success!',
      text: text || 'Operation completed successfully.',
      confirmButtonText: confirmButtonText || 'OK',
      // timer:1500,
    }
  } else if (type === 'reports saved') {
    config = {
      icon: 'success',
      title: title || 'Success!',
      text: text || 'Reports saved successfully.',
      confirmButtonText: confirmButtonText || 'OK',
      // timer:1500,
    }
  } else if (type === 'reports updated') {
    config = {
      icon: 'success',
      title: title || 'Success!',
      text: text || 'Reports updated successfully.',
      confirmButtonText: confirmButtonText || 'OK',
      // timer:1500,
    }
  } else if (type === 'delete') {
    config = {
      icon: 'warning',
      title: title || 'Are you sure?',
      text: text || "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: confirmButtonText || 'Yes, delete it!',
    }
  } else if (type === 'pdf') {
    config = {
      icon: 'success',
      title: title || 'Are you sure?',
      text: text || "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: confirmButtonText || 'Yes, delete it!',
    }
  } else if (type === 'error') {
    config = {
      icon: 'error',
      title: title || 'Error!',
      text: text || 'An error occurred.',
      confirmButtonText: confirmButtonText || 'OK',
      timer: 1500,
    }
  }

  Swal.fire(config).then((result) => {
    if (result.isConfirmed && onConfirm) {
      onConfirm()
    }
  })
}

export default AlertDialog
