import React, { useEffect } from 'react'
import AutoSelectBox from '../../input/AutoSelectBox'
import Typography from '@mui/material/Typography'
import Helper from '../../services/Helper'
import TextBox from '../../input/TextBox'
import { useRecoilState } from 'recoil'
import {
  increased_productivity,
  increased_productivity_nrm,
} from '../../../recoil/atoms'
import StyledButton from '../../input/StyledButton'
import { trial_name } from '../../../recoil/atoms'
import { useRecoilValue } from 'recoil'
import {
  maxNoOfHeatsSelector,
  noOfSinteringSelector,
  totDownTimeSelector,
  totDownTimeDueToFceSelector,
  totTimeAvailableProdSelector,
  actualNoOfHeatsSelector,
  actualProdInMonthSelector,
  maxNoOfHeatsNRMSelector,
  noOfSinteringNRMSelector,
  totDownTimeNRMSelector,
  totDownTimeDueToFceNRMSelector,
  totTimeAvailableProdNRMSelector,
  actualNoOfHeatsNRMSelector,
  actualProdInMonthNRMSelector,
  prodIncreaseSelector,
  perOfProdDueToNRMSelector,
  costBenefitsSelector,
  savingDueToIncProdSelector,
} from '../../../recoil/selector'

export default function IncreasedProductivity(props) {
  const [increasedProductivity, setIncreasedProductivity] = useRecoilState(
    increased_productivity
  )
  const [increasedProductivityNRM, setIncreasedProductivityNRM] =
    useRecoilState(increased_productivity_nrm)
  const [trialName] = useRecoilState(trial_name)
  const isReadOnly = trialName === 'viewCostSheet'

  const maxNoOfHeats = useRecoilValue(maxNoOfHeatsSelector)
  console.log('Max No. of Heats:', maxNoOfHeats)

  const noOfSintering = useRecoilValue(noOfSinteringSelector)
  console.log('No. of Sintering:', noOfSintering)

  const totDownTime = useRecoilValue(totDownTimeSelector)
  console.log('Total Down Time:', totDownTime)

  const totDownTimeDueToFce = useRecoilValue(totDownTimeDueToFceSelector)
  console.log('Total Down Time Due to FCE:', totDownTimeDueToFce)

  const totTimeAvailableProd = useRecoilValue(totTimeAvailableProdSelector)
  console.log('Total Time Available for Production:', totTimeAvailableProd)

  const actualNoOfHeats = useRecoilValue(actualNoOfHeatsSelector)
  console.log('Actual No. of Heats:', actualNoOfHeats)

  const actualProdInMonth = useRecoilValue(actualProdInMonthSelector)
  console.log('Actual Production in a Month:', actualProdInMonth)

  // NRM

  const maxNoOfHeatsNRM = useRecoilValue(maxNoOfHeatsNRMSelector)
  console.log('Max No. of Heats NRM:', maxNoOfHeatsNRM)

  const noOfSinteringNRM = useRecoilValue(noOfSinteringNRMSelector)
  console.log('No. of Sintering NRM:', noOfSinteringNRM)

  const totDownTimeNRM = useRecoilValue(totDownTimeNRMSelector)
  console.log('Total Down Time NRM:', totDownTimeNRM)

  const totDownTimeDueToFceNRM = useRecoilValue(totDownTimeDueToFceNRMSelector)
  console.log('Total Down Time Due to FCE NRM:', totDownTimeDueToFceNRM)

  const totTimeAvailableProdNRM = useRecoilValue(
    totTimeAvailableProdNRMSelector
  )
  console.log(
    'Total Time Available for Production NRM:',
    totTimeAvailableProdNRM
  )

  const actualNoOfHeatsNRM = useRecoilValue(actualNoOfHeatsNRMSelector)
  console.log('Actual No. of Heats NRM:', actualNoOfHeatsNRM)

  const actualProdInMonthNRM = useRecoilValue(actualProdInMonthNRMSelector)
  console.log('Actual Production in a Month NRM:', actualProdInMonthNRM)

  const prodIncrease = useRecoilValue(prodIncreaseSelector)
  console.log('Production Increase:', prodIncrease)

  const perOfProdDueToNRM = useRecoilValue(perOfProdDueToNRMSelector)
  console.log(
    'Percentage of Production Increase Due to NRM:',
    perOfProdDueToNRM
  )

  const costBenefits = useRecoilValue(costBenefitsSelector)
  console.log('Cost Benefits:', costBenefits)

  const savingDueToIncProd = useRecoilValue(savingDueToIncProdSelector)
  console.log('Savings Due to Increased Production:', savingDueToIncProd)

  const handleIncreasedProductivity = (name, value) => {
    setIncreasedProductivity((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleIncreasedProductivityNRM = (name, value) => {
    setIncreasedProductivityNRM((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  //calculation basic

  const totalAvailableTime = 24 * 60 * 30

  // const maxNoOfHeats = increasedProductivity && Number(increasedProductivity.avg_heat_time) ? (totalAvailableTime / Number(increasedProductivity.avg_heat_time)).toFixed(2) : "0.00";

  // const noOfSintering = savedCalculationObj && Number(savedCalculationObj.avg_life) ?  (maxNoOfHeats / Number(savedCalculationObj.avg_life)).toFixed(2) : "0.00";

  // const totDownTime = (increasedProductivity.extra_time_sintering * noOfSintering).toFixed(2);

  // const totDownTimeDueToFce = increasedProductivity && Number(increasedProductivity.frequency_of_furnace)  ? (maxNoOfHeats * increasedProductivity.frequency_of_furnace * increasedProductivity.avg_fce_checking_time).toFixed(2) : "0.00";

  // const totTimeAvailableProd = (totalAvailableTime - totDownTime - totDownTimeDueToFce - Number(increasedProductivity.total_down_time_change_over)).toFixed(2);

  // const actualNoOfHeats = (totTimeAvailableProd / Number(increasedProductivity.avg_heat_time)).toFixed(2);

  // const actualProdInMonth = savedCalculationObj && Number(savedCalculationObj.avg_in_heat_size) ? (actualNoOfHeats * savedCalculationObj.avg_in_heat_size).toFixed(2) : "0.00";

  // // NRM

  // const maxNoOfHeatsNRM = increasedProductivityNRM && Number(increasedProductivityNRM.avg_heat_time) ? (totalAvailableTime / increasedProductivityNRM.avg_heat_time).toFixed(2) : "0.00";

  // const noOfSinteringNRM = savedCalculationObjNRM && Number(savedCalculationObjNRM.maxNoOfHeatsNRM) ? Number(maxNoOfHeatsNRM / savedCalculationObjNRM.avg_life).toFixed(2) : "0.00";

  // const totDownTimeNRM = increasedProductivityNRM && Number(increasedProductivityNRM.extra_time_sintering) ? (increasedProductivityNRM.extra_time_sintering * noOfSinteringNRM).toFixed(2) : "0.00";

  // const totDownTimeDueToFceNRM = (maxNoOfHeatsNRM * increasedProductivityNRM.frequency_of_furnace * increasedProductivityNRM.avg_fce_checking_time).toFixed(2);

  // const totTimeAvailableProdNRM = (totalAvailableTime - totDownTimeNRM - totDownTimeDueToFceNRM - increasedProductivityNRM.total_down_time_change_over).toFixed(2);

  // const actualNoOfHeatsNRM = increasedProductivityNRM && Number(increasedProductivityNRM) ?(totTimeAvailableProdNRM / increasedProductivityNRM.avg_heat_time).toFixed(2) : "0.00";

  // const actualProdInMonthNRM =savedCalculationObjNRM && Number(savedCalculationObjNRM.avg_in_heat_size) ? (actualNoOfHeatsNRM * savedCalculationObjNRM.avg_in_heat_size).toFixed(2) : "0.00";

  // const prodIncrease = (actualProdInMonthNRM - actualProdInMonth).toFixed(2);

  // const perOfProdDueToNRM = Number(actualProdInMonth) ? (((prodIncrease * 100) / actualProdInMonth)).toFixed(2) : "0.00";

  // const costBenefits = (prodIncrease * 2000).toFixed(2);

  // const savingDueToIncProd = Number(costBenefits) && Number(actualProdInMonthNRM) ? (Number(costBenefits) / Number(actualProdInMonthNRM)).toFixed(2) : "0.00";

  // const IncreasedProdObj = {
  //   avg_heat_time: increasedProductivity.avg_heat_time,
  //   totalAvailableTime: totalAvailableTime,
  //   maxNoOfHeats: maxNoOfHeats,
  //   noOfSintering: noOfSintering,
  //   extra_time_sintering: increasedProductivity.extra_time_sintering,
  //   totDownTime: totDownTime,
  //   avg_fce_checking_time: increasedProductivity.avg_fce_checking_time,
  //   frequency_of_furnace: increasedProductivity.frequency_of_furnace,
  //   totDownTimeDueToFce: totDownTimeDueToFce,
  //   total_down_time_change_over: increasedProductivity.total_down_time_change_over,
  //   totTimeAvailableProd : totTimeAvailableProd,
  //   actualNoOfHeats: actualNoOfHeats,
  //   actualProdInMonth: actualProdInMonth,
  //   production_increase_due_to_nrm: 'NA',
  //   percentage_production_increase: 'NA',
  //   profit_margin: 'NA',
  //   cost_benefit: 'NA',
  //   saving_due_to_increased_productivity: 'NA'
  // }

  // const IncreasedProdObjNRM = {
  //   avg_heat_time: increasedProductivityNRM.avg_heat_time,
  //   totalAvailableTime: totalAvailableTime,
  //   maxNoOfHeats: maxNoOfHeatsNRM,
  //   noOfSintering: noOfSinteringNRM,
  //   extra_time_sintering: increasedProductivityNRM.extra_time_sintering,
  //   totDownTime: totDownTimeNRM,
  //   avg_fce_checking_time: increasedProductivityNRM.avg_fce_checking_time,
  //   frequency_of_furnace: increasedProductivityNRM.frequency_of_furnace,
  //   totDownTimeDueToFce: totDownTimeDueToFceNRM,
  //   total_down_time_change_over: increasedProductivityNRM.total_down_time_change_over,
  //   totTimeAvailableProd : totTimeAvailableProdNRM,
  //   actualNoOfHeats: actualNoOfHeatsNRM,
  //   actualProdInMonth: actualProdInMonthNRM,
  //   production_increase_due_to_nrm: prodIncrease,
  //   percentage_production_increase: perOfProdDueToNRM,
  //   profit_margin: increasedProductivityNRM.profit_margin,
  //   cost_benefit: costBenefits,
  //   saving_due_to_increased_productivity: savingDueToIncProd
  // }

  //   useEffect(() => {
  //     localStorage.setItem('noOfSintering', JSON.stringify(IncreasedProdObj.noOfSintering));
  //     localStorage.setItem('noOfSinteringNRM', JSON.stringify(IncreasedProdObjNRM.noOfSintering));
  //     localStorage.setItem('actualProdInMonth', JSON.stringify(IncreasedProdObj.actualProdInMonth));
  //     localStorage.setItem('actualProdInMonthNRM', JSON.stringify(IncreasedProdObjNRM.actualProdInMonth));
  // }, [IncreasedProdObj.noOfSintering, IncreasedProdObjNRM.noOfSintering, IncreasedProdObj.actualProdInMonth, IncreasedProdObjNRM.actualProdInMonth]);

  useEffect(() => {
    if (
      props.trialName === 'costSheetEdit' &&
      props.editSavingsDetails.length > 0
    ) {
      console.log(props.editSavingsDetails, 'props.editSavingsDetails')
      let editObj = doesKeyExist(
        props.editSavingsDetails,
        'increasedProductivityObject'
      )
      console.log(editObj, 'editObj')
      if (editObj) {
        setIncreasedProductivity(
          editObj.increasedProductivityObject.IncreasedProductivity.basic
        )
        setIncreasedProductivityNRM(
          editObj.increasedProductivityObject.IncreasedProductivity.nrm
        )
      }
    }
  }, [props.editSavingsDetails])

  function doesKeyExist(array, key) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].hasOwnProperty(key)) {
        return array[i]
      }
    }
    return false
  }

  // const handlenextTriger =()=>{
  //   props.handleNext()
  //   props.handleIncreasedProductivityObject(IncreasedProdObj, IncreasedProdObjNRM)
  // }

  const custDet = JSON.parse(localStorage.getItem('customerDetails'))

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body1"
          style={{
            color: '#000000',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '20px',
            marginTop: '0px',
          }}
        >
          {custDet.parameter}
        </Typography>
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="avg_heat_time"
          label="Average Heat time(with/without hot heel for NRM)"
          type="number"
          value={increasedProductivity.avg_heat_time}
          onChange={(event) =>
            handleIncreasedProductivity('avg_heat_time', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Total Available time in a month (30 days)"
          type="number"
          value={totalAvailableTime}
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Maximum No. of Heats in a month"
          type="number"
          value={maxNoOfHeats ? maxNoOfHeats : '0.00'}
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="No. of sintering Heats in a month"
          type="number"
          value={noOfSintering}
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Extra time in sintering heat in min with Main panel"
          type="number"
          value={increasedProductivity.extra_time_sintering}
          onChange={(event) =>
            handleIncreasedProductivity(
              'extra_time_sintering',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Total Down time in a month due to sintering"
          type="number"
          value={totDownTime ? totDownTime : '0.00'}
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="avg_fce_checking_time"
          label="Average Fce Checking Time"
          type="number"
          value={increasedProductivity.avg_fce_checking_time}
          onChange={(event) =>
            handleIncreasedProductivity(
              'avg_fce_checking_time',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="frequency_of_furnace"
          label="Frequency of furnace Checking as Percentage"
          type="number"
          value={increasedProductivity.frequency_of_furnace}
          onChange={(event) =>
            handleIncreasedProductivity(
              'frequency_of_furnace',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Total down time in a month due to Fce Checking time"
          type="number"
          value={totDownTimeDueToFce ? totDownTimeDueToFce : '0.00'}
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Total down time in a month due to Change over"
          type="number"
          value={increasedProductivity.total_down_time_change_over}
          onChange={(event) =>
            handleIncreasedProductivity(
              'total_down_time_change_over',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Total time available for production in a month"
          type="number"
          value={totTimeAvailableProd ? totTimeAvailableProd : '0.00'}
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Actual No. of heats in a month"
          type="number"
          value={actualNoOfHeats ? actualNoOfHeats : '0.00'}
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Actual Production in a month"
          type="number"
          value={actualProdInMonth ? actualProdInMonth : '0.00'}
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Production increase due to NRM"
          type="number"
          value="NA"
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="% Production Increase in a month"
          type="number"
          value="NA"
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Profit Margin per ton of steel"
          type="number"
          value="NA"
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Cost Benefits due to NRM in a month"
          type="number"
          value="NA"
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Savings due to increased productivity"
          type="number"
          value="NA"
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body1"
          style={{
            color: '#000000',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '20px',
            marginTop: '0px',
          }}
        >
          NRM
        </Typography>
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="mobile_number"
          label="Average Heat time(with/without hot heel for NRM)"
          type="number"
          value={increasedProductivityNRM.avg_heat_time}
          onChange={(event) =>
            handleIncreasedProductivityNRM('avg_heat_time', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Total Available time in a month (30 days)"
          type="number"
          value={totalAvailableTime}
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Maximum No. of Heats in a month"
          type="number"
          value={maxNoOfHeatsNRM}
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="No. of sintering Heats in a month"
          type="number"
          value={noOfSinteringNRM}
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Extra time in sintering heat in min with Main panel"
          type="number"
          value={increasedProductivityNRM.extra_time_sintering}
          onChange={(event) =>
            handleIncreasedProductivityNRM(
              'extra_time_sintering',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Total Down time in a month due to sintering"
          type="number"
          value={totDownTimeNRM}
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="avg_fce_checking_time"
          label="Average Fce Checking Time"
          type="number"
          value={increasedProductivityNRM.avg_fce_checking_time}
          onChange={(event) =>
            handleIncreasedProductivityNRM(
              'avg_fce_checking_time',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Frequency of furnace Checking as Percentage"
          type="number"
          value={increasedProductivityNRM.frequency_of_furnace}
          onChange={(event) =>
            handleIncreasedProductivityNRM(
              'frequency_of_furnace',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Total down time in a month due to Fce Checking time"
          type="number"
          value={totDownTimeDueToFceNRM}
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Total down time in a month due to Change over"
          type="number"
          value={increasedProductivityNRM.total_down_time_change_over}
          onChange={(event) =>
            handleIncreasedProductivityNRM(
              'total_down_time_change_over',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Total time available for production in a month"
          type="number"
          value={totTimeAvailableProdNRM}
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Actual No. of heats in a month"
          type="number"
          value={actualNoOfHeatsNRM}
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Actual Production in a month"
          type="number"
          value={actualProdInMonthNRM}
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Production increase due to NRM"
          type="number"
          value={prodIncrease}
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="% Production Increase in a month"
          type="number"
          value={perOfProdDueToNRM ? perOfProdDueToNRM : '0.00'}
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Profit Margin per ton of steel"
          type="number"
          value={increasedProductivityNRM.profit_margin}
          onChange={(event) =>
            handleIncreasedProductivityNRM('profit_margin', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Cost Benefits due to NRM in a month"
          type="number"
          value={costBenefits ? costBenefits : '0.00'}
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Savings due to increased productivity"
          type="number"
          value={savingDueToIncProd ? savingDueToIncProd : '0.00'}
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>
      {/* <div style={{ justifyContent: 'space-between', flexDirection: "row", display: "flex", marginTop: "auto", marginBottom: "10px", position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1000 }}>
        <StyledButton
          variant="outlined"
          text="Cancel"
          onClick={props.handleBack}
          disabled={props.activeStep === 0}
          style={{ marginRight: 10 }}
        />
        <StyledButton
          variant="contained"
          color="primary"
          text={
            (props.trialName === 'edit' && props.selectedTab === 2) ? 'Update' :
            (props.activeStep === (props.selectedTab === 0 ? props.CostSheetTabs.length - 1 : props.savingsDetails.length - 1)) ? 'Finish' : 
            'Next'
          }
        onClick={handlenextTriger}
        />
      </div> */}
    </div>
  )
}
