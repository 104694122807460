import React, { useState, useEffect } from 'react'
import TextBox from '../../input/TextBox'
import Helper from '../../services/Helper'
import API from '../../services/API'
import axios from 'axios'
import { nrm_usage_details } from '../../../recoil/atoms'
import { v4 as uuidv4 } from 'uuid'
import CustomAutocomplete from '../../input/CustomAutocomplete'
import { useRecoilState, useRecoilValue } from 'recoil'
import { trial_name } from '../../../recoil/atoms'
import { tot_NRM_Consumption } from '../../../recoil/selector'
const NrmUsageDetails = () => {
  const [isEdit, setIsEdit] = useState(localStorage.getItem('Trial') === 'Edit')
  const [usageDetails, setusageDetails] = useRecoilState(nrm_usage_details)
  const [trialName] = useRecoilState(trial_name)
  const isReadOnly = trialName === 'viewErosionProfile'
  const totalNRM = useRecoilValue(tot_NRM_Consumption)
  console.log(totalNRM, 'totalNRM')
  const handleChange = (name, value) => {
    console.log('nammeee', name)
    console.log('valuee', value)
    const TrialName = localStorage.getItem('Trial')
    if (TrialName === 'new' || TrialName === 'add') {
      const uniqueId = uuidv4()
      setusageDetails((prevState) => ({
        ...prevState,
        [name]: value,
        id: uniqueId,
        isdelete: false,
      }))
    } else {
      setusageDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <div style={{ marginTop: '15px', marginRight: '15px' }}>
        <CustomAutocomplete
          isLabel={true}
          labelValue="Bottom Product"
          options={Helper.CostSheet.map((option) => option.name)}
          value={usageDetails.bottom_product}
          onChange={(event, value) => handleChange('bottom_product', value)}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px', marginRight: '15px' }}>
        <CustomAutocomplete
          name="crucible_no"
          labelValue="Sidewall Product 1"
          isLabel={true}
          options={Helper.CostSheet.map((option) => option.name)}
          value={usageDetails.sidewall_1}
          onChange={(event, value) => handleChange('sidewall_1', value)}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px', marginRight: '15px' }}>
        <CustomAutocomplete
          name="campaign_no"
          isLabel={true}
          labelValue="Sidewall Product 2"
          options={Helper.CostSheet.map((option) => option.name)}
          value={usageDetails.sidewall_2}
          onChange={(event, value) => handleChange('sidewall_2', value)}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px', marginRight: '15px' }}>
        <CustomAutocomplete
          name="locking_product"
          isLabel={true}
          labelValue="Locking product"
          options={Helper.CostSheet.map((option) => option.name)}
          value={usageDetails.locking_product}
          onChange={(event, value) => handleChange('locking_product', value)}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px', marginRight: '15px' }}>
        <CustomAutocomplete
          name="spout_launder_product"
          isLabel={true}
          labelValue="Spout/Launder product"
          options={Helper.CostSheet.map((option) => option.name)}
          value={usageDetails.spout_launder_product}
          onChange={(event, value) =>
            handleChange('spout_launder_product', value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px', marginRight: '15px' }}>
        <TextBox
          name="conspt_bottom_product"
          label="NRM Consumption-Bottom Product"
          isString={true}
          value={usageDetails.conspt_bottom_product}
          onChange={(event) =>
            handleChange('conspt_bottom_product', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px', marginRight: '15px' }}>
        <TextBox
          name="conspt_sidewall_1"
          label="NRM Consumption - sidewall product 1"
          isString={true}
          value={usageDetails.conspt_sidewall_1}
          onChange={(event) =>
            handleChange('conspt_sidewall_1', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px', marginRight: '15px' }}>
        <TextBox
          name="conspt_sidewall_2"
          label="NRM Consumption - sidewall product 2"
          isString={true}
          value={usageDetails.conspt_sidewall_2}
          onChange={(event) =>
            handleChange('conspt_sidewall_2', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px', marginRight: '15px' }}>
        <TextBox
          name="conspt_locking_product"
          label="NRM Consumption - Locking product"
          isString={true}
          value={usageDetails.conspt_locking_product}
          onChange={(event) =>
            handleChange('conspt_locking_product', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px', marginRight: '15px' }}>
        <TextBox
          name="conspt_spout_launder_product"
          label="NRM Consumption - Spout/Launder product"
          isString={true}
          value={usageDetails.conspt_spout_launder_product}
          onChange={(event) =>
            handleChange('conspt_spout_launder_product', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px', marginRight: '15px' }}>
        <TextBox
          name="tot_nrm_conspt"
          label="Total NRM Consumption"
          isString={true}
          value={totalNRM.tot_Consumpt}
          onChange={() => handleChange()}
          disabled={isReadOnly}
        />
      </div>
    </div>
  )
}

export default NrmUsageDetails
