import React, { useState, useEffect } from 'react'
import TextBox from '../../input/TextBox'
import SelectBox from '../../input/SelectBox'
import { useRecoilState, useRecoilValue } from 'recoil'
import { production_summary, lining_reference } from '../../../recoil/atoms'
import HourMinInput from '../../input/TimePicker'

const ProductionSummary = () => {
  const [productionSummary, setProductionSummary] =
    useRecoilState(production_summary)
  const [liningReference] = useRecoilState(lining_reference)
  const uniqueId = liningReference.id ? liningReference.id : ''

  // const handleChange = (e) => {
  //     const { name, value } = e.target;
  //     const TrialName = localStorage.getItem("Trial");
  //     // Check if TrialName is "new"
  //     if (TrialName === "new") {
  //       setProductionSummary(prevState => ({
  //         ...prevState,
  //         [name]: value,
  //         id: uniqueId,
  //     }));
  //     } else {
  //       // If TrialName is not "new", no need to generate unique ID
  //       setProductionSummary(prevState => ({
  //         ...prevState,
  //         [name]: value
  //       }));
  //     }
  // };

  const handleChange = (e) => {
    const { name, value } = e.target
    const TrialName = localStorage.getItem('Trial')
    const updatedState = { ...productionSummary, [name]: value }
    if (name === 'average_heat_size' || name === 'life') {
      const averageHeatSize = parseFloat(updatedState.average_heat_size) || ''
      const life = parseFloat(updatedState.life) || ''
      updatedState.total_production = averageHeatSize * life
    }
    console.log('updatedState', updatedState)
    if (TrialName === 'new') {
      updatedState.id = uniqueId
    }

    setProductionSummary(updatedState)
  }

  const changeHeatTimes = (name, newValue) => {
    if (newValue && newValue.isValid()) {
      const hours = newValue.hour().toString().padStart(2, '0')
      const minutes = newValue.minute().toString().padStart(2, '0')

      const formattedTime = `${hours}:${minutes}`

      console.log(`Extracted ${name.replace('_', ' ')}:`, formattedTime)
      setProductionSummary((prevDetails) => {
        const updatedDetails = {
          ...prevDetails,
          [name]: formattedTime,
        }
        //   if (updatedDetails.heat_on_time && updatedDetails.heat_tap_time) {
        //     const duration = calculateDuration(updatedDetails.heat_on_time, updatedDetails.heat_tap_time);
        //     updatedDetails.heat_duration_calculated = duration;

        //     console.log('Calculated Duration:', duration);
        //   }

        return updatedDetails
      })
    } else {
      console.error('Invalid date value:', newValue)
    }
  }

  function calculateProductionTime(productionSummary) {
    try {
      const {
        fce_prod_start_date,
        fce_prod_start_time,
        fce_prod_down_date,
        fce_prod_down_time,
      } = productionSummary

      if (
        !fce_prod_start_date ||
        !fce_prod_start_time ||
        !fce_prod_down_date ||
        !fce_prod_down_time
      ) {
        console.error('Missing date or time values.')
        return 'N/A' // Or handle this case as appropriate
      }

      // Validate date and time formats
      const startDate = new Date(fce_prod_start_date)
      if (isNaN(startDate.getTime())) throw new Error('Invalid start date.')

      const startTime = fce_prod_start_time
      const downDate = new Date(fce_prod_down_date)
      if (isNaN(downDate.getTime())) throw new Error('Invalid down date.')

      const downTime = fce_prod_down_time

      // Combine date and time
      const startDateTime = new Date(
        `${startDate.toISOString().split('T')[0]}T${startTime}:00`
      )
      const downDateTime = new Date(
        `${downDate.toISOString().split('T')[0]}T${downTime}:00`
      )

      // Validate combined DateTime
      if (isNaN(startDateTime.getTime()) || isNaN(downDateTime.getTime()))
        throw new Error('Invalid DateTime values.')

      // Calculate difference
      const differenceInMillis = downDateTime - startDateTime
      const differenceInHours = differenceInMillis / (1000 * 60 * 60)

      return differenceInHours.toFixed(2)
    } catch (error) {
      console.error('Error calculating production time:', error)
      return 'Error' // Or handle this case as appropriate
    }
  }

  const totFurnaceRunninghrsCal = calculateProductionTime(productionSummary)

  return (
    <div>
      <div style={{ flex: 1, marginRight: '15px', marginTop: '15px' }}>
        <TextBox
          label="Fce prod start date"
          name="fce_prod_start_date"
          type="date"
          value={productionSummary.fce_prod_start_date}
          onChange={handleChange}
          showCalendarIcon={true} // Add this prop
        />
        <div style={{ flex: 1, marginRight: '20px' }}>
          <HourMinInput
            label="Fce prod start time"
            name="fce_prod_start_time"
            value={productionSummary.fce_prod_start_time}
            onChange={(newValue) =>
              changeHeatTimes('fce_prod_start_time', newValue)
            }
          />
        </div>
      </div>

      <div style={{ flex: 1, marginRight: '15px', marginTop: '15px' }}>
        <TextBox
          name="fce_prod_down_date"
          label="Fce prod down date"
          type="date"
          value={productionSummary.fce_prod_down_date}
          onChange={handleChange}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <HourMinInput
          name="fce_prod_down_time"
          label="Fce prod down time (HH:mm)"
          value={productionSummary.fce_prod_down_time}
          onChange={(newValue) =>
            changeHeatTimes('fce_prod_down_time', newValue)
          }
        />
      </div>
      <div style={{ flex: 1, marginRight: '15px', marginTop: '15px' }}>
        <TextBox
          name="life"
          label="Life (No of Heats)"
          type="number"
          maxLength={5}
          value={productionSummary.life}
          onChange={handleChange}
        />
      </div>
      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="average_heat_size"
          label="Average Heat size (MT)"
          type="number"
          maxLength={5}
          value={productionSummary.average_heat_size}
          onChange={handleChange}
        />
      </div>
      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="total_furnace_running_hours"
          label="Total Furnace Running Hours (Hrs)"
          type="number"
          value={productionSummary.total_furnace_running_hours}
          onChange={handleChange}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="total_production"
          label="Total Production (MT)"
          type="number"
          value={productionSummary.total_production}
          disabled
          onChange={handleChange}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="total_furnace_running_hours_calculated"
          label="Total Furnace Running Hours (Hrs)"
          type="number"
          value={totFurnaceRunninghrsCal}
          disabled
          onChange={handleChange}
        />
      </div>
    </div>
  )
}

export default ProductionSummary
