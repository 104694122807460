import React, { useEffect } from 'react'
import AutoSelectBox from '../../input/AutoSelectBox'
import Typography from '@mui/material/Typography'
import Helper from '../../services/Helper'
import TextBox from '../../input/TextBox'
import { useRecoilState } from 'recoil'
import { former_cost, former_cost_nrm } from '../../../recoil/atoms'
import StyledButton from '../../input/StyledButton'
import { trial_name } from '../../../recoil/atoms'
import { useRecoilValue } from 'recoil'
import {
  formerCostCampaignNRMSelector,
  formerCostCampaignSelector,
  formerCostPerTonSelector,
  formerCostPerTonNRMSelector,
  savingInFormerCostSelector,
} from '../../../recoil/selector'

export default function FormerCost(props) {
  const [formerCost, setFormerCost] = useRecoilState(former_cost)
  const [formerCostNRM, setFormerCostNRM] = useRecoilState(former_cost_nrm)
  const [trialName] = useRecoilState(trial_name)
  const isReadOnly = trialName === 'viewCostSheet'

  const FormerCostCampaign = useRecoilValue(formerCostCampaignSelector)
  const formerCostPerTon = useRecoilValue(formerCostPerTonSelector)
  const FormerCostCampaignNRM = useRecoilValue(formerCostCampaignNRMSelector)
  const formerCostPerTonNRM = useRecoilValue(formerCostPerTonNRMSelector)
  const savingInFormerCost = useRecoilValue(savingInFormerCostSelector)
  console.log(FormerCostCampaign, 'FormerCostCampaign')

  const handleFormerCostChange = (name, value) => {
    setFormerCost((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleFormerCostNRMChange = (name, value) => {
    setFormerCostNRM((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  //calculation

  // const savedNewLining = JSON.parse(localStorage.getItem('newLining'));
  // const savedSideLining = JSON.parse(localStorage.getItem('sideLining'));
  // const savedPatchingDetails = JSON.parse(localStorage.getItem('patchingDetails'));
  // const savedCalculationObj = JSON.parse(localStorage.getItem('calculationObject'));
  // const savedCalculationObjNRM = JSON.parse(localStorage.getItem('calculationObjectNRM'));

  //basic

  // const FormerCostCampaign = formerCost.former_cost_per_piece * (
  //   (savedNewLining && Number(savedNewLining.no_of_new_lining) || 0) +
  //   (savedSideLining && Number(savedSideLining.no_of_side_lining) || 0) +
  //   (savedPatchingDetails && Number(savedPatchingDetails.no_of_patching) || 0)
  // );

  // const formerCostPerTon = savedCalculationObj && Number(savedCalculationObj.tot_prod) ? (FormerCostCampaign / Number(savedCalculationObj.tot_prod)).toFixed(2): "0.00"

  //   //nrm
  //   const savedNewLiningNRM = JSON.parse(localStorage.getItem('newLiningNRM'));
  //   const savedSideLiningNRM = JSON.parse(localStorage.getItem('sideLiningNRM'));
  //   const savedPatchingDetailsNRM = JSON.parse(localStorage.getItem('patchingDetailsNRM'));

  //   const FormerCostCampaignNRM = formerCostNRM.former_cost_per_piece * (
  //     (savedNewLiningNRM && Number(savedNewLiningNRM.no_of_new_lining) || 0) +
  //     (savedSideLiningNRM && Number(savedSideLiningNRM.no_of_side_lining) || 0) +
  //     (savedPatchingDetailsNRM && Number(savedPatchingDetailsNRM.no_of_patching) || 0)
  //   );

  //   const formerCostPerTonNRM = savedCalculationObjNRM && Number(savedCalculationObjNRM.tot_prod) ? (FormerCostCampaignNRM / savedCalculationObjNRM.tot_prod).toFixed(2) : "0.00"

  //  const savingInFormerCost = (formerCostPerTon - formerCostPerTonNRM).toFixed(2)
  //  console.log(savingInFormerCost, "savingInLaborCost")

  //  const formerCostObj = {
  //   former_cost_per_piece: formerCost.former_cost_per_piece,
  //   former_cost_campaign: FormerCostCampaign,
  //   former_cost_per_steel_produced: formerCostPerTon,
  //   savings_former_cost: "NA",
  // }

  // const formerCostObjNRM = {
  //   former_cost_per_piece: formerCostNRM.former_cost_per_piece,
  //   former_cost_campaign: FormerCostCampaignNRM,
  //   former_cost_per_steel_produced: formerCostPerTonNRM,
  //   savings_former_cost: savingInFormerCost,
  // }

  useEffect(() => {
    if (
      props.trialName === 'costSheetEdit' &&
      props.editSavingsDetails.length > 0
    ) {
      console.log(props.editSavingsDetails, 'props.editSavingsDetails')
      let editObj = doesKeyExist(
        props.editSavingsDetails,
        'formerCostDataObject'
      )
      console.log(editObj, 'editObj')
      if (editObj) {
        setFormerCost(editObj.formerCostDataObject.formerCost.basic)
        setFormerCostNRM(editObj.formerCostDataObject.formerCost.nrm)
      }
    }
  }, [props.editSavingsDetails])

  function doesKeyExist(array, key) {
    for (let i = 0; i < array.length; i++) {
      if (Array.isArray(array[i])) {
        // If the current item is an array, recursively search within it
        const result = doesKeyExist(array[i], key)
        if (result) return result
      } else if (typeof array[i] === 'object') {
        // If the current item is an object, check if it has the desired key
        if (array[i].hasOwnProperty(key)) {
          return array[i]
        }
      }
    }
    return false
  }

  // const handlenextTriger =()=>{
  //   props.handleNext()
  //   props.handleFormerCostObject(formerCostObj, formerCostObjNRM)
  // }

  const custDet = JSON.parse(localStorage.getItem('customerDetails'))

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body1"
          style={{
            color: '#000000',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '20px',
            marginTop: '0px',
          }}
        >
          {custDet.parameter}
        </Typography>
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="former_cost_per_piece"
          label="Former cost per piece"
          type="number"
          value={formerCost.former_cost_per_piece}
          onChange={(event) =>
            handleFormerCostChange('former_cost_per_piece', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="total_former_cost_campaign"
          label="Total former cost in a campaign"
          type="number"
          value={FormerCostCampaign ? FormerCostCampaign : '0.00'}
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="former_cost_per_ton"
          label="Former cost per Ton of steel produced"
          type="number"
          value={formerCostPerTon ? formerCostPerTon : '0.00'}
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="savings_former_cost"
          label="Savings in Former cost"
          type="number"
          value="NA"
          onChange={(event) =>
            handleFormerCostChange('former_cost_per_piece', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body1"
          style={{
            color: '#000000',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '20px',
            marginTop: '0px',
          }}
        >
          NRM
        </Typography>
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="former_cost_per_piece"
          label="Former cost per piece"
          type="number"
          value={formerCostNRM.former_cost_per_piece}
          onChange={(event) =>
            handleFormerCostNRMChange(
              'former_cost_per_piece',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="total_former_cost_campaign"
          label="Total former cost in a campaign"
          type="number"
          value={FormerCostCampaignNRM ? FormerCostCampaignNRM : '0.00'}
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="former_cost_per_ton"
          label="Former cost per Ton of steel produced"
          type="number"
          value={formerCostPerTonNRM ? formerCostPerTonNRM : '0.00'}
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="savings_former_cost"
          label="Savings in Former cost"
          type="number"
          value={savingInFormerCost ? savingInFormerCost : '0.00'}
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>

      {/* <div style={{ justifyContent: 'space-between', flexDirection: "row", display: "flex", marginTop: "auto", marginBottom: "10px", position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1000 }}>
        <StyledButton
          variant="outlined"
          text="Cancel"
          onClick={props.handleBack}
          disabled={props.activeStep === 0}
          style={{ marginRight: 10 }}
        />
        <StyledButton
          variant="contained"
          color="primary"
          text={
            (props.trialName === 'edit' && props.selectedTab === 2) ? 'Update' :
            (props.activeStep === (props.selectedTab === 0 ? props.CostSheetTabs.length - 1 : props.savingsDetails.length - 1)) ? 'Finish' : 
            'Next'
          }
        onClick={handlenextTriger}
        />
      </div> */}
    </div>
  )
}
