import React, { useState, useEffect } from 'react'
import { Button, Modal, Box, Typography } from '@mui/material'
import { styled } from '@mui/system'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import TextBox from '../input/TextBox'
import StyledButton from '../input/StyledButton'
import SelectBox from '../input/SelectBox'
import API from '../services/API'
import axios from 'axios'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material'
import EditIcon from '../../assets/icons/ic_edit.svg'
import DeleteIcon from '../../assets/icons/ic_delete.svg'
import AlertDialog from '../input/AlertDialog'
import Helper from '../services/Helper'
import SearchIcon from '../../assets/icons/ic_search.svg'
// import FilterIcon from "../../assets/icons/ic_filter.svg";
import DownloadIcon from '../../assets/icons/ic_dowload_settings.svg'
import closeIcon from '../../assets/icons/ic_close.svg'
import CircularProgress from '@mui/material/CircularProgress'
const ExcelJS = require('exceljs')

const ModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: 700,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[24],
  padding: theme.spacing(4),
}))

const firstRowStyles = {
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 800,
  lineHeight: '24px',
  letterSpacing: '-0.006em',
  textAlign: 'left',
  color: '#252C32',
}

const Furnaces = () => {
  const [open, setOpen] = useState(false)
  const [formData, setFormData] = useState({
    furnace_number: '',
    year_of_manufacture: '',
    capacity: '',
    coil_grout: '',
    panels_info: '',
    crucible_info_id: '',
    end_product: '',
    steel_grade: '',
    ms_grade_ratio: '',
    supplier_name: '',
    supplier_contact_number: '',
    assign_customer_id: '',
  })
  const [furnaces, setFurnaces] = useState([])
  const [selectedFurnaceIndex, setSelectedFurnaceIndex] = useState(null)
  const [isEditMode, setIsEditMode] = useState(false) // New state for edit mode
  const [isInputVisible, setInputVisible] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredFurnaces, setFilteredFurnaces] = useState([])
  const [formErrors, setFormErrors] = useState({}) // Initialize formErrors state
  const [loading, setLoading] = useState(false) // Loader state
  const [error, setError] = useState(null) // Error state
  const [customers, setCustomers] = useState([])
  const access_token = localStorage.getItem('accesstoken')

  useEffect(() => {
    fetchData()
    fetchCustomers()
  }, [])

  const fetchCustomers = async () => {
    try {
      const response = await axios.get(API.GET_CUSTOMER, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }) // Adjust the URL as needed
      setCustomers(response.data) // Update state with fetched data
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const fetchData = async () => {
    try {
      const response = await axios.get(API.GET_FURNACES, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      console.log('furnace response', response)
      setFurnaces(response.data)
    } catch (error) {
      console.error('Error fetching users:', error)
    }
  }

  const handleOpenModal = () => {
    setOpen(true)
    setIsEditMode(false)
    setFormData({
      furnace_number: '',
      year_of_manufacture: '',
      capacity: '',
      coil_grout: '',
      panels_info: '',
      crucible_info_id: '',
      end_product: '',
      steel_grade: '',
      ms_grade_ratio: '',
      supplier_name: '',
      supplier_contact_number: '',
    })
  }

  const handleCloseModal = () => {
    setOpen(false)
  }

  const getCrucibleInfoName = (crucible_info_id) => {
    const crucible = Helper.CrucibleInfo.find(
      (crucibleInfo) => crucibleInfo.id === parseInt(crucible_info_id)
    )
    return crucible ? crucible.name : '' //
  }

  const handleEdit = async (index) => {
    setSelectedFurnaceIndex(index)
    setIsEditMode(true)
    // Populate formData with data of the selected customer
    const selectedFurnace = furnaces[index]
    setFormData({
      furnace_number: selectedFurnace.furnace_number,
      year_of_manufacture: selectedFurnace.year_of_manufacture,
      capacity: selectedFurnace.capacity,
      coil_grout: selectedFurnace.coil_grout,
      panels_info: selectedFurnace.panels_info,
      crucible_info_id: selectedFurnace.crucible_info_id,
      end_product: selectedFurnace.end_product,
      steel_grade: selectedFurnace.steel_grade,
      ms_grade_ratio: selectedFurnace.ms_grade_ratio,
      supplier_name: selectedFurnace.supplier_name,
      supplier_contact_number: selectedFurnace.supplier_contact_number,
      assign_customer_id: selectedFurnace.assign_customer_id,
    })

    setOpen(true)
  }

  const handleDelete = async (index) => {
    setSelectedFurnaceIndex(index)
    const selectedFurnace = furnaces[index]
    const id = selectedFurnace.id
    AlertDialog({
      type: 'delete',
      title: 'Delete?',
      text: 'Are you sure you want to delete?',
      confirmButtonText: 'Yes',
      onConfirm: async () => {
        const response = await axios.delete(`${API.DELETE_FURNACE}/${id}`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        if (response) {
          fetchData()
        }
      },
    })
  }

  const handleChange = (event) => {
    const { name, value } = event.target || event

    // Validation for furnace_number
    let updatedValue = value
    if (name === 'furnace_number') {
      const numValue = parseInt(value, 10)
      if (numValue < 1 || numValue > 20) {
        updatedValue = '' // Reset to empty string if out of range
      }
    }

    // const updatedValue = name === 'crucible_info_id' ? value : value;
    setFormData((prevState) => ({
      ...prevState,
      [name]: updatedValue,
    }))
  }

  // const handleSave = async () => {
  //   const jsonData = formData;
  //   console.log("jasonDataaaa", jsonData);
  //   const response = await axios.post(API.INSERT_FURNACE, jsonData);
  //   console.log("responseresponse", response);
  // }

  const handleSave = async () => {
    try {
      const isValid = await validateFormData()
      if (!isValid) return

      setLoading(true) // Start loader

      const jsonData = formData
      console.log('jsonData', jsonData)
      let response
      let successMessage

      if (isEditMode && selectedFurnaceIndex !== null) {
        const furnaceId = furnaces[selectedFurnaceIndex].id
        response = await axios.put(
          `${API.UPDATE_FURNACE}/${furnaceId}`,
          jsonData,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        successMessage = 'Furnace Updated Successfully'
      } else {
        response = await axios.post(API.INSERT_FURNACE, jsonData, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        successMessage = 'Furnace Inserted Successfully'
      }

      if (response.status === 200) {
        AlertDialog({
          type: 'success',
          title: 'Success',
          text: successMessage,
          confirmButtonText: 'Ok',
          onConfirm: async () => {
            await fetchData()
          },
        })
      } else {
        console.error('Error saving data:', response.data)
        AlertDialog({
          type: 'warning',
          title: 'Error',
          text: 'Failed to save data',
        })
      }

      setOpen(false) // Close the modal after saving
    } catch (error) {
      console.error('Error saving data:', error)
      AlertDialog({
        type: 'warning',
        title: 'Error',
        text: error.message || 'An error occurred',
      })
    } finally {
      setLoading(false) // Stop loader regardless of success or failure
    }
  }

  const validateFormData = async () => {
    // Implement your validation logic here
    const errors = {}

    // Check if organization name is provided
    if (!formData.assign_customer_id) {
      errors.assign_customer_id = 'Assign Customer is required'
    }
    // Check if organization name is provided
    // if (!formData.furnace_number) {
    //   errors.furnace_number = 'Furnace Number is required';
    // }

    if (!formData.furnace_number) {
      errors.furnace_number = 'Furnace Number is required'
    } else {
      const furnaceNumber = parseInt(formData.furnace_number, 10)
      if (isNaN(furnaceNumber) || furnaceNumber < 1 || furnaceNumber > 20) {
        errors.furnace_number = 'Furnace Number must be between 1 and 20'
      }
    }

    if (!formData.capacity) {
      errors.capacity = 'Capacity is required'
    }

    if (!formData.coil_grout) {
      errors.coil_grout = 'Coil Grout is required'
    }

    // Check if city is provided
    if (!formData.crucible_info_id) {
      errors.crucible_info_id = 'Crucible Info is required'
    }
    if (!formData.end_product) {
      errors.end_product = 'End Product is required'
    }

    if (!formData.steel_grade) {
      errors.steel_grade = 'Steel Grade is required'
    }

    // Set the form errors state
    setFormErrors(errors)

    // Return true if there are no errors, otherwise return false
    return Object.keys(errors).length === 0
  }

  const handleSearchIconClick = () => {
    setInputVisible(true)
  }

  const handleInputChange = (event) => {
    const query = event.target.value.toLowerCase() // Convert query to lowercase
    setSearchQuery(query)
    let filtered
    if (query.trim() === '') {
      filtered = furnaces // Reset to original list if query is empty
    } else {
      filtered = furnaces.filter((furnace) => {
        const furnaceNumberIncludesQuery = furnace.furnace_number
          .toLowerCase()
          .includes(query.toLowerCase()) // Case-insensitive comparison
        const yearOfMfgIncludesQuery = furnace.year_of_manufacture
          .toString()
          .includes(query)
        const capacityIncludesQuery = furnace.capacity
          .toLowerCase()
          .includes(query)
        const coilGroutIncludesQuery = furnace.coil_grout
          .toLowerCase()
          .includes(query)
        const crucibleInfotIncludesQuery = getCrucibleInfoName(
          furnace.crucible_info_id
        )
          .toLowerCase()
          .includes(query)
        const endProductIncludesQuery = furnace.end_product
          .toLowerCase()
          .includes(query)
        const steelGradeIncludesQuery = furnace.steel_grade
          .toLowerCase()
          .includes(query)
        return (
          furnaceNumberIncludesQuery ||
          yearOfMfgIncludesQuery ||
          capacityIncludesQuery ||
          coilGroutIncludesQuery ||
          crucibleInfotIncludesQuery ||
          endProductIncludesQuery ||
          steelGradeIncludesQuery
        )
      })
    }
    setFilteredFurnaces(filtered)
  }

  const handleCloseIconClick = () => {
    setInputVisible(false)
    setSearchQuery('')
    setFilteredFurnaces([])
  }

  const handleExcelDownload = async () => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Furnaces')
    // Define column headers
    worksheet.columns = [
      { header: 'No', key: 'no', width: 10 },
      { header: 'Furnace Number', key: 'furnace_number', width: 20 },
      { header: 'Year of Mfg.', key: 'year_of_manufacture', width: 20 },
      { header: 'Capacity', key: 'capacity', width: 20 },
      { header: 'Coil Grout', key: 'coil_grout', width: 30 },
      { header: 'Crucible Info', key: 'crucible_info', width: 30 },
      { header: 'End Product', key: 'end_product', width: 30 },
      { header: 'Steel Grades', key: 'steel_grade', width: 30 },
    ]

    const dataToDownload = searchQuery ? filteredFurnaces : customers

    // Add customer data to the worksheet
    dataToDownload.forEach((row, index) => {
      const crucibleInfo = getCrucibleInfoName(row.crucible_info_id)
      worksheet.addRow({ ...row, no: index + 1, crucible_info: crucibleInfo })
    })


    // Generate the Excel file as a Blob
    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob)

    // Create a temporary <a> element to trigger the download
    const link = document.createElement('a')
    link.href = url
    link.download = 'furnaces.xlsx'

    // Programmatically click the link to trigger the download
    document.body.appendChild(link)
    link.click()

    // Cleanup
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }

  // const handleSave = async () => {
  //   try {
  //     const jsonData = formData;
  //     try {
  //       // const jsonData = formData;
  //       if (isEditMode && selectedFurnaceIndex !== null) {
  //         const furnaceId = furnaces[selectedFurnaceIndex].id;
  //         const response = await axios.put(`${API.UPDATE_FURNACE}/${furnaceId}`, jsonData);
  //         console.log("response", response);
  //          await fetchData();
  //       } else {
  //         const response = await axios.post(API.INSERT_FURNACE, jsonData);
  //         console.log("response", response);
  //        await fetchData();
  //       }
  //       setOpen(false); // Close the modal after saving
  //     } catch (error) {
  //       console.error('Error saving data:', error);
  //     }
  //   } catch (error) {
  //     console.error('Error saving user:', error);
  //   }
  // };
  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: '1px solid #E5E9EB',
          paddingBottom: '8px',
          marginBottom: '8px',
        }}
      >
        <Typography
          variant="h5"
          component="div"
          sx={{
            fontFamily: 'Inter',
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '24px',
            letterSpacing: '-0.006em',
            textAlign: 'left',
            color: '#252C32',
          }}
        >
          Furnace Details
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={SearchIcon}
            alt="search icon"
            style={{ marginRight: '15px', cursor: 'pointer' }}
            onClick={handleSearchIconClick}
          />
          {isInputVisible && (
            <div style={{ position: 'relative' }}>
              <input
                type="text"
                value={searchQuery}
                onChange={handleInputChange}
                placeholder="Search..."
                style={{ marginRight: '15px', height: '35px' }}
              />
              <img
                src={closeIcon}
                alt="close icon"
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '20px',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer',
                }}
                onClick={handleCloseIconClick}
              />
            </div>
          )}
          {/* <img src={FilterIcon} style={{ marginRight: '15px' }} /> */}
          <img
            src={DownloadIcon}
            style={{ marginRight: '20px' }}
            onClick={handleExcelDownload}
          />
          <Button
            variant="contained"
            color="primary"
            sx={{
              textTransform: 'none', // Remove all-caps transformation
            }}
            onClick={handleOpenModal}
          >
            Add Furnace
          </Button>
        </div>
      </div>

      <FurnaceTable
        furnaces={furnaces}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        getCrucibleInfoName={getCrucibleInfoName}
        filteredFurnaces={filteredFurnaces}
        searchQuery={searchQuery}
      />

      <FurnaceModal
        open={open}
        handleClose={handleCloseModal}
        formData={formData}
        handleChange={handleChange}
        handleSave={handleSave}
        isEditMode={isEditMode}
        formErrors={formErrors}
        customers={customers}
      />
    </div>
  )
}

const FurnaceTable = ({
  furnaces,
  handleEdit,
  handleDelete,
  getCrucibleInfoName,
  filteredFurnaces,
  searchQuery,
}) => {
  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={firstRowStyles}>S.No</TableCell>
              <TableCell style={firstRowStyles}>Furnace Number</TableCell>
              <TableCell style={firstRowStyles}>Year of Mfg.</TableCell>
              <TableCell style={firstRowStyles}>Capacity</TableCell>
              <TableCell style={firstRowStyles}>Coil Grout</TableCell>
              <TableCell style={firstRowStyles}>Crucible Info.</TableCell>
              <TableCell style={firstRowStyles}>End Product</TableCell>
              <TableCell style={firstRowStyles}>Steel Grades</TableCell>
              <TableCell style={firstRowStyles}>Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {(searchQuery ? filteredFurnaces : furnaces).map(
              (furnace, index) => (
                <TableRow key={furnaces.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{furnace.furnace_number}</TableCell>
                  <TableCell>{furnace.year_of_manufacture}</TableCell>
                  <TableCell>{furnace.capacity}</TableCell>
                  <TableCell>{furnace.coil_grout}</TableCell>
                  <TableCell>
                    {getCrucibleInfoName(furnace.crucible_info_id)}
                  </TableCell>
                  <TableCell>{furnace.end_product}</TableCell>
                  <TableCell>{furnace.steel_grade}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="edit"
                      onClick={() => handleEdit(index)}
                    >
                      <img src={EditIcon} alt="Edit Icon" />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={() => handleDelete(index)}
                    >
                      <img src={DeleteIcon} alt="Delete Icon" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
const FurnaceModal = ({
  open,
  handleClose,
  formData,
  handleChange,
  handleSave,
  isEditMode,
  formErrors,
  customers,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContent style={{ maxHeight: '80vh', overflowY: 'auto' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="body1"
            sx={{
              fontFamily: 'Inter',
              fontSize: 18,
              fontWeight: 600,
              lineHeight: '24px',
              letterSpacing: '-0.006em',
              textAlign: 'left',
              color: '#252C32',
            }}
          >
            Add Furnace
          </Typography>
        </div>

        <div>
          <div style={{ marginTop: '10px' }}>
            <Typography
              sx={{
                fontFamily: 'Inter',
                fontSize: 12,
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '-0.006em',
                textAlign: 'left',
                color: '#7F7D83',
              }}
            >
              CUSTOMER INFORMATION
            </Typography>
          </div>

          <div style={{ marginTop: '10px' }}>
            <SelectBox
              name="assign_customer_id"
              labelValue="Assign Customer*"
              value={formData.assign_customer_id} // Assuming role is one of the fields in your form
              onChange={handleChange} // Assuming you have a handleChange function
              options={customers}
              labelCondition="organization_name" // Assuming you want to display role names
              error={formErrors.assign_customer_id}
              isLabel={true}
            />
          </div>
        </div>
        <div>
          <div style={{ marginTop: '10px' }}>
            <Typography
              sx={{
                fontFamily: 'Inter',
                fontSize: 12,
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '-0.006em',
                textAlign: 'left',
                color: '#7F7D83',
              }}
            >
              FURNACE INFORMATION
            </Typography>
          </div>
          <div style={{ flexDirection: 'row', display: 'flex', flex: 1 }}>
            <div style={{ marginTop: '15px', marginRight: '15px' }}>
              <TextBox
                name="furnace_number"
                label="Furnace Number*"
                isString={true}
                value={formData.furnace_number}
                onChange={handleChange}
                error={formErrors.furnace_number}
              />
            </div>

            <div style={{ marginTop: '15px', marginRight: '15px' }}>
              <TextBox
                name="year_of_manufacture"
                label="Year of Manufacture"
                isString={true}
                value={formData.year_of_manufacture}
                onChange={handleChange}
                // error={formErrors.firstName}
              />
            </div>
            <div style={{ marginTop: '15px' }}>
              <TextBox
                name="capacity"
                label="Capacity*"
                isString={true}
                value={formData.capacity}
                onChange={handleChange}
                error={formErrors.capacity}
              />
            </div>
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ flex: 1, marginRight: '15px' }}>
            <TextBox
              name="coil_grout"
              label="Coil Grout*"
              isString={true}
              value={formData.coil_grout}
              onChange={handleChange}
              error={formErrors.coil_grout}
            />
          </div>

          <div style={{ flex: 1, marginRight: '15px' }}>
            <TextBox
              name="panels_info"
              label="Panels Information"
              isString={true}
              value={formData.panels_info}
              onChange={handleChange}
              // error={formErrors.firstName}
            />
          </div>

          <div style={{ flex: 1 }}>
            <SelectBox
              name="crucible_info_id"
              labelValue="Crucible Info.*"
              value={formData.crucible_info_id} // Assuming role is one of the fields in your form
              onChange={handleChange} // Assuming you have a handleChange function
              options={Helper.CrucibleInfo}
              labelCondition="name" // Assuming you want to display role names
              isLabel={true}
              error={formErrors.crucible_info_id}
            />
          </div>
        </div>

        <div>
          <div style={{ marginTop: '10px' }}>
            <Typography
              sx={{
                fontFamily: 'Inter',
                fontSize: 12,
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '-0.006em',
                textAlign: 'left',
                color: '#7F7D83',
              }}
            >
              PRODUCT INFORMATION
            </Typography>
          </div>
          <div style={{ flexDirection: 'row', display: 'flex', flex: 1 }}>
            <div style={{ marginTop: '15px', marginRight: '15px' }}>
              <TextBox
                name="end_product"
                label="End Product*"
                isString={true}
                onChange={handleChange}
                value={formData.end_product}
                error={formErrors.end_product}
              />
            </div>

            <div style={{ marginTop: '15px', marginRight: '15px' }}>
              <TextBox
                name="steel_grade"
                label="Steel Grade*"
                isString={true}
                onChange={handleChange}
                value={formData.steel_grade}
                error={formErrors.steel_grade}
              />
            </div>
            <div style={{ marginTop: '15px' }}>
              <TextBox
                name="ms_grade_ratio"
                label="MS Grade Ratio"
                isString={true}
                onChange={handleChange}
                value={formData.ms_grade_ratio}
              />
            </div>
          </div>
        </div>

        <div>
          <div style={{ marginTop: '10px' }}>
            <Typography
              sx={{
                fontFamily: 'Inter',
                fontSize: 12,
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '-0.006em',
                textAlign: 'left',
                color: '#7F7D83',
              }}
            >
              SUPPLIER INFORMATION
            </Typography>
          </div>
          <div style={{ flexDirection: 'row', display: 'flex', flex: 1 }}>
            <div style={{ marginTop: '15px', marginRight: '15px' }}>
              <TextBox
                name="supplier_name"
                label="Supplier Name"
                isString={true}
                value={formData.supplier_name}
                onChange={handleChange}
                // error={formErrors.firstName}
              />
            </div>

            <div style={{ marginTop: '15px' }}>
              <TextBox
                name="supplier_contact_number"
                label="Supplier Contact Number"
                isString={true}
                onChange={handleChange}
                value={formData.supplier_contact_number}
                // error={formErrors.firstName}
              />
            </div>
          </div>
        </div>
        <div style={{ flexDirection: 'row', flex: 1, display: 'flex' }}>
          <StyledButton
            variant="outlined"
            text="Cancel"
            onClick={handleClose} // Define handleBackButtonClick function
          />
          <StyledButton
            variant="contained"
            text={isEditMode ? 'Update' : 'Save'}
            onClick={handleSave}
          />
        </div>
      </ModalContent>
    </Modal>
  )
}

export default Furnaces
