import React, { useState, useRef, useEffect } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  Snackbar,
  Alert,
} from '@mui/material'
import EditIcon from '../../../assets/icons/ic_edit.svg'
import DeleteIcon from '../../../assets/icons/ic_delete.svg'
import IconButton from '@mui/material/IconButton'
import API from '../../services/API'
import axios from 'axios'
import { lining_reference, trail_name } from '../../../recoil/atoms'
import { useNavigate } from 'react-router-dom'
import AlertDialog from '../../input/AlertDialog'
import StyledButton from '../../input/StyledButton'

const ViewTrialNRM = () => {
  const navigate = useNavigate()
  const [tableData, setTableData] = useState([])
  const [generalDetails, setGeneralDetails] = useState([])
  const [furnaceDetails, setFurnaceDetails] = useState([])
  const [alloyingAddition, setAlloyingAddition] = useState([])
  const [carbonObj, setCarbonObj] = useState([])
  const [chargeMixDetails, setChargeMixDetails] = useState([])
  const [otherParameters, setOtherParameters] = useState([])
  const [headerText, setHeaderText] = useState('Lining Reference')
  const [submissionStatus, setSubmissionStatus] = useState('')
  const tableRef = useRef(null)
  const id = localStorage.getItem('nrm_performance_id')
  const [comments, setComments] = useState('')
  const currentUser = JSON.parse(localStorage.getItem('user'))
  const [commentsError, setCommentsError] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [editPerformed, setEditPerformed] = useState('')
  const viewMode = localStorage.getItem('Trial')
  const [loading, setLoading] = useState(false) // Loader state
  const [error, setError] = useState(null)
  const [isOnline, setIsOnline] = useState(window.navigator.onLine)
  const access_token = localStorage.getItem('accesstoken')

  const [db, setDb] = useState(null)

  useEffect(() => {
    const handleOnline = () => {
      if (!isOnline) {
        setIsOnline(true)
        localStorage.setItem('onlinestate', 1)

        window.location.reload()
      }
    }

    const handleOffline = () => {
      localStorage.setItem('onlinestate', 0)
      // setIsOnlines(false)
      setIsOnline(false)
      // navigate('/reports')
    }

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [isOnline])

  const dbName = 'pcr'
  const customerName = 'customers'
  const furnacesName = 'furnaces'
  const templateName = 'template'
  const segmentName = 'segment'
  const datafileName = 'datafile'
  const dailytrialName = 'daily_trial'
  const nrmName = 'nrm_performance'
  const erosionName = 'erosion_profile'
  const dataCollectionName = 'data_collection'
  const costSheetName = 'cost_sheet'
  const LiningName = 'lining_patching_no'

  useEffect(() => {
    if (!isOnline) {
      const request = indexedDB.open(dbName, 1)

      request.onupgradeneeded = (event) => {
        const database = event.target.result
        database.createObjectStore(customerName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(furnacesName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(templateName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(segmentName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(datafileName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(dailytrialName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(nrmName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(erosionName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(dataCollectionName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(costSheetName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(LiningName, {
          keyPath: 'id',
          autoIncrement: true,
        })
      }

      request.onsuccess = (event) => {
        const database = event.target.result
        const transaction = database.transaction(
          [
            'customers',
            'furnaces',
            'template',
            'segment',
            'datafile',
            'daily_trial',
            'nrm_performance',
            'erosion_profile',
            'data_collection',
            'cost_sheet',
            'lining_patching_no',
          ],
          'readwrite'
        )

        setDb(database)
      }

      request.onerror = (event) => {
        console.error('Error opening IndexedDB', event)
      }
    }
  }, [isOnline])

  useEffect(() => {
    if (db) {
      fetchData()
    }
  }, [db])

  useEffect(() => {
    if (isOnline) {
      fetchData()
    }
  }, [id, isOnline])

  useEffect(() => {
    fetchData()
  }, [id])

  const fetchData = async () => {
    if (isOnline) {
      try {
        const response = await axios.get(
          `${API.GET_NRM_PERFORMANCE_BY_ID}/${id}`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        const data = response.data
        console.log(data, 'dataviewtrial')

        // const result = data.lining_reference
        //   .filter((nrm) => !nrm.isdelete) // Filter out items with isdelete set to false
        //   .map((nrm) => {
        //     const objectId = nrm.id;
        //     console.log("objectId", objectId);

        //     const submissionstatus = nrm.submission_status;
        //     setSubmissionStatus(submissionstatus);

        //     // Match details from other arrays based on the id
        //     const matchedDetails = {
        //       lining_reference: nrm,
        //       production_summary: data.production_summary.find(
        //         (item) => item.id === objectId || {}
        //       ),
        //       lining_material_product_details:
        //         data.lining_material_product_details.find(
        //           (item) => item.id === objectId || {}
        //         ),
        //       lining_material_consumption:
        //         data.lining_material_consumption.find(
        //           (item) => item.id === objectId || {}
        //         ),
        //       dimensional_parameters: data.dimensional_parameters.find(
        //         (item) => item.id === objectId || {}
        //       ),
        //       sintering_parameters: data.sintering_parameters.find(
        //         (item) => item.id === objectId || {}
        //       ),
        //       id: data.id,
        //       // upload_datafile_id: data.upload_datafile_id // Make sure to include this if needed
        //     };
        //     console.log(matchedDetails, "matchedDetails");
        //     return matchedDetails;
        //   });

        if (data && data.lining_reference) {
          const result = data.lining_reference
            .filter((detail) => detail && !detail.isdelete) // Filter out items with isdelete set to false
            .map((detail) => {
              if (detail) {
                const submissionstatus = detail.submission_status
                const comments = detail.comments
                console.log('comments', comments)
                console.log('submissionstatus', submissionStatus)
                setComments(comments)
                setSubmissionStatus(submissionstatus)
                const objectId = detail.id
                // Match details from other arrays based on the id
                const matchedDetails = {
                  lining_reference: detail,
                  lining_material_product_details:
                    data.lining_material_product_details,
                  lining_material_consumption: data.lining_material_consumption,
                  dimensional_parameters: data.dimensional_parameters,
                  sintering_parameters: data.sintering_parameters,
                  production_summary: data.production_summary,
                  id: data.id,
                }

                console.log(matchedDetails, 'matchedDetails')

                return matchedDetails
              } else {
                return null // or any default value you want to return for null objects
              }
            })
            .filter((detail) => detail !== null) // Filter out null objects

          console.log('RESULTTT', result)
          setTableData(result)
        }
        // setTableData(result);
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    } else {
      try {
        const transaction1 = db.transaction(nrmName, 'readonly')
        const objectStore1 = transaction1.objectStore(nrmName)
        const id = localStorage.getItem('nrmName_id')
        const id_int = parseInt(id, 10)
        const getAllRequest1 = objectStore1.get(id_int)

        getAllRequest1.onsuccess = () => {
          const trialdata = getAllRequest1.result
          console.log('allData123', trialdata)

          const tableData = []
          let Trial = 0

          // trialdata.general_details.forEach((generalDetail, index) => {
          //   const groupedData = {
          //     general_details: [generalDetail],
          //     furnace_details: (trialdata.furnace_details[index] && trialdata.furnace_details[index]) || {},
          //     charge_mix_details: (trialdata.charge_mix_details[index] && trialdata.charge_mix_details[index]) || {},
          //     carbon: (trialdata.carbon[index] && trialdata.carbon[index]) || {},
          //     alloying_addition: (trialdata.alloying_addition[index] && trialdata.alloying_addition[index]) || {},
          //     other_parameters: (trialdata.other_parameters[index] && trialdata.other_parameters[index]) || {},
          //     id: trialdata.datafile_id,
          //   };

          //   tableData.push(groupedData);
          //   Trial++;
          // });

          let generalDetailsArray = []
          if (typeof trialdata.lining_reference === 'object') {
            // Convert general_details object to an array
            generalDetailsArray = [trialdata.lining_reference]
          } else if (Array.isArray(trialdata.lining_reference)) {
            generalDetailsArray = trialdata.lining_reference
          }

          // Iterate through generalDetailsArray
          generalDetailsArray.forEach((lining_reference, index) => {
            const groupedData = {
              lining_reference: Array.isArray(lining_reference)
                ? lining_reference
                : [lining_reference],
              production_summary: Array.isArray(
                trialdata.production_summary[index]
              )
                ? trialdata.production_summary[index]
                : trialdata.production_summary || [],
              lining_material_product_details: Array.isArray(
                trialdata.lining_material_product_details[index]
              )
                ? trialdata.lining_material_product_details[index]
                : trialdata.lining_material_product_details || [],
              lining_material_consumption: Array.isArray(
                trialdata.lining_material_consumption[index]
              )
                ? trialdata.lining_material_consumption[index]
                : trialdata.lining_material_consumption || [],
              dimensional_parameters: Array.isArray(
                trialdata.dimensional_parameters[index]
              )
                ? trialdata.dimensional_parameters[index]
                : trialdata.dimensional_parameters || [],
              sintering_parameters: Array.isArray(
                trialdata.sintering_parameters[index]
              )
                ? trialdata.sintering_parameters[index]
                : trialdata.sintering_parameters || [],
              id: trialdata.datafile_id,
            }

            tableData.push(groupedData)
            Trial++
          })

          console.log('Table Data:', tableData)
          setTableData(tableData)
        }

        getAllRequest1.onerror = (event) => {
          setTableData([])
          console.error('Error getting data from IndexedDB', event)
        }
      } catch (error) {
        console.error('Error fetching data:', error)
        setTableData([])
      }
    }
  }
  const handleEdit = (index) => {
    localStorage.setItem('TrialIndex', index)
    navigate('/nrmperformance')
  }

  const handleSnackbarClose = () => {
    setShowSnackbar(false)
  }

  const handleResubmit = () => {
    if (
      (currentUser.role.role_id === 1 || currentUser.role.role_id === 2) &&
      submissionStatus === 'rejected'
    ) {
      if (editPerformed) {
        // Assuming editPerformed is a state variable that tracks whether an edit action has been performed
        // Proceed with resubmit logic
        setShowSnackbar('Resubmitting...')
        // Add your resubmit logic here
      } else {
        // Show a message or handle the case where edit action is required
        setShowSnackbar(
          'Please click on the edit button before proceeding with the resubmit.'
        )
      }
    }
  }

  const handleReject = async () => {
    if (!comments.trim()) {
      setCommentsError(true)
      setShowSnackbar(true)
    } else {
      setCommentsError(false)
      try {
        const updatedLiningReference = tableData.map((item) => ({
          ...item,
          lining_reference: {
            ...item.lining_reference,
            submission_status: 'rejected',
          },
        }))

        const id = localStorage.getItem('nrm_performance_id')

        const updateResponse = await axios.put(
          `${API.NRM_FORM_UPDATE_LINING_REFERENCE}/${id}`,
          { data: updatedLiningReference, id },
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        console.log('Update response:', updateResponse.data)
        if (updateResponse.status === 200) {
          navigate('/rejectstatus')
        } else {
          console.log('problem with API')
        }
      } catch (error) {
        console.error('Error updating data:', error)
      }
    }
  }
  const handleApprove = async () => {
    try {
      const updatedLiningReference = tableData.map((item) => ({
        ...item,
        lining_reference: {
          ...item.lining_reference,
          submission_status: 'approved',
        },
      }))

      const id = localStorage.getItem('nrm_performance_id')

      const updateResponse = await axios.put(
        `${API.NRM_FORM_UPDATE_LINING_REFERENCE}/${id}`,
        { data: updatedLiningReference, id },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      console.log('Update response:', updateResponse.data)
      if (updateResponse.status === 200) {
        navigate('/approvestatus')
      } else {
        console.log('problem with API')
      }
    } catch (error) {
      console.error('Error updating data:', error)
    }
  }

  const handleDelete = async (tableData, index) => {
    if (isOnline) {
      console.log('tableData', tableData, index)
      const id = tableData[index].lining_reference.id
      console.log(id)
      AlertDialog({
        type: 'delete',
        title: 'Delete?',
        text: 'Are you sure you want to delete?',
        confirmButtonText: 'Yes',
        onConfirm: async () => {
          const updatedDataWithoutDeletedItem = tableData.map((item) => {
            if (item.lining_reference.id === id) {
              // Set isdelete to true within general_details for the item at the specified index
              return {
                ...item,
                lining_reference: {
                  ...item.lining_reference,
                  isdelete: true,
                },
              }
            }
            return item
          })
          console.log(
            updatedDataWithoutDeletedItem,
            'updatedDataWithoutDeletedItem'
          )
          // Set the state with the updated array
          setTableData(updatedDataWithoutDeletedItem)

          const rowId = tableData[index].id
          console.log('rowIdrowId', rowId)

          const updateResponse = await axios.put(
            `${API.DELETE_NRM_PERFORMANCE}/${rowId}`,
            { data: updatedDataWithoutDeletedItem, rowId: rowId },
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          )

          if (updateResponse.status === 200) {
            AlertDialog({
              type: 'success',
              title: 'Success',
              text: 'Deleted Successfully',
              confirmButtonText: 'Ok',
            })
          }
        },
      })
    } else {
      console.log('tableData', tableData)
      console.log('index', index)

      const updatedTableData = tableData.map((item) => ({
        ...item,
        lining_reference: item.lining_reference.filter(
          (_, idx) => idx !== index
        ),
        production_summary: item.production_summary.filter(
          (_, idx) => idx !== index
        ),
        lining_material_product_details:
          item.lining_material_product_details.filter(
            (_, idx) => idx !== index
          ),
        lining_material_consumption: item.lining_material_consumption.filter(
          (_, idx) => idx !== index
        ),
        dimensional_parameters: item.dimensional_parameters.filter(
          (_, idx) => idx !== index
        ),
        sintering_parameters: item.sintering_parameters.filter(
          (_, idx) => idx !== index
        ),
      }))

      console.log('updatedTableData', updatedTableData)

      // Update state with the modified array
      setTableData(updatedTableData)

      const id = localStorage.getItem('nrmName_id')
      const TrialIndex = localStorage.getItem('TrialIndex')

      const transaction = db.transaction(nrmName, 'readwrite')
      const objectStore = transaction.objectStore(nrmName)

      const id_int = parseInt(id, 10)
      const getRequest = objectStore.get(id_int)

      getRequest.onsuccess = async () => {
        const trialdata = getRequest.result
        console.log('trialdata', trialdata)

        const updatedTrialData = {
          ...trialdata,

          lining_reference: trialdata.lining_reference.filter(
            (_, idx) => idx !== index
          ),
          production_summary: trialdata.production_summary.filter(
            (_, idx) => idx !== index
          ),
          lining_material_product_details:
            trialdata.lining_material_product_details.filter(
              (_, idx) => idx !== index
            ),
          lining_material_consumption:
            trialdata.lining_material_consumption.filter(
              (_, idx) => idx !== index
            ),
          dimensional_parameters: trialdata.dimensional_parameters.filter(
            (_, idx) => idx !== index
          ),
          sintering_parameters: trialdata.sintering_parameters.filter(
            (_, idx) => idx !== index
          ),
        }

        const updateRequest = objectStore.put(updatedTrialData)

        updateRequest.onsuccess = () => {
          console.log('trialdata updated successfully')
        }

        updateRequest.onerror = (error) => {
          console.error('Error updating trialdata:', error)
        }
      }

      getRequest.onerror = () => {
        console.error(
          'Offline Mode - Error fetching trial data from IndexedDB.'
        )
        setError('Error fetching trial data from IndexedDB.')
        setLoading(false)
      }
    }
  }
  useEffect(() => {
    const handleScroll = () => {
      const container = tableRef.current
      if (container) {
        const rect = container.getBoundingClientRect()
        const containerLeft = rect.left
        const heatOnTimeColumnIndex = 9 // Index of the "Heat ON Time" column
        const cells = container.querySelectorAll('td')
        const heatOnTimeCell = cells[heatOnTimeColumnIndex]
        if (heatOnTimeCell) {
          const heatOnTimeCellRect = heatOnTimeCell.getBoundingClientRect()
          const heatOnTimeCellLeft = heatOnTimeCellRect.left
          if (heatOnTimeCellLeft >= tableRef) {
            setHeaderText('Furnace Details')
          } else {
            setHeaderText('Lining Reference')
          }
        }
      }
    }

    const container = tableRef.current
    if (container) {
      container.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])
  // Empty dependency array to run this effect only once on mount
  const cellStyles = {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '-0.006em',
    textAlign: 'left',
    color: '#000000',
  }

  const rowStyles = {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.006em',
    textAlign: 'left',
    color: '#252C32',
  }

  const firstRowStyle = {
    fontFamily: 'Inter',
    fontSize: '14px',
    width: '30px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.006em',
    textAlign: 'left',
    color: '#252C32',
  }
  const renderCell = (value) => {
    if (value === null || value === undefined || value === '') {
      return 'N/A'
    } else {
      return value
    }
  }
  return (
    <>
      {isOnline ? (
        <div style={{ position: 'relative', overflowX: 'auto' }}>
          {tableData.length === 0 ? (
            <Typography
              variant="body1"
              sx={{ textAlign: 'center', marginTop: '20px' }}
            >
              Trials not found
            </Typography>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '20px',
                  letterSpacing: '-0.006em',
                  textAlign: 'left',
                  color: '#252C32',
                  marginTop: '10px',
                  zIndex: 1,
                  backgroundColor: '#fff',
                  position: 'sticky',
                  top: 0,
                }}
              >
                {headerText}
              </Typography>
              <div style={{ overflowX: 'auto' }}>
                <Table ref={tableRef}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          ...cellStyles,
                          position: 'sticky',
                          left: 0,
                          zIndex: 1,
                          backgroundColor: '#fff',
                        }}
                      >
                        S.No
                      </TableCell>
                      <TableCell style={cellStyles}>Furnace NO.</TableCell>
                      <TableCell style={cellStyles}>Crucible NO.</TableCell>
                      <TableCell style={cellStyles}>Campaign NO.</TableCell>
                      <TableCell style={cellStyles}>
                        Lining or Patching
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Date of Completion lining or Patching
                      </TableCell>

                      <TableCell style={cellStyles}>
                        Fce prod start date
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Fce prod start time
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Fce prod down date
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Fce prod down time
                      </TableCell>
                      <TableCell style={cellStyles}>Life</TableCell>
                      <TableCell style={cellStyles}>
                        Average Heat size
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Total Furnace Running Hours
                      </TableCell>
                      <TableCell style={cellStyles}>Total Production</TableCell>

                      <TableCell style={cellStyles}>Bottom Product</TableCell>
                      <TableCell style={cellStyles}>
                        Sidewall product 1
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Sidewall product 2
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Top Locking product
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Topping mass product
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Sidewall 1 Coverage upto X%
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Sidewall 2 Coverage upto 100%
                      </TableCell>

                      <TableCell style={cellStyles}>
                        NRM consumption bottom
                      </TableCell>
                      <TableCell style={cellStyles}>
                        NRM consumption Sidewall 1
                      </TableCell>
                      <TableCell style={cellStyles}>
                        NRM consumption Sidewall 2
                      </TableCell>
                      <TableCell style={cellStyles}>
                        NRM Consumption at Top Locking
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Topping mass consumption
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Total NRM consumption sidewall
                      </TableCell>
                      <TableCell style={cellStyles}>
                        NRM specific consumption
                      </TableCell>

                      <TableCell style={cellStyles}>Former Dimension</TableCell>
                      <TableCell style={cellStyles}>Coil coat ID</TableCell>
                      <TableCell style={cellStyles}>Fce Ht.</TableCell>
                      <TableCell style={cellStyles}>Castable top Ht.</TableCell>
                      <TableCell style={cellStyles}>Bottom ht.</TableCell>

                      <TableCell style={cellStyles}>
                        Total Sintering time
                      </TableCell>
                      <TableCell style={cellStyles}>holding time</TableCell>
                      <TableCell style={cellStyles}>holding temp</TableCell>
                      <TableCell style={cellStyles}>Reason for down</TableCell>
                      <TableCell style={cellStyles}>IR no.</TableCell>
                      <TableCell style={cellStyles}>Job done by</TableCell>
                      <TableCell style={cellStyles}>Remarks</TableCell>
                      {(submissionStatus === 'draft' ||
                        submissionStatus === 'rejected') &&
                        viewMode !== 'view' && (
                          <TableCell
                            style={{
                              ...cellStyles,
                              position: 'sticky',
                              right: 0,
                              zIndex: 1,
                              backgroundColor: '#fff',
                            }}
                          >
                            Action
                          </TableCell>
                        )}
                      {submissionStatus === 'draft' && viewMode === 'view' && (
                        <TableCell
                          style={{
                            ...cellStyles,
                            position: 'sticky',
                            right: 0,
                            zIndex: 1,
                            backgroundColor: '#fff',
                          }}
                        >
                          Action
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell
                          style={{
                            position: 'sticky',
                            left: 0,
                            zIndex: 1,
                            backgroundColor: '#fff',
                          }}
                        >
                          {renderCell(index + 1)}
                        </TableCell>
                        <TableCell style={rowStyles}>
                          {renderCell(item.lining_reference.furnace_no)}
                        </TableCell>
                        <TableCell style={rowStyles}>
                          {renderCell(item.lining_reference.crucible_no)}
                        </TableCell>
                        <TableCell style={rowStyles}>
                          {renderCell(item.lining_reference.campaign_no)}
                        </TableCell>
                        <TableCell style={rowStyles}>
                          {renderCell(item.lining_reference.lining_or_patching)}
                        </TableCell>
                        <TableCell style={rowStyles}>
                          {renderCell(
                            item.lining_reference
                              .date_of_completion_lining_or_patching
                          )}
                        </TableCell>

                        <TableCell style={rowStyles}>
                          {renderCell(
                            item.production_summary[index].fce_prod_start_date
                          )}
                        </TableCell>
                        <TableCell style={rowStyles}>
                          {renderCell(
                            item.production_summary[index].fce_prod_start_time
                          )}
                        </TableCell>
                        <TableCell style={rowStyles}>
                          {renderCell(
                            item.production_summary[index].fce_prod_down_date
                          )}
                        </TableCell>
                        <TableCell style={rowStyles}>
                          {renderCell(
                            item.production_summary[index].fce_prod_down_time
                          )}
                        </TableCell>
                        <TableCell style={rowStyles}>
                          {renderCell(item.production_summary[index].life)}
                        </TableCell>
                        <TableCell style={rowStyles}>
                          {renderCell(
                            item.production_summary[index].average_heat_size
                          )}
                        </TableCell>
                        <TableCell style={rowStyles}>
                          {renderCell(
                            item.production_summary[index]
                              .total_furnace_running_hours
                          )}
                        </TableCell>
                        <TableCell style={rowStyles}>
                          {renderCell(
                            item.production_summary[index].total_production
                          )}
                        </TableCell>

                        <TableCell style={rowStyles}>
                          {renderCell(
                            item.lining_material_product_details[index]
                              .bottom_product
                          )}
                        </TableCell>
                        <TableCell style={rowStyles}>
                          {renderCell(
                            item.lining_material_product_details[index]
                              .sidewall_product_one
                          )}
                        </TableCell>
                        <TableCell style={rowStyles}>
                          {renderCell(
                            item.lining_material_product_details[index]
                              .sidewall_product_two
                          )}
                        </TableCell>
                        <TableCell style={rowStyles}>
                          {renderCell(
                            item.lining_material_product_details[index]
                              .top_locking_product
                          )}
                        </TableCell>
                        <TableCell style={rowStyles}>
                          {renderCell(
                            item.lining_material_product_details[index]
                              .topping_mass_product
                          )}
                        </TableCell>
                        <TableCell style={rowStyles}>
                          {renderCell(
                            item.lining_material_product_details[index]
                              .sidewall_one_coverage_upto_x
                          )}
                        </TableCell>
                        <TableCell style={rowStyles}>
                          {renderCell(
                            item.lining_material_product_details[index]
                              .sidewall_two_coverage_upto_100
                          )}
                        </TableCell>

                        <TableCell style={rowStyles}>
                          {renderCell(
                            item.lining_material_consumption[index]
                              .nrm_consumption_bottom
                          )}
                        </TableCell>
                        <TableCell style={rowStyles}>
                          {renderCell(
                            item.lining_material_consumption[index]
                              .nrm_consumption_sidewall_one
                          )}
                        </TableCell>
                        <TableCell style={rowStyles}>
                          {renderCell(
                            item.lining_material_consumption[index]
                              .nrm_consumption_sidewall_two
                          )}
                        </TableCell>
                        <TableCell style={rowStyles}>
                          {renderCell(
                            item.lining_material_consumption[index]
                              .nrm_consumption_top_locking
                          )}
                        </TableCell>
                        <TableCell style={rowStyles}>
                          {renderCell(
                            item.lining_material_consumption[index]
                              .topping_mass_consumption
                          )}
                        </TableCell>
                        <TableCell style={rowStyles}>
                          {renderCell(
                            item.lining_material_consumption[index]
                              .total_nrm_consumption_sidewall_one
                          )}
                        </TableCell>
                        <TableCell style={rowStyles}>
                          {renderCell(
                            item.lining_material_consumption[index]
                              .nrm_specific_consumption
                          )}
                        </TableCell>

                        <TableCell style={rowStyles}>
                          {renderCell(
                            item.dimensional_parameters[index].former_dimension
                          )}
                        </TableCell>
                        <TableCell style={rowStyles}>
                          {renderCell(
                            item.dimensional_parameters[index].coil_coat_id
                          )}
                        </TableCell>
                        <TableCell style={rowStyles}>
                          {renderCell(
                            item.dimensional_parameters[index].fce_ht
                          )}
                        </TableCell>
                        <TableCell style={rowStyles}>
                          {renderCell(
                            item.dimensional_parameters[index].costable_top_ht
                          )}
                        </TableCell>
                        <TableCell style={rowStyles}>
                          {renderCell(
                            item.dimensional_parameters[index].bottom_ht
                          )}
                        </TableCell>

                        <TableCell style={rowStyles}>
                          {renderCell(
                            item.sintering_parameters[index]
                              .total_sintering_time
                          )}
                        </TableCell>
                        <TableCell style={rowStyles}>
                          {renderCell(
                            item.sintering_parameters[index].holding_time
                          )}
                        </TableCell>
                        <TableCell style={rowStyles}>
                          {renderCell(
                            item.sintering_parameters[index].reason_for_down
                          )}
                        </TableCell>
                        <TableCell style={rowStyles}>
                          {renderCell(
                            item.sintering_parameters[index].holding_temp
                          )}
                        </TableCell>
                        <TableCell style={rowStyles}>
                          {renderCell(item.sintering_parameters[index].IR_no)}
                        </TableCell>
                        <TableCell style={rowStyles}>
                          {renderCell(
                            item.sintering_parameters[index].job_done_by
                          )}
                        </TableCell>
                        <TableCell style={rowStyles}>
                          {renderCell(item.sintering_parameters[index].remarks)}
                        </TableCell>

                        {(submissionStatus === 'draft' ||
                          submissionStatus === 'rejected') &&
                          viewMode !== 'view' && (
                            <TableCell
                              style={{
                                position: 'sticky',
                                right: 0,
                                zIndex: 1,
                                backgroundColor: '#fff',
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'row',
                              }}
                            >
                              <IconButton
                                aria-label="edit"
                                onClick={() => handleEdit(index)}
                                style={{ marginRight: '5px' }}
                              >
                                <img src={EditIcon} alt="Edit Icon" />
                              </IconButton>
                              <IconButton
                                aria-label="delete"
                                onClick={() => handleDelete(tableData, index)}
                              >
                                <img src={DeleteIcon} alt="Delete Icon" />
                              </IconButton>
                            </TableCell>
                          )}
                        {submissionStatus === 'draft' &&
                          viewMode === 'view' && (
                            <TableCell
                              style={{
                                position: 'sticky',
                                right: 0,
                                zIndex: 1,
                                backgroundColor: '#fff',
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'row',
                              }}
                            >
                              <IconButton
                                aria-label="edit"
                                onClick={() => handleEdit(index)}
                                style={{ marginRight: '5px' }}
                              >
                                <img src={EditIcon} alt="Edit Icon" />
                              </IconButton>
                              <IconButton
                                aria-label="delete"
                                onClick={() => handleDelete(tableData, index)}
                              >
                                <img src={DeleteIcon} alt="Delete Icon" />
                              </IconButton>
                            </TableCell>
                          )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          )}

          {(currentUser.role.role_id === 3 || currentUser.role.role_id === 4) &&
            submissionStatus === 'submitted' && (
              <div style={{ paddingTop: '30px' }}>
                <TextField
                  label="Comments"
                  variant="outlined"
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  fullWidth
                  multiline
                  rows={4}
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                    '& .MuiInputBase-root': {
                      fontSize: '16px', // Input text size
                    },
                    '& .MuiInputLabel-root': {
                      fontSize: '16px', // Label text size
                    },
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'fixed',
                    bottom: 20,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    backgroundColor: 'white',
                    zIndex: 1000,
                    padding: '10px',
                    alignItems: 'center',
                  }}
                >
                  <StyledButton
                    variant="outlined_reject"
                    text="Reject"
                    onClick={handleReject}
                    style={{ marginRight: 10 }}
                  />
                  <StyledButton
                    variant="contained"
                    color="primary"
                    text="Approve"
                    onClick={handleApprove}
                  />
                </div>
              </div>
            )}
          {(currentUser.role.role_id === 1 || currentUser.role.role_id === 2) &&
            submissionStatus === 'rejected' &&
            viewMode === 'edit' && (
              <div style={{ paddingTop: '30px' }}>
                <TextField
                  label="Comments"
                  variant="outlined"
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  fullWidth
                  multiline
                  rows={4}
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                    '& .MuiInputBase-root': {
                      fontSize: '16px', // Input text size
                    },
                    '& .MuiInputLabel-root': {
                      fontSize: '16px', // Label text size
                    },
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'fixed',
                    bottom: 20,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    backgroundColor: 'white',
                    zIndex: 1000,
                    padding: '10px',
                    alignItems: 'center',
                  }}
                >
                  <StyledButton
                    variant="contained"
                    color="primary"
                    text="Resubmit"
                    onClick={handleResubmit}
                  />
                </div>
              </div>
            )}
          <Snackbar
            open={showSnackbar}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="error"
              sx={{ width: '100%' }}
            >
              {submissionStatus === 'submitted'
                ? 'Please fill comments before rejecting'
                : "Please click on 'Edit' before proceeding with the Resubmit."}
            </Alert>
          </Snackbar>
        </div>
      ) : (
        <div style={{ position: 'relative', overflowX: 'auto' }}>
          <div style={{ overflowX: 'auto' }}>
            {tableData.length === 0 ? (
              <Typography
                variant="body1"
                sx={{ textAlign: 'center', marginTop: '20px' }}
              >
                Trials not found
              </Typography>
            ) : (
              <div>
                {console.log('tableData', tableData)}
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '16px',
                      fontWeight: 600,
                      lineHeight: '20px',
                      letterSpacing: '-0.006em',
                      textAlign: 'left',
                      color: '#252C32',
                      marginTop: '10px',
                      zIndex: 1,
                      backgroundColor: '#fff',
                      position: 'sticky',
                      top: 0,
                    }}
                  >
                    {headerText}
                  </Typography>
                </div>

                <Table ref={tableRef}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          ...cellStyles,
                          position: 'sticky',
                          left: 0,
                          zIndex: 1,
                          backgroundColor: '#fff',
                        }}
                      >
                        S.No
                      </TableCell>
                      <TableCell style={cellStyles}>Furnace NO.</TableCell>
                      <TableCell style={cellStyles}>Crucible NO.</TableCell>
                      <TableCell style={cellStyles}>Campaign NO.</TableCell>
                      <TableCell style={cellStyles}>
                        Lining or Patching
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Date of Completion lining or Patching
                      </TableCell>

                      <TableCell style={cellStyles}>
                        Fce prod start date
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Fce prod start time
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Fce prod down date
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Fce prod down time
                      </TableCell>
                      <TableCell style={cellStyles}>Life</TableCell>
                      <TableCell style={cellStyles}>
                        Average Heat size
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Total Furnace Running Hours
                      </TableCell>
                      <TableCell style={cellStyles}>Total Production</TableCell>

                      <TableCell style={cellStyles}>Bottom Product</TableCell>
                      <TableCell style={cellStyles}>
                        Sidewall product 1
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Sidewall product 2
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Top Locking product
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Topping mass product
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Sidewall 1 Coverage upto X%
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Sidewall 2 Coverage upto 100%
                      </TableCell>

                      <TableCell style={cellStyles}>
                        NRM consumption bottom
                      </TableCell>
                      <TableCell style={cellStyles}>
                        NRM consumption Sidewall 1
                      </TableCell>
                      <TableCell style={cellStyles}>
                        NRM consumption Sidewall 2
                      </TableCell>
                      <TableCell style={cellStyles}>
                        NRM Consumption at Top Locking
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Topping mass consumption
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Total NRM consumption sidewall
                      </TableCell>
                      <TableCell style={cellStyles}>
                        NRM specific consumption
                      </TableCell>

                      <TableCell style={cellStyles}>Former Dimension</TableCell>
                      <TableCell style={cellStyles}>Coil coat ID</TableCell>
                      <TableCell style={cellStyles}>Fce Ht.</TableCell>
                      <TableCell style={cellStyles}>Castable top Ht.</TableCell>
                      <TableCell style={cellStyles}>Bottom ht.</TableCell>

                      <TableCell style={cellStyles}>
                        Total Sintering time
                      </TableCell>
                      <TableCell style={cellStyles}>holding time</TableCell>
                      <TableCell style={cellStyles}>holding temp</TableCell>
                      <TableCell style={cellStyles}>Reason for down</TableCell>
                      <TableCell style={cellStyles}>IR no.</TableCell>
                      <TableCell style={cellStyles}>Remarks</TableCell>
                      {(submissionStatus === 'draft' ||
                        submissionStatus === 'rejected') &&
                        viewMode !== 'view' && (
                          <TableCell
                            style={{
                              ...cellStyles,
                              position: 'sticky',
                              right: 0,
                              zIndex: 1,
                              backgroundColor: '#fff',
                            }}
                          >
                            Action
                          </TableCell>
                        )}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {tableData.map((item, index) => (
                      <React.Fragment key={index}>
                        {!item.lining_reference.isdelete &&
                          (Array.isArray(item.lining_reference) ||
                            (typeof item.lining_reference === 'object' &&
                              !Array.isArray(item.lining_reference))) && (
                            <React.Fragment>
                              {item.lining_reference.map(
                                (detail, detailIndex) => (
                                  <TableRow key={`${index}-${detailIndex}`}>
                                    {/* Sticky column with index */}
                                    <TableCell
                                      style={{
                                        position: 'sticky',
                                        left: 0,
                                        zIndex: 1,
                                        backgroundColor: '#fff',
                                      }}
                                    >
                                      {/* Ensure index + 1 increments correctly */}
                                      {renderCell(detailIndex + 1)}
                                    </TableCell>
                                    {/* Render other general_details */}
                                    <TableCell style={rowStyles}>
                                      {renderCell(detail.furnace_no || '')}
                                    </TableCell>
                                    <TableCell style={rowStyles}>
                                      {renderCell(detail.crucible_no || '')}
                                    </TableCell>
                                    <TableCell style={rowStyles}>
                                      {renderCell(detail.campaign_no || '')}
                                    </TableCell>
                                    <TableCell style={rowStyles}>
                                      {renderCell(
                                        detail.lining_or_patching || ''
                                      )}
                                    </TableCell>
                                    <TableCell style={rowStyles}>
                                      {renderCell(
                                        detail.date_of_completion_lining_or_patching ||
                                          ''
                                      )}
                                    </TableCell>

                                    {/* Render furnace_details */}
                                    {Array.isArray(item.production_summary) &&
                                      item.production_summary.map(
                                        (
                                          productionDetails,
                                          productionIndex
                                        ) => (
                                          <React.Fragment
                                            key={`productionsummary-${index}-${productionIndex}`}
                                          >
                                            <TableCell style={rowStyles}>
                                              {renderCell(
                                                productionDetails.fce_prod_start_date ||
                                                  'N/A'
                                              )}
                                            </TableCell>
                                            <TableCell style={rowStyles}>
                                              {renderCell(
                                                productionDetails.fce_prod_start_time ||
                                                  'N/A'
                                              )}
                                            </TableCell>
                                            {/* Render more fields from furnaceDetail */}
                                            <TableCell style={rowStyles}>
                                              {renderCell(
                                                productionDetails.fce_prod_down_date ||
                                                  'N/A'
                                              )}
                                            </TableCell>
                                            <TableCell style={rowStyles}>
                                              {renderCell(
                                                productionDetails.fce_prod_down_time ||
                                                  'N/A'
                                              )}
                                            </TableCell>
                                            <TableCell style={rowStyles}>
                                              {renderCell(
                                                productionDetails.life || 'N/A'
                                              )}
                                            </TableCell>
                                            <TableCell style={rowStyles}>
                                              {renderCell(
                                                productionDetails.average_heat_size ||
                                                  'N/A'
                                              )}
                                            </TableCell>
                                            <TableCell style={rowStyles}>
                                              {renderCell(
                                                productionDetails.total_furnace_running_hours ||
                                                  'N/A'
                                              )}
                                            </TableCell>
                                            <TableCell style={rowStyles}>
                                              {renderCell(
                                                productionDetails.total_production ||
                                                  'N/A'
                                              )}
                                            </TableCell>

                                            {/* Add more TableCell components for other fields */}
                                          </React.Fragment>
                                        )
                                      )}
                                    {/* Conditionally render if furnace_details is an object */}
                                    {typeof item.lining_material_product_details ===
                                      'object' &&
                                      !Array.isArray(
                                        item.lining_material_product_details
                                      ) && (
                                        <React.Fragment>
                                          <TableCell style={rowStyles}>
                                            {renderCell(
                                              item
                                                .lining_material_product_details
                                                .bottom_product || 'N/A'
                                            )}
                                          </TableCell>
                                          <TableCell style={rowStyles}>
                                            {renderCell(
                                              item
                                                .lining_material_product_details
                                                .sidewall_product_one || 'N/A'
                                            )}
                                          </TableCell>
                                          {/* Render more fields from item.furnace_details */}
                                          <TableCell style={rowStyles}>
                                            {renderCell(
                                              item
                                                .lining_material_product_details
                                                .sidewall_product_two || 'N/A'
                                            )}
                                          </TableCell>
                                          <TableCell style={rowStyles}>
                                            {renderCell(
                                              item
                                                .lining_material_product_details
                                                .top_locking_product || 'N/A'
                                            )}
                                          </TableCell>
                                          <TableCell style={rowStyles}>
                                            {renderCell(
                                              item
                                                .lining_material_product_details
                                                .topping_mass_product || 'N/A'
                                            )}
                                          </TableCell>
                                          <TableCell style={rowStyles}>
                                            {renderCell(
                                              item
                                                .lining_material_product_details
                                                .sidewall_one_coverage_upto_x ||
                                                'N/A'
                                            )}
                                          </TableCell>
                                          <TableCell style={rowStyles}>
                                            {renderCell(
                                              item
                                                .lining_material_product_details
                                                .sidewall_two_coverage_upto_100 ||
                                                'N/A'
                                            )}
                                          </TableCell>

                                          {/* Add more TableCell components for other fields */}
                                        </React.Fragment>
                                      )}

                                    {/* Render charge_mix_details */}
                                    {Array.isArray(
                                      item.lining_material_consumption
                                    ) &&
                                      item.lining_material_consumption.map(
                                        (
                                          lining_material_consumptionDetails,
                                          lining_material_consumptionIndex
                                        ) => (
                                          <React.Fragment
                                            key={`liningmaterialconsumptionDetails-${index}-${lining_material_consumptionIndex}`}
                                          >
                                            <TableCell>
                                              {renderCell(
                                                lining_material_consumptionDetails.nrm_consumption_bottom ||
                                                  'N/A'
                                              )}
                                            </TableCell>
                                            <TableCell>
                                              {renderCell(
                                                lining_material_consumptionDetails.nrm_consumption_sidewall_one ||
                                                  'N/A'
                                              )}
                                            </TableCell>
                                            <TableCell>
                                              {renderCell(
                                                lining_material_consumptionDetails.nrm_consumption_sidewall_two ||
                                                  'N/A'
                                              )}
                                            </TableCell>
                                            <TableCell>
                                              {renderCell(
                                                lining_material_consumptionDetails.nrm_consumption_top_locking ||
                                                  'N/A'
                                              )}
                                            </TableCell>

                                            <TableCell>
                                              {renderCell(
                                                lining_material_consumptionDetails.topping_mass_consumption ||
                                                  'N/A'
                                              )}
                                            </TableCell>
                                            <TableCell>
                                              {renderCell(
                                                lining_material_consumptionDetails.total_nrm_consumption_sidewall_one ||
                                                  'N/A'
                                              )}
                                            </TableCell>
                                            <TableCell>
                                              {renderCell(
                                                lining_material_consumptionDetails.nrm_specific_consumption ||
                                                  'N/A'
                                              )}
                                            </TableCell>

                                            {/* Add more TableCell components for other fields */}
                                          </React.Fragment>
                                        )
                                      )}
                                    {/* Render charge_mix_details if it's an object */}
                                    {typeof item.dimensional_parameters ===
                                      'object' &&
                                      !Array.isArray(
                                        item.dimensional_parameters
                                      ) && (
                                        <React.Fragment>
                                          <TableCell>
                                            {renderCell(
                                              item.dimensional_parameters
                                                .former_dimension || 'N/A'
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {renderCell(
                                              item.dimensional_parameters
                                                .coil_coat_id || 'N/A'
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {renderCell(
                                              item.dimensional_parameters
                                                .fce_ht || 'N/A'
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {renderCell(
                                              item.dimensional_parameters
                                                .costable_top_ht || 'N/A'
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {renderCell(
                                              item.dimensional_parameters
                                                .bottom_ht || 'N/A'
                                            )}
                                          </TableCell>
                                          {/* Add more TableCell components for other fields */}
                                        </React.Fragment>
                                      )}

                                    {typeof item.sintering_parameters ===
                                      'object' &&
                                      !Array.isArray(
                                        item.sintering_parameters
                                      ) && (
                                        <React.Fragment>
                                          <TableCell>
                                            {renderCell(
                                              item.sintering_parameters
                                                .total_sintering_time || 'N/A'
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {renderCell(
                                              item.sintering_parameters
                                                .holding_time || 'N/A'
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {renderCell(
                                              item.sintering_parameters
                                                .reason_for_down || 'N/A'
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {renderCell(
                                              item.sintering_parameters
                                                .holding_temp || 'N/A'
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {renderCell(
                                              item.sintering_parameters.IR_no ||
                                                'N/A'
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {renderCell(
                                              item.sintering_parameters
                                                .remarks || 'N/A'
                                            )}
                                          </TableCell>

                                          {/* Add more TableCell components for other fields */}
                                        </React.Fragment>
                                      )}

                                    {/* Conditional rendering for edit and delete buttons */}
                                    {item.lining_reference[detailIndex]
                                      .submission_status === 'draft' &&
                                      viewMode === 'view' && (
                                        <TableCell
                                          style={{
                                            position: 'sticky',
                                            right: 0,
                                            zIndex: 1,
                                            backgroundColor: '#fff',
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexDirection: 'row',
                                          }}
                                        >
                                          <IconButton
                                            aria-label="edit"
                                            onClick={() =>
                                              handleEdit(detailIndex)
                                            }
                                            style={{ marginRight: '5px' }}
                                          >
                                            <img
                                              src={EditIcon}
                                              alt="Edit Icon"
                                            />
                                          </IconButton>
                                          <IconButton
                                            aria-label="delete"
                                            onClick={() =>
                                              handleDelete(
                                                tableData,
                                                detailIndex
                                              )
                                            }
                                          >
                                            <img
                                              src={DeleteIcon}
                                              alt="Delete Icon"
                                            />
                                          </IconButton>
                                        </TableCell>
                                      )}
                                  </TableRow>
                                )
                              )}
                            </React.Fragment>
                          )}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default ViewTrialNRM
