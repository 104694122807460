import React, { useState, useEffect } from 'react'
import TextBox from '../../input/TextBox'
import { mild_steel_customer } from '../../../recoil/atoms'
import { useRecoilState } from 'recoil'
import { v4 as uuidv4 } from 'uuid'
import { trial_name } from '../../../recoil/atoms'

const MildSteelCustomer = () => {
  const [compaignNo, setCompaignNo] = useState([])
  const [liningPatchingNo, setLiningPatchingNo] = useState([])
  const [mildSteel, setMildSteel] = useRecoilState(mild_steel_customer)
  const uuid = uuidv4()
  const [trialName] = useRecoilState(trial_name)

  const handleChange = (e) => {
    const { name, value } = e.target
    setMildSteel((prevState) => ({
      ...prevState,
      [name]: value,
      id: uuid,
    }))
  }

  const isReadOnly = trialName === 'viewDataCollection'

  return (
    <div style={{ width: '100%' }}>
      <div style={{ marginTop: '15px', marginRight: '15px' }}>
        <TextBox
          name="deslagging_by"
          label="Deslagging by - (Spoon/Tilt)"
          type="text"
          value={mildSteel.deslagging_by}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="using_magnet_or_not"
          label="Use Magnet to remove the Slag jam after every deslagging"
          type="text"
          value={mildSteel.using_magnet_or_not}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="avg_c_percent_at_50"
          label="Avg. C% at 50% of Bath Metal - %"
          type="text"
          value={mildSteel.avg_c_percent_at_50}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="avg_c_percent_at_80"
          label="Avg. C% at 80% of Bath Metal _ %"
          type="text"
          value={mildSteel.avg_c_percent_at_80}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="final_c_percent_beforeTapping"
          label="Final C% Before Tapping - %"
          type="text"
          value={mildSteel.final_c_percent_beforeTapping}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>

      <TextBox
        name="data_mill_scale"
        label="Mill Scale/heat - kg"
        type="text"
        value={mildSteel.data_mill_scale}
        onChange={handleChange}
        disabled={isReadOnly}
      />
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="tilt_count_for_deslagging"
          label="No of times TILT the Fce for deslagging per heat - No."
          type="text"
          value={mildSteel.tilt_count_for_deslagging}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="are_they_taking_liquid_metal"
          label="Are they taking Liquid metal up to full level"
          type="text"
          value={mildSteel.are_they_taking_liquid_metal}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="dri_quality"
          label="DRI Quality (Lumps/Pellets/Fines) - (Ratio)"
          type="text"
          value={mildSteel.dri_quality}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="slag_generation_per_heat"
          label="Slag Generation per Heat - %"
          type="text"
          value={mildSteel.slag_generation_per_heat}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="power_utilised"
          label="Power utilised - (Own/Grid)"
          type="text"
          value={mildSteel.power_utilised}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="silica_price"
          label="Silica Price(Landed Price) - INR/Kg"
          type="text"
          value={mildSteel.silica_price}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="ccm"
          label="CCM (Avg casting speed) - (m/min.)"
          type="text"
          value={mildSteel.ccm}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="casting_time"
          label="Casting Time - (min.)"
          type="text"
          value={mildSteel.casting_time}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="tundish_cap"
          label="Tundish cap - (MT)"
          type="text"
          value={mildSteel.tundish_cap}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="min_level_of_tundish"
          label="Min level of tundish - (MT)"
          type="text"
          value={mildSteel.min_level_of_tundish}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="billet_size"
          label="Billet size in (LXHXT) - (mm*mm*mm)"
          type="text"
          value={mildSteel.billet_size}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="no_of_sequence"
          label="No of sequence"
          type="text"
          value={mildSteel.no_of_sequence}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="ccm_make"
          label="CCM make - OEM"
          type="text"
          value={mildSteel.ccm_make}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="ccm_preparation_time"
          label="CCM preparation time in Hours - (HH)"
          type="text"
          value={mildSteel.ccm_preparation_time}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="purpose_of_using_nrm"
          label="Objective/Prupose of using NRM"
          type="text"
          value={mildSteel.purpose_of_using_nrm}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
    </div>
  )
}
export default MildSteelCustomer
