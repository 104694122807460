import React, { useState, useRef, useEffect } from 'react'
import { Card, CardContent, Typography, IconButton } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ViewIcon from '../../assets/icons/ic_edit.svg'
import DownloadIcon from '../../assets/icons/ic_download.svg'
import DeleteIcon from '../../assets/icons/ic_delete.svg'
import DailyTrialIcon from '../../assets/icons/Box Chart.svg'
import NRMIcon from '../../assets/icons/Line Chart.svg'
import ErosionIcon from '../../assets/icons/Scatter Charts.svg'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import Plotly from 'plotly.js-dist/plotly'
import axios from 'axios'
import API from '../services/API'
import { useNavigate } from 'react-router-dom'

const ReportAnalysis = ({ reportCardDetails, selectedTemplate }) => {
  // localStorage.setItem("selectedTemplate", selectedTemplate);

  ////console.log("reportCardDetails", reportCardDetails);
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [popupPosition, setPopupPosition] = useState({ top: 0, right: 0 })
  const [cardIdDetails, setCardId] = useState(null)
  const [chartData, setChartData] = useState([])
  const [chartsRendered, setChartsRendered] = useState(false) // State to track if charts have been rendered
  const navigate = useNavigate()
  const chartContainerRef1 = useRef(null) // Ref for the first chart container
  const chartContainerRef2 = useRef(null)
  const popupRef = useRef(null)
  const [iconsData, setIconsData] = useState([])

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      closePopup()
    }
  }

  const closePopup = () => {
    setIsPopupOpen(false)
    //console.log('Popup closed');
  }

  useEffect(() => {
    if (isPopupOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isPopupOpen])
  const handleCardClick = async (cardId) => {
    //console.log("cardId", cardId);
  }

  const handleDelete = async () => {
    //console.log('Delete button clicked!');
    try {
      const cardId = localStorage.getItem('reportCardId') // Assuming this is set elsewhere or fetched
      const url = `${API.DELETE_REPORTS}/${cardId}`
      const response = await axios.put(url)
      window.location.reload()
    } catch (error) {
      //console.error("Error fetching chart data:", error);
    }
  }

  const handleMenuOpen = (event, item) => {
    event.stopPropagation() // Prevents event bubbling
    const rect = event.target.getBoundingClientRect()
    setIsPopupOpen(true)
    setPopupPosition({
      top: rect.top + window.scrollY,
      right: window.innerWidth - rect.right,
    })
    //console.log("itemdetails", item.id);
    localStorage.setItem('reportCardId', item.id)
    setCardId(item.id)
  }

  const handleView = async () => {
    navigate('/ReportsDataSetup')
  }

  const handleDownload = async () => {
    navigate('./pdf')
  }

  useEffect(() => {
    // Fetch chart data when component mounts
    fetchChartData()
  }, [])

  const fetchChartData = async () => {
    try {
      const cardId = localStorage.getItem('reportCardId') // Assuming this is set elsewhere or fetched
      const url = `${API.GET_GRAPH}/${cardId}`
      const response = await axios.get(url)
      const chartData = response.data
      setChartData(chartData)
    } catch (error) {
      //console.error("Error fetching chart data:", error);
    }
  }

  useEffect(() => {
    // Function to fetch icons for a specific cardId
    const fetchIcons = async (cardId) => {
      try {
        const url = `${API.GET_REPORTSICON}/${cardId}`
        const response = await axios.get(url)
        if (response.status === 200) {
          const transformedIcons = response.data.map((item) => item.template_id)
          setIconsData((prevState) => ({
            ...prevState,
            [cardId]: transformedIcons,
          }))
        } else {
          console.warn(
            `Unexpected status code ${response.status} for URL: ${url}`
          )
          setIconsData((prevState) => ({
            ...prevState,
            [cardId]: [], // Empty array indicating no icons fetched
          }))
        }
      } catch (error) {
        //console.error("Error fetching icons:", error);
        setIconsData((prevState) => ({
          ...prevState,
          [cardId]: [], // Empty array indicating no icons fetched
        }))
      }
    }

    // Fetch icons for all cards when reportCardDetails changes
    if (reportCardDetails) {
      reportCardDetails.forEach((item) => {
        if (item.id) {
          fetchIcons(item.id)
        }
      })
    }
  }, [reportCardDetails])

  const renderIcons = (cardId) => {
    const icons = iconsData[cardId] || []
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: '10px',
        }}
      >
        {icons.includes(1) && (
          <img
            src={DailyTrialIcon}
            alt="Daily Trial Icon"
            style={{ marginRight: '8px' }}
          />
        )}
        {icons.includes(2) && (
          <img src={NRMIcon} alt="NRM Icon" style={{ marginRight: '8px' }} />
        )}
        {icons.includes(3) && <img src={ErosionIcon} alt="Erosion Icon" />}
      </div>
    )
  }

  useEffect(() => {
    // Render charts when chartData changes
    if (chartData.length > 0) {
      renderCharts()
    }
  }, [chartData])

  const renderCharts = () => {
    chartData.forEach((chart) => {
      switch (chart.id) {
        case 1:
          renderPlotlyChart(chart, chartContainerRef1.current)
          break
        case 2:
          renderPlotlyChart(chart, chartContainerRef2.current)
          break
        default:
          break
      }
    })
    // Set state to indicate charts are rendered
    setChartsRendered(true)
  }

  const renderPlotlyChart = (chart, containerRef) => {
    const data = chart.data
    const layout = { title: chart.title }

    Plotly.newPlot(containerRef, data, layout)
  }

  const handlePDF = () => {
    // Check if charts have been rendered
    if (
      chartsRendered &&
      chartContainerRef1.current &&
      chartContainerRef2.current
    ) {
      html2canvas(chartContainerRef1.current)
        .then((canvas1) => {
          html2canvas(chartContainerRef2.current)
            .then((canvas2) => {
              const pdf = new jsPDF('landscape', 'mm', 'a4')
              const imgData1 = canvas1.toDataURL('image/png')
              const imgData2 = canvas2.toDataURL('image/png')

              // Calculate image dimensions based on PDF page size
              const imgWidth = pdf.internal.pageSize.getWidth()
              const imgHeight1 = (canvas1.height * imgWidth) / canvas1.width
              const imgHeight2 = (canvas2.height * imgWidth) / canvas2.width

              // Add first image to PDF
              pdf.addImage(
                imgData1,
                'PNG',
                10,
                10,
                imgWidth - 20,
                imgHeight1 - 20
              ) // Adjusting margins
              pdf.addPage() // Add new page for the second chart

              // Add second image to PDF
              pdf.addImage(
                imgData2,
                'PNG',
                10,
                10,
                imgWidth - 20,
                imgHeight2 - 20
              ) // Adjusting margins

              pdf.save('combined_charts.pdf')
            })
            .catch((error) => {
              //console.error('Error rendering chart 2 to canvas:', error);
            })
        })
        .catch((error) => {
          //console.error('Error rendering chart 1 to canvas:', error);
        })
    } else {
      //console.error('One or both chart containers are not available.');
    }
  }

  useEffect(() => {
    // Call handlePDF when charts are rendered and when chartData changes
    if (chartsRendered) {
      handlePDF()
    }
  }, [chartsRendered, chartData])

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', paddingRight: '20px' }}>
      {reportCardDetails &&
        reportCardDetails.map((item) => (
          <Card
            key={item.id}
            onClick={() => handleCardClick(item.id)}
            sx={{
              border: '1px solid #E6E6E6',
              boxShadow: '0px 5px 13px -5px #0A090B0D',
              width: '20%',
              height: '180px',
              marginBottom: '20px',
              marginRight: '20px',
              borderRadius: 4,
            }}
          >
            <CardContent>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontWeight: 600,
                    lineHeight: '20px',
                    letterSpacing: '-0.006em',
                    textAlign: 'left',
                    color: '#252C32',
                    marginTop: '10px',
                  }}
                >
                  {item.report_name}
                </Typography>
                <IconButton
                  aria-label="options"
                  onClick={(event) => handleMenuOpen(event, item)} // Pass 'item' here
                >
                  <MoreVertIcon style={{ color: '#0086DC' }} />
                </IconButton>
              </div>

              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  fontWeight: 700,
                  lineHeight: '20px',
                  letterSpacing: '-0.6%',
                  textAlign: 'left',
                  marginTop: '5px',
                  color: '#252C32',
                }}
              >
                {item.description}
              </Typography>

              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  fontWeight: 400,
                  lineHeight: '20px',
                  letterSpacing: '-0.006em',
                  textAlign: 'left',
                  color: '#6E7C87',
                }}
              >
                {item.customer}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  fontWeight: 500,
                  lineHeight: '16px',
                  letterSpacing: '-0.006em',
                  marginTop: '15px',
                  textAlign: 'left',
                  color: '#7F7D83',

                  background: '#7F7D831A',
                  padding: '0px 4px',
                  borderRadius: '4px',
                }}
              >
                {item.data_template}
              </Typography>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: '12px',
                    fontWeight: 500,
                    lineHeight: '20px',
                    letterSpacing: '-0.006em',
                    textAlign: 'left',
                    color: '#6E7C87',
                  }}
                >
                  Last Updated:
                </Typography>

                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: '12px',
                    fontWeight: 700,
                    lineHeight: '20px',
                    letterSpacing: '-0.006em',
                    textAlign: 'left',
                    color: '#252C32',
                    marginLeft: '8px',
                  }}
                >
                  {item.updated_at}
                </Typography>
              </div>
              {renderIcons(item.id)}
            </CardContent>
          </Card>
        ))}

      {chartData.map((chart) => (
        <div key={chart.id} style={{ marginTop: '20px' }}>
          <Typography variant="h6">{chart.title}</Typography>
          {chart.id === 1 ? (
            <div
              ref={chartContainerRef1}
              style={{ width: '100%', height: '300px' }}
            ></div>
          ) : chart.id === 2 ? (
            <div
              ref={chartContainerRef2}
              style={{ width: '100%', height: '300px' }}
            ></div>
          ) : null}
        </div>
      ))}

      {isPopupOpen && (
        <div
          ref={popupRef}
          style={{
            position: 'absolute',
            top: `${popupPosition.top}px`,
            right: `${popupPosition.right}px`,
            background: 'rgba(255, 255, 255, 1)',
            padding: '20px',
            borderRadius: '8px',
            border: '1px solid #E8E8E8',
            boxShadow: '0px 0px 35px 0px rgba(0, 0, 0, 0.1)',
            zIndex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <div
            onClick={handleView}
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              padding: '10px',
            }}
          >
            <img
              src={ViewIcon}
              alt="Edit Icon"
              style={{ marginRight: '10px' }}
            />
            <Typography
              style={{
                color: '#252C32',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 800,
                lineHeight: 'normal',
                letterSpacing: '-0.084px',
              }}
            >
              Edit Data Setup
            </Typography>
          </div>

          <div
            onClick={handleDelete}
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              padding: '10px',
            }}
          >
            <img
              src={DeleteIcon}
              alt="Delete Icon"
              style={{ marginRight: '10px' }}
            />
            <Typography
              style={{
                color: '#FF3347',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 800,
                lineHeight: 'normal',
                letterSpacing: '-0.084px',
              }}
            >
              Delete
            </Typography>
          </div>
        </div>
      )}
    </div>
  )
}

export default ReportAnalysis
