import React, { useState, useEffect } from 'react'
import TextBox from '../../input/TextBox'
import { alloy_and_steel } from '../../../recoil/atoms'
import { useRecoilState, useRecoilValue } from 'recoil'
import { trial_name } from '../../../recoil/atoms'

const AlloyAndSteel = () => {
  const [alloyAndSteel, setaAlloyAndSteel] = useRecoilState(alloy_and_steel)
  const [trialName] = useRecoilState(trial_name)
  console.log(alloyAndSteel, 'alloyAndSteel')

  const handleChange = (e) => {
    const { name, value } = e.target

    const numericRegex = /^\d{0,5}(\.\d{0,2})?$/
    const textWithSpecialCharsRegex = /^[a-zA-Z\s\d\-\.,()&:%/]*$/ 
    let regex
    switch (name) {
      case 'addition_of_simn':
      case 'final_chemistry_at_furnace':
      case 'casting_method':
      case 'agreement':
      case 'final_chemistry_at_billet':
      case 'simn_consumption':
      case 'mn_percentage':
      case 'mn_recovery':
        regex = textWithSpecialCharsRegex // Apply text and special character regex for these fields
        break
      default:
        regex = numericRegex // Apply numeric regex for other fields
        break
    }

    // Validate value against the selected regex
    if (regex.test(value)) {
      setaAlloyAndSteel((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const handleDateSelection = (value, type) => {
    console.log('typee', type)
  }

  const isReadOnly = trialName === 'viewDataCollection'

  return (
    <div style={{ width: '100%' }}>
      <div style={{ marginTop: '15px', marginRight: '15px' }}>
        <TextBox
          name="fecr_consumption"
          label="FeCr Consumption / Heat - (Kg/heat)"
          type="text"
          value={alloyAndSteel.fecr_consumption}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="cr_percentage"
          label="Cr% in FeCr - (%)"
          type="text"
          value={alloyAndSteel.cr_percentage}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="final_cr_percentage"
          label="Final Cr - (%)"
          type="text"
          value={alloyAndSteel.final_cr_percentage}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="cr_recovery"
          label="Cr Recovery - (%)"
          value={alloyAndSteel.cr_recovery}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="alloy_addition"
          label="Any other alloys additions"
          type="text"
          value={alloyAndSteel.alloy_addition}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="casting_method"
          label="Casting method"
          value={alloyAndSteel.casting_method}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="simn_consumption"
          label="Simn consumption/heat - (Kg/heat)"
          type="text"
          value={alloyAndSteel.simn_consumption}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="mn_percentage"
          label="Mn % in Simn - (%)"
          type="text"
          value={alloyAndSteel.mn_percentage}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="mn_recovery"
          label="Mn Recovery - (%)"
          type="text"
          value={alloyAndSteel.mn_recovery}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="addition_of_simn"
          label="Addition of SiMn (FC or ladle ratio) - (kg/heat)"
          type="text"
          value={alloyAndSteel.addition_of_simn}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="any_other_alloy_addition"
          label="Any other alloys additions"
          type="text"
          value={alloyAndSteel.any_other_alloy_addition}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="final_chemistry_at_furnace"
          label="Final Chemistry at Furnace (Range)"
          value={alloyAndSteel.final_chemistry_at_furnace}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="final_chemistry_at_billet"
          label="Final Chemistry at Billet (Range)"
          value={alloyAndSteel.final_chemistry_at_billet}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="agreement"
          label="Agreement to share relevant plant data during trial evaluation of the proposed Heat King refactory lining Solution"
          value={alloyAndSteel.agreement}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
    </div>
  )
}
export default AlloyAndSteel
