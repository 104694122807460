import React, { useState, useRef, useEffect } from 'react'
import { Card, CardContent, Typography, Avatar, Grid } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton } from '@mui/material'
import DeleteIcon from '../../assets/icons/ic_delete.svg'
import ViewIcon from '../../assets/icons/ic_view.png'
import ApprovalIcon from '../../assets/icons/ic_approval.svg'
import { useNavigate } from 'react-router-dom'
import API from '../services/API'
import axios from 'axios'
import AlertDialog from '../input/AlertDialog'
import Tooltip from '@mui/material/Tooltip'
import DownloadIcon from '../../assets/icons/download_icon.svg'
import * as XLSX from 'xlsx'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import Collapsed from '../../assets/icons/collapsed.svg'
import Expanded from '../../assets/icons/expanded.svg'
const CostSheet = ({
  selectedTab,
  cardsSubmitted,
  cardsDraft,
  approvedData,
  rejectData,
  file,
  selectedTemplate,
  filteredCostSheetData,
}) => {
  console.log('selectedTemplate', selectedTemplate)
  console.log('selectedTab', selectedTab)
  console.log('file', file)
  console.log('cardsSubmittedcheck2', cardsSubmitted)
  console.log('filteredCostSheetData', filteredCostSheetData)

  const navigate = useNavigate()
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [popupPosition, setPopupPosition] = useState({ top: 0, right: 0 })
  const [getCardDetails, setgetCardDetails] = useState([])
  const [dataFileId, setDataFileId] = useState(null)
  const popupRef = useRef(null)
  const [selectedItem, setSelectedItem] = useState({})
  const [submissionStatus, setSubmissionStatus] = useState('')
  const currentUser = JSON.parse(localStorage.getItem('user'))
  const access_token = localStorage.getItem('accesstoken')

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      closePopup()
    }
  }

  const closePopup = () => {
    setIsPopupOpen(false)
    console.log('Popup closed')
  }

  useEffect(() => {
    if (isPopupOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isPopupOpen])

  // const handleDelete = async () => {
  //   console.log("Button clicked!");
  //   console.log("cardId", dataFileId);

  //   try {
  //     // Make the API call here
  //     const url = `${API.DELETE_CARD}/${dataFileId}`;
  //     console.log("url", url);
  //     console.log("API.DELETE_CARD", API.DELETE_CARD);
  //     const response = await axios.delete(url);
  //     const data = response.data;

  //     // Do something with the response data
  //     console.log("DELETE_CARD", data);
  //     setIsPopupOpen(false);
  //     window.location.reload();
  //   } catch (error) {
  //     console.error("Error:", error);
  //     // Optionally, you can handle the error state here
  //   }
  // };

  const handleEdit = () => {
    console.log('Button clicked!')
  }

  const handleView = async () => {
    localStorage.setItem('datafile_id', dataFileId)
    navigate('/viewCostSheet')
  }

  const handleMenuOpen = (event, item) => {
    const rect = event.target.getBoundingClientRect()
    setIsPopupOpen(true)
    setPopupPosition({
      top: rect.top + window.scrollY,
      right: window.innerWidth - rect.right,
    })
    console.log('itemdetails', item)
    localStorage.setItem('datafile_info', JSON.stringify(item))
    localStorage.setItem('costSheetID', item.id)
    setDataFileId(item.id)
    setSubmissionStatus(item.submission_status)
    setSelectedItem(item)
  }

  const handleApproval = async () => {
    console.log('selectedITEEEEE', selectedItem)
    AlertDialog({
      type: 'delete',
      title: 'Submission',
      text: 'Are you sure you want to approve?',
      confirmButtonText: 'Yes',
      onConfirm: async () => {
        if (currentUser.role.role_id === 3 || currentUser.role.role_id === 4) {
          try {
            const uploadId = localStorage.getItem('datafile_id')
            console.log('uploadIduploadId', uploadId)
            const data = {
              datafile_id: selectedItem.datafile_id,
              submission_status: 'approved',
              updated_data: {
                general_details: selectedItem.general_details,
                consumption_details: selectedItem.consumption_details,
                saving_details: selectedItem.saving_details,
                cost_calculation: selectedItem.cost_calculation,
              },
            }

            console.log(data, 'update data')
            const response = await axios.put(
              API.UPDATE_COST_CALCULATION,
              data,
              {
                headers: {
                  Authorization: `Bearer ${access_token}`,
                },
              }
            )
            console.log('Data updated successfully:', response.data)
            if (response.status === 200) {
              // Navigate after successful update
              window.location.reload()
            }
          } catch (error) {
            // Handle errors
            console.error('An error occurred while updating data:', error)
          }
        }
      },
    })
  }

  const handleReject = async () => {
    AlertDialog({
      type: 'delete',
      title: 'Submission',
      text: 'Are you sure you want to Reject?',
      confirmButtonText: 'Yes',
      onConfirm: async () => {
        if (currentUser.role.role_id === 3 || currentUser.role.role_id === 4) {
          try {
            const uploadId = localStorage.getItem('datafile_id')
            console.log('uploadIduploadId', uploadId)
            const data = {
              datafile_id: selectedItem.datafile_id,
              submission_status: 'approved',
              updated_data: {
                general_details: selectedItem.general_details,
                consumption_details: selectedItem.consumption_details,
                saving_details: selectedItem.saving_details,
                cost_calculation: selectedItem.cost_calculation,
              },
            }

            console.log(data, 'update data')
            const response = await axios.put(
              API.UPDATE_COST_CALCULATION,
              data,
              {
                headers: {
                  Authorization: `Bearer ${access_token}`,
                },
              }
            )
            console.log('Data updated successfully:', response.data)
            if (response.status === 200) {
              // Navigate after successful update
              window.location.reload()
            }
          } catch (error) {
            // Handle errors
            console.error('An error occurred while updating data:', error)
          }
        }
      },
    })
  }

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
    return buf
  }

  const workbookToBlob = (workbook) => {
    return new Promise((resolve) => {
      // Write the workbook to a binary string
      const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' })

      // Convert the binary string to a Blob
      const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' })
      resolve(blob)
    })
  }

  const handleDownload = async () => {
    console.log('handle download clicked')
    const id = localStorage.getItem('costSheetID')
    console.log('idd', id)

    try {
      // Fetch data from the endpoint
      const response = await axios.get(`${API.GET_COST_SHEET_BY_ID}/${id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      const data = response.data

      console.log('DATAAA', data)

      // Make a GET request to the downloadTemplate API endpoint
      const responseTemplate = await axios.get(
        API.GET_COST_SHEET_EXCEL_DOWNLOAD,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )

      // Decode base64 to ArrayBuffer
      const base64Data = responseTemplate.data.downloadedTemplates[0].data
      const byteCharacters = atob(base64Data)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const arrayBuffer = new Uint8Array(byteNumbers).buffer
      const workbook = new ExcelJS.Workbook()
      await workbook.xlsx.load(arrayBuffer)
      const worksheet = workbook.worksheets[0]

      const setCellValue = (worksheet, cell, value) => {
        worksheet.getCell(cell).value = value
      }

      const furnaceDetails = data.consumption_details[0].furnaceDetails.basic
      const furnaceDetailsNRM = data.consumption_details[0].furnaceDetails.nrm

      setCellValue(worksheet, 'B9', furnaceDetails.furnace_capacity || '')
      setCellValue(worksheet, 'B10', furnaceDetails.furnace_output || '')
      setCellValue(worksheet, 'C9', furnaceDetailsNRM.furnace_capacity || '')
      setCellValue(worksheet, 'C10', furnaceDetailsNRM.furnace_output || '')

      // Set values and styles for the `newLiningDetails` section
      const newLiningDetailsBasic =
        data.consumption_details[1].newLiningDetails.basic
      const newLiningDetailsNRM =
        data.consumption_details[1].newLiningDetails.nrm

      setCellValue(
        worksheet,
        'B11',
        newLiningDetailsBasic.ramming_material_new_lining_1 || ''
      )
      setCellValue(
        worksheet,
        'B12',
        newLiningDetailsBasic.qty_new_lining_1 || ''
      )
      setCellValue(
        worksheet,
        'C11',
        newLiningDetailsNRM.ramming_material_new_lining_1 || ''
      )
      setCellValue(worksheet, 'C12', newLiningDetailsNRM.qty_new_lining_1 || '')

      // Set values and styles for the `sideLiningDetails` section
      const sideLiningDetailsBasic =
        data.consumption_details[2].sideLiningDetails.basic
      const sideLiningDetailsNRM =
        data.consumption_details[2].sideLiningDetails.nrm

      setCellValue(
        worksheet,
        'B21',
        sideLiningDetailsBasic.ramming_material_side_lining_1 || ''
      )
      setCellValue(
        worksheet,
        'B22',
        sideLiningDetailsBasic.qty_side_lining_1 || ''
      )
      setCellValue(
        worksheet,
        'C21',
        sideLiningDetailsNRM.ramming_material_side_lining_1 || ''
      )
      setCellValue(
        worksheet,
        'C22',
        sideLiningDetailsNRM.qty_side_lining_1 || ''
      )

      // Set values and styles for the `patchingDetails` section
      const patchingDetailsBasic =
        data.consumption_details[3].patchingDetails.basic
      const patchingDetailsNRM = data.consumption_details[3].patchingDetails.nrm

      setCellValue(
        worksheet,
        'B31',
        patchingDetailsBasic.ramming_material_patching_1 || ''
      )
      setCellValue(worksheet, 'B32', patchingDetailsBasic.qty_patching_1 || '')
      setCellValue(
        worksheet,
        'C31',
        patchingDetailsNRM.ramming_material_patching_1 || ''
      )
      setCellValue(worksheet, 'C32', patchingDetailsNRM.qty_patching_1 || '')

      // Set values and styles for the `repairingDetails` section
      const repairingDetailsBasic =
        data.consumption_details[4].repairingDetails.basic
      const repairingDetailsNRM =
        data.consumption_details[4].repairingDetails.nrm

      setCellValue(
        worksheet,
        'B41',
        repairingDetailsBasic.ramming_material_repairing_1 || ''
      )
      setCellValue(
        worksheet,
        'B42',
        repairingDetailsBasic.qty_repairing_1 || ''
      )
      setCellValue(
        worksheet,
        'C41',
        repairingDetailsNRM.ramming_material_repairing_1 || ''
      )
      setCellValue(worksheet, 'C42', repairingDetailsNRM.qty_repairing_1 || '')

      // Set values and styles for the `costCalculation` section
      const costCalculation = data.cost_calculation.costCalculation.basic
      const costCalculationNRM = data.cost_calculation.costCalculation.nrm

      setCellValue(worksheet, 'B48', costCalculation.avg_in_heat_size || '')
      setCellValue(worksheet, 'B49', costCalculation.avg_life || '')
      setCellValue(worksheet, 'C48', costCalculationNRM.avg_in_heat_size || '')
      setCellValue(worksheet, 'C49', costCalculationNRM.avg_life || '')

      // Set values and styles for the `laborCost` section
      const laborCost =
        data.saving_details[0].laborCostDataObject.laborCost.basic
      const laborCostNRM =
        data.saving_details[0].laborCostDataObject.laborCost.nrm

      setCellValue(worksheet, 'G4', laborCost.labor_cost_per_nl || '')
      setCellValue(worksheet, 'G5', laborCost.labor_cost_campaign || '')
      setCellValue(worksheet, 'H4', laborCostNRM.labor_cost_per_nl || '')
      setCellValue(worksheet, 'H5', laborCostNRM.labor_cost_campaign || '')

      // Set values and styles for the `formerCost` section
      const formerCost =
        data.saving_details[1].formerCostDataObject.formerCost.basic
      const formerCostNRM =
        data.saving_details[1].formerCostDataObject.formerCost.nrm

      setCellValue(worksheet, 'G10', formerCost.former_cost_campaign || '')
      setCellValue(worksheet, 'G11', formerCost.labor_cost_campaign || '')
      setCellValue(worksheet, 'H10', formerCostNRM.former_cost_campaign || '')
      setCellValue(worksheet, 'H11', formerCostNRM.labor_cost_campaign || '')

      // Set values and styles for the `increasedProductivity` section
      const increasedProductivity =
        data.saving_details[2].increasedProductivityObject.IncreasedProductivity
          .basic
      const increasedProductivityNRM =
        data.saving_details[2].increasedProductivityObject.IncreasedProductivity
          .nrm

      setCellValue(
        worksheet,
        'G16',
        increasedProductivity.increased_productivity_cost || ''
      )
      setCellValue(
        worksheet,
        'G17',
        increasedProductivity.savings_increased_productivity || ''
      )
      setCellValue(
        worksheet,
        'H16',
        increasedProductivityNRM.increased_productivity_cost || ''
      )
      setCellValue(
        worksheet,
        'H17',
        increasedProductivityNRM.savings_increased_productivity || ''
      )

      // Set values and styles for the `energy` section
      const energy = data.saving_details[3].energyDataObject.energy.basic
      const energyNRM = data.saving_details[3].energyDataObject.energy.nrm

      setCellValue(worksheet, 'G22', energy.energy_cost || '')
      setCellValue(worksheet, 'G23', energy.savings_energy || '')
      setCellValue(worksheet, 'H22', energyNRM.energy_cost || '')
      setCellValue(worksheet, 'H23', energyNRM.savings_energy || '')

      // Set values and styles for the `yieldImprovement` section
      const yieldImprovement =
        data.saving_details[4].yieldImprovementObject.yieldImprovement.basic
      const yieldImprovementNRM =
        data.saving_details[4].yieldImprovementObject.yieldImprovement.nrm

      setCellValue(worksheet, 'G28', yieldImprovement.yield_improvement || '')
      setCellValue(
        worksheet,
        'G29',
        yieldImprovement.savings_yield_improvement || ''
      )
      setCellValue(
        worksheet,
        'H28',
        yieldImprovementNRM.yield_improvement || ''
      )
      setCellValue(
        worksheet,
        'H29',
        yieldImprovementNRM.savings_yield_improvement || ''
      )

      // Set values and styles for the `alloySavings` section
      const alloySavings = data.saving_details[5].alloyObject.alloy.basic
      const alloySavingsNRM = data.saving_details[5].alloyObject.alloy.nrm

      setCellValue(worksheet, 'G34', alloySavings.alloy_savings || '')
      setCellValue(worksheet, 'G35', alloySavings.savings_alloy_savings || '')
      setCellValue(worksheet, 'H34', alloySavingsNRM.alloy_savings || '')
      setCellValue(
        worksheet,
        'H35',
        alloySavingsNRM.savings_alloy_savings || ''
      )

      // Set values and styles for the `tangibleBenefits` section
      const tangibleBenefits =
        data.saving_details[6].tangibleBenefitsObject.tangibleBenefits.basic
      const tangibleBenefitsNRM =
        data.saving_details[6].tangibleBenefitsObject.tangibleBenefits.nrm

      setCellValue(worksheet, 'G40', tangibleBenefits.tangible_benefits || '')
      setCellValue(
        worksheet,
        'G41',
        tangibleBenefits.savings_tangible_benefits || ''
      )
      setCellValue(
        worksheet,
        'H40',
        tangibleBenefitsNRM.tangible_benefits || ''
      )
      setCellValue(
        worksheet,
        'H41',
        tangibleBenefitsNRM.savings_tangible_benefits || ''
      )

      // Generate the modified workbook and save
      const buffer = await workbook.xlsx.writeBuffer()
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })

      // Use FileSaver to save the file
      saveAs(blob, 'Cost Sheet.xlsx')
    } catch (error) {
      console.error('Error handling download:', error)
    }
  }

  // Make sure to import and use this function in your component or wherever it's needed

  const handleCardClick = async (cardId) => {
    try {
      // Make the API call here
      const url = `${API.GET_CARD_DETAILS}/${cardId}`
      console.log('url', url)
      console.log('API.GET_CARD_DETAILS', API.GET_CARD_DETAILS)
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      const data = response.data
      // Do something with the response data
      console.log('Card Details:', data)
    } catch (error) {
      console.error('Error:', error)
      // Optionally, you can handle the error state here
    }
  }

  const [isDraftOpen, setIsDraftOpen] = useState(false)
  const [isSubmittedOpen, setIsSubmittedOpen] = useState(false)
  const [isRejectedOpen, setIsRejectedOpen] = useState(false)
  const [isApprovedOpen, setIsApprovedOpen] = useState(false)

  const [toggleCount, setToggleCount] = useState(0)
  console.log(toggleCount, 'count')

  const handleToggle = (section) => {
    switch (section) {
      case 'draft':
        setIsDraftOpen((prev) => {
          setToggleCount(prev ? toggleCount - 1 : toggleCount + 1)
          return !prev
        })
        break
      case 'submitted':
        setIsSubmittedOpen((prev) => {
          setToggleCount(prev ? toggleCount - 1 : toggleCount + 1)
          return !prev
        })
        break
      case 'rejected':
        setIsRejectedOpen((prev) => {
          setToggleCount(prev ? toggleCount - 1 : toggleCount + 1)
          return !prev
        })
        break
      case 'approved':
        setIsApprovedOpen((prev) => {
          setToggleCount(prev ? toggleCount - 1 : toggleCount + 1)
          return !prev
        })
        break
      default:
        break
    }
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        borderTop: '2px solid #ebebeb',
      }}
    >
      {/* draft */}
      <div
        style={{
          display: isDraftOpen ? 'block' : 'flex',
          padding: '8px',
          width:
            toggleCount === 1 && isDraftOpen
              ? '90%' // Wrap percentage values in quotes
              : toggleCount === 2 && isDraftOpen
                ? '50%'
                : toggleCount === 3 && isDraftOpen
                  ? '50%'
                  : toggleCount === 4 && isDraftOpen
                    ? '33%'
                    : '',
        }}
      >
        {' '}
        {isDraftOpen ? (
          <div
            onClick={() => handleToggle('draft')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
            }}
          >
            <img
              src={Expanded}
              alt="Expanded"
              style={{ marginRight: '10px' }}
            />{' '}
            {/* Expanded Icon */}
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              DRAFT
            </Typography>
          </div>
        ) : (
          <div
            onClick={() => handleToggle('draft')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
              borderRight: isDraftOpen ? 'none' : '2px solid #ebebeb',
              alignItems: 'center'
            }}
          >
            <img
              src={Collapsed}
              alt="Collapsed"
              style={{ marginBottom: '5px' }}
            />{' '}
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              D
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              R
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              A
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              F
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              T
            </Typography>
          </div>
        )}
        <Grid
          container
          style={{
            marginTop: '0px',
            display: isDraftOpen ? 'flex' : 'none',
            flexWrap: 'wrap',
          }}
        >
          {(filteredCostSheetData.length > 0
            ? filteredCostSheetData.filter(
                (item) => item?.submission_status === 'draft'
              )
            : cardsDraft && cardsDraft
          ).map((item) => {
            if (item.dataFile.template.template_id === 5) {
              const createdDate = new Date(item.created_at)
              const formattedDate = createdDate.toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })
              return (
                <Grid
                  xs={
                    toggleCount === 1
                      ? 3
                      : toggleCount === 2
                        ? 6
                        : toggleCount === 3
                          ? 12
                          : 12 // When all 4 are open
                  }
                >
                  <Card
                    key={item.id}
                    sx={{
                      border: '1px solid #E6E6E6',
                      boxShadow: '0px 5px 13px -5px #0A090B0D',
                      height: '180px',
                      marginBottom: '20px',
                      marginRight: '5px', 
                    }}
                  >
                    <CardContent>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: '16px',
                            fontWeight: 600,
                            lineHeight: '20px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#252C32',
                            marginTop: '10px',
                          }}
                        >
                          {`Cost_Sheet_${item.dataFile.customer.organization_name}`}
                        </Typography>
                        <IconButton
                          aria-label="options"
                          onClick={(event) => handleMenuOpen(event, item)}
                        >
                          <MoreVertIcon style={{ color: '#0086DC' }} />
                        </IconButton>
                      </div>

                      <Typography
                        variant="body1"
                        gutterBottom
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '14px',
                          fontWeight: 700,
                          lineHeight: '20px',
                          letterSpacing: '-0.6%',
                          textAlign: 'left',
                          marginTop: '5px',
                          color: '#252C32',
                        }}
                      >
                        {item.dataFile.customer.organization_name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: 500,
                          lineHeight: '16px',
                          letterSpacing: '-0.006em',
                          marginTop: '15px',
                          textAlign: 'left',
                          color: '#7F7D83',
                          width: '37px',
                          height: '16px',
                          background: '#7F7D831A', // Add background color here
                          padding: '0px 4px', // Add padding
                          borderRadius: '4px', // Add border radius
                        }}
                      >
                        Draft
                      </Typography>

                      <div
                        style={{
                          flex: 1,
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              fontFamily: 'Inter',
                              fontSize: '12px',
                              fontWeight: 500,
                              lineHeight: '20px',
                              letterSpacing: '-0.006em',
                              textAlign: 'left',
                              color: '#6E7C87',
                            }}
                          >
                            Created Date:
                          </Typography>

                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              fontFamily: 'Inter',
                              fontSize: '12px',
                              fontWeight: 700,
                              lineHeight: '20px',
                              letterSpacing: '-0.006em',
                              textAlign: 'left',
                              color: '#252C32',
                              marginLeft: '8px', // Adjust spacing between components
                            }}
                          >
                            {formattedDate}
                          </Typography>
                        </div>

                        {item.dataFile.user &&
                          item.dataFile.user.firstname &&
                          item.dataFile.user.lastname && (
                            <Tooltip
                              title={`${item.dataFile.user.firstname} ${item.dataFile.user.lastname}`}
                              arrow
                            >
                              <Avatar
                                style={{
                                  width: '30px',
                                  height: '30px',
                                  borderRadius: '12px',
                                  background: '#D7EDFF',
                                  color: '#0086DC',
                                  fontWeight: 700,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  marginLeft: 'auto', // Pushes the Avatar to the right
                                  fontSize: '10px', // Adjust font size of the Avatar content
                                }}
                              >
                                {item.dataFile.user.firstname.charAt(0)}
                                {item.dataFile.user.lastname.charAt(0)}
                              </Avatar>
                            </Tooltip>
                          )}
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              )
            }
          })}
        </Grid>
      </div>
      {/* submitted */}
      <div
        style={{
          display: isSubmittedOpen ? 'block' : 'flex',
          padding: '8px',
          width:
            toggleCount === 1 && isSubmittedOpen
              ? '90%' // Wrap percentage values in quotes
              : toggleCount === 2 && isSubmittedOpen
                ? '50%'
                : toggleCount === 3 && isSubmittedOpen
                  ? '50%'
                  : toggleCount === 4 && isSubmittedOpen
                    ? '33%'
                    : '',
        }}
      >
        {isSubmittedOpen ? (
          <div
            onClick={() => handleToggle('submitted')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
            }}
          >
            <img
              src={Expanded}
              alt="Expanded"
              style={{ marginRight: '10px' }}
            />
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                letterSpacing: '0.006em',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              {currentUser.role.role_id === 3 || currentUser.role.role_id === 4
                ? 'AWAITING APPROVAL'
                : 'SUBMITTED'}
            </Typography>
          </div>
        ) : (
          <div
            onClick={() => handleToggle('submitted')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
              borderRight: isDraftOpen ? 'none' : '2px solid #ebebeb',
              alignItems: 'center'
            }}
          >
            <img
              src={Collapsed}
              alt="Collapsed"
              style={{ marginBottom: '5px' }}
            />{' '}
            {/* Collapsed Icon */}
            {Array.from(
              currentUser.role.role_id === 3 || currentUser.role.role_id === 4
                ? 'AWAITING APPROVAL'
                : 'SUBMITTED'
            ).map((letter, index) => (
              <Typography
                key={index}
                variant="body1"
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  letterSpacing: '0.006em',
                  textAlign: 'left',
                  color: '#254A9A',
                }}
              >
                {letter}
              </Typography>
            ))}
          </div>
        )}

        <Grid
          container
          style={{
            marginTop: '0',
            display: isSubmittedOpen ? 'flex' : 'none',
            flexWrap: 'wrap',
          }}
        >
          {(filteredCostSheetData.length > 0
            ? filteredCostSheetData.filter(
                (item) => item?.submission_status === 'submitted'
              )
            : cardsSubmitted && cardsSubmitted
          ).map((item) => {
            const createdDate = new Date(item.created_at)
            const formattedDate = createdDate.toLocaleDateString('en-US', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            })
            return (
              <Grid
                xs={
                  toggleCount === 1
                    ? 3
                    : toggleCount === 2
                      ? 6
                      : toggleCount === 3
                        ? 12
                        : 12 // When all 4 are open
                }
              >
                <Card
                  key={item.id}
                  sx={{
                    border: '1px solid #E6E6E6',
                    boxShadow: '0px 5px 13px -5px #0A090B0D',
                    height: '180px',
                    marginBottom: '20px',
                    marginRight: '5px', 
                  }}
                >
                  <CardContent>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '16px',
                          fontWeight: 600,
                          lineHeight: '20px',
                          letterSpacing: '-0.006em',
                          textAlign: 'left',
                          color: '#252C32',
                          marginTop: '10px',
                        }}
                      >
                        {`Cost_sheet_${item.dataFile.customer.organization_name}`}
                      </Typography>
                      <IconButton
                        aria-label="options"
                        onClick={(event) => handleMenuOpen(event, item)}
                      >
                        <MoreVertIcon style={{ color: '#0086DC' }} />
                      </IconButton>
                    </div>

                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: 700,
                        lineHeight: '20px',
                        letterSpacing: '-0.6%',
                        textAlign: 'left',
                        marginTop: '5px',
                        color: '#252C32',
                      }}
                    >
                      {item.dataFile.customer.organization_name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '16px',
                        letterSpacing: '-0.006em',
                        marginTop: '15px',
                        textAlign: 'left',
                        color: '#0086DC',
                        width:
                          currentUser.role.role_id === 3 ||
                          currentUser.role.role_id === 4
                            ? '120px'
                            : '70px',
                        height: '16px',
                        background: '#0086DC1A', // Add background color here
                        padding: '0px 4px', // Add padding
                        borderRadius: '4px', // Add border radius
                      }}
                    >
                      {currentUser.role.role_id === 3 ||
                      currentUser.role.role_id === 4
                        ? 'Awaiting approval'
                        : 'Submitted'}
                    </Typography>

                    <div
                      style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '20px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#6E7C87',
                          }}
                        >
                          Created Date:
                        </Typography>

                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 700,
                            lineHeight: '20px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#252C32',
                            marginLeft: '8px', // Adjust spacing between components
                          }}
                        >
                          {formattedDate}
                        </Typography>
                      </div>

                      {item.dataFile.user &&
                        item.dataFile.user.firstname &&
                        item.dataFile.user.lastname && (
                          <Tooltip
                            title={`${item.dataFile.user.firstname} ${item.dataFile.user.lastname}`}
                            arrow
                          >
                            <Avatar
                              style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '12px',
                                background: '#D7EDFF',
                                color: '#0086DC',
                                fontWeight: 700,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: 'auto', // Pushes the Avatar to the right
                                fontSize: '10px', // Adjust font size of the Avatar content
                              }}
                            >
                              {item.dataFile.user.firstname.charAt(0)}
                              {item.dataFile.user.lastname.charAt(0)}
                            </Avatar>
                          </Tooltip>
                        )}
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            )
          })}
        </Grid>

        {isPopupOpen &&
          (currentUser.role.role_id === 3 ||
            currentUser.role.role_id === 4 ||
            currentUser.role.role_id === 1 ||
            currentUser.role.role_id === 2) &&
          submissionStatus === 'submitted' && (
            <div
              ref={popupRef}
              style={{
                position: 'absolute',
                top: `${popupPosition.top}px`,
                right: `${popupPosition.right}px`,
                background: 'rgba(255, 255, 255, 1)',
                padding: '20px',
                borderRadius: '8px',
                border: '1px solid #E8E8E8',
                boxShadow: '0px 0px 35px 0px rgba(0, 0, 0, 0.1)',
                zIndex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              {/* Your popup content */}
              <div
                onClick={() => handleView(file)}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  padding: '10px',
                  padding: '10px',
                }}
              >
                <img
                  src={ViewIcon}
                  alt="Edit Icon"
                  style={{ marginRight: '10px' }}
                />
                <Typography
                  style={{
                    color: '#252C32',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 800,
                    lineHeight: 'normal',
                    letterSpacing: '-0.084px',
                  }}
                >
                  View Trial
                </Typography>
              </div>
              {/* <div
              onClick={handleApproval}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "10px",
              }}
            >
              <img
                src={ApprovalIcon}
                alt="Edit Icon"
                style={{ marginRight: "10px" }}
              />
              <Typography
                style={{
                  color: "#252C32",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 800,
                  lineHeight: "normal",
                  letterSpacing: "-0.084px",
                }}
              >
                Approval
              </Typography>
            </div>
            <div
              onClick={handleReject}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "10px",
              }}
            >
              <img
                src={ApprovalIcon}
                alt="Edit Icon"
                style={{ marginRight: "10px" }}
              />
              <Typography
                style={{
                  color: "#252C32",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 800,
                  lineHeight: "normal",
                  letterSpacing: "-0.084px",
                }}
              >
                Rejected
              </Typography>
            </div> */}
              {/* <div
              onClick={handleDelete}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "10px",
              }}
            >
              <img
                src={DeleteIcon}
                alt="Delete Icon"
                style={{ marginRight: "10px" }}
              />
              <Typography
                style={{
                  color: "#FF3347",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 800,
                  lineHeight: "normal",
                  letterSpacing: "-0.084px",
                }}
              >
                Delete
              </Typography>
            </div>  */}
            </div>
          )}
      </div>
      {/* approved */}
<div
        style={{
          display: isApprovedOpen ? 'block' : 'flex',
          padding: '8px',
          width:
            toggleCount === 1 && isApprovedOpen
              ? '90%'
              : toggleCount === 2 && isApprovedOpen
                ? '50%'
                : toggleCount === 3 && isApprovedOpen
                  ? '50%'
                  : toggleCount === 4 && isApprovedOpen
                    ? '33%'
                    : '',
        }}
      >
        {isApprovedOpen ? (
          <div
            onClick={() => handleToggle('approved')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
            }}
          >
            <img
              src={Expanded}
              alt="Expanded"
              style={{ marginRight: '10px' }}
            />
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                letterSpacing: '0.006em',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              APPROVED
            </Typography>
          </div>
        ) : (
          <div
            onClick={() => handleToggle('approved')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
              borderRight: isApprovedOpen ? 'none' : '2px solid #ebebeb',
              alignItems: 'center'
            }}
          >
            <img
              src={Collapsed}
              alt="Collapsed"
              style={{ marginBottom: '5px' }}
            />{' '}
            {/* Collapsed Icon */}
            {Array.from('APPROVED').map((letter, index) => (
              <Typography
                key={index}
                variant="body1"
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  letterSpacing: '0.006em',
                  textAlign: 'left',
                  color: '#254A9A',
                }}
              >
                {letter}
              </Typography>
            ))}
          </div>
        )}

<Grid
          container
          style={{
            marginTop: '0',
            display: isApprovedOpen ? 'flex' : 'none',
            flexWrap: 'wrap',
          }}
        >
          {(filteredCostSheetData.length > 0
            ? filteredCostSheetData.filter(
                (item) => item?.submission_status === 'approved'
              )
            : approvedData && approvedData
          ).map((item) => {
            const createdDate = new Date(item.created_at)
            const formattedDate = createdDate.toLocaleDateString('en-US', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            })
            return (
              <Grid
                  xs={
                    toggleCount === 1
                      ? 3
                      : toggleCount === 2
                        ? 6
                        : toggleCount === 3
                          ? 12
                          : 12 // When all 4 are open
                  }
                >
              <Card
                key={item.id}
                sx={{
                  border: '1px solid #E6E6E6',
                  boxShadow: '0px 5px 13px -5px #0A090B0D',
                  height: '180px',
                  marginBottom: '20px',
                  marginRight: '5px', 
                }}
              >
                <CardContent>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: '20px',
                        letterSpacing: '-0.006em',
                        textAlign: 'left',
                        color: '#252C32',
                        marginTop: '10px',
                      }}
                    >
                      {`Cost_Sheet_${item.dataFile.customer.organization_name}`}
                    </Typography>
                    <IconButton
                      aria-label="options"
                      onClick={(event) => handleMenuOpen(event, item)}
                    >
                      <MoreVertIcon style={{ color: '#0086DC' }} />
                    </IconButton>
                  </div>

                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      fontWeight: 700,
                      lineHeight: '20px',
                      letterSpacing: '-0.6%',
                      textAlign: 'left',
                      marginTop: '5px',
                      color: '#252C32',
                    }}
                  >
                    {item.dataFile.customer.organization_name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '12px',
                      fontWeight: 500,
                      lineHeight: '16px',
                      letterSpacing: '-0.006em',
                      marginTop: '15px',
                      textAlign: 'left',
                      color: '#34A853',
                      width: '68px',
                      height: '16px',
                      background: '#34A8531A',
                      padding: '0px 4px', // Add padding
                      borderRadius: '4px', // Add border radius
                    }}
                  >
                    Approved
                  </Typography>

                  <div
                    style={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: 500,
                          lineHeight: '20px',
                          letterSpacing: '-0.006em',
                          textAlign: 'left',
                          color: '#6E7C87',
                        }}
                      >
                        Created Date:
                      </Typography>

                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: 700,
                          lineHeight: '20px',
                          letterSpacing: '-0.006em',
                          textAlign: 'left',
                          color: '#252C32',
                          marginLeft: '8px', // Adjust spacing between components
                        }}
                      >
                        {formattedDate}
                      </Typography>
                    </div>

                    {item.dataFile.user &&
                      item.dataFile.user.firstname &&
                      item.dataFile.user.lastname && (
                        <Tooltip
                          title={`${item.dataFile.user.firstname} ${item.dataFile.user.lastname}`}
                          arrow
                        >
                          <Avatar
                            style={{
                              width: '30px',
                              height: '30px',
                              borderRadius: '12px',
                              background: '#D7EDFF',
                              color: '#0086DC',
                              fontWeight: 700,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginLeft: 'auto', // Pushes the Avatar to the right
                              fontSize: '10px', // Adjust font size of the Avatar content
                            }}
                          >
                            {item.dataFile.user.firstname.charAt(0)}
                            {item.dataFile.user.lastname.charAt(0)}
                          </Avatar>
                        </Tooltip>
                      )}
                  </div>
                </CardContent>
              </Card>
              </Grid>
            )
          })}
        </Grid>
        {isPopupOpen && (
          <div
            ref={popupRef}
            style={{
              position: 'absolute',
              top: `${popupPosition.top}px`,
              right: `${popupPosition.right}px`,
              background: 'rgba(255, 255, 255, 1)',
              padding: '20px',
              borderRadius: '8px',
              border: '1px solid #E8E8E8',
              boxShadow: '0px 0px 35px 0px rgba(0, 0, 0, 0.1)',
              zIndex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            {/* Your popup content */}
            <div
              onClick={() => handleView(file)}
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                padding: '10px',
                padding: '10px',
              }}
            >
              <img
                src={ViewIcon}
                alt="Edit Icon"
                style={{ marginRight: '10px' }}
              />
              <Typography
                style={{
                  color: '#252C32',
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 800,
                  lineHeight: 'normal',
                  letterSpacing: '-0.084px',
                }}
              >
                View Trial
              </Typography>
            </div>
            <div
              onClick={handleDownload}
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                padding: '10px',
              }}
            >
              <img
                src={DownloadIcon}
                alt="Edit Icon"
                style={{ marginRight: '10px' }}
              />
              <Typography
                style={{
                  color: '#252C32',
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 800,
                  lineHeight: 'normal',
                  letterSpacing: '-0.084px',
                }}
              >
                Download
              </Typography>
            </div>
            {/* <div
              onClick={handleApproval}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "10px",
              }}
            >
              <img
                src={ApprovalIcon}
                alt="Edit Icon"
                style={{ marginRight: "10px" }}
              />
              <Typography
                style={{
                  color: "#252C32",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 800,
                  lineHeight: "normal",
                  letterSpacing: "-0.084px",
                }}
              >
                Approval
              </Typography>
            </div>
            <div
              onClick={handleReject}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "10px",
              }}
            >
              <img
                src={ApprovalIcon}
                alt="Edit Icon"
                style={{ marginRight: "10px" }}
              />
              <Typography
                style={{
                  color: "#252C32",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 800,
                  lineHeight: "normal",
                  letterSpacing: "-0.084px",
                }}
              >
                Rejected
              </Typography>
            </div> */}
            {/* <div
              onClick={handleDelete}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "10px",
              }}
            >
              <img
                src={DeleteIcon}
                alt="Delete Icon"
                style={{ marginRight: "10px" }}
              />
              <Typography
                style={{
                  color: "#FF3347",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 800,
                  lineHeight: "normal",
                  letterSpacing: "-0.084px",
                }}
              >
                Delete
              </Typography>
            </div>  */}
          </div>
        )}
      </div>
      {/* rejected */}
      <div
        style={{
          display: isRejectedOpen ? 'block' : 'flex',
          padding: '8px',
          width:
            toggleCount === 1 && isRejectedOpen
              ? '90%' // Wrap percentage values in quotes
              : toggleCount === 2 && isRejectedOpen
                ? '50%'
                : toggleCount === 3 && isRejectedOpen
                  ? '50%'
                  : toggleCount === 4 && isRejectedOpen
                    ? '33%'
                    : '',
        }}
      >
        {isRejectedOpen ? (
          <div
            onClick={() => handleToggle('rejected')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
            }}
          >
            <img
              src={Expanded}
              alt="Expanded"
              style={{ marginRight: '10px' }}
            />
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                letterSpacing: '0.006em',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              REJECTED
            </Typography>
          </div>
        ) : (
          <div
            onClick={() => handleToggle('rejected')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
              borderRight: isDraftOpen ? 'none' : '2px solid #ebebeb',
              alignItems: 'center'
            }}
          >
            <img
              src={Collapsed}
              alt="Collapsed"
              style={{ marginBottom: '5px' }}
            />{' '}
            {/* Collapsed Icon */}
            {Array.from('REJECTED').map((letter, index) => (
              <Typography
                key={index}
                variant="body1"
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  letterSpacing: '0.006em',
                  textAlign: 'left',
                  color: '#254A9A',
                }}
              >
                {letter}
              </Typography>
            ))}
          </div>
        )}
         <Grid
          container
          style={{
            marginTop: '0',
            display: isRejectedOpen ? 'flex' : 'none',
            flexWrap: 'wrap',
          }}
        >
          {(filteredCostSheetData.length > 0
            ? filteredCostSheetData.filter(
                (item) => item?.submission_status === 'rejected'
              )
            : rejectData && rejectData
          ).map((item) => {
            const createdDate = new Date(item.created_at)
            const formattedDate = createdDate.toLocaleDateString('en-US', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            })
            return (
              <Grid
                  xs={
                    toggleCount === 1
                      ? 3
                      : toggleCount === 2
                        ? 6
                        : toggleCount === 3
                          ? 9
                          : 9 // When all 4 are open
                  }
                >
              <Card
                key={item.id}
                sx={{
                  border: '1px solid #E6E6E6',
                  boxShadow: '0px 5px 13px -5px #0A090B0D',
                  height: '180px',
                  marginBottom: '20px',
                  marginRight: '5px', 
                }}
              >
                <CardContent>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        fontWeight: 600,
                        lineHeight: '20px',
                        letterSpacing: '-0.006em',
                        textAlign: 'left',
                        color: '#252C32',
                        marginTop: '10px',
                      }}
                    >
                      {`Cost_Sheet_${item.dataFile.customer.organization_name}`}
                    </Typography>
                    <IconButton
                      aria-label="options"
                      onClick={(event) => handleMenuOpen(event, item)}
                    >
                      <MoreVertIcon style={{ color: '#0086DC' }} />
                    </IconButton>
                  </div>

                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      fontWeight: 700,
                      lineHeight: '20px',
                      letterSpacing: '-0.6%',
                      textAlign: 'left',
                      marginTop: '5px',
                      color: '#252C32',
                    }}
                  >
                    {item.dataFile.customer.organization_name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '12px',
                      fontWeight: 500,
                      lineHeight: '16px',
                      letterSpacing: '-0.006em',
                      marginTop: '15px',
                      textAlign: 'left',
                      color: '#EA4335',
                      width: '68px',
                      height: '16px',
                      background: '#EA43351A',
                      padding: '0px 4px', // Add padding
                      borderRadius: '4px', // Add border radius
                    }}
                  >
                    Rejected
                  </Typography>

                  <div
                    style={{
                      flex: 1,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: 500,
                          lineHeight: '20px',
                          letterSpacing: '-0.006em',
                          textAlign: 'left',
                          color: '#6E7C87',
                        }}
                      >
                        Created Date:
                      </Typography>

                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: 700,
                          lineHeight: '20px',
                          letterSpacing: '-0.006em',
                          textAlign: 'left',
                          color: '#252C32',
                          marginLeft: '8px', // Adjust spacing between components
                        }}
                      >
                        {formattedDate}
                      </Typography>
                    </div>

                    {item.dataFile.user &&
                      item.dataFile.user.firstname &&
                      item.dataFile.user.lastname && (
                        <Tooltip
                          title={`${item.dataFile.user.firstname} ${item.dataFile.user.lastname}`}
                          arrow
                        >
                          <Avatar
                            style={{
                              width: '30px',
                              height: '30px',
                              borderRadius: '12px',
                              background: '#D7EDFF',
                              color: '#0086DC',
                              fontWeight: 700,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginLeft: 'auto', // Pushes the Avatar to the right
                              fontSize: '10px', // Adjust font size of the Avatar content
                            }}
                          >
                            {item.dataFile.user.firstname.charAt(0)}
                            {item.dataFile.user.lastname.charAt(0)}
                          </Avatar>
                        </Tooltip>
                      )}
                  </div>
                </CardContent>
              </Card>
              </Grid>
            )
          })}
        </Grid>
      </div>
    </div>
  )
}
export default CostSheet
