import React, { useState, useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  Typography,
  IconButton,
  Tabs,
  Tab,
  TextField,
  Alert,
  Snackbar,
} from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import StyledButton from '../../input/StyledButton'
import GeneralDetailsPage from '../../mobile/costsheet/CustomerDetails'
import Furnace from './Furnace'
import NewLiningDetails from './NewLiningDetails'
import SideLiningDetails from './SideLiningDetails'
import PatchingDetails from './PatchingDetails'
import RepairDetails from './RepairDetails'
import FormerCost from './FormerCost'
import LaborCost from './LaborCost'
import IncreasedProductivity from './IncreasedProductivity'
import Energy from './Energy'
import YieldImprovement from './YieldImprovement'
import Alloy from './Alloy'
import TangibleBenefits from './TangibleBenefits'
import MetallurgicalBenefits from './MetallurgicalBenefits'
import SinteringHeats from './SinteringHeats'
import {
  editSavingDetails,
  savingsArray,
  trial_name,
  cost_calculation_nrm,
  cost_calculation,
  savings_metallurgical_benefit_nrm,
  savings_metallurgical_benefit,
  savings_tangible_benefit_nrm,
  savings_tangible_benefit,
  savings_alloy_nrm,
  savings_alloy,
  yield_improvement_nrm,
  yield_improvement,
  savings_energy_nrm,
  savings_energy,
  increased_productivity_nrm,
  increased_productivity,
  former_cost,
  former_cost_nrm,
  labor_cost_nrm,
  customer_details,
  cost_sheet_furnace,
  cost_sheet_furnace_nrm,
  new_lining_details,
  new_lining_details_nrm,
  side_lining_details,
  side_lining_details_nrm,
  patching_details,
  patching_details_nrm,
  repairing_details,
  repairing_details_nrm,
  labor_cost,
  sintering_heats,
  sintering_heats_nrm,
} from '../../../recoil/atoms'
// import { useFurnaceDetails, useFurnaceDetailsNRM} from '../../mobile/costsheet/Selector'
import CostCalculations from './CostCalculation'
import axios from 'axios'
import API from '../../services/API'
import { useNavigate } from 'react-router-dom'
import AlertDialog from '../../input/AlertDialog'
import {
  costCalculation,
  costCalculationNRM,
  laborCostCampaignSelector,
  laborCostPerTonSelector,
  laborCostCampaignNRMSelector,
  laborCostPerTonNRMSelector,
  savingInLaborCostNRMSelector,
  formerCostCampaignSelector,
  formerCostPerTonSelector,
  formerCostCampaignNRMSelector,
  formerCostPerTonNRMSelector,
  savingInFormerCostSelector,
  maxNoOfHeatsSelector,
  noOfSinteringSelector,
  totDownTimeSelector,
  totDownTimeDueToFceSelector,
  totTimeAvailableProdSelector,
  actualNoOfHeatsSelector,
  actualProdInMonthSelector,
  maxNoOfHeatsNRMSelector,
  noOfSinteringNRMSelector,
  totDownTimeNRMSelector,
  totDownTimeDueToFceNRMSelector,
  totTimeAvailableProdNRMSelector,
  actualNoOfHeatsNRMSelector,
  actualProdInMonthNRMSelector,
  perOfProdDueToNRMSelector,
  savingDueToIncProdSelector,
  prodIncreaseSelector,
  costBenefitsSelector,
  costSavingsDueToPowerSelector,
  costSavingsDueToYieldImprovementSelector,
  savingsDueToReductionSelector,
  otherTangibleBenefitsSelector,
  chargedSinteringPerMonthSelector,
  costSinteringHeatPerMonthSelector,
  costOfReplacingWithDRISelector,
  costOfchargedSinteringHeatPerMTSelector,
  chargedSinteringPerMonthNRMSelector,
  costSinteringHeatPerMonthNRMSelector,
  costOfReplacingWithDRINRMSelector,
  costOfchargedSinteringHeatPerMTNRMSelector,
} from '../../../recoil/selector'

function CostSheetTabs() {
  const navigate = useNavigate()
  const [selectedTab, setSelectedTab] = useState(0)
  const [activeStep, setActiveStep] = useState(0)
  const [GeneralDetails, setGeneralDetails] = useRecoilState(customer_details)
  // consumptions Details
  const [furnaceDetails, setFurnaceDetails] = useRecoilState(cost_sheet_furnace)
  const [furnaceDetailsNRM, setFurnaceDetailsNRM] = useRecoilState(
    cost_sheet_furnace_nrm
  )
  const [newLining, setNewLining] = useRecoilState(new_lining_details)
  const [newLiningNrm, setNewLiningNrm] = useRecoilState(new_lining_details_nrm)
  const [sideLining, setSideLining] = useRecoilState(side_lining_details)
  const [sideLiningNrm, setSideLiningNrm] = useRecoilState(
    side_lining_details_nrm
  )
  const [patchingDetails, setPatchingDetails] = useRecoilState(patching_details)
  const [patchingDetailsNrm, setPatchingDetailsNrm] =
    useRecoilState(patching_details_nrm)
  const [repairingDetails, setRepairingDetails] =
    useRecoilState(repairing_details)
  const [repairingDetailsNrm, setRepairingDetailsNrm] = useRecoilState(
    repairing_details_nrm
  )

  // labor cost

  const laborCostCampaign = useRecoilValue(laborCostCampaignSelector)
  const laborCostPerTon = useRecoilValue(laborCostPerTonSelector)
  const laborCostCampaignNRM = useRecoilValue(laborCostCampaignNRMSelector)
  const laborCostPerTonNRM = useRecoilValue(laborCostPerTonNRMSelector)
  const SavingInLaborCostNRM = useRecoilValue(savingInLaborCostNRMSelector)

  // former cost
  const FormerCostCampaign = useRecoilValue(formerCostCampaignSelector)
  const formerCostPerTon = useRecoilValue(formerCostPerTonSelector)
  const FormerCostCampaignNRM = useRecoilValue(formerCostCampaignNRMSelector)
  const formerCostPerTonNRM = useRecoilValue(formerCostPerTonNRMSelector)
  const savingInFormerCost = useRecoilValue(savingInFormerCostSelector)
  // increased productivity

  const maxNoOfHeats = useRecoilValue(maxNoOfHeatsSelector)
  console.log('Max No. of Heats:', maxNoOfHeats)

  const noOfSintering = useRecoilValue(noOfSinteringSelector)
  console.log('No. of Sintering:', noOfSintering)

  const totDownTime = useRecoilValue(totDownTimeSelector)
  console.log('Total Down Time:', totDownTime)

  const totDownTimeDueToFce = useRecoilValue(totDownTimeDueToFceSelector)
  console.log('Total Down Time Due to FCE:', totDownTimeDueToFce)

  const totTimeAvailableProd = useRecoilValue(totTimeAvailableProdSelector)
  console.log('Total Time Available for Production:', totTimeAvailableProd)

  const actualNoOfHeats = useRecoilValue(actualNoOfHeatsSelector)
  console.log('Actual No. of Heats:', actualNoOfHeats)

  const actualProdInMonth = useRecoilValue(actualProdInMonthSelector)
  console.log('Actual Production in a Month:', actualProdInMonth)

  // NRM

  const maxNoOfHeatsNRM = useRecoilValue(maxNoOfHeatsNRMSelector)
  console.log('Max No. of Heats NRM:', maxNoOfHeatsNRM)

  const noOfSinteringNRM = useRecoilValue(noOfSinteringNRMSelector)
  console.log('No. of Sintering NRM:', noOfSinteringNRM)

  const totDownTimeNRM = useRecoilValue(totDownTimeNRMSelector)
  console.log('Total Down Time NRM:', totDownTimeNRM)

  const totDownTimeDueToFceNRM = useRecoilValue(totDownTimeDueToFceNRMSelector)
  console.log('Total Down Time Due to FCE NRM:', totDownTimeDueToFceNRM)

  const totTimeAvailableProdNRM = useRecoilValue(
    totTimeAvailableProdNRMSelector
  )
  console.log(
    'Total Time Available for Production NRM:',
    totTimeAvailableProdNRM
  )

  const actualNoOfHeatsNRM = useRecoilValue(actualNoOfHeatsNRMSelector)
  console.log('Actual No. of Heats NRM:', actualNoOfHeatsNRM)

  const actualProdInMonthNRM = useRecoilValue(actualProdInMonthNRMSelector)
  console.log('Actual Production in a Month NRM:', actualProdInMonthNRM)

  const prodIncrease = useRecoilValue(prodIncreaseSelector)
  console.log('Production Increase:', prodIncrease)

  const perOfProdDueToNRM = useRecoilValue(perOfProdDueToNRMSelector)
  console.log(
    'Percentage of Production Increase Due to NRM:',
    perOfProdDueToNRM
  )

  const costBenefits = useRecoilValue(costBenefitsSelector)
  console.log('Cost Benefits:', costBenefits)

  const savingDueToIncProd = useRecoilValue(savingDueToIncProdSelector)
  console.log('Savings Due to Increased Production:', savingDueToIncProd)

  const costSavingsDutToPower = useRecoilValue(costSavingsDueToPowerSelector)
  const costSavingsDueToPower = useRecoilValue(
    costSavingsDueToYieldImprovementSelector
  )
  const savingsDueToReduction = useRecoilValue(savingsDueToReductionSelector)
  const otherTangibleBenefits = useRecoilValue(otherTangibleBenefitsSelector)

  // savings details

  const [laborCost, setLaborCost] = useRecoilState(labor_cost)
  const [laborCostNRM, setLaborCostNRM] = useRecoilState(labor_cost_nrm)
  const [formerCost, setFormerCost] = useRecoilState(former_cost)
  const [formerCostNRM, setFormerCostNRM] = useRecoilState(former_cost_nrm)
  const [increasedProductivity, setIncreasedProductivity] = useRecoilState(
    increased_productivity
  )
  const [increasedProductivityNRM, setIncreasedProductivityNRM] =
    useRecoilState(increased_productivity_nrm)
  const [energy, setEnergy] = useRecoilState(savings_energy)
  const [energyNRM, setEnergyNRM] = useRecoilState(savings_energy_nrm)
  const [yieldImprovement, setYieldImprovement] =
    useRecoilState(yield_improvement)
  const [yieldImprovementNRM, setYieldImprovementNRM] = useRecoilState(
    yield_improvement_nrm
  )
  const [alloy, setAlloy] = useRecoilState(savings_alloy)
  const [alloyNRM, setAlloyNRM] = useRecoilState(savings_alloy_nrm)
  const [tangibleBenefits, setTangibleBenefits] = useRecoilState(
    savings_tangible_benefit
  )
  const [tangibleBenefitsNRM, setTangibleBenefitsNRM] = useRecoilState(
    savings_tangible_benefit_nrm
  )
  const [metallurgicalBenefits, setMetallurgicalBenefits] = useRecoilState(
    savings_metallurgical_benefit
  )
  const [metallurgicalBenefitsNRM, setMetallurgicalBenefitsNRM] =
    useRecoilState(savings_metallurgical_benefit_nrm)
  const [sinteringHeats, setSinteringHeats] = useRecoilState(sintering_heats)
  const [sinteringHeatsNRM, setSinteringHeatsNRM] =
    useRecoilState(sintering_heats_nrm)

  const [CostSheet, setCostSheet] = useState([])
  console.log(CostSheet, 'CostSheet.consumption_details[0]')
  const [trialName, setTrialName] = useRecoilState(trial_name)
  const [generalDetailsOpen, setGeneralDetailsOpen] = useState(false)
  const [editSavingsDetails, setEditSavingsDetails] =
    useRecoilState(editSavingDetails)
  const [comments, setComments] = useState('')
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const currentUser = JSON.parse(localStorage.getItem('user'))

  //cost calculation
  const [costCalculationAtom, setCostCalculationAtom] =
    useRecoilState(cost_calculation)
  const [costCalculationNRMAtom, setCostCalculationNRMAtom] =
    useRecoilState(cost_calculation_nrm)
  const costCalculatedValues = useRecoilValue(costCalculation)
  const costCalculatedNRMValues = useRecoilValue(costCalculationNRM)
  const access_token = localStorage.getItem('accesstoken')

  //general details

  const [custDet, setCustDet] = useRecoilState(customer_details)
  console.log(custDet, 'custDet123')

  // custDet object

  const custDettObj = {
    custDet: {
      customerDetails: custDet,
    },
  }

  // consumptions Details
  const furnaceDetailsObj = {
    furnaceDetails: {
      basic: furnaceDetails,
      nrm: furnaceDetailsNRM,
    },
  }
  const newLiningObj = {
    newLiningDetails: {
      basic: newLining,
      nrm: newLiningNrm,
    },
  }
  const sideLiningObj = {
    sideLiningDetails: {
      basic: sideLining,
      nrm: sideLiningNrm,
    },
  }
  const patchingObj = {
    patchingDetails: {
      basic: patchingDetails,
      nrm: patchingDetailsNrm,
    },
  }
  const repairDetailsObj = {
    repairingDetails: {
      basic: repairingDetails,
      nrm: repairingDetailsNrm,
    },
  }

  const laborCostObj = {
    ...laborCost,
    labor_cost_campaign: laborCostCampaign,
    labor_cost_per_steel_produced: laborCostPerTon,
    savings_labor_cost: 'NA',
  }
  console.log(laborCostObj, 'laborCostObj')

  const laborCostObjNRM = {
    ...laborCostNRM,
    labor_cost_campaign: laborCostCampaignNRM,
    labor_cost_per_steel_produced: laborCostPerTonNRM,
    savings_labor_cost: SavingInLaborCostNRM,
  }

  const laborCostDataObject = [
    {
      laborCostDataObject: {
        laborCost: {
          basic: laborCostObj,
          nrm: laborCostObjNRM,
        },
      },
    },
  ]
  // former cost
  const formerCostObj = {
    ...formerCost,
    former_cost_campaign: FormerCostCampaign,
    former_cost_per_steel_produced: formerCostPerTon,
    savings_former_cost: 'NA',
  }

  const formerCostObjNRM = {
    ...formerCostNRM,
    former_cost_campaign: FormerCostCampaignNRM,
    former_cost_per_steel_produced: formerCostPerTonNRM,
    savings_former_cost: savingInFormerCost,
  }

  const formerCostDataObject = [
    {
      formerCostDataObject: {
        formerCost: {
          basic: formerCostObj,
          nrm: formerCostObjNRM,
        },
      },
    },
  ]

  //increased productivity

  const IncreasedProdObj = {
    ...increasedProductivity,
    // totalAvailableTime: totalAvailableTime,
    maxNoOfHeats: maxNoOfHeats,
    noOfSintering: noOfSintering,
    totDownTime: totDownTime,
    totDownTimeDueToFce: totDownTimeDueToFce,
    totTimeAvailableProd: totTimeAvailableProd,
    actualNoOfHeats: actualNoOfHeats,
    actualProdInMonth: actualProdInMonth,
    production_increase_due_to_nrm: 'NA',
    percentage_production_increase: 'NA',
    profit_margin: 'NA',
    cost_benefit: 'NA',
    saving_due_to_increased_productivity: 'NA',
  }

  const IncreasedProdObjNRM = {
    ...increasedProductivityNRM,
    // totalAvailableTime: totalAvailableTime,
    maxNoOfHeats: maxNoOfHeatsNRM,
    noOfSintering: noOfSinteringNRM,
    totDownTime: totDownTimeNRM,
    totDownTimeDueToFce: totDownTimeDueToFceNRM,
    totTimeAvailableProd: totTimeAvailableProdNRM,
    actualNoOfHeats: actualNoOfHeatsNRM,
    actualProdInMonth: actualProdInMonthNRM,
    production_increase_due_to_nrm: prodIncrease,
    percentage_production_increase: perOfProdDueToNRM,
    cost_benefit: costBenefits,
    saving_due_to_increased_productivity: savingDueToIncProd,
  }

  const increasedProductivityDataObject = [
    {
      increasedProductivityObject: {
        IncreasedProductivity: {
          basic: IncreasedProdObj,
          nrm: IncreasedProdObjNRM,
        },
      },
    },
  ]

  // energy

  const energyObj = { ...energy }

  const energyObjNRM = {
    ...energyNRM,
    savings_power_consumption: costSavingsDutToPower,
  }

  const energyDataObject = [
    {
      energyDataObject: {
        energy: {
          basic: energyObj,
          nrm: energyObjNRM,
        },
      },
    },
  ]

  // yield improvement
  const yieldImprovementObj = {
    ...yieldImprovement,
  }

  const yieldImprovementObjNRM = {
    ...yieldImprovementNRM,
    savings_due_to_yield_benefit: costSavingsDueToPower,
  }

  const yieldImprovementDataObject = [
    {
      yieldImprovementObject: {
        yieldImprovement: {
          basic: yieldImprovementObj,
          nrm: yieldImprovementObjNRM,
        },
      },
    },
  ]

  // alloy

  const alloyObj = { ...alloy }

  const alloyObjNRM = {
    ...alloyNRM,
    savings_due_to_reduction: savingsDueToReduction,
  }

  const alloyDataObject = [
    {
      alloyObject: {
        alloy: {
          basic: alloyObj,
          nrm: alloyObjNRM,
        },
      },
    },
  ]

  // tangilable benefits
  const tangibleBenefitsObj = {
    ...tangibleBenefits,
  }

  const tangibleBenefitsObjNRM = {
    ...tangibleBenefitsNRM,
    other_tangible_benefits: otherTangibleBenefits,
  }
  const tangibleBenefitsDataObject = [
    {
      tangibleBenefitsObject: {
        tangibleBenefits: {
          basic: tangibleBenefitsObj,
          nrm: tangibleBenefitsObjNRM,
        },
      },
    },
  ]

  const metaObj = {
    savings_metallurgical: 'NA',
  }

  const metaObjNRM = {
    savings_metallurgical: metallurgicalBenefitsNRM.savings_metallurgical,
  }

  // sintering heats

  const chargedSinteringPerMonth = useRecoilValue(
    chargedSinteringPerMonthSelector
  )
  const costSinteringHeatPerMonth = useRecoilValue(
    costSinteringHeatPerMonthSelector
  )
  const costOfReplacingWithDRI = useRecoilValue(costOfReplacingWithDRISelector)
  const costOfchargedSinteringHeatPerMT = useRecoilValue(
    costOfchargedSinteringHeatPerMTSelector
  )
  // nrm
  const chargedSinteringPerMonthNRM = useRecoilValue(
    chargedSinteringPerMonthNRMSelector
  )
  const costSinteringHeatPerMonthNRM = useRecoilValue(
    costSinteringHeatPerMonthNRMSelector
  )
  const costOfReplacingWithDRINRM = useRecoilValue(
    costOfReplacingWithDRINRMSelector
  )
  const costOfchargedSinteringHeatPerMTNRM = useRecoilValue(
    costOfchargedSinteringHeatPerMTNRMSelector
  )

  const sinteringHeatObj = {
    ...sinteringHeats,
    excess_scrap_charged__sintering_perMonth: chargedSinteringPerMonth,
    cost_charged_due_sinteringHeat_perMonth: costSinteringHeatPerMonth,
    cost_of_replacing_with_dri: costOfReplacingWithDRI,
    cost_charged_sinteringHeat_per_MT: costOfchargedSinteringHeatPerMT,
  }

  const sinteringHeaNRMtObj = {
    ...sinteringHeatsNRM,
    excess_scrap_charged__sintering_perMonth: chargedSinteringPerMonthNRM,
    cost_charged_due_sinteringHeat_perMonth: costSinteringHeatPerMonthNRM,
    cost_of_replacing_with_dri: costOfReplacingWithDRINRM,
    cost_charged_sinteringHeat_per_MT: costOfchargedSinteringHeatPerMTNRM,
  }

  const metallurgicalBenefitsDataObject = [
    {
      metallurgicalBenefitsObject: {
        metallurgicalBenefits: {
          basic: metaObj,
          nrm: metaObjNRM,
        },
      },
    },
  ]

  const sinteringHeatDataObject = [
    {
      sinteringHeatBenefitsObject: {
        sinteringHeat: {
          basic: sinteringHeatObj,
          nrm: sinteringHeaNRMtObj,
        },
      },
    },
  ]
  // cost calculation

  const savedCalculationObj = JSON.parse(
    localStorage.getItem('calculationObject')
  )
  const savedCalculationNRMObj = JSON.parse(
    localStorage.getItem('calculationObjectNRM')
  )

  console.log(savedCalculationNRMObj, 'savedCalculationNRMObj')
  console.log(savedCalculationObj, 'savedCalculationObj')

  const costCalculationObj = {
    costCalculation: {
      basic: {
        ...costCalculationAtom,
        avg_life: costCalculatedValues.averageLife,
        tot_prod: costCalculatedValues.totProduction,
        no_of_heats: costCalculatedValues.totNoOfHeats,
        no_of_cycles: costCalculatedValues.totNoOfCycle,
        tot_refractory: costCalculatedValues.tot_refractory,
        avg_in_heat_size: costCalculatedValues.averageHeatSize,
        cost_in_campaign_2: costCalculatedValues.toppingMaterialCost_2,
        cost_in_campaign_3: costCalculatedValues.toppingMaterialCost_3,
        cost_of_refractory: costCalculatedValues.costOfrefractory,
        tot_cost_in_campaign: costCalculatedValues.totRefractoryConsumption,
        spec_refractory_consumpt:
          costCalculatedValues.SpecificRefractoryConsumpt,
        ramming_cost_in_campaign_1: costCalculatedValues.rammingMaterialCost_1,
        tot_refractory: costCalculatedValues.totRefractoryConsumption,
      },
      nrm: {
        ...costCalculationNRMAtom,
        avg_in_heat_size: costCalculatedNRMValues.averageHeatSizeNRM,
        avg_life: costCalculatedNRMValues.averageLifeNRM,
        no_of_cycles: costCalculatedNRMValues.totNoOfCycleNRM,
        no_of_heats: costCalculatedNRMValues.totNoOfHeatsNRM,
        tot_prod: costCalculatedNRMValues.totProductionNRM,
        ramming_cost_in_campaign_1:
          costCalculatedNRMValues.rammingMaterialCostNRM_1,
        cost_in_campaign_2: costCalculatedNRMValues.toppingMaterialCostNRM_2,
        cost_in_campaign_3: costCalculatedNRMValues.toppingMaterialCostNRM_3,
        tot_cost_in_campaign:
          costCalculatedNRMValues.totCostCampaignRefractoryNRM,
        tot_refractory: costCalculatedNRMValues.totRefractoryConsumptionNRM,
        spec_refractory_consumpt:
          costCalculatedNRMValues.SpecificRefractoryConsumptNRM,
        cost_of_refractory: costCalculatedNRMValues.costOfrefractoryNRM,
        inc_in_cost: costCalculatedNRMValues.increaseInCostDueToNRM,
        relative_gain: costCalculatedNRMValues.relativeGainNRM,
      },
    },
  }

  console.log(costCalculationObj, 'costCalculationObj')

  var consumptionObjects = [
    furnaceDetailsObj,
    newLiningObj,
    sideLiningObj,
    patchingObj,
    repairDetailsObj,
  ]
  console.log(consumptionObjects, 'consumptionObjects123')

  const costCalculationObjects = costCalculationObj
  console.log(costCalculationObjects, 'costCalculationObjects')

  const savingsObject = [
    laborCostDataObject,
    formerCostDataObject,
    increasedProductivityDataObject,
    energyDataObject,
    yieldImprovementDataObject,
    alloyDataObject,
    tangibleBenefitsDataObject,
    metallurgicalBenefitsDataObject,
    sinteringHeatDataObject,
  ]

  const submitFormData = async () => {
    try {
      let submissionStatus = ''
      if (currentUser.role.role_id === 3 || currentUser.role.role_id === 4) {
        submissionStatus = 'approved'
      } else {
        submissionStatus = 'submitted'
      }
      const uploadId = localStorage.getItem('datafile_id')
      console.log('uploadIduploadId', uploadId)
      const data = {
        datafile_id: uploadId,
        submission_status: submissionStatus,
        data: [
          {
            datafile_id: uploadId,
            general_details: custDettObj,
            consumption_details: consumptionObjects,
            saving_details: savingsObject,
            cost_calculation: costCalculationObjects,
          },
        ],
      }

      console.log(data, 'data123')

      const response = await axios.post(API.INSERT_COST_CALCULATION, data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })

      console.log('Data inserted successfully:', response.data)
      if (response.status === 200) {
        AlertDialog({
          type: 'success',
          title: 'Success',
          text: 'Form submitted successfully!',
          confirmButtonText: 'Ok',
          onConfirm: async () => {
            navigate('/datamanagement')
          },
        })
      } else {
        console.error('Error saving data:', response.data)
        AlertDialog({
          type: 'warning',
          title: 'Error',
          text: 'Failed to save data',
        })
      }

      navigate('/submittedstatus')
    } catch (error) {
      // Handle errors
      console.error('An error occurred while inserting data:', error)
    }
  }

  const updateFormData = async () => {
    try {
      const uploadId = localStorage.getItem('datafile_id')
      console.log('uploadIduploadId', uploadId)
      const data = {
        datafile_id: uploadId,
        submission_status: 'submitted',
        updated_data: {
          general_details: custDettObj,
          consumption_details: consumptionObjects,
          saving_details: savingsObject,
          cost_calculation: costCalculationObjects,
        },
      }

      console.log(data, 'update data')
      const response = await axios.put(API.UPDATE_COST_CALCULATION, data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      console.log('Data updated successfully:', response.data)
      if (response.status === 200) {
        // Navigate after successful update
        navigate('/submittedstatus')
      }
    } catch (error) {
      // Handle errors
      console.error('An error occurred while updating data:', error)
    }
  }
  const handleApprove = async () => {
    try {
      const uploadId = localStorage.getItem('datafile_id')
      console.log('uploadIduploadId', uploadId)
      const data = {
        datafile_id: uploadId,
        submission_status: 'approved',
        updated_data: {
          general_details: custDettObj,
          consumption_details: consumptionObjects,
          saving_details: savingsObject,
          cost_calculation: costCalculationObjects,
        },
      }

      console.log(data, 'update data')
      const response = await axios.put(API.UPDATE_COST_CALCULATION, data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      console.log('Data updated successfully:', response.data)
      if (response.status === 200) {
        // Navigate after successful update
        navigate('/submittedstatus')
      }
    } catch (error) {
      // Handle errors
      console.error('An error occurred while updating data:', error)
    }
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  const handleReject = async () => {
    if (comments.trim() === '') {
      setSnackbarOpen(true)
      return
    }
    try {
      const uploadId = localStorage.getItem('datafile_id')
      console.log('uploadIduploadId', uploadId)
      const data = {
        datafile_id: uploadId,
        submission_status: 'rejected',
        updated_data: {
          general_details: custDettObj,
          consumption_details: consumptionObjects,
          saving_details: savingsObject,
          cost_calculation: costCalculationObjects,
        },
      }

      console.log(data, 'update data')
      const response = await axios.put(API.UPDATE_COST_CALCULATION, data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      console.log('Data updated successfully:', response.data)
      if (response.status === 200) {
        // Navigate after successful update
        navigate('/submittedstatus')
      }
    } catch (error) {
      // Handle errors
      console.error('An error occurred while updating data:', error)
    }
  }

  useEffect(() => {
    setGeneralDetailsOpen(true)
  }, [])

  useEffect(() => {
    if (
      trialName === 'costSheetEdit' ||
      (trialName === 'viewCostSheet' && CostSheet)
    ) {
      console.log(CostSheet, 'working')
      console.log(trialName, 'trialName')
      const uploadID = CostSheet.datafile_id
      console.log(uploadID, 'uploadID123')
      localStorage.setItem('uploadID', uploadID)
      if (
        CostSheet.consumption_details &&
        CostSheet.consumption_details.length > 0 &&
        CostSheet.saving_details &&
        CostSheet.saving_details.length > 0
      ) {
        console.log(CostSheet, 'CostSheet')
        setFurnaceDetails(CostSheet?.consumption_details?.[0]?.furnaceDetails?.basic || {});
setFurnaceDetailsNRM(CostSheet?.consumption_details?.[0]?.furnaceDetails?.nrm || {});
setNewLining(CostSheet?.consumption_details?.[1]?.newLiningDetails?.basic || {});
setNewLiningNrm(CostSheet?.consumption_details?.[1]?.newLiningDetails?.nrm || {});
setSideLining(CostSheet?.consumption_details?.[2]?.sideLiningDetails?.basic || {});
setSideLiningNrm(CostSheet?.consumption_details?.[2]?.sideLiningDetails?.nrm || {});
setPatchingDetails(CostSheet?.consumption_details?.[3]?.patchingDetails?.basic || {});
setPatchingDetailsNrm(CostSheet?.consumption_details?.[3]?.patchingDetails?.nrm || {});
setRepairingDetails(CostSheet?.consumption_details?.[4]?.repairingDetails?.basic || {});
setRepairingDetailsNrm(CostSheet?.consumption_details?.[4]?.repairingDetails?.nrm || {});

setEditSavingsDetails(CostSheet?.saving_details || []);

// savings details
setLaborCost(CostSheet?.saving_details?.[0]?.laborCostDataObject?.laborCost?.basic || {});
setLaborCostNRM(CostSheet?.saving_details?.[0]?.laborCostDataObject?.laborCost?.nrm || {});
setFormerCost(CostSheet?.saving_details?.[1]?.formerCostDataObject?.formerCost?.basic || {});
setFormerCostNRM(CostSheet?.saving_details?.[1]?.formerCostDataObject?.formerCost?.nrm || {});
setIncreasedProductivity(CostSheet?.saving_details?.[2]?.increasedProductivityObject?.IncreasedProductivity?.basic || {});
setIncreasedProductivityNRM(CostSheet?.saving_details?.[2]?.increasedProductivityObject?.IncreasedProductivity?.nrm || {});
setEnergy(CostSheet?.saving_details?.[3]?.energyDataObject?.energy?.basic || {});
setEnergyNRM(CostSheet?.saving_details?.[3]?.energyDataObject?.energy?.nrm || {});
setYieldImprovement(CostSheet?.saving_details?.[4]?.yieldImprovementObject?.yieldImprovement?.basic || {});
setYieldImprovementNRM(CostSheet?.saving_details?.[4]?.yieldImprovementObject?.yieldImprovement?.nrm || {});
setAlloy(CostSheet?.saving_details?.[5]?.alloyObject?.alloy?.basic || {});
setAlloyNRM(CostSheet?.saving_details?.[5]?.alloyObject?.alloy?.nrm || {});
setTangibleBenefits(CostSheet?.saving_details?.[6]?.tangibleBenefitsObject?.tangibleBenefits?.basic || {});
setTangibleBenefitsNRM(CostSheet?.saving_details?.[6]?.tangibleBenefitsObject?.tangibleBenefits?.nrm || {});
setMetallurgicalBenefits(CostSheet?.saving_details?.[7]?.metallurgicalBenefitsObject?.metallurgicalBenefits?.basic || {});
setMetallurgicalBenefitsNRM(CostSheet?.saving_details?.[7]?.metallurgicalBenefitsObject?.metallurgicalBenefits?.nrm || {});
setSinteringHeats(CostSheet?.saving_details?.[8]?.sinteringHeatBenefitsObject?.sinteringHeat?.basic || {});
setSinteringHeatsNRM(CostSheet?.saving_details?.[8]?.sinteringHeatBenefitsObject?.sinteringHeat?.nrm || {});

// calculations
setCostCalculationAtom(CostSheet?.cost_calculation?.costCalculation?.basic || {});
setCostCalculationNRMAtom(CostSheet?.cost_calculation?.costCalculation?.nrm || {});

      }
    }
  }, [CostSheet, trialName])

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue)
    setActiveStep(0) // Reset activeStep when changing tab

    // Open the modal when changing to the first tab
    setGeneralDetailsOpen(newValue === 0)
  }

  const handleNextTab = () => {
    setSelectedTab((prevTab) => (prevTab === 2 ? 0 : prevTab + 1))
    setActiveStep(0) // Reset activeStep when changing tab
  }

  const handlePrevTab = () => {
    setSelectedTab((prevTab) => (prevTab === 0 ? 2 : prevTab - 1))
    setActiveStep(0) // Reset activeStep when changing tab
  }

  const CostSheetTabs = [
    'General Details',
    'Furnace Details(A)',
    'New Lining Details(B)',
    'Side Lining Details(C)',
    'Patching Details(D)',
    'Repair Details(E)',
  ]
  const savingsDetails = [
    'S1: Savings in Labor Cost',
    'S2: Savings in Former Cost',
    'S3: Savings due to increased productivity',
    'S4: Savings due to energy',
    'S5: Savings due to yield improvement',
    'S6: Savings in alloy',
    'S7: Savings due to other tangible benefits',
    'S8: Savings due to other metallurgical benefits',
    'S9 : Savings in scrap due to less number of sintering heats',
  ]

  const handleNext = async () => {
    if (selectedTab === 2) {
      if (trialName === 'costSheetEdit') {
        await updateFormData()
      } else {
        await submitFormData()
      }
    } else {
      if (
        (selectedTab === 0 && activeStep === 5) ||
        (selectedTab === 1 && activeStep === 8)
      ) {
        await handleNextTab()
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
      }
    }
  }

  // const handleDraft = async() => {

  //   try {

  //     const uploadId =  localStorage.getItem('datafile_id');
  //     console.log("uploadIduploadId",uploadId)
  //     const data = {
  //       datafile_id: uploadId,
  //       submission_status: "draft",
  //       data: [
  //         {
  //           datafile_id: uploadId,
  //           general_details: custDettObj,
  //           consumption_details: consumptionObjects,
  //           saving_details: savingsObject,
  //           cost_calculation: costCalculationObjects,
  //         },
  //       ]
  //     };

  //     console.log(data, "data123")

  //     const response = await axios.post(API.INSERT_COST_CALCULATION, data, {
  //       headers: {
  //         Authorization: `Bearer ${access_token}`,
  //       },
  //     });

  //     console.log('Data inserted successfully NRM:', response.data);

  //     navigate('/draftstatus');
  //   } catch (error) {
  //     // Handle errors
  //     console.error('An error occurred while inserting data:', error);
  //   }

  // }

  const handleDraft = async () => {
    if (trialName === 'costSheetEdit') {
      try {
        const uploadId = localStorage.getItem('datafile_id')
        console.log('uploadIduploadId', uploadId)
        const data = {
          datafile_id: uploadId,
          submission_status: 'draft',
          updated_data: {
            general_details: custDettObj,
            consumption_details: consumptionObjects,
            saving_details: savingsObject,
            cost_calculation: costCalculationObjects,
          },
        }

        console.log(data, 'update data')
        const response = await axios.put(API.UPDATE_COST_CALCULATION, data, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        console.log('Data updated successfully:', response.data)
        if (response.status === 200) {
          // Navigate after successful update
          navigate('/draftstatus')
        }
      } catch (error) {
        // Handle errors
        console.error('An error occurred while updating data:', error)
      }
    } else {
      try {
        const uploadId = localStorage.getItem('datafile_id')
        console.log('uploadIduploadId', uploadId)
        const data = {
          datafile_id: uploadId,
          submission_status: 'draft',
          data: [
            {
              datafile_id: uploadId,
              general_details: custDettObj,
              consumption_details: consumptionObjects,
              saving_details: savingsObject,
              cost_calculation: costCalculationObjects,
            },
          ],
        }

        console.log(data, 'data123')

        const response = await axios.post(API.INSERT_COST_CALCULATION, data, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })

        console.log('Data inserted successfully NRM:', response.data)

        navigate('/draftstatus')
      } catch (error) {
        // Handle errors
        console.error('An error occurred while inserting data:', error)
      }
    }
  }

  const handleStepClick = (index) => {
    setActiveStep(index)
  }

  const handleBack = () => {
    if (activeStep === 0) {
      handlePrevTab()
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }
  }

  const handleSaveGeneralDetails = (value) => {
    console.log(value, 'value123rt')
    // Handle saving general details here
    setGeneralDetailsOpen(false)
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setGeneralDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  // const progressText = `${activeStep + 1} of ${selectedTab === 0 ? CostSheetTabs.length : savingsDetails.length} datas completed`;

  const consumptionDetailsComponent = () => {
    switch (activeStep) {
      case 0:
        return (
          <GeneralDetailsPage
            onSave={handleSaveGeneralDetails}
            onChange={handleChange}
          />
        )
      case 1:
        return <Furnace />
      case 2:
        return <NewLiningDetails />
      case 3:
        return <SideLiningDetails />
      case 4:
        return <PatchingDetails />
      case 5:
        return <RepairDetails />
      default:
        return null
    }
  }

  const savingsDetailsComponent = () => {
    switch (activeStep) {
      case 0:
        return (
          <LaborCost
            activeStep={activeStep}
            trialName={trialName}
            selectedTab={selectedTab}
            CostSheetTabs={CostSheetTabs}
            savingsDetails={savingsDetails}
            handleBack={handleBack}
            handleNext={handleNext}
            editSavingsDetails={editSavingsDetails}
          />
        )
      case 1:
        return (
          <FormerCost
            activeStep={activeStep}
            trialName={trialName}
            selectedTab={selectedTab}
            CostSheetTabs={CostSheetTabs}
            savingsDetails={savingsDetails}
            handleBack={handleBack}
            handleNext={handleNext}
            editSavingsDetails={editSavingsDetails}
          />
        )
      case 2:
        return (
          <IncreasedProductivity
            activeStep={activeStep}
            trialName={trialName}
            selectedTab={selectedTab}
            CostSheetTabs={CostSheetTabs}
            savingsDetails={savingsDetails}
            handleBack={handleBack}
            handleNext={handleNext}
            editSavingsDetails={editSavingsDetails}
          />
        )
      case 3:
        return (
          <Energy
            activeStep={activeStep}
            trialName={trialName}
            selectedTab={selectedTab}
            CostSheetTabs={CostSheetTabs}
            savingsDetails={savingsDetails}
            handleBack={handleBack}
            handleNext={handleNext}
            editSavingsDetails={editSavingsDetails}
          />
        )
      case 4:
        return (
          <YieldImprovement
            activeStep={activeStep}
            trialName={trialName}
            selectedTab={selectedTab}
            CostSheetTabs={CostSheetTabs}
            savingsDetails={savingsDetails}
            handleBack={handleBack}
            handleNext={handleNext}
            editSavingsDetails={editSavingsDetails}
          />
        )
      case 5:
        return (
          <Alloy
            activeStep={activeStep}
            trialName={trialName}
            selectedTab={selectedTab}
            CostSheetTabs={CostSheetTabs}
            savingsDetails={savingsDetails}
            handleBack={handleBack}
            handleNext={handleNext}
            editSavingsDetails={editSavingsDetails}
          />
        )
      case 6:
        return (
          <TangibleBenefits
            activeStep={activeStep}
            trialName={trialName}
            selectedTab={selectedTab}
            CostSheetTabs={CostSheetTabs}
            savingsDetails={savingsDetails}
            handleBack={handleBack}
            handleNext={handleNext}
            editSavingsDetails={editSavingsDetails}
          />
        )
      case 7:
        return (
          <MetallurgicalBenefits
            activeStep={activeStep}
            trialName={trialName}
            selectedTab={selectedTab}
            CostSheetTabs={CostSheetTabs}
            savingsDetails={savingsDetails}
            handleBack={handleBack}
            handleNext={handleNext}
            editSavingsDetails={editSavingsDetails}
          />
        )
      case 8:
        return <SinteringHeats />
      default:
        return null
    }
  }

  const costCalculationComponent = () => <CostCalculations />

  //   const handleLaborCostObject = (laborCostObjBasic, laborCostObjNRM) => {
  //     console.log(laborCostObjBasic, "laborCostObjBasic");
  //     console.log(laborCostObjNRM, "laborCostObjNRM")
  //     const laborCostDataObject =[{laborCostDataObject : {
  //         laborCost : {
  //             basic: laborCostObjBasic,
  //             nrm: laborCostObjNRM
  //         }
  //     }}]
  //     console.log(laborCostDataObject, "laborCostDataObject")
  //     let prevStateData = [...savingsObject]
  //     let keytoSearch = 'laborCostDataObject'
  //     var index = findIndexByKey(savingsObject, keytoSearch);
  //     if(index !== -1){
  //       console.log(index, "index")
  //       prevStateData=prevStateData.filter(x=>{
  //         if(!x.laborCostDataObject){
  //           return x
  //         }
  //       })
  //       prevStateData=prevStateData.filter(
  //       function (element) {
  //       return element !== undefined;
  //      });
  //     console.log(prevStateData,"prevStateData")
  //     }
  //     setSavingsObject([...prevStateData,...laborCostDataObject])

  // }

  // function findIndexByKey(array, key) {
  //   for (var i = 0; i < array.length; i++) {
  //       if (key in array[i]) {
  //           return i; // Return the index of the object if the key is found
  //       }
  //   }
  //   return -1; // Return -1 if the key is not found in any object
  // }

  // useEffect(() =>{
  // console.log(savingsObject, "savingsObject")
  // }, [savingsObject])

  // const handleFormerCostObject = (formerCostObj, formerCostObjNRM) => {
  //   console.log(formerCostObj, "formerCostObj");
  //   console.log(formerCostObjNRM, "formerCostObjNRM")
  //   const formerCostDataObject = [{ formerCostDataObject : {
  //       formerCost : {
  //           basic: formerCostObj,
  //           nrm: formerCostObjNRM
  //       }
  //   }}]
  //   console.log(formerCostDataObject, "formerCostDataObject")
  //   let prevStateData = [...savingsObject]
  //   let keytoSearch = 'formerCostDataObject'
  //   var index = findIndexByKey(savingsObject, keytoSearch);
  //   if(index !== -1){
  //     prevStateData = prevStateData.filter(x => {
  //       if(!x.formerCostDataObject){
  //         return x
  //       }
  //     })
  //     prevStateData = prevStateData.filter(
  //       function (element) {
  //         return element !== undefined;
  //       });
  //   }
  //   setSavingsObject([...prevStateData, ...formerCostDataObject])
  // };

  // const handleIncreasedProductivityObject = (increasedProdObj, increasedProdObjNRM) => {
  //   console.log(increasedProdObj, "increasedProdObj");
  //   console.log(increasedProdObjNRM, "increasedProdObjNRM");
  //   const increasedProductivityDataObject = [{
  //     increasedProductivityObject: {
  //       IncreasedProductivity: {
  //         basic: increasedProdObj,
  //         nrm: increasedProdObjNRM
  //       }
  //     }
  //   }];
  //   console.log(increasedProductivityDataObject, "increasedProductivityDataObject");
  //   let prevStateData = [...savingsObject];
  //   let keytoSearch = 'increasedProductivityObject';
  //   var index = findIndexByKey(savingsObject, keytoSearch);
  //   if (index !== -1) {
  //     console.log(index, "index");
  //     prevStateData = prevStateData.filter(x => !x.increasedProductivityObject);
  //     console.log(prevStateData, "prevStateData");
  //   }
  //   setSavingsObject([...prevStateData, ...increasedProductivityDataObject]);
  // };

  // const handleEnergyObject = (energyObj, energyObjNRM) => {
  //   console.log(energyObj, "energyObj");
  //   console.log(energyObjNRM, "energyObjNRM");
  //   const energyDataObject = [{
  //     energyDataObject: {
  //       energy: {
  //         basic: energyObj,
  //         nrm: energyObjNRM
  //       }
  //     }
  //   }];
  //   console.log(energyDataObject, "energyDataObject");
  //   let prevStateData = [...savingsObject];
  //   let keytoSearch = 'energyDataObject';
  //   var index = findIndexByKey(savingsObject, keytoSearch);
  //   if (index !== -1) {
  //     console.log(index, "index");
  //     prevStateData = prevStateData.filter(x => !x.energyDataObject);
  //     console.log(prevStateData, "prevStateData");
  //   }
  //   setSavingsObject([...prevStateData, ...energyDataObject]);
  // };

  // const handleYieldImprovementObject = (yieldImprovementObj, yieldImprovementObjNRM) => {
  //   const yieldImprovementDataObject = [{
  //     yieldImprovementObject: {
  //       yieldImprovement: {
  //         basic: yieldImprovementObj,
  //         nrm: yieldImprovementObjNRM
  //       }
  //     }
  //   }];
  //   console.log(yieldImprovementDataObject, "yieldImprovementDataObject");
  //   let prevStateData = [...savingsObject];
  //   let keytoSearch = 'yieldImprovementObject';
  //   var index = findIndexByKey(savingsObject, keytoSearch);
  //   if (index !== -1) {
  //     console.log(index, "index");
  //     prevStateData = prevStateData.filter(x => !x.yieldImprovementObject);
  //     console.log(prevStateData, "prevStateData");
  //   }
  //   setSavingsObject([...prevStateData, ...yieldImprovementDataObject]);
  // };

  // const handleAlloyObject = (alloyObj, alloyObjNRM) => {
  //   console.log(alloyObj, "alloyObj");
  //   console.log(alloyObjNRM, "alloyObjNRM");
  //   const alloyDataObject = [{
  //     alloyObject: {
  //       alloy: {
  //         basic: alloyObj,
  //         nrm: alloyObjNRM
  //       }
  //     }
  //   }];
  //   console.log(alloyDataObject, "alloyDataObject");
  //   let prevStateData = [...savingsObject];
  //   let keytoSearch = 'alloyObject';
  //   var index = findIndexByKey(savingsObject, keytoSearch);
  //   if (index !== -1) {
  //     console.log(index, "index");
  //     prevStateData = prevStateData.filter(x => !x.alloyObject);
  //     console.log(prevStateData, "prevStateData");
  //   }
  //   setSavingsObject([...prevStateData, ...alloyDataObject]);
  // };

  // const handleTangibleBenefitsObject = (tangibleBenefitsObj, tangibleBenefitsObjNRM) => {
  //   console.log(tangibleBenefitsObj, "tangibleBenefitsObj");
  //   console.log(tangibleBenefitsObjNRM, "tangibleBenefitsObjNRM");
  //   const tangibleBenefitsDataObject = [{
  //     tangibleBenefitsObject: {
  //       tangibleBenefits: {
  //         basic: tangibleBenefitsObj,
  //         nrm: tangibleBenefitsObjNRM
  //       }
  //     }
  //   }];
  //   console.log(tangibleBenefitsDataObject, "tangibleBenefitsDataObject");
  //   let prevStateData = [...savingsObject];
  //   let keytoSearch = 'tangibleBenefitsObject';
  //   var index = findIndexByKey(savingsObject, keytoSearch);
  //   if (index !== -1) {
  //     console.log(index, "index");
  //     prevStateData = prevStateData.filter(x => !x.tangibleBenefitsObject);
  //     console.log(prevStateData, "prevStateData");
  //   }
  //   setSavingsObject([...prevStateData, ...tangibleBenefitsDataObject]);
  // };

  // const handleMetaObject = (metaObj, metaObjNRM) => {
  //   console.log(metaObj, "metaObj");
  //   console.log(metaObjNRM, "metaObjNRM");
  //   const metallurgicalBenefitsDataObject = [{
  //     metallurgicalBenefitsObject: {
  //       metallurgicalBenefits: {
  //         basic: metaObj,
  //         nrm: metaObjNRM
  //       }
  //     }
  //   }];
  //   console.log(metallurgicalBenefitsDataObject, "metallurgicalBenefitsDataObject");
  //   let prevStateData = [...savingsObject];
  //   let keytoSearch = 'metallurgicalBenefitsObject';
  //   var index = findIndexByKey(savingsObject, keytoSearch);
  //   if (index !== -1) {
  //     console.log(index, "index");
  //     prevStateData = prevStateData.filter(x => !x.metallurgicalBenefitsObject);
  //     console.log(prevStateData, "prevStateData");
  //   }
  //   setSavingsObject([...prevStateData, ...metallurgicalBenefitsDataObject]);
  // };

  // // const handleSinteringHeatsObject = (sinteringHeatObj, sinteringHeaNRMtObj) => {
  //   console.log(sinteringHeaNRMtObj, "sinteringHeaNRMtObj");
  //   console.log(sinteringHeaNRMtObj, "sinteringHeaNRMtObj")
  //   const sinteringHeatsDataObject = [{ sinteringHeatDataObject : {
  //       sinteringHeat : {
  //           basic: sinteringHeatObj,
  //           nrm: sinteringHeaNRMtObj
  //       }
  //   }}]
  //   console.log(sinteringHeatsDataObject, "sinteringHeatsDataObject")
  //   let prevStateData = [...sinteringHeatsDataObject]
  //   let keytoSearch = 'sinteringHeatDataObject'
  //   var index = findIndexByKey(savingsObject, keytoSearch);
  //   if(index !== -1){
  //     prevStateData = prevStateData.filter(x => {
  //       if(!x.sinteringHeatDataObject){
  //         return x
  //       }
  //     })
  //     prevStateData = prevStateData.filter(
  //       function (element) {
  //         return element !== undefined;
  //       });
  //   }
  //   setSavingsObject([...prevStateData, ...sinteringHeatsDataObject])
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(API.GET_ALL_COST_SHEETS, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        const data = response.data
        console.log('data123333', data)
        let currentID = localStorage.getItem('costSheetID')
        setCostSheet(data.find((x) => x.id === currentID))
      } catch (error) {}
    }

    fetchData()
  }, [])

  return (
    <div
      style={{
        width: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <IconButton onClick={handlePrevTab}>
          <KeyboardArrowLeftIcon />
        </IconButton>
        <Tabs value={selectedTab} onChange={handleTabChange}>
          <Tab label="Consumption Details" />
          <Tab label="Saving Details" />
          <Tab label="Cost Calculation" />
        </Tabs>
        <IconButton onClick={handleNextTab}>
          <KeyboardArrowRightIcon />
        </IconButton>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {selectedTab !== 2 && (
          <Typography
            variant="body1"
            style={{
              color: '#000000',
              fontFamily: 'Inter',
              fontWeight: 600,
              fontSize: '18px',
              lineHeight: '20px',
              marginTop: '15px',
            }}
          >
            {selectedTab === 0
              ? CostSheetTabs[activeStep]
              : savingsDetails[activeStep]}
          </Typography>
        )}
      </div>

      <div>
        <Typography
          style={{
            color: '#000000',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '20px',
            marginTop: '15px',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              marginBottom: '20px',
              color: '#6E7C87',
              fontFamily: 'Inter',
              fontWeight: 400,
              fontSize: '14px',
              marginTop: '5px',
            }}
          >
            {selectedTab === 0
              ? `${activeStep + 1} of ${CostSheetTabs.length} data completed`
              : selectedTab === 1
                ? `${activeStep + 1} of ${savingsDetails.length} data completed`
                : ''}
          </Typography>
        </Typography>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
      >
        {selectedTab === 0 &&
          CostSheetTabs.map((step, index) => (
            <div
              key={index}
              style={{ flex: 1, position: 'relative', marginRight: '10px' }}
            >
              <div
                onClick={() => handleStepClick(index)}
                style={{
                  borderBottom: `5px solid ${index === activeStep ? '#0086DC' : '#BDBDBD'}`,
                  width: '100%',
                  marginBottom: '10px',
                  marginTop: '5px',
                  borderRadius: '7px',
                }}
              />
            </div>
          ))}

        {selectedTab === 1 &&
          savingsDetails.map((step, index) => (
            <div
              key={index}
              style={{ flex: 1, position: 'relative', marginRight: '10px' }}
            >
              <div
                onClick={() => handleStepClick(index)}
                style={{
                  borderBottom: `5px solid ${index === activeStep ? '#0086DC' : '#BDBDBD'}`,
                  width: '100%',
                  marginBottom: '10px',
                  marginTop: '5px',
                  borderRadius: '7px',
                }}
              />
            </div>
          ))}
        {selectedTab === 2 && null}
      </div>

      <div>{selectedTab === 0 && consumptionDetailsComponent()} </div>
      <div>{selectedTab === 1 && savingsDetailsComponent()} </div>
      <div>{selectedTab === 2 && costCalculationComponent()} </div>

      {/* 
{trialName === 'viewCostSheet' && (currentUser.role.role_id === 3 || currentUser.role.role_id === 4) && selectedTab ===  2 ?(
  return null;
) */}

      {trialName !== 'viewCostSheet' && (
        <div>
          {(selectedTab === 0 || selectedTab === 1 || selectedTab === 2) && (
            <div
              style={{
                justifyContent: 'space-between',
                flexDirection: 'row',
                display: 'flex',
                marginTop: 'auto',
                marginBottom: '10px',
                position: 'sticky',
                bottom: 0,
                backgroundColor: 'white',
                zIndex: 1000,
              }}
            >
              {/* Back Button - Display only if activeStep is not 0 */}
              {activeStep !== 0 && (
                <StyledButton
                  variant="outlined"
                  text="Back"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                  style={{ marginRight: 10 }}
                />
              )}

              {/* Save Draft Button - Display for all selectedTab values */}
              <StyledButton
                variant="outlined_blue"
                color="secondary"
                text="Save Draft"
                onClick={handleDraft}
              />

              {/* Next Button - Changes based on selectedTab and activeStep */}
              <StyledButton
                variant="contained"
                color="primary"
                text={
                  trialName === 'edit' && selectedTab === 2
                    ? 'Update' // Show 'Update' if in 'edit' mode and selectedTab is 2
                    : selectedTab === 2
                      ? 'Submit for Approval' // Show 'Submit for Approval' for tab 2
                      : activeStep ===
                          (selectedTab === 0
                            ? CostSheetTabs.length - 1
                            : savingsDetails.length - 1)
                        ? 'Finish' // Show 'Finish' when it's the last step
                        : 'Next' // Otherwise show 'Next'
                }
                onClick={handleNext}
              />
            </div>
          )}
        </div>
      )}

      {/* {trialName === 'viewCostSheet' && (currentUser.role.role_id === 1 || currentUser.role.role_id === 2) && selectedTab ===  2 && (
  <div>
    </div>
)} */}

      {trialName === 'viewCostSheet' &&
        (currentUser.role.role_id === 3 || currentUser.role.role_id === 4) &&
        selectedTab === 2 && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              minHeight: '100vh',
              paddingBottom: '60px',
            }}
          >
            <div style={{ paddingTop: '30px' }}>
              <TextField
                label="Comments"
                variant="outlined"
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                fullWidth
                multiline
                rows={4}
                sx={{
                  marginTop: 2,
                  marginBottom: 2,
                  '& .MuiInputBase-root': {
                    fontSize: '16px', // Input text size
                  },
                  '& .MuiInputLabel-root': {
                    fontSize: '16px', // Label text size
                  },
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                position: 'fixed',
                bottom: 20,
                left: '50%',
                transform: 'translateX(-50%)',
                backgroundColor: 'white',
                zIndex: 1000,
                padding: '10px',
                alignItems: 'center',
              }}
            >
              <StyledButton
                variant="outlined_reject"
                text="Reject"
                onClick={handleReject}
                style={{ marginRight: 10 }}
              />
              <StyledButton
                variant="contained"
                color="primary"
                text="Approve"
                onClick={handleApprove}
              />
            </div>

            <Snackbar
              open={snackbarOpen}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
            >
              <Alert
                onClose={handleSnackbarClose}
                severity="warning"
                sx={{ width: '100%' }}
              >
                Comments cannot be empty!
              </Alert>
            </Snackbar>
          </div>
        )}
    </div>
  )
}

export default CostSheetTabs
