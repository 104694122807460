import React, { useEffect } from 'react'
import TextBox from '../../input/TextBox'
import AutoSelectBox from '../../input/AutoSelectBox'
import Typography from '@mui/material/Typography'
import Helper from '../../services/Helper'
import { savings_alloy, savings_alloy_nrm } from '../../../recoil/atoms'
import { useRecoilState } from 'recoil'
import StyledButton from '../../input/StyledButton'
import { trial_name } from '../../../recoil/atoms'
import { useRecoilValue } from 'recoil'
import { savingsDueToReductionSelector } from '../../../recoil/selector'
export default function Alloy(props) {
  const [alloy, setAlloy] = useRecoilState(savings_alloy)
  const [alloyNRM, setAlloyNRM] = useRecoilState(savings_alloy_nrm)
  const [trialName] = useRecoilState(trial_name)
  const isReadOnly = trialName === 'viewCostSheet'

  const savingsDueToReduction = useRecoilValue(savingsDueToReductionSelector)
  console.log('Savings Due to Reduction:', savingsDueToReduction)

  const handleAlloy = (name, value) => {
    setAlloy((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleAlloyNRM = (name, value) => {
    setAlloyNRM((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  //calculation

  // const savingsDueToReduction = (Number(alloyNRM.simn_savings) + Number(alloyNRM.fecr_savings) + Number(alloyNRM.savings_due_to_other_alloy)).toFixed(2)
  // console.log(savingsDueToReduction, "savingsDueToReduction")

  const alloyObj = {
    simn_savings: 'NA',
    fecr_savings: 'NA',
    savings_due_to_other_alloy: 'NA',
    savings_due_to_reduction: 'NA',
  }

  const alloyObjNRM = {
    simn_savings: alloyNRM.simn_savings,
    fecr_savings: alloyNRM.fecr_savings,
    savings_due_to_other_alloy: alloyNRM.savings_due_to_other_alloy,
    savings_due_to_reduction: savingsDueToReduction,
  }

  useEffect(() => {
    if (
      props.trialName === 'costSheetEdit' &&
      props.editSavingsDetails.length > 0
    ) {
      console.log(props.editSavingsDetails, 'props.editSavingsDetails')
      let editObj = doesKeyExist(props.editSavingsDetails, 'alloyObject')
      console.log(editObj, 'editObj')
      if (editObj) {
        setAlloy(editObj.alloyObject.alloy.basic)
        setAlloyNRM(editObj.alloyObject.alloy.nrm)
      }
    }
  }, [props.editSavingsDetails])

  function doesKeyExist(array, key) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].hasOwnProperty(key)) {
        return array[i]
      }
    }
    return false
  }

  const handlenextTriger = () => {
    props.handleNext()
    props.handleAlloyObject(alloyObj, alloyObjNRM)
  }

  const custDet = JSON.parse(localStorage.getItem('customerDetails'))

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body1"
          style={{
            color: '#000000',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '20px',
            marginTop: '0px',
          }}
        >
          {custDet.parameter}
        </Typography>
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="mobile_number"
          label="SIMn Savings"
          type="number"
          value="NA"
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="mobile_number"
          label="FeCr Savings"
          type="number"
          value="NA"
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Savings due to other, if applicable - (AI)"
          type="number"
          value="NA"
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Savings due to reduction in alloy addition"
          type="number"
          value="NA"
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body1"
          style={{
            color: '#000000',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '20px',
            marginTop: '0px',
          }}
        >
          NRM
        </Typography>
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="mobile_number"
          label="SIMn Savings"
          type="number"
          value={alloyNRM.simn_savings}
          onChange={(event) =>
            handleAlloyNRM('simn_savings', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="mobile_number"
          label="FeCr Savings"
          type="number"
          value={alloyNRM.fecr_savings}
          onChange={(event) =>
            handleAlloyNRM('fecr_savings', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Savings due to other, if applicable - (AI)"
          type="number"
          value={alloyNRM.savings_due_to_other_alloy}
          onChange={(event) =>
            handleAlloyNRM('savings_due_to_other_alloy', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Savings due to reduction in alloy addition"
          type="number"
          value={savingsDueToReduction}
          onChange={(event) =>
            handleAlloyNRM('savings_due_to_reduction', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      {/* <div style={{ justifyContent: 'space-between', flexDirection: "row", display: "flex", marginTop: "auto", marginBottom: "10px", position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1000 }}>
        <StyledButton
          variant="outlined"
          text="Cancel"
          onClick={props.handleBack}
          disabled={props.activeStep === 0}
          style={{ marginRight: 10 }}
        />
        <StyledButton
          variant="contained"
          color="primary"
          text={
            (props.trialName === 'edit' && props.selectedTab === 2) ? 'Update' :
            (props.activeStep === (props.selectedTab === 0 ? props.CostSheetTabs.length - 1 : props.savingsDetails.length - 1)) ? 'Finish' : 
            'Next'
          }
        onClick={handlenextTriger}
        />
      </div> */}
    </div>
  )
}
