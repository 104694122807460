import React, { useState, useRef, useEffect } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
} from '@mui/material'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import API from '../services/API'
import AlertDialog from '../input/AlertDialog'
import StyledButtonWeb from '../input/StyledButtonWeb'
const ViewCostSheet = () => {
  const styles = {
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      marginBottom: '20px',
    },
    thTd: {
      border: '1px solid #000',
      padding: '8px',
      textAlign: 'left',
      backgroundColor: '#c9daf8',
    },
    th: {
      backgroundColor: 'white',
      border: '1px solid #000',
      padding: '8px',
      textAlign: 'left',
    },
    categoryHeader: {
      backgroundColor: '#6d9eeb',
      fontWeight: 'bold',
    },
    unitHeader: {
      backgroundColor: '#c9daf8',
    },
    detailsTable: {
      marginTop: '20px',
    },
    furnaceImage: {
      textAlign: 'center',
    },
    furnaceImageImg: {
      maxWidth: '100%',
    },
  }

  const navigate = useNavigate()
  const [tableData, setTableData] = useState([])
  console.log(tableData, 'tableData')
  const [headerText, setHeaderText] = useState('General Details')
  const tableRef = useRef(null)
  const id = localStorage.getItem('daily_trial_id')
  const dataFileData = localStorage.getItem('datafile_info')
  // Parse the JSON string back to an object
  const parsedData = JSON.parse(dataFileData)
  const currentUser = JSON.parse(localStorage.getItem('user'))
  const [showRejectPopup, setShowRejectPopup] = useState(false)
  const [comments, setComments] = useState('')
  const [submissionStatus, setSubmissionStatus] = useState('')
  const access_token = localStorage.getItem('accesstoken')

  console.log('parsedData', parsedData)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataFileData = localStorage.getItem('datafile_info')
        const parsedData = JSON.parse(dataFileData)
        if (parsedData.dataFile.template.template_id === 5) {
          const datafile_id = parsedData.datafile_id
          console.log(datafile_id, 'datafile_id')
          let url = `${API.VIEW_COST_SHEET}/${datafile_id}`
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          })
          console.log('viewtrial', response.data)
          setTableData(response.data)
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [])

  const consumption_details = tableData.consumption_details
  const cost_calculation = tableData.cost_calculation
  const savingsDetails = tableData.saving_details
  console.log(savingsDetails, 'savingsDetails')

  const handleApprove = async () => {
    AlertDialog({
      type: 'delete',
      title: 'Submission',
      text: 'Are you sure you want to approve?',
      confirmButtonText: 'Yes',
      onConfirm: async () => {
        if (currentUser.role.role_id === 3 || currentUser.role.role_id === 4) {
          updateFormData('approved')
          // if (parsedData.dataFile.template.template_id === 1) {
          //   updateGeneralDetails();
          // }else{
          //   updateNRMPerformance();
          // }
        }
      },
    })
  }

  // const handleReject = async() => {

  //   AlertDialog({
  //     type: 'delete',
  //     title: 'Submission',
  //     text: 'Are you sure you want to Reject?',
  //     confirmButtonText: 'Yes',
  //     onConfirm: async () => {
  //       if (currentUser.role.role_id === 3 || currentUser.role.role_id === 4) {

  //         try {
  //           updateFormData("rejected");
  //          // Navigate after successful update
  //        } catch (error) {
  //          // Handle errors
  //          console.error('An error occurred while updating data:', error);
  //        }
  //       }
  //     }
  //   });

  // }

  const handleReject = () => {
    setShowRejectPopup(true)
  }

  const handleRejectComment = async () => {
    const commentsFromData = tableData.comments
    setComments(commentsFromData)
    setShowRejectPopup(true)
  }

  const handlePopupOk = async () => {
    // Handle OK button click logic (e.g., send comments to server)
    console.log(comments)
    setShowRejectPopup(false)

    if (currentUser.role.role_id === 3 || currentUser.role.role_id === 4) {
      // Map through parsedData and update general_details
      try {
        const updated_data = {
          id: tableData.id,
          submission_status: 'rejected',
          comments: comments || '',
          updated_data: {
            general_details: tableData.general_details,
            consumption_details: tableData.consumption_details,
            saving_details: tableData.saving_details,
            cost_calculation: tableData.cost_calculation,
          },
        }

        const response = await axios.post(
          API.UPDATE_COST_CALCULATION,
          updated_data,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        console.log('Data updated successfully:', response.data)
        if (response.status === 200) {
          AlertDialog({
            type: 'success',
            title: 'Success',
            text: 'Your Trial is Rejected',
            confirmButtonText: 'Ok',
            onConfirm: async () => {
              navigate('/datamanagement')
            },
          })
        } else {
          console.error('Error saving data:', response.data)
          AlertDialog({
            type: 'warning',
            title: 'Error',
            text: 'Failed to save data',
          })
        }
      } catch (error) {
        // Handle errors
        console.error('An error occurred while updating data:', error)
      }
    }
  }

  const updateFormData = async (submission_status) => {
    try {
      const updated_data = {
        id: tableData.id,
        submission_status: submission_status,
        updated_data: {
          general_details: tableData.general_details,
          consumption_details: tableData.consumption_details,
          saving_details: tableData.saving_details,
          cost_calculation: tableData.cost_calculation,
        },
      }

      const response = await axios.post(
        API.UPDATE_COST_CALCULATION,
        updated_data,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      console.log('Data updated successfully:', response.data)
      if (response.status === 200) {
        AlertDialog({
          type: 'success',
          title: 'Success',
          text: 'Approved Suceessfully',
          confirmButtonText: 'Ok',
          onConfirm: async () => {
            navigate('/datamanagement')
          },
        })
      } else {
        console.error('Error saving data:', response.data)
        AlertDialog({
          type: 'warning',
          title: 'Error',
          text: 'Failed to save data',
        })
      }
    } catch (error) {
      // Handle errors
      console.error('An error occurred while updating data:', error)
    }
  }

  const TableA = () => {
    if (!tableData) {
      return <p>Loading...</p>
    }

    return (
      <div>
        <table style={styles.table}>
          <thead>
            <tr>
              <th
                colSpan="4"
                style={{
                  ...styles.thTd,
                  ...styles.categoryHeader,
                  textAlign: 'center',
                  padding: '8px',
                }}
              >
                Cost Sheet
              </th>
              <th
                colSpan="4"
                style={{
                  ...styles.thTd,
                  ...styles.categoryHeader,
                  textAlign: 'center',
                  padding: '8px',
                }}
              >
                Relative Gain / Loss of NRM
              </th>
            </tr>
            <tr>
              <th style={{ ...styles.thTd, ...styles.unitHeader }}>
                Parameter
              </th>
              <th style={{ ...styles.thTd, ...styles.unitHeader }}>SRM</th>
              <th style={{ ...styles.thTd, ...styles.unitHeader }}>NRM</th>
              <th style={{ ...styles.thTd, ...styles.unitHeader }}>Unit</th>
              <th style={{ ...styles.thTd, ...styles.unitHeader }}>
                Parameter(s)
              </th>
              <th style={{ ...styles.thTd, ...styles.unitHeader }}>SRM</th>
              <th style={{ ...styles.thTd, ...styles.unitHeader }}>NRM</th>
              <th style={{ ...styles.thTd, ...styles.unitHeader }}>Unit</th>
            </tr>
            <tr>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}>
                Customer Details
              </th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}>
                S1: Savings in Labor Cost
              </th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}>
                dia/MT
              </th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>Customer Name</th>
              <th colSpan="3" style={{ ...styles.th }}></th>
              <th style={{ ...styles.th }}>Labor cost per NL / SL / PA</th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[0]?.laborCostDataObject?.laborCost?.basic
                  ?.labor_cost_per_nl || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[0]?.laborCostDataObject?.laborCost?.nrm
                  ?.labor_cost_per_nl || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>dia/MT</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>Plant running Hr.</th>
              <th colSpan="3" style={{ ...styles.th, textAlign: 'center' }}>
                24
              </th>
              <th style={{ ...styles.th }}>Labor cost in a campaign</th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[0]?.laborCostDataObject?.laborCost?.basic
                  ?.labor_cost_campaign || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[0]?.laborCostDataObject?.laborCost?.nrm
                  ?.labor_cost_campaign || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>dia/MT</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>Country</th>
              <th colSpan="3" style={{ ...styles.th }}></th>
              <th style={{ ...styles.th }}>
                Labor cost per ton of steel produced
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[0]?.laborCostDataObject?.laborCost?.basic
                  ?.labor_cost_per_steel_produced || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[0]?.laborCostDataObject?.laborCost?.nrm
                  ?.labor_cost_per_steel_produced || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>dia/MT</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>Currency</th>
              <th colSpan="3" style={{ ...styles.th }}></th>
              <th style={{ ...styles.th }}>Savings In Labor Cost</th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[0]?.laborCostDataObject?.laborCost?.basic
                  ?.savings_labor_cost || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[0]?.laborCostDataObject?.laborCost?.nrm
                  ?.savings_labor_cost || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>dia/MT</th>
            </tr>
            <tr>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}>
                Furnace Details (A)
              </th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}>MT</th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}>
                S2: Savings in Former Cost
              </th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}>
                dia/MT
              </th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>Furnace Capacity</th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[0]?.furnaceDetails?.basic
                  ?.furnace_capacity || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[0]?.furnaceDetails?.basic
                  ?.furnace_capacity || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>MT</th>
              <th style={{ ...styles.th }}>Former cost per piece</th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[1]?.formerCostDataObject?.formerCost?.basic
                  ?.former_cost_per_piece || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[1]?.formerCostDataObject?.formerCost?.nrm
                  ?.former_cost_per_piece || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>dia/MT</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>
                Furnace output in Tons - Sintering heat (As per Former size)
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[0]?.furnaceDetails?.basic
                  ?.furnace_output || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[0]?.furnaceDetails?.basic
                  ?.furnace_output || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>MT</th>
              <th style={{ ...styles.th }}>Total former cost in a campaign</th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[1]?.formerCostDataObject?.formerCost?.basic
                  ?.former_cost_campaign || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[1]?.formerCostDataObject?.formerCost?.nrm
                  ?.former_cost_campaign || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>dia/MT</th>
            </tr>
            <tr>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}>
                New Lining Details (B)
              </th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.th }}>
                Former cost per ton of steel produced
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[1]?.formerCostDataObject?.formerCost?.basic
                  ?.former_cost_per_steel_produced || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[1]?.formerCostDataObject?.formerCost?.nrm
                  ?.former_cost_per_steel_produced || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>dia/MT</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>
                Ramming material used in new lining - 1
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[1]?.newLiningDetails?.basic
                  ?.ramming_material_new_lining_1 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[1]?.newLiningDetails?.nrm
                  ?.ramming_material_new_lining_1 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}></th>
              <th style={{ ...styles.th }}>Savings in Former cost</th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[1]?.formerCostDataObject?.formerCost?.basic
                  ?.savings_former_cost || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[1]?.formerCostDataObject?.formerCost?.nrm
                  ?.savings_former_cost || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>dia/MT</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>
                Quantity of material required for side lining - 1
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[1]?.newLiningDetails?.basic
                  ?.qty_new_lining_1 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[1]?.newLiningDetails?.nrm
                  ?.qty_new_lining_1 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>MT</th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}>
                S3: Savings due to increased productivity
              </th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}>
                dia/MT
              </th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>
                Material used in spout and top - 2
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[1]?.newLiningDetails?.basic
                  ?.material_used_spout_and_top_2 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[1]?.newLiningDetails?.nrm
                  ?.material_used_spout_and_top_2 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>dia</th>
              <th style={{ ...styles.th }}>No. of hours saved</th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[2]?.productivityDataObject?.productivity
                  ?.basic?.hours_saved || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[2]?.productivityDataObject?.productivity?.nrm
                  ?.hours_saved || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>dia</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>
                Quantity required for other linings - 2
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[1]?.newLiningDetails?.basic
                  ?.qty_other_lining_2 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[1]?.newLiningDetails?.nrm
                  ?.qty_other_lining_2 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>dia</th>
              <th style={{ ...styles.th }}>Cost of saving in electricity</th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[2]?.productivityDataObject?.productivity
                  ?.basic?.cost_saving_electricity || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[2]?.productivityDataObject?.productivity?.nrm
                  ?.cost_saving_electricity || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>dia/MT</th>
            </tr>

            {/* // side lining */}
            <tr>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}>
                Side Lining Details (C)
              </th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.th }}>
                Frequency of furnace Checking as percentage
              </th>
              <th style={{ ...styles.th }}></th>
              <th style={{ ...styles.th }}></th>
              <th style={{ ...styles.th }}>%</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>
                Ramming material used in side lining - 1
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[2]?.sideLiningDetails?.basic
                  ?.ramming_material_side_lining_1 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[2]?.sideLiningDetails?.nrm
                  ?.ramming_material_side_lining_1 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>MT</th>
              <th style={{ ...styles.th }}>
                Total down time in a month due to Fce Checking time
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[2]?.increasedProductivityObject
                  ?.IncreasedProductivity?.basic?.totDownTimeDueToFce || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[2]?.increasedProductivityObject
                  ?.IncreasedProductivity?.nrm?.totDownTimeDueToFce || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>Minute</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>
                Quantity of material required for side lining - 1
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[2]?.sideLiningDetails?.basic
                  ?.qty_side_lining_1 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[2]?.sideLiningDetails?.nrm
                  ?.qty_side_lining_1 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>MT</th>
              <th style={{ ...styles.th }}>
                Total down time in a month due to Change over
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[2]?.increasedProductivityObject
                  ?.IncreasedProductivity?.basic?.total_down_time_change_over ||
                  'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[2]?.increasedProductivityObject
                  ?.IncreasedProductivity?.nrm?.total_down_time_change_over ||
                  'N/A'}
              </th>
              <th style={{ ...styles.th }}>Minute</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>
                Material used in spout and top - 2
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[2]?.sideLiningDetails?.basic
                  ?.material_used_spout_and_top_2 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[2]?.sideLiningDetails?.nrm
                  ?.material_used_spout_and_top_2 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>MT</th>
              <th style={{ ...styles.th }}>
                Average Fce Repair Time in a month
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[2]?.increasedProductivityObject
                  ?.IncreasedProductivity?.basic?.avg_fce_checking_time ||
                  'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[2]?.increasedProductivityObject
                  ?.IncreasedProductivity?.nrm?.avg_fce_checking_time || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>Minute</th>
            </tr>

            <tr>
              <th style={{ ...styles.th }}>
                Qty. of Material required for Spout & Top - 2
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[2]?.sideLiningDetails?.basic
                  ?.qty_spout_and_top_2 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[2]?.sideLiningDetails?.nrm
                  ?.qty_spout_and_top_2 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>MT</th>
              <th style={{ ...styles.th }}>
                Total time available for Production in a Month
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[2]?.increasedProductivityObject
                  ?.IncreasedProductivity?.basic?.totTimeAvailableProd || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[2]?.increasedProductivityObject
                  ?.IncreasedProductivity?.nrm?.totTimeAvailableProd || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>Minute</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>
                Material used in spout and top - 3
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[2]?.sideLiningDetails?.basic
                  ?.material_used_top_spout_and_top_3 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[2]?.sideLiningDetails?.nrm
                  ?.material_used_top_spout_and_top_3 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>MT</th>
              <th style={{ ...styles.th }}>Actual No.of heats in a month</th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[2]?.increasedProductivityObject
                  ?.IncreasedProductivity?.basic?.actualNoOfHeats || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[2]?.increasedProductivityObject
                  ?.IncreasedProductivity?.nrm?.actualNoOfHeats || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>No of Heat</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>
                Qty. of Material required for Spout & Top - 3
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[2]?.sideLiningDetails?.basic
                  ?.qty_spout_and_top_3 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[2]?.sideLiningDetails?.nrm
                  ?.qty_spout_and_top_3 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>MT</th>
              <th style={{ ...styles.th }}>Actual Production in a month</th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[2]?.increasedProductivityObject
                  ?.IncreasedProductivity?.basic?.actualProdInMonth || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[2]?.increasedProductivityObject
                  ?.IncreasedProductivity?.nrm?.actualProdInMonth || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>MT</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>Average Side lining life</th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[2]?.sideLiningDetails?.basic
                  ?.average_side_lining_life || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[2]?.sideLiningDetails?.nrm
                  ?.average_side_lining_life || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>MT</th>
              <th style={{ ...styles.th }}>Production increase due to NRM</th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[2]?.increasedProductivityObject
                  ?.IncreasedProductivity?.basic
                  ?.production_increase_due_to_nrm || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[2]?.increasedProductivityObject
                  ?.IncreasedProductivity?.nrm
                  ?.production_increase_due_to_nrm || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>Mt/ Month</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>No of Side Lining</th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[2]?.sideLiningDetails?.basic
                  ?.no_of_side_lining || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[2]?.sideLiningDetails?.nrm
                  ?.no_of_side_lining || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>No</th>
              <th style={{ ...styles.th }}>% production increase in a month</th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[2]?.increasedProductivityObject
                  ?.IncreasedProductivity?.basic
                  ?.percentage_production_increase || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[2]?.increasedProductivityObject
                  ?.IncreasedProductivity?.nrm
                  ?.percentage_production_increase || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>%</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>
                Average heat size per Side lining
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[2]?.sideLiningDetails?.basic
                  ?.avg_heat_size || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[2]?.sideLiningDetails?.nrm
                  ?.avg_heat_size || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>MT</th>
              <th style={{ ...styles.th }}>Profit Margin per ton of steel</th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[2]?.increasedProductivityObject
                  ?.IncreasedProductivity?.basic?.profit_margin || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[2]?.increasedProductivityObject
                  ?.IncreasedProductivity?.nrm?.profit_margin || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>dia</th>
            </tr>

            {/* // patching details */}

            <tr>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}>
                Patching Details (D)
              </th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.th }}>
                Cost benefit due to NRM in a month
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[2]?.increasedProductivityObject
                  ?.IncreasedProductivity?.basic?.cost_benefit || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[2]?.increasedProductivityObject
                  ?.IncreasedProductivity?.nrm?.cost_benefit || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>dia</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>
                Ramming material used in patching - 1
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[3]?.patchingDetails?.basic
                  ?.ramming_material_patching_1 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[3]?.patchingDetails?.nrm
                  ?.ramming_material_patching_1 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>MT</th>
              <th style={{ ...styles.th }}>
                Saving due to increased productivity
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[2]?.increasedProductivityObject
                  ?.IncreasedProductivity?.basic
                  ?.saving_due_to_increased_productivity || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[2]?.increasedProductivityObject
                  ?.IncreasedProductivity?.nrm
                  ?.saving_due_to_increased_productivity || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>dia/MT</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>
                Quantity of material required for patching - 1
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[3]?.patchingDetails?.basic
                  ?.qty_patching_1 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[3]?.patchingDetails?.nrm
                  ?.qty_patching_1 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>MT</th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}>
                S4 : Savings due to energy
              </th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>
                Material used in spout and top - 2
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[3]?.patchingDetails?.basic
                  ?.material_used_spout_and_top_2 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[3]?.patchingDetails?.nrm
                  ?.material_used_spout_and_top_2 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>MT</th>
              <th style={{ ...styles.th }}>Cost per electricity unit</th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[3]?.energyDataObject?.energy?.basic
                  ?.cost_per_electricity_unit || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[3]?.energyDataObject?.energy?.nrm
                  ?.cost_per_electricity_unit || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>dia / KWh</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>
                Qty. of Material required for Spout & Top - 2
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[3]?.patchingDetails?.basic
                  ?.qty_patching_1 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[3]?.patchingDetails?.nrm
                  ?.qty_patching_1 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>MT</th>
              <th style={{ ...styles.th }}>Power savings per MT</th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[3]?.energyDataObject?.energy?.basic
                  ?.power_savings_per_mt || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[3]?.energyDataObject?.energy?.nrm
                  ?.power_savings_per_mt || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>KWh/MT</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>
                Material used in spout and top - 3
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[3]?.patchingDetails?.basic
                  ?.material_used_spout_and_top_3 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[3]?.patchingDetails?.nrm
                  ?.material_used_spout_and_top_3 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>MT</th>
              <th style={{ ...styles.th }}>
                Cost Savings due to power consumption in KWh / MT of steel
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[3]?.energyDataObject?.energy?.basic
                  ?.savings_power_consumption || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[3]?.energyDataObject?.energy?.nrm
                  ?.savings_power_consumption || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>dia / KWh</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>
                Qty. of Material required for Spout & Top - 3
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[3]?.patchingDetails?.basic
                  ?.qty_spout_and_top_3 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[3]?.patchingDetails?.nrm
                  ?.qty_spout_and_top_3 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>MT</th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}>
                S5 : Savings due to Yield improvement
              </th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>Average Patching lining life</th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[3]?.patchingDetails?.basic
                  ?.average_patching_lining_life || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[3]?.patchingDetails?.nrm
                  ?.average_patching_lining_life || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>Heat / Lining</th>
              <th style={{ ...styles.th }}>Yield Benefit</th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[4]?.yieldImprovementObject?.yieldImprovement
                  ?.basic?.yield_benefit || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[4]?.yieldImprovementObject?.yieldImprovement
                  ?.nrm?.yield_benefit || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>%</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>No of patching</th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[3]?.patchingDetails?.basic
                  ?.no_of_patching || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[3]?.patchingDetails?.nrm
                  ?.no_of_patching || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>No</th>
              <th style={{ ...styles.th }}>
                Market price of Scrap or DRI per ton
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[4]?.yieldImprovementObject?.yieldImprovement
                  ?.basic?.market_price_of_scrap || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[4]?.yieldImprovementObject?.yieldImprovement
                  ?.nrm?.market_price_of_scrap || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>%</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>Average heat size per Patching</th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[3]?.patchingDetails?.basic
                  ?.avg_heat_size_per_patching || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[3]?.patchingDetails?.nrm
                  ?.avg_heat_size_per_patching || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>MT</th>
              <th style={{ ...styles.th }}>Savings due to yield benefit</th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[4]?.yieldImprovementObject?.yieldImprovement
                  ?.basic?.savings_due_to_yield_benefit || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[4]?.yieldImprovementObject?.yieldImprovement
                  ?.nrm?.savings_due_to_yield_benefit || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>%</th>
            </tr>

            {/* // repair details */}

            <tr>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}>
                Repair details (E)
              </th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}>
                S6 : Savings in alloy
              </th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>
                Ramming material used in patching - 1
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[4]?.repairingDetails?.basic
                  ?.ramming_material_side_lining_1 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[4]?.repairingDetails?.nrm
                  ?.ramming_material_side_lining_1 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>MT</th>
              <th style={{ ...styles.th }}>SiMn Savings</th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[5]?.alloyObject?.alloy?.basic?.simn_savings ||
                  'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[5]?.alloyObject?.alloy?.nrm?.simn_savings ||
                  'N/A'}
              </th>
              <th style={{ ...styles.th }}>dia/MT</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>
                Quantity of material required for patching - 1
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[4]?.repairingDetails?.basic
                  ?.qty_patching_1 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[4]?.repairingDetails?.nrm
                  ?.qty_patching_1 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>MT</th>
              <th style={{ ...styles.th }}>FeCr Savings</th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[5]?.alloyObject?.alloy?.basic?.fecr_savings ||
                  'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[5]?.alloyObject?.alloy?.nrm?.fecr_savings ||
                  'N/A'}
              </th>
              <th style={{ ...styles.th }}>MT</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>
                Material used in spout and top - 2
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[4]?.repairingDetails?.basic
                  ?.material_used_spout_and_top_2 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[4]?.repairingDetails?.nrm
                  ?.material_used_spout_and_top_2 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>MT</th>
              <th style={{ ...styles.th }}>
                Savings due to other alloys, if applicable (Al)
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[5]?.alloyObject?.alloy?.basic
                  ?.savings_due_to_other_alloy || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[5]?.alloyObject?.alloy?.nrm
                  ?.savings_due_to_other_alloy || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>dia / KWh</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>
                Qty. of Material required for Spout & Top - 2
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[4]?.repairingDetails?.basic
                  ?.qty_spout_and_top_2 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[4]?.repairingDetails?.nrm
                  ?.qty_spout_and_top_2 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>MT</th>
              <th style={{ ...styles.th }}>
                Savings due to reduction in alloy addition
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[5]?.alloyObject?.alloy?.basic
                  ?.savings_due_to_reduction || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[5]?.alloyObject?.alloy?.nrm
                  ?.savings_due_to_reduction || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>KWh/MT</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>
                Material used in spout and top - 3
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[4]?.repairingDetails?.basic
                  ?.material_used_spout_and_top_3 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[4]?.repairingDetails?.nrm
                  ?.material_used_spout_and_top_3 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>MT</th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}>
                S7 : Savings due to other tangible benefits
              </th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>
                Qty. of Material required for Spout & Top - 3
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[4]?.repairingDetails?.basic
                  ?.qty_spout_and_top_3 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {consumption_details?.[4]?.repairingDetails?.nrm
                  ?.qty_spout_and_top_3 || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>MT</th>
              <th style={{ ...styles.th }}>
                Savings due to Asbestos sheet, avoiding antenna replacement
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[6]?.tangibleBenefitsObject?.tangibleBenefits
                  ?.basic?.asbestos_sheet || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails?.[6]?.tangibleBenefitsObject?.tangibleBenefits
                  ?.nrm?.asbestos_sheet || 'N/A'}
              </th>
              <th style={{ ...styles.th }}>dia/MT</th>
            </tr>

            {/* // cost calculation */}

            <tr>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}>
                Cost Calculations (E)
              </th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.th }}>
                Savings due to Coil coat for repair , Sodium Silicate
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails[6]?.tangibleBenefitsObject?.tangibleBenefits
                  ?.basic?.coil_coat_for_repair ?? 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails[6]?.tangibleBenefitsObject?.tangibleBenefits
                  ?.nrm?.coil_coat_for_repair ?? 'N/A'}
              </th>
              <th style={{ ...styles.th }}>dia/MT</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>Average heat size in a campaign</th>
              <th style={{ ...styles.th }}>
                {cost_calculation?.costCalculation?.basic?.avg_in_heat_size ??
                  'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {cost_calculation?.costCalculation?.nrm?.avg_in_heat_size ??
                  'N/A'}
              </th>
              <th style={{ ...styles.th }}>MT</th>
              <th style={{ ...styles.th }}>
                Savings due to Slag Dumping , safety in operations
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails[6]?.tangibleBenefitsObject?.tangibleBenefits
                  ?.basic?.slag_dumping ?? 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails[6]?.tangibleBenefitsObject?.tangibleBenefits
                  ?.nrm?.slag_dumping ?? 'N/A'}
              </th>
              <th style={{ ...styles.th }}>dia/MT</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>Average life in a campaign</th>
              <th style={{ ...styles.th }}>
                {cost_calculation?.costCalculation?.basic?.avg_life ?? 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {cost_calculation?.costCalculation?.nrm?.avg_life ?? 'N/A'}
              </th>
              <th style={{ ...styles.th }}>Heats</th>
              <th style={{ ...styles.th }}>
                Savings due to Other Tangible Benefit (F5)
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails[6]?.tangibleBenefitsObject?.tangibleBenefits
                  ?.basic?.other_tangible_benefits ?? 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails[6]?.tangibleBenefitsObject?.tangibleBenefits
                  ?.nrm?.other_tangible_benefits ?? 'N/A'}
              </th>
              <th style={{ ...styles.th }}>dia/MT</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>Total no of cycle in a Campaign</th>
              <th style={{ ...styles.th }}>
                {cost_calculation?.costCalculation?.basic?.no_of_cycles ??
                  'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {cost_calculation?.costCalculation?.nrm?.no_of_cycles ?? 'N/A'}
              </th>
              <th style={{ ...styles.th }}>Heats</th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}>
                S8 : Savings due to other metallurgical benefits
              </th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>Total no of heats in a campaign</th>
              <th style={{ ...styles.th }}>
                {cost_calculation?.costCalculation?.basic?.no_of_heats ?? 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {cost_calculation?.costCalculation?.nrm?.no_of_heats ?? 'N/A'}
              </th>
              <th style={{ ...styles.th }}>Heats</th>
              <th style={{ ...styles.th }}>
                Savings due to Metallurgical Benefit (F6)
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails[7]?.metallurgicalBenefitsObject
                  ?.metallurgicalBenefits?.basic?.savings_metallurgical ??
                  'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {savingsDetails[7]?.metallurgicalBenefitsObject
                  ?.metallurgicalBenefits?.nrm?.savings_metallurgical ?? 'N/A'}
              </th>
              <th style={{ ...styles.th }}>dia/MT</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>Total Production per Campaign</th>
              <th style={{ ...styles.th }}>
                {cost_calculation?.costCalculation?.basic?.tot_prod ?? 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {cost_calculation?.costCalculation?.nrm?.tot_prod ?? 'N/A'}
              </th>
              <th style={{ ...styles.th }}>Heats</th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}>
                S9 : Savings in scrap due to less number of sintering heats
              </th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>Ramming material Landed cost - 1</th>
              <th style={{ ...styles.th }}>
                {cost_calculation?.costCalculation?.basic
                  ?.ramming_material_landed_cost_1 ?? 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {cost_calculation?.costCalculation?.nrm
                  ?.ramming_material_landed_cost_1 ?? 'N/A'}
              </th>
              <th style={{ ...styles.th }}>Heats</th>
              <th style={{ ...styles.th }}>
                Yield of overall charge mix in regular heat
              </th>
              <th style={{ ...styles.th }}></th>
              <th style={{ ...styles.th }}></th>
              <th style={{ ...styles.th }}>dia/MT</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>
                Ramming material cost In a Campaign - 1
              </th>
              <th style={{ ...styles.th }}>
                {cost_calculation?.costCalculation?.basic
                  ?.ramming_cost_in_campaign_1 ?? 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {cost_calculation?.costCalculation?.nrm
                  ?.ramming_cost_in_campaign_1 ?? 'N/A'}
              </th>
              <th style={{ ...styles.th }}>Heats</th>
              <th style={{ ...styles.th }}>Yield of Sintering scrap</th>
              <th style={{ ...styles.th }}></th>
              <th style={{ ...styles.th }}></th>
              <th style={{ ...styles.th }}>dia/MT</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>Topping material Landed cost - 2</th>
              <th style={{ ...styles.th }}>
                {cost_calculation?.costCalculation?.basic?.topping_material_2 ??
                  'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {cost_calculation?.costCalculation?.nrm?.topping_material_2 ??
                  'N/A'}
              </th>
              <th style={{ ...styles.th }}>Heats</th>
              <th style={{ ...styles.th }}>
                Market Price of Sintering scrap/MT
              </th>
              <th style={{ ...styles.th }}></th>
              <th style={{ ...styles.th }}></th>
              <th style={{ ...styles.th }}>dia/MT</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>
                Topping material cost in a Campaign - 2
              </th>
              <th style={{ ...styles.th }}>
                {cost_calculation?.costCalculation?.basic?.cost_in_campaign_2 ??
                  'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {cost_calculation?.costCalculation?.nrm?.cost_in_campaign_2 ??
                  'N/A'}
              </th>
              <th style={{ ...styles.th }}>Heats</th>
              <th style={{ ...styles.th }}>
                Percentage Scrap addition difference between sintering and
                regular heat
              </th>
              <th style={{ ...styles.th }}></th>
              <th style={{ ...styles.th }}></th>
              <th style={{ ...styles.th }}>dia/MT</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>Topping material Landed cost - 3</th>
              <th style={{ ...styles.th }}>
                {cost_calculation?.costCalculation?.basic?.topping_material_3 ??
                  'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {cost_calculation?.costCalculation?.nrm?.topping_material_3 ??
                  'N/A'}
              </th>
              <th style={{ ...styles.th }}>Heats</th>
              <th style={{ ...styles.th }}>
                Excess Scrap charged per Sintering heat
              </th>
              <th style={{ ...styles.th }}></th>
              <th style={{ ...styles.th }}></th>
              <th style={{ ...styles.th }}>dia/MT</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>
                Topping material cost in a Campaign - 3
              </th>
              <th style={{ ...styles.th }}>
                {cost_calculation?.costCalculation?.basic?.cost_in_campaign_3 ??
                  'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {cost_calculation?.costCalculation?.nrm?.cost_in_campaign_3 ??
                  'N/A'}
              </th>
              <th style={{ ...styles.th }}>Heats</th>
              <th style={{ ...styles.th }}>
                Percentage change in Landed cost of DRI/Scrap
              </th>
              <th style={{ ...styles.th }}></th>
              <th style={{ ...styles.th }}></th>
              <th style={{ ...styles.th }}>dia/MT</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>Total Refractory Consumption</th>
              <th style={{ ...styles.th }}>
                {cost_calculation?.costCalculation?.basic?.tot_refractory ??
                  'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {cost_calculation?.costCalculation?.nrm?.tot_refractory ??
                  'N/A'}
              </th>
              <th style={{ ...styles.th }}>dia</th>
              <th style={{ ...styles.th }}>
                Market Price of DRI/MT (For DRI Customer) or Regular Scrap /MT
                (For Scrap Customer)
              </th>
              <th style={{ ...styles.th }}></th>
              <th style={{ ...styles.th }}></th>
              <th style={{ ...styles.th }}>dia/MT</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>
                Cost of refractory per ton of steel produced
              </th>
              <th style={{ ...styles.th }}>
                {cost_calculation?.costCalculation?.basic?.cost_of_refractory ??
                  'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {cost_calculation?.costCalculation?.nrm?.cost_of_refractory ??
                  'N/A'}
              </th>
              <th style={{ ...styles.th }}>dia</th>
              <th style={{ ...styles.th }}>
                Cost of Excess Scrap charged due to Sintering heat per month
              </th>
              <th style={{ ...styles.th }}></th>
              <th style={{ ...styles.th }}></th>
              <th style={{ ...styles.th }}>dia/MT</th>
            </tr>
            <tr>
              <th style={{ ...styles.th }}>
                Specific Refractory Consumption (kg/T of steel)
              </th>
              <th style={{ ...styles.th }}>
                {cost_calculation?.costCalculation?.basic
                  ?.spec_refractory_consumpt ?? 'N/A'}
              </th>
              <th style={{ ...styles.th }}>
                {cost_calculation?.costCalculation?.nrm
                  ?.spec_refractory_consumpt ?? 'N/A'}
              </th>
              <th style={{ ...styles.th }}>dia</th>
              <th style={{ ...styles.th }}>
                Cost of Excess Scrap after replacing with DRI or Regular Scrap
                (per month)
              </th>
              <th style={{ ...styles.th }}></th>
              <th style={{ ...styles.th }}></th>
              <th style={{ ...styles.th }}>INR/MT</th>
            </tr>
            <tr>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}>
                Increase in cost due to NRM
              </th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}>
                {cost_calculation?.costCalculation?.basic?.inc_in_cost ?? 'N/A'}
              </th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}>
                {cost_calculation?.costCalculation?.nrm?.inc_in_cost ?? 'N/A'}
              </th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}>
                dia/MT
              </th>
              <th style={{ ...styles.th }}>
                Cost of Excess Scrap charged in Sintering heat per MT
              </th>
              <th style={{ ...styles.th }}></th>
              <th style={{ ...styles.th }}></th>
              <th style={{ ...styles.th }}>INR/MT</th>
            </tr>
            <tr>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}>
                Total Savings due to NRM (S1 to S8)
              </th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}>
                {cost_calculation?.costCalculation?.basic?.tot_savings ?? 'N/A'}
              </th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}>
                {cost_calculation?.costCalculation?.nrm?.tot_savings ?? 'N/A'}
              </th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}>
                dia/MT
              </th>
              <th style={{ ...styles.th }}>
                Scrap Cost saving in NRM due to less no. of Sintering heats
              </th>
              <th style={{ ...styles.th }}></th>
              <th style={{ ...styles.th }}></th>
              <th style={{ ...styles.th }}>INR/MT</th>
            </tr>
            <tr>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}>
                Relative Gain in cost due to NRM
              </th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}>
                {cost_calculation?.costCalculation?.basic?.relative_gain ??
                  'N/A'}
              </th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}>
                {cost_calculation?.costCalculation?.nrm?.relative_gain ?? 'N/A'}
              </th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}>
                dia/MT
              </th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}>
                Total Savings due to NRM (S1 to S9)
              </th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
              <th style={{ ...styles.thTd, ...styles.categoryHeader }}></th>
            </tr>
            <tr>
              <th
                colSpan="8"
                style={{ ...styles.th, textAlign: 'center', padding: 8 }}
              >
                Other Comments
              </th>
            </tr>
            <tr>
              <th
                colSpan="8"
                style={{ ...styles.th, textAlign: 'center', padding: 15 }}
              ></th>
            </tr>
          </thead>
        </table>
      </div>
    )
  }

  //   const handleApprove = () => {
  //     AlertDialog({
  //       type: 'delete',
  //       title: 'Submission',
  //       text: 'Are you sure you want to Approval?',
  //       confirmButtonText: 'Yes',
  //       onConfirm: async () => {
  //         // if(currentUser.role.role_id === 3 || currentUser.role.role_id === 4){
  //         //   await insertData(updatedGeneralDetails, uploadId,"approved");
  //         // }else{
  //         //   await insertData(updatedGeneralDetails, uploadId,"submitted");
  //         // }
  //       },
  //     });

  //   }
  //   const handleReject = () => {

  //   }
  return (
    <div>
      <div style={{ overflowX: 'auto' }}>
        {tableData.length === 0 ? (
          <Typography
            variant="body1"
            sx={{ textAlign: 'center', marginTop: '20px' }}
          >
            Trials not found
          </Typography>
        ) : (
          <div>
            {tableData.submission_status === 'submitted' &&
              (currentUser.role.role_id === 3 ||
                currentUser.role.role_id === 4) && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'fixed',
                    top: 100,
                    right: 20,
                    backgroundColor: 'white',
                    zIndex: 1000,
                    padding: '10px',
                    alignItems: 'center',
                  }}
                >
                  {/* Example of StyledButtonWeb */}
                  <StyledButtonWeb
                    variant="outlined_reject"
                    text="Reject"
                    onClick={handleReject}
                    style={{ marginRight: 10, width: '100px' }}
                  />
                  <StyledButtonWeb
                    variant="contained"
                    color="primary"
                    text="Approve"
                    onClick={handleApprove}
                    style={{ width: '100px' }}
                  />
                </div>
              )}

            {tableData.submission_status === 'rejected' && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  position: 'fixed',
                  top: 100,
                  right: 20,
                  backgroundColor: 'white',
                  zIndex: 1000,
                  padding: '10px',
                  alignItems: 'center',
                }}
              >
                <StyledButtonWeb
                  variant="contained"
                  color="primary"
                  text="View"
                  onClick={handleRejectComment}
                  style={{ width: '100px' }}
                />
              </div>
            )}

            {showRejectPopup && (
              <div
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 1001,
                }}
              >
                <div
                  style={{
                    backgroundColor: 'white',
                    padding: '20px',
                    borderRadius: '8px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <TextField
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    placeholder="Enter comments"
                    style={{
                      width: '300px',
                      height: '100px',
                      marginBottom: '10px',
                    }}
                  />
                  <StyledButtonWeb
                    variant="contained"
                    color="primary"
                    text="OK"
                    onClick={handlePopupOk}
                    style={{ width: '100px' }}
                  />
                </div>
              </div>
            )}

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '20px',
                  letterSpacing: '-0.006em',
                  textAlign: 'left',
                  color: '#252C32',
                  marginTop: '10px',
                  zIndex: 1,
                  backgroundColor: '#fff',
                  position: 'sticky',
                  top: 0,
                }}
              >
                Cost Sheet
              </Typography>
            </div>
            <TableContainer component={Paper}>
              {(() => {
                if (parsedData.dataFile.template.template_id === 5) {
                  return <TableA />
                } else {
                  return null
                }
              })()}
            </TableContainer>
          </div>
        )}
      </div>
    </div>
  )
}

export default ViewCostSheet
