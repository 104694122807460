import React, { useState, useRef, useEffect } from 'react'
import { Card, CardContent, Typography, Avatar, Grid } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton } from '@mui/material'
import ViewIcon from '../../assets/icons/ic_view.png'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import ViewTrial from './ViewTrial'
import API from '../services/API'
import ApprovalIcon from '../../assets/icons/ic_approval.svg'
import AlertDialog from '../input/AlertDialog'
import Tooltip from '@mui/material/Tooltip'
import DownloadIcon from '../../assets/icons/download_icon.svg'
import * as XLSX from 'xlsx'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import Collapsed from '../../assets/icons/collapsed.svg'
import Expanded from '../../assets/icons/expanded.svg'
const DataCollection = ({
  selectedTab,
  cardsSubmitted,
  cardsDraft,
  approvedData,
  rejectData,
  file,
  templateId,
  filteredDataCollection,
}) => {
  console.log('templateId', templateId)
  console.log('selectedTab', selectedTab)
  console.log('2232', cardsSubmitted)
  console.log('cardsDraft', cardsDraft)
  console.log('filteredDataCollection', filteredDataCollection)
  const navigate = useNavigate()
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [popupPosition, setPopupPosition] = useState({ top: 0, right: 0 })
  const [getCardDetails, setgetCardDetails] = useState([])
  const [dataFileId, setDataFileId] = useState(null)
  const popupRef = useRef(null)
  const currentUser = JSON.parse(localStorage.getItem('user'))
  const [selectedItem, setSelectedItem] = useState({})
  const [submissionStatus, setSubmissionStatus] = useState('')
  const access_token = localStorage.getItem('accesstoken')

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      closePopup()
    }
  }

  const closePopup = () => {
    setIsPopupOpen(false)
    console.log('Popup closed')
  }

  useEffect(() => {
    if (isPopupOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isPopupOpen])

  const handleDelete = async () => {
    console.log('Button clicked!')
    console.log('cardId', dataFileId)

    try {
      // Make the API call here
      const url = `${API.DELETE_CARD}/${dataFileId}`
      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      const data = response.data

      // Do something with the response data
      console.log('DELETE_CARD', data)
      setIsPopupOpen(false)
      window.location.reload()
    } catch (error) {
      console.error('Error:', error)
      // Optionally, you can handle the error state here
    }
  }

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
    return buf
  }

  const workbookToBlob = (workbook) => {
    return new Promise((resolve) => {
      // Write the workbook to a binary string
      const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' })

      // Convert the binary string to a Blob
      const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' })
      resolve(blob)
    })
  }

  const handleDownload = async () => {
    const id = localStorage.getItem('dataCollectionID')
    // const id = "cfe1c20d-f0d8-45ca-a7a5-8469e123cf51"

    try {
      // Fetch data from the endpoint
      const response = await axios.get(
        `${API.GET_DATA_COLLECTION_BY_ID}/${id}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      const data = response.data
      console.log(data, 'data')

      // Fetch the Excel template
      const responseTemplate = await axios.get(
        API.GET_DATA_COLLECTION_EXCEL_DOWNLOAD,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      console.log(responseTemplate, 'sjhsafjas')

      // Decode base64 to ArrayBuffer
      const base64Data = responseTemplate.data.downloadedTemplates[0].data
      const byteCharacters = atob(base64Data)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const arrayBuffer = new Uint8Array(byteNumbers).buffer

      // Create a new workbook and load the template
      const workbook = new ExcelJS.Workbook()
      await workbook.xlsx.load(arrayBuffer)

      // Get the worksheet
      const worksheet = workbook.worksheets[0]
      // Define a style to be used
      const style = {
        fill: {
          patternType: 'solid',
          fgColor: { rgb: 'FFFF00' }, // Yellow background
        },
      }

      // general_details
      const generalDetails = data.general_details || {}
      worksheet.getCell('B1').value = generalDetails.customer_name || ''
      worksheet.getCell('B1').style = style
      worksheet.getCell('B2').value = generalDetails.contact_person || ''
      worksheet.getCell('B2').style = style
      worksheet.getCell('E1').value = generalDetails.date || ''
      worksheet.getCell('E1').style = style
      worksheet.getCell('E2').value = generalDetails.mobile_number || ''
      worksheet.getCell('E2').style = style

      // product_details
      const productDetails = data.product_details || {}
      worksheet.getCell('B4').value = productDetails.end_product || ''
      worksheet.getCell('B5').value = productDetails.steel_produce || ''
      worksheet.getCell('B6').value = productDetails.ratio || ''

      // furnace_details
      const furnaceDetails = data.furnace_details || {}

      worksheet.getCell('B8').value = furnaceDetails.no_of_panels || ''
      worksheet.getCell('B9').value = furnaceDetails.fce_capacity || ''
      worksheet.getCell('B10').value = furnaceDetails.avg_heatsize || ''
      worksheet.getCell('B11').value = furnaceDetails.power_rating || ''
      worksheet.getCell('B12').value = furnaceDetails.fce_running || ''
      worksheet.getCell('B13').value = furnaceDetails.tap_time || ''
      worksheet.getCell('B14').value = furnaceDetails.sintering_panel || ''
      worksheet.getCell('B15').value =
        furnaceDetails.power_rating_sintering || ''
      worksheet.getCell('B16').value = furnaceDetails.no_of_heats_perday || ''
      worksheet.getCell('B17').value =
        furnaceDetails.avg_power_consumption || ''
      worksheet.getCell('B18').value = furnaceDetails.lrf || ''
      worksheet.getCell('B19').value = furnaceDetails.frequency_range || ''
      worksheet.getCell('B20').value = furnaceDetails.max_tapping_temp || ''
      worksheet.getCell('B21').value = furnaceDetails.avg_tapping_temp || ''
      worksheet.getCell('B22').value = furnaceDetails.furnace_voltage || ''
      worksheet.getCell('B23').value = furnaceDetails.furnace_oem || ''
      worksheet.getCell('B24').value = furnaceDetails.furnace_year || ''
      worksheet.getCell('B25').value = furnaceDetails.coil_grout || ''
      worksheet.getCell('B26').value = furnaceDetails.sintering_time || ''
      worksheet.getCell('B27').value = furnaceDetails.operation || ''
      worksheet.getCell('B28').value = furnaceDetails.change_overtime || ''
      worksheet.getCell('B29').value = furnaceDetails.checking_time || ''

      // mildSteelDetails
      const mildSteelDetails = data.mild_steel_customers_only || {}

      worksheet.getCell('B31').value = mildSteelDetails.deslagging_by || ''
      worksheet.getCell('B32').value =
        mildSteelDetails.using_magnet_or_not || ''
      worksheet.getCell('B33').value =
        mildSteelDetails.avg_c_percent_at_50 || ''
      worksheet.getCell('B34').value =
        mildSteelDetails.avg_c_percent_at_80 || ''
      worksheet.getCell('B35').value =
        mildSteelDetails.final_c_percent_beforeTapping || ''
      worksheet.getCell('B36').value = mildSteelDetails.data_mill_scale || ''
      worksheet.getCell('B37').value =
        mildSteelDetails.tilt_count_for_deslagging || ''
      worksheet.getCell('B38').value =
        mildSteelDetails.are_they_taking_liquid_metal || ''
      worksheet.getCell('B39').value = mildSteelDetails.dri_quality || ''
      worksheet.getCell('B40').value =
        mildSteelDetails.slag_generation_per_heat || ''
      worksheet.getCell('B41').value = mildSteelDetails.power_utilised || ''
      worksheet.getCell('B42').value = mildSteelDetails.silica_price || ''
      worksheet.getCell('B43').value = mildSteelDetails.ccm || ''
      worksheet.getCell('B44').value = mildSteelDetails.casting_time || ''
      worksheet.getCell('B45').value = mildSteelDetails.tundish_cap || ''
      worksheet.getCell('B46').value =
        mildSteelDetails.min_level_of_tundish || ''
      worksheet.getCell('B47').value = mildSteelDetails.billet_size || ''
      worksheet.getCell('B48').value = mildSteelDetails.no_of_sequence || ''
      worksheet.getCell('B49').value = mildSteelDetails.ccm_make || ''
      worksheet.getCell('B50').value =
        mildSteelDetails.ccm_preparation_time || ''
      worksheet.getCell('B51').value =
        mildSteelDetails.purpose_of_using_nrm || ''

      // rawMaterialDetails
      const rawMaterialDetails = data.raw_material_energy_details || {}

      worksheet.getCell('E4').value = rawMaterialDetails.type_of_scrap || ''
      worksheet.getCell('E5').value = rawMaterialDetails.charge_mix_ratio || ''
      worksheet.getCell('E6').value =
        rawMaterialDetails.yield_of_charge_mix || ''
      worksheet.getCell('E7').value = rawMaterialDetails.profit || ''
      worksheet.getCell('E8').value =
        rawMaterialDetails.unit_price_of_power || ''

      // furnaceDimension
      const furnaceDimension = data.furnace_dimension || {}

      worksheet.getCell('E21').value = furnaceDimension.inner_dia_of_coil || ''
      worksheet.getCell('E22').value =
        furnaceDimension.inner_dia_after_coilcoat || ''
      worksheet.getCell('E23').value = furnaceDimension.former_outer_dia || ''
      worksheet.getCell('E24').value = furnaceDimension.furnace_height || ''
      worksheet.getCell('E25').value = furnaceDimension.bottom_taper_dia || ''
      worksheet.getCell('E26').value = furnaceDimension.bottom_outer_dia || ''
      worksheet.getCell('E27').value = furnaceDimension.former_ht || ''
      worksheet.getCell('E28').value = furnaceDimension.bottom_lining_ht || ''
      worksheet.getCell('E29').value =
        furnaceDimension.total_power_given_to_ms || ''

      // liningDetails
      const liningDetails = data.lining_details || {}

      worksheet.getCell('E31').value =
        liningDetails.consumption_for_new_lining || ''
      worksheet.getCell('E32').value =
        liningDetails.consumption_for_patching || ''
      worksheet.getCell('E33').value = liningDetails.new_lining_life || ''
      worksheet.getCell('E34').value = liningDetails.patching_lining_life || ''
      worksheet.getCell('E35').value =
        liningDetails.total_no_of_side_lining || ''
      worksheet.getCell('E36').value = liningDetails.present_lining_method || ''

      // alloyDetails
      const alloyDetails = data.alloy_stainless_steel_customers || {}

      worksheet.getCell('E38').value = alloyDetails.fecr_consumption || ''
      worksheet.getCell('E39').value = alloyDetails.cr_percentage || ''
      worksheet.getCell('E40').value = alloyDetails.final_cr_percentage || ''
      worksheet.getCell('E41').value = alloyDetails.cr_recovery || ''
      worksheet.getCell('E42').value = alloyDetails.alloy_addition || ''
      worksheet.getCell('E43').value = alloyDetails.casting_method || ''
      worksheet.getCell('E44').value = alloyDetails.simn_consumption || ''
      worksheet.getCell('E45').value = alloyDetails.mn_percentage || ''
      worksheet.getCell('E46').value = alloyDetails.mn_recovery || ''
      worksheet.getCell('E47').value = alloyDetails.addition_of_simn || ''
      worksheet.getCell('E48').value =
        alloyDetails.any_other_alloy_addition || ''
      worksheet.getCell('E49').value =
        alloyDetails.final_chemistry_at_furnace || ''
      worksheet.getCell('E50').value =
        alloyDetails.final_chemistry_at_billet || ''
      worksheet.getCell('E51').value = alloyDetails.agreement || ''

      // Generate the modified workbook and save
      const buffer = await workbook.xlsx.writeBuffer()
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })

      // Use FileSaver to save the file
      saveAs(blob, 'data_collection_with_data.xlsx')
    } catch (error) {
      console.error('Error downloading template:', error.message)
    }
  }

  const handleEdit = () => {
    console.log('Button clicked!')
  }

  const handleView = async () => {
    localStorage.setItem('datafile_id', dataFileId)
    navigate('/viewdatacollection')
  }

  const handleMenuOpen = (event, item) => {
    const rect = event.target.getBoundingClientRect()
    setIsPopupOpen(true)
    setPopupPosition({
      top: rect.top + window.scrollY,
      right: window.innerWidth - rect.right,
    })
    console.log('itemdetails', item)
    localStorage.setItem('datafile_info', JSON.stringify(item))
    localStorage.setItem('dataCollectionID', item.id)
    setDataFileId(item.id)
    setSubmissionStatus(item.submission_status)
    setSelectedItem(item)
  }

  // const handleCardClick = async (cardId ) => {
  //     try {
  //         // Make the API call here
  //         const url= `${API.GET_CARD_DETAILS}/${cardId}`
  //         console.log("url",url)
  //         console.log("API.GET_CARD_DETAILS",API.GET_CARD_DETAILS)
  //         const response = await axios.get(url);
  //         const data = response.data;
  //         // Do something with the response data
  //         console.log('Card Details:', data);

  //     } catch (error) {
  //         console.error('Error:', error);
  //         // Optionally, you can handle the error state here
  //     }

  // };

  // const handleApproval = async () => {
  //   AlertDialog({
  //     type: 'delete',
  //     title: 'Submission',
  //     text: 'Are you sure you want to approve?',
  //     confirmButtonText: 'Yes',
  //     onConfirm: async () => {
  //       if (currentUser.role.role_id === 3 || currentUser.role.role_id === 4) {

  //         updateGeneralDetails();

  //       }
  //     }
  //   });
  // };

  const handleApproval = async () => {
    AlertDialog({
      type: 'delete',
      title: 'Submission',
      text: 'Are you sure you want to approve?',
      confirmButtonText: 'Yes',
      onConfirm: async () => {
        if (currentUser.role.role_id === 3 || currentUser.role.role_id === 4) {
          try {
            const data = {
              id: selectedItem.id,
              submission_status: 'approved',
              updated_data: {
                general_details: selectedItem.generalDetails,
                product_details: selectedItem.productDetails,
                furnace_details: selectedItem.furnaceDetails,
                mild_steel_customers_only: selectedItem.mildSteelCustomer,
                raw_material_energy_details: selectedItem.rawAndEnergyDetails,
                furnace_dimension: selectedItem.furnaceDimension,
                lining_details: selectedItem.liningDetails,
                alloy_stainless_steel_customers: selectedItem.alloyAndSteel,
              },
            }

            console.log(data, 'update data')
            const response = await axios.put(API.UPDATE_DATA_COLLECTION, data, {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            })
            console.log('Data updated successfully:', response.data)
            window.location.reload()

            // Navigate after successful update
            // navigate('/approvestatus');
          } catch (error) {
            // Handle errors
            console.error('An error occurred while updating data:', error)
          }
        }
      },
    })
  }

  const handleReject = async () => {
    AlertDialog({
      type: 'delete',
      title: 'Submission',
      text: 'Are you sure you want to Reject?',
      confirmButtonText: 'Yes',
      onConfirm: async () => {
        if (currentUser.role.role_id === 3 || currentUser.role.role_id === 4) {
          try {
            const data = {
              id: selectedItem.id,
              submission_status: 'rejected',
              updated_data: {
                general_details: selectedItem.generalDetails,
                product_details: selectedItem.productDetails,
                furnace_details: selectedItem.furnaceDetails,
                mild_steel_customers_only: selectedItem.mildSteelCustomer,
                raw_material_energy_details: selectedItem.rawAndEnergyDetails,
                furnace_dimension: selectedItem.furnaceDimension,
                lining_details: selectedItem.liningDetails,
                alloy_stainless_steel_customers: selectedItem.alloyAndSteel,
              },
            }

            console.log(data, 'update data')
            const response = await axios.put(API.UPDATE_DATA_COLLECTION, data, {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            })
            console.log('Data updated successfully:', response.data)
            window.location.reload()
            // Navigate after successful update
            // navigate('/approvestatus');
          } catch (error) {
            // Handle errors
            console.error('An error occurred while updating data:', error)
          }
        }
      },
    })
  }
  // const handleReject = async () => {

  //   if (comments.trim() === '') {
  //     setSnackbarOpen(true);
  //     return;
  //   }

  //   try {
  //     const id = localStorage.getItem('dataCollectionID');

  //     const data = {
  //       id: id,
  //       submission_status: "rejected",
  //       comments :comments,
  //       updated_data: {
  //         general_details: generalDetails,
  //         product_details: productDetails,
  //         furnace_details: furnaceDetails,
  //         mild_steel_customers_only: mildSteelCustomer,
  //         raw_material_energy_details: rawAndEnergyDetails,
  //         furnace_dimension: furnaceDimension,
  //         lining_details: liningDetails,
  //         alloy_stainless_steel_customers: alloyAndSteel,
  //       },
  //     };

  //     console.log(data, "update data");
  //     const response = await axios.put(API.UPDATE_DATA_COLLECTION, data);
  //     console.log('Data updated successfully:', response.data);

  //     // Navigate after successful update
  //     navigate('/rejectstatus');
  //   } catch (error) {
  //     // Handle errors
  //     console.error('An error occurred while updating data:', error);
  //   }
  // };

  // const handleApproval = async () => {
  //   console.log("cardId", dataFileId);

  //   try {
  //     // Make the API call here
  //     const url = `${API.APPROVED_CARD_UPDATE}/${dataFileId}`;
  //     console.log("url", url);
  //     console.log("API.APPROVED_CARD_UPDATE", API.APPROVED_CARD_UPDATE);
  //     const response = await axios.post(url);
  //     const data = response.data;
  //     // Do something with the response data
  //     console.log("Card Details:", data);
  //     setIsPopupOpen(false);
  //     window.location.reload();
  //   } catch (error) {
  //     console.error("Error:", error);
  //     // Optionally, you can handle the error state here
  //   }
  // };

  // const handleRejected = async () => {
  //   console.log("cardId", dataFileId);

  //   try {
  //     // Make the API call here
  //     const url = `${API.REJECTED_CARD_UPDATE}/${dataFileId}`;
  //     console.log("url", url);
  //     console.log("API.REJECTED_CARD_UPDATE", API.REJECTED_CARD_UPDATE);
  //     const response = await axios.post(url);
  //     const data = response.data;

  //     // Do something with the response data
  //     console.log("Card Details:", data);
  //     setIsPopupOpen(false);
  //     window.location.reload();
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  const [isDraftOpen, setIsDraftOpen] = useState(false)
  const [isSubmittedOpen, setIsSubmittedOpen] = useState(false)
  const [isRejectedOpen, setIsRejectedOpen] = useState(false)
  const [isApprovedOpen, setIsApprovedOpen] = useState(false)

  const [toggleCount, setToggleCount] = useState(0)
  console.log(toggleCount, 'count')

  const handleToggle = (section) => {
    switch (section) {
      case 'draft':
        setIsDraftOpen((prev) => {
          setToggleCount(prev ? toggleCount - 1 : toggleCount + 1)
          return !prev
        })
        break
      case 'submitted':
        setIsSubmittedOpen((prev) => {
          setToggleCount(prev ? toggleCount - 1 : toggleCount + 1)
          return !prev
        })
        break
      case 'rejected':
        setIsRejectedOpen((prev) => {
          setToggleCount(prev ? toggleCount - 1 : toggleCount + 1)
          return !prev
        })
        break
      case 'approved':
        setIsApprovedOpen((prev) => {
          setToggleCount(prev ? toggleCount - 1 : toggleCount + 1)
          return !prev
        })
        break
      default:
        break
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        borderTop: '2px solid #ebebeb',
      }}
    >
      {/* draft */}
      <div
        style={{
          display: isDraftOpen ? 'block' : 'flex',
          padding: '8px',
          width:
            toggleCount === 1 && isDraftOpen
              ? '90%' // Wrap percentage values in quotes
              : toggleCount === 2 && isDraftOpen
                ? '50%'
                : toggleCount === 3 && isDraftOpen
                  ? '50%'
                  : toggleCount === 4 && isDraftOpen
                    ? '33%'
                    : '',
        }}
      >
        {isDraftOpen ? (
          <div
            onClick={() => handleToggle('draft')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
            }}
          >
            <img
              src={Expanded}
              alt="Expanded"
              style={{ marginRight: '10px' }}
            />{' '}
            {/* Expanded Icon */}
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              DRAFT
            </Typography>
          </div>
        ) : (
          <div
            onClick={() => handleToggle('draft')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
              borderRight: isDraftOpen ? 'none' : '2px solid #ebebeb',
              alignItems: 'center',
            }}
          >
            <img
              src={Collapsed}
              alt="Collapsed"
              style={{ marginBottom: '5px' }}
            />{' '}
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              D
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              R
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              A
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              F
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              T
            </Typography>
          </div>
        )}

        <Grid
          container
          style={{
            marginTop: '0px',
            display: isDraftOpen ? 'flex' : 'none',
            flexWrap: 'wrap',
          }}
        >
          {(filteredDataCollection.length > 0
            ? filteredDataCollection.filter(
                (item) => item?.submission_status === 'draft'
              )
            : cardsDraft && cardsDraft
          ).map((item) => {
            console.log('itemitem', item) // Move console.log here to debug

            const createdDate = new Date(item.created_at)
            const formattedDate = createdDate.toLocaleDateString('en-US', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            })
            return (
              <Grid
                xs={
                  toggleCount === 1
                    ? 3
                    : toggleCount === 2
                      ? 6
                      : toggleCount === 3
                        ? 12
                        : 12 // When all 4 are open
                }
              >
                <Card
                  key={item.id}
                  sx={{
                    border: '1px solid #E6E6E6',
                    boxShadow: '0px 5px 13px -5px #0A090B0D',
                    height: '180px',
                    marginBottom: '20px',
                    marginRight: '5px',
                  }}
                >
                  <CardContent>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '16px',
                          fontWeight: 600,
                          lineHeight: '20px',
                          letterSpacing: '-0.006em',
                          textAlign: 'left',
                          color: '#252C32',
                          marginTop: '10px',
                        }}
                      >
                        {`Data collection_${item.dataFile.customer.organization_name}`}
                      </Typography>
                      <IconButton
                        aria-label="options"
                        onClick={(event) => handleMenuOpen(event, item)}
                      >
                        <MoreVertIcon style={{ color: '#0086DC' }} />
                      </IconButton>
                    </div>

                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: 700,
                        lineHeight: '20px',
                        letterSpacing: '-0.6%',
                        textAlign: 'left',
                        marginTop: '5px',
                        color: '#252C32',
                      }}
                    >
                      {item.dataFile.customer.organization_name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '16px',
                        letterSpacing: '-0.006rem',
                        marginTop: '15px',
                        textAlign: 'left',
                        color: '#7F7D83',
                        width: '37px',
                        height: '16px',
                        background: '#7F7D831A', // Add background color here
                        padding: '0px 4px', // Add padding
                        borderRadius: '4px', // Add border radius
                      }}
                    >
                      Draft
                    </Typography>

                    <div
                      style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '20px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#6E7C87',
                          }}
                        >
                          Created Date:
                        </Typography>

                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 700,
                            lineHeight: '20px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#252C32',
                            marginLeft: '8px', // Adjust spacing between components
                          }}
                        >
                          {formattedDate}
                        </Typography>
                      </div>

                      {item.dataFile.user &&
                        item.dataFile.user.firstname &&
                        item.dataFile.user.lastname && (
                          <Tooltip
                            title={`${item.dataFile.user.firstname} ${item.dataFile.user.lastname}`}
                            arrow
                          >
                            <Avatar
                              style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '12px',
                                background: '#D7EDFF',
                                color: '#0086DC',
                                fontWeight: 700,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: 'auto', // Pushes the Avatar to the right
                                fontSize: '10px', // Adjust font size of the Avatar content
                              }}
                            >
                              {item.dataFile.user.firstname.charAt(0)}
                              {item.dataFile.user.lastname.charAt(0)}
                            </Avatar>
                          </Tooltip>
                        )}
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      </div>
      {/* awaiting approval or submitted */}
      <div
        style={{
          display: isSubmittedOpen ? 'block' : 'flex',
          padding: '8px',
          width:
            toggleCount === 1 && isSubmittedOpen
              ? '90%' // Wrap percentage values in quotes
              : toggleCount === 2 && isSubmittedOpen
                ? '50%'
                : toggleCount === 3 && isSubmittedOpen
                  ? '50%'
                  : toggleCount === 4 && isSubmittedOpen
                    ? '33%'
                    : '',
        }}
      >
        {isSubmittedOpen ? (
          <div
            onClick={() => handleToggle('submitted')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
            }}
          >
            <img
              src={Expanded}
              alt="Expanded"
              style={{ marginRight: '10px' }}
            />
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                letterSpacing: '0.006em',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              {currentUser.role.role_id === 3 || currentUser.role.role_id === 4
                ? 'AWAITING APPROVAL'
                : 'SUBMITTED'}
            </Typography>
          </div>
        ) : (
          <div
            onClick={() => handleToggle('submitted')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
              borderRight: isDraftOpen ? 'none' : '2px solid #ebebeb',
              alignItems: 'center',
            }}
          >
            <img
              src={Collapsed}
              alt="Collapsed"
              style={{ marginBottom: '5px' }}
            />{' '}
            {/* Collapsed Icon */}
            {Array.from(
              currentUser.role.role_id === 3 || currentUser.role.role_id === 4
                ? 'AWAITING APPROVAL'
                : 'SUBMITTED'
            ).map((letter, index) => (
              <Typography
                key={index}
                variant="body1"
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  letterSpacing: '0.006em',
                  textAlign: 'left',
                  color: '#254A9A',
                }}
              >
                {letter}
              </Typography>
            ))}
          </div>
        )}
        <Grid
          container
          style={{
            marginTop: '0',
            display: isSubmittedOpen ? 'flex' : 'none',
            flexWrap: 'wrap',
          }}
        >
          {(filteredDataCollection.length > 0
            ? filteredDataCollection.filter(
                (item) => item?.submission_status === 'submitted'
              )
            : cardsSubmitted
          ).map((item) => {
            console.log('itemitem', item)
            if (item.dataFile.template.template_id === 4) {
              const createdDate = new Date(item.created_at)
              const formattedDate = createdDate.toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })
              return (
                <Grid
                  xs={
                    toggleCount === 1
                      ? 3
                      : toggleCount === 2
                        ? 6
                        : toggleCount === 3
                          ? 12
                          : 12 // When all 4 are open
                  }
                >
                  <Card
                    key={item.id}
                    sx={{
                      border: '1px solid #E6E6E6',
                      boxShadow: '0px 5px 13px -5px #0A090B0D',
                      height: '180px',
                      marginBottom: '20px',
                      marginRight: '5px',
                    }}
                  >
                    <CardContent>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: '16px',
                            fontWeight: 600,
                            lineHeight: '20px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#252C32',
                            marginTop: '10px',
                          }}
                        >
                          {`Data collection_${item.dataFile.customer.organization_name}`}
                        </Typography>
                        <IconButton
                          aria-label="options"
                          onClick={(event) => handleMenuOpen(event, item)}
                        >
                          <MoreVertIcon style={{ color: '#0086DC' }} />
                        </IconButton>
                      </div>

                      <Typography
                        variant="body1"
                        gutterBottom
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '14px',
                          fontWeight: 700,
                          lineHeight: '20px',
                          letterSpacing: '-0.6%',
                          textAlign: 'left',
                          marginTop: '5px',
                          color: '#252C32',
                        }}
                      >
                        {item.dataFile.customer.organization_name}
                      </Typography>

                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: 500,
                          lineHeight: '16px',
                          letterSpacing: '-0.006em',
                          marginTop: '15px',
                          textAlign: 'left',
                          color: '#0086DC',
                          width:
                            currentUser.role.role_id === 3 ||
                            currentUser.role.role_id === 4
                              ? '120px'
                              : '70px',
                          height: '16px',
                          background: '#0086DC1A', // Add background color here
                          padding: '0px 4px', // Add padding
                          borderRadius: '4px', // Add border radius
                        }}
                      >
                        {currentUser.role.role_id === 3 ||
                        currentUser.role.role_id === 4
                          ? 'Awaiting approval'
                          : 'Submitted'}
                      </Typography>

                      <div
                        style={{
                          flex: 1,
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              fontFamily: 'Inter',
                              fontSize: '12px',
                              fontWeight: 500,
                              lineHeight: '20px',
                              letterSpacing: '-0.006em',
                              textAlign: 'left',
                              color: '#6E7C87',
                            }}
                          >
                            Created Date:
                          </Typography>

                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              fontFamily: 'Inter',
                              fontSize: '12px',
                              fontWeight: 700,
                              lineHeight: '20px',
                              letterSpacing: '-0.006em',
                              textAlign: 'left',
                              color: '#252C32',
                              marginLeft: '8px', // Adjust spacing between components
                            }}
                          >
                            {formattedDate}
                          </Typography>
                        </div>

                        {item.dataFile.user &&
                          item.dataFile.user.firstname &&
                          item.dataFile.user.lastname && (
                            <Tooltip
                              title={`${item.dataFile.user.firstname} ${item.dataFile.user.lastname}`}
                              arrow
                            >
                              <Avatar
                                style={{
                                  width: '30px',
                                  height: '30px',
                                  borderRadius: '12px',
                                  background: '#D7EDFF',
                                  color: '#0086DC',
                                  fontWeight: 700,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  marginLeft: 'auto', // Pushes the Avatar to the right
                                  fontSize: '10px', // Adjust font size of the Avatar content
                                }}
                              >
                                {item.dataFile.user.firstname.charAt(0)}
                                {item.dataFile.user.lastname.charAt(0)}
                              </Avatar>
                            </Tooltip>
                          )}
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              )
            }
          })}
        </Grid>

        {isPopupOpen &&
          (currentUser.role.role_id === 3 ||
            currentUser.role.role_id === 4 ||
            currentUser.role.role_id === 2 ||
            currentUser.role.role_id === 1) && (
            <div
              ref={popupRef}
              style={{
                position: 'absolute',
                top: `${popupPosition.top}px`,
                right: `${popupPosition.right}px`,
                background: 'rgba(255, 255, 255, 1)',
                padding: '20px',
                borderRadius: '8px',
                border: '1px solid #E8E8E8',
                boxShadow: '0px 0px 35px 0px rgba(0, 0, 0, 0.1)',
                zIndex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              {/* Your popup content */}
              <div
                onClick={() => handleView(file)}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  padding: '10px',
                  padding: '10px',
                }}
              >
                <img
                  src={ViewIcon}
                  alt="Edit Icon"
                  style={{ marginRight: '10px' }}
                />
                <Typography
                  style={{
                    color: '#252C32',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 800,
                    lineHeight: 'normal',
                    letterSpacing: '-0.084px',
                  }}
                >
                  View Trial
                </Typography>
              </div>
              <div
                onClick={handleDownload}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  padding: '10px',
                }}
              >
                <img
                  src={DownloadIcon}
                  alt="Edit Icon"
                  style={{ marginRight: '10px' }}
                />
                <Typography
                  style={{
                    color: '#252C32',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 800,
                    lineHeight: 'normal',
                    letterSpacing: '-0.084px',
                  }}
                >
                  Download
                </Typography>
              </div>
              {/* <div
              onClick={handleReject}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "10px",
              }}
            >
              <img
                src={ApprovalIcon}
                alt="Edit Icon"
                style={{ marginRight: "10px" }}
              />
              <Typography
                style={{
                  color: "#252C32",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 800,
                  lineHeight: "normal",
                  letterSpacing: "-0.084px",
                }}
              >
                Rejected
              </Typography>
            </div> */}
              {/* <div
              onClick={handleDelete}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "10px",
              }}
            >
              <img
                src={DeleteIcon}
                alt="Delete Icon"
                style={{ marginRight: "10px" }}
              />
              <Typography
                style={{
                  color: "#FF3347",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 800,
                  lineHeight: "normal",
                  letterSpacing: "-0.084px",
                }}
              >
                Delete
              </Typography>
            </div> */}
            </div>
          )}
      </div>
      {/* approved */}
      <div
        style={{
          display: isApprovedOpen ? 'block' : 'flex',
          padding: '8px',
          width:
            toggleCount === 1 && isApprovedOpen
              ? '90%'
              : toggleCount === 2 && isApprovedOpen
                ? '50%'
                : toggleCount === 3 && isApprovedOpen
                  ? '50%'
                  : toggleCount === 4 && isApprovedOpen
                    ? '33%'
                    : '',
        }}
      >
        {isApprovedOpen ? (
          <div
            onClick={() => handleToggle('approved')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
            }}
          >
            <img
              src={Expanded}
              alt="Expanded"
              style={{ marginRight: '10px' }}
            />
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                letterSpacing: '0.006em',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              APPROVED
            </Typography>
          </div>
        ) : (
          <div
            onClick={() => handleToggle('approved')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
              borderRight: isApprovedOpen ? 'none' : '2px solid #ebebeb',
              alignItems: 'center',
            }}
          >
            <img
              src={Collapsed}
              alt="Collapsed"
              style={{ marginBottom: '5px' }}
            />{' '}
            {/* Collapsed Icon */}
            {Array.from('APPROVED').map((letter, index) => (
              <Typography
                key={index}
                variant="body1"
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  letterSpacing: '0.006em',
                  textAlign: 'left',
                  color: '#254A9A',
                }}
              >
                {letter}
              </Typography>
            ))}
          </div>
        )}

        <Grid
          container
          style={{
            marginTop: '0',
            display: isApprovedOpen ? 'flex' : 'none',
            flexWrap: 'wrap',
          }}
        >
          {(filteredDataCollection.length > 0
            ? filteredDataCollection.filter(
                (item) => item?.submission_status === 'approved'
              )
            : approvedData && approvedData
          ).map((item) => {
            const createdDate = new Date(item.created_at)
            const formattedDate = createdDate.toLocaleDateString('en-US', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            })
            return (
              <Grid
                xs={
                  toggleCount === 1
                    ? 3
                    : toggleCount === 2
                      ? 6
                      : toggleCount === 3
                        ? 12
                        : 12 // When all 4 are open
                }
              >
                <Card
                  key={item.id}
                  sx={{
                    border: '1px solid #E6E6E6',
                    boxShadow: '0px 5px 13px -5px #0A090B0D',
                    height: '180px',
                    marginBottom: '20px',
                    marginRight: '5px',
                  }}
                >
                  <CardContent>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '16px',
                          fontWeight: 600,
                          lineHeight: '20px',
                          letterSpacing: '-0.006em',
                          textAlign: 'left',
                          color: '#252C32',
                          marginTop: '10px',
                        }}
                      >
                        {`Data collection_${item.dataFile.customer.organization_name}`}
                      </Typography>
                      <IconButton
                        aria-label="options"
                        onClick={(event) => handleMenuOpen(event, item)}
                      >
                        <MoreVertIcon style={{ color: '#0086DC' }} />
                      </IconButton>
                    </div>

                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: 700,
                        lineHeight: '20px',
                        letterSpacing: '-0.6%',
                        textAlign: 'left',
                        marginTop: '5px',
                        color: '#252C32',
                      }}
                    >
                      {item.dataFile.customer.organization_name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '16px',
                        letterSpacing: '-0.006em',
                        marginTop: '15px',
                        textAlign: 'left',
                        color: '#34A853',
                        width: '68px',
                        height: '16px',
                        background: '#34A8531A',
                        padding: '0px 4px', // Add padding
                        borderRadius: '4px', // Add border radius
                      }}
                    >
                      Approved
                    </Typography>

                    <div
                      style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '20px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#6E7C87',
                          }}
                        >
                          Created Date:
                        </Typography>

                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 700,
                            lineHeight: '20px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#252C32',
                            marginLeft: '8px',
                          }}
                        >
                          {formattedDate}
                        </Typography>
                      </div>

                      {item.dataFile.user &&
                        item.dataFile.user.firstname &&
                        item.dataFile.user.lastname && (
                          <Tooltip
                            title={`${item.dataFile.user.firstname} ${item.dataFile.user.lastname}`}
                            arrow
                          >
                            <Avatar
                              style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '12px',
                                background: '#D7EDFF',
                                color: '#0086DC',
                                fontWeight: 700,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: 'auto',
                                fontSize: '10px',
                              }}
                            >
                              {item.dataFile.user.firstname.charAt(0)}
                              {item.dataFile.user.lastname.charAt(0)}
                            </Avatar>
                          </Tooltip>
                        )}
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      </div>
      {/* rejected */}
      <div
        style={{
          display: isRejectedOpen ? 'block' : 'flex',
          padding: '8px',
          width:
            toggleCount === 1 && isRejectedOpen
              ? '90%' // Wrap percentage values in quotes
              : toggleCount === 2 && isRejectedOpen
                ? '50%'
                : toggleCount === 3 && isRejectedOpen
                  ? '50%'
                  : toggleCount === 4 && isRejectedOpen
                    ? '33%'
                    : '',
        }}
      >
        {isRejectedOpen ? (
          <div
            onClick={() => handleToggle('rejected')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
            }}
          >
            <img
              src={Expanded}
              alt="Expanded"
              style={{ marginRight: '10px' }}
            />
            <Typography
              variant="body1"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                letterSpacing: '0.006em',
                textAlign: 'left',
                color: '#254A9A',
              }}
            >
              REJECTED
            </Typography>
          </div>
        ) : (
          <div
            onClick={() => handleToggle('rejected')}
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '10px',
              background: '#FFFFFF',
              borderRight: isDraftOpen ? 'none' : '2px solid #ebebeb',
              alignItems: 'center',
            }}
          >
            <img
              src={Collapsed}
              alt="Collapsed"
              style={{ marginBottom: '5px' }}
            />{' '}
            {/* Collapsed Icon */}
            {Array.from('REJECTED').map((letter, index) => (
              <Typography
                key={index}
                variant="body1"
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '24px',
                  letterSpacing: '0.006em',
                  textAlign: 'left',
                  color: '#254A9A',
                }}
              >
                {letter}
              </Typography>
            ))}
          </div>
        )}
        <Grid
          container
          style={{
            marginTop: '0',
            display: isRejectedOpen ? 'flex' : 'none',
            flexWrap: 'wrap',
          }}
        >
          {(filteredDataCollection.length > 0
            ? filteredDataCollection.filter(
                (item) => item?.submission_status === 'rejected'
              )
            : rejectData && rejectData
          ).map((item) => {
            const createdDate = new Date(item.created_at)
            const formattedDate = createdDate.toLocaleDateString('en-US', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            })
            return (
              <Grid
                xs={
                  toggleCount === 1
                    ? 3
                    : toggleCount === 2
                      ? 6
                      : toggleCount === 3
                        ? 9
                        : 9 // When all 4 are open
                }
              >
                <Card
                  key={item.id}
                  sx={{
                    border: '1px solid #E6E6E6',
                    boxShadow: '0px 5px 13px -5px #0A090B0D',
                    height: '180px',
                    marginBottom: '20px',
                    marginRight: '5px',
                  }}
                >
                  <CardContent>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '16px',
                          fontWeight: 600,
                          lineHeight: '20px',
                          letterSpacing: '-0.006em',
                          textAlign: 'left',
                          color: '#252C32',
                          marginTop: '10px',
                        }}
                      >
                        {`Data collection_${item.dataFile.customer.organization_name}`}
                      </Typography>
                      <IconButton
                        aria-label="options"
                        onClick={(event) => handleMenuOpen(event, item)}
                      >
                        <MoreVertIcon style={{ color: '#0086DC' }} />
                      </IconButton>
                    </div>

                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: 700,
                        lineHeight: '20px',
                        letterSpacing: '-0.6%',
                        textAlign: 'left',
                        marginTop: '5px',
                        color: '#252C32',
                      }}
                    >
                      {item.dataFile.customer.organization_name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 500,
                        lineHeight: '16px',
                        letterSpacing: '-0.006em',
                        marginTop: '15px',
                        textAlign: 'left',
                        color: '#EA4335',
                        width: '68px',
                        height: '16px',
                        background: '#EA43351A',
                        padding: '0px 4px', // Add padding
                        borderRadius: '4px', // Add border radius
                      }}
                    >
                      Rejected
                    </Typography>

                    <div
                      style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '20px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#6E7C87',
                          }}
                        >
                          Created Date:
                        </Typography>

                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 700,
                            lineHeight: '20px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#252C32',
                            marginLeft: '8px', // Adjust spacing between components
                          }}
                        >
                          {formattedDate}
                        </Typography>
                      </div>

                      {item.dataFile.user &&
                        item.dataFile.user.firstname &&
                        item.dataFile.user.lastname && (
                          <Tooltip
                            title={`${item.dataFile.user.firstname} ${item.dataFile.user.lastname}`}
                            arrow
                          >
                            <Avatar
                              style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '12px',
                                background: '#D7EDFF',
                                color: '#0086DC',
                                fontWeight: 700,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: 'auto', // Pushes the Avatar to the right
                                fontSize: '10px', // Adjust font size of the Avatar content
                              }}
                            >
                              {item.dataFile.user.firstname.charAt(0)}
                              {item.dataFile.user.lastname.charAt(0)}
                            </Avatar>
                          </Tooltip>
                        )}
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      </div>
    </div>
  )
}
export default DataCollection
