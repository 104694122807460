import React, { useState, useEffect } from 'react'
import TextBox from '../../input/TextBox'
import axios from 'axios'
import {
  lining_side_left,
  lining_side_right,
  lining_side_spout,
  lining_side_back,
} from '../../../recoil/atoms'
import { useRecoilState } from 'recoil'
import { v4 as uuidv4 } from 'uuid'
import Typography from '@mui/material/Typography'
import { trial_name } from '../../../recoil/atoms'

const LiningSide = () => {
  const [left, setLeft] = useRecoilState(lining_side_left)
  const [right, setRight] = useRecoilState(lining_side_right)
  const [spout, setSpout] = useRecoilState(lining_side_spout)
  const [back, setBack] = useRecoilState(lining_side_back)
  const uuid = uuidv4()

  const handleChange = (name, value, setState) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const [trialName] = useRecoilState(trial_name)
  const isReadOnly = trialName === 'viewErosionProfile'
  const handleDateSelection = (value, type) => {
    console.log('typee', type)
  }

  return (
    <div style={{ width: '100%' }}>
      <div>
        <div style={{ marginTop: '10px' }}>
          <Typography
            variant="body1"
            style={{
              color: '#000000',
              fontFamily: 'Inter',
              fontWeight: 600,
              fontSize: '16px',
              lineHeight: '20px',
              marginTop: '0px',
            }}
          >
            Lining Side - Left
          </Typography>
        </div>
        <div style={{ marginTop: '15px', marginRight: '15px' }}>
          <TextBox
            name="measurement_of_4_phase"
            label="Measurement of 4 phase at diff height(inch)"
            type="text"
            value={left.measurement_of_4_phase}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setLeft)
            }
            disabled={isReadOnly}
          />
        </div>
        <div style={{ marginTop: '15px' }}>
          <TextBox
            name="black_sintered_layer_thickness"
            label="Black sintered layer thickness(inch)"
            type="text"
            value={left.black_sintered_layer_thickness}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setLeft)
            }
            disabled={isReadOnly}
          />
        </div>
        <div style={{ marginTop: '15px' }}>
          <TextBox
            name="white_hard_sintered_layer_thickness"
            label="White hard sintered layer thickness(inch)"
            value={left.white_hard_sintered_layer_thickness}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setLeft)
            }
            disabled={isReadOnly}
          />
        </div>
        <div style={{ marginTop: '15px' }}>
          <TextBox
            name="semi_centered_layer_thickness"
            label="Semi sintered layer thickness(inch)"
            value={left.semi_centered_layer_thickness}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setLeft)
            }
            disabled={isReadOnly}
          />
        </div>
        <div style={{ marginTop: '15px' }}>
          <TextBox
            name="powder_layer_thickness"
            label="Powder layer thickness(inch)"
            value={left.powder_layer_thickness}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setLeft)
            }
            disabled={isReadOnly}
          />
        </div>
        <div style={{ marginTop: '15px' }}>
          <TextBox
            name="chipping_height"
            label="Chipping Height(inch)"
            isString={true}
            value={left.chipping_height}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setLeft)
            }
            disabled={isReadOnly}
          />
        </div>
        <div style={{ marginTop: '15px' }}>
          <TextBox
            name="breaking_height"
            label="Breaking height"
            isString={true}
            value={left.breaking_height}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setLeft)
            }
            disabled={isReadOnly}
          />
        </div>
      </div>
      <div>
        <div style={{ marginTop: '10px' }}>
          <Typography
            variant="body1"
            style={{
              color: '#000000',
              fontFamily: 'Inter',
              fontWeight: 600,
              fontSize: '16px',
              lineHeight: '20px',
              marginTop: '0px',
            }}
          >
            Lining Side - Right
          </Typography>
        </div>
        <div style={{ marginTop: '15px', marginRight: '15px' }}>
          <TextBox
            name="measurement_of_4_phase"
            label="Measurement of 4 phase at diff height(inch)"
            type="text"
            value={right.measurement_of_4_phase}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setRight)
            }
            disabled={isReadOnly}
          />
        </div>
        <div style={{ marginTop: '15px' }}>
          <TextBox
            name="black_sintered_layer_thickness"
            label="Black sintered layer thickness(inch)"
            type="text"
            value={right.black_sintered_layer_thickness}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setRight)
            }
            disabled={isReadOnly}
          />
        </div>
        <div style={{ marginTop: '15px' }}>
          <TextBox
            name="white_hard_sintered_layer_thickness"
            label="White hard sintered layer thickness(inch)"
            value={right.white_hard_sintered_layer_thickness}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setRight)
            }
            disabled={isReadOnly}
          />
        </div>
        <div style={{ marginTop: '15px' }}>
          <TextBox
            name="semi_centered_layer_thickness"
            label="Semi sintered layer thickness(inch)"
            value={right.semi_centered_layer_thickness}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setRight)
            }
            disabled={isReadOnly}
          />
        </div>
        <div style={{ marginTop: '15px' }}>
          <TextBox
            name="powder_layer_thickness"
            label="Powder layer thickness(inch)"
            value={right.powder_layer_thickness}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setRight)
            }
            disabled={isReadOnly}
          />
        </div>
        <div style={{ marginTop: '15px' }}>
          <TextBox
            name="chipping_height"
            label="Chipping Height(inch)"
            isString={true}
            value={right.chipping_height}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setRight)
            }
            disabled={isReadOnly}
          />
        </div>
        <div style={{ marginTop: '15px' }}>
          <TextBox
            name="breaking_height"
            label="Chipping Height(inch)"
            isString={true}
            value={right.breaking_height}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setRight)
            }
            disabled={isReadOnly}
          />
        </div>
      </div>
      <div>
        <div style={{ marginTop: '10px' }}>
          <Typography
            variant="body1"
            style={{
              color: '#000000',
              fontFamily: 'Inter',
              fontWeight: 600,
              fontSize: '16px',
              lineHeight: '20px',
              marginTop: '0px',
            }}
          >
            Lining Side - Spout
          </Typography>
        </div>
        <div style={{ marginTop: '15px', marginRight: '15px' }}>
          <TextBox
            name="measurement_of_4_phase"
            label="Measurement of 4 phase at diff height(inch)"
            type="text"
            value={spout.measurement_of_4_phase}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setSpout)
            }
            disabled={isReadOnly}
          />
        </div>
        <div style={{ marginTop: '15px' }}>
          <TextBox
            name="black_sintered_layer_thickness"
            label="Black sintered layer thickness(inch)"
            type="text"
            value={spout.black_sintered_layer_thickness}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setSpout)
            }
            disabled={isReadOnly}
          />
        </div>
        <div style={{ marginTop: '15px' }}>
          <TextBox
            name="white_hard_sintered_layer_thickness"
            label="White hard sintered layer thickness(inch)"
            value={spout.white_hard_sintered_layer_thickness}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setSpout)
            }
            disabled={isReadOnly}
          />
        </div>
        <div style={{ marginTop: '15px' }}>
          <TextBox
            name="semi_centered_layer_thickness"
            label="Semi sintered layer thickness(inch)"
            value={spout.semi_centered_layer_thickness}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setSpout)
            }
            disabled={isReadOnly}
          />
        </div>
        <div style={{ marginTop: '15px' }}>
          <TextBox
            name="powder_layer_thickness"
            label="Powder layer thickness(inch)"
            value={spout.powder_layer_thickness}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setSpout)
            }
            disabled={isReadOnly}
          />
        </div>
        <div style={{ marginTop: '15px' }}>
          <TextBox
            name="chipping_height"
            label="Chipping Height(inch)"
            isString={true}
            value={spout.chipping_height}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setSpout)
            }
            disabled={isReadOnly}
          />
        </div>
        <div style={{ marginTop: '15px' }}>
          <TextBox
            name="breaking_height"
            label="Breaking Height"
            isString={true}
            value={spout.breaking_height}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setSpout)
            }
            disabled={isReadOnly}
          />
        </div>
      </div>
      <div>
        <div style={{ marginTop: '10px' }}>
          <Typography
            variant="body1"
            style={{
              color: '#000000',
              fontFamily: 'Inter',
              fontWeight: 600,
              fontSize: '16px',
              lineHeight: '20px',
              marginTop: '0px',
            }}
          >
            Lining Side - Back
          </Typography>
        </div>
        <div style={{ marginTop: '15px', marginRight: '15px' }}>
          <TextBox
            name="measurement_of_4_phase"
            label="Measurement of 4 phase at diff height(inch)"
            type="text"
            value={back.measurement_of_4_phase}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setBack)
            }
            disabled={isReadOnly}
          />
        </div>
        <div style={{ marginTop: '15px' }}>
          <TextBox
            name="black_sintered_layer_thickness"
            label="Black sintered layer thickness(inch)"
            type="text"
            value={back.black_sintered_layer_thickness}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setBack)
            }
            disabled={isReadOnly}
          />
        </div>
        <div style={{ marginTop: '15px' }}>
          <TextBox
            name="white_hard_sintered_layer_thickness"
            label="White hard sintered layer thickness(inch)"
            value={back.white_hard_sintered_layer_thickness}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setBack)
            }
            disabled={isReadOnly}
          />
        </div>
        <div style={{ marginTop: '15px' }}>
          <TextBox
            name="semi_centered_layer_thickness"
            label="Semi sintered layer thickness(inch)"
            value={back.semi_centered_layer_thickness}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setBack)
            }
            disabled={isReadOnly}
          />
        </div>
        <div style={{ marginTop: '15px' }}>
          <TextBox
            name="powder_layer_thickness"
            label="Powder layer thickness(inch)"
            value={back.powder_layer_thickness}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setBack)
            }
            disabled={isReadOnly}
          />
        </div>
        <div style={{ marginTop: '15px' }}>
          <TextBox
            name="chipping_height"
            label="Chipping Height(inch)"
            isString={true}
            value={back.chipping_height}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setBack)
            }
            disabled={isReadOnly}
          />
        </div>
        <div style={{ marginTop: '15px' }}>
          <TextBox
            name="breaking_height"
            label="Breaking Height"
            isString={true}
            value={back.breaking_height}
            onChange={(e) =>
              handleChange(e.target.name, e.target.value, setBack)
            }
            disabled={isReadOnly}
          />
        </div>
      </div>
    </div>
  )
}
export default LiningSide
