import React, { useState, useEffect } from 'react'
import { Box, Typography, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import API from '../services/API'

const CustomerModal = () => {
  const [customers, setCustomers] = useState([])
  const [customer, setCustomer] = useState(null) // State to store the selected customer
  const navigate = useNavigate()
  const currentUser = JSON.parse(localStorage.getItem('user'))
  const access_token = localStorage.getItem('accesstoken')

  useEffect(() => {
    fetchCustomers()
  }, [])

  const fetchCustomers = async () => {
    try {
      const response = await axios.get(API.GET_CUSTOMER, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      const customerResponse = response.data

      if (currentUser.role.role_id === 1) {
        const customerId = currentUser.assign_customer_id[0]
        const customers = customerResponse.filter(
          (item) => item.id === customerId
        )
        setCustomers(customers)
      } else if (
        currentUser.role.role_id === 2 ||
        currentUser.role.role_id === 3
      ) {
        const assignCustomerIds = currentUser.assign_customer_id.map((id) => id)
        const filteredCustomers = customerResponse.filter((customer) =>
          assignCustomerIds.includes(customer.id)
        )
        setCustomers(filteredCustomers)
      } else {
        setCustomers(customerResponse)
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const handleCustomerChange = (cust) => {
    setCustomer(cust)
  }

  const handleGetStarted = () => {
    if (customer) {
      localStorage.setItem('selectedCustomer', JSON.stringify(customer))
      navigate('/datamanagement')
    } else {
      alert('Please select a customer first!')
    }
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        backdropFilter: 'blur(10px)',
        zIndex: 9999,
      }}
    >
      <Box
        sx={{
          width: { xs: '90%', sm: '80%', md: '600px' },
          height: 'auto', // Set height to auto to adjust to content
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: { xs: 2, sm: 3, md: 4 },
          borderRadius: '8px',
          boxShadow: 3,
          backgroundColor: 'white',
        }}
      >
        <Typography variant="h5" align="center" gutterBottom>
          Hi {currentUser.firstname} {currentUser.lastname}, Please select a
          customer to get started!
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            mt: 2,
            gap: 2,
          }}
        >
          {customers.map((cust, index) => (
            <Button
              key={index}
              variant="contained"
              sx={{
                borderRadius: '20px',
                padding: { xs: '8px 16px', sm: '10px 20px' },
                backgroundColor: customer === cust ? '#0086DC' : 'white',
                color: customer === cust ? 'white' : '#0086DC',
                border: `1px solid ${customer === cust ? '#0086DC' : '#0086DC'}`,
                '&:hover': {
                  backgroundColor: customer === cust ? 'darkblue' : 'lightgray',
                },
              }}
              onClick={() => handleCustomerChange(cust)}
            >
              {cust.organization_name}
            </Button>
          ))}
        </Box>

        <Button
          sx={{
            marginTop: 2,
            padding: { xs: '8px 16px', sm: '10px 20px' },
            backgroundColor: '#0086DC',
            border: '1px solid #0086DC',
            color: 'white',
            '&:hover': {
              backgroundColor: 'darkblue',
            },
          }}
          onClick={handleGetStarted}
        >
          Get Started
        </Button>
      </Box>
    </Box>
  )
}

export default CustomerModal
