import React from 'react'
import Typography from '@mui/material/Typography'
import { IconButton } from '@mui/material'
import team from '../../assets/icons/ic_teams_logo.svg'

const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh', // 100% of the viewport height
}

const buttonStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '200px', // Adjust the width as needed
  height: '150px', // Adjust the height as needed
  backgroundColor: '#ffffff', // Change the background color
  color: 'black', // Change the text color
  border: '2px solid #2980b9', // Change the border color
  borderRadius: '8px',
  cursor: 'pointer',
  transition: 'background-color 0.3s',
}

const iconStyle = {
  marginBottom: '8px',
}

const textStyles = {
  fontSize: '12px',
  textAlign: 'center',
}

const text = {
  fontSize: '10px',
  textAlign: 'center',
}

const boldTextStyle = {
  fontWeight: 'bold',
}
const HelpSupport = () => {
  const handleButtonClick = () => {
    // Replace 'your-teams-channel-url' with the actual URL of your Teams channel
    const teamsChannelUrl =
      'https://teams.microsoft.com/l/team/19%3AkoL3NS7rD4Z3PcblN4k4_NFirtW-e4zXA3JxgAq7y-01%40thread.tacv2/conversations?groupId=5feda3ef-8f99-4207-b71e-301e079eadfa&tenantId=e339bd4b-2e3b-4035-a452-2112d502f2ff'
    const newWindow = window.open('', '_blank')
    newWindow.opener = null // Set the opener to null for enhanced security
    newWindow.location.href = teamsChannelUrl
  }
  return (
    <div style={containerStyle}>
      {/* Change the div to a button */}
      <button
        type="button"
        style={buttonStyle}
        onClick={handleButtonClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleButtonClick()
          }
        }}
      >
        <div style={iconStyle}>
          <IconButton aria-label="Team">
            <img
              src={team}
              alt="Teams"
              style={{ width: '60px', height: '60px' }}
            />
          </IconButton>
        </div>
        <div style={textStyles}>
          <span style={boldTextStyle}>
            Talk to our <br /> support engineer
          </span>
        </div>
        <div style={text}>
          Let us know your difficulties with this application. We are happy to
          help.
        </div>
      </button>
    </div>
  )
}

export default HelpSupport
