import React, { Suspense, useState } from 'react'
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom'
import {
  createTheme,
  CssBaseline,
  LinearProgress,
  ThemeProvider,
} from '@mui/material'
import Login from './pages/login/Login'
import routes from './routes'
import './App.css'
import PCRTemplate from './layouts/PCRTemplate'
import { AuthContext } from './Context'
import RouteMissing from './RouteMissing'

export const switchRoutes = (
  <React.Fragment>
    {routes.mainRoutes.map((route, index) => {
      return route.component ? (
        <Route
          path={route.path}
          name={route.name}
          element={<route.component />}
          key={route.name}
        />
      ) : (
        <React.Fragment key={route.name}></React.Fragment>
      )
    })}

    <Route path="/" element={<Navigate replace to="/login" />} />
  </React.Fragment>
)

const PrivateOutlet = () => {
  const currentUser = localStorage.getItem('user')
  const user = JSON.parse(JSON.stringify(currentUser))
  return user ? <PCRTemplate /> : <Navigate to="/login" />
}

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: `'Nunito Sans'`,
    },
  })

  const currentUser = localStorage.getItem('user')
  const user = JSON.parse(JSON.stringify(currentUser))
  const [userId, _setUserId] = useState(user)

  return (
    <ThemeProvider theme={theme}>
      <AuthContext.Provider value={{ userId, setAuthTokens: _setUserId }}>
        <CssBaseline />
        <BrowserRouter>
          <Suspense fallback={<LinearProgress />}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<RouteMissing />} />
              <Route path="/" element={<PrivateOutlet />}>
                {switchRoutes}
              </Route>
            </Routes>
          </Suspense>
        </BrowserRouter>
      </AuthContext.Provider>
    </ThemeProvider>
  )
}

export default App
