import React, { useState, useEffect } from 'react'
import TextBox from '../../input/TextBox'
import AutoSelectBox from '../../input/AutoSelectBox'
import Typography from '@mui/material/Typography'
import Helper from '../../services/Helper'
import {
  new_lining_details,
  new_lining_details_nrm,
} from '../../../recoil/atoms'
import { useRecoilState } from 'recoil'
import { trial_name } from '../../../recoil/atoms'
export default function NewLiningDetails() {
  const [newLining, setNewLining] = useRecoilState(new_lining_details)
  const [newLiningNRM, setNewLiningNRM] = useRecoilState(new_lining_details_nrm)
  const [trialName] = useRecoilState(trial_name)
  const isReadOnly = trialName === 'viewCostSheet'

  const handleNewLiningChange = (name, value) => {
    const regex = /^[0-9]*\.?[0-9]{0,2}$/
    if (regex.test(value) || value === '') {
      setNewLining((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const handleNewLiningNRMChange = (name, value) => {
    const regex = /^[0-9]*\.?[0-9]{0,2}$/
    if (regex.test(value) || value === '') {
      setNewLiningNRM((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }
  useEffect(() => {
    let newLiningObj = {
      newLiningDetails: {
        basic: newLining,
        nrm: newLiningNRM,
      },
    }
  }, [newLining, newLiningNRM])

  useEffect(() => {
    localStorage.setItem('newLining', JSON.stringify(newLining))
    localStorage.setItem('newLiningNRM', JSON.stringify(newLiningNRM))
  }, [newLining, newLiningNRM])

  const custDet = JSON.parse(localStorage.getItem('customerDetails'))

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body1"
          style={{
            color: '#000000',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '20px',
            marginTop: '0px',
          }}
        >
          {custDet.parameter}
        </Typography>
      </div>

      <AutoSelectBox
        labelValue="Ramming Material used in new lining - 1"
        name="Select_country"
        isLabel={true}
        options={Helper.CostSheet}
        value={newLining.ramming_material_new_lining_1}
        onChange={(event) =>
          handleNewLiningChange(
            'ramming_material_new_lining_1',
            event.target.value
          )
        }
        disabled={isReadOnly}
      />

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="qty_new_lining_1"
          label="Quality of Material required for new lining - 1"
          type="text"
          value={newLining.qty_new_lining_1}
          onChange={(event) =>
            handleNewLiningChange('qty_new_lining_1', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginBottom: '15px' }}>
        <AutoSelectBox
          labelValue="Material used in Spout and Top - 2"
          name="Select_country"
          isLabel={true}
          options={Helper.CostSheet}
          value={newLining.material_used_spout_and_top_2}
          onChange={(event) =>
            handleNewLiningChange(
              'material_used_spout_and_top_2',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="qty_spout_and_top_2"
          label="Quality of Material required for Spout & Top -2"
          type="text"
          value={newLining.qty_spout_and_top_2}
          onChange={(event) =>
            handleNewLiningChange('qty_spout_and_top_2', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginBottom: '15px' }}>
        <AutoSelectBox
          labelValue="Material used in spout and top -3"
          name="Select_country"
          isLabel={true}
          options={Helper.CostSheet}
          value={newLining.material_used_spout_and_top_3}
          onChange={(event) =>
            handleNewLiningChange(
              'material_used_spout_and_top_3',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="qty_spout_and_top_3"
          label="Qty. of Material required for Spout & Top -3"
          type="text"
          value={newLining.qty_spout_and_top_3}
          onChange={(event) =>
            handleNewLiningChange('qty_spout_and_top_3', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="average_new_lining_life"
          label="Average New Lining Life"
          type="text"
          value={newLining.average_new_lining_life}
          onChange={(event) =>
            handleNewLiningChange('average_new_lining_life', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="no_of_new_lining"
          label="No of New Lining"
          type="text"
          value={newLining.no_of_new_lining}
          onChange={(event) =>
            handleNewLiningChange('no_of_new_lining', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="avg_heat_size"
          label="Average Heat Size Per New Lining"
          type="text"
          value={newLining.avg_heat_size}
          onChange={(event) =>
            handleNewLiningChange('avg_heat_size', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body1"
          style={{
            color: '#000000',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '20px',
            marginTop: '0px',
          }}
        >
          NRM
        </Typography>
      </div>

      <div style={{ marginBottom: '15px' }}>
        <AutoSelectBox
          labelValue="Ramming Material used in new lining - 1"
          name="Select_country"
          isLabel={true}
          options={Helper.CostSheet}
          value={newLiningNRM.ramming_material_new_lining_1}
          onChange={(event) =>
            handleNewLiningNRMChange(
              'ramming_material_new_lining_1',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="qty_new_lining_1_nrm"
          label="Quality of Material required for new lining - 1"
          type="text"
          value={newLiningNRM.qty_new_lining_1}
          onChange={(event) =>
            handleNewLiningNRMChange('qty_new_lining_1', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginBottom: '15px' }}>
        <AutoSelectBox
          labelValue="Material used in Spout and Top - 2"
          name="Select_country"
          isLabel={true}
          options={Helper.CostSheet}
          value={newLiningNRM.material_used_spout_and_top_2}
          onChange={(event) =>
            handleNewLiningNRMChange(
              'material_used_spout_and_top_2',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="qty_spout_and_top_2_nrm"
          label="Quality of Material required for Spout & Top -2"
          type="text"
          value={newLiningNRM.qty_spout_and_top_2}
          onChange={(event) =>
            handleNewLiningNRMChange('qty_spout_and_top_2', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginBottom: '15px' }}>
        <AutoSelectBox
          labelValue="Material used in spout and top - 3"
          name="Select_country"
          isLabel={true}
          options={Helper.CostSheet}
          value={newLiningNRM.material_used_spout_and_top_3}
          onChange={(event) =>
            handleNewLiningNRMChange(
              'material_used_spout_and_top_3',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="qty_spout_and_top_3_nrm"
          label="Qty. of Material required for Spout & Top - 3"
          type="text"
          value={newLiningNRM.qty_spout_and_top_3}
          onChange={(event) =>
            handleNewLiningNRMChange('qty_spout_and_top_3', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="average_new_lining_life_nrm"
          label="Average New Lining Life"
          type="text"
          value={newLiningNRM.average_new_lining_life}
          onChange={(event) =>
            handleNewLiningNRMChange(
              'average_new_lining_life',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="no_of_new_lining_nrm"
          label="No of New Lining"
          type="text"
          value={newLiningNRM.no_of_new_lining}
          onChange={(event) =>
            handleNewLiningNRMChange('no_of_new_lining', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="avg_heat_size_nrm"
          label="Average Heat Size Per New Lining"
          type="text"
          value={newLiningNRM.avg_heat_size}
          onChange={(event) =>
            handleNewLiningNRMChange('avg_heat_size', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
    </div>
  )
}
