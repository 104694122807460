import React from 'react'
import TextBox from '../../input/TextBox'
import SelectBox from '../../input/SelectBox'
import { alloying_addition, general_details } from '../../../recoil/atoms'
import { useRecoilState } from 'recoil'

const AlloyingAddition = () => {
  const [alloyingAddition, setAlloyingAddition] =
    useRecoilState(alloying_addition)
  const [generalDetails] = useRecoilState(general_details)
  const uniqueId = generalDetails.id ? generalDetails.id : ''

  const handleChange = (e) => {
    const { name, value } = e.target
    const TrialName = localStorage.getItem('Trial')
    const regex = /^\d{0,5}(\.\d{0,2})?$/
    if (regex.test(value)) {
      if (TrialName === 'new' || TrialName === 'add') {
        setAlloyingAddition((prevState) => ({
          ...prevState,
          [name]: value,
          id: uniqueId,
        }))
      } else {
        setAlloyingAddition((prevState) => ({
          ...prevState,
          [name]: value,
        }))
      }
    }
  }

  return (
    <div>
      <div style={{ flex: 1, marginRight: '15px', marginTop: '15px' }}>
        <TextBox
          name="si_before_alloy_addition"
          label="Si% before alloy addition (%)"
          type="text"
          value={alloyingAddition?.si_before_alloy_addition}
          onChange={handleChange}
        />
      </div>
      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="final_si"
          label="Si% after alloy additon (%)"
          type="text"
          value={alloyingAddition?.final_si}
          onChange={handleChange}
          //   error={formErrors.coil_grout}
        />
      </div>
      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="mn_before_alloy_addition"
          label="Mn% before alloy addition (%)"
          type="text"
          value={alloyingAddition?.mn_before_alloy_addition}
          onChange={handleChange}
          //   error={formErrors.coil_grout}
        />
      </div>
      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="final_mn"
          label="Mn% after alloy additon (%)"
          type="text"
          value={alloyingAddition?.final_mn}
          onChange={handleChange}
          //   error={formErrors.coil_grout}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="cr_before_alloy_addition"
          label="Cr% before alloy addition (%)"
          type="text"
          value={alloyingAddition?.cr_before_alloy_addition}
          onChange={handleChange}
          //   error={formErrors.coil_grout}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="final_cr"
          label="Cr% after alloy addition (%)"
          type="text"
          value={alloyingAddition?.final_cr}
          onChange={handleChange}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="si_mn"
          label="SiMn (kg)"
          type="text"
          value={alloyingAddition?.si_mn}
          onChange={handleChange}
          //   error={formErrors.coil_grout}
        />
      </div>
      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="mn_in_simn"
          label="Mn % in SiMn (%)"
          type="text"
          value={alloyingAddition?.mn_in_simn}
          onChange={handleChange}
          //   error={formErrors.coil_grout}
        />
      </div>
      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="feMn"
          label="FeMn (kg)"
          type="text"
          value={alloyingAddition?.feMn}
          onChange={handleChange}
          //   error={formErrors.coil_grout}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="feSi"
          label="FeSi (kg)"
          type="text"
          value={alloyingAddition?.feSi}
          onChange={handleChange}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="feCr"
          label="FeCr (kg)"
          type="text"
          value={alloyingAddition?.feCr}
          onChange={handleChange}
          //   error={formErrors.coil_grout}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="mill_scale"
          label="Mill scale (kg)"
          type="text"
          value={alloyingAddition?.mill_scale}
          onChange={handleChange}
          //   error={formErrors.coil_grout}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="any_other_alloy_addition"
          label="Any other Alloy addition"
          type="text"
          value={alloyingAddition?.any_other_alloy_addition}
          onChange={handleChange}
          //   error={formErrors.coil_grout}
        />
      </div>
    </div>
  )
}

export default AlloyingAddition
