import React, { useState, useEffect, useRef } from 'react'
import { Typography, IconButton, Avatar } from '@mui/material'
import axios from 'axios'
import API from '../../services/API'
import { useNavigate } from 'react-router-dom'
import { Card, CardContent } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EditIcon from '../../../assets/icons/ic_edit.svg'
import DeleteIcon from '../../../assets/icons/ic_delete.svg'
import ViewIcon from '../../../assets/icons/ic_view.png'
import { trial_name } from '../../../recoil/atoms'
import { useRecoilState } from 'recoil'
import AlertDialog from '../../input/AlertDialog'
import * as XLSX from 'xlsx'
import FilterIcon from '../../../assets/icons/ic_home_filter.svg'
import Erosionfilter from './ErosionFilter'
import Tooltip from '@mui/material/Tooltip'
import WifiOffIcon from '@mui/icons-material/WifiOff'
import CircularProgress from '@mui/material/CircularProgress'

const ErosionProfileHome = () => {
  const navigate = useNavigate()
  const [filteredTrials, setFilteredTrials] = useState([])
  const [formData, setFormData] = useState({
    segment_id: '',
    customer_id: '',
    template_id: '',
    furnace_id: '',
  })
  const [isFilterOpen, setIsFilterOpen] = useState(false)

  const [allErosionProfile, setAllErosionProfile] = useState([])
  const currentUser = JSON.parse(localStorage.getItem('user'))
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [popupPosition, setPopupPosition] = useState({ top: 0, right: 0 })
  const [trialName, setTrialName] = useRecoilState(trial_name)
  console.log(trialName, 'trialName')
  const [popupDataFileId, setPopupDatafileId] = useState('')
  const [selectedCardDataId, setSelectedCardDataId] = useState(null)
  const [selectedSubmissionStatus, setSelectedSubmissionStatus] = useState('')
  const [isOnline, setIsOnline] = useState(window.navigator.onLine)
  const [OnlineTest, setOnlineTest] = useState()
  const [loading, setLoading] = useState(false)
  const [offlineInspectionlength, setofflineInspectionlength] = useState(
    localStorage.getItem('lenofflineerosiondata') || '0'
  )
  const [db, setDb] = useState(null)
  const access_token = localStorage.getItem('accesstoken')
  const [error, setError] = useState(null) // Error state
  const popupRef = useRef(null)

  const handleFilterClick = () => {
    setIsFilterOpen(true)
  }
  useEffect(() => {
    indexedDB.databases().then((databases) => {
      const numberOfDatabases = databases.length

      databases.forEach((db) => {})
      if (databases.find((option) => option.name === 'pcr') === undefined) {
        localStorage.setItem('lenofflineerosiondata', 0)
        setofflineInspectionlength(0)
      }
    })
  }, [])
  useEffect(() => {
    const handleOnline = () => {
      if (!isOnline) {
        setIsOnline(true)
        localStorage.setItem('onlinestate', 1)

        window.location.reload()
      }
    }

    const handleOffline = () => {
      localStorage.setItem('onlinestate', 0)
      // setIsOnlines(false)
      setIsOnline(false)
      // navigate('/reports')
    }

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [isOnline])

  useEffect(() => {
    const onlinecheck = localStorage.getItem('onlinestate')

    setOnlineTest(onlinecheck)
  }, [])

  const dbName = 'pcr'
  const customerName = 'customers'
  const furnacesName = 'furnaces'
  const templateName = 'template'
  const segmentName = 'segment'
  const datafileName = 'datafile'
  const dailytrialName = 'daily_trial'
  const nrmName = 'nrm_performance'
  const erosionName = 'erosion_profile'
  const dataCollectionName = 'data_collection'
  const costSheetName = 'cost_sheet'
  const LiningName = 'lining_patching_no'

  useEffect(() => {
    if (!isOnline) {
      const request = indexedDB.open(dbName, 1)

      request.onupgradeneeded = (event) => {
        const database = event.target.result
        database.createObjectStore(customerName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(furnacesName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(templateName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(segmentName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(datafileName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(dailytrialName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(nrmName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(erosionName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(dataCollectionName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(costSheetName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(LiningName, {
          keyPath: 'id',
          autoIncrement: true,
        })
      }

      request.onsuccess = (event) => {
        const database = event.target.result
        const transaction = database.transaction(
          [
            'customers',
            'furnaces',
            'template',
            'segment',
            'datafile',
            'daily_trial',
            'nrm_performance',
            'erosion_profile',
            'data_collection',
            'cost_sheet',
            'lining_patching_no',
          ],
          'readwrite'
        )

        setDb(database)
      }

      request.onerror = (event) => {
        console.error('Error opening IndexedDB', event)
      }
    }
  }, [isOnline])

  useEffect(() => {
    if (!isOnline && db) {
      const transaction = db.transaction(
        [
          'erosion_profile',
          'datafile',
          'template',
          'segment',
          'furnaces',
          'customers',
        ],
        'readonly'
      )
      const daily_trialStore = transaction.objectStore('erosion_profile')
      const datafileStore = transaction.objectStore('datafile')
      const templateStore = transaction.objectStore('template')
      const segmentStore = transaction.objectStore('segment')
      const furnacesStore = transaction.objectStore('furnaces')
      const customersStore = transaction.objectStore('customers')

      const daily_trialdata = []
      const datafileData = []
      const templateData = []
      const segmentData = []
      const furnacesData = []
      const customersData = []

      const daily_trialCursorRequest = daily_trialStore.openCursor()
      daily_trialCursorRequest.onsuccess = function (event) {
        const cursor = event.target.result

        if (cursor) {
          daily_trialdata.push(cursor.value)
          cursor.continue()
        } else {
          const datafileCursorRequest = datafileStore.openCursor()
          datafileCursorRequest.onsuccess = function (event) {
            const cursor = event.target.result

            if (cursor) {
              datafileData.push(cursor.value)
              cursor.continue()
            } else {
              const templateCursorRequest = templateStore.openCursor()
              templateCursorRequest.onsuccess = function (event) {
                const cursor = event.target.result

                if (cursor) {
                  templateData.push(cursor.value)
                  cursor.continue()
                } else {
                  const segmentCursorRequest = segmentStore.openCursor()
                  segmentCursorRequest.onsuccess = function (event) {
                    const cursor = event.target.result
                    if (cursor) {
                      segmentData.push(cursor.value)
                      cursor.continue()
                    } else {
                      const furnacesCursorRequest = furnacesStore.openCursor()
                      furnacesCursorRequest.onsuccess = function (event) {
                        const cursor = event.target.result

                        if (cursor) {
                          furnacesData.push(cursor.value)
                          cursor.continue()
                        } else {
                          const customersCursorRequest =
                            customersStore.openCursor()
                          customersCursorRequest.onsuccess = function (event) {
                            const cursor = event.target.result
                            if (cursor) {
                              customersData.push(cursor.value)
                              cursor.continue()
                            } else {
                              // Perform join operations based on specified conditions

                              const joinedData = daily_trialdata.map(
                                (daily_trialItem, index) => {
                                  console.log(
                                    'daily_trialItem.datafile_id:',
                                    daily_trialItem.datafile_id
                                  )
                                  const matchingDatafile = datafileData.find(
                                    (datafileItem) =>
                                      datafileItem.id ===
                                      parseInt(daily_trialItem.datafile_id, 10)
                                  )
                                  const matchingTemplate = templateData.find(
                                    (templateItem) =>
                                      templateItem.id ===
                                      datafileData[index].template_id
                                  )
                                  const matchingSegment = segmentData.find(
                                    (segmentItem) =>
                                      segmentItem.id ===
                                      datafileData[index].segment_id
                                  )
                                  const matchingFurnace = furnacesData.find(
                                    (furnaceItem) =>
                                      furnaceItem.id ===
                                      datafileData[index].furnace_id
                                  )
                                  const matchingCustomer = customersData.find(
                                    (customerItem) =>
                                      customerItem.id ===
                                      datafileData[index].customer_id
                                  )

                                  return {
                                    ...daily_trialItem,
                                    datafile: matchingDatafile,
                                    template: matchingTemplate,
                                    segment: matchingSegment,
                                    furnace: matchingFurnace,
                                    customer: matchingCustomer,
                                  }
                                }
                              )
                              // setJoined(joinedData)
                              console.log('Joined data:', joinedData)
                              localStorage.setItem(
                                'lenofflineerosiondata',
                                joinedData.length
                              )
                              setAllErosionProfile(joinedData)
                              console.log('daily_trialdata:', daily_trialdata)
                              console.log('datafileData:', datafileData)
                              console.log('templateData:', templateData)
                              console.log('segmentData:', segmentData)
                              console.log('furnacesData:', furnacesData)
                              console.log('customersData:', customersData)
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          transaction.onerror = function (event) {
            console.error('Error in transaction:', event.target.error)
          }
        }
      }
    }
  }, [db])

  const Indexeddbdatas = async () => {
    indexedDB.databases().then((databases) => {
      const numberOfDatabases = databases.length
      const pcrDatabase = databases.find((db) => db.name === 'pcr')
      databases.forEach((db) => {})
      if (numberOfDatabases > 0 && pcrDatabase) {
        const request = indexedDB.open('pcr')
        request.onsuccess = async function (event) {
          const db = event.target.result
          const transaction = db.transaction(
            [
              'erosion_profile',
              'datafile',
              'template',
              'segment',
              'furnaces',
              'customers',
            ],
            'readonly'
          )
          const daily_trialStore = transaction.objectStore('erosion_profile')
          const datafileStore = transaction.objectStore('datafile')
          const templateStore = transaction.objectStore('template')
          const segmentStore = transaction.objectStore('segment')
          const furnacesStore = transaction.objectStore('furnaces')
          const customersStore = transaction.objectStore('customers')

          const daily_trialdata = []
          const datafileData = []
          const templateData = []
          const segmentData = []
          const furnacesData = []
          const customersData = []

          const daily_trialCursorRequest = daily_trialStore.openCursor()
          daily_trialCursorRequest.onsuccess = async function (event) {
            const cursor = event.target.result

            if (cursor) {
              daily_trialdata.push(cursor.value)
              cursor.continue()
            } else {
              const datafileCursorRequest = datafileStore.openCursor()
              datafileCursorRequest.onsuccess = async function (event) {
                const cursor = event.target.result

                if (cursor) {
                  datafileData.push(cursor.value)
                  cursor.continue()
                } else {
                  const templateCursorRequest = templateStore.openCursor()
                  templateCursorRequest.onsuccess = async function (event) {
                    const cursor = event.target.result

                    if (cursor) {
                      templateData.push(cursor.value)
                      cursor.continue()
                    } else {
                      const segmentCursorRequest = segmentStore.openCursor()
                      segmentCursorRequest.onsuccess = async function (event) {
                        const cursor = event.target.result
                        if (cursor) {
                          segmentData.push(cursor.value)
                          cursor.continue()
                        } else {
                          const furnacesCursorRequest =
                            furnacesStore.openCursor()
                          furnacesCursorRequest.onsuccess = async function (
                            event
                          ) {
                            const cursor = event.target.result

                            if (cursor) {
                              furnacesData.push(cursor.value)
                              cursor.continue()
                            } else {
                              const customersCursorRequest =
                                customersStore.openCursor()
                              customersCursorRequest.onsuccess =
                                async function (event) {
                                  const cursor = event.target.result
                                  if (cursor) {
                                    customersData.push(cursor.value)
                                    cursor.continue()
                                  } else {
                                    // Perform join operations based on specified conditions

                                    const joinedData = daily_trialdata.map(
                                      (daily_trialItem, index) => {
                                        console.log(
                                          'daily_trialItem.datafile_id:',
                                          daily_trialItem.datafile_id
                                        )
                                        const matchingDatafile =
                                          datafileData.find(
                                            (datafileItem) =>
                                              datafileItem.id ===
                                              parseInt(
                                                daily_trialItem.datafile_id,
                                                10
                                              )
                                          )
                                        const matchingTemplate =
                                          templateData.find(
                                            (templateItem) =>
                                              templateItem.id ===
                                              datafileData[index].template_id
                                          )
                                        const matchingSegment =
                                          segmentData.find(
                                            (segmentItem) =>
                                              segmentItem.id ===
                                              datafileData[index].segment_id
                                          )
                                        const matchingFurnace =
                                          furnacesData.find(
                                            (furnaceItem) =>
                                              furnaceItem.id ===
                                              datafileData[index].furnace_id
                                          )
                                        const matchingCustomer =
                                          customersData.find(
                                            (customerItem) =>
                                              customerItem.id ===
                                              datafileData[index].customer_id
                                          )

                                        return {
                                          ...daily_trialItem,
                                          datafile: matchingDatafile,
                                          template: matchingTemplate,
                                          segment: matchingSegment,
                                          furnace: matchingFurnace,
                                          customer: matchingCustomer,
                                        }
                                      }
                                    )
                                    // setJoined(joinedData)
                                    console.log('Joined data:', joinedData)
                                    localStorage.setItem(
                                      'lenofflineerosiondata',
                                      joinedData.length
                                    )
                                    // setofflineInspectionlength(joinedData.length)
                                    if (joinedData.length > 0) {
                                      const imagesData =
                                        await uploadBlobImagesAPI(
                                          joinedData[0],
                                          0,
                                          joinedData.length
                                        )
                                    }
                                    if (joinedData.length === 0) {
                                      setLoading(false)
                                    }
                                    // if(joinedData.length===0){
                                    //   const arrayTrialId = JSON.parse(localStorage.getItem('datafile_dailytrial_id'));
                                    //   if(arrayTrialId!==null&& arrayTrialId.length>0){
                                    //     const response5 = await axios.post(API.GET_ARRAY_DAILY_TRIAL, arrayTrialId);
                                    //     // Do something with response5 if needed

                                    //     const uniqueEntries = new Map();
                                    //     const filteredData = [];
                                    //     const remainingData = [];

                                    //     response5.data.forEach(item => {
                                    //       const key = `${item.alloying_addition}-${item.carbon}-${item.charge_mix_details}-${item.dataFile.user_id}-${item.dataFile.segment_id}-${item.dataFile.furnace_id}-${item.furnace_details}-${item.general_details}-${item.other_calculated_varialble}`;
                                    //       if (!uniqueEntries.has(key)) {
                                    //         uniqueEntries.set(key, true);
                                    //         filteredData.push(item);
                                    //       } else {
                                    //         remainingData.push(item);
                                    //       }
                                    //     });

                                    //     const trialIds = remainingData.map(item => item.datafile_id);
                                    //     const response6 = await axios.post(API.DELETE_ARRAY_DAILY_TRIAL, trialIds);
                                    //     // Do something with response5 if needed
                                    //     if(response6.status===200){
                                    //       localStorage.setItem('datafile_dailytrial_id',JSON.stringify([]))
                                    //     }

                                    //   }
                                    // }
                                  }
                                }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              transaction.onerror = function (event) {
                console.error('Error in transaction:', event.target.error)
              }
            }
          }
        }
        request.onerror = function (event) {
          console.error('Error opening database:', event.target.error)
        }
      }
    })
  }

  const MAX_RETRIES = 10 // Set maximum retry attempts

  const fetchWithRetry = async (url, options, retries = 0) => {
    try {
      const response = await axios.post(url, options)
      if (response.status === 200) {
        return response
      }
    } catch (error) {
      if (retries < MAX_RETRIES && error.response.status !== 200) {
        await new Promise((resolve) => setTimeout(resolve, 1000)) // Adjust delay as needed
        return fetchWithRetry(url, options, retries + 1)
      } else {
        throw error // Re-throw error if exceeding retries or status code is not the issue
      }
    }
  }

  const fetchWithRetryGet = async (url, retries = 0) => {
    try {
      const response = await axios.get(url)
      if (response.status === 200) {
        return response
      }
    } catch (error) {
      if (retries < MAX_RETRIES && error.response.status !== 200) {
        await new Promise((resolve) => setTimeout(resolve, 1000)) // Adjust delay as needed
        return fetchWithRetryGet(url, retries + 1)
      } else {
        throw error // Re-throw error if exceeding retries or status code is not the issue
      }
    }
  }

  const uploadBlobImagesAPI = async (data, index, length) => {
    try {
      const dailytrialdata = {
        submission_status: data.submission_status,
        data: {
          details: data.details,
          dimensional_details: data.dimensional_details,
          nrm_usage_details: data.nrm_usage_details,
          before_chipping: data.before_chipping,
          after_chipping: data.after_chipping,
          lining_side: data.lining_side,
        },
      }
      const newTrail = {
        customer_id: data.datafile.customer_id,
        template_id: data.datafile.template_id,
        user_id: data.datafile.user_id,
        segment_id: data.datafile.segment_id,
        furnace_id: data.datafile.furnace_id,
      }
      const response6 = await fetchWithRetry(
        API.UPLOAD_DATAFILE_MOBILE,
        newTrail,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      if (response6.status === 200) {
        console.log('response.iddd', response6.data)
        console.log('response.iddd1', response6.data.data.id)
        //           const arrayTrialId=JSON.parse(localStorage.getItem('datafile_dailytrial_id'))
        // if(arrayTrialId===null){
        //   let trialIdvalues=[]
        //   trialIdvalues.push(response6.data.data.id)
        //   localStorage.setItem('datafile_dailytrial_id',JSON.stringify(trialIdvalues))
        // }
        // if (arrayTrialId!==null){
        //   let trialIdvalues=arrayTrialId
        //   trialIdvalues.push(response6.data.data.id)
        //   localStorage.setItem('datafile_dailytrial_id',JSON.stringify(trialIdvalues))
        // }
        dailytrialdata.datafile_id = response6.data.data.id

        indexedDB.databases().then((databases) => {
          const numberOfDatabases = databases.length

          databases.forEach((db) => {})
          if (numberOfDatabases > 0) {
            const request = indexedDB.open('pcr')
            request.onsuccess = function (event) {
              const db = event.target.result
              const transactiontrial = db.transaction(datafileName, 'readwrite')
              const objectStoretrial =
                transactiontrial.objectStore(datafileName)

              const deleterequesttrial = objectStoretrial.delete(data.id)
              deleterequesttrial.onsuccess = () => {}

              deleterequesttrial.onerror = () => {
                console.error('Error updating record in IndexedDB')
              }
              const transactiontrialdetail = db.transaction(
                erosionName,
                'readwrite'
              )
              const objectStoretrialdetail =
                transactiontrialdetail.objectStore(erosionName)

              const deleterequest = objectStoretrialdetail.delete(data.id)

              deleterequest.onsuccess = async () => {}

              deleterequest.onerror = () => {
                console.error('Error updating record in IndexedDB')
              }
            }
            request.onerror = function (event) {
              console.error('Error opening database:', event.target.error)
            }
          }
        })
        const response7 = await fetchWithRetry(
          API.INSERT_EROSION_PROFILE,
          dailytrialdata,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        // const arrayTrialDetailsId=JSON.parse(localStorage.getItem('trialdetailsidarray'))
        // let trialdetailsIdvalues=arrayTrialDetailsId
        //     trialdetailsIdvalues.push(response7.data.identifiers[0].id)
        //     localStorage.setItem('trialdetailsidarray',JSON.stringify(trialdetailsIdvalues))
        //   localStorage.setItem("trialsyncid",response7.data.identifiers[0].id)

        if (response7.status === 200) {
          // localStorage.setItem("trialsyncid",response7.data.identifiers[0].id)
          setofflineInspectionlength(length)

          await Indexeddbdatas()
          // handleOpenAlert(`Inspection ${index+1} created Successfully`);

          //      if(length-1===index){
          //       setTimeout(() => {
          //         setLoading(false)
          //         localStorage.setItem("lenofflinedata",0)
          //         window.location.reload();
          //       }, 2500);
          // }
        }
      }

      // Handle the response and update your UI or state as needed
    } catch (error) {
      console.error('Error uploading images:', error)
      // Handle error, update UI, etc.
    }
  }

  useEffect(() => {
    if (OnlineTest === '1') {
      // window.location.reload()

      setTimeout(async () => {
        indexedDB.databases().then((databases) => {
          const numberOfDatabases = databases.length
          const pcrDatabase = databases.find((db) => db.name === 'pcr')
          databases.forEach((db) => {})
          if (numberOfDatabases > 0 && pcrDatabase) {
            const request = indexedDB.open('pcr')
            request.onsuccess = async function (event) {
              setLoading(true)
              const db = event.target.result
              const transaction = db.transaction(
                [
                  'erosion_profile',
                  'datafile',
                  'template',
                  'segment',
                  'furnaces',
                  'customers',
                ],
                'readonly'
              )
              const daily_trialStore =
                transaction.objectStore('erosion_profile')
              const datafileStore = transaction.objectStore('datafile')
              const templateStore = transaction.objectStore('template')
              const segmentStore = transaction.objectStore('segment')
              const furnacesStore = transaction.objectStore('furnaces')
              const customersStore = transaction.objectStore('customers')

              const daily_trialdata = []
              const datafileData = []
              const templateData = []
              const segmentData = []
              const furnacesData = []
              const customersData = []

              const daily_trialCursorRequest = daily_trialStore.openCursor()
              daily_trialCursorRequest.onsuccess = async function (event) {
                const cursor = event.target.result

                if (cursor) {
                  daily_trialdata.push(cursor.value)
                  cursor.continue()
                } else {
                  const datafileCursorRequest = datafileStore.openCursor()
                  datafileCursorRequest.onsuccess = async function (event) {
                    const cursor = event.target.result

                    if (cursor) {
                      datafileData.push(cursor.value)
                      cursor.continue()
                    } else {
                      const templateCursorRequest = templateStore.openCursor()
                      templateCursorRequest.onsuccess = async function (event) {
                        const cursor = event.target.result

                        if (cursor) {
                          templateData.push(cursor.value)
                          cursor.continue()
                        } else {
                          const segmentCursorRequest = segmentStore.openCursor()
                          segmentCursorRequest.onsuccess = async function (
                            event
                          ) {
                            const cursor = event.target.result
                            if (cursor) {
                              segmentData.push(cursor.value)
                              cursor.continue()
                            } else {
                              const furnacesCursorRequest =
                                furnacesStore.openCursor()
                              furnacesCursorRequest.onsuccess = async function (
                                event
                              ) {
                                const cursor = event.target.result

                                if (cursor) {
                                  furnacesData.push(cursor.value)
                                  cursor.continue()
                                } else {
                                  const customersCursorRequest =
                                    customersStore.openCursor()
                                  customersCursorRequest.onsuccess =
                                    async function (event) {
                                      const cursor = event.target.result
                                      if (cursor) {
                                        customersData.push(cursor.value)
                                        cursor.continue()
                                      } else {
                                        // Perform join operations based on specified conditions

                                        const joinedData = daily_trialdata.map(
                                          (daily_trialItem, index) => {
                                            console.log(
                                              'daily_trialItem.datafile_id:',
                                              daily_trialItem.datafile_id
                                            )
                                            const matchingDatafile =
                                              datafileData.find(
                                                (datafileItem) =>
                                                  datafileItem.id ===
                                                  parseInt(
                                                    daily_trialItem.datafile_id,
                                                    10
                                                  )
                                              )
                                            const matchingTemplate =
                                              templateData.find(
                                                (templateItem) =>
                                                  templateItem.id ===
                                                  datafileData[index]
                                                    .template_id
                                              )
                                            const matchingSegment =
                                              segmentData.find(
                                                (segmentItem) =>
                                                  segmentItem.id ===
                                                  datafileData[index].segment_id
                                              )
                                            const matchingFurnace =
                                              furnacesData.find(
                                                (furnaceItem) =>
                                                  furnaceItem.id ===
                                                  datafileData[index].furnace_id
                                              )
                                            const matchingCustomer =
                                              customersData.find(
                                                (customerItem) =>
                                                  customerItem.id ===
                                                  datafileData[index]
                                                    .customer_id
                                              )

                                            return {
                                              ...daily_trialItem,
                                              datafile: matchingDatafile,
                                              template: matchingTemplate,
                                              segment: matchingSegment,
                                              furnace: matchingFurnace,
                                              customer: matchingCustomer,
                                            }
                                          }
                                        )
                                        // setJoined(joinedData)
                                        console.log('Joined data:', joinedData)
                                        localStorage.setItem(
                                          'lenofflineerosiondata',
                                          joinedData.length
                                        )
                                        // setofflineInspectionlength(joinedData.length)
                                        if (joinedData.length > 0) {
                                          const imagesData =
                                            await uploadBlobImagesAPI(
                                              joinedData[0],
                                              0,
                                              joinedData.length
                                            )
                                        }
                                        if (joinedData.length === 0) {
                                          setLoading(false)
                                        }
                                        // if(joinedData.length===0){
                                        //   const arrayTrialId = JSON.parse(localStorage.getItem('datafile_dailytrial_id'));
                                        //   if(arrayTrialId!==null&& arrayTrialId.length>0){
                                        //     const response5 = await axios.post(API.GET_ARRAY_DAILY_TRIAL, arrayTrialId);
                                        //     // Do something with response5 if needed

                                        //     const uniqueEntries = new Map();
                                        //     const filteredData = [];
                                        //     const remainingData = [];

                                        //     response5.data.forEach(item => {
                                        //       const key = `${item.alloying_addition}-${item.carbon}-${item.charge_mix_details}-${item.dataFile.user_id}-${item.dataFile.segment_id}-${item.dataFile.furnace_id}-${item.furnace_details}-${item.general_details}-${item.other_calculated_varialble}`;
                                        //       if (!uniqueEntries.has(key)) {
                                        //         uniqueEntries.set(key, true);
                                        //         filteredData.push(item);
                                        //       } else {
                                        //         remainingData.push(item);
                                        //       }
                                        //     });

                                        //     const trialIds = remainingData.map(item => item.datafile_id);
                                        //     const response6 = await axios.post(API.DELETE_ARRAY_DAILY_TRIAL, trialIds);
                                        //     // Do something with response5 if needed
                                        //     if(response6.status===200){
                                        //       localStorage.setItem('datafile_dailytrial_id',JSON.stringify([]))
                                        //     }

                                        //   }
                                        // }
                                      }
                                    }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }

                  transaction.onerror = function (event) {
                    console.error('Error in transaction:', event.target.error)
                  }
                }
              }
            }
            request.onerror = function (event) {
              console.error('Error opening database:', event.target.error)
            }
          }
        })
      }, 0)
    }
  }, [isOnline, OnlineTest])

  useEffect(() => {
    const fetchDataAndProcess = async () => {
      if (
        localStorage.getItem('lenofflinedata') === '0' &&
        localStorage.getItem('lenofflinenrmdata') === '0' &&
        localStorage.getItem('lenofflineerosiondata') === '0' &&
        localStorage.getItem('onlinestate') === '1'
      ) {
        // const arrayTrialId = JSON.parse(localStorage.getItem('datafile_dailytrial_id'));
        // if(arrayTrialId!==null&& arrayTrialId.length>0){
        //   const response5 = await axios.post(API.GET_ARRAY_DAILY_TRIAL, arrayTrialId);
        //   // Do something with response5 if needed

        //   const uniqueEntries = new Map();
        //   const filteredData = [];
        //   const remainingData = [];

        //   response5.data.forEach(item => {
        //     const key = `${item.alloying_addition}-${item.carbon}-${item.charge_mix_details}-${item.dataFile.user_id}-${item.dataFile.segment_id}-${item.dataFile.furnace_id}-${item.furnace_details}-${item.general_details}-${item.other_calculated_varialble}`;
        //     if (!uniqueEntries.has(key)) {
        //       uniqueEntries.set(key, true);
        //       filteredData.push(item);
        //     } else {
        //       remainingData.push(item);
        //     }
        //   });

        //   const trialIds = remainingData.map(item => item.datafile_id);
        //   const response6 = await axios.post(API.DELETE_ARRAY_DAILY_TRIAL, trialIds);
        //   // Do something with response5 if needed
        //   if(response6.status===200){
        //     localStorage.setItem('datafile_dailytrial_id',JSON.stringify([]))
        //   }

        // }

        const dbName = 'pcr' // Replace 'YourDatabaseName' with the name of your database
        const deleteDBRequest = window.indexedDB.deleteDatabase(dbName)

        deleteDBRequest.onsuccess = () => {
          console.log('db deleted successfully')
        }

        deleteDBRequest.onerror = (event) => {
          console.error('Error deleting database:', event.target.error)
        }

        deleteDBRequest.onblocked = (event) => {
          console.error('Database deletion is blocked:', event.target.error)
        }
      }
    }

    fetchDataAndProcess()
  }, [isOnline, OnlineTest])

  useEffect(() => {
    setofflineInspectionlength(
      localStorage.getItem('lenofflineerosiondata') || '0'
    )
  }, [offlineInspectionlength])

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setLoading(true)
    setError(null)

    try {
      const response = await axios.get(API.GET_ALL_EROSION_PROFILE, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })

      const responseData = response.data
      const selectedCustomer = JSON.parse(
        localStorage.getItem('selectedCustomer')
      )
      const customer_id = selectedCustomer?.id

      if (!customer_id) {
        console.warn('No customer selected or customer_id not found')
        setAllErosionProfile([])
        return
      }

      if (currentUser.role.role_id === 1) {
        const userId = currentUser.id
        const selectedCustomer = JSON.parse(
          localStorage.getItem('selectedCustomer')
        )
        const customer_id = selectedCustomer?.id
        const filteredErosionProfile = responseData.filter(
          (item) =>
            item.dataFile.customer_id === customer_id &&
            item.dataFile.user_id === userId
        )
        setAllErosionProfile(filteredErosionProfile)
      } else if (currentUser.role.role_id === 2) {
        const assignCustomerIds = currentUser.assign_customer_id
        const selectedCustomer = JSON.parse(
          localStorage.getItem('selectedCustomer')
        )
        const customer_id = selectedCustomer?.id
        const filteredErosionProfile = responseData.filter((item) =>
          customer_id.includes(item.dataFile.customer_id)
        )
        setAllErosionProfile(filteredErosionProfile)
      } else if (
        currentUser.role.role_id === 3 ||
        currentUser.role.role_id === 4
      ) {
        const selectedCustomer = JSON.parse(
          localStorage.getItem('selectedCustomer')
        )
        const customer_id = selectedCustomer?.id
        const filteredErosionProfile = responseData.filter(
          (item) => item.dataFile.customer_id === customer_id
        )
        setAllErosionProfile(filteredErosionProfile)
      }
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const fetchofflinedata = async () => {
    const transaction = db.transaction(
      [
        'erosion_profile',
        'datafile',
        'template',
        'segment',
        'furnaces',
        'customers',
      ],
      'readonly'
    )
    const daily_trialStore = transaction.objectStore('erosion_profile')
    const datafileStore = transaction.objectStore('datafile')
    const templateStore = transaction.objectStore('template')
    const segmentStore = transaction.objectStore('segment')
    const furnacesStore = transaction.objectStore('furnaces')
    const customersStore = transaction.objectStore('customers')

    const daily_trialdata = []
    const datafileData = []
    const templateData = []
    const segmentData = []
    const furnacesData = []
    const customersData = []

    const daily_trialCursorRequest = daily_trialStore.openCursor()
    daily_trialCursorRequest.onsuccess = function (event) {
      const cursor = event.target.result

      if (cursor) {
        daily_trialdata.push(cursor.value)
        cursor.continue()
      } else {
        const datafileCursorRequest = datafileStore.openCursor()
        datafileCursorRequest.onsuccess = function (event) {
          const cursor = event.target.result

          if (cursor) {
            datafileData.push(cursor.value)
            cursor.continue()
          } else {
            const templateCursorRequest = templateStore.openCursor()
            templateCursorRequest.onsuccess = function (event) {
              const cursor = event.target.result

              if (cursor) {
                templateData.push(cursor.value)
                cursor.continue()
              } else {
                const segmentCursorRequest = segmentStore.openCursor()
                segmentCursorRequest.onsuccess = function (event) {
                  const cursor = event.target.result
                  if (cursor) {
                    segmentData.push(cursor.value)
                    cursor.continue()
                  } else {
                    const furnacesCursorRequest = furnacesStore.openCursor()
                    furnacesCursorRequest.onsuccess = function (event) {
                      const cursor = event.target.result

                      if (cursor) {
                        furnacesData.push(cursor.value)
                        cursor.continue()
                      } else {
                        const customersCursorRequest =
                          customersStore.openCursor()
                        customersCursorRequest.onsuccess = function (event) {
                          const cursor = event.target.result
                          if (cursor) {
                            customersData.push(cursor.value)
                            cursor.continue()
                          } else {
                            // Perform join operations based on specified conditions

                            const joinedData = daily_trialdata.map(
                              (daily_trialItem, index) => {
                                console.log(
                                  'daily_trialItem.datafile_id:',
                                  daily_trialItem.datafile_id
                                )
                                const matchingDatafile = datafileData.find(
                                  (datafileItem) =>
                                    datafileItem.id ===
                                    parseInt(daily_trialItem.datafile_id, 10)
                                )
                                const matchingTemplate = templateData.find(
                                  (templateItem) =>
                                    templateItem.id ===
                                    datafileData[index].template_id
                                )
                                const matchingSegment = segmentData.find(
                                  (segmentItem) =>
                                    segmentItem.id ===
                                    datafileData[index].segment_id
                                )
                                const matchingFurnace = furnacesData.find(
                                  (furnaceItem) =>
                                    furnaceItem.id ===
                                    datafileData[index].furnace_id
                                )
                                const matchingCustomer = customersData.find(
                                  (customerItem) =>
                                    customerItem.id ===
                                    datafileData[index].customer_id
                                )

                                return {
                                  ...daily_trialItem,
                                  datafile: matchingDatafile,
                                  template: matchingTemplate,
                                  segment: matchingSegment,
                                  furnace: matchingFurnace,
                                  customer: matchingCustomer,
                                }
                              }
                            )
                            // setJoined(joinedData)
                            console.log('Joined data:', joinedData)
                            localStorage.setItem(
                              'lenofflineerosiondata',
                              joinedData.length
                            )
                            setAllErosionProfile(joinedData)
                            console.log('daily_trialdata:', daily_trialdata)
                            console.log('datafileData:', datafileData)
                            console.log('templateData:', templateData)
                            console.log('segmentData:', segmentData)
                            console.log('furnacesData:', furnacesData)
                            console.log('customersData:', customersData)
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        transaction.onerror = function (event) {
          console.error('Error in transaction:', event.target.error)
        }
      }
    }
  }

  const handleMenuOpen = (event, datafile_id, trial, submission_status) => {
    const rect = event.target.getBoundingClientRect()
    setIsPopupOpen(true)
    setPopupPosition({
      top: rect.top + window.scrollY,
      right: window.innerWidth - rect.right,
    })
    setPopupDatafileId(datafile_id)
    const erosionProfileID = trial.id
    localStorage.setItem('erosionProfileID', erosionProfileID)
    const ErosionID = trial.id
    localStorage.setItem('ErosionID', ErosionID)
    setSelectedSubmissionStatus(submission_status)
    setSelectedCardDataId(trial.id)
  }

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsPopupOpen(false) // Close the popup if clicked outside
    }
  }

  useEffect(() => {
    if (isPopupOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isPopupOpen])

  const handleDelete = () => {
    setIsPopupOpen(false)
    const id = selectedCardDataId
    console.log('selectedCardDataIdselectedCardDataId', selectedCardDataId)
    AlertDialog({
      type: 'delete',
      title: 'Delete?',
      text: 'Are you sure you want to delete?',
      confirmButtonText: 'Yes',
      onConfirm: async () => {
        if (isOnline) {
          const response = await axios.delete(
            `${API.DELETE_DATA_COLLECTION}/${id}`,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          )
          if (response) {
            if (response.status == 200) {
              AlertDialog({
                type: 'success',
                title: 'Success',
                text: 'Deleted Successfully',
                confirmButtonText: 'Ok',
              })
              fetchData()
            }
          }
        } else {
          const transaction = db.transaction(erosionName, 'readwrite')
          const objectStore = transaction.objectStore(erosionName)
          const deleteerosionrecord = objectStore.delete(id)
          deleteerosionrecord.onsuccess = () => {
            AlertDialog({
              type: 'success',
              title: 'Success',
              text: 'Deleted Successfully',
              confirmButtonText: 'Ok',
            })
            fetchofflinedata()
          }
        }
      },
    })
  }
  const handleEdit = () => {
    setTrialName('ErosionProfileEdit')
    localStorage.setItem('ErosionProfileEdit', 'ErosionProfileEdit')
    navigate('/erosionprofile')
  }

  const handleView = async () => {
    setTrialName('viewErosionProfile')
    localStorage.setItem('viewCostSheet', 'viewCostSheet')
    navigate('/erosionprofile')
  }

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
    return buf
  }

  const workbookToBlob = (workbook) => {
    return new Promise((resolve) => {
      // Write the workbook to a binary string
      const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' })

      // Convert the binary string to a Blob
      const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' })
      resolve(blob)
    })
  }

  //   const handleDownload = async () => {
  //     const id = localStorage.getItem("ErosionID");

  //     try {
  //       // Fetch data from the endpoint
  //       const response = await axios.get(`${API.GET_DATA_COLLECTION_BY_ID}/${id}`);
  //       const data = response.data;
  //       console.log(data, "datadata12")

  //       const responseTemplate = await axios.get(API.GET_DATA_COLLECTION_TEMPLATE, {
  //         responseType: 'blob', // Set the response type to 'blob'
  //       });

  //       // Read the downloaded template as binary string
  //       const binaryStr = await new Response(responseTemplate.data).arrayBuffer();
  //       const workbook = XLSX.read(binaryStr, { type: 'array' });

  //       // Modify the workbook with data from the response
  //       const sheetName = workbook.SheetNames[0];
  //       const worksheet = workbook.Sheets[sheetName];

  //       // Get the general_details object from the provided JSON
  //       const generalDetails = data.general_details;
  //       const productDetails = data.product_details;
  //       const furanceDetails = data.furnace_details;
  //       const mildSteelDetails = data.mild_steel_customers_only;
  //       const rawMaterialDetails = data.raw_material_energy_details;
  //       const furnaceDimension = data.furnace_dimension;
  //       const liningDetails = data.lining_details;
  //       const alloyDetails = data.alloy_stainless_steel_customers;

  //       //general details
  //       const contactPerson = generalDetails.contact_person || '';
  //       const customerName = generalDetails.customer_name || '';
  //       const date = generalDetails.date || '';
  //       const mobileNumber = generalDetails.mobile_number || '';

  //       const style = {
  //         fill: {
  //             patternType: "solid", // Add this line
  //             fgColor: { rgb: "FFFF00" } // Yellow background
  //         }
  //     };

  //     worksheet['B1'] = { t: 's', v: customerName, s: style };
  //     worksheet['B2'] = { t: 's', v: contactPerson, s: style };
  //     worksheet['E1'] = { t: 's', v: date, s: style };
  //     worksheet['E2'] = { t: 's', v: mobileNumber, s: style }

  //      // product details
  //     const endProduct = productDetails.end_product || '';
  //     const grades = productDetails.steel_produce || '';
  //     const ratio = productDetails.ratio || '';

  //     worksheet['B4'] = { t: 's', v: endProduct };
  //     worksheet['B5'] = { t: 's', v: grades };
  //     worksheet['B6'] = { t: 's', v: ratio };

  //     // furanceDetails
  //     const lrf = furanceDetails.lrf || '';
  //     const tap_time = furanceDetails.tap_time || '';
  //     const operation = furanceDetails.operation || '';
  //     const coil_grout = furanceDetails.coil_grout || '';
  //     const fce_running = furanceDetails.fce_running || '';
  //     const furnace_oem = furanceDetails.furnace_oem || '';
  //     const avg_heatsize = furanceDetails.avg_heatsize || '';
  //     const fce_capacity = furanceDetails.fce_capacity || '';
  //     const furnace_year = furanceDetails.furnace_year || '';
  //     const no_of_panels = furanceDetails.no_of_panels || '';
  //     const power_rating = furanceDetails.power_rating || '';
  //     const checking_time = furanceDetails.checking_time || '';
  //     const sintering_time = furanceDetails.sintering_time || '';
  //     const change_overtime = furanceDetails.change_overtime || '';
  //     const frequency_range = furanceDetails.frequency_range || '';
  //     const furnace_voltage = furanceDetails.furnace_voltage || '';
  //     const sintering_panel = furanceDetails.sintering_panel || '';
  //     const avg_tapping_temp = furanceDetails.avg_tapping_temp || '';
  //     const max_tapping_temp = furanceDetails.max_tapping_temp || '';
  //     const no_of_heats_perday = furanceDetails.no_of_heats_perday || '';
  //     const avg_power_consumption = furanceDetails.avg_power_consumption || '';
  //     const power_rating_sintering = furanceDetails.power_rating_sintering || '';

  //     const columnData = [no_of_panels, fce_capacity,avg_heatsize,power_rating,fce_running,tap_time,sintering_panel,power_rating_sintering,no_of_heats_perday,avg_power_consumption,lrf,frequency_range,max_tapping_temp,avg_tapping_temp,furnace_voltage,furnace_oem,furnace_year,coil_grout,sintering_time,operation,change_overtime,checking_time]

  //     for (let i = 7; i < 29; i++) {
  //         worksheet['B' + (i + 1)] = { t: 's', v: '' };
  //     }
  //     const rowData = columnData.map(data => [data]);
  //     try {
  //       XLSX.utils.sheet_add_aoa(worksheet, rowData, { origin: 'B8' });
  //     } catch (error) {
  //       console.error("Error adding new data:", error);
  //     }

  //     //mild Steel customer only
  //     const mildSteelColumnData = [
  //       mildSteelDetails.deslagging_by,
  //       mildSteelDetails.using_magnet_or_not,
  //       mildSteelDetails.avg_c_percent_at_50,
  //       mildSteelDetails.avg_c_percent_at_80,
  //       mildSteelDetails.final_c_percent_beforeTapping,
  //       mildSteelDetails.data_mill_scale,
  //       mildSteelDetails.tilt_count_for_deslagging,
  //       mildSteelDetails.are_they_taking_liquid_metal,
  //       mildSteelDetails.dri_quality,
  //       mildSteelDetails.slag_generation_per_heat,
  //       mildSteelDetails.power_utilised,
  //       mildSteelDetails.silica_price,
  //       mildSteelDetails.ccm,
  //       mildSteelDetails.casting_time,
  //       mildSteelDetails.tundish_cap,
  //       mildSteelDetails.min_level_of_tundish,
  //       mildSteelDetails.billet_size,
  //       mildSteelDetails.no_of_sequence,
  //       mildSteelDetails.ccm_make,
  //       mildSteelDetails.ccm_preparation_time,
  //       mildSteelDetails.purpose_of_using_nrm
  //   ];
  //   for (let i = 30; i < 51; i++) {
  //     worksheet['B' + (i + 1)] = { t: 's', v: '' }; // Clearing existing data
  // }
  //   const mildSteelRowData = mildSteelColumnData.map(data => [data]);
  // try {
  //     XLSX.utils.sheet_add_aoa(worksheet, mildSteelRowData, { origin: 'B31' });
  // } catch (error) {
  //     console.error("Error adding new data:", error)

  // }

  // const rawMaterialColumnData = [
  // rawMaterialDetails.type_of_scrap,
  // rawMaterialDetails.charge_mix_ratio,
  // rawMaterialDetails.yield_of_charge_mix,
  // rawMaterialDetails.profit,
  // rawMaterialDetails.unit_price_of_power,
  // ]

  // for (let i = 3; i < 8; i++) {
  //   worksheet['E' + (i + 1)] = { t: 's', v: '' }; // Clearing existing data
  // }
  // const rawMaterialRowData = rawMaterialColumnData.map(data => [data]);
  // try {
  //   XLSX.utils.sheet_add_aoa(worksheet, rawMaterialRowData, { origin: 'E4' });
  // } catch (error) {
  //   console.error("Error adding new data:", error)

  // }

  // const furnaceDimensionData = [
  //   furnaceDimension.inner_dia_of_coil,
  //   furnaceDimension.inner_dia_after_coilcoat,
  //   furnaceDimension.former_outer_dia,
  //   furnaceDimension.furnace_height,
  //   furnaceDimension.bottom_taper_dia,
  //   furnaceDimension.bottom_outer_dia,
  //   furnaceDimension.former_ht,
  //   furnaceDimension.bottom_lining_ht,
  //   furnaceDimension.total_power_given_to_ms,
  // ]

  // for (let i = 21; i < 29; i++) {
  //   worksheet['E' + (i + 1)] = { t: 's', v: '' }; // Clearing existing data
  // }
  // const furnaceDimensionRowData = furnaceDimensionData.map(data => [data]);
  // try {
  //   XLSX.utils.sheet_add_aoa(worksheet, furnaceDimensionRowData, { origin: 'E21' });
  // } catch (error) {
  //   console.error("Error adding new data:", error)
  // }

  // const liningDetailsData = [
  //   liningDetails.consumption_for_new_lining,
  //   liningDetails.consumption_for_patching,
  //   liningDetails.new_lining_life,
  //   liningDetails.patching_lining_life,
  //   liningDetails.total_no_of_side_lining,
  //   liningDetails.present_lining_method,
  // ]

  // for (let i = 31; i < 36; i++) {
  //   worksheet['E' + (i + 1)] = { t: 's', v: '' }; // Clearing existing data
  // }
  // const liningDetailsRowData = liningDetailsData.map(data => [data]);
  // try {
  //   XLSX.utils.sheet_add_aoa(worksheet, liningDetailsRowData, { origin: 'E31' });
  // } catch (error) {
  //   console.error("Error adding new data:", error)
  // }

  // const alloyDetailsDataArray = [
  //   alloyDetails.fecr_consumption,
  //   alloyDetails.cr_percentage,
  //   alloyDetails.final_cr_percentage,
  //   alloyDetails.cr_recovery,
  //   alloyDetails.alloy_addition,
  //   alloyDetails.casting_method,
  //   alloyDetails.simn_consumption,
  //   alloyDetails.mn_percentage,
  //   alloyDetails.mn_recovery,
  //   alloyDetails.addition_of_simn,
  //   alloyDetails.any_other_alloy_addition,
  //   alloyDetails.final_chemistry_at_furnace,
  //   alloyDetails.final_chemistry_at_billet,
  //   alloyDetails.agreement,
  // ]

  // for (let i = 38; i < 51; i++) {
  //   worksheet['E' + (i + 1)] = { t: 's', v: '' }; // Clearing existing data
  // }
  // const alloyDetailsData = alloyDetailsDataArray.map(data => [data]);
  // try {
  //   XLSX.utils.sheet_add_aoa(worksheet, alloyDetailsData, { origin: 'E38' });
  // } catch (error) {
  //   console.error("Error adding new data:", error)
  // }

  //       const newWorkbookBlob = new Blob([s2ab(XLSX.write(workbook, { type: 'binary' }))], { type: 'application/octet-stream' });

  //       // Create a URL for the blob data
  //       const url = window.URL.createObjectURL(newWorkbookBlob);

  //       // Create a link element
  //       const link = document.createElement('a');
  //       link.href = url;
  //       link.setAttribute('download', 'data_collection.xlsx'); // Change extension to .xlsx

  //       // Append the link to the document body and click it
  //       document.body.appendChild(link);
  //       link.click();

  //       // Clean up
  //       document.body.removeChild(link);
  //       window.URL.revokeObjectURL(url);
  //     } catch (error) {
  //       console.error('Error downloading template:', error.message);
  //     }
  //   }

  const capitalizeFirstLetter = (string) => {
    if (!string) return string
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const handleApplyFilter = (filteredData) => {
    console.log(filteredData, 'filteredData')
    setFilteredTrials(filteredData)
  }

  allErosionProfile.map((trial, index) => {
    console.log('erosiontrial', trial)
  })

  return (
    <>
      {isOnline ? (
        <div>
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: 20,
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  width: '96%',
                  margin: 10,
                }}
              >
                <img
                  src={FilterIcon}
                  alt="Filter Icon"
                  style={{
                    width: '22px',
                    height: '22px',
                    marginRight: '15px',
                    cursor: 'pointer',
                  }}
                  onClick={handleFilterClick}
                />
                <Tooltip
                  title={`Number Of Offline Inspections:${offlineInspectionlength}`}
                >
                  <IconButton style={{}}>
                    <WifiOffIcon />
                    <span
                      style={{
                        position: 'absolute',
                        top: -1,
                        right: -5,
                        background: 'red',
                        color: 'white',
                      }}
                    >
                      {offlineInspectionlength}
                    </span>
                  </IconButton>
                </Tooltip>
              </div>
              {isFilterOpen ? (
                <Erosionfilter
                  onClose={() => setIsFilterOpen(false)}
                  allErosionProfile={allErosionProfile}
                  applyFilter={handleApplyFilter}
                />
              ) : (
                <></>
              )}

              <div>
                {filteredTrials && filteredTrials.length > 0 ? (
                  filteredTrials.map((trial, index) => {
                    const createdDate = new Date(trial.dataFile.created_at)
                    const data_collection_id = trial.id
                    const datafile_id = trial.datafile_id
                    const submission_status = trial.submission_status
                    const capitalizedSubmissionStatus =
                      capitalizeFirstLetter(submission_status)

                    const SubmisionBackgroundColor =
                      capitalizedSubmissionStatus === 'Submitted'
                        ? '#0086DC1A'
                        : capitalizedSubmissionStatus === 'Draft'
                          ? '#7F7D831A'
                          : capitalizedSubmissionStatus === 'Approved'
                            ? '#34A8531A'
                            : capitalizedSubmissionStatus === 'Rejected'
                              ? '#EA43351A'
                              : 'transparent'

                    const SubmisionColor =
                      capitalizedSubmissionStatus === 'Submitted'
                        ? '#0086DC'
                        : capitalizedSubmissionStatus === 'Draft'
                          ? '#7F7D83'
                          : capitalizedSubmissionStatus === 'Approved'
                            ? '#34A853'
                            : capitalizedSubmissionStatus === 'Rejected'
                              ? '#EA4335'
                              : 'transparent'

                    const shouldDisplayAwaitingApproval =
                      (currentUser.role.role_id === 3 ||
                        currentUser.role.role_id === 4) &&
                      capitalizedSubmissionStatus === 'Submitted'
                    const displaySubmissionStatus =
                      shouldDisplayAwaitingApproval
                        ? 'Awaiting Approval'
                        : capitalizedSubmissionStatus
                    console.log(
                      'displaySubmissionStatus',
                      displaySubmissionStatus
                    )

                    const formattedDate = createdDate.toLocaleDateString(
                      'en-US',
                      {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                      }
                    )
                    return (
                      <Card
                        key={index}
                        sx={{
                          border: '1px solid #E6E6E6',
                          boxShadow: '0px 5px 13px -5px #0A090B0D',
                          width: '100%',
                          height: '180px',
                          marginBottom: '20px',
                        }}
                      >
                        <CardContent>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              height: '100%',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Typography
                                variant="h6"
                                gutterBottom
                                sx={{
                                  fontFamily: 'Inter',
                                  fontSize: '16px',
                                  fontWeight: 600,
                                  lineHeight: '20px',
                                  letterSpacing: '-0.006em',
                                  textAlign: 'left',
                                  color: '#252C32',
                                  marginTop: '10px',
                                }}
                              >
                                {`Erosion_profile_${trial.dataFile.customer.organization_name}`}
                              </Typography>

                              <IconButton
                                aria-label="options"
                                onClick={(event) =>
                                  handleMenuOpen(
                                    event,
                                    datafile_id,
                                    trial,
                                    submission_status
                                  )
                                }
                              >
                                <MoreVertIcon style={{ color: '#0086DC' }} />
                              </IconButton>
                            </div>

                            <Typography
                              variant="body1"
                              gutterBottom
                              sx={{
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontWeight: 700,
                                lineHeight: '20px',
                                letterSpacing: '-0.6%',
                                textAlign: 'left',
                                marginTop: '5px',
                                color: '#252C32',
                              }}
                            >
                              {trial.dataFile.customer.organization_name}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{
                                fontFamily: 'Inter',
                                fontSize: '12px',
                                fontWeight: 400,
                                lineHeight: '20px',
                                letterSpacing: '-0.006em',
                                textAlign: 'left',
                                color: '#6E7C87',
                              }}
                            >
                              {trial?.dataFile?.segment?.name
                                ? trial.dataFile.segment.name
                                : ''}{' '}
                            </Typography>

                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{
                                fontFamily: 'Inter',
                                fontSize: '12px',
                                fontWeight: 400,
                                lineHeight: '20px',
                                letterSpacing: '-0.006em',
                                textAlign: 'left',
                                color: SubmisionColor,
                              }}
                            >
                              <span
                                style={{
                                  backgroundColor: SubmisionBackgroundColor,
                                  padding: '0 8px',
                                  borderRadius: '4px',
                                }}
                              >
                                {displaySubmissionStatus}
                              </span>
                            </Typography>

                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: 'auto',
                              }}
                            >
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                sx={{
                                  fontFamily: 'Inter',
                                  fontSize: '12px',
                                  fontWeight: 500,
                                  lineHeight: '20px',
                                  letterSpacing: '-0.006em',
                                  textAlign: 'left',
                                  color: '#6E7C87',
                                }}
                              >
                                Created Date:
                              </Typography>

                              <Typography
                                variant="body2"
                                color="textSecondary"
                                sx={{
                                  fontFamily: 'Inter',
                                  fontSize: '12px',
                                  fontWeight: 700,
                                  lineHeight: '20px',
                                  letterSpacing: '-0.006em',
                                  textAlign: 'left',
                                  color: '#252C32',
                                  marginLeft: '8px',
                                }}
                              >
                                {formattedDate}
                              </Typography>

                              {/* Avatar component */}
                              <Avatar
                                style={{
                                  width: '30px',
                                  height: '30px',
                                  borderRadius: '12px',
                                  background: '#D7EDFF',
                                  color: '#0086DC',
                                  fontWeight: 700,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  marginLeft: 'auto',
                                  fontSize: '10px',
                                }}
                              >
                                {trial.dataFile.user &&
                                  trial.dataFile.user.firstname &&
                                  trial.dataFile.user.lastname && (
                                    <Avatar
                                      style={{
                                        width: '30px',
                                        height: '30px',
                                        borderRadius: '12px',
                                        background: '#D7EDFF',
                                        color: '#0086DC',
                                        fontWeight: 700,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginLeft: 'auto',
                                        fontSize: '10px',
                                      }}
                                    >
                                      {trial.dataFile.user.firstname.charAt(0)}
                                      {trial.dataFile.user.lastname.charAt(0)}
                                    </Avatar>
                                  )}
                              </Avatar>
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    )
                  })
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'space-around',
                      marginTop: 20,
                    }}
                  >
                    {allErosionProfile.map((trial, index) => {
                      console.log(trial, 'trialtrial')
                      const createdDate = new Date(trial.dataFile.created_at)
                      const data_collection_id = trial.id
                      console.log(
                        data_collection_id,
                        'daily_trial_iddaily_trial_id'
                      )
                      const datafile_id = trial.datafile_id
                      const submission_status = trial.submission_status
                      const capitalizedSubmissionStatus =
                        capitalizeFirstLetter(submission_status)
                      console.log(
                        'capitalizedSubmissionStatus',
                        capitalizedSubmissionStatus
                      )

                      const SubmisionBackgroundColor =
                        capitalizedSubmissionStatus === 'Submitted'
                          ? '#0086DC1A'
                          : capitalizedSubmissionStatus === 'Draft'
                            ? '#7F7D831A'
                            : capitalizedSubmissionStatus === 'Approved'
                              ? '#34A8531A'
                              : capitalizedSubmissionStatus === 'Rejected'
                                ? '#EA43351A'
                                : 'transparent'

                      const SubmisionColor =
                        capitalizedSubmissionStatus === 'Submitted'
                          ? '#0086DC'
                          : capitalizedSubmissionStatus === 'Draft'
                            ? '#7F7D83'
                            : capitalizedSubmissionStatus === 'Approved'
                              ? '#34A853'
                              : capitalizedSubmissionStatus === 'Rejected'
                                ? '#EA4335'
                                : 'transparent'

                      const shouldDisplayAwaitingApproval =
                        (currentUser.role.role_id === 3 ||
                          currentUser.role.role_id === 4) &&
                        capitalizedSubmissionStatus === 'Submitted'
                      const displaySubmissionStatus =
                        shouldDisplayAwaitingApproval
                          ? 'Awaiting Approval'
                          : capitalizedSubmissionStatus

                      const formattedDate = createdDate.toLocaleDateString(
                        'en-US',
                        {
                          day: 'numeric',
                          month: 'short',
                          year: 'numeric',
                        }
                      )

                      return (
                        <Card
                          key={index}
                          sx={{
                            border: '1px solid #E6E6E6',
                            boxShadow: '0px 5px 13px -5px #0A090B0D',
                            width: '100%',
                            height: '180px',
                            marginBottom: '20px',
                          }}
                        >
                          <CardContent>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  sx={{
                                    fontFamily: 'Inter',
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    lineHeight: '20px',
                                    letterSpacing: '-0.006em',
                                    textAlign: 'left',
                                    color: '#252C32',
                                    marginTop: '10px',
                                  }}
                                >
                                  {`Erosion_Profile_${trial.dataFile.customer.organization_name}`}
                                </Typography>

                                <IconButton
                                  aria-label="options"
                                  onClick={(event) =>
                                    handleMenuOpen(
                                      event,
                                      datafile_id,
                                      trial,
                                      submission_status
                                    )
                                  }
                                >
                                  <MoreVertIcon style={{ color: '#0086DC' }} />
                                </IconButton>
                              </div>

                              <Typography
                                variant="body1"
                                gutterBottom
                                sx={{
                                  fontFamily: 'Inter',
                                  fontSize: '14px',
                                  fontWeight: 700,
                                  lineHeight: '20px',
                                  letterSpacing: '-0.6%',
                                  textAlign: 'left',
                                  marginTop: '5px',
                                  color: '#252C32',
                                }}
                              >
                                {trial.dataFile.customer.organization_name}
                              </Typography>
                              <Typography
                                variant="body2"
                                color="textSecondary"
                                sx={{
                                  fontFamily: 'Inter',
                                  fontSize: '12px',
                                  fontWeight: 400,
                                  lineHeight: '20px',
                                  letterSpacing: '-0.006em',
                                  textAlign: 'left',
                                  color: '#6E7C87',
                                }}
                              >
                                {trial?.dataFile?.segment?.name
                                  ? trial.dataFile.segment.name
                                  : ''}{' '}
                              </Typography>

                              <Typography
                                variant="body2"
                                color="textSecondary"
                                sx={{
                                  fontFamily: 'Inter',
                                  fontSize: '12px',
                                  fontWeight: 400,
                                  lineHeight: '20px',
                                  letterSpacing: '-0.006em',
                                  textAlign: 'left',
                                  color: SubmisionColor,
                                }}
                              >
                                <span
                                  style={{
                                    backgroundColor: SubmisionBackgroundColor,
                                    padding: '0 8px',
                                    borderRadius: '4px',
                                  }}
                                >
                                  {displaySubmissionStatus}
                                </span>
                              </Typography>

                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  marginTop: 'auto',
                                }}
                              >
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  sx={{
                                    fontFamily: 'Inter',
                                    fontSize: '12px',
                                    fontWeight: 500,
                                    lineHeight: '20px',
                                    letterSpacing: '-0.006em',
                                    textAlign: 'left',
                                    color: '#6E7C87',
                                  }}
                                >
                                  Created Date:
                                </Typography>

                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  sx={{
                                    fontFamily: 'Inter',
                                    fontSize: '12px',
                                    fontWeight: 700,
                                    lineHeight: '20px',
                                    letterSpacing: '-0.006em',
                                    textAlign: 'left',
                                    color: '#252C32',
                                    marginLeft: '8px',
                                  }}
                                >
                                  {formattedDate}
                                </Typography>

                                {/* Avatar component */}
                                <Avatar
                                  style={{
                                    width: '30px',
                                    height: '30px',
                                    borderRadius: '12px',
                                    background: '#D7EDFF',
                                    color: '#0086DC',
                                    fontWeight: 700,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginLeft: 'auto',
                                    fontSize: '10px',
                                  }}
                                >
                                  {trial.dataFile.user &&
                                    trial.dataFile.user.firstname &&
                                    trial.dataFile.user.lastname && (
                                      <Avatar
                                        style={{
                                          width: '30px',
                                          height: '30px',
                                          borderRadius: '12px',
                                          background: '#D7EDFF',
                                          color: '#0086DC',
                                          fontWeight: 700,
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          marginLeft: 'auto',
                                          fontSize: '10px',
                                        }}
                                      >
                                        {trial.dataFile.user.firstname.charAt(
                                          0
                                        )}
                                        {trial.dataFile.user.lastname.charAt(0)}
                                      </Avatar>
                                    )}
                                </Avatar>
                              </div>
                            </div>
                          </CardContent>
                        </Card>
                      )
                    })}
                  </div>
                )}
              </div>

              {isPopupOpen && (
                <div
                  ref={popupRef}
                  style={{
                    position: 'absolute',
                    top: `${popupPosition.top}px`,
                    right: `${popupPosition.right}px`,
                    background: 'rgba(255, 255, 255, 1)',
                    padding: '20px',
                    borderRadius: '8px',
                    border: '1px solid #E8E8E8',
                    boxShadow: '0px 0px 35px 0px rgba(0, 0, 0, 0.1)',
                    zIndex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  {(selectedSubmissionStatus === 'draft' ||
                    (selectedSubmissionStatus === 'rejected' &&
                      (currentUser.role.role_id === 1 ||
                        currentUser.role.role_id === 2))) && (
                    <div
                      onClick={handleEdit}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        padding: '10px',
                      }}
                    >
                      <img
                        src={EditIcon}
                        alt="Edit Icon"
                        style={{ marginRight: '10px' }}
                      />
                      <Typography
                        style={{
                          color: '#252C32',
                          fontFamily: 'Inter',
                          fontSize: '16px',
                          fontStyle: 'normal',
                          fontWeight: 800,
                          lineHeight: 'normal',
                          letterSpacing: '-0.084px',
                        }}
                      >
                        Edit
                      </Typography>
                    </div>
                  )}

                  {selectedSubmissionStatus !== 'draft' && (
                    <div
                      onClick={handleView}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        padding: '10px',
                      }}
                    >
                      <img
                        src={ViewIcon}
                        alt="Edit Icon"
                        style={{ marginRight: '10px' }}
                      />
                      <Typography
                        style={{
                          color: '#252C32',
                          fontFamily: 'Inter',
                          fontSize: '16px',
                          fontStyle: 'normal',
                          fontWeight: 800,
                          lineHeight: 'normal',
                          letterSpacing: '-0.084px',
                        }}
                      >
                        View
                      </Typography>
                    </div>
                  )}

                  <div
                    onClick={() => handleDelete()}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      padding: '10px',
                    }}
                  >
                    <img
                      src={DeleteIcon}
                      alt="Delete Icon"
                      style={{ marginRight: '10px' }}
                    />
                    <Typography
                      style={{
                        color: '#FF3347',
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 800,
                        lineHeight: 'normal',
                        letterSpacing: '-0.084px',
                      }}
                    >
                      Delete
                    </Typography>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '96%',
              margin: 10,
            }}
          >
            {/* <img
       src={FilterIcon}
       alt="Filter Icon"
       style={{ width: "22px", height: "22px", marginRight: "15px", cursor: "pointer" }}
       onClick={handleFilterClick}
     /> */}
          </div>
          {/* {isFilterOpen ? (
      <Erosionfilter
      onClose={() => setIsFilterOpen(false)}
      allErosionProfile={allErosionProfile}
      applyFilter={handleApplyFilter}
      
    />
   ) : <></>} */}

          <div>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-around',
                marginTop: 20,
              }}
            >
              {allErosionProfile.map((trial, index) => {
                const createdDate = new Date(trial.created_at)
                const data_collection_id = trial.id
                const datafile_id = trial.datafile_id
                const submission_status = trial.submission_status
                const capitalizedSubmissionStatus =
                  capitalizeFirstLetter(submission_status)

                const SubmisionBackgroundColor =
                  capitalizedSubmissionStatus === 'Submitted'
                    ? '#0086DC1A'
                    : capitalizedSubmissionStatus === 'Draft'
                      ? '#7F7D831A'
                      : capitalizedSubmissionStatus === 'Approved'
                        ? '#34A8531A'
                        : capitalizedSubmissionStatus === 'Rejected'
                          ? '#EA43351A'
                          : 'transparent'

                const SubmisionColor =
                  capitalizedSubmissionStatus === 'Submitted'
                    ? '#0086DC'
                    : capitalizedSubmissionStatus === 'Draft'
                      ? '#7F7D83'
                      : capitalizedSubmissionStatus === 'Approved'
                        ? '#34A853'
                        : capitalizedSubmissionStatus === 'Rejected'
                          ? '#EA4335'
                          : 'transparent'

                const shouldDisplayAwaitingApproval =
                  (currentUser.role.role_id === 3 ||
                    currentUser.role.role_id === 4) &&
                  capitalizedSubmissionStatus === 'Submitted'
                const displaySubmissionStatus = shouldDisplayAwaitingApproval
                  ? 'Awaiting Approval'
                  : capitalizedSubmissionStatus
                console.log('displaySubmissionStatus', displaySubmissionStatus)

                const formattedDate = createdDate.toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })
                return (
                  <Card
                    key={index}
                    sx={{
                      border: '1px solid #E6E6E6',
                      boxShadow: '0px 5px 13px -5px #0A090B0D',
                      width: '100%',
                      height: '180px',
                      marginBottom: '20px',
                    }}
                  >
                    <CardContent>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: '100%',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Typography
                            variant="h6"
                            gutterBottom
                            sx={{
                              fontFamily: 'Inter',
                              fontSize: '16px',
                              fontWeight: 600,
                              lineHeight: '20px',
                              letterSpacing: '-0.006em',
                              textAlign: 'left',
                              color: '#252C32',
                              marginTop: '10px',
                            }}
                          >
                            {`Erosion_profile_${trial.customer.organization_name}`}
                          </Typography>

                          <IconButton
                            aria-label="options"
                            onClick={(event) =>
                              handleMenuOpen(
                                event,
                                datafile_id,
                                trial,
                                submission_status
                              )
                            }
                          >
                            <MoreVertIcon style={{ color: '#0086DC' }} />
                          </IconButton>
                        </div>

                        <Typography
                          variant="body1"
                          gutterBottom
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontWeight: 700,
                            lineHeight: '20px',
                            letterSpacing: '-0.6%',
                            textAlign: 'left',
                            marginTop: '5px',
                            color: '#252C32',
                          }}
                        >
                          {trial.customer.organization_name}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 400,
                            lineHeight: '20px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#6E7C87',
                          }}
                        >
                          {trial?.dataFile?.segment?.name
                            ? trial.dataFile.segment.name
                            : ''}{' '}
                        </Typography>

                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 400,
                            lineHeight: '20px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: SubmisionColor,
                          }}
                        >
                          <span
                            style={{
                              backgroundColor: SubmisionBackgroundColor,
                              padding: '0 8px',
                              borderRadius: '4px',
                            }}
                          >
                            {displaySubmissionStatus}
                          </span>
                        </Typography>

                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: 'auto',
                          }}
                        >
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              fontFamily: 'Inter',
                              fontSize: '12px',
                              fontWeight: 500,
                              lineHeight: '20px',
                              letterSpacing: '-0.006em',
                              textAlign: 'left',
                              color: '#6E7C87',
                            }}
                          >
                            Created Date:
                          </Typography>

                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              fontFamily: 'Inter',
                              fontSize: '12px',
                              fontWeight: 700,
                              lineHeight: '20px',
                              letterSpacing: '-0.006em',
                              textAlign: 'left',
                              color: '#252C32',
                              marginLeft: '8px',
                            }}
                          >
                            {formattedDate}
                          </Typography>

                          {/* Avatar component */}
                          {/* <Avatar
                style={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "12px",
                  background: "#D7EDFF",
                  color: "#0086DC",
                  fontWeight: 700,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "auto",
                  fontSize: "10px",
                }}
              >
                {trial.dataFile.user &&
                  trial.dataFile.user.firstname &&
                  trial.dataFile.user.lastname && (
                    <Avatar
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "12px",
                        background: "#D7EDFF",
                        color: "#0086DC",
                        fontWeight: 700,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "auto",
                        fontSize: "10px",
                      }}
                    >
                      {trial.dataFile.user.firstname.charAt(0)}
                      {trial.dataFile.user.lastname.charAt(0)}
                    </Avatar>
                  )}
              </Avatar> */}
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                )
              })}
            </div>
          </div>

          {isPopupOpen && (
            <div
              ref={popupRef}
              style={{
                position: 'absolute',
                top: `${popupPosition.top}px`,
                right: `${popupPosition.right}px`,
                background: 'rgba(255, 255, 255, 1)',
                padding: '20px',
                borderRadius: '8px',
                border: '1px solid #E8E8E8',
                boxShadow: '0px 0px 35px 0px rgba(0, 0, 0, 0.1)',
                zIndex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              {(selectedSubmissionStatus === 'draft' ||
                (selectedSubmissionStatus === 'rejected' &&
                  (currentUser.role.role_id === 1 ||
                    currentUser.role.role_id === 2))) && (
                <div
                  onClick={handleEdit}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    padding: '10px',
                  }}
                >
                  <img
                    src={EditIcon}
                    alt="Edit Icon"
                    style={{ marginRight: '10px' }}
                  />
                  <Typography
                    style={{
                      color: '#252C32',
                      fontFamily: 'Inter',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 800,
                      lineHeight: 'normal',
                      letterSpacing: '-0.084px',
                    }}
                  >
                    Edit
                  </Typography>
                </div>
              )}

              {selectedSubmissionStatus !== 'draft' && (
                <div
                  onClick={handleView}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    padding: '10px',
                  }}
                >
                  <img
                    src={ViewIcon}
                    alt="Edit Icon"
                    style={{ marginRight: '10px' }}
                  />
                  <Typography
                    style={{
                      color: '#252C32',
                      fontFamily: 'Inter',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 800,
                      lineHeight: 'normal',
                      letterSpacing: '-0.084px',
                    }}
                  >
                    View
                  </Typography>
                </div>
              )}

              <div
                onClick={() => handleDelete()}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',
                  padding: '10px',
                }}
              >
                <img
                  src={DeleteIcon}
                  alt="Delete Icon"
                  style={{ marginRight: '10px' }}
                />
                <Typography
                  style={{
                    color: '#FF3347',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 800,
                    lineHeight: 'normal',
                    letterSpacing: '-0.084px',
                  }}
                >
                  Delete
                </Typography>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default ErosionProfileHome
