import React, { useState, useEffect } from 'react'
import TextBox from '../../input/TextBox'
import { useRecoilState } from 'recoil'
import { carbon, general_details, carbon_type } from '../../../recoil/atoms'
import CustomAutocomplete from '../../input/CustomAutocomplete'
import Helper from '../../services/Helper'

const Carbon = () => {
  const [carbonObj, setCarbonObj] = useRecoilState(carbon)
  const [generalDetails] = useRecoilState(general_details)
  const [ddlabel, setLabel] = useState('')
  const [carbonType, setCarbonType] = useRecoilState(carbon_type)
  const [previousKey, setPreviousKey] = useState('')

  useEffect(() => {
    console.log('generalDetails:', generalDetails)
  }, [generalDetails])

  const uniqueId = generalDetails.id || ''

  useEffect(() => {
    const TrialName = localStorage.getItem('Trial')
    if (carbonType || TrialName === 'edit') {
      setLabel(
        carbonType.charAt(0).toUpperCase() +
          carbonType.slice(1).replace(/_/g, ' ')
      )
    }
  }, [carbonType])

  const getKeyFromCarbonType = (type) => {
    switch (type) {
      case 'Anthracite':
        return 'anthracite'
      case 'Carbon block':
        return 'carbon_block'
      case 'CPC':
        return 'cpc'
      default:
        return type.toLowerCase().replace(/\s/g, '_')
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    const TrialName = localStorage.getItem('Trial')
    const regex = /^\d{0,5}(\.\d{0,2})?$/

    if (regex.test(value)) {
      if (TrialName === 'new') {
        setCarbonObj((prevState) => ({
          ...prevState,
          [name]: value,
          id: uniqueId,
        }))
      } else {
        setCarbonObj((prevState) => ({
          ...prevState,
          [name]: value,
        }))
      }
    }
  }

  const handleChangeCarbonType = (e) => {
    const { name, value } = e.target
    const TrialName = localStorage.getItem('Trial')
    const regex = /^[0-9]*\.?[0-9]{0,2}$/

    if (regex.test(value)) {
      const key = getKeyFromCarbonType(ddlabel)
      setCarbonObj((prevState) => ({
        ...prevState,
        [key]: value,
        ...(TrialName === 'new' ? { id: uniqueId } : {}),
      }))
    }
  }

  useEffect(() => {
    console.log('Current carbonObj state:', carbonObj)
  }, [carbonObj])

  const handleAutoCompleteChange = (event, value) => {
    console.log('Changing carbon type to:', value)
    const newKey = getKeyFromCarbonType(value)

    setCarbonObj((prevState) => {
      console.log('Previous state before update:', prevState)
      const newState = { ...prevState }
      const keysToRemove = ['anthracite', 'carbon_block', 'cpc']
      keysToRemove.forEach((key) => {
        if (newState.hasOwnProperty(key)) {
          delete newState[key]
        }
      })

      // Set new key with current value or empty string
      newState[newKey] = prevState[newKey] || ''

      console.log('New state after update:', newState)

      // Update previous key
      setPreviousKey(value)

      return newState
    })

    // Update label and carbon type
    setLabel(value)
    setCarbonType(value)
  }

  return (
    <div>
      <div style={{ marginTop: '15px' }}>
        <CustomAutocomplete
          name="carbon_type"
          labelValue="Carbon Type"
          isLabel={true}
          options={Helper.carbonType.map((option) => option.name)}
          value={carbonType}
          onChange={handleAutoCompleteChange}
        />
      </div>
      <div style={{ flex: 1, marginTop: '15px' }}>
        <TextBox
          name="anthracite"
          label={ddlabel ? `${ddlabel} (kg)` : 'CPC (kg)'}
          type="text"
          value={carbonObj[getKeyFromCarbonType(ddlabel)] || ''}
          onChange={handleChangeCarbonType}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="met_coke"
          label="Met Coke (kg)"
          type="text"
          value={carbonObj.met_coke}
          onChange={handleChange}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="fifty_c_percentage"
          label="50 C% (%)"
          type="text"
          value={carbonObj.fifty_c_percentage}
          onChange={handleChange}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="eightyfive_c_percentage"
          label="85 C% (%)"
          type="text"
          value={carbonObj.eightyfive_c_percentage}
          onChange={handleChange}
        />
      </div>

      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="final_c_percentage"
          label="Final C%"
          type="text"
          value={carbonObj.final_c_percentage}
          onChange={handleChange}
        />
      </div>
    </div>
  )
}

export default Carbon
