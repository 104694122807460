import React, { useEffect, useState } from 'react'
import TextBox from '../../input/TextBox'
import Typography from '@mui/material/Typography'
import { useRecoilState } from 'recoil'
import StyledButton from '../../input/StyledButton'
import { trial_name } from '../../../recoil/atoms'
import { useRecoilValue } from 'recoil'
import {
  savingsArray,
  labor_cost,
  labor_cost_nrm,
  new_lining_details,
  new_lining_details_nrm,
  side_lining_details,
  side_lining_details_nrm,
  patching_details,
  patching_details_nrm,
} from '../../../recoil/atoms'
import {
  laborCostCampaignSelector,
  laborCostCampaignNRMSelector,
  laborCostPerTonSelector,
  laborCostPerTonNRMSelector,
  savingInLaborCostNRMSelector,
} from '../../../recoil/selector'

export default function LaborCost(props) {
  const [laborCost, setLaborCost] = useRecoilState(labor_cost)
  const [laborCostNRM, setLaborCostNRM] = useRecoilState(labor_cost_nrm)
  const [trialName] = useRecoilState(trial_name)
  const isReadOnly = trialName === 'viewCostSheet'

  const laborCostCampaign = useRecoilValue(laborCostCampaignSelector)
  const laborCostPerTon = useRecoilValue(laborCostPerTonSelector)
  const laborCostCampaignNRM = useRecoilValue(laborCostCampaignNRMSelector)
  const laborCostPerTonNRM = useRecoilValue(laborCostPerTonNRMSelector)
  const SavingInLaborCostNRM = useRecoilValue(savingInLaborCostNRMSelector)
  console.log(laborCostCampaign, 'laborCostCampaign')

  const [newLining] = useRecoilState(new_lining_details)
  // const[newLiningNrm]=useRecoilState(new_lining_details_nrm);
  // const[sideLining]=useRecoilState(side_lining_details);
  // const[sideLiningNrm]=useRecoilState(side_lining_details_nrm);
  // const[patchingDetails]=useRecoilState(patching_details);
  // const[patchingDetailsNrm]=useRecoilState(patching_details_nrm);
  // const [savingsObject, setSavingsObject] = useRecoilState(savingsArray);

  const [laborCostObject, setLaborCostObject] = useState([])
  console.log(newLining, 'newLining')

  const handleLaborCostChange = (name, value) => {
    setLaborCost((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleLaborCostNRMChange = (name, value) => {
    setLaborCostNRM((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  //calculation
  // const savedNewLining = JSON.parse(localStorage.getItem('newLining'));
  // const savedSideLining = JSON.parse(localStorage.getItem('sideLining'));
  // const savedPatchingDetails = JSON.parse(localStorage.getItem('patchingDetails'));
  // const savedCalculationObj = JSON.parse(localStorage.getItem('calculationObject'));

  // const laborCostCampaign = laborCost.labor_cost_per_nl * (
  //   (savedNewLining && Number(savedNewLining.no_of_new_lining) || 0) +
  //   (savedSideLining && Number(savedSideLining.no_of_side_lining) || 0) +
  //   (savedPatchingDetails && Number(savedPatchingDetails.no_of_patching) || 0)
  // );

  // const laborCostPerTon = savedCalculationObj && Number(savedCalculationObj.tot_prod)
  // ? (laborCostCampaign / Number(savedCalculationObj.tot_prod)).toFixed(2)
  // : "0.00";

  //nrm
  // const savedNewLiningNRM = JSON.parse(localStorage.getItem('newLiningNRM'));
  // const savedSideLiningNRM = JSON.parse(localStorage.getItem('sideLiningNRM'));
  // const savedPatchingDetailsNRM = JSON.parse(localStorage.getItem('patchingDetailsNRM'));
  // const savedCalculationObjNRM = JSON.parse(localStorage.getItem('calculationObjectNRM'));

  // const laborCostCampaignNRM = laborCostNRM.labor_cost_per_nl * (
  //   (savedNewLiningNRM && Number(savedNewLiningNRM.no_of_new_lining) || 0) +
  //   (savedSideLiningNRM && Number(savedSideLiningNRM.no_of_side_lining) || 0) +
  //   (savedPatchingDetailsNRM && Number(savedPatchingDetailsNRM.no_of_patching) || 0)
  // );

  // const laborCostPerTonNRM = savedCalculationObjNRM && Number(savedCalculationObjNRM.tot_prod)
  // ? (laborCostCampaignNRM / Number(savedCalculationObjNRM.tot_prod)).toFixed(2)
  // : "0.00";

  // const SavingInLaborCostNRM = (laborCostPerTon - laborCostPerTonNRM).toFixed(2);
  // console.log(SavingInLaborCostNRM, "SavingInLaborCostNRM");

  // const laborCostObj = {
  //   labor_cost_per_nl: laborCost.labor_cost_per_nl,
  //   labor_cost_campaign: laborCostCampaign,
  //   labor_cost_per_steel_produced: laborCostPerTon,
  //   savings_labor_cost: "NA",
  // }

  // const laborCostObjNRM = {
  //   labor_cost_per_nl: laborCostNRM.labor_cost_per_nl,
  //   labor_cost_campaign: laborCostCampaignNRM,
  //   labor_cost_per_steel_produced: laborCostPerTonNRM,
  //   savings_labor_cost: "NA",
  // }

  useEffect(() => {
    if (
      props.trialName === 'costSheetEdit' &&
      props.editSavingsDetails.length > 0
    ) {
      console.log(props.editSavingsDetails, 'props.editSavingsDetails')
      let editObj = doesKeyExist(
        props.editSavingsDetails,
        'laborCostDataObject'
      )
      console.log(editObj, 'editObj')
      if (editObj) {
        setLaborCost(editObj.laborCostDataObject.laborCost.basic)
        setLaborCostNRM(editObj.laborCostDataObject.laborCost.nrm)
      }
    }
  }, [props.editSavingsDetails])

  function doesKeyExist(array, key) {
    for (let i = 0; i < array.length; i++) {
      if (Array.isArray(array[i])) {
        const result = doesKeyExist(array[i], key)
        if (result) return result
      } else if (typeof array[i] === 'object') {
        if (array[i].hasOwnProperty(key)) {
          return array[i]
        }
      }
    }
    return false
  }

  // const handlenextTriger =()=>{
  //   props.handleNext()
  //   props.handleLaborCostObject(laborCostObj, laborCostObjNRM)
  // }

  const custDet = JSON.parse(localStorage.getItem('customerDetails'))
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body1"
          style={{
            color: '#000000',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '20px',
            marginTop: '0px',
          }}
        >
          {custDet.parameter}
        </Typography>
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="labor_cost_per_nl"
          label="Labor Cost per NL/SL/PA"
          type="number"
          value={laborCost.labor_cost_per_nl}
          onChange={(event) =>
            handleLaborCostChange('labor_cost_per_nl', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="labor_cost_campaign"
          label="Labor Cost in a Campaign "
          type="number"
          value={laborCostCampaign ? laborCostCampaign : '0.00'}
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="labor_cost_per_steel_produced"
          label="Labor cost per ton of steel produced"
          type="number"
          value={laborCostPerTon ? laborCostPerTon : '0.00'}
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="savings_labor_cost"
          label="Savings in Labor Cost"
          type="number"
          value="NA"
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body1"
          style={{
            color: '#000000',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '20px',
            marginTop: '0px',
          }}
        >
          NRM
        </Typography>
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="labor_cost_per_nl"
          label="Labor Cost per NL/SL/PA"
          type="number"
          value={laborCostNRM.labor_cost_per_nl}
          onChange={(event) =>
            handleLaborCostNRMChange('labor_cost_per_nl', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="mobile_number"
          label="Labor Cost in a Campaign "
          type="number"
          value={laborCostCampaignNRM ? laborCostCampaignNRM : '0.00'}
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="labor_cost_per_steel_produced"
          label="Labor cost per ton of steel produced"
          type="number"
          value={laborCostPerTonNRM ? laborCostPerTonNRM : '0.00'}
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="savings_labor_cost"
          label="Savings in Labor Cost"
          type="number"
          value={SavingInLaborCostNRM ? SavingInLaborCostNRM : '0.00'}
          onChange={() => {}}
          disabled={isReadOnly}
        />
      </div>

      {/* <div style={{ justifyContent: 'space-between', flexDirection: "row", display: "flex", marginTop: "auto", marginBottom: "10px", position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1000 }}>
        <StyledButton
          variant="outlined"
          text="Cancel"
          onClick={props.handleBack}
          disabled={props.activeStep === 0}
          style={{ marginRight: 10 }}
        />
        <StyledButton
          variant="contained"
          color="primary"
          text={
            (props.trialName === 'edit' && props.selectedTab === 2) ? 'Update' :
            (props.activeStep === (props.selectedTab === 0 ? props.CostSheetTabs.length - 1 : props.savingsDetails.length - 1)) ? 'Finish' : 
            'Next'
          }
        onClick={handlenextTriger}
        />
      </div> */}
    </div>
  )
}
