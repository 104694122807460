import React, { useState, useEffect } from 'react'
import TextBox from '../../input/TextBox'
import AutoSelectBox from '../../input/AutoSelectBox'
import Typography from '@mui/material/Typography'
import Helper from '../../services/Helper'
import { repairing_details, repairing_details_nrm } from '../../../recoil/atoms'
import { useRecoilState } from 'recoil'
import { trial_name } from '../../../recoil/atoms'

export default function RepairDetails() {
  const [repairingDetails, setRepairingDetails] =
    useRecoilState(repairing_details)
  const [repairingDetailsNRM, setRepairingDetailsNRM] = useRecoilState(
    repairing_details_nrm
  )
  const [trialName] = useRecoilState(trial_name)
  const isReadOnly = trialName === 'viewCostSheet'

  const handleRepairingDetailsChange = (name, value) => {
    const regex = /^[0-9]*\.?[0-9]{0,2}$/
    if (regex.test(value) || value === '') {
      setRepairingDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const handleRepairingDetailsNRMChange = (name, value) => {
    const regex = /^[0-9]*\.?[0-9]{0,2}$/
    if (regex.test(value) || value === '') {
      setRepairingDetailsNRM((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  useEffect(() => {
    let repairingDetailsObj = {
      repairingDetails: {
        basic: repairingDetails,
        nrm: repairingDetailsNRM,
      },
    }
    console.log(repairingDetailsObj, '35656123repairing')
  }, [repairingDetails, repairingDetailsNRM])

  useEffect(() => {
    localStorage.setItem('repairingDetails', JSON.stringify(repairingDetails))
    localStorage.setItem(
      'repairingDetailsNRM',
      JSON.stringify(repairingDetailsNRM)
    )
  }, [repairingDetails, repairingDetailsNRM])

  const custDet = JSON.parse(localStorage.getItem('customerDetails'))

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body1"
          style={{
            color: '#000000',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '20px',
            marginTop: '0px',
          }}
        >
          {custDet.parameter}
        </Typography>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <AutoSelectBox
          labelValue="Ramming Material used in Repairing - 1"
          name="ramming_material_side_lining_1"
          isLabel={true}
          options={Helper.CostSheet}
          value={repairingDetails.ramming_material_side_lining_1}
          onChange={(event) =>
            handleRepairingDetailsChange(
              'ramming_material_side_lining_1',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="qty_repairing_1"
          label="Qty. of Material required for Repairing - 1"
          type="text"
          value={repairingDetails.qty_repairing_1}
          onChange={(event) =>
            handleRepairingDetailsChange('qty_repairing_1', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginBottom: '15px' }}>
        <AutoSelectBox
          labelValue="Material used in Spout and Top - 2"
          name="material_used_spout_and_top_2"
          isLabel={true}
          options={Helper.CostSheet}
          value={repairingDetails.material_used_spout_and_top_2}
          onChange={(event) =>
            handleRepairingDetailsChange(
              'material_used_spout_and_top_2',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="qty_spout_and_top_2"
          label="Quality of Material required for Spout & Top - 2"
          type="text"
          value={repairingDetails.qty_spout_and_top_2}
          onChange={(event) =>
            handleRepairingDetailsChange(
              'qty_spout_and_top_2',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginBottom: '15px' }}>
        <AutoSelectBox
          labelValue="Material used in spout and top - 3"
          name="material_used_spout_and_top_3"
          isLabel={true}
          options={Helper.CostSheet}
          value={repairingDetails.material_used_spout_and_top_3}
          onChange={(event) =>
            handleRepairingDetailsChange(
              'material_used_spout_and_top_3',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="qty_spout_and_top_3"
          label="Qty. of Material required for Spout & Top - 3"
          type="text"
          value={repairingDetails.qty_spout_and_top_3}
          onChange={(event) =>
            handleRepairingDetailsChange(
              'qty_spout_and_top_3',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body1"
          style={{
            color: '#000000',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '20px',
            marginTop: '0px',
          }}
        >
          NRM
        </Typography>
      </div>

      <div style={{ marginBottom: '15px' }}>
        <AutoSelectBox
          labelValue="Ramming Material used in Repairing - 1"
          name="ramming_material_side_lining_1"
          isLabel={true}
          options={Helper.CostSheet}
          value={repairingDetailsNRM.ramming_material_side_lining_1}
          onChange={(event) =>
            handleRepairingDetailsNRMChange(
              'ramming_material_side_lining_1',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="qty_repairing_1"
          label="Quality of Material required for Repairing - 1"
          type="text"
          value={repairingDetailsNRM.qty_repairing_1}
          onChange={(event) =>
            handleRepairingDetailsNRMChange(
              'qty_repairing_1',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginBottom: '15px' }}>
        <AutoSelectBox
          labelValue="Material used in Spout and Top - 2"
          name="material_used_spout_and_top_2"
          isLabel={true}
          options={Helper.CostSheet}
          value={repairingDetailsNRM.material_used_spout_and_top_2}
          onChange={(event) =>
            handleRepairingDetailsNRMChange(
              'material_used_spout_and_top_2',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="qty_spout_and_top_2"
          label="Quality of Material required for Spout & Top -2"
          type="text"
          value={repairingDetailsNRM.qty_spout_and_top_2}
          onChange={(event) =>
            handleRepairingDetailsNRMChange(
              'qty_spout_and_top_2',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginBottom: '15px' }}>
        <AutoSelectBox
          labelValue="Material used in spout and top - 3"
          name="material_used_spout_and_top_3"
          isLabel={true}
          options={Helper.CostSheet}
          value={repairingDetailsNRM.material_used_spout_and_top_3}
          onChange={(event) =>
            handleRepairingDetailsNRMChange(
              'material_used_spout_and_top_3',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="qty_spout_and_top_3"
          label="Qty. of Material required for Spout & Top - 3"
          type="text"
          value={repairingDetailsNRM.qty_spout_and_top_3}
          onChange={(event) =>
            handleRepairingDetailsNRMChange(
              'qty_spout_and_top_3',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>
    </div>
  )
}
