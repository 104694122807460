import React, { useState, useEffect } from 'react'
import TextBox from '../../input/TextBox'
import Helper from '../../services/Helper'
import API from '../../services/API'
import axios from 'axios'
import { erosion_details } from '../../../recoil/atoms'
import { v4 as uuidv4 } from 'uuid'
import CustomAutocomplete from '../../input/CustomAutocomplete'
import { useRecoilState } from 'recoil'
import { trial_name } from '../../../recoil/atoms'
const ErosionProfileDetails = () => {
  const [isEdit, setIsEdit] = useState(localStorage.getItem('Trial') === 'Edit')
  const [details, setDetails] = useRecoilState(erosion_details)
  const [trialName] = useRecoilState(trial_name)
  const isReadOnly = trialName === 'viewErosionProfile'

  const handleChange = (name, value) => {
    console.log('nammeee', name)
    console.log('valuee', value)
    const TrialName = localStorage.getItem('Trial')
    if (TrialName === 'new' || TrialName === 'add') {
      const uniqueId = uuidv4()
      setDetails((prevState) => ({
        ...prevState,
        [name]: value,
        id: uniqueId, // Set the unique ID
        isdelete: false,
      }))
    } else {
      // If TrialName is not "new", no need to generate unique ID
      setDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  //   useEffect(() => {
  //     const fetchData = async () => {
  //       try {
  //         const campaignNumbers = await Helper.getCompaignNumbers();
  //         console.log("campaignNumbers", campaignNumbers);
  //         setCompaignNo(campaignNumbers);
  //       } catch (error) {
  //         console.error("Error fetching campaign numbers:", error);
  //       }
  //     };

  //     // const fetchLiningData = async () => {
  //     //   try {
  //     //     const response = await axios.get(API.LINING_PATCHING_NO);
  //     //     console.log("RESSSS", response);
  //     //     setLiningPatchingNo(response.data);
  //     //   } catch (error) {
  //     //     console.error("Error fetching lining:", error);
  //     //   }
  //     // };

  //     fetchData();
  //     fetchLiningData();
  //   }, []);

  return (
    <div style={{ width: '100%' }}>
      <div style={{ marginTop: '15px', marginRight: '15px' }}>
        <div style={{ marginTop: '15px' }}>
          <TextBox
            name="customer_name"
            label="Customer Name"
            type="text"
            value={details.cus_name || ''}
            onChange={(event) => handleChange('cus_name', event.target.value)}
            disabled={isReadOnly}
          />
          <div>
            <TextBox
              label="Date of Lining or patching or side lining"
              name="date_of_lining"
              type="date"
              value={details.date_of_lining}
              onChange={(event) =>
                handleChange('date_of_lining', event.target.value)
              }
              showCalendarIcon={true}
              disabled={isReadOnly}
            />
          </div>
        </div>
        <CustomAutocomplete
          name="segment"
          isLabel={true}
          labelValue="Segment"
          options={Helper.Segment.map((option) => option.name)}
          value={details.segment}
          onChange={(event, value) => handleChange('segment', value)}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <CustomAutocomplete
          name="furnace_no"
          labelValue="Furnace No"
          isLabel={true}
          options={Helper.FurnaceNo.map((option) => option.name)}
          value={details.furnace_no}
          onChange={(event, value) => handleChange('furnace_no', value)}
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <CustomAutocomplete
          name="crucible_no"
          isLabel={true}
          labelValue="Crucible No."
          options={Helper.CrucibleNo.map((option) => option.name)}
          value={details.crucible_no}
          onChange={(event, value) => handleChange('crucible_no', value)}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <CustomAutocomplete
          name="lining_no"
          isLabel={true}
          labelValue="Lining/Patching No."
          options={Helper.patchingNo.map((option) => option.name)}
          value={details.lining_no}
          onChange={(event, value) => handleChange('lining_no', value)}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <CustomAutocomplete
          name="campaign_no"
          isLabel={true}
          labelValue="Campaign No."
          options={Helper.CampaignNo.map((option) => option.name)}
          value={details.campaign_no}
          onChange={(event, value) => handleChange('campaign_no', value)}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="furnace_capacity"
          label="Furance Capacity(MT)"
          isString={true}
          value={details.furnace_capacity}
          onChange={(event) =>
            handleChange('furnace_capacity', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="furnace_down"
          label="Furnace Down at(No of Heats)"
          isString={true}
          value={details.furnace_down}
          onChange={(event) => handleChange('furnace_down', event.target.value)}
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <CustomAutocomplete
          name="former_type"
          isLabel={true}
          labelValue="Type of Former"
          options={Helper.FormerType.map((option) => option.name)}
          value={details.former_type}
          onChange={(event, value) => handleChange('former_type', value)}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <CustomAutocomplete
          name="furnace_make"
          isLabel={true}
          labelValue="Make of the furnace"
          options={Helper.FurnaceMake.map((option) => option.name)}
          value={details.furnace_make}
          onChange={(event, value) => handleChange('furnace_make', value)}
          disabled={isReadOnly}
        />
      </div>
    </div>
  )
}
export default ErosionProfileDetails
