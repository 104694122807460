import React, { useEffect } from 'react'
import TextBox from '../../input/TextBox'
import AutoSelectBox from '../../input/AutoSelectBox'
import Typography from '@mui/material/Typography'
import Helper from '../../services/Helper'
import {
  savings_tangible_benefit,
  savings_tangible_benefit_nrm,
} from '../../../recoil/atoms'
import { useRecoilState } from 'recoil'
import StyledButton from '../../input/StyledButton'
import { trial_name } from '../../../recoil/atoms'
import { useRecoilValue } from 'recoil'
import { otherTangibleBenefitsSelector } from '../../../recoil/selector'

export default function TangibleBenefits(props) {
  const [tangibleBenefits, setTangibleBenefits] = useRecoilState(
    savings_tangible_benefit
  )
  const [tangibleBenefitsNRM, setTangibleBenefitsNRM] = useRecoilState(
    savings_tangible_benefit_nrm
  )
  const [trialName] = useRecoilState(trial_name)
  const isReadOnly = trialName === 'viewCostSheet'

  const otherTangibleBenefits = useRecoilValue(otherTangibleBenefitsSelector)

  const handleTangibleBenefits = (name, value) => {
    setTangibleBenefits((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleTangibleBenefitsNRM = (name, value) => {
    setTangibleBenefitsNRM((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  // const otherTangibleBenefits = (Number(tangibleBenefitsNRM.asbestos_sheet) +  Number(tangibleBenefitsNRM.coil_coat_for_repair) +  Number(tangibleBenefitsNRM.antenna_replacement) +  Number(tangibleBenefitsNRM.sodium_silicate) +  Number(tangibleBenefitsNRM.slag_dumping) +  Number(tangibleBenefitsNRM.safety_in_operations)).toFixed(2)

  // console.log(otherTangibleBenefits, "otherTangibleBenefits")

  const tangibleBenefitsObj = {
    asbestos_sheet: 'NA',
    coil_coat_for_repair: 'NA',
    // antenna_replacement: "NA",
    // sodium_silicate: "NA",
    slag_dumping: 'NA',
    // safety_in_operations: "NA",
    other_tangible_benefits: 'NA',
  }

  const tangibleBenefitsObjNRM = {
    asbestos_sheet: tangibleBenefitsNRM.asbestos_sheet,
    coil_coat_for_repair: tangibleBenefitsNRM.coil_coat_for_repair,
    // antenna_replacement: tangibleBenefitsNRM.antenna_replacement,
    // sodium_silicate: tangibleBenefitsNRM.sodium_silicate,
    slag_dumping: tangibleBenefitsNRM.slag_dumping,
    // safety_in_operations: tangibleBenefitsNRM.safety_in_operations,
    other_tangible_benefits: otherTangibleBenefits,
  }

  useEffect(() => {
    if (
      props.trialName === 'costSheetEdit' &&
      props.editSavingsDetails.length > 0
    ) {
      console.log(props.editSavingsDetails, 'props.editSavingsDetails')
      let editObj = doesKeyExist(
        props.editSavingsDetails,
        'tangibleBenefitsObject'
      )
      console.log(editObj, 'editObj')
      if (editObj) {
        setTangibleBenefits(
          editObj.tangibleBenefitsObject.tangibleBenefits.basic
        )
        setTangibleBenefitsNRM(
          editObj.tangibleBenefitsObject.tangibleBenefits.nrm
        )
      }
    }
  }, [props.editSavingsDetails])

  function doesKeyExist(array, key) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].hasOwnProperty(key)) {
        return array[i]
      }
    }
    return false
  }

  const handlenextTriger = () => {
    props.handleNext()
    props.handleTangibleBenefitsObject(
      tangibleBenefitsObj,
      tangibleBenefitsObjNRM
    )
  }

  const custDet = JSON.parse(localStorage.getItem('customerDetails'))
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body1"
          style={{
            color: '#000000',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '20px',
            marginTop: '0px',
          }}
        >
          {custDet.parameter}
        </Typography>
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="mobile_number"
          label="Savings Due to Asbestos Sheet, Avoiding antenna replacement"
          type="text"
          value="NA"
          onChange={(event) =>
            handleTangibleBenefits('asbestos_sheet', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="mobile_number"
          label="Savings due to coil coat for repair, Sodium Silicate"
          type="text"
          value="NA"
          onChange={(event) =>
            handleTangibleBenefits('asbestos_sheet', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      {/* <div style={{ marginTop: "15px", }}>
          <TextBox
              name="date"
              label="Savings due to avoiding antenna replacement"
              type="text"
              value= 'NA'
              onChange={(event) => handleTangibleBenefits('asbestos_sheet', event.target.value)}
            />
          </div> */}
      {/* <div style={{ marginTop: "15px", }}>
          <TextBox
              name="date"
              label="Savings due to Sodium Silicate"
              type="text"
              value= 'NA'
              onChange={(event) => handleTangibleBenefits('asbestos_sheet', event.target.value)}
            />
          </div> */}
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Savings due to Slag Dumping, safety in operations"
          type="text"
          value="NA"
          onChange={(event) =>
            handleTangibleBenefits('asbestos_sheet', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      {/* <div style={{ marginTop: "15px", }}>
          <TextBox
              name="date"
              label="Savings due to Safety in operations"
              type="text"
              value= 'NA'
              onChange={(event) => handleTangibleBenefits('asbestos_sheet', event.target.value)}
            />
          </div> */}
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Savings due to Other Tangible Benefit(F5)"
          type="text"
          value="NA"
          onChange={(event) =>
            handleTangibleBenefits('asbestos_sheet', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body1"
          style={{
            color: '#000000',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '20px',
            marginTop: '0px',
          }}
        >
          NRM
        </Typography>
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="mobile_number"
          label="Savings Due to Asbestos Sheet"
          type="text"
          value={tangibleBenefitsNRM.asbestos_sheet}
          onChange={(event) =>
            handleTangibleBenefitsNRM('asbestos_sheet', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="mobile_number"
          label="Savings due to coil coat for repair"
          type="text"
          value={tangibleBenefitsNRM.coil_coat_for_repair}
          onChange={(event) =>
            handleTangibleBenefitsNRM(
              'coil_coat_for_repair',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Savings due to avoiding antenna replacement"
          type="text"
          value={tangibleBenefitsNRM.antenna_replacement}
          onChange={(event) =>
            handleTangibleBenefitsNRM('antenna_replacement', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Savings due to Sodium Silicate"
          type="text"
          value={tangibleBenefitsNRM.sodium_silicate}
          onChange={(event) =>
            handleTangibleBenefitsNRM('sodium_silicate', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Savings due to Slag Dumping"
          type="text"
          value={tangibleBenefitsNRM.slag_dumping}
          onChange={(event) =>
            handleTangibleBenefitsNRM('slag_dumping', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Savings due to Safety in operations"
          type="text"
          value={tangibleBenefitsNRM.safety_in_operations}
          onChange={(event) =>
            handleTangibleBenefitsNRM(
              'safety_in_operations',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Savings due to Other Tangible Benefit(F5)"
          type="text"
          value={otherTangibleBenefits}
          onChange={(event) =>
            handleTangibleBenefitsNRM(
              'other_tangible_benefits',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      {/* <div style={{ justifyContent: 'space-between', flexDirection: "row", display: "flex", marginTop: "auto", marginBottom: "10px", position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1000 }}>
        <StyledButton
          variant="outlined"
          text="Cancel"
          onClick={props.handleBack}
          disabled={props.activeStep === 0}
          style={{ marginRight: 10 }}
        />
        <StyledButton
          variant="contained"
          color="primary"
          text={
            (props.trialName === 'edit' && props.selectedTab === 2) ? 'Update' :
            (props.activeStep === (props.selectedTab === 0 ? props.CostSheetTabs.length - 1 : props.savingsDetails.length - 1)) ? 'Finish' : 
            'Next'
          }
        onClick={handlenextTriger}
        />
      </div> */}
    </div>
  )
}
