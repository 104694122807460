const _get_api_url = function () {
  switch (process.env.REACT_APP_STAGE) {
    case 'dev':
      return 'https://web-linux-itsindia-dfddboard-dev-centralindia-feapi.azurewebsites.net/api/'
    case 'prod':
      return 'http://localhost:3044'
    default:
      return 'https://web-linux-itsindia-dfddboard-dev-centralindia-feapi.azurewebsites.net/api/'
  }
}

const _get_app_url = function () {
  switch (process.env.REACT_APP_STAGE) {
    case 'dev':
      return 'https://dev.indec4.saint-gobain.com/aim'
    case 'prod':
      return 'https://dc02.saint-gobain.com/aim'
    default:
      return 'https://dev.indec4.saint-gobain.com/aim'
  }
}
const _get_sso_url = function () {
  switch (process.env.REACT_APP_STAGE) {
    case 'dev':
      return 'https://uat.cloudgateway.saint-gobain.com/openam/oauth2/realms/root/realms/AccessManagement/authorize?response_type=code&client_id=POC_Digital_Foundary&redirect_uri=https://dev.digital-foundry.saint-gobain.com/login&service=simple&scope=stGoSGI'
    case 'prod':
      return 'https://cloudsso.saint-gobain.com/openam/oauth2/realms/root/realms/AccessManagement/authorize?response_type=code&client_id=Chryso_PROD&redirect_uri=https://sgcc-auth.saint-gobain.com/chryso/v2/auth/login&service=simple&scope=stGoSGI'
    default:
      return 'https://uat.cloudgateway.saint-gobain.com/openam/oauth2/realms/root/realms/AccessManagement/authorize?response_type=code&client_id=POC_Digital_Foundary&redirect_uri=https://dev.digital-foundry.saint-gobain.com/login&service=simple&scope=stGoSGI'
  }
}
export const configParam = {
  primaryColor: '#FF6D04',
  lightBackground: '#FC8C200C',
  grey: '#D8D8D8',
  API_URL: _get_api_url(),
  APP_URL: _get_app_url(),
  RUN_SSO_URL: _get_sso_url,
}

export default configParam
