import React, { useState, useEffect } from 'react'
import TextBox from '../../input/TextBox'
import StyledButton from '../../input/StyledButton'
import SelectBox from '../../input/SelectBox'
import { Typography } from '@mui/material'
import AutoSelectBox from '../../input/AutoSelectBox'
import Helper from '../../services/Helper'
import { CompareArrows } from '@mui/icons-material'
import API from '../../services/API'
import axios from 'axios'
import { lining_details } from '../../../recoil/atoms'
import { useRecoilState, useRecoilValue } from 'recoil'
import { v4 as uuidv4 } from 'uuid'
import { trial_name } from '../../../recoil/atoms'

const LiningDetails = () => {
  const [liningDetails, setLiningDetails] = useRecoilState(lining_details)
  const [trialName] = useRecoilState(trial_name)
  const uuid = uuidv4()

  const handleChange = (e) => {
    const { name, value } = e.target
    const TrialName = localStorage.getItem('Trial')

    // Alphabet regex for 'present_lining_method' field
    const alphabetRegex = /^[a-zA-Z\s]*$/

    // Numeric regex for other fields
    const numericRegex = /^\d{0,5}(\.\d{0,2})?$/

    // Apply different validation for different fields
    if (name === 'present_lining_method') {
      if (!alphabetRegex.test(value)) {
        return 
      }
    } else {
      // For other fields, use numeric validation
      if (!numericRegex.test(value)) {
        return // Exit if input doesn't match numeric regex
      }
    }

    // Set state with conditional ID logic
    if (TrialName === 'new' || TrialName === 'add') {
      setLiningDetails((prevState) => ({
        ...prevState,
        [name]: value,
        id: uuid,
      }))
    } else {
      setLiningDetails((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const handleDateSelection = (value, type) => {
    console.log('typee', type)
  }

  const isReadOnly = trialName === 'viewDataCollection'

  return (
    <div style={{ width: '100%' }}>
      <div style={{ marginTop: '15px', marginRight: '15px' }}>
        <TextBox
          name="consumption_for_new_lining"
          label="Consumption for New Lining - (kg/Lining)"
          type="text"
          value={liningDetails.consumption_for_new_lining}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="consumption_for_patching"
          label="Consumption for Patching /Side Lining - (Kg/ SideLining)"
          type="text"
          value={liningDetails.consumption_for_patching}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="new_lining_life"
          label="New Lining Life - (No. of heats)"
          type="text"
          value={liningDetails.new_lining_life}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="patching_lining_life"
          label="Side / Patching lining life - (No. of heats)"
          type="text"
          value={liningDetails.patching_lining_life}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="total_no_of_side_lining"
          label="Total No. of Side Lining with Keeping Same Bottom - (each)"
          type="text"
          value={liningDetails.total_no_of_side_lining}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="present_lining_method"
          label="Present Lining Method"
          type="text"
          value={liningDetails.present_lining_method}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
    </div>
  )
}
export default LiningDetails
