import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import TopAppBar from '../Components/drawer/TopAppBar'
import LeftDrawer from '../Components/drawer/LeftDrawer'

export default function PCRTemplate() {
  const [isDrawerOpen, setDrawerOpen] = useState(false)
  console.log(isDrawerOpen, 'isDrawerOpen')

  const toggleDrawerOpen = () => {
    console.log('Toggle Drawer Open called')
    setDrawerOpen((prevState) => !prevState)
  }

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <TopAppBar toggleDrawerOpen={toggleDrawerOpen} open={isDrawerOpen} />
        <LeftDrawer toggleDrawerOpen={toggleDrawerOpen} open={isDrawerOpen} />
        <main
          id="nis-main-element"
          style={{
            flexGrow: 1,
            minHeight: 'calc(100vh - 70px)',
            height: 'calc(100vh - 70px)',
            marginTop: '70px',
            padding: '20px',
            width: isDrawerOpen ? 'calc(100% - 300px)' : 'calc(100% - 70px)',
          }}
        >
          <Outlet />
        </main>
      </div>
    </div>
  )
}
