import React, { useState, useEffect } from 'react'
import TextBox from '../../input/TextBox'
import StyledButton from '../../input/StyledButton'
import SelectBox from '../../input/SelectBox'
import { Typography } from '@mui/material'
import AutoSelectBox from '../../input/AutoSelectBox'
// import Helper from '../services/Helper';
import { CompareArrows } from '@mui/icons-material'
// import API from '../services/API';
import axios from 'axios'
import { Product_Details } from '../../../recoil/atoms'
import { useRecoilState, useRecoilValue } from 'recoil'
import { trial_name } from '../../../recoil/atoms'

const ProductDetails = () => {
  const [productDetails, setProductDetails] = useRecoilState(Product_Details)
  const [trialName] = useRecoilState(trial_name)
  console.log(productDetails, 'productDetails')
  // const uuid = uuidv4();

  const handleChange = (e) => {
    const { name, value } = e.target
    setProductDetails((prevProductDetails) => ({
      ...prevProductDetails,
      [name]: value,
    }))
  }

  const isReadOnly = trialName === 'viewDataCollection'

  return (
    <div style={{ width: '100%' }}>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="end_product"
          label="End Product of the plant"
          value={productDetails.end_product}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>

      <TextBox
        label="Grades of Steel Produce"
        name="steel_produce"
        value={productDetails.steel_produce}
        onChange={handleChange}
        disabled={isReadOnly}
      />

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="ratio"
          label="Ratio of MS Grade"
          isString={true}
          value={productDetails.ratio}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
    </div>
  )
}
export default ProductDetails
