import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { NavLink } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  IconButton,
  Grid,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  SvgIcon,
  Tooltip,
  Modal,
} from '@mui/material'
import Routes from '../../routes'
import { useRecoilState, useRecoilValue } from 'recoil'
import { menuList, dataManagementTab } from '../../recoil/atoms'
import { Link as RouterLink } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { ReactComponent as AppsIcon } from '../../assets/icons/apps.svg'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as HomeIcon } from '../../assets/icons/HomeIcon.svg'
import MobileHomeIcon from '../../assets/icons/ic_mobile_pcr_logo.svg'
import AddIcon from '../../assets/icons/ic_add.svg'
import ProfileIconM from '../../assets/icons/profile_icon.svg'
import AutoSelectBox from '../../pages/input/AutoSelectBox'
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout_icon.svg'
import { ReactComponent as ProfileIcon } from '../../assets/icons/profile_icon.svg'
import TextBox from '../../pages/input/TextBox'
import { ReactComponent as BackIcon } from '../../assets/icons/back_icon.svg'
import axios from 'axios'
import API from '../../pages/services/API'
// const [customers, setCustomers] = useState([])
// const [selectCustomers, setSelectCustomers] = useState([])

export default function TopAppBar({
  pathname,
  toggleDrawerOpen,
  open,
  onClickFunction,
}) {
  const [, setMenu] = useRecoilState(menuList)
  const [, setSelectedItem] = useState(null)
  const menu = useRecoilValue(menuList)
  const [userDet, setUserDet] = useState({})
  const [, setLocationDet] = useState({})
  const [plantDet, setPlantDet] = useState('')
  const [breadcrumb, setBreadcrumb] = useState('')
  const navigate = useNavigate()
  const location = useLocation()
  const [profileOpen, setProfileOpen] = useState(false)
  const [customers, setCustomers] = useState([])
  const [selectCustomers, setSelectCustomers] = useState([])
  const [organizationNames, setOrganizationNames] = useState('')
  const [liningPatchingNo, setLiningPatchingNo] = useState([])
  const [containerStyle, setContainerStyle] = useState(getResponsiveStyles())
  const [selectedTab, setSelectedTab] = useRecoilState(dataManagementTab)
  const access_token = localStorage.getItem('accesstoken')
  const handleOpen = () => setProfileOpen(true)
  const handleClose = () => setProfileOpen(false)
  const [selectCustomer, setSelectCustomer] = useState('')

  function getResponsiveStyles() {
    let style = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '10px',
      width: 'calc(100% - 120px)',
    }

    return style
  }

  useEffect(() => {
    function handleResize() {
      setContainerStyle(getResponsiveStyles())
    }

    window.addEventListener('resize', handleResize)
    setContainerStyle(getResponsiveStyles())
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const isMobileOrTablet = window.innerWidth <= 1024 
  const isDatamanagementTemplate = location.pathname === '/datamanagement'
  const isDailyTrialMonitoringTemplate =
    location.pathname === '/dailytrialmonitoringM'
  const isNRMPerformanceTemplate = location.pathname === '/nrmperformance'
  const isDataCollectionTemplate =
    location.pathname === '/HorizontalDataCollectionStepper'
  const isCostSheetTemplate = location.pathname === '/costsheet'
  const isErosionProfileTemplate = location.pathname === '/erosionprofile'
  const isFilterPage = location.pathname === '/dailyTrialFilter'
  const isSettingsPage = location.pathname === '/settings'
  const isViewDailytrial = location.pathname === '/viewdailytrial'
  const isViewtrialNRM = location.pathname === '/viewtrialNRM'
  const isDashboard = location.pathname === '/dashboard'
  const isReportsPage = location.pathname === '/reports'
  const isReleasePage = location.pathname === '/releases'
  const isHelpAndSupportPage = location.pathname === '/helpandSupport'
  const isDashboardPage = location.pathname === '/dashboard'
  const isProfileView = location.pathname === '/profileview'
  const storedCustomerDet = JSON.parse(localStorage.getItem('selectedCustomer'))
  const currentUser = JSON.parse(localStorage.getItem('user'))

  const goBack = () => {
    if (isViewtrialNRM) {
      setSelectedTab(1)
      navigate('/datamanagement')
    } else if (isViewDailytrial) {
      setSelectedTab(0)
      navigate('/datamanagement')
    } else if (isErosionProfileTemplate) {
      setSelectedTab(4)
      navigate('/datamanagement')
    } else if (isDataCollectionTemplate) {
      setSelectedTab(2)
      navigate('/datamanagement')
    } else if (isCostSheetTemplate) {
      setSelectedTab(3)
      navigate('/datamanagement')
    } else {
      setSelectedTab(0)
      navigate('/datamanagement')
    }
  }

  useEffect(() => {
    const pathnames = location.pathname.split('/').filter((path) => path)
    const hasMultiplePaths = pathnames.length > 1
    const currentBreadcrumb = hasMultiplePaths
      ? pathnames[pathnames.length - 2]
      : pathnames[0]
    setBreadcrumb(currentBreadcrumb)
  }, [location])

  useEffect(() => {
    let data = localStorage.getItem('user')
    let user = JSON.parse(data)
    setUserDet(user)
  }, [])

  useEffect(() => {
    let data = localStorage.getItem('user')
    let user = JSON.parse(data)
    fetchCustomers(user)
  }, [])

  useEffect(() => {
    fetchCustomers()
  }, [])

  const fetchCustomers = async (user) => {
    try {
      const response = await axios.get(API.GET_CUSTOMER, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })      
      const customerResponse = response.data

      if (currentUser.role.role_id === 1) {
        const customerId = currentUser.assign_customer_id[0]
        const customers = customerResponse.filter(
          (item) => item.id === customerId
        )
        setCustomers(customers)

        const customer_id = storedCustomerDet.id
        const selectedCustomer = customerResponse.filter(
          (item) => item.id === customer_id
        )
        setSelectCustomers(selectedCustomer)

        // For roles 2 and 3
      } else if (
        currentUser.role.role_id === 2 ||
        currentUser.role.role_id === 3
      ) {
        const assignCustomerIds = currentUser.assign_customer_id.map((id) => id)
        const filteredCustomers = customerResponse.filter((customer) =>
          assignCustomerIds.includes(customer.id)
        )
        setCustomers(filteredCustomers)

        const customer_id = storedCustomerDet.id
        const selectedCustomer = filteredCustomers.filter(
          (item) => item.id === customer_id
        )
        setSelectCustomers(selectedCustomer)

        // For other roles (e.g., 4, 5, etc.)
      } else {
        setCustomers(customerResponse)

        const customer_id = storedCustomerDet.id
        const selectedCustomer = customerResponse.filter(
          (item) => item.id === customer_id
        )
        setSelectCustomers(selectedCustomer)
      }

      const template_response = await axios.get(API.GET_TEMPLATES) // Adjust the URL as needed
      localStorage.setItem(
        'setTemplates',
        JSON.stringify(template_response.data)
      )

      const Segment_response = await axios.get(API.GET_SEGMENT, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      localStorage.setItem('setSegments', JSON.stringify(Segment_response.data))

      const furnace_response = await axios.get(API.GET_FURNACES, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      localStorage.setItem('setFurnaces', JSON.stringify(furnace_response.data))

      const lining_response = await axios.get(API.LINING_PATCHING_NO, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      localStorage.setItem(
        'setLiningPatchingNo',
        JSON.stringify(lining_response.data)
      )
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  useEffect(() => {}, [selectCustomer])
  // useEffect(() => {
  //   if (customers.length > 0) {
  //     setSelectCustomer(
  //       customers.filter((x) => x.id === storedCustomerDet.id).length > 0 &&
  //         customers.filter((x) => x.id === storedCustomerDet.id)[0].id
  //         ? customers.filter((x) => x.id === storedCustomerDet.id)[0].id
  //         : null
  //     )
  //   }
  // }, [customers])

  const handleCustomerChange = (selectedOption) => {
    const selectedCustomerDetails = customers.find(
      (customer) => customer.id === selectedOption
    )

    if (selectedCustomerDetails) {
      setSelectCustomer(selectedCustomerDetails.id)
      localStorage.setItem(
        'selectedCustomer',
        JSON.stringify(selectedCustomerDetails)
      )
      setTimeout(() => {
        window.location.reload()
      }, 0)
    } else {
      console.error('Selected customer not found in the customers list')
    }
  }

  const changeMenu = (name) => {
    setMenu(name)
    setSelectedItem(name)
  }

  const handleAdd = () => {
    onClickFunction()
  }

  const handleProfile = () => {
    navigate('/profileview')
  }

  function getListItemSet(text, index) {
    const isSelected = menu === text.name
    const colorScheme = isSelected
      ? { textColor: '#000', backgroundColor: '#f2f2f2', iconColor: '#000' }
      : { textColor: '#757575', backgroundColor: '', iconColor: '#757575' }
    const { textColor, backgroundColor, iconColor } = colorScheme
    if (!text.icon) return null

    return (
      <NavLink key={index} style={{ textDecoration: 'none' }} to={text.path}>
        <ListItem
          key={text}
          disablePadding
          sx={{
            display: 'block',
            paddingLeft: 2,
            paddingRight: 2,
            paddingTop: 1,
            paddingBottom: 1,
            marginBottom: 1,
            backgroundColor: backgroundColor,
          }}
          style={{ backgroundColor: isSelected ? '#008C0000' : '' }}
          onClick={() => changeMenu(text.name)}
        >
          <ListItemButton
            sx={{
              minHeight: 58,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <SvgIcon style={{ color: iconColor, marginRight: 12 }}>
              <Tooltip title={text.name} placement="right">
                <text.icon />
              </Tooltip>
            </SvgIcon>
            {open && (
              <ListItemText
                className="Drawer-ListItemText"
                primary={
                  text.name === 'Logout'
                    ? `${text.name} - ${userDet.name}`
                    : text.name
                }
                sx={{ opacity: 1 }}
                style={{ color: textColor }}
              />
            )}
          </ListItemButton>
        </ListItem>
      </NavLink>
    )
  }

  const handleToggleDrawer = () => {
    toggleDrawerOpen(!open)
  }

  const drawer = (
    <div>
      <List>
        {Routes.mainRoutes
          .filter(
            (text) =>
              text.name !== 'Settings' ||
              userDet.role_id === '1' ||
              userDet.role_id === '2'
          )
          .map(getListItemSet)}
      </List>
    </div>
  )

  const drawerWidth = open ? '300px' : '0'
  const justifyContentValue = open ? 'initial' : 'center'
  const mobileDrawer = (
    <Drawer
      variant="persistent"
      style={{
        whiteSpace: 'nowrap',
        position: 'fixed',
        width: drawerWidth,
        left: '0',
        top: '0',
        height: '100%',
        zIndex: 9999,
      }}
      open={open}
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton
            sx={{
              minHeight: 58,
              justifyContent: justifyContentValue,
              px: 2.5,
            }}
            onClick={toggleDrawerOpen}
          >
            <IconButton
              color="inherit"
              aria-label="toggle drawer"
              edge="start"
              sx={{
                display: 'block',
                cursor: 'pointer',
                width: 48,
                height: 48,
              }}
            >
              <img src={MobileHomeIcon} />
            </IconButton>
            {open && (
              <ListItemText
                primary="NiFi Application"
                sx={{ opacity: 1 }}
                style={{ color: 'black' }}
              />
            )}
          </ListItemButton>
        </ListItem>
        <List>
          {Routes.mainRoutes
            .filter((text) => text.footer && text.iconFlag)
            .map((text, index) => getListItemSet(text, index))}
        </List>
      </List>
    </Drawer>
  )

  const shouldRenderComponent =
    userDet.role_id !== undefined && userDet.role_id !== null
  const leftValue = open ? '300px' : '70px'
  return shouldRenderComponent ? (
    <div>
      <div className="desktop-view">
        <AppBar
          elevation={0}
          sx={{
            backgroundColor: '#ffffff',
            padding: '3px',
            boxShadow: '0px 2px 5px #00000026',
            left: leftValue,
            width: '100%',
            height: '72px',
          }}
        >
          <Helmet>
            <meta charSet="utf-8" />
            <title>Digital Foundry</title>
          </Helmet>
          <Toolbar variant="dense" disableGutters style={{ minHeight: 48 }}>
            <Grid
              container
              style={{ padding: '20px 0 10px ' }}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Box
                sx={{
                  marginLeft: {
                    xs: '85px',
                    sm: '85px',
                    md: '85px',
                    xl: '65px',
                  },
                }}
              >
                <Typography
                  style={{
                    color: '#252C32',
                    fontSize: '26px',
                    fontWeight: 700,
                  }}
                >
                  {isDashboard
                    ? 'Dashboard'
                    : isSettingsPage
                      ? 'Settings'
                      : isReportsPage
                        ? 'Reports'
                        : isReleasePage
                          ? 'Releases'
                          : isHelpAndSupportPage
                            ? 'Help & Support'
                            : 'Data Management'}
                </Typography>
              </Box>
              {/* <ProfileIcon style={{ marginRight: '60px', height: '30px', width: '30px',  cursor: "pointer" }} onClick={handleOpen}/> */}
              <Grid item>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'absolute', // Added absolute positioning to fix location
                    right: '35px', // Fixed distance from the right edge
                    top: '15px',
                  }}
                >
                  <ProfileIcon
                    style={{
                      marginRight: open ? '80px' : '60px',
                      height: '30px',
                      width: '30px',
                      cursor: 'pointer',
                    }}
                    onClick={handleOpen}
                  />
                </div>
              </Grid>
              {profileOpen ? (
                <Modal
                  open={profileOpen}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <div
                    style={{
                      maxHeight: '80vh',
                      overflowY: 'auto',
                      padding: '20px',
                      backgroundColor: 'white',
                      margin: 'auto',
                      marginTop: '10vh',
                      borderRadius: '8px',
                      maxWidth: '500px',
                      width: '90%',
                      scrollbarWidth: 'thin',
                      scrollbarColor: '#cccccc #f2f2f2',
                      WebkitOverflowScrolling: 'touch',
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: 20,
                          fontWeight: 600,
                          lineHeight: '24px',
                          letterSpacing: '-0.006em',
                          textAlign: 'left',
                          color: '#252C32',
                        }}
                      >
                        My Profile
                      </Typography>
                    </div>

                    <div style={{ marginTop: '5px' }}>
                      <TextBox
                        label="Name"
                        isString={true}
                        value={`${userDet.firstname || ''} ${userDet.lastname || ''}`.trim()}
                      />
                    </div>

                    <div style={{ marginTop: '5px' }}>
                      <TextBox
                        name="description"
                        label="Designation"
                        isString={true}
                        multiline={true}
                        value={userDet.designation || ''}
                      />
                    </div>
                    <div style={{ marginTop: '5px' }}>
                      <TextBox
                        name="email"
                        label="Email"
                        value={userDet.email || ''}
                      />
                    </div>
                    <div style={{ marginTop: '5px' }}>
                      <TextBox
                        name="website"
                        label="Contact Number"
                        value={userDet.contact_number || ''}
                      />
                    </div>
                    <div style={{ marginTop: '5px' }}>
                      <TextBox
                        name="address"
                        label="SGID"
                        value={userDet.sgid || ''}
                      />
                    </div>
                    <div style={{ marginTop: '5px' }}>
                      <TextBox
                        name="city"
                        label="Role"
                        value={userDet.role.name || ''}
                      />
                    </div>
                    <div style={{ marginTop: '5px' }}>
                      <TextBox name="state" label="Customers" />
                    </div>
                  </div>
                </Modal>
              ) : (
                <></>
              )}
            </Grid>
          </Toolbar>
        </AppBar>
      </div>

      <div className="mobile-content">
        <AppBar
          elevation={0}
          sx={{
            backgroundColor: '#ffffff',
            padding: '3px',
            boxShadow: '0px 2px 5px #00000026',
            left: '0px',
            width: '100%',
            height: '72px',
          }}
        >
          <Helmet>
            <meta charSet="utf-8" />
            <title>Digital Foundry</title>
          </Helmet>
          {isMobileOrTablet && isDatamanagementTemplate && (
            <Toolbar
              variant="dense"
              disableGutters
              style={{ minHeight: 48, position: 'sticky', top: 0, zIndex: 1 }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  justifyContent: 'space-between', // Ensures left and right alignment
                  overflowX: 'auto',
                  alignItems: 'center',
                }}
              >
                {/* Left Section with "Data Management" Text */}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flex: 1,
                    textAlign: 'left',
                    marginLeft: '10px',
                    padding: '25px',
                  }}
                >
                  <IconButton
                    color="inherit"
                    aria-label="toggle drawer"
                    edge="start"
                    sx={{
                      display: 'block',
                      cursor: 'pointer',
                      width: 48,
                      height: 48,
                      marginRight: '10px',
                    }}
                    onClick={handleToggleDrawer}
                  >
                    <img src={MobileHomeIcon} />
                  </IconButton>

                  <Typography
                    variant="h5"
                    component="div"
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '20px',
                      fontWeight: 600,
                      lineHeight: '24px',
                      letterSpacing: '-0.006em',
                      color: '#252C32',
                      marginLeft: '50px',
                    }}
                  >
                    Data Management
                  </Typography>
                </div>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    width: 'auto',
                  }}
                >
                  <IconButton
                    color="inherit"
                    aria-label="add"
                    edge="end"
                    sx={{ marginRight: '10px' }}
                    onClick={handleAdd}
                  >
                    <img src={AddIcon} />
                  </IconButton>

                  <AutoSelectBox
                    name="controllable-states-demo"
                    isLabel={true}
                    labelCondition="organization_name"
                    id="select-customer"
                    onChange={(e) => handleCustomerChange(e.target.value)}
                    options ={customers}
                    sx={{
                      width: '100px',
                      marginRight: '10px',
                      marginTop: {
                        xs: '15px',
                        sm: '0px',
                      },
                      '& .MuiInputBase-root': {
                        fontSize: '12px',
                        padding: '5px',
                        marginTop: '15px',
                      },
                      '& .MuiAutocomplete-inputRoot': {
                        minHeight: '30px',
                        marginTop: '15px',
                      },
                    }}
                  />

                  <IconButton
                    color="inherit"
                    aria-label="profile"
                    edge="end"
                    sx={{ marginRight: '5px' }}
                    onClick={handleProfile}
                  >
                    <img src={ProfileIconM} alt="Profile Icon" />
                  </IconButton>
                </div>
              </Box>
            </Toolbar>
          )}

          {isMobileOrTablet &&
            (isDailyTrialMonitoringTemplate ||
              isNRMPerformanceTemplate ||
              isDataCollectionTemplate ||
              isCostSheetTemplate ||
              isErosionProfileTemplate) && (
              <Toolbar
                variant="dense"
                disableGutters
                style={{ minHeight: 48, position: 'sticky', top: 0, zIndex: 1 }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'end',
                    overflowX: 'auto',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: 'calc(100% - 120px)',
                    }}
                  >
                    {/* <div style={containerStyle}> */}
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '15px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      onClick={goBack}
                    >
                      <BackIcon
                        style={{ fontSize: '24px', color: '#252C32' }}
                      />
                    </div>
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '20px',
                        fontWeight: 600,
                        lineHeight: '24px',
                        letterSpacing: '-0.006em',
                        color: '#252C32',
                      }}
                    >
                      {isDailyTrialMonitoringTemplate
                        ? 'Daily Trial Monitoring'
                        : isNRMPerformanceTemplate
                          ? 'NRM Performance'
                          : isDataCollectionTemplate
                            ? 'Data Collection'
                            : isCostSheetTemplate
                              ? 'Cost Sheet'
                              : isErosionProfileTemplate
                                ? 'Erosion Profile'
                                : 'New Data File Template'}
                    </Typography>
                  </div>
                  {/* </div> */}
                </Box>
              </Toolbar>
            )}

          {isMobileOrTablet &&
            (isViewDailytrial || isViewtrialNRM || isProfileView) && (
              <Toolbar
                variant="dense"
                disableGutters
                style={{ minHeight: 48, position: 'sticky', top: 0, zIndex: 1 }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'end',
                    overflowX: 'auto',
                  }}
                >
                  <div style={containerStyle}>
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '15px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      onClick={goBack}
                    >
                      <BackIcon
                        style={{ fontSize: '24px', color: '#252C32' }}
                      />
                    </div>
                    <div
                      style={{
                        flex: '1',
                        textAlign: 'center',
                        padding: '10px',
                      }}
                    >
                      <Typography
                        variant="h5"
                        component="div"
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '20px',
                          fontWeight: 600,
                          lineHeight: '24px',
                          letterSpacing: '-0.006em',
                          color: '#252C32',
                        }}
                      >
                        {isProfileView ? 'Profile' : 'View Trial'}
                      </Typography>
                    </div>
                  </div>
                </Box>
              </Toolbar>
            )}

          {mobileDrawer}
        </AppBar>
      </div>
    </div>
  ) : null
}
