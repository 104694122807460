import configParam from '../../config'
const API = {
  INSERT_CUSTOMER: configParam.API_URL + 'insert_customer',
  GET_CUSTOMER: configParam.API_URL + 'get_customers',
  UPDATE_CUSTOMER: configParam.API_URL + 'update_customer',
  DELETE_CUSTOMER: configParam.API_URL + 'delete_customer',

  INSERT_USER: configParam.API_URL + 'insert_user',
  GET_USERS: configParam.API_URL + 'get_users',
  UPDATE_USER: configParam.API_URL + 'update_user',
  DELETE_USER: configParam.API_URL + 'delete_user',
  GET_USER_DATA_FROM_ID: configParam.API_URL + 'get_user_info',
  UPDATE_ALL_USERS: configParam.API_URL + 'update_all_users',

  INSERT_FURNACE: configParam.API_URL + 'insert_furnace',
  GET_FURNACES: configParam.API_URL + 'get_furnaces',
  UPDATE_FURNACE: configParam.API_URL + 'update_furnace',
  DELETE_FURNACE: configParam.API_URL + 'delete_furnace',

  ROLE: configParam.API_URL + 'role',
  REGION: configParam.API_URL + 'region',

  UPLOAD_DATAFILE: configParam.API_URL + 'upload_data',
  EXCEL_INSERT: configParam.API_URL + 'excel_insert',
  CARD_DRAFT: configParam.API_URL + 'show_cards_draft',
  CARD_SUBMITTED: configParam.API_URL + 'card_submitted',
  EXCEL_INSERT_NRM: configParam.API_URL + 'excel_insert_nrm',

  GET_CARD_DETAILS: configParam.API_URL + 'getCardDetails',
  GET_SEGMENT: configParam.API_URL + 'getSegment',
  GET_REASON: configParam.API_URL + 'getReason',
  EXCEL_INSERT_EROSION: configParam.API_URL + 'excelinserterosion',
  SHOW_TABLE_EROSION: configParam.API_URL + 'showTableErosion',

  LINING_PATCHING_NO: configParam.API_URL + 'lining_patching_no',
  FORM_INSERT: configParam.API_URL + 'form_insert',
  FORM_INSERT_SYNC: configParam.API_URL + 'form_insert_sync',

  UPDATE_NEW_TRIAL: configParam.API_URL + 'update_new_trial',
  UPLOAD_DATAFILE_MOBILE: configParam.API_URL + 'upload_data_mobile',
  FORM_UPDATE: configParam.API_URL + 'form_update',
  FORM_UPDATE_GENERAL_DETAILS_WEB:
    configParam.API_URL + 'form_update_general_details_web',
  FORM_UPDATE_GENERAL_DETAILS:
    configParam.API_URL + 'form_update_general_details',

  NRM_FORM_UPDATE_LINING_REFERENCE:
    configParam.API_URL + 'nrm_form_update_lining_reference',

  FORM_INSERT_NRM: configParam.API_URL + 'form_insert_nrm',
  FORM_INSERT_SYNC_NRM: configParam.API_URL + 'form_insert_sync_nrm',
  GET_ALL_DAILY_TRIAL_MONITORING: configParam.API_URL + 'get_all_daily_trials',
  GET_DAILY_TRIAL_BY_ID: configParam.API_URL + 'get_daily_trial',
  GET_ALL_UPLAOD_DATA_FILE_INFO:
    configParam.API_URL + 'get_all_upload_datafile_info',
  GET_NRM_PRODUCT_RANGE: configParam.API_URL + 'nrm_product_range',
  GET_SEGMENT: configParam.API_URL + 'segment',
  EXCEL_EROSION: configParam.API_URL + 'excelErosion',

  //data collection

  INSERT_DATA_COLLECTION: configParam.API_URL + 'data_collection_insert',
  GET_ALL_DATA_COLLECTION: configParam.API_URL + 'all_data_collection',
  GET_DATA_COLLECTION_BY_ID: configParam.API_URL + 'get_dataCollection_byID',
  UPDATE_DATA_COLLECTION: configParam.API_URL + 'updateDataCollection',
  INSERT_DATA_COLLECTION_EXCEL:
    configParam.API_URL + 'insertDataCollectionExcel',
  UPLOAD_DATA_COLLECTION_DATAFILE:
    configParam.API_URL + 'upload_data_collection_file',
  VIEW_DATA_COLLECTION: configParam.API_URL + 'view_data_collection',

  //cost calculation
  INSERT_COST_CALCULATION: configParam.API_URL + 'cost_calculation_insert',
  UPDATE_COST_CALCULATION: configParam.API_URL + 'update_cost_sheet',
  GET_ALL_COST_SHEETS: configParam.API_URL + 'all_cost_sheets',
  GET_COST_SHEET_TEMPLATE: configParam.API_URL + 'excel_cost_sheet_download',
  GET_COST_SHEET_BY_ID: configParam.API_URL + 'get_cost_sheet_by_ID',
  INSERT_COST_SHEET_EXCEL: configParam.API_URL + 'insertCostSheetExcel',
  VIEW_COST_SHEET: configParam.API_URL + 'view_cost_sheet',

  // erosion profile
  INSERT_EROSION_PROFILE: configParam.API_URL + 'erosion_profile_insert',
  UPDATE_EROSION_PROFILE: configParam.API_URL + 'erosion_profile_update',
  GET_ALL_EROSION_PROFILE: configParam.API_URL + 'all_erosion_profiles',
  GET_EROSION_PROFILE_BY_ID: configParam.API_URL + 'get_erosion_profile_by_ID',
  DELETE_EROSION_PROFILE: configParam.API_URL + 'delete_erosion_profile',
  INSERT_EROSION_SHEET_EXCEL:
    configParam.API_URL + 'erosion_profile_excel_insert',
  VIEW_EROSION_PROFILE: configParam.API_URL + 'view_erosion_profile',

  //AZURE CLOUD DOWNLOAD
  GET_DAILY_TRIAL_EXCEL_DOWNLOAD:
    configParam.API_URL + 'daily_trial_excel_download',
  GET_NRM_EXCEL_DOWNLOAD: configParam.API_URL + 'nrm_excel_download',
  GET_EROSION_EXCEL_DOWNLOAD:
    configParam.API_URL + 'erosion_profile_excel_download',
  GET_DATA_COLLECTION_EXCEL_DOWNLOAD:
    configParam.API_URL + 'data_collection_excel_download',
  GET_COST_SHEET_EXCEL_DOWNLOAD:
    configParam.API_URL + 'cost_sheet_excel_download',

  GET_TEMPLATES: configParam.API_URL + 'file_template',
  GET_DATA_COLLECTION_TEMPLATE:
    configParam.API_URL + 'excel_data_collection_download',

  DELETE_DATAFILE: configParam.API_URL + 'delete_datafile',
  GET_ALL_NRM_PERFORMANCE: configParam.API_URL + 'get_all_nrm_performance',
  GET_NRM_PERFORMANCE_BY_ID: configParam.API_URL + 'get_nrm_performance',
  UPDATE_NEW_TRIAL_NRM: configParam.API_URL + 'update_new_trail_nrm',
  FORM_UPDATE_NRM: configParam.API_URL + 'form_update_nrm',

  DELETE_DAILY_TRIAL_UPDATE: configParam.API_URL + 'delete_daily_trial',
  DELETE_NRM_PERFORMANCE: configParam.API_URL + 'delete_nrm_performance',
  DELETE_DATA_COLLECTION: configParam.API_URL + 'delete_data_collection',
  DELETE_COST_SHEET: configParam.API_URL + 'delete_cost_sheet',
  APPROVED_CARD_LIST: configParam.API_URL + 'show_approved_cards',
  REJECTED_CARD_LIST: configParam.API_URL + 'show_rejected_cards',
  APPROVED_CARD_UPDATE: configParam.API_URL + 'approved_card_update',
  REJECTED_CARD_UPDATE: configParam.API_URL + 'rejected_card_update',
  VIEW_DAILY_TRIAL: configParam.API_URL + 'view_daily_trial',

  VIEW_NRM_PERFORMANCE: configParam.API_URL + 'view_nrm_performance',

  //  reports portion
  CREATE_REPORT: configParam.API_URL + 'createReport',
  DATABASE_SEARCH: configParam.API_URL + 'databaseSearch',
  DATABASE_SEARCH_NRM: configParam.API_URL + 'databaseSearchNRM',
  REPORTS_EXCEL_INSERT: configParam.API_URL + 'reportsexcelinsert',
  REPORTS_EXCEL_INSERT_NRM: configParam.API_URL + 'nrmreportsexcelinsert',
  REPORTS_CARD_DETAILS: configParam.API_URL + 'showcardsDetails',
  GET_REPORTS_DATA_SETUP: configParam.API_URL + 'getReportsDataSetup',
  GET_REPORTS_DATA_SETUP_NRM: configParam.API_URL + 'getReportsDataSetupNRM',
  SHOW_REPORTS_TABLE: configParam.API_URL + 'showReportsTable',
  GET_REPORTS_DATA_SETUP_EXCELROWDETAILS_DAILYTRIAL:
    configParam.API_URL + 'getDataSetupExcelRowDetailsDailyTrial',
  DATA_SEARCH_LIST: configParam.API_URL + 'dataSearchlist',
  DATA_SEARCH_LIST_NRM: configParam.API_URL + 'dataSearchlistNRM',
  VIEW_COMPLETE_DATA: configParam.API_URL + 'viewCompleteData',
  VIEW_COMPLETE_DATA_NRM: configParam.API_URL + 'viewCompleteDataNRM',
  DATA_SEARCH_VIEW: configParam.API_URL + 'dataSearchView',
  DATA_SEARCH_VIEW_NRM: configParam.API_URL + 'dataSearchViewNRM',
  CHECK_ID_EXIST: configParam.API_URL + 'checkIdExist',
  CHECK_ID_EXIST_NRM: configParam.API_URL + 'checkIdExistNRM',
  DELETE_EXCEL: configParam.API_URL + 'deleteExcel',
  DELETE_EXCEL_NRM: configParam.API_URL + 'deleteExcelNRM',
  DELETE_DB: configParam.API_URL + 'deleteDB',
  DELETE_DB_NRM: configParam.API_URL + 'deleteDBNRM',
  GRAPH: configParam.API_URL + 'graphData',
  GET_GRAPH: configParam.API_URL + 'getGraph',
  UPDATE_GRAPH: configParam.API_URL + 'updateGraph',
  UPDATE_REPORTS: configParam.API_URL + 'updateReports',
  DELETE_REPORTS: configParam.API_URL + 'deleteReports',
  GET_REPORTSICON: configParam.API_URL + 'reportsIcon',
  GET_TEMPLATETAB: configParam.API_URL + 'templateTab',
  // templates from blob storage
  // templates from blob storage

  GET_TEMPLATES_FROM_BLOB: configParam.API_URL + 'get_templates',
  GET_ARRAY_DAILY_TRIAL: configParam.API_URL + 'get_array_daily_trials',
  DELETE_ARRAY_DAILY_TRIAL: configParam.API_URL + 'delete_array_daily_trials',


  // Dashboard

  GET_DTM_MONTHLY_COUNT: configParam.API_URL + "dtmMonthWiseCount",
  GET_NRM_MONTHLY_COUNT: configParam.API_URL + "NRMMonthWiseCount",
  GET_EP_MONTHLY_COUNT: configParam.API_URL + "EPMonthWiseCount",
  GET_COST_SHEET_MONTHLY_COUNT: configParam.API_URL + "costSheetMonthWiseCount",
  GET_DATA_COLLECTION_MONTHLY_COUNT: configParam.API_URL + "dataCollectionMonthWiseCount",

}
export default API
