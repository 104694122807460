import React, { useState, useEffect } from 'react'
import { Typography } from '@mui/material'
import StyledButton from '../../input/StyledButton'
import LiningReference from './LiningReference'
import DimensionalParameters from './DimensionalParameters'
import LiningMaterialConsumption from './LiningMaterialConsumption'
import LiningMaterialProductDetails from './LiningMaterialProductDetails'
import ProductionSummary from './ProductionSummary'
import SinteringParameters from './SinteringParameters'
import AlertDialog from '../../input/AlertDialog'
import {
  lining_reference,
  production_summary,
  lining_material_product_details,
  lining_material_consumption,
  dimensional_parameters,
  sintering_parameters,
  trial_name,
} from '../../../recoil/atoms'
import { useRecoilState, useRecoilValue } from 'recoil'
import axios from 'axios'
import API from '../../services/API'
import { useNavigate } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'

const steps = [
  'Lining Reference',
  'Production Summary',
  'Lining material product details',
  'Lining material consumption',
  'Dimensional Parameters',
  'Sintering Parameters',
]
function NRMPerformance() {
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState(0)
  const [liningReference, setLiningReference] = useRecoilState(lining_reference)
  const [productionSummary, setProductionSummary] =
    useRecoilState(production_summary)
  const [liningMaterialProductDetails, setLiningMaterialProductDetails] =
    useRecoilState(lining_material_product_details)
  const [liningMaterialConsumption, setLiningMaterialConsumption] =
    useRecoilState(lining_material_consumption)
  const [dimensionalParameters, setDimensionalParameters] = useRecoilState(
    dimensional_parameters
  )
  const [sinteringParameters, setSinteringParameters] =
    useRecoilState(sintering_parameters)
  const [loading, setLoading] = useState(false) // Loader state
  const [error, setError] = useState(null) // Error state
  const currentUser = JSON.parse(localStorage.getItem('user'))
  const access_token = localStorage.getItem('accesstoken')

  const [isOnline, setIsOnline] = useState(window.navigator.onLine)

  const [db, setDb] = useState(null)

  useEffect(() => {
    const handleOnline = () => {
      if (!isOnline) {
        setIsOnline(true)
        localStorage.setItem('onlinestate', 1)

        window.location.reload()
      }
    }

    const handleOffline = () => {
      localStorage.setItem('onlinestate', 0)
      // setIsOnlines(false)
      setIsOnline(false)
      // navigate('/reports')
    }

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [isOnline])

  const dbName = 'pcr'
  const customerName = 'customers'
  const furnacesName = 'furnaces'
  const templateName = 'template'
  const segmentName = 'segment'
  const datafileName = 'datafile'
  const dailytrialName = 'daily_trial'
  const nrmName = 'nrm_performance'
  const erosionName = 'erosion_profile'
  const dataCollectionName = 'data_collection'
  const costSheetName = 'cost_sheet'
  const LiningName = 'lining_patching_no'

  useEffect(() => {
    if (!isOnline) {
      const request = indexedDB.open(dbName, 1)

      request.onupgradeneeded = (event) => {
        const database = event.target.result
        database.createObjectStore(customerName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(furnacesName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(templateName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(segmentName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(datafileName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(dailytrialName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(nrmName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(erosionName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(dataCollectionName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(costSheetName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(LiningName, {
          keyPath: 'id',
          autoIncrement: true,
        })
      }

      request.onsuccess = (event) => {
        const database = event.target.result
        const transaction = database.transaction(
          [
            'customers',
            'furnaces',
            'template',
            'segment',
            'datafile',
            'daily_trial',
            'nrm_performance',
            'erosion_profile',
            'data_collection',
            'cost_sheet',
            'lining_patching_no',
          ],
          'readwrite'
        )

        setDb(database)
      }

      request.onerror = (event) => {
        console.error('Error opening IndexedDB', event)
      }
    }
  }, [isOnline])

  const isEdit = localStorage.getItem('Trial')
  console.log('isEditNRMMM', isEdit)

  console.log('liningReference', liningReference)
  console.log('productionSummary', productionSummary)
  console.log('liningMaterialProductDetails', liningMaterialProductDetails)
  console.log('liningMaterialConsumption', liningMaterialConsumption)
  console.log('dimensionalParameters', dimensionalParameters)
  console.log('sinteringParameters', sinteringParameters)

  useEffect(() => {
    if (isOnline) {
      fetchData() // Call the function to fetch data
    }
  }, [isEdit, isOnline])

  useEffect(() => {
    if (db) {
      fetchData()
    }
  }, [db])

  const fetchData = async () => {
    if (isOnline) {
      if (isEdit === 'view' || isEdit === 'edit') {
        setLoading(true)
        setError(null)
        try {
          const id = localStorage.getItem('nrm_performance_id')
          const TrialIndex = localStorage.getItem('TrialIndex')

          const response = await axios.get(
            `${API.GET_NRM_PERFORMANCE_BY_ID}/${id}`,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          )
          const liningReference = response.data.lining_reference[TrialIndex]
          const productionSummary = response.data.production_summary[TrialIndex]
          const liningMaterialProductDetails =
            response.data.lining_material_product_details[TrialIndex]
          const liningMaterialConsumption =
            response.data.lining_material_consumption[TrialIndex]
          const dimensionalParameters =
            response.data.dimensional_parameters[TrialIndex]
          const sinteringParameters =
            response.data.sintering_parameters[TrialIndex]

          setLiningReference(liningReference)
          setProductionSummary(productionSummary)
          setLiningMaterialProductDetails(liningMaterialProductDetails)
          setLiningMaterialConsumption(liningMaterialConsumption)
          setDimensionalParameters(dimensionalParameters)
          setSinteringParameters(sinteringParameters)
        } catch (error) {
          console.error('Error fetching data:', error)
          setError(error.message)
        } finally {
          setLoading(false)
        }
      } else {
        setLiningReference({})
        setProductionSummary({})
        setLiningMaterialProductDetails({})
        setLiningMaterialConsumption({})
        setDimensionalParameters({})
        setSinteringParameters({})
      }
    } else {
      if (isEdit === 'view' || isEdit === 'edit') {
        setLoading(true)
        setError(null)
        try {
          const id = localStorage.getItem('nrm_performance_id')
          const TrialIndex = localStorage.getItem('TrialIndex')

          const response = await axios.get(
            `${API.GET_NRM_PERFORMANCE_BY_ID}/${id}`,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          )
          const liningReference = response.data.lining_reference[TrialIndex]
          const productionSummary = response.data.production_summary[TrialIndex]
          const liningMaterialProductDetails =
            response.data.lining_material_product_details[TrialIndex]
          const liningMaterialConsumption =
            response.data.lining_material_consumption[TrialIndex]
          const dimensionalParameters =
            response.data.dimensional_parameters[TrialIndex]
          const sinteringParameters =
            response.data.sintering_parameters[TrialIndex]

          setLiningReference(liningReference)
          setProductionSummary(productionSummary)
          setLiningMaterialProductDetails(liningMaterialProductDetails)
          setLiningMaterialConsumption(liningMaterialConsumption)
          setDimensionalParameters(dimensionalParameters)
          setSinteringParameters(sinteringParameters)
        } catch (error) {
          console.error('Error fetching data:', error)
          setError(error.message)
        } finally {
          setLoading(false)
        }
      } else {
        setLiningReference({})
        setProductionSummary({})
        setLiningMaterialProductDetails({})
        setLiningMaterialConsumption({})
        setDimensionalParameters({})
        setSinteringParameters({})
      }

      if (isEdit === 'view') {
        setLoading(true)
        setError(null)
        try {
          const id = localStorage.getItem('nrm_performance_id')
          const TrialIndex = localStorage.getItem('TrialIndex')

          const transaction1 = db.transaction(nrmName, 'readonly')
          const objectStore1 = transaction1.objectStore(nrmName)

          const id_int = parseInt(id, 10)
          const getAllRequest1 = objectStore1.get(id_int)

          console.log('id', typeof id)

          // const liningReference = response.data.lining_reference[TrialIndex];
          // const productionSummary =
          //   response.data.production_summary[TrialIndex];
          // const liningMaterialProductDetails =
          //   response.data.lining_material_product_details[TrialIndex];
          // const liningMaterialConsumption =
          //   response.data.lining_material_consumption[TrialIndex];
          // const dimensionalParameters =
          //   response.data.dimensional_parameters[TrialIndex];
          // const sinteringParameters =
          //   response.data.sintering_parameters[TrialIndex];

          getAllRequest1.onsuccess = () => {
            const trialdata = getAllRequest1.result
            console.log('all123', trialdata)
            const liningReference =
              trialdata.data?.[TrialIndex]?.lining_reference || {}
            const productionSummary =
              trialdata.data?.[TrialIndex]?.production_summary || {}
            const liningMaterialProductDetails =
              trialdata.data?.[TrialIndex]?.lining_material_product_details ||
              {}
            const dimensionalParameters =
              trialdata.data?.[TrialIndex]?.dimensional_parameters || {}
            const sinteringParameters =
              trialdata.data?.[TrialIndex]?.sintering_parameters || {}

            console.log('liningReference1233', liningReference)

            setLiningReference(liningReference)
            setProductionSummary(productionSummary)
            setLiningMaterialProductDetails(liningMaterialProductDetails)
            setLiningMaterialConsumption(liningMaterialConsumption)
            setDimensionalParameters(dimensionalParameters)
            setSinteringParameters(sinteringParameters)
          }

          getAllRequest1.onerror = (event) => {
            console.error('Error getting data from IndexedDB', event)
          }
        } catch (error) {
          console.error('Error fetching data:', error)
          setError(error.message)
          // Handle error
        } finally {
          setLoading(false)
        }
      } else {
        setLiningReference({})
        setProductionSummary({})
        setLiningMaterialProductDetails({})
        setLiningMaterialConsumption({})
        setDimensionalParameters({})
        setSinteringParameters({})
      }
    }
  }

  // useEffect(() => {

  //   fetchData(); // Call the function to fetch data
  // }, [isEdit]);

  const handleNext = () => {
    console.log('activeStep', activeStep)
    if (activeStep === 5) {
      submitFormData()
    } else {
      // If it's not the last step, proceed to the next step
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const submitFormData = async () => {
    try {
      let updatedLiningReference

      const datafile_id = localStorage.getItem('datafile_id')

      if (currentUser.role.role_id === 3 || currentUser.role.role_id === 4) {
        updatedLiningReference = {
          ...liningReference,
          submission_status: 'approved',
        }
      } else {
        updatedLiningReference = {
          ...liningReference,
          submission_status: 'submitted',
        }
      }

      try {
        if (isEdit === 'new') {
          AlertDialog({
            type: 'delete',
            title: 'Submission',
            text: 'Are you sure you want to Submit for an Approval?',
            confirmButtonText: 'Yes',
            onConfirm: async () => {
              if (
                currentUser.role.role_id === 3 ||
                currentUser.role.role_id === 4
              ) {
                await insertData(
                  updatedLiningReference,
                  datafile_id,
                  'approved'
                )
              } else {
                await insertData(
                  updatedLiningReference,
                  datafile_id,
                  'submitted'
                )
              }
            },
          })
        } else if (isEdit === 'add') {
          // For example:
          const id = localStorage.getItem('nrm_performance_id')
          AlertDialog({
            type: 'delete',
            title: 'Submission',
            text: 'Are you sure you want to Submit for an Approval?',
            confirmButtonText: 'Yes',
            onConfirm: async () => {
              if (
                currentUser.role.role_id === 3 ||
                currentUser.role.role_id === 4
              ) {
                await addData(
                  updatedLiningReference,
                  datafile_id,
                  id,
                  'approved'
                )
              } else {
                await addData(
                  updatedLiningReference,
                  datafile_id,
                  id,
                  'submitted'
                )
              }
            },
          })
        } else {
          try {
            const id = localStorage.getItem('nrm_performance_id')
            const TrialIndex = parseInt(localStorage.getItem('TrialIndex'))
            AlertDialog({
              type: 'delete',
              title: 'Submission',
              text: 'Are you sure you want to Submit for an Approval?',
              confirmButtonText: 'Yes',
              onConfirm: async () => {
                if (
                  currentUser.role.role_id === 3 ||
                  currentUser.role.role_id === 4
                ) {
                  await updateData(
                    updatedLiningReference,
                    datafile_id,
                    id,
                    TrialIndex,
                    'approved'
                  )
                } else {
                  await updateData(
                    updatedLiningReference,
                    datafile_id,
                    id,
                    TrialIndex,
                    'submitted'
                  )
                }
              },
            })
          } catch (error) {}
        }

        // Optionally, navigate to another page or perform other actions
      } catch (error) {
        // Handle errors
        console.error('An error occurred while inserting data:', error.message)
      }

      // Optionally, navigate to another page or perform other actions
    } catch (error) {
      // Handle errors
      console.error('An error occurred while inserting data123:', error.message)
    }
  }

  const addData = async (updatedLiningReference, datafile_id, id, status) => {
    setLoading(true)
    setError(null)
    try {
      if (isOnline) {
        const res = await axios.get(`${API.GET_NRM_PERFORMANCE_BY_ID}/${id}`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })

        const existingData = res.data
        // Create a new object to add to existingData
        const newTrialObj = {
          lining_reference: updatedLiningReference,
          production_summary: productionSummary,
          lining_material_product_details: liningMaterialProductDetails,
          lining_material_consumption: liningMaterialConsumption,
          dimensional_parameters: dimensionalParameters,
          sintering_parameters: sinteringParameters,
        }
        // Iterate over each key in newTrialObj
        Object.keys(newTrialObj).forEach((key) => {
          existingData[key].push(newTrialObj[key])
        })
        console.log(newTrialObj, 'newTrialObj657')
        console.log(existingData)
        console.log(existingData, 'updated existingData')

        if (status === 'submitted') {
          existingData.lining_reference.forEach((detail) => {
            detail.submission_status = 'submitted'
          })
        } else if (status === 'approved') {
          existingData.lining_reference.forEach((detail) => {
            detail.submission_status = 'approved'
          })
        } else {
          existingData.lining_reference.forEach((detail) => {
            detail.submission_status = 'draft'
          })
        }

        const data = {
          datafile_id: datafile_id,
          data: existingData,
        }
        // Make the POST request using axios
        const response = await axios.put(API.UPDATE_NEW_TRIAL_NRM, data, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        // Handle the response if needed
        console.log('Data updated successfully:', response.data)
        if (status === 'submitted') {
          navigate('/submittedstatus')
        } else if (status === 'approved') {
          navigate('/approvestatus')
        } else {
          navigate('/draftstatus')
        }
      } else {
        // Offline mode: use IndexedDB
        const transaction = db.transaction(nrmName, 'readonly')
        const objectStore = transaction.objectStore(nrmName)

        const id_int = parseInt(id, 10)

        // Define newTrialObj with updated details
        const newTrialObj = {
          lining_reference: updatedLiningReference,
          production_summary: productionSummary,
          lining_material_product_details: liningMaterialProductDetails,
          lining_material_consumption: liningMaterialConsumption,
          dimensional_parameters: dimensionalParameters,
          sintering_parameters: sinteringParameters,
        }

        // Retrieve existing data from IndexedDB
        const getRequest = objectStore.get(id_int)

        getRequest.onsuccess = async () => {
          const existingData = getRequest.result
          console.log('existingData', existingData, typeof existingData)

          Object.keys(newTrialObj).forEach((key) => {
            if (existingData[key] === undefined) {
              existingData[key] = [] // Initialize as an array if it's undefined
            } else if (!Array.isArray(existingData[key])) {
              // Handle cases where existingData[key] is not an array (if needed)
              // For example, convert it to an array or handle differently
              existingData[key] = [existingData[key]] // Convert to array
            }

            existingData[key].push(newTrialObj[key])
          })

          if (Array.isArray(existingData.lining_reference)) {
            existingData.lining_reference.forEach((detail) => {
              if (status === 'submitted') {
                detail.submission_status = 'submitted'
              } else if (status === 'draft') {
                detail.submission_status = 'draft'
              } else {
                detail.submission_status = 'approved'
              }
            })
          } else {
            // If trialdata.general_details is not an array
            existingData.lining_reference = existingData.lining_reference || {} // Ensure it's an object
            if (status === 'submitted') {
              existingData.lining_reference.submission_status = 'submitted'
            } else if (status === 'draft') {
              existingData.lining_reference.submission_status = 'draft'
            } else {
              existingData.lining_reference.submission_status = 'approved'
            }
          }

          const dataToUpdate = {
            ...existingData,
          }

          const transactionUpdate2 = db.transaction(nrmName, 'readwrite')
          const objectStoreUpdate2 = transactionUpdate2.objectStore(nrmName)

          // Update IndexedDB with the updated data
          const updateRequest = objectStoreUpdate2.put(dataToUpdate)

          updateRequest.onsuccess = () => {
            console.log(
              'Offline Mode - Trial data updated successfully:',
              updateRequest.result
            )
            const daily_trial_id = updateRequest.result
            localStorage.setItem('daily_trial_id', daily_trial_id)

            // Navigate based on status
            if (status === 'submitted') {
              navigate('/submittedstatus')
            } else {
              navigate('/draftstatus')
            }
          }

          updateRequest.onerror = () => {
            console.error('Offline Mode - Error updating trial data')
            setError('Error updating trial data in IndexedDB.')
            setLoading(false)
          }
        }

        getRequest.onerror = () => {
          console.error('Offline Mode - Error fetching trial data')
          setError('Error fetching trial data from IndexedDB.')
          setLoading(false)
        }
      }
    } catch (error) {
      setError('Problem with adding data', error.message)
    } finally {
      setLoading(false)
    }
  }

  const updateData = async (
    updatedLiningReference,
    datafile_id,
    id,
    TrialIndex,
    status
  ) => {
    setLoading(true)
    setError(null)
    try {
      if (isOnline) {
        const response = await axios.get(
          `${API.GET_NRM_PERFORMANCE_BY_ID}/${id}`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        const data = response.data

        // Update the values based on the index
        data.lining_reference[TrialIndex] = updatedLiningReference
        data.production_summary[TrialIndex] = productionSummary
        data.lining_material_product_details[TrialIndex] =
          liningMaterialProductDetails
        data.lining_material_consumption[TrialIndex] = liningMaterialConsumption
        data.dimensional_parameters[TrialIndex] = dimensionalParameters
        data.sintering_parameters[TrialIndex] = sinteringParameters

        if (status === 'submitted') {
          data.lining_reference.forEach((detail) => {
            detail.submission_status = 'submitted'
          })
        } else if (status === 'approved') {
          data.lining_reference.forEach((detail) => {
            detail.submission_status = 'approved'
          })
        } else {
          data.lining_reference.forEach((detail) => {
            detail.submission_status = 'draft'
          })
        }
        const updateResponse = await axios.put(
          `${API.FORM_UPDATE_NRM}/${id}`,
          {
            data: data,
            id: id,
          },
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        console.log('Response:', updateResponse.data)
        if (status === 'submitted') {
          navigate('/submittedstatus')
        } else if (status === 'approved') {
          navigate('/approvestatus')
        } else {
          navigate('/draftstatus')
        }
      } else {
        const id = localStorage.getItem('nrmName_trial_id')
        const TrialIndex = localStorage.getItem('TrialIndex')

        const transaction = db.transaction(nrmName, 'readonly')
        const objectStore = transaction.objectStore(nrmName)

        const id_int = parseInt(id, 10)
        const getAllRequest = objectStore.get(id_int)

        getAllRequest.onsuccess = () => {
          const trialdata = getAllRequest.result
          console.log('Offline Mode - Retrieved trialdata:', trialdata)

          // Prepare updated data structure
          const updatedData = {
            id: id_int,
            datafile_id: datafile_id,
            created_at: new Date(),
            isDeleted: false,
            lining_reference: updatedLiningReference,
            production_summary: productionSummary,
            lining_material_product_details: liningMaterialProductDetails,
            lining_material_consumption: liningMaterialConsumption,
            dimensional_parameters: dimensionalParameters,
            sintering_parameters: sinteringParameters,
          }

          // Update IndexedDB with the updated data
          const transactionUpdate = db.transaction(nrmName, 'readwrite')
          const objectStoreUpdate = transactionUpdate.objectStore(nrmName)
          const updateRequest = objectStoreUpdate.put(updatedData)

          updateRequest.onsuccess = () => {
            console.log(
              'Offline Mode - Trial data updated successfully:',
              updateRequest.result
            )
            const nrmName_trial_id = updateRequest.result
            localStorage.setItem('nrmName_trial_id', nrmName_trial_id)

            // Navigate based on status
            if (status === 'submitted') {
              navigate('/submittedstatus')
            } else {
              navigate('/draftstatus')
            }
          }

          updateRequest.onerror = () => {
            console.log('Offline Mode - Error updating trial data')
            setError('Error updating trial data in IndexedDB.')
            setLoading(false)
          }
        }

        getAllRequest.onerror = () => {
          console.log('Offline Mode - Error fetching trial data')
          setError('Error fetching trial data from IndexedDB.')
          setLoading(false)
        }

        //old
      }
    } catch (error) {
      setError(error.message) // Set the error state if an error occurs
    } finally {
      setLoading(false)
    }
  }

  const insertData = async (updatedLiningReference, datafile_id, status) => {
    setLoading(true)
    setError(null)
    try {
      if (isOnline) {
        console.log('updatedLiningReference', updatedLiningReference)
        const data = {
          datafile_id: datafile_id,
          data: [
            // Assuming you have an array of data objects to be inserted
            {
              lining_reference: updatedLiningReference,
              production_summary: productionSummary,
              lining_material_product_details: liningMaterialProductDetails,
              lining_material_consumption: liningMaterialConsumption,
              dimensional_parameters: dimensionalParameters,
              sintering_parameters: sinteringParameters,
            },
          ],
        }

        // Make the POST request using axios
        const response = await axios.post(API.FORM_INSERT_NRM, data, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        const nrm_performance_id = response.data.raw[0].id
        localStorage.setItem('nrm_performance_id', nrm_performance_id)
        // Handle the response if needed
        console.log('Data inserted successfully NRM:', response.data)
      } else {
        const data1 = {
          lining_reference: updatedLiningReference,
          production_summary: productionSummary,
          lining_material_product_details: liningMaterialProductDetails,
          lining_material_consumption: liningMaterialConsumption,
          dimensional_parameters: dimensionalParameters,
          sintering_parameters: sinteringParameters,
          datafile_id: datafile_id,
          created_at: new Date(),
        }
        const transaction = db.transaction(nrmName, 'readwrite')
        const objectStore = transaction.objectStore(nrmName)
        const trialdetailsrequest = objectStore.add(data1)
        trialdetailsrequest.onsuccess = () => {
          console.log(
            'File saved successfully in IndexedDB trialdetails',
            trialdetailsrequest.result
          )
          const nrmName_id = trialdetailsrequest.result
          localStorage.setItem('nrmName_id', nrmName_id)
        }

        trialdetailsrequest.onerror = () => {
          console.log('Error saving file in IndexedDB')
        }
      }

      if (status === 'submitted') {
        navigate('/submittedstatus')
      } else if (status === 'approved') {
        navigate('/approvestatus')
      } else {
        navigate('/draftstatus')
      }
    } catch (error) {
      setError('Problem with inserting data', error)
    } finally {
      setLoading(false)
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleDraft = async () => {
    const updatedLiningReference = {
      ...liningReference,
      submission_status: 'draft',
    }
    const datafile_id = localStorage.getItem('datafile_id')
    try {
      if (isEdit === 'new') {
        console.log('datafile_id', datafile_id)
        await insertData(updatedLiningReference, datafile_id, 'draft')
      } else if (isEdit === 'add') {
        const id = localStorage.getItem('nrm_performance_id')
        await addData(updatedLiningReference, datafile_id, id, 'draft')
      } else {
        const id = localStorage.getItem('nrm_performance_id')
        const TrialIndex = parseInt(localStorage.getItem('TrialIndex'))
        await updateData(
          updatedLiningReference,
          datafile_id,
          id,
          TrialIndex,
          'draft'
        )
      }
      // Optionally, navigate to another page or perform other actions
    } catch (error) {
      // Handle errors
      console.error('An error occurred while inserting data:', error.message)
    }
  }

  const progressText = `${activeStep + 1} of ${steps.length} datas completed`

  const handleStepClick = (index) => {
    setActiveStep(index)
  }

  return (
    <div>
      {error && <div>{error}</div>}
      {loading ? (
        <div
          style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div
          style={{
            width: '100%',
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          <div style={{ flex: 1, overflowY: 'auto' }}>
            <div style={{ marginTop: '30px' }}>
              <Typography
                variant="body1"
                style={{
                  color: '#000000',
                  fontFamily: 'Inter',
                  fontWeight: 600,
                  fontSize: '18px',
                  lineHeight: '20px',
                }}
              >
                {steps[activeStep]}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  marginBottom: '20px',
                  color: '#6E7C87',
                  fontFamily: 'Inter',
                  fontWeight: 400,
                  fontSize: '14px',
                  marginTop: '5px',
                }}
              >
                {progressText}
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '20px',
              }}
            >
              {steps.map((step, index) => (
                <div
                  key={index}
                  style={{ flex: 1, position: 'relative', marginRight: '10px' }}
                >
                  <div
                    onClick={() => handleStepClick(index)}
                    style={{
                      borderBottom: `5px solid ${
                        index === activeStep ? '#0086DC' : '#BDBDBD'
                      }`,
                      width: '100%',
                      marginBottom: '10px',
                      marginTop: '10px',
                      borderRadius: '7px',
                    }}
                  />
                </div>
              ))}
            </div>
            <div>
              {activeStep === steps.length ? (
                <div>
                  <Typography>All steps completed</Typography>
                </div>
              ) : (
                <div>
                  {activeStep === 0 && <LiningReference />}
                  {activeStep === 1 && <ProductionSummary />}
                  {activeStep === 2 && <LiningMaterialProductDetails />}
                  {activeStep === 3 && <LiningMaterialConsumption />}
                  {activeStep === 4 && <DimensionalParameters />}
                  {activeStep === 5 && <SinteringParameters />}
                </div>
              )}
            </div>
          </div>

          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              marginTop: 'auto',
              marginBottom: '10px',
              position: 'sticky',
              bottom: 0,
              backgroundColor: 'white',
              zIndex: 1000,
            }}
          >
            {activeStep !== 0 && (
              <StyledButton
                variant="outlined"
                text="Back"
                onClick={handleBack}
                disabled={activeStep === 0}
                style={{ marginRight: 10 }}
              />
            )}

            <StyledButton
              variant="outlined_blue"
              color="secondary" // Choose your preferred color
              text="Save Draft"
              onClick={handleDraft}
            />
            {/* )} */}
            <StyledButton
              variant="contained"
              color="primary"
              text={
                activeStep === steps.length - 1 ? 'Submit for Approval' : 'Next'
              }
              onClick={handleNext}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default NRMPerformance
