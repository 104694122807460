import React, { useState, useEffect } from 'react'
import TextBox from '../../input/TextBox'
import { raw_materials_energy_details } from '../../../recoil/atoms'
import { useRecoilState, useRecoilValue } from 'recoil'
import { trial_name } from '../../../recoil/atoms'

const RawMaterialsAndEnergy = () => {
  const [trialName] = useRecoilState(trial_name)
  const [energyDetails, setEnergyDetails] = useRecoilState(
    raw_materials_energy_details
  )
  console.log(energyDetails, 'energyDetailsenergyDetails')

  const handleChange = (e) => {
    const { name, value } = e.target
    setEnergyDetails((prevEnergyDetails) => ({
      ...prevEnergyDetails,
      [name]: value,
    }))
  }

  const handleDateSelection = (value, type) => {
    console.log('typee', type)
  }
  const isReadOnly = trialName === 'viewDataCollection'

  return (
    <div style={{ width: '100%' }}>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="type_of_scrap"
          label="Type of Scrap"
          value={energyDetails.type_of_scrap}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>

      <TextBox
        label="Charge Mix Ratio (Scrap : PI : DRI)"
        name="charge_mix_ratio"
        value={energyDetails.charge_mix_ratio}
        onChange={handleChange}
        disabled={isReadOnly}
      />

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="yield_of_charge_mix"
          label="Yield of Charge Mix - (%)"
          isString={true}
          value={energyDetails.yield_of_charge_mix}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="profit"
          label="Profit / kg - (INR/Kg)"
          isString={true}
          value={energyDetails.profit}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="unit_price_of_power"
          label="Unit Price of Power - (INR/kWh)"
          isString={true}
          value={energyDetails.unit_price_of_power}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
    </div>
  )
}
export default RawMaterialsAndEnergy
