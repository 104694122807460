import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Checkbox from '@mui/material/Checkbox'
import { createFilterOptions } from '@mui/material/Autocomplete'

function CustomAutocompleteCheckbox(props) {
  const handleChange = (event, value) => {
    if (props.onChange) {
      // Create a synthetic event to pass to the handleChange function
      const syntheticEvent = {
        target: {
          name: props.organization_name,
          value: value,
        },
      }
      props.onChange(syntheticEvent, value)
    }
  }

  const filterOptions = createFilterOptions({
    stringify: (option) => option.organization_name,
  })

  return (
    <div
      style={{ minWidth: '100%', marginBottom: '16px', position: 'relative' }}
    >
      {props.isLabel && (
        <Typography
          variant="body2"
          style={{ fontSize: '14px', textAlign: 'left', marginBottom: 10 }}
        >
          {props.labelValue}
        </Typography>
      )}
      <Autocomplete
        id={props.organization_name}
        multiple
        options={props.options || []}
        value={props.value || []} // Ensure value is always an array
        onChange={handleChange}
        filterOptions={filterOptions}
        getOptionLabel={(option) => option.organization_name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox checked={selected} style={{ marginRight: 8 }} />
            {option.organization_name}
          </li>
        )}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label={props.inputLabel} />
        )}
        openOnFocus
      />
    </div>
  )
}

export default CustomAutocompleteCheckbox
