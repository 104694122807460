import React, { useState, useEffect } from 'react'
import * as XLSX from 'xlsx'
import configParam from '../../config'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Typography,
  Tooltip,
  Grid,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { v4 as uuidv4 } from 'uuid'
import FileSaver from 'file-saver'
import { Modal } from '@mui/material'

import API from '../services/API'
import axios from 'axios'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
} from 'react-router-dom' // Import Routes
import AutoSelectBox from '../input/AutoSelectBox'
import Box from '@mui/material/Box'
import AlertDialog from '../input/AlertDialog'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'

import UploadIcon from '../../assets/icons/ic_cloud.svg'

import ReportAnalysis from './ReportsAnalysis'
import Helper from '../services/Helper'

const moment = require('moment')

const ApiURL = configParam.API_URL
const Reports = () => {
  const navigate = useNavigate()
  const [showModals, setShowModals] = useState(false)
  const [reportName, setReportName] = useState('')
  const [desc, setDesc] = useState('')
  const [minDate, setMinDate] = useState('')
  const [maxDate, setMaxDate] = useState('')
  // const [templateId, setTemplateId] = useState(0);
  const [templates, setTemplates] = useState([])
  const [fileTemplate, setfileTemplate] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState('')
  const [templateError, setTemplateError] = useState('')
  const [selectedGrade, setSelectedGrade] = useState('')
  const [selectedLiningNumber, setSelectedLiningNumber] = useState('')
  const [selectedMinimumYield, setSelectedMinimumYield] = useState('')
  const [selectedMaximumYield, setSelectedMaximumYield] = useState('')
  const [selectedMinimumTappedTonnage, setSelectedMinimumTappedTonnage] =
    useState('')
  const [selectedMaximumTappedTonnage, setSelectedMaximumTappedTonnage] =
    useState('')

  const [file, setFile] = useState(null)
  const [match_template, setMatchTemplate] = useState('')

  const [furnaceCapacity, setFurnaceCapacity] = useState('')
  const [customer, setCustomer] = useState('')
  const [isDatabaseSearchSelected, setIsDatabaseSearchSelected] =
    useState(false)
  const [isUploadFileSelected, setIsUploadFileSelected] = useState(false)
  const [reportCardDetails, setReportCardDetails] = useState([])
  const [formErrors, setFormErrors] = useState({})
  const [customers, setCustomers] = useState([])
  const access_token = localStorage.getItem('accesstoken')

  const [formData, setFormData] = useState({
    customer_id: '',
    furnace_id: '',
    template_id: '',
  })

  const [furnaces, setFurnaces] = useState([])
  const handleModalOpens = () => {
    localStorage.removeItem('reportCardId')
    localStorage.removeItem('reports_id')
    setShowModals(true)
    fetchCustomers()
    fetchTemplates()
  }

  const fetchTemplates = async () => {
    try {
      const response = await axios.get(API.GET_TEMPLATES) // Adjust the URL as needed
      //console.log("responsetemplates", response);
      setTemplates(response.data) // Update state with fetched data
    } catch (error) {
      //console.error("Error fetching data:", error);
    }
  }
  const fetchCustomers = async () => {
    try {
      const response = await axios.get(API.GET_CUSTOMER, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }) // Adjust the URL as needed
      //console.log("response", response);
      setCustomers(response.data) // Update state with fetched data
    } catch (error) {
      //console.error("Error fetching data:", error);
    }
  }

  const handleChange = async (event) => {
    const { name, value } = event.target

    // If the name is 'customer_id', directly set the value in the state
    if (name === 'customer_id') {
      setFormData({ ...formData, [name]: value })
      await fetchFurnaceData(value)
    } else {
      // Otherwise, update the form data as usual
      setFormData({ ...formData, [name]: value })
    }
  }

  useEffect(() => {
    if (formData.template_id !== '') {
      const matchedTemplate = templates.find(
        (template) => template.id === formData.template_id
      )
      if (matchedTemplate) {
        const { template_id } = matchedTemplate
        setMatchTemplate(template_id)
        // localStorage.setItem('match_template_id', template_id);
      }
    }
  }, [formData.template_id, templates])

  const handleModalCloses = () => {
    setShowModals(false)
    window.location.reload()
    // setReportName('');
    // setDesc('');
    // setFurnaceCapacity('');
    // setCustomers('');
    // setFurnaces('');
  }

  const handleFileUploads = (e) => {
    e.preventDefault()
    const uploadedFiles = e.target.files
    //console.log("Uploaded Files:", uploadedFiles);
    if (uploadedFiles.length === 0) {
      // No file selected, set file state to null
      setFile(null)
      return
    }

    const uploadedFile = uploadedFiles[0]
    //console.log("Uploaded File:", uploadedFile);
    setFile(uploadedFile)
  }

  const handleButtonClick = (e) => {
    //console.log("button cliked")
    e.preventDefault()
    // Trigger file input click event
    const fileInput = document.getElementById('file-input')
    fileInput.click()
    // handleFormSubmit();
  }

  const getCustomerName = async () => {
    const getCustomerDetails = customers.find(
      (customer) => customer.id === formData.customer_id
    )
    const customerName = getCustomerDetails.organization_name
    const trigram = Helper.getCutomerTrigram(customerName)
    //console.log(trigram,'trigramtrigram');
    return trigram
  }

  const fetchFurnaceData = async (value) => {
    try {
      const response = await axios.get(API.GET_FURNACES, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      //console.log("furnace response", response);
      //console.log("value", value);
      //   const matchingFurnace = response.data.find(furnace => furnace.assign_customer_id === value);
      const matchingFurnaces = response.data.filter(
        (furnace) => furnace.assign_customer_id === value
      )

      if (matchingFurnaces) {
        //console.log("Matching Furnace:", matchingFurnaces);
        const matchingFurnaceArr = matchingFurnaces
        setFurnaces(matchingFurnaceArr)
        // Do something with the matching furnace object
      } else {
        setFurnaces([])
        //console.log("No matching furnace found");
      }
      //   setFurnaces(response.data);
    } catch (error) {
      //console.error("Error fetching users:", error);
    }
  }

  const handleFormSubmit = async (e) => {
    if (minDate && !maxDate) {
      alert('Maximum Date is required when Minimum Date is selected.')
      return
    }

    if (!minDate && maxDate) {
      alert('Minimum Date is required when Maximum Date is selected.')
      return
    }

    if (selectedMinimumYield && !selectedMaximumYield) {
      alert('Maximum Yield is required when Minimum Yield is selected.')
      return
    }

    if (!selectedMinimumYield && selectedMaximumYield) {
      alert('Minimum Yield is required when Maximum Yield is selected.')
      return
    }

    if (selectedMinimumTappedTonnage && !selectedMaximumTappedTonnage) {
      alert(
        'Maximum Tapped Tonnage is required when Minimum Tapped Tonnage is selected.'
      )
      return
    }

    if (!selectedMinimumTappedTonnage && selectedMaximumTappedTonnage) {
      alert(
        'Minimum Tapped Tonnage is required when Maximum Tapped Tonnage is selected.'
      )
      return
    }

    //console.log('hello:')
    // navigate('/ReportAnalysis')
    //   if (!selectedTemplate) {
    //     setTemplateError('Please select a template');
    //     return;
    // }
    try {
      const jsonData = {
        report_name: reportName,
        description: desc,
        ...formData,
      }

      //console.log("jsonDatajsonDatajsonData",jsonData);
      const create_response = await axios.post(API.CREATE_REPORT, jsonData)
      //console.log("create_response", create_response);
      const id = create_response.data.data.id
      //console.log("reports_id", id);
      localStorage.setItem('reports_id', id)
      if (create_response.status === 200) {
        AlertDialog({
          type: 'success',
          title: 'Success',
          text: 'Uploaded Successfully!',
          confirmButtonText: 'Ok',
          onConfirm: async () => {
            window.location.reload()
          },
        })
      } else {
        // //console.error('Error saving data:', response.data);
        AlertDialog({
          type: 'warning',
          title: 'Error',
          text: 'Failed to save data',
        })
      }
      setShowModals(false)

      const reports_id = localStorage.getItem('reports_id')
      const matchedTemplate = templates.find(
        (template) => template.id === formData.template_id
      )
      //console.log("matchedTemplate", matchedTemplate);
      const match_template_id = matchedTemplate.template_id
      localStorage.setItem('match_template_id', match_template_id)

      //console.log("match_template_id", match_template_id);

      const userString = localStorage.getItem('user')

      // Parse the JSON string into a JavaScript object
      const user = JSON.parse(userString)
      //console.log("user",user);
      const created_by = user.firstname + ' ' + user.lastname
      //console.log("created_by",created_by);

      if (isDatabaseSearchSelected && reports_id) {
        if (match_template_id === 1) {
          //console.log("isDatabaseSearchSelected") ;
          const jsonDatabaseSearch = {
            reports_id: reports_id,
            created_by: created_by,
            customer_id: formData.customer_id,
            database_name: reportName,
            furnace_id: formData.furnace_id,
            from_date: minDate,
            to_date: maxDate,
            grade: selectedGrade,
            lining_patching: selectedLiningNumber,
            min_yield: selectedMinimumYield,
            max_yield: selectedMaximumYield,
            min_tapped_tonnage: selectedMinimumTappedTonnage,
            max_tapped_tonnage: selectedMaximumTappedTonnage,
            template_id: formData.template_id,
          }

          //console.log("jsonDatabaseSearch", jsonDatabaseSearch) ;

          const result = await axios.post(
            API.DATABASE_SEARCH,
            jsonDatabaseSearch
          )

          if (result.status === 200) {
            AlertDialog({
              type: 'success',
              title: 'Success',
              text: 'Uploaded Successfully!',
              confirmButtonText: 'Ok',
              onConfirm: async () => {
                window.location.reload()
              },
            })
            setShowModals(false)
          } else {
            // //console.error('Error saving data:', result.data);
            AlertDialog({
              type: 'warning',
              title: 'Error',
              text: 'Failed to save data',
            })
          }
        } else if (match_template_id === 2) {
          //console.log("isDatabaseSearchSelected") ;
          const jsonDatabaseSearch = {
            reports_id: reports_id,
            created_by: created_by,
            customer_id: formData.customer_id,
            database_name: reportName,
            furnace_id: formData.furnace_id,
            from_date: minDate,
            to_date: maxDate,
            crucible: selectedGrade,
            liningPatching: selectedLiningNumber,
            template_id: formData.template_id,
          }

          //console.log("jsonDatabaseSearch", jsonDatabaseSearch) ;

          const result = await axios.post(
            API.DATABASE_SEARCH_NRM,
            jsonDatabaseSearch
          )

          if (result.status === 200) {
            AlertDialog({
              type: 'success',
              title: 'Success',
              text: 'Uploaded Successfully!',
              confirmButtonText: 'Ok',
              onConfirm: async () => {
                window.location.reload()
              },
            })
            setShowModals(false)
          } else {
            //console.error('Error saving data:', result.data);
            AlertDialog({
              type: 'warning',
              title: 'Error',
              text: 'Failed to save data',
            })
          }
        }
        // Add validation logic for database search fields
        // if (!minDate || !maxDate) {
        //   setDatabaseSearchError('Please select both minimum and maximum dates');
        //   return;
        // }
        // // Reset error message for database search fields
        // setDatabaseSearchError('');
      } else if (isUploadFileSelected && reports_id) {
        //console.log("good");
        //console.log("file",file);
        if (file) {
          //console.log("good");
          if (match_template_id === 1) {
            //console.log("good");
            await handleExtractData(match_template_id, file)
            const extractedData = JSON.parse(
              localStorage.getItem('extractedData')
            )
            //console.log("extractedData", extractedData);

            const predefinedHeaders1 = [
              'Furnace No.',
              'Crucible No.',
              'Campaign No.',
              'Lining / Patching No',
              'Heat No',
              'Date',
              'Customer Ref No',
              'Grade',
              'Heat ON Time',
              'Heat TAP Time',
              'Breakdown duration',
              'Heat Duration actual',
              'Heat Duration calculated',
              'Tapping temperature',
              'Tapped tonnage',
              'Melt Rate based on actual duration',
              'Melt Rate based on calculated duration',
              'Frequency',
              'Voltage',
              'Power In the panel',
              'Capacitors Removed',
              'Total Power Consumed',
              'Energy consumption per unit Ton actual',
              'Energy consumption per unit Ton calculated',
              'Scrap',
              'Shredded',
              'Pig Iron',
              'Pooled Iron',
              'Hot Heel',
              'Lump',
              'Pellet',
              'Fines less than 5 mm',
              'Fines less than 1 mm',
              'Slag Crushers',
              'Tundish or Slag or Metalic Jam',
              'Total charge',
              'CPC ',
              'Met Coke',
              '50 C%',
              '85 C%',
              'Final C%',
              'Si% Before addition',
              'Final Si%',
              'Mn% before Addition',
              'Final Mn%',
              'Cr% before addition',
              'Final Cr%',
              'SiMn',
              'Mn % in SiMn',
              'FeMn',
              'FeSi',
              'FeCr',
              'Mill scale',
              'Any other Alloy addition',
              'Inlet water temp.',
              'Outlet Water temp.',
              'Water Pr.',
              'Remarks',
            ] // Define your predefined headers here
            const predefinedHeaders2 = [
              'Furnace No.',
              'Crucible No.',
              'Campaign No.',
              'Lining / Patching No',
              'Heat No',
              'Date',
              'Customer Ref No',
              'Grade',
              'Heat ON Time',
              'Heat TAP Time',
              'Breakdown duration',
              'Heat Duration actual',
              'Heat Duration calculated',
              'Tapping temperature',
              'Tapped tonnage',
              'Melt Rate based on actual duration',
              'Melt Rate based on calculated duration',
              'Frequency',
              'Voltage',
              'Power In the panel',
              'Capacitors Removed',
              'Total Power Consumed',
              'Energy consumption per unit Ton actual',
              'Energy consumption per unit Ton calculated',
              'Scrap',
              'Shredded',
              'Pig Iron',
              'Pooled Iron',
              'Hot Heel',
              'Lump',
              'Pellet',
              'Fines less than 5 mm',
              'Fines less than 1 mm',
              'Slag Crushers',
              'Tundish or Slag or Metalic Jam',
              'Total charge',
              'Anthracite',
              'Met Coke',
              '50 C%',
              '85 C%',
              'Final C%',
              'Si% Before addition',
              'Final Si%',
              'Mn% before Addition',
              'Final Mn%',
              'Cr% before addition',
              'Final Cr%',
              'SiMn',
              'Mn % in SiMn',
              'FeMn',
              'FeSi',
              'FeCr',
              'Mill scale',
              'Any other Alloy addition',
              'Inlet water temp.',
              'Outlet Water temp.',
              'Water Pr.',
              'Remarks',
            ]
            const predefinedHeaders3 = [
              'Furnace No.',
              'Crucible No.',
              'Campaign No.',
              'Lining / Patching No',
              'Heat No',
              'Date',
              'Customer Ref No',
              'Grade',
              'Heat ON Time',
              'Heat TAP Time',
              'Breakdown duration',
              'Heat Duration actual',
              'Heat Duration calculated',
              'Tapping temperature',
              'Tapped tonnage',
              'Melt Rate based on actual duration',
              'Melt Rate based on calculated duration',
              'Frequency',
              'Voltage',
              'Power In the panel',
              'Capacitors Removed',
              'Total Power Consumed',
              'Energy consumption per unit Ton actual',
              'Energy consumption per unit Ton calculated',
              'Scrap',
              'Shredded',
              'Pig Iron',
              'Pooled Iron',
              'Hot Heel',
              'Lump',
              'Pellet',
              'Fines less than 5 mm',
              'Fines less than 1 mm',
              'Slag Crushers',
              'Tundish or Slag or Metalic Jam',
              'Total charge',
              'Carbon block ',
              'Met Coke',
              '50 C%',
              '85 C%',
              'Final C%',
              'Si% Before addition',
              'Final Si%',
              'Mn% before Addition',
              'Final Mn%',
              'Cr% before addition',
              'Final Cr%',
              'SiMn',
              'Mn % in SiMn',
              'FeMn',
              'FeSi',
              'FeCr',
              'Mill scale',
              'Any other Alloy addition',
              'Inlet water temp.',
              'Outlet Water temp.',
              'Water Pr.',
              'Remarks',
            ]
            const extractedHeaders = extractedData.headers
            //console.log("extractedHeaders", extractedHeaders);

            function arraysEqual(arr1, arr2) {
              // Check if any item in arr1 exists in arr2
              return arr1.some((item) => arr2.includes(item))
            }

            const headersMatch =
              arraysEqual(predefinedHeaders1, extractedHeaders) ||
              arraysEqual(predefinedHeaders2, extractedHeaders) ||
              arraysEqual(predefinedHeaders3, extractedHeaders)

            if (headersMatch) {
              //console.log("good");

              const Data = extractedData.data
              //console.log("Data",Data);
              //console.log("reports_idreports_id",reports_id);
              if (reports_id && Data) {
                //console.log("good");
                const general_details = []
                const furnace_details = []
                const charge_mix_details = []
                const carbon = []
                const alloying_addition = []
                const other_parameters = []
                const convertToHHMM = (value) => {
                  const totalHours = value * 24
                  const hours = Math.floor(totalHours)
                  const minutes = Math.round((totalHours - hours) * 60)
                  const formattedHours = String(hours)
                  const formattedMinutes = String(minutes).padStart(2, '0')
                  return `${formattedHours}:${formattedMinutes}`
                }

                const formatDecimal = (value) => {
                  // Ensure at least 5 digits before the decimal point
                  let integerValue = Math.abs(value)
                  if (integerValue >= 100000) {
                    integerValue = Math.round(integerValue / 1000) * 1000 // Round off to the nearest thousand
                  }

                  if (!Number.isInteger(value)) {
                    return Number(value).toFixed(2)
                  } else {
                    return `${value}.00`
                  }
                }

                const formatPercentage = (value) => {
                  const formattedPercentage = (value * 100).toFixed(2) + '%'
                  return `${formattedPercentage}`
                }

                const decimalHoursToHHMM = (value) => {
                  // Convert decimal hours to total minutes
                  var totalMinutes = value * 60

                  // Calculate hours and minutes
                  var hours = Math.floor(totalMinutes / 60)
                  var minutes = Math.round(totalMinutes % 60)

                  // If minutes is 60, increment hours and set minutes to 0
                  if (minutes === 60) {
                    hours++
                    minutes = 0
                  }

                  // Format the output as HH:mm
                  var formattedTime =
                    hours.toString().padStart(2, '0') +
                    ':' +
                    minutes.toString().padStart(2, '0')

                  return formattedTime
                }

                const formatDate = (value) => {
                  // Convert value to a JavaScript Date object
                  const date = new Date(
                    value * 24 * 60 * 60 * 1000 + Date.UTC(1899, 11, 30)
                  )

                  // Extract day, month, and year from the Date object
                  const day = date.getUTCDate()
                  const month = date.getUTCMonth() + 1 // Month is zero-based
                  const year = date.getUTCFullYear()

                  // Format day and month with single digits if necessary
                  const formattedDay = day < 10 ? '0' + day : day
                  const formattedMonth = month < 10 ? '0' + month : month

                  // Return the date in the desired format
                  return `${formattedMonth}/${formattedDay}/${year}`
                }

                // Data.forEach((entry) => {
                await Promise.all(
                  Data.map(async (entry) => {
                    //console.log("good");

                    const entryId = uuidv4()
                    //console.log("entryId", entryId);
                    const customerName = await getCustomerName()
                    // //console.log("customerName", customerName);

                    function getAbbreviatedLiningType(liningType) {
                      if (!liningType) return '' // Check if liningType is undefined or empty
                      let abbreviated = ''
                      liningType.split(' ').forEach((word) => {
                        abbreviated += word.charAt(0).toUpperCase()
                      })
                      return abbreviated
                    }

                    const lorp_id =
                      customerName +
                      (entry['Furnace No.'] ? entry['Furnace No.'] : '') +
                      '-' +
                      (entry['Crucible No.'] ? entry['Crucible No.'] : '') +
                      '-' +
                      (entry['Campaign No.'] ? entry['Campaign No.'] : '') +
                      '-' +
                      getAbbreviatedLiningType(entry['Lining / Patching No'])

                    const isEmptyGeneralDetails =
                      !entry['Furnace No.'] &&
                      !entry['Crucible No.'] &&
                      !entry['Campaign No.'] &&
                      !entry['Lining / Patching No']

                    if (!isEmptyGeneralDetails) {
                      //console.log("good");

                      general_details.push({
                        id: entryId,
                        furnace_no: entry['Furnace No.'],
                        crucible_no: entry['Crucible No.'],
                        campaign_no: entry['Campaign No.'],
                        lining_patch_no: entry['Lining / Patching No'],
                        heat_no: entry['Heat No'],
                        date: formatDate(entry['Date']),
                        customer_ref_no: entry['Customer Ref No'],
                        grade: entry['Grade'],
                        isdelete: false,
                        lorp_id: lorp_id,
                      })

                      furnace_details.push({
                        id: entryId,
                        heat_on_time: convertToHHMM(entry['Heat ON Time']),
                        heat_tap_time: convertToHHMM(entry['Heat TAP Time']),
                        break_down_duration: decimalHoursToHHMM(
                          entry['Breakdown duration']
                        ),

                        heat_duration_actual: convertToHHMM(
                          entry['Heat Duration actual']
                        ),
                        heat_duration_calculated: convertToHHMM(
                          entry['Heat Duration calculated']
                        ),
                        tapping_temperature: formatDecimal(
                          entry['Tapping temperature']
                        ),
                        tapped_tonnage: formatDecimal(entry['Tapped tonnage']),
                        melt_rate_based_on_actual_duration: formatDecimal(
                          entry['Melt Rate based on actual duration']
                        ),
                        melt_rate_based_on_calculated_duration: formatDecimal(
                          entry['Melt Rate based on calculated duration']
                        ),
                        frequency: formatDecimal(entry['Frequency']),
                        voltage: formatDecimal(entry['Voltage']),
                        power_in_the_panel: formatDecimal(
                          entry['Power In the panel']
                        ),
                        capacitors_removed: formatDecimal(
                          entry['Capacitors Removed']
                        ),
                        total_power_consumed: formatDecimal(
                          entry['Total Power Consumed']
                        ),
                        energy_consumption_per_unit_ton_actual: formatDecimal(
                          entry['Energy consumption per unit Ton actual']
                        ),
                        energy_consumption_per_unit_ton_calculated:
                          formatDecimal(
                            entry['Energy consumption per unit Ton calculated']
                          ),
                      })

                      charge_mix_details.push({
                        id: entryId,
                        scrap: formatDecimal(entry['Scrap']),
                        shredded: formatDecimal(entry['Shredded']),
                        pig_iron: formatDecimal(entry['Pig Iron']),
                        pooled_iron: formatDecimal(entry['Pooled Iron']),
                        hot_heel: formatDecimal(entry['Hot Heel']),
                        lump: formatDecimal(entry['Lump']),
                        pellet: formatDecimal(entry['Pellet']),
                        fines_lessthan_5mm: formatDecimal(
                          entry['Fines less than 5 mm']
                        ),
                        fines_lessthan_1mm: formatDecimal(
                          entry['Fines less than 1 mm']
                        ),
                        slag_crushers: formatDecimal(entry['Slag Crushers']),
                        tundish_or_slag_or_metalic_jam: formatDecimal(
                          entry['Tundish or Slag or Metalic Jam']
                        ),
                        total_charge: formatDecimal(entry['Total charge']),
                      })

                      let value
                      let key
                      if (entry['Anthracite']) {
                        key = 'anthracite'
                        value = formatDecimal(entry['Anthracite'])
                      } else if (entry['Carbon block']) {
                        key = 'carbon_block'
                        value = formatDecimal(entry['Carbon block'])
                      } else if (entry['CPC ']) {
                        key = 'cpc'
                        value = formatDecimal(entry['CPC '])
                      } else {
                        key = 'anthracite'
                        value = formatDecimal(entry['Anthracite'])
                      }

                      carbon.push({
                        id: entryId,
                        [key]: value,
                        met_coke: formatDecimal(entry['Met Coke']),
                        fifty_c_percentage: formatDecimal(entry['50 C%']),
                        eightyfive_c_percentage: formatDecimal(entry['85 C%']),
                        final_c_percentage: formatDecimal(entry['Final C%']),
                      })

                      alloying_addition.push({
                        id: entryId,
                        si_before_alloy_addition: formatPercentage(
                          entry['Si% Before addition']
                        ),
                        final_si: formatPercentage(entry['Final Si%']),
                        mn_before_alloy_addition: formatPercentage(
                          entry['Mn% before Addition']
                        ),
                        final_mn: formatPercentage(entry['Final Mn%']),
                        cr_before_alloy_addition: formatPercentage(
                          entry['Cr% before addition']
                        ),
                        final_cr: formatPercentage(entry['Final Cr%']),
                        si_mn: formatDecimal(entry['SiMn']),
                        mn_in_simn: entry['Mn % in SiMn'],
                        feMn: formatDecimal(entry['FeMn']),
                        feSi: formatDecimal(entry['FeSi']),
                        feCr: formatDecimal(entry['FeCr']),
                        mill_scale: formatDecimal(entry['Mill scale']),
                        any_other_alloy_addition:
                          entry['Any other Alloy addition'],
                      })

                      other_parameters.push({
                        id: entryId,
                        inlet_water_temp: formatDecimal(
                          entry['Inlet water temp.']
                        ),
                        outlet_water_temp: formatDecimal(
                          entry['Outlet Water temp.']
                        ),
                        water_pr: formatDecimal(entry['Water Pr.']),
                        remarks: entry['Remarks'],
                      })
                    }
                  })
                )
                //console.log("good");

                //console.log('general_detailsgeneral_details',general_details);

                const jsonDataexcel = Data.map((entry, index) => ({
                  general_details: general_details[index],
                  furnace_details: furnace_details[index],
                  charge_mix_details: charge_mix_details[index],
                  carbon: carbon[index],
                  alloying_addition: alloying_addition[index],
                  other_parameters: other_parameters[index],
                }))

                //console.log("jsonexxcel", jsonDataexcel);
                //console.log("jsonexxcel");

                const userString = localStorage.getItem('user')

                // Parse the JSON string into a JavaScript object
                const user = JSON.parse(userString)
                //console.log("user",user);
                const created_by = user.firstname + ' ' + user.lastname
                //console.log("created_by",created_by);

                axios
                  .post(
                    API.REPORTS_EXCEL_INSERT,
                    {
                      jsonDataexcel: jsonDataexcel,
                      reports_id: reports_id,
                      created_by: created_by,
                      customer_id: formData.customer_id,
                      file_name: file.name,
                      template_id: formData.template_id,
                    },
                    { headers: { 'Content-Type': 'application/json' } }
                  )
                  .then((excelresponse) => {
                    if (excelresponse.status === 200) {
                      AlertDialog({
                        type: 'success',
                        title: 'Success',
                        text: 'Uploaded Successfully!',
                        confirmButtonText: 'Ok',
                        onConfirm: async () => {
                          window.location.reload()
                        },
                      })
                    } else {
                      //console.error('Error saving data:', excelresponse.data);
                      AlertDialog({
                        type: 'warning',
                        title: 'Error',
                        text: 'Failed to save data',
                      })
                    }
                    //console.log("Excel data inserted successfully:", excelresponse);
                  })
                  .catch((error) => {
                    //console.log('An error occurred while inserting Excel data:', error);
                    AlertDialog({
                      type: 'error',
                      title: 'Error',
                      text: 'An error occurred while inserting Excel data',
                    })
                  })
              }
            } else {
              alert('Please attach the proper template')
              setShowModals(false)

              return
            }
          } else if (match_template_id === 2) {
            await handleExtractData(match_template_id, file)
            const extractedData = JSON.parse(
              localStorage.getItem('extractedData')
            )
            //console.log("extractedData", extractedData);
            const predefinedHeaders = [
              'S.No.',
              'Furnace No.',
              'Crucible No.',
              'Campaign No.',
              'Lining or Patching',

              'Date of Completion lining or Patching',
              'Fce prod start date',
              'Fce prod start time',

              'Fce prod down date',
              'Fce prod down time',
              'Life',
              'Average Heat size',
              'Total Furnace Running Hours',

              'Total Production',
              'Total Furnace Running Hours',
              'Bottom Product',
              'Sidewall product 1 ',

              'Sidewall product 2',
              'Top Locking product',
              'Topping Mass Product',
              'Side wall 1  Coverage upto X%',

              'Side wall 2 Coverage upto 100%',
              'NRM consumption\r\nbottom',
              'NRM consumption Sidewall 1',

              'NRM consumption Sidewall 2',
              'NRM consumption at Top Locking',
              'Topping mass consumption',

              'Total NRM  consumption sidewall ',
              'NRM Specific consumption',
              'Former Dimension',

              'Coil coat ID',
              'Fce Ht.',
              'Castable top Ht.',
              'Bottom ht.',
              'Total Sintering time ',

              'holding time ',
              'holding temp ',
              'Reason for Down',
              'IR no. ',
              'Job Done by',
              'Remarks',
            ] // Define your predefined headers here
            const extractedHeaders = extractedData.headers
            function arraysEqual(arr1, arr2) {
              // Check if the arrays have the same length
              if (arr1.length !== arr2.length) return false

              // Check if all items are equal and in the same order
              for (let i = 0; i < arr1.length; i++) {
                if (arr1[i] !== arr2[i]) return false
              }

              return true
            }

            const headersMatch = arraysEqual(
              predefinedHeaders,
              extractedHeaders
            )
            //console.log("headersMatchheadersMatchheadersMatch", headersMatch);
            if (headersMatch) {
              const Data = extractedData.data
              //console.log("Data",Data);

              if (reports_id && Data) {
                const lining_reference = []
                const production_summary = []
                const lining_material_product_details = []
                const lining_material_consumption = []
                const dimensional_parameters = []
                const sintering_parameters = []
                const convertToHHMM = (value) => {
                  const totalHours = value * 24
                  const hours = Math.floor(totalHours)
                  const minutes = Math.round((totalHours - hours) * 60)
                  const formattedHours = String(hours)
                  const formattedMinutes = String(minutes).padStart(2, '0')
                  return `${formattedHours}:${formattedMinutes}`
                }

                const formatDecimal = (value) => {
                  // Ensure at least 5 digits before the decimal point
                  let integerValue = Math.abs(value)
                  if (integerValue >= 100000) {
                    integerValue = Math.round(integerValue / 1000) * 1000 // Round off to the nearest thousand
                  }

                  if (!Number.isInteger(value)) {
                    return Number(value).toFixed(2)
                  } else {
                    return `${value}.00`
                  }
                }

                const formatPercentage = (value) => {
                  const formattedPercentage = (value * 100).toFixed(2) + '%'
                  return `${formattedPercentage}`
                }

                const decimalHoursToHHMM = (value) => {
                  // Convert decimal hours to total minutes
                  var totalMinutes = value * 60

                  // Calculate hours and minutes
                  var hours = Math.floor(totalMinutes / 60)
                  var minutes = Math.round(totalMinutes % 60)

                  // If minutes is 60, increment hours and set minutes to 0
                  if (minutes === 60) {
                    hours++
                    minutes = 0
                  }

                  // Format the output as HH:mm
                  var formattedTime =
                    hours.toString().padStart(2, '0') +
                    ':' +
                    minutes.toString().padStart(2, '0')

                  return formattedTime
                }

                const formatDate = (value) => {
                  // Convert value to a JavaScript Date object
                  const date = new Date(
                    value * 24 * 60 * 60 * 1000 + Date.UTC(1899, 11, 30)
                  )

                  // Extract day, month, and year from the Date object
                  const day = date.getUTCDate()
                  const month = date.getUTCMonth() + 1 // Month is zero-based
                  const year = date.getUTCFullYear()

                  // Format day and month with single digits if necessary
                  const formattedDay = day < 10 ? '0' + day : day
                  const formattedMonth = month < 10 ? '0' + month : month

                  // Return the date in the desired format
                  return `${formattedMonth}/${formattedDay}/${year}`
                }

                await Promise.all(
                  Data.map(async (entry) => {
                    const entryId = uuidv4()
                    const customerName = await getCustomerName()
                    // const entryId = uuidv4();
                    const isEmptyGeneralDetails =
                      !entry['Furnace No.'] &&
                      !entry['Crucible No.'] &&
                      !entry['Campaign No.'] &&
                      !entry['Lining or Patching']

                    function getAbbreviatedLiningType(liningType) {
                      // Convert liningType to string if it's not already
                      const liningTypeStr = String(liningType)

                      // Split the string and build the abbreviation
                      let abbreviated = ''
                      liningTypeStr.split(' ').forEach((word) => {
                        if (word) {
                          // Ensure the word is not empty
                          abbreviated += word.charAt(0).toUpperCase()
                        }
                      })

                      return abbreviated
                    }

                    const lorp_id =
                      customerName +
                      (entry['Furnace No.'] ? entry['Furnace No.'] : '') +
                      '-' +
                      (entry['Crucible No.'] ? entry['Crucible No.'] : '') +
                      '-' +
                      (entry['Campaign No.'] ? entry['Campaign No.'] : '') +
                      '-' +
                      getAbbreviatedLiningType(entry['Lining or Patching'])

                    if (!isEmptyGeneralDetails) {
                      lining_reference.push({
                        id: entryId,
                        'S.No.': entry['S.No.'],
                        furnace_no: entry['Furnace No.'],
                        crucible_no: entry['Crucible No.'],
                        campaign_no: entry['Campaign No.'],
                        lining_or_patching: entry['Lining or Patching'],
                        date_of_completion_lining_or_patching: formatDate(
                          entry['Date of Completion lining or Patching']
                        ),
                        lorp_id: lorp_id,
                      })

                      production_summary.push({
                        id: entryId,
                        fce_prod_start_date: formatDate(
                          entry['Fce prod start date']
                        ),
                        fce_prod_start_time: convertToHHMM(
                          entry['Fce prod start time']
                        ),
                        fce_prod_down_date: formatDate(
                          entry['Fce prod down date']
                        ),
                        fce_prod_down_time: convertToHHMM(
                          entry['Fce prod down time']
                        ),
                        life: entry['Life'],
                        average_heat_size: formatDecimal(
                          entry['Average Heat size']
                        ),
                        total_furnace_running_hours: formatDecimal(
                          entry['Total Furnace Running Hours']
                        ),
                        total_production: formatDecimal(
                          entry['Total Production']
                        ),
                        total_furnace_running_hours: formatDecimal(
                          entry['Total Furnace Running Hours']
                        ),
                      })

                      lining_material_product_details.push({
                        id: entryId,
                        bottom_product: entry['Bottom Product'],
                        sidewall_product_one: entry['Sidewall product 1 '],
                        sidewall_product_two: entry['Sidewall product 2'],
                        top_locking_product: entry['Top Locking product'],
                        topping_mass_product: entry['Topping Mass Product'],
                        sidewall_one_coverage_upto_x: formatPercentage(
                          entry['Side wall 1  Coverage upto X%']
                        ),
                        sidewall_two_coverage_upto_100: formatPercentage(
                          entry['Side wall 2 Coverage upto 100%']
                        ),
                      })

                      lining_material_consumption.push({
                        id: entryId,

                        nrm_consumption_bottom: formatDecimal(
                          entry['NRM consumption\r\nbottom']
                        ),
                        nrm_consumption_sidewall_one: formatDecimal(
                          entry['NRM consumption Sidewall 1']
                        ),
                        nrm_consumption_sidewall_two: formatDecimal(
                          entry['NRM consumption Sidewall 2']
                        ),
                        nrm_consumption_top_locking: formatDecimal(
                          entry['NRM consumption at Top Locking']
                        ),
                        topping_mass_consumption: formatDecimal(
                          entry['Topping mass consumption']
                        ),
                        total_nrm_consumption_sidewall_one: formatDecimal(
                          entry['Total NRM  consumption sidewall ']
                        ),
                        nrm_specific_consumption: formatDecimal(
                          entry['NRM Specific consumption']
                        ),
                      })

                      dimensional_parameters.push({
                        id: entryId,
                        former_dimension: entry['Former Dimension'],
                        coil_coat_id: formatDecimal(entry['Coil coat ID']),
                        fce_ht: formatDecimal(entry['Fce Ht.']),
                        costable_top_ht: formatDecimal(
                          entry['Castable top Ht.']
                        ),
                        bottom_ht: formatDecimal(entry['Bottom ht.']),
                      })

                      sintering_parameters.push({
                        id: entryId,
                        total_sintering_time: convertToHHMM(
                          entry['Total Sintering time ']
                        ),
                        holding_time: formatDecimal(entry['holding time ']),
                        holding_temp: formatDecimal(entry['holding temp ']),
                        reason_for_down: entry['Reason for Down'],
                        IR_no: entry['IR no. '],
                        job_done_by: entry['Job Done by'],
                        remarks: entry['Remarks'],
                      })
                    }
                  })
                )

                const jsonDataexcel = Data.map((entry, index) => ({
                  lining_reference: lining_reference[index],
                  production_summary: production_summary[index],
                  lining_material_product_details:
                    lining_material_product_details[index],
                  lining_material_consumption:
                    lining_material_consumption[index],
                  dimensional_parameters: dimensional_parameters[index],
                  sintering_parameters: sintering_parameters[index],
                }))

                //console.log("jsonexxcel", jsonDataexcel);
                //console.log("jsonexxcel");

                const userString = localStorage.getItem('user')

                // Parse the JSON string into a JavaScript object
                const user = JSON.parse(userString)
                //console.log("user",user);
                const created_by = user.firstname + ' ' + user.lastname
                //console.log("created_by",created_by);

                axios
                  .post(
                    API.REPORTS_EXCEL_INSERT_NRM,
                    {
                      jsonDataexcel: jsonDataexcel,
                      reports_id: reports_id,
                      created_by: created_by,
                      customer_id: formData.customer_id,
                      file_name: file.name,
                      template_id: formData.template_id,
                    },
                    { headers: { 'Content-Type': 'application/json' } }
                  )
                  .then((excelresponse) => {
                    if (excelresponse.status === 200) {
                      AlertDialog({
                        type: 'success',
                        title: 'Success',
                        text: 'Uploaded Successfully!',
                        confirmButtonText: 'Ok',
                        onConfirm: async () => {
                          window.location.reload()
                        },
                      })
                      setShowModals(false)
                    } else {
                      //console.error('Error saving data:', excelresponse.data);
                      AlertDialog({
                        type: 'warning',
                        title: 'Error',
                        text: 'Failed to save data',
                      })
                    }
                    //console.log("Excel data inserted successfully:", excelresponse);
                  })
                  .catch((error) => {
                    //console.log('An error occurred while inserting Excel data:', error);
                    // Optionally, you can handle the error state here
                  })
              }
            } else {
              alert('Please attach the proper template')
              // setShowModals(false);
              return
              //  Stop execution if headers don't match
            }
          }
        } else {
          return
        }
      }

      // window.location.reload();
    } catch (error) {
      //console.error('An error occurred while submitting form:', error);
      // Optionally, you can handle the error state here
    }
    // setShowModals(false);
    // window.location.reload();
  }

  const handleExtractData = async (match_template_id, file) => {
    try {
      if (file) {
        //console.log("good");
        //console.log("file",file);
        //console.log("template_idhandleExtractData", match_template_id);
        //console.log("filehandleExtractData", file);
        const reader = new FileReader()
        reader.readAsArrayBuffer(file)
        const extracted = await new Promise((resolve, reject) => {
          reader.onload = (e) => {
            const data = new Uint8Array(e.target.result)
            const workbook = XLSX.read(data, {
              type: 'array',
              cellStyles: true,
            })
            const worksheetName = workbook.SheetNames[0]
            const worksheet = workbook.Sheets[worksheetName]
            let headerRange
            if (match_template_id === 1) {
              headerRange = XLSX.utils.decode_range('A7:BF7')
            } else if (match_template_id === 2) {
              console.log(
                'template_idtemplate_idtemplate_idtemplate_id',
                match_template_id
              )
              headerRange = XLSX.utils.decode_range('A7:AO7')
              //console.log("headerRangeeee", headerRange);
            } else {
              //console.log("templatesss");
              reject('Invalid template')
              return
            }
            const headers = []
            if (match_template_id === 4) {
              // For template_id === 4, headers are in column A from row 1 to row 55
              let headerRangeA = XLSX.utils.decode_range('A1:A55')
              let headerRangeD = XLSX.utils.decode_range('D1:D55')

              for (let R = headerRangeA.s.r; R <= headerRangeA.e.r; ++R) {
                const cellAddressA = { c: headerRangeA.s.c, r: R }
                const headerCellA = XLSX.utils.encode_cell(cellAddressA)

                try {
                  const headerCellValueA = worksheet[headerCellA].v
                  headers.push(headerCellValueA) // Push header from column A
                } catch (error) {
                  console.error(
                    'An error occurred while extracting header from column A:',
                    error
                  )
                  reject('Invalid header')
                  return
                }
              }

              for (let R = headerRangeD.s.r; R <= headerRangeD.e.r; ++R) {
                const cellAddressD = { c: headerRangeD.s.c, r: R }
                const headerCellD = XLSX.utils.encode_cell(cellAddressD)

                //console.log("Reading header cell D:", headerCellD); // Add this logging

                try {
                  const headerCellValueD = worksheet[headerCellD].v
                  headers.push(headerCellValueD)
                } catch (error) {
                  console.error(
                    'An error occurred while extracting header from column D:',
                    error
                  )
                  reject('Invalid header')
                  return
                }
              }
            } else {
              for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
                const cellAddress = { c: C, r: headerRange.s.r }
                const headerCell = XLSX.utils.encode_cell(cellAddress)
                try {
                  const headerCellValue = worksheet[headerCell].v
                  headers.push(headerCellValue)
                } catch (error) {
                  console.error(
                    'An error occurred while extracting header:',
                    error
                  )
                  reject('Invalid header')
                  return
                }
              }
            }

            //console.log("hello");
            const dataRange = XLSX.utils.decode_range(worksheet['!ref'])
            //console.log(dataRange, "dataRange");
            const extractedData = []
            let startRow = 8
            if (match_template_id === 3) {
              startRow = 16
            }
            if (match_template_id === 4) {
              for (let R = dataRange.s.r; R <= dataRange.e.r; ++R) {
                const rowA = {}

                // Fetching header and data from column A
                const headerCellAddressA = { c: dataRange.s.c, r: R }
                const headerCellRefA =
                  XLSX.utils.encode_cell(headerCellAddressA)
                const headerValueA = worksheet[headerCellRefA]
                  ? worksheet[headerCellRefA].v
                  : undefined

                const dataCellAddressA = { c: dataRange.s.c + 1, r: R }
                const dataCellRefA = XLSX.utils.encode_cell(dataCellAddressA)
                const dataValueA = worksheet[dataCellRefA]
                  ? worksheet[dataCellRefA].v
                  : undefined

                // Adding data from column A to rowA
                rowA[headerValueA] = dataValueA

                // Pushing rowA to extractedData
                extractedData.push(rowA)
              }

              for (let R = dataRange.s.r; R <= dataRange.e.r; ++R) {
                const rowD = {}

                // Fetching header and data from column D
                const headerCellAddressD = { c: dataRange.s.c + 3, r: R }
                const headerCellRefD =
                  XLSX.utils.encode_cell(headerCellAddressD)
                const headerValueD = worksheet[headerCellRefD]
                  ? worksheet[headerCellRefD].v
                  : undefined

                const dataCellAddressD = { c: dataRange.s.c + 4, r: R }
                const dataCellRefD = XLSX.utils.encode_cell(dataCellAddressD)
                const dataValueD = worksheet[dataCellRefD]
                  ? worksheet[dataCellRefD].v
                  : undefined

                // Adding data from column D to rowD
                rowD[headerValueD] = dataValueD

                // Pushing rowD to extractedData
                extractedData.push(rowD)
              }
            } else {
              for (let R = startRow; R <= dataRange.e.r; ++R) {
                const row = {}
                for (let C = dataRange.s.c; C <= dataRange.e.c; ++C) {
                  const cellAddress = { c: C, r: R }
                  const cellRef = XLSX.utils.encode_cell(cellAddress)
                  const cellValue = worksheet[cellRef]
                    ? worksheet[cellRef].v
                    : undefined
                  row[headers[C]] = cellValue
                }
                extractedData.push(row)
              }
            }
            const result = { headers, data: extractedData }
            //console.log(result, "resultsss");
            localStorage.setItem('extractedData', JSON.stringify(result))
            resolve(result)
          }

          reader.onerror = (error) => {
            reject(error)
          }
        })
        return extracted
      }
    } catch (error) {
      console.error(
        'An error occurred while extracting data from Excel:',
        error
      )
      throw error
    }
  }

  //   const handleExtractData = async (selectedTemplate) => {
  //     try {
  //         if (file) {
  //             //console.log("selectedTemplate", selectedTemplate);
  //             //console.log("file", file);
  //             const reader = new FileReader();
  //             reader.readAsArrayBuffer(file);
  //             const extracted = await new Promise((resolve, reject) => {
  //                 reader.onload = (e) => {
  //                     //console.log("hello");

  //                     const data = new Uint8Array(e.target.result);
  //                     const workbook = XLSX.read(data, { type: 'array' });
  //                     const worksheetName = workbook.SheetNames[0];
  //                     const worksheet = workbook.Sheets[worksheetName];
  //                     let headerRange;
  //                     switch (selectedTemplate) {
  //                         case "Daily Trial Monitoring":
  //                             headerRange = XLSX.utils.decode_range("A7:BF7");
  //                             break;
  //                         case "NRM Performance":
  //                             headerRange = XLSX.utils.decode_range("A7:AN7");
  //                             break;
  //                         case "Erosion Profile":
  //                             headerRange = XLSX.utils.decode_range("A15:K15");
  //                             break;
  //                         default:
  //                             reject("Invalid template");
  //                     }
  //                     const headers = [];
  //                     for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
  //                         const cellAddress = { c: C, r: headerRange.s.r };
  //                         //console.log("Cell cellAddress cellAddress:", cellAddress);
  //                         const headerCell = XLSX.utils.encode_cell(cellAddress);
  //                         //console.log("Cell headerCell headerCell:", headerCell);
  //                         try {
  //                           const headerCellValue = worksheet[headerCell].v;
  //                           headers.push(headerCellValue);
  //                       } catch (error) {
  //                           //console.error("An error occurred while extracting header:", error);
  //                           localStorage.setItem('extractedData', null);
  //                           resolve({ headers: [], data: [] });
  //                           alert("Invalid template ");
  //                           setShowModals(false);
  //                           return;
  //                           // Handle the error condition here, such as returning or breaking out of the loop
  //                       }
  //                     }
  //                     //console.log("hello");
  //                     const dataRange = XLSX.utils.decode_range(worksheet['!ref']);
  //                     const extractedData = [];
  //                     let startRow = 8;
  //                     if (selectedTemplate === "Erosion Profile") {
  //                         startRow = 16;
  //                     }
  //                     for (let R = startRow; R <= dataRange.e.r; ++R) {
  //                         const row = {};
  //                         for (let C = dataRange.s.c; C <= dataRange.e.c; ++C) {
  //                             const cellAddress = { c: C, r: R };
  //                             //console.log("Cell cellAddress:", cellAddress);
  //                             const cellRef = XLSX.utils.encode_cell(cellAddress);
  //                             //console.log("Cell Reference:", cellRef);
  //                             //console.log("Cell Value:", worksheet[cellRef]);
  //                             const cellValue = worksheet[cellRef] ? worksheet[cellRef].v : undefined;
  //                             row[headers[C]] = cellValue;
  //                         }
  //                         extractedData.push(row);
  //                     }
  //                     const result = { headers, data: extractedData };
  //                     localStorage.setItem('extractedData', JSON.stringify(result));

  //                     resolve(result);
  //                 };

  //                 reader.onerror = (error) => {
  //                     reject(error);
  //                 };
  //             });
  //             return extracted;
  //         }
  //     } catch (error) {
  //         //console.error('An error occurred while extracting data from Excel:', error);
  //         throw error;

  //     }
  // };

  const handleDatabaseSearchSelect = () => {
    setIsDatabaseSearchSelected(true)
    setIsUploadFileSelected(false)
  }

  const handleUploadFileSelect = () => {
    setIsDatabaseSearchSelected(false)
    setIsUploadFileSelected(true)
  }

  useEffect(() => {
    const fetchCards = async () => {
      try {
        //console.log('hello:')
        const filetemplate = await axios.get(API.GET_TEMPLATES) // Assuming your API endpoint is '/api/showcardsSubmitted'
        const dataTemplate = filetemplate.data.map((item) => item.name)
        //console.log("dataTemplate",dataTemplate);
        setfileTemplate(dataTemplate)
        const response = await axios.get(API.REPORTS_CARD_DETAILS) // Assuming your API endpoint is '/api/showcardsSubmitted'
        const data = await response.data
        setReportCardDetails(data)
      } catch (error) {
        //console.error('An error occurred while fetching submitted cards:', error);
        // Optionally, you can handle the error state here
      }
    }

    // Call the function when component mounts or when specific dependencies change
    fetchCards()
  }, [])

  const grades = ['MS', 'Grade 2', 'Grade 3'] // Example array of grades
  const crucible = ['A', 'B', 'C', 'D', 'E']
  const liningNumbers = [
    'New Lining',
    'Side Lining 1',
    'Side Lining 2',
    'Side Lining 3',
    'Side Lining 4',
    'Side Lining 5',
    'Side Lining 6',
    'Side Lining 7',
    'Side Lining 8',
    'Side Lining 9',
    'Side Lining 10',
    'Side Lining 11',
    'Side Lining 12',
    'Side Lining 13',
    'Side Lining 14',
    'Side Lining 15',
    'Patching 1',
    'Patching 2',
    'Patching 3',
    'Patching 4',
    'Patching 5',
    'Patching 6',
    'Patching 7',
    'Patching 8',
    'Patching 9',
    'Patching 10',
    'Patching 11',
    'Patching 12',
    'Patching 13',
    'Patching 14',
    'Patching 15',
    'Patching 16',
    'Patching 17',
    'Patching 18',
    'Patching 19',
    'Patching 20',
    'Patching 21',
    'Patching 22',
    'Patching 23',
    'Patching 24',
    'Patching 25',
  ]

  const handleGradeChange = (event) => {
    setSelectedGrade(event.target.value)
  }

  const handleLiningNumberChange = (event) => {
    setSelectedLiningNumber(event.target.value)
  }

  const handleMinimumYield = (event) => {
    setSelectedMinimumYield(event.target.value)
  }

  const handleMaximumYield = (event) => {
    setSelectedMaximumYield(event.target.value)
  }

  const handleMinimumTappedTonnage = (event) => {
    setSelectedMinimumTappedTonnage(event.target.value)
  }

  const handleMaximumTappedTonnage = (event) => {
    setSelectedMaximumTappedTonnage(event.target.value)
  }

  const handleModalClose = () => {
    setShowModals(false)
  }

  const handleTemplateChange = (event) => {
    const value = event.target.value
    setSelectedTemplate(value)
    setTemplateError(value ? '' : 'Please select a template')
  }

  return (
    <div>
      <Grid container justifyContent={'flex-end'}>
        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleModalOpens}
          >
            Create Report
          </Button>
        </Grid>
      </Grid>

      <div>
        <Modal
          open={showModals}
          onClose={handleModalCloses}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              width: 600,
              maxHeight: '80vh',
              overflowY: 'auto',
              left: 660,
              padding: '8px 16px',
              borderRadius: 3,
              background: 'white',
              border: 'none',
              gap: 16,
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ flex: 1 }}>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: 18,
                    fontWeight: 600,
                    lineHeight: '24px',
                    letterSpacing: '-0.006em',
                    textAlign: 'left',
                    marginTop: '10px',
                    color: '#2D2B32',
                  }}
                >
                  Create Report
                </Typography>
              </div>
              <IconButton onClick={handleModalCloses}>
                <CloseIcon />
              </IconButton>
            </div>

            <form>
              <div style={{ marginTop: '20px', borderRadius: 8 }}>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: 14,
                    fontWeight: 700,
                    lineHeight: '17px',
                    letterSpacing: '-0.006em',
                    textAlign: 'left',
                    color: '#2D2B32',
                  }}
                >
                  Report Name
                </Typography>
                <TextField
                  id="report-name"
                  value={reportName}
                  onChange={(e) => setReportName(e.target.value)}
                  fullWidth
                  style={{ marginTop: '10px', minWidth: 90 }}
                />
              </div>

              <div style={{ marginTop: '20px' }}>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: 14,
                    fontWeight: 700,
                    lineHeight: '17px',
                    letterSpacing: '-0.006em',
                    textAlign: 'left',
                    color: '#2D2B32',
                  }}
                >
                  Description
                </Typography>
                <TextField
                  id="description"
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                  fullWidth
                  style={{ marginTop: '10px' }}
                />
              </div>

              <div style={{ marginTop: '20px' }}>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: 14,
                    fontWeight: 700,
                    lineHeight: '25px',
                    letterSpacing: '-0.006em',
                    textAlign: 'left',
                    color: '#2D2B32',
                  }}
                >
                  Customer
                </Typography>
                <AutoSelectBox
                  name="customer_id"
                  isLabel={true}
                  options={customers}
                  labelCondition="organization_name"
                  value={formData.customer_id}
                  onChange={handleChange}
                  error={formErrors.customer_id}
                  style={{ margin: '10px' }}
                />
              </div>

              <div style={{ marginTop: '20px' }}>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: 14,
                    fontWeight: 700,
                    lineHeight: '25px',
                    letterSpacing: '-0.006em',
                    textAlign: 'left',
                    color: '#2D2B32',
                  }}
                >
                  Select Furnace
                </Typography>
                <AutoSelectBox
                  name="furnace_id"
                  isLabel={true}
                  options={furnaces}
                  labelCondition="furnace_id"
                  value={formData.furnace_id}
                  onChange={handleChange}
                  error={formErrors.furnace_id}
                />
              </div>

              <div style={{ marginTop: '20px' }}>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: 14,
                    fontWeight: 700,
                    lineHeight: '25px',
                    letterSpacing: '-0.006em',
                    textAlign: 'left',
                    color: '#2D2B32',
                  }}
                >
                  Data File Template
                </Typography>
                <AutoSelectBox
                  name="template_id"
                  isLabel={true}
                  options={templates}
                  labelCondition="name"
                  value={formData.template_id}
                  onChange={handleChange}
                  error={formErrors.template_id}
                />
              </div>
              {match_template === 1 && (
                <Accordion style={{ marginTop: '20px', border: 'none' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    onClick={handleDatabaseSearchSelect}
                  >
                    <Typography variant="body1">DATABASE SEARCH</Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '20px',
                      }}
                    >
                      <div style={{ flex: 1, marginRight: '10px' }}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: 14,
                            fontWeight: 700,
                            lineHeight: '17px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#2D2B32',
                          }}
                        >
                          Minimum Date
                        </Typography>
                        <TextField
                          id="min-date"
                          type="date"
                          value={minDate}
                          onChange={(e) => setMinDate(e.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          sx={{
                            marginTop: '10px',
                            border: '1px solid #E6E6E6',
                            borderRadius: '8px',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#E0E0E0',
                              },
                              '&:hover fieldset': {
                                borderColor: '#A0A0A0',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#3f51b5',
                              },
                            },
                          }}
                        />
                      </div>
                      <div style={{ flex: 1 }}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: 14,
                            fontWeight: 700,
                            lineHeight: '17px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#2D2B32',
                          }}
                        >
                          Maximum Date
                        </Typography>
                        <TextField
                          id="max-date"
                          type="date"
                          value={maxDate}
                          onChange={(e) => setMaxDate(e.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          sx={{
                            marginTop: '10px',
                            border: '1px solid #E6E6E6',
                            borderRadius: '8px',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#E0E0E0',
                              },
                              '&:hover fieldset': {
                                borderColor: '#A0A0A0',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#3f51b5',
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '20px',
                      }}
                    >
                      <div style={{ flex: 1, marginRight: '10px' }}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: 14,
                            fontWeight: 700,
                            lineHeight: '17px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#2D2B32',
                          }}
                        >
                          Grade
                        </Typography>
                        <TextField
                          id="grade"
                          value={selectedGrade}
                          onChange={handleGradeChange}
                          fullWidth
                          sx={{
                            marginTop: '10px',
                            border: '1px solid #E6E6E6',
                            borderRadius: '8px',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#E0E0E0',
                              },
                              '&:hover fieldset': {
                                borderColor: '#A0A0A0',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#3f51b5',
                              },
                            },
                          }}
                        />
                      </div>
                      <div style={{ flex: 1 }}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: 14,
                            fontWeight: 700,
                            lineHeight: '17px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#2D2B32',
                          }}
                        >
                          Lining/Patching Number
                        </Typography>
                        <TextField
                          id="lining-number"
                          select
                          value={selectedLiningNumber}
                          onChange={handleLiningNumberChange}
                          fullWidth
                          sx={{
                            marginTop: '10px',
                            border: '1px solid #E6E6E6',
                            borderRadius: '8px',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#E0E0E0',
                              },
                              '&:hover fieldset': {
                                borderColor: '#A0A0A0',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#3f51b5',
                              },
                            },
                          }}
                        >
                          {liningNumbers.map((number, index) => (
                            <MenuItem key={index} value={number}>
                              {number}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '20px',
                      }}
                    >
                      <div style={{ flex: 1, marginRight: '10px' }}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: 14,
                            fontWeight: 700,
                            lineHeight: '17px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#2D2B32',
                          }}
                        >
                          Minimum Yield
                        </Typography>
                        <TextField
                          id="minimum-yield"
                          value={selectedMinimumYield}
                          onChange={handleMinimumYield}
                          fullWidth
                          sx={{
                            marginTop: '10px',
                            border: '1px solid #E6E6E6',
                            borderRadius: '8px',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#E0E0E0',
                              },
                              '&:hover fieldset': {
                                borderColor: '#A0A0A0',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#3f51b5',
                              },
                            },
                          }}
                        />
                      </div>
                      <div style={{ flex: 1 }}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: 14,
                            fontWeight: 700,
                            lineHeight: '17px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#2D2B32',
                          }}
                        >
                          Maximum Yield
                        </Typography>
                        <TextField
                          id="maximum-yield"
                          value={selectedMaximumYield}
                          onChange={handleMaximumYield}
                          fullWidth
                          sx={{
                            marginTop: '10px',
                            border: '1px solid #E6E6E6',
                            borderRadius: '8px',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#E0E0E0',
                              },
                              '&:hover fieldset': {
                                borderColor: '#A0A0A0',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#3f51b5',
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '20px',
                      }}
                    >
                      <div style={{ flex: 1, marginRight: '10px' }}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: 14,
                            fontWeight: 700,
                            lineHeight: '17px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#2D2B32',
                          }}
                        >
                          Minimum Tapped Tonnage
                        </Typography>
                        <TextField
                          id="minimum-tapped-tonnage"
                          value={selectedMinimumTappedTonnage}
                          onChange={handleMinimumTappedTonnage}
                          fullWidth
                          sx={{
                            marginTop: '10px',
                            border: '1px solid #E6E6E6',
                            borderRadius: '8px',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#E0E0E0',
                              },
                              '&:hover fieldset': {
                                borderColor: '#A0A0A0',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#3f51b5',
                              },
                            },
                          }}
                        />
                      </div>
                      <div style={{ flex: 1 }}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: 14,
                            fontWeight: 700,
                            lineHeight: '17px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#2D2B32',
                          }}
                        >
                          Maximum Tapped Tonnage
                        </Typography>
                        <TextField
                          id="maximum-tapped-tonnage"
                          value={selectedMaximumTappedTonnage}
                          onChange={handleMaximumTappedTonnage}
                          fullWidth
                          sx={{
                            marginTop: '10px',
                            border: '1px solid #E6E6E6',
                            borderRadius: '8px',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#E0E0E0',
                              },
                              '&:hover fieldset': {
                                borderColor: '#A0A0A0',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#3f51b5',
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}
              {match_template === 2 && (
                <Accordion style={{ marginTop: '20px', border: 'none' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    onClick={handleDatabaseSearchSelect}
                  >
                    <Typography variant="body1">DATABASE SEARCH</Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '20px',
                      }}
                    >
                      <div style={{ flex: 1, marginRight: '10px' }}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: 14,
                            fontWeight: 700,
                            lineHeight: '17px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#2D2B32',
                          }}
                        >
                          Minimum Date
                        </Typography>
                        <TextField
                          id="min-date"
                          type="date"
                          value={minDate}
                          onChange={(e) => setMinDate(e.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          sx={{
                            marginTop: '10px',
                            border: '1px solid #E6E6E6',
                            borderRadius: '8px',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#E0E0E0',
                              },
                              '&:hover fieldset': {
                                borderColor: '#A0A0A0',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#3f51b5',
                              },
                            },
                          }}
                        />
                      </div>
                      <div style={{ flex: 1 }}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: 14,
                            fontWeight: 700,
                            lineHeight: '17px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#2D2B32',
                          }}
                        >
                          Maximum Date
                        </Typography>
                        <TextField
                          id="max-date"
                          type="date"
                          value={maxDate}
                          onChange={(e) => setMaxDate(e.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          fullWidth
                          sx={{
                            marginTop: '10px',
                            border: '1px solid #E6E6E6',
                            borderRadius: '8px',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#E0E0E0',
                              },
                              '&:hover fieldset': {
                                borderColor: '#A0A0A0',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#3f51b5',
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '20px',
                      }}
                    >
                      <div style={{ flex: 1, marginRight: '10px' }}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: 14,
                            fontWeight: 700,
                            lineHeight: '17px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#2D2B32',
                          }}
                        >
                          Crucible
                        </Typography>
                        <TextField
                          id="grade"
                          value={selectedGrade}
                          onChange={handleGradeChange}
                          fullWidth
                          sx={{
                            marginTop: '10px',
                            border: '1px solid #E6E6E6',
                            borderRadius: '8px',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#E0E0E0',
                              },
                              '&:hover fieldset': {
                                borderColor: '#A0A0A0',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#3f51b5',
                              },
                            },
                          }}
                        >
                          {crucible.map((number, index) => (
                            <MenuItem key={index} value={number}>
                              {number}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                      <div style={{ flex: 1 }}>
                        <Typography
                          variant="body2"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: 14,
                            fontWeight: 700,
                            lineHeight: '17px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#2D2B32',
                          }}
                        >
                          Lining/Patching Number
                        </Typography>
                        <TextField
                          id="lining-number"
                          select
                          value={selectedLiningNumber}
                          onChange={handleLiningNumberChange}
                          fullWidth
                          sx={{
                            marginTop: '10px',
                            border: '1px solid #E6E6E6',
                            borderRadius: '8px',
                            '& .MuiOutlinedInput-root': {
                              '& fieldset': {
                                borderColor: '#E0E0E0',
                              },
                              '&:hover fieldset': {
                                borderColor: '#A0A0A0',
                              },
                              '&.Mui-focused fieldset': {
                                borderColor: '#3f51b5',
                              },
                            },
                          }}
                        >
                          {liningNumbers.map((number, index) => (
                            <MenuItem key={index} value={number}>
                              {number}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}

              <Typography
                variant="body1"
                align="center"
                style={{
                  margin: '20px 0',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Divider style={{ flex: 1 }} />
                <Typography variant="body1" style={{ margin: '0 10px' }}>
                  or
                </Typography>
                <Divider style={{ flex: 1 }} />
              </Typography>

              <Accordion style={{ marginTop: '20px' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  onClick={handleUploadFileSelect}
                >
                  <Typography variant="body1">UPLOAD DATA FILE</Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    border: 'none',
                  }}
                >
                  <div
                    style={{
                      border: '2px dashed #00448B',
                      padding: '20px',
                      flex: 1,
                      display: 'flex',
                      width: '100%',
                      height: '150px',
                      marginTop: '10px',
                      marginRight: 20,
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '4px',
                    }}
                  >
                    <label htmlFor="file-input">
                      <button
                        style={{
                          width: 30,
                          height: 30,
                          marginLeft: 10,
                          marginBottom: 10,
                          cursor: 'pointer',
                          padding: 0,
                          border: 'none',
                          background: 'none',
                        }}
                        aria-label="Upload File"
                        onClick={handleButtonClick}
                      >
                        <img src={UploadIcon} alt="Upload File" />
                      </button>
                    </label>
                    <Typography
                      style={{
                        textAlign: 'center',
                        marginLeft: 15,
                        fontWeight: 600,
                        width: 170,
                        fontSize: '14px',
                        color: '#0086DC',
                      }}
                    >
                      Browse files
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 400,
                        lineHeight: '18px',
                        textAlign: 'center',
                        color: '#4F4D55',
                      }}
                    >
                      .xlsx - max file size 2MB
                    </Typography>
                    <input
                      type="file"
                      id="file-input"
                      onChange={handleFileUploads}
                      style={{ display: 'none' }}
                    />
                  </div>
                </AccordionDetails>
              </Accordion>

              <div
                style={{
                  width: '95%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: 10,
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    width: '252px',
                    height: '40px',
                    padding: '8px 12px',
                    borderRadius: '6px',
                    color: '#252C32',
                    gap: '8px',
                    border: '1px solid #E5E9EB',
                    fontSize: '14px',
                    marginRight: '10px',
                  }}
                  onClick={handleModalCloses}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    width: '252px',
                    height: '40px',
                    padding: '8px 12px',
                    borderRadius: '6px',
                    color: '#FFFFFF',
                    gap: '8px',
                    background: '#0086DC',
                    fontSize: '14px',
                  }}
                  onClick={handleFormSubmit}
                >
                  Save & Next
                </Button>
              </div>
            </form>
          </Box>
        </Modal>
      </div>
      <ReportAnalysis
        reportCardDetails={reportCardDetails}
        selectedTemplate={selectedTemplate}
      />
    </div>
  )
}

export default Reports
