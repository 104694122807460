import React, { useState, useEffect } from 'react'
import { Bar } from 'react-chartjs-2'
import API from '../services/API'
import axios from 'axios'
import AutoSelectBox from '../input/AutoSelectBox'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Typography } from '@mui/material'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const Dashboard = () => {
  const createDataset = (label, backgroundColor, data) => ({
    label,
    backgroundColor,
    data,
    stack: 'Stack 0',
  })
  const [dailyTrialData, setDailyTrialData] = useState([])
  const [nrmData, setNRMData] = useState([])
  const [EPData, setEPData] = useState([])
  const [costSheetData, setCostSheetData] = useState([])
  const [dataCollectionData, setDataCollectionData] = useState([])

  useEffect(() => {
    const fetchMonthlyCounts = async () => {
      try {
        const [
          dtmResponse,
          nrmResponse,
          erosionResponse,
          costSheetResponse,
          dataCollectionResponse,
        ] = await Promise.all([
          axios.get(API.GET_DTM_MONTHLY_COUNT),
          axios.get(API.GET_NRM_MONTHLY_COUNT),
          axios.get(API.GET_EP_MONTHLY_COUNT),
          axios.get(API.GET_COST_SHEET_MONTHLY_COUNT),
          axios.get(API.GET_DATA_COLLECTION_MONTHLY_COUNT),
        ])

        setDailyTrialData(dtmResponse.data)
        setNRMData(nrmResponse.data)
        setEPData(erosionResponse.data)
        setCostSheetData(costSheetResponse.data)
        setDataCollectionData(dataCollectionResponse.data)
      } catch (error) {
        console.error('Error fetching monthly counts:', error)
      }
    }

    fetchMonthlyCounts()
  }, [])

  const dailyTrialRecordCounts = dailyTrialData.map((item) =>
    parseInt(item.record_count)
  )
  const nrmRecordCounts = nrmData.map((item) => parseInt(item.record_count))
  const EPRecordCounts = EPData.map((item) => parseInt(item.record_count))
  const CostSheetRecordCounts = costSheetData.map((item) =>
    parseInt(item.record_count)
  )
  const dataCollectionRecordCounts = dataCollectionData.map((item) =>
    parseInt(item.record_count)
  )

  const data = {
    labels: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    datasets: [
      createDataset('Daily Trial', '#254A9A', dailyTrialRecordCounts),
      createDataset('NRM Performance', '#0086DC', nrmRecordCounts),
      createDataset('Erosion Profile', '#F25C19', EPRecordCounts),
      createDataset('Data Collection', '#9747FF', dataCollectionRecordCounts),
      createDataset('Cost Sheet', '#27AE68', CostSheetRecordCounts),
    ],
  }

  // Chart options
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: 'bottom' },
      // title: { display: true, text: 'Stacked Bar Chart' },
    },
    scales: {
      x: { stacked: true },
      y: {
        stacked: true,
        ticks: {
          stepSize: 20,
        },
      },
    },
  }

  return (
    <div
      style={{ ...styles.container, display: 'flex', flexDirection: 'column' }}
    >
      <div style={{ marginTop: '20px', alignSelf: 'flex-end' }}>
        <AutoSelectBox
          name="controllable-states-demo"
          isLabel={true}
          // options={customers}
          labelCondition="organization_name"
          label="select customer"
          // value={selectCustomer}
          // onChange={(e) => handleCustomerChange(e.target.value)}
          // error={formErrors.customer_id}
          TextFieldProps={{
            InputProps: {
              style: { padding: '5px' }, // Add local padding here
            },
          }}
        />
      </div>
      <div style={styles.chartWrapper}>
        <Bar data={data} options={options} />
      </div>
    </div>
  )
}

const styles = {
  container: {
    padding: '16px',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  chartWrapper: {
    height: '80%',
    width: '80%',
  },
}

export default Dashboard
