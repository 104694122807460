import React, { useState, useEffect, useRef } from 'react'
import { Typography, IconButton, Avatar } from '@mui/material'
import axios from 'axios'
import API from '../../services/API'
import { useNavigate } from 'react-router-dom'
import { Card, CardContent } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EditIcon from '../../../assets/icons/ic_edit.svg'
import DeleteIcon from '../../../assets/icons/ic_delete.svg'
import ViewIcon from '../../../assets/icons/ic_view.png'
import { trial_name } from '../../../recoil/atoms'
import { useRecoilState } from 'recoil'
import AlertDialog from '../../input/AlertDialog'
import * as XLSX from 'xlsx'
import FilterIcon from '../../../assets/icons/ic_home_filter.svg'
import CostSheetFilterPage from './CostSheetFilter'

const CostSheetHome = () => {
  const navigate = useNavigate()
  const [filteredTrials, setFilteredTrials] = useState([])
  const [formData, setFormData] = useState({
    segment_id: '',
    customer_id: '',
    template_id: '',
    furnace_id: '',
  })
  const [isFilterOpen, setIsFilterOpen] = useState(false)

  const [costSheet, setCostSheet] = useState([])
  const currentUser = JSON.parse(localStorage.getItem('user'))
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [popupPosition, setPopupPosition] = useState({ top: 0, right: 0 })
  const [trialName, setTrialName] = useRecoilState(trial_name)
  console.log(trialName, 'trialName')
  const [popupDataFileId, setPopupDatafileId] = useState('')
  const [selectedCardDataId, setSelectedCardDataId] = useState(null)
  const [selectedSubmissionStatus, setSelectedSubmissionStatus] = useState('')
  const access_token = localStorage.getItem('accesstoken')
  const [error, setError] = useState(null) // Error state
  const [loading, setLoading] = useState(false) // Loader state
  const popupRef = useRef(null)

  const handleFilterClick = () => {
    setIsFilterOpen(true)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setLoading(true)
    setError(null)

    try {
      const response = await axios.get(API.GET_ALL_COST_SHEETS, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })

      const costsheetResponse = response.data
      const selectedCustomer = JSON.parse(
        localStorage.getItem('selectedCustomer')
      )
      const customer_id = selectedCustomer?.id

      if (!customer_id) {
        console.warn('No customer selected or customer_id not found')
        setCostSheet([])
        return
      }

      if (currentUser.role.role_id === 1) {
        const userId = currentUser.id
        const selectedCustomer = JSON.parse(
          localStorage.getItem('selectedCustomer')
        )
        const customer_id = selectedCustomer?.id
        const filteredCostSheet = costsheetResponse.filter(
          (item) =>
            item.dataFile.customer_id === customer_id &&
            item.dataFile.user_id === userId
        )
        setCostSheet(filteredCostSheet)
      } else if (currentUser.role.role_id === 2) {
        const assignCustomerIds = currentUser.assign_customer_id
        const selectedCustomer = JSON.parse(
          localStorage.getItem('selectedCustomer')
        )
        const customer_id = selectedCustomer?.id
        const filteredCostSheet = costsheetResponse.filter((item) =>
          customer_id.includes(item.dataFile.customer_id)
        )
        setCostSheet(filteredCostSheet)
      } else if (
        currentUser.role.role_id === 3 ||
        currentUser.role.role_id === 4
      ) {
        const selectedCustomer = JSON.parse(
          localStorage.getItem('selectedCustomer')
        )
        const customer_id = selectedCustomer?.id
        const filteredCostSheet = costsheetResponse.filter(
          (item) => item.dataFile.customer_id === customer_id
        )
        setCostSheet(filteredCostSheet)
      }
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const handleMenuOpen = (event, datafile_id, trial, submission_status) => {
    console.log('trialtrialtrialtrial1213232', trial)
    const rect = event.target.getBoundingClientRect()
    setIsPopupOpen(true)
    setPopupPosition({
      top: rect.top + window.scrollY,
      right: window.innerWidth - rect.right,
    })
    setPopupDatafileId(datafile_id)
    const costSheetID = trial.id
    localStorage.setItem('costSheetID', costSheetID)
    setSelectedSubmissionStatus(submission_status)
    setSelectedCardDataId(trial.id)
  }

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsPopupOpen(false) // Close the popup if clicked outside
    }
  }

  useEffect(() => {
    if (isPopupOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isPopupOpen])

  const handleDelete = () => {
    setIsPopupOpen(false)
    const id = selectedCardDataId
    console.log('selectedCardDataIdselectedCardDataId', selectedCardDataId)
    AlertDialog({
      type: 'delete',
      title: 'Delete?',
      text: 'Are you sure you want to delete?',
      confirmButtonText: 'Yes',
      onConfirm: async () => {
        const response = await axios.delete(
          `${API.DELETE_DATA_COLLECTION}/${id}`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        if (response) {
          if (response.status == 200) {
            AlertDialog({
              type: 'success',
              title: 'Success',
              text: 'Deleted Successfully',
              confirmButtonText: 'Ok',
            })
            fetchData()
          }
        }
      },
    })
  }
  const handleEdit = () => {
    setTrialName('costSheetEdit')
    localStorage.setItem('costSheetEdit', 'costSheetEdit')
    navigate('/costsheet')
  }

  const handleView = async () => {
    setTrialName('viewCostSheet')
    localStorage.setItem('viewCostSheet', 'viewCostSheet')
    navigate('/costsheet')
  }

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
    return buf
  }

  const workbookToBlob = (workbook) => {
    return new Promise((resolve) => {
      // Write the workbook to a binary string
      const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' })

      // Convert the binary string to a Blob
      const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' })
      resolve(blob)
    })
  }

  //   const handleDownload = async () => {
  //     const id = localStorage.getItem("costSheetID");

  //     try {
  //       // Fetch data from the endpoint
  //       const response = await axios.get(`${API.GET_DATA_COLLECTION_BY_ID}/${id}`);
  //       const data = response.data;
  //       console.log(data, "datadata12")

  //       const responseTemplate = await axios.get(API.GET_DATA_COLLECTION_TEMPLATE, {
  //         responseType: 'blob', // Set the response type to 'blob'
  //       });

  //       // Read the downloaded template as binary string
  //       const binaryStr = await new Response(responseTemplate.data).arrayBuffer();
  //       const workbook = XLSX.read(binaryStr, { type: 'array' });

  //       // Modify the workbook with data from the response
  //       const sheetName = workbook.SheetNames[0];
  //       const worksheet = workbook.Sheets[sheetName];

  //       // Get the general_details object from the provided JSON
  //       const generalDetails = data.general_details;
  //       const productDetails = data.product_details;
  //       const furanceDetails = data.furnace_details;
  //       const mildSteelDetails = data.mild_steel_customers_only;
  //       const rawMaterialDetails = data.raw_material_energy_details;
  //       const furnaceDimension = data.furnace_dimension;
  //       const liningDetails = data.lining_details;
  //       const alloyDetails = data.alloy_stainless_steel_customers;

  //       //general details
  //       const contactPerson = generalDetails.contact_person || '';
  //       const customerName = generalDetails.customer_name || '';
  //       const date = generalDetails.date || '';
  //       const mobileNumber = generalDetails.mobile_number || '';

  //       const style = {
  //         fill: {
  //             patternType: "solid", // Add this line
  //             fgColor: { rgb: "FFFF00" } // Yellow background
  //         }
  //     };

  //     worksheet['B1'] = { t: 's', v: customerName, s: style };
  //     worksheet['B2'] = { t: 's', v: contactPerson, s: style };
  //     worksheet['E1'] = { t: 's', v: date, s: style };
  //     worksheet['E2'] = { t: 's', v: mobileNumber, s: style }

  //      // product details
  //     const endProduct = productDetails.end_product || '';
  //     const grades = productDetails.steel_produce || '';
  //     const ratio = productDetails.ratio || '';

  //     worksheet['B4'] = { t: 's', v: endProduct };
  //     worksheet['B5'] = { t: 's', v: grades };
  //     worksheet['B6'] = { t: 's', v: ratio };

  //     // furanceDetails
  //     const lrf = furanceDetails.lrf || '';
  //     const tap_time = furanceDetails.tap_time || '';
  //     const operation = furanceDetails.operation || '';
  //     const coil_grout = furanceDetails.coil_grout || '';
  //     const fce_running = furanceDetails.fce_running || '';
  //     const furnace_oem = furanceDetails.furnace_oem || '';
  //     const avg_heatsize = furanceDetails.avg_heatsize || '';
  //     const fce_capacity = furanceDetails.fce_capacity || '';
  //     const furnace_year = furanceDetails.furnace_year || '';
  //     const no_of_panels = furanceDetails.no_of_panels || '';
  //     const power_rating = furanceDetails.power_rating || '';
  //     const checking_time = furanceDetails.checking_time || '';
  //     const sintering_time = furanceDetails.sintering_time || '';
  //     const change_overtime = furanceDetails.change_overtime || '';
  //     const frequency_range = furanceDetails.frequency_range || '';
  //     const furnace_voltage = furanceDetails.furnace_voltage || '';
  //     const sintering_panel = furanceDetails.sintering_panel || '';
  //     const avg_tapping_temp = furanceDetails.avg_tapping_temp || '';
  //     const max_tapping_temp = furanceDetails.max_tapping_temp || '';
  //     const no_of_heats_perday = furanceDetails.no_of_heats_perday || '';
  //     const avg_power_consumption = furanceDetails.avg_power_consumption || '';
  //     const power_rating_sintering = furanceDetails.power_rating_sintering || '';

  //     const columnData = [no_of_panels, fce_capacity,avg_heatsize,power_rating,fce_running,tap_time,sintering_panel,power_rating_sintering,no_of_heats_perday,avg_power_consumption,lrf,frequency_range,max_tapping_temp,avg_tapping_temp,furnace_voltage,furnace_oem,furnace_year,coil_grout,sintering_time,operation,change_overtime,checking_time]

  //     for (let i = 7; i < 29; i++) {
  //         worksheet['B' + (i + 1)] = { t: 's', v: '' };
  //     }
  //     const rowData = columnData.map(data => [data]);
  //     try {
  //       XLSX.utils.sheet_add_aoa(worksheet, rowData, { origin: 'B8' });
  //     } catch (error) {
  //       console.error("Error adding new data:", error);
  //     }

  //     //mild Steel customer only
  //     const mildSteelColumnData = [
  //       mildSteelDetails.deslagging_by,
  //       mildSteelDetails.using_magnet_or_not,
  //       mildSteelDetails.avg_c_percent_at_50,
  //       mildSteelDetails.avg_c_percent_at_80,
  //       mildSteelDetails.final_c_percent_beforeTapping,
  //       mildSteelDetails.data_mill_scale,
  //       mildSteelDetails.tilt_count_for_deslagging,
  //       mildSteelDetails.are_they_taking_liquid_metal,
  //       mildSteelDetails.dri_quality,
  //       mildSteelDetails.slag_generation_per_heat,
  //       mildSteelDetails.power_utilised,
  //       mildSteelDetails.silica_price,
  //       mildSteelDetails.ccm,
  //       mildSteelDetails.casting_time,
  //       mildSteelDetails.tundish_cap,
  //       mildSteelDetails.min_level_of_tundish,
  //       mildSteelDetails.billet_size,
  //       mildSteelDetails.no_of_sequence,
  //       mildSteelDetails.ccm_make,
  //       mildSteelDetails.ccm_preparation_time,
  //       mildSteelDetails.purpose_of_using_nrm
  //   ];
  //   for (let i = 30; i < 51; i++) {
  //     worksheet['B' + (i + 1)] = { t: 's', v: '' }; // Clearing existing data
  // }
  //   const mildSteelRowData = mildSteelColumnData.map(data => [data]);
  // try {
  //     XLSX.utils.sheet_add_aoa(worksheet, mildSteelRowData, { origin: 'B31' });
  // } catch (error) {
  //     console.error("Error adding new data:", error)

  // }

  // const rawMaterialColumnData = [
  // rawMaterialDetails.type_of_scrap,
  // rawMaterialDetails.charge_mix_ratio,
  // rawMaterialDetails.yield_of_charge_mix,
  // rawMaterialDetails.profit,
  // rawMaterialDetails.unit_price_of_power,
  // ]

  // for (let i = 3; i < 8; i++) {
  //   worksheet['E' + (i + 1)] = { t: 's', v: '' }; // Clearing existing data
  // }
  // const rawMaterialRowData = rawMaterialColumnData.map(data => [data]);
  // try {
  //   XLSX.utils.sheet_add_aoa(worksheet, rawMaterialRowData, { origin: 'E4' });
  // } catch (error) {
  //   console.error("Error adding new data:", error)

  // }

  // const furnaceDimensionData = [
  //   furnaceDimension.inner_dia_of_coil,
  //   furnaceDimension.inner_dia_after_coilcoat,
  //   furnaceDimension.former_outer_dia,
  //   furnaceDimension.furnace_height,
  //   furnaceDimension.bottom_taper_dia,
  //   furnaceDimension.bottom_outer_dia,
  //   furnaceDimension.former_ht,
  //   furnaceDimension.bottom_lining_ht,
  //   furnaceDimension.total_power_given_to_ms,
  // ]

  // for (let i = 21; i < 29; i++) {
  //   worksheet['E' + (i + 1)] = { t: 's', v: '' }; // Clearing existing data
  // }
  // const furnaceDimensionRowData = furnaceDimensionData.map(data => [data]);
  // try {
  //   XLSX.utils.sheet_add_aoa(worksheet, furnaceDimensionRowData, { origin: 'E21' });
  // } catch (error) {
  //   console.error("Error adding new data:", error)
  // }

  // const liningDetailsData = [
  //   liningDetails.consumption_for_new_lining,
  //   liningDetails.consumption_for_patching,
  //   liningDetails.new_lining_life,
  //   liningDetails.patching_lining_life,
  //   liningDetails.total_no_of_side_lining,
  //   liningDetails.present_lining_method,
  // ]

  // for (let i = 31; i < 36; i++) {
  //   worksheet['E' + (i + 1)] = { t: 's', v: '' }; // Clearing existing data
  // }
  // const liningDetailsRowData = liningDetailsData.map(data => [data]);
  // try {
  //   XLSX.utils.sheet_add_aoa(worksheet, liningDetailsRowData, { origin: 'E31' });
  // } catch (error) {
  //   console.error("Error adding new data:", error)
  // }

  // const alloyDetailsDataArray = [
  //   alloyDetails.fecr_consumption,
  //   alloyDetails.cr_percentage,
  //   alloyDetails.final_cr_percentage,
  //   alloyDetails.cr_recovery,
  //   alloyDetails.alloy_addition,
  //   alloyDetails.casting_method,
  //   alloyDetails.simn_consumption,
  //   alloyDetails.mn_percentage,
  //   alloyDetails.mn_recovery,
  //   alloyDetails.addition_of_simn,
  //   alloyDetails.any_other_alloy_addition,
  //   alloyDetails.final_chemistry_at_furnace,
  //   alloyDetails.final_chemistry_at_billet,
  //   alloyDetails.agreement,
  // ]

  // for (let i = 38; i < 51; i++) {
  //   worksheet['E' + (i + 1)] = { t: 's', v: '' }; // Clearing existing data
  // }
  // const alloyDetailsData = alloyDetailsDataArray.map(data => [data]);
  // try {
  //   XLSX.utils.sheet_add_aoa(worksheet, alloyDetailsData, { origin: 'E38' });
  // } catch (error) {
  //   console.error("Error adding new data:", error)
  // }

  //       const newWorkbookBlob = new Blob([s2ab(XLSX.write(workbook, { type: 'binary' }))], { type: 'application/octet-stream' });

  //       // Create a URL for the blob data
  //       const url = window.URL.createObjectURL(newWorkbookBlob);

  //       // Create a link element
  //       const link = document.createElement('a');
  //       link.href = url;
  //       link.setAttribute('download', 'data_collection.xlsx'); // Change extension to .xlsx

  //       // Append the link to the document body and click it
  //       document.body.appendChild(link);
  //       link.click();

  //       // Clean up
  //       document.body.removeChild(link);
  //       window.URL.revokeObjectURL(url);
  //     } catch (error) {
  //       console.error('Error downloading template:', error.message);
  //     }
  //   }

  const capitalizeFirstLetter = (string) => {
    if (!string) return string
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const handleApplyFilter = (filteredData) => {
    console.log(filteredData, 'filteredData')
    setFilteredTrials(filteredData)
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          width: '96%',
          margin: 10,
        }}
      >
        <img
          src={FilterIcon}
          alt="Filter Icon"
          style={{
            width: '22px',
            height: '22px',
            marginRight: '15px',
            cursor: 'pointer',
          }}
          onClick={handleFilterClick}
        />
      </div>
      {isFilterOpen ? (
        <CostSheetFilterPage
          onClose={() => setIsFilterOpen(false)}
          costSheet={costSheet}
          applyFilter={handleApplyFilter}
        />
      ) : (
        <></>
      )}

      <div>
        {filteredTrials && filteredTrials.length > 0 ? (
          filteredTrials.map((trial, index) => {
            const createdDate = new Date(trial.dataFile.created_at)
            const data_collection_id = trial.id
            const datafile_id = trial.datafile_id
            const submission_status = trial.submission_status
            const capitalizedSubmissionStatus =
              capitalizeFirstLetter(submission_status)

            const SubmisionBackgroundColor =
              capitalizedSubmissionStatus === 'Submitted'
                ? '#0086DC1A'
                : capitalizedSubmissionStatus === 'Draft'
                  ? '#7F7D831A'
                  : capitalizedSubmissionStatus === 'Approved'
                    ? '#34A8531A'
                    : capitalizedSubmissionStatus === 'Rejected'
                      ? '#EA43351A'
                      : 'transparent'

            const SubmisionColor =
              capitalizedSubmissionStatus === 'Submitted'
                ? '#0086DC'
                : capitalizedSubmissionStatus === 'Draft'
                  ? '#7F7D83'
                  : capitalizedSubmissionStatus === 'Approved'
                    ? '#34A853'
                    : capitalizedSubmissionStatus === 'Rejected'
                      ? '#EA4335'
                      : 'transparent'

            const shouldDisplayAwaitingApproval =
              (currentUser.role.role_id === 3 ||
                currentUser.role.role_id === 4) &&
              capitalizedSubmissionStatus === 'Submitted'
            const displaySubmissionStatus = shouldDisplayAwaitingApproval
              ? 'Awaiting Approval'
              : capitalizedSubmissionStatus
            console.log('displaySubmissionStatus', displaySubmissionStatus)

            const formattedDate = createdDate.toLocaleDateString('en-US', {
              day: 'numeric',
              month: 'short',
              year: 'numeric',
            })
            return (
              <Card
                key={index}
                sx={{
                  border: '1px solid #E6E6E6',
                  boxShadow: '0px 5px 13px -5px #0A090B0D',
                  width: '100%',
                  height: '180px',
                  marginBottom: '20px',
                }}
              >
                <CardContent>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '16px',
                          fontWeight: 600,
                          lineHeight: '20px',
                          letterSpacing: '-0.006em',
                          textAlign: 'left',
                          color: '#252C32',
                          marginTop: '10px',
                        }}
                      >
                        {`Cost_Sheet_${trial.dataFile.customer.organization_name}`}
                      </Typography>

                      <IconButton
                        aria-label="options"
                        onClick={(event) =>
                          handleMenuOpen(
                            event,
                            datafile_id,
                            trial,
                            submission_status
                          )
                        }
                      >
                        <MoreVertIcon style={{ color: '#0086DC' }} />
                      </IconButton>
                    </div>

                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: 700,
                        lineHeight: '20px',
                        letterSpacing: '-0.6%',
                        textAlign: 'left',
                        marginTop: '5px',
                        color: '#252C32',
                      }}
                    >
                      {trial.dataFile.customer.organization_name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 400,
                        lineHeight: '20px',
                        letterSpacing: '-0.006em',
                        textAlign: 'left',
                        color: '#6E7C87',
                      }}
                    >
                      {/* {trial.dataFile.segment.name !== null ? trial.dataFile.segment.name : "N/A"} */}
                    </Typography>

                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontWeight: 400,
                        lineHeight: '20px',
                        letterSpacing: '-0.006em',
                        textAlign: 'left',
                        color: SubmisionColor,
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: SubmisionBackgroundColor,
                          padding: '0 8px',
                          borderRadius: '4px',
                        }}
                      >
                        {displaySubmissionStatus}
                      </span>
                    </Typography>

                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 'auto',
                      }}
                    >
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: 500,
                          lineHeight: '20px',
                          letterSpacing: '-0.006em',
                          textAlign: 'left',
                          color: '#6E7C87',
                        }}
                      >
                        Created Date:
                      </Typography>

                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: 700,
                          lineHeight: '20px',
                          letterSpacing: '-0.006em',
                          textAlign: 'left',
                          color: '#252C32',
                          marginLeft: '8px',
                        }}
                      >
                        {formattedDate}
                      </Typography>

                      {/* Avatar component */}
                      <Avatar
                        style={{
                          width: '30px',
                          height: '30px',
                          borderRadius: '12px',
                          background: '#D7EDFF',
                          color: '#0086DC',
                          fontWeight: 700,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginLeft: 'auto',
                          fontSize: '10px',
                        }}
                      >
                        {trial.dataFile.user &&
                          trial.dataFile.user.firstname &&
                          trial.dataFile.user.lastname && (
                            <Avatar
                              style={{
                                width: '30px',
                                height: '30px',
                                borderRadius: '12px',
                                background: '#D7EDFF',
                                color: '#0086DC',
                                fontWeight: 700,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: 'auto',
                                fontSize: '10px',
                              }}
                            >
                              {trial.dataFile.user.firstname.charAt(0)}
                              {trial.dataFile.user.lastname.charAt(0)}
                            </Avatar>
                          )}
                      </Avatar>
                    </div>
                  </div>
                </CardContent>
              </Card>
            )
          })
        ) : (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-around',
              marginTop: 20,
            }}
          >
            {costSheet.map((trial, index) => {
              console.log(trial, 'trialtrial')
              const createdDate = new Date(trial.dataFile.created_at)
              const data_collection_id = trial.id
              console.log(data_collection_id, 'daily_trial_iddaily_trial_id')
              const datafile_id = trial.datafile_id
              const submission_status = trial.submission_status
              const capitalizedSubmissionStatus =
                capitalizeFirstLetter(submission_status)
              console.log(
                'capitalizedSubmissionStatus',
                capitalizedSubmissionStatus
              )

              const SubmisionBackgroundColor =
                capitalizedSubmissionStatus === 'Submitted'
                  ? '#0086DC1A'
                  : capitalizedSubmissionStatus === 'Draft'
                    ? '#7F7D831A'
                    : capitalizedSubmissionStatus === 'Approved'
                      ? '#34A8531A'
                      : capitalizedSubmissionStatus === 'Rejected'
                        ? '#EA43351A'
                        : 'transparent'

              const SubmisionColor =
                capitalizedSubmissionStatus === 'Submitted'
                  ? '#0086DC'
                  : capitalizedSubmissionStatus === 'Draft'
                    ? '#7F7D83'
                    : capitalizedSubmissionStatus === 'Approved'
                      ? '#34A853'
                      : capitalizedSubmissionStatus === 'Rejected'
                        ? '#EA4335'
                        : 'transparent'

              const shouldDisplayAwaitingApproval =
                (currentUser.role.role_id === 3 ||
                  currentUser.role.role_id === 4) &&
                capitalizedSubmissionStatus === 'Submitted'
              const displaySubmissionStatus = shouldDisplayAwaitingApproval
                ? 'Awaiting Approval'
                : capitalizedSubmissionStatus

              const formattedDate = createdDate.toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })

              return (
                <Card
                  key={index}
                  sx={{
                    border: '1px solid #E6E6E6',
                    boxShadow: '0px 5px 13px -5px #0A090B0D',
                    width: '100%',
                    height: '180px',
                    marginBottom: '20px',
                  }}
                >
                  <CardContent>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: '16px',
                            fontWeight: 600,
                            lineHeight: '20px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#252C32',
                            marginTop: '10px',
                          }}
                        >
                          {`Cost_Sheet_${trial.dataFile.customer.organization_name}`}
                        </Typography>

                        <IconButton
                          aria-label="options"
                          onClick={(event) =>
                            handleMenuOpen(
                              event,
                              datafile_id,
                              trial,
                              submission_status
                            )
                          }
                        >
                          <MoreVertIcon style={{ color: '#0086DC' }} />
                        </IconButton>
                      </div>

                      <Typography
                        variant="body1"
                        gutterBottom
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '14px',
                          fontWeight: 700,
                          lineHeight: '20px',
                          letterSpacing: '-0.6%',
                          textAlign: 'left',
                          marginTop: '5px',
                          color: '#252C32',
                        }}
                      >
                        {trial.dataFile.customer.organization_name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: 400,
                          lineHeight: '20px',
                          letterSpacing: '-0.006em',
                          textAlign: 'left',
                          color: '#6E7C87',
                        }}
                      >
                        {/* {trial.dataFile.segment.name !== null ? trial.dataFile.segment.name : "N/A"} */}
                      </Typography>

                      <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: 400,
                          lineHeight: '20px',
                          letterSpacing: '-0.006em',
                          textAlign: 'left',
                          color: SubmisionColor,
                        }}
                      >
                        <span
                          style={{
                            backgroundColor: SubmisionBackgroundColor,
                            padding: '0 8px',
                            borderRadius: '4px',
                          }}
                        >
                          {displaySubmissionStatus}
                        </span>
                      </Typography>

                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: 'auto',
                        }}
                      >
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '20px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#6E7C87',
                          }}
                        >
                          Created Date:
                        </Typography>

                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 700,
                            lineHeight: '20px',
                            letterSpacing: '-0.006em',
                            textAlign: 'left',
                            color: '#252C32',
                            marginLeft: '8px',
                          }}
                        >
                          {formattedDate}
                        </Typography>

                        {/* Avatar component */}
                        <Avatar
                          style={{
                            width: '30px',
                            height: '30px',
                            borderRadius: '12px',
                            background: '#D7EDFF',
                            color: '#0086DC',
                            fontWeight: 700,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginLeft: 'auto',
                            fontSize: '10px',
                          }}
                        >
                          {trial.dataFile.user &&
                            trial.dataFile.user.firstname &&
                            trial.dataFile.user.lastname && (
                              <Avatar
                                style={{
                                  width: '30px',
                                  height: '30px',
                                  borderRadius: '12px',
                                  background: '#D7EDFF',
                                  color: '#0086DC',
                                  fontWeight: 700,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  marginLeft: 'auto',
                                  fontSize: '10px',
                                }}
                              >
                                {trial.dataFile.user.firstname.charAt(0)}
                                {trial.dataFile.user.lastname.charAt(0)}
                              </Avatar>
                            )}
                        </Avatar>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              )
            })}
          </div>
        )}
      </div>

      {isPopupOpen && (
        <div
          ref={popupRef}
          style={{
            position: 'absolute',
            top: `${popupPosition.top}px`,
            right: `${popupPosition.right}px`,
            background: 'rgba(255, 255, 255, 1)',
            padding: '20px',
            borderRadius: '8px',
            border: '1px solid #E8E8E8',
            boxShadow: '0px 0px 35px 0px rgba(0, 0, 0, 0.1)',
            zIndex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          {(selectedSubmissionStatus === 'draft' ||
            (selectedSubmissionStatus === 'rejected' &&
              (currentUser.role.role_id === 1 ||
                currentUser.role.role_id === 2))) && (
            <div
              onClick={handleEdit}
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                padding: '10px',
              }}
            >
              <img
                src={EditIcon}
                alt="Edit Icon"
                style={{ marginRight: '10px' }}
              />
              <Typography
                style={{
                  color: '#252C32',
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 800,
                  lineHeight: 'normal',
                  letterSpacing: '-0.084px',
                }}
              >
                Edit
              </Typography>
            </div>
          )}

          {selectedSubmissionStatus !== 'draft' && (
            <div
              onClick={handleView}
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                padding: '10px',
              }}
            >
              <img
                src={ViewIcon}
                alt="Edit Icon"
                style={{ marginRight: '10px' }}
              />
              <Typography
                style={{
                  color: '#252C32',
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 800,
                  lineHeight: 'normal',
                  letterSpacing: '-0.084px',
                }}
              >
                View
              </Typography>
            </div>
          )}

          <div
            onClick={() => handleDelete()}
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              padding: '10px',
            }}
          >
            <img
              src={DeleteIcon}
              alt="Delete Icon"
              style={{ marginRight: '10px' }}
            />
            <Typography
              style={{
                color: '#FF3347',
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 800,
                lineHeight: 'normal',
                letterSpacing: '-0.084px',
              }}
            >
              Delete
            </Typography>
          </div>
        </div>
      )}
    </div>
  )
}

export default CostSheetHome
