import React, { useState, useRef, useEffect, Fragment } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Snackbar,
  Alert,
} from '@mui/material'
import EditIcon from '../../../assets/icons/ic_edit.svg'
import DeleteIcon from '../../../assets/icons/ic_delete.svg'
import IconButton from '@mui/material/IconButton'
import API from '../../services/API'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import AlertDialog from '../../input/AlertDialog'
import StyledButton from '../../input/StyledButton'

const ViewDailyTrial = () => {
  const navigate = useNavigate()
  const [tableData, setTableData] = useState([])
  const [headerText, setHeaderText] = useState('General Details')
  const [submissionStatus, setSubmissionStatus] = useState('')
  const tableRef = useRef(null)
  const id = localStorage.getItem('daily_trial_id')

  const [comments, setComments] = useState('')
  const currentUser = JSON.parse(localStorage.getItem('user'))
  const [commentsError, setCommentsError] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [editPerformed, setEditPerformed] = useState('')
  const viewMode = localStorage.getItem('Trial')
  const [loading, setLoading] = useState(false) // Loader state
  const [error, setError] = useState(null)
  const access_token = localStorage.getItem('accesstoken')

  /////////////offline//////////

  const [isOnline, setIsOnline] = useState(window.navigator.onLine)

  const [db, setDb] = useState(null)

  useEffect(() => {
    const handleOnline = () => {
      if (!isOnline) {
        setIsOnline(true)
        localStorage.setItem('onlinestate', 1)

        window.location.reload()
      }
    }

    const handleOffline = () => {
      localStorage.setItem('onlinestate', 0)
      // setIsOnlines(false)
      setIsOnline(false)
      // navigate('/reports')
    }

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [isOnline])

  const dbName = 'pcr'
  const customerName = 'customers'
  const furnacesName = 'furnaces'
  const templateName = 'template'
  const segmentName = 'segment'
  const datafileName = 'datafile'
  const dailytrialName = 'daily_trial'
  const nrmName = 'nrm_performance'
  const erosionName = 'erosion_profile'
  const dataCollectionName = 'data_collection'
  const costSheetName = 'cost_sheet'
  const LiningName = 'lining_patching_no'

  useEffect(() => {
    if (!isOnline) {
      const request = indexedDB.open(dbName, 1)

      request.onupgradeneeded = (event) => {
        const database = event.target.result
        database.createObjectStore(customerName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(furnacesName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(templateName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(segmentName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(datafileName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(dailytrialName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(nrmName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(erosionName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(dataCollectionName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(costSheetName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(LiningName, {
          keyPath: 'id',
          autoIncrement: true,
        })
      }

      request.onsuccess = (event) => {
        const database = event.target.result
        const transaction = database.transaction(
          [
            'customers',
            'furnaces',
            'template',
            'segment',
            'datafile',
            'daily_trial',
            'nrm_performance',
            'erosion_profile',
            'data_collection',
            'cost_sheet',
            'lining_patching_no',
          ],
          'readwrite'
        )

        setDb(database)
      }

      request.onerror = (event) => {
        console.error('Error opening IndexedDB', event)
      }
    }
  }, [isOnline])

  useEffect(() => {
    if (db) {
      fetchData()
    }
  }, [db])

  useEffect(() => {
    if (isOnline) {
      fetchData()
    }
  }, [id, isOnline])

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(`${API.GET_DAILY_TRIAL_BY_ID}/${id}`);
  //       const data = response.data;

  //       // Check if data.general_details is not null
  //       if (data.general_details) {
  //         const result = data.general_details
  //           .filter((detail) => !detail.isdelete) // Filter out items with isdelete set to false
  //           .map((detail) => {
  //             const objectId = detail.id;
  //             // Match details from other arrays based on the id
  //             const matchedDetails = {
  //               general_details: detail,
  //               furnace_details:
  //                 data.furnace_details.find((item) => item.id === objectId) || {},
  //               charge_mix_details:
  //                 data.charge_mix_details.find((item) => item.id === objectId) ||
  //                 {},
  //               carbon: data.carbon.find((item) => item.id === objectId) || {},
  //               alloying_addition:
  //                 data.alloying_addition.find((item) => item.id === objectId) ||
  //                 {},
  //               other_parameters:
  //                 data.other_parameters.find((item) => item.id === objectId) ||
  //                 {},
  //               id: data.id,
  //             };
  //             return matchedDetails;
  //           });

  //         console.log("RESULTTT", result);
  //         setTableData(result);
  //       } else {
  //         // Handle the case where data.general_details is null
  //         console.error("Data general details is null");
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //       // Handle error or set a default state for tableData
  //     }
  //   };
  //   fetchData();
  // }, [id]);

  const fetchData = async () => {
    const access_token = localStorage.getItem('accesstoken')
    if (isOnline) {
      try {
        const response = await axios.get(`${API.GET_DAILY_TRIAL_BY_ID}/${id}`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        const data = response.data
        console.log(data, 'data123')

        if (data && data.general_details) {
          const result = data.general_details
            .filter((detail) => detail && !detail.isdelete) // Filter out items with isdelete set to false
            .map((detail) => {
              if (detail) {
                const submissionstatus = detail.submission_status
                const comments = detail.comments
                console.log('comments', comments)
                console.log('submissionstatus', submissionStatus)
                setComments(comments)
                setSubmissionStatus(submissionstatus)
                const objectId = detail.id
                // Match details from other arrays based on the id
                const matchedDetails = {
                  general_details: detail,
                  furnace_details: data.furnace_details,
                  charge_mix_details: data.charge_mix_details,
                  carbon: data.carbon,
                  alloying_addition: data.alloying_addition,
                  other_parameters: data.other_parameters,
                  id: data.id,
                }

                console.log(matchedDetails, 'matchedDetails')

                return matchedDetails
              } else {
                return null // or any default value you want to return for null objects
              }
            })
            .filter((detail) => detail !== null) // Filter out null objects

          console.log('RESULTTT', result)
          setTableData(result)
        } else {
          console.error('Data or general details is null')
        }
      } catch (error) {
        console.error('Error fetching data:', error)
        // Handle error or set a default state for tableData
      }
    } else {
      try {
        const transaction1 = db.transaction(dailytrialName, 'readonly')
        const objectStore1 = transaction1.objectStore(dailytrialName)
        const id = localStorage.getItem('daily_trial_id')
        const id_int = parseInt(id, 10)
        const getAllRequest1 = objectStore1.get(id_int)

        getAllRequest1.onsuccess = () => {
          const trialdata = getAllRequest1.result
          console.log('allData123', trialdata)

          const tableData = []
          let Trial = 0

          // trialdata.general_details.forEach((generalDetail, index) => {
          //   const groupedData = {
          //     general_details: [generalDetail],
          //     furnace_details: (trialdata.furnace_details[index] && trialdata.furnace_details[index]) || {},
          //     charge_mix_details: (trialdata.charge_mix_details[index] && trialdata.charge_mix_details[index]) || {},
          //     carbon: (trialdata.carbon[index] && trialdata.carbon[index]) || {},
          //     alloying_addition: (trialdata.alloying_addition[index] && trialdata.alloying_addition[index]) || {},
          //     other_parameters: (trialdata.other_parameters[index] && trialdata.other_parameters[index]) || {},
          //     id: trialdata.datafile_id,
          //   };

          //   tableData.push(groupedData);
          //   Trial++;
          // });

          let generalDetailsArray = []
          if (typeof trialdata.general_details === 'object') {
            // Convert general_details object to an array
            generalDetailsArray = [trialdata.general_details]
          } else if (Array.isArray(trialdata.general_details)) {
            generalDetailsArray = trialdata.general_details
          }

          // Iterate through generalDetailsArray
          generalDetailsArray.forEach((generalDetail, index) => {
            const groupedData = {
              general_details: Array.isArray(generalDetail)
                ? generalDetail
                : [generalDetail],
              furnace_details: Array.isArray(trialdata.furnace_details[index])
                ? trialdata.furnace_details[index]
                : trialdata.furnace_details || [],
              charge_mix_details: Array.isArray(
                trialdata.charge_mix_details[index]
              )
                ? trialdata.charge_mix_details[index]
                : trialdata.charge_mix_details || [],
              carbon: Array.isArray(trialdata.carbon[index])
                ? trialdata.carbon[index]
                : trialdata.carbon || [],
              alloying_addition: Array.isArray(
                trialdata.alloying_addition[index]
              )
                ? trialdata.alloying_addition[index]
                : trialdata.alloying_addition || [],
              other_parameters: Array.isArray(trialdata.other_parameters[index])
                ? trialdata.other_parameters[index]
                : trialdata.other_parameters || [],
              id: trialdata.datafile_id,
            }

            tableData.push(groupedData)
            Trial++
          })

          console.log('Table Data:', tableData)
          setTableData(tableData)
        }

        getAllRequest1.onerror = (event) => {
          setTableData([])
          console.error('Error getting data from IndexedDB', event)
        }
      } catch (error) {
        console.error('Error fetching data:', error)
        setTableData([])
      }
    }
  }

  const renderCarbonValue = (carbonData) => {
    if (carbonData) {
      if (carbonData.anthracite) {
        return carbonData.anthracite
      } else if (carbonData.cpc) {
        return carbonData.cpc
      } else if (carbonData.carbon_block) {
        return carbonData.carbon_block
      }
    }
    return null
  }

  const handleEdit = (index) => {
    setEditPerformed(true) // Set editPerformed to true after edit action
    console.log('index check', index)
    localStorage.setItem('TrialIndex', index)
    localStorage.setItem('Trial', 'edit')
    // setTrialName("edit");
    navigate('/dailytrialmonitoringM')
  }

  const handleResubmit = () => {
    if (
      (currentUser.role.role_id === 1 || currentUser.role.role_id === 2) &&
      submissionStatus === 'rejected'
    ) {
      if (editPerformed) {
        // Assuming editPerformed is a state variable that tracks whether an edit action has been performed
        // Proceed with resubmit logic
        setShowSnackbar('Resubmitting...')
        // Add your resubmit logic here
      } else {
        // Show a message or handle the case where edit action is required
        setShowSnackbar(
          'Please click on the edit button before proceeding with the resubmit.'
        )
      }
    }
  }

  const handleApprove = async () => {
    try {
      const updatedGeneralDetails = tableData.map((item) => ({
        ...item,
        general_details: {
          ...item.general_details,
          submission_status: 'approved',
        },
      }))

      const id = localStorage.getItem('daily_trial_id')

      const updateResponse = await axios.put(
        `${API.FORM_UPDATE_GENERAL_DETAILS}/${id}`,
        { data: updatedGeneralDetails, id },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      console.log('Update response:', updateResponse.data)
      if (updateResponse.status === 200) {
        navigate('/approvestatus')
      } else {
        console.log('problem with API')
      }
    } catch (error) {
      console.error('Error updating data:', error)
    }
  }

  const handleReject = async () => {
    // Ensure comments is always a string
    const commentsTrimmed = (comments || '').trim()

    if (!commentsTrimmed) {
      setCommentsError(true)
      setShowSnackbar(true)
    } else {
      setCommentsError(false)
      try {
        const updatedGeneralDetails = tableData.map((item) => ({
          ...item,
          general_details: {
            ...item.general_details,
            submission_status: 'rejected',
            comments: commentsTrimmed,
          },
        }))

        const id = localStorage.getItem('daily_trial_id')

        const updateResponse = await axios.put(
          `${API.FORM_UPDATE_GENERAL_DETAILS}/${id}`,
          { data: updatedGeneralDetails, id },
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        console.log('Update response:', updateResponse.data)

        if (updateResponse.status === 200) {
          navigate('/rejectstatus')
        } else {
          console.log('problem with API')
        }
      } catch (error) {
        console.error('Error updating data:', error)
      }
    }
  }

  const handleSnackbarClose = () => {
    setShowSnackbar(false)
  }

  const handleDelete = async (tableData, index) => {
    if (isOnline) {
      console.log('tableData', tableData, index)
      const id = tableData[index].general_details.id
      console.log(id)
      AlertDialog({
        type: 'delete',
        title: 'Delete?',
        text: 'Are you sure you want to delete?',
        confirmButtonText: 'Yes',
        onConfirm: async () => {
          // const updatedTableData = [...tableData];
          // updatedTableData[index].general_details.isdelete = true; // Set isdelete within general_details
          //   console.log("updatedTableData",updatedTableData);
          // const updatedDataWithoutDeletedItem = updatedTableData.filter(item => !item.general_details.isdelete);
          const updatedDataWithoutDeletedItem = tableData.map((item) => {
            if (item.general_details.id === id) {
              // Set isdelete to true within general_details for the item at the specified index
              return {
                ...item,
                general_details: {
                  ...item.general_details,
                  isdelete: true,
                },
              }
            }
            return item
          })
          console.log(
            updatedDataWithoutDeletedItem,
            'updatedDataWithoutDeletedItem'
          )
          // Set the state with the updated array
          setTableData(updatedDataWithoutDeletedItem)

          const rowId = tableData[index].id

          const updateResponse = await axios.put(
            `${API.DELETE_DAILY_TRIAL_UPDATE}/${rowId}`,
            { data: updatedDataWithoutDeletedItem, rowId: rowId },
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          )

          if (updateResponse.status === 200) {
            AlertDialog({
              type: 'success',
              title: 'Success',
              text: 'Deleted Successfully',
              confirmButtonText: 'Ok',
            })
          }
        },
      })
    } else {
      console.log('tableData', tableData)
      console.log('index', index)

      const updatedTableData = tableData.map((item) => ({
        ...item,
        general_details: item.general_details.filter((_, idx) => idx !== index),
        furnace_details: item.furnace_details.filter((_, idx) => idx !== index),
        carbon: item.carbon.filter((_, idx) => idx !== index),
        charge_mix_details: item.charge_mix_details.filter(
          (_, idx) => idx !== index
        ),
        alloying_addition: item.alloying_addition.filter(
          (_, idx) => idx !== index
        ),
        other_parameters: item.other_parameters.filter(
          (_, idx) => idx !== index
        ),
      }))

      console.log('updatedTableData', updatedTableData)

      // Update state with the modified array
      setTableData(updatedTableData)

      const id = localStorage.getItem('daily_trial_id')
      const TrialIndex = localStorage.getItem('TrialIndex')

      const transaction = db.transaction(dailytrialName, 'readwrite')
      const objectStore = transaction.objectStore(dailytrialName)

      const id_int = parseInt(id, 10)
      const getRequest = objectStore.get(id_int)

      getRequest.onsuccess = async () => {
        const trialdata = getRequest.result
        console.log('trialdata', trialdata)

        const updatedTrialData = {
          ...trialdata,
          general_details: trialdata.general_details.filter(
            (_, idx) => idx !== index
          ),
          furnace_details: trialdata.furnace_details.filter(
            (_, idx) => idx !== index
          ),
          carbon: trialdata.carbon.filter((_, idx) => idx !== index),
          charge_mix_details: trialdata.charge_mix_details.filter(
            (_, idx) => idx !== index
          ),
          alloying_addition: trialdata.alloying_addition.filter(
            (_, idx) => idx !== index
          ),
          other_parameters: trialdata.other_parameters.filter(
            (_, idx) => idx !== index
          ),
          other_calculated_varialble:
            trialdata.other_calculated_varialble.filter(
              (_, idx) => idx !== index
            ),
        }

        const updateRequest = objectStore.put(updatedTrialData)

        updateRequest.onsuccess = () => {
          console.log('trialdata updated successfully')
        }

        updateRequest.onerror = (error) => {
          console.error('Error updating trialdata:', error)
        }
      }

      getRequest.onerror = () => {
        console.error(
          'Offline Mode - Error fetching trial data from IndexedDB.'
        )
        // setError("Error fetching trial data from IndexedDB.");
        // setLoading(false);
      }
    }
  }

  const determineHeader = () => {
    for (let i = 0; i < tableData.length; i++) {
      const carbonData = tableData[i]?.carbon[0] // Assuming tableData is structured similarly
      if (carbonData) {
        if (carbonData.anthracite) {
          return 'Anthracite'
        } else if (carbonData.cpc) {
          return 'CPC'
        } else if (carbonData.carbon_block) {
          return 'Carbon Block'
        }
      }
    }
    return null
  }

  useEffect(() => {
    const handleScroll = () => {
      const container = tableRef.current
      if (container) {
        const rect = container.getBoundingClientRect()
        const containerLeft = rect.left
        const heatOnTimeColumnIndex = 9 // Index of the "Heat ON Time" column
        const cells = container.querySelectorAll('td')
        const heatOnTimeCell = cells[heatOnTimeColumnIndex]
        if (heatOnTimeCell) {
          const heatOnTimeCellRect = heatOnTimeCell.getBoundingClientRect()
          const heatOnTimeCellLeft = heatOnTimeCellRect.left
          if (heatOnTimeCellLeft >= tableRef) {
            setHeaderText('Furnace Details')
          } else {
            setHeaderText('General Details')
          }
        }
      }
    }

    const container = tableRef.current
    if (container) {
      container.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])
  // Empty dependency array to run this effect only once on mount
  const cellStyles = {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '-0.006em',
    textAlign: 'left',
    color: '#000000',
    backgroundColor: '#f0f0f0',
  }

  const rowStyles = {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.006em',
    textAlign: 'left',
    color: '#252C32',
    backgroundColor: '#f4f9e9'
  }

  const firstRowStyles = {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 800,
    lineHeight: '24px',
    letterSpacing: '-0.006em',
    textAlign: 'left',
    color: '#252C32',
    backgroundColor: '#f0f0f0',
  }

  const renderCell = (value) => {
    if (value === null || value === undefined || value === '') {
      return 'N/A'
    } else {
      return value
    }
  }

  const isEmpty = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) return false
    }
    return true
  }
  return (
    <>
      {isOnline ? (
        <div style={{ position: 'relative', overflowX: 'auto' }}>
          <div style={{ overflowX: 'auto' }}>
            {tableData.length === 0 ? (
              <Typography
                variant="body1"
                sx={{ textAlign: 'center', marginTop: '20px' }}
              >
                Trials not found
              </Typography>
            ) : (
              <div>
                {console.log('tableData', tableData)}

                <Table ref={tableRef}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{ ...firstRowStyles, width: '30px' }}
                      ></TableCell>
                      <TableCell colspan={8} style={{ ...firstRowStyles }}>
                        General Details
                      </TableCell>
                      <TableCell
                        colspan={16}
                        style={{
                          ...firstRowStyles,
                          borderLeft: '2px solid #e0e0e0',
                        }}
                      >
                        Furnace Details
                      </TableCell>
                      <TableCell
                        colspan={12}
                        style={{
                          ...firstRowStyles,
                          borderLeft: '2px solid #e0e0e0',
                        }}
                      >
                        Charge Mix Details
                      </TableCell>
                      <TableCell
                        colspan={5}
                        style={{
                          ...firstRowStyles,
                          borderLeft: '2px solid #e0e0e0',
                        }}
                      >
                        Carbon
                      </TableCell>
                      <TableCell
                        colspan={13}
                        style={{
                          ...firstRowStyles,
                          borderLeft: '2px solid #e0e0e0',
                        }}
                      >
                        Alloying Addition
                      </TableCell>
                      <TableCell
                        colspan={4}
                        style={{
                          ...firstRowStyles,
                          borderLeft: '2px solid #e0e0e0',
                        }}
                      >
                        Other parameters
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          ...cellStyles,
                          position: 'sticky',
                          left: 0,
                          zIndex: 1,
                          backgroundColor: '#f0f0f0',
                          
                        }}
                      >
                        S.No
                      </TableCell>
                      <TableCell style={cellStyles}>Furnace NO.</TableCell>
                      <TableCell style={cellStyles}>Crucible NO.</TableCell>
                      <TableCell style={cellStyles}>Campaign NO.</TableCell>
                      <TableCell style={cellStyles}>
                        Lining / Patching
                      </TableCell>
                      <TableCell style={cellStyles}>Heat No</TableCell>
                      <TableCell style={cellStyles}>Date</TableCell>
                      <TableCell style={cellStyles}>Customer Ref No</TableCell>
                      <TableCell style={cellStyles}>Grade</TableCell>
                      <TableCell style={cellStyles}>Heat ON Time</TableCell>
                      <TableCell style={cellStyles}>Heat TAP Time</TableCell>
                      <TableCell style={cellStyles}>
                        Breakdown duration
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Heat Duration actual
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Heat Duration calculated
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Tapping temperature
                      </TableCell>
                      <TableCell style={cellStyles}>Tapped tonnage</TableCell>
                      <TableCell style={cellStyles}>
                        Melt Rate based on actual duration
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Melt Rate based on calculated duration
                      </TableCell>
                      <TableCell style={cellStyles}>Frequency</TableCell>
                      <TableCell style={cellStyles}>Voltage</TableCell>
                      <TableCell style={cellStyles}>
                        Power In the panel
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Capacitors Removed
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Total Power Consumed
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Energy consumption per unit Ton actual
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Energy consumption per unit Ton calculated
                      </TableCell>
                      <TableCell style={cellStyles}>Scrap</TableCell>
                      <TableCell style={cellStyles}>Shredded</TableCell>
                      <TableCell style={cellStyles}>Pig Iron</TableCell>
                      <TableCell style={cellStyles}>Pooled Iron</TableCell>
                      <TableCell style={cellStyles}>Hot Heel</TableCell>
                      <TableCell style={cellStyles}>Lump</TableCell>
                      <TableCell style={cellStyles}>Pellet</TableCell>
                      <TableCell style={cellStyles}>
                        Fines less than 5 mm
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Fines less than 1 mm
                      </TableCell>
                      <TableCell style={cellStyles}>Slag Crushers</TableCell>
                      <TableCell style={cellStyles}>
                        Tundish or Slag or Metalic Jam
                      </TableCell>
                      <TableCell style={cellStyles}>Total charge</TableCell>
                      <TableCell style={cellStyles}>
                        {determineHeader()}
                      </TableCell>
                      <TableCell style={cellStyles}>Met Coke</TableCell>
                      <TableCell style={cellStyles}>50 C%</TableCell>
                      <TableCell style={cellStyles}>85 C%</TableCell>
                      <TableCell style={cellStyles}>Final C%</TableCell>
                      <TableCell style={cellStyles}>
                        Si% Before addition
                      </TableCell>
                      <TableCell style={cellStyles}>Final Si%</TableCell>
                      <TableCell style={cellStyles}>
                        Mn% before Addition
                      </TableCell>
                      <TableCell style={cellStyles}>Final Mn%</TableCell>
                      <TableCell style={cellStyles}>
                        Cr% before addition
                      </TableCell>
                      <TableCell style={cellStyles}>Final Cr%</TableCell>
                      <TableCell style={cellStyles}>SiMn</TableCell>
                      <TableCell style={cellStyles}>Mn % in SiMn</TableCell>
                      <TableCell style={cellStyles}>FeMn</TableCell>
                      <TableCell style={cellStyles}>FeSi</TableCell>
                      <TableCell style={cellStyles}>FeCr</TableCell>
                      <TableCell style={cellStyles}>Mill scale</TableCell>
                      <TableCell style={cellStyles}>
                        Any other Alloy addition
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Inlet water temp.
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Outlet Water temp.
                      </TableCell>
                      <TableCell style={cellStyles}>Water Pr.</TableCell>
                      <TableCell style={cellStyles}>Remarks</TableCell>
                      {(submissionStatus === 'draft' ||
                        submissionStatus === 'rejected') &&
                        viewMode !== 'view' && (
                          <TableCell
                            style={{
                              ...cellStyles,
                              position: 'sticky',
                              right: 0,
                              zIndex: 1,
                              backgroundColor: '#fff',
                            }}
                          >
                            Action
                          </TableCell>
                        )}
                      {submissionStatus === 'draft' && viewMode === 'view' && (
                        <TableCell
                          style={{
                            ...cellStyles,
                            position: 'sticky',
                            right: 0,
                            zIndex: 1,
                            backgroundColor: '#fff',
                          }}
                        >
                          Action
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{ ...firstRowStyles, width: '30px' }}
                      ></TableCell>
                      <TableCell style={firstRowStyles}>No.</TableCell>
                      <TableCell style={firstRowStyles}>NO.</TableCell>
                      <TableCell style={firstRowStyles}>NO.</TableCell>
                      <TableCell style={firstRowStyles}>NO</TableCell>
                      <TableCell style={firstRowStyles}>NO.</TableCell>
                      <TableCell style={firstRowStyles}>DD-MM-YYYY</TableCell>
                      <TableCell style={firstRowStyles}>No unit</TableCell>
                      <TableCell style={firstRowStyles}>No unit</TableCell>
                      <TableCell
                        style={{
                          ...firstRowStyles,
                          borderLeft: '2px solid #e0e0e0',
                        }}
                      >
                        HH:mm:ss
                      </TableCell>
                      <TableCell style={firstRowStyles}>HH:mm:ss</TableCell>
                      <TableCell style={firstRowStyles}>HH:mm:ss</TableCell>
                      <TableCell style={firstRowStyles}>HH:mm:ss</TableCell>
                      <TableCell style={firstRowStyles}>HH:mm:ss</TableCell>
                      <TableCell style={firstRowStyles}>⁰C</TableCell>
                      <TableCell style={firstRowStyles}>MT</TableCell>
                      <TableCell style={firstRowStyles}>kg/min</TableCell>
                      <TableCell style={firstRowStyles}>kg/min</TableCell>
                      <TableCell style={firstRowStyles}>Hz</TableCell>
                      <TableCell style={firstRowStyles}>V</TableCell>
                      <TableCell style={firstRowStyles}>KW</TableCell>
                      <TableCell style={firstRowStyles}>NO.</TableCell>
                      <TableCell style={firstRowStyles}>KWH</TableCell>
                      <TableCell style={firstRowStyles}>KWH/Ton</TableCell>
                      <TableCell style={firstRowStyles}>KWH/Ton</TableCell>
                      <TableCell
                        style={{
                          ...firstRowStyles,
                          borderLeft: '2px solid #e0e0e0',
                        }}
                      >
                        MT
                      </TableCell>
                      <TableCell style={firstRowStyles}>MT</TableCell>
                      <TableCell style={firstRowStyles}>MT</TableCell>
                      <TableCell style={firstRowStyles}>MT</TableCell>
                      <TableCell style={firstRowStyles}>MT</TableCell>
                      <TableCell style={firstRowStyles}>MT</TableCell>
                      <TableCell style={firstRowStyles}>MT</TableCell>
                      <TableCell style={firstRowStyles}>MT</TableCell>
                      <TableCell style={firstRowStyles}>MT</TableCell>
                      <TableCell style={firstRowStyles}>MT</TableCell>
                      <TableCell style={firstRowStyles}>MT</TableCell>
                      <TableCell style={firstRowStyles}>MT</TableCell>
                      <TableCell
                        style={{
                          ...firstRowStyles,
                          borderLeft: '2px solid #e0e0e0',
                        }}
                      >
                        Kg
                      </TableCell>
                      <TableCell style={firstRowStyles}>Kg</TableCell>
                      <TableCell style={firstRowStyles}>%</TableCell>
                      <TableCell style={firstRowStyles}>%</TableCell>
                      <TableCell style={firstRowStyles}>%</TableCell>
                      <TableCell
                        style={{
                          ...firstRowStyles,
                          borderLeft: '2px solid #e0e0e0',
                        }}
                      >
                        %
                      </TableCell>
                      <TableCell style={firstRowStyles}>%</TableCell>
                      <TableCell style={firstRowStyles}>%</TableCell>
                      <TableCell style={firstRowStyles}>%</TableCell>
                      <TableCell style={firstRowStyles}>%</TableCell>
                      <TableCell style={firstRowStyles}>%</TableCell>
                      <TableCell style={firstRowStyles}>Kg</TableCell>
                      <TableCell style={firstRowStyles}>%</TableCell>
                      <TableCell style={firstRowStyles}>Kg</TableCell>
                      <TableCell style={firstRowStyles}>Kg</TableCell>
                      <TableCell style={firstRowStyles}>Kg</TableCell>
                      <TableCell style={firstRowStyles}>Kg</TableCell>
                      <TableCell style={firstRowStyles}>No Unit</TableCell>
                      <TableCell
                        style={{
                          ...firstRowStyles,
                          borderLeft: '2px solid #e0e0e0',
                        }}
                      >
                        %
                      </TableCell>
                      <TableCell style={firstRowStyles}>⁰C</TableCell>
                      <TableCell style={firstRowStyles}>Bar</TableCell>
                      <TableCell style={firstRowStyles}></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {tableData.map(
                      (item, index) =>
                        !item.general_details.isdelete &&
                        (!isEmpty(item.general_details) ||
                          !isEmpty(item.furnace_details) ||
                          !isEmpty(item.charge_mix_details) ||
                          !isEmpty(item.carbon) ||
                          !isEmpty(item.alloying_addition) ||
                          !isEmpty(item.other_parameters)) && (
                          <TableRow key={index}>
                            {/* Your table cell rendering logic */}
                            <TableCell
                              style={{
                                position: 'sticky',
                                left: 0,
                                zIndex: 1,
                                backgroundColor: '#f4f9e9'
                              }}
                            >
                              {renderCell(index + 1)}
                              {console.log(item, 'item124')}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(item.general_details.furnace_no)}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(item.general_details.crucible_no)}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(item.general_details.campaign_no)}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(item.general_details.lining_patch_no)}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(item.general_details.heat_no)}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(item.general_details.date)}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(item.general_details.customer_ref_no)}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(item.general_details.grade)}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {item.furnace_details &&
                              item.furnace_details[index]
                                ? renderCell(
                                    item.furnace_details[index].heat_on_time
                                  )
                                : 'N/A'}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {item.furnace_details &&
                              item.furnace_details[index]
                                ? renderCell(
                                    item.furnace_details[index].heat_tap_time
                                  )
                                : 'N/A'}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {item.furnace_details &&
                              item.furnace_details[index]
                                ? renderCell(
                                    item.furnace_details[index]
                                      .break_down_duration
                                  )
                                : 'N/A'}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {item.furnace_details &&
                              item.furnace_details[index]
                                ? renderCell(
                                    item.furnace_details[index]
                                      .heat_duration_actual
                                  )
                                : 'N/A'}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {item.furnace_details &&
                              item.furnace_details[index]
                                ? renderCell(
                                    item.furnace_details[index]
                                      .heat_duration_calculated
                                  )
                                : 'N/A'}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {item.furnace_details &&
                              item.furnace_details[index]
                                ? renderCell(
                                    item.furnace_details[index]
                                      .tapping_temperature
                                  )
                                : 'N/A'}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {item.furnace_details &&
                              item.furnace_details[index]
                                ? renderCell(
                                    item.furnace_details[index].tapped_tonnage
                                  )
                                : 'N/A'}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {item.furnace_details &&
                              item.furnace_details[index]
                                ? renderCell(
                                    item.furnace_details[index]
                                      .melt_rate_based_on_actual_duration
                                  )
                                : 'N/A'}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {item.furnace_details &&
                              item.furnace_details[index]
                                ? renderCell(
                                    item.furnace_details[index]
                                      .melt_rate_based_on_calculated_duration
                                  )
                                : 'N/A'}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(
                                item.furnace_details[index].frequency
                              )}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(item.furnace_details[index].voltage)}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(
                                item.furnace_details[index].power_in_the_panel
                              )}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(
                                item.furnace_details[index].capacitors_removed
                              )}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(
                                item.furnace_details[index].total_power_consumed
                              )}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(
                                item.furnace_details[index]
                                  .energy_consumption_per_unit_ton_actual
                              )}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(
                                item.furnace_details[index]
                                  .energy_consumption_per_unit_ton_calculated
                              )}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(item.charge_mix_details[index].scrap)}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(
                                item.charge_mix_details[index].shredded
                              )}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(
                                item.charge_mix_details[index].pig_iron
                              )}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(
                                item.charge_mix_details[index].pooled_iron
                              )}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(
                                item.charge_mix_details[index].hot_heel
                              )}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(item.charge_mix_details[index].lump)}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(
                                item.charge_mix_details[index].pellet
                              )}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(
                                item.charge_mix_details[index]
                                  .fines_lessthan_5mm
                              )}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(
                                item.charge_mix_details[index]
                                  .fines_lessthan_1mm
                              )}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(
                                item.charge_mix_details[index].slag_crushers
                              )}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(
                                item.charge_mix_details[index]
                                  .tundish_or_slag_or_metalic_jam
                              )}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(
                                item.charge_mix_details[index].total_charge
                              )}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCarbonValue(item.carbon[0])}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(item.carbon[index].met_coke)}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(
                                item.carbon[index].fifty_c_percentage
                              )}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(
                                item.carbon[index].eightyfive_c_percentage
                              )}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(
                                item.carbon[index].final_c_percentage
                              )}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(
                                item.alloying_addition[index]
                                  .si_before_alloy_addition
                              )}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(
                                item.alloying_addition[index].final_si
                              )}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(
                                item.alloying_addition[index]
                                  .mn_before_alloy_addition
                              )}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(
                                item.alloying_addition[index].final_mn
                              )}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(
                                item.alloying_addition[index]
                                  .cr_before_alloy_addition
                              )}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(
                                item.alloying_addition[index].final_cr
                              )}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(item.alloying_addition[index].si_mn)}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(
                                item.alloying_addition[index].mn_in_simn
                              )}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(item.alloying_addition[index].feMn)}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(item.alloying_addition[index].feSi)}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(item.alloying_addition[index].feCr)}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(
                                item.alloying_addition[index].mill_scale
                              )}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(
                                item.alloying_addition[index]
                                  .any_other_alloy_addition
                              )}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(
                                item.other_parameters[index].inlet_water_temp
                              )}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(
                                item.other_parameters[index].outlet_water_temp
                              )}
                            </TableCell>
                            <TableCell style={rowStyles}>
                              {renderCell(
                                item.other_parameters[index].water_pr
                              )}
                            </TableCell>
                            {/* Render other table cells */}
                            <TableCell style={rowStyles}>
                              {renderCell(item.other_parameters[index].remarks)}
                            </TableCell>

                            {console.log(
                              'submissionStatus123',
                              submissionStatus
                            )}
                            {console.log('viewMode31', viewMode)}

                            {(submissionStatus === 'approved' ||
                              submissionStatus === 'rejected') &&
                              viewMode !== 'view' && (
                                <TableCell
                                  style={{
                                    position: 'sticky',
                                    right: 0,
                                    zIndex: 1,
                                    backgroundColor: '#fff',
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  }}
                                >
                                  <IconButton
                                    aria-label="edit"
                                    onClick={() => handleEdit(index)}
                                    style={{ marginRight: '5px' }}
                                  >
                                    <img src={EditIcon} alt="Edit Icon" />
                                  </IconButton>
                                  <IconButton
                                    aria-label="delete"
                                    onClick={() =>
                                      handleDelete(tableData, index)
                                    }
                                  >
                                    <img src={DeleteIcon} alt="Delete Icon" />
                                  </IconButton>
                                </TableCell>
                              )}

                            {submissionStatus === 'draft' &&
                              viewMode === 'view' && (
                                <TableCell
                                  style={{
                                    position: 'sticky',
                                    right: 0,
                                    zIndex: 1,
                                    backgroundColor: '#fff',
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                  }}
                                >
                                  <IconButton
                                    aria-label="edit"
                                    onClick={() => handleEdit(index)}
                                    style={{ marginRight: '5px' }}
                                  >
                                    <img src={EditIcon} alt="Edit Icon" />
                                  </IconButton>
                                  <IconButton
                                    aria-label="delete"
                                    onClick={() =>
                                      handleDelete(tableData, index)
                                    }
                                  >
                                    <img src={DeleteIcon} alt="Delete Icon" />
                                  </IconButton>
                                </TableCell>
                              )}
                          </TableRow>
                        )
                    )}
                  </TableBody>
                </Table>
              </div>
            )}
          </div>
          {(currentUser.role.role_id === 3 || currentUser.role.role_id === 4) &&
            submissionStatus === 'submitted' && (
              <div style={{ paddingTop: '30px' }}>
                <TextField
                  label="Comments"
                  variant="outlined"
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  fullWidth
                  multiline
                  rows={4}
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                    '& .MuiInputBase-root': {
                      fontSize: '16px', // Input text size
                    },
                    '& .MuiInputLabel-root': {
                      fontSize: '16px', // Label text size
                    },
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'fixed',
                    bottom: 20,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    backgroundColor: 'white',
                    zIndex: 1000,
                    padding: '10px',
                    alignItems: 'center',
                  }}
                >
                  <StyledButton
                    variant="outlined_reject"
                    text="Reject"
                    onClick={handleReject}
                    style={{ marginRight: 10 }}
                  />
                  <StyledButton
                    variant="contained"
                    color="primary"
                    text="Approve"
                    onClick={handleApprove}
                  />
                </div>
              </div>
            )}
          {(currentUser.role.role_id === 1 || currentUser.role.role_id === 2) &&
            submissionStatus === 'rejected' &&
            viewMode === 'edit' && (
              <div style={{ paddingTop: '30px' }}>
                <TextField
                  label="Comments"
                  variant="outlined"
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                  fullWidth
                  multiline
                  disabled={true}
                  rows={4}
                  sx={{
                    marginTop: 2,
                    marginBottom: 2,
                    '& .MuiInputBase-root': {
                      fontSize: '16px', // Input text size
                    },
                    '& .MuiInputLabel-root': {
                      fontSize: '16px', // Label text size
                    },
                  }}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'fixed',
                    bottom: 20,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    backgroundColor: 'white',
                    zIndex: 1000,
                    padding: '10px',
                    alignItems: 'center',
                  }}
                >
                  {/* <StyledButton
                    variant="outlined_reject"
                    text="Reject"
                    onClick={handleReject}
                    style={{ marginRight: 10 }}
                  /> */}
                  <StyledButton
                    variant="contained"
                    color="primary"
                    text="Resubmit"
                    onClick={handleResubmit}
                  />
                </div>
              </div>
            )}
          <Snackbar
            open={showSnackbar}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity="error"
              sx={{ width: '100%' }}
            >
              {submissionStatus === 'submitted'
                ? 'Please fill comments before rejecting'
                : "Please click on 'Edit' before proceeding with the Resubmit."}
            </Alert>
          </Snackbar>
        </div>
      ) : (
        <div style={{ position: 'relative', overflowX: 'auto' }}>
          <div style={{ overflowX: 'auto' }}>
            {tableData.length === 0 ? (
              <Typography
                variant="body1"
                sx={{ textAlign: 'center', marginTop: '20px' }}
              >
                Trials not found
              </Typography>
            ) : (
              <div>
                <Table ref={tableRef}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{ ...firstRowStyles, width: '30px' }}
                      ></TableCell>
                      <TableCell colspan={8} style={{ ...firstRowStyles }}>
                        General Details
                      </TableCell>
                      <TableCell
                        colspan={16}
                        style={{
                          ...firstRowStyles,
                          borderLeft: '2px solid #e0e0e0',
                        }}
                      >
                        Furnace Details
                      </TableCell>
                      <TableCell
                        colspan={12}
                        style={{
                          ...firstRowStyles,
                          borderLeft: '2px solid #e0e0e0',
                        }}
                      >
                        Charge Mix Details
                      </TableCell>
                      <TableCell
                        colspan={5}
                        style={{
                          ...firstRowStyles,
                          borderLeft: '2px solid #e0e0e0',
                        }}
                      >
                        Carbon
                      </TableCell>
                      <TableCell
                        colspan={13}
                        style={{
                          ...firstRowStyles,
                          borderLeft: '2px solid #e0e0e0',
                        }}
                      >
                        Alloying Addition
                      </TableCell>
                      <TableCell
                        colspan={4}
                        style={{
                          ...firstRowStyles,
                          borderLeft: '2px solid #e0e0e0',
                        }}
                      >
                        Other parameters
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          ...cellStyles,
                          position: 'sticky',
                          left: 0,
                          zIndex: 1,
                          backgroundColor: '#fff',
                        }}
                      >
                        S.No
                      </TableCell>
                      <TableCell style={cellStyles}>Furnace NO.</TableCell>
                      <TableCell style={cellStyles}>Crucible NO.</TableCell>
                      <TableCell style={cellStyles}>Campaign NO.</TableCell>
                      <TableCell style={cellStyles}>
                        Lining / Patching
                      </TableCell>
                      <TableCell style={cellStyles}>Heat No</TableCell>
                      <TableCell style={cellStyles}>Date</TableCell>
                      <TableCell style={cellStyles}>Customer Ref No</TableCell>
                      <TableCell style={cellStyles}>Grade</TableCell>
                      <TableCell style={cellStyles}>Heat ON Time</TableCell>
                      <TableCell style={cellStyles}>Heat TAP Time</TableCell>
                      <TableCell style={cellStyles}>
                        Breakdown duration
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Heat Duration actual
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Heat Duration calculated
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Tapping temperature
                      </TableCell>
                      <TableCell style={cellStyles}>Tapped tonnage</TableCell>
                      <TableCell style={cellStyles}>
                        Melt Rate based on actual duration
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Melt Rate based on calculated duration
                      </TableCell>
                      <TableCell style={cellStyles}>Frequency</TableCell>
                      <TableCell style={cellStyles}>Voltage</TableCell>
                      <TableCell style={cellStyles}>
                        Power In the panel
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Capacitors Removed
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Total Power Consumed
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Energy consumption per unit Ton actual
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Energy consumption per unit Ton calculated
                      </TableCell>
                      <TableCell style={cellStyles}>Scrap</TableCell>
                      <TableCell style={cellStyles}>Shredded</TableCell>
                      <TableCell style={cellStyles}>Pig Iron</TableCell>
                      <TableCell style={cellStyles}>Pooled Iron</TableCell>
                      <TableCell style={cellStyles}>Hot Heel</TableCell>
                      <TableCell style={cellStyles}>Lump</TableCell>
                      <TableCell style={cellStyles}>Pellet</TableCell>
                      <TableCell style={cellStyles}>
                        Fines less than 5 mm
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Fines less than 1 mm
                      </TableCell>
                      <TableCell style={cellStyles}>Slag Crushers</TableCell>
                      <TableCell style={cellStyles}>
                        Tundish or Slag or Metalic Jam
                      </TableCell>
                      <TableCell style={cellStyles}>Total charge</TableCell>
                      <TableCell style={cellStyles}>CPC</TableCell>
                      <TableCell style={cellStyles}>Met Coke</TableCell>
                      <TableCell style={cellStyles}>50 C%</TableCell>
                      <TableCell style={cellStyles}>85 C%</TableCell>
                      <TableCell style={cellStyles}>Final C%</TableCell>
                      <TableCell style={cellStyles}>
                        Si% Before addition
                      </TableCell>
                      <TableCell style={cellStyles}>Final Si%</TableCell>
                      <TableCell style={cellStyles}>
                        Mn% before Addition
                      </TableCell>
                      <TableCell style={cellStyles}>Final Mn%</TableCell>
                      <TableCell style={cellStyles}>
                        Cr% before addition
                      </TableCell>
                      <TableCell style={cellStyles}>Final Cr%</TableCell>
                      <TableCell style={cellStyles}>SiMn</TableCell>
                      <TableCell style={cellStyles}>Mn % in SiMn</TableCell>
                      <TableCell style={cellStyles}>FeMn</TableCell>
                      <TableCell style={cellStyles}>FeSi</TableCell>
                      <TableCell style={cellStyles}>FeCr</TableCell>
                      <TableCell style={cellStyles}>Mill scale</TableCell>
                      <TableCell style={cellStyles}>
                        Any other Alloy addition
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Inlet water temp.
                      </TableCell>
                      <TableCell style={cellStyles}>
                        Outlet Water temp.
                      </TableCell>
                      <TableCell style={cellStyles}>Water Pr.</TableCell>
                      <TableCell style={cellStyles}>Remarks</TableCell>
                      {submissionStatus === 'draft' && (
                        <TableCell
                          style={{
                            ...cellStyles,
                            position: 'sticky',
                            right: 0,
                            zIndex: 1,
                            backgroundColor: '#fff',
                          }}
                        >
                          Action
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{ ...firstRowStyles, width: '30px' }}
                      ></TableCell>
                      <TableCell style={firstRowStyles}>No.</TableCell>
                      <TableCell style={firstRowStyles}>NO.</TableCell>
                      <TableCell style={firstRowStyles}>NO.</TableCell>
                      <TableCell style={firstRowStyles}>NO</TableCell>
                      <TableCell style={firstRowStyles}>NO.</TableCell>
                      <TableCell style={firstRowStyles}>DD-MM-YYYY</TableCell>
                      <TableCell style={firstRowStyles}>No unit</TableCell>
                      <TableCell style={firstRowStyles}>No unit</TableCell>
                      <TableCell
                        style={{
                          ...firstRowStyles,
                          borderLeft: '2px solid #e0e0e0',
                        }}
                      >
                        HH:mm:ss
                      </TableCell>
                      <TableCell style={firstRowStyles}>HH:mm:ss</TableCell>
                      <TableCell style={firstRowStyles}>HH:mm:ss</TableCell>
                      <TableCell style={firstRowStyles}>HH:mm:ss</TableCell>
                      <TableCell style={firstRowStyles}>HH:mm:ss</TableCell>
                      <TableCell style={firstRowStyles}>⁰C</TableCell>
                      <TableCell style={firstRowStyles}>MT</TableCell>
                      <TableCell style={firstRowStyles}>kg/min</TableCell>
                      <TableCell style={firstRowStyles}>kg/min</TableCell>
                      <TableCell style={firstRowStyles}>Hz</TableCell>
                      <TableCell style={firstRowStyles}>V</TableCell>
                      <TableCell style={firstRowStyles}>KW</TableCell>
                      <TableCell style={firstRowStyles}>NO.</TableCell>
                      <TableCell style={firstRowStyles}>KWH</TableCell>
                      <TableCell style={firstRowStyles}>KWH/Ton</TableCell>
                      <TableCell style={firstRowStyles}>KWH/Ton</TableCell>
                      <TableCell
                        style={{
                          ...firstRowStyles,
                          borderLeft: '2px solid #e0e0e0',
                        }}
                      >
                        MT
                      </TableCell>
                      <TableCell style={firstRowStyles}>MT</TableCell>
                      <TableCell style={firstRowStyles}>MT</TableCell>
                      <TableCell style={firstRowStyles}>MT</TableCell>
                      <TableCell style={firstRowStyles}>MT</TableCell>
                      <TableCell style={firstRowStyles}>MT</TableCell>
                      <TableCell style={firstRowStyles}>MT</TableCell>
                      <TableCell style={firstRowStyles}>MT</TableCell>
                      <TableCell style={firstRowStyles}>MT</TableCell>
                      <TableCell style={firstRowStyles}>MT</TableCell>
                      <TableCell style={firstRowStyles}>MT</TableCell>
                      <TableCell style={firstRowStyles}>MT</TableCell>
                      <TableCell
                        style={{
                          ...firstRowStyles,
                          borderLeft: '2px solid #e0e0e0',
                        }}
                      >
                        Kg
                      </TableCell>
                      <TableCell style={firstRowStyles}>Kg</TableCell>
                      <TableCell style={firstRowStyles}>%</TableCell>
                      <TableCell style={firstRowStyles}>%</TableCell>
                      <TableCell style={firstRowStyles}>%</TableCell>
                      <TableCell
                        style={{
                          ...firstRowStyles,
                          borderLeft: '2px solid #e0e0e0',
                        }}
                      >
                        %
                      </TableCell>
                      <TableCell style={firstRowStyles}>%</TableCell>
                      <TableCell style={firstRowStyles}>%</TableCell>
                      <TableCell style={firstRowStyles}>%</TableCell>
                      <TableCell style={firstRowStyles}>%</TableCell>
                      <TableCell style={firstRowStyles}>%</TableCell>
                      <TableCell style={firstRowStyles}>Kg</TableCell>
                      <TableCell style={firstRowStyles}>%</TableCell>
                      <TableCell style={firstRowStyles}>Kg</TableCell>
                      <TableCell style={firstRowStyles}>Kg</TableCell>
                      <TableCell style={firstRowStyles}>Kg</TableCell>
                      <TableCell style={firstRowStyles}>Kg</TableCell>
                      <TableCell style={firstRowStyles}>No Unit</TableCell>
                      <TableCell
                        style={{
                          ...firstRowStyles,
                          borderLeft: '2px solid #e0e0e0',
                        }}
                      >
                        %
                      </TableCell>
                      <TableCell style={firstRowStyles}>⁰C</TableCell>
                      <TableCell style={firstRowStyles}>Bar</TableCell>
                      <TableCell style={firstRowStyles}></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {tableData.map((item, index) => (
                      <React.Fragment key={index}>
                        {!item.general_details.isdelete &&
                          (Array.isArray(item.general_details) ||
                            (typeof item.furnace_details === 'object' &&
                              !Array.isArray(item.furnace_details))) && (
                            <React.Fragment>
                              {item.general_details.map(
                                (detail, detailIndex) => (
                                  <TableRow key={`${index}-${detailIndex}`}>
                                    {/* Sticky column with index */}
                                    <TableCell
                                      style={{
                                        position: 'sticky',
                                        left: 0,
                                        zIndex: 1,
                                        backgroundColor: '#fff',
                                      }}
                                    >
                                      {/* Ensure index + 1 increments correctly */}
                                      {renderCell(detailIndex + 1)}
                                    </TableCell>
                                    {/* Render other general_details */}
                                    <TableCell style={rowStyles}>
                                      {renderCell(detail.furnace_no || '')}
                                    </TableCell>
                                    <TableCell style={rowStyles}>
                                      {renderCell(detail.crucible_no || '')}
                                    </TableCell>
                                    <TableCell style={rowStyles}>
                                      {renderCell(detail.campaign_no || '')}
                                    </TableCell>
                                    <TableCell style={rowStyles}>
                                      {renderCell(detail.lining_patch_no || '')}
                                    </TableCell>
                                    <TableCell style={rowStyles}>
                                      {renderCell(detail.heat_no || '')}
                                    </TableCell>
                                    <TableCell style={rowStyles}>
                                      {renderCell(detail.date || '')}
                                    </TableCell>
                                    <TableCell style={rowStyles}>
                                      {renderCell(detail.customer_ref_no || '')}
                                    </TableCell>
                                    <TableCell style={rowStyles}>
                                      {renderCell(detail.grade || '')}
                                    </TableCell>

                                    {/* Render furnace_details */}
                                    {Array.isArray(item.furnace_details) &&
                                      item.furnace_details.map(
                                        (furnaceDetail, furnaceIndex) => (
                                          <React.Fragment
                                            key={`furnaceDetail-${index}-${furnaceIndex}`}
                                          >
                                            <TableCell style={rowStyles}>
                                              {renderCell(
                                                furnaceDetail.heat_on_time ||
                                                  'N/A'
                                              )}
                                            </TableCell>
                                            <TableCell style={rowStyles}>
                                              {renderCell(
                                                furnaceDetail.heat_tap_time ||
                                                  'N/A'
                                              )}
                                            </TableCell>
                                            {/* Render more fields from furnaceDetail */}
                                            <TableCell style={rowStyles}>
                                              {renderCell(
                                                furnaceDetail.melt_rate_based_on_actual_duration ||
                                                  'N/A'
                                              )}
                                            </TableCell>
                                            <TableCell style={rowStyles}>
                                              {renderCell(
                                                furnaceDetail.melt_rate_based_on_calculated_duration ||
                                                  'N/A'
                                              )}
                                            </TableCell>
                                            {/* Add more TableCell components for other fields */}
                                          </React.Fragment>
                                        )
                                      )}
                                    {/* Conditionally render if furnace_details is an object */}
                                    {typeof item.furnace_details === 'object' &&
                                      !Array.isArray(item.furnace_details) && (
                                        <React.Fragment>
                                          <TableCell style={rowStyles}>
                                            {renderCell(
                                              item.furnace_details
                                                .heat_on_time || 'N/A'
                                            )}
                                          </TableCell>
                                          <TableCell style={rowStyles}>
                                            {renderCell(
                                              item.furnace_details
                                                .heat_tap_time || 'N/A'
                                            )}
                                          </TableCell>
                                          {/* Render more fields from item.furnace_details */}
                                          <TableCell style={rowStyles}>
                                            {renderCell(
                                              item.furnace_details
                                                .melt_rate_based_on_actual_duration ||
                                                'N/A'
                                            )}
                                          </TableCell>
                                          <TableCell style={rowStyles}>
                                            {renderCell(
                                              item.furnace_details
                                                .melt_rate_based_on_calculated_duration ||
                                                'N/A'
                                            )}
                                          </TableCell>
                                          {/* Add more TableCell components for other fields */}
                                        </React.Fragment>
                                      )}

                                    {/* Render charge_mix_details */}
                                    {Array.isArray(item.charge_mix_details) &&
                                      item.charge_mix_details.map(
                                        (chargeMix, chargeIndex) => (
                                          <React.Fragment
                                            key={`chargeMix-${index}-${chargeIndex}`}
                                          >
                                            <TableCell>
                                              {renderCell(
                                                chargeMix.heat_on_time || 'N/A'
                                              )}
                                            </TableCell>
                                            <TableCell>
                                              {renderCell(
                                                chargeMix.heat_tap_time || 'N/A'
                                              )}
                                            </TableCell>
                                            {/* Add more TableCell components for other fields */}
                                          </React.Fragment>
                                        )
                                      )}
                                    {/* Render charge_mix_details if it's an object */}
                                    {typeof item.charge_mix_details ===
                                      'object' &&
                                      !Array.isArray(
                                        item.charge_mix_details
                                      ) && (
                                        <React.Fragment>
                                          <TableCell>
                                            {renderCell(
                                              item.charge_mix_details
                                                .heat_on_time || 'N/A'
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {renderCell(
                                              item.charge_mix_details
                                                .heat_tap_time || 'N/A'
                                            )}
                                          </TableCell>
                                          {/* Add more TableCell components for other fields */}
                                        </React.Fragment>
                                      )}

                                    {/* Render carbon */}
                                    {Array.isArray(item.carbon) &&
                                      item.carbon.map(
                                        (carbonItem, carbonIndex) => (
                                          <TableCell
                                            key={`carbon-${index}-${carbonIndex}`}
                                          >
                                            {renderCell(
                                              carbonItem.some_field || 'N/A'
                                            )}
                                          </TableCell>
                                        )
                                      )}
                                    {/* Render carbon if it's an object */}
                                    {typeof item.carbon === 'object' &&
                                      !Array.isArray(item.carbon) && (
                                        <TableCell>
                                          {renderCell(
                                            item.carbon.some_field || 'N/A'
                                          )}
                                        </TableCell>
                                      )}

                                    {/* Render alloying_addition */}
                                    {Array.isArray(item.alloying_addition) &&
                                      item.alloying_addition.map(
                                        (alloyingAddition, alloyIndex) => (
                                          <TableCell
                                            key={`alloyingAddition-${index}-${alloyIndex}`}
                                          >
                                            {renderCell(
                                              alloyingAddition.some_field ||
                                                'N/A'
                                            )}
                                          </TableCell>
                                        )
                                      )}
                                    {/* Render alloying_addition if it's an object */}
                                    {typeof item.alloying_addition ===
                                      'object' &&
                                      !Array.isArray(
                                        item.alloying_addition
                                      ) && (
                                        <TableCell>
                                          {renderCell(
                                            item.alloying_addition.some_field ||
                                              'N/A'
                                          )}
                                        </TableCell>
                                      )}

                                    {/* Render other_parameters */}
                                    {Array.isArray(item.other_parameters) &&
                                      item.other_parameters.map(
                                        (otherParam, otherIndex) => (
                                          <TableCell
                                            key={`otherParam-${index}-${otherIndex}`}
                                          >
                                            {renderCell(
                                              otherParam.some_field || 'N/A'
                                            )}
                                          </TableCell>
                                        )
                                      )}
                                    {/* Render other_parameters if it's an object */}
                                    {typeof item.other_parameters ===
                                      'object' &&
                                      !Array.isArray(item.other_parameters) && (
                                        <TableCell>
                                          {renderCell(
                                            item.other_parameters.some_field ||
                                              'N/A'
                                          )}
                                        </TableCell>
                                      )}

                                    {/* Conditional rendering for edit and delete buttons */}
                                    {item.general_details[detailIndex]
                                      .submission_status === 'draft' &&
                                      viewMode === 'view' && (
                                        <TableCell
                                          style={{
                                            position: 'sticky',
                                            right: 0,
                                            zIndex: 1,
                                            backgroundColor: '#fff',
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexDirection: 'row',
                                          }}
                                        >
                                          <IconButton
                                            aria-label="edit"
                                            onClick={() =>
                                              handleEdit(detailIndex)
                                            }
                                            style={{ marginRight: '5px' }}
                                          >
                                            <img
                                              src={EditIcon}
                                              alt="Edit Icon"
                                            />
                                          </IconButton>
                                          <IconButton
                                            aria-label="delete"
                                            onClick={() =>
                                              handleDelete(
                                                tableData,
                                                detailIndex
                                              )
                                            }
                                          >
                                            <img
                                              src={DeleteIcon}
                                              alt="Delete Icon"
                                            />
                                          </IconButton>
                                        </TableCell>
                                      )}
                                  </TableRow>
                                )
                              )}
                            </React.Fragment>
                          )}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default ViewDailyTrial
