import React from 'react'

class Releases extends React.Component {
  handleClick = () => {
    alert('Button clicked!')
  }

  render() {
    return (
      <div>
        <button onClick={this.handleClick}>Click Me</button>
      </div>
    )
  }
}

export default Releases
