import React, { useEffect, useState } from 'react'
import { Button, Modal, Box, Typography } from '@mui/material'
import { styled } from '@mui/system'
import IconButton from '@mui/material/IconButton'
import TextBox from '../input/TextBox'
import StyledButton from '../input/StyledButton'
import SelectBox from '../input/SelectBox'
import axios from 'axios'
import API from '../services/API'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material'
import EditIcon from '../../assets/icons/ic_edit.svg'
import DeleteIcon from '../../assets/icons/ic_delete.svg'
import SearchIcon from '../../assets/icons/ic_search.svg'
import FilterIcon from '../../assets/icons/ic_filter.svg'
import DownloadIcon from '../../assets/icons/ic_dowload_settings.svg'
import closeIcon from '../../assets/icons/ic_close.svg'
import CircularProgress from '@mui/material/CircularProgress'
import AlertDialog from '../input/AlertDialog'
import CustomAutocompleteCheckbox from '../input/CustomAutocompleteCheckbox'
const ExcelJS = require('exceljs')

const ModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  height: 800,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[24],
  padding: theme.spacing(4),
}))

const firstRowStyles = {
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 800,
  lineHeight: '24px',
  letterSpacing: '-0.006em',
  textAlign: 'left',
  color: '#252C32',
}
const Users = () => {
  const [open, setOpen] = useState(false)
  const [roles, setRoles] = useState([])
  const [region, setRegion] = useState([])
  const [customers, setCustomers] = useState([])
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    sgid: '',
    role_id: '',
    region_id: '',
    designation: '',
    email: '',
    contact_number: '',
    assign_customer_id: '',
  })
  const [users, setUsers] = useState([])
  const [selectedUserIndex, setSelectedUserIndex] = useState(null)
  const [isEditMode, setIsEditMode] = useState(false) // New state for edit mode
  const [isInputVisible, setInputVisible] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredUsers, setFilteredUsers] = useState([])
  const [formErrors, setFormErrors] = useState({}) // Initialize formErrors state
  const [loading, setLoading] = useState(false) // Loader state
  const [error, setError] = useState(null) // Error state
  const access_token = localStorage.getItem('accesstoken')

  useEffect(() => {
    fetchCustomers()
    fetchRoles()
    fetchData()
    fetchRegion()
  }, [])

  const fetchRoles = async () => {
    try {
      const response = await axios.get(API.ROLE, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      console.log('Roles response', response.data)
      setRoles(response.data)
    } catch (error) {
      console.error('Error fetching roles:', error)
    }
  }

  const fetchRegion = async () => {
    try {
      const response = await axios.get(API.REGION, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      console.log('regionnn', response.data)
      setRegion(response.data)
    } catch (error) {
      console.error('Error fetching region:', error)
    }
  }

  const fetchCustomers = async () => {
    try {
      const response = await axios.get(API.GET_CUSTOMER, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }) // Adjust the URL as needed
      console.log('responseeee', response.data)
      setCustomers(response.data) // Update state with fetched data
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const fetchData = async () => {
    setError(null)
    try {
      setLoading(true)
      const response = await axios.get(API.GET_USERS, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      setUsers(response.data)
    } catch (error) {
      setError('Error fetching data')
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false) // Close the loader regardless of success or failure
    }
  }

  const handleChange = (event) => {
    console.log(event, "everegion")
    console.log('role', event.target.value)
    const { name, value } = event.target || event
    const updatedValue = name === 'assign_customer_id' ? value : value
    console.log('updatedValueupdatedValue', updatedValue)
    setFormData((prevState) => ({
      ...prevState,
      [name]: updatedValue,
    }))
  }

  // const handleChange = (event) => {
  //   console.log("role",event.target.value)
  //   // const { name, value } = event.target;
  //   const { name, value } = event.target || event; // If event is not from an input field, get the value directly
  //   const updatedValue = name === 'role' || name === 'assign_customer_id' ? value : value;
  //   console.log("updatedValueupdatedValue",updatedValue);
  //   setFormData(prevState => ({
  //     ...prevState,
  //     [name]: updatedValue
  //   }));
  // };

  // const handleChange = (event, value) => {
  //   const { name } = event.target || event;
  //   const updatedValue = name === 'assign_customer_id' ? value.map(option => option.id) : value;

  //   console.log("updatedValueupdatedValue",updatedValue);

  //   setFormData(prevState => ({
  //     ...prevState,
  //     [name]: updatedValue
  //   }));
  // };

  const handleOpenModal = () => {
    setOpen(true)
    setIsEditMode(false)
    setFormData({
      firstname: '',
      lastname: '',
      sgid: '',
      role_id: '',
      region_id: '',
      designation: '',
      email: '',
      contact_number: '',
      assign_customer_id: '',
    })
  }

  const handleSearchIconClick = () => {
    setInputVisible(true)
  }

  const handleInputChange = (event) => {
    const query = event.target.value.toLowerCase() // Convert query to lowercase
    setSearchQuery(query)
    let filtered
    if (query.trim() === '') {
      filtered = users // Reset to original list if query is empty
    } else {
      filtered = users.filter((user) => {
        const fullName = `${user.firstname} ${user.lastname}` // Combine first name and last name
        const fullNameIncludesQuery = fullName
          .toLowerCase()
          .includes(query.toLowerCase()) // Case-insensitive comparison
        const sgidIncludesQuery = user.sgid.toLowerCase().includes(query)
        const roleIncludesQuery = getRoleName(user.role_id)
        const regionIncludesQuery = getRegionName(user.region_id)
          .toLowerCase()
          .includes(query)
        const assignCustomerIncludesQuery = getAssignCustomer(
          user.assign_customer_id
        )
          .toLowerCase()
          .includes(query)
        return (
          fullNameIncludesQuery ||
          sgidIncludesQuery ||
          roleIncludesQuery ||
          assignCustomerIncludesQuery ||
          regionIncludesQuery
        )
      })
    }
    setFilteredUsers(filtered)
  }

  const handleExcelDownload = async () => {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet('Users')
    // Define column headers
    worksheet.columns = [
      { header: 'No', key: 'no', width: 10 },
      { header: 'Name', key: 'name', width: 20 },
      { header: 'SGID', key: 'sgid', width: 20 },
      { header: 'Role', key: 'role', width: 20 },
      { header: 'Assign Customer', key: 'assign_customer', width: 30 },
    ]

    const dataToDownload = searchQuery ? filteredUsers : customers

    dataToDownload.forEach((row, index) => {
      const fullName = `${row.firstname} ${row.lastname}`
      const role = getRoleName(row.role_id)
      const region = getRegionName(row.region_id)
      const assign_customer = getAssignCustomer(row.assign_customer_id)
      worksheet.addRow({
        ...row,
        no: index + 1,
        name: fullName,
        role: role,
        region: region,
        assign_customer: assign_customer,
      })
    })

    // Generate the Excel file as a Blob
    const buffer = await workbook.xlsx.writeBuffer()
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob)

    // Create a temporary <a> element to trigger the download
    const link = document.createElement('a')
    link.href = url
    link.download = 'users.xlsx'

    // Programmatically click the link to trigger the download
    document.body.appendChild(link)
    link.click()

    // Cleanup
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }

  const handleCloseIconClick = () => {
    setInputVisible(false)
    setSearchQuery('')
    setFilteredUsers([])
  }

  const handleCloseModal = () => {
    setOpen(false)
  }

  const handleSave = async () => {
    try {
      // If role ID is 4, set assign_customer_id to all customer IDs
      if (roleId === 4 || roleId === 3) {
        formData.assign_customer_id = customers
          .map((customer) => customer.id)
          .join(',')
      } else {
        if (typeof formData.assign_customer_id === 'string') {
          // Convert comma-separated string to array
          formData.assign_customer_id = formData.assign_customer_id
            .split(',')
            .map((id) => id.trim())
        }
      }

      const isValid = await validateFormData(roleId)
      if (!isValid) return

      setLoading(true)

      const jsonData = formData
      console.log('jsonData', jsonData)
      let response
      let successMessage

      if (isEditMode && selectedUserIndex !== null) {
        const userId = users[selectedUserIndex].id
        response = await axios.put(`${API.UPDATE_USER}/${userId}`, jsonData, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        successMessage = 'User Updated Successfully'
      } else {
        console.log('elseee', 'callled')
        response = await axios.post(API.INSERT_USER, jsonData, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        successMessage = 'User Inserted Successfully'
      }

      if (response.status === 200) {
        AlertDialog({
          type: 'success',
          title: 'Success',
          text: successMessage,
          confirmButtonText: 'Ok',
          onConfirm: async () => {
            await fetchData()
          },
        })
      } else {
        console.error('Error saving data:', response.data)
        AlertDialog({
          type: 'warning',
          title: 'Error',
          text: 'Failed to save data',
        })
      }

      setOpen(false) // Close the modal after saving
    } catch (error) {
      console.error('Error saving data:', error)
      AlertDialog({
        type: 'warning',
        title: 'Error',
        text: error.message || 'An error occurred',
      })
    } finally {
      setLoading(false) // Stop loader regardless of success or failure
    }
  }

  const validateFormData = async (roleId) => {
    const errors = {}

    if (!formData.firstname) {
      errors.firstname = 'First name is required'
    }

    if (!formData.lastname) {
      errors.lastname = 'Last name is required'
    }

    if (!formData.sgid) {
      errors.sgid = 'Last name is required'
    }

    // Check if city is provided
    if (!formData.role_id) {
      errors.role_id = 'Role is required'
    }
    if (!formData.assign_customer_id) {
      errors.assign_customer_id = 'Assign Customer is required'
    }

    // Set the form errors state
    setFormErrors(errors)

    // Return true if there are no errors, otherwise return false
    return Object.keys(errors).length === 0
  }

  // const handleSave = async () => {
  //   try {
  //     const jsonData = {
  //       ...formData,
  //     };
  //     try {
  //       // const jsonData = formData;
  //       if (isEditMode && selectedUserIndex !== null) {
  //         const userId = users[selectedUserIndex].id;
  //         const response = await axios.put(`${API.UPDATE_USER}/${userId}`, jsonData);
  //         console.log("response", response);
  //          await fetchData();
  //       } else {
  //         const response = await axios.post(API.INSERT_USER, jsonData);
  //         console.log("response", response);
  //        await fetchData();
  //       }
  //       setOpen(false); // Close the modal after saving
  //     } catch (error) {
  //       console.error('Error saving data:', error);
  //     }
  //   } catch (error) {
  //     console.error('Error saving user:', error);
  //   }
  // };

  const handleEdit = async (index) => {
    setSelectedUserIndex(index)
    setIsEditMode(true)
    const selectedUser = users[index]
    console.log('selectedUser', selectedUser)
    setFormData({
      firstname: selectedUser.firstname,
      lastname: selectedUser.lastname,
      sgid: selectedUser.sgid,
      role_id: selectedUser.role_id,
      region_id: selectedUser.region_id,
      designation: selectedUser.designation,
      email: selectedUser.email,
      contact_number: selectedUser.contact_number,
      assign_customer_id: selectedUser.assign_customer_id,
    })
    setOpen(true)
  }

  const handleDelete = async (index) => {
    setSelectedUserIndex(index)
    const selectedUser = users[index]
    const id = selectedUser.id
    AlertDialog({
      type: 'delete',
      title: 'Delete?',
      text: 'Are you sure you want to delete?',
      confirmButtonText: 'Yes',
      onConfirm: async () => {
        const response = await axios.delete(`${API.DELETE_USER}/${id}`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        if (response) {
          fetchData()
        }
      },
    })
  }
  // role

  const getRoleName = (role_id) => {
    const role = roles.find((role) => role.id === role_id)
    return role ? role.name : ''
  }

  function getRoleIdById(data, id) {
    const item = data.find((obj) => obj.id === id)
    return item ? item.role_id : null
  }
  const roleId = getRoleIdById(roles, formData.role_id)

  //regions

  const getRegionName = (region_id) => {
    console.log(region_id, "regcheck1")
    const regionName = region.find((region) => region.id === region_id)
    return regionName ? regionName.region_name : ''
  }

  function getRegionIdById(data, id) {
    const item = data.find((obj) => obj.id === id)
    return item ? item.region_id : null
  }
  const regionId = getRegionIdById(region, formData.region_id)

  //customers

  const getAssignCustomer = (assign_customer_id) => {
    // Log the input
    console.log('assign_customer_id', assign_customer_id)

    // Handle single ID case by converting to array
    if (typeof assign_customer_id === 'string') {
      assign_customer_id = [assign_customer_id]
    }

    // Convert Set to Array if necessary
    if (assign_customer_id instanceof Set) {
      assign_customer_id = Array.from(assign_customer_id)
    }

    // Ensure it's an array now
    if (!Array.isArray(assign_customer_id) || assign_customer_id.length === 0) {
      return null
    }

    console.log('customerscustomers', customers)
    // Get organization names
    const organizationNames = assign_customer_id
      .map((id) => {
        const organization = customers.find((org) => org.id === id)
        return organization ? organization.organization_name : null
      })
      .filter((name) => name !== null)

    // Log the result
    console.log('organizationNames', organizationNames)

    // Return the joined names or null
    return organizationNames.length > 0 ? organizationNames.join(', ') : null
  }

  return (
    <div>
      {error && <div>{error}</div>}
      {loading ? (
        <CircularProgress />
      ) : (
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: '1px solid #E5E9EB',
              paddingBottom: '8px',
              marginBottom: '8px',
            }}
          >
            <Typography
              variant="h5"
              component="div"
              sx={{
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                letterSpacing: '-0.006em',
                textAlign: 'left',
                color: '#252C32',
              }}
            >
              User Details
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={SearchIcon}
                alt="search icon"
                style={{ marginRight: '15px', cursor: 'pointer' }}
                onClick={handleSearchIconClick}
              />
              {isInputVisible && (
                <div style={{ position: 'relative' }}>
                  <input
                    type="text"
                    value={searchQuery}
                    onChange={handleInputChange}
                    placeholder="Search..."
                    style={{ marginRight: '15px', height: '35px' }}
                  />
                  <img
                    src={closeIcon}
                    alt="close icon"
                    style={{
                      position: 'absolute',
                      top: '50%',
                      right: '20px',
                      transform: 'translateY(-50%)',
                      cursor: 'pointer',
                    }}
                    onClick={handleCloseIconClick}
                  />
                </div>
              )}
              {/* <img src={FilterIcon} style={{ marginRight: '15px' }} /> */}
              <img
                src={DownloadIcon}
                style={{ marginRight: '20px' }}
                onClick={handleExcelDownload}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{
                  textTransform: 'none', // Remove all-caps transformation
                }}
                onClick={handleOpenModal}
              >
                Add User
              </Button>
            </div>
          </div>
          {/* Table displaying users */}
          <UserTable
            users={users}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            getRoleName={getRoleName}
            getRegionName = {getRegionName}
            getAssignCustomer={getAssignCustomer}
            searchQuery={searchQuery}
            filteredUsers={filteredUsers}
          />

          {/* Modal for adding/editing users */}
          <UserModal
            open={open}
            handleClose={handleCloseModal}
            formData={formData}
            handleChange={handleChange}
            handleSave={handleSave}
            roles={roles}
            region={region}
            isEditMode={isEditMode}
            customers={customers}
            formErrors={formErrors}
            roleId={roleId}
            regionId={regionId}
          />
        </div>
      )}
    </div>
  )
}

const UserTable = ({
  users,
  handleEdit,
  handleDelete,
  getRoleName,
  getRegionName,
  getAssignCustomer,
  searchQuery,
  filteredUsers,
}) => {
  console.log('getRegionName', getRegionName)
  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={firstRowStyles}>S.No</TableCell>
              <TableCell style={firstRowStyles}>Name</TableCell>
              <TableCell style={firstRowStyles}>SGID</TableCell>
              <TableCell style={firstRowStyles}>Assign Customer</TableCell>
              <TableCell style={firstRowStyles}>Role</TableCell>
              <TableCell style={firstRowStyles}>Region</TableCell>
              <TableCell style={firstRowStyles}>Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {(searchQuery ? filteredUsers : users).map((user, index) => (
              <TableRow key={user.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  {user.firstname} {user.lastname}
                </TableCell>
                <TableCell>{user.sgid}</TableCell>
                <TableCell>
                  {getAssignCustomer(user.assign_customer_id)}
                </TableCell>
                <TableCell>{getRoleName(user.role_id)}</TableCell>
                <TableCell>{getRegionName(user.region_id)}</TableCell>
                <TableCell>
                  <IconButton
                    aria-label="edit"
                    onClick={() => handleEdit(index)}
                  >
                    <img src={EditIcon} alt="Edit Icon" />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleDelete(index)}
                  >
                    <img src={DeleteIcon} alt="Delete Icon" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

const UserModal = ({
  open,
  handleClose,
  formData,
  handleChange,
  handleSave,
  roles,
  region,
  isEditMode,
  customers,
  formErrors,
  roleId,
  regionId
}) => {
  const [selectedOptions, setSelectedOptions] = useState([])

  useEffect(() => {
    if (
      formData.assign_customer_id &&
      Array.isArray(formData.assign_customer_id)
    ) {
      setSelectedOptions(
        customers.filter((customer) =>
          formData.assign_customer_id.includes(customer.id)
        )
      )
    } else {
      setSelectedOptions([])
    }
  }, [formData.assign_customer_id, customers])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalContent style={{ maxHeight: '80vh', overflowY: 'auto' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="body1"
            sx={{
              fontFamily: 'Inter',
              fontSize: 18,
              fontWeight: 600,
              lineHeight: '24px',
              letterSpacing: '-0.006em',
              textAlign: 'left',
              color: '#252C32',
            }}
          >
            Add User
          </Typography>
        </div>

        <TextBox
          name="firstname"
          label="First Name*"
          value={formData.firstname}
          onChange={handleChange}
          error={formErrors.firstname}
        />
        <div style={{ marginTop: '15px' }}>
          <TextBox
            name="lastname"
            label="Last Name*"
            value={formData.lastname}
            onChange={handleChange}
            isString={true}
            error={formErrors.lastname}
          />
        </div>

        <div style={{ marginTop: '15px' }}>
          <TextBox
            name="sgid"
            label="Sgid*"
            value={formData.sgid}
            onChange={handleChange}
            isString={true}
            error={formErrors.sgid}
          />
        </div>

        <SelectBox
          name="role_id"
          labelValue="Role*"
          value={formData.role_id} // Assuming role is one of the fields in your form
          onChange={handleChange} // Assuming you have a handleChange function
          options={roles}
          labelCondition="name" // Assuming you want to display role names
          isLabel={true}
          error={formErrors.role_id}
        />
        {roleId === 1 ? (
          <SelectBox
            name="assign_customer_id"
            labelValue="Assign Customer*"
            value={formData.assign_customer_id}
            onChange={handleChange}
            options={customers}
            labelCondition="organization_name"
            error={formErrors.assign_customer_id}
            isLabel={true}
          />
        ) : roleId === 3 || roleId === 4 ? (
          <div>
            <div style={{ marginTop: '5px' }}>
              <TextBox
                name="assign_customer_id"
                label="Assign Customer*"
                value={`All - ${customers.map((customer) => customer.organization_name).join(', ')}`}
                onChange={handleChange}
                isString={true}
              />
            </div>
          </div>
        ) : (
          <CustomAutocompleteCheckbox
            organization_name="assign_customer_id"
            options={customers}
            value={selectedOptions}
            onChange={(event, value) => {
              setSelectedOptions(value)
              const selectedCustomerIds = value.map((customer) => customer.id)
              handleChange({
                target: {
                  name: 'assign_customer_id',
                  value: selectedCustomerIds,
                },
              })
            }}
            isLabel={true}
            labelValue="Assign Customer*"
          />
        )}

        <div>
          <SelectBox
            name="region_id"
            labelValue="Region*"
            value={formData.region_id} 
            onChange={handleChange}
            options={region}
            labelCondition="name"
            isLabel={true}
            error={formErrors.region_id}
          />
        </div>

        <div style={{ marginTop: '5px' }}>
          <TextBox
            name="designation"
            label="Designation"
            value={formData.designation}
            onChange={handleChange}
            isString={true}
            // onChange={firstNameOnChnage}
            // error={formErrors.firstName}
          />
        </div>
        <div style={{ marginTop: '5px' }}>
          <TextBox
            name="email"
            label="Email Address"
            value={formData.email}
            onChange={handleChange}
            isString={true}
            // onChange={firstNameOnChnage}
            // error={formErrors.firstName}
          />
        </div>
        <div style={{ marginTop: '5px' }}>
          <TextBox
            name="contact_number"
            label="Contact Number"
            value={formData.contact_number}
            onChange={handleChange}
            isString={true}
            type="number"
            // onChange={firstNameOnChnage}
            // error={formErrors.firstName}
          />
        </div>

        <div style={{ flexDirection: 'row', flex: 1, display: 'flex' }}>
          <StyledButton variant="outlined" text="Cancel" onClick={handleClose}>
            Cancel
          </StyledButton>
          <StyledButton
            variant="contained"
            text={isEditMode ? 'Update' : 'Save'}
            onClick={handleSave}
          >
            Save
          </StyledButton>
        </div>
      </ModalContent>
    </Modal>
  )
}

export default Users
