import React, { useState, useEffect } from 'react'
import TextBox from '../../input/TextBox'
import axios from 'axios'
import { furnace_Details_data_collection } from '../../../recoil/atoms'
import { useRecoilState } from 'recoil'
import { v4 as uuidv4 } from 'uuid'
import { trial_name } from '../../../recoil/atoms'

const FurnaceDetails = () => {
  const [trialName] = useRecoilState(trial_name)
  const [FurnaceDetails, setFurnaceDetails] = useRecoilState(
    furnace_Details_data_collection
  )
  console.log(FurnaceDetails, 'FurnaceDetails')
  const uuid = uuidv4()

  const handleChange = (e) => {
    const { name, value } = e.target
    setFurnaceDetails((prevState) => ({
      ...prevState,
      [name]: value,
      id: uuid,
    }))
  }

  const isReadOnly = trialName === 'viewDataCollection'

  return (
    <div style={{ width: '100%' }}>
      <div style={{ marginTop: '15px', marginRight: '15px' }}>
        <TextBox
          name="no_of_panels"
          label="Total No. of Panel (No.)"
          type="number"
          value={FurnaceDetails.no_of_panels}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="fce_capacity"
          label="Fce. Capacity in which need to Trial - (MT)"
          type="number"
          value={FurnaceDetails.fce_capacity}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="avg_heatsize"
          label="Avg. Heat Size(total MT per heat) - (MT)"
          value={FurnaceDetails.avg_heatsize}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="power_rating"
          label="Power rating of Fce. (KW)"
          value={FurnaceDetails.power_rating}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="fce_running"
          label="Is Fce is running with full Power"
          value={FurnaceDetails.fce_running}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>

      <TextBox
        name="tap_time"
        label="Tap to tap time (HH:MM)"
        value={FurnaceDetails.tap_time}
        onChange={handleChange}
        disabled={isReadOnly}
      />
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="sintering_panel"
          label="Sintering Panel"
          isString={true}
          value={FurnaceDetails.sintering_panel}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="power_rating_sintering"
          label="Power rating(Sintering panel) - (KW)"
          value={FurnaceDetails.power_rating_sintering}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="no_of_heats_perday"
          label="Total No. of Heats/day"
          value={FurnaceDetails.no_of_heats_perday}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="avg_power_consumption"
          label="Avg. Power Consumption/MT of Billet(Only Fce) - (kWh/MT)"
          value={FurnaceDetails.avg_power_consumption}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="lrf"
          label="LRF/AOD"
          value={FurnaceDetails.lrf}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="frequency_range"
          label="Frequency Range - (Hz)"
          value={FurnaceDetails.frequency_range}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="max_tapping_temp"
          label="Maximum tapping temperature - (⁰C)"
          value={FurnaceDetails.max_tapping_temp}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="avg_tapping_temp"
          label="Average tapping Temperature - (⁰C)"
          value={FurnaceDetails.avg_tapping_temp}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="furnace_voltage"
          label="Furnace Voltage - (V)"
          value={FurnaceDetails.furnace_voltage}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="furnace_oem"
          label="Furnance OEM"
          value={FurnaceDetails.furnace_oem}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="furnace_year"
          label="Furnace Year of manufacture - (YYYY)"
          value={FurnaceDetails.furnace_year}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="coil_grout"
          label="Coil grout/coat supplier name"
          isString={true}
          value={FurnaceDetails.coil_grout}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="sintering_time"
          label="Sintering time -(Hrs.)"
          value={FurnaceDetails.sintering_time}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="operation"
          label="Operation"
          value={FurnaceDetails.operation}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="change_overtime"
          label="Furnace Change Over Time - (minutes)"
          value={FurnaceDetails.change_overtime}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="checking_time"
          label="Furnace Checking time - (minutes)"
          value={FurnaceDetails.checking_time}
          onChange={handleChange}
          disabled={isReadOnly}
        />
      </div>
    </div>
  )
}
export default FurnaceDetails
