import React, { useState, useRef, useEffect } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  CircularProgress,
} from '@mui/material'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import API from '../services/API'
import StyledButtonWeb from '../input/StyledButtonWeb'
import AlertDialog from '../input/AlertDialog'

const ViewDailyTrial = () => {
  const firstRowStyles = {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 800,
    lineHeight: '24px',
    letterSpacing: '-0.006em',
    textAlign: 'left',
    color: '#252C32',
    backgroundColor: '#f0f0f0',
  }

  const navigate = useNavigate()
  const [tableData, setTableData] = useState([])
  const [headerText, setHeaderText] = useState('General Details')
  const tableRef = useRef(null)
  const id = localStorage.getItem('daily_trial_id')
  const dataFileData = localStorage.getItem('datafile_info')
  const [showRejectPopup, setShowRejectPopup] = useState(false)
  const [comments, setComments] = useState('')
  const [submissionStatus, setSubmissionStatus] = useState('')
  const access_token = localStorage.getItem('accesstoken')
  // Parse the JSON string back to an object
  const parsedData = JSON.parse(dataFileData)
  console.log('parsedData', parsedData)

  const currentUser = JSON.parse(localStorage.getItem('user'))

  const [loading, setLoading] = useState(true) // loading state

  useEffect(() => {
    if (tableData.length > 0) {
      setLoading(false)
    }
  }, [tableData])

  const handleBack = () => {
    navigate(-1) // Go back to the previous page
  }

  //   useEffect(() => {
  //     const fetchData = async () =>
  //       try {
  //         const response = await axios.get(`${API.GET_DAILY_TRIAL_BY_ID}/${id}`);
  //         const data = response.data;

  //         const result = data.general_details
  //           .filter((detail) => !detail.isdelete) // Filter out items with isdelete set to false
  //           .map((detail) => {
  //             const objectId = detail.id;
  //             // Match details from other arrays based on the id
  //             const matchedDetails = {
  //               general_details: detail,
  //               furnace_details:
  //                 data.furnace_details.find((item) => item.id === objectId) || {},
  //               charge_mix_details:
  //                 data.charge_mix_details.find((item) => item.id === objectId) ||
  //                 {},
  //               carbon: data.carbon.find((item) => item.id === objectId) || {},
  //               alloying_addition:
  //                 data.alloying_addition.find((item) => item.id === objectId) ||
  //                 {},
  //               other_parameters:
  //                 data.other_parameters.find((item) => item.id === objectId) ||
  //                 {},
  //               id: data.id,
  //             };
  //             return matchedDetails;
  //           });

  //         console.log("RESULTTT", result);
  //         setTableData(result);
  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //         // Handle error or set a default state for tableData
  //       }
  //     };
  //     fetchData();
  //   }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true) // Show loader at the beginning
      try {
        const dataFileData = localStorage.getItem('datafile_info')
        // Parse the JSON string back to an object
        const parsedData = JSON.parse(dataFileData)

        if (parsedData.dataFile.template.template_id === 1) {
          const datafile_id = parsedData.datafile_id
          let url = `${API.VIEW_DAILY_TRIAL}/${datafile_id}`
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          })
          console.log('viewtrial', response.data)
          const responseData = response.data
          const submission_status =
            responseData.general_details &&
            Array.isArray(responseData.general_details) &&
            responseData.general_details.length > 0
              ? responseData.general_details[0].submission_status
              : ''
          setSubmissionStatus(submission_status)
          setTableData(response.data)
        } else if (parsedData.dataFile.template.template_id === 2) {
          const datafile_id = parsedData.datafile_id
          let url = `${API.VIEW_NRM_PERFORMANCE}/${datafile_id}`
          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          })
          console.log('viewtrialnrmmm', response.data)
          const responseData = response.data
          const submission_status =
            responseData.lining_reference &&
            Array.isArray(responseData.lining_reference) &&
            responseData.lining_reference.length > 0
              ? responseData.lining_reference[0].submission_status
              : ''
          setSubmissionStatus(submission_status)
          setTableData(response.data)
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        setLoading(false) // Hide loader after data is fetched or on error
      }
    }

    fetchData()
  }, [])

  const TableA = () => {
    console.log('good')
    if (
      !tableData ||
      !tableData.general_details ||
      !tableData.furnace_details ||
      !tableData.charge_mix_details ||
      !tableData.carbon ||
      !tableData.alloying_addition ||
      !tableData.other_parameters
    ) {
      // If tableData or its properties are undefined, render a loading indicator or message
      return <p>Loading...</p>
    }

    console.log('tableData', tableData)
    console.log('tableData.personal_details', tableData.general_details)
    console.log('tableData.furnace_details', tableData.furnace_details)
    // const personalDetails = JSON.parse(tableData.personal_details);
    // const contactDetails = JSON.parse(tableData.contact_details);
    const general_details = tableData.general_details
    const furnace_details = tableData.furnace_details
    const charge_mix_details = tableData.charge_mix_details
    const carbon = tableData.carbon
    const alloying_addition = tableData.alloying_addition
    const other_parameters = tableData.other_parameters

    const validateFurnaceNo = (value) => {
      return value >= 1 && value <= 20
    }

    const validateCrucibleNo = (value) => {
      return /[A-E]/.test(value)
    }

    const validateCampaignNo = (value) => {
      return value >= 1 && value <= 100
    }

    const validateLiningPatchingNo = (value) => {
      const validValues = [
        'New Lining',
        'Side Lining 1',
        'Side Lining 2',
        'Side Lining 3',
        'Side Lining 4',
        'Side Lining 5',
        'Side Lining 6',
        'Side Lining 7',
        'Side Lining 8',
        'Side Lining 9',
        'Side Lining 10',
        'Side Lining 11',
        'Side Lining 12',
        'Side Lining 13',
        'Side Lining 14',
        'Side Lining 15',
        'Patching 1',
        'Patching 2',
        'Patching 3',
        'Patching 4',
        'Patching 5',
        'Patching 6',
        'Patching 7',
        'Patching 8',
        'Patching 9',
        'Patching 10',
        'Patching 11',
        'Patching 12',
        'Patching 13',
        'Patching 14',
        'Patching 15',
        'Patching 16',
        'Patching 17',
        'Patching 18',
        'Patching 19',
        'Patching 20',
        'Patching 21',
        'Patching 22',
        'Patching 23',
        'Patching 24',
        'Patching 25',
      ]
      return validValues.includes(value)
    }

    const validateHeatNo = (value) => {
      return !isNaN(value) // Check if it's a valid number
    }

    const validateDate = (value) => {
      const regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/
      return regex.test(value)
    }

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ ...firstRowStyles, width: '30px' }}></TableCell>
            <TableCell colspan={8} style={{ ...firstRowStyles }}>
              General Details
            </TableCell>
            <TableCell
              colspan={16}
              style={{ ...firstRowStyles, borderLeft: '2px solid #e0e0e0' }}
            >
              Furnace Details
            </TableCell>
            <TableCell
              colspan={12}
              style={{ ...firstRowStyles, borderLeft: '2px solid #e0e0e0' }}
            >
              Charge Mix Details
            </TableCell>
            <TableCell
              colspan={5}
              style={{ ...firstRowStyles, borderLeft: '2px solid #e0e0e0' }}
            >
              Carbon
            </TableCell>
            <TableCell
              colspan={13}
              style={{ ...firstRowStyles, borderLeft: '2px solid #e0e0e0' }}
            >
              Alloying Addition
            </TableCell>
            <TableCell
              colspan={4}
              style={{ ...firstRowStyles, borderLeft: '2px solid #e0e0e0' }}
            >
              Other parameters
            </TableCell>
          </TableRow>
        </TableHead>
        <TableHead>
          <TableRow>
            <TableCell style={{ ...firstRowStyles, width: '30px' }}>
              S.No
            </TableCell>
            <TableCell style={firstRowStyles}>Furnace NO.</TableCell>
            <TableCell style={firstRowStyles}>Crucible NO.</TableCell>
            <TableCell style={firstRowStyles}>Campaign NO.</TableCell>
            <TableCell style={firstRowStyles}>Lining / Patching</TableCell>
            <TableCell style={firstRowStyles}>Heat No</TableCell>
            <TableCell style={firstRowStyles}>Date</TableCell>
            <TableCell style={firstRowStyles}>Customer Ref No</TableCell>
            <TableCell style={firstRowStyles}>Grade</TableCell>
            <TableCell
              style={{ ...firstRowStyles, borderLeft: '2px solid #e0e0e0' }}
            >
              Heat ON Time
            </TableCell>
            <TableCell style={firstRowStyles}>Heat TAP Time</TableCell>
            <TableCell style={firstRowStyles}>Breakdown duration</TableCell>
            <TableCell style={firstRowStyles}>Heat Duration actual</TableCell>
            <TableCell style={firstRowStyles}>
              Heat Duration calculated
            </TableCell>
            <TableCell style={firstRowStyles}>Tapping temperature</TableCell>
            <TableCell style={firstRowStyles}>Tapped tonnage</TableCell>
            <TableCell style={firstRowStyles}>
              Melt Rate based on actual duration
            </TableCell>
            <TableCell style={firstRowStyles}>
              Melt Rate based on calculated duration
            </TableCell>
            <TableCell style={firstRowStyles}>Frequency</TableCell>
            <TableCell style={firstRowStyles}>Voltage</TableCell>
            <TableCell style={firstRowStyles}>Power In the panel</TableCell>
            <TableCell style={firstRowStyles}>Capacitors Removed</TableCell>
            <TableCell style={firstRowStyles}>Total Power Consumed</TableCell>
            <TableCell style={firstRowStyles}>
              Energy consumption per unit Ton actual
            </TableCell>
            <TableCell style={firstRowStyles}>
              Energy consumption per unit Ton calculated
            </TableCell>
            <TableCell
              style={{ ...firstRowStyles, borderLeft: '2px solid #e0e0e0' }}
            >
              Scrap
            </TableCell>
            <TableCell style={firstRowStyles}>Shredded</TableCell>
            <TableCell style={firstRowStyles}>Pig Iron</TableCell>
            <TableCell style={firstRowStyles}>Pooled Iron</TableCell>
            <TableCell style={firstRowStyles}>Hot Heel</TableCell>
            <TableCell style={firstRowStyles}>Lump</TableCell>
            <TableCell style={firstRowStyles}>Pellet</TableCell>
            <TableCell style={firstRowStyles}>Fines less than 5 mm</TableCell>
            <TableCell style={firstRowStyles}>Fines less than 1 mm</TableCell>
            <TableCell style={firstRowStyles}>Slag Crushers</TableCell>
            <TableCell style={firstRowStyles}>
              Tundish or Slag or Metalic Jam
            </TableCell>
            <TableCell style={firstRowStyles}>Total charge</TableCell>
            <TableCell
              style={{ ...firstRowStyles, borderLeft: '2px solid #e0e0e0' }}
            >
              {(() => {
                if (carbon && carbon[0]) {
                  if (carbon[0]['cpc']) {
                    return 'CPC'
                  } else if (carbon[0]['anthracite']) {
                    return 'Anthracite'
                  } else if (carbon[0]['carbon_block']) {
                    return 'Carbon Block'
                  } else {
                    return null
                  }
                } else {
                  return null
                }
              })()}
            </TableCell>{' '}
            <TableCell style={firstRowStyles}>Met Coke</TableCell>
            <TableCell style={firstRowStyles}>50 C%</TableCell>
            <TableCell style={firstRowStyles}>85 C%</TableCell>
            <TableCell style={firstRowStyles}>Final C%</TableCell>
            <TableCell
              style={{ ...firstRowStyles, borderLeft: '2px solid #e0e0e0' }}
            >
              Si% Before addition
            </TableCell>
            <TableCell style={firstRowStyles}>Final Si%</TableCell>
            <TableCell style={firstRowStyles}>Mn% before Addition</TableCell>
            <TableCell style={firstRowStyles}>Final Mn%</TableCell>
            <TableCell style={firstRowStyles}>Cr% before addition</TableCell>
            <TableCell style={firstRowStyles}>Final Cr%</TableCell>
            <TableCell style={firstRowStyles}>SiMn</TableCell>
            <TableCell style={firstRowStyles}>Mn % in SiMn</TableCell>
            <TableCell style={firstRowStyles}>FeMn</TableCell>
            <TableCell style={firstRowStyles}>FeSi</TableCell>
            <TableCell style={firstRowStyles}>FeCr</TableCell>
            <TableCell style={firstRowStyles}>Mill scale</TableCell>
            <TableCell style={firstRowStyles}>
              Any other Alloy addition
            </TableCell>
            <TableCell
              style={{ ...firstRowStyles, borderLeft: '2px solid #e0e0e0' }}
            >
              Inlet water temp.
            </TableCell>
            <TableCell style={firstRowStyles}>Outlet Water temp.</TableCell>
            <TableCell style={firstRowStyles}>Water Pr.</TableCell>
            <TableCell style={firstRowStyles}>Remarks</TableCell>
          </TableRow>
        </TableHead>
        <TableHead>
          <TableRow>
            <TableCell style={{ ...firstRowStyles, width: '30px' }}></TableCell>
            <TableCell style={firstRowStyles}>No.</TableCell>
            <TableCell style={firstRowStyles}>NO.</TableCell>
            <TableCell style={firstRowStyles}>NO.</TableCell>
            <TableCell style={firstRowStyles}>NO</TableCell>
            <TableCell style={firstRowStyles}>NO.</TableCell>
            <TableCell style={firstRowStyles}>DD-MM-YYYY</TableCell>
            <TableCell style={firstRowStyles}>No unit</TableCell>
            <TableCell style={firstRowStyles}>No unit</TableCell>
            <TableCell
              style={{ ...firstRowStyles, borderLeft: '2px solid #e0e0e0' }}
            >
              HH:mm:ss
            </TableCell>
            <TableCell style={firstRowStyles}>HH:mm:ss</TableCell>
            <TableCell style={firstRowStyles}>HH:mm:ss</TableCell>
            <TableCell style={firstRowStyles}>HH:mm:ss</TableCell>
            <TableCell style={firstRowStyles}>HH:mm:ss</TableCell>
            <TableCell style={firstRowStyles}>⁰C</TableCell>
            <TableCell style={firstRowStyles}>MT</TableCell>
            <TableCell style={firstRowStyles}>kg/min</TableCell>
            <TableCell style={firstRowStyles}>kg/min</TableCell>
            <TableCell style={firstRowStyles}>Hz</TableCell>
            <TableCell style={firstRowStyles}>V</TableCell>
            <TableCell style={firstRowStyles}>KW</TableCell>
            <TableCell style={firstRowStyles}>NO.</TableCell>
            <TableCell style={firstRowStyles}>KWH</TableCell>
            <TableCell style={firstRowStyles}>KWH/Ton</TableCell>
            <TableCell style={firstRowStyles}>KWH/Ton</TableCell>
            <TableCell
              style={{ ...firstRowStyles, borderLeft: '2px solid #e0e0e0' }}
            >
              MT
            </TableCell>
            <TableCell style={firstRowStyles}>MT</TableCell>
            <TableCell style={firstRowStyles}>MT</TableCell>
            <TableCell style={firstRowStyles}>MT</TableCell>
            <TableCell style={firstRowStyles}>MT</TableCell>
            <TableCell style={firstRowStyles}>MT</TableCell>
            <TableCell style={firstRowStyles}>MT</TableCell>
            <TableCell style={firstRowStyles}>MT</TableCell>
            <TableCell style={firstRowStyles}>MT</TableCell>
            <TableCell style={firstRowStyles}>MT</TableCell>
            <TableCell style={firstRowStyles}>MT</TableCell>
            <TableCell style={firstRowStyles}>MT</TableCell>
            <TableCell
              style={{ ...firstRowStyles, borderLeft: '2px solid #e0e0e0' }}
            >
              Kg
            </TableCell>
            <TableCell style={firstRowStyles}>Kg</TableCell>
            <TableCell style={firstRowStyles}>%</TableCell>
            <TableCell style={firstRowStyles}>%</TableCell>
            <TableCell style={firstRowStyles}>%</TableCell>
            <TableCell
              style={{ ...firstRowStyles, borderLeft: '2px solid #e0e0e0' }}
            >
              %
            </TableCell>
            <TableCell style={firstRowStyles}>%</TableCell>
            <TableCell style={firstRowStyles}>%</TableCell>
            <TableCell style={firstRowStyles}>%</TableCell>
            <TableCell style={firstRowStyles}>%</TableCell>
            <TableCell style={firstRowStyles}>%</TableCell>
            <TableCell style={firstRowStyles}>Kg</TableCell>
            <TableCell style={firstRowStyles}>%</TableCell>
            <TableCell style={firstRowStyles}>Kg</TableCell>
            <TableCell style={firstRowStyles}>Kg</TableCell>
            <TableCell style={firstRowStyles}>Kg</TableCell>
            <TableCell style={firstRowStyles}>Kg</TableCell>
            <TableCell style={firstRowStyles}>No Unit</TableCell>
            <TableCell
              style={{ ...firstRowStyles, borderLeft: '2px solid #e0e0e0' }}
            >
              %
            </TableCell>
            <TableCell style={firstRowStyles}>⁰C</TableCell>
            <TableCell style={firstRowStyles}>Bar</TableCell>
            <TableCell style={firstRowStyles}></TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {general_details.map((detail, index) => {
            if (detail && typeof detail === 'object') {
              console.log('Detail:', detail)
              if (
                Object.keys(detail).length > 1 ||
                (Object.keys(detail).length === 1 &&
                  Object.keys(detail)[0] !== 'id')
              ) {
                console.log('Rendering row for detail:', detail)
                return (
                  <React.Fragment key={index}>
                    <TableRow key={index}>
                      <TableCell
                        style={{
                          borderLeft: '1px solid #e0e0e0',
                          borderRight: '1px solid #e0e0e0',
                          width: '20px',
                        }}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        style={{
                          border: !validateFurnaceNo(detail['furnace_no'])
                            ? '2px solid lightgrey'
                            : 'none',
                          backgroundColor: '#f4f9e9',
                        }}
                      >
                        {detail['furnace_no']}
                      </TableCell>
                      <TableCell
                        style={{
                          border: !validateCrucibleNo(detail['crucible_no'])
                            ? '2px solid lightgrey'
                            : 'none',
                          backgroundColor: '#f4f9e9',
                        }}
                      >
                        {detail['crucible_no']}
                      </TableCell>
                      <TableCell
                        style={{
                          border: !validateCampaignNo(detail['campaign_no'])
                            ? '2px solid lightgrey'
                            : 'none',
                          backgroundColor: '#f4f9e9',
                        }}
                      >
                        {detail['campaign_no']}
                      </TableCell>
                      <TableCell
                        style={{
                          border: !validateLiningPatchingNo(
                            detail['lining_patch_no']
                          )
                            ? '2px solid lightgrey'
                            : 'none',
                          backgroundColor: '#f4f9e9',
                        }}
                      >
                        {detail['lining_patch_no']}
                      </TableCell>
                      <TableCell
                        style={{
                          border: !validateHeatNo(detail['heat_no'])
                            ? '2px solid lightgrey'
                            : 'none',
                          backgroundColor: '#f4f9e9',
                        }}
                      >
                        {detail['heat_no']}
                      </TableCell>
                      <TableCell
                        style={{
                          border: !validateDate(detail['date'])
                            ? '2px solid lightgrey'
                            : 'none',
                          backgroundColor: '#f4f9e9',
                        }}
                      >
                        {detail['date']}
                      </TableCell>
                      <TableCell style={{ backgroundColor: '#f4f9e9' }}>
                        {detail['customer_ref_no']}
                      </TableCell>
                      <TableCell style={{ backgroundColor: '#f4f9e9' }}>
                        {detail['grade']}
                      </TableCell>
                      {furnace_details[index] && (
                        <>
                          <TableCell
                            style={{
                              border: (() => {
                                const timeString = furnace_details[index][
                                  'heat_on_time'
                                ]
                                  ? furnace_details[index]['heat_on_time']
                                      .toString()
                                      .trim()
                                  : ''
                                console.log('time:', timeString)
                                const [hours, minutes] = timeString
                                  .split(':')
                                  .map(Number)
                                console.log(
                                  'Hourstime:',
                                  hours,
                                  'Minutes:',
                                  minutes
                                )
                                if (
                                  hours >= 0 &&
                                  hours < 24 &&
                                  minutes >= 0 &&
                                  minutes < 60
                                ) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {furnace_details[index]['heat_on_time']}
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const timeString = furnace_details[index][
                                  'heat_tap_time'
                                ]
                                  ? furnace_details[index]['heat_tap_time']
                                      .toString()
                                      .trim()
                                  : ''
                                console.log('time:', timeString)
                                const [hours, minutes] = timeString
                                  .split(':')
                                  .map(Number)
                                console.log(
                                  'Hourstime:',
                                  hours,
                                  'Minutes:',
                                  minutes
                                )
                                if (
                                  hours >= 0 &&
                                  hours < 24 &&
                                  minutes >= 0 &&
                                  minutes < 60
                                ) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {furnace_details[index]['heat_tap_time']}
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const timeString = furnace_details[index][
                                  'break_down_duration'
                                ]
                                  ? furnace_details[index][
                                      'break_down_duration'
                                    ]
                                      .toString()
                                      .trim()
                                  : ''
                                console.log('time:', timeString)
                                const [hours, minutes] = timeString
                                  .split(':')
                                  .map(Number)
                                console.log(
                                  'Hourstime:',
                                  hours,
                                  'Minutes:',
                                  minutes
                                )
                                if (
                                  hours >= 0 &&
                                  hours < 24 &&
                                  minutes >= 0 &&
                                  minutes < 60
                                ) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {furnace_details[index]['break_down_duration']}
                          </TableCell>
                          <TableCell
                            style={{
                              border: (() => {
                                const timeString = furnace_details[index][
                                  'heat_duration_actual'
                                ]
                                  ? furnace_details[index][
                                      'heat_duration_actual'
                                    ]
                                      .toString()
                                      .trim()
                                  : ''
                                console.log('time:', timeString)
                                const [hours, minutes] = timeString
                                  .split(':')
                                  .map(Number)
                                console.log(
                                  'Hourstime:',
                                  hours,
                                  'Minutes:',
                                  minutes
                                )
                                if (
                                  hours >= 0 &&
                                  hours < 24 &&
                                  minutes >= 0 &&
                                  minutes < 60
                                ) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {furnace_details[index]['heat_duration_actual']}
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const timeString = furnace_details[index][
                                  'heat_duration_calculated'
                                ]
                                  ? furnace_details[index][
                                      'heat_duration_calculated'
                                    ]
                                      .toString()
                                      .trim()
                                  : ''
                                console.log('time:', timeString)
                                const [hours, minutes] = timeString
                                  .split(':')
                                  .map(Number)
                                console.log(
                                  'Hourstime:',
                                  hours,
                                  'Minutes:',
                                  minutes
                                )
                                if (
                                  hours >= 0 &&
                                  hours < 24 &&
                                  minutes >= 0 &&
                                  minutes < 60
                                ) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {furnace_details[index]['heat_duration_calculated']}
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value =
                                  furnace_details[index]['tapping_temperature']
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {furnace_details[index]['tapping_temperature']}
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value =
                                  furnace_details[index]['tapped_tonnage']
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {furnace_details[index]['tapped_tonnage']}
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value =
                                  furnace_details[index][
                                    'melt_rate_based_on_actual_duration'
                                  ]
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {
                              furnace_details[index][
                                'melt_rate_based_on_actual_duration'
                              ]
                            }
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value =
                                  furnace_details[index][
                                    'melt_rate_based_on_calculated_duration'
                                  ]
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {
                              furnace_details[index][
                                'melt_rate_based_on_calculated_duration'
                              ]
                            }
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value =
                                  furnace_details[index]['frequency']
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {furnace_details[index]['frequency']}
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value = furnace_details[index]['voltage']
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {furnace_details[index]['voltage']}
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value =
                                  furnace_details[index]['power_in_the_panel']
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {furnace_details[index]['power_in_the_panel']}
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value =
                                  furnace_details[index]['capacitors_removed']
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {furnace_details[index]['capacitors_removed']}
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value =
                                  furnace_details[index]['total_power_consumed']
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {furnace_details[index]['total_power_consumed']}
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value =
                                  furnace_details[index][
                                    'energy_consumption_per_unit_ton_actual'
                                  ]
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {
                              furnace_details[index][
                                'energy_consumption_per_unit_ton_actual'
                              ]
                            }
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value =
                                  furnace_details[index][
                                    'energy_consumption_per_unit_ton_calculated'
                                  ]
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {
                              furnace_details[index][
                                'energy_consumption_per_unit_ton_calculated'
                              ]
                            }
                          </TableCell>
                        </>
                      )}

                      {charge_mix_details[index] && (
                        <>
                          <TableCell
                            style={{
                              border: (() => {
                                const value = charge_mix_details[index]['scrap']
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {charge_mix_details[index]['scrap']}
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value =
                                  charge_mix_details[index]['shredded']
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {charge_mix_details[index]['shredded']}
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value =
                                  charge_mix_details[index]['pig_iron']
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {charge_mix_details[index]['pig_iron']}
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value =
                                  charge_mix_details[index]['pooled_iron']
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {charge_mix_details[index]['pooled_iron']}
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value =
                                  charge_mix_details[index]['hot_heel']
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {charge_mix_details[index]['hot_heel']}
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value = charge_mix_details[index]['lump']
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {charge_mix_details[index]['lump']}
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value =
                                  charge_mix_details[index]['pellet']
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {charge_mix_details[index]['pellet']}
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value =
                                  charge_mix_details[index][
                                    'fines_lessthan_5mm'
                                  ]
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {charge_mix_details[index]['fines_lessthan_5mm']}
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value =
                                  charge_mix_details[index][
                                    'fines_lessthan_1mm'
                                  ]
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {charge_mix_details[index]['fines_lessthan_1mm']}
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value =
                                  charge_mix_details[index]['slag_crushers']
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {charge_mix_details[index]['slag_crushers']}
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value =
                                  charge_mix_details[index][
                                    'tundish_or_slag_or_metalic_jam'
                                  ]
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {
                              charge_mix_details[index][
                                'tundish_or_slag_or_metalic_jam'
                              ]
                            }
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value =
                                  charge_mix_details[index]['total_charge']
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {charge_mix_details[index]['total_charge']}
                          </TableCell>
                        </>
                      )}
                      {carbon[index] && (
                        <>
                          <TableCell
                            style={{
                              border: (() => {
                                const keys = [
                                  'cpc',
                                  'carbon_block',
                                  'anthracite',
                                ]
                                let value = null

                                for (let key of keys) {
                                  if (carbon[index][key]) {
                                    value = carbon[index][key]
                                    break
                                  }
                                }

                                const regex = /^\d{1,5}\.\d{2}$/
                                return regex.test(value)
                                  ? 'none'
                                  : '2px solid lightgrey'
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {(() => {
                              const keys = ['cpc', 'carbon_block', 'anthracite']
                              for (let key of keys) {
                                if (carbon[index][key]) {
                                  return carbon[index][key]
                                }
                              }
                              return null // Default value if none of the keys match
                            })()}
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value = carbon[index]['met_coke']
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {carbon[index]['met_coke']}
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value =
                                  carbon[index]['fifty_c_percentage']
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {carbon[index]['fifty_c_percentage']}
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value =
                                  carbon[index]['eightyfive_c_percentage']
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {carbon[index]['eightyfive_c_percentage']}
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value =
                                  carbon[index]['final_c_percentage']
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {carbon[index]['final_c_percentage']}
                          </TableCell>
                        </>
                      )}
                      {alloying_addition[index] && (
                        <>
                          <TableCell
                            style={{
                              border: (() => {
                                const value =
                                  alloying_addition[index][
                                    'si_before_alloy_addition'
                                  ]
                                const regex = /^\d{1,5}\.\d{2}%?$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {
                              alloying_addition[index][
                                'si_before_alloy_addition'
                              ]
                            }
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value =
                                  alloying_addition[index]['final_si']
                                const regex = /^\d{1,5}\.\d{2}%?$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {alloying_addition[index]['final_si']}
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value =
                                  alloying_addition[index][
                                    'mn_before_alloy_addition'
                                  ]
                                const regex = /^\d{1,5}\.\d{2}%?$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {
                              alloying_addition[index][
                                'mn_before_alloy_addition'
                              ]
                            }
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value =
                                  alloying_addition[index]['final_mn']
                                const regex = /^\d{1,5}\.\d{2}%?$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {alloying_addition[index]['final_mn']}
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value =
                                  alloying_addition[index][
                                    'cr_before_alloy_addition'
                                  ]
                                const regex = /^\d{1,5}\.\d{2}%?$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {
                              alloying_addition[index][
                                'cr_before_alloy_addition'
                              ]
                            }
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value =
                                  alloying_addition[index]['final_cr']
                                const regex = /^\d{1,5}\.\d{2}%?$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {alloying_addition[index]['final_cr']}
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value = alloying_addition[index]['si_mn']
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {alloying_addition[index]['si_mn']}
                          </TableCell>

                          <TableCell style={{ backgroundColor: '#f4f9e9' }}>
                            {alloying_addition[index]['mn_in_simn']}
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value = alloying_addition[index]['feMn']
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {alloying_addition[index]['feMn']}
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value = alloying_addition[index]['feSi']
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {alloying_addition[index]['feSi']}
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value = alloying_addition[index]['feCr']
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {alloying_addition[index]['feCr']}
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value =
                                  alloying_addition[index]['mill_scale']
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {alloying_addition[index]['mill_scale']}
                          </TableCell>

                          <TableCell style={{ backgroundColor: '#f4f9e9' }}>
                            {
                              alloying_addition[index][
                                'any_other_alloy_addition'
                              ]
                            }
                          </TableCell>
                        </>
                      )}
                      {other_parameters[index] && (
                        <>
                          <TableCell
                            style={{
                              border: (() => {
                                const value =
                                  other_parameters[index]['inlet_water_temp']
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {other_parameters[index]['inlet_water_temp']}
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value =
                                  other_parameters[index]['outlet_water_temp']
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {other_parameters[index]['outlet_water_temp']}
                          </TableCell>

                          <TableCell
                            style={{
                              border: (() => {
                                const value =
                                  other_parameters[index]['water_pr']
                                const regex = /^\d{1,5}\.\d{2}$/
                                if (regex.test(value)) {
                                  return 'none'
                                } else {
                                  return '2px solid lightgrey'
                                }
                              })(),
                              backgroundColor: '#f4f9e9',
                            }}
                          >
                            {other_parameters[index]['water_pr']}
                          </TableCell>

                          <TableCell style={{ backgroundColor: '#f4f9e9' }}>
                            {other_parameters[index]['remarks']}
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  </React.Fragment>
                )
              } else {
                return null
              }
            } else {
              return null
            }
          })}
        </TableBody>
      </Table>
    )
  }

  const TableB = () => {
    console.log('good3333434')
    console.log('tableData', tableData)
    if (
      !tableData ||
      !tableData.lining_reference ||
      !tableData.production_summary ||
      !tableData.lining_material_product_details ||
      !tableData.lining_material_consumption ||
      !tableData.dimensional_parameters ||
      !tableData.sintering_parameters
    ) {
      // If tableData or its properties are undefined, render a loading indicator or message
      return <p>Loading...</p>
    }

    console.log('tableData', tableData)
    console.log('tableData.lining_reference', tableData.lining_reference)

    const lining_reference = tableData.lining_reference
    const production_summary = tableData.production_summary
    const lining_material_product_details =
      tableData.lining_material_product_details
    const lining_material_consumption = tableData.lining_material_consumption
    const dimensional_parameters = tableData.dimensional_parameters
    const sintering_parameters = tableData.sintering_parameters

    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell> S.NO.</TableCell>
            <TableCell>Furnace NO.</TableCell>
            <TableCell>Crucible NO.</TableCell>
            <TableCell>Campaign NO.</TableCell>
            <TableCell>Lining or Patching</TableCell>
            <TableCell>Date of Completion lining or Patching</TableCell>
            <TableCell>Fce prod start date</TableCell>
            <TableCell>Fce prod start time</TableCell>
            <TableCell>Fce prod down date</TableCell>
            <TableCell>Fce prod down time</TableCell>
            <TableCell>Life</TableCell>
            <TableCell>Average Heat size</TableCell>
            <TableCell>Total Furnace Running Hours</TableCell>
            <TableCell>Total Production</TableCell>
            <TableCell>Total Furnace Running Hours</TableCell>
            <TableCell>Bottom Product</TableCell>
            <TableCell>Sidewall product 1</TableCell>
            <TableCell>Sidewall product 2</TableCell>
            <TableCell>Top Locking product</TableCell>
            <TableCell>Topping Mass Product</TableCell>
            <TableCell>Side wall 1 Coverage upto X%</TableCell>
            <TableCell>Side wall 2 Coverage upto 100%</TableCell>
            <TableCell>NRM consumption bottom</TableCell>
            <TableCell>NRM consumption Sidewall 1</TableCell>
            <TableCell>NRM consumption Sidewall 2</TableCell>
            <TableCell>NRM consumption at Top Locking</TableCell>
            <TableCell>Topping mass consumption</TableCell>
            <TableCell>Total NRM consumption sidewall</TableCell>
            <TableCell>NRM Specific consumption</TableCell>
            <TableCell>Former Dimension</TableCell>
            <TableCell>Coil coat ID</TableCell>
            <TableCell>Fce Ht.</TableCell>
            <TableCell>Castable top Ht.</TableCell>
            <TableCell>Bottom ht.</TableCell>
            <TableCell>Total Sintering time</TableCell>
            <TableCell>holding time</TableCell>
            <TableCell>holding temp</TableCell>
            <TableCell>Reason for Down</TableCell>
            <TableCell>IR no.</TableCell>
            <TableCell>Remarks</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lining_reference.map((detail, index) => {
            if (detail && typeof detail === 'object') {
              // Check if detail is not null or undefined and is an object
              console.log('Detail:', detail)
              if (
                Object.keys(detail).length > 1 ||
                (Object.keys(detail).length === 1 &&
                  Object.keys(detail)[0] !== 'id')
              ) {
                return (
                  <TableRow key={index}>
                    <TableCell>{detail['S.No.']}</TableCell>
                    <TableCell>{detail['furnace_no']}</TableCell>
                    <TableCell>{detail['crucible_no']}</TableCell>
                    <TableCell>{detail['campaign_no']}</TableCell>
                    <TableCell>{detail['lining_or_patching']}</TableCell>
                    <TableCell>
                      {detail['date_of_completion_lining_or_patching']}
                    </TableCell>

                    {/* Render corresponding contact details */}
                    {production_summary[index] && (
                      <>
                        <TableCell>
                          {production_summary[index]['fce_prod_start_date']}
                        </TableCell>
                        <TableCell>
                          {production_summary[index]['fce_prod_start_time']}
                        </TableCell>
                        <TableCell>
                          {production_summary[index]['fce_prod_down_date']}
                        </TableCell>
                        <TableCell>
                          {production_summary[index]['fce_prod_down_time']}
                        </TableCell>
                        <TableCell>
                          {production_summary[index]['life']}
                        </TableCell>
                        <TableCell>
                          {production_summary[index]['average_heat_size']}
                        </TableCell>
                        <TableCell>
                          {
                            production_summary[index][
                              'total_furnace_running_hours'
                            ]
                          }
                        </TableCell>
                        <TableCell>
                          {production_summary[index]['total_production']}
                        </TableCell>
                        <TableCell>
                          {
                            production_summary[index][
                              'total_furnace_running_hours'
                            ]
                          }
                        </TableCell>
                      </>
                    )}
                    {lining_material_product_details[index] && (
                      <>
                        <TableCell>
                          {
                            lining_material_product_details[index][
                              'bottom_product'
                            ]
                          }
                        </TableCell>
                        <TableCell>
                          {
                            lining_material_product_details[index][
                              'sidewall_product_one'
                            ]
                          }
                        </TableCell>
                        <TableCell>
                          {
                            lining_material_product_details[index][
                              'sidewall_product_two'
                            ]
                          }
                        </TableCell>
                        <TableCell>
                          {
                            lining_material_product_details[index][
                              'top_locking_product'
                            ]
                          }
                        </TableCell>
                        <TableCell>
                          {
                            lining_material_product_details[index][
                              'topping_mass_product'
                            ]
                          }
                        </TableCell>
                        <TableCell>
                          {
                            lining_material_product_details[index][
                              'sidewall_one_coverage_upto_x'
                            ]
                          }
                        </TableCell>
                        <TableCell>
                          {
                            lining_material_product_details[index][
                              'sidewall_two_coverage_upto_100'
                            ]
                          }
                        </TableCell>
                      </>
                    )}
                    {lining_material_consumption[index] && (
                      <>
                        <TableCell>
                          {
                            lining_material_consumption[index][
                              'nrm_consumption_bottom'
                            ]
                          }
                        </TableCell>
                        <TableCell>
                          {
                            lining_material_consumption[index][
                              'nrm_consumption_sidewall_one'
                            ]
                          }
                        </TableCell>
                        <TableCell>
                          {
                            lining_material_consumption[index][
                              'nrm_consumption_sidewall_two'
                            ]
                          }
                        </TableCell>
                        <TableCell>
                          {
                            lining_material_consumption[index][
                              'nrm_consumption_top_locking'
                            ]
                          }
                        </TableCell>
                        <TableCell>
                          {
                            lining_material_consumption[index][
                              'topping_mass_consumption'
                            ]
                          }
                        </TableCell>
                        <TableCell>
                          {
                            lining_material_consumption[index][
                              'total_nrm_consumption_sidewall_one'
                            ]
                          }
                        </TableCell>
                        <TableCell>
                          {
                            lining_material_consumption[index][
                              'nrm_specific_consumption'
                            ]
                          }
                        </TableCell>
                      </>
                    )}
                    {dimensional_parameters[index] && (
                      <>
                        <TableCell>
                          {dimensional_parameters[index]['former_dimension']}
                        </TableCell>
                        <TableCell>
                          {dimensional_parameters[index]['coil_coat_id']}
                        </TableCell>
                        <TableCell>
                          {dimensional_parameters[index]['fce_ht']}
                        </TableCell>
                        <TableCell>
                          {dimensional_parameters[index]['costable_top_ht']}
                        </TableCell>
                        <TableCell>
                          {dimensional_parameters[index]['bottom_ht']}
                        </TableCell>
                      </>
                    )}
                    {sintering_parameters[index] && (
                      <>
                        <TableCell>
                          {sintering_parameters[index]['total_sintering_time']}
                        </TableCell>
                        <TableCell>
                          {sintering_parameters[index]['holding_time']}
                        </TableCell>
                        <TableCell>
                          {sintering_parameters[index]['holding_temp']}
                        </TableCell>
                        <TableCell>
                          {sintering_parameters[index]['reason_for_down']}
                        </TableCell>
                        <TableCell>
                          {sintering_parameters[index]['IR_no']}
                        </TableCell>
                        <TableCell>
                          {sintering_parameters[index]['remarks']}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                )
              }
            }
          })}
        </TableBody>
      </Table>
    )
  }

  //   useEffect(() => {
  //     const handleScroll = () => {
  //       const container = tableRef.current;
  //       if (container) {
  //         const rect = container.getBoundingClientRect();
  //         const containerLeft = rect.left;
  //         const heatOnTimeColumnIndex = 9; // Index of the "Heat ON Time" column
  //         const cells = container.querySelectorAll("td");
  //         const heatOnTimeCell = cells[heatOnTimeColumnIndex];
  //         if (heatOnTimeCell) {
  //           const heatOnTimeCellRect = heatOnTimeCell.getBoundingClientRect();
  //           const heatOnTimeCellLeft = heatOnTimeCellRect.left;
  //           if (heatOnTimeCellLeft >= tableRef) {
  //             setHeaderText("Furnace Details");
  //           } else {
  //             setHeaderText("General Details");
  //           }
  //         }
  //       }
  //     };

  //     const container = tableRef.current;
  //     if (container) {
  //       container.addEventListener("scroll", handleScroll);
  //     }

  //     return () => {
  //       if (container) {
  //         container.removeEventListener("scroll", handleScroll);
  //       }
  //     };
  //   }, []);
  // Empty dependency array to run this effect only once on mount
  const cellStyles = {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '-0.006em',
    textAlign: 'left',
    color: '#000000',
  }

  const rowStyles = {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.006em',
    textAlign: 'left',
    color: '#252C32',
  }

  const firstRowStyle = {
    fontFamily: 'Inter',
    fontSize: '14px',
    width: '30px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.006em',
    textAlign: 'left',
    color: '#252C32',
  }
  const renderCell = (value) => {
    if (value === null || value === undefined || value === '') {
      return 'N/A'
    } else {
      return value
    }
  }

  const isEmpty = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) return false
    }
    return true
  }

  const handleApprove = async () => {
    AlertDialog({
      type: 'delete',
      title: 'Submission',
      text: 'Are you sure you want to approve?',
      confirmButtonText: 'Yes',
      onConfirm: async () => {
        if (currentUser.role.role_id === 3 || currentUser.role.role_id === 4) {
          if (parsedData.dataFile.template.template_id === 1) {
            updateGeneralDetails()
          } else {
            updateNRMPerformance()
          }
        }
      },
    })
  }

  const updateNRMPerformance = async () => {
    try {
      console.log('tableDataaa', tableData)
      console.log('updateNRMPerformance', 'updateNRMPerformance')
      const updatedLiningReference = tableData.lining_reference.map((item) => ({
        ...item,
        lining_reference: {
          ...item.lining_reference,
          submission_status: 'approved',
        },
      }))
      console.log('updatedLiningReference', updatedLiningReference)

      const id = localStorage.getItem('nrm_performance_id')

      const updateResponse = await axios.put(
        `${API.NRM_FORM_UPDATE_LINING_REFERENCE}/${id}`,
        { data: updatedLiningReference, id },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      console.log('Update response:', updateResponse.data)
      if (updateResponse.status === 200) {
        navigate('/datamanagement')
      } else {
        console.log('problem with API')
      }
    } catch (error) {
      console.error('Error updating data:', error)
    }
  }

  const updateGeneralDetails = async () => {
    // Map through parsedData and update general_details
    const updatedGeneralDetails = tableData.general_details.map((item) => ({
      ...item,
      submission_status: 'approved',
    }))

    const id = tableData.id
    console.log('id:', id)
    console.log('updatedGeneralDetails:', updatedGeneralDetails)

    // Create the updatedData object
    const updatedData = {
      ...tableData,
      general_details: updatedGeneralDetails,
    }
    try {
      const updateResponse = await axios.put(
        `${API.FORM_UPDATE_GENERAL_DETAILS_WEB}/${id}`,
        { data: updatedData, id },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        }
      )
      console.log('updateResponse:', updateResponse)
      if (updateResponse.status === 200) {
        AlertDialog({
          type: 'success',
          title: 'Success',
          text: 'Approved Successfully',
          confirmButtonText: 'Ok',
          onConfirm: async () => {
            navigate('/datamanagement')
          },
        })
      } else {
        console.error('Error updating data:', updateResponse)
      }
    } catch (error) {
      console.error('Error updating data:', error)
    }
  }

  const handleReject = () => {
    setShowRejectPopup(true)
  }

  const handleRejectComment = async () => {
    const commentsFromData = tableData.general_details
      .map((item) => item.comments)
      .join('\n')
    setComments(commentsFromData)
    setShowRejectPopup(true)
  }

  const handlePopupOk = async () => {
    console.log(comments)
    setShowRejectPopup(false)

    if (currentUser.role.role_id === 3 || currentUser.role.role_id === 4) {
      const updatedGeneralDetails = tableData.general_details.map((item) => ({
        ...item,
        submission_status: 'rejected',
        comments: comments,
      }))

      const id = tableData.id
      console.log('id:', id)
      console.log('updatedGeneralDetails:', updatedGeneralDetails)

      const updatedData = {
        ...tableData,
        general_details: updatedGeneralDetails,
      }
      try {
        const updateResponse = await axios.put(
          `${API.FORM_UPDATE_GENERAL_DETAILS}/${id}`,
          { data: updatedData, id },
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        console.log('updateResponse:', updateResponse)
        if (updateResponse.status === 200) {
          AlertDialog({
            type: 'success',
            title: 'Success',
            text: 'Your Trial is Rejected',
            confirmButtonText: 'Ok',
            onConfirm: async () => {
              navigate('/datamanagement')
            },
          })
        } else {
          console.error('Error updating data:', updateResponse)
        }
      } catch (error) {
        console.error('Error updating data:', error)
      }
    }
  }

  return (
    <div>
      <div
        style={{
          position: 'fixed',
          top: '75px',
          left: -30,
          width: '100vw',
          backgroundColor: 'white',
          zIndex: 1000,
          padding: '10px',
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '10px',
          boxSizing: 'border-box',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            position: 'fixed',
            top: 100,
            right: 20,
            backgroundColor: 'white',
            zIndex: 1000,
            padding: '10px',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="body1"
            onClick={handleBack}
            style={{
              cursor: 'pointer',
              color: '#1976d2',
              padding: '10px',
            }}
          >
            Back
          </Typography>

          {submissionStatus === 'submitted' &&
            (currentUser.role.role_id === 3 ||
              currentUser.role.role_id === 4) && (
              <>
                <StyledButtonWeb
                  variant="outlined_reject"
                  text="Reject"
                  onClick={handleReject}
                  style={{ width: '100px' }}
                />
                <StyledButtonWeb
                  variant="contained"
                  color="primary"
                  text="Approve"
                  onClick={handleApprove}
                  style={{ width: '100px' }}
                />
              </>
            )}

          {submissionStatus === 'rejected' && (
            <StyledButtonWeb
              variant="contained"
              color="primary"
              text="View"
              onClick={handleRejectComment}
              style={{ width: '100px' }}
            />
          )}
        </div>
      </div>

      {/* Reject Popup */}
      {showRejectPopup && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1001,
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <TextField
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              placeholder="Enter comments"
              style={{ width: '300px', height: '100px', marginBottom: '10px' }}
              disabled={currentUser.role.role_id === 1 || currentUser.role.role_id === 2}
            />
            <StyledButtonWeb
              variant="contained"
              color="primary"
              text="OK"
              onClick={handlePopupOk}
              style={{ width: '100px' }}
            />
          </div>
        </div>
      )}

      <div style={{ overflowX: 'auto', marginTop: '80px' }}>
        {loading ? ( // Show loader and image when loading
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <CircularProgress />
          </div>
        ) : tableData.length === 0 ? ( // Show "Trials not found" only when tableData is empty
          <Typography
            variant="body1"
            sx={{ textAlign: 'center', marginTop: '20px' }}
          >
            Trials not found
          </Typography>
        ) : (
          <div>
            <TableContainer component={Paper}>
              {parsedData?.dataFile?.template?.template_id === 1 ? (
                <TableA />
              ) : parsedData?.dataFile?.template?.template_id === 2 ? (
                <TableB />
              ) : null}
            </TableContainer>
          </div>
        )}
      </div>
    </div>
  )
}

export default ViewDailyTrial
