import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import { menuList } from '../../recoil/atoms'
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
  IconButton,
} from '@mui/material'
import Routes from '../../routes'
import { useMediaQuery, createTheme, Typography } from '@mui/material'
import { ReactComponent as AppLogo } from '../../assets/sidemenu/ic_logo.svg'
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout_icon.svg'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import saintGobainLogo from '../../assets/icons/saint-gobain-logo.svg'

const drawerWidth = 300

function NavDrawer({ toggleDrawerOpen, open }) {
  const theme = createTheme() // Create or import your theme object
  const matches = useMediaQuery(theme.breakpoints.up('sm'))

  const [, setMenu] = useRecoilState(menuList)
  const [userData, setUserData] = useState({})

  const [, setSelectedItem] = useState(null)
  const menu = useRecoilValue(menuList)
  const navigate = useNavigate()

  useEffect(() => {
    let data = localStorage.getItem('user')
    console.log('datadatadatadata', data)
    let User = JSON.parse(data)
    console.log('userrreer', User.role.role_id)
    setUserData(User)
  }, [])

  useEffect(() => {
    if (matches) {
      toggleDrawerOpen(true)
    } else {
      toggleDrawerOpen(false)
    }
  }, [matches])

  const handleDashboardClick = () => {
    console.log('clicked')
  }

  const handleLogout = () => {
    localStorage.removeItem('user')
    window.location.href = '/login'
  }

  const changeMenu = (name) => {
    setMenu(name)
    setSelectedItem(name)
  }

  function getListItemSet(text, index) {
    const isSelected = menu === text.name
    const textColor = isSelected ? '#0086DC' : '#252C32'
    const backgroundColor = isSelected ? '#0086DC1A' : ''
    const iconColor = isSelected ? '#000' : '#757575'
    const Icon = isSelected ? text.iconSelected : text.icon

    return text.icon ? (
      <NavLink key={index} style={{ textDecoration: 'none' }} to={text.path}>
        <ListItem
          key={text.name}
          disablePadding
          sx={{
            display: 'block',
            paddingLeft: 2,
            paddingRight: 2,
            backgroundColor: backgroundColor,
            paddingTop: 0.3,
            paddingBottom: 0.3,
            marginLeft: '15px',
            marginRight: '10px',
            borderRadius: '5px',
            boxSizing: 'border-box',
          }}
          style={{
            marginRight: '10px',
          }}
          onClick={() => changeMenu(text.name)}
        >
          <ListItemButton
            sx={{
              minHeight: 20,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <Tooltip title={text.name} placement="right">
              <Icon />
            </Tooltip>
            {open && (
              <ListItemText
                className="Drawer-ListItemText"
                primary={
                  text.name === 'Logout'
                    ? text.name + ' - ' + userData.name
                    : text.name
                }
                sx={{ opacity: 1 }}
                style={{
                  color: textColor,
                  marginLeft: '8px',
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '24px',
                  letterSpacing: '-0.006em',
                  textAlign: 'left',
                }}
              />
            )}
            <ListItemText
              className="Dashboardpage"
              primary={handleDashboardClick}
            />
          </ListItemButton>
        </ListItem>
      </NavLink>
    ) : null
  }
  // const filteredRoutes = Routes.mainRoutes.filter(
  //   (route) =>
  //     route.name !== "Settings" ||
  //     (userData.role && userData.role.role_id !== 1)
  // );

  // const filteredRoutes = Routes.mainRoutes.filter((route) => {
  //   if (userData.role) {

  //     if (route.path === "/settings") {
  //       return userData.role.role_id !== 1 && userData.role.role_id !== 2;
  //     }
  //     if (route.path === "/reports") {
  //       return userData.role.role_id === 3;
  //     }
  //   }
  //   return true;
  // });

  // const filteredRoutes = Routes.mainRoutes.filter((route) => {
  //   if (userData.role) {
  //     if (route.path === "/settings") {
  //       return userData.role.role_id !== 1 && userData.role.role_id !== 2 && userData.role.role_id !== 3;
  //     }
  //   }
  //   return true;
  // });

  const filteredRoutes = Routes.mainRoutes.filter((route) => {
    if (userData.role) {
      if (route.path === '/settings') {
        // Exclude routes if user role_id is 1, 2, or 3
        return (
          userData.role.role_id !== 1 &&
          userData.role.role_id !== 2 &&
          userData.role.role_id !== 3
        )
      }

      // existing one
      if (route.path === '/reports') {
        // Exclude routes if user role_id is 1, 2, or 3
        return userData.role.role_id !== 1 && userData.role.role_id !== 2
      }

      // new one
      // if (route.path === "/reports") {
      //   // Exclude routes if user role_id is 1, 2, or 3
      //   return userData.role.role_id !== 1 && userData.role.role_id !== 2 && userData.role.role_id !== 3;
      // }
    }
    // Default to true to include all other routes
    return true
  })

  const drawer = (
    <div>
      <List>
        {filteredRoutes.map((text, index) => getListItemSet(text, index))}
      </List>
    </div>
  )

  return (
    <div>
      <div className="desktop-view">
        <Drawer
          variant={matches ? 'permanent' : 'persistent'}
          style={{
            whiteSpace: 'nowrap',
            width: open ? drawerWidth : 110,
            position: 'relative',
          }}
          open={open}
        >
          <List>
            <ListItem disablePadding>
              <ListItemButton
                sx={{
                  minHeight: 58,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={toggleDrawerOpen}
              >
                <IconButton
                  color="inherit"
                  aria-label="toggle drawer"
                  edge="start"
                  sx={{
                    display: 'block',
                    cursor: 'pointer',
                    width: 48,
                    height: 48,
                  }}
                >
                  <AppLogo />
                </IconButton>
                {open && (
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 800,
                      fontFamily: 'Inter',
                      fontSize: '20px',
                      lineHeight: '24px',
                      letterSpacing: '-0.006em',
                      textAlign: 'left',
                      color: '#252C32',
                    }}
                  >
                    Digital Foundry
                  </Typography>
                )}
              </ListItemButton>
            </ListItem>
            {drawer}
            <List>
              {filteredRoutes.map((text, index) =>
                text.footer && text.iconFlag
                  ? getListItemSet(text, index)
                  : null
              )}
            </List>
          </List>
          <Button
            onClick={handleLogout}
            sx={{
              backgroundColor: '#0086dc',
              color: '#FFFFFF',
              margin: '10px',
              borderRadius: '5px',
              '&:hover': {
                backgroundColor: '#0086dc',
              },
            }}
          >
            Logout
          </Button>
          {open && (
            <>
              <ListItem
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: 'auto',
                  paddingTop: '10px',
                }}
              >
                <img
                  src={saintGobainLogo}
                  alt="Saint Gobain Logo"
                  style={{ width: 'auto', height: '110%', marginBottom: 5 }} // Adjust width and height as needed
                />
              </ListItem>
              <ListItem
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '-10px',
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    fontFamily: 'Inter',
                    fontSize: '12px',
                    fontWeight: 600,
                    lineHeight: '12.1px',
                    letterSpacing: '-0.006em',
                    textAlign: 'center',
                  }}
                >
                  Copyright © 2024 INDEC4.0
                </Typography>
              </ListItem>
            </>
          )}
        </Drawer>
      </div>
      <div className="mobile-view">
        <Drawer
          variant="persistent"
          style={{
            whiteSpace: 'nowrap',
            position: 'fixed',
            width: open ? '300px' : '0',
            left: '0',
            top: '0',
            height: '100%',
            zIndex: 9999,
          }}
          open={open}
        >
          <List>
            <ListItem disablePadding>
              <ListItemButton
                sx={{
                  minHeight: 58,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                onClick={toggleDrawerOpen}
              >
                <IconButton
                  color="inherit"
                  aria-label="toggle drawer"
                  edge="start"
                  sx={{
                    display: 'block',
                    cursor: 'pointer',
                    width: 48,
                    height: 48,
                  }}
                >
                  <AppLogo />
                </IconButton>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {open && (
                    <ListItemText
                      primary="Digital Foundry"
                      sx={{ opacity: 1 }}
                      style={{ color: 'black' }}
                    />
                  )}
                  <IconButton
                    edge="end"
                    color="inherit"
                    aria-label="logout"
                    sx={{ marginLeft: 10 }}
                  >
                    <LogoutIcon />
                  </IconButton>
                </div>
              </ListItemButton>
            </ListItem>
            {drawer}
            <List>
              {filteredRoutes.map((text, index) =>
                text.footer && text.iconFlag
                  ? getListItemSet(text, index)
                  : null
              )}
            </List>
          </List>
          <Button
            onClick={handleLogout}
            sx={{
              backgroundColor: '#0086dc',
              color: '#FFFFFF',
              margin: '10px',
              borderRadius: '5px',
              '&:hover': {
                backgroundColor: '#0086dc',
              },
            }}
          >
            Logout
          </Button>
        </Drawer>
      </div>
    </div>
  )
}

export default NavDrawer
