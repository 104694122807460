import React, { useState, useEffect } from 'react'
import Plot from 'react-plotly.js'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Grid,
  useTheme,
  TableContainer,
  Tabs,
  Tab,
  TextField,
  FormHelperText,
} from '@mui/material'
import { MoreVert as MoreVertIcon } from '@mui/icons-material'
import { Delete } from '@mui/icons-material'
import { ReactComponent as DownloadIcon } from '../../assets/icons/download_icon.svg'
import { ReactComponent as DeletIcon } from '../../assets/icons/delete_icon.svg'
import SGLogo from '../../assets/icons/SgLogo.png'
import { sampleCorrelation } from 'simple-statistics'
import { useLocation } from 'react-router-dom'
import ListItemText from '@mui/material/ListItemText'
import InputLabel from '@mui/material/InputLabel'
import API from '../services/API'
import axios from 'axios'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import TopAppBar from '../../Components/drawer/TopAppBar'
import AlertDialog from '../input/AlertDialog'

import Plotly from 'plotly.js-dist-min'

const GraphComponent = () => {
  const location = useLocation()
  const theme = useTheme()
  const [tabs, setTabs] = useState([])
  const tab = localStorage.getItem('selectedTabLabel')
  const [selectedTab, setSelectedTab] = useState(tab || tabs[0].label)
  const [warning, setWarning] = useState('')
  useEffect(() => {
    localStorage.setItem('selectedTabLabel', selectedTab)
  }, [selectedTab])

  const templateId = location.state ? location.state.template : null
  console.log('templateId', templateId)
  const data = location.state ? location.state.cleanedDataGraph : null
  console.log('cleanedDataGraph', data)
  //console.log(templateId,'templateId');

  const actualdata = location.state ? location.state.actualdata : null ///data
  console.log('actualdata', actualdata)

  const [modalOpen, setModalOpen] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [selectedChart, setSelectedChart] = useState(null)
  const [charts, setCharts] = useState([])
  const [updateCharts, setUpdateCharts] = useState([])
  const [graphType, setGraphType] = useState('')
  const [metric, setMetric] = useState('')
  const [xAxis, setXAxis] = useState('')
  const [yAxis, setYAxis] = useState([])
  const [selectedCharts, setSelectedCharts] = useState([])
  const [activeChart, setActiveChart] = useState(null)
  const [allCharts, setAllCharts] = useState(charts)
  const [correlationMatrix, setCorrelationMatrix] = useState([])
  const [notes, setNotes] = useState('')
  const [jsonMap, setJsonMap] = useState([])
  const [jsonData, setJsonData] = useState(null)
  const [heatmapModalOpen, setHeatmapModalOpen] = useState(false)
  const [heatmapSelection, setHeatmapSelection] = useState('')
  const handleModalOpen = () => {
    setShowModal(true)
  }

  const handleModalClose = () => {
    setShowModal(false)
  }

  const handleGraphTypeChange = (event) => {
    setGraphType(event.target.value)
    if (event.target.value === 'heatmap') {
      setHeatmapModalOpen(true)
      setHeatmapSelection('')
      if (tab === 'Daily Trial Monitoring') {
        setXAxis('lorp_id')
      } else if (tab === 'NRM Performance') {
        setXAxis('life')
      }
    } else {
      setHeatmapModalOpen(false)
      setXAxis('')
    }
  }

  const handleXAxisChange = (event) => {
    setXAxis(event.target.value)
  }

  const handleMetricChange = (event) => {
    const selectedMetric = event.target.value
    setMetric(selectedMetric)
    //console.log("metric",metric);
    setGraphType('')
    setXAxis('')
    setYAxis([])
  }

  // const handleYAxisChange = (event) => {
  //   const selected = event.target.value;
  //   setYAxis(selected);
  // };

  const handleYAxisChange = (event) => {
    const {
      target: { value },
    } = event

    // Check if the number of selections exceeds 5
    if (value.length > 5) {
      setWarning('You can only select up to 5 options.')
    } else {
      setWarning('')
      setYAxis(value)
    }
  }

  // const tabs = [
  //   { label: "Daily Trial Monitoring", value: "1278e494-7771-421c-8bbe-d0409031efa2" },
  //   { label: "NRM performance", value: "0271c530-6fb5-4b96-9576-3eadaab5c317" },
  //   { label: "Erosion profile", value: "492e5308-1ebd-4cf0-965b-0f323cc2e531" }
  // ];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  const handleNotesChange = (event) => {
    setNotes(event.target.value)
  }

  function calculateMean(array) {
    return array.reduce((total, val) => total + val, 0) / array.length
  }
  const cardId = localStorage.getItem('reportCardId')
  const rowIdsString = localStorage.getItem('rowIdsString')
  const rowIdsArray = rowIdsString.split(',')
  //console.log('rowIdsArray',rowIdsArray);

  // let jsonData = null;
  // const jsonMap=[]
  useEffect(() => {
    fetchCards()
  }, [])

  const fetchCards = async () => {
    try {
      const url_tab = `${API.GET_TEMPLATETAB}`
      const response_tab = await axios.get(url_tab)
      const data = response_tab.data
      console.log('tab data', data)
      const trimmedData = data.slice(0, -2)
      const tab_data = trimmedData.map((item) => ({
        label: item.name,
        value: item.id,
      }))

      setTabs(tab_data)

      const repcardId = localStorage.getItem('reportCardId')

      const response = await axios.get(
        API.GET_GRAPH,
        {
          params: { reports_id: repcardId, template_id: templateId },
        },
        {
          headers: { 'Content-Type': 'application/json' },
        }
      )
      // const graphdata = response.data;
      const responseData = await response.data[0].standard_graphs

      // const sg=data.standard_graphs;
      // //console.log("sg",sg);
      //console.log("responsegraph", responseData);
      setCharts(responseData)
    } catch (error) {
      //console.error('An error occurred while fetching submitted cards:', error);
      // Optionally, you can handle the error state here
    }
  }

  const handleAddGraph = () => {
    let lastId = 0
    if (charts.length > 0) {
      lastId = charts[charts.length - 1].id
    }

    // Calculate newId
    const newId = lastId + 1
    //console.log('newId',newId, charts.length);
    // const yAxis = yAxis;
    //console.log('yAxis',yAxis);
    const newData = groupDataByXAxis(data, xAxis, yAxis)
    const newData2 = groupDataByXAxis1(actualdata, xAxis, yAxis)
    //console.log("data",data);
    //console.log("xAxis",xAxis);
    //console.log("yAxis",yAxis);
    //console.log("newData",newData);

    const newJsonData = {
      notes: notes,
      graph_type: graphType,
      x_axis: xAxis,
      y_axis: yAxis,
    }
    setJsonData(newJsonData)

    setJsonMap((prevJsonMap) => [...prevJsonMap, newJsonData])

    const newCharts = []
    const chartData = []

    if (tab === 'Daily Trial Monitoring') {
      // Define your y-axis variables here
      try {
        if (graphType === 'box') {
          // Assuming yAxis is an array with at least one element
          const yAxisItem = yAxis.length > 0 ? yAxis[0] : null

          for (const key in newData2) {
            if (newData2.hasOwnProperty(key) && key !== 'undefined') {
              chartData.push({
                x: key,
                y: newData2[key][yAxisItem], // Data for each y-axis variable
                name: key, // Use the key as the name
                type: 'box',
              })
            }
          }

          //console.log('chartData', chartData);
        } else if (
          graphType === 'bar' ||
          graphType === 'scatter' ||
          graphType === 'line' ||
          graphType === 'horizontal-bar'
        ) {
          //console.log("metricmetricmetric", metric);
          yAxis.forEach((yAxisItem) => {
            const xValues = []
            const yValues = []
            for (const key in newData) {
              if (Object.hasOwnProperty.call(newData, key)) {
                // xValues.push(key);
                const values = newData[key][yAxisItem]
                //console.log('values', values);
                let result
                switch (metric) {
                  case 'mean':
                    result = calculateMean(values)
                    break
                  case 'first_value':
                    result = Array.isArray(values) ? values[0] : values // Handle array values
                    break
                  case 'last_value':
                    result = Array.isArray(values)
                      ? values[values.length - 1]
                      : values // Handle array values
                    break
                  case 'maximum':
                    result = Math.max(...values)
                    break
                  case 'minimum':
                    result = Math.min(...values)
                    break
                  case 'total':
                    result = Array.isArray(values)
                      ? values.reduce((total, val) => total + val, 0)
                      : values
                    break
                  case 'count':
                    result = Array.isArray(values) ? values.length : 1 // If array, return length; else, return 1
                    break
                  default:
                    // Default behavior, in case metric is not recognized
                    result = calculateMean(values)
                }
                //console.log('result', result);
                // yValues.push(result);
                if (key !== 'undefined') {
                  xValues.push(key)
                  yValues.push(result)
                }
              }
            }
            //console.log("xValues",xValues);
            //console.log("yValues",yValues);
            chartData.push({
              x: graphType === 'horizontal-bar' ? yValues : xValues,
              y: graphType === 'horizontal-bar' ? xValues : yValues,
              mode: graphType === 'scatter' ? 'markers' : 'lines',
              name: yAxisItem,
              type: graphType === 'horizontal-bar' ? 'bar' : graphType,
              orientation: graphType === 'horizontal-bar' ? 'h' : undefined,
              metric: metric,
            })
          })
        }
        // Handle stacked-bar chart
        else if (graphType === 'stacked-bar') {
          const categories = Object.keys(newData).filter(
            (category) => category !== 'undefined'
          )
          yAxis.forEach((yAxisItem) => {
            //console.log("categories",categories);
            const yValues = categories.map((category) => {
              const values = newData[category][yAxisItem]
              let result
              switch (metric) {
                case 'mean':
                  result = calculateMean(values)
                  break
                case 'first_value':
                  result = Array.isArray(values) ? values[0] : values
                  break
                case 'last_value':
                  result = Array.isArray(values)
                    ? values[values.length - 1]
                    : values
                  break
                case 'maximum':
                  result = Math.max(...values)
                  break
                case 'minimum':
                  result = Math.min(...values)
                  break
                case 'total':
                  result = Array.isArray(values)
                    ? values.reduce((total, val) => total + val, 0)
                    : values
                  break
                case 'count':
                  result = Array.isArray(values) ? values.length : 1
                  break
                default:
                  result = calculateMean(values)
              }
              return result
            })
            chartData.push({
              x: categories,
              y: yValues,
              name: yAxisItem,
              type: 'bar',
              metric: metric,
            })
          })
        } else if (graphType === 'pie') {
          const categories = Object.keys(newData).filter(
            (category) => category !== 'undefined'
          )
          const values = categories.map((category) => {
            let result = 0
            yAxis.forEach((yAxisItem) => {
              // Loop through each yAxisItem
              switch (metric) {
                case 'mean':
                  result += calculateMean(newData[category][yAxisItem])
                  break
                case 'first_value':
                  result += Array.isArray(newData[category][yAxisItem])
                    ? newData[category][yAxisItem][0]
                    : newData[category][yAxisItem]
                  break
                case 'last_value':
                  result = Array.isArray(newData[category][yAxisItem])
                    ? newData[category][yAxisItem][
                        newData[category][yAxisItem].length - 1
                      ]
                    : newData[category][yAxisItem]
                  break
                case 'maximum':
                  result = Array.isArray(newData[category][yAxisItem])
                    ? Math.max(...newData[category][yAxisItem])
                    : newData[category][yAxisItem]
                  break
                case 'minimum':
                  result = Array.isArray(newData[category][yAxisItem])
                    ? Math.min(...newData[category][yAxisItem])
                    : newData[category][yAxisItem]
                  break
                case 'total':
                  result = Array.isArray(newData[category][yAxisItem])
                    ? newData[category][yAxisItem].reduce((a, b) => a + b, 0)
                    : newData[category][yAxisItem]
                  break
                case 'count':
                  result = Array.isArray(newData[category][yAxisItem])
                    ? newData[category][yAxisItem].length
                    : 1
                  break
                default:
                  result = calculateMean(newData[category][yAxisItem])
              }
            })
            return result
          })

          // Construct the data for the pie chart
          const dataForPie = {
            labels: categories,
            values: values,
            type: 'pie',
            metric: metric,
          }

          // Push the dataForPie object into the chartData array
          chartData.push(dataForPie)
        } else if (graphType === 'heatmap') {
          try {
            // Extract all unique lorp_id values
            const lorpIds = Array.from(
              new Set(data.map((item) => item.lorp_id))
            )
            //console.log("lorpIds", lorpIds);

            // Filter out undefined values
            const validLorpIds = lorpIds.filter((id) => id !== undefined)

            // Prepare an empty correlation matrix
            const correlationMatrix = []

            // Loop through each lorp_id
            validLorpIds.forEach((lorpId) => {
              //console.log("lorpIdslorpIdslorpIds", lorpIds);
              // Filter data for the current lorp_id
              const filteredData = data.filter(
                (item) => item.lorp_id === lorpId
              )
              //console.log("filteredData", filteredData);
              // Calculate correlation coefficient for each yAxis option
              const correlations = yAxis.map((yAxisItem) => {
                // Filter data for the current yAxisItem
                const yAxisData = filteredData.map((item) => item[yAxisItem])
                //   //console.log("Data type of yAxisData:", typeof yAxisData);
                //console.log("yAxisData:", yAxisData);
                //   //console.log("Is yAxisData an array?", Array.isArray(yAxisData));
                //   // const yAxisValues = Array.isArray(yAxisData) ? yAxisData : [yAxisData];
                //   const numericYAxisData = yAxisData.map(timeString => {
                //     const [hours, minutes] = timeString.split(':').map(Number);
                //     return hours + minutes / 60; // Convert minutes to fraction of an hour
                // });
                //   //console.log("Data type of yAxisValues:", typeof numericYAxisData);
                //   //console.log("numericYAxisData", numericYAxisData);
                //   //console.log("Data type of filteredData:", typeof filteredData.map(item => item.heat_tap_time));

                const check = filteredData.map((item) => item[heatmapSelection])
                //console.log("check:", check);
                //   const numericcheckData = check.map(timeString => {
                //     const [hours, minutes] = timeString.split(':').map(Number);
                //     return hours + minutes / 60; // Convert minutes to fraction of an hour
                // });
                //   //console.log(" numericcheckData:",  numericcheckData);
                //   //console.log("Is check an array?", Array.isArray(check));

                if (yAxisData.some(isNaN) || check.some(isNaN)) {
                  return // Skip this lorpId
                }

                const correlation = sampleCorrelation(yAxisData, check)
                //console.log("correlation", correlation);

                return correlation
              })

              // Push correlation data for the current lorp_id
              correlationMatrix.push({
                lorp_id: lorpId,
                correlations: correlations,
              })
            })

            // Prepare data for the heatmap
            const heatmapData = {
              x: yAxis, // The yAxis items will be represented on the x-axis
              y: correlationMatrix.map((matrix) => matrix.lorp_id),
              z: correlationMatrix.map((matrix) => matrix.correlations),
              type: 'heatmap',
              colorscale: 'Viridis',
              heatmapSelection: heatmapSelection,
            }
            //console.log("heatmapData", heatmapData);
            // Push heatmapData to chartData
            chartData.push(heatmapData)
          } catch (error) {
            //console.error("Error:", error);
            window.alert(
              'Error: sampleCovariance requires at least two data points in each sample'
            )
          }
        }
      } catch (error) {
        //console.error('An error occurred while inserting chart data:', error);
        window.alert('An error occurred while inserting chart data.' + error)
        // Optionally, handle error state here
      }
    } else if (tab === 'NRM Performance') {
      try {
        if (graphType === 'box') {
          // Assuming yAxis is an array with at least one element
          const yAxisItem = yAxis.length > 0 ? yAxis[0] : null

          for (const key in newData2) {
            if (newData2.hasOwnProperty(key) && key !== 'undefined') {
              chartData.push({
                x: key,
                y: newData2[key][yAxisItem], // Data for each y-axis variable
                name: key, // Use the key as the name
                type: 'box',
              })
            }
          }

          //console.log('chartData', chartData);
        } else if (
          graphType === 'bar' ||
          graphType === 'scatter' ||
          graphType === 'line' ||
          graphType === 'horizontal-bar'
        ) {
          //console.log("metricmetricmetric", metric);
          yAxis.forEach((yAxisItem) => {
            const xValues = []
            const yValues = []
            for (const key in newData) {
              if (Object.hasOwnProperty.call(newData, key)) {
                // xValues.push(key);
                const values = newData[key][yAxisItem]
                //console.log('values', values);
                let result
                switch (metric) {
                  case 'mean':
                    result = calculateMean(values)
                    //console.log('result', result);
                    break
                  case 'first_value':
                    result = Array.isArray(values) ? values[0] : values // Handle array values
                    //console.log('result', result);
                    break
                  case 'last_value':
                    result = Array.isArray(values)
                      ? values[values.length - 1]
                      : values // Handle array values
                    //console.log('result', result);
                    break
                  case 'maximum':
                    result = Math.max(...values)
                    //console.log('result', result);
                    break
                  case 'minimum':
                    result = Math.min(...values)
                    //console.log('result', result);
                    break
                  case 'total':
                    result = Array.isArray(values)
                      ? values.reduce((total, val) => total + val, 0)
                      : values
                    //console.log('result', result);
                    break
                  case 'count':
                    result = Array.isArray(values) ? values.length : 1 // If array, return length; else, return 1
                    //console.log('result', result);
                    break
                  default:
                    // Default behavior, in case metric is not recognized
                    result = calculateMean(values)
                  //console.log('result', result);
                }
                //console.log('result', result);
                // yValues.push(result);
                if (key !== 'undefined') {
                  xValues.push(key)
                  yValues.push(result)
                }
              }
            }
            //console.log("xValues",xValues);
            //console.log("yValues",yValues);
            chartData.push({
              x: graphType === 'horizontal-bar' ? yValues : xValues,
              y: graphType === 'horizontal-bar' ? xValues : yValues,
              mode: graphType === 'scatter' ? 'markers' : 'lines',
              name: yAxisItem,
              type: graphType === 'horizontal-bar' ? 'bar' : graphType,
              orientation: graphType === 'horizontal-bar' ? 'h' : undefined,
              metric: metric,
            })
          })
        }
        // Handle stacked-bar chart
        else if (graphType === 'stacked-bar') {
          const categories = Object.keys(newData).filter(
            (category) => category !== 'undefined'
          )
          yAxis.forEach((yAxisItem) => {
            //console.log("categories",categories);
            const yValues = categories.map((category) => {
              const values = newData[category][yAxisItem]
              let result
              switch (metric) {
                case 'mean':
                  result = calculateMean(values)
                  break
                case 'first_value':
                  result = Array.isArray(values) ? values[0] : values
                  break
                case 'last_value':
                  result = Array.isArray(values)
                    ? values[values.length - 1]
                    : values
                  break
                case 'maximum':
                  result = Math.max(...values)
                  break
                case 'minimum':
                  result = Math.min(...values)
                  break
                case 'total':
                  result = Array.isArray(values)
                    ? values.reduce((total, val) => total + val, 0)
                    : values
                  break
                case 'count':
                  result = Array.isArray(values) ? values.length : 1
                  break
                default:
                  result = calculateMean(values)
              }
              return result
            })
            chartData.push({
              x: categories,
              y: yValues,
              name: yAxisItem,
              type: 'bar',
              metric: metric,
            })
          })
        } else if (graphType === 'pie') {
          const categories = Object.keys(newData).filter(
            (category) => category !== 'undefined'
          )
          const values = categories.map((category) => {
            let result = 0
            yAxis.forEach((yAxisItem) => {
              // Loop through each yAxisItem
              switch (metric) {
                case 'mean':
                  result += calculateMean(newData[category][yAxisItem])
                  break
                case 'first_value':
                  result += Array.isArray(newData[category][yAxisItem])
                    ? newData[category][yAxisItem][0]
                    : newData[category][yAxisItem]
                  break
                case 'last_value':
                  result = Array.isArray(newData[category][yAxisItem])
                    ? newData[category][yAxisItem][
                        newData[category][yAxisItem].length - 1
                      ]
                    : newData[category][yAxisItem]
                  break
                case 'maximum':
                  result = Array.isArray(newData[category][yAxisItem])
                    ? Math.max(...newData[category][yAxisItem])
                    : newData[category][yAxisItem]
                  break
                case 'minimum':
                  result = Array.isArray(newData[category][yAxisItem])
                    ? Math.min(...newData[category][yAxisItem])
                    : newData[category][yAxisItem]
                  break
                case 'total':
                  result = Array.isArray(newData[category][yAxisItem])
                    ? newData[category][yAxisItem].reduce((a, b) => a + b, 0)
                    : newData[category][yAxisItem]
                  break
                case 'count':
                  result = Array.isArray(newData[category][yAxisItem])
                    ? newData[category][yAxisItem].length
                    : 1
                  break
                default:
                  result = calculateMean(newData[category][yAxisItem])
              }
            })
            return result
          })

          // Construct the data for the pie chart
          const dataForPie = {
            labels: categories,
            values: values,
            type: 'pie',
            metric: metric,
          }

          // Push the dataForPie object into the chartData array
          chartData.push(dataForPie)
        } else if (graphType === 'heatmap') {
          try {
            // //console.log('xAxis',xAxis);
            // const yAxis=xAxis;
            // //console.log('yAxis',yAxis);
            //console.log('graphType',graphType);

            //console.log('heatmapSelection', heatmapSelection);
            // Extract all unique life values
            const Lifes = Array.from(new Set(data.map((item) => item.life)))
            //console.log("Lifes", Lifes);

            // Filter out undefined values
            const validLifes = Lifes.filter((id) => id !== undefined)

            // Prepare an empty correlation matrix
            const correlationMatrix = []

            // Loop through each life
            validLifes.forEach((Life) => {
              //console.log("LifesLifesLifes", Lifes);
              // Filter data for the current life
              const filteredData = data.filter((item) => item.life === Life)
              //console.log("filteredData", filteredData);
              // Calculate correlation coefficient for each yAxis option
              const correlations = yAxis.map((yAxisItem) => {
                // Filter data for the current yAxisItem
                const yAxisData = filteredData.map((item) => item[yAxisItem])
                //   //console.log("data type of yAxisData:", typeof yAxisData);
                //console.log("yAxisData:", yAxisData);

                const check = filteredData.map((item) => item[heatmapSelection])
                //console.log("check:", check);

                if (yAxisData.some(isNaN) || check.some(isNaN)) {
                  return // Skip this Life
                }

                const correlation = sampleCorrelation(yAxisData, check)
                //console.log("correlation", correlation);

                return correlation
              })

              // Push correlation data for the current life
              correlationMatrix.push({
                life: Life,
                correlations: correlations,
              })
            })

            // Prepare data for the heatmap
            const heatmapData = {
              x: yAxis, // The yAxis items will be represented on the x-axis
              y: correlationMatrix.map((matrix) => matrix.life),
              z: correlationMatrix.map((matrix) => matrix.correlations),
              type: 'heatmap',
              colorscale: 'Viridis',
              heatmapSelection: heatmapSelection,
            }
            //console.log("heatmapData", heatmapData);
            // Push heatmapData to chartData
            chartData.push(heatmapData)
          } catch (error) {
            //console.error("Error:", error);
            window.alert(
              'Error: sampleCovariance requires at least two data points in each sample'
            )
          }
        }
      } catch (error) {
        //console.error('An error occurred while inserting chart data:', error);
        window.alert('An error occurred while inserting chart data.' + error)
        // Optionally, handle error state here
      }
    }
    // Create a single chart with the combined data
    newCharts.push({
      id: newId + newCharts.length,
      xAxis: xAxis,
      yAxis: yAxis,
      title: notes,
      // title: `${xAxis}-Combined`, // Use a title indicating it's combined
      data: chartData,
    })
    const updatedData = [...charts, ...newCharts]

    //console.log("updatedData", updatedData);

    setCharts(updatedData)

    axios
      .post(
        API.UPDATE_GRAPH,
        {
          reports_id: cardId,
          standard_graphs: updatedData,
          template_id: templateId,
        },
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((excelresponse) => {
        if (excelresponse.status === 200) {
          AlertDialog({
            type: 'success',
            title: 'Success',
            text: 'Saved Successfully!',
            confirmButtonText: 'Ok',
            onConfirm: async () => {
              window.location.reload()
            },
          })
        } else {
          //console.error('Error saving data:', excelresponse.data);
          AlertDialog({
            type: 'warning',
            title: 'Error',
            text: 'Failed to save data',
          })
        }
      })
    // .catch((error) => {
    //   AlertDialog({
    //     type: 'warning',
    //     title: 'Error',
    //     text: 'Failed to save data',
    //   });
    // });

    setSelectedCharts([])
    setNotes('')
    setShowModal(false)
  }

  const groupDataByXAxis = (data, xAxis, yAxis) => {
    const groupedData = {}

    data.forEach((item) => {
      const xValue = item[xAxis]
      if (!groupedData[xValue]) {
        groupedData[xValue] = {}
      }
      yAxis.forEach((axis) => {
        if (!groupedData[xValue][axis]) {
          groupedData[xValue][axis] = []
        }
        if (item[axis] !== undefined) {
          groupedData[xValue][axis].push(item[axis])
        }
      })
    })

    //console.log("groupedData", groupedData);
    return groupedData
  }

  const groupDataByXAxis1 = (actualdata, xAxis, yAxis) => {
    const groupedData = {}

    actualdata.forEach((item) => {
      const xValue = item[xAxis]
      if (!groupedData[xValue]) {
        groupedData[xValue] = {}
      }
      yAxis.forEach((axis) => {
        if (!groupedData[xValue][axis]) {
          groupedData[xValue][axis] = []
        }
        if (item[axis] !== undefined) {
          groupedData[xValue][axis].push(item[axis])
        }
      })
    })

    console.log('groupedData', groupedData)
    return groupedData
  }

  const groupedData = groupDataByXAxis(data, xAxis, yAxis) // Call groupDataByXAxis to get grouped data

  //console.log('jsonMap', jsonMap);

  // Generate traces based on groupedData
  const traces = []
  Object.keys(groupedData).forEach((xValue) => {
    Object.keys(groupedData[xValue]).forEach((yAxis) => {
      traces.push({
        y: groupedData[xValue][yAxis],
        name: `${xValue} - ${yAxis}`,
        type: 'box',
      })
    })
  })

  const convertImageToBase64 = () => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => {
        const canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height

        const ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0)

        const base64Img = canvas.toDataURL('image/png')
        resolve(base64Img)
      }
      img.onerror = (error) => {
        reject(error)
      }
      img.src = SGLogo
    })
  }

  //  const base64Img = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARDSURBVHgB5VZNaF1FFP7O3Js0ie8lBTVaJfo22tJN2rpqFBHEhZqKboxCoYK1G0EptGuRgkgJFBcuNIJxI6kLLbF14c9Oo1DandC4KW2hQWpqm74meUnuPT1z5ufeV0rfeymlix64d+bOnDvnO2e+OWeA+12olcLjn44OU5ZMiuI2tCH9Tw0hrfahPeFJ00qlE+OdC73TGgBhI+6itARwt+WeA0gHd28fJuYjAqXGdkRiDjJKTzIGK+f+rdkx0nEC67hv5WHRSXo2IKm0S7ybACDLj8HQk8zeuFgmfZGOYC1TYwGU6CLPrarx44wsW4Lp6QalKTqVVDyrScvSWou6pm2hiKS1E2KZbde27P8k1XMq8ldj7jKSgV40lq8j68rbB8BslyTrCGuY4ZOD2qVgzsUlBqmk5995vQFeWgVvlYhx+wDKJLQY1KuDb+zDycPT+Pr9wxQ8fnPnKzj5yQ/4fv/n4BgG+D+c5BZAI0MnYoLX0PC7/Z/6/TgG+ip4eccLeHbLM2rk4GvvYejBTZj68wTCP44kzcmU8/a9VwDQYJO3rQ9fuHSRJn6ZUtcOiOG3RkbV+IX5OXz310/OELNTBjdFoVMxbi0Oq2hwLQm/+PlbXF28hpHNO3BobL8qfzB56JaLUOuSclsATWv5MNDCUh3j01+pa/19VRydOYGZf04rQvU+EpEijPVICsd3RrGt8Rj+fX42rnpewh8QulCx/9EdT1xbAZbWkC8sY623C+2K4UBpjowmL/js3Y+i4r4Xx/CE8MArxPF4PDMBspKDV7OOHkMhx+or5Ffmsed2OeL9N4cvf52SU1HFgV17YyIqg7hjEobIKxN9SIT9amF8egLjP04oIcd2voqRp7c7o1yYpSItdg6gnFRCOG0iGnpoE/6YPSXkO46FxTomfjvq5kb3Ot1YoOCfdZJQiSTiagHbZEQzZ07xh/MfY2b2dFS0ACwRrX5/bwVX5ZS4RO1BrDMENPj2cG7Lq2UDAhgpsf5bKc6BIaR6rizbocSfYvnOzl4B11fxwEubkT5SbRtAUT+LI+Dd8sNcfIUz6mhLTfkAcgKMPQVn5sEXF52S8VtlSjnD3ye0L20qGt/I3B72lTeUZLgM6S4f8We3QDAc8gBsAbrccNG5tAheWHVGwz3COF1j3B3CtvFSYy0N7t72usnl8qkhlWKSFAly5Vz9iChtZO+1dz9uhy7w/zI4c571PTwgF5NEV1ZDIQIehG4vIfZbUndg62NnRbtWZj3p7YjQPCbhrPagu9LrFvaGyRTXOdc3MTpWr+UdKq30xPCX9y565gGZrgTJhq7SsYzcigB9vdM1AndaArCLUjnsVHhlW2NM3JZo8CbihrItMIvD6idaX8uZJ0OuKl0HCwOBkCjXQy7XlnKFQ7hfhrm20tejz2/ZI9SsqbdWpNWutkbdMO4F7aXGq5XnfN8Uc0k3HcO9lhvRha5ivLbR8AAAAABJRU5ErkJggg=='

  const handleChartDownload = () => {
    //console.log("selectedCharts", selectedCharts);
    selectedCharts.forEach((chartId) => {
      const chartElement = document.querySelector(`#chart-${chartId}`)
      if (chartElement) {
        //console.log("good");
        try {
          const plotlyPlot = chartElement.querySelector('.js-plotly-plot')
          if (plotlyPlot) {
            const svgString = plotlyPlot.outerHTML
            //console.log("SVG String for chart", chartId, ":", svgString);

            const blob = new Blob([svgString], { type: 'image/svg+xml' })
            const url = URL.createObjectURL(blob)

            const link = document.createElement('a')
            link.href = url
            link.download = `chart-${chartId}.svg`
            document.body.appendChild(link)
            link.click()

            document.body.removeChild(link)
            URL.revokeObjectURL(url)
          } else {
            //console.error(`Plotly plot not found for chart ${chartId}`);
          }
        } catch (error) {
          //console.error("Error during download for chart", chartId, ":", error);
          // Handle the error (e.g., show an error message)
        }
      } else {
        //console.error(`Chart element not found for chart ${chartId}`);
      }
    })
  }

  const getCurrentDateFormatted = () => {
    const today = new Date()
    const day = String(today.getDate()).padStart(2, '0') // Ensures 2-digit day
    const month = String(today.getMonth() + 1).padStart(2, '0') // Months are zero-based, so +1
    const year = today.getFullYear()

    return `${day}-${month}-${year}`
  }

  // const handlePDF = async () => {
  //   handleClose();
  //   const updatedDate = getCurrentDateFormatted();

  //   // Sample static texts
  //   const introductionText = `
  //     Introduction

  //     This report provides a comprehensive analysis of the data collected over the past quarter. It includes various charts and graphs that illustrate key metrics and trends. The following sections detail the performance indicators and offer insights into the data, with a focus on significant patterns and noteworthy observations.

  //     The charts presented in this report visualize the key performance indicators (KPIs) and other relevant metrics. These visualizations are intended to help stakeholders understand the data more effectively and make informed decisions based on the presented information.
  //   `;

  //   const conclusionText = `
  //     Conclusion

  //     In summary, the data presented in this report highlights several important trends and insights. The charts demonstrate the fluctuations and patterns in the key metrics, offering a clear view of the performance over the past quarter.

  //     Overall, the analysis indicates areas of improvement as well as strengths in the current performance. It is recommended that stakeholders focus on the identified areas for improvement and leverage the strengths to optimize future performance. Further investigation and analysis may be required to address specific concerns and capitalize on opportunities.

  //     Thank you for reviewing this report. Should you have any questions or require additional information, please do not hesitate to reach out.
  //   `;

  //   try {
  //     const excelresponse = await axios.post(API.UPDATE_REPORTS, { id: cardId, updated_at: updatedDate }, { headers: { 'Content-Type': 'application/json' } });

  //     if (excelresponse.status === 200) {
  //       AlertDialog({
  //         type: 'pdf',
  //         title: 'Report saved successfully',
  //         text: 'Do you want to download the reports?',
  //         confirmButtonText: 'Yes',
  //         onConfirm: async () => {
  //           const pdf = new jsPDF('landscape', 'mm', 'a4');

  //           // Initial Y position
  //           let y = 20;

  //           // Function to add header and footer
  //           const addHeaderFooter =async () => {
  //             const base64 = await convertImageToBase64();
  //             // Add Header
  //             pdf.addImage(base64, 'PNG', 140, 10, 30, 30);

  //             // Add Footer
  //             const footerLine1 = "SAINT-GOBAIN PERFORMANCE CERAMICS & REFRACTORIES";
  //             const footerLine2 = "Grindwell Norton LTD, Devanahalli Road, Off Old Madras Road, Bangalore. 560049, India";
  //             const footerLine3 = "E: SteelClubIndia@saint-gobain.com; W: www.ceramicsrefractories.saint-gobain.com; www.heatkingsolutions.com";

  //             const pageHeight = pdf.internal.pageSize.height;
  //             const footerHeight = 15;
  //             const footerY = pageHeight - footerHeight - 10;

  //             pdf.addImage(base64, 'PNG', 60, footerY, 15, 15);

  //             const lineX = 80;
  //             const lineYStart = footerY + 13;
  //             const lineYEnd = pageHeight - 22;

  //             pdf.setLineWidth(0.1);
  //             pdf.line(lineX, lineYStart, lineX, lineYEnd);

  //             pdf.setFontSize(10);
  //             pdf.text(footerLine1, 85, pageHeight - 20);
  //             pdf.text(footerLine2, 85, pageHeight - 15);
  //             pdf.text(footerLine3, 85, pageHeight - 10);
  //           };

  //           // Add Introduction Text
  //           pdf.setFontSize(12);
  //           pdf.text(introductionText, 10, y, { maxWidth: 270 });
  //           y += 30; // Adjust Y position after introduction text

  //           // Add Header and Footer to the first page
  //           addHeaderFooter();

  //           if (selectedCharts.length === 0) {
  //             for (let i = 0; i < charts.length; i++) {
  //               const chart = charts[i];
  //               const data = chart.data;
  //               const layout = { title: chart.title };

  //               if (i > 0) {
  //                 pdf.addPage();
  //                 y = 20; // Reset Y position on new page
  //                 addHeaderFooter();
  //               }

  //               // Create a temporary div for rendering the Plotly chart
  //               const tempDiv = document.createElement('div');
  //               document.body.appendChild(tempDiv);

  //               // Render Plotly chart to the temporary div
  //               await Plotly.newPlot(tempDiv, data, layout);

  //               // Convert Plotly chart to PNG image using toImage function
  //               const imageData = await Plotly.toImage(tempDiv, { format: 'png' });

  //               // Add chart image to PDF
  //               pdf.addImage(imageData, 'PNG', 10, y + 10, 250, 140);

  //               y += 150; // Update Y position after adding chart

  //               // Clean up: remove temporary div from DOM
  //               document.body.removeChild(tempDiv);
  //             }
  //           } else {
  //             for (const chartId of selectedCharts) {
  //               const chart = charts.find(c => c.id === chartId);
  //               if (!chart) {
  //                 //console.warn(`Chart with id ${chartId} not found in charts array.`);
  //                 continue;
  //               }
  //               const data = chart.data;
  //               const layout = { title: chart.title };

  //               pdf.addPage();
  //               y = 20; // Reset Y position on new page
  //               addHeaderFooter();

  //               const tempDiv = document.createElement('div');
  //               document.body.appendChild(tempDiv);

  //               await Plotly.newPlot(tempDiv, data, layout);

  //               const imageData = await Plotly.toImage(tempDiv, { format: 'png' });

  //               pdf.addImage(imageData, 'PNG', 10, y + 10, 250, 140);

  //               y += 150;

  //               document.body.removeChild(tempDiv);
  //             }
  //           }

  //           // Add a new page for Conclusion Text
  //           pdf.addPage();
  //           y = 20; // Reset Y position for Conclusion page
  //           pdf.setFontSize(12);
  //           pdf.text(conclusionText, 10, y, { maxWidth: 270 });

  //           // Add Header and Footer to the conclusion page
  //           addHeaderFooter();

  //           // Save the PDF
  //           pdf.save('combined_charts.pdf');
  //         }
  //       });
  //     } else {
  //       //console.error('Error saving data:', excelresponse.data);
  //       AlertDialog({
  //         type: 'warning',
  //         title: 'Error',
  //         text: 'Failed to save data',
  //       });
  //     }
  //   } catch (error) {
  //     //console.error('Error:', error);
  //     AlertDialog({
  //       type: 'warning',
  //       title: 'Error',
  //       text: 'An unexpected error occurred',
  //     });
  //   }
  // };

  const handlePDF = async (textA, textB) => {
    handleClose()
    handlePDFopen()
    const updatedDate = getCurrentDateFormatted()

    const introductionText = textA
    const conclusionText = textB

    console.log(introductionText, 'introductionText')
    console.log(conclusionText, 'conclusionText')

    // const conclusionText = `
    //   Conclusion

    //   In summary, the data presented in this report highlights several important trends and insights. The charts demonstrate the fluctuations and patterns in the key metrics, offering a clear view of the performance over the past quarter.

    //   Overall, the analysis indicates areas of improvement as well as strengths in the current performance. It is recommended that stakeholders focus on the identified areas for improvement and leverage the strengths to optimize future performance. Further investigation and analysis may be required to address specific concerns and capitalize on opportunities.

    //   Thank you for reviewing this report. Should you have any questions or require additional information, please do not hesitate to reach out.

    //   In summary, the data presented in this report highlights several important trends and insights. The charts demonstrate the fluctuations and patterns in the key metrics, offering a clear view of the performance over the past quarter.

    //   Overall, the analysis indicates areas of improvement as well as strengths in the current performance. It is recommended that stakeholders focus on the identified areas for improvement and leverage the strengths to optimize future performance. Further investigation and analysis may be required to address specific concerns and capitalize on opportunities.

    //   Thank you for reviewing this report. Should you have any questions or require additional information, please do not hesitate to reach out.

    //   In summary, the data presented in this report highlights several important trends and insights. The charts demonstrate the fluctuations and patterns in the key metrics, offering a clear view of the performance over the past quarter.

    //   Overall, the analysis indicates areas of improvement as well as strengths in the current performance. It is recommended that stakeholders focus on the identified areas for improvement and leverage the strengths to optimize future performance. Further investigation and analysis may be required to address specific concerns and capitalize on opportunities.

    //   Thank you for reviewing this report. Should you have any questions or require additional information, please do not hesitate to reach out.
    // `;

    const pdf = new jsPDF('landscape', 'mm', 'a4')
    const pageWidth = 297 // A4 width in mm
    const pageHeight = 210 // A4 height in mm
    const headerHeight = 15
    const footerHeight = 15
    const contentMargin = 10 // Margin for text content
    const lineHeight = 10 // Approximate line height in mm

    // Function to calculate available height on the page
    const calculateAvailableHeight = () => {
      return pageHeight - headerHeight - footerHeight - contentMargin * 2
    }

    // Function to add header and footer to the current page
    const addHeaderFooter = async () => {
      const base64 = await convertImageToBase64()
      pdf.addImage(base64, 'PNG', 140, 3, 30, 30)

      const footerLine1 = 'SAINT-GOBAIN PERFORMANCE CERAMICS & REFRACTORIES'
      const footerLine2 =
        'Grindwell Norton LTD, Devanahalli Road, Off Old Madras Road, Bangalore. 560049, India'
      const footerLine3 =
        'E: SteelClubIndia@saint-gobain.com; W: www.ceramicsrefractories.saint-gobain.com; www.heatkingsolutions.com'

      // const footerY = pageHeight - footerHeight - contentMargin;

      // pdf.addImage(base64, 'PNG', 60, footerY, 15, 15);

      const footerY = pageHeight - footerHeight + 5 // Adjust footer Y to fit better

      pdf.addImage(base64, 'PNG', 60, footerY - 5, 15, 15)

      const lineX = 80
      const lineYStart = footerY - 10
      const lineYEnd = pageHeight - footerHeight + 10

      pdf.setLineWidth(0.1)
      pdf.line(lineX, lineYStart, lineX, lineYEnd)

      pdf.setFontSize(10)

      // Adjust footer text positions to fit within the footer area
      pdf.text(footerLine1, 85, footerY - 10)
      pdf.text(footerLine2, 85, footerY - 5)
      pdf.text(footerLine3, 85, footerY + 5)

      // const lineX = 80;
      // const lineYStart = footerY + 13;
      // const lineYEnd = pageHeight - footerHeight - 10;

      // pdf.setLineWidth(0.1);
      // pdf.line(lineX, lineYStart, lineX, lineYEnd);

      // pdf.setFontSize(10);
      // pdf.text(footerLine1, 85, pageHeight - footerHeight + 5);
      // pdf.text(footerLine2, 85, pageHeight - footerHeight + 10);
      // pdf.text(footerLine3, 85, pageHeight - footerHeight + 15);
    }

    // Function to add text to the PDF with page handling
    const addTextToPDF = async (text, startY) => {
      const lines = pdf.splitTextToSize(text, pageWidth - contentMargin * 2)
      let y = startY

      for (const line of lines) {
        if (y + lineHeight > pageHeight - footerHeight - contentMargin) {
          pdf.addPage()
          await addHeaderFooter()
          y = headerHeight + contentMargin
        }
        pdf.text(line, contentMargin, y)
        y += lineHeight
      }
    }

    // Function to add a chart to the PDF
    const addChartPage = async (chart) => {
      const data = chart.data
      const layout = { title: chart.title }

      pdf.addPage()
      await addHeaderFooter()
      const tempDiv = document.createElement('div')
      document.body.appendChild(tempDiv)

      await Plotly.newPlot(tempDiv, data, layout)
      const imageData = await Plotly.toImage(tempDiv, { format: 'png' })
      const chartHeight = 140
      let y = headerHeight + contentMargin

      if (y + chartHeight > pageHeight - footerHeight - contentMargin) {
        pdf.addPage()
        await addHeaderFooter()
        y = headerHeight + contentMargin
      }

      pdf.addImage(
        imageData,
        'PNG',
        contentMargin,
        y,
        pageWidth - contentMargin * 2,
        chartHeight
      )
      y += chartHeight + 10

      document.body.removeChild(tempDiv)
    }

    try {
      const excelresponse = await axios.post(
        API.UPDATE_REPORTS,
        { id: cardId, updated_at: updatedDate },
        { headers: { 'Content-Type': 'application/json' } }
      )

      if (excelresponse.status === 200) {
        AlertDialog({
          type: 'pdf',
          title: 'Report saved successfully',
          text: 'Do you want to download the reports?',
          confirmButtonText: 'Yes',
          onConfirm: async () => {
            // Add Introduction Text
            await addHeaderFooter()
            await addTextToPDF(introductionText, headerHeight + contentMargin)

            // Add Charts
            if (selectedCharts.length === 0) {
              for (const chart of charts) {
                await addChartPage(chart)
              }
            } else {
              for (const chartId of selectedCharts) {
                const chart = charts.find((c) => c.id === chartId)
                if (chart) {
                  await addChartPage(chart)
                }
              }
            }

            // Add Conclusion Text
            pdf.addPage()
            await addHeaderFooter()
            await addTextToPDF(conclusionText, headerHeight + contentMargin)

            // Save the PDF
            pdf.save('combined_charts.pdf')
          },
        })
      } else {
        //console.error('Error saving data:', excelresponse.data);
        AlertDialog({
          type: 'warning',
          title: 'Error',
          text: 'Failed to save data',
        })
      }
    } catch (error) {
      //console.error('Error:', error);
      AlertDialog({
        type: 'warning',
        title: 'Error',
        text: 'An unexpected error occurred',
      })
    }
  }

  //     const handlePDF = async () => {

  //       // //console.log(`Text A: ${textA}`);
  //       // //console.log(`Text B: ${textB}`);
  //       // //console.log(`Text C: ${textC}`);
  //       handleClose();
  //       const updatedDate = getCurrentDateFormatted();
  //       axios.post(API.UPDATE_REPORTS, {  id: cardId , updated_at: updatedDate}, { headers: { 'Content-Type': 'application/json' } })
  //       .then((excelresponse) => {
  //         if (excelresponse.status === 200) {

  //       AlertDialog({
  //         type: 'pdf',
  //         title: 'Report saved successfully',
  //         text: 'Do you want to download the reports?',
  //         confirmButtonText: 'Yes',
  //         onConfirm: async () => {

  //       //console.log("charts", charts);
  //       //console.log("selectedCharts", selectedCharts);
  //       //console.log("activeChart", activeChart);
  //       const pdf = new jsPDF('landscape', 'mm', 'a4');

  //       const footer = "Page Number: ";

  //       // let currentY = 10; // Initial y-coordinate
  //       // const lineHeight = 70; // Line height for spacing between lines

  //       // // Example usage:
  //       // pdf.text(textA.toString(), 0, currentY);
  //       // currentY += lineHeight; // Move to the next line
  //       // pdf.text(textB.toString(), 10, currentY);
  //       // currentY += lineHeight; // Move to the next line
  //       // pdf.text(textC.toString(), 10, currentY);

  //       if (selectedCharts.length === 0) {
  //         for (let i = 0; i < charts.length; i++) {
  //           const chart = charts[i];
  //           const data = chart.data;
  //           const layout = { title: chart.title };

  //           if (i > 0) {
  //             pdf.addPage();
  //           }

  //           const base64 = await convertImageToBase64(); // Assuming convertImageToBase64 returns a promise
  //           //console.log('Base64 Image:', base64);

  //      const footerLine1 = "SAINT-GOBAIN PERFORMANCE CERAMICS & REFRACTORIES";
  //      const footerLine2 = "Grindwell Norton LTD, Devanahalli Road, Off Old Madras Road, Bangalore. 560049, India";
  //      const footerLine3 = "E: SteelClubIndia@saint-gobain.com; W: www.ceramicsrefractories.saint-gobain.com; www.heatkingsolutions.com";

  //      // Add header to the current page
  //           pdf.addImage(base64, 'PNG', 140, 10, 30, 30);

  //           // Create a temporary div for rendering the Plotly chart
  //           const tempDiv = document.createElement('div');
  //           document.body.appendChild(tempDiv);

  //           // Render Plotly chart to the temporary div
  //           await Plotly.newPlot(tempDiv, data, layout);

  //           // Convert Plotly chart to PNG image using toImage function
  //           const imageData = await Plotly.toImage(tempDiv, { format: 'png' });

  //           // Add image to PDF
  //           pdf.addImage(imageData, 'PNG', 10, 35, 250, 140); // Adjust dimensions and position as needed

  //           // Clean up: remove temporary div from DOM
  //           document.body.removeChild(tempDiv);

  //           pdf.setFontSize(10);
  //           const pageHeight = pdf.internal.pageSize.height;  // Get the height of the page
  //           const footerHeight = 15;  // Assuming the height of your footer image is 15 units

  //           // Position the footer image at the bottom of the page (considering margins)
  //           const footerY = pageHeight - footerHeight - 10;

  //           //console.log('footerY',footerY);// Adjust 10 according to your margins

  //           pdf.addImage(base64, 'PNG', 60, footerY, 15, 15);

  //           const lineX = 80; // X-coordinate for the vertical line
  //           const lineYStart = footerY + 13; // Start Y-coordinate for the vertical line (just below the footer image)
  //           const lineYEnd = pageHeight - 22; // End Y-coordinate for the vertical line (adjust as needed)

  //           // Draw a vertical line
  //           pdf.setLineWidth(0.1); // Set line width
  //           pdf.line(lineX, lineYStart, lineX, lineYEnd);

  //           pdf.text(footerLine1, 85, pdf.internal.pageSize.height - 20);
  //           pdf.text(footerLine2, 85, pdf.internal.pageSize.height - 15);
  //           pdf.text(footerLine3, 85, pdf.internal.pageSize.height - 10);
  //         }

  //       // Save the PDF with all charts
  //       pdf.save('combined_charts.pdf');
  //     }else{

  //       for (const chartId of selectedCharts) {
  //         // Find the chart object from charts array based on chartId
  //         const chart = charts.find(c => c.id === chartId);
  //         if (!chart) {
  //           //console.warn(`Chart with id ${chartId} not found in charts array.`);
  //           continue;
  //         }
  //         const data = chart.data;
  //         const layout = { title: chart.title };

  //         // Create a temporary div for rendering the Plotly chart
  //         const tempDiv = document.createElement('div');
  //         document.body.appendChild(tempDiv);

  //         // Render Plotly chart to the temporary div
  //         await Plotly.newPlot(tempDiv, data, layout);

  //         // Convert Plotly chart to PNG image using toImage function
  //         const imageData = await Plotly.toImage(tempDiv, { format: 'png' });

  //         // Add image to PDF
  //         pdf.addImage(imageData, 'PNG', 10, 10, 280, 150); // Adjust dimensions and position as needed

  //         // Add a new page for the next chart
  //         pdf.addPage();

  //         // Clean up: remove temporary div from DOM
  //         document.body.removeChild(tempDiv);
  //       }

  //       pdf.save('combined_charts.pdf');
  //     }

  //   }

  // });
  // } else {
  //   //console.error('Error saving data:', excelresponse.data);
  //   AlertDialog({
  //     type: 'warning',
  //     title: 'Error',
  //     text: 'Failed to save data',
  //   });
  // }
  // })
  //   };

  const handleChartDelete = () => {
    if (selectedCharts.length === 0) {
      alert('Please select at least one chart to delete.')
    } else {
      // Log the selected charts for debugging
      //console.log("Selected Charts for Deletion:", selectedCharts);

      // Confirm the deletion with the user
      AlertDialog({
        type: 'delete',
        title: 'Are you sure?',
        text: 'Are you sure you want to delete?',
        confirmButtonText: 'Yes',
        onConfirm: async () => {
          // Create a new array excluding the selected charts
          const updatedCharts = charts.filter(
            (chart) => !selectedCharts.includes(chart.id)
          )

          setCharts(updatedCharts)
          // Send the updated charts list to the server
          axios
            .post(
              API.UPDATE_GRAPH,
              {
                reports_id: cardId,
                standard_graphs: updatedCharts,
                template_id: templateId,
              },
              {
                headers: { 'Content-Type': 'application/json' },
              }
            )
            .then((response) => {
              if (response.status === 200) {
                // Show success dialog
                AlertDialog({
                  type: 'success',
                  title: 'Success',
                  text: 'Deleted Successfully!',
                  confirmButtonText: 'Ok',
                  onConfirm: async () => {
                    window.location.reload()
                  },
                })
              } else {
                // Handle server-side errors
                //console.error('Error deleting data:', response.data);
                AlertDialog({
                  type: 'warning',
                  title: 'Error',
                  text: 'Failed to save data',
                })
              }
            })
            .catch((error) => {
              // Handle client-side or network errors
              //console.error('Error in API request:', error);
              AlertDialog({
                type: 'warning',
                title: 'Error',
                text: 'An error occurred during deletion',
              })
            })

          // Clear the selection
          setSelectedCharts([])
        },
      })
      // const confirmed = window.confirm("Are you sure you want to delete?");

      // if (!confirmed) {
      //   return; // User canceled the deletion
      // }
    }
  }

  const handleChartCheckboxChange = (chartId) => {
    setSelectedCharts((prevSelectedCharts) =>
      prevSelectedCharts.includes(chartId)
        ? prevSelectedCharts.filter((id) => id !== chartId)
        : [...prevSelectedCharts, chartId]
    )
    //console.log("selectedCharts", selectedCharts);
  }

  const handleChartButtonClick = (chartId) => {
    setActiveChart(chartId)
  }

  const handleHeatmapClose = () => {
    setHeatmapModalOpen(false)
  }

  const handleHeatmapSelect = (value) => {
    setHeatmapSelection(value)
    //console.log(heatmapSelection,'heatmapSelection');
    setHeatmapModalOpen(false)
  }

  const restrictedMetrics = [
    'first_value',
    'last_value',
    'minimum',
    'maximum',
    'total',
    'mean',
    'count',
    'quartiles',
    'correlation',
  ]

  // Determine available graph types based on selected metric
  const getAvailableGraphTypes = (selectedMetric) => {
    switch (selectedMetric) {
      case 'first_value':
      case 'last_value':
      case 'minimum':
      case 'maximum':
      case 'total':
      case 'mean':
      case 'count':
        return [
          'scatter',
          'bar',
          'line',
          'horizontal-bar',
          'stacked-bar',
          'pie',
        ]
      case 'quartiles':
        return ['box']
      case 'correlation':
        return ['heatmap']
      default:
        return [
          'scatter',
          'bar',
          'box',
          'line',
          'horizontal-bar',
          'stacked-bar',
          'pie',
          'heatmap',
        ]
    }
  }

  const availableGraphTypes = getAvailableGraphTypes(metric)

  const handleClose = () => {
    setModalOpen(false)
  }

  const handlePDFopen = () => {
    setModalOpen(true)
  }

  const [textA, setTextA] = useState('')
  const [textB, setTextB] = useState('')
  const [textC, setTextC] = useState('')

  const maxLengths = {
    A: 500,
    B: 1000,
    C: 1500,
  }

  const handleChangeTextA = (event) => {
    if (event.target.value.length <= maxLengths.A) {
      setTextA(event.target.value)
    }
  }

  const handleChangeTextB = (event) => {
    if (event.target.value.length <= maxLengths.B) {
      setTextB(event.target.value)
    }
  }

  const handleChangeTextC = (event) => {
    if (event.target.value.length <= maxLengths.C) {
      setTextC(event.target.value)
    }
  }

  const getModalContent = () => {
    if (tab === 'Daily Trial Monitoring') {
      return (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="heatmap-modal-title" variant="h6" component="h2">
            Select Heatmap Option
          </Typography>
          <Select
            labelId="heatmap-select-label"
            id="heatmap-select"
            value={heatmapSelection}
            onChange={(event) => handleHeatmapSelect(event.target.value)}
            sx={{ width: '100%', mt: 2 }}
          >
            <MenuItem value="total_power_consumed">
              Total Power Consumed
            </MenuItem>
            <MenuItem value="tapped_tonnage">Tapped Tonnage</MenuItem>
            <MenuItem value="energy_consumption_per_unit_ton_actual">
              Energy Consumption Per Unit Ton Actual
            </MenuItem>
            <MenuItem value="energy_consumption_per_unit_ton_calculated">
              Energy Consumption Per Unit Ton Calculated
            </MenuItem>
            <MenuItem value="heat_no">Heat No</MenuItem>
          </Select>
        </Box>
      )
    } else if (tab === 'NRM Performance') {
      return (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography
            id="nrm-performance-modal-title"
            variant="h6"
            component="h2"
          >
            NRM Performance Options
          </Typography>
          <Select
            labelId="nrm-performance-select-label"
            id="nrm-performance-select"
            value={heatmapSelection}
            onChange={(event) => handleHeatmapSelect(event.target.value)}
            sx={{ width: '100%', mt: 2 }}
          >
            <MenuItem value="total_furnace_running_hours">
              Total Furnace Running Hours
            </MenuItem>
            <MenuItem value="total_nrm_consumption_sidewall_one">
              Total NRM consumption sidewall
            </MenuItem>
            <MenuItem value="nrm_specific_consumption">
              NRM Specific consumption
            </MenuItem>
            <MenuItem value="holding_time">Holding Time</MenuItem>
            <MenuItem value="holding_temp">Holding Temp</MenuItem>
          </Select>
        </Box>
      )
    }
  }

  return (
    <div>
      <div>
        <TableContainer>
          <Grid
            container
            spacing={2}
            sx={{
              border: '1px solid #E5E9EB',
              padding: '12px 10px 10px 10px',
              background: 'white',
            }}
          >
            <Grid item xs={10}>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  fontFamily: 'inter',
                  padding: '5px',
                }}
              >
                Reports Data List
              </Typography>
            </Grid>
            <Grid
              item
              xs={2}
              container
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                onClick={handlePDF}
                style={{
                  fontFamily: 'inter',
                  fontSize: 16,
                  fontWeight: 500,
                  marginRight: '15px',
                  background: '#0086DC',
                  color: '#ffffff',
                }}
              >
                GENERATE REPORT
              </Button>
            </Grid>
            <Grid item>
              <Modal
                open={modalOpen} // Use modalOpen state to control modal open/close
                onClose={handleClose}
                aria-labelledby="save-pdf-modal-title"
                aria-describedby="save-pdf-modal-description"
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '80%', // Adjusted width to be 80% of the viewport width
                    maxWidth: '800px', // Maximum width for the modal
                    maxHeight: '80%', // Maximum height for the modal
                    overflowY: 'auto', // Enable vertical scrollbar when content exceeds modal height
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '10px',
                    }}
                  >
                    <Typography
                      id="save-pdf-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      Enter Text for PDF
                    </Typography>
                    <IconButton onClick={handleClose} sx={{ p: '10px' }}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <TextField
                    id="textA"
                    label="Background"
                    multiline
                    rows={8} // Increased rows to 8 for larger input area
                    variant="outlined"
                    value={textA}
                    onChange={handleChangeTextA}
                    inputProps={{ maxLength: maxLengths.A }}
                    fullWidth
                    helperText={`${textA.length}/${maxLengths.A} characters`}
                    sx={{ marginBottom: '10px' }}
                  />
                  <TextField
                    id="textB"
                    label="Summary"
                    multiline
                    rows={8} // Increased rows to 8 for larger input area
                    variant="outlined"
                    value={textB}
                    onChange={handleChangeTextB}
                    inputProps={{ maxLength: maxLengths.B }}
                    fullWidth
                    helperText={`${textB.length}/${maxLengths.B} characters`}
                    sx={{ marginBottom: '10px' }}
                  />
                  <Button
                    onClick={() => {
                      handlePDF(textA, textB) // Pass textA and textB to handlePDF
                      handleClose() // Close the modal after saving
                    }}
                    style={{
                      fontFamily: 'inter',
                      fontSize: 16,
                      fontWeight: 500,
                      background: '#0086DC',
                      color: '#ffffff',
                      marginTop: '10px',
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </Modal>

              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                aria-label="Tabs"
                indicatorColor="primary"
                TabIndicatorProps={{ style: { width: '150px' } }}
              >
                {tabs.map((tabb) => (
                  <Tab
                    key={tabb.value}
                    label={tabb.label}
                    value={tabb.label} // Set the value of the tab to its label for comparison
                    disabled={tabb.label !== selectedTab} // Disable tabs other than the selected one
                  />
                ))}
              </Tabs>
            </Grid>
          </Grid>
        </TableContainer>
      </div>

      <div className="app-container" style={{ background: 'white' }}>
        <div className="chart-list">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              style={{
                fontSize: 16,
                fontWeight: 600,
                display: 'flex',
                flex: 4,
              }}
            >
              Standard Charts
            </Typography>
            <Button
              style={{
                background: '#0086DC',
                color: '#ffffff',
                borderRadius: 6,
                padding: '4px 16px',
                width: '120px',
              }}
              onClick={handleModalOpen}
            >
              Add Graph
            </Button>
            <div style={{ background: 'none', display: 'flex', flex: 1 }}>
              {/* <IconButton onClick={handleDownload} style={{background: "none",  marginBottom: "-2px"}}>
        <DownloadIcon />
      </IconButton> */}
              {/* <div style={{ background: "none",  display: 'flex', flex: 1 }}> */}
              <IconButton
                onClick={handleChartDelete}
                style={{ background: 'none', marginBottom: '-2px' }}
              >
                <Delete />
              </IconButton>
              {/* </div>  */}
            </div>
          </div>

          <div>
            {charts.map((chart) => (
              <div
                key={chart.id}
                className="chart-list-item"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <input
                  type="checkbox"
                  checked={selectedCharts.includes(chart.id)}
                  onChange={() => handleChartCheckboxChange(chart.id)}
                  style={{
                    marginRight: 8,
                    transform: 'scale(1.5)',
                    padding: '6px 6px', // Optional: Adds space between checkbox and button
                  }}
                />
                <Button
                  onClick={() => handleChartButtonClick(chart.id)}
                  style={{
                    background: chart.id === activeChart ? '#0086DC1A' : 'none',
                    border: 'none',
                    fontSize: 16,
                    fontWeight: 400,
                    color: '#252C32',
                    marginTop: 1,
                  }}
                >
                  {/* <span style={{ marginRight: 8 }}>{index + 1}.</span> */}
                  {chart.title}
                </Button>
              </div>
            ))}
          </div>
        </div>

        <div
          className="chart-display"
          style={{
            maxHeight: '900px',
            overflowY: 'auto',
            background: '#EFF0F2',
          }}
        >
          {selectedCharts.map((chartId) => {
            const chart = charts.find((chart) => chart.id === chartId)
            return (
              <div
                key={chartId}
                style={{
                  position: 'relative',
                  marginBottom: '20px',
                  minWidth: '550px',
                }}
              >
                <Plot
                  data={chart.data}
                  layout={{
                    title: chart.title,
                    yaxis: { title: 'Values' },
                    width: 1265,
                    height: 800,
                  }}
                />
                <div style={{ marginTop: '10px' }}>
                  <TextField
                    id="textA"
                    label="COMMENTS"
                    multiline
                    rows={8} // Increased rows to 8 for larger input area
                    variant="outlined"
                    value={textA} // Assuming `textA` is related to the background text for each chart
                    onChange={handleChangeTextA}
                    inputProps={{ maxLength: maxLengths.A }}
                    fullWidth
                    helperText={`${textA.length}/${maxLengths.A} characters`}
                    sx={{ marginBottom: '10px' }}
                  />
                </div>
              </div>
            )
          })}

          {activeChart && !selectedCharts.includes(activeChart) && (
            <div
              key={activeChart}
              style={{ position: 'relative', marginBottom: '20px' }}
            >
              {charts.find((chart) => chart.id === activeChart) && (
                <Plot
                  data={charts.find((chart) => chart.id === activeChart).data}
                  layout={{
                    title: charts.find((chart) => chart.id === activeChart)
                      .title,
                    width: 1265,
                    height: 800,
                  }}
                />
              )}
              <div style={{ marginTop: '10px' }}>
                <TextField
                  id="textA"
                  label="COMMENTS"
                  multiline
                  rows={8} // Increased rows to 8 for larger input area
                  variant="outlined"
                  value={textA} // Assuming `textA` is related to the background text for each chart
                  onChange={handleChangeTextA}
                  inputProps={{ maxLength: maxLengths.A }}
                  fullWidth
                  helperText={`${textA.length}/${maxLengths.A} characters`}
                  sx={{ marginBottom: '10px' }}
                />
              </div>
            </div>
          )}
        </div>

        <Modal
          open={showModal}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              width: 600,
              maxHeight: '90vh',
              overflowY: 'auto',
              padding: '16px',
              borderRadius: 3,
              background: 'white',
              border: 'none',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '16px',
              }}
            >
              <Typography sx={{ flex: 1, fontSize: 20, fontWeight: 600 }}>
                Add Graph
              </Typography>
              <IconButton onClick={handleModalClose}>
                <CloseIcon />
              </IconButton>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '16px',
                padding: '10px 10px',
              }}
            >
              <div style={{ marginBottom: '16px', flex: '1' }}>
                <Typography
                  style={{ fontSize: 16, fontWeight: 600, color: '#2D2B32' }}
                >
                  Yaxis Metric *
                </Typography>
                <Select
                  id="metricSelect"
                  value={metric}
                  onChange={handleMetricChange}
                  sx={{ width: '100%', minWidth: 200, borderRadius: 2 }} // Adjust width and minWidth as needed
                >
                  <MenuItem value="Visualization">Visualization</MenuItem>
                  <MenuItem value="first_value">First Value</MenuItem>
                  <MenuItem value="last_value">Last Value</MenuItem>
                  <MenuItem value="minimum">Minimum</MenuItem>
                  <MenuItem value="maximum">Maximum</MenuItem>
                  <MenuItem value="total">Total</MenuItem>
                  <MenuItem value="mean">Mean</MenuItem>
                  <MenuItem value="count">Count</MenuItem>
                  <MenuItem value="quartiles">Quartiles</MenuItem>
                  <MenuItem value="correlation">Correlation</MenuItem>
                </Select>
              </div>
              <div style={{ marginBottom: '16px', flex: '1' }}>
                <Typography
                  style={{ fontSize: 16, fontWeight: 600, color: '#2D2B32' }}
                >
                  Graph Type *
                </Typography>
                <Select
                  id="graphTypeSelect"
                  value={graphType}
                  onChange={handleGraphTypeChange}
                  sx={{ width: '100%', minWidth: 200, borderRadius: 2 }} // Adjust width and minWidth as needed
                >
                  {availableGraphTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type.charAt(0).toUpperCase() +
                        type.slice(1).replace('-', ' ')}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>

            {tab === 'Daily Trial Monitoring' && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '16px',
                  padding: '10px 10px',
                }}
              >
                <div style={{ marginRight: '16px', flex: 1 }}>
                  <div>
                    <Typography
                      style={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: '#2D2B32',
                      }}
                    >
                      X-Axis *
                    </Typography>
                    <FormControl sx={{ width: '100%' }}>
                      <Select
                        id="x-axis-select"
                        value={xAxis}
                        onChange={handleXAxisChange}
                        sx={{ width: '100%', borderRadius: 2 }} // Adjust minWidth as needed
                        disabled={graphType === 'heatmap'}
                      >
                        <MenuItem value="lorp_id">LORP ID</MenuItem>
                        <MenuItem value="furnace_no">Furnace No</MenuItem>
                        <MenuItem value="campaign_no">Campaign No</MenuItem>
                        <MenuItem value="crucible_no">Crucible No</MenuItem>
                        <MenuItem value="heat_on_time">Heat On Time</MenuItem>
                        <MenuItem value="heat_tap_time">Heat Tap Time</MenuItem>
                        <MenuItem value="capacitors_removed">
                          Capacitors Removed
                        </MenuItem>
                        <MenuItem value="cr_before_alloy_addition">
                          Cr Before Alloy Addition
                        </MenuItem>
                        <MenuItem value="eightyfive_c_percentage">85%</MenuItem>
                        <MenuItem value="energy_consumption_per_unit_ton_actual">
                          Energy Consumption Per Unit Ton Actual
                        </MenuItem>
                        <MenuItem value="energy_consumption_per_unit_ton_calculated">
                          Energy Consumption Per Unit Ton Calculated
                        </MenuItem>
                        <MenuItem value="feCr">FeCr</MenuItem>
                        <MenuItem value="feMn">FeMn</MenuItem>
                        <MenuItem value="feSi">FeSi</MenuItem>
                        <MenuItem value="fines_lessthan_1mm">
                          Fines Lessthan 1mm
                        </MenuItem>
                        <MenuItem value="fines_lessthan_5mm">
                          Fines Lessthan 5mm
                        </MenuItem>
                        <MenuItem value="frequency">Frequency</MenuItem>
                        <MenuItem value="heat_duration_actual">
                          Heat Duration Actual
                        </MenuItem>
                        <MenuItem value="heat_duration_calculated">
                          Heat Duration Calculated
                        </MenuItem>
                        <MenuItem value="heat_no">Heat No</MenuItem>
                        <MenuItem value="hot_heel">Hot Heel</MenuItem>
                        <MenuItem value="inlet_water_temp">
                          Inlet Water Temp
                        </MenuItem>
                        <MenuItem value="lump">Lump</MenuItem>
                        <MenuItem value="melt_rate_based_on_actual_duration">
                          Melt Rate Based On Actual Duration
                        </MenuItem>
                        <MenuItem value="melt_rate_based_on_calculated_duration">
                          Melt Rate Based On Calculated Duration
                        </MenuItem>
                        <MenuItem value="met_coke">Met Coke</MenuItem>
                        <MenuItem value="mill_scale">Mill Scale</MenuItem>
                        <MenuItem value="mn_before_alloy_addition">
                          Mn Before Alloy Addition
                        </MenuItem>
                        <MenuItem value="mn_in_simn">Mn In Simn</MenuItem>
                        <MenuItem value="outlet_water_temp">
                          Outlet Water Temp
                        </MenuItem>
                        <MenuItem value="pellet">Pellet</MenuItem>
                        <MenuItem value="pig_iron">Pig Iron</MenuItem>
                        <MenuItem value="pooled_iron">Pooled Iron</MenuItem>
                        <MenuItem value="power_in_the_panel">
                          Power In The Panel
                        </MenuItem>
                        <MenuItem value="scrap">Scrap</MenuItem>
                        <MenuItem value="shredded">Shredded</MenuItem>
                        <MenuItem value="si_before_alloy_addition">
                          Si Before Alloy Addition
                        </MenuItem>
                        <MenuItem value="si_mn">Si Mn</MenuItem>
                        <MenuItem value="slag_crushers">Slag Crushers</MenuItem>
                        <MenuItem value="tapped_tonnage">
                          Tapped Tonnage
                        </MenuItem>
                        <MenuItem value="tapping_temperature">
                          Tapping Temperature
                        </MenuItem>
                        <MenuItem value="total_charge">Total Charge</MenuItem>
                        <MenuItem value="total_power_consumed">
                          Total Power Consumed
                        </MenuItem>
                        <MenuItem value="tundish_or_slag_or_metalic_jam">
                          Tundish Or Slag Or Metalic Jam
                        </MenuItem>
                        <MenuItem value="voltage">Voltage</MenuItem>
                        <MenuItem value="water_pr">Water PR</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div style={{ flex: 1 }}>
                  <div>
                    <Typography
                      style={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: '#2D2B32',
                      }}
                    >
                      Y-Axis *
                    </Typography>
                    <FormControl
                      sx={{ width: '100%', minWidth: 200 }}
                      error={!!warning}
                    >
                      <Select
                        labelId="y-axis-label"
                        id="y-axis-select"
                        multiple
                        value={yAxis}
                        onChange={handleYAxisChange}
                        renderValue={(selected) => selected.join(', ')}
                        sx={{ width: '100%', borderRadius: 2 }} // Adjust minWidth as needed
                      >
                        <MenuItem value="furnace_no">Furnace No</MenuItem>
                        <MenuItem value="campaign_no">Campaign No</MenuItem>
                        <MenuItem value="crucible_no">Crucible No</MenuItem>
                        <MenuItem value="heat_on_time">Heat On Time</MenuItem>
                        <MenuItem value="heat_tap_time">Heat Tap Time</MenuItem>
                        <MenuItem value="capacitors_removed">
                          Capacitors Removed
                        </MenuItem>
                        <MenuItem value="cr_before_alloy_addition">
                          Cr Before Alloy Addition
                        </MenuItem>
                        <MenuItem value="eightyfive_c_percentage">85%</MenuItem>
                        <MenuItem value="energy_consumption_per_unit_ton_actual">
                          Energy Consumption Per Unit Ton Actual
                        </MenuItem>
                        <MenuItem value="energy_consumption_per_unit_ton_calculated">
                          Energy Consumption Per Unit Ton Calculated
                        </MenuItem>
                        <MenuItem value="feCr">FeCr</MenuItem>
                        <MenuItem value="feMn">FeMn</MenuItem>
                        <MenuItem value="feSi">FeSi</MenuItem>
                        <MenuItem value="fines_lessthan_1mm">
                          Fines Lessthan 1mm
                        </MenuItem>
                        <MenuItem value="fines_lessthan_5mm">
                          Fines Lessthan 5mm
                        </MenuItem>
                        <MenuItem value="frequency">Frequency</MenuItem>
                        <MenuItem value="heat_duration_actual">
                          Heat Duration Actual
                        </MenuItem>
                        <MenuItem value="heat_duration_calculated">
                          Heat Duration Calculated
                        </MenuItem>
                        <MenuItem value="heat_no">Heat No</MenuItem>
                        <MenuItem value="hot_heel">Hot Heel</MenuItem>
                        <MenuItem value="inlet_water_temp">
                          Inlet Water Temp
                        </MenuItem>
                        <MenuItem value="lump">Lump</MenuItem>
                        <MenuItem value="melt_rate_based_on_actual_duration">
                          Melt Rate Based On Actual Duration
                        </MenuItem>
                        <MenuItem value="melt_rate_based_on_calculated_duration">
                          Melt Rate Based On Calculated Duration
                        </MenuItem>
                        <MenuItem value="met_coke">Met Coke</MenuItem>
                        <MenuItem value="mill_scale">Mill Scale</MenuItem>
                        <MenuItem value="mn_before_alloy_addition">
                          Mn Before Alloy Addition
                        </MenuItem>
                        <MenuItem value="mn_in_simn">Mn In Simn</MenuItem>
                        <MenuItem value="outlet_water_temp">
                          Outlet Water Temp
                        </MenuItem>
                        <MenuItem value="pellet">Pellet</MenuItem>
                        <MenuItem value="pig_iron">Pig Iron</MenuItem>
                        <MenuItem value="pooled_iron">Pooled Iron</MenuItem>
                        <MenuItem value="power_in_the_panel">
                          Power In The Panel
                        </MenuItem>
                        <MenuItem value="scrap">Scrap</MenuItem>
                        <MenuItem value="shredded">Shredded</MenuItem>
                        <MenuItem value="si_before_alloy_addition">
                          Si Before Alloy Addition
                        </MenuItem>
                        <MenuItem value="si_mn">Si Mn</MenuItem>
                        <MenuItem value="slag_crushers">Slag Crushers</MenuItem>
                        <MenuItem value="tapped_tonnage">
                          Tapped Tonnage
                        </MenuItem>
                        <MenuItem value="tapping_temperature">
                          Tapping Temperature
                        </MenuItem>
                        <MenuItem value="total_charge">Total Charge</MenuItem>
                        <MenuItem value="total_power_consumed">
                          Total Power Consumed
                        </MenuItem>
                        <MenuItem value="tundish_or_slag_or_metalic_jam">
                          Tundish Or Slag Or Metalic Jam
                        </MenuItem>
                        <MenuItem value="voltage">Voltage</MenuItem>
                        <MenuItem value="water_pr">Water PR</MenuItem>
                      </Select>
                      {warning && <FormHelperText>{warning}</FormHelperText>}
                    </FormControl>
                  </div>
                </div>
              </div>
            )}
            {tab === 'NRM Performance' && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '16px',
                  padding: '10px 10px',
                }}
              >
                <div style={{ marginRight: '16px', flex: 1 }}>
                  <div>
                    <Typography
                      style={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: '#2D2B32',
                      }}
                    >
                      X-Axis *
                    </Typography>
                    <FormControl sx={{ width: '100%' }}>
                      <Select
                        id="x-axis-select"
                        value={xAxis}
                        onChange={handleXAxisChange}
                        sx={{ width: '100%', borderRadius: 2 }} // Adjust minWidth as needed
                        disabled={graphType === 'heatmap'}
                      >
                        <MenuItem value="lorp_id">LORP ID</MenuItem>
                        <MenuItem value="furnace_no">Furnace No</MenuItem>
                        <MenuItem value="campaign_no">Campaign No</MenuItem>
                        <MenuItem value="crucible_no">Crucible No</MenuItem>
                        <MenuItem value="lining_or_patching">
                          Lining or Patching
                        </MenuItem>
                        <MenuItem value="date_of_completion_lining_or_patching">
                          Date of Completion lining or Patching
                        </MenuItem>
                        <MenuItem value="fce_prod_start_date">
                          Fce prod start date
                        </MenuItem>
                        <MenuItem value="fce_prod_start_time">
                          Fce prod start time
                        </MenuItem>
                        <MenuItem value="fce_prod_down_date">
                          Fce prod down date
                        </MenuItem>
                        <MenuItem value="fce_prod_down_time">
                          Fce prod down time
                        </MenuItem>
                        <MenuItem value="life">Life</MenuItem>
                        <MenuItem value="average_heat_size">
                          Average Heat size
                        </MenuItem>
                        <MenuItem value="total_furnace_running_hours">
                          Total Furnace Running Hours
                        </MenuItem>
                        <MenuItem value="total_production">
                          Total Production
                        </MenuItem>
                        <MenuItem value="total_furnace_running_hours">
                          Total Furnace Running Hours
                        </MenuItem>
                        <MenuItem value="bottom_product">
                          Bottom Produc
                        </MenuItem>
                        <MenuItem value="sidewall_product_one">
                          Sidewall product 1
                        </MenuItem>
                        <MenuItem value="sidewall_product_two">
                          Sidewall product 2
                        </MenuItem>
                        <MenuItem value="top_locking_product">
                          Top Locking product
                        </MenuItem>
                        <MenuItem value="topping_mass_product">
                          Topping Mass Product
                        </MenuItem>
                        <MenuItem value="sidewall_one_coverage_upto_x">
                          Side wall 1 Coverage upto X%
                        </MenuItem>
                        <MenuItem value="sidewall_two_coverage_upto_100">
                          Side wall 2 Coverage upto 100%
                        </MenuItem>
                        <MenuItem value="nrm_consumption_bottom">
                          NRM consumption bottom
                        </MenuItem>
                        <MenuItem value="nrm_consumption_sidewall_one">
                          NRM consumption Sidewall 1
                        </MenuItem>
                        <MenuItem value="nrm_consumption_sidewall_two">
                          NRM consumption Sidewall 2
                        </MenuItem>
                        <MenuItem value="nrm_consumption_top_locking">
                          NRM consumption at Top Locking
                        </MenuItem>
                        <MenuItem value="topping_mass_consumption">
                          Topping mass consumption
                        </MenuItem>
                        <MenuItem value="total_nrm_consumption_sidewall_one">
                          Total NRM consumption sidewall
                        </MenuItem>
                        <MenuItem value="nrm_specific_consumption">
                          NRM Specific consumption
                        </MenuItem>
                        <MenuItem value="former_dimension">
                          Former Dimension
                        </MenuItem>
                        <MenuItem value="coil_coat_id">Coil coat ID</MenuItem>
                        <MenuItem value="fce_ht">Fce Ht.</MenuItem>
                        <MenuItem value="costable_top_ht">
                          Castable top Ht.
                        </MenuItem>
                        <MenuItem value="bottom_ht">Bottom ht.</MenuItem>
                        <MenuItem value="total_sintering_time">
                          Total Sintering time
                        </MenuItem>
                        <MenuItem value="holding_time">holding time</MenuItem>
                        <MenuItem value="holding_temp">holding temp</MenuItem>
                        <MenuItem value="IR_no">IR no.</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div style={{ flex: 1 }}>
                  <div>
                    <Typography
                      style={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: '#2D2B32',
                      }}
                    >
                      Y-Axis *
                    </Typography>
                    <FormControl
                      sx={{ width: '100%', minWidth: 200 }}
                      error={!!warning}
                    >
                      <Select
                        labelId="y-axis-label"
                        id="y-axis-select"
                        multiple
                        value={yAxis}
                        onChange={handleYAxisChange}
                        renderValue={(selected) => selected.join(', ')}
                        sx={{ width: '100%', borderRadius: 2 }} // Adjust minWidth as needed
                      >
                        <MenuItem value="lorp_id">LORP ID</MenuItem>
                        <MenuItem value="furnace_no">Furnace No</MenuItem>
                        <MenuItem value="campaign_no">Campaign No</MenuItem>
                        <MenuItem value="crucible_no">Crucible No</MenuItem>
                        <MenuItem value="lining_or_patching">
                          Lining or Patching
                        </MenuItem>
                        <MenuItem value="date_of_completion_lining_or_patching">
                          Date of Completion lining or Patching
                        </MenuItem>
                        <MenuItem value="fce_prod_start_date">
                          Fce prod start date
                        </MenuItem>
                        <MenuItem value="fce_prod_start_time">
                          Fce prod start time
                        </MenuItem>
                        <MenuItem value="fce_prod_down_date">
                          Fce prod down date
                        </MenuItem>
                        <MenuItem value="fce_prod_down_time">
                          Fce prod down time
                        </MenuItem>
                        <MenuItem value="life">Life</MenuItem>
                        <MenuItem value="average_heat_size">
                          Average Heat size
                        </MenuItem>
                        <MenuItem value="total_furnace_running_hours">
                          Total Furnace Running Hours
                        </MenuItem>
                        <MenuItem value="total_production">
                          Total Production
                        </MenuItem>
                        <MenuItem value="total_furnace_running_hours">
                          Total Furnace Running Hours
                        </MenuItem>
                        <MenuItem value="bottom_product">
                          Bottom Produc
                        </MenuItem>
                        <MenuItem value="sidewall_product_one">
                          Sidewall product 1
                        </MenuItem>
                        <MenuItem value="sidewall_product_two">
                          Sidewall product 2
                        </MenuItem>
                        <MenuItem value="top_locking_product">
                          Top Locking product
                        </MenuItem>
                        <MenuItem value="topping_mass_product">
                          Topping Mass Product
                        </MenuItem>
                        <MenuItem value="sidewall_one_coverage_upto_x">
                          Side wall 1 Coverage upto X%
                        </MenuItem>
                        <MenuItem value="sidewall_two_coverage_upto_100">
                          Side wall 2 Coverage upto 100%
                        </MenuItem>
                        <MenuItem value="nrm_consumption_bottom">
                          NRM consumption bottom
                        </MenuItem>
                        <MenuItem value="nrm_consumption_sidewall_one">
                          NRM consumption Sidewall 1
                        </MenuItem>
                        <MenuItem value="nrm_consumption_sidewall_two">
                          NRM consumption Sidewall 2
                        </MenuItem>
                        <MenuItem value="nrm_consumption_top_locking">
                          NRM consumption at Top Locking
                        </MenuItem>
                        <MenuItem value="topping_mass_consumption">
                          Topping mass consumption
                        </MenuItem>
                        <MenuItem value="total_nrm_consumption_sidewall_one">
                          Total NRM consumption sidewall
                        </MenuItem>
                        <MenuItem value="nrm_specific_consumption">
                          NRM Specific consumption
                        </MenuItem>
                        <MenuItem value="former_dimension">
                          Former Dimension
                        </MenuItem>
                        <MenuItem value="coil_coat_id">Coil coat ID</MenuItem>
                        <MenuItem value="fce_ht">Fce Ht.</MenuItem>
                        <MenuItem value="costable_top_ht">
                          Castable top Ht.
                        </MenuItem>
                        <MenuItem value="bottom_ht">Bottom ht.</MenuItem>
                        <MenuItem value="total_sintering_time">
                          Total Sintering time
                        </MenuItem>
                        <MenuItem value="holding_time">holding time</MenuItem>
                        <MenuItem value="holding_temp">holding temp</MenuItem>
                        <MenuItem value="IR_no">IR no.</MenuItem>
                      </Select>
                      {warning && <FormHelperText>{warning}</FormHelperText>}
                    </FormControl>
                  </div>
                </div>
              </div>
            )}
            <Modal
              open={heatmapModalOpen}
              onClose={handleHeatmapClose}
              aria-labelledby="heatmap-modal-title"
              aria-describedby="heatmap-modal-description"
            >
              {getModalContent()}
            </Modal>

            <div
              style={{ marginBottom: '16px', flex: 1, padding: '10px 10px' }}
            >
              <div>
                <label
                  htmlFor="notes-textarea"
                  style={{ fontSize: 16, fontWeight: 600, color: '#2D2B32' }}
                >
                  Notes
                </label>
                <textarea
                  id="notes-textarea"
                  value={notes}
                  onChange={handleNotesChange}
                  style={{
                    width: '100%',
                    padding: '8px',
                    borderRadius: 2,
                    border: '1px solid #ccc',
                    boxSizing: 'border-box',
                    minHeight: '100px',
                  }}
                />
              </div>
            </div>

            <div
              style={{ display: 'flex', width: '100%', padding: '10px 10px' }}
            >
              <Button
                onClick={handleModalClose}
                variant="outlined"
                sx={{
                  flex: 1,
                  marginRight: '25px',
                  borderRadius: 2,
                  padding: '10px 10px',
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleAddGraph}
                variant="contained"
                color="primary"
                sx={{ flex: 1, borderRadius: 2, padding: '10px 10px' }}
              >
                Add
              </Button>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  )
}

export default GraphComponent
