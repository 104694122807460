import React, { useEffect } from 'react'
import TextBox from '../../input/TextBox'
import AutoSelectBox from '../../input/AutoSelectBox'
import Typography from '@mui/material/Typography'
import Helper from '../../services/Helper'
import {
  savings_metallurgical_benefit,
  savings_metallurgical_benefit_nrm,
} from '../../../recoil/atoms'
import { useRecoilState } from 'recoil'
import StyledButton from '../../input/StyledButton'
import { trial_name } from '../../../recoil/atoms'

export default function MetallurgicalBenefits(props) {
  const [metallurgicalBenefits, setMetallurgicalBenefits] = useRecoilState(
    savings_metallurgical_benefit
  )
  const [metallurgicalBenefitsNRM, setMetallurgicalBenefitsNRM] =
    useRecoilState(savings_metallurgical_benefit_nrm)
  const [trialName] = useRecoilState(trial_name)
  const isReadOnly = trialName === 'viewCostSheet'
  const handleMetallurgicalBenefitsNRM = (name, value) => {
    setMetallurgicalBenefitsNRM((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const metaObj = {
    savings_metallurgical: 'NA',
  }

  const metaObjNRM = {
    savings_metallurgical: metallurgicalBenefitsNRM.savings_metallurgical,
  }

  useEffect(() => {
    if (
      props.trialName === 'costSheetEdit' &&
      props.editSavingsDetails.length > 0
    ) {
      console.log(props.editSavingsDetails, 'props.editSavingsDetails')
      let editObj = doesKeyExist(
        props.editSavingsDetails,
        'metallurgicalBenefitsObject'
      )
      console.log(editObj, 'editObj')
      if (editObj) {
        setMetallurgicalBenefits(
          editObj.metallurgicalBenefitsObject.metallurgicalBenefits.basic
        )
        setMetallurgicalBenefitsNRM(
          editObj.metallurgicalBenefitsObject.metallurgicalBenefits.nrm
        )
      }
    }
  }, [props.editSavingsDetails])

  function doesKeyExist(array, key) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].hasOwnProperty(key)) {
        return array[i]
      }
    }
    return false
  }

  const handlenextTriger = () => {
    props.handleNext()
    props.handleMetaObject(metaObj, metaObjNRM)
  }

  const custDet = JSON.parse(localStorage.getItem('customerDetails'))

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body1"
          style={{
            color: '#000000',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '20px',
            marginTop: '0px',
          }}
        >
          {custDet.parameter}
        </Typography>
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Savings due to Metallurgical Benefit(F6)"
          type="number"
          value="NA"
          onChange={(event) =>
            handleMetallurgicalBenefitsNRM(
              'savings_metallurgical',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body1"
          style={{
            color: '#000000',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '20px',
            marginTop: '0px',
          }}
        >
          NRM
        </Typography>
      </div>
      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Savings due to Metallurgical Benefit(F6)"
          type="number"
          value={metallurgicalBenefitsNRM.savings_metallurgical}
          onChange={(event) =>
            handleMetallurgicalBenefitsNRM(
              'savings_metallurgical',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      {/* <div style={{ justifyContent: 'space-between', flexDirection: "row", display: "flex", marginTop: "auto", marginBottom: "10px", position: 'sticky', bottom: 0, backgroundColor: 'white', zIndex: 1000 }}>
        <StyledButton
          variant="outlined"
          text="Cancel"
          onClick={props.handleBack}
          disabled={props.activeStep === 0}
          style={{ marginRight: 10 }}
        />
        <StyledButton
          variant="contained"
          color="primary"
          text={
            (props.trialName === 'edit' && props.selectedTab === 2) ? 'Update' :
            (props.activeStep === (props.selectedTab === 0 ? props.CostSheetTabs.length - 1 : props.savingsDetails.length - 1)) ? 'Finish' : 
            'Next'
          }
        onClick={handlenextTriger}
        />
      </div> */}
    </div>
  )
}
