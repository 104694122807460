import React from 'react'
import { Typography, FormControl } from '@mui/material'
import TextField from '@mui/material/TextField'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import dayjs from 'dayjs'

const HourMinInput = (props) => {
  // Convert the value to a Dayjs object if it is a string
  const value = props.value ? dayjs(props.value, 'HH:mm') : null

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Typography
          style={{
            fontSize: '12px',
            lineHeight: 1.75,
            fontWeight: 600,
            marginBottom: '5px',
          }}
        >
          {props.label}
        </Typography>
        <FormControl style={{ width: '100%' }}>
          <TimePicker
            ampm={false}
            name={props.name}
            value={value}
            error={props.error}
            onChange={(newValue) => props.onChange(newValue)}
            maxDate={props.maxLimit ? props.maxLimit : null}
            renderInput={(params) => (
              <TextField size="small" {...params} error={props.error} />
            )}
          />
        </FormControl>
      </LocalizationProvider>
    </div>
  )
}
export default HourMinInput
