import React, { useState, useEffect } from 'react'
import TextBox from '../../input/TextBox'
import AutoSelectBox from '../../input/AutoSelectBox'
import Typography from '@mui/material/Typography'
import Helper from '../../services/Helper'
import {
  side_lining_details,
  side_lining_details_nrm,
} from '../../../recoil/atoms'
import { useRecoilState } from 'recoil'
import { trial_name } from '../../../recoil/atoms'
export default function SideLiningDetails() {
  const [sideLining, setSideLining] = useRecoilState(side_lining_details)
  const [sideLiningNRM, setSideLiningNRM] = useRecoilState(
    side_lining_details_nrm
  )
  const [trialName] = useRecoilState(trial_name)
  const isReadOnly = trialName === 'viewCostSheet'
  const handleSideLiningChange = (name, value) => {
    const regex = /^[0-9]*\.?[0-9]{0,2}$/
    if (regex.test(value) || value === '') {
      setSideLining((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const handleSideLiningNRMChange = (name, value) => {
    const regex = /^[0-9]*\.?[0-9]{0,2}$/
    if (regex.test(value) || value === '') {
      setSideLiningNRM((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  useEffect(() => {
    let sideLiningObj = {
      sideLiningDetails: {
        basic: sideLining,
        nrm: sideLiningNRM,
      },
    }
  }, [sideLining, sideLiningNRM])

  useEffect(() => {
    localStorage.setItem('sideLining', JSON.stringify(sideLining))
    localStorage.setItem('sideLiningNRM', JSON.stringify(sideLiningNRM))
  }, [sideLining, sideLiningNRM])

  const custDet = JSON.parse(localStorage.getItem('customerDetails'))

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body1"
          style={{
            color: '#000000',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '20px',
            marginTop: '0px',
          }}
        >
          {custDet.parameter}
        </Typography>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <AutoSelectBox
          labelValue="Ramming Material used in Side lining - 1"
          name="ramming_material_side_lining_1"
          isLabel={true}
          options={Helper.CostSheet}
          value={sideLining.ramming_material_side_lining_1}
          onChange={(event) =>
            handleSideLiningChange(
              'ramming_material_side_lining_1',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="qty_side_lining_1"
          label="Quality of Material required for Side lining - 1"
          type="text"
          value={sideLining.qty_side_lining_1}
          onChange={(event) =>
            handleSideLiningChange('qty_side_lining_1', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginBottom: '15px' }}>
        <AutoSelectBox
          labelValue="Material used in Spout and Top - 2"
          name="material_used_spout_and_top_2"
          isLabel={true}
          options={Helper.CostSheet}
          value={sideLining.material_used_spout_and_top_2}
          onChange={(event) =>
            handleSideLiningChange(
              'material_used_spout_and_top_2',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="qty_spout_and_top_2"
          label="Quality of Material required for Spout & Top -2"
          type="text"
          value={sideLining.qty_spout_and_top_2}
          onChange={(event) =>
            handleSideLiningChange('qty_spout_and_top_2', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginBottom: '15px' }}>
        <AutoSelectBox
          labelValue="Material used in spout and top - 3"
          name="material_used_top_spout_and_top_3"
          isLabel={true}
          options={Helper.CostSheet}
          value={sideLining.material_used_top_spout_and_top_3}
          onChange={(event) =>
            handleSideLiningChange(
              'material_used_top_spout_and_top_3',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="Qty. of Material required for Spout & Top -3"
          label="Qty. of Material required for Spout & Top -3"
          type="text"
          value={sideLining.qty_spout_and_top_3}
          onChange={(event) =>
            handleSideLiningChange('qty_spout_and_top_3', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="average_side_lining_life"
          label="Average Side Lining Life"
          type="text"
          value={sideLining.average_side_lining_life}
          onChange={(event) =>
            handleSideLiningChange(
              'average_side_lining_life',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="no_of_side_lining"
          label="No of Side Lining"
          type="text"
          value={sideLining.no_of_side_lining}
          onChange={(event) =>
            handleSideLiningChange('no_of_side_lining', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="avg_heat_size"
          label="Average Heat Size Per Side Lining"
          type="text"
          value={sideLining.avg_heat_size}
          onChange={(event) =>
            handleSideLiningChange('avg_heat_size', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="body1"
          style={{
            color: '#000000',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '20px',
            marginTop: '0px',
          }}
        >
          NRM
        </Typography>
      </div>

      <div style={{ marginBottom: '15px' }}>
        <AutoSelectBox
          labelValue="Ramming Material used in Side lining - 1"
          name="ramming_material_side_lining_1"
          isLabel={true}
          options={Helper.CostSheet}
          value={sideLiningNRM.ramming_material_side_lining_1}
          onChange={(event) =>
            handleSideLiningNRMChange(
              'ramming_material_side_lining_1',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="date"
          label="Quality of Material required for Side lining - 1"
          type="text"
          value={sideLiningNRM.qty_side_lining_1}
          onChange={(event) =>
            handleSideLiningNRMChange('qty_side_lining_1', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginBottom: '15px' }}>
        <AutoSelectBox
          labelValue="Material used in Spout and Top - 2"
          name="material_used_spout_and_top_2"
          isLabel={true}
          options={Helper.CostSheet}
          value={sideLiningNRM.material_used_spout_and_top_2}
          onChange={(event) =>
            handleSideLiningNRMChange(
              'material_used_spout_and_top_2',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="qty_spout_and_top_2"
          label="Qty. of Material required for Spout & Top -2"
          type="text"
          value={sideLiningNRM.qty_spout_and_top_2}
          onChange={(event) =>
            handleSideLiningNRMChange('qty_spout_and_top_2', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginBottom: '15px' }}>
        <AutoSelectBox
          labelValue="Material used in spout and top - 3"
          name="material_used_top_spout_and_top_3"
          isLabel={true}
          options={Helper.CostSheet}
          value={sideLiningNRM.material_used_top_spout_and_top_3}
          onChange={(event) =>
            handleSideLiningNRMChange(
              'material_used_top_spout_and_top_3',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="qty_spout_and_top_3"
          label="Qty. of Material required for Spout & Top - 3"
          type="text"
          value={sideLiningNRM.qty_spout_and_top_3}
          onChange={(event) =>
            handleSideLiningNRMChange('qty_spout_and_top_3', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="average_side_lining_life"
          label="Average Side Lining Life"
          type="text"
          value={sideLiningNRM.average_side_lining_life}
          onChange={(event) =>
            handleSideLiningNRMChange(
              'average_side_lining_life',
              event.target.value
            )
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="no_of_side_lining"
          label="No of Side Lining"
          type="text"
          value={sideLiningNRM.no_of_side_lining}
          onChange={(event) =>
            handleSideLiningNRMChange('no_of_side_lining', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>

      <div style={{ marginTop: '15px' }}>
        <TextBox
          name="avg_heat_size"
          label="Average Heat Size Per Side Lining"
          type="text"
          value={sideLiningNRM.avg_heat_size}
          onChange={(event) =>
            handleSideLiningNRMChange('avg_heat_size', event.target.value)
          }
          disabled={isReadOnly}
        />
      </div>
    </div>
  )
}
