import React from 'react'
import { Typography } from '@mui/material'
import StatusImage from '../../../assets/icons/ic_status.svg'
import StyledButton from '../../input/StyledButton'
import { useNavigate } from 'react-router-dom'

const RejectStatus = () => {
  const navigate = useNavigate()

  const handleDataManagement = () => {
    navigate('/datamanagement')
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <img src={StatusImage} alt="Status" style={{ marginBottom: 20 }} />
      <Typography
        style={{
          fontFamily: 'Inter',
          fontSize: '16px',
          fontWeight: 800,
          lineHeight: '28px',
        }}
      >
        Data File is Rejected
      </Typography>
      <Typography variant="body1" align="center">
        Your Submitted data is rejected
      </Typography>

      <div style={{ marginTop: 40 }}>
        <StyledButton
          variant="contained"
          color="primary"
          text="Go to Data Management"
          onClick={handleDataManagement}
        />
      </div>
    </div>
  )
}
export default RejectStatus
