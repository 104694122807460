import React from 'react'
import TextBox from '../../input/TextBox'
import { other_parameters, general_details } from '../../../recoil/atoms'
import { useRecoilState } from 'recoil'

const OtherParameters = () => {
  const [otherParameters, setOtherParameters] = useRecoilState(other_parameters)
  const [generalDetails] = useRecoilState(general_details)
  const uniqueId = generalDetails.id ? generalDetails.id : ''

  const handleChange = (e) => {
    const { name, value } = e.target
    console.log(value, 'value')
    const TrialName = localStorage.getItem('Trial')

    const regex = /^\d{0,5}(\.\d{0,2})?$/

    if (regex.test(value) || (name === 'remarks' && value.length <= 75)) {
      if (TrialName === 'new') {
        setOtherParameters((prevState) => ({
          ...prevState,
          [name]: value,
          id: uniqueId,
        }))
      } else {
        // If TrialName is not "new", no need to generate unique ID
        setOtherParameters((prevState) => ({
          ...prevState,
          [name]: value,
        }))
      }
    }
  }

  return (
    <div>
      <div style={{ flex: 1, marginRight: '15px', marginTop: '15px' }}>
        <TextBox
          name="inlet_water_temp"
          label="Inlet water temp. (°C)"
          type="text"
          value={otherParameters.inlet_water_temp}
          onChange={handleChange}
        />
      </div>
      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="outlet_water_temp"
          label="Outlet Water temp. (°C)"
          type="text"
          value={otherParameters.outlet_water_temp}
          onChange={handleChange}
        />
      </div>
      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="water_pr"
          label="Water Pr.(Bar)"
          type="text"
          value={otherParameters.water_pr}
          onChange={handleChange}
        />
      </div>
      <div style={{ flex: 1, marginRight: '15px' }}>
        <TextBox
          name="remarks"
          label="Remarks"
          type="text"
          value={otherParameters.remarks}
          onChange={handleChange}
        />
      </div>
    </div>
  )
}

export default OtherParameters
