import React, { useState } from 'react'
import { Typography } from '@mui/material'
import { ReactComponent as BackIcon } from '../../../assets/icons/back_icon.svg'

const styles = {
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    zIndex: 9999,
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px',
    borderBottom: '1px solid #ddd',
  },
  body: {
    flex: 1,
    overflowY: 'auto',
    padding: '10px',
    display: 'flex',
  },
  sidebar: {
    width: '40%',
    borderRight: '1px solid #ddd',
  },
  content: {
    flex: 1,
    padding: '10px',
  },
  footer: {
    padding: '15px',
    borderTop: '1px solid #ddd',
  },
  heading: {
    padding: '15px',
    borderBottom: '1px solid #ddd',
    cursor: 'pointer',
  },
  headingActive: {
    padding: '15px',
    borderBottom: '1px solid #ddd',
    cursor: 'pointer',
    backgroundColor: '#e7f3ff',
    fontWeight: 'bold',
  },
  search: {
    padding: '10px',
    borderBottom: '1px solid #ddd',
  },
  searchInput: {
    width: '100%',
    padding: '10px',
    borderRadius: '6px',
    border: '1px solid #ddd',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px',
    borderBottom: '1px solid #ddd',
  },
  checkbox: {
    marginRight: '15px',
  },
  applyButton: {
    width: '100%',
    padding: '10px',
    backgroundColor: '#007BFF',
    color: 'white',
    textAlign: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  dateInput: {
    width: '100%',
    padding: '10px',
    margin: '10px 0',
    borderRadius: '6px',
    border: '1px solid #ddd',
  },
}

const DateStyles = {
  dateInput: {
    padding: '8px',
    margin: '5px',
    fontSize: '14px',
    width: '100%',
    borderRadius: '4px',
  },
}

const FilterPage = ({ onClose, applyFilter, dailyTrials }) => {
  console.log(dailyTrials, 'heheh')
  const [selectedHeading, setSelectedHeading] = useState('Date')
  const [selectedOptions, setSelectedOptions] = useState({})
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')

  const headings = ['Date', 'Status']
  const options = {
    Status: ['Select All', 'Draft', 'Submitted', 'Rejected', 'Approved'],
  }

  const handleOptionChange = (option) => {
    setSelectedOptions((prev) => {
      const newOptions = { ...prev }

      if (option === 'Select All') {
        if (newOptions[selectedHeading]?.includes('Select All')) {
          newOptions[selectedHeading] = []
        } else {
          newOptions[selectedHeading] = options[selectedHeading]
        }
      } else {
        if (newOptions[selectedHeading]?.includes(option)) {
          newOptions[selectedHeading] = newOptions[selectedHeading].filter(
            (item) => item !== option
          )
        } else {
          newOptions[selectedHeading] = [
            ...(newOptions[selectedHeading] || []),
            option,
          ]
        }

        const allOptionsSelected = options[selectedHeading].every((opt) =>
          newOptions[selectedHeading]?.includes(opt)
        )

        if (allOptionsSelected) {
          newOptions[selectedHeading] = [...options[selectedHeading]]
        } else {
          newOptions[selectedHeading] = newOptions[selectedHeading].filter(
            (opt) => opt !== 'Select All'
          )
        }
      }

      return newOptions
    })
  }

  const handleApplyFilter = () => {
    const { Status: selectedStatusOptions = [] } = selectedOptions
    const hasDraftSelected = selectedStatusOptions.includes('Draft')
    const hasSubmittedSelected = selectedStatusOptions.includes('Submitted')
    const hasApprovedSelected = selectedStatusOptions.includes('Approved')
    const hasRejectedSelected = selectedStatusOptions.includes('Rejected')
    const hasSelectAllSelected = selectedStatusOptions.includes('Select All')

    const isStatusFilterSelected = selectedStatusOptions.length > 0
    const isDateFilterSelected = fromDate || toDate

    const filteredTrials = dailyTrials.filter((trial) => {
      const trialDate = new Date(trial.created_at)
      trialDate.setHours(0, 0, 0, 0)

      const from = new Date(fromDate)
      from.setHours(0, 0, 0, 0)

      const to = new Date(toDate)
      to.setHours(23, 59, 59, 999)

      const isWithinDateRange =
        (!fromDate || trialDate >= from) && (!toDate || trialDate <= to)

      if (isDateFilterSelected && !isWithinDateRange) {
        return false
      }

      if (isStatusFilterSelected) {
        if (hasSelectAllSelected) {
          return true
        }

        let hasMatchingStatus = false

        // Ensure trial.general_details is an array before iterating
        if (Array.isArray(trial.general_details)) {
          for (const detail of trial.general_details) {
            const submissionStatus = detail?.submission_status ?? 'test'

            if (
              (hasDraftSelected && submissionStatus === 'draft') ||
              (hasSubmittedSelected && submissionStatus === 'submitted') ||
              (hasApprovedSelected && submissionStatus === 'approved') ||
              (hasRejectedSelected && submissionStatus === 'rejected')
            ) {
              hasMatchingStatus = true
              break
            }
          }
        }

        if (!hasMatchingStatus) {
          return false
        }
      }

      return true
    })

    console.log(filteredTrials)

    if (filteredTrials.length === 0) {
      alert('No records found matching the selected criteria.')
      return
    }

    applyFilter(filteredTrials)
    onClose(false)
  }

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <BackIcon onClick={() => onClose(false)} />
          <Typography
            style={{
              fontWeight: 600,
              fontSize: '16px',
              color: '#000000',
              fontFamily: 'Inter',
              marginLeft: '5px',
              cursor: 'pointer',
            }}
            onClick={() => onClose(false)}
          >
            Filter
          </Typography>
        </div>
        <Typography
          style={{
            fontWeight: 600,
            fontSize: '16px',
            color: '#0086DC',
            fontFamily: 'Inter',
            cursor: 'pointer',
          }}
          onClick={() => {
            setSelectedOptions({})
            setFromDate('')
            setToDate('')
          }}
        >
          Clear All
        </Typography>
      </div>
      <div style={styles.body}>
        <div style={styles.sidebar}>
          {headings.map((heading) => (
            <div
              key={heading}
              style={
                heading === selectedHeading
                  ? styles.headingActive
                  : styles.heading
              }
              onClick={() => setSelectedHeading(heading)}
            >
              {heading}
            </div>
          ))}
        </div>
        <div style={styles.content}>
          {selectedHeading === 'Date' ? (
            <div style={{ width: '100%' }}>
              <div style={{ width: '100%' }}>
                <label htmlFor="fromDate">From Date</label>
                <br />
                <input
                  type="date"
                  id="fromDate"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  placeholder="From Date"
                  style={DateStyles.dateInput}
                />
              </div>
              <div style={{ width: '100%' }}>
                <label htmlFor="toDate">To Date</label>
                <br />
                <input
                  type="date"
                  id="toDate"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  placeholder="To Date"
                  style={DateStyles.dateInput}
                />
              </div>
            </div>
          ) : (
            <>
              <div style={styles.search}>
                <input
                  type="text"
                  placeholder="Search"
                  style={styles.searchInput}
                />
              </div>
              {options[selectedHeading]?.map((option, index) => (
                <div style={styles.listItem} key={index}>
                  <input
                    type="checkbox"
                    style={{
                      ...styles.checkbox,
                      width: '20px',
                      height: '20px',
                      borderRadius: '10px',
                    }}
                    checked={
                      selectedOptions[selectedHeading]?.includes(option) ||
                      false
                    }
                    onChange={() => handleOptionChange(option)}
                  />
                  <span>{option}</span>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      <div style={styles.footer}>
        <div style={styles.applyButton} onClick={handleApplyFilter}>
          Apply Filter
        </div>
      </div>
    </div>
  )
}

export default FilterPage
