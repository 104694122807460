import React, { useState, useEffect } from 'react'
import { Typography, TextField } from '@mui/material'
import StyledButton from '../../input/StyledButton'
import ErosionProfileDetails from './Details'
import DimensionalDetails from './DimensionalDetails'
import NrmUsageDetails from './NRMUsageDetails'
import BeforeChipping from './BeforeChipping'
import AfterChipping from './AfterChipping'
import LiningSide from './LiningSide'

import {
  erosion_details,
  dimensional_details,
  nrm_usage_details,
  lining_side_left,
  lining_side_right,
  lining_side_spout,
  trial_name,
  height_bc_0,
  height_bc_10,
  height_bc_20,
  height_bc_30,
  height_bc_40,
  height_bc_50,
  height_bc_60,
  height_bc_70,
  height_bc_80,
  height_bc_90,
  height_bc_100,
  height_ac_0,
  height_ac_10,
  height_ac_20,
  height_ac_30,
  height_ac_40,
  height_ac_50,
  height_ac_60,
  height_ac_70,
  height_ac_80,
  height_ac_90,
  height_ac_100,
  lining_side_back,
} from '../../../recoil/atoms'

import {
  beforeChipping_0,
  beforeChipping_10,
  beforeChipping_20,
  beforeChipping_30,
  beforeChipping_40,
  beforeChipping_50,
  beforeChipping_60,
  beforeChipping_70,
  beforeChipping_80,
  beforeChipping_90,
  beforeChipping_100,
  afterChipping_0,
  afterChipping_10,
  afterChipping_20,
  afterChipping_30,
  afterChipping_40,
  afterChipping_50,
  afterChipping_60,
  afterChipping_70,
  afterChipping_80,
  afterChipping_90,
  afterChipping_100,
  initialLiningHeight,
  tot_NRM_Consumption,
} from '../../../recoil/selector'
import { useRecoilState, useRecoilValue } from 'recoil'
import axios from 'axios'
import API from '../../services/API'
import { useNavigate } from 'react-router-dom'
import AlertDialog from '../../input/AlertDialog'
import { Snackbar } from '@mui/material'
import { Alert } from '@mui/material'

const steps = [
  'Details',
  'Dimensional Details',
  'NRM usage Details',
  'Erosion before Chipping',
  'Erosion after chipping',
  'Lining Side',
]

function ErosionProfileStepper() {
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = useState(0)
  const [trialName] = useRecoilState(trial_name)
  console.log(trialName, 'sakhd')
  const [erosionDetails, setErosionDetails] = useRecoilState(erosion_details)
  const [dimensionalDetails, setDimensionalDetails] =
    useRecoilState(dimensional_details)
  const [NRMUsageDetails, setNRMUsageDetails] =
    useRecoilState(nrm_usage_details)
  const [comments, setComments] = useState('')
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const currentUser = JSON.parse(localStorage.getItem('user'))
  const [submissionStatus, setSubmissionStatus] = useState('')
  const [heightBc_0, setHeightBc_0] = useRecoilState(height_bc_0)
  const [heightBc_10, setHeightBc_10] = useRecoilState(height_bc_10)
  const [heightBc_20, setHeightBc_20] = useRecoilState(height_bc_20)
  const [heightBc_30, setHeightBc_30] = useRecoilState(height_bc_30)
  const [heightBc_40, setHeightBc_40] = useRecoilState(height_bc_40)
  const [heightBc_50, setHeightBc_50] = useRecoilState(height_bc_50)
  const [heightBc_60, setHeightBc_60] = useRecoilState(height_bc_60)
  const [heightBc_70, setHeightBc_70] = useRecoilState(height_bc_70)
  const [heightBc_80, setHeightBc_80] = useRecoilState(height_bc_80)
  const [heightBc_90, setHeightBc_90] = useRecoilState(height_bc_90)
  const [heightBc_100, setHeightBc_100] = useRecoilState(height_bc_100)

  const [heightAc_0, setHeightAc_0] = useRecoilState(height_ac_0)
  const [heightAc_10, setHeightAc_10] = useRecoilState(height_ac_10)
  const [heightAc_20, setHeightAc_20] = useRecoilState(height_ac_20)
  const [heightAc_30, setHeightAc_30] = useRecoilState(height_ac_30)
  const [heightAc_40, setHeightAc_40] = useRecoilState(height_ac_40)
  const [heightAc_50, setHeightAc_50] = useRecoilState(height_ac_50)
  const [heightAc_60, setHeightAc_60] = useRecoilState(height_ac_60)
  const [heightAc_70, setHeightAc_70] = useRecoilState(height_ac_70)
  const [heightAc_80, setHeightAc_80] = useRecoilState(height_ac_80)
  const [heightAc_90, setHeightAc_90] = useRecoilState(height_ac_90)
  const [heightAc_100, setHeightAc_100] = useRecoilState(height_ac_100)

  // lining side
  const [left, setLeft] = useRecoilState(lining_side_left)
  const [right, setRight] = useRecoilState(lining_side_right)
  const [spout, setSpout] = useRecoilState(lining_side_spout)
  const [back, setBack] = useRecoilState(lining_side_back)

  // bc calculated fields
  const bc_spout_back_0 = useRecoilValue(beforeChipping_0)
  const bc_spout_back_10 = useRecoilValue(beforeChipping_10)
  const bc_spout_back_20 = useRecoilValue(beforeChipping_20)
  const bc_spout_back_30 = useRecoilValue(beforeChipping_30)
  const bc_spout_back_40 = useRecoilValue(beforeChipping_40)
  const bc_spout_back_50 = useRecoilValue(beforeChipping_50)
  const bc_spout_back_60 = useRecoilValue(beforeChipping_60)
  const bc_spout_back_70 = useRecoilValue(beforeChipping_70)
  const bc_spout_back_80 = useRecoilValue(beforeChipping_80)
  const bc_spout_back_90 = useRecoilValue(beforeChipping_90)
  const bc_spout_back_100 = useRecoilValue(beforeChipping_100)

  // ac calculated fields
  const ac_lot_0 = useRecoilValue(afterChipping_0)
  const ac_lot_10 = useRecoilValue(afterChipping_10)
  const ac_lot_20 = useRecoilValue(afterChipping_20)
  const ac_lot_30 = useRecoilValue(afterChipping_30)
  const ac_lot_40 = useRecoilValue(afterChipping_40)
  const ac_lot_50 = useRecoilValue(afterChipping_50)
  const ac_lot_60 = useRecoilValue(afterChipping_60)
  const ac_lot_70 = useRecoilValue(afterChipping_70)
  const ac_lot_80 = useRecoilValue(afterChipping_80)
  const ac_lot_90 = useRecoilValue(afterChipping_90)
  const ac_lot_100 = useRecoilValue(afterChipping_100)
  const access_token = localStorage.getItem('accesstoken')

  // dimensional Details

  const initialLiningHeightvalue = useRecoilValue(initialLiningHeight)
  const totalNRM = useRecoilValue(tot_NRM_Consumption)

  const [open, setOpen] = useState(false)
  const [isOnline, setIsOnline] = useState(window.navigator.onLine)
  const [db, setDb] = useState(null)

  const handleDraft = async () => {
    if (activeStep === 5) {
      if (trialName === 'ErosionProfileEdit') {
        await updateFormData('draft')
      } else {
        await submitFormData('draft')
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }
  useEffect(() => {
    const handleOnline = () => {
      if (!isOnline) {
        setIsOnline(true)
        localStorage.setItem('onlinestate', 1)

        window.location.reload()
      }
    }

    const handleOffline = () => {
      localStorage.setItem('onlinestate', 0)
      // setIsOnlines(false)
      setIsOnline(false)
      // navigate('/reports')
    }

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [isOnline])

  const dbName = 'pcr'
  const customerName = 'customers'
  const furnacesName = 'furnaces'
  const templateName = 'template'
  const segmentName = 'segment'
  const datafileName = 'datafile'
  const dailytrialName = 'daily_trial'
  const nrmName = 'nrm_performance'
  const erosionName = 'erosion_profile'
  const dataCollectionName = 'data_collection'
  const costSheetName = 'cost_sheet'
  const LiningName = 'lining_patching_no'

  useEffect(() => {
    if (!isOnline) {
      const request = indexedDB.open(dbName, 1)

      request.onupgradeneeded = (event) => {
        const database = event.target.result
        database.createObjectStore(customerName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(furnacesName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(templateName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(segmentName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(datafileName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(dailytrialName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(nrmName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(erosionName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(dataCollectionName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(costSheetName, {
          keyPath: 'id',
          autoIncrement: true,
        })
        database.createObjectStore(LiningName, {
          keyPath: 'id',
          autoIncrement: true,
        })
      }

      request.onsuccess = (event) => {
        const database = event.target.result
        const transaction = database.transaction(
          [
            'customers',
            'furnaces',
            'template',
            'segment',
            'datafile',
            'daily_trial',
            'nrm_performance',
            'erosion_profile',
            'data_collection',
            'cost_sheet',
            'lining_patching_no',
          ],
          'readwrite'
        )

        setDb(database)
      }

      request.onerror = (event) => {
        console.error('Error opening IndexedDB', event)
      }
    }
  }, [isOnline])

  const handleNext = async () => {
    if (activeStep === 5) {
      AlertDialog({
        type: 'delete',
        title: 'Submission',
        text: 'Are you sure you want to Submit for an Approval?',
        confirmButtonText: 'Yes',
        onConfirm: async () => {
          if (trialName === 'ErosionProfileEdit') {
            await updateFormData('submitted')
          } else {
            if (
              currentUser.role.role_id === 3 ||
              currentUser.role.role_id === 4
            ) {
              await submitFormData('approved')
            } else {
              await submitFormData('submitted')
            }
          }
        },
      })
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }
  const dimensionalDetailsObject = [
    {
      ...dimensionalDetails,
      initial_lining_height: initialLiningHeightvalue.initLiningHt,
      lining_height_erosion: initialLiningHeightvalue.liningHeightAE,
    },
  ]

  console.log(dimensionalDetailsObject, 'dimensionalDetailsObject')

  const nrmUsageObject = [
    {
      ...NRMUsageDetails,
      tot_nrm_conspt: totalNRM.tot_Consumpt,
    },
  ]

  console.log(nrmUsageObject, 'nrmUsageObject')

  const beforeChipping = [
    {
      height0: {
        ...heightBc_0,
        spout_back: bc_spout_back_0.spout_back_0,
        left_right: bc_spout_back_0.left_right_0,
      },
    },
    {
      height10: {
        ...heightBc_10,
        spout_back: bc_spout_back_10.spout_back_10,
        left_right: bc_spout_back_10.left_right_10,
      },
    },
    {
      height20: {
        ...heightBc_20,
        spout_back: bc_spout_back_20.spout_back_20,
        left_right: bc_spout_back_20.left_right_20,
      },
    },
    {
      height30: {
        ...heightBc_30,
        spout_back: bc_spout_back_30.spout_back_30,
        left_right: bc_spout_back_30.left_right_30,
      },
    },
    {
      height40: {
        ...heightBc_40,
        spout_back: bc_spout_back_40.spout_back_40,
        left_right: bc_spout_back_40.left_right_40,
      },
    },
    {
      height50: {
        ...heightBc_50,
        spout_back: bc_spout_back_50.spout_back_50,
        left_right: bc_spout_back_50.left_right_50,
      },
    },
    {
      height60: {
        ...heightBc_60,
        spout_back: bc_spout_back_60.spout_back_60,
        left_right: bc_spout_back_60.left_right_60,
      },
    },
    {
      height70: {
        ...heightBc_70,
        spout_back: bc_spout_back_70.spout_back_70,
        left_right: bc_spout_back_70.left_right_70,
      },
    },
    {
      height80: {
        ...heightBc_80,
        spout_back: bc_spout_back_80.spout_back_80,
        left_right: bc_spout_back_80.left_right_80,
      },
    },
    {
      height90: {
        ...heightBc_90,
        spout_back: bc_spout_back_90.spout_back_90,
        left_right: bc_spout_back_90.left_right_90,
      },
    },
    {
      height100: {
        ...heightBc_100,
        spout_back: bc_spout_back_100.spout_back_100,
        left_right: bc_spout_back_100.left_right_100,
      },
    },
  ]

  const afterChipping = [
    {
      height0: {
        ...heightAc_0,
        spout_back: ac_lot_0.spout_back_0,
        left_right: ac_lot_0.left_right_0,
      },
    },
    {
      height10: {
        ...heightAc_10,
        spout_back: ac_lot_10.spout_back_10,
        left_right: ac_lot_10.left_right_10,
      },
    },
    {
      height20: {
        ...heightAc_20,
        spout_back: ac_lot_20.spout_back_20,
        left_right: ac_lot_20.left_right_20,
      },
    },
    {
      height30: {
        ...heightAc_30,
        spout_back: ac_lot_30.spout_back_30,
        left_right: ac_lot_30.left_right_30,
      },
    },
    {
      height40: {
        ...heightAc_40,
        spout_back: ac_lot_40.spout_back_40,
        left_right: ac_lot_40.left_right_40,
      },
    },
    {
      height50: {
        ...heightAc_50,
        spout_back: ac_lot_50.spout_back_50,
        left_right: ac_lot_50.left_right_50,
      },
    },
    {
      height60: {
        ...heightAc_60,
        spout_back: ac_lot_60.spout_back_60,
        left_right: ac_lot_60.left_right_60,
      },
    },
    {
      height70: {
        ...heightAc_70,
        spout_back: ac_lot_70.spout_back_70,
        left_right: ac_lot_70.left_right_70,
      },
    },
    {
      height80: {
        ...heightAc_80,
        spout_back: ac_lot_80.spout_back_80,
        left_right: ac_lot_80.left_right_80,
      },
    },
    {
      height90: {
        ...heightAc_90,
        spout_back: ac_lot_90.spout_back_90,
        left_right: ac_lot_90.left_right_90,
      },
    },
    {
      height100: {
        ...heightAc_100,
        spout_back: ac_lot_100.spout_back_100,
        left_right: ac_lot_100.left_right_100,
      },
    },
  ]

  const liningSide = [
    { left: left },
    { right: right },
    { spout, spout },
    { back: back },
  ]

  const submitFormData = async (submission_status) => {
    try {
      if (isOnline) {
        const uploadId = localStorage.getItem('datafile_id')
        console.log('uploadIduploadId', uploadId)
        const data = {
          datafile_id: uploadId,
          submission_status: submission_status,
          data: {
            details: erosionDetails,
            dimensional_details: dimensionalDetailsObject,
            nrm_usage_details: nrmUsageObject,
            before_chipping: beforeChipping,
            after_chipping: afterChipping,
            lining_side: liningSide,
          },
        }

        console.log(data, 'data123')

        // Make the POST request using axios
        const response = await axios.post(API.INSERT_EROSION_PROFILE, data, {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        })
        console.log('Data inserted successfully', response.data)
        if (response.status === 200) {
          if (submission_status === 'draft') {
            navigate('/draftstatus')
          } else {
            navigate('/submittedstatus')
          }
          // AlertDialog({
          //   type: 'success',
          //   title: 'Success',
          //   text: "Form submitted successfully!",
          //   confirmButtonText: 'Ok',
          //   onConfirm: async () => {
          //     navigate('/datamanagement')
          //   },
          // });
        } else {
          console.error('Error saving data:', response.data)
          AlertDialog({
            type: 'warning',
            title: 'Error',
            text: 'Failed to save data',
          })
        }
      } else {
        const uploadId = localStorage.getItem('datafile_id')
        const data1 = {
          datafile_id: uploadId,
          created_at: new Date(),
          submission_status: submission_status,
          details: erosionDetails,
          dimensional_details: dimensionalDetailsObject,
          nrm_usage_details: nrmUsageObject,
          before_chipping: beforeChipping,
          after_chipping: afterChipping,
          lining_side: liningSide,
        }
        const transaction = db.transaction(erosionName, 'readwrite')
        const objectStore = transaction.objectStore(erosionName)
        const trialdetailsrequest = objectStore.add(data1)
        trialdetailsrequest.onsuccess = () => {
          console.log(
            'File saved successfully in IndexedDB trialdetails',
            trialdetailsrequest.result
          )
          const daily_trial_id = trialdetailsrequest.result
          // localStorage.setItem("daily_trial_id", daily_trial_id);
        }

        trialdetailsrequest.onerror = () => {
          console.log('Error saving file in IndexedDB')
        }

        if (submission_status === 'draft') {
          navigate('/draftstatus')
        } else {
          navigate('/submittedstatus')
        }
      }
    } catch (error) {
      // Handle errors
      console.error('An error occurred while inserting data:', error)
    }
  }

  const handleApprove = async () => {
    try {
      updateFormData('approved')

      // Navigate after successful update
    } catch (error) {
      // Handle errors
      console.error('An error occurred while updating data:', error)
    }
  }

  const handleReject = async () => {
    try {
      updateFormData('rejected')
      // Navigate after successful update
    } catch (error) {
      // Handle errors
      console.error('An error occurred while updating data:', error)
    }
  }

  const updateFormData = async (submission_status) => {
    try {
      if (isOnline) {
        const id = localStorage.getItem('erosionProfileID')

        const updated_data = {
          id: id,
          submission_status: submission_status,
          updated_data: {
            details: erosionDetails,
            nrm_usage_details: nrmUsageObject,
            dimensional_details: dimensionalDetailsObject,
            before_chipping: beforeChipping,
            after_chipping: afterChipping,
            lining_side: liningSide,
          },
        }
        console.log(updated_data, 'update data')
        const response = await axios.post(
          API.UPDATE_EROSION_PROFILE,
          updated_data,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        console.log('Data updated successfully:', response.data)
        if (response.status === 200) {
          if (submission_status === 'draft') {
            navigate('/draftstatus')
          } else if (submission_status === 'submitted') {
            navigate('/submittedstatus')
          } else if (submission_status === 'approved') {
            navigate('/approvestatus')
          } else {
            navigate('/rejectstatus')
          }
          // AlertDialog({
          //   type: 'success',
          //   title: 'Success',
          //   text: "Form Updated successfully!",
          //   confirmButtonText: 'Ok',
          //   onConfirm: async () => {
          //     navigate('/datamanagement')
          //   },
          // });
        } else {
          console.error('Error saving data:', response.data)
          AlertDialog({
            type: 'warning',
            title: 'Error',
            text: 'Failed to save data',
          })
        }
      } else {
        const id = localStorage.getItem('erosionProfileID')

        const updated_data = {
          id: id,
          submission_status: submission_status,

          details: erosionDetails,
          nrm_usage_details: NRMUsageDetails,
          dimensional_details: dimensionalDetails,
          before_chipping: beforeChipping,
          after_chipping: afterChipping,
          lining_side: liningSide,
        }
        const transaction = db.transaction(erosionName, 'readwrite')
        const objectStore = transaction.objectStore(erosionName)
        const updateRequest = objectStore.put(updated_data)

        updateRequest.onsuccess = () => {
          console.log(
            'Offline Mode - Trial data updated successfully:',
            updateRequest.result
          )
          if (submission_status === 'draft') {
            navigate('/draftstatus')
          } else if (submission_status === 'submitted') {
            navigate('/submittedstatus')
          } else if (submission_status === 'approved') {
            navigate('/approvestatus')
          } else {
            navigate('/rejectstatus')
          }
        }

        updateRequest.onerror = () => {
          console.error(
            'Offline Mode - Error updating trial data in IndexedDB.'
          )
        }
      }
    } catch (error) {
      // Handle errors
      console.error('An error occurred while updating data:', error)
    }
  }

  useEffect(() => {
    if (
      trialName === 'ErosionProfileEdit' ||
      trialName === 'viewErosionProfile'
    ) {
      const fetchData = async () => {
        if (isOnline) {
          try {
            const id = localStorage.getItem('erosionProfileID')
            const response = await axios.get(
              `${API.GET_EROSION_PROFILE_BY_ID}/${id}`,
              {
                headers: {
                  Authorization: `Bearer ${access_token}`,
                },
              }
            )
            const erosionProfileData = response.data
            console.log(erosionProfileData, 'erosionProfileData')
            const status = erosionProfileData.submission_status
            setSubmissionStatus(status)

            if (erosionProfileData && Object.keys(erosionProfileData)) {
              setErosionDetails(erosionProfileData.details)
              setDimensionalDetails(erosionProfileData.dimensional_details[0])
              setNRMUsageDetails(erosionProfileData.nrm_usage_details[0])
              //before chipping
              setHeightBc_0(erosionProfileData.before_chipping[0].height0)
              setHeightBc_10(erosionProfileData.before_chipping[1].height10)
              setHeightBc_20(erosionProfileData.before_chipping[2].height20)
              setHeightBc_30(erosionProfileData.before_chipping[3].height30)
              setHeightBc_40(erosionProfileData.before_chipping[4].height40)
              setHeightBc_50(erosionProfileData.before_chipping[5].height50)
              setHeightBc_60(erosionProfileData.before_chipping[6].height60)
              setHeightBc_70(erosionProfileData.before_chipping[7].height70)
              setHeightBc_80(erosionProfileData.before_chipping[8].height80)
              setHeightBc_90(erosionProfileData.before_chipping[9].height90)
              setHeightBc_100(erosionProfileData.before_chipping[10].height100)
              //after chipping
              setHeightAc_0(erosionProfileData.after_chipping[0].height0)
              setHeightAc_10(erosionProfileData.after_chipping[1].height10)
              setHeightAc_20(erosionProfileData.after_chipping[2].height20)
              setHeightAc_30(erosionProfileData.after_chipping[3].height30)
              setHeightAc_40(erosionProfileData.after_chipping[4].height40)
              setHeightAc_50(erosionProfileData.after_chipping[5].height50)
              setHeightAc_60(erosionProfileData.after_chipping[6].height60)
              setHeightAc_70(erosionProfileData.after_chipping[7].height70)
              setHeightAc_80(erosionProfileData.after_chipping[8].height80)
              setHeightAc_90(erosionProfileData.after_chipping[9].height90)
              setHeightAc_100(erosionProfileData.after_chipping[10].height100)
              //lining side
              setLeft(erosionProfileData.lining_side[0].left)
              setRight(erosionProfileData.lining_side[1].right)
              setSpout(erosionProfileData.lining_side[2].spout)
              setBack(erosionProfileData.lining_side[3].back)
            }
          } catch (error) {
            console.error('Error fetching data:', error)
          }
        } else {
          try {
            const id = localStorage.getItem('erosionProfileID')
            // const TrialIndex = localStorage.getItem("TrialIndex");

            const transaction1 = db.transaction(erosionName, 'readonly')
            const objectStore1 = transaction1.objectStore(erosionName)

            const id_int = parseInt(id, 10)
            const getAllRequest1 = objectStore1.get(id_int)

            console.log('id', typeof id)

            getAllRequest1.onsuccess = () => {
              const trialdata = getAllRequest1.result
              console.log('all123', trialdata)
              const status = trialdata.submission_status
              setSubmissionStatus(status)

              setErosionDetails(trialdata.details)
              setDimensionalDetails(trialdata.dimensional_details)
              setNRMUsageDetails(trialdata.nrm_usage_details)
              //before chipping
              setHeightBc_0(trialdata.before_chipping[0].height0)
              setHeightBc_10(trialdata.before_chipping[1].height10)
              setHeightBc_20(trialdata.before_chipping[2].height20)
              setHeightBc_30(trialdata.before_chipping[3].height30)
              setHeightBc_40(trialdata.before_chipping[4].height40)
              setHeightBc_50(trialdata.before_chipping[5].height50)
              setHeightBc_60(trialdata.before_chipping[6].height60)
              setHeightBc_70(trialdata.before_chipping[7].height70)
              setHeightBc_80(trialdata.before_chipping[8].height80)
              setHeightBc_90(trialdata.before_chipping[9].height90)
              setHeightBc_100(trialdata.before_chipping[10].height100)
              //after chipping
              setHeightAc_0(trialdata.after_chipping[0].height0)
              setHeightAc_10(trialdata.after_chipping[1].height10)
              setHeightAc_20(trialdata.after_chipping[2].height20)
              setHeightAc_30(trialdata.after_chipping[3].height30)
              setHeightAc_40(trialdata.after_chipping[4].height40)
              setHeightAc_50(trialdata.after_chipping[5].height50)
              setHeightAc_60(trialdata.after_chipping[6].height60)
              setHeightAc_70(trialdata.after_chipping[7].height70)
              setHeightAc_80(trialdata.after_chipping[8].height80)
              setHeightAc_90(trialdata.after_chipping[9].height90)
              setHeightAc_100(trialdata.after_chipping[10].height100)
              //lining side
              setLeft(trialdata[0].left)
              setRight(trialdata[1].right)
              setSpout(trialdata[2].spout)
              setBack(trialdata[3].back)
            }

            getAllRequest1.onerror = (event) => {
              console.error('Error getting data from IndexedDB', event)
            }
          } catch (error) {
            console.error('Error fetching data:', error)
          }
        }
      }

      fetchData()
    }
  }, [trialName, isOnline, db])

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleSnackbarClose = () => {
    setSnackbarOpen(false)
  }

  const handleStepClick = (index) => {
    setActiveStep(index)
  }

  const progressText = `${activeStep + 1} of ${steps.length} datas completed`

  return (
    <div
      style={{
        width: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <div style={{ flex: 1, overflowY: 'auto' }}>
        <div style={{ marginTop: '30px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="body1"
              style={{
                color: '#000000',
                fontFamily: 'Inter',
                fontWeight: 600,
                fontSize: '18px',
                lineHeight: '20px',
              }}
            >
              {steps[activeStep]}
            </Typography>
          </div>
          <Typography
            variant="body1"
            sx={{
              marginBottom: '20px',
              color: '#6E7C87',
              fontFamily: 'Inter',
              fontWeight: 400,
              fontSize: '14px',
              marginTop: '5px',
            }}
          >
            {progressText}
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '20px',
          }}
        >
          {steps.map((step, index) => (
            <div
              key={index}
              style={{ flex: 1, position: 'relative', marginRight: '10px' }}
            >
              <div
                onClick={() => handleStepClick(index)}
                style={{
                  borderBottom: `5px solid ${index === activeStep ? '#0086DC' : '#BDBDBD'}`,
                  width: '100%',
                  marginBottom: '10px',
                  marginTop: '10px',
                  borderRadius: '7px',
                }}
              />
            </div>
          ))}
        </div>

        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography>All steps completed</Typography>
            </div>
          ) : (
            <div>
              {activeStep === 0 && <ErosionProfileDetails />}
              {activeStep === 1 && <DimensionalDetails />}
              {activeStep === 2 && <NrmUsageDetails />}
              {activeStep === 3 && <BeforeChipping />}
              {activeStep === 4 && <AfterChipping />}
              {activeStep === 5 && <LiningSide />}
            </div>
          )}
        </div>
      </div>

      {trialName !== 'viewErosionProfile' ? (
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            marginTop: 'auto',
            marginBottom: '10px',
            position: 'sticky',
            bottom: 0,
            backgroundColor: 'white',
            zIndex: 1000,
          }}
        >
          <StyledButton
            variant="outlined"
            text="Back"
            onClick={handleBack}
            disabled={activeStep === 0}
            style={{ marginRight: 10 }}
          />
          <StyledButton
            variant="outlined_blue"
            color="secondary"
            text="Save Draft"
            onClick={handleDraft}
            style={{ marginRight: 10 }}
          />
          <StyledButton
            variant="contained"
            color="primary"
            text={
              activeStep === steps.length - 1 ? 'Submit for Approval' : 'Next'
            }
            onClick={handleNext}
          />
        </div>
      ) : (
        <div>
          {activeStep === steps.length - 1 ? null : ( // No buttons shown on the last step in view mode
            <div
              style={{
                flexDirection: 'row',
                display: 'flex',
                marginTop: 'auto',
                marginBottom: '10px',
                position: 'sticky',
                bottom: 0,
                backgroundColor: 'white',
                zIndex: 1000,
              }}
            >
              <StyledButton
                variant="outlined"
                text="Back"
                onClick={handleBack}
                disabled={activeStep === 0}
                style={{ marginRight: 10 }}
              />
              <StyledButton
                variant="contained"
                color="primary"
                text="Next"
                onClick={handleNext}
              />
            </div>
          )}
        </div>
      )}

      {trialName === 'viewErosionProfile' &&
        (currentUser.role.role_id === 3 || currentUser.role.role_id === 4) &&
        activeStep === 5 &&
        submissionStatus !== 'approved' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              minHeight: '100vh',
              paddingBottom: '60px',
            }}
          >
            <div style={{ paddingTop: '30px' }}>
              <TextField
                label="Comments"
                variant="outlined"
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                fullWidth
                multiline
                rows={4}
                sx={{
                  marginTop: 2,
                  marginBottom: 2,
                  '& .MuiInputBase-root': { fontSize: '16px' },
                  '& .MuiInputLabel-root': { fontSize: '16px' },
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                position: 'fixed',
                bottom: 20,
                left: '50%',
                transform: 'translateX(-50%)',
                backgroundColor: 'white',
                zIndex: 1000,
                padding: '10px',
                alignItems: 'center',
              }}
            >
              <StyledButton
                variant="outlined_reject"
                text="Reject"
                onClick={handleReject}
                style={{ marginRight: 10 }}
              />
              <StyledButton
                variant="contained"
                color="primary"
                text="Approve"
                onClick={handleApprove}
              />
            </div>

            <Snackbar
              open={snackbarOpen}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
            >
              <Alert
                onClose={handleSnackbarClose}
                severity="warning"
                sx={{ width: '100%' }}
              >
                Comments cannot be empty!
              </Alert>
            </Snackbar>
          </div>
        )}
    </div>
  )
}
export default ErosionProfileStepper
