import React from 'react'
import Typography from '@mui/material/Typography'

const AccessTemplate = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Typography variant="h6" align="center">
        In this mode, only the "Data Management" tab is visible. To explore
        additional tabs, please switch to desktop mode.
      </Typography>
    </div>
  )
}
export default AccessTemplate
